import { createAction, props } from '@ngrx/store';

import { BudgetModel } from '../../budget-approval/models';
import { BuildingProgressPeriod } from '../../building-progress/models/building-progress-period.model';
import { BuildingProgressSharing } from '../../building-progress/models/building-progress-sharing/building-progress-sharing.model';
import { PeriodDateInterval } from '../../building-progress/models/period-date-interval.model';
import { Subcontractor } from '../../building-progress/modules/subcontractors/models';

export const loadSubcontractorsStart = createAction('[Subcontractors] Load subcontractors start',
  props<{ projectId: number, selectedContractorId?: number }>());
export const loadSubcontractorsSuccess = createAction(
  '[Subcontractors] Load subcontractors success', props<{ subcontractors: Subcontractor[], selectedContractorId?: number }>()
);
export const loadSubcontractorsError = createAction('[Subcontractors] Load subcontractors error', props<{ error: any }>());

export const selectSubcontractor = createAction('[Subcontractors] Select subcontractor', props<{ subcontractor: Subcontractor }>());

export const loadDialogItemsListStart = createAction('[Subcontractors] Load dialog items list start', props<{ projectId: number }>());
export const loadDialogItemsListSuccess = createAction(
  '[Subcontractors] Load dialog items list success', props<{ dialogItemsList: BudgetModel }>()
);
export const loadDialogItemsListError = createAction('[Subcontractors] Load dialog items list error', props<{ error: any }>());

export const createSubcontractorItemsStart = createAction(
  '[Subcontractors] Create subcontractor items start', props<{ projectId: number, name: string, itemIds: number[] }>()
);
export const createSubcontractorItemsSuccess = createAction('[Subcontractors] Create subcontractor items success');
export const createSubcontractorItemsError = createAction('[Subcontractors] Create subcontractor items error', props<{ error: any }>());

export const addSubcontractorItemsStart = createAction(
  '[Subcontractors] Add subcontractor items start', props<{ projectId: number, contractorId: number, itemIds: number[] }>()
);
export const addSubcontractorItemsSuccess = createAction('[Subcontractors] Add subcontractor items success');
export const addSubcontractorItemsError = createAction('[Subcontractors] Add subcontractor items error', props<{ error: any }>());

export const removeSubcontractorItemsStart = createAction(
  '[Subcontractors] Remove subcontractor items start', props<{ projectId: number, contractorId: number, itemIds: number[] }>()
);
export const removeSubcontractorItemsSuccess = createAction('[Subcontractors] Remove subcontractor items success');
export const removeSubcontractorItemsError = createAction('[Subcontractors] Remove subcontractor items error', props<{ error: any }>());

export const loadSharingListStart = createAction(
  '[Subcontractors] Load sharing list start', props<{ projectId: number, contractorId: number }>());
export const loadSharingListSuccess = createAction(
  '[Subcontractors] Load sharing list success', props<{ sharingList: BuildingProgressSharing[] }>());
export const loadSharingListError = createAction('[Subcontractors] Load sharing list error', props<{ error: any }>());

export const loadSubcontractorsSharingListStart = createAction(
  '[Subcontractors] Load subcontractors sharing list start', props<{ projectId: number }>());
export const loadSubcontractorsSharingListSuccess = createAction(
  '[Subcontractors] Load subcontractors sharing list success', props<{ sharingList: BuildingProgressSharing[] }>());
export const loadSubcontractorsSharingListError = createAction(
  '[Subcontractors] Load subcontractors sharing list error', props<{ error: any }>());

export const loadSubcontractorsPeriodsStart = createAction(
  '[Subcontractors] Load subcontractors periods start', props<{ projectId: number }>());
export const loadSubcontractorsPeriodsSuccess = createAction(
  '[Subcontractors] Load subcontractors periods success', props<{ periods: BuildingProgressPeriod[] }>());
export const loadSubcontractorsPeriodsError = createAction(
  '[Subcontractors] Load subcontractors periods error', props<{ error: any }>());

export const setSubcontractorsPeriodsStart = createAction(
  '[Subcontractors] Set subcontractors periods start',
  props<{
    projectId: number,
    contractorId: number,
    dateFrom: string,
    dateTo: string,
    areDetailsIncluded: boolean,
    targetPeriod?: PeriodDateInterval
  }>());

export const removeSubcontractorStart = createAction(
  '[Subcontractors] Remove subcontractor start', props<{ projectId: number, contractorId: number, selectedContractorId: number }>());
export const removeSubcontractorSuccess = createAction('[Subcontractors] Remove subcontractor success',
  props<{ projectId: number, selectedContractorId: number }>());
export const removeSubcontractorError = createAction('[Subcontractors] Remove subcontractor error', props<{ error: any }>());

export const editSubcontractorStart = createAction('[Subcontractors] Edit subcontractor start',
  props<{ projectId: number, selectedContractorId: number, contractorData: Subcontractor }>());
export const editSubcontractorSuccess = createAction('[Subcontractors] Edit subcontractor success',
  props<{ projectId: number, selectedContractorId: number }>());
export const editSubcontractorError = createAction('[Subcontractors] Edit subcontractor error', props<{ error: any }>());
