<div class="relative">
  <kros-input #searchInput
              [options]=searchInputSettings
              (selectItem)="selectItem($event)"></kros-input>

  <div class="search-indicator" *ngIf="showSearchIndicator$ | async" [class.search-indicator-offset]="isIOS">
    <div class="spinner-border" role="status"></div>
  </div>

  <ng-template #rt let-r="result" let-t="term">
      <div class="purchaser"
           data-test="purchaser-option"
           *ngIf="r.registrationId === constantPartnerNotFound"
           (click)="closeAutocomplete($event)">
        <kros-search-error-handler [errorItem]="r">
          <div class="business-name">{{r.address.businessName}}</div>
          <div class="purchaser-detail business-name">Pre zadaný výraz sme nenašli žiadneho partnera.</div>
        </kros-search-error-handler>
      </div>
      <div class="purchaser"
           data-test="purchaser-option"
           *ngIf="r.id >= 0 || r.registrationId >= 0  || !r.registrationId">
        <kros-search-error-handler [errorItem]="r">
          <div class="business-name">{{r.address.businessName}}</div>
          <div *ngIf="r.registrationId" class="purchaser-detail">IČO: {{r.registrationId}}</div>
          <div class="purchaser-detail">{{r.address.city}}</div>
        </kros-search-error-handler>
      </div>
      <div class="new-purchaser"
           *ngIf="r.registrationId === constantAddNewPartner && addNewPartner"
          (click)="emitNewPartner($event)">
        <span class="alternative">
          <i class="material-icons">add</i>
          Nový partner
        </span>
      </div>
  </ng-template>
</div>
