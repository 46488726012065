import { Injectable } from '@angular/core';

import {
  AnalyticsService,
  ExportFileType,
} from '@kros-sk/ssw-shared-legacy';
import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

import { BuildingProgressInvoiceDetailExportModel, CoverSheetExportTypeEnum } from '../models/building-progress-export.model';
import { getAppInsightsPrefix } from '../shared/building-progress-utils';
import { PermissionHelper } from './permission.helper';

@Injectable()
export class ExportAnalyticsHelper {
  constructor(
    private analyticsService: AnalyticsService,
    private appInsightsService: AppInsightsBaseService,
    private permissionHelper: PermissionHelper
  ) { }

  sendInvoiceAnalyticsNotification(exportModel: BuildingProgressInvoiceDetailExportModel): void {
    const prefix = getAppInsightsPrefix(this.permissionHelper.isContractor);
    this.appInsightsService.trackEvent(`${prefix}export`
      + `${exportModel.subcontractorId ? '-contractors' : ''}` + '-invoice'
      + `${exportModel.exportFileType === ExportFileType.Pdf ? '-pdf' : ''}`, {
      isIncludedConfirmationProtocol: '' + exportModel.isIncludedConfirmationProtocol,
      isIncludedBuildingObjectsSummary: '' + exportModel.isIncludedBuildingObjectsSummary,
      isIncludedSectionsSummary: '' + exportModel.isIncludedSectionsSummary,
      coverSheetExportType: exportModel.coverSheetExportType.toString(),
      isIncludedCompletedWorks: '' + exportModel.isIncludedCompletedWorks,
      completedWorksExportType: '' + exportModel.completedWorksSettings.completedWorksExportType.toString(),
      areCompletedWorksZeroItemsIncluded: '' + exportModel.completedWorksSettings.areZeroItemsIncluded,
      isBoqIncluded: '' + exportModel.completedWorksSettings.isBoqIncluded,
      areDetailsIncluded: '' + exportModel.completedWorksSettings.areDetailsIncluded,
      areNotesIncluded: '' + exportModel.completedWorksSettings.areNotesIncluded,
      isTotalPriceIncluded: '' + exportModel.completedWorksSettings.isTotalPriceIncluded,
      isPeriodPercentageIncluded: '' + exportModel.completedWorksSettings.isPeriodPercentageIncluded,
      isPartlyCompletedPercentageIncluded: '' + exportModel.completedWorksSettings.isPartlyCompletedPercentageIncluded,
      isPartlyCompletedAmountIncluded: '' + exportModel.completedWorksSettings.isPartlyCompletedAmountIncluded,
      isPartlyCompletedTotalPriceIncluded: '' + exportModel.completedWorksSettings.isPartlyCompletedTotalPriceIncluded,
      isRestPercentageIncluded: '' + exportModel.completedWorksSettings.isRestPercentageIncluded,
      isRestAmountIncluded: '' + exportModel.completedWorksSettings.isRestAmountIncluded,
      isRestTotalPriceIncluded: '' + exportModel.completedWorksSettings.isRestTotalPriceIncluded,
      isAdditionalPercentageIncluded: '' + exportModel.completedWorksSettings.isAdditionalPercentageIncluded,
      isAdditionalAmountIncluded: '' + exportModel.completedWorksSettings.isAdditionalAmountIncluded,
      isAdditionalTotalPriceIncluded: '' + exportModel.completedWorksSettings.isAdditionalTotalPriceIncluded,
      isNotCompletedPercentageIncluded: '' + exportModel.completedWorksSettings.isNotCompletedPercentageIncluded,
      isNotCompletedAmountIncluded: '' + exportModel.completedWorksSettings.isNotCompletedAmountIncluded,
      isNotCompletedTotalPriceIncluded: '' + exportModel.completedWorksSettings.isNotCompletedTotalPriceIncluded,
      isLogoIncluded: '' + exportModel.generalSettings.isLogoIncluded,
      isStampIncluded: '' + exportModel.generalSettings.isStampIncluded,
      statisticPropertyNames: 'isLogoIncluded, isStampIncluded'
    });

    this.raiseCheckboxAnalyticsEvent(exportModel.isIncludedConfirmationProtocol, 'zisťovací protokol', 'Zisťovací protokol');
    this.raiseCheckboxAnalyticsEvent(exportModel.isIncludedBuildingObjectsSummary && !exportModel.isIncludedSectionsSummary,
      'rekapitulácie za objekty', '"Rekapitulácie"');
    this.raiseCheckboxAnalyticsEvent(exportModel.isIncludedBuildingObjectsSummary && exportModel.isIncludedSectionsSummary,
      'rekapitulácie za objekty a diely', '"Rekapitulácie"');
    this.raiseCheckboxAnalyticsEvent(exportModel.coverSheetExportType.toString() === CoverSheetExportTypeEnum.Summary.toString(),
      'krycí list sumarizačný', '"Krycí list"');
    this.raiseCheckboxAnalyticsEvent(
      exportModel.coverSheetExportType.toString() === CoverSheetExportTypeEnum.SummaryAndBuildingObjects.toString(),
      'krycí list sumarizačný a objektový', '"Krycí list"');
    this.raiseCheckboxAnalyticsEvent(exportModel.isIncludedCompletedWorks,
      'súpis vykonaných prác', '"Súpis vykonaných prác"');
    this.raiseCheckboxAnalyticsEvent(exportModel.completedWorksSettings.areZeroItemsIncluded,
      'aj položky s nulovým výkonom', '"Aj položky s nulovým výkonom"');
    this.raiseCheckboxAnalyticsEvent(exportModel.isCompletedWorksObjects,
      'každý objekt na samostatný hárok', '"Exportovať každý objekt na samostatný hárok"');
    this.raiseCheckboxAnalyticsEvent(exportModel.completedWorksSettings.isBoqIncluded,
      's výkazom výmer', '"Exportovať s výkazom výmer"');
    this.raiseCheckboxAnalyticsEvent(exportModel.completedWorksSettings.areDetailsIncluded,
      'podrobnosti', '"Exportovať podrobnosti"');
    this.raiseCheckboxAnalyticsEvent(exportModel.completedWorksSettings.areNotesIncluded,
      'podrobnosti', '"Exportovať poznámku položky"');
    this.raiseCheckboxAnalyticsEvent(exportModel.completedWorksSettings.areColorCodesIncluded,
      'farebné príznaky položiek', '"Exportovať farebné príznaky položiek"');
    this.raiseCheckboxAnalyticsEvent(exportModel.completedWorksSettings.isTotalPriceIncluded,
      'stĺpec celková cena z rozpočtu', 'pre export stĺpca "Celková cena z rozpočtu"');
    this.raiseCheckboxAnalyticsEvent(exportModel.completedWorksSettings.isPeriodPercentageIncluded,
      'stĺpec obdobie - %', 'pre export stĺpca "Obdobie - %"');
    this.raiseCheckboxAnalyticsEvent(exportModel.completedWorksSettings.isPartlyCompletedPercentageIncluded,
      'stĺpec prestavané - %', 'pre export stĺpca "Prestavané - %"');
    this.raiseCheckboxAnalyticsEvent(exportModel.completedWorksSettings.isPartlyCompletedAmountIncluded,
      'stĺpec prestavané - výkon', 'pre export stĺpca "Prestavané - výkon"');
    this.raiseCheckboxAnalyticsEvent(exportModel.completedWorksSettings.isPartlyCompletedTotalPriceIncluded,
      'stĺpec prestavané - cena', 'pre export stĺpca "Prestavané - cena"');
    this.raiseCheckboxAnalyticsEvent(exportModel.completedWorksSettings.isRestPercentageIncluded,
      'stĺpec zostatok - %', 'pre export stĺpca "Zostatok - %"');
    this.raiseCheckboxAnalyticsEvent(exportModel.completedWorksSettings.isRestAmountIncluded,
      'stĺpec zostatok - výkon', 'pre export stĺpca "Zostatok - výkon"');
    this.raiseCheckboxAnalyticsEvent(exportModel.completedWorksSettings.isRestTotalPriceIncluded,
      'stĺpec zostatok - cena', 'pre export stĺpca "Zostatok - cena"');
    this.raiseCheckboxAnalyticsEvent(exportModel.completedWorksSettings.isAdditionalPercentageIncluded,
      'stĺpec prečerpané - %', 'pre export stĺpca "Prečerpané - %"');
    this.raiseCheckboxAnalyticsEvent(exportModel.completedWorksSettings.isAdditionalAmountIncluded,
      'stĺpec prečerpané - výkon', 'pre export stĺpca "Prečerpané - výkon"');
    this.raiseCheckboxAnalyticsEvent(exportModel.completedWorksSettings.isAdditionalTotalPriceIncluded,
      'stĺpec prečerpané - cena', 'pre export stĺpca "Prečerpané - cena"');
    this.raiseCheckboxAnalyticsEvent(exportModel.completedWorksSettings.isNotCompletedPercentageIncluded,
      'stĺpec menej práce - %', 'pre export stĺpca "Menej práce - %"');
    this.raiseCheckboxAnalyticsEvent(exportModel.completedWorksSettings.isNotCompletedAmountIncluded,
      'stĺpec menej práce - výkon', 'pre export stĺpca "Menej práce - výkon"');
    this.raiseCheckboxAnalyticsEvent(exportModel.completedWorksSettings.isNotCompletedTotalPriceIncluded,
      'stĺpec menej práce - cena', 'pre export stĺpca "Menej práce - cena"');
    this.raiseCheckboxAnalyticsEvent(exportModel.generalSettings.isLogoIncluded,
      'použiť logo', '"Použiť logo"');
    this.raiseCheckboxAnalyticsEvent(exportModel.generalSettings.isStampIncluded,
      'použiť pečiatku', '"Použiť pečiatku"');
  }

  private raiseCheckboxAnalyticsEvent(canSendStats: boolean, eventAction: string, eventLabel: string): void {
    if (canSendStats) {
      this.analyticsService.raiseEvent('Priebeh výstavby', 'Exportovať ' + eventAction,
        'Zaškrtnutý checkbox ' + eventLabel + ' v okne exportu "Podklady pre fakturáciu"');
    }
  }
}
