import { ChangeDetectorRef, Directive, HostBinding, Inject, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SafeUrl } from '@angular/platform-browser';

import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { APP_CONFIG } from '@kros-sk/app-config';
import {
  ApplicationType,
  EshopCompany,
  EshopTransactionType,
  InvoicingModuleType,
  License,
  ModuleType,
  PackageName,
  PackageType,
  PurchaseType,
  WarehouseModuleType
} from '@kros-sk/models';
import { AuthSelectorsService, HandleMethodErrors } from '@kros-sk/auth';

import { EshopCartLinkService } from '../services/eshop-cart-link.service';
import { KROS_COMPANY_SELECTOR } from '../../tools';

@Directive({
  selector: 'a:[krosEswEshopCartLink]'
})
export class EswEshopCartLinkDirective implements OnInit, OnDestroy {

  @Input() product: ApplicationType = ApplicationType.Invoicing;
  @Input() package: PackageType;
  @Input() module: ModuleType = null;
  @Input() modules: ModuleType[] = null;
  @Input() purchaseType: PurchaseType = PurchaseType.year;
  @Input() isLicenseRenewal = false;
  @Input() currentLicense: License = null;

  @HostBinding('target') target = '_blank';
  @HostBinding('rel') rel = 'noopener';

  private allLicenses: License[];
  private subs = new SubSink();
  private eshopCompany: EshopCompany;
  private encryptedCustomerNumber: string;
  private companySelector = inject(KROS_COMPANY_SELECTOR, { optional: true });

  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private authSelectors: AuthSelectorsService,
    private cdRef: ChangeDetectorRef,
    private linkService: EshopCartLinkService,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.getCompanyDataAndCustomerNumber().subscribe((object: {
      encryptedCustomerNumber, eshopCompany, licenses
    }) => {
      this.eshopCompany = object.eshopCompany;
      this.encryptedCustomerNumber = object.encryptedCustomerNumber;
      this.allLicenses = object.licenses;
      this.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  @HostBinding('href')
  get href(): SafeUrl {
    if (!this.allLicenses) return '';
    return this.linkService.getUrl(
      ApplicationType.Invoicing,
      this.package,
      this.purchaseType,
      this.getOtherParams(),
      true,
      this.getPackageName(),
      this.eshopCompany,
      this.encryptedCustomerNumber,
    );
  }

  @HandleMethodErrors({ status: ['404'] })
  private getCompanyDataAndCustomerNumber(): Observable<{
      encryptedCustomerNumber, eshopCompany, licenses
    }> {
    return combineLatest([
      this.authSelectors.allLicenses$,
      this.companySelector?.currentCompany$
    ]).pipe(
      filter(([licenses, company]) => !!licenses && !!company),
      tap(([licenses, _]) => this.setCurrentLicenseIfHasProduct(licenses)),
      switchMap(([licenses, company]) =>
        forkJoin({
          eshopCompany: of({
            registrationId: company.registrationId,
            taxId: company.taxId,
            vatId: company.vatId,
            city: company.address.city,
            street: company.address.street,
            businessName: company.address.businessName,
            postalCode: company.address.postCode,
            phoneNumber: company.phoneNumber
          }),
          customerNumber: this.isLicenseExpiredOrAboutToExpire()
            ? this.http.get<any>(`${this.appConfig.gatewayApiUrl}/companies/${company.id}/licenses/customerNumber`)
            : of(null),
          licenses: of(licenses)
        }).pipe(
          map(({ customerNumber, eshopCompany, licenses }) => ({
            encryptedCustomerNumber: customerNumber ? encodeURIComponent(customerNumber.customerNumber) : null,
            eshopCompany,
            licenses
          }))
        )
      )
    );
  }

  private isLicenseExpiredOrAboutToExpire(): boolean {
    return this.currentLicense && ((![PackageType.Free, PackageType.Trial].includes(this.currentLicense?.type) &&
      this.currentLicense?.daysValid <= 10) ||
      this.isLicenseRenewal);
  }

  private getOtherParams(): { [key: string]: number | boolean } {
    const otherParams: [string, number | boolean][] = [];
    const hasActiveLicense =
      this.allLicenses.some(license => license.type !== PackageType.Free && license.type !== PackageType.Trial && license.daysValid > 0);
    const hasExpiredLicense =
      this.allLicenses.some(license => (license.type === PackageType.Free || license.daysValid <= 0) && license.type !== PackageType.Trial);

    if (this.isLicenseRenewal) {
      otherParams.push(['type', EshopTransactionType.LicenseRenewal]);
    } else if (hasActiveLicense) {
      otherParams.push(['type', EshopTransactionType.LicenseExtension]);
    } else if (hasExpiredLicense) {
      otherParams.push(['type', EshopTransactionType.LicenseRenewal]);
    }

    this.allLicenses.some(license => {
      if (license?.customerNumber) {
        otherParams.push(['customerId', +license.customerNumber]);
        return;
      } else {
        otherParams.push(['buyOnNewCompany', true]);
        return;
      }
    });

    // Flexi package modules have its own params in eshop queries
    if (this.module) {
      this.setModulePath(this.product, this.module, otherParams);
    }

    if (this.modules) {
      for (const module of this.modules) {
        this.setModulePath(this.product, module, otherParams);
      }
    }

    // return otherParams as object
    if (otherParams.length > 0) {
      const ret = {};
      otherParams.forEach(value => {
        ret[value[0]] = value[1];
      });
      return ret;
    }
    return null;
  }

  private setModulePath(applicationType: ApplicationType, module: ModuleType, otherParams: [string, (number | boolean)][]): void {
    switch (applicationType) {
      case ApplicationType.Invoicing:
        this.setInvoicingModulePath(module, otherParams);
        break;
      case ApplicationType.Warehouse:
        this.setWarehouseModulePath(module, otherParams);
        break;
    }
  }

  private setInvoicingModulePath(module: ModuleType, otherParams: [string, (number | boolean)][]): void {
    switch (module) {
      case InvoicingModuleType.PriceOffers:
        otherParams.push(['priceOffers', 1]);
        break;
      case InvoicingModuleType.DeliveryNotes:
        otherParams.push(['deliveryNotes', 1]);
        break;
      case InvoicingModuleType.Orders:
        otherParams.push(['orders', 1]);
        break;
      case InvoicingModuleType.OpenApi:
        otherParams.push(['apiConnection', 1]);
        break;
    }
  }

  private setWarehouseModulePath(module: ModuleType, otherParams: [string, (number | boolean)][]): void {
    switch (module) {
      case WarehouseModuleType.OpenApi:
        otherParams.push(['apiConnection', 1]);
        break;
    }
  }

  private getCurrentLicense(allLicenses: License[], applicationType: ApplicationType): License {
    return allLicenses.find(lic => lic.applicationType === applicationType);
  }

  private setCurrentLicenseIfHasProduct(allLicenses: License[]): void {
    if (this.product) {
      this.currentLicense = this.getCurrentLicense(allLicenses, this.product);
    }
  }

  private getPackageName(): PackageName {
    switch (this.product) {
      case ApplicationType.Warehouse: return PackageName.WarehousePackage;
      case ApplicationType.DigitalOffice: return PackageName.DigitalOfficePackage;
      default: return PackageName.Package;
    }
  }

  private detectChanges(): void {
    if (!this.cdRef['destroyed']) {
      this.cdRef.detectChanges();
    }
  }
}
