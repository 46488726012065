import { inject, Injectable } from '@angular/core';

import { BrowserTabService, NavbarService } from '@kros-sk/ssw-shared-legacy';

import { environment } from '../../../environments/environment';

@Injectable()
export class BudgetApprovalHelper {

  private navbarService = inject(NavbarService);
  private browserTabService = inject(BrowserTabService);

  setTitleAndNavbar(): void {
    this.browserTabService.setTitle('BUDGET_APPROVAL.TITLE');
    this.navbarService.changeTitle('BUDGET_APPROVAL.TITLE');
    this.navbarService.setNewsData({
      app: 'Titan',
      isTest: environment.news.isTest.toString(),
      modul: 'ZPV'
    });
  }
}
