import { DataSource } from '@angular/cdk/collections';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { DashboardItem, SummaryDashboardTableModel } from '../../../models/summary-dashboard.model';

export class DashboardDatasource implements DataSource<DashboardItem> {
  private tableDataSubject = new BehaviorSubject<DashboardItem[]>([]);
  private loadingDataSubject = new BehaviorSubject<boolean>(false);
  private dataSub?: Subscription;

  constructor(private data$: Observable<SummaryDashboardTableModel>) { }

  connect(): Observable<DashboardItem[]> {
    return this.tableDataSubject.asObservable();
  }

  loading(): Observable<boolean> {
    return this.loadingDataSubject.asObservable();
  }

  disconnect(): void {
    this.tableDataSubject.complete();
    this.loadingDataSubject.complete();
    this.dataSub?.unsubscribe();
  }

  load(): void {
    this.loadingDataSubject.next(true);
    this.dataSub = this.data$.subscribe((data: SummaryDashboardTableModel) => {
      this.loadingDataSubject.next(false);
      this.tableDataSubject.next(data.items);
    });
  }
}
