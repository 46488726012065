import produce from 'immer';

import { BuildingProgressSettings, BuildingProgressSettingsEditModel } from '@kros-sk/ssw-shared/settings';
import {
  CalculationType,
  isItem,
  SetColorCodeResult,
  VatRateType
} from '@kros-sk/ssw-shared-legacy';

import { adapterPeriods } from './building-progress.adapters';
import {
  BuildingProgressCell,
  BuildingProgressItem,
  BuildingProgressModel,
  BuildingProgressPeriodItem,
  UnmarkedBoqItem
} from '../../building-progress/models/construction-data.model';
import {
  BuildingProgressChangedProperty,
  BuildingProgressEditedPeriodItem,
  BuildingProgressEditModel
} from '../../building-progress/models/building-progress-edit.model';
import {
  BuildingProgressChangedPropertyToAnimate,
  BuildingProgressSetModel,
  BuildingProgressSettedPeriodItem
} from '../../building-progress/models/building-progress-set.model';
import { BuildingProgressPeriod } from '../../building-progress/models/building-progress-period.model';
import { BuildingProgressState } from './building-progress.state';
import { SetAutocalculatedAmountModel } from '../../building-progress/models/set-autocalculated-amount.model';

export function editPeriods(payload: any, periods: BuildingProgressPeriod[]): void {
  const index = periods.findIndex(p => p.id === payload.id);
  if (index > -1) {
    periods[index].dateFrom = payload.dateFrom;
    periods[index].dateTo = payload.dateTo;
    periods[index].basicVatRate = payload.basicVatRate;
    periods[index].reducedVatRate = payload.reducedVatRate;
  }
}

export function sortPeriods(periods: BuildingProgressPeriod[], descending: boolean): void {
  periods.sort((a, b) => {
    const delta = (new Date(a.dateFrom).getTime() - new Date(b.dateFrom).getTime()) * (descending ? -1 : 1);
    return delta === 0
      ? (new Date(a.dateTo).getTime() - new Date(b.dateTo).getTime()) * (descending ? -1 : 1)
      : delta;
  });
}

export function createPeriodItem(periodId: number, item: BuildingProgressItem): BuildingProgressPeriodItem {
  return {
    amount: 0,
    calculationType: item.calculationType,
    order: 0,
    percentage: 0,
    periodId,
    totalPrice: 0,
    detailId: null
  };
}

export function getAffectedCellsAfterEdit(editModel: BuildingProgressEditModel): BuildingProgressCell[] {
  return editModel.editedPeriodItems.map(p => getAffectedCell(p, editModel.periodId));
}

export function getAffectedCellsAfterSet(editModel: BuildingProgressSetModel): BuildingProgressCell[] {
  let ret = [];
  editModel.periodItems.forEach(p => ret = ret.concat(getAffectedCellAfterSet(p, editModel.periodId)));
  return ret;
}

export function getAffectedCellsAfterPercentageSet(editedItems: BuildingProgressItem[], periodId: number): BuildingProgressCell[] {
  return editedItems.filter(p => isItem(p))
    .map(p => {
      return { rowId: p.id, columnId: 'percentage-' + periodId } as BuildingProgressCell;
    });
}

export function getAffectedCellAfterAmountChanged(editModel: SetAutocalculatedAmountModel): BuildingProgressCell {
  return { rowId: editModel.budgetItemId, columnId: 'amount-' + editModel.periodId };
}

export function getAffectedCellAfterColorChanged(setColorCodeResult: SetColorCodeResult): BuildingProgressCell[] {
  return Object.keys(setColorCodeResult.budgetItemIdToColorCodeMap).map(id => {
    return { rowId: +id, columnId: 'itemNumber' } as BuildingProgressCell;
  });
}

export function loadNewConstructionData(
  constructionData: BuildingProgressModel,
  editedItems: BuildingProgressItem[],
  resetCalculationTypeBudgetItemId = 0,
  loadCalculationTypes?: boolean
): BuildingProgressModel {
  return produce(constructionData, newData => {
    editedItems?.forEach(element => {
      const index = newData?.items?.findIndex(item => item.id === element.id);

      if (index > -1) {
        const modifiedItem = newData.items[index];
        if (modifiedItem.id === resetCalculationTypeBudgetItemId) {
          modifiedItem.periods.forEach(p => p.calculationType = CalculationType.None);
        }
        loadPeriodsItemData(modifiedItem, element, loadCalculationTypes);
        modifiedItem.partlyCompletedAmount = element.partlyCompletedAmount;
        modifiedItem.partlyCompletedTotalPrice = element.partlyCompletedTotalPrice;
        modifiedItem.partlyCompletedPercentage = element.partlyCompletedPercentage;
        modifiedItem.completedAmount = element.completedAmount;
        modifiedItem.completedTotalPrice = element.completedTotalPrice;
        modifiedItem.completedPercentage = element.completedPercentage;
        modifiedItem.restAmount = element.restAmount;
        modifiedItem.restTotalPrice = element.restTotalPrice;
        modifiedItem.restPercentage = element.restPercentage;
        modifiedItem.additionalAmount = element.additionalAmount;
        modifiedItem.additionalTotalPrice = element.additionalTotalPrice;
        modifiedItem.additionalPercentage = element.additionalPercentage;
        modifiedItem.notCompletedPercentage = element.notCompletedPercentage;
        modifiedItem.notCompletedAmount = element.notCompletedAmount;
        modifiedItem.notCompletedTotalPrice = element.notCompletedTotalPrice;
        modifiedItem.isNotCompleted = element.isNotCompleted;
      }
    });
  });
}

const loadPeriodsItemData = (modifiedItem: BuildingProgressItem, element: BuildingProgressItem, allPeriods?: boolean): void => {
  const max = allPeriods ? element.periods.length : 1;
  for (let i = 0; i < max; i++) {
    const newPeriodItem = element.periods[i];
    if (newPeriodItem) {
      const periodIndex = modifiedItem.periods.findIndex(p => p.periodId === newPeriodItem.periodId);
      if (periodIndex !== -1) {
        const oldPeriodItem = modifiedItem.periods[periodIndex];
        modifiedItem.periods[periodIndex] = {
          ...newPeriodItem,
          detailId: oldPeriodItem.detailId
        };
      }
    }
  }
};

export function loadNewConstructionDataColors(
  constructionData: BuildingProgressModel,
  setColorCodeResult: SetColorCodeResult
): BuildingProgressModel {
  return produce(constructionData, newData => {
    Object.keys(setColorCodeResult.budgetItemIdToColorCodeMap).forEach(id => {
      const index = newData.items.findIndex(item => item.id === +id);
      if (index !== -1) {
        const modifiedItem = newData.items[index];
        modifiedItem.colorCode = setColorCodeResult.budgetItemIdToColorCodeMap[+id];
      }
    });
  });
}

export function loadNewConstructionDataDelete(
  constructionData: BuildingProgressModel,
  editedItems: BuildingProgressItem[],
  deletedItemIds: number[]
): BuildingProgressModel {
  return produce(constructionData, newData => {
    deletedItemIds.forEach(pp => {
      const index = newData.items.findIndex(item => item.id === pp);
      if (index !== -1) {
        newData.items.splice(index, 1);
      }
    });

    if (newData.items.length === 0) {
      newData.periods = [];
    }

    editedItems.forEach(element => {
      const index = newData.items.findIndex(item => item.id === element.id);
      const modifiedItem = newData.items[index];

      modifiedItem.amount = element.amount;
      modifiedItem.totalPrice = element.totalPrice;
      modifiedItem.periods = element.periods;
      modifiedItem.completedAmount = element.completedAmount;
      modifiedItem.completedTotalPrice = element.completedTotalPrice;
      modifiedItem.completedPercentage = element.completedPercentage;
      modifiedItem.restAmount = element.restAmount;
      modifiedItem.restTotalPrice = element.restTotalPrice;
      modifiedItem.restPercentage = element.restPercentage;
      modifiedItem.additionalAmount = element.additionalAmount;
      modifiedItem.additionalTotalPrice = element.additionalTotalPrice;
      modifiedItem.additionalPercentage = element.additionalPercentage;
    });
  });
}

export function createPeriod(
  state: BuildingProgressState,
  payload: any
): BuildingProgressState {
  const period: BuildingProgressPeriod = {
    id: payload.id,
    dateFrom: payload.dateFrom,
    dateTo: payload.dateTo,
    isLocked: false,
    isApproved: false,
    isApprovalRequested: false,
    isMarkedInFilter: true,
    hasDocuments: false,
    hasInvoices: false,
    basicVatRate: state.settings.vatRates[VatRateType.Basic] ?? state.settings.vatRates[VatRateType.BasicTransferred] ?? 0,
    reducedVatRate: state.settings.vatRates[VatRateType.Reduced] ?? state.settings.vatRates[VatRateType.ReducedTransferred] ?? 0,
    isMultiStageApprovalInProgress: false,
    canApprove: false,
    canCancelApprove: false
  };
  const data = produce(state.constructionData, newData => {
    newData.periods.push(period);
    sortPeriods(newData.periods, false);
    newData.items.forEach(item => {
      item.periods.push(createPeriodItem(payload.id, item));
    });
  });

  const periods = produce(
    adapterPeriods.getSelectors().selectAll(payload.addToWholeBuildingPeriods ? state.wholeBuildingPeriods : state.periods), newPeriods => {
      newPeriods.push({
        ...period,
        dateFrom: new Date(period.dateFrom.getTime() - period.dateFrom.getTimezoneOffset() * 60000),
        dateTo: new Date(period.dateTo.getTime() - period.dateTo.getTimezoneOffset() * 60000)
      });
      sortPeriods(newPeriods, true);
    });
  return {
    ...state,
    ...(!payload.addToWholeBuildingPeriods && { constructionData: data }),
    ...(payload.addToWholeBuildingPeriods && { wholeBuildingPeriods: adapterPeriods.setAll(periods, state.wholeBuildingPeriods) }),
    ...(!payload.addToWholeBuildingPeriods && { periods: adapterPeriods.setAll(periods, state.periods) }),
    periodId: payload.id,
    periodsLoading: false
  };
}

export function setApprovingPeriods(
  state: BuildingProgressState,
  payload: any,
  isApprovalRequested: boolean,
  isApproved: boolean,
  isMultiStageApprovalModeEnabled = false): BuildingProgressState {
  const data = produce(state.constructionData, newData => {
    setApprovingPeriod(payload.periodId, newData.periods, isApprovalRequested, isApproved, isMultiStageApprovalModeEnabled);
  });

  const periods = produce(state.periods, newPeriods => {
    newPeriods.entities[payload.periodId].isApprovalRequested = isApprovalRequested;
    newPeriods.entities[payload.periodId].isApproved = isApproved;
    newPeriods.entities[payload.periodId].canApprove = false;
    newPeriods.entities[payload.periodId].isMultiStageApprovalInProgress = isMultiStageApprovalModeEnabled && !isApproved;
    newPeriods.entities[payload.periodId].canCancelApprove = true;
  });

  return {
    ...state,
    constructionData: data,
    periods
  };
}

export function setCancelIsApprovalRequestedPeriods(state: BuildingProgressState): BuildingProgressState {
  const data = produce(state.constructionData, newData => {
    newData.periods.forEach(period => {
      period.isApprovalRequested = false;
    });
  });

  const periods = produce(state.periods, newPeriods => {
    Object.entries(newPeriods.entities).forEach(([_, value]) => {
      value.isApprovalRequested = false;
    });
  });

  return {
    ...state,
    constructionData: data,
    periods
  };
}

export function setLockedPeriods(
  state: BuildingProgressState,
  payload: any,
  isLocked: boolean
): BuildingProgressState {
  const data = state.constructionData
    ? produce(state.constructionData, newData => setLockedPeriod(payload.periodId, newData.periods, isLocked))
    : state.constructionData;

  const periods = produce(state.periods, newPeriods => {
    newPeriods.entities[payload.periodId].isLocked = isLocked;
  });

  return {
    ...state,
    constructionData: data,
    periods
  };
}

export function deletePeriod(state: BuildingProgressState, payload: any): BuildingProgressState {
  let periods = adapterPeriods.removeOne(payload.id, state.periods);
  let hasAnyMarkedPeriod = false;
  let periodId: number;

  if (periods?.ids.length > 0 && state.constructionData?.periods) {
    periods.ids.forEach(id => {
      if (periods.entities[id].isMarkedInFilter) {
        hasAnyMarkedPeriod = true;
      }
    });

    if (!hasAnyMarkedPeriod) {
      periods = produce(periods, newPeriods => {
        newPeriods.entities[newPeriods.ids[0]].isMarkedInFilter = true;
      });
    }

    const remainingPeriods = state.constructionData.periods.filter(period => period.id !== payload.id);
    periodId = remainingPeriods[remainingPeriods.length - 1].id;
  }

  return {
    ...state,
    periods,
    periodId
  };
}

const setApprovingPeriod = (
  id: number,
  periods: BuildingProgressPeriod[],
  isApprovalRequested: boolean,
  isApproved: boolean,
  isMultiStageApprovalModeEnabled: boolean): void => {
  const index = periods.findIndex(p => p.id === id);
  if (index > -1) {
    periods[index].isApprovalRequested = isApprovalRequested;
    periods[index].isApproved = isApproved;
    periods[index].isMultiStageApprovalInProgress = isMultiStageApprovalModeEnabled && !isApproved;
  }
};

const setLockedPeriod = (periodId: number, periods: BuildingProgressPeriod[], isLocked: boolean): void => {
  const index = periods.findIndex(p => p.id === periodId);
  if (index > -1) {
    periods[index].isLocked = isLocked;
  }
};

const getAffectedCell = (editModel: BuildingProgressEditedPeriodItem, periodId: number): BuildingProgressCell => {
  return { rowId: editModel.budgetItemId, columnId: getColumnIdByChangedProperty(editModel.changedProperty) + periodId };
};

export const getColumnIdByChangedProperty = (changedProperty: BuildingProgressChangedProperty): string => {
  switch (changedProperty) {
    case BuildingProgressChangedProperty.Percentage:
      return 'percentage-';
    case BuildingProgressChangedProperty.Amount:
      return 'amount-';
    case BuildingProgressChangedProperty.TotalPrice:
      return 'totalPrice-';
  }
};

const getAffectedCellAfterSet = (editModel: BuildingProgressSettedPeriodItem, periodId: number): BuildingProgressCell[] => {
  const ret = [];
  // eslint-disable-next-line no-bitwise
  if ((editModel.changedProperty | BuildingProgressChangedPropertyToAnimate.Amount) === editModel.changedProperty) {
    ret.push({ rowId: editModel.budgetItemId, columnId: 'amount-' + periodId });
  }
  // eslint-disable-next-line no-bitwise
  if ((editModel.changedProperty | BuildingProgressChangedPropertyToAnimate.Percentage) === editModel.changedProperty) {
    ret.push({ rowId: editModel.budgetItemId, columnId: 'percentage-' + periodId });
  }
  // eslint-disable-next-line no-bitwise
  if ((editModel.changedProperty | BuildingProgressChangedPropertyToAnimate.TotalPrice) === editModel.changedProperty) {
    ret.push({ rowId: editModel.budgetItemId, columnId: 'totalPrice-' + periodId });
  }

  return ret;
};

export function loadNewBudgetSettings(
  settings: BuildingProgressSettings,
  newSettings: BuildingProgressSettingsEditModel
): BuildingProgressSettings {
  return produce(settings, newData => {
    newData.isTransferredVat = newSettings.isTransferredVat;
  });
}

export const getGroupedEditModels = (editModels: BuildingProgressEditModel[]): BuildingProgressEditModel[] => {
  let ret: BuildingProgressEditModel[] = [];

  editModels.forEach(editModel => {
    const period = ret.find(r => r.periodId === editModel.periodId);
    const items = period ? [
      ...period?.editedPeriodItems.filter(p => !editModel.editedPeriodItems.some(i => p.budgetItemId === i.budgetItemId)),
      ...editModel.editedPeriodItems
    ] : [...editModel.editedPeriodItems];

    const editedEditModel = { ...editModel, editedPeriodItems: items };
    ret = [...ret.filter(r => r.periodId !== editModel.periodId), editedEditModel];
  });

  return ret;
};

export const getUnmarkedBoqItemIdsFromItems = (unmarkedBoqItems: UnmarkedBoqItem[]): number[] => {
  return unmarkedBoqItems.map(p => p.unmarkedBoqItemIds).reduce((prev, p) => (prev.concat(p)), []);
};

export function loadNewConstructionDataDetails(
  constructionData: BuildingProgressModel,
  itemId: number,
  periodId: number,
  detailId: number
): BuildingProgressModel {
  return produce(constructionData, newData => {
    const index = newData.items.findIndex(item => item.id === itemId);
    const modifiedItem = newData.items[index];

    modifiedItem.periods = modifiedItem.periods.map(period =>
      period.periodId === periodId ? { ...period, detailId } : period);
  });
}

export function addEditingBudgetItemId(editingBudgetItems: number[], budgetItemId: number): number[] {
  return produce(editingBudgetItems, newData => {
    newData.push(budgetItemId);
  });
}

export function deleteEditingBudgetItemId(editingBudgetItems: number[], budgetItemId: number): number[] {
  return produce(editingBudgetItems, newData => {
    newData.splice(newData.indexOf(budgetItemId), 1);
  });
}
