import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SearchErrorHandlerComponent } from './search-error-handler/search-error-handler.component';

@NgModule({
  declarations: [
    SearchErrorHandlerComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SearchErrorHandlerComponent
  ]
})
export class SearchErrorHandlingModule { }
