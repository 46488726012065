import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';

import { SubSink } from 'subsink';

import { DeviceDetectorService, GlobalEventsService } from '../../services';

export interface MobileHeightSettings {
  heightPropertyName?: string;
  height?: number;
  offset?: string;
}

@Directive({
  selector: '[mobileHeight]',
  exportAs: 'mobileHeight'
})
export class MobileHeightDirective implements OnInit, OnDestroy {

  @Input() mobileHeightSettings: MobileHeightSettings | MobileHeightSettings[] | null;

  private subs = new SubSink();
  private lastFullHeight = 0;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private detector: DeviceDetectorService,
    protected globalEventService: GlobalEventsService
  ) { }

  ngOnInit(): void {
    if (this.detector.isMobileOS()) {
      this.subs.sink = this.globalEventService.listenEvent('window:resize').subscribe(_ => {
        this.setHeight();
      });

      this.setHeight();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  setHeight(): void {
    const fullHeight = this.detector.windowHeight;
    if (fullHeight !== this.lastFullHeight) {
      this.lastFullHeight = fullHeight;

      if (!this.mobileHeightSettings) {
        // null
        this.mobileHeightSettings = this.getDefaultSettings();
      }

      if (Array.isArray(this.mobileHeightSettings)) {
        if (this.mobileHeightSettings.length === 0) {
          this.mobileHeightSettings = [ this.getDefaultSettings() ];
        }

        this.mobileHeightSettings.forEach(property => {
          this.addDefaults(property);
          this.setHeightPropertyToElement(fullHeight, property);
        });
      } else {
        this.addDefaults(this.mobileHeightSettings);
        this.setHeightPropertyToElement(fullHeight, this.mobileHeightSettings);
      }
    }
  }

  private setHeightPropertyToElement(fullHeight: number, settings: MobileHeightSettings): void {
    const height = this.getNewHeight(fullHeight, settings);

    this.renderer.setStyle(this.element.nativeElement, settings.heightPropertyName, height);
  }

  private getNewHeight(fullHeight: number, settings: MobileHeightSettings): string {
    const height = this.calculateHeight(fullHeight, settings) + 'px';

    if (!settings.offset) {
      return height;
    } else {
      return `calc(${height} - ${settings.offset})`;
    }
  }

  private calculateHeight(fullHeight: number, settings: MobileHeightSettings): number {
    if (settings.height === 100) {
      return fullHeight;
    }

    return fullHeight / 100 * Math.abs(Math.min(settings.height, 100));
  }

  private addDefaults(settings: MobileHeightSettings): void {
    settings.heightPropertyName = settings.heightPropertyName || 'height';
    settings.height = settings.height || 100;
    settings.offset = settings.offset || '';
  }

  private getDefaultSettings(): MobileHeightSettings {
    return {
      offset: '',
      heightPropertyName: 'height',
      height: 100,
    };
  }
}
