import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { SafeHtmlPipe } from '@kros-sk/shared/pipes';

import { FilterPipe } from './filter.pipe';
import { FormatNumberPipe } from './format-number.pipe';
import { FormatStringPipe } from './format-string.pipe';
import { LicenseEshopRedirectPipe } from './license-eshop-redirect.pipe';
import { LicenseInfoFormatPipe } from './license-info-format.pipe';
import { PluralisationByCountPipe } from './pluralisation-by-count.pipe';
import { TruncatePipe } from './truncate.pipe';
import { VatRateTypePipe } from './vat-rate-type.pipe';

@NgModule({
  declarations: [
    PluralisationByCountPipe,
    FormatStringPipe,
    TruncatePipe,
    FormatNumberPipe,
    VatRateTypePipe,
    FilterPipe,
    LicenseInfoFormatPipe,
    LicenseEshopRedirectPipe
  ],
  imports: [
    CommonModule,
    SafeHtmlPipe
  ],
  exports: [
    PluralisationByCountPipe,
    SafeHtmlPipe,
    FormatStringPipe,
    TruncatePipe,
    FormatNumberPipe,
    TitleCasePipe,
    VatRateTypePipe,
    FilterPipe,
    LicenseInfoFormatPipe,
    LicenseEshopRedirectPipe
  ],
  providers: [
    PluralisationByCountPipe,
    SafeHtmlPipe,
    FormatStringPipe,
    FormatNumberPipe,
    TruncatePipe,
    TitleCasePipe,
    VatRateTypePipe
  ]
})
export class PipesModule {
}
