import { Component, LOCALE_ID } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { KrosModalRef, KrosModalService } from '@kros-sk/components';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

import { BuildingProgressItemDrawSheetModel } from '../../../models/building-progress-export.model';
import { BuildingProgressPeriod } from '../../../models/building-progress-period.model';
import { environment } from '../../../../../environments/environment';
import { getAppInsightsPrefix } from '../../../shared/building-progress-utils';
import { openHeaderSettingsDialog } from '../building-progress-export-helpers/building-progress-export-helpers';
import { PermissionHelper } from '../../../helpers';
import { ProjectsDispatchersService } from '../../../../store/projects';

@Component({
  selector: 'app-building-progress-export-item-draw-sheet',
  templateUrl: './building-progress-export-item-draw-sheet.component.html',
  styleUrls: ['./building-progress-export-item-draw-sheet.component.scss'],
  providers: [
    { provide: LOCALE_ID, useValue: environment.defaultLanguage },
  ]
})

export class BuildingProgressExportItemDrawSheetComponent extends UnsubscribeComponent {
  protected periods: BuildingProgressPeriod[] = [];
  protected itemIds: number[] = [];
  protected exportForm = new FormGroup({
    periodIds: new FormControl<number[]>(null, Validators.required),
    contractorName: new FormControl<string>('', Validators.required),
    supervisorName: new FormControl<string>('', Validators.required),
    areDetailsIncluded: new FormControl<boolean>(true),
    exportDate: new FormControl<string>(''),
  });

  get isContractor(): boolean {
    return this.permissionHelper.isContractor;
  }

  constructor(
    private modalRef: KrosModalRef<{ exportModel: BuildingProgressItemDrawSheetModel, periods: BuildingProgressPeriod[] }>,
    private krosModalService: KrosModalService,
    private projectsDispatcherService: ProjectsDispatchersService,
    private appInsightsService: AppInsightsBaseService,
    private datePipe: DatePipe,
    private permissionHelper: PermissionHelper
  ) {
    super();
    this.periods = this.modalRef.data.periods;
    this.itemIds = this.modalRef.data.exportModel.itemIds;
    this.exportForm.controls.periodIds.setValue(this.modalRef.data.exportModel.periodIds);
    this.exportForm.controls.contractorName.setValue(this.modalRef.data.exportModel.contractorName);
    this.exportForm.controls.supervisorName.setValue(this.modalRef.data.exportModel.supervisorName);
    this.exportForm.controls.areDetailsIncluded.setValue(this.modalRef.data.exportModel.areDetailsIncluded);
    this.exportForm.controls.exportDate.setValue('');
  }

  onSubmit(): void {
    this.sendAnalyticsNotification(this.itemIds.length !== 0);
    this.modalRef.submit(
      {
        exportModel: {
          contractorName: this.exportForm.value.contractorName,
          supervisorName: this.exportForm.value.supervisorName,
          periodIds: this.exportForm.value.periodIds,
          projectId: this.modalRef.data.exportModel.projectId,
          areDetailsIncluded: this.exportForm.value.areDetailsIncluded,
          exportDate: this.datePipe.transform(this.exportForm.value.exportDate, 'yyyy-MM-dd'),
          itemIds: this.itemIds
        },
        periods: this.periods
      }
    );
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  onHeaderDataEdit(): void {
    this.subs.sink = openHeaderSettingsDialog(
      this.krosModalService,
      this.projectsDispatcherService,
      this.permissionHelper,
      this.appInsightsService,
      this.modalRef.data.exportModel.projectId,
      this.isContractor
    );
  }

  private sendAnalyticsNotification(itemsSelected :boolean): void {
    this.appInsightsService.trackEvent(
      `${getAppInsightsPrefix(this.isContractor)}export-${itemsSelected ? 'selected-' : ''}item-draw-sheet`,
      { periodIds: this.exportForm.value.periodIds }
    );
  }
}
