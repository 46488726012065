export const countries = [
  { code: 'SK', name: 'Slovenská republika' , search: 'slovenska republika'},
  { code: 'CZ', name: 'Česká republika' , search: 'ceska republika'},
  { code: 'AF', name: 'Afganistan' , search: 'afganistan'},
  { code: 'AX', name: 'Alandy' , search: 'alandy'},
  { code: 'AL', name: 'Albánsko' , search: 'albansko'},
  { code: 'DZ', name: 'Alžírsko' , search: 'alzirsko'},
  { code: 'AS', name: 'Americká Samoa' , search: 'americka samoa'},
  { code: 'VI', name: 'Americké Panenské ostrovy' , search: 'americke panenske ostrovy'},
  { code: 'AD', name: 'Andorra' , search: 'andorra'},
  { code: 'AO', name: 'Angola' , search: 'angola'},
  { code: 'AI', name: 'Anguilla' , search: 'anguilla'},
  { code: 'AQ', name: 'Antarktída' , search: 'antarktida'},
  { code: 'AG', name: 'Antigua a Barbuda' , search: 'antigua a barbuda'},
  { code: 'AR', name: 'Argentína' , search: 'argentina'},
  { code: 'AM', name: 'Arménsko' , search: 'armensko'},
  { code: 'AW', name: 'Aruba' , search: 'aruba'},
  { code: 'AU', name: 'Austrália' , search: 'australia'},
  { code: 'AZ', name: 'Azerbajdžan' , search: 'azerbajdzan'},
  { code: 'BS', name: 'Bahamy' , search: 'bahamy'},
  { code: 'BH', name: 'Bahrajn' , search: 'bahrajn'},
  { code: 'BD', name: 'Bangladéš' , search: 'banglades'},
  { code: 'BB', name: 'Barbados' , search: 'barbados'},
  { code: 'BE', name: 'Belgicko' , search: 'belgicko'},
  { code: 'BZ', name: 'Belize' , search: 'belize'},
  { code: 'BJ', name: 'Benin' , search: 'benin'},
  { code: 'BM', name: 'Bermudy' , search: 'bermudy'},
  { code: 'BT', name: 'Bhután' , search: 'bhutan'},
  { code: 'BY', name: 'Bielorusko' , search: 'bielorusko'},
  { code: 'BO', name: 'Bolívia' , search: 'bolivia'},
  { code: 'BQ', name: 'Bonaire, Svätý Eustach a Saba' , search: 'bonaire, svaty eustach a saba'},
  { code: 'BA', name: 'Bosna a Hercegovina' , search: 'bosna a hercegovina'},
  { code: 'BW', name: 'Botswana' , search: 'botswana'},
  { code: 'BV', name: 'Bouvetov ostrov' , search: 'bouvetov ostrov'},
  { code: 'BR', name: 'Brazília' , search: 'brazilia'},
  { code: 'IO', name: 'Britské indickooceánske územie' , search: 'britske indickooceanske uzemie'},
  { code: 'VG', name: 'Britské Panenské ostrovy' , search: 'britske panenske ostrovy'},
  { code: 'BN', name: 'Brunei' , search: 'brunei'},
  { code: 'BG', name: 'Bulharsko' , search: 'bulharsko'},
  { code: 'BF', name: 'Burkina' , search: 'burkina'},
  { code: 'BI', name: 'Burundi' , search: 'burundi'},
  { code: 'CK', name: 'Cookove ostrovy' , search: 'cookove ostrovy'},
  { code: 'CW', name: 'Curaçao' , search: 'curaçao'},
  { code: 'CY', name: 'Cyprus' , search: 'cyprus'},
  { code: 'TD', name: 'Čad' , search: 'cad'},
  { code: 'ME', name: 'Čierna Hora' , search: 'cierna hora'},
  { code: 'CL', name: 'Čile' , search: 'cile'},
  { code: 'CN', name: 'Čína' , search: 'cina'},
  { code: 'DK', name: 'Dánsko' , search: 'dansko'},
  { code: 'DM', name: 'Dominika' , search: 'dominika'},
  { code: 'DO', name: 'Dominikánska republika' , search: 'dominikanska republika'},
  { code: 'DJ', name: 'Džibutsko' , search: 'dzibutsko'},
  { code: 'EG', name: 'Egypt' , search: 'egypt'},
  { code: 'EC', name: 'Ekvádor' , search: 'ekvador'},
  { code: 'ER', name: 'Eritrea' , search: 'eritrea'},
  { code: 'EE', name: 'Estónsko' , search: 'estonsko'},
  { code: 'ET', name: 'Etiópia' , search: 'etiopia'},
  { code: 'FO', name: 'Faerské ostrovy' , search: 'faerske ostrovy'},
  { code: 'FK', name: 'Falklandy' , search: 'falklandy'},
  { code: 'FJ', name: 'Fidži' , search: 'fidzi'},
  { code: 'PH', name: 'Filipíny' , search: 'filipiny'},
  { code: 'FI', name: 'Fínsko' , search: 'finsko'},
  { code: 'GF', name: 'Francúzska Guyana' , search: 'francuzska guyana'},
  { code: 'PF', name: 'Francúzska Polynézia' , search: 'francuzska polynezia'},
  { code: 'TF', name: 'Francúzske južné územie' , search: 'francuzske juzne uzemie'},
  { code: 'FR', name: 'Francúzsko' , search: 'francuzsko'},
  { code: 'GA', name: 'Gabon' , search: 'gabon'},
  { code: 'GM', name: 'Gambia' , search: 'gambia'},
  { code: 'GH', name: 'Ghana' , search: 'ghana'},
  { code: 'GI', name: 'Gibraltár' , search: 'gibraltar'},
  { code: 'GR', name: 'Grécko' , search: 'grecko'},
  { code: 'GD', name: 'Grenada' , search: 'grenada'},
  { code: 'GL', name: 'Grónsko' , search: 'gronsko'},
  { code: 'GE', name: 'Gruzínsko' , search: 'gruzinsko'},
  { code: 'GP', name: 'Guadeloupe' , search: 'guadeloupe'},
  { code: 'GU', name: 'Guam' , search: 'guam'},
  { code: 'GT', name: 'Guatemala' , search: 'guatemala'},
  { code: 'GG', name: 'Guernsey' , search: 'guernsey'},
  { code: 'GN', name: 'Guinea' , search: 'guinea'},
  { code: 'GW', name: 'Guinea-Bissau' , search: 'guinea-bissau'},
  { code: 'GY', name: 'Guyana' , search: 'guyana'},
  { code: 'HT', name: 'Haiti' , search: 'haiti'},
  { code: 'HM', name: 'Heardov ostrov' , search: 'heardov ostrov'},
  { code: 'NL', name: 'Holandsko' , search: 'holandsko'},
  { code: 'HN', name: 'Honduras' , search: 'honduras'},
  { code: 'HK', name: 'Honkong' , search: 'honkong'},
  { code: 'HR', name: 'Chorvátsko' , search: 'chorvatsko'},
  { code: 'IN', name: 'India' , search: 'india'},
  { code: 'ID', name: 'Indonézia' , search: 'indonezia'},
  { code: 'IQ', name: 'Irak' , search: 'irak'},
  { code: 'IR', name: 'Irán' , search: 'iran'},
  { code: 'IE', name: 'Írsko' , search: 'irsko'},
  { code: 'IS', name: 'Island' , search: 'island'},
  { code: 'IL', name: 'Izrael' , search: 'izrael'},
  { code: 'JM', name: 'Jamajka' , search: 'jamajka'},
  { code: 'JP', name: 'Japonsko' , search: 'japonsko'},
  { code: 'YE', name: 'Jemen ' , search: 'jemen '},
  { code: 'JE', name: 'Jersey' , search: 'jersey'},
  { code: 'JO', name: 'Jordánsko' , search: 'jordansko'},
  { code: 'ZA', name: 'Južná Afrika' , search: 'juzna afrika'},
  { code: 'GS', name: 'Južná Georgia a Južné Sandwichove ostrovy' , search: 'juzna georgia a juzne sandwichove ostrovy'},
  { code: 'SS', name: 'Južný Sudán' , search: 'juzny sudan'},
  { code: 'KY', name: 'Kajmanie ostrovy' , search: 'kajmanie ostrovy'},
  { code: 'KH', name: 'Kambodža' , search: 'kambodza'},
  { code: 'CM', name: 'Kamerun' , search: 'kamerun'},
  { code: 'CA', name: 'Kanada' , search: 'kanada'},
  { code: 'CV', name: 'Kapverdy' , search: 'kapverdy'},
  { code: 'QA', name: 'Katar' , search: 'katar'},
  { code: 'KZ', name: 'Kazachstan' , search: 'kazachstan'},
  { code: 'KE', name: 'Keňa' , search: 'kena'},
  { code: 'KG', name: 'Kirgizsko' , search: 'kirgizsko'},
  { code: 'KI', name: 'Kiribati' , search: 'kiribati'},
  { code: 'CC', name: 'Kokosové ostrovy' , search: 'kokosove ostrovy'},
  { code: 'CO', name: 'Kolumbia' , search: 'kolumbia'},
  { code: 'KM', name: 'Komory' , search: 'komory'},
  { code: 'CG', name: 'Kongo' , search: 'kongo'},
  { code: 'CD', name: 'Konžská demokratická republika' , search: 'konzska demokraticka republika'},
  { code: 'KP', name: 'Kórejská ľudovodemokratická republika' , search: 'korejska ľudovodemokraticka republika'},
  { code: 'KR', name: 'Kórejská republika' , search: 'korejska republika'},
  { code: 'CR', name: 'Kostarika' , search: 'kostarika'},
  { code: 'CU', name: 'Kuba' , search: 'kuba'},
  { code: 'KW', name: 'Kuvajt' , search: 'kuvajt'},
  { code: 'LA', name: 'Laos' , search: 'laos'},
  { code: 'LS', name: 'Lesotho' , search: 'lesotho'},
  { code: 'LB', name: 'Libanon' , search: 'libanon'},
  { code: 'LR', name: 'Libéria' , search: 'liberia'},
  { code: 'LY', name: 'Líbya' , search: 'libya'},
  { code: 'LI', name: 'Lichtenštajnsko' , search: 'lichtenstajnsko'},
  { code: 'LT', name: 'Litva' , search: 'litva'},
  { code: 'LV', name: 'Lotyšsko' , search: 'lotyssko'},
  { code: 'LU', name: 'Luxembursko' , search: 'luxembursko'},
  { code: 'MO', name: 'Macao' , search: 'macao'},
  { code: 'MK', name: 'Macedónsko' , search: 'macedonsko'},
  { code: 'MG', name: 'Madagaskar' , search: 'madagaskar'},
  { code: 'HU', name: 'Maďarsko' , search: 'madarsko'},
  { code: 'MY', name: 'Malajzia' , search: 'malajzia'},
  { code: 'MW', name: 'Malawi' , search: 'malawi'},
  { code: 'MV', name: 'Maldivy' , search: 'maldivy'},
  { code: 'ML', name: 'Mali' , search: 'mali'},
  { code: 'MT', name: 'Malta' , search: 'malta'},
  { code: 'IM', name: 'Man' , search: 'man'},
  { code: 'MA', name: 'Maroko' , search: 'maroko'},
  { code: 'MH', name: 'Marshallove ostrovy' , search: 'marshallove ostrovy'},
  { code: 'MQ', name: 'Martinik' , search: 'martinik'},
  { code: 'MU', name: 'Maurícius' , search: 'mauricius'},
  { code: 'MR', name: 'Mauritánia' , search: 'mauritania'},
  { code: 'YT', name: 'Mayotte' , search: 'mayotte'},
  { code: 'UM', name: 'Menšie odľahlé ostrovy USA' , search: 'mensie odľahle ostrovy usa'},
  { code: 'MX', name: 'Mexico' , search: 'mexico'},
  { code: 'FM', name: 'Mikronézia' , search: 'mikronezia'},
  { code: 'MM', name: 'Mjanmarsko' , search: 'mjanmarsko'},
  { code: 'MD', name: 'Moldavsko' , search: 'moldavsko'},
  { code: 'MC', name: 'Monako' , search: 'monako'},
  { code: 'MN', name: 'Mongolsko' , search: 'mongolsko'},
  { code: 'MS', name: 'Montserrat' , search: 'montserrat'},
  { code: 'MZ', name: 'Mozambik' , search: 'mozambik'},
  { code: 'NA', name: 'Namíbia' , search: 'namibia'},
  { code: 'NR', name: 'Nauru' , search: 'nauru'},
  { code: 'DE', name: 'Nemecko' , search: 'nemecko'},
  { code: 'NP', name: 'Nepál' , search: 'nepal'},
  { code: 'NE', name: 'Niger' , search: 'niger'},
  { code: 'NG', name: 'Nigéria' , search: 'nigeria'},
  { code: 'NI', name: 'Nikaragua' , search: 'nikaragua'},
  { code: 'NU', name: 'Niue' , search: 'niue'},
  { code: 'NF', name: 'Norfolk' , search: 'norfolk'},
  { code: 'NO', name: 'Nórsko' , search: 'norsko'},
  { code: 'NC', name: 'Nová Kaledónia' , search: 'nova kaledonia'},
  { code: 'NZ', name: 'Nový Zéland' , search: 'novy zeland'},
  { code: 'OM', name: 'Omán' , search: 'oman'},
  { code: 'PK', name: 'Pakistan' , search: 'pakistan'},
  { code: 'PW', name: 'Palau' , search: 'palau'},
  { code: 'PS', name: 'Palestína' , search: 'palestina'},
  { code: 'PA', name: 'Panama' , search: 'panama'},
  { code: 'PG', name: 'Papua-Nová Guinea' , search: 'papua-nova guinea'},
  { code: 'PY', name: 'Paraguaj' , search: 'paraguaj'},
  { code: 'PE', name: 'Peru' , search: 'peru'},
  { code: 'PN', name: 'Pitcairnove ostrovy' , search: 'pitcairnove ostrovy'},
  { code: 'CI', name: 'Pobrežie Slonoviny' , search: 'pobrezie slonoviny'},
  { code: 'PL', name: 'Poľsko' , search: 'polsko'},
  { code: 'PR', name: 'Portoriko' , search: 'portoriko'},
  { code: 'PT', name: 'Portugalsko' , search: 'portugalsko'},
  { code: 'AT', name: 'Rakúsko' , search: 'rakusko'},
  { code: 'RE', name: 'Réunion' , search: 'reunion'},
  { code: 'GQ', name: 'Rovníková Guinea' , search: 'rovnikova guinea'},
  { code: 'RO', name: 'Rumunsko' , search: 'rumunsko'},
  { code: 'RU', name: 'Rusko' , search: 'rusko'},
  { code: 'RW', name: 'Rwanda' , search: 'rwanda'},
  { code: 'PM', name: 'Saint Pierre a Miquelon' , search: 'saint pierre a miquelon'},
  { code: 'SV', name: 'Salvádor' , search: 'salvador'},
  { code: 'WS', name: 'Samoa' , search: 'samoa'},
  { code: 'SM', name: 'San Maríno' , search: 'san marino'},
  { code: 'SA', name: 'Saudská Arábia' , search: 'saudska arabia'},
  { code: 'SN', name: 'Senegal' , search: 'senegal'},
  { code: 'MP', name: 'Serverné Mariány' , search: 'serverne mariany'},
  { code: 'SC', name: 'Seychely' , search: 'seychely'},
  { code: 'SL', name: 'Sierra Leone' , search: 'sierra leone'},
  { code: 'SG', name: 'Singapur' , search: 'singapur'},
  { code: 'SI', name: 'Slovinsko' , search: 'slovinsko'},
  { code: 'SO', name: 'Somálsko' , search: 'somalsko'},
  { code: 'AE', name: 'Spojené arabské emiráty' , search: 'spojene arabske emiraty'},
  { code: 'GB', name: 'Spojené kráľovstvo' , search: 'spojene kraľovstvo'},
  { code: 'US', name: 'Spojené štáty, USA' , search: 'spojene staty, usa'},
  { code: 'RS', name: 'Srbsko' , search: 'srbsko'},
  { code: 'LK', name: 'Srí Lanka' , search: 'sri lanka'},
  { code: 'CF', name: 'Stredoafrická republika' , search: 'stredoafricka republika'},
  { code: 'SD', name: 'Sudán' , search: 'sudan'},
  { code: 'SR', name: 'Surinam' , search: 'surinam'},
  { code: 'SJ', name: 'Svalbard' , search: 'svalbard'},
  { code: 'SZ', name: 'Svazijsko' , search: 'svazijsko'},
  { code: 'SH', name: 'Svätá Helena' , search: 'svata helena'},
  { code: 'LC', name: 'Svätá Lucia' , search: 'svata lucia'},
  { code: 'BL', name: 'Svätý Bartolomej' , search: 'svaty bartolomej'},
  { code: 'KN', name: 'Svätý Krištov a Nevis' , search: 'svaty kristov a nevis'},
  { code: 'MF', name: 'Svätý Martin - francúzska časť' , search: 'svaty martin - francuzska cast'},
  { code: 'SX', name: 'Svätý Martin - holandská časť' , search: 'svaty martin - holandska cast'},
  { code: 'ST', name: 'Svätý Tomáš a Princov ostrov' , search: 'svaty tomas a princov ostrov'},
  { code: 'VC', name: 'Svätý Vincent a Grenadíny' , search: 'svaty vincent a grenadiny'},
  { code: 'SY', name: 'Sýria' , search: 'syria'},
  { code: 'SB', name: 'Šalamúnove ostrovy' , search: 'salamunove ostrovy'},
  { code: 'ES', name: 'Španielsko' , search: 'spanielsko'},
  { code: 'CH', name: 'Švajčiarsko' , search: 'svajciarsko'},
  { code: 'SE', name: 'Švédsko' , search: 'svedsko'},
  { code: 'TJ', name: 'Tadžikistan' , search: 'tadzikistan'},
  { code: 'TW', name: 'Taiwan' , search: 'taiwan'},
  { code: 'IT', name: 'Taliansko' , search: 'taliansko'},
  { code: 'TZ', name: 'Tanzánia' , search: 'tanzania'},
  { code: 'TH', name: 'Thajsko' , search: 'thajsko'},
  { code: 'TG', name: 'Togo' , search: 'togo'},
  { code: 'TK', name: 'Tokelau' , search: 'tokelau'},
  { code: 'TO', name: 'Tonga' , search: 'tonga'},
  { code: 'TT', name: 'Trinidad a Tobago' , search: 'trinidad a tobago'},
  { code: 'TN', name: 'Tunisko' , search: 'tunisko'},
  { code: 'TR', name: 'Turecko' , search: 'turecko'},
  { code: 'TM', name: 'Turkménsko' , search: 'turkmensko'},
  { code: 'TC', name: 'Turks a Caicos' , search: 'turks a caicos'},
  { code: 'TV', name: 'Tuvalu' , search: 'tuvalu'},
  { code: 'UG', name: 'Uganda' , search: 'uganda'},
  { code: 'UA', name: 'Ukrajina' , search: 'ukrajina'},
  { code: 'UY', name: 'Uruguaj' , search: 'uruguaj'},
  { code: 'UZ', name: 'Uzbekistan' , search: 'uzbekistan'},
  { code: 'VU', name: 'Vanuatu' , search: 'vanuatu'},
  { code: 'VA', name: 'Vatikán' , search: 'vatikan'},
  { code: 'VE', name: 'Venezuela' , search: 'venezuela'},
  { code: 'CX', name: 'Vianočný ostrov' , search: 'vianocny ostrov'},
  { code: 'VN', name: 'Vietnam' , search: 'vietnam'},
  { code: 'TL', name: 'Východný Timor' , search: 'vychodny timor'},
  { code: 'WF', name: 'Wallis a Futuna' , search: 'wallis a futuna'},
  { code: 'ZM', name: 'Zambia' , search: 'zambia'},
  { code: 'EH', name: 'Západná Sahara' , search: 'zapadna sahara'},
  { code: 'ZW', name: 'Zimbabwe' , search: 'zimbabwe'}

];

// TODO: remove when BE (if ;)) creates country list
export const ossCountries = [
  {code: 'sr', name: 'Slovensko', alias: ['slovenska republika', 'slovakia', 'slovak republic']},
  {code: 'cz', name: 'Česko', alias: ['ceska republika', 'czech', 'czech republic']},
  {code: 'be', name: 'Belgicko', alias: ['belgium', 'belgien']},
  {code: 'bg', name: 'Bulharsko', alias: ['bulgaria']},
  {code: 'hr', name: 'Chorvátsko', alias: ['hrvatska', 'croatia', 'kroatien']},
  {code: 'cy', name: 'Cyprus', alias: []},
  {code: 'dk', name: 'Dánsko', alias: ['Dänemark', 'denmark']},
  {code: 'ee', name: 'Estónsko', alias: ['estonia']},
  {code: 'fi', name: 'Fínsko', alias: ['finland']},
  {code: 'fr', name: 'Francúzsko', alias: ['france']},
  {code: 'gr', name: 'Grécko', alias: ['greece']},
  {code: 'nl', name: 'Holandsko', alias: ['netherlands', 'Holland', 'niederland', 'niederlande']},
  {code: 'ie', name: 'Írsko', alias: ['ireland', 'republic of ireland'], ignored: ['severne irsko']},
  {code: 'lt', name: 'Litva', alias: ['Lithuania']},
  {code: 'lv', name: 'Lotyšsko', alias: ['latvia']},
  {code: 'lu', name: 'Luxembursko', alias: ['luxembourg', 'luxemburg']},
  {code: 'hu', name: 'Maďarsko', alias: ['madarska republika', 'hungary', 'Magyaország']},
  {code: 'mt', name: 'Malta', alias: ['mt', 'mlt']},
  {code: 'de', name: 'Nemecko', alias: ['germany', 'Bundesrepublik Deutschland', 'Deutschland',
    'Nemecká spolková republika', 'Spolkova republika Nemecko']},
  {code: 'pl', name: 'Poľsko', alias: ['poland', 'Polska']},
  {code: 'pt', name: 'Portugalsko', alias: ['portugal']},
  {code: 'at', name: 'Rakúsko', alias: ['austria', 'Oesterreich', 'Osterreich']},
  {code: 'ro', name: 'Rumunsko', alias: ['romania'] },
  {code: 'si', name: 'Slovinsko', alias: ['slovenia']},
  {code: 'es', name: 'Španielsko', alias: ['espana', 'spain']},
  {code: 'se', name: 'Švédsko', alias: ['sweden']},
  {code: 'it', name: 'Taliansko', alias: ['italy']},
];

export class Country {
  code: string;
  name: string;
  search: string;
}
