import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { NoWhiteSpacesValidator, TimelineType } from '@kros-sk/ssw-shared-legacy';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { Note } from '../../../building-progress/models/note.model';
import { NoteSetModel } from '../../../building-progress/models/note.model';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotesComponent extends UnsubscribeComponent implements OnInit {
  timelineType = TimelineType;
  notesForm: UntypedFormGroup;
  isContentLoaded = false;

  @Input() readOnly: boolean;
  @Input() set note(note: Note) {
    this.setDataCore(note);
  }

  @Output() editing: EventEmitter<boolean> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<NoteSetModel> = new EventEmitter();
  @Output() noteClicked: EventEmitter<NoteSetModel> = new EventEmitter();

  get isReadOnly(): boolean {
    return this.readOnly || this.isReadOnlyItem;
  }

  private originalData: Note;
  private isReadOnlyItem = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    if (!this.notesForm) {
      this.notesForm = this.formBuilder.group(
        { note: ['', [Validators.required, NoWhiteSpacesValidator]] }
      );

      this.subs.sink = this.notesForm.valueChanges.subscribe(() => {
        this.editing.emit(this.originalData?.note !== this.notesForm.controls['note'].value);
      });
    }
  }

  onClose(): void {
    this.editing.emit(false);
    this.close.emit();
  }

  cancelChanges(): void {
    this.editing.emit(false);
    this.setDataCore(this.originalData);
  }

  onSubmit(): void {
    this.editing.emit(false);
    this.save.emit(this.getDataFromForm());
  }

  onClickNote(): void {
    this.noteClicked.emit();
  }

  private setDataCore(data: Note): void {
    this.originalData = { ...data };
    this.initializeForm();
    this.notesForm.patchValue({ note: data ? data.note : '' });
    this.isReadOnlyItem = !data;
    this.setValidators(!data?.note);
    this.isContentLoaded = true;
    this.cdr.detectChanges();
    this.notesForm.markAsUntouched();
    this.notesForm.markAsPristine();
    this.editing.emit(false);
  }

  private setValidators(isNew: boolean): void {
    this.notesForm.controls['note'].setValidators(isNew ? [Validators.required, NoWhiteSpacesValidator] : []);
  }

  private getDataFromForm(): NoteSetModel {
    return {
      budgetItemId: this.originalData?.budgetItemId,
      applicationType: this.originalData?.applicationType,
      note: this.notesForm.controls['note'].value === '' ? null : this.notesForm.controls['note'].value
    };
  }
}
