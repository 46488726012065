import { License } from '../../license';

export interface LicenseState {
  mishaLicense: License;
  buildingProgressLicense: License;
  budgetLicense: License;
  quantityManagerLicense: License;
  desktopLicense: License;
  returnToBuildingProgress: boolean;
  returnToBudgetApproval: boolean;
  isAutomaticRenewalLicense: boolean;
  licenseNumber: string;
  existValid: boolean;
}

export const initialLicenseState: LicenseState = {
  mishaLicense: undefined,
  buildingProgressLicense: undefined,
  budgetLicense: undefined,
  quantityManagerLicense: undefined,
  desktopLicense: undefined,
  returnToBuildingProgress: false,
  returnToBudgetApproval: false,
  isAutomaticRenewalLicense: false,
  licenseNumber: undefined,
  existValid: undefined
};
