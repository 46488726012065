<ng-container *ngIf="!creatingPeriod else createPeriodForm">
  <div class="kros-modal-header">
    <h5 class="modal-title">
      {{ 'SUBCONTRACTORS.TRANSFER_EXECUTIONS_WHOLE_BUILDING' | translate }}
    </h5>

    <button
      class="btn btn-link close"
      type="button"
      tabindex="-1"
      data-test="modal-close-button"
      (click)="onCloseClick()"
    >
      <i class="material-icons secondary">close</i>
    </button>
  </div>

  <form
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
  >
    <div
      class="kros-modal-body"
      data-test="subcontractor-select-modal-body"
    >
      <div class="form-group">
        <div class="form-group one-row">
          <label>
            <input
              id="subcontractorOne"
              type="radio"
              formControlName="all"
              data-test="subcontractor-one-radio"
              [attr.disabled]="(hasPartialBudgetItems || (periods.length === 0)) ? true : null"
              [value]="false"
            >

            <span data-test="subcontractor-one-text">{{ 'SUBCONTRACTORS.FOR_SUBCONTRACTOR' | translate }}</span>

            <span id="subcontractorName">&nbsp;{{ contractorName }}</span>

            <i *ngIf="hasPartialBudgetItems"
              class="material-icons has-partial-budget-items-tooltip-icon"
              [krosTooltip]="'SUBCONTRACTORS.TRANSFER_IMPOSSIBLE_PARTIAL_ITEMS_EXIST' | translate"
              tooltipClass="has-partial-budget-items-tooltip"
            >info</i>
          </label>
        </div>

        <div class="form-group one-row">
          <label>
            <input
              id="subcontractorAll"
              type="radio"
              formControlName="all"
              data-test="subcontractor-all-radio"
              [value]="true"
            >

            <span data-test="subcontractor-all-text">{{ 'SUBCONTRACTORS.FOR_ALL_SUBCONTRACTOR' | translate }}</span>
          </label>
        </div>

        <ng-container *ngIf="hasPeriods else noPeriods">
          <label
            for="period"
            class="caption mt-25"
          >{{'BUILDING_PROGRESS.PERIODS.FROM_PERIOD' | translate}}:</label>

          <select
            id="period"
            class="form-control"
            formControlName="period"
            data-test="subcontractor-transfer-executions-period"
            [compareWith]="comparePeriods"
          >
            <option
              *ngFor="let period of periodsByForm"
              [ngValue]="period"
            >{{ period.dateFrom | periodDate : period.dateTo }}</option>
          </select>
        </ng-container>

        <div class="target-period mt-25">
          <div class="select-box">
            <label
              for="targetPeriod"
              class="caption"
            >{{'BUILDING_PROGRESS.PERIODS.TO_PERIOD' | translate}}:</label>

            <select
              id="targetPeriod"
              class="form-control"
              formControlName="targetPeriod"
              data-test="subcontractor-transfer-executions-target-period"
              [compareWith]="comparePeriods"
            >
              <option
                *ngIf="periodToCreate"
                [ngValue]="null"
              >
                {{ periodToCreate.dateFrom | periodDate : periodToCreate.dateTo }}
              </option>
              <option
                *ngFor="let period of wholeBuildingPeriods"
                [ngValue]="period"
              >{{ period.dateFrom | periodDate : period.dateTo }}</option>
            </select>
          </div>

          <button
            (click)="openCreatingPeriod()"
            type="button"
            data-test="create-new-period"
            class="btn btn-link"
          >
            <i class="material-icons icon-button">add</i>

            <span class="toolbar-action-text">{{'BUILDING_PROGRESS.PERIODS.NEW_PERIOD' | translate}}</span>
          </button>
        </div>
      </div>

      <div class="form-group details-checkbox checkbox">
        <input
          id="areDetailsIncluded"
          class="form-control"
          type="checkbox"
          formControlName="areDetailsIncluded"
          data-test="are-details-included-checkbox"
        >
        <label for="areDetailsIncluded">{{ "SUBCONTRACTORS.TRANSFER_DETAILS" | translate }}</label>
      </div>
  </div>

    <div class="kros-modal-footer">
      <button
        class="btn btn-primary ga-pv-subcontractor-transfer-executions"
        type="submit"
        data-test="subcontractor-transfer-executions-button"
        [disabled]="form.invalid"
      >{{ 'SUBCONTRACTORS.TRANSFER_EXECUTIONS' | translate }}</button>
    </div>
  </form>
</ng-container>

<ng-template #createPeriodForm>
  <div class="kros-modal-header">
    <h5 class="modal-title">
      {{ 'BUILDING_PROGRESS.PERIODS.NEW_PERIOD' | translate }}
    </h5>
  </div>

  <app-period-date-picker
    [selectedPeriod]="selectedPeriod"
    [lastPeriod]="lastPeriod"
    (close)="onCloseCreatingPeriod()"
    (save)="onCreatePeriod($event)"
  ></app-period-date-picker>
</ng-template>

<ng-template #noPeriods>
  <p class="no-periods">{{ 'BUILDING_PROGRESS.PERIODS.NO_PERIODS' | translate }}</p>
</ng-template>
