<div class="kros-modal-header">
  <h5 class="modal-title">
    {{ 'BUILDING_PROGRESS.PERIODS.DELETE_QUESTION' | translate }}
  </h5>
</div>

<form
  [formGroup]="periodDeleteForm"
  (ngSubmit)="onSubmit()"
>
  <div class="kros-modal-body">
    <div
      *ngIf="data.period.hasDocuments"
      class="form-container"
    >
      <div class="form-group checkbox">
        <input
          id="deletePeriodGallery"
          type="checkbox"
          formControlName="deletePeriodGallery"
        >
        <label for="deletePeriodGallery">
          {{'BUILDING_PROGRESS.PERIODS.DELETE_PERIOD_GALLERY' | translate }}
        </label>
      </div>
    </div>

    <div
      *ngIf="data.period.hasInvoices"
      class="invoice-warning"
      [innerHtml]="periodWithInvoiceWarning"
    ></div>
  </div>

  <div
    class="kros-modal-footer"
    [class.questionless-footer]="!data.period.hasDocuments && !data.period.hasInvoices"
  >
    <button
      class="btn btn-primary"
      type="submit"
      data-test="confirm-delete-period-button"
    >{{ 'COMMON.DELETE' | translate }}</button>

    <button
      class="btn btn-outline-secondary"
      type="button"
      data-test="cancel-delete-period-button"
      (click)="onCloseClick()"
    >{{ 'COMMON.KEEP' | translate }}</button>
  </div>
</form>
