<div class="actions left"></div>

<div class="toolbar-title tablet-visible">
  <h3>{{'PROJECTS.PROJECTS' | translate}}</h3>
</div>

<div class="tablet-hidden">
  <kros-tags-filter
    [label]="activeFilterTagIds.length > 0 ? 'FILTER.PROJECTS' : 'FILTER.FILTER_PROJECTS'"
    [tags]="tagsList"
    [activeTagIds]="activeFilterTagIds"
    [maxTagsLimit]="3"
    (tagFilterChanged)="onTagFilterChanged($event)"
  ></kros-tags-filter>
</div>

<div class="tablet-hidden">
  <div class="actions right">
    <button
      class="btn toolbar-action no-selection blue-toolbar-action"
      type="button"
      krosAnalytic
      eventCategory="projekt"
      eventAction="otvorit-projekt"
      eventLabel="{{selectedProject?.name}}"
      data-test="project-open-button"
      krosTooltip="{{ 'PROJECTS.ZOZNAM.OTVORIT' | translate }}"
      (click)="openProject()"
      [class.disabled-toolbar-action]="!selectedProject"
    >
      <i class="material-icons icon-button">open_in_new</i>
      <span class="toolbar-action-text">{{ 'PROJECTS.ZOZNAM.OTVORIT' | translate }}</span>
    </button>

    <button
      *ngIf="isOwnerMe"
      class="btn toolbar-action no-selection gray-toolbar-action"
      type="button"
      krosTooltip="{{ 'PROJECTS.ZOZNAM.SHARE' | translate }}"
      (click)="shareProject()"
    >
      <i class="material-icons icon-button">person_add</i>
      <span class="toolbar-action-text">{{ 'PROJECTS.ZOZNAM.SHARE' | translate }}</span>
    </button>

    <button
      *ngIf="isProjectSelected"
      class="btn toolbar-action no-selection gray-toolbar-action"
      type="button"
      krosTooltip="{{ 'PROJECTS.ZOZNAM.TAGS' | translate }}"
      data-test="tags-button"
      (click)="openProjectTags(showTagsBtnTemplate)"
      #showTagsBtnTemplate
    >
      <i class="material-icons">label</i>
      <span class="toolbar-action-text">{{ 'PROJECTS.ZOZNAM.TAGS' | translate }}</span>
    </button>

    <button
      class="btn toolbar-action no-selection gray-toolbar-action project-info"
      type="button"
      data-test="project-info-button"
      krosTooltip="{{ 'PROJECTS.ZOZNAM.PROJECT_INFO' | translate }}"
      (click)="openProjectDetail()"
      krosAnalytic
      eventCategory="projekt"
      eventAction="detail-projektu"
      eventLabel="{{selectedProject?.name}}"
      [class.disabled-toolbar-action]="!selectedProject"
    >
      <i class="material-icons icon-button">info</i>
      <span class="toolbar-action-text">{{ 'PROJECTS.ZOZNAM.DETAIL' | translate }}</span>
    </button>

    <button
      *ngIf="isProjectSelected && isDemoAdminUser && isOldestDemoProjectSelected"
      class="btn toolbar-action no-selection gray-toolbar-action"
      type="button"
      data-test="publish-demo-project-button"
      (click)="onPublishDemoProject()"
    >
      <i class="material-icons icon-button">published_with_changes</i>

      <span class="toolbar-action-text">{{ 'PROJECTS.ZOZNAM.PUBLISH_DEMO_PROJECT' | translate }}</span>
    </button>

    <button
      *ngIf="selectedProject"
      class="btn toolbar-action no-selection gray-toolbar-action dropdown-action"
    >
      <div
        ngbDropdown
        container="body"
      >
        <button
          ngbDropdownToggle
          class="btn dropdown-toggle"
          type="button"
          data-test="project-more-dropdown"
        >
          <div
            class="dropdown-toggle-content"
            krosTooltip="{{ 'COMMON.MORE' | translate }}"
          >
            <i class="material-icons icon-button">more_vert</i>
            <span class="toolbar-action-text">{{ 'COMMON.MORE' | translate }}</span>
          </div>
        </button>

        <div
          ngbDropdownMenu
          aria-labelledby="moreDropdown"
          display="dynamic"
        >
          <button
            *ngIf="selectedProject"
            class="btn toolbar-action no-selection gray-toolbar-action"
            ngbDropdownItem
            type="button"
            data-test="project-copy-button"
            krosTooltip="{{ 'PROJECTS.ZOZNAM.COPY_PROJECT' | translate }}"
            position="before"
            (click)="copyProject()"
          >
            <i class="material-icons icon-button">file_copy</i>
            <span class="toolbar-action-text">{{ 'PROJECTS.ZOZNAM.COPY_PROJECT' | translate }}</span>
          </button>

          <button
            *ngIf="isOwnerMe"
            class="btn toolbar-action no-selection gray-toolbar-action"
            ngbDropdownItem
            type="button"
            data-test="project-delete-button"
            krosTooltip="{{ 'PROJECTS.ZOZNAM.DELETE' | translate }}"
            position="before"
            (click)="deleteProject()"
            [class.disabled-toolbar-action]="!selectedProject"
          >
            <i class="material-icons icon-button">delete</i>
            <span class="toolbar-action-text">{{ 'PROJECTS.ZOZNAM.DELETE' | translate }}</span>
          </button>

          <button
            *ngIf="!isOwnerMe && selectedProject"
            class="btn toolbar-action no-selection gray-toolbar-action"
            ngbDropdownItem
            type="button"
            data-test="project-remove-button"
            krosTooltip="{{ 'PROJECTS.ZOZNAM.REMOVE' | translate }}"
            position="before"
            (click)="removeProjectSharing()"
          >
            <i class="material-icons icon-button">delete</i>
            <span class="toolbar-action-text">{{ 'PROJECTS.ZOZNAM.REMOVE' | translate }}</span>
          </button>
        </div>
      </div>
    </button>
  </div>
</div>

<div class="actions right tablet-visible">
  <button
    class="btn link  tablet-visible"
    [class.active-filter]="isActiveFilterTags"
    type="button"
    data-test="mobile-filter-projects-button"
    (click)="onTagFilterButtonClick()"
  >
    <span>{{ 'FILTER.FILTER_PROJECTS' | translate }}</span>
    <i class="material-icons">filter_list</i>
  </button>
</div>
