import { Action, ActionCreator, createReducer, on, ReducerTypes } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { areEmailsEqual } from '@kros-sk/ssw-cdk';
import { PermissionType } from '@kros-sk/ssw-shared/permission';

import * as actions from './documents-explorer.actions';
import { adapterDocumentList, adapterMarkedDocuments } from './documents-explorer.adapters';
import { AvatarUser } from '../../avatars';
import { DocumentListInfoModel } from '../models';
import { DocumentsState, initialDocumentsState } from './documents-explorer.state';
import { ItemSharing } from '../../models';

export const documentsExplorerReducers: ReducerTypes<DocumentsState, ActionCreator[]>[] = [
  on(actions.loadDocumentsListSuccess, (state, payload) => {
    return {
      ...state,
      documentList: adapterDocumentList.setAll(payload.documentsList, state.documentList),
      isDocumentsListLoaded: true
    };
  }),
  on(actions.loadRootDocumentPermissionSuccess, (state, payload) => {
    return {
      ...state,
      rootDocumentPermission: payload.permission
    };
  }),
  on(actions.loadFolderListSuccess, (state, payload) => {
    return {
      ...state,
      documentList: adapterDocumentList.setAll(payload.documentsList, state.documentList),
      isDocumentsListLoaded: true
    };
  }),
  on(actions.loadEmptyList, state => {
    return {
      ...state,
      documentList: adapterDocumentList.removeAll(state.documentList)
    };
  }),
  on(actions.markDocument, (state, payload) => {
    return {
      ...state,
      markedDocuments: adapterMarkedDocuments.addOne(payload.documentId, state.markedDocuments)
    };
  }),
  on(actions.markDocuments, (state, payload) => {
    return {
      ...state,
      markedDocuments: adapterMarkedDocuments.addMany(payload.documents, state.markedDocuments)
    };
  }),
  on(actions.clearMarkedDocuments, (state, payload) => {
    return {
      ...state,
      markedDocuments: adapterMarkedDocuments.removeMany(payload.documents, state.markedDocuments)
    };
  }),
  on(actions.clearAllMarkedDocuments, state => {
    return {
      ...state,
      markedDocuments: adapterMarkedDocuments.removeAll(state.markedDocuments)
    };
  }),
  on(actions.updateSharings, (state, payload) => {
    const update = payload.documents.map(p => {
      return {
        id: p.id,
        changes: {
          isShared: payload.isShared,
          sharings: getNewAvatarUsers(p.sharings, payload.sharings)
        }
      } as Update<DocumentListInfoModel>;
    });

    return {
      ...state,
      documentList: adapterDocumentList.updateMany(update, state.documentList)
    };
  }),
  on(actions.setCurrentFolder, (state, payload): DocumentsState => {
    return { ...state, currentFolder: payload.currentFolder };
  }),
  on(actions.setProjectId, (state, payload): DocumentsState => {
    if (state.projectId !== payload.projectId) {
      return { ...state, projectId: payload.projectId, currentFolder: null };
    } else {
      return { ...state };
    }
  }),
  on(actions.clearCurrentFolder, (state): DocumentsState => {
    return ({ ...state, currentFolder: null });
  })
];

const getNewAvatarUsers = (oldSharings: AvatarUser[], newSharings: ItemSharing[]): AvatarUser[] => {
  return newSharings
    .filter(s => {
      if (s.permissionType === PermissionType.Unidentified) {
        return !!oldSharings.find(oldSharing => areEmailsEqual(oldSharing.email, s.email));
      } else {
        return s.permissionType !== PermissionType.Owner;
      }
    })
    .map(s => ({ email: s.email, permissionType: s.permissionType, isRegistered: s.isRegisteredUser }));
};

export function documentsExplorerReducer(state: DocumentsState | undefined, action: Action): any {
  const reducer = createReducer(initialDocumentsState, ...documentsExplorerReducers);
  return reducer(state, action);
}
