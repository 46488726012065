import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter } from 'rxjs/operators';

import {
  BudgetPermission,
  BudgetPermissionType,
  BuildingProgressPermission,
  BuildingProgressPermissionType,
  PermissionType
} from '../models';
import { PermissionSelectorsService } from '../store/permission-selectors.service';


@Injectable()
export class PermissionService {

  private destroyRef = inject(DestroyRef);
  private permissionSelectorsService = inject(PermissionSelectorsService);

  private buildingProgressPermission: BuildingProgressPermission;
  private budgetPermission: BudgetPermission;

  constructor() {
    this.permissionSelectorsService.buildingProgressPermission$.pipe(filter(p => !!p), takeUntilDestroyed(this.destroyRef))
      .subscribe(buildingProgressPermission => this.buildingProgressPermission = buildingProgressPermission);
    this.permissionSelectorsService.budgetPermission$.pipe(filter(p => !!p), takeUntilDestroyed(this.destroyRef))
      .subscribe(budgetPermission => this.budgetPermission = budgetPermission);
  }

  isBudgetNoAccess(): boolean {
    return this.budgetPermission?.permissionType === BudgetPermissionType.NoAccess;
  }

  isBudgetReader(): boolean {
    return this.budgetPermission?.permissionType === BudgetPermissionType.Reader;
  }

  isBuildingProgressNoAccess(): boolean {
    return this.buildingProgressPermission?.permissionType === PermissionType.NoAccess;
  }

  isBuildingProgressOwner(): boolean {
    return this.buildingProgressPermission?.permissionType === PermissionType.Owner;
  }

  isBuildingProgressContributor(): boolean {
    return this.buildingProgressPermission?.permissionType === PermissionType.Contributor;
  }

  isBuildingProgressReader(): boolean {
    return this.buildingProgressPermission?.permissionType === PermissionType.Reader;
  }

  isBuildingProgressReaderOnly(): boolean {
    return this.buildingProgressPermission?.permissionType === PermissionType.Reader
      && this.buildingProgressPermission?.buildingProgressPermissionType === BuildingProgressPermissionType.None;
  }

  isBuildingProgressInvestor(): boolean {
    return this.buildingProgressPermission?.permissionType === PermissionType.Reader
      && this.buildingProgressPermission?.buildingProgressPermissionType === BuildingProgressPermissionType.CommenterExporterApproverFull;
  }

  isBuildingProgressApprover(): boolean {
    return this.buildingProgressPermission?.permissionType === PermissionType.Reader
      && this.buildingProgressPermission?.buildingProgressPermissionType === BuildingProgressPermissionType.CommenterExporterApproverView;
  }

  isBuildingProgressContractorFull(): boolean {
    return this.buildingProgressPermission?.buildingProgressPermissionType === BuildingProgressPermissionType.ContractorFull;
  }

  isBuildingProgressContractor(): boolean {
    return this.buildingProgressPermission?.permissionType === PermissionType.Shared
      && this.buildingProgressPermission?.buildingProgressPermissionType === BuildingProgressPermissionType.Contractor;
  }

  isBuildingProgressAnyContractor(): boolean {
    return this.buildingProgressPermission?.permissionType === PermissionType.Shared
      && (this.buildingProgressPermission?.buildingProgressPermissionType === BuildingProgressPermissionType.Contractor
        || this.buildingProgressPermission?.buildingProgressPermissionType === BuildingProgressPermissionType.ContractorFull);
  }
}
