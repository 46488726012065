import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { View } from '@kros-sk/ssw-shared-legacy';

import { UserFeatureFlags } from '@kros-sk/models';

import { BuildingProgressDispatchersService } from '../../store/building-progress';

export enum ViewType {
  standard = 0,
  drawn = 1,
  undrawn = 2,
  completed = 3,
  overdrawn = 4,
  nonZero = 5,
  colorCodes = 6,
  selectedItems = 7,
  main = 8,
  drawnSelected = 9,
  undrawnSelected = 10,
  buildingObjectsSummary = 11,
  sectionsSummary = 12,
  nonZeroRest = 13,
  notCompletedItems = 14,
  withComment = 15
}

export enum ViewGroup {
  allPeriods = 0,
  selectedPeriods = 1,
  recaps = 2
}

export const DEFAULT_COLOR_CODES_FILTER = 'search.in(ColorCode, \'##codes##\', \'|\')';
export const DEFAULT_MAIN_ITEMS_FILTER = '##MainItemsFilter##';
export const DEFAULT_DRAWN_SELECTED_FILTER = 'DrawnPeriodIds/any(p: ##selectedPeriods##)';
export const DEFAULT_UNDRAWN_SELECTED_FILTER = 'DrawnPeriodIds/all(p: ##selectedPeriods##)';
export const ZERO_PERIOD_SELECTED_FILTER = 'DrawnPeriodIds/any(p: p eq -1)';
export const DEFAULT_COMMENTED_SELECTED_FILTER = 'CommentedPeriodIds/any(p: ##selectedPeriods##)';
export const ZERO_COMMENTED_SELECTED_FILTER = 'CommentedPeriodIds/any(p: p eq -1)';

export const VIEWS: View[] = [
  {
    panelDescription: 'FILTERS.STANDARD_PANEL',
    menuDescription: 'FILTERS.STANDARD_MENU',
    viewType: ViewType.standard,
    filter: '',
    group: ViewGroup.allPeriods
  },
  {
    panelDescription: 'BUILDING_PROGRESS.VIEWS.DRAWN_PANEL',
    menuDescription: 'BUILDING_PROGRESS.VIEWS.DRAWN_MENU',
    viewType: ViewType.drawn,
    filter: 'CompletedPercentage gt 0 and CompletedPercentage lt 100',
    group: ViewGroup.allPeriods
  },
  {
    panelDescription: 'BUILDING_PROGRESS.VIEWS.UNDRAWN_PANEL',
    menuDescription: 'BUILDING_PROGRESS.VIEWS.UNDRAWN_MENU',
    viewType: ViewType.undrawn,
    filter: 'CompletedPercentage eq 0',
    group: ViewGroup.allPeriods
  },
  {
    panelDescription: 'BUILDING_PROGRESS.VIEWS.NON_ZERO_REST_PANEL',
    menuDescription: 'BUILDING_PROGRESS.VIEWS.NON_ZERO_REST_MENU',
    viewType: ViewType.nonZeroRest,
    filter: 'RestPercentage gt 0',
    group: ViewGroup.allPeriods
  },
  {
    panelDescription: 'BUILDING_PROGRESS.VIEWS.COMPLETED_PANEL',
    menuDescription: 'BUILDING_PROGRESS.VIEWS.COMPLETED_MENU',
    viewType: ViewType.completed,
    filter: 'CompletedPercentage eq 100',
    group: ViewGroup.allPeriods
  },
  {
    panelDescription: 'BUILDING_PROGRESS.VIEWS.OVERDRAWN_PANEL',
    menuDescription: 'BUILDING_PROGRESS.VIEWS.OVERDRAWN_MENU',
    viewType: ViewType.overdrawn,
    filter: 'CompletedPercentage gt 100',
    group: ViewGroup.allPeriods
  },
  {
    panelDescription: 'BUILDING_PROGRESS.VIEWS.NON_ZERO_PANEL',
    menuDescription: 'BUILDING_PROGRESS.VIEWS.NON_ZERO_MENU',
    viewType: ViewType.nonZero,
    filter: 'CompletedPercentage gt 0',
    group: ViewGroup.allPeriods
  },
  {
    panelDescription: 'BUILDING_PROGRESS.VIEWS.NOT_COMPLETED_PANEL',
    menuDescription: 'BUILDING_PROGRESS.VIEWS.NOT_COMPLETED_MENU',
    viewType: ViewType.notCompletedItems,
    filter: 'IsNotCompleted eq true',
    group: ViewGroup.allPeriods
  },
  {
    panelDescription: 'FILTERS.COLOR_CODES_PANEL',
    menuDescription: 'FILTERS.COLOR_CODES_MENU',
    viewType: ViewType.colorCodes,
    filter: DEFAULT_COLOR_CODES_FILTER,
    group: ViewGroup.allPeriods
  },
  {
    panelDescription: 'FILTERS.SELECTED_PANEL',
    menuDescription: 'FILTERS.SELECTED_MENU',
    viewType: ViewType.selectedItems,
    filter: '',
    group: ViewGroup.allPeriods
  },
  {
    panelDescription: 'BUILDING_PROGRESS.VIEWS.MAIN_ITEMS_PANEL',
    menuDescription: 'BUILDING_PROGRESS.VIEWS.MAIN_ITEMS_MENU',
    viewType: ViewType.main,
    filter: DEFAULT_MAIN_ITEMS_FILTER,
    group: ViewGroup.allPeriods
  },
  {
    panelDescription: 'BUILDING_PROGRESS.VIEWS.DRAWN_SELECTED_PANEL',
    menuDescription: 'BUILDING_PROGRESS.VIEWS.DRAWN_SELECTED_MENU',
    viewType: ViewType.drawnSelected,
    filter: '',
    group: ViewGroup.selectedPeriods
  },
  {
    panelDescription: 'BUILDING_PROGRESS.VIEWS.UNDRAWN_SELECTED_PANEL',
    menuDescription: 'BUILDING_PROGRESS.VIEWS.UNDRAWN_SELECTED_MENU',
    viewType: ViewType.undrawnSelected,
    filter: '',
    group: ViewGroup.selectedPeriods
  },
  {
    panelDescription: 'BUILDING_PROGRESS.VIEWS.WITH_COMMENT_PANEL',
    menuDescription: 'BUILDING_PROGRESS.VIEWS.WITH_COMMENT_MENU',
    viewType: ViewType.withComment,
    filter: DEFAULT_COMMENTED_SELECTED_FILTER,
    group: ViewGroup.selectedPeriods
  },
  {
    panelDescription: 'BUILDING_PROGRESS.VIEWS.BUILDING_OBJECTS_SUMMARY_PANEL',
    menuDescription: 'BUILDING_PROGRESS.VIEWS.BUILDING_OBJECTS_SUMMARY_MENU',
    viewType: ViewType.buildingObjectsSummary,
    filter: 'search.in(ItemType, \'S|O\', \'|\')',
    group: ViewGroup.recaps
  },
  {
    panelDescription: 'BUILDING_PROGRESS.VIEWS.SECTIONS_SUMMARY_PANEL',
    menuDescription: 'BUILDING_PROGRESS.VIEWS.SECTIONS_SUMMARY_MENU',
    viewType: ViewType.sectionsSummary,
    filter: 'search.in(ItemType, \'S|O|D\', \'|\')',
    group: ViewGroup.recaps
  }
];

@Injectable()
export class BuildingProgressViewsService {
  currentView: View;

  private readonly views: View[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private buildingProgressDispatchersService: BuildingProgressDispatchersService
  ) {
    this.views = VIEWS;
    this.currentView = this.getDefaultView();
  }

  getViews(featureFlags?: Partial<UserFeatureFlags>): View[] {
    if (featureFlags) {
      return this.views.filter(view => !view.featureFlag || featureFlags[view.featureFlag]);
    }
    return this.views;
  }

  private getDefaultView(): View {
    return this.views[0];
  }

  setColorCodesFilter(colorCodes: string[]): void {
    const view = this.views.find(v => v.viewType === ViewType.colorCodes);
    view.filter = DEFAULT_COLOR_CODES_FILTER.replace('##codes##', colorCodes.join('|'));
  }

  setDrawnSelectedPeriodsFilter(selectedPeriods: number[]): void {
    const filter = 'p eq ' + selectedPeriods.join(' or p eq ');
    const view = this.views.find(v => v.viewType === ViewType.drawnSelected);
    view.filter = (selectedPeriods && selectedPeriods.length > 0)
      ? DEFAULT_DRAWN_SELECTED_FILTER.replace('##selectedPeriods##', filter)
      : ZERO_PERIOD_SELECTED_FILTER;
  }

  setUndrawnSelectedPeriodsFilter(selectedPeriods: number[]): void {
    const filter = 'p ne ' + selectedPeriods.join(' and p ne ');
    const view = this.views.find(v => v.viewType === ViewType.undrawnSelected);
    view.filter = (selectedPeriods && selectedPeriods.length > 0)
      ? DEFAULT_UNDRAWN_SELECTED_FILTER.replace('##selectedPeriods##', filter)
      : ZERO_PERIOD_SELECTED_FILTER;
  }

  setWithCommentFilter(selectedPeriods: number[]): void {
    const filter = 'p eq ' + selectedPeriods.join(' or p eq ');
    const view = this.views.find(v => v.viewType === ViewType.withComment);
    view.filter = (selectedPeriods && selectedPeriods.length > 0)
      ? DEFAULT_COMMENTED_SELECTED_FILTER.replace('##selectedPeriods##', filter)
      : ZERO_COMMENTED_SELECTED_FILTER;
  }

  getViewFilter(view: ViewType): string {
    return this.views.find(v => view === v.viewType).filter;
  }

  setDefaultView(): void {
    this.currentView = this.getDefaultView();
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        search: null
      },
      queryParamsHandling: 'merge'
    });
    this.changeView(this.currentView.viewType);
  }

  changeView(viewType: ViewType, data?: any): void {
    this.buildingProgressDispatchersService.changeSelectedView({ viewType, data });
  }
}
