import { animate, AnimationTransitionMetadata, state, style, transition, trigger } from '@angular/animations';

export function createRotatedStateAnimation(expandedDegree: number): Partial<AnimationTransitionMetadata> {
  return trigger('rotatedState', [
    state('collapsed', style({ transform: 'rotate(0)' })),
    state('expanded', style({ transform: `rotate(${expandedDegree}deg)` })),
    transition('collapsed => expanded', animate('200ms ease-out')),
    transition('expanded => collapsed', animate('200ms ease-out'))
  ]);
}
