import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';

import { APP_CONFIG } from '@kros-sk/app-config';
import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import {
  AppInsightsUserContextHelper,
  ButtonConfig,
  isOwnersEmail,
  LicenseDispatchersService,
  LicenseHelper,
  LicenseInfo,
  LicenseUrlService,
  MarketingContentHelper,
  MarketingModalComponent,
  NonPaidPackageConfig,
  PluralisationByCountPipe,
  ProjectDetail,
  QuestionModalComponent,
  TranslateService
} from '@kros-sk/ssw-shared-legacy';
import { AuthSelectorsService, KrosUser } from '@kros-sk/auth';
import { BudgetSharedSelectorsService } from '@kros-sk/ssw-budget/shared/data-access/store';
import {
  formatString,
  License,
  LicenseDispatchersService as LicenseCdkDispatchersService,
  LicenseSelectorsService,
  LicenseType,
  UISettingsService
} from '@kros-sk/ssw-cdk';
import { KrosModalRef, SmsActivationEndpoints, SmsActivationModalComponent } from '@kros-sk/components';

const RemindBudgetLicenseEndingLastDate = 'RemindBudgetLicenseEndingLastDate';

@Injectable()
export class BudgetLicenseHelper extends LicenseHelper {

  private uiSettingsService = inject(UISettingsService);
  private destroyRef = inject(DestroyRef);
  private licenseCdkDispatchersService = inject(LicenseCdkDispatchersService);
  private licenseDispatchersService = inject(LicenseDispatchersService);
  private sharedSelectors = inject(BudgetSharedSelectorsService);
  private translateService = inject(TranslateService);
  private pluralisationByCountPipe = inject(PluralisationByCountPipe);
  private appInsightsService = inject(AppInsightsBaseService);
  private appInsightsUserContext = inject(AppInsightsUserContextHelper);
  private licenseUrlService = inject(LicenseUrlService);
  private marketingContentHelper = inject(MarketingContentHelper, { optional: true });

  get isReadonlyLicense(): boolean {
    return this.license?.licenseType === LicenseType.Readonly;
  }

  get isFullLicense(): boolean {
    return this.license?.licenseType === LicenseType.Full;
  }

  get isTrialLicense(): boolean {
    return this.license?.licenseType === LicenseType.Trial;
  }

  get isFreeLicense(): boolean {
    return this.license?.licenseType === LicenseType.Free;
  }

  get isTrialOrFullLicense(): boolean {
    return this.isTrialLicense || this.isFullLicense;
  }

  get isFreeOrReadonlyLicense(): boolean {
    return this.isFreeLicense || this.isReadonlyLicense;
  }

  get budgetConfiguration(): number {
    return this.license?.configuration;
  }

  get isSchoolLicense(): boolean {
    return this.license?.isSchool;
  }

  private get isCzechVersion(): boolean {
    return this.appConfig.location === 'cz';
  }

  private get canActivateTrial(): boolean {
    return this.license?.canActivateTrial;
  }

  private get isLicenseToExpire(): boolean {
    return this.license?.remindExpiringFullLicenseInDays >= this.license?.remainingDays;
  }

  private license: License;
  private licenseNumber: string;
  private permissionLoaded = new ReplaySubject<number>(1);
  private licenseLoaded = new ReplaySubject<number>(1);
  private licenseDetailLoaded = new ReplaySubject<number>(1);
  private licenseEndingLastShowTimeLoaded = new ReplaySubject<number>(1);
  private licenseEndingLastShowTime = 0;
  private isOwner = false;
  private existValid: boolean;
  private appConfig = inject(APP_CONFIG);
  private authSelectorsService = inject(AuthSelectorsService);

  constructor(licenseCdkSelectorsService: LicenseSelectorsService) {
    super();

    licenseCdkSelectorsService.budgetLicense$
      .pipe(filter(license => !!license), takeUntilDestroyed())
      .subscribe(license => {
        this.license = license;
        this.appInsightsUserContext.initUserContext(license);
        this.licenseDispatchersService.setBudgetLicenseInfo(this.getLicense(license));
        this.licenseLoaded.next(1);
      });
    this.authSelectorsService.currentUser$
      .pipe(
        filter(Boolean),
        distinctUntilChanged((prev: KrosUser, curr: KrosUser) => prev.userId === curr.userId),
        switchMap((user: KrosUser) => this.uiSettingsService.getUiSettings(RemindBudgetLicenseEndingLastDate, user.userId)),
        takeUntilDestroyed()
      ).subscribe(p => {
        this.licenseEndingLastShowTime = p?.lastShowTime;
        this.licenseEndingLastShowTimeLoaded.next(1);
      });
    this.sharedSelectors.project$
      .pipe(filter(project => !!project), takeUntilDestroyed())
      .subscribe((project: ProjectDetail) => {
        if (project) {
          this.isOwner = isOwnersEmail(project.owner);
          this.permissionLoaded.next(1);
        }
      });

    licenseCdkSelectorsService.licenseDetail$.pipe(filter(Boolean), takeUntilDestroyed())
      .subscribe(licenseDetail => {
        this.licenseNumber = licenseDetail.licenseNumber;
        this.existValid = licenseDetail.existValid;
        this.licenseDispatchersService.setLicenseDetail(licenseDetail);
        this.licenseDetailLoaded.next(1);
      });
  }

  destroy(): void {
    this.licenseDispatchersService.clearBudgetLicenseInfo();
    this.appInsightsUserContext.resetUserContext(this.license);
  }

  openAutoCalculateDialog(): void {
    this.krosModalService.openCentered(
      MarketingModalComponent,
      {
        contentHelper: this.marketingContentHelper,
        canActivateTrial: this.canActivateTrial,
        orderUrl: this.licenseUrlService.orderUrl(this.existValid),
        trialButton: this.getTrialButton(),
        appPrefix: this.licenseUrlService.appPrefix,
        isLicenseFree: this.isFreeLicense,
        isLicenseReadonly: this.isReadonlyLicense,
        isLicenseFull: this.isFullLicense,
        isLicenseTrial: this.isTrialLicense,
        isLicenseToExpire: this.isLicenseToExpire,
        isAutoCalculatedAmount: true
      },
      { allowFocusAutocapture: false }
    );
  }

  openNotAccessDialog(isAutomatic = false): Observable<KrosModalRef> {
    return combineLatest([
      this.licenseLoaded,
      this.licenseDetailLoaded,
      this.permissionLoaded,
      this.licenseEndingLastShowTimeLoaded
    ]).pipe(
      take(1),
      filter(() => this.canLaunchNotAccessDialog(isAutomatic)),
      switchMap(() => this.saveLicenseEndingLastShowTime()),
      map(() => this.launchNotAccessDialog(isAutomatic))
    );
  }

  checkTrialFullLicense(): boolean {
    const ret = this.isTrialOrFullLicense;

    if (!ret) {
      this.openNotAccessDialog().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    }

    return ret;
  }

  checkFullLicense(): boolean {
    if (!this.isFullLicense) {
      this.openNotAccessDialog().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    }
    return this.isFullLicense;
  }

  checkLicense(): Observable<any> {
    return this.canLaunchNotAccessDialog(true) ? this.openNotAccessDialog(true).pipe(switchMap(p => p.afterClosed$)) : of(null);
  }

  private canLaunchNotAccessDialog(isAutomatic: boolean): boolean {
    return this.canShowMarketingDialogTriggeredByUser(isAutomatic) ||
      this.canShowMarketingDialogTriggeredAutomatically(isAutomatic) ||
      this.canShowMarketingDialogForLicenseExpiry();
  }

  private canShowMarketingDialogTriggeredAutomatically(automatic: boolean): boolean {
    return (this.isFreeLicense || this.isReadonlyLicense) && automatic && this.isOwner;
  }

  private canShowMarketingDialogTriggeredByUser(isAutomatic: boolean): boolean {
    return (this.isFreeLicense || this.isReadonlyLicense || this.isTrialLicense) && !isAutomatic;
  }

  private canShowMarketingDialogForLicenseExpiry(): boolean {
    if (this.isFullLicense && this.isLicenseToExpire && this.isOwner && !this.isSchoolLicense &&
      (!this.licenseEndingLastShowTime || (Date.now() - +this.licenseEndingLastShowTime) > 24 * 60 * 60 * 1000)) {
      return true;
    }

    return false;
  }

  private saveLicenseEndingLastShowTime(): Observable<void> {
    this.licenseEndingLastShowTime = Date.now();
    return this.uiSettingsService.saveUiSettings(
      RemindBudgetLicenseEndingLastDate,
      { lastShowTime: this.licenseEndingLastShowTime.toString() }
    );
  }

  private launchNotAccessDialog(isAutomatic = false): KrosModalRef {
    return this.krosModalService.openCentered<NonPaidPackageConfig>(
      MarketingModalComponent,
      {
        contentHelper: this.marketingContentHelper,
        canActivateTrial: this.canActivateTrial,
        orderUrl: this.licenseUrlService.orderUrl(this.existValid, this.license, this.licenseNumber),
        trialButton: this.getTrialButton(),
        appPrefix: this.licenseUrlService.appPrefix,
        isAutomaticLaunch: isAutomatic,
        isLicenseFree: this.isFreeLicense,
        isLicenseReadonly: this.isReadonlyLicense,
        isLicenseFull: this.isFullLicense,
        isLicenseTrial: this.isTrialLicense,
        isLicenseToExpire: this.isLicenseToExpire,
        trialDuration: this.license.trialDuration,
        remainingDays: this.license.remainingDays,
        isOwner: this.isOwner
      },
      { allowFocusAutocapture: false }
    );
  }

  private getLicense(license: License): LicenseInfo {
    return {
      license,
      freeCanActivateTrialTextKey: 'BUDGET.LICENSE.INFO_FREE_CAN_ACTIVATE_TRIAL_TEXT',
      freeCanActivateTrialTextKeyPhone: 'BUDGET.LICENSE.INFO_FREE_CAN_ACTIVATE_TRIAL_TEXT_PHONE',
      freeTextKeyPhone: 'BUDGET.LICENSE.INFO_FREE_TEXT_PHONE',
      freeTextKey: 'BUDGET.LICENSE.INFO_FREE_TEXT',
      trialTextKey: 'BUDGET.LICENSE.INFO_TRIAL_TEXT',
      trialTextKeyPhone: 'BUDGET.LICENSE.INFO_TRIAL_TEXT_PHONE',
      readonlyTextKey: 'BUDGET.LICENSE.INFO_READONLY_TEXT',
      readonlyTextKeyPhone: 'BUDGET.LICENSE.INFO_READONLY_TEXT_PHONE',
      licenseEndingTextKey: 'BUDGET.LICENSE.INFO_ENDING_TEXT',
      licenseEndingTextKeyPhone: 'BUDGET.LICENSE.INFO_ENDING_TEXT_PHONE',
      activateTrialLicense: (): void => this.activateTrial()
    };
  }

  private activateTrialLicenseWithQuestion(): void {
    this.krosModalService.openCentered(
      QuestionModalComponent,
      {
        body: this.translateTrialText('BUDGET.LICENSE.ACTIVATE_TRIAL_QUESTION'),
        confirmButton: this.translateService.translate('COMMON.YES')
      },
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: false,
        showMobileArrowBack: false
      },
      NaN,
      NaN,
      undefined,
      'no-min-width',
    ).afterClosed$.pipe(
      take(1),
      filter((result: any) => result.type === 'submit')
    ).subscribe(() => this.licenseCdkDispatchersService.activateBudgetTrialLicense());
  }

  private getTrialButton(): ButtonConfig {
    return {
      label: this.translateTrialText('LICENSE_INFO.TRY_FOR_X_DAYS'),
      action: (): void => { setTimeout(() => this.activateTrial(), 100); }
    };
  }

  private activateTrial(): void {
    if (this.isCzechVersion) {
      this.activateTrialLicenseWithQuestion();
    } else {
      this.activateTrialLicenseWithSms();
    }
  }

  private activateTrialLicenseWithSms(): void {
    this.krosModalService.openCentered(
      SmsActivationModalComponent,
      {
        appInsightsPrefix: 'B-',
        trialDuration: this.license.trialDuration,
        applicationNameKey: 'LICENSE_ACTIVATION.SMS_ACTIVATION.APPLICATION_NAME_BUDGET',
        endpoints: {
          tryGetPhoneNumber: `${this.appConfig.gatewayApiUrl}/api/trialLicensePhoneNumber`,
          checkPhoneNumberAvailability: `${this.appConfig.gatewayApiUrl}/api/budgetTrialValidate`,
          sendCode: `${this.appConfig.gatewayApiUrl}/api/budgetTrialActivate`
        } as SmsActivationEndpoints
      },
      {},
      NaN, NaN, undefined, 'small-modal-column-footer'
    ).afterClosed$.pipe(take(1)).subscribe(result => {
      if (result.type === 'submit') {
        this.appInsightsService.trackEvent('B-license-activate-trial');
        this.licenseCdkDispatchersService.loadBudgetLicense();
      }
    });
  }

  private translateTrialText(word: string): string {
    return formatString(
      this.translateService.translate(word),
      '' + this.license.trialDuration,
      this.pluralisationByCountPipe.transform(this.license.trialDuration, 'LICENSE_INFO.DAY'));
  }
}
