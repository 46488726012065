<div class="settings-module">
  <div class="header-line">
    <div class="title">
      <h4>{{ 'SETTINGS.GENERAL' | translate }}</h4>

      <i
        class="material-icons tooltip-icon"
        [krosTooltip]="'SETTINGS.GENERAL_INFO' | translate"
      >info</i>
    </div>

    <button
      class="btn btn-outline-primary"
      type="button"
      [routerLink]="routerLink"
      data-test="close-settings-button"
    >
      {{ 'SETTINGS.CLOSE' | translate }}
      <i class="material-icons">clear</i>
    </button>
  </div>

  <div class="scrollable-content">
    <div class="card img">
      <h4>{{ 'SETTINGS.LOGO' | translate }}</h4>

      <div
        class="description"
        [innerHtml]="'SETTINGS.LOGO_AND_STAMP_DESCRIPTION' | translate"
      ></div>
      <kros-image-upload-button [controller]="'Logo'"></kros-image-upload-button>
    </div>

    <div class="card img">
      <h4>{{ 'SETTINGS.STAMP' | translate }}</h4>

      <div
        class="description"
        [innerHtml]="'SETTINGS.LOGO_AND_STAMP_DESCRIPTION' | translate"
      ></div>
      <kros-image-upload-button [controller]="'Stamp'"></kros-image-upload-button>
    </div>
  </div>
</div>
