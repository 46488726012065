<ng-container [formGroup]="form">
  <div *ngIf="!exportPeriodRange; else periodsRange">
    <label
      for="period"
      class="caption"
    >{{'BUILDING_PROGRESS.PERIODS.PERIOD' | translate}}:</label>

    <select
      id="period"
      class="form-control"
      formControlName="periodId"
      data-test="period-select"
    >
      <option
        *ngFor="let period of periods"
        [value]="period.id"
      >{{ getPeriodTitle(period) }}</option>
    </select>

    <div
      *ngIf="!allowOnlySinglePeriod"
      class="period-range-switch"
      krosTooltip="{{ tooltipPeriodRange }}"
      position="after"
      (click)="changeMode()"
      data-test="export-period-range-switch"
    >
      {{ periodRangeTitle }}
    </div>
  </div>

  <ng-template #periodsRange>
    <div *ngIf="exportPeriodRange">
      <div class="flex">
        <div class="period-range">
          <label
            for="periodsFrom"
            class="caption"
          >{{'COMMON.FROM' | translate}}:</label>

          <select
            id="periodsFrom"
            class="form-control"
            formControlName="periodsFrom"
            data-test="periods-from-select"
            (change)="onPeriodDateFromChange()"
          >
            <option
              *ngFor="let period of periods"
              [value]="period.dateFrom"
            >{{ period.dateFrom | date:'dd.MM.yyyy' }}</option>
          </select>
        </div>

        <div class="period-range">
          <label
            for="periodsTo"
            class="caption"
          >{{'COMMON.TO' | translate}}:</label>

          <select
            id="periodsTo"
            class="form-control"
            formControlName="periodsTo"
            data-test="periods-to-select"
          >
            <option
              value="null"
              disabled
              hidden
            ></option>
            <option
              *ngFor="let period of getFilteredPeriodRangeEnds()"
              [value]="period.dateTo"
            >{{ period.dateTo | date:'dd.MM.yyyy' }}</option>
          </select>
        </div>
      </div>

      <div
        class="period-range-switch"
        krosTooltip="{{ tooltipSinglePeriod }}"
        position="after"
        (click)="changeMode()"
        data-test="export-period-range-switch"
      >
        {{ singlePeriodTitle }}
      </div>
    </div>
  </ng-template>
</ng-container>
