import { Injectable } from '@angular/core';

import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { ApplicationType, License, LicenseProblem, ModuleType, PackageType } from '@kros-sk/models';

import * as authActions from './auth.actions';
import { KrosUser } from '../services/auth/kros-user.model';
import { waitForResultAction } from '../../shared/tools';

@Injectable()
export class AuthDispatchersService {

  constructor(
    private store: Store,
    private actions$: Actions,
  ) { }

  /**
   * Starting authentication process
   */
  public startAuthentication(): void {
    this.store.dispatch(authActions.startAuthentication());
  }

  /**
   * Logout currently logged user
   */
  public logoutUser(): void {
    this.store.dispatch(authActions.logoutUser());
  }

  /**
   * Setting current user
   */
  public setUser(user: KrosUser): void {
    this.store.dispatch(authActions.setUser({ user }));
  }

  /**
   * Resetting current user to null
   */
  public setEmptyUser(): void {
    this.store.dispatch(authActions.setEmptyUser());
  }

  /**
   * Load company license
   */
  public loadCurrentLicense(companyId: number): Promise<boolean> {
    const result = waitForResultAction(
      this.actions$,
      authActions.loadCurrentLicenseSuccess,
      authActions.loadCurrentLicenseFail,
    );
    this.store.dispatch(authActions.loadCurrentLicense({ companyId }));
    return result;
  }

  public startLicenseTrial(companyId: number): Promise<boolean> {
    const result = waitForResultAction(
      this.actions$,
      authActions.startLicenseTrialSuccess,
      authActions.startLicenseTrialFail,
    );
    this.store.dispatch(authActions.startLicenseTrial({ companyId }));
    return result;
  }

  /**
   * Assign license with customer number to specific company
   */
  public assignLicense(companyId: number, customerNumber: string): Promise<boolean> {
    const result = waitForResultAction(
      this.actions$,
      authActions.assignLicenseSuccess,
      authActions.assignLicenseFail,
    );
    this.store.dispatch(authActions.assignLicense({ companyId, customerNumber }));
    return result;
  }

  /**
   * Remove companies from license
   */
  public removeCompaniesFromLicense(
    companyId: number,
    companyIds: number[],
    currentlyUsedCompanies: number[],
    customerNumber: string): Promise<boolean> {
    const result = waitForResultAction(
      this.actions$,
      authActions.removeCompaniesFromLicenseSuccess,
      authActions.removeCompaniesFromLicenseFail,
    );
    this.store.dispatch(authActions.removeCompaniesFromLicense({ companyId, companyIds, currentlyUsedCompanies, customerNumber }));
    return result;
  }

  /**
   * reset current license
   */
  public resetCurrentLicense(): void {
    this.store.dispatch(authActions.resetCurrentLicense());
  }

  /**
   * Just for debugging purposes
   */
  public forcePackageType(packageType: PackageType, modules: ModuleType[]): void {
    const license = {
      applicationType: ApplicationType.Unknown,
      type: packageType,
      modules,
      daysValid: 30,
      validUntil: null,
      autoRenew: false,
      nextPaymentDate: null,
      customerNumber: null
    } as License;

    this.store.dispatch(authActions.loadCurrentLicenseSuccess({
      licenseState: {
        currentLicense: {...license, usedCompanyIds: [], problemCode: LicenseProblem.None},
        allLicenses: [license],
        userCount: null,
        licensesResponseStatus: null
      }
    }));
  }
}
