import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { Observable, of, switchMap, take, tap } from 'rxjs';

import { AuthSelectorsService } from '@kros-sk/auth';
import { LicenseDispatchersService, UnsubscribeComponent } from '@kros-sk/ssw-cdk';
import { NavbarService, Project } from '@kros-sk/ssw-shared-legacy';

import { BudgetSettingService, BuildingProgressSettingService, SettingService } from '../../services';
import { BuildingProgressBasePathSegment } from '../../model';

@Component({
  selector: 'ssw-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent extends UnsubscribeComponent implements OnInit, OnDestroy {

  private projects: Project[];

  constructor(
    protected authSelectorsService: AuthSelectorsService,
    private budgetSettingService: BudgetSettingService,
    private buildingProgressSettingService: BuildingProgressSettingService,
    private licenseDispatchersService: LicenseDispatchersService,
    private settingService: SettingService,
    private navbarService: NavbarService,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.settingService.projectId = +this.activatedRoute.snapshot.paramMap.get('projectId');

    if (this.settingService.projectId) {
      this.subs.sink = this.settingService.getProjects()
        .pipe(take(1))
        .subscribe(projects => {
          this.projects = projects;
          this.setDesktopDropdown();
        });
      this.subs.sink = this.activatedRoute.paramMap
        .pipe(
          tap(i => this.settingService.projectId = +i.get('projectId')),
          switchMap(() => this.getBudgetPipe()),
          switchMap(() => this.getBuildingProgressPipe())
        )
        .subscribe(() => this.setDesktopDropdown());
      this.subs.sink = this.navbarService.loadProjects$.subscribe(() => this.setDesktopDropdown());
      this.navbarService.setProjectsLabel('SETTINGS.PROJECT_SETTINGS');
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.navbarService.setDesktopDropdown(undefined);
    this.navbarService.setProjectsLabel(undefined);
  }

  private getBudgetPipe(): Observable<boolean> {
    return this.budgetSettingService.checkBudget()
      .pipe(
        take(1),
        tap((r) => r ? this.budgetSettingService.loadBudgetSettings() : of(false))
      );
  }

  private getBuildingProgressPipe(): Observable<boolean> {
    return this.buildingProgressSettingService.checkBuildingProgress()
      .pipe(
        take(1),
        tap((r) => {
          if (r) {
            this.buildingProgressSettingService.loadBuildingProgressSettings();
            this.licenseDispatchersService.loadBuildingProgressLicense();
            this.licenseDispatchersService.loadBudgetLicense();
          }
          return of(r);
        })
      );
  }

  private setDesktopDropdown(): void {
    if (this.projects?.length) {
      const path = !!this.activatedRoute.snapshot.children[0].routeConfig.matcher
        ? BuildingProgressBasePathSegment
        : this.activatedRoute.snapshot.children[0].routeConfig.path;
      this.navbarService.setDesktopDropdown(
        this.projects.map(p => ({
          url: `settings/${p.id}/${path}`,
          title: p.name,
          selected: p.id === this.settingService.projectId
        })));
    }
  }
}
