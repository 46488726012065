import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { KrosDropdownComponent } from './kros-dropdown.component';
import { KrosDropdownGroupDirective } from './kros-dropdown-group.directive';
import { KrosDropdownHeaderItemComponent } from './kros-dropdown-header-item.component';
import { KrosDropdownItemComponent } from './kros-dropdown-item.component';
import { KrosDropdownItemRadioComponent } from './kros-dropdown-item-radio.component';
import { KrosDropdownTrigger } from './kros-dropdown-trigger.directive';

const DECLARATIONS = [
  KrosDropdownTrigger,
  KrosDropdownComponent,
  KrosDropdownItemComponent,
  KrosDropdownItemRadioComponent,
  KrosDropdownHeaderItemComponent,
  KrosDropdownGroupDirective
];

@NgModule({
  declarations: [
    ...DECLARATIONS,
  ],
  imports: [
    CommonModule,
    CdkMenuModule,
  ],
  exports: [
    ...DECLARATIONS,
  ]
})
export class KrosDropdownModule {
}
