<div
  class="column-filter"
  [class.horizontal]="horizontal"
  ngbDropdown
  (openChange)="onDropdownOpenChange($event)"
  #dropdown="ngbDropdown"
  autoClose="outside"
>
  <button
    ngbDropdownToggle
    class="btn btn-sm btn-link btn-column-filter"
    type="button"
    data-test="column-filter-toggle"
  >
    {{ dropdownTitle | translate }}
    <i class="material-icons column-filter">expand_more</i>
  </button>

  <div ngbDropdownMenu>
    <label
      *ngIf="hasUngroupedFilterColumns"
      class="font-weight-bold ungrouped-label"
    >{{ ungroupedColumnsLabel | translate }}</label>

    <div *ngFor="let colDef of filterColumns">
      <div
        *ngIf="!colDef.colsInGroup"
        class="single-filter-column form-group checkbox"
      >
        <input
          id="column-filter-check-{{colDef.id}}"
          type="checkbox"
          data-test="column-filter-check"
          [checked]="!colDef.isHidden"
          (change)="onColumnVisibilityChange(colDef)"
        >
        <label
          for="filter-column-check-{{colDef.id}}"
          class="filter-column-label"
        >{{colDef.title | translate}}</label>
      </div>

      <ng-container *ngIf="colDef.colsInGroup">
        <div
          ngbDropdownItem
          class="filter-column-group form-group checkbox"
        >
          <input
            *ngIf="allowGroupCheck"
            id="column-filter-group-check-{{colDef.id}}"
            type="checkbox"
            data-test="column-filter-group-check"
            [checked]="!colDef.isHidden"
            (change)="onGroupVisibilityChange(colDef)"
          >

          <label
            class="font-weight-bold"
            for="filter-check-{{colDef.id}}"
          >{{colDef.title | translate}}</label>
        </div>

        <div class="column-filter-group-columns">
          <div
            *ngFor="let col of colDef.colsInGroup"
            class="filter-column form-group checkbox"
          >
            <input
              id="column-filter-check-{{col.id}}"
              type="checkbox"
              data-test="column-filter-check"
              [checked]="!col.isHidden"
              (change)="onColumnVisibilityChange(col, colDef)"
            >
            <label
              for="filter-column-check-{{col.id}}"
              class="filter-column-label"
            >{{col.title | translate}}</label>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
