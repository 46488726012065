import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RecalcWatcherDirective } from './recalc-watcher-slave/recalc-watcher.directive';
import { RecalcWatcherMasterDirective } from './recalc-watcher-master/recalc-watcher-master.directive';

@NgModule({
  declarations: [
    RecalcWatcherMasterDirective,
    RecalcWatcherDirective,
  ],
  exports: [
    RecalcWatcherMasterDirective,
    RecalcWatcherDirective,
  ],
  imports: [
    CommonModule,
  ]
})
export class RecalcWatcherModule { }
