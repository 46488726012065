import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import produce from 'immer';

import * as buildingProgressActions from './building-progress.actions';
import * as reducerHelper from './building-progress-reducer.helper';
import { adapterPeriods } from './building-progress.adapters';
import { BuildingProgressPeriod } from '../../building-progress/models/building-progress-period.model';
import { BuildingProgressState } from './building-progress.state';

export const periodsReducers: ReducerTypes<BuildingProgressState, ActionCreator[]>[] = [
  on(buildingProgressActions.loadPeriodsStart, (state): BuildingProgressState => {
    return { ...state, periodsLoading: true };
  }),
  on(buildingProgressActions.loadPeriodsSuccess, (state, payload): BuildingProgressState => {
    let periods: BuildingProgressPeriod[];
    if (payload.keepSelectedPeriods) {
      periods = produce(payload.periods, newPeriods => {
        const oldPeriods = adapterPeriods.getSelectors().selectAll(state.periods);
        oldPeriods.forEach(p => {
          const newPeriod = newPeriods.find(np => np.id === p.id);
          if (newPeriod) {
            newPeriod.isMarkedInFilter = p.isMarkedInFilter;
          }
        });
      });
    } else {
      periods = payload.periods;
    }

    return {
      ...state,
      ...(payload.wholeBuilding && { wholeBuildingPeriods: adapterPeriods.setAll(periods, state.wholeBuildingPeriods) }),
      ...(!payload.wholeBuilding && { periods: adapterPeriods.setAll(periods, state.periods) }),
      periodsLoading: false
    };
  }),
  on(buildingProgressActions.createPeriodStart, (state): BuildingProgressState => {
    return { ...state, periodsLoading: true };
  }),
  on(buildingProgressActions.createPeriodSuccess, (state, payload): BuildingProgressState => {
    return reducerHelper.createPeriod(state, payload);
  }),
  on(buildingProgressActions.createPeriodError, (state, payload): BuildingProgressState => {
    return { ...state, periodsLoading: payload.loading };
  }),
  on(buildingProgressActions.editPeriodStart, (state): BuildingProgressState => {
    return { ...state, periodsLoading: true };
  }),
  on(buildingProgressActions.editPeriodSuccess, (state, payload): BuildingProgressState => {
    const data = produce(state.constructionData, newData => {
      reducerHelper.editPeriods(payload, newData.periods);
      reducerHelper.sortPeriods(newData.periods, false);
    });

    const periods = produce(adapterPeriods.getSelectors().selectAll(state.periods), newPeriods => {
      reducerHelper.editPeriods(payload, newPeriods);
      reducerHelper.sortPeriods(newPeriods, true);
    });

    return {
      ...state,
      constructionData: reducerHelper.loadNewConstructionData(data, payload.items),
      periods: adapterPeriods.setAll(periods, state.periods),
      periodsLoading: false
    };
  }),
  on(buildingProgressActions.editPeriodError, (state, payload): BuildingProgressState => {
    return { ...state, periodsLoading: payload.loading };
  }),
  on(buildingProgressActions.deletePeriodSuccess, (state, payload): BuildingProgressState => {
    return reducerHelper.deletePeriod(state, payload);
  }),
  on(buildingProgressActions.cancelIsApprovalRequestedPeriodSuccess, (state, payload): BuildingProgressState => {
    return reducerHelper.deletePeriod(state, payload);
  }),
  on(buildingProgressActions.cancelIsApprovalRequestedPeriods, (state): BuildingProgressState => {
    return reducerHelper.setCancelIsApprovalRequestedPeriods(state);
  }),
  on(buildingProgressActions.setIsApprovalRequestedPeriodSuccess, (state, payload): BuildingProgressState => {
    return reducerHelper.setApprovingPeriods(state, payload, true, false);
  }),
  on(buildingProgressActions.setApprovedPeriodSuccess, (state, payload): BuildingProgressState => {
    return reducerHelper.setApprovingPeriods(
      state,
      payload,
      payload.isApprovalRequested,
      payload.isApproved,
      payload.isMultiStageApprovalModeEnabled);
  }),
  on(buildingProgressActions.cancelApprovedPeriodSuccess, (state, payload): BuildingProgressState => {
    return reducerHelper.setApprovingPeriods(state, payload, false, false);
  }),
  on(buildingProgressActions.setIsLockedPeriodSuccess, (state, payload): BuildingProgressState => {
    return reducerHelper.setLockedPeriods(state, payload, true);
  }),
  on(buildingProgressActions.cancelIsLockedPeriodSuccess, (state, payload): BuildingProgressState => {
    return reducerHelper.setLockedPeriods(state, payload, false);
  }),
  on(buildingProgressActions.selectPeriodId, (state, payload): BuildingProgressState => {
    return { ...state, periodId: payload.id };
  }),
  on(buildingProgressActions.markPeriodInFilter, (state, payload): BuildingProgressState => {
    return {
      ...state,
      periods: adapterPeriods.updateOne({
        id: payload.id,
        changes: { isMarkedInFilter: payload.isMarked }
      } as Update<BuildingProgressPeriod>, state.periods)
    };
  }),
  on(buildingProgressActions.clearAllMarkedPeriodsInFilter, (state, payload): BuildingProgressState => {
    const update = payload.periods.map(p => {
      return {
        id: p.id,
        changes: { isMarkedInFilter: false }
      } as Update<BuildingProgressPeriod>;
    });

    return {
      ...state,
      periods: adapterPeriods.updateMany(update, state.periods)
    };
  }),
  on(buildingProgressActions.openPeriodDetailsPanel, (state, payload): BuildingProgressState => {
    return { ...state, periodDetails: { itemId: payload.itemId, period: payload.period, details: undefined, loading: true } };
  }),
  on(buildingProgressActions.closePeriodDetailsPanel, (state): BuildingProgressState => {
    return { ...state, periodDetails: undefined };
  }),
  on(buildingProgressActions.loadItemPeriodDetailsSuccess, (state, payload): BuildingProgressState => {
    return { ...state, periodDetails: { ...state.periodDetails, details: payload.details, loading: false } };
  }),
  on(buildingProgressActions.loadItemPeriodDetailsError, (state): BuildingProgressState => {
    return { ...state, periodDetails: { ...state.periodDetails, details: undefined, loading: false } };
  }),
  on(buildingProgressActions.savePeriodDetailsSuccess, (state, payload): BuildingProgressState => {
    return {
      ...state,
      constructionData: reducerHelper.loadNewConstructionDataDetails(
        state.constructionData, state.periodDetails.itemId, state.periodDetails.period.periodId, payload.newDetailId),
      periodDetails: {
        ...state.periodDetails, details: payload.details, period: { ...state.periodDetails.period, detailId: payload.newDetailId }
      }
    };
  })
];
