import { Action } from '@ngrx/store';

import * as editBudgetActions from '@kros-sk/ssw-budget/edit-budget';
import {
  boqSharedActions,
  budgetDeleteActions,
  budgetItemsSharedActions,
  budgetSharedActions,
  buildingObjectsSharedActions,
  otherBudgetSharedActions
} from '@kros-sk/ssw-budget/shared/data-access/store';

import * as budgetActions from '../budget.actions';
import * as budgetItemActions from '../budget-item.actions';

export const undoableBudgetActions: Action[] = [
  ...budgetDeleteActions,
  budgetItemsSharedActions.editBudgetItemSuccess,
  budgetActions.moveBudgetSectionSuccess,
  buildingObjectsSharedActions.moveBuildingObjectsSuccess,
  budgetItemActions.moveBudgetItemsSuccess,
  budgetItemsSharedActions.moveBudgetItemsMultipleSuccess,
  editBudgetActions.editTotalPriceSuccess,
  editBudgetActions.setPriceIndexSuccess,
  boqSharedActions.setBoqSuccess,
  boqSharedActions.setAmountCalculationTypeSuccess,
  budgetItemActions.searchSuccess,
  budgetItemActions.setColorCodeSuccess,
  budgetItemsSharedActions.updateBudgetItemSuccess,
  budgetItemsSharedActions.editBudgetItemsSuccess,
  budgetItemActions.createBudgetItem,
  budgetItemActions.createCustomBudgetItemSuccess,
  budgetActions.createCustomBudgetSectionSuccess,
  budgetItemActions.copyBudgetItemSuccess,
  otherBudgetSharedActions.createOtherBudgetItemsSuccess,
  otherBudgetSharedActions.createOtherBudgetSectionSuccess,
  otherBudgetSharedActions.createOtherBudgetBuildingObjectSuccess,
  buildingObjectsSharedActions.createCustomBuildingObjectSuccess,
  budgetItemActions.renumberItemsSuccess
];

export const needClearStackBudgetActions: Action[] = [
  budgetSharedActions.setProject,
  budgetSharedActions.setBudget,
  budgetSharedActions.setBudgetGrouping,
  budgetActions.importConstructionSuccess,
  budgetItemActions.substituteBudgetItem,
  boqSharedActions.setQuantityManagerBoq,
  budgetItemActions.reloadQuantityManagerBoq
];
