import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
  BudgetApprovalDifferencesModel,
  BudgetModel,
  ChangeSheetApproveModel,
  ChangeSheetModel,
  ChangeSheetRelationEditModel
} from '../models';
import { DeleteRelationViewModel } from '../models/delete-relation-view-model';
import { environment } from '../../../environments/environment';

const gatewayApi = '/api/projects/';
const budgetApi = '/budgetApprovalService/budget';
const changeSheetApi = '/budgetApprovalService/changeSheet';
const getBudgetEndpoint = '/data/';
const getDifferenciesDataEndpoint = '/differentUnitPriceItemList/';
const getDataEndpoint = '/data/';
const approveEndpoint = '/approve';
const cancelApprovedEndpoint = '/approvedCancel';
const setRelationEndpoint = '/relationSet';
const deleteRelationEndpoint = '/relationListDelete';

@Injectable()
export class ChangeSheetService {

  private get gatewayApi(): string {
    return environment.appUrls.titanGatewayUrl + gatewayApi;
  }

  constructor(
    private http: HttpClient,
  ) { }

  getDifferencesData(projectId: number): Observable<BudgetApprovalDifferencesModel> {
    return this.http.get<BudgetApprovalDifferencesModel>(this.getBudgetApiEndpointPath(projectId, getDifferenciesDataEndpoint));
  }

  getChangeSheetData(projectId: number, buildingObjectId: number): Observable<ChangeSheetModel> {
    return this.http.get<ChangeSheetModel>(
      this.getChangeSheetApiEndpointPath(projectId, getDataEndpoint) + projectId + '/' + buildingObjectId);
  }

  postApprove(approveBuildingObjectModel: ChangeSheetApproveModel): Observable<any> {
    return this.http.post(
      this.getChangeSheetApiEndpointPath(approveBuildingObjectModel.projectId, approveEndpoint), approveBuildingObjectModel);
  }

  postCancelApproved(approveBuildingObjectModel: ChangeSheetApproveModel): Observable<any> {
    const cancelApprove = {
      projectId: approveBuildingObjectModel.projectId,
      buildingObjectId: approveBuildingObjectModel.buildingObjectIds[0]
    };
    return this.http.post(this.getChangeSheetApiEndpointPath(approveBuildingObjectModel.projectId, cancelApprovedEndpoint), cancelApprove);
  }

  getBudgetDataForRelation(projectId: number): Observable<BudgetModel> {
    return this.http.get<BudgetModel>(this.getBudgetApiEndpointPath(projectId, getBudgetEndpoint) + projectId);
  }

  editRelation(editModel: ChangeSheetRelationEditModel): Observable<any> {
    return this.http.patch<any>(this.getChangeSheetApiEndpointPath(editModel.projectId, setRelationEndpoint), editModel);
  }

  deleteRelation(projectId: number, budgetItemIds: number[]): Observable<any> {
    const request = { projectId, budgetItemIds } as DeleteRelationViewModel;
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', }),
      body: JSON.stringify(request)
    };

    return this.http.delete<any>(this.getChangeSheetApiEndpointPath(projectId, deleteRelationEndpoint), options);
  }

  private getBudgetApiEndpointPath(projectId: number, endpoint: string): string {
    return this.gatewayApi + projectId + budgetApi + endpoint;
  }

  private getChangeSheetApiEndpointPath(projectId: number, endpoint: string): string {
    return this.gatewayApi + projectId + changeSheetApi + endpoint;
  }
}
