import { LicenseInfo } from '../../license';

export interface LicenseState {
  mishaLicenseInfo: LicenseInfo;
  buildingProgressLicenseInfo: LicenseInfo;
  budgetLicenseInfo: LicenseInfo;
  quantityManagerLicenseInfo: LicenseInfo;
  isAutomaticRenewalLicense: boolean;
  licenseNumber: string;
}

export const initialLicenseState: LicenseState = {
  mishaLicenseInfo: undefined,
  buildingProgressLicenseInfo: undefined,
  budgetLicenseInfo: undefined,
  quantityManagerLicenseInfo: undefined,
  isAutomaticRenewalLicense: false,
  licenseNumber: null
};
