import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter, from, merge, Observable, switchMap, timeout } from 'rxjs';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { ApplicationRoute, OWNER_ME, Project } from '@kros-sk/ssw-shared-legacy';
import { DocumentService } from '@kros-sk/ssw-quantity-manager/qm';
import { PATH_QM_AREA } from '@kros-sk/ssw-shared/constants';
import { RegistrationNotificationService, UserService } from '@kros-sk/ssw-cdk';

import { ConnectorService } from '../../../core/services/connector.service';
import { ProjectsService } from '../../services/projects.service';

const enum ProjectPosition {
  Demo = 'demo',
  Latest = 'latest'
}

@Component({
  selector: 'ssw-project-navigate',
  templateUrl: './project-navigate.component.html',
  styleUrls: ['./project-navigate.component.scss'],
  providers: [
    DocumentService
  ]
})
export class ProjectNavigateComponent implements OnInit {

  private notificationService = inject(RegistrationNotificationService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private destroyRef = inject(DestroyRef);
  private projectsService = inject(ProjectsService);
  private userService = inject(UserService);
  private documentService = inject(DocumentService);
  private connectorService = inject(ConnectorService);
  private appInsightsService = inject(AppInsightsBaseService);

  constructor() {
    this.connectorService.connect(this.userService.getUserIdentityId() + '');
  }

  private navigateToProject$ = (params: ParamMap): Observable<any> => {
    return this.projectsService.getProjects().pipe(
      switchMap((projects: Project[]) => {
        const applicationRoute = params.get('application');
        const useMyProjectsOnly = applicationRoute === ApplicationRoute.QuantityManager || applicationRoute === ApplicationRoute.Budget;
        const requestingProject = this.chooseProject(params.get('project-position'), projects, useMyProjectsOnly);
        const requestingMyProjectsAndNotHavingDemo = useMyProjectsOnly && !requestingProject;
        return (!projects.length || requestingMyProjectsAndNotHavingDemo)
          ? this.userService.createDemoProject()
          : this.navigate(
            requestingProject,
            applicationRoute,
            params.get('project-position'),
            applicationRoute === ApplicationRoute.QuantityManager);
      })
    );
  };

  private demoCreated$ = (params: ParamMap): Observable<any> => {
    const applicationRoute = params.get('application');
    const navigateToDemo$ = this.projectsService.getProjects().pipe(
      filter(projects => !!projects.length),
      switchMap((projects: Project[]) =>
        this.navigate(
          this.chooseProject(params.get('project-position'), projects),
          applicationRoute,
          params.get('project-position'),
          applicationRoute === ApplicationRoute.QuantityManager)));

    return this.notificationService.demoProjectCreated$.pipe(
      filter(Boolean),
      timeout({ each: 15000, with: () => navigateToDemo$ }),
      timeout({ each: 20000, with: () => this.navigateRoot() }),
      switchMap(() => navigateToDemo$),
    );
  };

  ngOnInit(): void {
    this.activatedRoute.paramMap.pipe(
      switchMap((params: ParamMap) => merge(
        this.demoCreated$(params),
        this.navigateToProject$(params)
      )),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  private chooseProject(projectPosition: string, projects: Project[], useMyProjectsOnly = false): Project {
    switch (projectPosition) {
      case ProjectPosition.Demo:
        return this.chooseDemoProject(projects, useMyProjectsOnly);
      default:
        return this.chooseLatestProject(projects, useMyProjectsOnly);
    }
  }

  private chooseLatestProject(projects: Project[], useMyProjectsOnly = false): Project {
    const ownProjects = projects.filter(project => useMyProjectsOnly ? project.owner === OWNER_ME : true);
    return ownProjects.reduce((acc, project) => (project.dateCreated > acc.dateCreated ? project : acc), ownProjects[0]);
  }

  private chooseDemoProject(projects: Project[], useMyProjectsOnly = false): Project {
    return projects
      .filter(project => useMyProjectsOnly ? project.owner === OWNER_ME : true)
      .find(p => p.isDemo);
  }

  private navigate(project: Project, application: string, projectPosition: string, useDocumentLink = false): Observable<any> {
    const trackEventName = projectPosition === ProjectPosition.Demo ? 'P-DemoProjectNavigated' : 'P-LatestProjectNavigated';
    this.appInsightsService.trackEvent(trackEventName, { application, statisticPropertyNames: 'application' });

    const navigateToProject$ = (projectId: number): Observable<any> =>
      from(this.router.navigate(['projects', 'project-detail', projectId, application]));

    const navigateToDocument$ = (projectId: number): Observable<any> => this.documentService.getFirstDocument(projectId).pipe(
      switchMap(document => (document)
        ? from(this.router.navigate(['projects', 'project-detail', projectId, application, PATH_QM_AREA],
          { queryParams: { documentVersionId: document.currentVersionId } }))
        : navigateToProject$(project.id)
      ));

    return useDocumentLink
      ? navigateToDocument$(project.id)
      : navigateToProject$(project.id);
  }

  private navigateRoot(): Observable<any> {
    return from(this.router.navigate(['/']));
  }
}
