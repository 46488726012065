import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

import { KrosDatepickerDirective } from './kros-datepicker.directive';
import { KrosInputDatepickerComponent } from './kros-input-datepicker.component';

const DECLARATIONS = [
  KrosDatepickerDirective,
  KrosInputDatepickerComponent,
];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
  ],
  exports: [...DECLARATIONS],
})
export class KrosDatepickerModule {}
