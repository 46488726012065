import { inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { permissionActions } from './permission.actions';

@Injectable()
export class PermissionDispatchersService {

  private store = inject(Store<PermissionState>);

  loadBuildingProgressPermission(projectId: number): void {
    this.store.dispatch(permissionActions.loadBuildingProgressPermissionStart({ projectId }));
  }

  loadBudgetPermission(projectId: number): void {
    this.store.dispatch(permissionActions.loadBudgetPermissionStart({ projectId }));
  }
}
