import { Component, OnInit } from '@angular/core';

import { filter } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { BuildingProgressPanelEnum } from '../../../building-progress/models/building-progress-panel.enum';
import { BuildingProgressPanelHelper, LicenseHelper, PermissionHelper } from '../../../building-progress/helpers';
import { BuildingProgressPeriod } from '../../../building-progress/models/building-progress-period.model';
import { BuildingProgressSelectorsService } from '../../../store/building-progress';
import { EditingPanelHelper } from '../../../building-progress/helpers/editing-panel.helper';
import { ItemDetails } from '../../../building-progress/models/item-period-details.model';
import { PeriodDatePipe } from '../../../building-progress/modules/periods/pipes/period-date.pipe';

@Component({
  selector: 'app-item-period-details',
  templateUrl: './item-period-details.component.html',
  styleUrls: ['./item-period-details.component.scss']
})
export class ItemPeriodDetailsComponent extends UnsubscribeComponent implements OnInit {
  itemPeriodDetails$: Observable<ItemDetails>;
  itemPeriodDetailsLoading: boolean;
  isNew: Subject<boolean> = new Subject();
  period: BuildingProgressPeriod;
  periodName: string;
  info = 'BUILDING_PROGRESS.DETAILS.INFO';

  constructor(
    private buildingProgressSelectorsService: BuildingProgressSelectorsService,
    private periodDatePipe: PeriodDatePipe,
    private permissionHelper: PermissionHelper,
    private licenseHelper: LicenseHelper,
    private editingPanelHelper: EditingPanelHelper,
    public panelHelper: BuildingProgressPanelHelper
  ) {
    super();
  }

  ngOnInit(): void {
    this.editingPanelHelper.initPanelType(BuildingProgressPanelEnum.Details);

    this.subs.sink = this.buildingProgressSelectorsService.period$
      .subscribe((period: BuildingProgressPeriod) => {
        this.periodName = period
          ? this.periodDatePipe.transform(period.dateFrom, period.dateTo)
          : undefined;
        this.period = period;
      });

    this.subs.sink = this.buildingProgressSelectorsService.periodDetails$.pipe(filter(d => !!d))
      .subscribe(periodDetails => {
        this.itemPeriodDetailsLoading = periodDetails.loading;
        this.isNew.next(!periodDetails.period?.detailId);
      });

    this.itemPeriodDetails$ = this.buildingProgressSelectorsService.itemPeriodDetails$;
  }

  areDetailsReadOnly(): boolean {
    return this.permissionHelper.areDetailsReadOnly ||
      this.licenseHelper.isReadonlyLicense ||
      this.period?.isLocked ||
      this.period?.isApproved ||
      this.period?.isApprovalRequested;
  }

  onEditingChanged(editing: boolean): void {
    this.editingPanelHelper.isEditing = editing;
  }
}
