<div class="kros-modal-header">
  <h4
    class="kros-modal-title"
    [innerHTML]="('DOCUMENTS.DESCRIPTION_BUILDING') | translate | formatString: modalRef.data?.name | safeHtml"
  ></h4>
  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onCloseClick()"
    data-test="close-description"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div class="kros-modal-body">
  <form
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
  >
    <textarea
      class="form-control"
      id="descriptionTextArea"
      data-test="description-textarea"
      rows="6"
      placeholder="{{ 'DOCUMENTS.DESCRIPTION' | translate }}"
      formControlName="description"
    ></textarea>
  </form>
</div>

<div class="kros-modal-footer">
  <button
    *ngIf="!canEdit"
    class="btn btn-primary btn-submit"
    id="ga-edit-document-description"
    [disabled]="!changesMade"
    data-test="edit-description"
    type="button"
    (click)="onSubmit()"
  >{{ 'COMMON.ULOZIT' | translate}}</button>

  <button
    class="btn btn-outline-secondary btn-cancel"
    type="button"
    (click)="onCloseClick()"
    data-test="cancel-description"
  >{{ 'COMMON.ZRUSIT' | translate }}</button>
</div>
