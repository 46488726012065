import { Injector, Provider } from '@angular/core';

import { AppInsightsBaseService } from './app-insights-base.service';
import {
  AppInsightsConfig,
  AppInsightsService,
  GET_APP_INSIGHTS_CONFIG_TOKEN,
  NoopAppInsightsService
} from './app-insights.service';

/**
 * This function is used to provide the necessary dependencies for the Application Insights service.
 * It takes a function `getConfig` as an argument, which is used to retrieve the configuration for Application Insights.
 * It returns an array of providers, which includes the configuration token and the class to be used for the Application Insights service.
 *
 * @param getConfig - A function that takes an Injector and returns an AppInsightsConfig.
 * @returns An array of Providers for the Application Insights service.
 */
export function provideAppInsights(
  getConfig: (injector: Injector) => AppInsightsConfig,
): Provider[] {
  return [
    { provide: GET_APP_INSIGHTS_CONFIG_TOKEN, useValue: getConfig },
    { provide: AppInsightsBaseService, useClass: AppInsightsService },
  ];
}

/**
 * This function is used to provide the necessary dependencies for the Application Insights service when no operation is to be performed.
 * It returns an array of providers that includes the class to be used for the Application Insights service.
 *
 * @returns An array of Providers for the Application Insights service when no operation is to be performed.
 */
export function provideNoopAppInsights(): Provider[] {
  return [
    { provide: AppInsightsBaseService, useClass: NoopAppInsightsService }
  ];
}
