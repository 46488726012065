import { ArrayDataSource } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';

import { KrosModalRef } from '@kros-sk/components';
import { TimelineType } from '@kros-sk/ssw-shared-legacy';

import {
  BuildingProgressPeriodService
} from '../../../../building-progress/services/building-progress-periods/building-progress-period.service';
import { PeriodHistory } from '../../../../building-progress/models/period-history.model';

@Component({
  selector: 'app-periods-history-dialog',
  templateUrl: './periods-history-dialog.component.html',
  styleUrls: ['./periods-history-dialog.component.scss']
})
export class PeriodsHistoryDialogComponent implements OnInit {
  columnsToDisplay = ['dateChanged', 'dateFrom', 'operationType', 'email'];
  dataSource: ArrayDataSource<PeriodHistory>;
  dataLoaded = false;
  hasData = false;
  timelineType = TimelineType;

  private projectId: number;
  private contractorId: number;

  constructor(
    private modalRef: KrosModalRef,
    private periodService: BuildingProgressPeriodService
  ) {
    this.projectId = this.modalRef.data.projectId;
    this.contractorId = this.modalRef.data.contractorId;
  }

  ngOnInit(): void {
    this.periodService.getPeriodsHistory(this.projectId, this.contractorId)
      .subscribe(resp => {
        this.dataSource = new ArrayDataSource(resp);
        this.dataLoaded = true;
        this.hasData = resp.length > 0;
      });
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }
}
