import { ActivatedRoute, GuardsCheckEnd, GuardsCheckStart, NavigationEnd, Router } from '@angular/router';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { catchError, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { EMPTY, NEVER, Observable, of } from 'rxjs';
import { SubSink } from 'subsink';

import { APP_CONFIG } from '@kros-sk/app-config';
import { KrosValidators } from '@kros-sk/components';
import {
  OpenApiSubscriptionApiModel,
  OpenApiSubscriptionCreateApiModel,
  OpenApiSubscriptionFormModel,
  OpenApiSubscriptionUpdateApiModel
} from '@kros-sk/models';

import { isOnDeleteRouteInSettings, SaveButtonStateService } from '../../../services';
import { OpenApiModalService } from '../../open-api-modal.service';
import { OpenApiService } from '../../open-api.service';

const openApiScope = 'Kros.Ssw';

@Component({
  selector: 'ssw-open-api-settings-edit',
  templateUrl: './open-api-settings-edit.component.html',
  styleUrls: ['./open-api-settings-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenApiSettingsEditComponent implements OnInit, OnDestroy {
  @Input() subscription: OpenApiSubscriptionApiModel;

  subscriptionForm: FormGroup<OpenApiSubscriptionFormModel>;
  tokenGenInProgress = false;

  get openApiSwaggerUrl(): string {
    return this.appConfig.openApiSwaggerUrl;
  }

  private checkingByGuard = false;
  private subs = new SubSink();

  constructor(
    private saveButtonState: SaveButtonStateService,
    private fb: FormBuilder,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private openApiService: OpenApiService,
    private openApiModalService: OpenApiModalService,
    private activatedRoute: ActivatedRoute,
    @Inject(APP_CONFIG) private appConfig: any) { }

  ngOnInit(): void {
    this.subscriptionForm = this.fb.group({
      clientId: [this.subscription?.clientId ?? ''],
      name: [this.subscription?.name ?? '', [Validators.required]],
      webHook: [this.subscription?.webHook ?? '', [KrosValidators.validUrl]],
      webHookSecret: [this.subscription?.webHookSecret ?? '',],
      secretHint: { value: this.subscription?.secretHint ?? '', disabled: true },
    });
    this.subscribeToRouterEvents();
    this.subs.sink = this.saveButtonState.save$.subscribe(() => this.save());
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  generateToken(): void {
    if (this.subscription.clientId) {
      this.tokenGenInProgress = true;
      this.subs.sink = this.openApiModalService.showResetTokenWarningModal(this.subscriptionForm.getRawValue().secretHint).pipe(
        switchMap(modalResponse => {
          if (modalResponse) {
            return this.openApiModalService.showGeneratedToken(this.mapFormToOpenApiModel());
          }
          this.resetSaveStateAfterGenToken();
          return EMPTY;
        }),
        catchError(_ => {
          this.resetSaveStateAfterGenToken();
          return NEVER;
        }))
        .subscribe(generateResponse => {
          if (generateResponse.type === 'submit') {
            this.subscriptionForm.get('secretHint').setValue(generateResponse.data.secretHint);
          }
          this.resetSaveStateAfterGenToken();
        });
    } else {
      this.subs.sink = this.saveAndGenerateToken().subscribe();
    }
  }

  delete(): void {
    const subId = this.subscriptionForm.value.clientId;

    this.navigate(['delete'], this.router.url).then(res => {
      if (res) {
        this.resetForm();
        this.subs.sink = this.openApiModalService.showDeleteConfirmationModal()
          .pipe(switchMap(res => {
            if (res.data === 'accept') {
              return this.openApiService.deleteSubscription(subId).pipe(map(_ => true));
            }
            return of(false);
          }))
          .subscribe(res => {
            if (res) {
              this.navigate(['../../']);
            } else {
              this.navigate(['../']);
            }
          });
      }
    });
  }

  showAllValidationErrors(): void {
    this.subscriptionForm.controls.name.markAsTouched();
    this.subscriptionForm.controls.name.updateValueAndValidity();
    this.detectChanges();
  }

  handleInput(): void {
    this.saveButtonState.setIsEdited(true);
  }

  private resetForm(): void {
    if (this.subscriptionForm.pristine) {
      return;
    }

    this.subscriptionForm.setValue({
      clientId: this.subscription?.clientId,
      name: this.subscription?.name,
      webHook: this.subscription?.webHook,
      webHookSecret: this.subscription?.webHookSecret,
      secretHint: this.subscription?.secretHint,
    });

    this.subscriptionForm.markAsPristine();
    this.subscriptionForm.markAsUntouched();
    this.saveButtonState.setIsEdited(false);
    this.detectChanges();
  }

  private detectChanges(): void {
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

  private navigate(commands: string[], prevUrl?: string): Promise<boolean> {
    if (prevUrl) {
      return this.router.navigate(commands, { relativeTo: this.activatedRoute, state: { prevUrl } });
    }
    return this.router.navigate(commands, { relativeTo: this.activatedRoute });
  }

  private save(): void {
    if (this.subscriptionForm.valid) {
      if (this.subscriptionForm.value.clientId) {
        this.subs.sink = this.openApiService.updateSubscription(this.mapFormToUpdateCommand())
          .subscribe(_ => this.afterSave());
      } else {
        this.subs.sink = this.openApiService.createSubscription(this.mapFormToCreateCommand())
          .pipe(
            map(createResult => {
              if (createResult != null) {
                if (!this.checkingByGuard) {
                  this.navigate(['../', createResult.clientId]);
                }
                return true;
              }
              return false;
            })
          )
          .subscribe(_ => this.afterSave());
      }
    } else {
      this.showAllValidationErrors();
    }
  }

  private saveAndGenerateToken(): Observable<any> {
    if (this.subscriptionForm.valid) {
      this.tokenGenInProgress = true;
      return this.openApiModalService.showGeneratedToken(this.mapFormToOpenApiModel()).pipe(
        tap(createResult => {
          if (this.checkingByGuard || createResult.type !== 'submit') {
            return;
          }
          this.navigate(['../', createResult.data.clientId]);
        }),
        tap(_ => {
          this.tokenGenInProgress = false;
          this.afterSave();
        }));
    } else {
      this.showAllValidationErrors();
      return EMPTY;
    }
  }

  private resetSaveStateAfterGenToken(): void {
    this.tokenGenInProgress = false;
    this.detectChanges();
  }

  private mapFormToCreateCommand(): OpenApiSubscriptionCreateApiModel {
    return {
      name: this.subscriptionForm.value.name,
      webHook: this.subscriptionForm.value.webHook,
      webHookSecret: this.subscriptionForm.value.webHookSecret,
      scope: openApiScope,
    };
  }

  private mapFormToUpdateCommand(): OpenApiSubscriptionUpdateApiModel {
    return {
      clientId: this.subscriptionForm.value.clientId,
      name: this.subscriptionForm.value.name,
      webHook: this.subscriptionForm.value.webHook,
      webHookSecret: this.subscriptionForm.value.webHookSecret,
      scope: openApiScope,
    };
  }

  private afterSave(): void {
    this.subscriptionForm.markAsPristine();
    this.subscriptionForm.markAsUntouched();
    this.saveButtonState.setIsEdited(false);
  }

  private subscribeToRouterEvents(): void {
    this.subs.sink = this.router.events.pipe(
      startWith(new NavigationEnd(0, this.router.url, this.router.url)),
      filter(event => event instanceof NavigationEnd || event instanceof GuardsCheckStart || event instanceof GuardsCheckEnd),
    ).subscribe(event => {
      if (event instanceof NavigationEnd) {
        // check, if route contains delete segment without prevUrl in state
        // it means deeplink => remove delete segment from route
        if (isOnDeleteRouteInSettings(this.router.parseUrl(this.router.url))) {
          const { prevUrl, _ } = window.history?.state;
          if (!prevUrl) {
            // is on delete route from deeplink, remove delete segment from route
            this.navigate(['../']);
          }
        }
      } else {
        // start and end of checking form with form guard
        this.checkingByGuard = event instanceof GuardsCheckStart;
      }
    });
  }

  private mapFormToOpenApiModel(): OpenApiSubscriptionApiModel {
    return {
      clientId: this.subscriptionForm.value.clientId,
      name: this.subscriptionForm.value.name,
      webHook: this.subscriptionForm.value.webHook,
      webHookSecret: this.subscriptionForm.value.webHookSecret,
      scope: openApiScope,
      secretHint: this.subscriptionForm.getRawValue().secretHint
    };
  }
}
