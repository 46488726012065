import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Note, NoteDeleteModel, NoteSetModel } from '../models/note.model';

const gatewayApi = '/api/projects/';
const notesApi = '/buildingProgressService/budget/';
const contractorsApi = '/contractors/';

@Injectable()
export class NotesService {

  constructor(
    private http: HttpClient,
  ) { }

  getBudgetItemNotes(projectId: number, contractorId?: number): Observable<Note[]> {
    return this.http.get<Note[]>(this.getEndpointPath(projectId, 'noteList', contractorId));
  }

  setNote(projectId: number, note: NoteSetModel): Observable<any> {
    return this.http.post(this.getEndpointPath(projectId, 'noteSet', null), note);
  }

  deleteBuildingProgressNote(projectId: number, note: NoteDeleteModel): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(note)
    };

    return this.http.delete<any>(this.getEndpointPath(projectId, 'noteDelete', null), options);
  }

  private getEndpointPath(projectId: number, endpoint: string, contractorId?: number): string {
    const contractorPath = contractorId ? contractorsApi + contractorId : '';
    return environment.appUrls.titanGatewayUrl + gatewayApi + projectId + contractorPath + notesApi + endpoint;
  }
}
