import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { Address, CreateCompanyApiModel, VatPayerType } from '@kros-sk/models';

/**
 * Company info editor for create company purpose.
 * Like the CreateCompanyComponent, but more generalized and without any dependencies to upper layers.
 * It uses the same internal editor - CompanyInfoComponent.
 */
@Component({
  selector: 'kros-create-company-editor',
  templateUrl: './create-company-editor.component.html',
  styleUrls: ['./create-company-editor.component.scss']
})
export class CreateCompanyEditorComponent implements OnInit, OnDestroy {

  /**
   * Emits company entity to create. If form values are valid, it emits api model value.
   * In case of invalid form it emits null.
   */
  @Output() createCompany = new EventEmitter<CreateCompanyApiModel | null>();

  companyForm: UntypedFormGroup;
  errorMessage: string;
  showAllErrors = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.initFormGroup();
    this.errorMessage = '';
  }

  ngOnDestroy(): void {
    this.errorMessage = '';
  }

  initFormGroup(): void {
    const initialValue: CreateCompanyFormModel = {
      companyInfo: {
        address: {
          businessName: '',
          contactName: '',
          street: '',
          postCode: '',
          city: '',
          country: '',
        },
        registrationId: '',
        taxId: '',
        vatId: '',
        vatPayerType: VatPayerType.NonPayer,
      },
      registrationCourtTextTranslations: {},
    };
    this.companyForm = this.formBuilder.group(initialValue);
  }

  setRegistrationCourtText(event: { [key: string]: string }): void {
    this.companyForm.patchValue({ registrationCourtTextTranslations: event });
  }

  submit(): void {
    if (this.companyForm.valid) {
      const companyToCreate = this.mapFormModelToApiModel(this.companyForm.value as CreateCompanyFormModel);
      this.fixVatId(companyToCreate);
      this.createCompany.emit(companyToCreate);
    } else {
      this.showAllErrors = true;
      this.errorMessage = 'Nie sú vyplnené všetky povinné polia';
      this.createCompany.emit(null);
    }
  }

  private fixVatId(company: CreateCompanyApiModel): void {
    if (company.vatPayerType === VatPayerType.NonPayer) company.vatId = '';
    if ((company.vatPayerType === VatPayerType.Payer || company.vatPayerType === VatPayerType.RegisteredVatPayer7)
      && !company.vatId && company.taxId) {
      company.vatId = 'SK' + company.taxId;
    }
  }

  private mapFormModelToApiModel(company: CreateCompanyFormModel): CreateCompanyApiModel {
    return {
      name: company.companyInfo.address.businessName,
      contactName: company.companyInfo.address.contactName ?? '',
      street: company.companyInfo.address.street,
      postCode: company.companyInfo.address.postCode,
      city: company.companyInfo.address.city,
      country: company.companyInfo.address.country,
      vatPayerType: company.companyInfo.vatPayerType,
      registrationId: company.companyInfo.registrationId,
      taxId: company.companyInfo.taxId,
      vatId: company.companyInfo.vatId,
      registrationCourtTextTranslations: company.registrationCourtTextTranslations,
      phoneNumber: '',
      email: '',
      web: '',
    };
  }
}

interface CreateCompanyFormModel {
  companyInfo: CompanyInfoModel,
  registrationCourtTextTranslations: { [key: string]: string }
}

interface CompanyInfoModel {
  address: Address,
  registrationId: string,
  taxId: string,
  vatId: string,
  vatPayerType: VatPayerType,
}
