import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BoqItem } from '@kros-sk/ssw-shared-legacy';

import { environment } from '../../../environments/environment';

const gatewayApi = '/api/projects/';
const budgetApproval = '/budgetApprovalService';

@Injectable()
export class BudgetApprovalBoqService {
  private readonly boqItemsEndpoint = '/budget/boqItemList';

  constructor(
    private http: HttpClient,
  ) { }

  getBoqItems(projectId: number, budgetItemId: number): Observable<BoqItem[]> {
    return this.http.get<BoqItem[]>(`${this.getEndpointPath(projectId, this.boqItemsEndpoint)}/${budgetItemId}`);
  }

  private getEndpointPath(projectId: number, endpoint: string): string {
    return environment.appUrls.titanGatewayUrl + gatewayApi + projectId + budgetApproval + endpoint;
  }
}
