import {
  AfterContentInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  InjectionToken,
  Input,
  OnDestroy,
  QueryList,
  Renderer2,
  ViewChild,
} from '@angular/core';

import { filter, map, takeUntil } from 'rxjs/operators';
import { merge, Subject } from 'rxjs';

import { TooltipDirective, TooltipPosition } from '@kros-sk/ui/tooltip';

import { KROS_INPUT_ERROR, KrosInputError } from './kros-error.directive';
import { KrosFormFieldControl } from './kros-form-field-control';
import { KrosInputHint } from './kros-hint.directive';
import { KrosLabel } from './kros-label.directive';

let nextUniqueId = 0;

export const KROS_FORM_FIELD = new InjectionToken<KrosFormFieldComponent>('KrosFormField');

@Component({
  selector: 'kros-form-field',
  templateUrl: './kros-form-field.component.html',
  styleUrls: ['./kros-form-field.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'kros-form-field',
    '[class.kros-form-field-disabled]': 'control?.disabled',
    '[class.kros-form-field-invalid]': 'control?.errorState',
    '[class.ng-untouched]': 'control?.ngControl?.untouched || control?.ngrxControl?.state?.isUntouched',
    '[class.ng-touched]': 'control?.ngControl?.touched || control?.ngrxControl?.state?.isTouched',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: KROS_FORM_FIELD, useExisting: KrosFormFieldComponent }],
})
export class KrosFormFieldComponent implements AfterContentInit, OnDestroy {
  readonly labelId = `kros-form-field-label-${nextUniqueId++}`;

  hasLabel = false;
  hintTooltipPlacement: TooltipPosition = 'above';

  @ContentChild(KrosLabel) _labelChild: KrosLabel | undefined;
  @ContentChild(KrosFormFieldControl) control: KrosFormFieldControl<any>;
  @ContentChild(KrosInputHint) _hintChild: KrosInputHint;
  @ContentChildren(KROS_INPUT_ERROR, { descendants: true }) _errorChildren: QueryList<KrosInputError>;

  @ViewChild('tooltip', { static: false }) tooltip: TooltipDirective;
  @ViewChild('inputContainer', { static: false }) inputContainer: ElementRef<HTMLElement>;

  @Input({transform: booleanAttribute}) showErrorsWhenUntouched = false;

  private destroyed = new Subject<void>();

  constructor(
    public elementRef: ElementRef,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2
  ) {
  }

  ngAfterContentInit(): void {
    this.hasLabel = !!this._labelChild;
    this.initializeControl();
    this.initializeTooltip();
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  get inputContainerWidth(): number {
    return this.inputContainer?.nativeElement?.clientWidth || 0;
  }

  getDisplayedMessages(): 'error' | 'hint' {
    const hasErrors = this._errorChildren?.length > 0;
    const isTouched = this.control?.ngControl
      ? this.control.ngControl.touched
      : this.control?.ngrxControl
        ? this.control.ngrxControl.state.isTouched
        : false;

    if (hasErrors && (this.showErrorsWhenUntouched || isTouched)) {
      return 'error';
    }
    return 'hint';
  }

  showTooltip(): void {
    if (this.canShowTooltip()) {
      this.tooltip.show();
    }
  }

  closeTooltip(): void {
    if (this.canCloseTooltip()) {
      this.tooltip.hide();
    }
  }

  addClass(className: string): void {
    this.renderer.addClass(this.elementRef.nativeElement, className);
  }

  removeClass(className: string): void {
    this.renderer.removeClass(this.elementRef.nativeElement, className);
  }

  private canShowTooltip(): boolean {
    return !this.tooltip.isTooltipVisible() && ((this.getDisplayedMessages() === 'error') || !!this._hintChild);
  }

  private canCloseTooltip(): boolean {
    return this.tooltip.isTooltipVisible() && ((this.getDisplayedMessages() !== 'error') || !this.control.focused);
  }

  private initializeTooltip(): void {
    if (this._hintChild) {
      this.hintTooltipPlacement = this._hintChild.align;
    }

    if (!this.control) return;

    const controlChanges$ = merge(
      this._errorChildren.changes.pipe(map(resp => resp.length > 0 && this.control.focused)),
      this.control.focusChanges.pipe(filter(() => this.getDisplayedMessages() === 'error'))
    ).pipe(
      takeUntil(this.destroyed)
    );

    controlChanges$.subscribe(resp => resp ? this.showTooltip() : this.closeTooltip());
  }

  private initializeControl(): void {
    if (!this.control) return;

    const control = this.control;

    control.stateChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => this.cdRef.markForCheck());

    if (control.ngControl && control.ngControl.valueChanges) {
      control.ngControl.valueChanges
        .pipe(takeUntil(this.destroyed))
        .subscribe(() => this.cdRef.markForCheck());
    }
  }
}
