import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ApplicationType, License } from '@kros-sk/ssw-cdk';
import { ExternalApplicationModel } from '@kros-sk/ssw-shared-legacy';

export const constructionDiaryActions = createActionGroup({
  source: 'ConstructionDiary',
  events: {
    'Load license start': props<{ product: ApplicationType }>(),
    'Load license success': props<{ license: License }>(),
    'Load license error': props<{ error: any }>(),

    'Load diary list start': emptyProps(),
    'Load diary list success': props<{ constructionDiaryList: ExternalApplicationModel[] }>(),
    'Load diary list error': props<{ error: any }>(),

    'Register new diary success': props<{ constructionDiary: ExternalApplicationModel }>(),
    'Register new diary error': props<{ error: any }>(),

    'Open diary': props<{ constructionDiary: ExternalApplicationModel }>(),
  }
});
