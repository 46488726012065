import { Action, createReducer, on } from '@ngrx/store';

import { getSortedTags, updateTagInTagsList } from '@kros-sk/ssw-shared-legacy';

import * as listActions from './projects.actions';
import { initialProjectsState, ProjectsState } from './projects.state';

const reducer = createReducer(
  initialProjectsState,
  on(listActions.loadApplicationPermissionsSuccess, (state, payload): ProjectsState => {
    return {
      ...state,
      applicationPermissions: payload.applicationPermissions,
    };
  }),
  on(listActions.loadProjectDetailStart, (state): ProjectsState => {
    return {
      ...state,
      projectDetail: null,
      applicationPermissions: []
    };
  }),
  on(listActions.setProjectDetail, (state, payload): ProjectsState => {
    return {
      ...state,
      projectDetail: payload.projectDetail,
    };
  }),
  on(listActions.loadProjectDetailSuccess, (state, payload): ProjectsState => {
    return {
      ...state,
      projectDetail: payload.projectDetail
    };
  }),
  on(listActions.setIsProjectMembersTabSelected, (state, payload): ProjectsState => {
    return {
      ...state,
      isProjectMembersTabSelected: payload.value
    };
  }),
  on(listActions.loadTagsListSuccess, (state, payload): ProjectsState => {
    return {
      ...state,
      tagsList: payload.tagsList,
      newTagCreated: null
    };
  }),
  on(listActions.createNewTagSuccess, (state, payload): ProjectsState => {
    const tags = [...state.tagsList];
    tags.push(payload.tag);

    return {
      ...state,
      tagsList: getSortedTags(tags),
      newTagCreated: payload.setTagToProject ? payload.tag : state.newTagCreated
    };
  }),
  on(listActions.clearNewTagCreated, (state): ProjectsState => {
    return {
      ...state,
      newTagCreated: null
    };
  }),
  on(listActions.editTagSuccess, (state, payload): ProjectsState => {
    return {
      ...state,
      tagsList: getSortedTags(updateTagInTagsList(state.tagsList, payload.tag))
    };
  })
);

export function projectsReducer(state: ProjectsState | undefined, action: Action): ProjectsState {
  return reducer(state, action);
}
