import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '../translate';
import { VatRateType } from '../enums/vat-rate-type.enum';

@Pipe({ name: 'vatRateType' })
export class VatRateTypePipe implements PipeTransform {

  constructor(
    private translateService: TranslateService
  ) { }

  transform(vatRateType: VatRateType): string {
    switch (vatRateType) {
      case VatRateType.Basic:
        return this.translateService.translate('SETTINGS.VAT.TYPE.BASIC');
      case VatRateType.Reduced:
        return this.translateService.translate('SETTINGS.VAT.TYPE.REDUCED');
      case VatRateType.Zero:
        return this.translateService.translate('SETTINGS.VAT.TYPE.ZERO');
      case VatRateType.BasicTransferred:
        return this.translateService.translate('SETTINGS.VAT.TYPE.BASIC_TRANSFERRED');
      case VatRateType.ReducedTransferred:
        return this.translateService.translate('SETTINGS.VAT.TYPE.REDUCED_TRANSFERRED');
      default:
        return this.translateService.translate('SETTINGS.VAT.VAT');
    }
  }
}
