import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import * as listActions from './subcontractors.actions';
import { PeriodDateInterval } from '../../building-progress/models/period-date-interval.model';
import { Subcontractor } from '../../building-progress/modules/subcontractors/models';
import { SubcontractorsState } from './subcontractors.state';

@Injectable()
export class SubcontractorsDispatchersService {

  constructor(
    private store: Store<SubcontractorsState>
  ) { }

  loadSubcontractors(projectId: number, selectedContractorId?: number): void {
    this.store.dispatch(
      listActions.loadSubcontractorsStart({ projectId, selectedContractorId })
    );
  }

  selectSubcontractor(subcontractor: Subcontractor): void {
    this.store.dispatch(
      listActions.selectSubcontractor({ subcontractor })
    );
  }

  loadDialogItemsList(projectId: number): void {
    this.store.dispatch(
      listActions.loadDialogItemsListStart({ projectId })
    );
  }

  createSubcontractorItems(projectId: number, name: string, itemIds: number[]): void {
    this.store.dispatch(
      listActions.createSubcontractorItemsStart({ projectId, name, itemIds })
    );
  }

  addSubcontractorItems(projectId: number, contractorId: number, itemIds: number[]): void {
    this.store.dispatch(
      listActions.addSubcontractorItemsStart({ projectId, contractorId, itemIds })
    );
  }

  removeSubcontractorItems(projectId: number, contractorId: number, itemIds: number[]): void {
    this.store.dispatch(
      listActions.removeSubcontractorItemsStart({ projectId, contractorId, itemIds })
    );
  }

  loadSharingList(projectId: number, contractorId: number): void {
    this.store.dispatch(
      listActions.loadSharingListStart({ projectId, contractorId })
    );
  }

  loadSubcontractorsSharingList(projectId: number): void {
    this.store.dispatch(
      listActions.loadSubcontractorsSharingListStart({ projectId })
    );
  }

  loadSubcontractorsPeriods(projectId: number): void {
    this.store.dispatch(
      listActions.loadSubcontractorsPeriodsStart({ projectId })
    );
  }

  setSubcontractorsPeriods(
    projectId: number, contractorId: number, dateFrom: string, dateTo: string, areDetailsIncluded: boolean, targetPeriod: PeriodDateInterval
  ): void {
    this.store.dispatch(
      listActions.setSubcontractorsPeriodsStart({ projectId, contractorId, dateFrom, dateTo, areDetailsIncluded, targetPeriod })
    );
  }

  removeSubcontractor(projectId: number, contractorId: number, selectedContractorId: number): void {
    this.store.dispatch(
      listActions.removeSubcontractorStart({ contractorId, projectId, selectedContractorId })
    );
  }

  editSubcontractor(projectId: number, selectedContractorId: number, contractorData: Subcontractor): void {
    this.store.dispatch(
      listActions.editSubcontractorStart({ projectId, selectedContractorId, contractorData })
    );
  }
}
