<div class="kros-modal-header">
  <h4 class="kros-modal-title">{{ title | translate }}</h4>

  <button
    class="btn btn-link close"
    (click)="onCloseClick()"
  >
    <i class="material-icons">close</i>
  </button>
</div>

<div
  class="kros-modal-body"
  [class.compacted]="compacted"
>
  @if (!readonly) {
  <div class="sharing-input">
    <kros-user-sharing-input
      [defaultValues]="defaultPermissions"
      [sharingMode]="sharingMode"
      [existingEmails]="getExistingSharingEmails()"
      [warningText]="warningText"
      [dataTestPrefix]="dataTestPrefix"
      (shared)="onShared($event)"
    ></kros-user-sharing-input>
  </div>

    @if (loadedItems) {
    <div>
      <kros-user-sharing-list
        [unconfirmedSharings]="unconfirmedSharings"
        [confirmedSharings]="sharings"
        [sharingMode]="sharingMode"
        (deletedPermission)="onDeletedPermission($event)"
        (unconfirmedPermissionDeleted)="onUnconfirmedPermissionDeleted($event)"
        (changedPermission)="onChangedPermission($event)"
      ></kros-user-sharing-list>
    </div>
    } @else {
    <kros-timeline [type]="timelineType.TwoLine"></kros-timeline>
    }
  } @else {
    @if (loadedItems) {
    <kros-user-sharing-list
      [confirmedSharings]="sharings"
      [sharingMode]="sharingMode"
      [readonly]="true"
    ></kros-user-sharing-list>
    } @else {
    <kros-timeline [type]="timelineType.TwoLine"></kros-timeline>
    }
  }
</div>

<div class="kros-modal-footer">
  @if (changesMade) {
  <div
    class="buttons"
  >
    <button
      class="btn btn-primary submit-button"
      (click)="submitChanges()"
      [attr.data-test]="dataTestPrefix + '-submit'"
    >{{'COMMON.ULOZIT' | translate }}</button>

    <button
      class="btn btn-outline-secondary cancel-button"
      (click)="cancelChanges()"
    >{{ 'COMMON.ZRUSIT' | translate }}</button>
  </div>
  }

  @if (customButton  && !changesMade) {
  <ng-container *ngTemplateOutlet="customButton"></ng-container>
  }
</div>
