import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FilterOptionsIncludingPipe } from './utils/filter-options-including.pipe';
import { FilterOptionsStartingWithPipe } from './utils/filter-options-starting-with.pipe';
import {
  KROS_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER,
  KrosAutocompleteDirective } from './autocomplete-directive/kros-autocomplete.directive';
import { KrosAutocompleteComponent } from './autocomplete/kros-autocomplete.component';
import { KrosAutocompleteContentDirective } from './autocomplete-content/kros-autocomplete-content.directive';
import { KrosAutocompleteOptionComponent } from './autocomplete-option/kros-autocomplete-option.component';

@NgModule({
  declarations: [
    KrosAutocompleteOptionComponent,
    KrosAutocompleteComponent,
    KrosAutocompleteContentDirective,
    KrosAutocompleteDirective,
    FilterOptionsIncludingPipe,
    FilterOptionsStartingWithPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    KrosAutocompleteOptionComponent,
    KrosAutocompleteComponent,
    KrosAutocompleteContentDirective,
    KrosAutocompleteDirective,
    FilterOptionsIncludingPipe,
    FilterOptionsStartingWithPipe
  ],
  providers: [KROS_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER],
})
export class KrosAutocompleteModule { }
