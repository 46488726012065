import { Component } from '@angular/core';

import { AnalyticsService } from '@kros-sk/ssw-shared-legacy';
import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { KrosModalRef } from '@kros-sk/components';

import { BuildingProgressTableExportModel } from '../../../models/building-progress-export.model';
import { getAppInsightsPrefix } from '../../../shared/building-progress-utils';
import { PermissionHelper } from '../../../helpers';

@Component({
  selector: 'app-building-progress-export',
  templateUrl: './building-progress-export.component.html',
  styleUrls: ['./building-progress-export.component.scss']
})
export class BuildingProgressExportComponent {
  exportModel: BuildingProgressTableExportModel;
  showSingleSheetExportCheck: boolean;

  get isExportPerObjects(): boolean {
    return !this.exportModel.isSingleSheetExport;
  }

  get isContractor(): boolean {
    return this.permissionHelper.isContractor;
  }

  constructor(
    private modalRef: KrosModalRef,
    private analyticsService: AnalyticsService,
    private appInsightsService: AppInsightsBaseService,
    private permissionHelper: PermissionHelper
  ) {
    this.exportModel = this.modalRef.data.exportModel;
    this.showSingleSheetExportCheck = this.modalRef.data.showSingleSheetExportCheck;

    if (!this.showSingleSheetExportCheck) {
      this.exportModel.isSingleSheetExport = true;
    }
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  export(): void {
    this.sendAnalyticsNotification();
    this.modalRef.submit(this.exportModel);
  }

  private sendAnalyticsNotification(): void {
    const prefix = getAppInsightsPrefix(this.isContractor);
    this.appInsightsService.trackEvent(`${prefix}export${this.exportModel.subcontractorId ? '-contractors' : ''}`,
      { isSingleSheetExport: '' + this.exportModel.isSingleSheetExport });
    const category = 'Priebeh výstavby';

    if (!this.exportModel.isSingleSheetExport) {
      this.analyticsService.raiseEvent(category, 'Exportovať každý objekt na samostatný hárok',
        'Zaškrtnutý checkbox "Exportovať každý objekt na samostatný hárok" v okne exportu "Export tabuľky"');
    }

    this.analyticsService.raiseEvent(category, 'Exportovať tabuľku', 'Tlačidlo "Export" na okne "Export tabuľky"');
  }
}
