import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { take } from 'rxjs';

import { isEnterKey } from '@kros-sk/ssw-shared-legacy';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import {
  BuildingProgressPartialItemsDispatchersService,
  BuildingProgressPartialItemsSelectorsService
} from '../../../store/building-progress';

@Component({
  selector: 'app-building-progress-partial-item-splitter',
  templateUrl: './building-progress-partial-item-splitter.component.html',
  styleUrls: ['./building-progress-partial-item-splitter.component.scss']
})
export class BuildingProgressPartialItemSplitterComponent extends UnsubscribeComponent implements OnInit {

  @ViewChild('splitBoxLabel') splitBoxLabel: ElementRef;

  partialItemsCounter$ = this.partialItemsSelectorsService.partialItemsCounter$;
  itemsCounterPreviousValue: number;

  constructor(
    private partialItemsSelectorsService: BuildingProgressPartialItemsSelectorsService,
    private partialItemsDispatchersService: BuildingProgressPartialItemsDispatchersService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.partialItemsCounter$.pipe(take(1))
      .subscribe(value => this.itemsCounterPreviousValue = value);
  }

  initChangeSplitNumber(newNumber: number): void {
    const numberDiff = Math.abs(newNumber - this.itemsCounterPreviousValue);
    if (this.itemsCounterPreviousValue > newNumber) {
      this.partialItemsDispatchersService.removeLastPartialItems(numberDiff);
    } else {
      this.partialItemsDispatchersService.addPartialItems(numberDiff);
    }
    this.itemsCounterPreviousValue = newNumber;
  }

  onKeyDown(e: any): void {
    if (isEnterKey(e.event.originalEvent?.key)) {
      this.splitBoxLabel.nativeElement.focus();
    }
  }
}
