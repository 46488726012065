import { HammerGestureConfig } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { velocity: 0.4, threshold: 20 },
    press: { threshold: 9, time: 251 },
    pinch: { enable: false },
    rotate: { enable: false }
  } as any;
}
