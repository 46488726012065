<div class="toolbar-actions">
  <div class="actions left">
    <div class="tablet-visible">
      <button
        class="btn toolbar-action gray-toolbar-action no-selection"
        type="button"
        (click)="onBackClick()"
        krosTooltip="{{ 'CHANGE_SHEET.BACK_TO_LIST' | translate }}"
      >
        <i class="material-icons icon-button">chevron_left</i>
      </button>
    </div>

    <div class="tablet-visible">
      <button
        class="btn toolbar-action no-selection gray-toolbar-action ga-ba-tablet-show-budget-change-structure"
        type="button"
        (click)="showBudgetChangesStructure()"
        krosTooltip="{{ 'BUDGET_CHANGES.STRUCTURE' | translate }}"
        data-test="budget-changes-show-budget-change-structure"
      >
        <i class="material-icons icon-button ga-ba-tablet-show-budget-change-structure">list</i>
        <span class="toolbar-action-text">{{ 'CHANGE_SHEET.STRUCTURE' | translate }}</span>
      </button>
    </div>

    <button
      *ngIf="canOpenBoq"
      class="btn toolbar-action no-selection gray-toolbar-action ga-ba-budget-changes-open-boq"
      type="button"
      (click)="openBoq()"
      krosTooltip="{{ 'BUDGET_CHANGES.BILL_OF_QUANTITIES' | translate }}"
      data-test="budget-changes-open-boq-panel"
    >
      <i class="material-icons icon-button ga-ba-budget-changes-open-boq">square_foot</i>
      <span class="toolbar-action-text">{{ 'BUDGET_CHANGES.BILL_OF_QUANTITIES' | translate }}</span>
    </button>

    <button
      *ifBudgetApprovalFunctionsPermission
      class="btn toolbar-action no-selection gray-toolbar-action ga-ba-budget-changes-export"
      type="button"
      (click)="export()"
      krosTooltip="{{ 'BUDGET_APPROVAL.EXPORT.EXPORT' | translate }}"
      data-test="budget-changes-export"
    >
      <i class="material-icons icon-button ga-ba-budget-changes-export">save_alt</i>
      <span class="toolbar-action-text">{{ 'BUDGET_APPROVAL.EXPORT.EXPORT' | translate }}</span>
    </button>
  </div>
</div>
