export const colors = [
  '#FFFFFF',
  '#F9D58F',
  '#F4B698',
  '#EC8F9D',
  '#C8AFCF',
  '#E086F9',
  '#809CB9',
  '#80CAEC',
  '#8FD7C1'
];

export const extraColors = [
  '#FFFFFF',
  '#F9D58F',
  '#D6ECDB',
  '#D7F2FF',
  '#C8AFCF',
  '#F8D2D8',
  '#EDEDED',
  '#F4B698',
  '#8FD7C1',
  '#80CAEC',
  '#E086F9',
  '#EC8F9D',
  '#CCCCCC',
  '#FFC701',
  '#6FB132',
  '#809CB9',
  '#FF6EC7',
  '#FF6B82'
];
