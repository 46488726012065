<div class="boq-container">
  <app-boq-panel-header
    [headerText]="'BILL_OF_QUANTITIES.ITEM_BILL_OF_QUANTITIES' | translate"
    (closeHeaderClicked)="onCloseClick()"
    (resizePanelClicked)="onResizePanelClick()"
  ></app-boq-panel-header>

  <div class="boq-content">
    <div class="table-container">
      <ng-container *ngIf="!isLoading; else loading">
        <ng-container *ngIf="canShowBoqItems; else noItems">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">{{ 'BILL_OF_QUANTITIES.CALCULATION' | translate }}</th>
                <th
                  scope="col"
                  class="quantity"
                >{{ 'BILL_OF_QUANTITIES.QUANTITY' | translate }}</th>
              </tr>
            </thead>

            <tbody>
              <tr
                *ngFor="let item of items; let i = index"
                data-test="boq-row"
                [ngClass]="getCellClass(item)"
                [class.focused]="isRowFocused(i)"
              >
                <td
                  tabindex="0"
                  class="text-expression"
                  [class.focused-cell]="isCellFocused(i, 0)"
                  (click)="onCellClick(i, 0)"
                  (keydown)="onCellKeyDown($event)"
                >{{item.expression}}</td>
                <td
                  [id]="getExpressionId(i)"
                  tabindex="0"
                  class="text-right"
                  [class.bkg-color-green]="item.isActual"
                  [class.focused-cell]="isCellFocused(i, 1)"
                  (click)="onCellClick(i, 1)"
                  (keydown)="onCellKeyDown($event)"
                >
                  <ng-container *ngIf="!isNote(item)">{{ getFormattedAmount(item.quantity) }}
                  </ng-container>
                </td>
              </tr>
            </tbody>

            <tfoot>
              <tr data-test="boq-summary-row">
                <td class="text-right">{{ 'BILL_OF_QUANTITIES.ITEM_AMOUNT' | translate }}</td>
                <td class="quantity-info-value text-right">{{ getFormattedAmount(resultAmount) }}</td>
              </tr>
            </tfoot>
          </table>
        </ng-container>
      </ng-container>

      <ng-template #loading>
        <kros-timeline [type]="timelineType.TwoLine"></kros-timeline>
      </ng-template>

      <ng-template #noItems>
        <p
          class="no-items"
          data-test="no-boq-items"
        >{{ 'BILL_OF_QUANTITIES.NO_BOQ_ITEMS' | translate }}</p>
      </ng-template>
    </div>
  </div>
</div>
