<div class="kros-modal-header">
  <button
    class="btn btn-link close"
    tabindex="-1"
    (click)="onCancel()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div
  class="kros-modal-body"
  data-test="school-license-modal"
>
  <div class="img-pckg">
    <img
      [src]="'shared-assets/images/school.svg'"
      alt="school-version"
    >
  </div>

  <h4>{{ 'LICENSE_INFO.SCHOOL_VERSION' | translate }}</h4>

  <p class="h5">{{ 'LICENSE_INFO.SCHOOL_VERSION_FORBIDDEN_FEATURE' | translate }}</p>
</div>

<div class="kros-modal-footer"></div>
