import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  OnDestroy,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'kros-tooltip',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent implements OnDestroy {
  @ViewChild('tooltipRef', { static: true }) tooltipRef: ElementRef<HTMLElement>;

  tooltip: string | TemplateRef<void> | null = null;
  context: any;
  tooltipClass: string | string[] | Set<string> | {[key: string]: any};
  _isVisible = false;
  private changeDetectorRef = inject(ChangeDetectorRef);

  private showClass = 'kros-tooltip-show';
  private hideClass = 'kros-tooltip-hide';
  private showTimeoutId: ReturnType<typeof setTimeout> | undefined;
  private hideTimeoutId: ReturnType<typeof setTimeout> | undefined;

  get isVisible(): boolean {
    return this._isVisible;
  }

  get isStringContent(): boolean {
    return typeof this.tooltip === 'string';
  }

  ngOnDestroy(): void {
    this.cancelPendingAnimations();
  }

  /**
   * Shows the tooltip with an animation originating from the provided origin
   * @param delay Amount of milliseconds to the delay showing the tooltip.
   */
  show(delay: number): void {
    if (this.hideTimeoutId) {
      clearTimeout(this.hideTimeoutId);
    }

    this.showTimeoutId = setTimeout(() => {
      this.toggleVisibility(true);
      this.showTimeoutId = null;
    }, delay);
  }

  /**
   * Begins the animation to hide the tooltip after the provided delay in ms.
   * @param delay Amount of milliseconds to delay showing the tooltip.
   */
  hide(delay: number): void {
    if (this.showTimeoutId) {
      clearTimeout(this.showTimeoutId);
    }

    this.hideTimeoutId = setTimeout(() => {
      this.toggleVisibility(false);
      this.hideTimeoutId = null;
    }, delay);
  }

  markForCheck(): void {
    this.changeDetectorRef.markForCheck();
  }

  /** Cancels any pending animation sequences. */
  cancelPendingAnimations(): void {
    if (this.showTimeoutId != null) {
      clearTimeout(this.showTimeoutId);
    }

    if (this.hideTimeoutId != null) {
      clearTimeout(this.hideTimeoutId);
    }

    this.showTimeoutId = this.hideTimeoutId = undefined;
  }

  /** Toggles the visibility of the tooltip element. */
  private toggleVisibility(isVisible: boolean): void {
    // We set the classes directly here ourselves so that toggling the tooltip state
    // isn't bound by change detection. This allows us to hide it even if the
    // view ref has been detached from the CD tree.
    const tooltip = this.tooltipRef.nativeElement;
    tooltip.classList.remove(isVisible ? this.hideClass : this.showClass);
    tooltip.classList.add(isVisible ? this.showClass : this.hideClass);
    this._isVisible = isVisible;
  }
}
