import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { ApprovalHelper } from '@kros-sk/ssw-shared/utils/building-progress';
import { ApprovalOrderDispatchersService, ApprovalOrderHelper } from '@kros-sk/ssw-building-progress/approval-order';
import { areEmailsEqual, UserService } from '@kros-sk/ssw-cdk';
import { BuildingProgressPermission, PermissionType } from '@kros-sk/ssw-shared/permission';
import { SearchModel, TimelineType, VatRateType } from '@kros-sk/ssw-shared-legacy';

import { BuildingProgressActionAccessService } from '../../../services/building-progress-action-access.service';
import {
  BuildingProgressDispatchersService,
  BuildingProgressPeriodDispatchersService,
  BuildingProgressSelectorsService
} from '../../../../store/building-progress';
import { BuildingProgressInvoiceService } from '../../../../building-progress/services/building-progress-invoice.service';
import { BuildingProgressPeriod } from '../../../../building-progress/models/building-progress-period.model';
import { BuildingProgressPeriodService } from '../../../services/building-progress-periods/building-progress-period.service';
import { BuildingProgressSharing } from '../../../../building-progress/models/building-progress-sharing/building-progress-sharing.model';
import { BuildingProgressTourService } from '../../../../building-progress/services/building-progress-tour.service';
import { getAppInsightsPrefix, hasContractorRight } from '../../../../building-progress/shared/building-progress-utils';
import { PeriodHistory, PeriodOperationType } from '../../../models/period-history.model';
import { PeriodsHelperService } from '../services/periods-helper.service';

@Component({
  selector: 'app-periods',
  templateUrl: './periods.component.html',
  styleUrls: ['./periods.component.scss']
})
export class PeriodsComponent implements OnInit {

  @ViewChild('approvalOrderLink') approvalOrderLink: TemplateRef<any>;

  @Input()
  get projectId(): number {
    return this._projectId;
  }
  set projectId(value: number) {
    this._projectId = value;
    this.editMode = false;
    this.loadData();
  }
  @Input()
  get editMode(): boolean {
    return this._editMode;
  }
  set editMode(value: boolean) {
    this._editMode = value;
    this.editModeChanged.emit(this._editMode);
  }
  @Input() searchModel: SearchModel;
  @Input() permission: BuildingProgressPermission;
  @Input() readonly: boolean;
  @Input() newPeriodStart: Subject<void>;
  @Input() canOwnerContributorApprove = false;
  @Input() contractorId: number;

  @Output() editModeChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() periodCreated = new EventEmitter();
  @Output() periodDeleted = new EventEmitter();
  @Output() periodViewClicked: EventEmitter<BuildingProgressPeriod> = new EventEmitter();

  get lastPeriod(): BuildingProgressPeriod {
    return this.periods ? this.periods[0] : undefined;
  }

  get noPeriod(): boolean {
    return !this.periods || this.periods?.length === 0;
  }

  sharingList: BuildingProgressSharing[];
  periods: BuildingProgressPeriod[];
  selectedPeriod: BuildingProgressPeriod;
  selectedPeriodId: number;
  loading = false;
  timelineType = TimelineType;
  vatRates: { [key in VatRateType]?: number };
  periodHistories: PeriodHistory[];
  isMultiStageApprovalEnabled = false;

  private _projectId: number;
  private _editMode = false;
  private get isApprover(): boolean {
    if (this.sharingList) {
      const myBuildingProgressPermission = this.sharingList.find(p => areEmailsEqual(p.email, this.userService.getUserEmail()));

      return myBuildingProgressPermission && this.approvalHelper.isApprover(myBuildingProgressPermission.buildingProgressPermissionType);
    }

    return false;
  }
  private buildingProgressActionAccessService = inject(BuildingProgressActionAccessService);
  private buildingProgressDispatchersService = inject(BuildingProgressDispatchersService);
  private buildingProgressInvoiceService = inject(BuildingProgressInvoiceService);
  private buildingProgressSelectorsService = inject(BuildingProgressSelectorsService);
  private periodDispatchersService = inject(BuildingProgressPeriodDispatchersService);
  private tourService = inject(BuildingProgressTourService);
  private appInsightsService = inject(AppInsightsBaseService);
  private userService = inject(UserService);
  private periodsHelperService = inject(PeriodsHelperService);
  private approvalOrderDispatcherService = inject(ApprovalOrderDispatchersService);
  private approvalHelper = inject(ApprovalHelper);
  private approvalOrderHelper = inject(ApprovalOrderHelper);
  private destroyRef = inject(DestroyRef);
  private isMultiStageApprovalMode = false;
  private periodService = inject(BuildingProgressPeriodService);

  ngOnInit(): void {
    this.buildingProgressSelectorsService.periods$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(periods => {
        this.periods = periods;
        this.editMode = false;
        this.selectPeriodAndScroll();
      });
    this.buildingProgressSelectorsService.sharingList$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(sharingList => this.sharingList = sharingList);
    this.buildingProgressSelectorsService.periodId$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(id => {
        if (id) {
          this.selectedPeriodId = id;
          this.selectedPeriod = this.periods.find(p => p.id === id);
          this.scrollToSelectedPeriodId();
        }
      });
    this.buildingProgressSelectorsService.periodsLoading$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(loading => this.loading = loading);
    this.newPeriodStart.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.onNewPeriod());
    this.buildingProgressSelectorsService.settings$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(s => {
      this.vatRates = s?.vatRates;
      this.isMultiStageApprovalMode = s?.isMultiStageApprovalModeEnabled;
    });
    this.buildingProgressSelectorsService.settings$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(s => this.vatRates = s?.vatRates);
    this.periodService.getPeriodsHistory(this.projectId, this.contractorId).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(histories => this.periodHistories = histories);
    this.loadData();
  }

  private loadData(): void {
    this.buildingProgressDispatchersService.loadSharingList(this.projectId);
  }

  private selectPeriodAndScroll(): void {
    if (this.selectedPeriodId && this.periods) {
      this.onSelectPeriod(this.periods.find(p => p.id === this.selectedPeriodId));
      this.scrollToSelectedPeriodId();
    }
  }

  onLockClick(): void {
    if (this.buildingProgressActionAccessService.checkForFreeOwner()) {
      this.periodsHelperService.lockPeriod(this.projectId, this.selectedPeriod.id);
    }
  }

  onUnlockClick(): void {
    if (this.buildingProgressActionAccessService.checkForFreeOwner()) {
      this.periodsHelperService.unlockPeriod(this.selectedPeriod.id, this.projectId, this.searchModel);
    }
  }

  onPeriodAction(): void {
    if (!hasContractorRight(this.permission.buildingProgressPermissionType) &&
      !this.canApprove(this.selectedPeriod) &&
      !this.sharingList.find(p => this.approvalHelper.isApprover(p.buildingProgressPermissionType))
    ) {
      this.periodsHelperService.showPeriodActionWarning(this.permission);
    } else if (this.canApprove(this.selectedPeriod)) {
      if (this.isMultiStageApprovalMode) {
        this.approvalOrderDispatcherService.loadApprovalOrder(this.projectId);
      }
      this.periodsHelperService.approvePeriodWithQuestion(
        this.projectId,
        this.selectedPeriod.id,
        this.isMultiStageApprovalMode,
        this.userService.userId,
        this.contractorId);
    } else {
      this.periodsHelperService.requestPeriodApprovalWithQuestion(
        this.projectId,
        this.selectedPeriod.id,
        this.isMultiStageApprovalMode,
        this.approvalOrderLink);
    }
  }

  onCancelApproved(period: BuildingProgressPeriod): void {
    this.periodsHelperService.cancelApproved(period, this.projectId, this.searchModel, this.isMultiStageApprovalMode, this.contractorId);
  }

  onCancelIsApprovalRequested(): void {
    this.periodsHelperService.cancelIsApprovalRequested(
      this.selectedPeriod.id, this.projectId, this.searchModel, this.isMultiStageApprovalMode, this.contractorId
    );
  }

  onSelectPeriod(period: BuildingProgressPeriod): void {
    if (period) {
      this.selectedPeriod = period;
      this.periodDispatchersService.selectPeriodId(period.id);
    }
  }

  canShowPeriodActionButton(period: BuildingProgressPeriod): boolean {
    return !this.readonly &&
      this.selectedPeriod?.id === period.id &&
      !period.isApproved &&
      ((this.isApprover && period.isApprovalRequested) ||
        this.permission.permissionType === PermissionType.Contributor ||
        this.permission.permissionType === PermissionType.Owner ||
        hasContractorRight(this.permission.buildingProgressPermissionType));
  }

  onNewPeriod(): void {
    this.editMode = true;
    this.selectedPeriod = undefined;
    this.tourService.hide();
  }

  onCloseEditMode(): void {
    this.editMode = false;
  }

  onSave(editPeriod: { from: Date, to: Date, basicVatRate: number, reducedVatRate: number }): void {
    this.periodsHelperService.save(
      this.selectedPeriod,
      this.projectId,
      editPeriod.from,
      editPeriod.to,
      editPeriod.basicVatRate,
      editPeriod.reducedVatRate,
      this.searchModel,
      this.periodCreated,
      this.noPeriod
    );
  }

  private scrollToSelectedPeriodId(): void {
    const selector = '#period-' + this.selectedPeriodId;

    setTimeout(() => {
      const element = document.querySelector(selector);

      if (element) {
        this.selectedPeriodId = undefined;
        element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }
    }, 200);
  }

  canShowMenu(period: BuildingProgressPeriod): boolean {
    return this.canEdit(period) && this.selectedPeriod?.id === period.id;
  }

  canEdit(period: BuildingProgressPeriod): boolean {
    return !this.readonly &&
      this.permission &&
      !period.isLocked &&
      !period.isApproved &&
      !period.isApprovalRequested &&
      (this.permission.permissionType === PermissionType.Contributor ||
        this.permission.permissionType === PermissionType.Owner ||
        hasContractorRight(this.permission.buildingProgressPermissionType));
  }

  canSend(period: BuildingProgressPeriod): boolean {
    return !period.isApprovalRequested && this.canShowPeriodActionButton(period);
  }

  canLock(period: BuildingProgressPeriod): boolean {
    return this.permission?.permissionType === PermissionType.Owner &&
      !period.isLocked &&
      !period.isApprovalRequested &&
      this.canShowPeriodActionButton(period);
  }

  canUnlock(period: BuildingProgressPeriod): boolean {
    return this.permission?.permissionType === PermissionType.Owner &&
      period.isLocked &&
      !period.isApprovalRequested &&
      this.canShowPeriodActionButton(period);
  }

  canApprove(period: BuildingProgressPeriod): boolean {
    return (this.isApprover ||
      (this.canOwnerContributorApprove && this.permission && (
        this.permission.permissionType === PermissionType.Contributor ||
        this.permission.permissionType === PermissionType.Owner))
    ) && period.isApprovalRequested && this.canShowPeriodActionButton(period) && period.canApprove;
  }

  onEdit(): void {
    setTimeout(() => this.editMode = true);
  }

  canCancelApproved(period: BuildingProgressPeriod): boolean {
    return !this.readonly &&
      (this.isApprover ||
        (this.canOwnerContributorApprove && this.permission && (
          this.permission.permissionType === PermissionType.Contributor ||
          this.permission.permissionType === PermissionType.Owner))) &&
      (period.canCancelApprove || period.isApproved || (period.isApprovalRequested && this.checkApprovalHistory(period.id))) &&
      this.selectedPeriod?.id === period.id;
  }

  private checkApprovalHistory(periodId: number): boolean {
    if (this.periodHistories) {
      for (const periodHistory of this.periodHistories) {
        if (periodHistory.periodId === periodId) {
          if (periodHistory.operationType !== PeriodOperationType.PartiallyApproved) {
            return false;
          }
          if (periodHistory.userId === this.userService.userId) {
            return true;
          }
        }
      }
    }
    return false;
  }

  onDelete(period: BuildingProgressPeriod): void {
    this.buildingProgressInvoiceService.getInvoices(this.projectId, period.id).pipe(take(1)).subscribe(invoices => {
      const hasInvoices = invoices.length > 0;
      if (period.hasInvoices !== hasInvoices) {
        this.periodDispatchersService.setHasPeriodInvoices([period.id], hasInvoices);
        period = { ...period, hasInvoices };
      }

      this.periodsHelperService.delete(period).afterClosed$
        .pipe(take(1))
        .subscribe(result => {
          if (result.type === 'submit') {
            this.periodDispatchersService.deletePeriod(
              period.id, result.data.deletePeriodGallery, this.projectId, this.searchModel
            );
            this.selectedPeriod = undefined;
            this.periodDeleted.emit();
            this.appInsightsService.trackEvent(
              getAppInsightsPrefix(
                hasContractorRight(this.permission.buildingProgressPermissionType)
              ) + 'period-delete'
            );
          }
        });
    });
  }

  protected onApprovalOrder(): void {
    if (this.buildingProgressActionAccessService.checkForAccessCauseOfReadonlyOrFreeLicense()) {
      this.appInsightsService.trackEvent('PV-approval-order-open-dialog');
      this.approvalOrderHelper.openApprovalOrderDialog(this.projectId, this.permission.permissionType !== PermissionType.Owner);
    }
  }
}
