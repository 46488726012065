import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

import { formatNumber, parseNumericValue, roundNumber } from '../../tools';

@Directive({
  selector: '[krosNumbersOnly]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: NumbersOnlyDirective,
      multi: true
    }
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[attr.type]': '"text"',
    '[attr.inputmode]': '"decimal"'
  }
})
export class NumbersOnlyDirective implements ControlValueAccessor {
  readonly allowedDelimiters = [',', '.']; // when adding to allowedDelimiters you need to add char to regExpression too
  readonly allowedChars = ['-']; // when adding to allowedChars you need to add char to regExpression too

  private regExpression = /[0-9,.\-]/gi;
  private regExp = new RegExp(this.regExpression, '');

  @Input() digitsGrouping = true;
  @Input() minFractionDigits = 2;
  @Input() maxFractionDigits = 6;
  @Input() minValue: number = null;
  @Input() maxValue: number = null;
  @Input() hideIfNull = false;

  constructor(
    private el: ElementRef,
    private platform: Platform
  ) { }


  @HostListener('keypress', ['$event'])
  onKeyPress(event: any): boolean {
    return this.isCharacterAllowed(event.key) && this.regExp.test(event.key);
  }

  @HostListener('input', ['$event.target.value'])
  onInput(_: any): void {}

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: any): void {
    if ((value === '' || value === null) && this.hideIfNull) {
      return;
    }
    const numberValue = this.checkNumberMinMax(this.parseNumericValue(value.toString()));
    const newValue = roundNumber(
      numberValue,
      this.maxFractionDigits,
    );

    this.reformatValue(newValue);
  }

  @HostListener('focus')
  onFocus(): void {
    this.el.nativeElement.value = this.el.nativeElement.value.replace(/\s/g, '');
    this.el.nativeElement.select();
  }

  @HostListener('mouseup', ['$event'])
  onmouseup(event: MouseEvent): void {
    // we prevent default behavior for the mouseup event on Safari, because it cancels the text selection
    // made in the focus event
    if (this.platform.SAFARI) event.preventDefault();
  }

  writeValue(value: any): void {
    if ((value === '' || value === null) && this.hideIfNull) {
      this.reformatValue(value);
      return;
    }
    const numberValue = this.checkNumberMinMax(this.parseNumericValue(value?.toString()));
    const newValue = roundNumber(numberValue, this.maxFractionDigits);
    this.reformatValue(newValue);
  }

  registerOnChange(fn: any): void {
    this.onInput = (value: string): void => {
      if ((value === '' || value === null) && this.hideIfNull) {
        fn(null);
        return;
      }
      const numberValue = this.checkNumberMinMax(this.parseNumericValue(value?.toString()));
      const newValue = roundNumber(numberValue, this.maxFractionDigits);
      fn(newValue);
    };
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    this.el.nativeElement.disabled = isDisabled;
  }

  private checkNumberMinMax(value: number): number {
    let newValue = value;
    if (this.minValue || this.minValue === 0) {
      if (this.minValue >= 0 && newValue < 0) {
        newValue = -newValue;
      }
      newValue = Math.max(newValue, this.minValue);
    }
    if (this.maxValue || this.maxValue === 0) {
      newValue = Math.min(newValue, this.maxValue);
    }
    return newValue;
  }

  private isCharacterAllowed(eventKey: string): boolean {
    const elementValue = this.el.nativeElement.value.toString();

    if (this.allowedDelimiters.includes(eventKey)) {
      if (elementValue.includes(',') || elementValue.includes('.')) {
        return false;
      }
    }

    if (this.allowedChars.includes(eventKey) && elementValue.includes(eventKey)) {
      return false;
    }

    return true;
  }

  private parseNumericValue(value: string): number {
    if (!/^-?[0-9\s]*,?[0-9\s]*$/i.test(value)) {
      if (!value) {
        value = '0';
      } else {
        const isMinus = value.startsWith('-', 0);
        value = value.replace(/\./gi, ',').replace(/[^0-9,\s]/gi, '');
        if (isMinus) {
          value = '-' + value;
        }
        if (!value) { // only text was entered
          value = '0';
        }
      }
    }
    return parseNumericValue(value);
  }

  private reformatValue(value: number): void {
    if ((value?.toString() === '' || value === null ) && this.hideIfNull) {
      this.el.nativeElement.value = null;
      return;
    }

    this.el.nativeElement.value = formatNumber(
      value,
      this.digitsGrouping,
      this.minFractionDigits,
      this.maxFractionDigits,
    );
  }
}
