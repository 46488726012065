import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoaderComponent } from './components-loader/loader.component';
import { SpinLoaderComponent } from './spin-loader/spin-loader.component';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    LoaderComponent,
    SpinLoaderComponent
  ],
  exports: [
    LoaderComponent,
    SpinLoaderComponent
  ]
})
export class LoaderModule { }
