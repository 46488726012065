import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

import { filter, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { Maintenance } from '@kros-sk/models';

@Injectable()
export class MaintenanceService {

  public readonly maintenanceEndpoint = '/api/maintenance';

  constructor(
    private swUpdate: SwUpdate,
    private http: HttpClient,
    private appInsights: AppInsightsBaseService,
    @Inject(APP_CONFIG) private appConfig: any
  ) { }

  getMaintenanceFromServer(): Observable<Maintenance> {
    return this.http.get<Maintenance>(`${this.appConfig.gatewayApiUrl}${this.maintenanceEndpoint}`)
      .pipe(map(p =>
        p ? {
          ...p,
          dateFrom: new Date(p.dateFrom),
          dateTo: new Date(p.dateTo)
        } as Maintenance : null
      ));
  }

  checkForOldVersion(): Observable<boolean> {
    if (this.swUpdate.isEnabled) {
      const startChecking = performance.now();
      const ret = this.swUpdate.versionUpdates.pipe(
        filter(event => event.type === 'VERSION_READY'),
        switchMap(() => this.swUpdate.activateUpdate()),
        map(() => {
          const checkingTime = performance.now() - startChecking;
          this.appInsights.trackEvent('oldVersionChecked', { checkingTime: `${checkingTime}` });
          if (checkingTime < 2000) {
            document.location.reload();
            return false;
          } else {
            return true;
          }
        })
      );
      this.swUpdate.checkForUpdate();

      return ret;
    }

    return of(false);
  }
}
