import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { KrosModalRef } from '@kros-sk/components';

import { CompletedWorksExportTypeEnum, CompletedWorksSettingsModel } from '../../../models/building-progress-export.model';
import { ExportColumnSelection } from '../export-colums-selection/export-column-selection.model';

@Component({
  selector: 'app-building-progress-export-completed-works',
  templateUrl: './building-progress-export-completed-works.component.html',
  styleUrls: ['./building-progress-export-completed-works.component.scss']
})
export class BuildingProgressExportCompletedWorksComponent implements OnInit {
  completedWorksSettings: CompletedWorksSettingsModel;
  isCompletedWorksObjectsDisabled = false;
  settingsForm: FormGroup;
  columns: ExportColumnSelection[];

  constructor(
    private formBuilder: FormBuilder,
    private modalRef: KrosModalRef
  ) {
    this.completedWorksSettings = this.modalRef.data.completedWorksSettings;
    this.isCompletedWorksObjectsDisabled = this.modalRef.data.isCompletedWorksObjectsDisabled;
  }

  ngOnInit(): void {
    this.initializeSettingsForm();
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  onSubmit(): void {
    this.copySettingsFromControl();
    this.modalRef.submit({ completedWorksSettings: this.completedWorksSettings });
  }

  private initializeSettingsForm(): void {
    this.settingsForm = this.formBuilder.group({
      areCompletedWorksZeroItemsIncluded: [this.completedWorksSettings.areZeroItemsIncluded],
      isBoqIncluded: [this.completedWorksSettings.isBoqIncluded],
      areDetailsIncluded: [this.completedWorksSettings.areDetailsIncluded],
      areNotesIncluded: [this.completedWorksSettings.areNotesIncluded],
      isCompletedWorksObjects: [{
        value: this.completedWorksSettings.completedWorksExportType === CompletedWorksExportTypeEnum.BuildingObjects,
        disabled: this.isCompletedWorksObjectsDisabled
      }],
      areColorCodesIncluded: [this.completedWorksSettings.areColorCodesIncluded],
      displayAllDecimalPlaces: [this.completedWorksSettings.displayAllDecimalPlaces],
    });

    this.columns = [
      {
        id: 'isTotalPriceIncluded',
        title: 'BUILDING_PROGRESS.EXPORT_COLUMNS_TOTAL_PRICE',
        dataTest: 'is-total-price-included-checkbox',
        checked: this.completedWorksSettings.isTotalPriceIncluded
      },
      {
        id: 'period',
        title: 'BUILDING_PROGRESS.PERIODS.PERIOD',
        dataTest: 'period',
        children: [
          {
            id: 'isPeriodPercentageIncluded',
            title: '%',
            dataTest: 'is-period-percentage-included-checkbox',
            checked: this.completedWorksSettings.isPeriodPercentageIncluded
          },
          {
            id: 'isPeriodWeightIncluded',
            title: 'BUDGET.TABLE.WEIGHT',
            dataTest: 'is-period-weight-included-checkbox',
            checked: this.completedWorksSettings.isPeriodWeightIncluded
          },
          {
            id: 'isPeriodRubbleIncluded',
            title: 'BUDGET.TABLE.RUBBLE',
            dataTest: 'is-period-rubble-included-checkbox',
            checked: this.completedWorksSettings.isPeriodRubbleIncluded
          }
        ]
      },
      {
        id: 'partlyCompleted',
        title: 'BUILDING_PROGRESS.TABLE.COMPLETED_UNTIL',
        dataTest: 'partly-completed',
        children: [
          {
            id: 'isPartlyCompletedPercentageIncluded',
            title: '%',
            dataTest: 'is-partly-completed-percentage-included-checkbox',
            checked: this.completedWorksSettings.isPartlyCompletedPercentageIncluded
          },
          {
            id: 'isPartlyCompletedAmountIncluded',
            title: 'BUILDING_PROGRESS.TABLE.AMOUNT',
            dataTest: 'is-partly-completed-amount-included-checkbox',
            checked: this.completedWorksSettings.isPartlyCompletedAmountIncluded
          },
          {
            id: 'isPartlyCompletedTotalPriceIncluded',
            title: 'BUILDING_PROGRESS.TABLE.PRICE',
            dataTest: 'is-partly-completed-total-price-included-checkbox',
            checked: this.completedWorksSettings.isPartlyCompletedTotalPriceIncluded
          }
        ]
      },
      {
        id: 'completed',
        title: 'BUILDING_PROGRESS.TABLE.UNTIL_NOW_BUILT',
        dataTest: 'completed',
        children: [
          {
            id: 'isCompletedPercentageIncluded',
            title: '%',
            dataTest: 'is-completed-percentage-included-checkbox',
            checked: this.completedWorksSettings.isCompletedPercentageIncluded
          },
          {
            id: 'isCompletedAmountIncluded',
            title: 'BUILDING_PROGRESS.TABLE.AMOUNT',
            dataTest: 'is-completed-amount-included-checkbox',
            checked: this.completedWorksSettings.isCompletedAmountIncluded
          },
          {
            id: 'isCompletedTotalPriceIncluded',
            title: 'BUILDING_PROGRESS.TABLE.PRICE',
            dataTest: 'is-completed-total-price-included-checkbox',
            checked: this.completedWorksSettings.isCompletedTotalPriceIncluded
          }
        ]
      },
      {
        id: 'rest',
        title: 'BUILDING_PROGRESS.TABLE.REST',
        dataTest: 'rest',
        children: [
          {
            id: 'isRestPercentageIncluded',
            title: '%',
            dataTest: 'is-rest-percentage-included-checkbox',
            checked: this.completedWorksSettings.isRestPercentageIncluded
          },
          {
            id: 'isRestAmountIncluded',
            title: 'BUILDING_PROGRESS.TABLE.AMOUNT',
            dataTest: 'is-rest-amount-included-checkbox',
            checked: this.completedWorksSettings.isRestAmountIncluded
          },
          {
            id: 'isRestTotalPriceIncluded',
            title: 'BUILDING_PROGRESS.TABLE.PRICE',
            dataTest: 'is-rest-total-price-included-checkbox',
            checked: this.completedWorksSettings.isRestTotalPriceIncluded
          }
        ]
      },
      {
        id: 'additional',
        title: 'BUILDING_PROGRESS.TABLE.OVERDRAWN',
        dataTest: 'additional',
        children: [
          {
            id: 'isAdditionalPercentageIncluded',
            title: '%',
            dataTest: 'is-additional-percentage-included-checkbox',
            checked: this.completedWorksSettings.isAdditionalPercentageIncluded
          },
          {
            id: 'isAdditionalAmountIncluded',
            title: 'BUILDING_PROGRESS.TABLE.AMOUNT',
            dataTest: 'is-additional-amount-included-checkbox',
            checked: this.completedWorksSettings.isAdditionalAmountIncluded
          },
          {
            id: 'isAdditionalTotalPriceIncluded',
            title: 'BUILDING_PROGRESS.TABLE.PRICE',
            dataTest: 'is-additional-total-price-included-checkbox',
            checked: this.completedWorksSettings.isAdditionalTotalPriceIncluded
          }
        ]
      },
      {
        id: 'notCompleted',
        title: 'BUILDING_PROGRESS.TABLE.NOT_COMPLETED',
        dataTest: 'not-completed',
        children: [
          {
            id: 'isNotCompletedPercentageIncluded',
            title: '%',
            dataTest: 'is-not-completed-percentage-included-checkbox',
            checked: this.completedWorksSettings.isNotCompletedPercentageIncluded
          },
          {
            id: 'isNotCompletedAmountIncluded',
            title: 'BUILDING_PROGRESS.TABLE.AMOUNT',
            dataTest: 'is-not-completed-amount-included-checkbox',
            checked: this.completedWorksSettings.isNotCompletedAmountIncluded
          },
          {
            id: 'isNotCompletedTotalPriceIncluded',
            title: 'BUILDING_PROGRESS.TABLE.PRICE',
            dataTest: 'is-not-completed-total-price-included-checkbox',
            checked: this.completedWorksSettings.isNotCompletedTotalPriceIncluded
          }
        ]
      }
    ];
  }

  private copySettingsFromControl(): void {
    const formControls = this.settingsForm.controls;
    this.completedWorksSettings.completedWorksExportType = formControls.isCompletedWorksObjects.value
      ? CompletedWorksExportTypeEnum.BuildingObjects
      : CompletedWorksExportTypeEnum.Summary;
    this.completedWorksSettings.isBoqIncluded = formControls.isBoqIncluded.value;
    this.completedWorksSettings.areDetailsIncluded = formControls.areDetailsIncluded.value;
    this.completedWorksSettings.areNotesIncluded = formControls.areNotesIncluded.value;
    this.completedWorksSettings.areZeroItemsIncluded = formControls.areCompletedWorksZeroItemsIncluded.value;
    this.completedWorksSettings.areColorCodesIncluded = formControls.areColorCodesIncluded.value;
    this.completedWorksSettings.displayAllDecimalPlaces = formControls.displayAllDecimalPlaces.value;
    this.completedWorksSettings.isCompletedPercentageIncluded = formControls.isCompletedPercentageIncluded.value;
    this.completedWorksSettings.isCompletedAmountIncluded = formControls.isCompletedAmountIncluded.value;
    this.completedWorksSettings.isCompletedTotalPriceIncluded = formControls.isCompletedTotalPriceIncluded.value;
    this.completedWorksSettings.isTotalPriceIncluded = formControls.isTotalPriceIncluded.value;
    this.completedWorksSettings.isPeriodPercentageIncluded = formControls.isPeriodPercentageIncluded.value;
    this.completedWorksSettings.isPeriodWeightIncluded = formControls.isPeriodWeightIncluded.value;
    this.completedWorksSettings.isPeriodRubbleIncluded = formControls.isPeriodRubbleIncluded.value;
    this.completedWorksSettings.isPartlyCompletedPercentageIncluded = formControls.isPartlyCompletedPercentageIncluded.value;
    this.completedWorksSettings.isPartlyCompletedAmountIncluded = formControls.isPartlyCompletedAmountIncluded.value;
    this.completedWorksSettings.isPartlyCompletedTotalPriceIncluded =
      formControls.isPartlyCompletedTotalPriceIncluded.value;
    this.completedWorksSettings.isRestPercentageIncluded = formControls.isRestPercentageIncluded.value;
    this.completedWorksSettings.isRestAmountIncluded = formControls.isRestAmountIncluded.value;
    this.completedWorksSettings.isRestTotalPriceIncluded = formControls.isRestTotalPriceIncluded.value;
    this.completedWorksSettings.isAdditionalPercentageIncluded = formControls.isAdditionalPercentageIncluded.value;
    this.completedWorksSettings.isAdditionalAmountIncluded = formControls.isAdditionalAmountIncluded.value;
    this.completedWorksSettings.isAdditionalTotalPriceIncluded = formControls.isAdditionalTotalPriceIncluded.value;
    this.completedWorksSettings.isNotCompletedPercentageIncluded = formControls.isNotCompletedPercentageIncluded.value;
    this.completedWorksSettings.isNotCompletedAmountIncluded = formControls.isNotCompletedAmountIncluded.value;
    this.completedWorksSettings.isNotCompletedTotalPriceIncluded = formControls.isNotCompletedTotalPriceIncluded.value;
  }
}
