import produce from 'immer';

import { BuildingProgressModel } from '../../building-progress/models/construction-data.model';

export function loadNewConstructionDataHasNotes(
  constructionData: BuildingProgressModel,
  notesBudgetItemIds: number[]
): BuildingProgressModel {
  return produce(constructionData, newData => {
    notesBudgetItemIds.forEach(id => {
      const index = newData.items.findIndex(item => item.id === id);
      if (index !== -1) {
        newData.items[index].hasNote = true;
      }
    });
  });
}

export function loadNewConstructionDataWithEditedNote(
  constructionData: BuildingProgressModel,
  id: number,
  isNew: boolean
): BuildingProgressModel {
  return produce(constructionData, newData => {
    const index = newData.items.findIndex(item => item.id === id);
    if (index !== -1) {
      newData.items[index].hasNote = isNew;
    }
  });
}
