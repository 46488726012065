import { inject, Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { FEATURE_FLAGS_SERVICE } from '../types/feature-flags.types';
import { featureFlagsActions } from './feature-flags.actions';

@Injectable()
export class FeatureFlagsEffects {

  private readonly actions$ = inject(Actions);
  private readonly featuresService = inject(FEATURE_FLAGS_SERVICE);

  loadFeatures$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(featureFlagsActions.loadFeatures),
      switchMap(() => this.featuresService.loadFeatureFlags().pipe(
        map(features => featureFlagsActions.loadFeaturesSuccess({features})),
        catchError(err => of(featureFlagsActions.loadFeaturesFail({err}))),
      )),
    );
  });
}
