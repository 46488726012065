import { Component } from '@angular/core';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { KrosModalService } from '@kros-sk/components';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { BuildingProgressDeleteItemsService } from '../../../building-progress/services/building-progress-delete-items.service';
import { BuildingProgressDispatchersService } from '../../../store/building-progress';
import { BuildingProgressEditingService } from '../../../building-progress/services/building-progress-editing.service';
import {
  BuildingProgressPanelHelper,
  DetailHelper,
  ExportHelper,
  InvoiceHelper,
  PermissionHelper
} from '../../../building-progress/helpers';
import {
  BuildingProgressPercentageCompleteService
} from '../../../building-progress/services/building-progress-percentage-complete.service';
import { BuildingProgressSharingDialogComponent } from '../building-progress-sharing-dialog/building-progress-sharing-dialog.component';
import { ColorCodesService } from '../../../building-progress/services/color-codes.service';
import { getAppInsightsPrefix } from '../../../building-progress/shared/building-progress-utils';
import { PhotoGalleryOpenerService } from '../../../building-progress/services/photo-gallery-opener.service';

@Component({
  selector: 'app-building-progress-detail-toolbar-container',
  templateUrl: './building-progress-detail-toolbar-container.component.html',
  styleUrls: ['./building-progress-detail-toolbar-container.component.scss']
})
export class BuildingProgressDetailToolbarContainerComponent extends UnsubscribeComponent {

  get canComplete(): boolean {
    return this.buildingProgressPercentageCompleteService.canComplete(this.detail.constructionData);
  }

  get canExport(): boolean {
    return !this.detail.isEmptyContent &&
      (!this.permissionHelper.isReader || this.permissionHelper.isContractor || this.permissionHelper.isExporter);
  }

  get canExportTable(): boolean {
    return this.detail.areConstructionDataLoaded ? this.detail.itemCount !== 0 &&
      (!this.permissionHelper.isReader || this.permissionHelper.isContractor || this.permissionHelper.isExporter) : false;
  }

  get canOpenBoq(): boolean {
    return !this.detail.isEmptyContent && this.detail.constructionData && !!this.detail.focusedCell;
  }

  get itemIdsForExportTable(): number[] {
    return this.detail.isViewOrSearchActive && this.detail.isConstructionData
      ? this.detail.constructionData.items.map(i => i.id)
      : [];
  }

  constructor(
    public detail: DetailHelper,
    public panelHelper: BuildingProgressPanelHelper,
    public exportHelper: ExportHelper,
    public invoiceHelper: InvoiceHelper,
    private appInsightsService: AppInsightsBaseService,
    private buildingProgressPercentageCompleteService: BuildingProgressPercentageCompleteService,
    private buildingProgressDispatchersService: BuildingProgressDispatchersService,
    private buildingProgressEditingService: BuildingProgressEditingService,
    private deleteItemsService: BuildingProgressDeleteItemsService,
    private permissionHelper: PermissionHelper,
    private krosModalService: KrosModalService,
    private colorCodesService: ColorCodesService,
    private galleryOpenerService: PhotoGalleryOpenerService
  ) {
    super();
  }

  onShareBuildingProgress(): void {
    const modalRef = this.krosModalService.openCentered(
      BuildingProgressSharingDialogComponent,
      { projectId: this.detail.projectId },
      { closeOnBackdropClick: false }
    );

    this.subs.sink = modalRef.afterClosed$
      .subscribe(result => {
        if (result.data) {
          this.buildingProgressDispatchersService.loadSharingList(this.detail.projectId);
        }
      });
  }

  onOpenColorCodes(toolbarElement: HTMLElement): void {
    const selectedItems: number[] = this.colorCodesService.getItemIdsForColorSet(
      this.detail.selectedItemIds, this.detail.constructionData.items[this.detail.focusedCell.rowIndex].id);
    this.colorCodesService.openColorCodesDialog(
      toolbarElement, selectedItems, this.detail.constructionData, this.detail.selectedItemIds.size > 0);
  }

  onOpenNotes(): void {
    this.panelHelper.openNotes(
      this.detail.constructionData.projectId,
      this.detail.constructionData.items[this.detail.focusedCell.rowIndex]);
  }

  onDashboardClick(): void {
    this.detail.currentNavigation = 'dashboard';
    this.panelHelper.onCloseBoq();
  }

  onTotalPriceSet(): void {
    if (this.detail.checkLastPeriodStateIsUnset()) {
      this.buildingProgressEditingService.openTotalPriceSetDialog(this.detail.constructionData, this.detail.projectId);
    }
  }

  onDelete(): void {
    this.detail.setRefocusItemModel();
    this.deleteItemsService.onDeleteBuildingProgress(this.detail.constructionData, this.detail.focusedCell);
    this.appInsightsService.trackEvent('PV-delete-item');
  }

  onOpenGalleryClick(): void {
    this.galleryOpenerService.openPhotoGallery(
      this.detail.projectId,
      this.detail.constructionData?.periods?.length > 0,
      this.panelHelper.selectedPeriodId);
  }

  onOpenBoqClick(): void {
    this.panelHelper.openBoq(this.detail.projectId, this.detail.constructionData.items[this.detail.focusedCell.rowIndex]);
  }

  onSetSelectedItemsNotCompleted(state: boolean): void {
    const constructionData = this.detail.constructionData;
    this.detail.setRefocusItemModel();
    const areSelected = this.detail.selectedItemIds.size > 0;
    const item = constructionData.items[this.detail.focusedCell.rowIndex];
    const budgetItemIds = areSelected
      ? [...this.detail.selectedItemIds] : this.deleteItemsService.getItemWithChildrenIds(item, constructionData.items);
    this.buildingProgressDispatchersService.setItemNotCompleted(this.detail.projectId, [...budgetItemIds], state);
    this.appInsightsService.trackEvent(
      getAppInsightsPrefix(this.permissionHelper.isContractor) + (state ? 'notcompleted-set' : 'notcompleted-unset')
    );
  }
}
