import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { BuildingProgressPeriod } from '../../../../building-progress/models/building-progress-period.model';
import { PeriodDatePipe } from '../../../../building-progress/modules/periods/pipes/period-date.pipe';

@Component({
  selector: 'app-period-range-control',
  templateUrl: './period-range-control.component.html',
  styleUrls: ['./period-range-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PeriodRangeControlComponent),
      multi: true
    }
  ]
})
export class PeriodRangeControlComponent implements ControlValueAccessor, OnInit {
  @Input() periods: BuildingProgressPeriod[] = [];
  @Input() tooltipSinglePeriod: string;
  @Input() tooltipPeriodRange: string;
  @Input() periodRangeTitle: string;
  @Input() singlePeriodTitle: string;
  @Input() allowOnlySinglePeriod = false;

  form: UntypedFormGroup;
  exportPeriodRange = false;

  private onChange: Function;

  constructor(
    private periodDatePipe: PeriodDatePipe,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      periodId: [],
      periodsFrom: [],
      periodsTo: []
    });
  }

  writeValue(value: any): void {
    if (value && value[0]) {
      const periodId = value[0];
      this.form.get('periodId').setValue(periodId);
      const period = this.getPeriod(periodId);
      if (period) {
        this.form.get('periodsFrom').setValue(period.dateFrom);
        this.form.get('periodsTo').setValue(period.dateTo);
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;

    this.form.get('periodId').valueChanges.subscribe(() => this.onChange(this.getExportedPeriodIds()));
    this.form.get('periodsFrom').valueChanges.subscribe(() => this.onChange(this.getExportedPeriodIds()));
    this.form.get('periodsTo').valueChanges.subscribe(() => this.onChange(this.getExportedPeriodIds()));
  }

  registerOnTouched(fn: any): void { }

  setDisabledState?(isDisabled: boolean): void { }

  private getExportedPeriodIds(): number[] {
    if (this.exportPeriodRange) {
      if (!!this.getPeriodsFromControlValue() && !!this.getPeriodsToControlValue()) {
        return this.getPeriodIdsBetweenDates(this.getPeriodsFromControlValue(), this.getPeriodsToControlValue());
      } else {
        return [];
      }
    } else {
      return [+this.form.get('periodId').value];
    }
  }

  private getPeriodIdsBetweenDates(dateFrom: Date, dateTo: Date): number[] {
    return this.periods.filter(p => (p.dateFrom >= dateFrom && p.dateFrom <= dateTo) &&
      (p.dateTo >= dateFrom && p.dateTo <= dateTo)).map(p => p.id);
  }

  private getPeriod(periodId: number): BuildingProgressPeriod {
    return this.periods.find(p => p.id === periodId);
  }

  changeMode(): void {
    this.exportPeriodRange = !this.exportPeriodRange;
    if (this.onChange) {
      this.onChange(this.getExportedPeriodIds());
    }
  }

  getPeriodTitle(period: BuildingProgressPeriod): string {
    return this.periodDatePipe.transform(period.dateFrom, period.dateTo);
  }

  onPeriodDateFromChange(): void {
    if (this.getPeriodsFromControlValue() > this.getPeriodsToControlValue()) {
      this.form.get('periodsTo').reset();
    }
  }

  getFilteredPeriodRangeEnds(): BuildingProgressPeriod[] {
    return this.getPeriodsFromControlValue() ?
      this.periods.filter(p => this.getPeriodsFromControlValue() <= p.dateFrom) : this.periods;
  }

  private getPeriodsFromControlValue(): Date {
    return this.form.get('periodsFrom').value;
  }

  private getPeriodsToControlValue(): Date {
    return this.form.get('periodsTo').value;
  }
}
