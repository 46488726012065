<form
  [formGroup]="parentForm"
  class="header-data"
>
  @if(showPlace) {
  <div class="form-group place">
    <label
      for="place"
      class="caption"
    >{{'PROJECTS.DETAIL.INFO.ADRESA' | translate}}</label>

    <input
      id="place"
      class="form-control"
      data-test="place-input"
      type="text"
      formControlName="place"
      maxlength="50"
    >
  </div>
  }

  @for (company of companies; track $index) {
  <div class="form-group company-name">
    <label
      for="{{company + 'Name'}}"
      class="caption"
    >{{('PROJECTS.DETAIL.INFO.'+ company) | uppercase | translate}}</label>

    @if (canSearch) {
    <app-company-search
      [formControlName]="company + 'Name'"
      [maxlength]="100"
      (selectedPartner)="onSelectedPartner(company, $event)"
    ></app-company-search>
    }
    @else {
    <input
      [id]="company + 'Name'"
      class="form-control"
      [attr.data-test]="company + 'Name'"
      type="text"
      [formControlName]="company + 'Name'"
      maxlength="100"
    >
    }
  </div>

  <div class="form-group company-id">
    <label
      for="{{company + 'Id'}}"
      class="caption"
    >{{'PROJECTS.DETAIL.INFO.COMPANY_ID' | translate}}</label>

    <input
      [id]="company + 'Id'"
      class="form-control"
      [attr.data-test]="company + 'Id'"
      type="text"
      [formControlName]="company + 'Id'"
      maxlength="20"
    >
  </div>

  <div class="form-group company-vat-id">
    <label
      for="{{company + 'VatId'}}"
      class="caption"
    >{{'PROJECTS.DETAIL.INFO.COMPANY_VAT_ID' | translate}}</label>

    <input
      [id]="company + 'VatId'"
      class="form-control"
      [attr.data-test]="company + 'VatId'"
      type="text"
      [formControlName]="company + 'VatId'"
      maxlength="20"
    >
  </div>
  }

  <div class="form-group construction-manager">
    <label
      for="constructionManager"
      class="caption"
    >{{'PROJECTS.DETAIL.INFO.CONSTRUCTION_MANAGER' | translate}}</label>

    <input
      id="constructionManager"
      class="form-control"
      data-test="constructionManager-input"
      type="text"
      formControlName="constructionManager"
      maxlength="100"
    >
  </div>
</form>
