import { Injectable, OnDestroy } from '@angular/core';

import { of, Subject } from 'rxjs';
import { SubSink } from 'subsink';
import { switchMap, takeUntil } from 'rxjs/operators';

import { AlertDialogComponent } from '@kros-sk/ssw-cdk';
import {
  AnalyticsService,
  ApplicationOpenerService,
  DeleteDocumentService,
  DocumentListInfoModel,
  DocumentsExplorerDispatchersService,
  DocumentsPermissionsService,
  DocumentType,
  IfcOpeningModel,
  PreparingExternalApplicationModel,
  ProjectDetail,
  TranslateService
} from '@kros-sk/ssw-shared-legacy';
import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { KrosModalService } from '@kros-sk/components';

import { AdBlockCheckerService } from './adblock-checker/adblock-checker.service';
import { ContentTypes } from '../shared/content-types';
import { DocumentService } from './document/document.service';
import { DocumentsSelectorsService } from '../../store/documents';
import { environment } from '../../../environments/environment';
import { ProjectsSelectorsService } from '../../store/projects';

@Injectable()
export class DocumentsOpenerService implements OnDestroy {
  project: ProjectDetail;
  documentId: string;
  markedDocument: DocumentListInfoModel;

  private currentFolderId: string;
  private subs = new SubSink();
  private unsubscribe: Subject<void> = new Subject();

  get isCzechVersion(): boolean {
    return environment.location === 'cz';
  }

  constructor(
    private adBlockCheckerService: AdBlockCheckerService,
    private analyticsService: AnalyticsService,
    private appInsightsService: AppInsightsBaseService,
    private applicationOpenerService: ApplicationOpenerService,
    private documentService: DocumentService,
    private deleteDocumentService: DeleteDocumentService,
    private documentsSelectors: DocumentsSelectorsService,
    private documentsExplorerDispatchers: DocumentsExplorerDispatchersService,
    private documentsPermissionsService: DocumentsPermissionsService,
    private projectsSelectorsService: ProjectsSelectorsService,
    private translateService: TranslateService,
    private krosModalService: KrosModalService,
  ) {
    this.subs.sink = this.documentsSelectors.selectMarkedDocument$.subscribe(doc => this.markedDocument = doc);
    this.subs.sink = this.projectsSelectorsService.projectDetail$.subscribe(project => this.project = project);
    this.subs.sink = this.documentsSelectors.currentFolderId$.subscribe(id => this.currentFolderId = id);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.subs.unsubscribe();
  }

  openOnNewTab(document: DocumentListInfoModel): void {
    const newWindow = window.open('', '_blank');

    this.adBlockCheckerService
      .isAdBlockEnabled()
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(isAdBlockEnabled => {
          if (isAdBlockEnabled) {
            return of(undefined);
          } else {
            return this.documentService.openDocument(document.currentVersionId);
          }
        })
      )
      .subscribe(resp => {
        if ((!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') || !resp) {
          newWindow?.close();
          this.krosModalService.openCentered(
            AlertDialogComponent,
            {
              caption: this.translateService.translate('DOCUMENTS.ADBLOCK_ACTIVE'),
              suggestion: this.translateService.translate('DOCUMENTS.ADBLOCK_SUGGESTION'),
              closeButtonText: this.translateService.translate('DOCUMENTS.ADBLOCK_CLOSE')
            },
            {
              closeOnBackdropClick: false,
              fullscreenOnMobile: false,
              showMobileArrowBack: false
            },
            NaN,
            NaN,
            undefined,
            'no-min-width'
          );
        } else {
          const file = new Blob([resp], { type: document.contentType });
          newWindow.location.href = window.URL.createObjectURL(file);
        }
      });
  }

  openDocument(document: DocumentListInfoModel): void {
    this.deleteDocumentService.dismissDeleteToast();

    if (document.type === DocumentType.Folder) {
      this.documentsExplorerDispatchers.loadFolder(
        {
          id: document.id,
          name: document.name,
          parentId: this.currentFolderId,
          permissionType: document.permissionType,
          documentPermissionType: document.documentPermissionType
        },
        this.project.id
      );
    } else if (this.documentsPermissionsService.canOpenDocumentOnNewTab() ||
      this.documentsPermissionsService.canOpenImages()) {
      this.openOnNewTab(document);
    } else if (this.documentsPermissionsService.canOpenIfc()) {
      this.appInsightsService.trackEvent('DOC-open-ifc');
      this.analyticsService.raiseEvent('sprava-suborov', 'zobrazit-dokument');
      this.openIfc('bimViewer', this.getSelectedDocumentAndDismissToast());
    } else if (this.documentsPermissionsService.canOpenDrawingFile() || this.documentsPermissionsService.canOpenPdfFile()) {
      this.appInsightsService.trackEvent('DOC-open-qm-document', { fileType: document.contentType });
      this.analyticsService.raiseEvent('sprava-suborov', 'zobrazit-dokument');
      this.openCad('quantityManager');
    } else if (this.documentsPermissionsService.canOpenConstruction()) {
      this.appInsightsService.trackEvent('DOC-open-construction');
      this.analyticsService.raiseEvent('sprava-suborov', 'zobrazit-stavbu');
      this.applicationOpenerService.openConstruction(this.project.id, document.currentVersionId);
    }
  }

  openApplication(appId: string, documentVersionId?: string): void {
    if (this.documentsPermissionsService.canOpenIfc()) {
      this.openIfc(appId, this.getSelectedDocumentAndDismissToast());
    } else if (this.documentsPermissionsService.canOpenDrawing()) {
      this.openCad(appId);
    } else if (this.documentsPermissionsService.canOpenConstruction()) {
      this.applicationOpenerService.openConstruction(this.project.id, documentVersionId);
    } else {
      this.openExternalApplication(appId, documentVersionId);
    }
  }

  private openExternalApplication(appId: string, documentVersionId: string): void {
    const data: PreparingExternalApplicationModel = {
      applicationId: appId,
      projectId: this.project.id,
      documentVersionId,
      folderId: this.currentFolderId,
    };
    this.applicationOpenerService.openExternalApplication(data);
  }

  private openCad(appId: string): void {
    const document = this.getSelectedDocumentAndDismissToast();
    this.applicationOpenerService.openCadApplication(
      appId,
      this.project.id,
      document.contentType === ContentTypes.pdf,
      document.id,
      document.currentVersionId);
  }

  private getSelectedDocumentAndDismissToast(): DocumentListInfoModel {
    this.deleteDocumentService.dismissDeleteToast();

    return this.markedDocument;
  }

  private openIfc(appId: string, document: DocumentListInfoModel): void {
    this.applicationOpenerService.openInternalIfcApplication({
      appId,
      projectId: this.project.id,
      documentId: document.id,
      documentVersionIds: [document.currentVersionId],
      documentName: document.name,
      folderId: this.currentFolderId,
      contractId: undefined,
      canSendData: false
    } as IfcOpeningModel);
  }
}
