import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ProgressBarService } from '../progress-bar';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor(private progressBarService: ProgressBarService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const pb = this.progressBarService.showProgress();

    if (!request.headers.has('Content-Type')) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }

    request = request.clone({ headers: request.headers.set('Accept', '*/*;q=0.8,application/json;q=0.9') });

    return next.handle(request).pipe(finalize(() => this.progressBarService.hideProgress(pb)));
  }
}
