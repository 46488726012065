import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

import { KrosModalRef } from '@kros-sk/components';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

@Component({
  selector: 'app-document-description-modal',
  templateUrl: './document-description-modal.component.html',
  styleUrls: ['./document-description-modal.component.scss']
})
export class DocumentDescriptionModalComponent extends UnsubscribeComponent implements OnInit {

  form: UntypedFormGroup;
  editable: boolean;
  changesMade = false;

  get canEdit(): boolean {
    return !this.editable;
  }

  get descriptionFormControl(): AbstractControl {
    return this.form.get('description');
  }

  constructor(
    public modalRef: KrosModalRef,
    private formBuilder: UntypedFormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      description: ['']
    });
    this.editable = this.modalRef.data.canEdit;
    if (this.modalRef.data.description) {
      this.descriptionFormControl.setValue(this.modalRef.data.description);
      if (!this.editable) {
        this.descriptionFormControl.disable();
      }
    }
    this.subs.sink = this.form.valueChanges.subscribe(() => {
      this.changesMade = true;
    });
    document.getElementById('descriptionTextArea').focus();
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  onSubmit(): void {
    this.modalRef.submit({ description: this.descriptionFormControl.value });
  }
}
