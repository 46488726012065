import { CdkMenuGroup } from '@angular/cdk/menu';
import { Directive } from '@angular/core';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';

@Directive({
  selector: '[krosDropdownGroup]',
  exportAs: 'krosDropdownGroup',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'kros-dropdown-group',
  },
  providers: [{provide: UniqueSelectionDispatcher, useClass: UniqueSelectionDispatcher}],
})
export class KrosDropdownGroupDirective extends CdkMenuGroup {}
