import { Component, Input, TemplateRef } from '@angular/core';

import { ToastService } from '../../services/toast.service';
import { ToastType } from '../../toast-type';

@Component({
  selector: 'kros-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent {
  @Input() type: ToastType;

  constructor(
    public toastService: ToastService
  ) { }

  get toasts(): any[] {
    if (this.toastService.isInfoType(this.type)) {
      return this.toastService.infoToasts;
    }

    return this.toastService.errorToasts;
  }

  isTemplate(toast): boolean {
    return toast.content instanceof TemplateRef;
  }

  classByType(toastType: ToastType): string {
    let className = '';

    switch (toastType) {
      case ToastType.Info:
        className = 'kros-info';
        break;
      case ToastType.Warning:
        className = 'kros-warning';
        break;
      case ToastType.Error:
        className = 'kros-error';
        break;
    }

    return 'text-light ' + className;
  }
}
