import { DateFilter, DateInterval } from '@kros-sk/models';

import { getDayEndString, resetUtcTime } from '../../../tools/date/date-tools';

export function getFilterParams(params: string): string {
  return '$filter=' + encodeURIComponent('(not IsDeleted)' + params);
}

export function dateFilterFromInterval(interval: DateInterval, colName: string): string {
  if (interval.from) {
    return ` and ${colName} ge ${interval.from.toISOString()} and ${colName} le ${getDayEndString(interval.to)}`;
  }
  return '';
}

export function translateYearChoiceToDates(year: DateFilter): DateInterval {
  let curYear = new Date().getFullYear();
  let curMonth = new Date().getMonth();

  if (year === DateFilter.CurrentYear) {
    return {
      from: resetUtcTime(new Date(curYear, 0, 1)),
      to: resetUtcTime(new Date(curYear, 11, 31)),
    };
  }

  if (year === DateFilter.LastYear) {
    curYear -= 1;
    return {
      from: resetUtcTime(new Date(curYear, 0, 1)),
      to: resetUtcTime(new Date(curYear, 11, 31)),
    };
  }

  if (year === DateFilter.CurrentMonth) {
    const lastDayInCurMonth = new Date(curYear, curMonth + 1, 0).getDate();
    return {
      from: resetUtcTime(new Date(curYear, curMonth, 1)),
      to: resetUtcTime(new Date(curYear, curMonth, lastDayInCurMonth)),
    };
  }

  if (year === DateFilter.LastMonth) {
    curMonth -= 1;
    const lastDayInLastMonth = new Date(curYear, curMonth + 1, 0).getDate();
    return {
      from: resetUtcTime(new Date(curYear, curMonth, 1)),
      to: resetUtcTime(new Date(curYear, curMonth, lastDayInLastMonth)),
    };
  }

  return {
    from: null,
    to: null,
  };
}

export function getSearchQuery(searchString?: string | null, filter?: string, searchPrefix = '&'): string {
  let search = '';
  if (searchString?.trim()) {
    search = `${searchPrefix}search=${encodeURIComponent(searchString)}`;
  }
  return search + (filter ? `&${filter}` : '') + '&searchMode=all';
}

export function getStringInterpretationFromDate(dateInterval: Date): string {
  const day = dateInterval.getDate().toString().padStart(2, '0');
  const mont = (dateInterval.getMonth() + 1).toString().padStart(2, '0');
  const year = dateInterval.getFullYear();
  return year + '-' + mont + '-' + day;
}

export function paramNameValuePair(paramName: string, paramValue: string): string {
  return paramValue ? `&${paramName}=${paramValue}` : '';
}
