import { inject, Injectable } from '@angular/core';

import { catchError, filter, take, tap } from 'rxjs/operators';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

import { AnalyticsService } from '../../analytics';
import { DeleteResultInfo, DeleteResultType } from '../models';
import { DocumentListInfoModel, SelectionService } from '../../document-explorer';
import { DocumentsEditDispatchersService } from '../store';
import { ToastRef, ToastService, ToastType } from '../../toast';
import { TranslateService } from '../../translate';

@Injectable()
export class DeleteDocumentService {

  private documentEditDispatchersService = inject(DocumentsEditDispatchersService);
  private translateService = inject(TranslateService);
  private toastService = inject(ToastService);
  private analyticsService = inject(AnalyticsService);
  private appInsightsService = inject(AppInsightsBaseService);
  private selection = inject(SelectionService);

  private toastDeleteCallbacks: { [id: string]: ToastRef; } = {};

  dismissDeleteToast(): void {
    const toasts = Object.values(this.toastDeleteCallbacks);
    if (toasts.length) {
      toasts.forEach(t => t.dismiss());
    }
  }

  deleteDocument(projectId: number, documentId: string): void {
    this.toastDeleteCallbacks[documentId] = this.toastService.open(
      this.translateService.translate('DOCUMENTS.DOKUMENT_VYMAZANY'),
      ToastType.Info,
      {},
      this.translateService.translate('COMMON.UNDO')
    );

    this.undoDelete(projectId, documentId, [documentId]);
    this.deleteDocumentAfterDismissed(projectId, documentId);
  }

  deleteDocumentVersion(projectId: number, documentId: string, documentVersionId: string): void {
    this.toastDeleteCallbacks[documentVersionId] = this.toastService.open(
      this.translateService.translate('DOCUMENTS.DELETE_VERSION'),
      ToastType.Info,
      {},
      this.translateService.translate('COMMON.ZRUSIT')
    );

    this.documentEditDispatchersService.softDeleteDocumentVersion(projectId, documentId, documentVersionId);
    this.undoDeleteVersion(documentVersionId);
    this.deleteDocumentVersionAfterDismissed(projectId, documentId, documentVersionId);
  }

  deleteSelectedDocuments(resp: DeleteResultInfo[], projectId: number, documents: DocumentListInfoModel[]): void {
    const canDelete = !resp.find(p => p.result !== DeleteResultType.Ok);

    if (canDelete) {
      const documentIds = documents.map(d => d.id);
      const id = documentIds.join(';');
      this.toastDeleteCallbacks[id] =
        this.toastService.open(
          this.translateService.translate('DOCUMENTS.DOKUMENTY_VYMAZANE'),
          ToastType.Info,
          {},
          this.translateService.translate('COMMON.UNDO'));

      this.undoDelete(projectId, id, documentIds);
      this.deleteDocumentsAfterSnackDismissed(projectId, documents);
    } else {
      this.toastService.open(
        this.translateService.translate('DOCUMENTS.ADRESAR_NIE_JE_PRAZDNY'),
        ToastType.Warning);
    }
  }

  dismissInfoToast(): void {
    this.toastService.dismissInfoToast();
  }

  private deleteNotifyStatistics(app: string, isSelectedFolder: boolean, isSelectedFile: boolean): void {
    if (isSelectedFolder) {
      this.appInsightsService.trackEvent(app + '-delete-folder');
      this.analyticsService.raiseEvent('sprava-suborov', 'vymazat-priecinok');
    }

    if (isSelectedFile) {
      this.appInsightsService.trackEvent(app + '-delete-document');
      this.analyticsService.raiseEvent('sprava-suborov', 'vymazat-dokument');
    }
  }

  onDeleteDocument(projectId: number, app: string): void {
    const docs = this.selection.getSelectedDocuments();
    this.deleteNotifyStatistics(app, this.selection.isSelectedFolder, this.selection.isSelectedFile);

    if (docs.length === 1) {
      this.documentEditDispatchersService.softDeleteDocument(projectId, docs[0].id);
    } else {
      this.documentEditDispatchersService.softDeleteDocuments(projectId, docs);
    }
  }

  private deleteDocumentAfterDismissed(projectId: number, documentId: string): void {
    this.toastDeleteCallbacks[documentId].afterDismissed()
      .pipe(
        take(1),
        filter(snackResp => !snackResp.dismissedByAction),
        tap(() => this.documentEditDispatchersService.deleteDocument(projectId, documentId)),
        catchError(this.handleError.bind(this))
      ).subscribe(() => delete this.toastDeleteCallbacks[documentId]);
  }

  private deleteDocumentVersionAfterDismissed(projectId: number, documentId: string, documentVersionId: string): void {
    this.toastDeleteCallbacks[documentVersionId].afterDismissed()
      .pipe(
        take(1),
        filter(snackResp => !snackResp.dismissedByAction),
        tap(() => this.documentEditDispatchersService.deleteDocumentVersion(projectId, documentId, documentVersionId)),
        catchError(this.handleError.bind(this))
      ).subscribe(() => delete this.toastDeleteCallbacks[documentVersionId]);
  }

  private deleteDocumentsAfterSnackDismissed(projectId: number, documents: DocumentListInfoModel[]): void {
    const id = documents.map(d => d.id).join(';');
    this.toastDeleteCallbacks[id].afterDismissed()
      .pipe(
        take(1),
        filter(snackResp => !snackResp.dismissedByAction),
        tap(() => this.documentEditDispatchersService.deleteDocuments(projectId, documents)),
        catchError(this.handleError.bind(this))
      ).subscribe(() => delete this.toastDeleteCallbacks[id]);
  }

  private undoDelete(projectId: number, id: string, documentIds: string[]): void {
    this.toastDeleteCallbacks[id].onAction()
      .pipe(take(1))
      .subscribe(() => {
        this.documentEditDispatchersService.undoDeleteDocuments(projectId, documentIds);
        this.toastService.open(this.translateService.translate('DOCUMENTS.UNDO_ACTION'));
      });
  }

  private undoDeleteVersion(documentVersionId: string): void {
    this.toastDeleteCallbacks[documentVersionId].onAction()
      .pipe(take(1))
      .subscribe(() => {
        this.documentEditDispatchersService.undoDeleteDocumentVersion(documentVersionId);
        this.toastService.open(this.translateService.translate('DOCUMENTS.UNDO_ACTION'));
      });
  }

  private handleError(error: any): void {
    this.toastService.open(error, ToastType.Error);
  }
}
