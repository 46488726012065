import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { filter, map, switchMap, tap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';

import { KrosAuthService } from '../services/auth/kros-auth.service';

@Injectable()
export class KrosAuthInterceptor implements HttpInterceptor {

  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private authService: KrosAuthService,
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.useInterceptor(req.url)) {
      return next.handle(req);
    }

    return this.handle(req, next);
  }

  private handle(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.authService.ensureValidToken()).pipe(
      tap(tokenIsValid => tokenIsValid || this.authService.startAuthentication()),
      filter(Boolean),
      switchMap(_ => this.authService.authorizationHeaderValue),
      map(token => this.authHeaders(req, token)),
      map(headers => req.clone({ headers })),
      switchMap((reqCloned) => next.handle(reqCloned))
    );
  }

  private authHeaders(req: HttpRequest<any>, token: string): HttpHeaders {
    return req.headers
      .set('Authorization', token)
      .set('ApplicationType', this.authService.applicationTypeHeaderValue.toString());
  }

  useInterceptor(url: string): boolean {
    return !url.includes('/assets/i18n/') && (url.includes(`${this.appConfig.searchApiUrl}`)
      || url.includes(`${this.appConfig.gatewayApiUrl}`) ||
      this.appConfig.gatewaysApiUrl?.some(p => url.includes(p)));
  }
}
