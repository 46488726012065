import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { LicenseDispatchersService } from './license-dispatchers.service';
import { LicenseEffects } from './license.effects';
import { licenseReducer } from './license.reducer';
import { LicenseSelectorsService } from './license-selectors.service';

@NgModule({
  imports: [
    StoreModule.forFeature('license-info', licenseReducer),
    EffectsModule.forFeature([LicenseEffects]),
  ],
  providers: [
    LicenseSelectorsService,
    LicenseDispatchersService
  ]
})
export class LicenseStoreModule { }
