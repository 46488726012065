import { Action, ActionReducer } from '@ngrx/store';

import produce from 'immer';

import { clearUndoRedoStack } from './undo-redo.actions';
import { undoRedoKey } from './undo-redo.state';

export const createUndoRedoActionSaveReducer = (undoableActions: Action[], needClearStackActions: Action[]) =>
  (reducer: ActionReducer<any>): ActionReducer<any> => {
    return (state: any, action: any): void => {
      const newState = { ...state };
      if (!action.isUndoRedoOperation) {
        if (undoableActions.find(a => a.type === action.type) && !action.needClearStack) {
          const newPast = [...state[undoRedoKey].history.past, action];
          newState[undoRedoKey] = {
            ...newState[undoRedoKey],
            history: {
              past: produce(newPast, draft => { }),
              future: []
            },
            isInProgress: false
          };
        } else if (
          action.type === clearUndoRedoStack.type ||
          needClearStackActions.find(a => a.type === action.type) ||
          action.needClearStack
        ) {
          newState[undoRedoKey] = {
            ...newState[undoRedoKey],
            history: {
              past: [],
              future: []
            },
            isInProgress: false
          };
        }
      }

      return reducer(newState, action);
    };
  };
