<kros-input [options]="{
    type: 'text',
    name: 'street',
    formControl: streetForm,
    label: 'COMMON.ADDRESS.STREET' | translate,
    testName: 'company-info-street-select',
    autocomplete: autocompleteConfig,
    autocompleteOff: true,
    maxLength: 255
  }">
</kros-input>

<ng-template #optionTemplate let-r="result">
  <div class="address-container">
    <div class="ellipsis" data-test="option">
      {{r.street}}
    </div>
    <div class="ellipsis" data-test="option">
      {{r.postCode + ' '}}{{r.city}}
    </div>
    <div class="ellipsis" data-test="option">
    </div>
  </div>
</ng-template>
