<div class="kros-modal-header">
  <button
    class="btn btn-link close"
    tabindex="-1"
    (click)="cancel()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div class="kros-modal-body">
  <div class="centered-container">
    <i class="material-icons-outlined main-icon">send_to_mobile</i>

    <h4>
      {{ 'LICENSE_ACTIVATION.SMS_ACTIVATION.' + (!phoneNumberSubmitted ? 'TRIAL_ACTIVATION' : 'SMS_CODE_ENTERING') | translate }}
    </h4>
  </div>

  <div class="message-text">
    <ng-container *ngIf="!phoneNumberSubmitted else enterCodeText">
      <span>{{ 'LICENSE_ACTIVATION.SMS_ACTIVATION.PHONE_NUMBER_SEND_SMS_MESSAGE' | translate }}</span><br>

      <span>{{ 'LICENSE_ACTIVATION.SMS_ACTIVATION.PHONE_NUMBER_VALIDATED_SMS_MESSAGE' | translate | stringFormat: [(applicationNameKey | translate), trialDuration, (trialDayPluralisation | translate)] }}</span>
    </ng-container>

    <ng-template #enterCodeText>
      <span>{{ 'LICENSE_ACTIVATION.SMS_ACTIVATION.ENTER_CODE_FROM_SMS' | translate }}</span>&nbsp;
      <b>{{form.controls.phoneNumber.value}}</b>
    </ng-template>
  </div>

  <form
    [formGroup]="form"
    id="formGroup"
  >
    <ng-container *ngIf="!phoneNumberSubmitted else enterCodeFormField">
      <kros-form-field>
        <kros-label>{{ 'LICENSE_ACTIVATION.SMS_ACTIVATION.YOUR_PHONE_NUMBER' | translate }}</kros-label>

        <input
          #phoneNumberInput
          krosControl
          focusableInput
          markableAsInvalid
          class="form-control"
          [class.disabled]="canTryAgain"
          [readOnly]="canTryAgain"
          formControlName="phoneNumber"
          data-test="phone-number-input"
        />

        <kros-input-error *ngIf="form.controls.phoneNumber?.hasError('required')">
          {{ 'COMMON.REQUIRED_ITEM' | translate }}
        </kros-input-error>

        <kros-input-error *ngIf="form.controls.phoneNumber?.hasError('invalidPhoneNumber')">
          {{ 'LICENSE_ACTIVATION.SMS_ACTIVATION.ERROR_INVALID_PHONE_NUMBER' | translate }}
        </kros-input-error>

        <kros-input-error *ngIf="form.controls.phoneNumber?.hasError('phoneNumberAlreadyActivated')">
          {{ 'LICENSE_ACTIVATION.SMS_ACTIVATION.ERROR_PHONE_NUMBER_ALREADY_ACTIVATED' | translate }}
        </kros-input-error>

        <kros-input-error *ngIf="form.controls.phoneNumber?.hasError('activationFailed')">
          {{ 'LICENSE_ACTIVATION.SMS_ACTIVATION.ERROR_ACTIVATION_FAILED' | translate }}
        </kros-input-error>
      </kros-form-field>
    </ng-container>

    <ng-template #enterCodeFormField>
      <kros-form-field>
        <kros-label>{{ 'LICENSE_ACTIVATION.SMS_ACTIVATION.ENTER_VALIDATION_CODE' | translate }}</kros-label>

        <input
          #codeInput
          krosControl
          focusableInput
          markableAsInvalid
          class="form-control"
          [class.disabled]="canTryAgain"
          [readOnly]="canTryAgain"
          formControlName="code"
          data-test="code-input"
        />

        <kros-input-error *ngIf="form.controls.code?.hasError('required')">
          {{ 'COMMON.REQUIRED_ITEM' | translate }}
        </kros-input-error>

        <kros-input-error *ngIf="form.controls.code?.hasError('invalidCode')">
          {{ 'LICENSE_ACTIVATION.SMS_ACTIVATION.ERROR_VALIDATION_NOT_SUCCESSFUL' | translate }}
        </kros-input-error>

        <kros-input-error *ngIf="form.controls.code?.hasError('codeExpired')">
          {{ 'LICENSE_ACTIVATION.SMS_ACTIVATION.ERROR_CODE_EXPIRED' | translate }}
        </kros-input-error>

        <kros-input-error *ngIf="form.controls.code?.hasError('activationFailed')">
          {{ 'LICENSE_ACTIVATION.SMS_ACTIVATION.ERROR_ACTIVATION_FAILED' | translate }}
        </kros-input-error>
      </kros-form-field>
    </ng-template>
  </form>
</div>

<div
  *ngrxLet="inProgress$ as inProgress"
  class="kros-modal-footer"
>
  <button
    type="button"
    class="btn btn-outline-secondary btn-close"
    data-test="close-modal-button"
    [disabled]="inProgress"
    (click)="cancel()"
  >{{ 'COMMON.CLOSE' | translate }}</button>

  <button
    *ngIf="!canTryAgain"
    type="submit"
    form="formGroup"
    class="btn btn-primary btn-submit"
    data-test="submit-button"
    [disabled]="(!phoneNumberSubmitted ? !form.controls.phoneNumber.valid : !form.controls.code.valid) || inProgress"
    (click)="!phoneNumberSubmitted ? submitPhoneNumber() : submitCode()"
  >
    <span
      *ngIf="inProgress"
      class="spinner-border"
    ></span>
    <span *ngIf="!inProgress">{{ 'COMMON.CONFIRM' | translate }}</span>
  </button>

  <button
    *ngIf="canTryAgain"
    type="button"
    class="btn btn-danger btn-submit"
    data-test="try-again-button"
    (click)="tryAgain()"
  >{{ 'COMMON.TRY_AGAIN' | translate }}</button>
</div>
