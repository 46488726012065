import { Injectable } from '@angular/core';

import { Observable, Subject, take } from 'rxjs';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import {
  CompanyDetails,
  CompanyPickerComponent,
  CompanyService,
  CreateCompanyModalComponent,
  CreateCompanyModel
} from '@kros-sk/ssw-shared/company';
import { InfoModalComponent, ToastService, ToastType, TranslateService } from '@kros-sk/ssw-shared-legacy';
import { KrosModalRef, KrosModalService } from '@kros-sk/components';

import {
  BuildingProgressCreateInvoiceComponent
} from '../components/building-progress-create-invoice/building-progress-create-invoice.component';
import {
  BuildingProgressCreateInvoiceModel
} from '../models/building-progress-invoice/building-progress-create-invoice.model';
import {
  BuildingProgressCreateInvoiceResult
} from '../models/building-progress-invoice/building-progress-create-invoice-result.model';
import {
  BuildingProgressInvoiceLicenseInfoComponent
} from '../components/building-progress-invoice-license-info/building-progress-invoice-license-info.component';
import {
  BuildingProgressInvoicePickerComponent
} from '../components/building-progress-invoice-picker/building-progress-invoice-picker.component';
import { BuildingProgressInvoiceService } from './../services/building-progress-invoice.service';
import { BuildingProgressModel } from '../models/construction-data.model';
import { BuildingProgressPeriodDispatchersService } from '../../store/building-progress';
import { InvoiceDetails } from './../models/building-progress-invoice/building-progress-invoice-details.model';
import { PeriodHelper } from './period.helper';

@Injectable()
export class InvoiceHelper {

  get invoiceCreated$(): Observable<void> {
    return this._invoiceCreated.asObservable();
  }

  private _invoiceCreated = new Subject<void>();

  constructor(
    private appInsightsService: AppInsightsBaseService,
    private periodsDispatcherService: BuildingProgressPeriodDispatchersService,
    private invoiceService: BuildingProgressInvoiceService,
    private modalService: KrosModalService,
    private periodHelper: PeriodHelper,
    private translateService: TranslateService,
    private toastService: ToastService,
    private companyService: CompanyService
  ) { }

  createInvoiceForCompany(constructionData: BuildingProgressModel): void {
    this.companyService.getCompanies()
      .pipe(take(1))
      .subscribe((companies: CompanyDetails[]) => {
        if (companies.length === 0) {
          this.createCompanyModal(constructionData);
        } else if (companies.length === 1) {
          this.createInvoiceModal(constructionData, `${companies[0].id}`);
        } else {
          this.showCompanyPicker(companies, constructionData);
        }
      });
  }

  showInvoices(projectId: number, periodId: number): void {
    this.invoiceService.getInvoices(projectId, periodId).pipe(take(1))
      .subscribe({
        next: (invoices: InvoiceDetails[]) => {
          if (invoices.length === 0) {
            const warningMessage = this.translateService.translate('BUILDING_PROGRESS.INVOICE.INVOICE_DELETED');
            this.toastService.open(warningMessage, ToastType.Warning);
            this.periodsDispatcherService.setHasPeriodInvoices([periodId], false);
          } else if (invoices.length === 1) {
            this.openInvoiceApp(invoices[0].url);
          } else {
            this.showInvoicePicker(invoices);
          }
        },
        error: (error) => this.handleInvoicesError(error, periodId)
      });
  }

  private handleInvoicesError(error: any, periodId: number): void {
    if (error.error?.toLowerCase().startsWith('esw-403')) {
      this.modalService.openCentered(
        InfoModalComponent,
        { body: this.translateService.translate('BUILDING_PROGRESS.INVOICE.INSUFFICIENT_RIGHTS_FOR_INVOICES') },
        {
          closeOnBackdropClick: false,
          fullscreenOnMobile: false,
          showMobileArrowBack: false
        }
      );

      this.periodsDispatcherService.setHasPeriodInvoices([periodId], false);
    }
  }

  openInvoiceApp(url: string): void {
    this.appInsightsService.trackEvent('PV-invoice-open');
    const newTab: Window = window.open(url, '_blank');
    if (!newTab || newTab.closed || typeof newTab.closed === 'undefined') {
      this.toastService.open(this.translateService.translate('BUILDING_PROGRESS.INVOICE.POPUP_BLOCKED'), ToastType.Error);
    }
  }

  private createInvoiceModal(constructionData: BuildingProgressModel, companyId: string): void {
    const initialModel: BuildingProgressCreateInvoiceModel = {
      projectId: constructionData.projectId,
      periodIds: [this.periodHelper.getSelectedOrLastPeriodId()],
      buildingObjectIds: [],
      invoiceDate: '',
      companyId
    };

    const modalRef: KrosModalRef = this.modalService.openCentered(
      BuildingProgressCreateInvoiceComponent,
      {
        invoiceModel: initialModel,
        periods: constructionData?.periods
      },
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: true,
        showMobileArrowBack: true,
        addModalToBrowsersHistory: false
      }
    );

    modalRef.afterClosed$.pipe(take(1)).subscribe(result => {
      if (result.type === 'submit') {
        this.createInvoice(result.data);
      }
    });
  }

  private showInvoicePicker(invoices): void {
    const modalRef = this.modalService.openCentered(
      BuildingProgressInvoicePickerComponent,
      {
        invoices
      },
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: true,
        showMobileArrowBack: true
      }
    );

    modalRef.afterClosed$.pipe(take(1)).subscribe(result => {
      if (result.type === 'submit') {
        this.openInvoiceApp(result.data);
      }
    });
  }

  private createInvoice(data: BuildingProgressCreateInvoiceModel): void {
    this.appInsightsService.trackEvent('PV-invoice-create');
    this.invoiceService.createInvoice(data, this.translateService.uiCulture).pipe(take(1))
      .subscribe({
        next: (invoiceResult: BuildingProgressCreateInvoiceResult) => {
          this.periodsDispatcherService.setHasPeriodInvoices(data.periodIds, true);
          this._invoiceCreated.next();
          this.openInvoiceApp(invoiceResult.redirectUrl);
        },
        error: (error) => this.handleError(error)
      });
  }

  private handleError(error: any): void {
    if (error.status === 404) {
      this.modalService.openCentered(
        InfoModalComponent,
        { body: error.error },
        {
          closeOnBackdropClick: false,
          fullscreenOnMobile: false,
          showMobileArrowBack: false
        }
      );
    } else if (error.status === 402 && error.error?.substring(0, 3).toLowerCase() === 'esw') {
      this.modalService.openCentered(
        BuildingProgressInvoiceLicenseInfoComponent,
        {},
        {
          closeOnBackdropClick: false,
          fullscreenOnMobile: false,
          showMobileArrowBack: false
        }
      );
    } else if (error.status === 403) {
      this.modalService.openCentered(
        InfoModalComponent,
        { body: this.translateService.translate('BUILDING_PROGRESS.INVOICE.INSUFFICIENT_RIGHTS') },
        {
          closeOnBackdropClick: false,
          fullscreenOnMobile: false,
          showMobileArrowBack: false
        }
      );
    } else {
      this.toastService.open(error.message, ToastType.Error);
    }
  }

  private createCompanyModal(constructionData: BuildingProgressModel): void {
    const modalRef = this.modalService.openCentered(
      CreateCompanyModalComponent,
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: true,
        showMobileArrowBack: true
      }
    );

    modalRef.afterClosed$.pipe(take(1)).subscribe(result => {
      if (result.type === 'submit') {
        const model: CreateCompanyModel = {
          company: result.data
        };
        this.createCompany(constructionData, model);
      }
    });
  }

  private createCompany(constructionData: BuildingProgressModel, createModel: CreateCompanyModel): void {
    this.appInsightsService.trackEvent('PV-company-create');
    this.companyService.createCompany(createModel).pipe(take(1))
      .subscribe(companyResult => {
        this.createInvoiceModal(constructionData, companyResult);
      });
  }

  private showCompanyPicker(companies, constructionData: BuildingProgressModel): void {
    const modalRef = this.modalService.openCentered(
      CompanyPickerComponent,
      {
        companies
      },
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: true,
        showMobileArrowBack: true
      }
    );

    modalRef.afterClosed$.pipe(take(1))
      .subscribe(result => {
        if (result.type === 'submit') {
          this.createInvoiceModal(constructionData, result.data);
        }
      });
  }
}
