import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { BrowserTabService, NavbarService } from '@kros-sk/ssw-shared-legacy';
import { KrosModalService } from '@kros-sk/components';

import { environment } from '../../../../environments/environment';
import { ProjectMembersPanelDialogComponent } from '../project-members-panel-dialog/project-members-panel-dialog.component';
import { ProjectsDispatchersService, ProjectsSelectorsService } from '../../../store/projects';

@Component({
  selector: 'app-project-members',
  templateUrl: './project-members.component.html',
  styleUrls: ['./project-members.component.scss']
})
export class ProjectMembersComponent implements OnInit {

  private destroyRef = inject(DestroyRef);
  private navbarService = inject(NavbarService);
  private projectsDispatchersService = inject(ProjectsDispatchersService);
  private projectsSelectorsService = inject(ProjectsSelectorsService);
  private krosModalService = inject(KrosModalService);
  private browserTabService = inject(BrowserTabService);

  isProjectMembersSelected = true;

  ngOnInit(): void {
    this.browserTabService.setTitle(environment.location === 'cz' ? 'BIM Platforma' : 'SHARING.TITLE');
    this.navbarService.changeTitle('SHARING.TITLE');
    this.navbarService.setNewsData({
      app: 'Titan',
      isTest: environment.news.isTest.toString(),
      modul: 'Projekty'
    });

    this.projectsDispatchersService.setIsProjectMembersTabSelected(true);

    this.projectsSelectorsService.isProjectMembersTabSelected$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => this.isProjectMembersSelected = value);
  }

  onProjectMembersSelected(isSelected: boolean): void {
    this.isProjectMembersSelected = isSelected;
  }

  onOpenPanel(): void {
    this.krosModalService.openCentered(
      ProjectMembersPanelDialogComponent,
      {},
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: false,
        showMobileArrowBack: false
      },
      NaN,
      NaN,
      undefined,
      'kros-modal-panel-left');
  }
}
