import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import {
  isItem,
  SetColorCodeModel,
  SetColorCodeResult,
  ToastService,
  ToastType,
  TranslateService,
  UndoRedoDispatchersService
} from '@kros-sk/ssw-shared-legacy';

import * as buildingProgressActions from './building-progress.actions';
import * as buildingProgressBoqActions from './building-progress-boq.actions';
import { BuildingProgressChangedProperty, BuildingProgressEditModel } from '../../building-progress/models/building-progress-edit.model';
import {
  BuildingProgressChangedPropertyToAnimate,
  BuildingProgressSetModel,
  BuildingProgressSettedPeriodItem
} from '../../building-progress/models/building-progress-set.model';
import { BuildingProgressDispatchersService } from './building-progress-dispatchers.service';
import {
  BuildingProgressItem,
  BuildingProgressModel,
  PeriodItemsEditResult
} from '../../building-progress/models/construction-data.model';
import { getUnmarkedBoqItemIdsFromItems } from './building-progress-reducer.helper';

@Injectable()
export class BuildingProgressEffectsService {
  constructor(
    private buildingProgressDispatchersService: BuildingProgressDispatchersService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private undoRedoDispatchers: UndoRedoDispatchersService
  ) { }

  cancelApprovedPeriod(payload: any): Action {
    this.toastService.open(this.translateService.translate('BUILDING_PROGRESS.PERIODS.PERIOD_WAS_CANCELED'));
    this.buildingProgressDispatchersService.loadConstructionData(
      payload.projectId, payload.searchModel, undefined, payload.contractorId
    );
    this.buildingProgressDispatchersService.loadPeriods(payload.projectId, false, payload.contractorId);

    return buildingProgressActions.cancelApprovedPeriodSuccess({ periodId: payload.periodId });
  }

  editPercentage(payload, result: PeriodItemsEditResult): Action {
    const oldValues = payload.isUndoRedoOperation ?
      payload.oldValues :
      this.createBuildingProgressSetModel(
        payload.constructionData,
        result.tableItems,
        getUnmarkedBoqItemIdsFromItems(result.unmarkedBoqItems),
        payload.editModel.periodId,
        BuildingProgressChangedPropertyToAnimate.Percentage);
    this.dispatchUndoRedoSuccess(payload);
    this.dispatchResetBoqPeriodId(payload.editModel.periodId);
    if (payload.showSuccessToast) {
      this.toastService.open(this.translateService.translate('BUILDING_PROGRESS.EXECUTIONS_HAVE_BEEN_SET'));
    }

    return buildingProgressActions.editPercentageSuccess(
      {
        editedItems: result.tableItems,
        editModel: payload.editModel,
        oldValues,
        isUndoRedoOperation: payload.isUndoRedoOperation
      });
  }

  setAutocalculatedAmount(payload, result: PeriodItemsEditResult): Action {
    const oldValues = payload.isUndoRedoOperation ?
      payload.oldValues :
      {
        periodId: payload.autocalculatedAmount.periodId,
        projectId: payload.autocalculatedAmount.projectId,
        boqItemIds: getUnmarkedBoqItemIdsFromItems(result.unmarkedBoqItems),
        periodItems: [{
          amount: payload.periodItem.amount,
          percentage: payload.periodItem.percentage,
          totalPrice: payload.periodItem.totalPrice,
          budgetItemId: payload.autocalculatedAmount.budgetItemId,
          calculationType: payload.periodItem.calculationType,
          changedProperty: BuildingProgressChangedPropertyToAnimate.Amount
        }]
      } as BuildingProgressSetModel;
    this.dispatchUndoRedoSuccess(payload);
    this.dispatchResetBoqPeriodId(payload.autocalculatedAmount.periodId);

    return buildingProgressActions.setAutocalculatedAmountSuccess({
      editedItems: result.tableItems,
      autocalculatedAmount: payload.autocalculatedAmount,
      oldValues,
      isUndoRedoOperation: payload.isUndoRedoOperation
    });
  }

  completePercentageSuccess(payload: any, result: PeriodItemsEditResult): Action {
    const periodId = payload.constructionData.periods[payload.constructionData.periods.length - 1].id;
    const oldValues = payload.isUndoRedoOperation ?
      payload.oldValues :
      this.createBuildingProgressSetModel(
        payload.constructionData,
        result.tableItems,
        getUnmarkedBoqItemIdsFromItems(result.unmarkedBoqItems),
        periodId,
        BuildingProgressChangedPropertyToAnimate.Percentage |
        BuildingProgressChangedPropertyToAnimate.Amount |
        BuildingProgressChangedPropertyToAnimate.TotalPrice);
    this.dispatchUndoRedoSuccess(payload);
    this.dispatchResetBoqPeriodId(periodId);
    if (payload.showSuccessToast) {
      this.toastService.open(this.translateService.translate('BUILDING_PROGRESS.EXECUTIONS_HAVE_BEEN_SET'));
    }

    return buildingProgressActions.completePercentageSuccess({
      editedItems: result.tableItems,
      percentageCompleteModel: payload.percentageCompleteModel,
      oldValues,
      isUndoRedoOperation: payload.isUndoRedoOperation
    });
  }

  dispatchUndoAction(action: any): void {
    switch (action.payload.presentAction.type) {
      case buildingProgressActions.editConstructionDataProgress.type:
        this.buildingProgressDispatchersService.setConstructionData(
          this.editedModelWithOldValue(
            action.payload.presentAction.editModel,
            action.payload.presentAction.unmarkedBoqItemIds));
        break;
      case buildingProgressActions.editPercentageSuccess.type:
      case buildingProgressActions.completePercentageSuccess.type:
      case buildingProgressActions.setAutocalculatedAmountSuccess.type:
      case buildingProgressActions.totalPriceSetSuccess.type:
        this.buildingProgressDispatchersService.setConstructionData(action.payload.presentAction.oldValues);
        break;
      case buildingProgressBoqActions.setBoqSuccess.type:
        this.buildingProgressDispatchersService.setBoqItemUndo(action.payload.presentAction.oldValue);
        break;
      case buildingProgressActions.setColorCodesSuccess.type:
        this.buildingProgressDispatchersService.setColorCodeUndo(
          action.payload.presentAction.oldValues, action.payload.presentAction.constructionData);
        break;
    }
  }

  dispatchRedoAction(action: any): void {
    switch (action.payload.futureAction.type) {
      case buildingProgressActions.editConstructionDataProgress.type:
        this.buildingProgressDispatchersService.editConstructionDataRedo(action.payload.futureAction.editModel);
        break;
      case buildingProgressActions.editPercentageSuccess.type:
        this.buildingProgressDispatchersService.editPercentageRedo(
          action.payload.futureAction.editModel, action.payload.futureAction.oldValues);
        break;
      case buildingProgressActions.setAutocalculatedAmountSuccess.type:
        const data = action.payload.futureAction.autocalculatedAmount;
        this.buildingProgressDispatchersService.setAutocalculatedAmountRedo(
          data.projectId, data.periodId, data.budgetItemId, action.payload.futureAction.oldValues);
        break;
      case buildingProgressActions.completePercentageSuccess.type:
        this.buildingProgressDispatchersService.completePercentageRedo(
          action.payload.futureAction.percentageCompleteModel, action.payload.futureAction.oldValues);
        break;
      case buildingProgressBoqActions.setBoqSuccess.type:
        this.buildingProgressDispatchersService.setBoqItemRedo(action.payload.futureAction.editModel);
        break;
      case buildingProgressActions.totalPriceSetSuccess.type:
        this.buildingProgressDispatchersService.totalPriceSetRedo(
          action.payload.futureAction.priceSetModel, action.payload.futureAction.oldValues);
        break;
      case buildingProgressActions.setColorCodesSuccess.type:
        this.buildingProgressDispatchersService.setColorCodeRedo(
          action.payload.futureAction.setColorCodeModel, action.payload.futureAction.oldValues);
        break;
    }
  }

  private createBuildingProgressSetModel(
    constructionData: BuildingProgressModel,
    editedItems: BuildingProgressItem[],
    boqItemsIds: number[],
    periodId: number,
    changedProperty: BuildingProgressChangedPropertyToAnimate): BuildingProgressSetModel {
    return {
      projectId: constructionData.projectId,
      periodId,
      boqItemIds: boqItemsIds,
      periodItems: editedItems
        .filter(p => isItem(p) && p.periods.find(p => p.periodId === periodId).calculationType === 0)
        .map(item => {
          const originalItem = constructionData.items.find(i => i.id === item.id);
          const currentPeriod = originalItem.periods.find(p => p.periodId === periodId);

          return {
            budgetItemId: item.id,
            totalPrice: currentPeriod.totalPrice,
            amount: currentPeriod.amount,
            percentage: currentPeriod.percentage,
            changedProperty,
            calculationType: currentPeriod.calculationType
          } as BuildingProgressSettedPeriodItem;
        })
    } as BuildingProgressSetModel;
  }

  private editedModelWithOldValue(editModel: BuildingProgressEditModel, unmarkedBoqItemIds: number[]): BuildingProgressSetModel {
    const editedItems = editModel.editedPeriodItems.map(p => {
      const editedItem = {
        amount: p.amount,
        percentage: p.percentage,
        totalPrice: p.totalPrice,
        budgetItemId: p.budgetItemId,
        calculationType: p.calculationType,
        changedProperty: 0
      };

      switch (p.changedProperty) {
        case BuildingProgressChangedProperty.Amount:
          editedItem.changedProperty = BuildingProgressChangedPropertyToAnimate.Amount;
          editedItem.amount = p.oldValue;
          break;
        case BuildingProgressChangedProperty.Percentage:
          editedItem.changedProperty = BuildingProgressChangedPropertyToAnimate.Percentage;
          editedItem.percentage = p.oldValue;
          break;
        case BuildingProgressChangedProperty.TotalPrice:
          editedItem.changedProperty = BuildingProgressChangedPropertyToAnimate.TotalPrice;
          editedItem.totalPrice = p.oldValue;
          break;
      }

      return editedItem;
    });

    return { ...editModel, periodItems: editedItems, boqItemIds: unmarkedBoqItemIds };
  }

  createEmptyConstructionData(projectId: number): BuildingProgressModel {
    return {
      projectId,
      periods: [],
      items: [],
      currency: '',
      decimalPlaces: {
        amount: 0,
        percentage: 0,
        totalPrice: 0,
        totalRubble: 0,
        totalWeight: 0,
        unitPrice: 0,
        buildingObjectTotalPrice: 0
      }
    };
  }

  handlePeriodErrorAndGetAction(error: HttpErrorResponse, action: any): Observable<Action> {
    this.handleError(error, 'PERIOD_');
    return of(action({ error }));
  }

  handleDetailsErrorAndGetAction(error: HttpErrorResponse, action: any): Observable<Action> {
    this.handleError(error, 'DETAILS_');
    return of(action({ error }));
  }

  handleError(error: HttpErrorResponse, errorPrefix?: string, customErrorMessages?: Partial<Record<HttpStatusCode, string>>): boolean {
    let errorMessage: string = this.translateService.translate('BUILDING_PROGRESS.ERROR.GENERAL_ERROR');
    let ret = true;

    if (customErrorMessages && customErrorMessages[error.status]) {
      errorMessage = this.translateService.translate(customErrorMessages[error.status]);
      ret = false;
    } else if (errorPrefix) {
      switch (error.status) {
        case 404:
          errorMessage = this.translateService.translate(`BUILDING_PROGRESS.ERROR.${errorPrefix}NOT_FOUND`);
          ret = false;
          break;
        case 409:
          errorMessage = this.translateService.translate(`BUILDING_PROGRESS.ERROR.${errorPrefix}EXISTS`);
          ret = false;
          break;
      }
    }

    this.toastService.open(errorMessage, ToastType.Error);
    return ret;
  }

  totalPriceSetSuccess(payload: any, result: PeriodItemsEditResult): Action {
    const periodId = payload.constructionData.periods[payload.constructionData.periods.length - 1].id;
    const oldValues = payload.isUndoRedoOperation ?
      payload.oldValues :
      this.createBuildingProgressSetModel(
        payload.constructionData,
        result.tableItems,
        getUnmarkedBoqItemIdsFromItems(result.unmarkedBoqItems),
        periodId,
        BuildingProgressChangedPropertyToAnimate.Percentage |
        BuildingProgressChangedPropertyToAnimate.Amount |
        BuildingProgressChangedPropertyToAnimate.TotalPrice);
    this.dispatchUndoRedoSuccess(payload);
    this.dispatchResetBoqPeriodId(periodId);

    return buildingProgressActions.totalPriceSetSuccess({
      editedItems: result.tableItems,
      priceSetModel: payload.priceSetModel,
      oldValues,
      isUndoRedoOperation: payload.isUndoRedoOperation
    });
  }

  setColorCodesSuccess(payload: any, request: SetColorCodeModel, result: SetColorCodeResult): Action {
    const oldValues = payload.isUndoRedoOperation ?
      payload.oldValues :
      this.createSetColorCodeModel(request, payload.constructionData);
    this.dispatchUndoRedoSuccess(payload);

    return buildingProgressActions.setColorCodesSuccess({
      setColorCodeResult: result,
      setColorCodeModel: request,
      constructionData: payload.constructionData,
      oldValues,
      isUndoRedoOperation: payload.isUndoRedoOperation
    });
  }

  private createSetColorCodeModel(request: SetColorCodeModel, constructionData: BuildingProgressModel): SetColorCodeModel {
    const map: { [key: number]: string; } = {};

    Object.keys(request.budgetItemIdToColorCodeMap).forEach(id => {
      const item = constructionData.items.find(x => x.id === +id);
      map[id] = item.colorCode;
    });

    return {
      projectId: request.projectId,
      budgetItemIdToColorCodeMap: map
    };
  }

  editConstructionData(editPayloads: any[], groupedEditModel: BuildingProgressEditModel, editResult: PeriodItemsEditResult): Action {
    editPayloads.filter(p => p.editModel.periodId === groupedEditModel.periodId).forEach(payload => {
      this.buildingProgressDispatchersService.progressEditConstructionData(
        payload.editModel, editResult.unmarkedBoqItems, payload.isUndoRedoOperation);
    });

    this.dispatchResetBoqPeriodId(groupedEditModel.periodId);
    if (editPayloads[editPayloads.length - 1].showSuccessToast) {
      this.toastService.open(this.translateService.translate('BUILDING_PROGRESS.EXECUTIONS_HAVE_BEEN_SET'));
    }

    return buildingProgressActions.editConstructionDataSuccess(
      { editModel: groupedEditModel, editedItems: editResult.tableItems, isUndoRedoOperation: false });
  }

  private dispatchUndoRedoSuccess(payload: any): void {
    if (payload.isUndoRedoOperation) {
      if (payload.isUndo) {
        this.undoRedoDispatchers.undoSuccess();
      } else {
        this.undoRedoDispatchers.redoSuccess();
      }
    }
  }

  private dispatchResetBoqPeriodId(periodId: number): void {
    this.buildingProgressDispatchersService.resetBoqPeriodId(periodId);
  }
}
