import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { BlobDownloadResponseModel } from '@azure/storage-blob';
import { from, Observable, switchMap, take } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { generateGuid, KrosShepherdService } from '@kros-sk/components';

import { ApplicationModule, ApplicationModuleIdProviderService } from '../../application-module';
import { DocumentStorageService } from '../../upload';
import { HelpService } from '../shared/help.service';
import { LicenseUrlService } from '../../license';
import { VideoTutorialService } from '../shared/video-tutorial.service';

const appIdentificator = new Map([
  [ApplicationModule.None, { Sk: 'Projekt', Cz: 'Projekt' }],
  [ApplicationModule.Projects, { Sk: 'Projekt', Cz: 'Projekt' }],
  [ApplicationModule.ProjectDashboard, { Sk: 'Projekt', Cz: 'Projekt' }],
  [ApplicationModule.Documents, { Sk: 'Projekt', Cz: 'Projekt' }],
  [ApplicationModule.Budget, { Sk: 'Rozpocet', Cz: 'Rozpocet' }],
  [ApplicationModule.BudgetApproval, { Sk: 'ZmenyPocasVystavby', Cz: 'ZmenyBehemVystavby' }],
  [ApplicationModule.BuildingProgress, { Sk: 'PriebehVystavby', Cz: 'PrubehVystavby' }],
  [ApplicationModule.QuantityManager, { Sk: 'Vymery', Cz: 'Vymery' }],
  [ApplicationModule.Misha, { Sk: 'CennikovaDatabaza', Cz: 'CenikovaDatabaze' }],
  [ApplicationModule.Bimprix, { Sk: 'Bim', Cz: 'Bim' }],
  [ApplicationModule.ProjectReport, { Sk: 'Projekt', Cz: 'Projekt' }],
  [ApplicationModule.DocumentViewer, { Sk: 'Projekt', Cz: 'Projekt' }],
  [ApplicationModule.ProjectSharing, { Sk: 'Projekt', Cz: 'Projekt' }]
]);
const skIfcManualName = 'IFC_manual.pdf';
const czIfcManualName = 'metodika_tvorby_modelu_v02.pdf';
const skFAQurl = 'https://www.kros.sk/priebeh-vystavby/faq/';
const skTeamViewerUrl = 'https://get.teamviewer.com/y67trj42';

@Component({
  selector: 'kros-help-buton',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss']
})
export class HelpButtonComponent implements OnInit {

  showFaq = true;
  showCookieSettings = true;
  faqUrl: string;
  videoTutorialUrl: string;

  private showTour = true;
  private applicationModuleId: ApplicationModule;

  protected helpService = inject(HelpService);
  private destroyRef = inject(DestroyRef);
  private krosShepherdService = inject(KrosShepherdService);
  private documentStorageService = inject(DocumentStorageService);
  private appConfig = inject(APP_CONFIG);
  private applicationModule = inject(ApplicationModuleIdProviderService);
  private videoTutorialService = inject(VideoTutorialService);
  private urlHelper = inject(LicenseUrlService, { optional: true });

  get canShowTour(): boolean {
    return this.showTour && this.krosShepherdService.isSetTour;
  }

  get canShowFaq(): boolean {
    return this.showFaq && !!this.faqUrl;
  }

  get canShowCookieSettings(): boolean {
    return this.showCookieSettings && (window as any).cookiehub;
  }

  get isSlovakVersion(): boolean {
    return this.appConfig.location === 'sk';
  }

  get eventCategory(): string {
    return 'napoveda';
  }

  get showIfcManualAction(): string {
    return 'zobrazit-ifcmanual';
  }

  get showHelpAction(): string {
    return 'zobrazit-napovedu';
  }

  get showTourAction(): string {
    return 'zobrazit-sprievodcku';
  }

  get showFaqAction(): string {
    return 'zobrazit-faq';
  }

  get showContactsAction(): string {
    return 'zobrazit-kontakty';
  }

  get showVideoTutorialsAction(): string {
    return 'zobrazit-videonavody';
  }

  get showCookieSettingsAction(): string {
    return 'zobrazit-cookies';
  }

  get showSupportAction(): string {
    return 'vzdialena-pomoc';
  }

  ngOnInit(): void {
    this.helpService.faqLinkChanged$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(faqUrl => this.faqUrl = faqUrl);
    this.helpService.showTourChanged$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(show => this.showTour = show);
    this.helpService.showFaqChanged$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(show => this.showFaq = show);
    this.helpService.videoTutorialUrlChanged$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(videoTutorialUrl => this.videoTutorialUrl = videoTutorialUrl);
    this.applicationModule?.applicationModuleId$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(applicationModuleId => this.applicationModuleId = applicationModuleId);
  }

  onShowHelp(): void {
    this.openHelp()
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(resp => {
        const file = new Blob([resp], { type: 'application/pdf' });
        window.open(window.URL.createObjectURL(file), '_blank');
      });
  }

  onShowIfcManual(): void {
    const ifcManualName = this.isSlovakVersion ? skIfcManualName : czIfcManualName;
    window.open(`shared-assets/docs/${ifcManualName}`);
  }

  onShowVideoTutorials(): void {
    const lang = this.isSlovakVersion ? 'Sk' : 'Cz';
    window.open(this.videoTutorialUrl ?? this.videoTutorialService.getVideoTutorialUrl(this.applicationModuleId, lang), '_blank');
  }

  onShowContacts(): void {
    if (this.urlHelper) {
      window.open(this.urlHelper.contactsUrl, '_blank');
    }
  }

  onShowTour(): void {
    this.krosShepherdService.show();
  }

  onShowFaq(): void {
    window.open(this.faqUrl ?? skFAQurl, '_blank');
  }

  onShowCookieSettings(): void {
    (window as any).cookiehub?.openSettings();
  }

  onShowSupport(): void {
    window.open(skTeamViewerUrl, '_blank');
  }

  private openHelp(): Observable<Blob> {
    const guid = generateGuid();
    const helpName = this.createHelpName();
    return from(this.documentStorageService.downloadDocument(
      { blobUri: `${this.appConfig.staticContentStorage}/shared-documents/${helpName}?=${guid}` }))
      .pipe(
        switchMap((resp: BlobDownloadResponseModel) => resp.blobBody)
      );
  }

  private createHelpName(): string {
    const lang = this.isSlovakVersion ? 'Sk' : 'Cz';
    const appModule = appIdentificator.get(this.applicationModuleId)[lang];
    return `${appModule}Napoveda${lang}.pdf`;
  }
}
