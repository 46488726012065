import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LicenseStoreModule } from '../store/license/license-store.module';
import { LicenseService } from './services/license.service';

@NgModule({
  imports: [
    CommonModule,
    LicenseStoreModule
  ]
})
export class LicenseModule {
  public static forRoot(licenseService: Type<LicenseService>): ModuleWithProviders<LicenseModule> {
    return {
      ngModule: LicenseModule,
      providers: [
        {
          provide: LicenseService,
          useClass: licenseService
        }
      ]
    };
  }
}
