import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { KrosPaginationComponent } from './kros-pagination.component';

@NgModule({
  declarations: [
    KrosPaginationComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    KrosPaginationComponent,
  ],
})
export class KrosPaginationModule { }
