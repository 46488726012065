import { CommonModule } from '@angular/common';
import { Component, inject, Input, TemplateRef, ViewChild } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { KrosModalRef, KrosModalService, MessageTypes } from '@kros-sk/components';
import { MarketingModalComponent, ProjectDetail, TranslateService } from '@kros-sk/ssw-shared-legacy';
import { PermissionService } from '@kros-sk/ssw-shared/permission';
import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { BuildingObjectsUploadLicenseHelper } from '../building-objects-upload-license.helper';
import { BuildingObjectsUploadMarketingContentHelper } from './building-objects-upload-marketing-content.helper';
import { BuildingObjectsUploadTableComponent } from '../building-objects-upload-table/building-objects-upload-table.component';
import { BuildingObjectsUploadToolbarComponent } from '../building-objects-upload-toolbar/building-objects-upload-toolbar.component';

@Component({
  selector: 'budget-building-objects-upload-modal',
  templateUrl: './building-objects-upload-modal.component.html',
  styleUrls: ['./building-objects-upload-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    BuildingObjectsUploadToolbarComponent,
    BuildingObjectsUploadTableComponent,
    TooltipDirective,
    TranslateModule
  ],
  providers: [
    BuildingObjectsUploadLicenseHelper,
    BuildingObjectsUploadMarketingContentHelper
  ]
})
export class BuildingObjectsUploadModalComponent {

  private krosModalService = inject(KrosModalService);
  private translateService = inject(TranslateService);
  private marketingContentHelper = inject(BuildingObjectsUploadMarketingContentHelper);
  private licenseHelper = inject(BuildingObjectsUploadLicenseHelper);
  private permissionService = inject(PermissionService);

  @ViewChild('modal') modalTemplate: TemplateRef<any>;
  @ViewChild(BuildingObjectsUploadTableComponent) buildingObjectsUploadTable: BuildingObjectsUploadTableComponent;

  @Input() project: ProjectDetail;
  @Input() launchedFrom: 'budget' | 'building-progress';
  @Input() budgetHasItems: boolean;
  @Input() isMainBudgetSelected;

  private modalRef: KrosModalRef;

  onOpen(): void {
    if (this.checkLicense()) {
      this.openUploadModal();
    }
  }

  onClose(): void {
    this.modalRef.cancel();
  }

  onUploadBuildingObjects(): void {
    this.buildingObjectsUploadTable.uploadBuildingObjects();
  }

  private checkLicense(): boolean {
    if (this.permissionService.isBuildingProgressReader()) {
      this.openBuildingProgressNoPermissionDialog();
      return false;
    }
    if (!this.licenseHelper.isLicenseFullOrTrial()) {
      this.openMarketingModal();
      return false;
    }
    if (this.licenseHelper.isLicenseFullOrTrial() && this.permissionService.isBudgetNoAccess()) {
      if (this.launchedFrom === 'budget') {
        this.openBuildingProgressNoPermissionDialog();
      } else if (this.launchedFrom === 'building-progress') {
        this.openBudgetNoPermissionDialog();
      }
      return false;
    }
    if (!this.isMainBudgetSelected && this.launchedFrom === 'budget') {
      this.openMainBudgetOnlyDialog();
      return false;
    }
    if (!this.budgetHasItems) {
      this.openBudgetEmptyDialog();
      return false;
    }
    return true;
  }

  private openUploadModal(): void {
    this.modalRef = this.krosModalService.openCentered(
      this.modalTemplate,
      {},
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: true,
        showMobileArrowBack: true
      },
      NaN, NaN, undefined, 'building-objects-upload'
    );
  }

  private openBuildingProgressNoPermissionDialog(): void {
    this.krosModalService.openModalMessageBoxModalRef({
      icon: 'warning',
      caption: this.translateService.translate('BUILDING_PROGRESS.NO_EDIT_PERMISSION_CAPTION'),
      message: this.translateService.translate('BUILDING_PROGRESS.NO_EDIT_PERMISSION_MESSAGE'),
      messageType: MessageTypes.Warning,
      acceptButton: this.translateService.translate('COMMON.CLOSE')
    });
  }

  private openBudgetNoPermissionDialog(): void {
    this.krosModalService.openModalMessageBoxModalRef({
      icon: 'warning',
      caption: this.translateService.translate('BUILDING_PROGRESS.NO_EDIT_PERMISSION_CAPTION'),
      message: this.translateService.translate('BUILDING_PROGRESS.NO_EDIT_PERMISSION_MESSAGE_BUDGET'),
      messageType: MessageTypes.Warning,
      acceptButton: this.translateService.translate('COMMON.CLOSE')
    });
  }

  private openBudgetEmptyDialog(): void {
    this.krosModalService.openModalMessageBoxModalRef({
      icon: 'warning',
      caption: this.translateService.translate('BUDGET.UPLOAD_TO_BUILDING_PROGRESS'),
      message: this.translateService.translate('BUDGET.EMPTY_BUDGET.BUDGET_IS_EMPTY'),
      messageType: MessageTypes.Warning,
      acceptButton: this.translateService.translate('COMMON.CLOSE')
    });
  }

  private openMainBudgetOnlyDialog(): void {
    this.krosModalService.openModalMessageBoxModalRef({
      icon: 'warning',
      caption: this.translateService.translate('BUDGET.UPLOAD_TO_BUILDING_PROGRESS'),
      message: this.translateService.translate('BUDGET.UPLOAD_TO_BUILDING_PROGRESS_ONLY_MAIN'),
      messageType: MessageTypes.Warning,
      acceptButton: this.translateService.translate('COMMON.CLOSE')
    });
  }

  private openMarketingModal(): void {
    this.marketingContentHelper.init({});

    this.krosModalService.openCentered(
      MarketingModalComponent,
      { contentHelper: this.marketingContentHelper },
      { allowFocusAutocapture: false }
    );
  }
}
