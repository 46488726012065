<div class="kros-modal-header">
  <div class="modal-title">
    <h4>{{ 'PROJECTS.ZOZNAM.TAGS' | translate }}</h4>
  </div>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onCloseClick()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div class="kros-modal-body">
  <div
    *ngFor="let tag of tags;"
    class="tag-row"
    data-test="tag"
    (click)="toggleTag(tag)"
  >
    <input
      type="checkbox"
      [checked]="isTagActive(tag)"
    />
    <kros-tag
      class="tag"
      [color]="tag.textColorCode"
      [backgroundColor]="tag.colorCode"
    >{{ tag.name | translate }}</kros-tag>
  </div>
</div>

<div class="kros-modal-footer">
  <button
    class="btn btn-primary"
    type="button"
    id="ga-project-tags-filter-submit"
    data-test="project-tags-filter-submit"
    (click)="onSubmitFilterClick()"
  >{{ 'TAGS.FILTER' | translate }}</button>

  <button
    class="btn btn-link"
    type="button"
    id="ga-project-tags-filter-cancel"
    data-test="project-tags-filter-cancel"
    (click)="onCancelFilterClick()"
  >{{ 'TAGS.CANCEL_FILTER' | translate }}</button>
</div>
