import { EntityAdapter, EntityState } from '@ngrx/entity';

import {
  BudgetItem,
  ChangedBudgetItems,
  emptyKey,
  IHasChildren,
  IItemType,
  IParentId,
  isConstructionOrBuildingObject,
  isItem,
  isSection,
  ToastService,
  ToastType,
  TranslateService
} from '@kros-sk/ssw-shared-legacy';
import { BuildingObject } from '@kros-sk/ssw-budget/shared/data-access/models';

export const getChanges = <T extends IParentId<string> & IHasChildren & IItemType>(
  budgetItems: EntityState<T>,
  adapter: EntityAdapter<T>,
  changedBudgetItems: ChangedBudgetItems,
  transformItem: (p) => any,
  hasChildren?: boolean
): EntityState<T> => {
  let ret = makeRootExpandable<T>(
    adapter, budgetItems, hasChildren ?? changedBudgetItems.createdItems?.length > 0);

  if (changedBudgetItems.deletedItems?.length) {
    ret = adapter.removeMany(changedBudgetItems.deletedItems.map(p => p.id), ret);
  }

  if (changedBudgetItems.createdItems?.length > 0) {
    const items = [...adapter.getSelectors().selectAll(ret)];
    changedBudgetItems.createdItems?.forEach(
      i => insertItem<T>(items, transformItem(i.tableItem), i.previousItemId, i.previousBuildingObjectId)
    );
    ret = adapter.setAll(items, ret);
  }

  if (changedBudgetItems.editedItems.length) {
    ret = adapter.updateMany(changedBudgetItems.editedItems.map(p => ({ id: p.id, changes: transformItem(p) })), ret);
  }

  ret = adapter.removeOne(-1, ret);

  return ret;
};

export const makeRootExpandable = <T extends IHasChildren>(
  adapter: EntityAdapter<T>,
  items: EntityState<T>,
  hasChildren: boolean
): EntityState<T> => {
  if (items.ids.length === 1) {
    return adapter.updateOne({
      id: items.ids[0] as number,
      changes: {
        hasChildren
      } as T
    }, items);
  }
  return items;
};

export const deleteEmptyItem = <T extends IParentId<string>>(items: T[], replacedParentId?: string, childrenToMove?: string[]): T[] => {
  const emptyItemIndex = items.findIndex(i => i.id === emptyKey);
  if (emptyItemIndex !== -1) {
    const emptyItemParentId = items[emptyItemIndex]?.parentId;
    const parentItemIndex = items.findIndex(i => i.id === emptyItemParentId);
    items.splice(emptyItemIndex, 1);
    if (replacedParentId && childrenToMove) {
      childrenToMove?.forEach(id => {
        const index = items.findIndex(i => i.id === id);
        items[index] = { ...items[index], parentId: replacedParentId };
      });
      const replacedParentIndex = items.findIndex(i => i.id === replacedParentId);
      items = setHasChildren(items, replacedParentIndex);
    }
    items = setHasChildren(items, parentItemIndex);
  }
  return items;
};

export const setHasChildren = <T extends IParentId<string>>(items: T[], itemIndex: number): T[] => {
  const hasChildren = items.some(i => i.parentId === items[itemIndex].id);
  items[itemIndex] = { ...items[itemIndex], hasChildren };
  return items;
};

export const insertEmptyItem = <T extends IHasChildren & IParentId<string> & IItemType>(
  originalItems: EntityState<T>,
  adapter: EntityAdapter<T>,
  item: T,
  previousItemId: string
): EntityState<T> => {
  const ret = makeRootExpandable<T>(adapter, originalItems, true) as EntityState<T>;
  const items = [...adapter.getSelectors().selectAll(ret)];
  insertItem<T>(items, item, previousItemId);
  return adapter.setAll(items, { ...ret, selected: item });
};

export const getItemPosition = <T extends BudgetItem | BuildingObject>(
  items: T[],
  itemIndex: number,
  addItems: T[] = []
): [T, [T[], boolean]] => {
  const item = items[itemIndex];
  let previousItemIndex = itemIndex - 1;
  let previousItem = items[previousItemIndex];

  while (previousItem.level > item.level) {
    previousItemIndex--;
    previousItem = items[previousItemIndex];
  }

  const wasInsidePreviousItem = previousItem.id === item.parentId;

  return [previousItem, [[item, ...addItems], wasInsidePreviousItem]];
};

export const showErrorToast = (messageKey: string, toastService: ToastService, translateService: TranslateService): void => {
  toastService.openAndCloseOthersByType(translateService.translate(messageKey), ToastType.Error, { delay: 3000 });
};

const insertItem = <T extends IParentId<string> & IItemType>(
  items: T[],
  item: T,
  previousItemId: string,
  previousBuildingObjectId?: string
): T[] => {
  let previousItemIndex = items.findIndex(i => i.id === previousItemId);
  if ((previousItemIndex === -1 || isConstructionOrBuildingObject(item)) && previousBuildingObjectId) {
    previousItemIndex = items.findIndex(i => i.id === previousBuildingObjectId);

    if (item.parentId === previousBuildingObjectId) {
      const parentItems = items.filter(i => i.parentId === previousBuildingObjectId && (isSection(i) || isItem(i)));
      if (parentItems.length > 0) {
        previousItemIndex = items.findIndex(i => i.id === parentItems[parentItems.length - 1].id);
      }
    }
  }
  if (previousItemIndex > -1) {
    items.splice(previousItemIndex + 1, 0, item);
    const parentItemIndex = items.findIndex(i => i.id === item.parentId);
    if (parentItemIndex > -1) {
      items[parentItemIndex] = { ...items[parentItemIndex], hasChildren: true };
    }
  }
  return items;
};
