<div
  class="sharing-list-card"
  data-test="sharing-list-item"
>
  <div
    class="card-content"
    [class.unsaved]="unsaved"
  >
    <div class="header">
      <div class="left-div">
        <div class="name-info">
          <kros-titan-avatar
            class="avatar-s"
            [email]="itemSharing.email"
            [icon]="itemSharing.isRegisteredUser ? '' : 'person_add_disabled'"
          ></kros-titan-avatar>
          <div class="user">
            <span
              class="user-mail"
              [class.deleted-right]="deleted"
            >
              {{ itemSharing.email }}
            </span>
            <span
              *ngIf="!unsaved && !itemSharing.isRegisteredUser"
              class="additional-info unregistered-text"
            >
              {{ 'SHARING.UNACTIVATED_USER' | translate }}
            </span>
            <span
              *ngIf="unsaved"
              class="additional-info unsaved-text"
              data-test="sharing-list-item-unsaved"
            >
              {{ 'SHARING.NOT_SAVED' | translate }}
            </span>
          </div>
        </div>
      </div>
      <div class="right-div">
        <ng-container *ngIf="!readonly; else readonlyRole">
          <kros-user-sharing-dropdown
            [sharingMode]="sharingMode"
            [defaultValues]="this.getDefaultDropdownValues()"
            [disabled]="deleted"
            [compactMode]="true"
            [menuDataTestValue]="'existing-dropdown'"
            (permissionChanged)="onPermissionChanged($event)"
          ></kros-user-sharing-dropdown>

          <i
            class="material-icons delete-button no-selection"
            (click)="deletePermission()"
            data-test="delete-sharing-button"
          >clear</i>
        </ng-container>
      </div>
    </div>

    <div
      *ngIf="unconfirmedSharing"
      class="message-div"
    >
      <textarea
        class="form-control message"
        placeholder="{{ 'SHARING.USER_MESSAGE_PLACEHOLDER' | translate }}"
        rows="3"
        [(ngModel)]="itemSharing.userMessage"
      ></textarea>
    </div>
  </div>
</div>

<ng-template #readonlyRole>
  <span
    class="readonly-role-name"
    [krosTooltip]="readonlyRoleTooltip"
  >{{ readonlyRoleName }}</span>
</ng-template>
