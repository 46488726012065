import { ActionCreator, createFeature, createReducer, on, ReducerTypes } from '@ngrx/store';

import * as approvalOrderActions from './approval-order.actions';
import { ApprovalOrderState, initialApprovalOrderState } from './approval-order.state';
import { buildingProgressFeatureKey } from './approval-order-selectors.service';

const reducers: ReducerTypes<ApprovalOrderState, ActionCreator[]>[] = [
  on(approvalOrderActions.loadApprovalOrderSuccess, (state, payload): ApprovalOrderState => {
    return { ...state, approvalOrders: payload.approvalOrders };
  }),
  on(approvalOrderActions.clearApprovalOrderStart, (state): ApprovalOrderState => {
    return { ...state, approvalOrders: [] };
  }),
  on(approvalOrderActions.saveApprovalOrderSuccess, (state, payload): ApprovalOrderState => {
    return { ...state, approvalOrders: payload.approvalOrders };
  }),
];

const reducer = createReducer(initialApprovalOrderState, ...reducers);

export const approvalOrderFeatureState = createFeature({
  name: buildingProgressFeatureKey,
  reducer
});
