import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { ApplicationType, areEmailsEqual } from '@kros-sk/ssw-cdk';
import { PermissionType } from '@kros-sk/ssw-shared/permission';

import { ItemSharingMultiple, ItemSharingMultiplePermission } from '../../models/item-sharing-multiple.model';
import { NewSharingComboMode } from '../../models/sharing-combo-mode.enum';
import { PermissionChangedDto } from '../../models/permission-changed-dto.model';
import { SharingPermissionMultipleItem, SharingPermissionValues } from '../../models/sharing-permission.interface';
import { UserSharingListItemBaseComponent } from '../user-sharing-list-item-base/user-sharing-list-item-base.component';

@Component({
  selector: 'kros-user-sharing-list-item-multiple',
  templateUrl: './user-sharing-list-item-multiple.component.html',
  styleUrls: ['./user-sharing-list-item-multiple.component.scss'],
  animations: [
    trigger(
      'expandCollapseAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0 }),
            animate('0.25s ease-out',
              style({ height: '*' })),
          ],
        ),
        transition(
          ':leave',
          [
            style({ height: '*' }),
            animate('0.25s ease-in',
              style({ height: 0 })),
          ],
        ),
      ],
    ),
  ]
})
export class UserSharingListItemMultipleComponent extends UserSharingListItemBaseComponent {
  @Input() itemSharing: ItemSharingMultiple;

  @Output() unconfirmedPermissionDeleted = new EventEmitter<ItemSharingMultiple>();

  newSharingComboMode = NewSharingComboMode;
  permissionItems: SharingPermissionMultipleItem[] = [];
  expanded = false;

  ngOnInit(): void {
    super.ngOnInit();
    this.sharingHelperService.resetState$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(userEmail => {
        if (areEmailsEqual(this.itemSharing.email, userEmail)) {
          this.unsaved = false;
        }
      });

    if (this.unconfirmedSharing) {
      this.expanded = true;
    }

    this.permissionItems = this.getPermissionItems();
    this.checkWarnings();
  }

  getReadonlyRoleName(item: SharingPermissionMultipleItem): string {
    const sharing = this.itemSharing.permissions.find(p => p.applicationType === item.applicationType);
    return this.permissionListService.getReadOnlyRoleName(
      this.getSharingMode(item), sharing.permissionType, sharing.additionalPermissionType
    );
  }

  getReadonlyRoleTooltip(item: SharingPermissionMultipleItem): string {
    const sharing = this.itemSharing.permissions.find(p => p.applicationType === item.applicationType);
    return this.permissionListService.getReadOnlyRoleTooltip(
      this.getSharingMode(item), sharing.permissionType, sharing.additionalPermissionType
    );
  }

  getLicenseTooltip(item: SharingPermissionMultipleItem): string {
    return this.permissionListService.getLicenseTooltip(item.applicationType);
  }

  hasLicense(item: SharingPermissionMultipleItem): boolean {
    return this.permissionListService.hasLicense(item.applicationType);
  }

  isReadOnly(item: SharingPermissionMultipleItem): boolean {
    const sharing = this.itemSharing.permissions.find(p => p.applicationType === item.applicationType);
    return this.readonly || sharing.permissionType === PermissionType.Owner;
  }

  getSharingMode(item: SharingPermissionMultipleItem): NewSharingComboMode {
    return this.permissionListService.getApplicationSharingComboMode(item.applicationType);
  }

  getDefaultDropdownValues(item: SharingPermissionMultipleItem): SharingPermissionValues {
    let permission: ItemSharingMultiplePermission;
    switch (item.applicationType) {
      case ApplicationType.BuildingProgress:
        permission = this.itemSharing.permissions.find(p => p.applicationType === ApplicationType.BuildingProgress);
        break;
      case ApplicationType.Documents:
        permission = this.itemSharing.permissions.find(p => p.applicationType === ApplicationType.Documents);
        break;
      case ApplicationType.Budget:
        permission = this.itemSharing.permissions.find(p => p.applicationType === ApplicationType.Budget);
        break;
      default:
        return null;
    }
    return { permission: permission.permissionType, additionalPermission: permission.additionalPermissionType };
  }

  deletePermission(): void {
    if (this.unconfirmedSharing) {
      this.unconfirmedPermissionDeleted.emit(this.itemSharing);
    } else {
      this.itemSharing.permissions.forEach(permission => {
        const changedPermissions = this.createPermissionChangedDto({
          permission: permission.permissionType,
          additionalPermission: permission.additionalPermissionType,
          applicationType: permission.applicationType
        });
        this.deletedPermission.emit(changedPermissions);
      });
    }

    this.unsaved = true;
    this.deleted = true;
  }

  onPermissionChanged(permission: SharingPermissionValues, item: ItemSharingMultiplePermission): void {
    if (!this.unconfirmedSharing) {
      const changedPermission = this.createPermissionChangedDto({ ...permission, applicationType: item.applicationType });
      this.unsaved = true;
      this.changedPermission.emit(changedPermission);
    }
    this.itemSharing.permissions.forEach(permissionItem => {
      if (permissionItem.applicationType === item.applicationType) {
        permissionItem.permissionType = permission.permission;
        permissionItem.additionalPermissionType = permission.additionalPermission;
      }
    });

    this.checkWarnings();
  }

  toggleExpand(): void {
    this.expanded = !this.expanded;
  }

  cancelChanges(): void {
    super.cancelChanges();
    this.checkWarnings();
  }

  private getPermissionItems(): SharingPermissionMultipleItem[] {
    return this.permissionListService.getPermissionMultipleList(this.sharingMode);
  }

  private createPermissionChangedDto(permission: SharingPermissionValues): PermissionChangedDto {
    const oldPermission = this.itemSharing.permissions.find(p => p.applicationType === permission.applicationType);
    return {
      oldPermission: oldPermission.permissionType,
      newPermission: permission.permission,
      oldAdditionalPermission: oldPermission.additionalPermissionType,
      newAdditionalPermission: permission.additionalPermission,
      email: this.itemSharing.email,
      isRegisteredUser: this.itemSharing.isRegisteredUser,
      applicationType: permission.applicationType
    };
  }

  private checkWarnings(): void {
    this.permissionItems.filter(p => p.expectedOtherPermissions).forEach(permissionItem => {
      permissionItem.canShowWarning = true;
      permissionItem.expectedOtherPermissions.forEach(expectedPermission => {
        const currentPermission = this.itemSharing.permissions.find(p => p.applicationType === expectedPermission.applicationType);
        if (!expectedPermission.permissions.includes(currentPermission?.permissionType)) {
          permissionItem.canShowWarning = false;
        }
      });
    });
  }
}
