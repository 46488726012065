<div class="header">
  <button
    type="button"
    class="btn btn-link"
    data-test="close-detail-button"
    (click)="toggleShowDetail()"
  >
    <i class="material-icons">arrow_back</i>
  </button>

  <h4 class="title">
    {{ currentItem.code }}
    <span *ngIf="isItem">&nbsp;&nbsp;&nbsp;{{ 'BUILDING_PROGRESS.TABLE.ITEM_NUMBER' | translate }}: {{
      currentItem.itemNumber }}</span>
  </h4>

  <kros-pie-chart
    *ngIf="!currentItem.isNotCompleted"
    [percentage]="currentItem.completedPercentage"
    [size]="35"
  ></kros-pie-chart>

  <span
    *ngIf="currentItem.isNotCompleted"
    class="text-centered"
  >
    <i
      class="material-icons icon-button"
      krosTooltip="{{ 'BUILDING_PROGRESS.LOCKED_ITEM' | translate }}"
    >lock</i>
  </span>

  <div ngbDropdown>
    <button
      *ngIf="canShowMore"
      ngbDropdownToggle
      type="button"
      class="btn"
      data-test="detail-more-button"
    >
      <i class="material-icons">more_vert</i>
    </button>
    <div ngbDropdownMenu>
      <button
        *ngIf="canShowComplete"
        ngbDropdownItem
        type="button"
        data-test="detail-more-complete-button"
        (click)="onCompleteClick()"
      >{{ 'BUILDING_PROGRESS.COMPLETE.COMPLETE' | translate }}</button>
    </div>
  </div>
</div>

<div class="body">
  <form [formGroup]="form">
    <div class="form-group">
      <label for="description">{{ 'BUILDING_PROGRESS.TABLE.DESCRIPTION' | translate }}</label>
      <kros-textarea-input [options]="{
                    formControl: form.get('description'),
                    name: 'description',
                    testName: 'description',
                    disableAutosize: false,
                    minAutosizeRows: 3,
                    type: 'text'
                }"></kros-textarea-input>
    </div>

    <div
      class="section-header"
      data-test="budget-section-header"
      (click)="budgetDataCollapse.toggle()"
    >
      <h4>{{ 'BUILDING_PROGRESS.OTHER_BUDGET_DATA' | translate }}</h4>
      <span>
        <i
          class="material-icons"
          [class.collapsed]="isBudgetDataCollapsed"
        >keyboard_arrow_up</i>
      </span>
    </div>

    <div
      #budgetDataCollapse="ngbCollapse"
      [(ngbCollapse)]="isBudgetDataCollapsed"
      (ngbCollapseChange)="onSectionCollapsedChange($event, budgetDataCollapsedIdKey)"
    >
      <div class="form-grid">
        <div *ngIf="isItem">
          <label for="measureUnit">{{ 'BUILDING_PROGRESS.TABLE.MEASURE_UNIT' | translate }}</label>
          <input
            id="measureUnit"
            class="form-control"
            formControlName="measureUnit"
            type="text"
          >
        </div>

        <kros-numeric-input
          *ngIf="isItem"
          [options]="{
                        name: 'amount',
                        inputClass: 'form-control text-right',
                        formControl: form.controls['amount'],
                        label: ('BUILDING_PROGRESS.TABLE.AMOUNT' | translate),
                        numericInput: {
                            minFractionDigits: decimalPlaces.amount,
                            maxFractionDigits: decimalPlaces.amount
                        },
                        testName: 'amount'
                    }"
        ></kros-numeric-input>

        <kros-numeric-input
          *ngIf="isItem"
          [options]="{
                        name: 'unitPrice',
                        inputClass: 'form-control text-right',
                        formControl: form.controls['unitPrice'],
                        label: ('BUILDING_PROGRESS.TABLE.UNIT_PRICE' | translate),
                        numericInput: {
                            minFractionDigits: decimalPlaces.unitPrice,
                            maxFractionDigits: decimalPlaces.unitPrice
                        },
                        testName: 'unit-price'
                    }"
        ></kros-numeric-input>

        <kros-numeric-input [options]="{
                        name: 'totalPrice',
                        inputClass: 'form-control text-right',
                        formControl: form.controls['totalPrice'],
                        label: ('BUILDING_PROGRESS.TABLE.TOTAL_PRICE' | translate),
                        numericInput: {
                            minFractionDigits: decimalPlaces.totalPrice,
                            maxFractionDigits: decimalPlaces.totalPrice
                        },
                        testName: 'total-price'
                    }"></kros-numeric-input>
      </div>
    </div>

    <ng-container *ngIf="selectedPeriod">
      <div
        class="section-header"
        data-test="period-section-header"
        (click)="periodDataCollapse.toggle()"
      >
        <h4>
          {{ 'BUILDING_PROGRESS.DRAWING' | translate }} {{ selectedPeriod.dateFrom | periodDate: selectedPeriod.dateTo
          }}
        </h4>
        <span>
          <i
            class="material-icons"
            [class.collapsed]="isPeriodDataCollapsed"
          >keyboard_arrow_up</i>
        </span>
      </div>

      <div
        #periodDataCollapse="ngbCollapse"
        [(ngbCollapse)]="isPeriodDataCollapsed"
        (ngbCollapseChange)="onSectionCollapsedChange($event, periodDataCollapsedIdKey)"
      >
        <div class="form-grid">
          <kros-numeric-input
            [options]="{
                            name: 'periodPercentage',
                            inputClass: 'form-control text-right',
                            formControl: form.controls['periodPercentage'],
                            label: '%',
                            autocompleteOff: true,
                            selectAllOnClick: true,
                            numericInput: {
                                minFractionDigits: decimalPlaces.percentage,
                                maxFractionDigits: decimalPlaces.percentage
                            },
                            testName: 'period-percentage'
                        }"
            (keydown)="onSubmitPercentage($event)"
            (focusout)="onSubmitPercentage()"
          ></kros-numeric-input>

          <kros-numeric-input
            *ngIf="isItem"
            [options]="{
                            name: 'periodAmount',
                            inputClass: 'form-control text-right',
                            formControl: form.controls['periodAmount'],
                            label: ('BUILDING_PROGRESS.TABLE.AMOUNT' | translate),
                            autocompleteOff: true,
                            selectAllOnClick: true,
                            numericInput: {
                                minFractionDigits: decimalPlaces.amount,
                                maxFractionDigits: 8
                            },
                            testName: 'period-amount'
                        }"
            (keydown)="onSubmitAmount($event)"
            (focus)="onPeriodAmountFocus()"
            (focusout)="onPeriodAmountFocusout()"
          ></kros-numeric-input>

          <kros-numeric-input
            [options]="{
                            name: 'periodTotalPrice',
                            inputClass: 'form-control text-right',
                            formControl: form.controls['periodTotalPrice'],
                            label: ('BUILDING_PROGRESS.TABLE.PRICE' | translate),
                            autocompleteOff: true,
                            selectAllOnClick: true,
                            numericInput: {
                                minFractionDigits: decimalPlaces.totalPrice,
                                maxFractionDigits: decimalPlaces.totalPrice
                            },
                            testName: 'period-total-price'
                        }"
            (keydown)="onSubmitTotalPrice($event)"
            (focusout)="onSubmitTotalPrice()"
          ></kros-numeric-input>
        </div>
      </div>
    </ng-container>

    <div
      class="section-header"
      data-test="summary-section-header"
      (click)="summaryDataCollapse.toggle()"
    >
      <h4>{{ 'BUILDING_PROGRESS.SUMMARY_DATA' | translate }}</h4>
      <span>
        <i
          class="material-icons"
          [class.collapsed]="isSummaryDataCollapsed"
        >keyboard_arrow_up</i>
      </span>
    </div>

    <div
      #summaryDataCollapse="ngbCollapse"
      [(ngbCollapse)]="isSummaryDataCollapsed"
      (ngbCollapseChange)="onSectionCollapsedChange($event, summaryDataCollapsedIdKey)"
    >
      <h5 class="section-header">{{ 'BUILDING_PROGRESS.TABLE.UNTIL_NOW_BUILT' | translate }}</h5>

      <div class="form-grid">
        <kros-numeric-input [options]="{
                        name: 'completedPercentage',
                        inputClass: 'form-control text-right',
                        formControl: form.controls['completedPercentage'],
                        label: '%',
                        numericInput: {
                            minFractionDigits: decimalPlaces.percentage,
                            maxFractionDigits: decimalPlaces.percentage
                        },
                        testName: 'completed-percentage'
                    }"></kros-numeric-input>

        <kros-numeric-input
          *ngIf="isItem"
          [options]="{
                        name: 'completedAmount',
                        inputClass: 'form-control text-right',
                        formControl: form.controls['completedAmount'],
                        label: ('BUILDING_PROGRESS.TABLE.AMOUNT' | translate),
                        numericInput: {
                            minFractionDigits: decimalPlaces.amount,
                            maxFractionDigits: decimalPlaces.amount
                        },
                        testName: 'completed-amount'
                    }"
        ></kros-numeric-input>

        <kros-numeric-input [options]="{
                        name: 'completedTotalPrice',
                        inputClass: 'form-control text-right',
                        formControl: form.controls['completedTotalPrice'],
                        label: ('BUILDING_PROGRESS.TABLE.PRICE' | translate),
                        numericInput: {
                            minFractionDigits: decimalPlaces.totalPrice,
                            maxFractionDigits: decimalPlaces.totalPrice
                        },
                        testName: 'completed-total-price'
                    }"></kros-numeric-input>
      </div>

      <h5 class="section-header">{{ 'BUILDING_PROGRESS.TABLE.REST' | translate }}</h5>

      <div class="form-grid">
        <kros-numeric-input [options]="{
                        name: 'restPercentage',
                        inputClass: 'form-control text-right',
                        formControl: form.controls['restPercentage'],
                        label: '%',
                        numericInput: {
                            minFractionDigits: decimalPlaces.percentage,
                            maxFractionDigits: decimalPlaces.percentage
                        },
                        testName: 'rest-percentage'
                    }"></kros-numeric-input>

        <kros-numeric-input
          *ngIf="isItem"
          [options]="{
                        name: 'restAmount',
                        inputClass: 'form-control text-right',
                        formControl: form.controls['restAmount'],
                        label: ('BUILDING_PROGRESS.TABLE.AMOUNT' | translate),
                        numericInput: {
                            minFractionDigits: decimalPlaces.amount,
                            maxFractionDigits: decimalPlaces.amount
                        },
                        testName: 'rest-amount'
                    }"
        ></kros-numeric-input>

        <kros-numeric-input [options]="{
                        name: 'restTotalPrice',
                        inputClass: 'form-control text-right',
                        formControl: form.controls['restTotalPrice'],
                        label: ('BUILDING_PROGRESS.TABLE.PRICE' | translate),
                        numericInput: {
                            minFractionDigits: decimalPlaces.totalPrice,
                            maxFractionDigits: decimalPlaces.totalPrice
                        },
                        testName: 'rest-total-price'
                    }"></kros-numeric-input>
      </div>

      <h5 class="section-header">{{ 'BUILDING_PROGRESS.TABLE.OVERDRAWN' | translate }}</h5>

      <div class="form-grid">
        <kros-numeric-input [options]="{
                        name: 'additionalPercentage',
                        inputClass: 'form-control text-right',
                        formControl: form.controls['additionalPercentage'],
                        label: '%',
                        numericInput: {
                            minFractionDigits: decimalPlaces.percentage,
                            maxFractionDigits: decimalPlaces.percentage
                        },
                        testName: 'additional-percentage'
                    }"></kros-numeric-input>

        <kros-numeric-input
          *ngIf="isItem"
          [options]="{
                        name: 'additionalAmount',
                        inputClass: 'form-control text-right',
                        formControl: form.controls['additionalAmount'],
                        label: ('BUILDING_PROGRESS.TABLE.AMOUNT' | translate),
                        numericInput: {
                            minFractionDigits: decimalPlaces.amount,
                            maxFractionDigits: decimalPlaces.amount
                        },
                        testName: 'additional-amount'
                    }"
        ></kros-numeric-input>

        <kros-numeric-input [options]="{
                        name: 'additionalTotalPrice',
                        inputClass: 'form-control text-right',
                        formControl: form.controls['additionalTotalPrice'],
                        label: ('BUILDING_PROGRESS.TABLE.PRICE' | translate),
                        numericInput: {
                            minFractionDigits: decimalPlaces.totalPrice,
                            maxFractionDigits: decimalPlaces.totalPrice
                        },
                        testName: 'additional-total-price'
                    }"></kros-numeric-input>
      </div>

      <h5 class="section-header">{{ 'BUILDING_PROGRESS.TABLE.NOT_COMPLETED' | translate }}</h5>

      <div class="form-grid">
        <kros-numeric-input [options]="{
                        name: 'notCompletedPercentage',
                        inputClass: 'form-control text-right',
                        formControl: form.controls['notCompletedPercentage'],
                        label: '%',
                        numericInput: {
                            minFractionDigits: decimalPlaces.percentage,
                            maxFractionDigits: decimalPlaces.percentage
                        },
                        testName: 'not-completed-percentage'
                    }"></kros-numeric-input>

        <kros-numeric-input
          *ngIf="isItem"
          [options]="{
                        name: 'notCompletedAmount',
                        inputClass: 'form-control text-right',
                        formControl: form.controls['notCompletedAmount'],
                        label: ('BUILDING_PROGRESS.TABLE.AMOUNT' | translate),
                        numericInput: {
                            minFractionDigits: decimalPlaces.amount,
                            maxFractionDigits: decimalPlaces.amount
                        },
                        testName: 'not-completed-amount'
                    }"
        ></kros-numeric-input>

        <kros-numeric-input [options]="{
                        name: 'notCompletedTotalPrice',
                        inputClass: 'form-control text-right',
                        formControl: form.controls['notCompletedTotalPrice'],
                        label: ('BUILDING_PROGRESS.TABLE.PRICE' | translate),
                        numericInput: {
                            minFractionDigits: decimalPlaces.totalPrice,
                            maxFractionDigits: decimalPlaces.totalPrice
                        },
                        testName: 'not-completed-total-price'
                    }"></kros-numeric-input>
      </div>
    </div>
  </form>
</div>
