<ng-container *ngIf="toolbarAction; else dropDownButton">
  <button
    class="btn toolbar-action gray-toolbar-action no-selection"
    type="button"
    krosTooltip="{{'DOCUMENTS.UPLOAD_VERSION'|translate}}"
    (click)="initializeUploader(fileInput)"
    krosAnalytic
    eventCategory="sprava-suborov"
    eventAction="nahrat-dokument"
    data-test="upload-version-button"
  >
    <i class="material-icons icon-button">update</i>
    <span class="toolbar-action-text">{{ 'DOCUMENTS.UPLOAD_VERSION' | translate }}</span>
  </button>
</ng-container>

<ng-template #dropDownButton>
  <button
    ngbDropdownItem
    class="btn link"
    type="button"
    [disabled]="disabled"
    (click)="initializeUploader(fileInput)"
    krosTooltip="{{'DOCUMENTS.UPLOAD_VERSION' | translate}}"
    data-test="upload-version-button"
    krosAnalytic
    eventCategory="sprava-suborov"
    eventAction="nahrat-dokument"
  >
    <i class="material-icons icon-button">update</i>
    <span>{{'DOCUMENTS.UPLOAD_VERSION' | translate}}</span>
  </button>
</ng-template>

<input
  type="file"
  ng2FileSelect
  class="file-upload-input"
  data-test="upload-version-input"
  [uploader]="uploader"
  (onFileSelected)="onFileAddVersionSelected()"
  #fileInput
/>
