import { Injectable } from '@angular/core';

import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { LicenseDetail } from '@kros-sk/ssw-cdk';

import { ApplicationModule } from '../../application-module';
import { LicenseInfo } from '../../license';
import { LicenseState } from '.';

const selectLicenseFeature = createFeatureSelector<LicenseState>('license');

const selectMishaLicenseInfo = createSelector(
  selectLicenseFeature,
  state => state.mishaLicenseInfo
);

const selectBuildingProgressLicenseInfo = createSelector(
  selectLicenseFeature,
  state => state.buildingProgressLicenseInfo
);

const selectBudgetLicenseInfo = createSelector(
  selectLicenseFeature,
  state => state.budgetLicenseInfo
);

const selectQuantityManagerLicenseInfo = createSelector(
  selectLicenseFeature,
  state => state.quantityManagerLicenseInfo
);

const selectLicenseDetail = createSelector(
  selectLicenseFeature,
  (state) : LicenseDetail => {
    return {
      licenseNumber: state.licenseNumber,
      isAutomaticRenewal: state.isAutomaticRenewalLicense
    } as LicenseDetail;
  }
);

const selectLicenseInfoByModule =
  (moduleId: ApplicationModule): any => createSelector(
    selectLicenseFeature,
    (state) => {
      switch (moduleId) {
        case ApplicationModule.Misha:
          return state.mishaLicenseInfo;
        case ApplicationModule.Budget:
          return state.budgetLicenseInfo;
        case ApplicationModule.QuantityManager:
          return state.quantityManagerLicenseInfo;
        case ApplicationModule.BuildingProgress:
          return state.buildingProgressLicenseInfo;
        case ApplicationModule.BudgetApproval:
          return state.buildingProgressLicenseInfo;
        default:
          return null;
      }
    }
  );

@Injectable()
export class LicenseSelectorsService {
  mishaLicenseInfo$: Observable<LicenseInfo> = this.store.select(selectMishaLicenseInfo);
  budgetLicenseInfo$: Observable<LicenseInfo> = this.store.select(selectBudgetLicenseInfo);
  buildingProgressLicenseInfo$: Observable<LicenseInfo> = this.store.select(selectBuildingProgressLicenseInfo);
  quantityManagerLicenseInfo$: Observable<LicenseInfo> = this.store.select(selectQuantityManagerLicenseInfo);
  licenseDetail$: Observable<LicenseDetail> = this.store.select(selectLicenseDetail);

  constructor(
    private store: Store<LicenseState>
  ) { }

  getLicenseInfoByModule(moduleId: ApplicationModule): Observable<LicenseInfo> {
    return this.store.select(selectLicenseInfoByModule(moduleId));
  }
}
