import { inject, Injectable } from '@angular/core';

import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ApplicationType, License } from '@kros-sk/ssw-cdk';

import { ConstructionDiaryState } from './construction-diary.state';

export const constructionDiaryFeatureKey = 'construction-diary';
const selectFeature = createFeatureSelector<ConstructionDiaryState>(constructionDiaryFeatureKey);

const selectProduct = createSelector(
  selectFeature,
  (state: ConstructionDiaryState) => state.product
);

const selectLicense = createSelector(
  selectFeature,
  (state: ConstructionDiaryState) => state.license
);

@Injectable()
export class ConstructionDiarySelectorsService {
  private store = inject(Store<ConstructionDiaryState>);

  product$: Observable<ApplicationType> = this.store.select(selectProduct);
  license$: Observable<License> = this.store.select(selectLicense);
}
