import { AbstractControl, NgControl } from '@angular/forms';
import { ConnectionPositionPair, FlexibleConnectedPositionStrategy, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';

import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';
import { fromEvent, merge, Observable } from 'rxjs';
import { SubSink } from 'subsink';

import { AutocompleteComponent } from './autocomplete.component';
import { GlobalEventsService } from '../services';

@Directive({
  selector: '[krosAutocomplete]'
})
export class AutocompleteDirective implements OnInit, OnDestroy {
  @Input() krosAutocomplete: AutocompleteComponent;

  @Input() maxOptionsShown: number;

  @Input() minOptionsShown: number;

  @Input() positions?: ConnectionPositionPair[];

  @Input() optionHeight?: number;

  @Input() valueAttribute?: string;

  @Input() inputFormater?: (item: any) => string;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur: EventEmitter<FocusEvent> = new EventEmitter();

  @Output() selectItem: EventEmitter<any> = new EventEmitter();

  private overlayRef: OverlayRef;
  private subSink = new SubSink();

  constructor(
    private host: ElementRef<HTMLInputElement>,
    private ngControl: NgControl,
    private vcr: ViewContainerRef,
    private overlay: Overlay,
    private globalEventsService: GlobalEventsService
  ) { }

  get control(): AbstractControl {
    return this.ngControl.control;
  }

  get origin(): HTMLInputElement {
    return this.host.nativeElement;
  }

  ngOnInit(): void {
    // null means no autocomplete
    if (this.krosAutocomplete === null) {
      return;
    }
    if (!this.maxOptionsShown && this.maxOptionsShown !== 0) {
      this.maxOptionsShown = 4;
    }

    if (!this.minOptionsShown || this.minOptionsShown < 0) {
      this.minOptionsShown = 0;
    }

    if (this.krosAutocomplete) {

      if (!this.optionHeight) this.optionHeight = 40;
      this.krosAutocomplete.optionHeight = this.optionHeight;
      this.krosAutocomplete.maxOptionShown = this.maxOptionsShown;
    }

    merge(
      fromEvent(this.host.nativeElement.parentElement, 'autoCompleteOptionsStateChanged'),
      fromEvent(this.origin, 'focus')
    ).subscribe((event: FocusEvent | CustomEvent) => {
      if (event.type === 'autoCompleteOptionsStateChanged' && event.detail.action === 'adCancelled') {
        this.close('cancelled');
      }
      if (
        event.type === 'autoCompleteOptionsStateChanged' &&
        event.detail.action !== 'optionsAdded') {
        return;
      }

      if (!this.krosAutocomplete) {
        return;
      }

      this.openDropdown();

      this.krosAutocomplete.optionsClick()
        .pipe(
          filter(x => x),
          takeUntil(this.overlayRef.detachments())
        )
        .subscribe((value: any) => {
          this.selectItem.emit(value);
          if (this.inputFormater) {
            this.control.setValue(this.inputFormater(value));
          } else {
            this.control.setValue(value);
          }
          this.origin.focus();
          this.origin.blur();
          this.close('selected', value);
        });

      this.krosAutocomplete.mouseAction()
        .pipe(
          takeUntil(this.overlayRef.detachments())
        )
        .subscribe();
    });
  }

  ngOnDestroy(): void {
    if (this.overlayRef) {
      this.close('cancelled');
    }
  }

  openDropdown(): void {
    if (this.overlayRef) {
      this.close();
    }

    this.krosAutocomplete.setActiveOptions(this.control.value, this.valueAttribute);
    const template = new TemplatePortal(this.krosAutocomplete.rootTemplate, this.vcr);
    document.body.classList.add('modal-opened');

    this.overlayRef = this.overlay.create({
      minWidth: this.origin.offsetWidth,
      width: 'min-content',
      minHeight: this.optionHeight * this.minOptionsShown + 16,
      maxHeight: this.optionHeight * this.maxOptionsShown + 16,
      backdropClass: '',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.getOverlayPosition()
    });
    this.overlayRef.attach(template);
    if (this.overlayRef.hasAttached()) {
      this.krosAutocomplete.emitAutoCompleteOptionsStateChanged('opened');
    }

    this.subSink.sink = this.globalEventsService.listenEvent('window:resize').pipe(debounceTime(300)).subscribe(() => {
      this.overlayRef.updateSize({
        minWidth: this.origin.offsetWidth
      });
    });

    this.subSink.sink = this.krosAutocomplete.closeModal$.subscribe(x => { if (x) this.close('cancelled'); });

    overlayClickOutside(this.overlayRef, this.origin).subscribe(() => this.close('cancelled'));
  }

  private close(action?: 'selected' | 'cancelled', selectedItem?: any): void {
    this.krosAutocomplete.emitAutoCompleteOptionsStateChanged(action, selectedItem);
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef = null;
    }
    document.body.classList.remove('modal-opened');
    this.subSink.unsubscribe();
  }

  private getOverlayPosition(): FlexibleConnectedPositionStrategy {
    const positions = [
      new ConnectionPositionPair(
        { originX: 'end', originY: 'bottom' },
        { overlayX: 'end', overlayY: 'top' },
        0, -1 // offset X, Y
      ),
      new ConnectionPositionPair(
        { originX: 'end', originY: 'top' },
        { overlayX: 'end', overlayY: 'bottom' },
        0, 0 // offset X, Y
      )
    ];

    return this.overlay
      .position()
      .flexibleConnectedTo(this.origin)
      .withFlexibleDimensions(true)
      .withGrowAfterOpen(true)
      .withPositions(this.positions ? this.positions : positions);
  }
}

export function overlayClickOutside(overlayRef: OverlayRef, origin: HTMLElement): Observable<MouseEvent> {
  let touchMove = false;
  fromEvent<MouseEvent>(document, 'touchmove').pipe(
    takeUntil(overlayRef.detachments())
  ).subscribe(() => {
    touchMove = true;
  });

  return merge(
    fromEvent<MouseEvent>(document, 'mousedown')
  ).pipe(
    filter(event => {
      if (touchMove) {
        touchMove = false;
        return false;
      }
      const clickTarget = event.target as HTMLElement;
      const notOrigin = clickTarget !== origin;
      const notOverlay = !!overlayRef && !overlayRef.overlayElement.contains(clickTarget);
      return notOrigin && notOverlay;
    }),
    tap(() => origin.blur()),
    takeUntil(overlayRef.detachments())
  );
}
