<div
  class="input-group"
  [ngStyle]="{ width: width }"
>
  <div class="input-group-prepend">
    <span
      class="input-group-text"
      [attr.data-test]="dataTestPrefix + 'search-icon'"
      (click)="onSearch()"
    >
      <i
        class="material-icons search-icon"
        krosTooltip="{{ 'FILTERS.SEARCH' | translate }}"
      >search</i>
    </span>
  </div>

  <input
    type="text"
    id="searchInput"
    class="form-control"
    placeholder="{{ 'COMMON.SEARCH' | translate }}"
    [formControl]="searchControl"
    [attr.data-test]="dataTestPrefix + 'search-input'"
    (keydown)="onSearchKeyDown($event)"
  />

  <div class="input-group-append">
    <button
      type="button"
      class="btn input-group-text btn-sm"
      [attr.data-test]="dataTestPrefix + 'search-clear-button'"
      (click)="cancelSearch()"
    >
      <i
        class="material-icons"
        krosTooltip="{{ 'FILTERS.SEARCH_CLEAR' | translate }}"
      >close</i>
    </button>
  </div>

  <div
    *ngIf="showOptions"
    ngbDropdown
    class="input-group-append"
    container="body"
  >
    <button
      type="button"
      class="btn input-group-text btn-sm"
      [attr.data-test]="dataTestPrefix + 'search-mode-button'"
      ngbDropdownToggle
    >
      <i
        class="material-icons"
        krosTooltip="{{ 'FILTERS.SEARCH_OPTIONS' | translate }}"
      >more_horiz</i>
    </button>

    <div
      ngbDropdownMenu
      [ngStyle]="{ width: width }"
    >
      <div
        class="dropdown-form"
        [formGroup]="radioGroupForm"
      >
        <h6>{{ 'FILTERS.SEARCH_RESULT' | translate }}</h6>

        <div class="form-group one-row">
          <label>
            <input
              id="searchModeAll"
              type="radio"
              formControlName="searchMode"
              [attr.data-test]="dataTestPrefix + 'search-mode-all-radio'"
              [value]="'all'"
              (click)="onRadioButtonClick()"
            >
            <span [attr.data-test]="dataTestPrefix + 'search-mode-text'">{{ 'FILTERS.SEARCH_MODE_ALL' | translate
              }}</span>
          </label>
        </div>

        <div class="form-group one-row">
          <label>
            <input
              id="searchModeAny"
              type="radio"
              formControlName="searchMode"
              [attr.data-test]="dataTestPrefix + 'search-mode-any-radio'"
              [value]="'any'"
              (click)="onRadioButtonClick()"
            >
            <span [attr.data-test]="dataTestPrefix + 'search-mode-text'">{{ 'FILTERS.SEARCH_MODE_ANY' | translate
              }}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
