import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { DirectivesModule } from '../directives';
import { KrosMenuItemComponent } from './menu/menu-item/menu-item.component';
import { KrosNavItemDirective } from './nav-item.directive';
import { NavigationComponent } from './navigation.component';
import { PipesModule } from '../pipes';
import { SubMenuItemDirective } from './menu/sub-menu-item.directive';

const DECLARATIONS = [
  KrosMenuItemComponent,
  KrosNavItemDirective,
  SubMenuItemDirective,
  NavigationComponent,
];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [
    CommonModule,
    NgbNavModule,
    DirectivesModule,
    PipesModule,
    TranslateModule,
    TooltipDirective
  ],
  exports: [...DECLARATIONS],
})
export class KrosNavigationModule { }
