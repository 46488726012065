import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { StorageService } from '@kros-sk/ssw-cdk';

import { ActionInProgress } from '../../enums';
import { BudgetApprovalHelper } from '../../helpers';
import { BudgetApprovalSelectorsService } from '../../../store/budget-approval';
import { changeSheetTableId } from '../../constants/budget-approval-constants';
import { ProjectsSelectorsService } from '../../../store/projects';

const changeSheetPanelCollapsedStorageItem = 'changeSheetPanelCollapsed';

@Component({
  selector: 'app-change-sheet',
  templateUrl: './change-sheet.component.html',
  styleUrls: ['./change-sheet.component.scss']
})
export class ChangeSheetComponent implements OnInit {

  private destroyRef = inject(DestroyRef);
  private budgetApprovalSelectorsService = inject(BudgetApprovalSelectorsService);
  private storageService = inject(StorageService);
  private router = inject(Router);
  private projectsSelectorsService = inject(ProjectsSelectorsService);
  private budgetApprovalHelper = inject(BudgetApprovalHelper);

  panelCollapsed = false;
  projectId: number;
  tableId = changeSheetTableId;
  actionInProgress$: Observable<ActionInProgress>;
  actionInProgress = ActionInProgress;

  ngOnInit(): void {
    this.budgetApprovalHelper.setTitleAndNavbar();

    this.panelCollapsed = JSON.parse(this.storageService.getItemFromLocalStorage(changeSheetPanelCollapsedStorageItem));
    this.projectsSelectorsService.projectDetail$.pipe(filter(p => !!p), takeUntilDestroyed(this.destroyRef))
      .subscribe(p => this.projectId = p.id);
    this.actionInProgress$ = this.budgetApprovalSelectorsService.actionInProgress$;
  }

  onPanelCollapsed(collapsed: boolean): void {
    this.panelCollapsed = collapsed;
    this.storageService.setItemToLocalStorage(changeSheetPanelCollapsedStorageItem, this.panelCollapsed.toString());
  }

  onBackClick(): void {
    this.router.navigate(['projects/project-detail', this.projectId, 'budget-approval']);
  }
}
