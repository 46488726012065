import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { catchError, filter, find, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { InfoModalComponent, ToastService, ToastType, TranslateService } from '@kros-sk/ssw-shared-legacy';
import { KrosModalService } from '@kros-sk/components';

import {
  BuildingProgressAmountsUniXmlExportModel,
  BuildingProgressConfirmationProtocolExportModel,
  BuildingProgressInvoiceDetailExportModel,
  BuildingProgressItemDrawSheetModel,
  BuildingProgressModel,
  BuildingProgressRemainingBudgetModel,
  BuildingProgressTableExportModel,
  CoverSheetExportTypeEnum,
} from '../models';
import {
  BuildingProgressExportAmountsUnixmlComponent
} from '../modules/export/building-progress-export-amounts-unixml/building-progress-export-amounts-unixml.component';
import { BuildingProgressExportComponent } from '../modules/export/building-progress-export/building-progress-export.component';
import {
  BuildingProgressExportConfirmationProtocolComponent
} from '../modules/export/building-progress-export-confirmation-protocol/building-progress-export-confirmation-protocol.component';
import { BuildingProgressExportDispatchersService } from '../../store/building-progress/building-progress-export-dispatchers.service';
import {
  BuildingProgressExportInvoiceDetailsComponent
} from '../modules/export/building-progress-export-invoice-details/building-progress-export-invoice-details.component';
import {
  BuildingProgressExportItemDrawSheetComponent
} from '../modules/export/building-progress-export-item-draw-sheet/building-progress-export-item-draw-sheet.component';
import {
  BuildingProgressExportRemainingBudgetComponent
} from '../modules/export/building-progress-export-remaining-budget/building-progress-export-remaining-budget.component';
import {
  BuildingProgressExportToAnotherProjectComponent
} from '../modules/export/building-progress-export-to-another-project/building-progress-export-to-another-project.component';
import { BuildingProgressExportToAnotherProjectService } from '../modules/export/services';
import { BuildingProgressSelectorsService } from '../../store/building-progress';
import { BuildingProgressService } from '../services/building-progress.service';
import { PeriodHelper } from './period.helper';

@Injectable()
export class ExportHelper {
  private projectName: string;

  private destroyRef = inject(DestroyRef);
  private buildingProgressService = inject(BuildingProgressService);
  private periodService = inject(PeriodHelper);
  private krosModalService = inject(KrosModalService);
  private translateService = inject(TranslateService);
  private toastService = inject(ToastService);
  private buildingProgressExportDispatchersService = inject(BuildingProgressExportDispatchersService);
  private buildingProgressSelectorsService = inject(BuildingProgressSelectorsService);
  private buildingProgressExportToAnotherProjectService = inject(BuildingProgressExportToAnotherProjectService);
  private appInsightsService = inject(AppInsightsBaseService);

  constructor() {
    this.buildingProgressExportDispatchersService.loadTableExportSettings();
  }

  setProjectData(projectName: string): void {
    this.projectName = projectName;
  }

  onOpenExportToExcel(
    itemIds: number[],
    periodIds: number[],
    summaryViewActive: boolean,
    projectId: number,
    subcontractorId?: number
  ): void {
    this.buildingProgressSelectorsService.tableExportSettings$
      .pipe(
        filter(tableExportSettings => !!tableExportSettings),
        take(1),
        switchMap(tableExportSettings => {
          const defaultExportModel: BuildingProgressTableExportModel = {
            projectId,
            isSingleSheetExport: tableExportSettings.isSingleSheetExport,
            areColorCodesIncluded: tableExportSettings.areColorCodesIncluded,
            itemIds,
            periodIds,
            subcontractorId
          };
          const modalRef = this.krosModalService.openCentered(
            BuildingProgressExportComponent,
            {
              exportModel: defaultExportModel,
              showSingleSheetExportCheck: !summaryViewActive
            },
            {
              closeOnBackdropClick: false,
              fullscreenOnMobile: false,
              showMobileArrowBack: false
            }
          );
          return modalRef.afterClosed$;
        }),
        filter((result: any) => result.type === 'submit'),
        takeUntilDestroyed(this.destroyRef))
      .subscribe((result: any) => this.exportToTable(result.data));
  }

  private exportToTable(tableExportModel: BuildingProgressTableExportModel): void {
    this.buildingProgressExportDispatchersService.changeTableExportSettings(
      {
        isSingleSheetExport: tableExportModel.isSingleSheetExport,
        areColorCodesIncluded: tableExportModel.areColorCodesIncluded
      }
    );
    this.buildingProgressService.exportTable(
      { ...tableExportModel },
      this.translateService.uiCulture, this.projectName
    )
      .pipe(take(1))
      .subscribe({ error: (err) => this.toastService.open(err.message, ToastType.Error) });
  }

  onOpenExportInvoiceDetails(constructionData: BuildingProgressModel, subcontractorId?: number): void {
    this.buildingProgressService.getInvoicingSettings().subscribe(settings => {
      const defaultExportModel: BuildingProgressInvoiceDetailExportModel = {
        projectId: constructionData.projectId,
        periodIds: [this.periodService.getSelectedOrLastPeriodId()],
        buildingObjectIds: [],
        coverSheetExportType: CoverSheetExportTypeEnum.Summary,
        isIncludedConfirmationProtocol: false,
        isIncludedCompletedWorks: false,
        isIncludedBuildingObjectsSummary: false,
        isIncludedSectionsSummary: false,
        exportDate: '',
        isCompletedWorksObjects: false,
        completedWorksSettings: settings.completedWorksSettings,
        summarySettings: settings.summarySettings,
        subcontractorId,
        generalSettings: settings.generalSettings
      };

      const modalRef = this.krosModalService.openCentered(
        BuildingProgressExportInvoiceDetailsComponent,
        {
          exportModel: defaultExportModel,
          periods: constructionData?.periods
        },
        {
          closeOnBackdropClick: false
        }
      );

      modalRef.afterClosed$.pipe(take(1)).subscribe(result => {
        if (result.type === 'submit') {
          this.exportInvoicingData(result.data);
        }
      });
    });
  }

  private exportInvoicingData(exportModel: BuildingProgressInvoiceDetailExportModel): void {
    this.buildingProgressService.exportInvoicingData(
      { ...exportModel }, this.translateService.uiCulture, this.projectName)
      .pipe(take(1))
      .subscribe({ error: (err) => this.handleErr(err) });
  }

  private handleErr(err: any, handleBadRequest: boolean = false): void {
    if (err.status === 404 || err.status === 409) {
      this.krosModalService.openCentered(
        InfoModalComponent,
        { body: err.error },
        {
          closeOnBackdropClick: false,
          fullscreenOnMobile: false,
          showMobileArrowBack: false
        }
      );
    } else if (handleBadRequest && err.status === 400) {
      this.toastService.open(
        this.translateService.translate('BUILDING_PROGRESS.EXPORT_AMOUNTS_TO_ANOTHER_PROJECT_CANNOT'),
        ToastType.Error
      );
    } else {
      this.toastService.open(err.message, ToastType.Error);
    }
  }

  onOpenExportAmountsUniXml(constructionData: BuildingProgressModel, subcontractorId?: number): void {
    const defaultExportModel: BuildingProgressAmountsUniXmlExportModel = {
      projectId: constructionData.projectId,
      periodIds: [],
      buildingObjectIds: [],
      subcontractorId
    };
    const modalRef = this.krosModalService.openCentered(
      BuildingProgressExportAmountsUnixmlComponent,
      {
        exportModel: defaultExportModel,
        initPeriodId: this.periodService.getSelectedOrLastPeriodId(),
        periods: constructionData?.periods
      },
      {
        closeOnBackdropClick: false
      }
    );

    modalRef.afterClosed$.pipe(take(1)).subscribe(result => {
      if (result.type === 'submit') {
        this.exportAmountsUniXmlData(result.data);
      }
    });
  }

  private exportAmountsUniXmlData(exportModel: BuildingProgressAmountsUniXmlExportModel): void {
    this.buildingProgressService.exportAmountsUniXml({ ...exportModel }, this.translateService.uiCulture)
      .pipe(take(1))
      .subscribe({ error: (err) => this.handleErr(err) });
  }

  onOpenConfirmationProtocol(constructionData: BuildingProgressModel): void {
    const defaultExportModel: BuildingProgressConfirmationProtocolExportModel = {
      projectId: constructionData.projectId,
      periodId: this.periodService.getSelectedOrLastPeriodId(),
      buildingObjectIds: [],
    };
    const modalRef = this.krosModalService.openCentered(
      BuildingProgressExportConfirmationProtocolComponent,
      {
        exportModel: defaultExportModel,
        periods: constructionData?.periods
      },
      {
        closeOnBackdropClick: false
      }
    );
    modalRef.afterClosed$.pipe(take(1)).subscribe(result => {
      if (result.type === 'submit') {
        this.exportConfirmationProtocol(result.data);
      }
    });
  }

  private exportConfirmationProtocol(exportModel: BuildingProgressConfirmationProtocolExportModel): void {
    this.buildingProgressService.exportConfirmationProtocolXml({ ...exportModel }, this.translateService.uiCulture)
      .pipe(take(1))
      .subscribe({ error: (err) => this.handleErr(err) });
  }

  onOpenExportItemDrawSheet(constructionData: BuildingProgressModel): void {
    this.buildingProgressExportDispatchersService.loadItemDrawSheetExportSettings(constructionData.projectId);
    this.buildingProgressSelectorsService.itemDrawSheetExportSettings$.pipe(
      find(settings => !!settings),
      withLatestFrom(this.buildingProgressSelectorsService.selectedItemIds$),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(([settings, selectedIds]) => {
      const defaultExportModel: BuildingProgressItemDrawSheetModel = {
        projectId: constructionData.projectId,
        periodIds: [this.periodService.getSelectedOrLastPeriodId()],
        contractorName: settings.contractorName || '',
        supervisorName: settings.supervisorName || '',
        areDetailsIncluded: settings.areDetailsIncluded,
        exportDate: '',
        itemIds: Array.from(selectedIds.values())
      };
      const modalRef = this.krosModalService.openCentered(
        BuildingProgressExportItemDrawSheetComponent,
        {
          exportModel: defaultExportModel,
          periods: constructionData.periods
        },
        {
          closeOnBackdropClick: false
        }
      );
      modalRef.afterClosed$.pipe(take(1)).subscribe(result => {
        if (result.type === 'submit') {
          this.exportItemDrawSheet(result.data.exportModel);
        }
      });
    });
  }

  onOpenExportRemainingBudget(constructionData: BuildingProgressModel, subcontractorId?: number): void {
    this.buildingProgressExportDispatchersService.loadRemainingBudgetExportSettings(constructionData.projectId);
    this.buildingProgressSelectorsService.remainingBudgetExportSettings$
      .pipe(find(settings => !!settings), takeUntilDestroyed(this.destroyRef))
      .subscribe(settings => {
        const defaultExportModel: BuildingProgressRemainingBudgetModel = {
          projectId: constructionData.projectId,
          exportZeroRemainingBudgetItems: settings?.exportZeroRemainingBudgetItems || false,
          isSingleSheetExport: settings?.isSingleSheetExport || false,
          subcontractorId
        };
        const modalRef = this.krosModalService.openCentered(
          BuildingProgressExportRemainingBudgetComponent,
          {
            exportModel: defaultExportModel
          },
          {
            closeOnBackdropClick: false
          }
        );
        modalRef.afterClosed$.pipe(take(1)).subscribe(result => {
          if (result.type === 'submit') {
            this.exportRemainingBudget(result.data.exportModel);
          }
        });
      });
  }

  onOpenExportToAnotherProject(constructionData: BuildingProgressModel): void {
    this.krosModalService.openCentered(
      BuildingProgressExportToAnotherProjectComponent,
      {
        projectId: constructionData.projectId,
        periods: constructionData.periods,
        initPeriodId: this.periodService.getSelectedOrLastPeriodId(),
      },
      {
        closeOnBackdropClick: false,
      }
    ).afterClosed$
      .pipe(
        take(1),
        filter((result: any) => result && result.type === 'submit'),
        switchMap((result: any) => {
          this.appInsightsService.trackEvent('PV-export-to-another-project');
          return this.buildingProgressExportToAnotherProjectService.copyPeriodItems(
            result.data.exportModel,
            constructionData.projectId);
        }),
        catchError((err) => {
          this.handleErr(err, true);
          return EMPTY;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private exportItemDrawSheet(exportModel: BuildingProgressItemDrawSheetModel): void {
    this.buildingProgressService.exportItemDrawSheet({ ...exportModel }, this.translateService.uiCulture, this.projectName)
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe({ error: (err) => this.handleErr(err) });
  }

  private exportRemainingBudget(exportModel: BuildingProgressRemainingBudgetModel): void {
    this.buildingProgressService.exportRemainingBudget({ ...exportModel }, this.translateService.uiCulture, this.projectName)
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe({ error: (err) => this.handleErr(err) });
  }
}
