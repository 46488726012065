import { Component, inject, OnInit } from '@angular/core';

import { BrowserTabService, NavbarService } from '@kros-sk/ssw-shared-legacy';
import { StorageService, UserService } from '@kros-sk/ssw-cdk';

import { environment } from '../../../../environments/environment';

const subcontractorsPanelCollapsedStorageItem = 'subcontractorsPanelCollapsed';

@Component({
  selector: 'app-subcontractors',
  templateUrl: './subcontractors.component.html',
  styleUrls: ['./subcontractors.component.scss']
})
export class SubcontractorsComponent implements OnInit {

  private storageService = inject(StorageService);
  private userService = inject(UserService);
  private navbarService = inject(NavbarService);
  private browserTabService = inject(BrowserTabService);

  panelCollapsed: boolean;
  tableId = 'subcontractorsTable';

  get panelCollapsedStorageItemKey(): string {
    return subcontractorsPanelCollapsedStorageItem + '-' + this.userService.userId;
  }

  ngOnInit(): void {
    this.browserTabService.setTitle('BUILDING_PROGRESS.TITLE');
    this.navbarService.changeTitle('BUILDING_PROGRESS.TITLE');
    this.navbarService.setNewsData({
      app: 'Titan',
      isTest: environment.news.isTest.toString(),
      modul: 'PV'
    });

    this.panelCollapsed = JSON.parse(this.storageService.getItemFromLocalStorage(this.panelCollapsedStorageItemKey));
  }

  onPanelCollapsed(collapsed: boolean): void {
    this.panelCollapsed = collapsed;
    this.storageService.setItemToLocalStorage(this.panelCollapsedStorageItemKey, this.panelCollapsed.toString());
  }
}
