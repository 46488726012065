import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter, map, take } from 'rxjs';

import { KrosModalRef } from '@kros-sk/components';
import { Project } from '@kros-sk/ssw-shared-legacy';

import { BuildingProgressCopyPeriodsModel, BuildingProgressPeriod } from '../../../models';
import { BuildingProgressExportToAnotherProjectService } from '../services';
import { BuildingProgressPeriodService } from '../../../services/building-progress-periods/building-progress-period.service';

@Component({
  selector: 'app-building-progress-export-to-another-project',
  templateUrl: './building-progress-export-to-another-project.component.html',
  styleUrls: ['./building-progress-export-to-another-project.component.scss'],
})
export class BuildingProgressExportToAnotherProjectComponent implements OnInit {
  form: FormGroup<{
    sourcePeriod: FormControl<BuildingProgressPeriod>;
    targetPeriod: FormControl<BuildingProgressPeriod | null>;
  }>;
  sourceProjectPeriods: BuildingProgressPeriod[];
  sourceProjectPeriod: BuildingProgressPeriod;
  targetProjectPeriods: BuildingProgressPeriod[] = [];
  targetProjects: Project[] = [];
  targetProjectName = '';
  creatingPeriod = false;
  searchButton = {
    icon: 'material-icons search-icon',
    stylingMode: 'text'
  };

  get hasPeriods(): boolean {
    return this.sourceProjectPeriods.length > 0;
  }

  get lastTargetPeriod(): BuildingProgressPeriod {
    return this.targetProjectPeriods[this.targetProjectPeriods.length - 1];
  }

  protected lastPeriod: BuildingProgressPeriod;
  protected targetProject: Project = null;

  private destroyRef = inject(DestroyRef);
  private fb = inject(UntypedFormBuilder);
  private modalRef = inject(KrosModalRef);
  private exportService = inject(BuildingProgressExportToAnotherProjectService);
  private buildingProgressPeriodService = inject(BuildingProgressPeriodService);

  private targetProjectPeriod: BuildingProgressPeriod;

  constructor() {
    this.sourceProjectPeriods = this.modalRef.data.periods;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      sourcePeriod: new FormControl(null, Validators.required),
      targetPeriod: new FormControl(null, Validators.required),
    });

    this.selectInitialPeriod();

    this.form.controls.sourcePeriod.valueChanges
      .pipe(filter((selectedPeriod) => !!selectedPeriod), takeUntilDestroyed(this.destroyRef))
      .subscribe((selectedPeriod) => {
        this.sourceProjectPeriod = selectedPeriod;
        this.setTargetPeriod(selectedPeriod);
      });

    this.form.controls.targetPeriod.valueChanges
      .pipe(filter((selectedPeriod) => !!selectedPeriod), takeUntilDestroyed(this.destroyRef))
      .subscribe((p) => {
        this.targetProjectPeriod = p;
        this.setLastPeriod();
      });

    this.exportService.getProjects()
      .pipe(map((projects) => projects.filter((p) => p.id !== this.modalRef.data.projectId)), takeUntilDestroyed(this.destroyRef))
      .subscribe((projects: Project[]) => this.targetProjects = projects);
  }

  onClose(): void {
    this.modalRef.cancel();
  }

  onSubmit(): void {
    const exportModel: BuildingProgressCopyPeriodsModel = {
      sourcePeriodId: this.sourceProjectPeriod.id,
      destinationProjectId: this.targetProject.id,
      destinationPeriodId: this.targetProjectPeriod.id
    };
    this.modalRef.submit({ exportModel });
  }

  onComparePeriods(periodA: Partial<BuildingProgressPeriod> | null, periodB: Partial<BuildingProgressPeriod> | null): boolean {
    return periodA?.dateFrom === periodB?.dateFrom && periodA?.dateTo === periodB?.dateTo;
  }

  onOpenCreatingPeriod(): void {
    this.creatingPeriod = true;
  }

  onCloseCreatingPeriod(): void {
    this.creatingPeriod = false;
  }

  onCreatePeriod(periodDate: { from: Date; to: Date }): void {
    this.buildingProgressPeriodService
      .createPeriod(this.targetProject.id, periodDate.from, periodDate.to)
      .pipe(take(1))
      .subscribe(() => this.updateTargetProjectPeriods());
    this.creatingPeriod = false;
  }

  protected onUpdateTargetProject(e: any): void {
    if (e.selectedItem && typeof e.selectedItem === 'object') {
      this.targetProject = e.selectedItem;
      this.updateTargetProjectPeriods();
    } else {
      this.targetProject = null;
      this.targetProjectName = '';
      this.targetProjectPeriods = [];
    }
  }

  private selectInitialPeriod(): void {
    this.sourceProjectPeriod = this.sourceProjectPeriods.find(p => p.id === this.modalRef.data.initPeriodId);
    const indexOfSourcePeriod = this.sourceProjectPeriod && this.sourceProjectPeriods.findIndex(
      p => this.onComparePeriods(p, this.sourceProjectPeriod));
    if (indexOfSourcePeriod > -1) {
      this.form.controls.sourcePeriod.setValue(this.sourceProjectPeriods[indexOfSourcePeriod]);
    }
    this.setLastPeriod();
  }

  private updateTargetProjectPeriods(): void {
    this.buildingProgressPeriodService
      .getPeriods(this.targetProject.id)
      .pipe(take(1))
      .subscribe((periods) => {
        this.targetProjectPeriods = periods.sort((a, b) => a.id - b.id);
        this.setTargetPeriod(this.sourceProjectPeriod);
      });
  }

  private setTargetPeriod(selectedPeriod: BuildingProgressPeriod): void {
    const indexOfTargetPeriod = this.targetProjectPeriods?.findIndex((p) => this.onComparePeriods(p, selectedPeriod));

    if (indexOfTargetPeriod > -1) {
      this.form.controls.targetPeriod.setValue(this.targetProjectPeriods[indexOfTargetPeriod]);
    } else if (this.targetProjectPeriods?.length > 0) {
      this.selectLastTargetPeriod();
    } else {
      this.form.controls.targetPeriod.setValue(null);
    }
  }

  private selectLastTargetPeriod(): void {
    this.form.get('targetPeriod').setValue(this.targetProjectPeriods[this.targetProjectPeriods.length - 1]);
  }

  private setLastPeriod(): void {
    if (this.onComparePeriods(this.sourceProjectPeriod, this.targetProjectPeriod)) {
      this.lastPeriod = this.targetProjectPeriods[this.targetProjectPeriods.length - 1];
    } else {
      const lastDateFrom = new Date(this.sourceProjectPeriod.dateFrom);
      lastDateFrom.setMonth(lastDateFrom.getMonth() - 1);

      const lastDateTo = new Date(this.sourceProjectPeriod.dateTo);
      lastDateTo.setMonth(lastDateTo.getMonth() - 1);

      this.lastPeriod = {
        ...this.sourceProjectPeriod,
        dateFrom: lastDateFrom,
        dateTo: lastDateTo
      };
    }
  }
}
