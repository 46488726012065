import { Directive, Input } from '@angular/core';

import { Constructor } from '../../tools';

export interface CanForbidCharacters {
  removeForbiddenCharacters(value: string): string;

  isCharacterAllowed(character: string): boolean;
}

type CanForbidCharactersCtor = Constructor<CanForbidCharacters>;

export function Forbidable<TBase extends Constructor<{}>>(base: TBase): CanForbidCharactersCtor & TBase {

  @Directive()
  class Forbidable extends base {
    @Input() krosForbiddenCharacters = '';

    private emojiRegExp = new RegExp(
      [
        /[\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|/,
        /\uD83D[\uDC00-\uDFFF]|[\u20AD-\u26FF]|\uD83E[\uDD10-\uDDFF]|/,
        /[\u200B-\u200F\uFEFF\uFE0F]/,
      ].map(r => r.source).join(''),
      'g',
    );

    private get forbiddenCharactersRegExp(): RegExp {
      if (!this._forbiddenCharactersRegExp) {
        this._forbiddenCharactersRegExp = new RegExp(this.forbiddenCharacters, 'g');
      }
      return this._forbiddenCharactersRegExp;
    }
    private _forbiddenCharactersRegExp: RegExp;

    removeForbiddenCharacters(value: string): string {
      if (value && typeof value === 'string') {
        if (this.areSetForbiddenCharacters) {
          value = value.replace(this.forbiddenCharactersRegExp, '');
        }

        return value.replace(this.emojiRegExp, '');
      }
      return value;
    }

    isCharacterAllowed(character: string): boolean {
      const isCharacterAllowed =
        !this.areSetForbiddenCharacters || !character.match(this.forbiddenCharactersRegExp);
      const isEmojiAllowed = !character.match(this.emojiRegExp);
      return isCharacterAllowed && isEmojiAllowed;
    }

    private get areSetForbiddenCharacters(): boolean {
      return this.krosForbiddenCharacters.length > 0;
    }

    private get forbiddenCharacters(): string {
      return Array.isArray(this.krosForbiddenCharacters)
        ? this.krosForbiddenCharacters.join('|')
        : this.krosForbiddenCharacters;
    }
  }

  return Forbidable;
}
