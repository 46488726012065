import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { KrosModalRef } from '@kros-sk/components';
import { TranslateService } from '@kros-sk/ssw-shared-legacy';

import { BuildingProgressPeriod } from '../../../../building-progress/models/building-progress-period.model';

@Component({
  selector: 'app-period-delete-dialog',
  templateUrl: './period-delete-dialog.component.html',
  styleUrls: ['./period-delete-dialog.component.scss']
})
export class PeriodDeleteDialogComponent implements OnInit {
  data: { period: BuildingProgressPeriod };
  periodDeleteForm: UntypedFormGroup;
  periodWithInvoiceWarning: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalRef: KrosModalRef,
    private translateService: TranslateService
  ) {
    this.data = this.modalRef.data;
    this.periodDeleteForm = this.formBuilder.group(
      {
        deletePeriodGallery: false
      }
    );
  }

  ngOnInit(): void {
    this.periodWithInvoiceWarning = this.translateService.translate('BUILDING_PROGRESS.INVOICE.DELETE_PERIOD_WARNING');
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  onSubmit(): void {
    this.modalRef.submit({
      deletePeriodGallery: this.periodDeleteForm.controls['deletePeriodGallery'].value
    });
  }
}
