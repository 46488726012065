import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TextFieldModule } from '@angular/cdk/text-field';

import { AddClassOnMouseOverDirective } from './add-class-mouse-on-over/add-class-on-mouse-over.directive';
import { DetectFocusWithinDirective } from './detect-focus-within/detect-focus-within.directive';
import { DisableElementDirective } from './disable-element/disable-element.directive';
import { FileDropZoneModule } from './file-drop-zone/file-drop-zone.module';
import { FocusableInputDirective } from './focus-first-ivalid-input/focusable-input.directive';
import { FocusFirstInvalidInputDirective } from './focus-first-ivalid-input/focus-first-ivalid-input.directive';
import { IfFeatureEnabledDirective } from './if-feature-enabled/if-feature-enabled.directive';
import { IfWidthAboveDirective } from './window-size/if-above/if-above.directive';
import { IfWidthBetweenDirective } from './window-size/if-between/if-between.directive';
import { IfWidthUnderDirective } from './window-size/if-under/if-under.directive';
import { InputAutoSizeDirective } from './input-autosize.directive';
import { KrosTextareaAutosize } from './textarea-autosize.directive';
import { LimitedNumberingDirective } from './limited-numbering/limited-numbering.directive';
import { MarkableAsInvalidDirective } from './mark-invalid-input/markable-input.directive';
import { MarkAllAsTouchedInputDirective } from './mark-invalid-input/mark-touched-on-invalidinput.directive';
import { MobileHeightDirective } from './mobile-height/mobile-height.directive';
import { NumericInputDirective } from './numeric-input/numeric-input.directive';
import { PortToDirective } from './port-to/port-to.directive';
import { ShowPasswordDirective } from './show-password/show-password.directive';

const DIRECTIVES = [
  AddClassOnMouseOverDirective,
  DisableElementDirective,
  DetectFocusWithinDirective,
  FocusableInputDirective,
  FocusFirstInvalidInputDirective,
  MarkAllAsTouchedInputDirective,
  MarkableAsInvalidDirective,
  IfFeatureEnabledDirective,
  IfWidthAboveDirective,
  IfWidthBetweenDirective,
  IfWidthUnderDirective,
  InputAutoSizeDirective,
  KrosTextareaAutosize,
  LimitedNumberingDirective,
  MobileHeightDirective,
  NumericInputDirective,
  PortToDirective,
  ShowPasswordDirective
];

@NgModule({
  declarations: [
    ...DIRECTIVES,
  ],
  imports: [
    CommonModule,
    TextFieldModule,
    FileDropZoneModule
  ],
  exports: [
    ...DIRECTIVES,
    FileDropZoneModule,
  ],
})
export class DirectivesModule { }
