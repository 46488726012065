import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AuthDispatchersService } from './auth-dispatchers.service';
import { AuthEffects } from './auth.effects';
import { authReducer } from './auth.reducer';
import { AuthSelectorsService } from './auth-selectors.service';

@NgModule({
  imports: [
    StoreModule.forFeature('auth', authReducer),
    EffectsModule.forFeature([ AuthEffects ]),
  ],
  providers: [
    AuthDispatchersService,
    AuthSelectorsService,
  ]
})
export class AuthStoreModule { }
