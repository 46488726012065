import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { Platform } from '@angular/cdk/platform';

import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { ResultTemplateContext } from '@ng-bootstrap/ng-bootstrap/typeahead/typeahead-window';
import { SubSink } from 'subsink';

import { AutocompleteComponent } from '../../autocomplete/autocomplete.component';
import { DeviceDetectorService, GlobalEventsService } from '../../services';
import { InputCommand, InputCommandType } from '../inputs.common.interface';
import { InputOptions, InputType } from './input.interface';
import { KrosFormsService } from '../forms.service';
import { KrosInputBase } from '../inputs.common.base.component';

@Component({
  selector: 'kros-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => KrosInputComponent),
      multi: true
    },
    {
      provide: KrosInputBase,
      useExisting: forwardRef(() => KrosInputComponent)
    }
  ]
})
export class KrosInputComponent extends KrosInputBase implements AfterViewInit, ControlValueAccessor, OnInit, OnDestroy {
  @Input() options: InputOptions;

  @Output() selectItem: EventEmitter<any> = new EventEmitter();

  @ViewChild('autocomplete', { static: false }) autocomplete: AutocompleteComponent;

  @ViewChild('defaultTypeaheadTemplate', { static: true }) defaultTypeaheadTemplate: TemplateRef<ResultTemplateContext>;

  @ViewChild('longTextTooltipTemplate') longTextTooltipTemplate: TemplateRef<any>;

  autocompleteOptions: readonly any[] = [];
  currentOptions: InputOptions;
  rect: DOMRect;
  initialScroll: number;
  formControl: UntypedFormControl;
  autocompleteTrigger$ = new Subject();
  changes$: Observable<any>;
  keyPress = new EventEmitter<KeyboardEvent>();
  showSearchIndicator$: Observable<boolean>;

  private keyPressSubscription = new SubSink();
  private globalEvents: GlobalEventsService;
  private ignoreAutocompleteOnFocus: boolean;
  private optionRemoval: boolean;
  private userChange: boolean;
  private showSearchIndicatorSubject = new BehaviorSubject<boolean>(false);

  @HostListener('focusout') focusout = (): void => {
    setTimeout(() => {
      this.input.nativeElement.scrollLeft = 0; // Firefox fix, don't ask
    }, 0);

    setTimeout(() => {
      if (this.optionRemoval) {
        this.optionRemoval = false;
      } else if (this.platform.IOS) {
        this.autocompleteOptions = [];
        this.detectChanges();
        this.input.nativeElement.blur();
      }
    }, 500); // timeout is avoiding bluring input before selecting drodpown option
  };

  @HostListener('keydown', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === 'Tab') {
      this.input.nativeElement.focus();
      this.hovered = false;
    }
  }

  constructor(
    protected cd: ChangeDetectorRef,
    protected globalEventsService: GlobalEventsService,
    protected injector: Injector,
    protected formsService: KrosFormsService,
    protected deviceDetector: DeviceDetectorService,
    protected platform: Platform,
    private renderer: Renderer2,
    private scrollDispatcher: ScrollDispatcher,
  ) {
    super(cd, globalEventsService, injector, formsService, deviceDetector);
    this.globalEvents = globalEventsService;
    this.showSearchIndicator$ = this.showSearchIndicatorSubject.asObservable();
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (!this.currentOptions.type) {
      this.currentOptions.type = InputType.TEXT;
    }
    this.formControl = new UntypedFormControl({
      value: this.mainControl.value,
      disabled: this.mainControl.disabled
    }, { updateOn: this.mainControl.updateOn });
    this.subs.sink = this.formControl.valueChanges.subscribe((newValue) => {
      this.mainControl.markAsTouched();
      this.formControl.markAsTouched();
      this.handleValueEmits(newValue);
    });

    if (this.currentOptions.longTextTooltip) {
      this.subs.sink = this.globalEventsService.listenEvent('window:resize').pipe(debounceTime(300)).subscribe(() => {
        this.showInfoForLongTexts();
      });
    }

    if (this.currentOptions.autocomplete?.searchMethod) this.initAutocomplete();

    // listen changes form outside
    this.subs.sink = this.mainControl.valueChanges.subscribe((newValue) => {
      if (this.formControl.disabled && this.mainControl.enabled) {
        this.formControl.enable({ emitEvent: false });
      } else if (this.formControl.enabled && this.mainControl.disabled) {
        this.formControl.disable({ emitEvent: false });
      }
      if (this.userChange) {
        this.userChange = false;
      } else {
        this.setFormControlFromMainControlValue(newValue);
      }
      this.showInfoForLongTexts();
    });
  }

  ngAfterViewInit(): void {
    this.showInfoForLongTexts();

    this.subs.sink = fromEvent(this.container.nativeElement, 'autoCompleteOptionsStateChanged')
      .subscribe((event: CustomEvent) => {
        if (event.detail.action === 'opened') {
          this.detectChanges();
        }
        if (event.detail.action === 'selected') {
          this.showInfoForLongTexts();
        }
      });
  }

  get width(): number {
    if (this.container?.nativeElement) {
      return this.container.nativeElement.offsetWidth - 45;
    }
    return 0;
  }

  get searchIndicatorPositionClass(): 'on-left-side' | 'on-right-side' {
    return (this.currentOptions.autocomplete?.searchIndicatorPosition === 'left')
      ? 'on-left-side'
      : 'on-right-side';
  }

  doFocus(event: FocusEvent): void {
    super.doFocus(event);
    if (this.ignoreAutocompleteOnFocus) {
      this.ignoreAutocompleteOnFocus = false;
    } else if (this.currentOptions.autocomplete?.openOnFocus) {
      this.autocompleteTrigger$.next(this.mainControl.value);
    }

    this.keyPressSubscription.sink = this.globalEvents.listenEvent('document:keydown').subscribe((evt) => {
      this.keyPress.emit(evt as KeyboardEvent);
      this.detectChanges();
    });
    // timeout to give time to scroll input to correct position before start listening to scroll event...
    setTimeout(() => this.subscribeScrollDispatcher(), 500);

  }

  doBlur(event: FocusEvent): void {
    if (this.forceBlur || (!this.hovered && !this.autocomplete.optionHovered)) {
      this.autocomplete.closeModal$.next(true);
      super.doBlur(event);
      this.mainControl.markAsTouched();
      this.formControl.markAsTouched();
      this.showSearchIndicatorSubject.next(false);
      this.keyPressSubscription.unsubscribe();
      this.forceBlur = false;
    } else {
      this.mainControl.markAsTouched();
      this.formControl.markAsTouched();
      this.focused = false;
    }
  }

  selectedItem(event: any): void {
    this.forceBlur = true;
    this.doBlur(new FocusEvent('blur'));
    this.selectItem.emit(event);
  }

  protected handleCommand(command: InputCommand): void {
    if (command.type === InputCommandType.CLOSE_AUTOCOMPLETE && this.currentOptions.autocomplete) {
      this.autocompleteOptions = [];
      this.detectChanges();
      this.input.nativeElement.blur();
    } else if (command.type === InputCommandType.REMOVE_AUTOCOMPLETE_OPTION) {
      if (typeof command.data?.value !== 'undefined') {
        this.autocompleteOptions = this.autocompleteOptions.filter((item) => {
          if (command.data.comparator) {
            return !command.data.comparator(item, command.data.value);
          }
          return item !== command.data.value;
        });
        this.ignoreAutocompleteOnFocus = true;
        this.optionRemoval = true;
        this.detectChanges();
        this.input.nativeElement.focus();
      } else {
        console.error('No value passed to REMOVE_AUTOCOMPLETE_OPTION command!');
      }
    } else if (command.type === InputCommandType.DETECT_CHANGES) {
      this.setFormControlFromMainControlValue(this.mainControl.value);
      this.detectChanges();
    } else if (command.type === InputCommandType.DETECT_CHANGES_AND_DISABLED_STATE) {
      this.setFormControlFromMainControlValue(this.mainControl.value);
      if (this.mainControl.disabled) {
        this.formControl.disable({ emitEvent: false });
      } else {
        this.formControl.enable({ emitEvent: false }); // never emit event to avoid cycling(this.mainControl.value);
      }
      this.detectChanges();
    } else if (command.type === InputCommandType.DETECT_RESIZE) {
      this.initialScroll = null;
      this.detectChanges();
    } else {
      super.handleCommand(command);
    }
  }

  protected handleValueEmits(newValue: any): void {
    if (!this.input) return;
    const caretPosition = this.formsService.getCaretPosition(this.input.nativeElement);
    newValue = this.removeForbiddenCharacters(newValue);
    if (newValue === this.mainControl.value) {
      this.formControl.setValue(newValue, { emitEvent: false });
      this.formsService.setCaretPosition(this.input.nativeElement, caretPosition);
      return;
    }
    this.userChange = true;
    const origValue = this.mainControl.value;
    if (this.currentOptions.transformValue) {
      const transformedValue = this.currentOptions.transformValue(newValue);
      this.mainControl.setValue(transformedValue);
      this.formControl.setValue(transformedValue, { emitEvent: false });
    } else {
      this.mainControl.setValue(newValue);
      this.formControl.setValue(newValue, { emitEvent: false });
    }
    this.formsService.setCaretPosition(this.input.nativeElement, caretPosition);

    if (origValue !== newValue) {
      this.mainControl.markAsDirty();
    }
  }

  protected setFormControlFromMainControlValue(value: any): void {
    value = this.removeForbiddenCharacters(value);
    this.formControl.setValue(value, { emitEvent: false }); // never emit event to avoid cycling
  }

  protected removeForbiddenCharacters(value: any): any {
    if (value && typeof value === 'string' && this.areSetForbiddenCharacters) {
      const forbiddenCharactersRegex = new RegExp(this.forbiddenCharacters, 'g');
      value = value.replace(forbiddenCharactersRegex, '');
    }

    return super.removeForbiddenCharacters(value);
  }

  protected showInfoForLongTexts(): void {
    if (!this.currentOptions.longTextTooltip) return;
    // Creates a temporary invisible span, which is then compared to the input.
    // The simple solution using scroll and offset width wasn't working on safari (#16471)
    const tempSpan = this.renderer.createElement('span') as HTMLSpanElement;
    tempSpan.classList.add('form-control');
    tempSpan.style.position = 'fixed';
    tempSpan.style.zIndex = '-9999';
    tempSpan.style.width = 'auto';
    tempSpan.textContent = this.mainControl.value;
    this.renderer.appendChild(this.container.nativeElement, tempSpan);

    let width = tempSpan.clientWidth;
    if (this.deviceDetector.isSafari) width++;

    if (width > this.input.nativeElement.clientWidth) {
      this.formsService.triggerInputCommand(
        this.options.name,
        {
          type: InputCommandType.CHANGE_OPTIONS,
          data: {
            info: this.longTextTooltipTemplate
          }
        }
      );
    } else {
      if (!this.showError) {
        this.formsService.triggerInputCommand(
          this.options.name,
          { type: InputCommandType.CHANGE_OPTIONS, data: { info: null } }
        );
      }
    }

    this.renderer.removeChild(this.container.nativeElement, tempSpan);
  }

  private initAutocomplete(): void {
    this.changes$ = merge(
      this.autocompleteTrigger$,
      this.mainControl.valueChanges.pipe(
        filter(term => term.length && this.userChange),
        debounceTime(300),
        distinctUntilChanged()
      )
    );
    this.subs.sink = this.currentOptions.autocomplete.searchMethod(this.changes$.pipe(
      tap((value: string) => {
        this.autocompleteOptions = [];
        this.autocomplete.reset();
        if (this.focused && this.showSearchIndicator(value)) {
          this.showSearchIndicatorSubject.next(true);
        }
      })
    )).subscribe((options) => {
      this.showSearchIndicatorSubject.next(false);
      this.autocompleteOptions = !this.focused ? [] : options; // Stops dropdown from opening, if user quickly tabs through the input
      const action = this.autocompleteOptions.length === 0 ? 'noOptions' : 'optionsAdded';
      this.autocomplete.emitAutoCompleteOptionsStateChanged(action);
      this.detectChanges();
      this.autocomplete.setActiveOptions(this.mainControl.value, this.currentOptions.autocomplete?.valueAttribute);
      this.detectChanges();
    });
  }

  private subscribeScrollDispatcher(): void {
    this.keyPressSubscription.sink = this.scrollDispatcher.scrolled().subscribe((x: CdkScrollable) => {
      let scrollTop = window.pageYOffset || window.scrollY;
      if (x) {
        scrollTop = x.getElementRef().nativeElement.scrollTop;
      }
      if (!this.initialScroll) {
        this.initialScroll = scrollTop;
        this.rect = this.container.nativeElement.getBoundingClientRect();
      }
      this.hideIfNotVisible(scrollTop);
    });
  }

  private hideIfNotVisible(scrollHeight: number): void {
    if (this.focused) {
      if (!this.platform.IOS &&
        (
          this.rect.top + this.initialScroll < scrollHeight - this.rect.height ||
          this.rect.top + this.initialScroll > scrollHeight + this.deviceDetector.windowHeight ||
          this.isOverlapped()
        )) {
        this.autocompleteOptions = [];
        this.detectChanges();
        this.input.nativeElement.blur();
      }
    }
  }

  private showSearchIndicator(value: string): boolean {
    if (this.currentOptions.autocomplete.searchIndicatorShownFrom) {
      return value.length >= this.currentOptions.autocomplete.searchIndicatorShownFrom;
    }
    return true;
  }

  private get areSetForbiddenCharacters(): boolean {
    return this.options.forbiddenCharacters?.length > 0;
  }

  private get forbiddenCharacters(): string {
    return Array.isArray(this.options.forbiddenCharacters) ? this.options.forbiddenCharacters.join('|') : this.options.forbiddenCharacters;
  }
}
