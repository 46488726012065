import { CdkTreeModule } from '@angular/cdk/tree';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';

import { AlertDialogModule } from '@kros-sk/ssw-cdk';
import {
  AnalyticsModule,
  DirectivesModule,
  DocumentEditModule,
  documentsFeatureKey,
  DocumentsPermissionsService,
  TranslateModule,
  UploadModule,
  VersionModule
} from '@kros-sk/ssw-shared-legacy';
import { FileUploadModule } from 'ng2-file-upload';

import { AdBlockCheckerService } from './services/adblock-checker/adblock-checker.service';
import { DocumentDescriptionModalComponent } from './components/document-description-modal/document-description-modal.component';
import { DocumentExplorerTreeComponent } from './components/document-explorer-tree/document-explorer-tree.component';
import { DocumentListComponent } from './components/document-list/document-list.component';
import { DocumentListContainerComponent } from './containers/document-list-container/document-list-container.component';
import { DocumentListToolbarComponent } from './components/document-list-toolbar/document-list-toolbar.component';
import { DocumentModalService } from './services/document-modal.service';
import { DocumentPanelContainerComponent } from './containers/document-panel-container/document-panel-container.component';
import { DocumentPanelDialogComponent } from './components/document-panel-dialog/document-panel-dialog.component';
import { DocumentsComponent } from './documents.component';
import { DocumentsDispatchersService, documentsReducer, DocumentsSelectorsService } from '../store/documents';
import { DocumentService } from './services/document/document.service';
import { DocumentSharingDialogComponent } from './components/document-sharing-dialog/document-sharing-dialog.component';
import { DocumentSharingService } from './services/document-sharing/document-sharing.service';
import { DocumentsOpenerService } from './services/documents-opener.service';
import { DocumentVersionListModalComponent } from './components/document-version-list-modal/document-version-list-modal.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    DocumentsComponent,
    DocumentExplorerTreeComponent,
    DocumentListComponent,
    DocumentListContainerComponent,
    DocumentListToolbarComponent,
    DocumentPanelContainerComponent,
    DocumentPanelDialogComponent,
    DocumentSharingDialogComponent,
    DocumentVersionListModalComponent,
    DocumentDescriptionModalComponent
  ],
  exports: [
    DocumentsComponent
  ],
  imports: [
    AlertDialogModule,
    AnalyticsModule,
    CdkTreeModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    FileUploadModule,
    SharedModule,
    StoreModule.forFeature(documentsFeatureKey, documentsReducer),
    UploadModule,
    DocumentEditModule,
    VersionModule
  ],
  providers: [
    AdBlockCheckerService,
    DocumentService,
    DocumentsDispatchersService,
    DocumentsOpenerService,
    DocumentsPermissionsService,
    DocumentsSelectorsService,
    DocumentSharingService,
    DocumentModalService,
  ]
})
export class DocumentsModule {
}
