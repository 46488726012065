import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlMatchResult, UrlSegment } from '@angular/router';

import { BudgetSettingsComponent, BuildingProgressSettingsComponent, GeneralSettingsComponent, SettingsComponent } from './components';
import { BuildingProgressBasePathSegment } from './model';

const buildingProgressUrlMatcher = (url: UrlSegment[]): UrlMatchResult | null => {
  const index = url.findIndex(segment => segment.path === BuildingProgressBasePathSegment);
  if (index > -1) {
    return {
      consumed: url,
    };
  }
  return null;
};

const routes: Routes = [
  {
    path: ':projectId',
    component: SettingsComponent,
    children: [
      {
        path: '',
        redirectTo: 'general',
        pathMatch: 'full'
      },
      {
        path: 'general',
        component: GeneralSettingsComponent
      },
      {
        path: 'budget',
        component: BudgetSettingsComponent
      },
      {
        matcher: buildingProgressUrlMatcher,
        component: BuildingProgressSettingsComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
