import { ActionCreator, createFeature, createReducer, on, ReducerTypes } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import {
  budgetDeleteActions,
  budgetItemsSharedActions,
  buildingObjectsSharedActions,
  deleteEmptyItem,
  insertEmptyItem
} from '@kros-sk/ssw-budget/shared/data-access/store';
import { BuildingObject } from '@kros-sk/ssw-budget/shared/data-access/models';

import * as actions from './building-objects.actions';
import { adapterBuildingObjects, BuildingObjectsState, initialBuildingObjectsState } from './building-objects.state';
import { buildingObjectsFeatureKey } from './building-objects-selectors.service';
import { getBuildingObjectChangesAfterCreate, getBuildingObjectsChanges } from './reducer-helpers';

export const buildingObjectsReducers: ReducerTypes<BuildingObjectsState, ActionCreator[]>[] = [
  on(buildingObjectsSharedActions.loadBuildingObjectsStart,
    (state): BuildingObjectsState => ({
      ...state,
      areBuildingObjectsFullyLoaded: false,
      canLoadState: false
    })),
  on(buildingObjectsSharedActions.loadBuildingObjectsSuccess, (state, payload): BuildingObjectsState =>
    adapterBuildingObjects.setAll(payload.objects, {
      ...state,
      selected: payload.objects?.find(p => p.id === state.selected?.id),
      areBuildingObjectsFullyLoaded: false,
      canLoadState: true
    })),
  on(buildingObjectsSharedActions.createCustomBuildingObjectStart,
    buildingObjectsSharedActions.moveBuildingObjectsStart,
    (state): BuildingObjectsState => ({
      ...state,
      isSaving: true
    })),
  on(buildingObjectsSharedActions.createCustomBuildingObjectSuccess, (state, payload): BuildingObjectsState =>
    getBuildingObjectChangesAfterCreate({ ...state, isSaving: false }, payload.changedBudgetItems)
  ),
  on(buildingObjectsSharedActions.createCustomBuildingObjectError,
    buildingObjectsSharedActions.moveBuildingObjectsError,
    (state): BuildingObjectsState => ({
      ...state,
      isSaving: false,
      selectAfterCreate: false
    })),
  on(actions.createEmptyBuildingObject, (state, payload): BuildingObjectsState =>
    insertEmptyItem<BuildingObject>(
      state,
      adapterBuildingObjects,
      payload.item.tableItem,
      payload.item.previousItemId ?? payload.item.previousBuildingObjectId) as BuildingObjectsState
  ),
  on(actions.editEmptyBuildingObject, (state, payload): BuildingObjectsState => {
    const changes = {};
    changes[payload.propertyName] = payload.value;
    return adapterBuildingObjects.updateOne({ id: -1, changes }, state);
  }),
  on(actions.deleteEmptyBuildingObject, (state): BuildingObjectsState =>
    adapterBuildingObjects.setAll(
      deleteEmptyItem<BuildingObject>(adapterBuildingObjects.getSelectors().selectAll(state)),
      {
        ...state,
        selected: null
      },
    )),
  on(actions.selectBuildingObjectAfterCreate, (state): BuildingObjectsState => ({
    ...state,
    selectAfterCreate: true
  })),
  on(actions.selectBuildingObject, (state, payload): BuildingObjectsState => ({
    ...state,
    selected: payload.buildingObject,
    selectAfterCreate: false
  })),
  on(buildingObjectsSharedActions.moveBuildingObjectsSuccess, (state, payload): BuildingObjectsState => {
    const newSelected = payload.changedBudgetItems.createdItems.find(i => i.tableItem.id === state.selected.id)?.tableItem
      ?? payload.changedBudgetItems.createdItems[0].tableItem;
    return !payload.isUndoRedoOperation ? { ...state, selected: { ...newSelected } } : state;
  }),
  on(budgetItemsSharedActions.budgetUpdateActions, (state, payload): BuildingObjectsState =>
    getBuildingObjectsChanges(
      { ...state, isSaving: false },
      payload.changedBudgetItems,
      undefined,
      (payload as any)?.canSetHasChildren)
  ),
  on(budgetItemsSharedActions.moveBudgetItemsMultipleSuccess, (state, payload): BuildingObjectsState =>
    payload.changedBudgetItemsMultiple.reduce(
      (prev, curr) => getBuildingObjectsChanges({ ...prev, isSaving: false }, curr),
      state
    )),
  on(budgetItemsSharedActions.editBudgetItemError, (state): BuildingObjectsState => ({
    ...state,
    isSaving: false
  })),
  on(budgetItemsSharedActions.editBudgetItemStart, (state, payload): BuildingObjectsState => ({
    ...state,
    isSaving: payload.isBuildingObject
  })),
  on(...budgetDeleteActions, (state, payload): BuildingObjectsState =>
    getBuildingObjectsChanges({ ...state }, payload.changedBudgetItems)
  ),
  on(actions.uploadBuildingObjectsSuccess, (state, payload): BuildingObjectsState => {
    const createUpdateEntry = (p) => ({
      id: p.buildingObjectId,
      changes: { uploadDate: p.timestamp }
    }) as Update<BuildingObject>;
    const updates = [
      ...payload.buildingObjectsUploadDates.createdItems?.map(createUpdateEntry),
      ...payload.buildingObjectsUploadDates.editedItems?.map(createUpdateEntry)
    ];
    return adapterBuildingObjects.updateMany(updates, state);
  }),
  on(actions.loadBuildingObjectsStateSuccess, (state, payload) =>
    adapterBuildingObjects.updateMany(
      payload.buildingObjects.map(o => ({ id: o.id, changes: { state: o.state, email: o.email, dateChanged: o.dateChanged } })),
      {
        ...state,
        canLoadState: false,
        areBuildingObjectsFullyLoaded: true
      }
    )
  ),
  on(actions.loadBuildingObjectsStateError, (state, payload): BuildingObjectsState => ({
    ...state,
    canLoadState: false
  })),
  on(actions.unsetAreBuildingObjectsFullyLoaded, (state, payload): BuildingObjectsState => ({
    ...state,
    areBuildingObjectsFullyLoaded: false
  })),
];

export const reducer = createReducer(initialBuildingObjectsState, ...buildingObjectsReducers);

export const buildingObjectsFeatureState = createFeature({
  name: buildingObjectsFeatureKey,
  reducer
});
