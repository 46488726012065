import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@kros-sk/ssw-shared-legacy';

import { PeriodOperationType } from '../../../../building-progress/models/period-history.model';

@Pipe({ name: 'periodOperationType' })
export class PeriodOperationTypePipe implements PipeTransform {
  constructor(
    private translateService: TranslateService
  ) {
  }

  transform(operationType: PeriodOperationType): string {
    switch (operationType) {
      case PeriodOperationType.Create:
        return this.translateService.translate('BUILDING_PROGRESS.PERIODS_HISTORY.OPERATION_TYPE_CREATE');
      case PeriodOperationType.Edit:
        return this.translateService.translate('BUILDING_PROGRESS.PERIODS_HISTORY.OPERATION_TYPE_EDIT');
      case PeriodOperationType.Delete:
        return this.translateService.translate('BUILDING_PROGRESS.PERIODS_HISTORY.OPERATION_TYPE_DELETE');
      case PeriodOperationType.Lock:
        return this.translateService.translate('BUILDING_PROGRESS.PERIODS_HISTORY.OPERATION_TYPE_LOCK');
      case PeriodOperationType.Unlock:
        return this.translateService.translate('BUILDING_PROGRESS.PERIODS_HISTORY.OPERATION_TYPE_UNLOCK');
      case PeriodOperationType.SetApprovalRequested:
        return this.translateService.translate('BUILDING_PROGRESS.PERIODS_HISTORY.OPERATION_TYPE_SET_APPROVAL_REQUESTED');
      case PeriodOperationType.CancelApprovalRequested:
        return this.translateService.translate('BUILDING_PROGRESS.PERIODS_HISTORY.OPERATION_TYPE_CANCEL_APPROVAL_REQUESTED');
      case PeriodOperationType.SetApproved:
        return this.translateService.translate('BUILDING_PROGRESS.PERIODS_HISTORY.OPERATION_TYPE_SET_APPROVED');
      case PeriodOperationType.CancelApproved:
        return this.translateService.translate('BUILDING_PROGRESS.PERIODS_HISTORY.OPERATION_TYPE_CANCEL_APPROVED');
      case PeriodOperationType.PartiallyApproved:
        return this.translateService.translate('BUILDING_PROGRESS.PERIODS_HISTORY.OPERATION_TYPE_PARTIALLY_APPROVED');
      default:
        return '';
    }
  }
}
