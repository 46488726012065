import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { filter, map, pairwise, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { DetailRouterConfig } from '../../models/router-config.model';
import { DeviceDetectorService, DeviceType } from '../../../services';
import { isValidGuid } from '../../../tools/guid/guid-tools';

@Injectable({
  providedIn: 'root'
})
export class MasterDetailUrlHelperService {

  private previousUrlEvent: NavigationEnd;
  private routeChanges = new Subject<NavigationEnd>();

  constructor(
    private router: Router,
    private deviceDetector: DeviceDetectorService
  ) {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        tap(e => this.routeChanges.next(e as NavigationEnd)),
        pairwise(),
      )
      .subscribe((events: [NavigationEnd, NavigationEnd]) => {
        this.previousUrlEvent = events[0];
      });
  }

  get previousUrl(): any {
    return this.previousUrlEvent;
  }

  /**
   * fires on every route change outside given evidence
   */
  leavingEvidence(detailRouterConfig: DetailRouterConfig): Observable<void> {
    return this.routeChanges.asObservable().pipe(
      filter(x => !x.url.includes(detailRouterConfig.parentRoute)),
      map(() => void 0)
    );
  }

  /**
   * Add dashboard url segment into url, right after parent url segment
   * @param url Whole url. After insertion it will match .../parent/dashboard/child/... url pattern
   * @param parent Parent url segment. Dashboard url segment will be inserted right after
   * @param dashboard Dashboard url segment to be inserted right after parent url segment
   */
  addDashboardIntoUrl(url: string, parent: string, dashboard: string): string {
    const segments = this.getUrlSegmentsWithoutQueryParams(url);
    const queryParams = this.getQueryParams(url);
    const parentIndex = segments.indexOf(parent);
    if (parentIndex > 0) {
      segments.splice(parentIndex + 1, 0, dashboard);
      return segments.join('/') + queryParams;
    }

    return url;
  }

  /**
   * Removes dashboard url segment from url
   * @param url Whole url. It must match .../parent/dashboard/child/... route pattern
   * @param parent Parent url segment
   * @param dashboard Dashboard url segment
   * @param children Child url segment
   */
  removeDashboardFromUrl(url: string, parent: string, dashboard: string, children: string | string[]): string {
    const segments = this.getUrlSegmentsWithoutQueryParams(url);
    const queryParams = this.getQueryParams(url);
    const parentIndex = segments.indexOf(parent);
    const dashboardIndex = segments.indexOf(dashboard);

    if (parentIndex > 0 &&
      dashboardIndex === parentIndex + 1 &&
      this.childIsNotTheLastUrlSegment(segments, children)) {
      segments.splice(dashboardIndex, 1);

      return segments.join('/') + queryParams;
    }

    return url;
  }

  /**
   * gets correct route to be used as relativeTo in calling router.navigate inside master-detail
   */
  getCorrectRoute(route: ActivatedRoute): ActivatedRoute {
    if (this.deviceDetector.deviceType === DeviceType.Desktop) {
      return route;
    }
    return route.parent;
  }

  /**
   * extracts detail's id from URL
   * @returns id or null when on dashboard/mobile list
   */
  getDetailId(): number | null {
    const id = +this.extractIdentifierFromUrl();
    if (isNaN(id)) {
      return null;
    }
    return id;
  }

  /**
   * extracts detail's guid from URL
   * @returns id or null when on dashboard/mobile list
   */
  getDetailGuid(): string | null {
    const id = this.extractIdentifierFromUrl();
    return isValidGuid(id) ? id : null;
  }

  /**
   * returns correct dashboard url
   */
  getDashboardUrl(route: ActivatedRoute, exact?: boolean): string[] {
    const routeConfig = route.snapshot.data;
    const parsedUrl = this.router.routerState.snapshot.url.split('/');
    let lastItem = parsedUrl.pop();
    while (lastItem !== routeConfig.parentRoute) {
      lastItem = parsedUrl.pop();
    }
    if (this.deviceDetector.deviceType === DeviceType.Desktop || exact) {
      return [
        ...parsedUrl,
        routeConfig.parentRoute,
        routeConfig.masterRoute,
        routeConfig.childRoute
      ];
    }
    return [
      ...parsedUrl,
      routeConfig.parentRoute,
      routeConfig.childRoute
    ];
  }

  /**
   * Navigates to target URL.
   * If target matches current URL, router redirects to parent ad back to target (so that forms get reset etc.)
   * @param route ActivatedRoute
   * @param parent Parent URL segment
   * @param target Target URL segment
   */
  async navigateWithRedirect(route: ActivatedRoute, parent: string, target: string): Promise<boolean> {
    if (this.router.url.includes(target)) {
      await this.router.navigate([ parent ], {
        relativeTo: this.getCorrectRoute(route),
        skipLocationChange: true,
        queryParamsHandling: 'preserve',
      });
      return await this.router.navigate(
        [ parent, target ],
        { relativeTo: this.getCorrectRoute(route), queryParamsHandling: 'preserve' });
    } else {
      return await this.router.navigate([ parent, target ], { relativeTo: this.getCorrectRoute(route), queryParamsHandling: 'preserve' });
    }
  }

  /**
   * Used to save form state when changing screen resolution
   * and Master/Detail caused route change and component destruction
   */
  isSwitchingDesktopMobile(
    detailRouterConfig: DetailRouterConfig,
    currentState: { url: string },
    nextState: { url: string }
  ): boolean {
    if (this.deviceDetector.deviceType === DeviceType.Desktop) {
      const desktopUrl = this.addDashboardIntoUrl(currentState.url, detailRouterConfig.parentRoute, detailRouterConfig.masterRoute);
      return desktopUrl === nextState.url;
    } else {
      const mobileUrl = this.removeDashboardFromUrl(
        currentState.url,
        detailRouterConfig.parentRoute,
        detailRouterConfig.masterRoute,
        detailRouterConfig.childRoute);
      return mobileUrl === nextState.url;
    }
  }

  /**
   * Checks whether child / active child from children collection is not the last url segment.
   * In that case dashboard isn't removed from url, because of defined routes.
   * Removing dashboard in that case may cause page not found
   * @param urlSegments Array of url segments split by /
   * @param children child/ array of children
   */
  private childIsNotTheLastUrlSegment(urlSegments: string[], children: string | string[]): boolean {
    if (!Array.isArray(children)) {
      return this.isNotLastSegmentInUrl(urlSegments, children);
    }

    return children.findIndex(child => this.isNotLastSegmentInUrl(urlSegments, child)) >= 0;
  }

  private getQueryParams(url: string): string {
    const segments = url.split('/');

    if (segments[segments.length - 1].indexOf('?') >= 0) {
      return '?' + segments[segments.length - 1].split('?')[1];
    }

    return '';
  }

  private getUrlSegmentsWithoutQueryParams(url: string): string[] {
    const segments = url.split('/');
    segments[segments.length - 1] = segments[segments.length - 1].split('?')[0];

    return segments;
  }

  private isNotLastSegmentInUrl(urlSegments: string[], child: string): boolean {
    return urlSegments.indexOf(child) !== (urlSegments.length - 1);
  }

  private extractIdentifierFromUrl(): string {
    return this.getUrlSegmentsWithoutQueryParams(this.router.url).pop();
  }

}
