<div
  ngbDropdown
  container="body"
  #dropdown="ngbDropdown"
  autoClose="outside"
  class="dropdown-container tablet-hidden"
  placement="auto"
>
  <button
    class="btn btn-outline-primary trigger-button"
    [class.compact-mode]="compactMode"
    ngbDropdownToggle
    [disabled]="disabled"
    data-test="sharing-list-item-dropdown"
  >
    <span class="name">{{ selectedPermission?.name }}</span>
    <i class="material-icons">expand_more</i>
  </button>

  <div
    ngbDropdownMenu
    class="tablet-height"
    [attr.data-test-sharing-menu]="menuDataTestValue"
  >
    <button
      ngbDropdownItem
      *ngFor="let permission of permissionList"
      (mouseup)="onPermissionChanged(permission); dropdown.close()"
    >
      <div class="right-name">
        <span>{{ permission.name }}</span>
      </div>
      <div class="right-description">
        <span>{{ permission.description }}</span>
      </div>
    </button>
  </div>
</div>

<div class="tablet-visible">
  <button
    class="btn btn-outline-primary trigger-button"
    [class.compact-mode]="compactMode"
    [disabled]="disabled"
    data-test="sharing-list-item-button"
    (click)="showPermissionModal()"
  >
    <span class="name">{{ selectedPermission?.name }}</span>
    <i class="material-icons">expand_more</i>
  </button>
</div>
