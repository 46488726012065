import { Component } from '@angular/core';

import { SettingService } from '../../services';

@Component({
  selector: 'ssw-general-setting',
  templateUrl: './general-setting.component.html',
  styleUrls: ['./general-setting.component.scss']
})
export class GeneralSettingsComponent {

  get routerLink(): string {
    return this.settingService.previousUrl;
  }

  constructor(
    private settingService: SettingService
  ) { }
}
