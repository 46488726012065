<div
  class="panel-content-container"
  [ngClass]="{'collapsed': collapsed}"
  data-test="panel-content-container"
>
  <div class="panel-container">
    <div class="panel-content">
      <div>
        <ng-content select="panelTop"></ng-content>
      </div>

      <div class="header">
        <h5>{{ headerText }}</h5>

        <div
          class="collapse-button tablet-hidden"
          (click)="toggleCollapsePanel()"
          data-test="change-sheet-panel-collapse"
        >
          <i class="icon-button material-icons">
            {{ collapsed ? 'keyboard_double_arrow_right' : 'keyboard_double_arrow_left' }}
          </i>
        </div>

        <div
          *ngIf="collapsed"
          class="collapsed-text tablet-hidden"
        >{{ headerText }}</div>
      </div>

      <div class="collapsible-content">
        <ng-content select="panel"></ng-content>
      </div>
    </div>
  </div>

  <div class="content-container">
    <ng-content select="content"></ng-content>
  </div>
</div>
