import { createActionGroup, props } from '@ngrx/store';

import {
  BoqNumberOfUses,
  BudgetEditItemPropertyModel,
  BudgetItem,
  ChangedBudgetItems,
  Database,
  SearchModel
} from '@kros-sk/ssw-shared-legacy';
import {
  BudgetEditItemActionModel,
  BuildingObject,
  IItemGroup,
  MoveItemsActionModel,
  MoveItemsPositions
} from '@kros-sk/ssw-budget/shared/data-access/models';

import { budgetSharedKey } from './budget-shared-actions';
import { buildingObjectsSharedActions } from './building-objects-shared-actions';

export const budgetItemsSharedActions = createActionGroup({
  source: budgetSharedKey,
  events: {
    'Load budget items start': props<{
      groupIds?: string[],
      budgetItemId?: string,
      clearItems?: boolean,
      canLoadChildren?: boolean,
      canExpandAll?: boolean,
      canSetBudgetItemOnLoad?: boolean,
      searchModel?: SearchModel,
      parentIds?: string[],
      selectedItemIds?: string[],
      canInsertCustomItem?: boolean
    }>(),
    'Load budget items success': props<{
      budgetId: number,
      items: BudgetItem[],
      itemGroups?: IItemGroup[],
      isInitializing: boolean,
      budgetItemId?: string,
      checkLoadedItems?: boolean,
      canSetBudgetItemOnLoad?: boolean,
      canExpandAll?: boolean,
      parentIds?: string[],
      selectedItemIds?: string[],
      canInsertCustomItem?: boolean,
      shouldRecalculateBoqs?: boolean
    }>(),
    'Load budget items error': props<{ error: any }>(),

    'Set selected budget item': props<{ budgetItem: BudgetItem }>(),

    'Update budget item start': props<{
      database: Database,
      canUpdatePrices: boolean,
      otherDataUpdateTypes: number,
      items: BudgetItem[],
      selectedItem: BudgetItem,
      selectedItems: BudgetItem[],
      budgetIsInSearchMode: boolean,
      loadedParentIds: string[],
      groupColumns: string[],
      isUpdatedByOnlineMaterials: boolean
    }>(),
    'Update budget item success': props<{ changedBudgetItems: ChangedBudgetItems, occurrenceItemIds: number[] }>(),
    'Update budget item empty success': props<{ occurrenceItemIds: number[] }>(),
    'Update budget item error': props<{ error: any }>(),

    'Edit budget items start': props<{
      occurrenceItems: BudgetEditItemPropertyModel[]
      isUndoRedoOperation?: boolean,
      isUndo?: boolean,
      needClearStack?: boolean
    }>(),
    'Edit budget items success': props<{
      changedBudgetItems: ChangedBudgetItems,
      occurrenceItems: BudgetEditItemPropertyModel[],
      isUndoRedoOperation?: boolean,
      isUndo?: boolean,
      needClearStack?: boolean
    }>(),
    'Edit budget items error': props<{ error: any }>(),

    'Budget update actions': props<{ budgetId: number, changedBudgetItems: ChangedBudgetItems }>(),

    'Move budget items multiple start': props<{
      data: MoveItemsActionModel[],
      isUndoRedoOperation?: boolean,
      isUndo?: boolean
    }>(),
    'Move budget items multiple success': props<{
      changedBudgetItemsMultiple: ChangedBudgetItems[],
      sourceDataPositions: MoveItemsPositions<BudgetItem>,
      storedAction: MoveItemsActionModel[],
      isUndoRedoOperation: boolean,
      isUndo: boolean
    }>(),
    'Move budget items multiple error': props<{ error: any }>(),

    'Edit budget item start': props<{
      item: BudgetItem,
      propertyName: string,
      propertyValue: any,
      isBuildingObject?: boolean,
      hierarchyItems?: BuildingObject[]
      isUndoRedoOperation?: boolean,
      isUndo?: boolean
    }>(),
    'Edit budget item in progress': props<{
      changedBudgetItems: ChangedBudgetItems,
      item: BudgetItem,
      propertyName: string,
      propertyValue: any,
      isBuildingObject: boolean,
      hierarchyItems?: BuildingObject[]
      isUndoRedoOperation: boolean,
      isUndo: boolean
    }>(),
    'Edit budget item success': props<{
      changedBudgetItems: ChangedBudgetItems,
      storedAction: BudgetEditItemActionModel,
      oldValue: any,
      isUndoRedoOperation: boolean,
      isUndo: boolean,
      changedAmountCalculationType: boolean
    }>(),
    'Edit budget item error': props<{ error: any }>(),

    'Delete budget item start': props<{ itemId: number, newId: string }>(),
    'Delete budget item success': props<{ budgetId: number, changedBudgetItems: ChangedBudgetItems, newId: string }>(),
    'Delete budget item error': props<{ error: any }>(),

    'Delete budget section start': props<{ itemId: number, newId: string }>(),
    'Delete budget section success': props<{ budgetId: number, changedBudgetItems: ChangedBudgetItems, newId: string }>(),
    'Delete budget section error': props<{ error: any }>(),

    'Delete selected budget items start': props<{ newId?: string }>(),
    'Delete selected budget items success': props<{ budgetId: number, changedBudgetItems: ChangedBudgetItems, newId: string }>(),
    'Delete selected budget items error': props<{ error: any }>(),

    'Delete group item start': props<{ itemId: string, newId: string, canGetBoqsNumberOfUses: boolean }>(),
    'Delete group item success': props<{
      budgetId: number,
      changedBudgetItems: ChangedBudgetItems,
      boqsNumberOfUses: BoqNumberOfUses[],
      newId: string,
      needClearStack: boolean
    }>(),
    'Delete group item error': props<{ error: any }>()
  }
});

export const budgetDeleteActions = [
  budgetItemsSharedActions.deleteBudgetItemSuccess,
  budgetItemsSharedActions.deleteBudgetSectionSuccess,
  buildingObjectsSharedActions.deleteBuildingObjectSuccess,
  budgetItemsSharedActions.deleteSelectedBudgetItemsSuccess,
  budgetItemsSharedActions.deleteGroupItemSuccess
];
