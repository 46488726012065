import { Directive, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

import { Subscription } from 'rxjs';

import { BlazorLoadingService } from '../../../services/blazor-loader/blazor-loading.service';
import { RecalcWatcherMasterDirective } from '../recalc-watcher-master/recalc-watcher-master.directive';

@Directive({
  selector: '[krosRecalcWatcher]',
})
export class RecalcWatcherDirective implements OnInit, OnDestroy {

  @Input('krosRecalcWatcher') mastered = '';

  private sub: Subscription;

  constructor(
    private ngControl: NgControl,
    private blazorLoadingService: BlazorLoadingService,
    @Optional() private parentNode?: RecalcWatcherMasterDirective
  ) { }

  ngOnInit(): void {
    if (!this.parentNode && (this.mastered === 'mastered')) return;

    this.sub = this.blazorLoadingService.isLoaded$.subscribe(isLoaded => {
      if (isLoaded) this.ngControl.control.enable();
      else this.ngControl.control.disable();
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
