import { AfterViewInit, Directive, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { ControlValueAccessor, UntypedFormControl } from '@angular/forms';

import { BehaviorSubject, lastValueFrom, Observable, of } from 'rxjs';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { TranslateService } from '@ngx-translate/core';

import { CompanySearchValidationOptions, emptyCompany, ItemSearchEnum } from '@kros-sk/models';
import { VatIdValidationService } from '@kros-sk/auth';

import {
  AutocompleteConfig,
  InputCommandType,
  InputOptions,
  InputType,
  KrosError,
  KrosFormsService,
  OptionTemplateContext
} from '../../inputs';
import { DeviceDetectorService } from '../../services';

@Directive()
export abstract class SearchBase implements OnInit, ControlValueAccessor, OnDestroy, AfterViewInit {
  @Input() validationOptions: CompanySearchValidationOptions;
  @Input() addNewPartner = false;

  @Input() set showAllErrors(value) {
    this.formService.triggerInputCommand('partner', {
      type: InputCommandType.CHANGE_OPTIONS,
      data: { validateUntouched: value },
    });
    this._showAllErrors = value;
  }

  @Input() setFocus = false;

  @Input() stopScrollInModal = false;

  @Input() set placeholderText(placeholder: string) {
    this._placeholderText = placeholder;
    if (placeholder) {
      this.formService.triggerInputCommand('partner', {
        type: InputCommandType.CHANGE_OPTIONS,
        data: { placeholder },
      });
    }
  }

  @Output() newPartner: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('rt', { static: true }) rt: TemplateRef<OptionTemplateContext>;

  companyFormControl: UntypedFormControl;
  showSearchIndicator$: Observable<boolean>;
  autocompleteSettings: AutocompleteConfig;
  searchInputSettings: InputOptions;
  onTouchedFn: () => void;

  protected showSearchIndicatorSubject = new BehaviorSubject<boolean>(false);
  protected partnerNameMaxLength = 255;

  private shouldNotTriggerSearch = false;
  private selectedName = '';
  private _showAllErrors = false;
  private _placeholderText: string;
  private onChange;
  protected subs = new SubSink();
  /**
   * This prevents valueChange from triggering on the formControl after choosing an autocomplete option.
   */
  private notEmit: boolean;

  constructor(
    protected deviceDetectorService: DeviceDetectorService,
    protected formService: KrosFormsService,
    protected vatIdValidationService: VatIdValidationService,
    protected translate: TranslateService
  ) {
    this.showSearchIndicator$ = this.showSearchIndicatorSubject.asObservable();
  }

  protected abstract getSearchResults(searchTerm: string): Observable<any[]>;

  ngOnInit(): void {
    this.initFormControl();
    this.initInputSettings();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.setFocus && !document.getElementById('lock-overlay')) {
        this.formService.triggerInputCommand('partner', {
          type: InputCommandType.FOCUS_INPUT,
          data: { preventScroll: true },
        });
      }
    }, 300);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get constantPartnerNotFound(): string {
    return ItemSearchEnum.ITEM_NOT_FOUND_REG_ID;
  }

  get constantAddNewPartner(): string {
    return ItemSearchEnum.ITEM_ADD_NEW_REG_ID;
  }

  get isIOS(): boolean {
    return this.deviceDetectorService.isIOS;
  }

  selectItem(event): void {
    this.shouldNotTriggerSearch = true;
    const partner = {
      ...event,
      discountPercent: event.discountPercent === '' ? null : event.discountPercent
    };
    if (partner.registrationId === this.constantPartnerNotFound) this.closeAutocomplete(partner);
    else if (partner.registrationId === this.constantAddNewPartner) this.emitNewPartner(partner);
    else {
      this.showSearchIndicatorSubject.next(false);
      this.notEmit = true;
      this.onChange(partner); // after choosing options, parent will set it to their control.
      this.validateVatId(partner?.vatId);
      this.selectedName = this.extractName(partner);
    }
  }

  writeValue(val): void {
    this.notEmit = true;
    if (val?.address) {
      this.companyFormControl.setValue(val.address?.businessName);
    } else {
      // if set emitEvent is set to false, shouldNotTriggerSearch is set to false, because searchTerm change is not emitted in this case
      // and we want to react to next term change
      this.shouldNotTriggerSearch = false;
      this.companyFormControl.setValue(val, { emitEvent: false });
      this.formService.triggerInputCommand('partner', { type: InputCommandType.DETECT_CHANGES });
    }
    this.notEmit = false;
    this.validateVatId(val?.vatId);
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) this.companyFormControl.disable();
    else this.companyFormControl.enable();
  }

  registerOnChange(fn): void {
    this.onChange = fn;
    this.subs.add(this.companyFormControl.valueChanges.subscribe(
      x => {
        if (!this.notEmit) { // if item was selected we dont want to emit new value
          const company = emptyCompany();
          company.address.businessName = x;
          this.onChange(company);
        }
        this.notEmit = false;
      }),
    );
  }

  registerOnTouched(fn): void {
    this.onTouchedFn = fn;
  }

  onBlur(): void {
    this.onTouchedFn();
    this.showSearchIndicatorSubject.next(false);
  }

  /**
   * To prevent deleting input value when "Partner not found" option is selected.
   * @param event
   */
  closeAutocomplete(event): void {
    if (event.target) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.formService.triggerInputCommand('partner', {
      type: InputCommandType.CLOSE_AUTOCOMPLETE,
      data: true,
    });
  }

  /**
   * To prevent deleting input value when "Add new partner" option is selected.
   * @param event
   */
  emitNewPartner(event: any): void {
    if (event.target) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.newPartner.emit(true);
    this.closeAutocomplete(event);
  }

  private initInputSettings(): void {
    this.autocompleteSettings = {
      searchMethod: (text$) => text$.pipe(
        tap(term => {
          if (term !== this.selectedName) {
            this.shouldNotTriggerSearch = false;
          }
        }),
        debounceTime(500),
        filter(term => term.length >= 3),
        switchMap(term => {
          if (this.shouldNotTriggerSearch) {
            this.shouldNotTriggerSearch = false;
            return of([]);
          }
          return this.getSearchResults(term);
        }),
      ),
      positions: [
        new ConnectionPositionPair(
          { originX: 'end', originY: 'bottom' },
          { overlayX: 'end', overlayY: 'top' },
          0, -1, // offset X, Y
        ),
        new ConnectionPositionPair(
          { originX: 'end', originY: 'top' },
          { overlayX: 'end', overlayY: 'bottom' },
          0, 0, // offset X, Y
        ),
      ],
      optionTemplate: this.rt,
      optionClass: 'partner-option',
      maxOptionsShown: 5,
      optionHeight: 94,
      inputFormatter: (x: any): string => {
        if (!x || [this.constantPartnerNotFound, this.constantAddNewPartner].includes(x.registrationId)) {
          return this.companyFormControl.value;
        }
        return this.extractName(x);
      },
    };
    this.searchInputSettings = {
      type: InputType.TEXT,
      name: 'partner',
      formControl: this.companyFormControl,
      className: 'company-input',
      placeholder: this._placeholderText || this.translate.instant('PARTNERS.BUSINESS_NAME.PLACEHOLDER'),
      autocomplete: this.autocompleteSettings,
      testName: 'purchaser-dropdown',
      validateUntouched: this._showAllErrors,
      maxLength: this.partnerNameMaxLength,
      stopScrollInModal: this.stopScrollInModal,
      errors: {
        invalidVatId: this.translate.instant('COMMON.INVALID_VAT_ID'),
        whiteSpaces: this.translate.instant('COMMON.WHITE_SPACES'),
        required: this.translate.instant('COMMON.REQUIRED'),
      },
    };
  }

  setOptionsError(krosErrors: QueryList<KrosError>): void {
    krosErrors.map(error => {
      this.searchInputSettings = {
        ...this.searchInputSettings,
        errors: {
          ...this.searchInputSettings.errors,
          [error.errorId]: error.nativeElement.textContent
        },
      };
    });
  }

  private extractName(x: any): string {
    if (!x.name && !x.address) return x.slice(0, this.partnerNameMaxLength);
    if (x.address) return x.address.businessName.slice(0, this.partnerNameMaxLength);
    return x.name.slice(0, this.partnerNameMaxLength);
  }

  private initFormControl(): void {
    this.companyFormControl = new UntypedFormControl('', this.validationOptions?.validators);
  }

  private validateVatId(vatId: string): void {
    if (this.validationOptions?.validateVatId) {
      this.companyFormControl.markAsPending();
      lastValueFrom(this.vatIdValidationService.vatIdValidation(vatId)).then(
        error => {
          this.companyFormControl.markAsTouched();
          if (error) this.addError(error);
          else this.removeError('invalidVatId');
        }
      ).catch(_ => {
      });
    }
  }

  private addError(error): void {
    const errors = this.companyFormControl.errors;
    this.companyFormControl.setErrors({
      ...errors,
      ...error,
    });
  }

  private removeError(errorName: string): void {
    const errors = this.companyFormControl.errors;
    if (errors && errors[errorName]) {
      errors[errorName] = null;
    }
    this.companyFormControl.setErrors(errors);
  }
}
