import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter } from 'rxjs';

import { formatString, License, LicenseType } from '@kros-sk/ssw-cdk';

import { LicenseSelectorsService } from '../store/license-selectors.service';
import { MarketingContentHelper } from '../helpers';
import { NonPaidPackageConfig } from '../non-paid-package-config.model';

@Injectable()
export class LicenseInfoContentHelper extends MarketingContentHelper {

  private licenseSelectorsService = inject(LicenseSelectorsService, { optional: true });
  private buildingProgressLicense: License;

  protected get moduleName(): string {
    return this.translateService.translate('LICENSE_INFO.BUILDING_PROGRESS');
  }

  constructor() {
    super();
    this.licenseSelectorsService?.buildingProgressLicenseInfo$
      .pipe(filter(l => !!l && !!l.license), takeUntilDestroyed())
      .subscribe(buildingProgressLicense => this.buildingProgressLicense = buildingProgressLicense.license);
  }

  init(config: NonPaidPackageConfig | any): void {
    this.config = config ?? {};
    this.config.isLicenseReadonly = this.buildingProgressLicense.licenseType === LicenseType.Readonly;
    this.config.isLicenseFull = this.buildingProgressLicense.licenseType === LicenseType.Full;
    this.config.isLicenseFree = this.buildingProgressLicense.licenseType === LicenseType.Free;
    this.config.canActivateTrial = false;
    this.config.isAutomaticLaunch = false;
    super.init(config);
  }

  protected resolveInfoText(): void {
    this.infoText = `${formatString(
      this.translateService.translate('LICENSE_INFO.MKT_MODAL_TEXT_FREE_CANNOT_ORDER_ALONE_TEXT_PV'),
    )} ${this.getMoreInfoLink()}`;
  }

  protected resolveFooterText(): void { }

  protected resolvePicture(): void {
    this.picture = 'time';
  }

  protected getMoreInfoLink(): string {
    return `<a href="${this.urlHelper?.skMoreInfoBuyWithK4Url}" target="new">`
      .concat(`${this.translateService.translate('LICENSE_INFO.ORDER_WITH_K4')}</a>`);
  }

  protected resolveOrderButtonText(): void {
    this.orderButtonText = this.translateService.translate('LICENSE_INFO.ORDER_WITH_BUDGET');
    this.config.orderUrl = 'https://www.kros.sk/stavebny-rozpocet/';
    this.showOrderButton = true;
  }
}
