import { formatNumber } from '@angular/common';
import { inject, Injectable } from '@angular/core';

import { APP_CONFIG } from '@kros-sk/app-config';

@Injectable()
export class FileSizeService {
  private appConfig = inject(APP_CONFIG);
  private units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

  transform(bytes: number): string {
    if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) {
      return '—';
    }

    let unit = 0;

    while (bytes >= 1024) {
      bytes /= 1024;
      unit++;
    }

    return `${formatNumber(bytes, this.appConfig.location, '1.0-1')} ${this.units[unit]}`;
  }
}
