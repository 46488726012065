import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable()
export class DocumentTitleService {

  titleChanged = new Subject<string[]>();

  constructor() { }

  changeTitle(newTitle: string): void {
    this.titleChanged.next([newTitle]);
  }

  changeTitles(newTitles: string[]): void {
    this.titleChanged.next(newTitles);
  }
}
