import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { FeatureFlags } from '../types/feature-flags.types';

export const featureFlagsActions = createActionGroup({
  source: 'Feature flags',
  events: {
    'Load features': emptyProps(),
    'Load features Success': props<{ features: FeatureFlags }>(),
    'Load features Fail': props<{ err: any }>(),
  }
});
