import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { filter } from 'rxjs/operators';

import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { BudgetApprovalDispatchersService } from '../../../store/budget-approval';
import { BuildingProgressActionAccessService } from '../../../building-progress/services/building-progress-action-access.service';
import { ProjectsSelectorsService } from '../../../store/projects';

@Component({
  selector: 'app-change-sheet-toolbar',
  templateUrl: './change-sheet-toolbar.component.html',
  styleUrls: ['./change-sheet-toolbar.component.scss']
})
export class ChangeSheetToolbarComponent extends UnsubscribeComponent implements OnInit {
  @Input() isChangeSheetApproved: boolean;
  @Input() canOpenBoq: boolean;

  @Output() backClick = new EventEmitter<void>();
  @Output() showChangeSheetItemsClick = new EventEmitter<void>();
  @Output() deleteRelationClick = new EventEmitter<void>();
  @Output() approveClick = new EventEmitter<void>();
  @Output() cancelApprovedClick = new EventEmitter<void>();
  @Output() exportClick = new EventEmitter<void>();
  @Output() openBoqClick = new EventEmitter<void>();

  constructor(
    private dispatchersService: BudgetApprovalDispatchersService,
    private projectsSelectorsService: ProjectsSelectorsService,
    private buildingProgressActionAccessService: BuildingProgressActionAccessService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.projectsSelectorsService.projectDetail$
      .pipe(filter(p => !!p))
      .subscribe(p => this.dispatchersService.loadSharingList(p.id));
  }

  onBackClick(): void {
    this.backClick.emit();
  }

  showChangeSheetItems(): void {
    this.showChangeSheetItemsClick.emit();
  }

  onDeleteRelationClick(): void {
    this.deleteRelationClick.emit();
  }

  onApproveClick(): void {
    this.approveClick.emit();
  }

  onCancelApprovedClick(): void {
    this.cancelApprovedClick.emit();
  }

  onExportClick(): void {
    if (this.buildingProgressActionAccessService.checkForAccessToExport(true)) {
      this.exportClick.emit();
    }
  }

  openBoq(): void {
    this.openBoqClick.emit();
  }
}
