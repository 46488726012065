/* eslint-disable max-lines */
import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { take } from 'rxjs/operators';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import {
  BudgetStructureService,
  Cell,
  DataTableConfigModel,
  formatNumberValue,
  getFocusedCellElementId,
  getParentItems,
  InfoModalComponent,
  isSummaryItem,
  PanelHelper,
  SearchMode,
  SearchModel,
  TranslateService
} from '@kros-sk/ssw-shared-legacy';
import { KrosModalService } from '@kros-sk/components';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { BuildingProgressCell, BuildingProgressItem, BuildingProgressModel } from '../models/construction-data.model';
import {
  BuildingProgressCommentsDispatchersService,
  BuildingProgressDataSettingsDispatchersService,
  BuildingProgressDispatchersService,
  BuildingProgressSelectorsService
} from '../../store/building-progress';
import { BuildingProgressEditingService } from '../services/building-progress-editing.service';
import { BuildingProgressNavigation } from '../../building-progress/models/building-progress-navigation.type';
import { BuildingProgressPanelHelper } from './building-progress-panel.helper';
import { BuildingProgressPercentageCompleteService } from '../services/building-progress-percentage-complete.service';
import { BuildingProgressViewsService, ViewType } from '../services/building-progress-views.service';
import { DetailMobileHelper } from './detail-mobile.helper';
import { environment } from '../../../environments/environment';
import { PeriodHelper } from './period.helper';
import { RefocusItemModel } from '../models/refocus-item.model';
import { SelectedView } from '../models/selected-view.model';
import { TableChangeDetectionService } from '../services/table-change-detection.service';
import { VisualHelper } from './visual.helper';

@Injectable()
export class DetailHelper extends UnsubscribeComponent {
  projectId: number;
  projectName: string;
  hierarchyCode: string = undefined;
  galleryPeriodId: number;
  initWithDashboardNavigation: boolean;
  areConstructionDataLoaded = false;
  constructionData: BuildingProgressModel;
  dataTableConfig: DataTableConfigModel;
  contractorId: number;

  selectedItemIds = new Set<number>();
  selectedPeriods: number[] = [];

  isViewOrSearchActive = false;
  currentView: SelectedView = { viewType: ViewType.standard };
  currentSearchModel: SearchModel = {
    filter: '',
    searchText: '',
    searchMode: 'all'
  };
  isMobileSearchVisible = false;
  isMobileSelectionVisible = false;
  isSummaryPanelVisible = false;
  refocusItemModel: RefocusItemModel;

  get currentNavigation(): BuildingProgressNavigation {
    return this._currentNavigation;
  }
  set currentNavigation(navigation: BuildingProgressNavigation) {
    if (this._currentNavigation === 'dashboard' && navigation === 'table') {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          dashboard: null
        },
        queryParamsHandling: 'merge'
      });
    }
    this._currentNavigation = navigation;
    if (navigation === 'table') {
      setTimeout(() => {
        this.setFocusToFocusedCell();
      }, 0);
    }
  }

  private _currentNavigation: BuildingProgressNavigation = 'table';

  constructor(
    public mobile: DetailMobileHelper,
    private appInsightsService: AppInsightsBaseService,
    private visualHelper: VisualHelper,
    private buildingProgressPanelHelper: BuildingProgressPanelHelper,
    private periodHelper: PeriodHelper,
    private panelHelper: PanelHelper,
    private buildingProgressPercentageCompleteService: BuildingProgressPercentageCompleteService,
    private buildingProgressDispatchersService: BuildingProgressDispatchersService,
    private buildingProgressDataSettingsDispatchersService: BuildingProgressDataSettingsDispatchersService,
    private commentsDispatchersService: BuildingProgressCommentsDispatchersService,
    private buildingProgressEditingService: BuildingProgressEditingService,
    private buildingProgressSelectorsService: BuildingProgressSelectorsService,
    private budgetStructureService: BudgetStructureService,
    private viewsService: BuildingProgressViewsService,
    private translateService: TranslateService,
    private tableChangeDetectionService: TableChangeDetectionService,
    private krosModalService: KrosModalService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
    this.setSummaryPanelVisible();
  }

  set focusedCell(value: Cell) {
    this.visualHelper.focusedCell = value;

    if (value) {
      if (this.panelHelper.isBoqPanelActive && this.panelHelper.previousRowIndex !== value.rowIndex) {
        this.panelHelper.focusedBoqBudgetItem$.next(this.constructionData.items[value.rowIndex]);
        this.panelHelper.previousRowIndex = value.rowIndex;
      }

      if (this.panelHelper.isBoqPanelActive || this.buildingProgressPanelHelper.isNotesPanelActive) {
        this.panelHelper.focusedCellChanged$.next(this.constructionData.items[value.rowIndex]);
      }
    }
  }

  get focusedCell(): Cell {
    return this.visualHelper.focusedCell;
  }

  get isConstructionData(): boolean {
    return this.constructionData?.items.some(i => i.id);
  }

  get itemCount(): number {
    return !!this.constructionData &&
      !!this.constructionData.items ? this.constructionData.items.length : 0;
  }

  get isEmptyContent(): boolean {
    return this.areConstructionDataLoaded ? this.itemCount === 0 && !this.isViewOrSearchActive : false;
  }

  get summaryViewActive(): boolean {
    return this.currentView.viewType === ViewType.sectionsSummary ||
      this.currentView.viewType === ViewType.buildingObjectsSummary;
  }

  get emptyContentDescription(): string {
    return environment.location === 'cz' ? 'BUILDING_PROGRESS.SOFTWARE_K4_CZ' : 'BUILDING_PROGRESS.SOFTWARE_K4_SK';
  }

  get emptyContentLink(): string {
    return environment.location === 'cz'
      ? 'https://www.urs.cz/software-a-data/kros-4-ocenovani-a-rizeni-stavebni-vyroby'
      : 'https://www.kros.sk/cenkros/';
  }

  get isFilterVisible(): boolean {
    return !this.isEmptyContent;
  }

  get hasPeriods(): boolean {
    return this.constructionData?.periods.length > 0;
  }

  destroy(): void {
    this.projectId = undefined;
    this.contractorId = undefined;
  }

  logBuildingProgressVisit(source: string): void {
    this.appInsightsService.trackEvent('PV-visit', { source });
  }

  setRefocusItemModel(): void {
    if (this.constructionData?.items.length > 0 && this.focusedCell) {
      const item = this.getFocusedItem();
      if (item) {
        this.refocusItemModel = {
          item,
          parentItemIds: getParentItems(this.constructionData.items, item.id).map(i => i.id)
        };
      }
    }
  }

  setFocusToStructureItemById(): void {
    const item = this.getFocusedItem();

    if (item) {
      if (isSummaryItem(item)) {
        this.budgetStructureService.setFocusToStructureItemById(item.id);
      } else {
        this.budgetStructureService.setFocusToStructureItemById(item.parentId);
      }
    }
  }

  setFocusToItemById(id: number): void {
    if (this.constructionData?.items?.length) {
      this.focusedCell = this.getFocusedCell(this.focusedCell?.colId ?? 'description',
        this.constructionData.items.findIndex(i => i.id === id),
        this.focusedCell?.params);
      this.setFocusToFocusedCell();
    }
  }

  private setFocusToFocusedCell(): void {
    if (this.focusedCell && this.constructionData) {
      this.visualHelper.scrollElementToView(this.getFocusedCellElementId(), false)
        .pipe(take(1))
        .subscribe(() => this.cellFocusedCore(true));
    }
  }

  focusAndAnimateCells(cells: BuildingProgressCell[]): void {
    if (this.constructionData?.items.length > 0 && cells.length) {
      this.focusedCell = this.getFocusedCell(cells[0].columnId,
        this.constructionData.items.findIndex(i => i.id === cells[0].rowId),
        this.focusedCell?.params);
      this.setFocusToStructureItemById();

      this.visualHelper.scrollElementToView(this.getFocusedCellElementId(), false).pipe(take(1)).subscribe(() => {
        setTimeout(() => {
          this.visualHelper.table.editHelper.animateCells(cells.map(cell => cell.columnId + '-row-' +
            this.constructionData.items.findIndex(i => i.id === cell.rowId)));
        }, 300);
      });

      this.setDetailsData(this.getFocusedItem());
    }
  }

  private getFocusedItem(): BuildingProgressItem {
    return this.constructionData.items[this.focusedCell.rowIndex];
  }

  getFocusedCell(colId: string, rowIndex: number, params?: any): Cell {
    return { colId, rowIndex, params: params ?? { periodId: 0, periodColumn: '' } };
  }

  getFocusedCellElementId(): string {
    return getFocusedCellElementId(this.focusedCell);
  }

  cellFocusedCore(scrollToComment?: boolean, cantLostFocus?: boolean, item?: BuildingProgressItem): void {
    if (!cantLostFocus) {
      this.visualHelper.refocusFocusedCell();
    }

    this.setFocusToStructureItemById();
    this.buildingProgressPanelHelper.setCommentData(this.projectId, scrollToComment, this.contractorId);
    this.setDetailsData(item);
    this.periodHelper.setSelectedPeriod();
  }

  getSelectedPeriodItemTotalPrice(item: BuildingProgressItem, selectedPeriodId: number): string {
    const period = item.periods.find(period => period.periodId === selectedPeriodId);
    const totalPrice = period ? period.totalPrice : 0;
    return formatNumberValue(totalPrice, this.constructionData.decimalPlaces.totalPrice, environment.location);
  }

  private setDetailsData(item: BuildingProgressItem): void {
    this.buildingProgressSelectorsService.periodDetails$.pipe(take(1)).subscribe(details => {
      if (details?.itemId !== item?.id || details?.period?.periodId !== this.focusedCell.params?.periodId) {
        this.buildingProgressPanelHelper.setDetailsData(item);
      }
    });
  }

  loadConstructionData(): void {
    if (this.projectId) {
      this.currentSearchModel = { ...this.currentSearchModel, filter: this.viewsService.getViewFilter(this.currentView.viewType) };
      this.isViewOrSearchActive = this.currentView.viewType !== ViewType.standard || this.currentSearchModel.searchText.length > 0;

      if (this.currentView.viewType === ViewType.selectedItems) {
        this.buildingProgressDispatchersService.loadSelectedConstructionData(
          this.projectId, this.currentSearchModel, this.contractorId
        );
      } else {
        this.buildingProgressDispatchersService.loadConstructionData(
          this.projectId, this.currentSearchModel, this.hierarchyCode, this.contractorId
        );
      }
    }
  }

  onRefresh(): void {
    this.buildingProgressPanelHelper.onCloseComments();
    this.setRefocusItemModel();
    this.loadConstructionData();
    this.buildingProgressDispatchersService.loadPeriods(this.projectId, true, this.contractorId);
    this.commentsDispatchersService.loadCommentPositions(this.projectId, this.contractorId);
    this.appInsightsService.trackEvent('PV-refresh');
  }

  onOpenComments(cell?: Cell): void {
    if (cell) {
      this.focusedCell = cell;
      this.setFocusToStructureItemById();
      this.periodHelper.setSelectedPeriod();
    }
    this.buildingProgressPanelHelper.openComments(this.projectId, !!cell, this.contractorId);
  }

  selectedViewChanged(selectedView: SelectedView): void {
    this.currentView = selectedView;
    this.setRefocusItemModel();
    this.dataTableConfig = undefined;
    this.areConstructionDataLoaded = false;
    this.constructionData = undefined;
    this.clearMultiSelectItems();
    this.currentSearchModel = { ...this.currentSearchModel, filter: this.viewsService.getViewFilter(this.currentView.viewType) };
    this.loadConstructionData();
  }

  specialViewChanged(selectedView: SelectedView): void {
    switch (selectedView.viewType) {
      case ViewType.colorCodes:
        this.viewsService.setColorCodesFilter(selectedView.data);
        break;
      case ViewType.drawnSelected:
        this.viewsService.setDrawnSelectedPeriodsFilter(this.selectedPeriods);
        break;
      case ViewType.undrawnSelected:
        this.viewsService.setUndrawnSelectedPeriodsFilter(this.selectedPeriods);
        break;
      case ViewType.withComment:
        this.viewsService.setWithCommentFilter(this.selectedPeriods);
        break;
    }
    this.selectedViewChanged(selectedView);
  }

  searchTextChanged(searchText: string): void {
    const searchTextChanged = searchText || this.currentSearchModel.searchText !== '';

    if (searchText) {
      const searchUrl = decodeURIComponent(searchText).split(';');
      this.currentSearchModel = {
        ...this.currentSearchModel,
        searchText: searchUrl[0],
        searchMode: searchUrl[1] as SearchMode
      };
      this.isMobileSearchVisible = true;
    } else {
      this.currentSearchModel = { ...this.currentSearchModel, searchText: '' };
    }

    if (searchTextChanged) {
      this.setRefocusItemModel();
      this.dataTableConfig = undefined;
      this.areConstructionDataLoaded = false;
      this.constructionData = undefined;
      this.clearMultiSelectItems();
      this.loadConstructionData();
    }
  }

  selectedPeriodsChanged(periodIds: number[]): void {
    this.selectedPeriods = periodIds;
    this.appInsightsService.trackEvent('PV-filter-period');
  }

  private clearMultiSelectItems(): void {
    if (this.currentView.viewType !== ViewType.selectedItems) {
      this.buildingProgressDispatchersService.clearMultiSelectItems();
    }
  }

  toggleMobileSearchVisible(): void {
    if (this.currentSearchModel.searchText.length === 0) {
      this.isMobileSearchVisible = !this.isMobileSearchVisible;
    }
  }

  onPercentageDraw(mobileSelection?: boolean): void {
    if (this.checkLastPeriodStateIsUnset()) {
      this.buildingProgressEditingService.openEditPercentageDialog(
        this.constructionData, this.projectId, mobileSelection);
    }
  }

  onPercentageComplete(mobileSelection?: boolean): void {
    if (this.checkLastPeriodStateIsUnset()) {
      this.buildingProgressPercentageCompleteService.completePercentage(
        this.constructionData, this.projectId, this.focusedCell, mobileSelection);
    }
  }

  checkLastPeriodStateIsUnset(): boolean {
    const lastPeriod = this.constructionData.periods[this.constructionData.periods.length - 1];
    const state = (lastPeriod.isApprovalRequested || lastPeriod.isApproved);
    if (state) {
      this.openInfoModal(this.translateService.translate('BUILDING_PROGRESS.COMPLETE.LAST_PERIOD_REQUESTED'));
    } else if (lastPeriod.isLocked) {
      this.openInfoModal(this.translateService.translate('BUILDING_PROGRESS.COMPLETE.LAST_PERIOD_LOCKED'));
      return false;
    }

    return !state;
  }

  private setSummaryPanelVisible(): void {
    this.subs.sink = this.buildingProgressSelectorsService.isSummaryPanelVisible$.subscribe(summaryPanelVisible => {
      this.isSummaryPanelVisible = summaryPanelVisible;
    });
  }

  onSummaryPanel(): void {
    this.isSummaryPanelVisible = !this.isSummaryPanelVisible;
    this.buildingProgressDataSettingsDispatchersService.saveDataSettings(
      { hiddenColumns: undefined, isSummaryPanelVisible: this.isSummaryPanelVisible });
    if (this.isSummaryPanelVisible) {
      this.appInsightsService.trackEvent('PV-summary-panel-button-open');
    } else {
      this.appInsightsService.trackEvent('PV-summary-panel-button-close');
    }
  }

  private openInfoModal(message: string): void {
    this.krosModalService.openCentered(
      InfoModalComponent,
      {
        body: message
      },
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: false,
        showMobileArrowBack: false
      }
    ).afterClosed$.pipe(
      take(1)
    ).subscribe(() => {
      this.buildingProgressDispatchersService.clearMultiSelectItems();
      this.tableChangeDetectionService.forceTableChanges();
    });
  }
}
