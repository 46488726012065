import { createAction, props } from '@ngrx/store';

import { ProjectDetail, Tag, TagCreationModel, TagEditViewModel } from '@kros-sk/ssw-shared-legacy';

import { ApplicationPermission, ApplicationSharingViewModel, ProjectOwnerEditViewModel } from '../../projects/models';

export const loadProjectDetailStart = createAction('[Projects] Load project detail start', props<{ projectId: number }>());
export const loadProjectDetailSuccess = createAction('[Projects] Load project detail success', props<{ projectDetail: ProjectDetail }>());
export const loadProjectDetailError = createAction('[Projects] Load project detail error', props<{ error: any }>());

export const setProjectDetail = createAction('[Projects] Set project detail', props<{ projectDetail: ProjectDetail }>());

export const editOwnerStart = createAction('[Projects] Edit owner start', props<{ viewModel: ProjectOwnerEditViewModel }>());
export const editOwnerSuccess = createAction('[Projects] Edit owner success');
export const editOwnerError = createAction('[Projects] Edit owner error', props<{ error: any }>());

export const loadApplicationPermissionsStart = createAction(
  '[Projects] Load application permissions start',
  props<{ projectId: number }>());
export const loadApplicationPermissionsSuccess = createAction(
  '[Projects] Load application permissions success',
  props<{ applicationPermissions: ApplicationPermission[] }>());
export const loadApplicationPermissionsError = createAction('[Projects] Load application permissions error', props<{ error: any }>());

export const shareApplications = createAction(
  '[Projects] Share applications start',
  props<{ viewModel: ApplicationSharingViewModel }>());

export const setIsProjectMembersTabSelected = createAction('[Projects] Set is project members tab selected', props<{ value: boolean }>());

export const editProjectDetailStart = createAction(
  '[Projects] Edit project detail start',
  props<{ projectDetail: ProjectDetail, subcontractorId?: number }>());
export const editProjectDetailError = createAction('[Projects] Edit project detail error', props<{ error: any }>());

export const loadTagsListStart = createAction('[Projects] Load tags list start');
export const loadTagsListSuccess = createAction('[Projects] Load tags list success', props<{ tagsList: Tag[] }>());
export const loadTagsListError = createAction('[Projects] Load tags list error', props<{ error: any }>());

export const createNewTagStart = createAction(
  '[Projects] Create new tag start',
  props<{ tag: TagCreationModel, setTagToProject: boolean }>());
export const createNewTagSuccess = createAction('[Projects] Create new tag success', props<{ tag: Tag, setTagToProject: boolean }>());
export const createNewTagError = createAction('[Projects] Create new tag error', props<{ error: any }>());

export const clearNewTagCreated = createAction('[Projects] Clear new tag created');

export const editTagStart = createAction('[Projects] Edit tag start', props<{ tag: TagEditViewModel }>());
export const editTagSuccess = createAction('[Projects] Edit tag success', props<{ tag: Tag }>());
export const editTagError = createAction('[Projects] Edit tag error', props<{ error: any }>());
