import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-boq-panel-header',
  templateUrl: './boq-panel-header.component.html',
  styleUrls: ['./boq-panel-header.component.scss']
})
export class BoqPanelHeaderComponent {
  @Input() headerText: string;

  @Output() closeHeaderClicked = new EventEmitter<void>();
  @Output() resizePanelClicked = new EventEmitter<void>();

  onResizePanelClick(): void {
    this.resizePanelClicked.emit();
  }

  onCloseClick(): void {
    this.closeHeaderClicked.emit();
  }
}
