import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AddressSelectorModule } from '../address-selector/address-selector.module';
import { CompaniesRegisterSearchModule } from '../companies-register-search/companies-register-search.module';
import { CompanyInfoComponent} from './company-info/company-info.component';
import { CountrySelectorModule } from '../country-selector/country-selector.module';
import { DirectivesModule } from '../directives/directives.module';
import { KrosInputsModule } from '../inputs';

@NgModule({
  imports: [
    DirectivesModule,
    AddressSelectorModule,
    CommonModule,
    ReactiveFormsModule,
    KrosInputsModule,
    CompaniesRegisterSearchModule,
    CountrySelectorModule
  ],
  declarations: [CompanyInfoComponent],
  exports: [CompanyInfoComponent],
})
export class CompanyInfoModule {
}
