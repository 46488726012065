import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { FileUploadModule } from 'ng2-file-upload';

import { KrosModalService } from '@kros-sk/components';
import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { BootstrapModule } from '../bootstrap';
import { DeleteDocumentService, DocumentCreateService, DocumentEditService, DocumentModalEditService } from './services';
import {
  DocumentCreateComponent,
  FolderCreateDialogComponent,
  FolderRenameDialogComponent,
  UploadButtonComponent,
  UploadVersionButtonComponent
} from './components';
import { DocumentExplorerModule } from '../document-explorer';
import { DocumentsEditDispatchersService } from './store';
import { TranslateModule } from '../translate';
import { UploadModule } from '../upload';

@NgModule({
  declarations: [
    UploadButtonComponent,
    UploadVersionButtonComponent,
    FolderCreateDialogComponent,
    DocumentCreateComponent,
    FolderRenameDialogComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ReactiveFormsModule,
    FormsModule,
    TooltipDirective,
    FileUploadModule,
    UploadModule,
    BootstrapModule,
    DocumentExplorerModule
  ],
  providers: [
    DocumentEditService,
    DeleteDocumentService,
    DocumentsEditDispatchersService,
    DocumentModalEditService,
    DocumentCreateService,
    KrosModalService
  ],
  exports: [
    UploadButtonComponent,
    UploadVersionButtonComponent,
    FolderCreateDialogComponent,
    DocumentCreateComponent,
    FolderRenameDialogComponent,
    DocumentExplorerModule
  ]
})
export class DocumentEditModule { }
