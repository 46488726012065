<div class="split-item-splitter">
  <span #splitBoxLabel
    tabindex="0"
  >{{'BUILDING_PROGRESS.PARTIAL_ITEMS.NUMBER_OF_ITEMS_AFTER_SPLIT' | translate }}</span>

  <dx-number-box
    [ngModel]="partialItemsCounter$ | async"
    (ngModelChange)="initChangeSplitNumber($event)"
    [showSpinButtons]="true"
    [min]="2"
    (onKeyDown)="onKeyDown($event)"
  ></dx-number-box>
</div>
