import { inject, Injectable } from '@angular/core';

import { KrosModalService } from '@kros-sk/components';

import { ApprovalOrderDialogComponent } from '../features';

@Injectable()
export class ApprovalOrderHelper {

  private krosModalService = inject(KrosModalService);

  openApprovalOrderDialog(projectId: number, isTableReadOnly: boolean): void {
    this.krosModalService.openCentered(
      ApprovalOrderDialogComponent,
      { projectId, isTableReadOnly },
      { closeOnBackdropClick: false }
    );
  }
}
