import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { DigitsOnlyDirective } from '../directives/digits-only.directive';
import { KrosControl } from './kros-control.directive';
import { KrosFormFieldComponent } from './kros-form-field.component';
import { KrosInputError } from './kros-error.directive';
import { KrosInputHint } from './kros-hint.directive';
import { KrosInputLoader } from './kros-input-loader.component';
import { KrosLabel } from './kros-label.directive';
import { KrosPrefix } from '../directives/kros-prefix.directive';
import { KrosSuffix } from '../directives/kros-suffix.directive';
import { NumbersOnlyDirective } from '../directives/numbers-only.directive';

const COMPONENTS = [
  DigitsOnlyDirective,
  NumbersOnlyDirective,
  KrosControl,
  KrosFormFieldComponent,
  KrosInputError,
  KrosInputHint,
  KrosInputLoader,
  KrosLabel,
  KrosPrefix,
  KrosSuffix,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    TooltipDirective
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class KrosFormFieldModule {}
