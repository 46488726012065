import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { KrosModalRef } from '@kros-sk/components';

import { tagsColorCodes } from '../tag-utils';
import { Tag } from '../tag.model';
import { TagCreationModel } from '../tag-creation.model';

@Component({
  selector: 'kros-tag-create-dialog',
  templateUrl: './tag-create-dialog.component.html',
  styleUrls: ['./tag-create-dialog.component.scss']
})
export class TagCreateDialogComponent implements OnInit {
  form: UntypedFormGroup;
  colorCodes = tagsColorCodes;
  selectedColor: { colorCode: string, textColorCode: string } = tagsColorCodes[0];
  alreadyExistingTags: Tag[];

  get colors(): string[] {
    return this.colorCodes.map(c => c.colorCode);
  }

  get tagName(): AbstractControl {
    return this.form.get('tagName');
  }

  constructor(
    private modalRef: KrosModalRef,
    private formBuilder: UntypedFormBuilder
  ) {
    this.alreadyExistingTags = this.modalRef.data.alreadyExistingTags;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      tagName: ['', [Validators.required, Validators.maxLength(25)]]
    });
  }

  onColorSelect(color: string): void {
    this.selectedColor = this.colorCodes.find(c => c.colorCode === color);
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  onSubmit(): void {
    const tag = {
      name: this.tagName.value.toString().trim(),
      colorCode: this.selectedColor.colorCode,
      textColorCode: this.selectedColor.textColorCode
    } as TagCreationModel;

    if (this.alreadyExistingTags.some(t => t.name === tag.name)) {
      this.tagName.setErrors({ alreadyExists: true });
      this.tagName.markAsTouched();
    } else {
      this.modalRef.submit({ tag });
    }
  }
}
