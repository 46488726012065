<div
  ngbDropdown
  autoClose="true"
>
  <button
    ngbDropdownToggle
    class="btn btn-sm btn-link ga-views"
    type="button"
    data-test="view-picker-dropdown"
  >
    <span class="ga-views">{{ 'BUILDING_PROGRESS.VIEWS.HEADING' | translate }}:&nbsp;</span>

    <span
      class="ga-views"
      [class.font-weight-bold]="isViewActive"
    >
      {{ viewsService.currentView.panelDescription | translate }}
    </span>

    <i class="material-icons ga-views">expand_more</i>
  </button>

  <div
    ngbDropdownMenu
    display="dynamic"
  >
    <div class="menu-header no-selection"><i class="material-icons">view_week</i>
      <span>{{ 'BUILDING_PROGRESS.VIEWS.TYPE_ALL_PERIODS' | translate }}</span>
    </div>
    <button
      class="view-item"
      ngbDropdownItem
      *ngFor="let view of getAllPeriodsTypeViews()"
      [class.font-weight-bold]="viewsService.currentView.viewType === view.viewType"
      type="button"
      (click)="selectView(view)"
      data-test="view-button"
    >
      <span class="view-item-text">{{ view.menuDescription | translate }}</span>
    </button>
    <div class="menu-header no-selection"><i class="material-icons">visibility</i>
      <span>{{ 'BUILDING_PROGRESS.VIEWS.TYPE_SELECTED_PERIODS' | translate }}</span>
    </div>
    <button
      class="view-item"
      ngbDropdownItem
      *ngFor="let view of getSelectedPeriodsTypeViews()"
      [class.font-weight-bold]="viewsService.currentView.viewType === view.viewType"
      type="button"
      (click)="selectView(view)"
      data-test="view-button"
    >
      <span class="view-item-text">{{ view.menuDescription | translate }}</span>
    </button>
    <div class="menu-header no-selection"><i class="material-icons">summarize</i>
      <span>{{ 'BUILDING_PROGRESS.VIEWS.TYPE_RECAPITULATIONS' | translate }}</span>
    </div>
    <button
      ngbDropdownItem
      *ngFor="let view of getRecapsTypeViews()"
      [class.font-weight-bold]="viewsService.currentView.viewType === view.viewType"
      type="button"
      (click)="selectView(view)"
      data-test="view-button"
    >
      <span class="view-item-text">{{ view.menuDescription | translate }}</span>
    </button>
  </div>
</div>
