import { Component, EventEmitter, inject, Input, Output } from '@angular/core';

import { getTagName } from '../tag-utils';
import { Tag } from '../tag.model';
import { TranslateService } from '../../translate';

@Component({
  selector: 'kros-tags-filter',
  templateUrl: './tags-filter.component.html',
  styleUrls: ['./tags-filter.component.scss']
})
export class TagsFilterComponent {
  private readonly translate = inject(TranslateService);

  @Input() label = 'FILTER.PROJECTS';
  @Input() tags: Tag[] = [];
  @Input() activeTagIds: number[] = [];
  @Input() maxTagsLimit: number;

  @Output() tagFilterChanged: EventEmitter<number[]> = new EventEmitter();

  get isAddTagButtonVisible(): boolean {
    return this.tags?.length !== this.activeTagIds.length;
  }

  get activeTags(): Tag[] {
    return this.tags?.filter(tag => this.activeTagIds.some(tagId => tagId === tag.id)) ?? [];
  }

  get displayedTags(): Tag[] {
    if (this.activeTags?.length > this.maxTagsLimit) {
      return this.activeTags.slice(0, this.maxTagsLimit);
    }
    return this.activeTags;
  }

  constructor() { }

  isTagActive(tag: Tag): boolean {
    return this.activeTagIds.some(tagId => tagId === tag.id);
  }

  onTagSelected(tag: Tag): void {
    this.activeTagIds = this.activeTagIds.concat(tag.id);
    this.tagFilterChanged.emit(this.activeTagIds);
  }

  onTagDeleted(tag: Tag): void {
    this.activeTagIds = this.activeTagIds.filter(tagId => tagId !== tag.id);
    this.tagFilterChanged.emit(this.activeTagIds);
  }

  getTagName(key: string): string {
    return getTagName(key, this.translate);
  }
}
