<div class="toolbar-actions">
  <div class="actions left">
    <div class="tablet-visible">
      <button
        class="btn toolbar-action gray-toolbar-action no-selection"
        type="button"
        (click)="onBackClick()"
        krosTooltip="{{ 'CHANGE_SHEET.BACK_TO_LIST' | translate }}"
      >
        <i class="material-icons icon-button">chevron_left</i>
      </button>
    </div>

    <div class="tablet-visible">
      <button
        class="btn toolbar-action no-selection gray-toolbar-action ga-ba-tablet-show-change-sheet-structure"
        type="button"
        (click)="showChangeSheetItems()"
        krosTooltip="{{ 'CHANGE_SHEET.STRUCTURE' | translate }}"
        data-test="budget-approval-show-change-sheet-items"
      >
        <i class="material-icons icon-button ga-ba-tablet-show-change-sheet-structure">list</i>
        <span class="toolbar-action-text">{{ 'CHANGE_SHEET.STRUCTURE' | translate }}</span>
      </button>
    </div>

    <button
      *ifBudgetApprovalFunctionsPermission="!isChangeSheetApproved"
      class="btn toolbar-action no-selection gray-toolbar-action ga-ba-change-sheet-approve"
      type="button"
      (click)="onApproveClick()"
      krosTooltip="{{ 'BUDGET_APPROVAL.APPROVE.APPROVE_CHANGE_SHEET' | translate }}"
      data-test="budget-approval-approve"
    >
      <i class="material-icons icon-button ga-ba-change-sheet-approve">done_all</i>
      <span class="toolbar-action-text">{{ 'BUDGET_APPROVAL.APPROVE.APPROVE_CHANGE_SHEET' | translate }}</span>
    </button>

    <button
      *ngIf="canOpenBoq"
      class="btn toolbar-action no-selection gray-toolbar-action ga-ba-budget-open-boq"
      type="button"
      (click)="openBoq()"
      krosTooltip="{{ 'BUDGET_APPROVAL.BILL_OF_QUANTITIES' | translate }}"
      data-test="budget-approval-open-boq-panel"
    >
      <i class="material-icons icon-button ga-ba-budget-open-boq">square_foot</i>
      <span class="toolbar-action-text">{{ 'BUDGET_CHANGES.BILL_OF_QUANTITIES' | translate }}</span>
    </button>

    <button
      *ifBudgetApprovalFunctionsPermission
      class="btn toolbar-action no-selection gray-toolbar-action ga-ba-budget-export"
      type="button"
      (click)="onExportClick()"
      krosTooltip="{{ 'BUDGET_APPROVAL.EXPORT.EXPORT' | translate }}"
      data-test="budget-approval-detail-export"
    >
      <i class="material-icons icon-button ga-ba-budget-export">save_alt</i>
      <span class="toolbar-action-text">{{ 'BUDGET_APPROVAL.EXPORT.EXPORT' | translate }}</span>
    </button>

    <div
      *ifBudgetApprovalFunctionsPermission
      class="toolbar-action no-selection gray-toolbar-action dropdown-action"
    >
      <div
        ngbDropdown
        container="body"
      >
        <button
          ngbDropdownToggle
          class="btn dropdown-toggle"
          type="button"
          data-test="change-sheet-more-dropdown"
        >
          <div
            class="dropdown-toggle-content"
            krosTooltip="{{ 'COMMON.MORE' | translate }}"
          >
            <i class="material-icons icon-button">more_vert</i>
            <span class="toolbar-action-text">{{ 'COMMON.MORE' | translate }}</span>
          </div>
        </button>

        <div
          ngbDropdownMenu
          aria-labelledby="moreDropdown"
          display="dynamic"
        >
          <button
            ngbDropdownItem
            class="ga-ba-change-sheet-cancel-relation"
            type="button"
            krosTooltip="{{ 'CHANGE_SHEET.RELATION.DELETE' | translate }}"
            position="before"
            data-test="change-sheet-delete-relation"
            (click)="onDeleteRelationClick()"
          >
            <i class="material-icons icon-button ga-ba-change-sheet-cancel-relation">delete</i>
            <span>{{ 'CHANGE_SHEET.RELATION.DELETE' | translate }}</span>
          </button>

          <button
            ngbDropdownItem
            class="ga-ba-change-sheet-cancel-approved"
            type="button"
            krosTooltip="{{ 'BUDGET_APPROVAL.APPROVE.CANCEL_APPROVED_CHANGE_SHEET' | translate }}"
            position="before"
            data-test="budget-approval-cancel-approved"
            (click)="onCancelApprovedClick()"
          >
            <i class="material-icons icon-button ga-ba-change-sheet-cancel-approved">remove_done</i>
            <span>{{ 'BUDGET_APPROVAL.APPROVE.CANCEL_APPROVED_CHANGE_SHEET' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
