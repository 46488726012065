<div class="kros-modal-body alert">
    <h1 *ngIf="data.caption"
        class="alert-caption">{{data.caption}}</h1>
    <p *ngIf="data.suggestion"
       class="alert-suggestion default-font-family">{{data.suggestion}}</p>
    <button
        *ngIf="data.closeButtonText"
        class="btn btn-primary btn-submit"
        type="button"
        (click)="onClose()"
    >{{data.closeButtonText}}</button>
</div>
