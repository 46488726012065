import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { DeviceDetectorService } from '@kros-sk/components';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { Cell, ColDef, ColDefType, DataTableConfigModel } from './data-table-config.model';
import { EditHelper } from './helpers/edit.helper';
import { getCellClass, getCellStyle, getColumnStyle, getFormattedValue, getHeaderCellClass, getTitle } from './helpers';
import { isItem } from '../budget/helpers';

const multiSelectColumnWidth = 26;

@Component({
  selector: 'kros-data-table',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  providers: [EditHelper]
})
export class DataTableComponent extends UnsubscribeComponent implements OnInit {

  @ViewChild('table') tableElement: ElementRef<HTMLElement>;
  @ViewChild(CdkVirtualScrollViewport) public viewPort: CdkVirtualScrollViewport;

  @Input() itemHeight: number;
  @Input() data: any;
  @Input() forceChangeDetection: Subject<void>;
  @Input() hiddenColumns$?: Observable<string[]>;
  @Input() appInsightsPrefix?: string;

  @Input() set itemCount(value: number) {
    this.editHelper.itemCount = value;
  }

  @Input()
  set config(value: DataTableConfigModel) {
    this.editHelper.config = value;
    this.setLeftForFixedColumns();
  }

  get config(): DataTableConfigModel {
    return this.editHelper.config;
  }

  @Input()
  set focusedCell(value: Cell) {
    this._focusedCell = value;
    this.editHelper.setFocusedCell(value);
  }

  get focusedCell(): Cell {
    return this._focusedCell;
  }

  @Output() cellFocused = new EventEmitter<{ cell: Cell, cantLostFocus?: boolean }>();
  @Output() scrollFocusedCellToView = new EventEmitter<boolean>();
  @Output() headerCheckChanged = new EventEmitter<boolean>();
  @Output() itemCheckChanged = new EventEmitter<{ item: any, state: boolean }>();
  @Output() openCommentsClick = new EventEmitter<Cell>();
  @Output() itemDblClick = new EventEmitter<Cell>();

  colType = ColDefType;

  get placeholderHeight(): string {
    if (!this.viewPort || !this.viewPort['_renderedContentOffset']) {
      return '0px';
    }
    return `${this.viewPort['_renderedContentOffset']}px`;
  }

  private _focusedCell: Cell;
  private isMobileResolution: boolean;
  private lastCheckedItemId: number;

  constructor(
    public editHelper: EditHelper,
    private changeDetector: ChangeDetectorRef,
    private deviceDetector: DeviceDetectorService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.forceChangeDetection?.subscribe(() => this.changeDetector.detectChanges());
    this.editHelper.init(this.cellFocused, this.scrollFocusedCellToView,
      (p) => this._focusedCell = p, this.itemDblClick, this.appInsightsPrefix);
    this.subs.sink = this.deviceDetector.deviceType$.subscribe(() => this.setLeftForFixedColumns());

    if (this.hiddenColumns$) {
      this.subs.sink = this.hiddenColumns$.subscribe(() => this.setLeftForFixedColumns());
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event): void {
    if (!this.tableElement.nativeElement.contains(event.target)) {
      this.editHelper.editingCell = undefined;
    }
  }

  hasColGroups(): boolean {
    return this.config ? this.config.colDefs.some(colDef => colDef.type === ColDefType.ColumnGroup) : false;
  }

  hasDropdownComponent(colDef: ColDef, item: any): boolean {
    return colDef.dropdownComponentConfig && colDef.dropdownComponentConfig.showCondition(item);
  }

  openDropdownComponent(colDef: ColDef, dropdownCellComponent: any, itemIndex: number): void {
    dropdownCellComponent.openChange.pipe(take(2)).subscribe(wasOpened => {
      if (!wasOpened) {
        document.getElementById(colDef.id + '-row-' + itemIndex)?.focus();
      }
    });
    this.config.openDropdownComponent(colDef, dropdownCellComponent, this.data.items[this.focusedCell.rowIndex].id);
  }

  getColumnStyle(colDef: ColDef, fixedWidth: boolean, item?: any): any {
    return getColumnStyle(colDef, fixedWidth, item);
  }

  getPlaceHolderStyle(): any {
    if (!this.viewPort || !this.viewPort['_renderedContentOffset']) {
      return { height: '0px' };
    }
    return { height: this.viewPort['_renderedContentOffset'] + 'px' };
  }

  getHeaderCellClass(colDef: ColDef, isLastInGroup?: boolean, groupColDef?: ColDef): string {
    return getHeaderCellClass(colDef, isLastInGroup, groupColDef);
  }

  getCellClass(colDef: ColDef, rowIndex: number, item: any, isLastInGroup?: boolean, groupColDef?: ColDef): string {
    return getCellClass(colDef, rowIndex, item, this.focusedCell, this.isItemRowSelected(item), isLastInGroup, groupColDef);
  }

  isItemRowSelected(item: any): boolean {
    return this.isItemCheckChecked(item) && !this.isItemCheckIndeterminate(item);
  }

  getCellTooltip(colDef: ColDef, item: any): string {
    if (colDef.valueTooltip) {
      return this.getCellValue(colDef, item);
    }
    return this.hasDropdownComponent(colDef, item) ? colDef.dropdownComponentConfig.tooltip : '';
  }

  getRowClass(item: any, rowIndex: number): string {
    return (this.config?.rowClassGetter ? this.config?.rowClassGetter(item) : '') + ' row-' + rowIndex;
  }

  getCellValue(colDef: ColDef, item: any): string {
    const value = colDef.valueGetter ? colDef.valueGetter(item) : item[colDef.id];
    const editedValue = this.editHelper.getEditedValue(item.id, colDef.id);

    const ret = getFormattedValue(editedValue ?? value, colDef, false, item);

    if (!ret) {
      return '';
    }

    return ret;
  }

  getCellActionButtonId(colDef: ColDef, item: any): string {
    return colDef.cellActionButton?.id ? colDef.cellActionButton.id(item) : '';
  }

  hasComments(colDef: ColDef, item: any): boolean {
    return this.config.hasCommentsGetter ? this.config.hasCommentsGetter(colDef, item) : false;
  }

  openComments(colDef: ColDef, rowIndex: number): void {
    this.openCommentsClick.emit({ colId: colDef.id, params: colDef.params, rowIndex } as Cell);
  }

  isColumnVisible(colDef: ColDef): boolean {
    if (colDef.mobileHidden && this.isMobileResolution) {
      return false;
    }
    return this.config.isColumnVisible ? this.config.isColumnVisible(colDef) : true;
  }

  isColumnGroupVisible(colDef: ColDef): boolean {
    return colDef.colsInGroup.some(groupColDef => this.isColumnVisible(groupColDef));
  }

  getCellStyle(colDef: ColDef, parentCol?: ColDef): any {
    return getCellStyle(colDef, parentCol);
  }

  isItemCheckChecked(item: any): boolean {
    return this.config?.isItemSelectedGetter ? this.config.isItemSelectedGetter(item) : false;
  }

  isItemCheckIndeterminate(item: any): boolean {
    return this.config?.isItemIndeterminateGetter ? this.config.isItemIndeterminateGetter(item) : false;
  }

  isHeaderCheckChecked(): boolean {
    return this.data.items.some(item => this.isItemCheckChecked(item));
  }

  isHeaderCheckIndeterminate(): boolean {
    return this.data.items.some(item => this.isItemCheckChecked(item)) && this.data.items.some(item => !this.isItemCheckChecked(item));
  }

  onHeaderCheckChanged(event: any): void {
    this.headerCheckChanged.emit(event.checked);
  }

  onItemCheckChanged(event: any, item: any): void {
    const checked = event.target.checked;
    event.target.checked = false;
    this.itemCheckChanged.emit({ item, state: checked });
    this.lastCheckedItemId = checked ? item.id : undefined;
  }

  onCheckItem(event: any, item: any): void {
    if (event.shiftKey && this.lastCheckedItemId) {
      const markedItems = this.getShiftMarkedItems(item);
      markedItems.forEach(x => this.itemCheckChanged.emit({ item: x, state: true }));
      if (markedItems.length > 1) {
        this.lastCheckedItemId = item.id;
      }
    }
    document.getSelection().removeAllRanges(); // prevent text selection in firefox
  }

  onRowClicked(item: any): void {
    if (this.config.rowClicked) {
      this.config.rowClicked(item);
    }
  }

  isComponent(colDef: ColDef): boolean {
    return !!colDef.componentConfig;
  }

  isComponentHidden(colDef: ColDef, item: any): boolean {
    return !!colDef.componentConfig.params.hidden ? colDef.componentConfig.params.hidden(item) : false;
  }

  onStatusIconClick(action: Function): void {
    if (action) {
      action();
    }
  }

  getNumOfVisibleColumns(colDef: ColDef): number {
    return colDef.colsInGroup?.length ? colDef.colsInGroup.filter(c => !c.isHidden).length : 0;
  }

  getTitle(colDef: ColDef): string {
    return getTitle(colDef);
  }

  private setLeftForFixedColumns(): void {
    let width = this.config.isMultiSelect ? multiSelectColumnWidth : 0;
    const padding = 11;

    this.config.colDefs.forEach((col: ColDef) => {
      if (col.isFixed && this.isColumnVisible(col)) {
        col.left = width;
        width += col.width + padding;
      }
    });
  }

  private getShiftMarkedItems(item: any): any[] {
    const selectedIndex = this.data.items.findIndex(i => i.id === item.id);
    const lastSelectedIndex = this.data.items.findIndex(i => i.id === this.lastCheckedItemId);

    return ((selectedIndex < lastSelectedIndex)
      ? this.getMarkedItemsByIndex(selectedIndex, lastSelectedIndex)
      : this.getMarkedItemsByIndex(lastSelectedIndex, selectedIndex)
    ).filter(mi => !(mi.id === item.id || mi.id === this.lastCheckedItemId));
  }

  private getMarkedItemsByIndex(startIndex: number, lastIndex: number): any[] {
    const markedItems: any[] = [];
    for (let index = startIndex; index <= lastIndex; index++) {
      if (isItem(this.data.items[index])) {
        markedItems.push(this.data.items[index]);
      }
    }

    return markedItems;
  }
}
