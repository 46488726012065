<div
  #tooltipRef
  class="kros-tooltip"
  [ngClass]="tooltipClass"
>
  <div class="kros-tooltip__content">
    <ng-container *ngIf="isStringContent else contentTemplate">
      {{ tooltip }}
    </ng-container>

    <ng-template #contentTemplate>
      <ng-container [ngTemplateOutlet]="tooltip" [ngTemplateOutletContext]="context"></ng-container>
    </ng-template>
  </div>
</div>
