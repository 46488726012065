import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter } from 'rxjs';

import { RegistrationNotificationService } from '@kros-sk/ssw-cdk';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit {

  private destroyRef = inject(DestroyRef);
  private notificationService = inject(RegistrationNotificationService);
  private router = inject(Router);

  ngOnInit(): void {
    this.notificationService.demoProjectNavigated$
      .pipe(filter(application => !!application), takeUntilDestroyed(this.destroyRef))
      .subscribe(application => this.router.navigate(['projects', 'navigate', application, 'demo']));
  }
}
