import { BuildingProgressPermissionType } from '@kros-sk/ssw-shared/permission';


export const getAppInsightsPrefix = (isContractor: boolean): string => {
  return isContractor ? 'PVSC-' : 'PV-';
};

export const hasContractorRight = (buildingProgressPermissionType: BuildingProgressPermissionType): boolean => {
  return ((buildingProgressPermissionType | BuildingProgressPermissionType.Contractor) === buildingProgressPermissionType);
};

export const hasContractorFullRight = (buildingProgressPermissionType: BuildingProgressPermissionType): boolean => {
  return ((buildingProgressPermissionType | BuildingProgressPermissionType.ContractorFull) === buildingProgressPermissionType);
};

export const hasApproverRight = (buildingProgressPermissionType: BuildingProgressPermissionType): boolean => {
  return ((buildingProgressPermissionType | BuildingProgressPermissionType.Approver) === buildingProgressPermissionType);
};

export const hasApproverViewRight = (buildingProgressPermissionType: BuildingProgressPermissionType): boolean => {
  return ((buildingProgressPermissionType | BuildingProgressPermissionType.ApproverView) === buildingProgressPermissionType);
};

export const hasExporterRight = (buildingProgressPermissionType: BuildingProgressPermissionType): boolean => {
  return ((buildingProgressPermissionType | BuildingProgressPermissionType.Exporter) === buildingProgressPermissionType);
};
