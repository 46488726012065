<ng-container *ngIf="!loading else contentLoader">
  <div
    *ngIf="!editMode else periodDetail"
    class="period-list"
  >
    <div class="periods">
      <div
        *ngFor="let period of periods"
        class="period"
        id="period-{{period.id}}"
        [ngClass]="{'selected': (selectedPeriod && selectedPeriod.id === period.id)}"
        (click)="onSelectPeriod(period)"
        data-test="period-in-period-list"
      >
        <span class="state flex-column">
          <i
            *ngIf="period.isApproved && !canShowPeriodActionButton(period)"
            class="material-icons"
            data-test="period-approved"
            krosTooltip="{{ 'BUILDING_PROGRESS.PERIODS.PERIOD_APPROVED' | translate}}"
          >done</i>

          <i
            *ngIf="period.isApprovalRequested && !period.isApproved && !period.isMultiStageApprovalInProgress"
            class="material-icons"
            data-test="period-approvale-request-sent"
            krosTooltip="{{ 'BUILDING_PROGRESS.PERIODS.REQUEST_FOR_APPROVAL_SENT' | translate}}"
          >forward_to_inbox</i>

          <i
            *ngIf="period.isApprovalRequested && !period.isApproved && period.isMultiStageApprovalInProgress"
            class="material-icons"
            data-test="period-multistage-approval-in-progress"
            krosTooltip="{{ 'BUILDING_PROGRESS.PERIODS.APPROVAL_IN_PROGRESS' | translate}}"
          >rule</i>

          <i
            *ngIf="period.isLocked && !period.isApprovalRequested && !period.isApproved"
            class="material-icons"
            data-test="period-locked"
            krosTooltip="{{ 'BUILDING_PROGRESS.PERIODS.LOCKED' | translate}}"
          >lock</i>
        </span>

        <span class="date">{{ period.dateFrom | periodDate: period.dateTo }}</span>

        <i
          class="view flex-column material-icons"
          id="ga-period-view"
          data-test="period-mobile-view"
          (click)="periodViewClicked.emit(period)"
        >visibility</i>

        <i
          class="edit flex-column material-icons"
          id="ga-period-edit"
          data-test="period-mobile-edit"
          [hidden]="!canEdit(period)"
          (click)="onEdit()"
        >edit</i>

        <i
          class="delete flex-column material-icons"
          id="ga-period-delete"
          data-test="period-mobile-delete"
          [hidden]="!canEdit(period)"
          (click)="onDelete(period)"
        >delete</i>

        <i
          *ngIf="canLock(period)"
          class="lock flex-column material-icons"
          id="ga-period-lock-request"
          data-test="period-lock-request"
          (click)="onLockClick()"
          krosTooltip="{{ 'BUILDING_PROGRESS.PERIODS.LOCK' | translate}}"
        >lock</i>

        <i
          *ngIf="canUnlock(period)"
          class="lock flex-column material-icons"
          id="ga-period-unlock-request"
          data-test="period-unlock-request"
          (click)="onUnlockClick()"
          krosTooltip="{{ 'BUILDING_PROGRESS.PERIODS.UNLOCK' | translate}}"
        >lock_open</i>

        <i
          *ngIf="canSend(period)"
          class="send flex-column material-icons"
          id="ga-period-sent-request"
          data-test="period-sent-request"
          (click)="onPeriodAction()"
          krosTooltip="{{ 'BUILDING_PROGRESS.PERIODS.SEND_REQUEST' | translate}}"
        >mail_outline</i>

        <i
          *ngIf="canApprove(period)"
          class="done flex-column material-icons"
          id="ga-period-approve"
          data-test="period-approve"
          (click)="onPeriodAction()"
          krosTooltip="{{ 'BUILDING_PROGRESS.PERIODS.APPROVE_PERIOD' | translate}}"
        >done</i>

        <i
          *ngIf="canApprove(period)"
          class="clear flex-column material-icons"
          id="ga-period-disapprove"
          data-test="period-disapprove"
          (click)="onCancelIsApprovalRequested()"
          krosTooltip="{{ 'BUILDING_PROGRESS.PERIODS.CANCEL_APPROVAL_REQUESTED' | translate}}"
        >clear</i>

        <i
          *ngIf="canCancelApproved(period)"
          class="cancel flex-column material-icons"
          id="ga-period-cancel-approve"
          data-test="period-cancel-approve"
          (click)="onCancelApproved(period)"
          krosTooltip="{{ 'BUILDING_PROGRESS.PERIODS.CANCEL_APPROVED' | translate}}"
        >highlight_off</i>

        <div
          *ngIf="canShowMenu(period)"
          class="menu"
          ngbDropdown
          display="dynamic"
          data-test="period-selected-drop-down-menu"
        >
          <button
            ngbDropdownToggle
            class="btn dropdown-toggle-arrow-hidden"
            type="button"
          >
            <i class="material-icons">more_vert</i>
          </button>

          <div ngbDropdownMenu>
            <button
              *ngIf="canEdit(period)"
              ngbDropdownItem
              (click)="onEdit()"
              class="btn link"
              type="button"
              data-test="period-edit"
            >
              <i class="material-icons">edit</i>
              <span>{{ 'COMMON.EDIT' | translate }}</span>
            </button>

            <button
              *ngIf="canEdit(period)"
              ngbDropdownItem
              (click)="onDelete(period)"
              class="btn link ga-period-delete"
              type="button"
              data-test="period-delete"
            >
              <i class="material-icons ga-period-delete">delete</i>
              <span class="ga-period-delete">{{ 'COMMON.DELETE' | translate }}</span>
            </button>
          </div>
        </div>
      </div>

      <div
        *ngIf="noPeriod"
        class="text-center no-periods"
      >
        {{'BUILDING_PROGRESS.PERIODS.NO_PERIODS' | translate}}
      </div>
    </div>
  </div>
</ng-container>

<ng-template #contentLoader>
  <kros-timeline [type]="timelineType.FourLine"></kros-timeline>
</ng-template>

<ng-template #periodDetail>
  <div class="detail">
    <app-period-date-picker
      [selectedPeriod]="selectedPeriod"
      [lastPeriod]="lastPeriod"
      [vatRates]="vatRates"
      (close)="onCloseEditMode()"
      (save)="onSave($event)"
    ></app-period-date-picker>
  </div>
</ng-template>

<ng-template #approvalOrderLink>
  <div>
    {{'BUILDING_PROGRESS.PERIODS.REQUEST_APPROVAL_QUESTION_MULTI_STAGE_MESSAGE' | translate}}

    <button
      class="btn btn-link-primary link-color"
      (click)="onApprovalOrder()"
      type="button"
      data-test="approval-order-request-approve-link"
    >
      {{'BUILDING_PROGRESS.PERIODS.REQUEST_APPROVAL_QUESTION_MULTI_STAGE_LINK' | translate}}</button>
  </div>
</ng-template>
