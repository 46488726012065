import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { take } from 'rxjs/operators';

import { BuildingProgressPermissionType, PermissionType } from '@kros-sk/ssw-shared/permission';
import { KrosModalService } from '@kros-sk/components';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { NewSharingComboMode } from '../../models/sharing-combo-mode.enum';
import { PermissionListService } from '../../services/permission-list.service';
import { SharingPermission, SharingPermissionValues } from '../../models/sharing-permission.interface';
import { UserSharingPermissionModalComponent } from '../user-sharing-permission-modal/user-sharing-permission-modal.component';

@Component({
  selector: 'kros-user-sharing-dropdown',
  templateUrl: './user-sharing-dropdown.component.html',
  styleUrls: ['./user-sharing-dropdown.component.scss']
})
export class UserSharingDropdownComponent extends UnsubscribeComponent implements OnInit, OnChanges {
  @Input() defaultValues: SharingPermissionValues;
  @Input() sharingMode: NewSharingComboMode = NewSharingComboMode.Undefined;
  @Input() disabled = false;
  @Input() compactMode = false;
  @Input() menuDataTestValue: string;
  @Input() permissionType: PermissionType;
  @Input() additionalPermissionType: BuildingProgressPermissionType;

  @Output() permissionChanged = new EventEmitter<SharingPermissionValues>();

  permissionList: SharingPermission[] = [];
  selectedPermission: SharingPermission;

  private indeterminateRights = false;

  constructor(
    private permissionListService: PermissionListService,
    private krosModalService: KrosModalService
  ) {
    super();
  }

  ngOnInit(): void {
    this.indeterminateRights = this.defaultValues.permission === PermissionType.Unidentified;
    this.selectedPermission = this.getSelectedPermission();
    this.permissionList = this.permissionListService.getPermissionList(
      this.sharingMode,
      this.indeterminateRights,
      this.permissionType,
      this.additionalPermissionType
    );
  }

  ngOnChanges(): void {
    this.selectedPermission = this.getSelectedPermission();
  }

  onPermissionChanged(permission: SharingPermission): void {
    if (this.selectedPermission?.permissions.permission !== permission.permissions.permission ||
      this.selectedPermission?.permissions.additionalPermission !== permission.permissions.additionalPermission) {
      this.selectedPermission = permission;

      this.permissionChanged.emit({
        permission: this.selectedPermission.permissions.permission,
        additionalPermission: this.selectedPermission.permissions.additionalPermission
      });
    }
  }

  showPermissionModal(): void {
    this.subs.sink = this.krosModalService.openCentered(
      UserSharingPermissionModalComponent,
      {
        permissionList: this.permissionList,
        selectedPermission: this.selectedPermission
      },
      {
        addModalToBrowsersHistory: false
      }
    ).afterClosed$
      .pipe(take(1))
      .subscribe(result => {
        if (result.type === 'submit') {
          this.onPermissionChanged(result.data);
        }
      });
  }

  private getSelectedPermission(): SharingPermission {
    return this.permissionListService.getSharingPermissionByValues(
      this.sharingMode, this.indeterminateRights, this.defaultValues, this.permissionType, this.additionalPermissionType);
  }
}
