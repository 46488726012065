import { inject, Injectable } from '@angular/core';

import { formatString } from '@kros-sk/ssw-cdk';
import { MarketingContentHelper, NonPaidPackageConfig } from '@kros-sk/ssw-shared-legacy';

import { BuildingObjectsUploadLicenseHelper } from '../building-objects-upload-license.helper';

@Injectable()
export class BuildingObjectsUploadMarketingContentHelper extends MarketingContentHelper {

  private buildingObjectsUploadLicenseHelper = inject(BuildingObjectsUploadLicenseHelper);

  init(config: NonPaidPackageConfig | any): void {
    this.config = config ?? {};
    this.config.isLicenseReadonly = this.buildingObjectsUploadLicenseHelper.isLicenseReadonly();
    this.config.isLicenseFull = this.buildingObjectsUploadLicenseHelper.isLicenseFullOrTrial();
    this.config.isLicenseToExpire = this.buildingObjectsUploadLicenseHelper.isLicenseToExpire();
    this.config.isLicenseFree = this.buildingObjectsUploadLicenseHelper.isLicenseFree();
    this.config.canActivateTrial = this.buildingObjectsUploadLicenseHelper.isLicenseCanActivateTrial();
    this.config.remainingDays = this.buildingObjectsUploadLicenseHelper.getLicenseRemainingDays();
    this.config.trialDuration = this.buildingObjectsUploadLicenseHelper.getTrialDuration();
    this.config.isAutomaticLaunch = false;
    super.init(config);
  }

  protected get moduleName(): string {
    return this.translateService.translate('LICENSE_INFO.BUILDING_PROGRESS');
  }

  protected resolveInfoText(): void {
    switch (true) {
      case this.config.isLicenseFree && this.config.canActivateTrial:
        this.infoText = `${formatString(
          this.translateService.translate('LICENSE_INFO.MKT_MODAL_TEXT_FREE_CAN_ACTIVATE_NOT_AUTOMATIC_TEXT'),
          this.urlHelper.krosCompanyPrefix,
          this.moduleName,
          this.config.trialDuration + '',
          this.pluralisationByCountPipe.transform(this.config.trialDuration, 'LICENSE_INFO.DAY')
        )} ${this.getMoreInfoLink()}`;
        break;
      case this.config.isLicenseFree && !this.config.canActivateTrial:
        this.infoText = formatString(
          this.translateService.translate('LICENSE_INFO.MKT_MODAL_TEXT_FREE_CANNOT_ACTIVATE_TEXT'),
          this.urlHelper.krosCompanyPrefix,
          this.moduleName,
          this.urlHelper.contactUs
        );
        break;
      case this.config.isLicenseReadonly:
        this.infoText = formatString(
          this.translateService.translate('LICENSE_INFO.MKT_MODAL_TEXT_READONLY_TEXT'),
          this.urlHelper.krosCompanyPrefix,
          this.moduleName,
          this.urlHelper.contactUs
        );
        break;
      case this.config.isLicenseFull && this.config.isLicenseToExpire:
        this.infoText = formatString(
          this.translateService.translate('LICENSE_INFO.MKT_MODAL_TEXT_FULL_EXPIRING_TEXT'),
          this.urlHelper.krosCompanyPrefix,
          this.moduleName,
          this.config.remainingDays + '',
          this.pluralisationByCountPipe.transform(this.config.remainingDays, 'LICENSE_INFO.DAY'),
          this.urlHelper.contactUs
        );
        break;
    }
  }

  protected resolveFooterText(): void {
    if (this.config.isLicenseReadonly || (this.config.isLicenseFull && this.config.isLicenseToExpire)) {
      this.footerText = formatString(
        this.translateService.translate('LICENSE_INFO.DOCUMENTS_AVAILABLE_INFO'),
        this.translateService.translate(this.urlHelper.isCzVersion
          ? 'LICENSE_INFO.DOCUMENTATION_CZ'
          : 'LICENSE_INFO.DOCUMENTATION_SK'),
      );
    }
  }
}
