import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter } from 'rxjs/operators';

import {
  AppInsightsUserContextHelper,
  LicenseDispatchersService,
  LicenseInfo
} from '@kros-sk/ssw-shared-legacy';
import {
  License,
  LicenseSelectorsService,
  LicenseType
} from '@kros-sk/ssw-cdk';

import { BuildingProgressActionAccessService } from '../services/building-progress-action-access.service';

@Injectable()
export class LicenseHelper {

  private licenseDispatchersService = inject(LicenseDispatchersService);
  private appInsightsUserContext = inject(AppInsightsUserContextHelper);
  private buildingProgressActionAccessService = inject(BuildingProgressActionAccessService);

  get isReadonlyLicense(): boolean {
    return this.license?.licenseType === LicenseType.Readonly;
  }

  get isFullLicense(): boolean {
    return this.license?.licenseType === LicenseType.Full;
  }

  get isTrialLicense(): boolean {
    return this.license?.licenseType === LicenseType.Trial;
  }

  get isFreeLicense(): boolean {
    return this.license?.licenseType === LicenseType.Free;
  }

  get isTrialOrFullLicense(): boolean {
    return this.isTrialLicense || this.isFullLicense;
  }

  get isFreeOrReadonlyLicense(): boolean {
    return this.isFreeLicense || this.isReadonlyLicense;
  }

  get isSchoolLicense(): boolean {
    return this.license?.isSchool;
  }

  private license: License;
  private isBudgetApproval: boolean;

  constructor(
    licenseCdkSelectorsService: LicenseSelectorsService,
    router: Router
  ) {
    router.events.pipe(filter(event => event instanceof NavigationEnd), takeUntilDestroyed())
      .subscribe((event: NavigationEnd) => this.isBudgetApproval = event.url.includes('/budget-approval'));

    licenseCdkSelectorsService.buildingProgressLicense$
      .pipe(filter(license => !!license), takeUntilDestroyed())
      .subscribe(license => {
        this.license = license;
        this.appInsightsUserContext.initUserContext(license);
        this.licenseDispatchersService.setBuildingProgressLicenseInfo(this.getLicenseInfoForBuildingProgress(license));
      });
  }

  private getLicenseInfoForBuildingProgress(license: License): LicenseInfo {
    return {
      license,
      trialTextKey: 'BUILDING_PROGRESS.LICENSE_INFO_TRIAL_TEXT',
      trialTextKeyPhone: 'BUILDING_PROGRESS.LICENSE_INFO_TRIAL_TEXT_PHONE',
      freeTextKey: 'BUILDING_PROGRESS.LICENSE_INFO_FREE_TEXT',
      freeTextKeyPhone: 'BUILDING_PROGRESS.LICENSE_INFO_FREE_TEXT_PHONE',
      freeCanActivateTrialTextKey: 'BUILDING_PROGRESS.LICENSE_INFO_FREE_CAN_ACTIVATE_TRIAL_TEXT',
      freeCanActivateTrialTextKeyPhone: 'BUILDING_PROGRESS.LICENSE_INFO_FREE_CAN_ACTIVATE_TRIAL_TEXT_PHONE',
      readonlyTextKey: 'BUILDING_PROGRESS.LICENSE_INFO_READONLY_TEXT',
      readonlyTextKeyPhone: 'BUILDING_PROGRESS.LICENSE_INFO_READONLY_TEXT_PHONE',
      licenseEndingTextKey: 'BUILDING_PROGRESS.LICENSE_INFO_ENDING_TEXT',
      licenseEndingTextKeyPhone: 'BUILDING_PROGRESS.LICENSE_INFO_ENDING_TEXT_PHONE',
      activateTrialLicense: (): void => this.activateTrialLicenseWithQuestion()
    };
  }

  destroy(): void {
    this.licenseDispatchersService.clearBuildingProgressLicenseInfo();
    this.appInsightsUserContext.resetUserContext(this.license);
  }

  private activateTrialLicenseWithQuestion(): void {
    this.buildingProgressActionAccessService.activateTrialLicenseWithQuestion(this.isBudgetApproval);
  }
}
