<div
  ngbDropdown
  container="body"
  #dropdown="ngbDropdown"
  autoClose="outside"
>
  <button
    class="btn btn-outline-primary btn-trigger"
    ngbDropdownToggle
    [disabled]="disabled"
    data-test="change-owner-dropdown"
  >
    {{ 'SHARING.CHANGE_OWNER' | translate }}
    <i class="material-icons">expand_more</i>
  </button>

  <ng-container *ngIf="(emails?.length > 0) else emptyMenu">
    <div ngbDropdownMenu>
      <button
        ngbDropdownItem
        *ngFor="let email of emails"
        (mouseup)="onOwnerChanged(email); dropdown.close()"
      >
        <div class="name-info">
          <kros-titan-avatar
            class="avatar-s"
            [email]="email"
          ></kros-titan-avatar>
          <span
            class="email"
            data-test="owner-candidate-email"
          >
            {{ email }}
          </span>
        </div>
      </button>
    </div>
  </ng-container>

  <ng-template #emptyMenu>
    <div ngbDropdownMenu>
      <p
        ngbDropdownItem
        class="empty-item"
      >
        {{ 'SHARING.SHARING_LIST_IS_EMPTY' | translate }}
      </p>
    </div>
  </ng-template>
</div>
