import { createAction, props } from '@ngrx/store';

export const undo = createAction('[Undo redo] Undo', props<{ payload: any }>());
export const undoInProgress = createAction('[Undo redo] Undo is in progress');
export const undoSuccess = createAction('[Undo redo] Undo Success');

export const redo = createAction('[Undo redo] Redo', props<{ payload: any }>());
export const redoInProgress = createAction('[Undo redo] Redo is in progress');
export const redoSuccess = createAction('[Undo redo] Redo Success');

export const clearUndoRedoStack = createAction('[Undo redo] Clear undo/redo stacks');
