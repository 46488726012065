import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { SearchModel, SetColorCodeModel } from '@kros-sk/ssw-shared-legacy';

import * as buildingProgressActions from './building-progress.actions';
import * as buildingProgressBoqActions from './building-progress-boq.actions';
import { BoqEditViewModel } from '../../building-progress/models/boq-edit.view-model';
import { BoqPeriodItem } from '../../building-progress/models/boq-period-item.model';
import { BuildingProgressEditModel } from '../../building-progress/models/building-progress-edit.model';
import { BuildingProgressModel, BuildingProgressPeriodItem, UnmarkedBoqItem } from '../../building-progress/models/construction-data.model';
import { BuildingProgressPercentageCompleteModel } from '../../building-progress/models/building-progress-percentage-complete.model';
import { BuildingProgressPeriod } from '../../building-progress/models/building-progress-period.model';
import { BuildingProgressSetModel } from '../../building-progress/models/building-progress-set.model';
import { BuildingProgressState } from './building-progress.state';
import { BuildingProgrressEditPercentageModel } from '../../building-progress/models/building-progress-edit-percentage.model';
import { DeleteItemModel } from '../../building-progress/models/item-list-delete.model';
import { getUnmarkedBoqItemIdsFromItems } from './building-progress-reducer.helper';
import { NoteSetModel } from '../../building-progress/models/note.model';
import { SelectedView } from '../../building-progress/models/selected-view.model';
import { TotalPriceSetModel } from '../../building-progress/models/total-price-set.model';

@Injectable()
export class BuildingProgressDispatchersService {

  constructor(
    private store: Store<BuildingProgressState>
  ) { }

  loadConstructionData(projectId: number, searchModel: SearchModel, hierarchyCode?: string, contractorId?: number): void {
    this.store.dispatch(
      buildingProgressActions.loadConstructionDataStart({ projectId, searchModel, hierarchyCode, contractorId })
    );
  }

  loadSelectedConstructionData(projectId: number, searchModel: SearchModel, contractorId?: number): void {
    this.store.dispatch(
      buildingProgressActions.loadSelectedConstructionDataStart({ projectId, searchModel, contractorId })
    );
  }

  editPercentage(
    editModel: BuildingProgrressEditPercentageModel,
    constructionData: BuildingProgressModel,
    showSuccessToast?: boolean
  ): void {
    this.store.dispatch(
      buildingProgressActions.editPercentageStart({ editModel, constructionData, isUndoRedoOperation: false, showSuccessToast })
    );
  }

  editPercentageRedo(editModel: BuildingProgrressEditPercentageModel, oldValues: BuildingProgressSetModel): void {
    this.store.dispatch(
      buildingProgressActions.editPercentageStart({ editModel, oldValues, isUndoRedoOperation: true })
    );
  }

  editConstructionDataRedo(editModel: BuildingProgressEditModel): void {
    this.store.dispatch(
      buildingProgressActions.editConstructionDataRedo({ editModel, isUndoRedoOperation: true })
    );
  }

  editConstructionData(editModel: BuildingProgressEditModel, isUndoRedoOperation = false, showSuccessToast?: boolean): void {
    this.store.dispatch(
      buildingProgressActions.editConstructionDataStart({ editModel, isUndoRedoOperation, showSuccessToast })
    );
  }

  setConstructionData(editModel: BuildingProgressSetModel): void {
    this.store.dispatch(
      buildingProgressActions.setConstructionDataStart({ editModel })
    );
  }

  progressEditConstructionData(
    editModel: BuildingProgressEditModel,
    unmarkedBoqItems: UnmarkedBoqItem[],
    isUndoRedoOperation: boolean
  ): void {
    this.store.dispatch(buildingProgressActions.editConstructionDataProgress(
      {
        editModel,
        unmarkedBoqItemIds: getUnmarkedBoqItemIdsFromItems(unmarkedBoqItems
          .filter(p => editModel.editedPeriodItems
            .some(e => e.budgetItemId === p.budgetItemId))),
        isUndoRedoOperation
      }));
  }

  loadPermission(projectId: number): void {
    this.store.dispatch(buildingProgressActions.loadPermissionStart({ projectId }));
  }

  loadPeriods(projectId: number, keepSelectedPeriods: boolean, contractorId?: number): void {
    this.store.dispatch(
      buildingProgressActions.loadPeriodsStart({ projectId, keepSelectedPeriods, contractorId })
    );
  }

  loadWholeBuildingPeriods(projectId: number): void {
    this.store.dispatch(
      buildingProgressActions.loadPeriodsStart(
        { projectId, keepSelectedPeriods: false, contractorId: undefined, wholeBuilding: true }
      )
    );
  }

  loadSharingList(projectId: number): void {
    this.store.dispatch(
      buildingProgressActions.loadSharingListStart({ projectId })
    );
  }

  setAutocalculatedAmount(
    projectId: number,
    periodId: number,
    budgetItemId: number,
    periodItem: BuildingProgressPeriodItem): void {
    this.store.dispatch(
      buildingProgressActions.setAutocalculatedAmountStart(
        {
          autocalculatedAmount: { projectId, periodId, budgetItemId },
          periodItem,
          isUndoRedoOperation: false
        })
    );
  }

  setAutocalculatedAmountRedo(
    projectId: number,
    periodId: number,
    budgetItemId: number,
    oldValues: BuildingProgressSetModel): void {
    this.store.dispatch(
      buildingProgressActions.setAutocalculatedAmountStart(
        {
          autocalculatedAmount: { projectId, periodId, budgetItemId },
          oldValues,
          isUndoRedoOperation: true
        })
    );
  }

  completePercentage(
    percentageCompleteModel: BuildingProgressPercentageCompleteModel,
    constructionData: BuildingProgressModel,
    showSuccessToast?: boolean): void {
    this.store.dispatch(
      buildingProgressActions.completePercentageStart({
        percentageCompleteModel, constructionData, isUndoRedoOperation: false, showSuccessToast
      })
    );
  }

  completePercentageRedo(percentageCompleteModel: BuildingProgressPercentageCompleteModel, oldValues: BuildingProgressSetModel): void {
    this.store.dispatch(
      buildingProgressActions.completePercentageStart({ percentageCompleteModel, oldValues, isUndoRedoOperation: true })
    );
  }

  markPeriodInFilter(id: number, isMarked: boolean): void {
    this.store.dispatch(
      buildingProgressActions.markPeriodInFilter({ id, isMarked })
    );
  }

  clearAllMarkedPeriodsInFilter(periods: BuildingProgressPeriod[]): void {
    this.store.dispatch(
      buildingProgressActions.clearAllMarkedPeriodsInFilter({ periods })
    );
  }

  clearMultiSelectItems(): void {
    this.store.dispatch(
      buildingProgressActions.clearMultiSelectItems()
    );
  }

  multiSelectAllitems(): void {
    this.store.dispatch(
      buildingProgressActions.multiSelectAllItems()
    );
  }

  multiSelectItem(id: number, state: boolean): void {
    this.store.dispatch(
      buildingProgressActions.multiSelectItems({ id, state })
    );
  }

  loadBoqPeriodItems(projectId: number, budgetItemId: number, contractorId?: number): void {
    this.store.dispatch(
      buildingProgressBoqActions.loadBoqPeriodItemsStart({ projectId, budgetItemId, contractorId })
    );
  }

  loadBoqItems(projectId: number, budgetItemId: number, contractorId?: number): void {
    this.store.dispatch(
      buildingProgressBoqActions.loadBoqItemsStart({ projectId, budgetItemId, contractorId })
    );
  }

  setBoqItemUndo(oldValue: BoqEditViewModel): void {
    this.store.dispatch(
      buildingProgressBoqActions.setBoqStart({ editModel: oldValue, isUndoRedoOperation: true, isUndo: true })
    );
  }

  setBoqItemRedo(editModel: BoqEditViewModel): void {
    this.store.dispatch(
      buildingProgressBoqActions.setBoqStart({ editModel, isUndoRedoOperation: true })
    );
  }

  setBoq(editModel: BoqEditViewModel, originalItems: BoqPeriodItem[], oldPeriodItem: BuildingProgressPeriodItem): void {
    this.store.dispatch(
      buildingProgressBoqActions.setBoqStart({ editModel, isUndoRedoOperation: false, originalItems, oldPeriodItem })
    );
  }

  editBoqItems(boqItems: BoqPeriodItem[]): void {
    this.store.dispatch(
      buildingProgressBoqActions.editBoqItems({ boqItems })
    );
  }

  setBoqIds(boqItems: BoqPeriodItem[]): void {
    this.store.dispatch(
      buildingProgressBoqActions.setBoqIds({ boqItems })
    );
  }

  resetBoqPeriodId(periodId: number): void {
    this.store.dispatch(
      buildingProgressBoqActions.resetBoqPeriodId({ periodId })
    );
  }

  markBoq(boqItem: BoqPeriodItem, periodId: number): void {
    this.store.dispatch(
      buildingProgressBoqActions.markBoqItem({ boqItem, periodId })
    );
  }

  deleteBoq(boqItemId: number, editedBoqItems: BoqPeriodItem[]): void {
    this.store.dispatch(
      buildingProgressBoqActions.deleteBoqItem({ boqItemId, editedBoqItems })
    );
  }

  createBoq(budgetItemId: number, position: number, isInsertDown: boolean): void {
    this.store.dispatch(
      buildingProgressBoqActions.createBoqItem({ budgetItemId, position, isInsertDown })
    );
  }

  cancelBoqEditation(): void {
    this.store.dispatch(
      buildingProgressBoqActions.cancelBoqEditation()
    );
  }

  totalPriceSet(totalPriceSetModel: TotalPriceSetModel, constructionData: BuildingProgressModel): void {
    this.store.dispatch(
      buildingProgressActions.totalPriceSetStart({ priceSetModel: totalPriceSetModel, constructionData, isUndoRedoOperation: false })
    );
  }

  totalPriceSetRedo(totalPriceSetModel: TotalPriceSetModel, oldValues: BuildingProgressSetModel): void {
    this.store.dispatch(
      buildingProgressActions.totalPriceSetStart({ priceSetModel: totalPriceSetModel, oldValues, isUndoRedoOperation: true })
    );
  }

  setColorCode(setColorCodeModel: SetColorCodeModel, constructionData: BuildingProgressModel): void {
    this.store.dispatch(
      buildingProgressActions.setColorCodesStart({ setColorCodeModel, constructionData, isUndoRedoOperation: false })
    );
  }

  setColorCodeUndo(setColorCodeModel: SetColorCodeModel, constructionData: BuildingProgressModel): void {
    this.store.dispatch(
      buildingProgressActions.setColorCodesStart({ setColorCodeModel, constructionData, isUndoRedoOperation: true, isUndo: true })
    );
  }

  setColorCodeRedo(setColorCodeModel: SetColorCodeModel, oldValues: SetColorCodeModel): void {
    this.store.dispatch(
      buildingProgressActions.setColorCodesStart({ setColorCodeModel, oldValues, isUndoRedoOperation: true })
    );
  }

  deleteItem(projectId: number, deleteItemsModel: DeleteItemModel): void {
    this.store.dispatch(
      buildingProgressActions.deleteItemsStart({ projectId, deleteItemsModel })
    );
  }

  loadSettings(projectId: number): void {
    this.store.dispatch(
      buildingProgressActions.loadSettingsStart({ projectId })
    );
  }

  loadLicenseAgreement(): void {
    this.store.dispatch(
      buildingProgressActions.loadLicenseAgreementStart()
    );
  }

  confirmLicenseAgreement(): void {
    this.store.dispatch(
      buildingProgressActions.confirmLicenseAgreementStart()
    );
  }

  changeSelectedView(selectedView: SelectedView): void {
    this.store.dispatch(
      buildingProgressActions.changeSelectedView({ selectedView })
    );
  }

  originalItemsAreReseted(): void {
    this.store.dispatch(
      buildingProgressBoqActions.originalItemsAreReseted()
    );
  }

  setItemNotCompleted(projectId: number, budgetItemIds: number[], notCompleted: boolean): void {
    this.store.dispatch(buildingProgressActions.setItemsNotCompletedStart({ projectId, budgetItemIds, notCompleted }));
  }

  loadBudgetHasItems(projectId: number): void {
    this.store.dispatch(buildingProgressActions.loadBudgetHasItemsStart({ projectId }));
  }

  startBudgetCreation(projectId: number): void {
    this.store.dispatch(buildingProgressActions.startBudgetCreationStart({ projectId }));
  }

  budgetCreationError(): void {
    this.store.dispatch(buildingProgressActions.startBudgetCreationError({}));
  }

  saveNote(projectId: number, note: NoteSetModel): void {
    this.store.dispatch(buildingProgressActions.saveBuildingProgressNoteStart({ projectId, note }));
  }

  updateVatRate(projectId: number): void {
    this.store.dispatch(buildingProgressActions.updateVatRateStart({ projectId }));
  }
}
