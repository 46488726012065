import { ActionCreator, on, ReducerTypes } from '@ngrx/store';

import * as buildingProgressActions from './building-progress.actions';
import * as reducerHelper from './building-progress-reducer.helper';
import { adapterPeriods } from './building-progress.adapters';
import { BuildingProgressState } from './building-progress.state';

export const editationReducers: ReducerTypes<BuildingProgressState, ActionCreator[]>[] = [
  on(buildingProgressActions.editConstructionDataStart, (state): BuildingProgressState => {
    return {
      ...state,
      tableIsBusy: true
    };
  }),
  on(buildingProgressActions.editConstructionDataRedo, (state): BuildingProgressState => {
    return {
      ...state,
      tableIsBusy: true
    };
  }),
  on(buildingProgressActions.editConstructionDataProgress, (state, payload): BuildingProgressState => {
    return {
      ...state,
      cellToFocusAndAnimate: payload.isUndoRedoOperation ? reducerHelper.getAffectedCellsAfterEdit(payload.editModel) : []
    };
  }),
  on(buildingProgressActions.editConstructionDataSuccess, (state, payload): BuildingProgressState => {
    return {
      ...state,
      lastEditedItemIds: payload.editModel.editedPeriodItems.map(p =>
        `${p.budgetItemId}-${reducerHelper.getColumnIdByChangedProperty(p.changedProperty)}${payload.editModel.periodId}`),
      constructionData: reducerHelper.loadNewConstructionData(state.constructionData, payload.editedItems),
      cellToFocusAndAnimate: payload.isUndoRedoOperation ? reducerHelper.getAffectedCellsAfterEdit(payload.editModel) : [],
      tableIsBusy: false
    };
  }),
  on(buildingProgressActions.setConstructionDataSuccess, (state, payload): BuildingProgressState => {
    return {
      ...state,
      constructionData: reducerHelper.loadNewConstructionData(state.constructionData, payload.editedItems),
      cellToFocusAndAnimate: reducerHelper.getAffectedCellsAfterSet(payload.editModel)
    };
  }),
  on(buildingProgressActions.completePercentageStart, (state): BuildingProgressState => {
    return {
      ...state,
      tableIsBusy: true
    };
  }),
  on(buildingProgressActions.completePercentageSuccess, (state, payload): BuildingProgressState => {
    return {
      ...state,
      constructionData: reducerHelper.loadNewConstructionData(state.constructionData, payload.editedItems),
      cellToFocusAndAnimate: payload.isUndoRedoOperation ?
        reducerHelper.getAffectedCellsAfterPercentageSet(payload.editedItems, payload.oldValues.periodId) : [],
      tableIsBusy: false
    };
  }),
  on(buildingProgressActions.editPercentageStart, (state): BuildingProgressState => {
    return {
      ...state,
      tableIsBusy: true
    };
  }),
  on(buildingProgressActions.editPercentageSuccess, (state, payload): BuildingProgressState => {
    return {
      ...state,
      constructionData: reducerHelper.loadNewConstructionData(state.constructionData, payload.editedItems),
      cellToFocusAndAnimate: payload.isUndoRedoOperation ?
        reducerHelper.getAffectedCellsAfterPercentageSet(payload.editedItems, payload.editModel.periodId) : [],
      tableIsBusy: false
    };
  }),
  on(buildingProgressActions.setAutocalculatedAmountStart, (state): BuildingProgressState => {
    return {
      ...state,
      tableIsBusy: true
    };
  }),
  on(buildingProgressActions.setAutocalculatedAmountSuccess, (state, payload): BuildingProgressState => {
    return {
      ...state,
      constructionData: reducerHelper.loadNewConstructionData(state.constructionData, payload.editedItems),
      cellToFocusAndAnimate: payload.isUndoRedoOperation ?
        [reducerHelper.getAffectedCellAfterAmountChanged(payload.autocalculatedAmount)] : [],
      tableIsBusy: false
    };
  }),
  on(buildingProgressActions.totalPriceSetStart, (state): BuildingProgressState => {
    return {
      ...state,
      tableIsBusy: true
    };
  }),
  on(buildingProgressActions.totalPriceSetSuccess, (state, payload): BuildingProgressState => {
    return {
      ...state,
      constructionData: reducerHelper.loadNewConstructionData(state.constructionData, payload.editedItems),
      cellToFocusAndAnimate: payload.isUndoRedoOperation ?
        reducerHelper.getAffectedCellsAfterPercentageSet(payload.editedItems, payload.oldValues.periodId) : [],
      tableIsBusy: false
    };
  }),
  on(buildingProgressActions.setColorCodesStart, (state): BuildingProgressState => {
    return {
      ...state,
      tableIsBusy: true
    };
  }),
  on(buildingProgressActions.setColorCodesSuccess, (state, payload): BuildingProgressState => {
    return {
      ...state,
      constructionData: reducerHelper.loadNewConstructionDataColors(state.constructionData, payload.setColorCodeResult),
      cellToFocusAndAnimate: payload.isUndoRedoOperation ?
        reducerHelper.getAffectedCellAfterColorChanged(payload.setColorCodeResult) : [],
      tableIsBusy: false
    };
  }),
  on(buildingProgressActions.deleteItemsError, (state): BuildingProgressState => {
    return { ...state, deletingItems: false };
  }),
  on(buildingProgressActions.deleteItemsStart, (state): BuildingProgressState => {
    return { ...state, deletingItems: true };
  }),
  on(buildingProgressActions.deleteItemsStart, (state): BuildingProgressState => {
    return {
      ...state,
      tableIsBusy: true
    };
  }),
  on(buildingProgressActions.deleteItemsSuccess, (state, payload): BuildingProgressState => {
    const newData = reducerHelper.loadNewConstructionDataDelete(
      state.constructionData,
      payload.buildingProgressDeleteItem.recalculatedItems,
      payload.buildingProgressDeleteItem.deletedBudgetItemIds);

    return {
      ...state,
      deletingItems: false,
      constructionData: newData,
      periods: newData.periods.length === 0 ? adapterPeriods.removeAll(state.periods) : state.periods,
      tableIsBusy: false
    };
  }),
];
