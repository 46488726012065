import { Component, OnInit } from '@angular/core';

import { Subject } from 'rxjs';

import { BuildingProgressPermission, PermissionType } from '@kros-sk/ssw-shared/permission';
import { KrosModalRef } from '@kros-sk/components';
import { SearchModel } from '@kros-sk/ssw-shared-legacy';

import { BuildingProgressPeriod } from '../../../../building-progress/models/building-progress-period.model';
import { BuildingProgressTourService } from '../../../services/building-progress-tour.service';
import { hasContractorRight } from '../../../../building-progress/shared/building-progress-utils';
import { PeriodsHelperService } from '../services/periods-helper.service';

@Component({
  selector: 'app-periods-dialog',
  templateUrl: './periods-dialog.component.html',
  styleUrls: ['./periods-dialog.component.scss'],
  providers: [
    BuildingProgressTourService,
    PeriodsHelperService
  ]
})
export class PeriodsDialogComponent implements OnInit {
  projectId: number;
  searchModel: SearchModel;
  permission: BuildingProgressPermission;
  readonly: boolean;
  editMode = false;
  newPeriodStart = new Subject<void>();

  get canAddPeriod(): boolean {
    return !this.readonly && !this.editMode &&
      (this.permission.permissionType === PermissionType.Contributor ||
        this.permission.permissionType === PermissionType.Owner ||
        hasContractorRight(this.permission.buildingProgressPermissionType));
  }

  constructor(
    private modalRef: KrosModalRef
  ) { }

  ngOnInit(): void {
    this.projectId = this.modalRef.data.projectId;
    this.searchModel = this.modalRef.data.searchModel;
    this.permission = this.modalRef.data.permission;
    this.readonly = this.modalRef.data.readonly;
  }

  onEditModeChanged(editMode: boolean): void {
    this.editMode = editMode;
  }

  onPeriodViewClicked(viewPeriod: BuildingProgressPeriod): void {
    this.modalRef.submit({ viewPeriod });
  }

  onNewPeriod(): void {
    this.newPeriodStart.next();
  }
}
