import { inject, Injectable } from '@angular/core';

import { LicenseUrlService } from '../license-url.service';
import { NonPaidPackageConfig } from '../non-paid-package-config.model';
import { PluralisationByCountPipe } from '../../pipes';
import { TranslateService } from '../../translate';

@Injectable()
export abstract class MarketingContentHelper {

  picture = '';
  pictureLabel = '';
  orderText = '';
  heading = '';
  infoText = '';
  footerText = '';
  orderButtonText = '';
  showTrialButton = false;
  showOrderButton = false;

  protected config: NonPaidPackageConfig;
  protected translateService = inject(TranslateService);
  protected pluralisationByCountPipe = inject(PluralisationByCountPipe);
  protected urlHelper = inject(LicenseUrlService, { optional: true });
  protected abstract get moduleName(): string;

  init(config: NonPaidPackageConfig): void {
    this.config = config;
    this.resolvePicture();
    this.resolvePictureLabel();
    this.resolveHeading();
    this.resolveInfoText();
    this.resolveTrialButton();
    this.resolveOrderButton();
    this.resolveFooterText();
    this.resolveOrderButtonText();
  }

  protected abstract resolveInfoText(): void;

  protected abstract resolveFooterText(): void;

  protected resolvePictureLabel(): void { }

  protected resolvePicture(): void {
    switch (true) {
      case this.config.isLicenseFree && this.config.canActivateTrial && this.config.isAutomaticLaunch:
        this.picture = 'calendar';
        break;
      case this.config.isLicenseFree && this.config.canActivateTrial && !this.config.isAutomaticLaunch:
      case this.config.isLicenseFree && !this.config.canActivateTrial && !this.config.isAutomaticLaunch:
      case this.config.isLicenseTrial && !this.config.isAutomaticLaunch:
        this.picture = 'lock';
        break;
      case this.config.isLicenseFull && this.config.isLicenseToExpire:
        this.picture = 'time';
        break;
      case this.config.isLicenseFree && !this.config.canActivateTrial && this.config.isAutomaticLaunch:
      case this.config.isLicenseReadonly:
        this.picture = 'time-up';
        break;
    }
  }

  protected resolveTrialButton(): void {
    this.showTrialButton = this.config.isLicenseFree && this.config.canActivateTrial;
  }

  protected resolveOrderButton(): void {
    if ((this.config.isLicenseFree && !this.config.canActivateTrial) ||
      this.config.isLicenseReadonly ||
      this.config.isLicenseFull ||
      this.config.isLicenseTrial) {
      this.showOrderButton = true;
    } else {
      this.showOrderButton = false;
    }
  }

  protected resolveHeading(): void {
    this.heading = `${this.urlHelper?.krosCompanyPrefix}${this.moduleName}`;
  }

  protected getMoreInfoLink(): string {
    return `<a href="${this.urlHelper?.moreInfoUrl}" target="new">${this.translateService.translate('LICENSE_INFO.MORE_INFO')}</a>`;
  }

  protected resolveOrderButtonText(): void {
    switch (true) {
      case this.config.isLicenseReadonly:
        this.orderButtonText = this.translateService.translate('LICENSE_INFO.RENEW');
        break;
      case this.config.isLicenseFull && this.config.remainingDays <= 30:
        this.orderButtonText = this.translateService.translate('LICENSE_INFO.PROLONG');
        break;
      case this.config.isLicenseFull:
        this.orderButtonText = this.translateService.translate('LICENSE_INFO.EXTEND');
        break;
      default:
        this.orderButtonText = this.translateService.translate('LICENSE_INFO.ORDER');
    }
  }
}
