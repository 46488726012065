<div class="panel-wrapper">
  <div class="panel-header">
    <h4 class="panel-header-caption">
      {{ 'SHARING.SELECTION' | translate }}
    </h4>
    <div class="panel-header-actions">
      <button
        ngbAutoFocus
        class="btn btn-link btn-close"
        type="button"
        (click)="onCloseClick()"
      >
        <span krosMaterialIcon>close</span>
      </button>
    </div>
  </div>

  <div class="divider-horizontal"></div>

  <div class="panel-content">
    <app-project-members-panel-container
      class="panel-container"
      (projectMembersSelected)="onProjectMembersSelected()"
    >
    </app-project-members-panel-container>
  </div>
</div>
