import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Subject } from 'rxjs';

import { BuildingProgressPermission, PermissionType } from '@kros-sk/ssw-shared/permission';
import { KrosModalService } from '@kros-sk/components';
import { SearchModel } from '@kros-sk/ssw-shared-legacy';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { BuildingProgressSelectorsService } from '../../../../store/building-progress';
import { hasContractorRight } from '../../../../building-progress/shared/building-progress-utils';
import {
  PeriodsHistoryDialogComponent
} from '../../../../building-progress/modules/periods/periods-history-dialog/periods-history-dialog.component';

@Component({
  selector: 'app-periods-panel',
  templateUrl: './periods-panel.component.html',
  styleUrls: ['./periods-panel.component.scss']
})
export class PeriodsPanelComponent extends UnsubscribeComponent implements OnInit {
  @Input() projectId: number;
  @Input() searchModel: SearchModel;
  @Input() permission: BuildingProgressPermission;
  @Input() readonly: boolean;
  @Input() canAdd = true;
  @Input() canOwnerContributorApprove = false;
  @Input() contractorId: number;

  @Output() close = new EventEmitter();
  @Output() periodDeleted = new EventEmitter();

  editMode = false;
  newPeriodStart = new Subject<void>();

  private hasConstructionData: boolean;

  get canAddPeriod(): boolean {
    return !this.readonly && !this.editMode && this.canAdd && this.hasConstructionData && this.permission &&
      (this.permission.permissionType === PermissionType.Contributor ||
        this.permission.permissionType === PermissionType.Owner ||
        hasContractorRight(this.permission.buildingProgressPermissionType));
  }

  constructor(
    private buildingProgressSelectorsService: BuildingProgressSelectorsService,
    private krosModalService: KrosModalService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.buildingProgressSelectorsService.constructionData$
      .subscribe(p => this.hasConstructionData = !!p && !!p.items);
  }

  onCloseClick(): void {
    this.close.emit();
  }

  onNewPeriod(): void {
    this.newPeriodStart.next();
  }

  onPeriodDeleted(): void {
    this.periodDeleted.emit();
  }

  onCloseEditMode(): void {
    this.editMode = false;
  }

  onEditModeChanged(editMode: boolean): void {
    this.editMode = editMode;
  }

  openPeriodHistory(): void {
    this.krosModalService.openCentered(
      PeriodsHistoryDialogComponent,
      {
        projectId: this.projectId,
        contractorId: this.contractorId
      },
      {}, NaN, NaN, undefined, 'modal-period-history'
    );
  }
}
