<div class="kros-modal-header">
  <h5 class="modal-title">
    {{ 'CHANGE_SHEET.RELATION.RELATION_TO_CONTRACT_BUDGET' | translate }}
  </h5>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    data-test="modal-close-button"
    (click)="onCloseClick()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div
  class="kros-modal-body"
  data-test="relation-modal-body"
>
  <app-panel-content
    [collapsed]="panelCollapsed"
    [headerText]="'CHANGE_SHEET.RELATION.CONTRACT_BUDGET' | translate"
    (panelCollapsed)="panelCollapsed = !panelCollapsed"
  >
    <ng-container ngProjectAs="panel">
      <app-budget-structure
        class="building-tree"
        [title]="'CHANGE_SHEET.RELATION.CONTRACT_BUDGET' | translate"
        [data$]="selectorService.budgetData$"
      ></app-budget-structure>
    </ng-container>

    <ng-container ngProjectAs="content">
      <div class="content-container">
        <div class="toolbar-actions tablet-visible">
          <div class="actions left">
            <button
              *ngIf="!isTabletStructureShown"
              class="btn toolbar-action no-selection gray-toolbar-action ga-show-relation-structure-items"
              type="button"
              krosTooltip="{{ 'BUDGET_CHANGES.STRUCTURE' | translate }}"
              data-test="relation-show-structure-items"
              (click)="isTabletStructureShown = true"
            >
              <i class="material-icons icon-button ga-show-relation-structure-items">list</i>
              <span class="toolbar-action-text">{{ 'BUDGET_APPROVAL.OPEN_BUDGET' | translate }}</span>
            </button>
          </div>
        </div>

        <app-budget-structure
          *ngIf="isTabletStructureShown"
          class="building-tree"
          [title]="'BUILDING_PROGRESS.BUILDING' | translate"
          [data$]="selectorService.budgetData$"
          (nodeSelected)="isTabletStructureShown = false"
        ></app-budget-structure>

        <ng-container *ngIf="!(selectorService.budgetDataLoading$ | async) else contentLoader">
          <kros-data-table
            *ngIf="hasData && !isTabletStructureShown"
            [itemHeight]="tableService.averageLineHeight"
            [data]="tableData"
            [itemCount]="itemCount"
            [config]="dataTableConfig"
            [focusedCell]="focusedCell"
            (cellFocused)="onCellFocused($event)"
            (itemDblClick)="onItemDblClick()"
          ></kros-data-table>
        </ng-container>
      </div>
    </ng-container>
  </app-panel-content>
</div>

<div class="kros-modal-footer">
  <div class="item-info">
    <div class="info-column">
      <label class="caption">{{'CHANGE_SHEET.RELATION.CHANGE_ITEM' | translate}}:</label>
    </div>

    <div class="info-column description">{{item.code}}&nbsp;&nbsp;-&nbsp;&nbsp;{{item.description}}</div>

    <div class="info-column">{{formattedItemAmount}}</div>

    <div class="info-column">{{item.measureUnit}}</div>
  </div>

  <div class="button-container">
    <button
      *ifBudgetApprovalFunctionsPermission
      class="btn btn-primary ga-ba-change-sheet-relation-set"
      type="button"
      data-test="create-relation-button"
      (click)="onSubmit()"
    >{{ 'CHANGE_SHEET.RELATION.CREATE_RELATION' | translate }}</button>
  </div>
</div>

<ng-template #contentLoader>
  <kros-timeline [type]="timelineType.Table"></kros-timeline>
</ng-template>
