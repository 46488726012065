<div class="msg-box-v2">
  <div class="kros-modal-header">
    <div class="modal-line"
         [ngClass]="modalLineClass"></div>
    <div class="modal-title">
      <span class="h4"> {{ settings?.caption }} </span>
    </div>
    <button *ngIf="showXButton" class="btn btn-link close" tabindex="-1" (click)="closeModal()">
      <i class="material-icons secondary">close</i>
    </button>
  </div>

  <div class="kros-modal-body line-break">
    <div *ngIf="isString(settings.message); else message" [innerHTML]="settings.message"></div>
  </div>

  <div class="kros-modal-footer">
    <ng-container *ngIf="isString(settings.acceptButton); else acceptBtn">
      <button #acceptButton
              *ngIf="settings.acceptButton"
              type="button"
              class="btn btn-submit"
              data-test="message-box-yes"
              [ngClass]="buttonType_v2"
              (click)="submitModal('accept')">
        {{ settings.acceptButton }}
      </button>
    </ng-container>
    <ng-container *ngIf="isString(settings.cancelButton); else cancelBtn">
      <button #cancelButton
              *ngIf="settings.cancelButton"
              type="button"
              class="btn btn-submit btn-outline-secondary"
              data-test="message-box-no"
              (click)="submitModal('cancel')">
        {{ settings.cancelButton }}
      </button>
    </ng-container>
  </div>
</div>

<ng-template #message>
  <ng-container *ngTemplateOutlet="$any(settings.message)"></ng-container>
</ng-template>

<ng-template #cancelBtn>
  <ng-container *ngTemplateOutlet="$any(settings.cancelButton); context: msgBoxContext"></ng-container>
</ng-template>

<ng-template #acceptBtn>
  <ng-container *ngTemplateOutlet="$any(settings.acceptButton); context: msgBoxContext"></ng-container>
</ng-template>
