import { Action, createReducer, on } from '@ngrx/store';

import { emptyLicenseState, errorLicenseState, LicensesState, UserFeatureFlags } from '@kros-sk/models';

import * as authActions from './auth.actions';
import { KrosUser } from '../services/auth/kros-user.model';

export interface AuthState {
  currentUser: KrosUser | null;
  features: UserFeatureFlags;
  licenses: LicensesState;
  featuresLoaded: boolean;
}

const initialAuthState: AuthState = {
  currentUser: null,
  features: new UserFeatureFlags(),
  licenses: emptyLicenseState(),
  featuresLoaded: false
};

const reducer = createReducer(
  initialAuthState,

  on(authActions.setUser,
    (state, payload): AuthState => ({ ...state, currentUser: payload.user })
  ),
  on(authActions.setEmptyUser,
    (state): AuthState => ({ ...state, currentUser: null, features: new UserFeatureFlags(), licenses: emptyLicenseState() })
  ),
  on(authActions.loadFeaturesSuccess,
    (state, payload): AuthState => ({ ...state, features: payload.features, featuresLoaded: true })
  ),
  on(authActions.loadFeaturesFail,
    (state): AuthState => ({ ...state, features: new UserFeatureFlags(), featuresLoaded: true })
  ),
  on(
    authActions.loadCurrentLicenseSuccess,
    authActions.assignLicenseSuccess,
    authActions.removeCompaniesFromLicenseSuccess,
    authActions.startLicenseTrialSuccess,
    (state, payload): AuthState => ({ ...state, licenses: payload.licenseState })
  ),
  on(authActions.loadCurrentLicenseFail,
    (state, payload): AuthState => ({ ...state, licenses: errorLicenseState() })
  ),
  on(authActions.resetCurrentLicense,
    (state, payload): AuthState => ({ ...state, licenses: emptyLicenseState() })
  )
);

export function authReducer(state: AuthState | undefined, action: Action): AuthState {
  return reducer(state, action);
}
