import { Pipe, PipeTransform } from '@angular/core';

import { removeDiacritics } from '../../../tools/string/string-tools';

@Pipe({
  name: 'filterOptionsStartingWith'
})
export class FilterOptionsStartingWithPipe implements PipeTransform {

  transform(options: unknown[], term: string, key?: string): unknown[] {
    if (!term) {
      return options;
    }

    if (options.some(o => typeof o === 'object' ? o['name'] === term : o === term)) {
      return options;
    }

    const termNorm = removeDiacritics(term?.toLowerCase());
    return options.filter(option => key ? this.filterObject(option, termNorm, key) : this.filterString(option, termNorm));
  }

  private filterString(option: unknown, term: string): boolean {
    return (option as string).toLowerCase().startsWith(term);
  }

  private filterObject(option: unknown, term: string, key: string): boolean {
    return (option[key] as string).toLowerCase().startsWith(term);
  }
}
