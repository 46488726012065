import { Component, Input } from '@angular/core';

import { IconComponentParams } from '../data-table-config.model';

@Component({
  selector: 'kros-column-icon',
  templateUrl: './column-icon.component.html',
  styleUrls: ['./column-icon.component.scss']
})
export class ColumnIconComponent {

  @Input() params: IconComponentParams;
  @Input() data: any;

  constructor() { }

  onAction($event: Event) {
    if (this.params.stopPropagation) {
      $event.stopPropagation();
    }
    this.params.action(this.data);
  }
}
