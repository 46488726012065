import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { CommentModel } from '../comment.iterface';

@Component({
  selector: 'kros-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentComponent {

  @Input() comment: CommentModel;
  @Input() loading: boolean;
  @Input() readOnly: boolean;
  @Input() showAnswerButton: boolean;
  @Input() newComment: boolean;
  @Input() answerComment: boolean;
  @Input() newAnswerComment: boolean;
  @Input() parentAnswering: boolean;
  @Input() answerShowAnswerButton: boolean;
  @Input() isLastAnswer: boolean;
  @Input() createAnswer: (c: CommentModel) => CommentModel;
  @Input()
  set focusedIds(ids: number[]) {
    this._focusedIds = ids;
    if (!this.isCommentFocused()) {
      this.editing = false;
      this.answering = false;
    }
  }
  get focusedIds(): number[] {
    return this._focusedIds;
  }

  _focusedIds: number[] = [];
  editing: boolean;
  originalComment: string;
  answering: boolean;
  answerCommentData: CommentModel;

  @Output() cancelComment = new EventEmitter<void>();
  @Output() saveComment = new EventEmitter<CommentModel>();
  @Output() saveAnswerComment = new EventEmitter<CommentModel>();
  @Output() deleteComment = new EventEmitter<CommentModel>();
  @Output() editingChanged = new EventEmitter<any>();
  @Output() clickComment = new EventEmitter<CommentModel>();
  @Output() clickAnswer = new EventEmitter<CommentModel>();

  get active(): boolean {
    return this.isCommentFocused() || this.editing || this.answering || this.newComment || this.newAnswerComment;
  }

  canAnswer(): boolean {
    return ((!!this.comment.datePublished && !this.comment.parentId) || this.answerShowAnswerButton) &&
      !this.answering && !this.readOnly && this.showAnswerButton;
  }

  hasAnswers(): boolean {
    return this.comment.answers?.length > 0;
  }

  isAnswerButtonVisible(): boolean {
    return this.canAnswer() && !this.hasAnswers();
  }

  onCommentTextClick(): void {
    if (!this.comment.datePublished && !this.readOnly && this.isCommentFocused()) {
      this.editComment();
    }
  }

  onCommentClick(comment: CommentModel): void {
    this.clickComment.emit(comment);
  }

  onDeleteClick(comment: CommentModel): void {
    this.deleteComment.emit(comment);
  }

  onCancelClick(): void {
    if (this.newComment || this.newAnswerComment) {
      this.cancelComment.emit();
    } else {
      this.editing = false;
      this.editingChanged.emit({ comment: this.comment, editing: this.editing });
      this.comment.comment = this.originalComment;
      this.originalComment = undefined;
    }
  }

  onSaveClick(comment: CommentModel): void {
    this.saveComment.emit(comment);
  }

  onAnswerClick(): void {
    if (this.comment.parentId) {
      this.clickAnswer.emit();
    } else {
      this.answerCommentData = this.createAnswer(this.comment);
      this.answering = true;
    }
  }

  onCancelAnswer(): void {
    this.answering = false;
    this.answerCommentData = undefined;
  }

  onSaveAnswer(comment: CommentModel): void {
    this.saveAnswerComment.emit(comment);
  }

  onCommentFocusOut(): void {
    this.editing = false;
    this.editingChanged.emit({ comment: this.comment, editing: this.editing });
  }

  editComment(): void {
    this.comment = { ...this.comment };
    this.editing = true;
    this.editingChanged.emit({ comment: this.comment, editing: this.editing });
    this.originalComment = this.comment.comment;
  }

  private isCommentFocused(): boolean {
    return this.focusedIds.some(focusedId => focusedId === this.comment.id || focusedId === this.comment.parentId);
  }
}
