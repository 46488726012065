import { Injectable } from '@angular/core';

import { take } from 'rxjs/operators';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { formatString } from '@kros-sk/ssw-cdk';
import { InfoModalComponent, QuestionModalComponent, TranslateService } from '@kros-sk/ssw-shared-legacy';
import { KrosModalService } from '@kros-sk/components';

import { ApprovalType } from '../enums';
import { BudgetApprovalDispatchersService } from '../../store/budget-approval';
import { BudgetApprovalItem } from '../models';
import { BuildingProgressActionAccessService } from '../../building-progress/services/building-progress-action-access.service';

@Injectable()
export class BudgetApprovalDeleteService {

  constructor(
    private dispatcher: BudgetApprovalDispatchersService,
    private krosModalService: KrosModalService,
    private translateService: TranslateService,
    private appInsightService: AppInsightsBaseService,
    private buildingProgressActionAccessService: BuildingProgressActionAccessService
  ) { }

  deleteBuildingObject(projectId: number, selectedItem: BudgetApprovalItem, childs: BudgetApprovalItem[]): void {
    this.deleteWithQuestionIfPossible(projectId, selectedItem, childs);
  }

  private deleteWithQuestionIfPossible(projectId: number, selectedItem: BudgetApprovalItem, childs: BudgetApprovalItem[]): void {
    if (selectedItem.approvalType === ApprovalType.None || selectedItem.approvalType === ApprovalType.Approved) {
      this.openInfoDialog(this.translateService.translate('BUDGET_APPROVAL.DELETE.ONLY_UNAPPROVED_CAN_BE_DELETED'));
    } else if (childs.length) {
      if (childs.some(p => p.approvalType === ApprovalType.Approved)) {
        this.openInfoDialog(this.translateService.translate('BUDGET_APPROVAL.DELETE.SOME_CHILD_ARE_APPROVED'));
      } else {
        this.deleteWithQuestion(this.translateService.translate('BUDGET_APPROVAL.DELETE.DELETE_WITH_CHILD_QUESTION'),
          projectId, selectedItem);
      }
    } else {
      this.deleteWithQuestion(this.translateService.translate('BUDGET_APPROVAL.DELETE.DELETE_QUESTION'), projectId, selectedItem);
    }
  }

  private deleteWithQuestion(questionText: string, projectId: number, selectedItem: BudgetApprovalItem): void {
    if (this.buildingProgressActionAccessService.checkForAccessToApproveAndDeleteChangeSheet()) {
      const modalRef = this.krosModalService.openCentered(
        QuestionModalComponent,
        {
          body: formatString(questionText, '<b>' + selectedItem.description + '</b>'),
          confirmButton: this.translateService.translate('COMMON.DELETE')
        },
        {
          closeOnBackdropClick: false,
          fullscreenOnMobile: false,
          showMobileArrowBack: false
        },
        NaN,
        NaN,
        undefined,
        'no-min-width'
      );

      modalRef.afterClosed$.pipe(take(1)).subscribe(result => {
        if (result.type === 'submit' && this.buildingProgressActionAccessService.checkForFreeOwner(true)) {
          this.dispatchDelete(projectId, selectedItem.id);
        }
      });
    }
  }

  private openInfoDialog(infoText: string): void {
    this.krosModalService.openCentered(
      InfoModalComponent,
      {
        body: infoText
      },
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: false,
        showMobileArrowBack: false
      },
      NaN,
      NaN,
      undefined,
      'no-min-width'
    );
  }

  private dispatchDelete(projectId: number, budgetObjectId: number): void {
    this.appInsightService.trackEvent('BA-building-object-delete', { budgetObjectId: '' + budgetObjectId });
    this.dispatcher.deleteBuildingObject(projectId, budgetObjectId);
  }
}
