import { BuildingObjectType, CalculationType, DecimalPlaces, IBudgetItem } from '@kros-sk/ssw-shared-legacy';

import { BoqPeriodItem } from './boq-period-item.model';
import { BuildingProgressPeriod } from './building-progress-period.model';
import { PartialBudgetItem } from '../../budget-approval/models';

export class BuildingProgressModel {
  projectId: number;
  currency: string;
  budgetItemId?: number;
  periods: BuildingProgressPeriod[];
  items: BuildingProgressItem[];
  decimalPlaces: DecimalPlaces;
}

export class PeriodItemsEditResult {
  periodId: number;
  tableItems: BuildingProgressItem[];
  unmarkedBoqItems: UnmarkedBoqItem[];
  createdPeriodBoqItems: BoqPeriodItem[];
  resetCalculationType: boolean;
}

export class UnmarkedBoqItem {
  unmarkedBoqItemIds: number[];
  budgetItemId: number;
}

export class BuildingProgressItem implements IBudgetItem {
  id: number;
  originalId: number;
  parentId: number;
  level: number;
  itemType: string;
  itemNumber: number;
  code: string;
  description: string;
  measureUnit: string;
  amount: number;
  calculationType: CalculationType;
  unitPrice: number;
  totalPrice: number;
  partlyCompletedAmount: number;
  partlyCompletedTotalPrice: number;
  partlyCompletedPercentage: number;
  completedAmount: number;
  completedTotalPrice: number;
  completedPercentage: number;
  restAmount: number;
  restTotalPrice: number;
  restPercentage: number;
  additionalAmount: number;
  additionalTotalPrice: number;
  additionalPercentage: number;
  periods: BuildingProgressPeriodItem[];
  buildingObjectType: BuildingObjectType;
  colorCode: string;
  productionClassification: string;
  contractor: string;
  customClassification: string;
  isNotCompleted: boolean;
  notCompletedPercentage: number;
  notCompletedAmount: number;
  notCompletedTotalPrice: number;
  hasNote = false;
  originalItemIdOfPartialItem? : number;
  partialBudgetItems: PartialBudgetItem[];
  hasPartialBudgetItems?: boolean;
}

export class BuildingProgressPeriodItem {
  periodId: number;
  amount: number;
  calculationType: CalculationType;
  percentage: number;
  totalPrice: number;
  order: number;
  detailId: number;
}

export class BuildingProgressCell {
  rowId: number;
  columnId: string;
}

export enum BuildingProgressPeriodColumn {
  Percentage = 0,
  Amount = 1,
  TotalPrice = 2,
  Details = 3
}
