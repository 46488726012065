export function getScrollableParent(element: any): ScrollableParent {
  let unscrollableTop = 0;
  while (element && !isScrollable(element)) {
    element = element.parentNode;
    if (element === document) return null; // we found nothing scollable, we give up
    if (!isScrollable(element)) {
      unscrollableTop += getSiblingStickyAreaHeight(element,true);
    }
  }
  return {element, unscrollableTop};
}

export function scrollToTop(nativeElement: HTMLElement): void {
  const scrollAbleElement = getScrollableParent(nativeElement);
  if (scrollAbleElement) {
    scrollAbleElement.element.scrollTop = 0;
  }
}

function getSiblingStickyAreaHeight(element: Element, isHeader: boolean): number {
  let stickyArea = 0;
  while (element) {
    const computedStyle = window.getComputedStyle(element);
    if (computedStyle.display !== 'none' && computedStyle.position === 'sticky' || computedStyle.position === 'fixed') {
      stickyArea += element.clientHeight;
    }
    element = isHeader ? element.previousElementSibling : element.nextElementSibling;
  }
  return stickyArea;
}

function isScrollable(el): boolean {
  const overflowY = window.getComputedStyle(el).overflowY;
  return overflowY !== 'visible' && overflowY !== 'hidden';
}

export interface ScrollableParent {
  element: HTMLElement;
  unscrollableTop: number;
}
