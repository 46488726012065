import { AfterContentChecked, Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

import { autosizeTextWidth } from './utils/autosize';

const WIDTH_ERROR_FALLBACK = 200;

@Directive({
  selector: 'input[krosInputAutoSize]',
  exportAs: 'krosInputAutoSize',
})
export class InputAutoSizeDirective implements AfterContentChecked {
  // Input width if there is no text or placeholder
  @Input() emptyWidth = 170;

  // Extra space around text
  @Input() extraWidth = 2;

  constructor(private element: ElementRef, private renderer: Renderer2) {}

  ngAfterContentChecked(): void {
    this.resizeToFitContent();
  }

  @HostListener('input')
  handleInput(event: Event): void {}

  resizeToFitContent(): void {
    const width = this.getInputTextWidth() + this.extraWidth;
    this.renderer.setStyle(this.element.nativeElement, 'width', width + 'px');
  }

  private getInputTextWidth(): number {
    const text = this.getProperty('value');
    if (text != null && text.toString().length > 0) {
      return this.textWidth(text) + this.getBordersAndPaddigsWidth();
    }
    return this.getEmptyInputWidth();
  }

  private getEmptyInputWidth(): number {
    const text = this.getProperty('placeholder');
    if (text != null && text.toString().length > 0) {
      return this.textWidth(text) + this.getBordersAndPaddigsWidth();
    }
    return this.emptyWidth;
  }

  private getBordersAndPaddigsWidth(): number {
    return (
      this.getStylePropertyValue('padding-left') +
      this.getStylePropertyValue('padding-right') +
      this.getStylePropertyValue('border-left') +
      this.getStylePropertyValue('border-right')
    );
  }

  private textWidth(value: string): number {
    try {
      return autosizeTextWidth(value, this.renderer, this.element);
    } catch (e) {
      return WIDTH_ERROR_FALLBACK;
    }
  }

  private getProperty(propertyName: string): string {
    try {
      return this.element.nativeElement[propertyName];
    } catch (error) {
      return '';
    }
  }

  private getStylePropertyValue(property: string): number {
    const width = window.getComputedStyle(this.element.nativeElement, '').getPropertyValue(property);
    if (width && width.length > 0) {
      return parseInt(width, 10);
    }
    return 0;
  }
}
