<div class="kros-modal-header">
  <button
    id="ga-colors-close"
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onCloseClick()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div class="kros-modal-body">
  <kros-color-palette
    [colors]="colors"
    [multiselect]="multiselect"
    [isExtraColors]="isExtraColors"
    (colorSelect)="onColorSelected($event)"
    (colorDeselect)="onColorDeselected($event)"
  ></kros-color-palette>
</div>

<div
  class="kros-modal-footer"
  *ngIf="multiselect"
>
  <button
    class="btn btn-primary btn-submit"
    id="ga-colors-confirm"
    type="button"
    (click)="submitColors()"
    data-test="filter-colors-button"
  >{{ 'COMMON.APPLY_FILTER' | translate }}</button>
</div>
