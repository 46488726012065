<div
  class="documents-container current-navigation-{{detail.currentNavigation}}"
  [ngClass]="{'collapsed': panelCollapsed, 'dashboard-visible': detail.currentNavigation === 'dashboard'}"
>
  <app-budget-structure-panel-container
    [collapsed]="panelCollapsed"
    [data$]="selectors.constructionData$"
    [dataTestCollapse]="'building-progress-panel-collapse'"
    [hasPermission]="permissionHelper.hasPermission"
    [title]="'BUILDING_PROGRESS.BUILDING' | translate"
    (panelCollapsed)="onPanelCollapsed($event)"
    (panelClosed)="detail.currentNavigation = 'table'"
  ></app-budget-structure-panel-container>

  <app-building-progress-container class="list-container"></app-building-progress-container>
</div>

<kros-spin-loader
  *ngIf="deleting"
  [description]="'BUILDING_PROGRESS.DELETE.IN_PROGRESS' | translate"
></kros-spin-loader>
