import { DestroyRef, inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { map, Observable, of, Subject, switchMap, take } from 'rxjs';

import {
  BrowserTabService,
  DocumentsExplorerDispatchersService,
  getSortedTags,
  isOwnersEmail,
  NavbarService,
  Project,
  ProjectDetail,
  ProjectFilter,
  ProjectInfoDialogComponent,
  QuestionModalComponent,
  Tag,
  TagEditModel,
  TagType,
  TranslateService
} from '@kros-sk/ssw-shared-legacy';
import { formatString, StorageService, UserService } from '@kros-sk/ssw-cdk';
import { KrosModalRef, KrosModalService, MessageTypes } from '@kros-sk/components';
import { PermissionType } from '@kros-sk/ssw-shared/permission';

import { BuildingProgressService } from '../../building-progress/services';
import { DocumentsDispatchersService } from '../../store/documents';
import { environment } from '../../../environments/environment';
import { hasApproverRight, hasContractorRight } from '../../building-progress/shared/building-progress-utils';
import { ProjectsService } from '../services';

export const tagsFilterStorageItem = 'projectListTagsFilterArray';

@Injectable()
export class ProjectListHelper {

  private documentsDispatchersService = inject(DocumentsDispatchersService);
  private buildingProgressService = inject(BuildingProgressService);
  private documentsExplorerDispatchers = inject(DocumentsExplorerDispatchersService);
  private krosModalService = inject(KrosModalService);
  private navbarService = inject(NavbarService);
  private router = inject(Router);
  private translateService = inject(TranslateService);
  private storage = inject(StorageService);
  private userService = inject(UserService);
  private browserTabService = inject(BrowserTabService);
  private projectService = inject(ProjectsService);
  private destroyRef = inject(DestroyRef);

  modalRef: KrosModalRef;

  get tagsFilterStorageItemKey(): string {
    return tagsFilterStorageItem + '-' + this.userService.userId;
  }

  setTitleAndNavbar(): void {
    this.browserTabService.setTitle(environment.location === 'cz' ? 'BIM Platforma' : 'PROJECTS.TITLE');
    this.navbarService.changeTitle('PROJECTS.TITLE');
    this.navbarService.setNewsData({
      app: 'Titan',
      isTest: environment.news.isTest.toString(),
      modul: 'Projekty'
    });
  }

  setMobileDropdownNavItems(filterChangedSubject: Subject<ProjectFilter>): void {
    this.navbarService.setMobileDropdown([
      {
        icon: 'home',
        onClick: (): void => {
          filterChangedSubject.next(ProjectFilter.All);
        },
        title: 'PROJECTS.FILTER.ALL_PROJECTS'
      },
      {
        icon: 'perm_identity',
        onClick: (): void => {
          filterChangedSubject.next(ProjectFilter.Mine);
        },
        title: 'PROJECTS.FILTER.MY_PROJECTS'
      },
      {
        icon: 'people',
        onClick: (): void => {
          filterChangedSubject.next(ProjectFilter.SharedWithMe);
        },
        title: 'PROJECTS.FILTER.SHARED_WITH_ME'
      },
      {
        icon: 'poll',
        onClick: (): void => {
          window.open(environment.appUrls.projectReportUrl, '_blank');
        },
        title: 'PROJECTS.ZOZNAM.PROJECT_REPORTS'
      }
    ]);
  }

  getIndexOfProject(project: Project, projects: Project[]): number {
    return projects.indexOf(projects.find(x => x.id === project.id));
  }

  getFilteredProjects(projects: Project[], filterIndex: ProjectFilter): Project[] {
    let data = projects;
    if (filterIndex === ProjectFilter.Mine) {
      data = projects.filter(p => isOwnersEmail(p.owner));
    } else if (filterIndex === ProjectFilter.SharedWithMe) {
      data = projects.filter(p => !isOwnersEmail(p.owner));
    }
    return data;
  }

  openProject(project: Project, isUploadMode: boolean): void {
    if (project) {
      this.documentsDispatchersService.clearState();
      this.documentsExplorerDispatchers.clearCurrentFolder();
      this.router.navigate(['projects/project-detail', project.id, ...isUploadMode ? ['documents'] : []]);
    }
  }

  getTagEditModel(projectId: number, tags: Tag[], isOwner: boolean): TagEditModel {
    return {
      projectId,
      systemTagIds: isOwner ? tags.filter(t => t.type === TagType.Project).map(t => t.id) : undefined,
      userTagIds: tags.filter(t => t.type === TagType.User).map(t => t.id)
    };
  }

  getSortedTags(tags: Tag[]): Tag[] {
    return getSortedTags(tags);
  }

  getProjectsWithEditedTags(projects: Project[], projectId: number, tags: Tag[]): Project[] {
    const sortedTags = getSortedTags(tags);
    return projects.map(p => ({
      ...p,
      userTags: p.id === projectId ? sortedTags : p.userTags
    }));
  }

  getProjectsAfterTagEdited(projects: Project[], editedTag: Tag): Project[] {
    return projects.map(p => ({
      ...p,
      userTags: p.userTags.some(t => t.id === editedTag.id)
        ? getSortedTags(p.userTags.map(t => {
          if (t.id === editedTag.id) {
            return {
              ...t,
              name: editedTag.name,
              colorCode: editedTag.colorCode,
              textColorCode: editedTag.textColorCode
            };
          }
          return t;
        }))
        : p.userTags
    }));
  }

  openDeleteProjectDialog(project: Project, hasVersions: boolean, callback?: Function): void {
    if (project) {
      if (hasVersions) {
        this.openDeleteProjectWithVersionsDialog(project)
          .pipe(take(1)).subscribe(result => {
            if (result) {
              callback(project);
            }
          });
      } else {
        this.openDeleteProjectWithoutVersionsDialog(project)
          .afterClosed$.pipe(take(1)).subscribe(result => {
            if (result.type === 'submit') {
              callback(project);
            }
          });
      }
    }
  }

  openRemoveProjectSharingDialog(project: Project): KrosModalRef {
    return this.krosModalService.openCentered(
      QuestionModalComponent,
      {
        body: formatString(
          this.translateService.translate('PROJECTS.ZOZNAM.REMOVE_PROJECT_SHARING_QUESTION'), `<b>${project.name}</b>`
        ),
        confirmButton: this.translateService.translate('PROJECTS.ZOZNAM.REMOVE_PROJECT'),
        cancelButton: this.translateService.translate('COMMON.DONT_MAKE_CHANGES'),
        noMaxWidth: true
      },
      {
        closeOnBackdropClick: false,
        showMobileArrowBack: false
      }
    );
  }

  openProjectDetailDialog(
    project: Project,
    projectDetail: ProjectDetail,
    canEditHeaderData: boolean,
    canEditApiCode: boolean,
    hideApiCode: boolean): KrosModalRef {
    return this.krosModalService.openCentered(
      ProjectInfoDialogComponent,
      {
        data: {
          project,
          projectDetail,
          disableForm: project.permissionType !== PermissionType.Owner,
          canEditHeaderData,
          canEditApiCode,
          hideApiCode
        }
      },
      { closeOnBackdropClick: false }
    );
  }

  getActiveFilterTagIds(): number[] {
    const tagsFromStorage = this.storage.getItemFromLocalStorage(this.tagsFilterStorageItemKey);
    return tagsFromStorage ? JSON.parse(tagsFromStorage) : [];
  }

  scrollIntoSelectedProject(selectedProjectId: number): void {
    setTimeout(() => {
      const element = document.getElementById('row-' + selectedProjectId);
      if (element) {
        element.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
      }
    }, 0);
  }

  openProjectDetail(project: Project, data: Project[]): void {
    if (project) {
      if (project.permissionType !== PermissionType.Owner) {
        this.buildingProgressService.getPermissionType(project.id)
          .pipe(take(1))
          .subscribe(permission => {
            this.openProjectDetailCore(
              project,
              data,
              permission.permissionType >= PermissionType.Contributor ||
              hasContractorRight(permission.buildingProgressPermissionType),
              permission.permissionType >= PermissionType.Contributor,
              hasContractorRight(permission.buildingProgressPermissionType) ||
              hasApproverRight(permission.buildingProgressPermissionType)
            );
          });
      } else {
        this.openProjectDetailCore(project, data, true, true, false);
      }
    }
  }

  openCannotCopyProjectDialog(): void {
    this.openWarningModalDialog(
      this.translateService.translate('PROJECTS.ZOZNAM.CANNOT_COPY_PROJECT_CAPTION'),
      this.translateService.translate('PROJECTS.ZOZNAM.CANNOT_COPY_PROJECT_MESSAGE'));
  }

  openCannotDeleteDemoProjectDialog(): void {
    this.openWarningModalDialog(
      this.translateService.translate('PROJECTS.ZOZNAM.CANNOT_DELETE_DEMO_CAPTION'),
      null);
  }

  openCannotRemoveProjectSharingDialog(): void {
    this.openWarningModalDialog(
      this.translateService.translate('PROJECTS.ZOZNAM.CANNOT_REMOVE_PROJECT_SHARING_CAPTION'),
      this.translateService.translate('PROJECTS.ZOZNAM.CANNOT_REMOVE_PROJECT_SHARING_MESSAGE'));
  }

  getOldestDemoProject(projects: Project[]): Project {
    const demoProjects = projects.filter(project => project.isDemo);
    return demoProjects.length > 0
      ? demoProjects.reduce((oldest, current) =>
        current.dateCreated < oldest.dateCreated
          ? current
          : oldest)
      : undefined;
  }

  private openWarningModalDialog(caption: string, message: string): void {
    this.krosModalService.openModalMessageBoxModalRef({
      icon: 'warning',
      caption,
      message,
      messageType: MessageTypes.Warning,
      acceptButton: this.translateService.translate('COMMON.CLOSE')
    });
  }

  private openDeleteProjectWithoutVersionsDialog(project: Project): KrosModalRef {
    return this.krosModalService.openCentered(
      QuestionModalComponent,
      {
        body: this.translateService.translate('PROJECTS.ZOZNAM.DELETE_PROJECT') + ' <b>' + project.name + '</b>?',
        confirmButton: this.translateService.translate('COMMON.DELETE'),
      },
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: false,
        showMobileArrowBack: false
      },
      NaN,
      NaN,
      undefined,
      'no-min-width'
    );
  }

  private openProjectDetailCore(
    project: Project,
    data: Project[],
    canEditHeaderData: boolean,
    canEditApiCode: boolean,
    hideApiCode: boolean): void {
    this.projectService.getProjectDetail(project.id)
      .pipe(
        switchMap(projectDetail => this.openProjectDetailDialog(project, projectDetail, canEditHeaderData, canEditApiCode, hideApiCode)
          .afterClosed$.pipe(take(1))
        ),
        switchMap((resp: any) => {
          if (resp.data) {
            return this.projectService.editProject(resp.data).pipe(takeUntilDestroyed(this.destroyRef));
          } else {
            return of(null);
          }
        }),
        takeUntilDestroyed(this.destroyRef))
      .subscribe((projectDetail: ProjectDetail) => {
        if (projectDetail) {
          const projectSource = data.find(d => d.id === projectDetail.id);
          projectSource.name = projectDetail.name;
        }
      });
  }

  private openDeleteProjectWithVersionsDialog(project: Project): Observable<boolean> {
    return this.krosModalService.openModalMessageBox({
      icon: 'danger',
      caption: this.translateService.translate('PROJECTS.ZOZNAM.DELETE_PROJECT_WITH_VERSIONS_CAPTION'),
      message: formatString(
        this.translateService.translate('PROJECTS.ZOZNAM.DELETE_PROJECT_WITH_VERSIONS_MESSAGE'),
        `<b>${project.name}</b>`
      ),
      messageType: MessageTypes.Danger,
      acceptButton: this.translateService.translate('COMMON.DELETE'),
      cancelButton: this.translateService.translate('COMMON.CLOSE'),
    }).pipe(map(modalResponse => modalResponse.data === 'accept'));
  }
}
