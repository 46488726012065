<div class="period-detail">
  <form
    [formGroup]="periodForm"
    (ngSubmit)="onSave()"
  >
    <ng-container *ngIf="!editMode && !datePickerExtended; else datePickerInputs">
      <label>
        {{ 'COMMON.YEAR' | translate }} {{ nextMonthDate.getFullYear() }}
        <select
          formControlName="month"
          class="form-control"
          data-test="building-progress-month-select"
          (change)="onMonthChange()"
        >
          <option
            class="months"
            *ngFor="let month of months"
            [ngValue]="month.monthIndex"
          >{{ month.monthName }}</option>
          <option value="-1">{{ 'COMMON.SELECT_MONTH' | translate }}</option>
        </select>
      </label>

      <a
        *ngIf="!editMode"
        data-test="building-progress-datepicker-extended"
        (click)="toggleDatePickerExtended()"
      >{{ 'COMMON.EXTENDED_SETTINGS' | translate }}</a>
    </ng-container>

    <ng-template #datePickerInputs>
      <kros-datepicker-input
        data-test="building-progress-datepicker-from"
        [commander]="datepickerCommander"
        [options]="{
                        formControl: periodForm.get('from'),
                        name: 'from',
                        label: ('COMMON.FROM' | translate),
                        icon: 'date_range',
                        iconPosition: 'right',
                        noClearButton: true,
                        noTodayButton: true,
                        errors: {
                            required: 'BUILDING_PROGRESS.ERROR.FILL_DATE' | translate
                        }
                    }"
      ></kros-datepicker-input>
      <kros-datepicker-input
        data-test="building-progress-datepicker-to"
        [commander]="datepickerCommander"
        [options]="{
                        formControl: periodForm.get('to'),
                        name: 'to',
                        label: ('COMMON.TO' | translate),
                        icon: 'date_range',
                        iconPosition: 'right',
                        noClearButton: true,
                        noTodayButton: true,
                        positions: [{
                            originX: 'start',
                            originY: 'top',
                            overlayX: 'start',
                            overlayY: 'bottom'
                        }],
                        errors: {
                            required: 'BUILDING_PROGRESS.ERROR.FILL_DATE' | translate
                        }
                    }"
      ></kros-datepicker-input>

      <a
        *ngIf="!editMode"
        (click)="toggleDatePickerExtended()"
      >{{ 'COMMON.BASIC_SETTINGS' | translate }}</a>

      <ng-container *ngIf="editMode">
        <div
          *ngIf="isVatRateDefined(vatRateType.Reduced) || isVatRateDefined(vatRateType.ReducedTransferred)"
          class="vat-item"
        >
          <span class="title">{{ getVatResName(vatRateType.Reduced) | translate }}:</span>

          <div class="value">
            <kros-form-field class="vat-value">
              <input
                krosControl
                krosNumbersOnly
                (input)="handleVatInput($event)"
                [digitsGrouping]="true"
                [minFractionDigits]="1"
                [maxFractionDigits]="1"
                [minValue]="0"
                [maxValue]="100"
                [formControl]="periodForm.controls['reducedVatRate']"
                class="form-control text-right vat-input"
                data-test="vat-rate-reduced" />
            </kros-form-field>

            <span>&nbsp;%</span>
          </div>
        </div>

        <div
          *ngIf="isVatRateDefined(vatRateType.Basic) || isVatRateDefined(vatRateType.BasicTransferred)"
          class="vat-item"
        >
        <span class="title">{{ getVatResName(vatRateType.Basic) | translate }}:</span>

          <div class="value">
            <kros-form-field class="vat-value">
              <input
                krosControl
                krosNumbersOnly
                (input)="handleVatInput($event)"
                [digitsGrouping]="true"
                [minFractionDigits]="1"
                [maxFractionDigits]="1"
                [minValue]="0"
                [maxValue]="100"
                [formControl]="periodForm.controls['basicVatRate']"
                class="form-control text-right vat-input"
                data-test="vat-rate-basic" />
            </kros-form-field>

            <span>&nbsp;%</span>
          </div>
        </div>

        <div
          *ngIf="isCzechVersion && isVatRateDefined(vatRateType.ReducedTransferred)"
          class="vat-item"
        >
          <span class="title">{{ getVatResName(vatRateType.ReducedTransferred) | translate }}:</span>

          <span
            class="value"
            data-test="vat-rate-reduced-transferred"
          >{{getVatRate(vatRateType.ReducedTransferred) | number : digitsInfoVat : appLocation}}&nbsp;%</span>
        </div>

        <div
        *ngIf="isCzechVersion && isVatRateDefined(vatRateType.BasicTransferred)"
        class="vat-item"
        >
          <span class="title">{{ getVatResName(vatRateType.BasicTransferred) | translate }}:</span>
          <span
            class="value"
            data-test="vat-rate-basic-transferred"
          >{{getVatRate(vatRateType.BasicTransferred) | number : digitsInfoVat : appLocation}}&nbsp;%</span>
        </div>

        <div
          *ngIf="isVatRateDefined(vatRateType.Zero)"
          class="vat-item"
        >
          <span class="title">{{ getVatResName(vatRateType.Zero) | translate }}:</span>

          <span
            class="value"
            data-test="vat-rate-zero"
          >{{getVatRate(vatRateType.Zero) | number : digitsInfoVat : appLocation}}&nbsp;%</span>
        </div>
      </ng-container>
    </ng-template>

    <div class="edit-buttons">
      <button
        id="ga-period-add"
        class="btn btn-primary btn-new"
        type="button"
        [disabled]="!canSave"
        [ngClass]="{'ga-edit-mode': !!selectedPeriod}"
        (click)="onSave()"
        data-test="building-progress-period-save"
      >{{ (selectedPeriod ? 'COMMON.ULOZIT' : 'COMMON.INSERT') | translate }}</button>

      <button
        class="btn btn-outline-secondary btn-cancel"
        type="button"
        (click)="onCloseEditMode()"
      >{{ 'COMMON.ZRUSIT' | translate}} </button>
    </div>
  </form>
</div>
