<div class="kros-modal-header">
  <h5 class="modal-title">
    {{ 'SUBCONTRACTORS.RENAME_SUBCONTACTOR' | translate }}
  </h5>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    data-test="modal-close-button"
    (click)="onCloseClick()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<form
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
>
  <div
    class="kros-modal-body"
    data-test="subcontractor-select-modal-body"
  >
    <div class="form-group">
      <label
        for="subcontractor"
        class="caption"
      >{{ 'SUBCONTRACTORS.NAME' | uppercase | translate }}</label>

      <kros-input [options]="{
                    formControl: form.controls.subcontractorName,
                    placeholder: 'SUBCONTRACTORS.SUBCONTRACTOR_NAME_PLACEHOLDER' | translate,
                    name: 'subcontractorName',
                    testName: 'subcontractorName',
                    maxLength: 100,
                    errors: errors
                }"></kros-input>
    </div>
  </div>

  <div class="kros-modal-footer">
    <button
      class="btn btn-primary ga-pv-subcontractor-rename"
      type="submit"
      data-test="rename-subcontractor-button"
      [disabled]="form.invalid"
    >{{ 'COMMON.RENAME' | translate }}</button>
  </div>
</form>
