import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, Validators } from '@angular/forms';

import { debounceTime, Observable, of, switchMap, tap } from 'rxjs';
import { SubSink } from 'subsink';

import { AutocompleteConfig } from '../inputs';
import { countries, Country } from './countries';
import { KrosAutocompleteDirective } from '../form/autocomplete';
import { removeDiacritics } from '../tools/string/string-tools';

@Component({
  selector: 'kros-country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CountrySelectorComponent,
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountrySelectorComponent implements OnInit, OnDestroy, ControlValueAccessor, OnChanges {
  @Input() required = false;
  @Input() showAllErrors = false;

  @ViewChild('optionTemplate', { static: true }) optionTemplate: TemplateRef<any>;
  @ViewChild(KrosAutocompleteDirective) autocomplete;

  countryForm = new UntypedFormControl('');

  selectAutoCompleteConfig: AutocompleteConfig;
  protected options = countries;

  private subs = new SubSink();

  constructor(
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes['showAllErrors']?.currentValue && changes['showAllErrors'].currentValue !== changes['showAllErrors'].previousValue) {
      this.countryForm.markAllAsTouched();
    }
  }

  ngOnInit(): void {
    if (this.required) this.countryForm.setValidators(Validators.required);
    this.prepareSelectAutoCompleteSettings();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  writeValue(name: any): void {
    this.countryForm.setValue(name,
      {
        emitEvent: false
      });
  }

  registerOnChange(fn: any): void {
    this.subs.add(this.countryForm.valueChanges.pipe(tap(() => {
      this.autocomplete.openDropdown();
    })
    ).subscribe(fn));
  }

  registerOnTouched(fn: any): void { }

  prepareSelectAutoCompleteSettings(): void {
    this.selectAutoCompleteConfig = {
      searchMethod: (text$): Observable<readonly Country[]> =>
        text$.pipe(
          debounceTime(200),
          switchMap(
            term => {
              if (term) {
                if (countries.some(x => x.name === term)) {
                  return of(countries);
                } else {
                  term = removeDiacritics(term.toLowerCase());

                  return of(countries.filter((x) => {
                    return x.search.startsWith(term);
                  }));
                }
              } else {
                return of(countries);
              }
            }
          )
        ),
      inputFormatter: (x: any): string => {
        return x.name;
      }
    };
  }

  displayFn(value: any): string {
    return value && typeof value === 'object' ? value.name : value;
  }
}
