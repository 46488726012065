import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';

import { FileUploader } from 'ng2-file-upload';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { DocumentCreateService, DocumentEditService } from '../../services';
import { UploaderService } from '../../../upload';

@Component({
  selector: 'kros-upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss']
})
export class UploadButtonComponent extends UnsubscribeComponent implements OnInit, OnDestroy {
  @Input() disabled = false;
  @Input() projectId: number;
  @Input() parentId: string;

  @Output() filesSelected = new EventEmitter<void>();

  @ViewChild('fileInput') fileInput: ElementRef<HTMLElement>;

  uploader: FileUploader;
  private uploadSubscription: Subscription;

  constructor(
    public fileUploaderService: UploaderService,
    private documentService: DocumentEditService,
    private documentCreateService: DocumentCreateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink =
      this.documentCreateService.createDocument$.subscribe(() => this.initializeUploader(this.fileInput.nativeElement));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.uploadSubscription && !this.uploadSubscription.closed) {
      this.uploadSubscription.unsubscribe();
    }
  }

  initializeUploader(fileInputElement: any): void {
    if (this.fileUploaderService.isUploaderReady) {
      fileInputElement.value = '';
      this.uploader = this.fileUploaderService.configure(this.projectId, this.parentId);
      fileInputElement.click();
    }
  }

  async onFilesSelected(): Promise<void> {
    this.filesSelected.emit();
    this.uploadSubscription = this.fileUploaderService.fileUploadReady.subscribe(uploadFiles => {
      this.documentService.uploadDocuments(
        this.fileUploaderService.filesToUpload, uploadFiles, this.fileUploaderService.progressItem, false, '')
        .subscribe({
          next: (response) => this.fileUploaderService.handleUploadDocumentsSuccess(response),
          error: (err) => this.fileUploaderService.handleUploadDocumentsError(err, uploadFiles)
        });
      this.uploadSubscription.unsubscribe();
    });
    this.fileUploaderService.onFileSelected();
  }
}
