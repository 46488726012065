import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BuildingProgressComponent } from './building-progress.component';
import { SubcontractorsComponent } from './modules/subcontractors/subcontractors.component';

const routes: Routes = [
  {
    path: '',
    component: BuildingProgressComponent
  },
  {
    path: 'subcontractors',
    component: SubcontractorsComponent
  },
  {
    path: 'subcontractors/:contractorId',
    component: SubcontractorsComponent
  },
  {
    path: 'subcontractors/:contractorId/:periodId',
    component: SubcontractorsComponent
  },
  {
    path: ':periodId',
    component: BuildingProgressComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BuildingProgressRoutingModule { }
