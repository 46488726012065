<ng-container [formGroup]="form">
  <kros-company-search
    [id]="formControlName"
    [attr.data-test]="formControlName"
    [formControl]="companyControl"
    [placeholderText]="placeholder"
    [maxLength]="maxlength"
    [validationOptions]="validationOptions"
    [stopScrollInModal]="true"
  >
    <ng-container *ngFor="let error of errorsArray">
      <kros-error [errorId]="error.id">{{error.text}}</kros-error>
    </ng-container>
  </kros-company-search>
</ng-container>
