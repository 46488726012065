import { createAction, props } from '@ngrx/store';

export interface UpdatePartialItemPayload {
  changedValue: number,
  rowIndex: number,
  propertyChanged: PartialBudgetItemChangedProperty
}

import { BuildingProgressItem } from '../../../building-progress/models/construction-data.model';
import {
  PartialBudgetItem,
  PartialBudgetItemChangedProperty,
  PartialBudgetItems
} from '../../../building-progress/models/building-progress-partial-budget-items.model';

export const initPartialItems = createAction('[BuildingProgress] Update partial items', props<{ budgetItem: BuildingProgressItem }>());

export const updatePartialItem = createAction('[BuildingProgress] Update partial item', props<UpdatePartialItemPayload>());
export const addPartialItem = createAction('[BuildingProgress] Add partial item', props<{ partialBudgetItem: PartialBudgetItem }>());
export const removeLastPartialItem = createAction('[BuildingProgress] Remove last partial item');

export const recalculatePartialItems = createAction('[BuildingProgress] Recalculate partial items');
export const recalculatePartialItemsError = createAction('[BuildingProgress] Recalculate partial items error', props<{ error: any }>());
export const recalculatePartialItemSuccess = createAction('[BuildingProgress] Recalculate partial items success',
  props<{ partialBudgetItems: PartialBudgetItems }>());

export const savePartialItems = createAction('[BuildingProgress] Save partial items', props<{ originalItemIdOfPartialItem?: number }>());
export const savePartialItemsError = createAction('[BuildingProgress] Save partial items error', props<{ error: any }>());
export const savePartialItemSuccess = createAction('[BuildingProgress] Save partial items success');
