import { ApplicationType, License } from '@kros-sk/ssw-cdk';

export interface ConstructionDiaryState {
  product: ApplicationType;
  license: License;
}

export const constructionDiaryInitialState: ConstructionDiaryState = {
  product: null,
  license: null
};
