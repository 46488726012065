<div class="comments-container">
  <div
    *ngIf="newComment"
    class="comment"
  >
    <kros-comment
      [comment]="newComment"
      [loading]="loading"
      [newComment]="true"
      (cancelComment)="onCancelNewComment()"
      (saveComment)="onSaveNewComment($event)"
    ></kros-comment>
  </div>

  <div class="comments">
    <ng-container *ngIf="areCommentsLoaded; else contentLoader">
      <div
        *ngFor="let comment of comments"
        class="comment"
      >
        <kros-comment
          [comment]="comment"
          [loading]="loading"
          [focusedIds]="focusedCommentIds"
          [readOnly]="readOnly"
          [showAnswerButton]="showAnswerButton"
          [createAnswer]="createAnswer"
          (saveComment)="onSaveComment($event)"
          (saveAnswerComment)="onSaveNewComment($event)"
          (deleteComment)="onDeleteComment($event)"
          (editingChanged)="onEditingChanged($event)"
          (clickComment)="onClickComment($event)"
        ></kros-comment>
      </div>

      <div
        *ngIf="comments?.length === 0 && !newComment"
        class="empty-message"
        data-test="empty-message"
      >
        {{'COMMENTS.EMPTY_MESSAGE' | translate}}
      </div>
    </ng-container>

    <ng-template #contentLoader>
      <kros-timeline [type]="timelineType.Comments"></kros-timeline>
    </ng-template>
  </div>
</div>

<div
  *ngIf="getNonPublishedCount() > 0 && !readOnly"
  class="non-published-comments"
  data-test="non-published-comments"
>
  {{'COMMENTS.NOT_PUBLISHED_COMMENTS' | translate}}: {{getNonPublishedCount()}}

  <button
    class="btn btn-primary btn-publish"
    type="button"
    [disabled]="newComment || editing"
    (click)="onPublish()"
    data-test="publish-comments-button"
  >{{'COMMENTS.PUBLISH' | translate}}</button>
</div>
