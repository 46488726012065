export enum DeleteResultType {
  /** Document is possible to delete. */
  Ok = 1,
  /** Document is not found. */
  NotFound = 2,
  /** No permission for deleting document. */
  Forbidden = 3,
  /** Application does not allow to delete document, for example not empty folder. */
  NotAllowed = 4
}
