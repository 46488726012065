<div class="side-panel-container">
  <div
    #leftPanelContainer
    class="left-panel default-panel"
    [class.simple-shadow]="openedPanelCount > 1"
  >
    <ng-container *ngIf="!isLeftPanelHidden">
      <ng-container *ngTemplateOutlet="leftPanel"></ng-container>
    </ng-container>
  </div>

  <div class="middle-container">
    <div
      #topPanelContainer
      class="top-panel default-panel"
    >
      <ng-container *ngIf="!isTopPanelHidden">
        <ng-container *ngTemplateOutlet="topPanel"></ng-container>
      </ng-container>
    </div>

    <div
      #contentContainer
      class="content-container"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>

    <div
      #bottomPanelContainer
      class="bottom-panel default-panel"
    >
      <ng-container *ngIf="!isBottomPanelHidden">
        <ng-container *ngTemplateOutlet="bottomPanel"></ng-container>
      </ng-container>
    </div>
  </div>

  <div
    #rightPanelContainer
    class="right-panel default-panel"
    [class.simple-shadow]="openedPanelCount > 1"
  >
    <ng-container *ngIf="!isRightPanelHidden">
      <ng-container *ngTemplateOutlet="rightPanel"></ng-container>
    </ng-container>
  </div>
</div>
