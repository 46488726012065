import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable, catchError, map, of, switchMap } from 'rxjs';

import * as actions from '.';

import { InfoMessageApiService } from '../api/info-message.api.service';

@Injectable()
export class InfoMessageEffects {

  loadInfoMessages$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.loadMessages),
    switchMap(() =>
      this.infoMessageApiService.loadInfoMessages().pipe(
        map(messages => actions.loadMessagesSuccess({ messages })),
        catchError(error => of(actions.loadMessagesError({ error })))
      )
    )));

  constructor(
    private actions$: Actions,
    private infoMessageApiService: InfoMessageApiService
  ) { }
}
