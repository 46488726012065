import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { KrosModalRef } from '@kros-sk/components';

import { ExportColumnSelection } from '../export-colums-selection/export-column-selection.model';
import { PermissionHelper } from '../../../helpers';
import { SummarySettingsModel } from '../../../models/building-progress-export.model';

@Component({
  selector: 'app-building-progress-export-summary',
  templateUrl: './building-progress-export-summary.component.html',
  styleUrls: ['./building-progress-export-summary.component.scss']
})
export class BuildingProgressExportSummaryComponent {
  summarySettings: SummarySettingsModel;
  settingsForm: FormGroup;
  columns: ExportColumnSelection[];

  private get canShowContractorCheckBox(): boolean {
    return !this.router.url.includes('subcontractors') &&
      (this.permissionHelper.isOwner || this.permissionHelper.isContributor);
  }

  constructor(
    private formBuilder: FormBuilder,
    private modalRef: KrosModalRef,
    private permissionHelper: PermissionHelper,
    private router: Router,
  ) {
    this.summarySettings = this.modalRef.data.summarySettings;
  }

  ngOnInit(): void {
    this.initializeSettingsForm();
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  onSubmit(): void {
    this.copySettingsFromControl();
    this.modalRef.submit({ summarySettings: this.summarySettings });
  }

  private initializeSettingsForm(): void {
    this.settingsForm = this.formBuilder.group({
      isIncludedSectionsSummary: [this.summarySettings.isIncludedSectionsSummary ?? false],
    });

    this.columns = [
      {
        id: 'period',
        title: 'BUILDING_PROGRESS.PERIODS.PERIOD',
        dataTest: 'period',
        children: [
          {
            id: 'isPeriodPercentageIncluded',
            title: '%',
            dataTest: 'is-period-percentage-included-checkbox',
            checked: this.summarySettings.isPeriodPercentageIncluded
          }
        ]
      },
      {
        id: 'partlyCompleted',
        title: 'BUILDING_PROGRESS.TABLE.COMPLETED_UNTIL',
        dataTest: 'partly-completed',
        children: [
          {
            id: 'isPartlyCompletedPercentageIncluded',
            title: '%',
            dataTest: 'is-partly-completed-percentage-included-checkbox',
            checked: this.summarySettings.isPartlyCompletedPercentageIncluded
          },
          {
            id: 'isPartlyCompletedTotalPriceIncluded',
            title: 'BUILDING_PROGRESS.TABLE.PRICE',
            dataTest: 'is-partly-completed-total-price-included-checkbox',
            checked: this.summarySettings.isPartlyCompletedTotalPriceIncluded
          }
        ]
      },
      {
        id: 'completed',
        title: 'BUILDING_PROGRESS.TABLE.UNTIL_NOW_BUILT',
        dataTest: 'completed',
        children: [
          {
            id: 'isCompletedPercentageIncluded',
            title: '%',
            dataTest: 'is-completed-percentage-included-checkbox',
            checked: this.summarySettings.isCompletedPercentageIncluded
          },
          {
            id: 'isCompletedTotalPriceIncluded',
            title: 'BUILDING_PROGRESS.TABLE.PRICE',
            dataTest: 'is-completed-total-price-included-checkbox',
            checked: this.summarySettings.isCompletedTotalPriceIncluded
          }
        ]
      },
      {
        id: 'rest',
        title: 'BUILDING_PROGRESS.TABLE.REST',
        dataTest: 'rest',
        children: [
          {
            id: 'isRestPercentageIncluded',
            title: '%',
            dataTest: 'is-rest-percentage-included-checkbox',
            checked: this.summarySettings.isRestPercentageIncluded
          },
          {
            id: 'isRestTotalPriceIncluded',
            title: 'BUILDING_PROGRESS.TABLE.PRICE',
            dataTest: 'is-rest-total-price-included-checkbox',
            checked: this.summarySettings.isRestTotalPriceIncluded
          }
        ]
      },
      {
        id: 'additional',
        title: 'BUILDING_PROGRESS.TABLE.OVERDRAWN',
        dataTest: 'additional',
        children: [
          {
            id: 'isAdditionalPercentageIncluded',
            title: '%',
            dataTest: 'is-additional-percentage-included-checkbox',
            checked: this.summarySettings.isAdditionalPercentageIncluded
          },
          {
            id: 'isAdditionalTotalPriceIncluded',
            title: 'BUILDING_PROGRESS.TABLE.PRICE',
            dataTest: 'is-additional-total-price-included-checkbox',
            checked: this.summarySettings.isAdditionalTotalPriceIncluded
          }
        ]
      },
      {
        id: 'notCompleted',
        title: 'BUILDING_PROGRESS.TABLE.NOT_COMPLETED',
        dataTest: 'not-completed',
        children: [
          {
            id: 'isNotCompletedPercentageIncluded',
            title: '%',
            dataTest: 'is-not-completed-percentage-included-checkbox',
            checked: this.summarySettings.isNotCompletedPercentageIncluded
          },
          {
            id: 'isNotCompletedTotalPriceIncluded',
            title: 'BUILDING_PROGRESS.TABLE.PRICE',
            dataTest: 'is-not-completed-total-price-included-checkbox',
            checked: this.summarySettings.isNotCompletedTotalPriceIncluded
          }
        ]
      },
      ...this.canShowContractorCheckBox ?
        [{
          id: 'isContractorIncluded',
          title: 'BUILDING_PROGRESS.TABLE.SUBCONTRACTORS',
          dataTest: 'is-contractor-included-checkbox',
          checked: this.summarySettings.isContractorIncluded
        }] : []
    ];
  }

  private copySettingsFromControl(): void {
    const formControls = this.settingsForm.controls;
    this.summarySettings.isPeriodPercentageIncluded = formControls.isPeriodPercentageIncluded.value;
    this.summarySettings.isIncludedSectionsSummary = formControls.isIncludedSectionsSummary.value;
    this.summarySettings.isPartlyCompletedPercentageIncluded = formControls.isPartlyCompletedPercentageIncluded.value;
    this.summarySettings.isPartlyCompletedTotalPriceIncluded =
      formControls.isPartlyCompletedTotalPriceIncluded.value;
    this.summarySettings.isCompletedPercentageIncluded = formControls.isCompletedPercentageIncluded.value;
    this.summarySettings.isCompletedTotalPriceIncluded = formControls.isCompletedTotalPriceIncluded.value;
    this.summarySettings.isRestPercentageIncluded = formControls.isRestPercentageIncluded.value;
    this.summarySettings.isRestTotalPriceIncluded = formControls.isRestTotalPriceIncluded.value;
    this.summarySettings.isAdditionalPercentageIncluded = formControls.isAdditionalPercentageIncluded.value;
    this.summarySettings.isAdditionalTotalPriceIncluded = formControls.isAdditionalTotalPriceIncluded.value;
    this.summarySettings.isNotCompletedPercentageIncluded = formControls.isNotCompletedPercentageIncluded.value;
    this.summarySettings.isNotCompletedTotalPriceIncluded = formControls.isNotCompletedTotalPriceIncluded.value;
    this.summarySettings.isContractorIncluded = this.canShowContractorCheckBox ?
      formControls.isContractorIncluded.value : undefined;
  }
}
