import { Component, Input } from '@angular/core';

@Component({
  selector: 'kros-components-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  @Input() showInParent = false;
  @Input() status: string;

  constructor() { }
}
