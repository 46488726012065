import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { catchError, Observable, of, throwError } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { DocumentListInfoModel, ToastService, ToastType, TranslateService } from '@kros-sk/ssw-shared-legacy';
import { httpRetryAndLog } from '@kros-sk/core';

import { Version } from '../../quantity-manager/models/version.model';

const documentVersionsController = '/api/documentService/DocumentVersions';
const getVersionEndpoint = documentVersionsController + '/version';
const documentsController = '/documentService/documents';
const getFirstDocumentEndpoint = documentsController + '/firstDocument';

@Injectable()
export class DocumentService {

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private translateService: TranslateService,
    @Inject(APP_CONFIG) private appConfig: any
  ) { }

  getDocumentVersion(documentVersionId: string): Observable<Version> {
    return this.http.get(
      this.appConfig.appUrls.titanGatewayUrl + getVersionEndpoint + '?documentVersionId=' + encodeURIComponent(documentVersionId))
      .pipe(catchError(this.handleError.bind(this)));
  }

  getFirstDocument(projectId: number): Observable<DocumentListInfoModel | null> {
    return this.http.get<DocumentListInfoModel>(
      this.gatewayApi + projectId + getFirstDocumentEndpoint + '?documentName=' + encodeURIComponent(this.appConfig.demoDocumentName))
      .pipe(
        httpRetryAndLog({
          retryOnStatus: /404/,
          retryDelays: [1000, 2000, 4000]
        }),
        catchError((error: HttpErrorResponse) => of(null))
      );
  }

  private get gatewayApi(): string {
    return this.appConfig.appUrls.titanGatewayUrl + '/api/projects/';
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      let errorMessage: string = this.translateService.translate('ERROR_MESSAGE.GENERAL_ERROR');

      switch (error.status) {
        case 403:
          errorMessage = this.translateService.translate('ERROR_MESSAGE.DOCUMENT_FORBBIDEN');
          break;

        case 404:
          errorMessage = this.translateService.translate('ERROR_MESSAGE.DOCUMENT_NOT_EXIST');
          break;
      }
      this.toastService.open(errorMessage, ToastType.Error);

      console.error(
        `Backend returned code ${error.status}, ` +
        `error message: ${errorMessage}, ` +
        `body was: ${error.error}`);

      return throwError(() => new Error(errorMessage));
    }
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
