import { Directive, InjectionToken } from '@angular/core';

/**
 * Injection token that can be used to reference instances of `KrosError`. It serves as
 * alternative token to the actual `KrosError` class which could cause unnecessary
 * retention of the class and its directive metadata.
 */
export const KROS_INPUT_ERROR = new InjectionToken<KrosInputError>('KrosInputError');

/** Single error message to be shown underneath the form-field. */
@Directive({
  selector: 'kros-input-error, [krosInputError]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'kros-form-field-error',
  },
  providers: [{ provide: KROS_INPUT_ERROR, useExisting: KrosInputError }],
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class KrosInputError {}
