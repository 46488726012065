import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BlobDownloadResponseModel } from '@azure/storage-blob';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { DocumentStorageService, getQuery, TranslateService } from '@kros-sk/ssw-shared-legacy';
import { downloadFile } from '@kros-sk/ssw-cdk';

import { BudgetApprovalExportBudgetModel } from '../models/budget-approval-export-budget.model';
import {
  BudgetApprovalExportModel as BudgetApprovalExportModel,
  BudgetApprovalExportSettings
} from '../models/budget-approval-export.model';
import { BudgetApprovalModel, BudgetChangeModel, DeleteBuildingObjectResult } from '../models';
import { environment } from '../../../environments/environment';

const budgetApprovalApi = '/api/budgetApprovalService/budgetApproval';
const gatewayApi = '/api/projects/';
const budgetApprovalExportApi = '/budgetApprovalService/Export';
const budgetApprovalExportSettingsApi = '/api/budgetApprovalService/Export';
const xlsxType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const getDataEndpoint = '/data/';
const buildingObjectDeleteEndpoint = '/buildingObjectDelete/';
const getBudgetChangesDataEndpoint = '/buildingObjectData/';
const xlsxExportBudgetEndpoint = '/xlsxTableExport/';
const xlsxExportEndpoint = '/xlsxExport/';
const exportXlsxSettings = '/exportXlsxSettings/';
const exportXlsxDifferences = '/xlsxDifferentUnitPriceTableExport';

@Injectable()
export class BudgetApprovalService {

  constructor(
    private http: HttpClient,
    private documentStorageService: DocumentStorageService,
    private translateService: TranslateService,
    private router: Router,
  ) { }

  private get budgetApprovalApi(): string {
    return environment.appUrls.titanGatewayUrl + budgetApprovalApi;
  }

  private get gatewayApi(): string {
    return environment.appUrls.titanGatewayUrl + gatewayApi;
  }

  private get budgetApprovalExportSettingsApi(): string {
    return environment.appUrls.titanGatewayUrl + budgetApprovalExportSettingsApi;
  }

  getBudgetApprovalData(projectId: number, hierarchyCode?: string): Observable<BudgetApprovalModel> {
    const queryString = hierarchyCode ? getQuery('hierarchyCode', hierarchyCode) : '';
    return this.http.get<BudgetApprovalModel>(`${this.budgetApprovalApi + getDataEndpoint}${projectId}${queryString}`);
  }

  deleteBuildingObject(projectId: number, buildingObjectId: number): Observable<DeleteBuildingObjectResult> {
    return this.http.delete<DeleteBuildingObjectResult>(
      this.budgetApprovalApi + buildingObjectDeleteEndpoint + projectId + '/' + buildingObjectId);
  }

  getBudgetChangesData(projectId: number, buildingObjectId: number): Observable<BudgetChangeModel> {
    return this.http.get<BudgetChangeModel>(this.budgetApprovalApi + getBudgetChangesDataEndpoint + projectId + '/' + buildingObjectId);
  }

  exportBudgetApprovalDifferences(projectId: number): Observable<any> {
    let exportName: string;

    return this.http.post(
      this.getEndpointPath(projectId, exportXlsxDifferences),
      null,
      { params: { 'ui-culture': this.translateService.uiCulture } })
      .pipe(
        switchMap((result: any) => {
          exportName = this.getNameFromResultName(result.name);

          return this.documentStorageService.downloadDocument(result);
        }),
        switchMap((resp: BlobDownloadResponseModel) => resp.blobBody),
        map(blob => this.downloadFile(blob, xlsxType, exportName))
      );
  }

  exportBudgetApprovalBudget(exportModel: BudgetApprovalExportBudgetModel): Observable<any> {
    let exportName: string;

    return this.http.post(
      this.getEndpointPath(exportModel.projectId, xlsxExportBudgetEndpoint),
      exportModel,
      { params: { 'ui-culture': this.translateService.uiCulture } })
      .pipe(
        switchMap((result: any) => {
          exportName = this.getNameFromResultName(result.name);

          return this.documentStorageService.downloadDocument(result);
        }),
        switchMap((resp: BlobDownloadResponseModel) => resp.blobBody),
        map(blob => this.downloadFile(blob, xlsxType, exportName))
      );
  }

  exportBudgetApproval(exportModel: BudgetApprovalExportModel): Observable<any> {
    let exportName: string;

    return this.http.post(
      this.getEndpointPath(exportModel.projectId, xlsxExportEndpoint),
      exportModel,
      { params: { 'ui-culture': this.translateService.uiCulture } })
      .pipe(
        switchMap((result: any) => {
          exportName = this.getNameFromResultName(result.name);

          return this.documentStorageService.downloadDocument(result);
        }),
        switchMap((resp: BlobDownloadResponseModel) => resp.blobBody),
        map(blob => this.downloadFile(blob, xlsxType, exportName))
      );
  }

  navigateToBuildingProgress(projectId: number, hierarchyCode: string): void {
    this.router.navigate(['projects/project-detail', projectId, 'building-progress'], { queryParams: { hierarchyCode } });
  }

  private getNameFromResultName(resultName: string): string {
    return resultName.substring(resultName.indexOf('_') + 1);
  }

  private downloadFile(blob: Blob, type: string, fileName: string): void {
    const file = new Blob([blob], { type });
    downloadFile(window.URL.createObjectURL(file), fileName);
  }

  getXlsxExportSettings(): Observable<BudgetApprovalExportSettings> {
    return this.http.get<BudgetApprovalExportSettings>(this.budgetApprovalExportSettingsApi + exportXlsxSettings);
  }

  private getEndpointPath(projectId: number, endpoint: string): string {
    return this.gatewayApi + projectId + budgetApprovalExportApi + endpoint;
  }
}
