import { Component } from '@angular/core';

import { KrosModalRef } from '@kros-sk/components';

import { environment } from '../../../../environments/environment';
import { InvoiceDetails } from '../../../building-progress/models/building-progress-invoice/building-progress-invoice-details.model';

@Component({
  selector: 'app-building-progress-invoice-picker',
  templateUrl: './building-progress-invoice-picker.component.html',
  styleUrls: ['./building-progress-invoice-picker.component.scss'],
})
export class BuildingProgressInvoicePickerComponent {
  invoices: InvoiceDetails[];

  get appLocation(): string {
    return environment.location;
  }

  constructor(
    private modalRef: KrosModalRef,
  ) {
    this.invoices = this.modalRef.data.invoices;
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  onOpenInvoiceClick(invoice: InvoiceDetails): void {
    this.modalRef.submit(invoice.url);
  }
}
