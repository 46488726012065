import { ActivatedRoute, Router } from '@angular/router';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter } from 'rxjs/operators';

import { DetailBaseComponent } from '@kros-sk/ssw-shared-legacy';

import { BudgetApprovalDifferencesModel } from '../../models';
import { BudgetApprovalDifferencesTableService } from '../../services';
import { BudgetApprovalDispatchersService, BudgetApprovalSelectorsService } from '../../../store/budget-approval';
import { BudgetApprovalHelper, ExportHelper, RelationHelper } from '../../helpers';
import { environment } from '../../../../environments/environment';
import { fixedColumnWidth } from '../budget-changes-detail/budget-changes-detail.component';
import { PermissionHelper } from '../../../building-progress/helpers';
import { ProjectsSelectorsService } from '../../../store/projects';

@Component({
  selector: 'app-budget-approval-differences',
  templateUrl: './budget-approval-differences.component.html',
  styleUrls: ['./budget-approval-differences.component.scss'],
  providers: [BudgetApprovalDifferencesTableService, PermissionHelper, RelationHelper]
})
export class BudgetApprovalDifferencesComponent extends DetailBaseComponent implements OnInit {

  protected tableService = inject(BudgetApprovalDifferencesTableService);
  private destroyRef = inject(DestroyRef);
  private selector = inject(BudgetApprovalSelectorsService);
  private dispatcher = inject(BudgetApprovalDispatchersService);
  private projectsSelectorsService = inject(ProjectsSelectorsService);
  private activatedRoute = inject(ActivatedRoute);
  private exportHelper = inject(ExportHelper);
  private router = inject(Router);
  private budgetApprovalHelper = inject(BudgetApprovalHelper);

  constructor() {
    super(environment.location);
  }

  ngOnInit(): void {
    this.budgetApprovalHelper.setTitleAndNavbar();

    this.projectId = +this.activatedRoute.parent.snapshot.paramMap.get('projectId');

    this.selector.differencesData$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(p => this.setData(p));
    this.projectsSelectorsService.projectDetail$.pipe(filter(p => !!p), takeUntilDestroyed(this.destroyRef))
      .subscribe(p => this.setProjectId(p.id));

    this.dispatcher.loadSharingList(this.projectId);
    this.fixedColumnWidth = fixedColumnWidth;
  }

  private setData(data: BudgetApprovalDifferencesModel): void {
    if (data) {
      this.tableData = data;
      this.dataTableConfig = this.hasData ?
        this.tableService.getTableConfig(this.tableData) :
        undefined;

      if (this.tableData?.items.length <= this.focusedCell?.rowIndex) {
        this.focusedCell.rowIndex = this.tableData.items.length - 1;
      }

      this.focusCell();
    } else {
      this.dispatcher.loadDifferencesData(this.projectId);
    }
  }

  onExportClick(): void {
    this.exportHelper.exportBudgetApprovalDifferences(this.projectId);
  }

  onBackClick(): void {
    this.onBack(this.projectId);
  }

  private setProjectId(projectId: number): void {
    if (this.projectId && this.projectId !== projectId) {
      this.onBack(projectId);
    }
  }

  private onBack(projectId: number): void {
    this.router.navigate(['projects/project-detail', projectId, 'budget-approval']);
  }
}
