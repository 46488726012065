import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ProjectFilter } from '@kros-sk/ssw-shared-legacy';

@Component({
  selector: 'app-project-panel-container',
  templateUrl: './project-panel-container.component.html',
  styleUrls: ['./project-panel-container.component.scss']
})
export class ProjectPanelContainerComponent {

  @Input() filterIndex = ProjectFilter.None;
  @Output() projectCreated = new EventEmitter<void>();
  @Output() filterChanged = new EventEmitter<ProjectFilter>();

  constructor() { }

  createProject(): void {
    this.projectCreated.emit();
  }

  onFilterChanged(filter: ProjectFilter): void {
    this.filterIndex = filter;
    this.filterChanged.emit(filter);
  }
}
