import { PRIMARY_OUTLET, UrlSegmentGroup, UrlTree } from '@angular/router';

export function isOnCreateRouteInSettings(urlTree: UrlTree): boolean {
  const segments = splitUrlTreeToStringSegments(urlTree);
  return segments.length > 0 ? segments[segments.length - 1] === 'create' : false;
}

export function isOnDeleteRouteInSettings(urlTree: UrlTree): boolean {
  const segments = splitUrlTreeToStringSegments(urlTree);
  return segments.length > 0 ? segments[segments.length - 1] === 'delete' : false;
}

export function getSettingsNavigateCommands(urlTree: UrlTree, id: string, baseSegment: string): string[] {
  const segments = splitUrlTreeToStringSegments(urlTree);
  const index = segments.findIndex(segment => segment === baseSegment);

  if (index === -1) {
    return [];
  }

  if (index === segments.length - 1) {
    return [id];
  } else {
    const lastSegment = segments[index + 1];
    if ((id === lastSegment && id !== 'create') ||
      (lastSegment === 'create' && id === '0')) {
      return ['../'];
    } else {
      return ['../', id];
    }
  }
}

export function getSettingIdFromRoute(urlTree: UrlTree, baseSegment: string): string | number | 'create' | null {
  const segments = splitUrlTreeToStringSegments(urlTree);
  const index = segments.findIndex(segment => segment === baseSegment);
  if (index > 0) {
    let pathLength = 1; // after baseSegment segment can be id
    if (segments[segments.length - 1] === 'delete') {
      // when the user clicks on delete button, delete segment is added to route to invoke FormGuard
      // increment path length, because in this case there are 2 segments
      pathLength++;
    }
    if (index === segments.length - 1 - pathLength) {
      const id: string = segments[index + 1];
      return id === 'create' ? 'create' : parseIfNumber(segments[index + 1]);
    }
  }
  return null;
}

function splitUrlTreeToStringSegments(urlTree: UrlTree): string[] {
  const group: UrlSegmentGroup = urlTree.root.children[PRIMARY_OUTLET];
  return !group ? [] : group.segments.map(segment => segment.path.toLowerCase());
}

function parseIfNumber(number: string): string | number {
  if (typeof number === 'number') {
    return +number;
  }
  return number;
}
