import { Action, createReducer, on } from '@ngrx/store';

import * as actions from './license.actions';
import { initialLicenseState, LicenseState } from './license.state';

const reducer = createReducer(
  initialLicenseState,
  on(actions.loadMishaLicenseSuccess, (state, payload): LicenseState => {
    return {
      ...state,
      mishaLicense: payload.mishaLicense
    };
  }),
  on(actions.loadBuildingProgressLicenseSuccess, (state, payload): LicenseState => {
    return {
      ...state,
      buildingProgressLicense: payload.buildingProgressLicense,
      returnToBuildingProgress: payload.returnToBuildingProgress,
      returnToBudgetApproval: payload.returnToBudgetApproval
    };
  }),
  on(actions.loadBudgetLicenseSuccess, (state, payload): LicenseState => {
    return {
      ...state,
      budgetLicense: payload.budgetLicense
    };
  }),
  on(actions.loadQuantityManagerLicenseSuccess, (state, payload): LicenseState => ({
    ...state,
    quantityManagerLicense: payload.quantityManagerLicense
  })),
  on(actions.loadDesktopLicenseSuccess, (state, payload): LicenseState => ({
    ...state,
    desktopLicense: payload.desktopLicense
  })),
  on(actions.loadLicenseDetailSuccess, (state, payload): LicenseState => ({
    ...state,
    licenseNumber: payload.licenseDetail.licenseNumber,
    existValid: payload.licenseDetail.existValid,
    isAutomaticRenewalLicense: payload.licenseDetail.isAutomaticRenewal
  })),
);

export function licenseReducer(state: LicenseState | undefined, action: Action): LicenseState {
  return reducer(state, action);
}
