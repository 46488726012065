import { inject, Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { concatLatestFrom } from '@ngrx/operators';
import { Observable, of } from 'rxjs';

import { ApplicationOpenerService, LicenseService, PreparingExternalApplicationModel, StatisticsService } from '@kros-sk/ssw-shared-legacy';
import { ApplicationType, UserService } from '@kros-sk/ssw-cdk';
import { KrosModalCloseEvent, KrosModalService } from '@kros-sk/components';
import { SelectionListModalComponent } from '@kros-sk/ui/selection-list-modal';
import { SingleInputModalComponent } from '@kros-sk/ui/single-input-modal';

import { constructionDiaryActions } from './construction-diary.actions';
import { ConstructionDiaryLicenseHelper } from '../../utils/construction-diary-license.helper';
import { ConstructionDiarySelectorsService } from './construction-diary-selectors.service';
import { ExternalApplicationService } from '../../utils/external-application.service';

const stavarioCompanyIdSettingName = 'stavarioCompanyId';

@Injectable()
export class ConstructionDiaryEffects {

  private actions$ = inject(Actions);
  private userService = inject(UserService);
  private modalService = inject(KrosModalService);
  private applicationOpenerService = inject(ApplicationOpenerService);
  private externalApplicationService = inject(ExternalApplicationService);
  private licenseService = inject(LicenseService);
  private statisticsService = inject(StatisticsService);
  private licenseHelper = inject(ConstructionDiaryLicenseHelper);
  private selectors = inject(ConstructionDiarySelectorsService);

  loadConstructionDiaryLicense$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(constructionDiaryActions.loadLicenseStart),
      switchMap(payload => this.licenseService.getConstructionDiaryLicense(payload.product).pipe(
        map(license => constructionDiaryActions.loadLicenseSuccess({ license }))
      )));
  });

  loadConstructionDiaryList$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(constructionDiaryActions.loadLicenseSuccess),
      map(() => constructionDiaryActions.loadDiaryListStart()));
  });

  loadConstructionDiaryListStart$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(constructionDiaryActions.loadDiaryListStart),
      concatLatestFrom(() => [this.selectors.product$]),
      switchMap(([_, product]) => {
        const data: PreparingExternalApplicationModel = {
          applicationId: ApplicationType[product].toLowerCase(),
          projectId: 0,
          documentVersionId: undefined,
          folderId: undefined
        };
        return this.applicationOpenerService.prepareExternalApplicationList(data).pipe(
          map(constructionDiaryList => constructionDiaryActions.loadDiaryListSuccess({ constructionDiaryList })),
          catchError(error => of(constructionDiaryActions.loadDiaryListError({ error })))
        );
      })
    );
  });

  onOpenConstructionDiary$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(constructionDiaryActions.loadDiaryListSuccess),
      filter(payload => payload.constructionDiaryList.length === 1),
      map(payload => constructionDiaryActions.openDiary({ constructionDiary: payload.constructionDiaryList[0] }))
    );
  });

  selectConstructionDiary$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(constructionDiaryActions.loadDiaryListSuccess),
      filter(payload => payload.constructionDiaryList.length > 1),
      switchMap(payload =>
        this.externalApplicationService.getUiSettings(this.userService.userId, stavarioCompanyIdSettingName).pipe(
          switchMap((stavarioCompanyId: number) => this.modalService.openCentered(
            SelectionListModalComponent,
            {
              items: payload.constructionDiaryList,
              defaultSelectedItem: payload.constructionDiaryList.find(p => p.id === stavarioCompanyId),
              modalTitleKey: 'CONSTRUCTION_DIARY.COMPANY_LIST',
              modalSubmitKey: 'CONSTRUCTION_DIARY.SELECT_COMPANY'
            },
            {
              closeOnBackdropClick: false,
              fullscreenOnMobile: true,
              showMobileArrowBack: true,
              closeOnEscClick: true,
              addModalToBrowsersHistory: false
            }
          ).afterClosed$),
          filter((result: KrosModalCloseEvent<any>) => result.type === 'submit'),
          switchMap((result: KrosModalCloseEvent<any>) => this.externalApplicationService.saveUiSettings(
            this.userService.userId,
            stavarioCompanyIdSettingName,
            result.data.selectedItem.id
          ).pipe(
            map(() => constructionDiaryActions.openDiary({ constructionDiary: result.data.selectedItem }))
          ))
        )
      )
    );
  });

  openMarketingWindow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(constructionDiaryActions.loadDiaryListSuccess),
      filter(payload => payload.constructionDiaryList.length === 0 && this.licenseHelper.isFreeOrReadonlyLicense),
      map(() => this.licenseHelper.openNotAccessDialog()),
    );
  }, { dispatch: false });

  registerNewConstructionDiary$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(constructionDiaryActions.loadDiaryListSuccess),
      concatLatestFrom(() => [this.selectors.product$]),
      filter(([payload, product]) => payload.constructionDiaryList.length === 0 &&
        this.licenseHelper.isTrialOrFullLicense &&
        product === ApplicationType.Stavario),
      switchMap(() => this.modalService.openCentered(
        SingleInputModalComponent,
        {
          modalTitleKey: 'CONSTRUCTION_DIARY.COMPANY_NAME',
          modalTextKey: 'CONSTRUCTION_DIARY.REGISTER_COMPANY_TEXT',
          modalSubmitKey: 'CONSTRUCTION_DIARY.REGISTER_COMPANY',
          placeholderKey: 'CONSTRUCTION_DIARY.COMPANY_NAME',
          requiredErrorTooltipKey: 'COMMON.REQUIRED_ITEM',
          useCompanyInput: true
        },
        {
          closeOnBackdropClick: false,
          fullscreenOnMobile: true,
          showMobileArrowBack: true,
          closeOnEscClick: true,
          addModalToBrowsersHistory: false
        }
      ).afterClosed$),
      filter((singleInputResult: KrosModalCloseEvent<any>) => singleInputResult.type === 'submit'),
      switchMap(singleInputResult => {
        return this.applicationOpenerService.registerStavario(singleInputResult.data.value.address.businessName).pipe(
          map(constructionDiary => constructionDiaryActions.registerNewDiarySuccess({ constructionDiary })),
          catchError(error => of(constructionDiaryActions.registerNewDiaryError({ error })))
        );
      })
    );
  });

  openConstructionDiary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(constructionDiaryActions.openDiary, constructionDiaryActions.registerNewDiarySuccess),
      concatLatestFrom(() => [this.selectors.product$]),
      switchMap(([payload, product]) => {
        if (payload.constructionDiary.state >= 0) {
          window.open(payload.constructionDiary.redirectUrl, '_blank');
          return this.statisticsService.visit(product);
        } else {
          this.licenseHelper.openNotAccessDialog();
          return of();
        }
      })
    );
  }, { dispatch: false });
}
