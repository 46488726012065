import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PermissionApiService } from './store/permission-api.service';
import { PermissionDispatchersService, PermissionEffects, permissionFeatureState, PermissionSelectorsService } from './store';
import { PermissionService } from './services/permission.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(permissionFeatureState),
    EffectsModule.forFeature([ PermissionEffects ])
  ],
  providers: [
    PermissionApiService,
    PermissionDispatchersService,
    PermissionSelectorsService,
    PermissionService
  ]
})
export class PermissionModule {}
