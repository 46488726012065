<div>
  <h5>{{ 'SHARING.SHARE_APPLICATIONS' | translate }}</h5>

  <div class="input-row">
    <tag-input
      id="tag-input"
      class="tag-input"
      [(ngModel)]="inputItems"
      [(inputText)]="inputText"
      [clearOnBlur]="false"
      [validators]="validators"
      [separatorKeyCodes]="separatorKeysCodes"
      [onTextChangeDebounce]="1"
      [errorMessages]="errorMessages"
      theme="titan-theme"
      placeholder="{{ 'SHARING.ENTER_EMAIL' | translate }}"
      secondaryPlaceholder="{{ 'SHARING.ENTER_EMAIL' | translate }}"
      (onTextChange)="onTextChange($event)"
      data-test="sharing-list-input"
    ></tag-input>
  </div>

  @if (wasModified) {
  <div
    class="permissions"
  >
    <div *ngFor="let permissionItem of permissionItems; trackBy: permissionItemTrackByFn">
      <div class="permission-item">
        <div class="permission-name">{{ permissionItem.name }}</div>

        <kros-user-sharing-dropdown
          [sharingMode]="getSharingMode(permissionItem)"
          [defaultValues]="getDefaultDropdownValues(permissionItem)"
          [compactMode]="true"
          [disabled]="!hasLicense(permissionItem)"
          (permissionChanged)="onPermissionChanged($event, permissionItem)"
          krosTooltip="{{getLicenseTooltip(permissionItem)}}"
        ></kros-user-sharing-dropdown>
      </div>

      @if (permissionItem.canShowWarning && permissionItem.warningText) {
      <div
        class="warning-text"
        [attr.data-test]="getApplicationName(permissionItem.applicationType) + '-warning'"
      >
        <span>{{ permissionItem.warningText }}</span>
      </div>
      }
    </div>
  </div>

  <textarea
    class="form-control text-area"
    placeholder="{{ 'SHARING.USER_MESSAGE_PLACEHOLDER' | translate }}"
    rows="3"
    [(ngModel)]="message"
  ></textarea>

  <div class="buttons">
    <button
      class="btn btn-primary btn-left"
      [disabled]="isInProgress"
      (click)="onSubmit()"
      data-test="sharing-list-input-submit"
    >{{'SHARING.ADD' | translate }}</button>
    <button
      class="btn btn-outline-secondary btn-right"
      (click)="onCloseClick()"
    >{{'COMMON.ZRUSIT' | translate }}</button>
  </div>
  }
</div>
