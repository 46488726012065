import { Component, EventEmitter, inject, Input, Output } from '@angular/core';

import { AuthSelectorsService } from '@kros-sk/auth';
import { ConstructionDiaryHelper } from '@kros-sk/ssw-shared/construction-diary';
import { ProjectFilter } from '@kros-sk/ssw-shared-legacy';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-project-filters',
  templateUrl: './project-filters.component.html',
  styleUrls: ['./project-filters.component.scss']
})
export class ProjectFiltersComponent {

  protected authSelectorsService = inject(AuthSelectorsService);
  private constructionDiaryHelper = inject(ConstructionDiaryHelper);

  @Input() filterIndex = ProjectFilter.None;
  @Output() filterChanged = new EventEmitter<ProjectFilter>();

  get isSlovakVersion(): boolean {
    return environment.location === 'sk';
  }

  changeFilter(filter: ProjectFilter): void {
    this.filterIndex = filter;
    this.filterChanged.emit(filter);
  }

  openProjectReport(): void {
    window.open(environment.appUrls.projectReportUrl, '_blank');
  }

  onOpenStavario(): void {
    this.constructionDiaryHelper.openStavario();
  }

  onOpenBuildary(): void {
    this.constructionDiaryHelper.openBuildary();
  }
}
