import { ComponentType } from '@angular/cdk/overlay';
import { Injectable, TemplateRef } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ToastService {
  arraySize = 3;

  private toasts = new BehaviorSubject<any[]>([]);
  private pwaToasts = new BehaviorSubject<any[]>([]);
  private bottomRightToasts = new BehaviorSubject<any[]>([]);

  get toasts$(): Observable<any[]> {
    return this.toasts.asObservable();
  }

  get pwaToasts$(): Observable<any[]> {
    return this.pwaToasts.asObservable();
  }

  get bottomRightToasts$(): Observable<any[]> {
    return this.bottomRightToasts.asObservable();
  }

  show(textOrTpl: string | TemplateRef<any> | ComponentType<any>, options: any = {}, pwa?: boolean): any {
    const toast = { textOrTpl, ...options };
    let currentToasts = (pwa ? this.pwaToasts : options.bottomRight ? this.bottomRightToasts : this.toasts).getValue();
    currentToasts.push(toast);
    if (currentToasts.length > this.arraySize) {
      currentToasts = currentToasts.slice(-this.arraySize);
    }
    (pwa ? this.pwaToasts : options.bottomRight ? this.bottomRightToasts : this.toasts).next(currentToasts);
    return toast;
  }

  showPwaToast(textOrTpl: string | TemplateRef<any>, options: any = {}): void {
    this.show(textOrTpl, options, true);
  }

  remove(toast): void {
    if (toast?.bottomRight) {
      this.bottomRightToasts.next(this.bottomRightToasts.getValue().filter(t => t !== toast));
    } else {
      this.toasts.next(this.toasts.getValue().filter(t => t !== toast));
    }
  }

  removeAll(): void {
    this.toasts.next([]);
    this.bottomRightToasts.next([]);
  }

  removePwaToast(toast, click): void {
    if (click) document.cookie = 'installPwa=false;path=/;expires=21 Oct 2100 07:28:00 GMT';
    this.pwaToasts.next(this.pwaToasts.getValue().filter(t => t !== toast));
  }

  showInstallPromotion(deferredPrompt): void {
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        this.pwaToasts.next([]);
      }
      deferredPrompt = null;
    });
  }
}
