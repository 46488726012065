import { NgModule } from '@angular/core';

import { AnnouncekitModule } from 'announcekit-angular';

import { AnnounceKitComponent } from './announce-kit.component';

@NgModule({
  declarations: [
    AnnounceKitComponent,
  ],
  imports: [
    AnnouncekitModule
  ],
  exports: [
    AnnounceKitComponent,
  ],
})
export class AnnounceKitModule { }
