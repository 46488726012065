import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { ColorCodeModule } from '../color-code';
import { TagComponent } from './tag/tag.component';
import { TagCreateDialogComponent } from './tag-create-dialog/tag-create-dialog.component';
import { TagEditDialogComponent } from './tag-edit-dialog/tag-edit-dialog.component';
import { TagsComponent } from './tags/tags.component';
import { TagsDialogComponent } from './tags-dialog/tags-dialog.component';
import { TagsDropdownComponent } from './tags-dropdown/tags-dropdown.component';
import { TagSettingsDialogComponent } from './tag-settings-dialog/tag-settings-dialog.component';
import { TagsFilterComponent } from './tags-filter/tags-filter.component';
import { TagsFilterDialogComponent } from './tags-filter-dialog/tags-filter-dialog.component';
import { TagsModalActionService } from './tags-modal-action.service';
import { TagsService } from './tags.service';
import { TranslateModule } from '../translate';

@NgModule({
  declarations: [
    TagComponent,
    TagsComponent,
    TagsDropdownComponent,
    TagsDialogComponent,
    TagsFilterComponent,
    TagCreateDialogComponent,
    TagsFilterDialogComponent,
    TagSettingsDialogComponent,
    TagEditDialogComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    TooltipDirective,
    TranslateModule.forChild(),
    ColorCodeModule
  ],
  exports: [
    TagComponent,
    TagsComponent,
    TagsDropdownComponent,
    TagsFilterComponent
  ],
  providers: [
    TagsService,
    TagsModalActionService
  ]
})
export class TagsModule {
}
