import { createAction, props } from '@ngrx/store';

import {
  AsyncOperationState,
  BudgetItem,
  BudgetItemPositionType,
  ChangedBudgetItems,
  QueueActiveState
} from '@kros-sk/ssw-shared-legacy';
import { MoveItemsPositions } from '@kros-sk/ssw-budget/shared/data-access/models';

import { MoveSectionActionModel } from '../models';

export const budgetKey = '[Budget]';

export const importConstructionStart = createAction(`${budgetKey} Import construction start`, props<{ accessKey: string }>());
export const importConstructionSuccess = createAction(`${budgetKey} Import construction success`);
export const importConstructionError = createAction(`${budgetKey} Import construction error`, props<{ error: any }>());

export const finishImportConstructionSuccess = createAction(`${budgetKey} Finish import construction success`);
export const finishImportConstructionError = createAction(`${budgetKey} Finish import construction error`);

export const moveBudgetSectionStart = createAction(`${budgetKey} Move budget section start`,
  props<{
    currentItem: BudgetItem,
    positionType: BudgetItemPositionType,
    sectionId: number,
    isUndoRedoOperation?: boolean,
    isUndo?: boolean
  }>());
export const moveBudgetSectionSuccess = createAction(
  `${budgetKey} Move budget section success`,
  props<{
    changedBudgetItems: ChangedBudgetItems,
    sourceDataPositions: MoveItemsPositions<BudgetItem>,
    storedAction: MoveSectionActionModel,
    isUndoRedoOperation?: boolean,
    isUndo: boolean
  }>());
export const moveBudgetSectionError = createAction(`${budgetKey} Move budget section error`, props<{ error: any }>());

export const moveBudgetSectionLevelUp = createAction(`${budgetKey} Move budget section level up start`);

export const moveBudgetSectionLevelDown = createAction(`${budgetKey} Move budget section level down start`,
  props<{ itemBefore: BudgetItem }>());

export const createCustomBudgetSectionStart = createAction(
  `${budgetKey} Create custom budget section start`,
  props<{ itemBefore: BudgetItem, item: BudgetItem }>());
export const createCustomBudgetSectionSuccess = createAction(
  `${budgetKey} Create custom budget section success`,
  props<{ changedBudgetItems: ChangedBudgetItems, needClearStack?: boolean }>());
export const createCustomBudgetSectionError = createAction(`${budgetKey} Create custom budget section error`, props<{ error: any }>());

export const setBudgetItemParentToGrandParent = createAction(
  `${budgetKey} Set parent of budget item to its grandparent`,
  props<{ item: BudgetItem, childrenToMove?: string[] }>()
);

export const setBudgetItemParent = createAction(
  `${budgetKey} Set parent of budget item`,
  props<{ item: BudgetItem, newParentId: string, childrenToMove?: string[] }>()
);

export const getBudgetImportActiveStateStart = createAction(`${budgetKey} Get budget import active state start`,
  props<{ projectId: number }>());
export const getBudgetImportActiveStateSuccess = createAction(`${budgetKey} Get budget import active state success`,
  props<{ state: QueueActiveState }>());
export const getBudgetImportActiveStateError = createAction(`${budgetKey} Get budget import active state error`, props<{ error: any }>());

export const updateBudgetImportState = createAction(`${budgetKey} Update budget import state`,
  props<{ projectId: number, state: AsyncOperationState }>());

export const uploadFileStart = createAction(`${budgetKey} Upload file start`, props<{ file: File }>());
export const uploadFileSuccess = createAction(`${budgetKey} Upload file success`, props<{ accessKey: string }>());
export const uploadFileError = createAction(`${budgetKey} Upload file error`, props<{ error: any }>());
