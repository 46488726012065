import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { AvatarsModule } from '../avatars';
import { BootstrapModule } from '../bootstrap';
import { CommentComponent } from './comment/comment.component';
import { CommentsComponent } from './comments/comments.component';
import { DirectivesModule } from '../directives';
import { TimelineModule } from '../timeline/timeline.module';
import { TranslateModule } from '../translate/translate.module';

@NgModule({
  imports: [
    CommonModule,
    BootstrapModule,
    TranslateModule.forChild(),
    AvatarsModule,
    DirectivesModule,
    FormsModule,
    TimelineModule,
    TooltipDirective
  ],
  declarations: [
    CommentComponent,
    CommentsComponent
  ],
  exports: [
    CommentComponent,
    CommentsComponent
  ],
})
export class CommentsModule { }
