import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { KrosModalService } from '@kros-sk/components';
import { QuestionModalComponent } from '@kros-sk/ssw-shared-legacy';
import { TranslateService } from '@kros-sk/ssw-shared-legacy';

import { BuildingProgressPanelEnum } from '../models/building-progress-panel.enum';

@Injectable()
export class EditingPanelHelper {
  isEditing: boolean;

  private panelType: BuildingProgressPanelEnum;

  constructor(
    private krosModalService: KrosModalService,
    private translateService: TranslateService
  ) { }

  initPanelType(panelType: BuildingProgressPanelEnum): void {
    this.panelType = panelType;
  }

  canLeavePanel(): Observable<boolean> {
    if (this.isEditing) {
      return this.krosModalService.openCentered(
        QuestionModalComponent,
        {
          warning: this.translateService.translate(this.panelType === BuildingProgressPanelEnum.Details
            ? 'BUILDING_PROGRESS.DETAILS.UNSAVED_CHANGES'
            : 'NOTES.UNSAVED_CHANGES'),
          confirmButton: this.translateService.translate('COMMON.DISCARD_CHANGES'),
          cancelButton: this.translateService.translate('COMMON.BACK')
        },
        {
          closeOnBackdropClick: false,
          fullscreenOnMobile: false,
          showMobileArrowBack: false
        },
        NaN,
        NaN,
        undefined,
        'no-min-width',
      ).afterClosed$.pipe(
        switchMap((result: any) => {
          if (result.type === 'submit') {
            this.isEditing = false;
          }
          return of(result.type === 'submit');
        })
      );
    } else {
      return of(true);
    }
  }
}
