import { Component, HostListener, Input } from '@angular/core';

import { ItemSearchFailed } from '@kros-sk/models';

@Component({
  selector: 'kros-search-error-handler',
  templateUrl: './search-error-handler.component.html',
  styleUrls: ['./search-error-handler.component.scss']
})
export class SearchErrorHandlerComponent {
  @Input() errorItem: ItemSearchFailed;

  constructor() { }

  get isError(): boolean {
    return this.errorItem.hasFailed;
  }

  @HostListener('click', ['$event'])
  onClick(e: MouseEvent): void {
    if (this.isError) {
      this.errorClick(e);
    }
  }

  errorClick(event: MouseEvent): void {
    event.stopPropagation();
  }
}
