export enum ChangeSheetColumn {
  ItemType = 'itemType',
  ItemNumber = 'itemNumber',
  Code = 'code',
  Description = 'description',
  MeasureUnit = 'measureUnit',
  Amount = 'amount',
  UnitPrice = 'unitPrice',
  TotalPrice = 'totalPrice',
  Relation = 'relation',
  Occurence = 'occurence',
}
