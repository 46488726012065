<div class="kros-modal-header">
  <h5 class="modal-title">
    {{ 'BUILDING_PROGRESS.COMPLETE.COMPLETION' | translate}}&nbsp;&nbsp;&nbsp;{{periodDateText}}
  </h5>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onCloseClick()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<form
  [formGroup]="completeForm"
  (ngSubmit)="onSubmit()"
>
  <div class="kros-modal-body">
    <div class="form-container">
      <div class="form-group checkbox">
        <input
          id="setZeroRestToOverdrawnItems"
          type="checkbox"
          formControlName="setZeroRestToOverdrawnItems"
        >
        <label for="setZeroRestToOverdrawnItems">
          {{'BUILDING_PROGRESS.COMPLETE.SET_ZERO_REST_TO_OVERDRAWN_ITEMS' | translate}}
        </label>
      </div>
    </div>
  </div>

  <div class="kros-modal-footer">
    <button
      class="btn btn-primary"
      type="submit"
      data-test="confirmation"
    >{{ buttonText | translate }}</button>
  </div>
</form>
