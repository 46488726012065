import { Injectable } from '@angular/core';

import { Cell, ColDef } from '../data-table-config.model';

@Injectable()
export class DataTableKeyboardHandlingService {

  handleTableMovement(focusedCell: Cell, colDefs: ColDef[], itemCount: number, key: string): Cell {
    switch (key) {
      case 'arrowup':
        if (focusedCell.rowIndex > 0) {
          focusedCell.rowIndex--;
        }
        break;
      case 'arrowdown':
      case 'enter':
        if (focusedCell.rowIndex < itemCount - 1) {
          focusedCell.rowIndex++;
        }
        break;
      case 'arrowleft':
        this.moveFocusedCellLeft(focusedCell, colDefs);
        break;
      case 'arrowright':
        this.moveFocusedCellRight(focusedCell, colDefs);
        break;
    }

    return focusedCell;
  }

  private moveFocusedCellLeft(focusedCell: Cell, colDefs: ColDef[]): void {
    const colIndex = colDefs.findIndex(x => x.id === focusedCell.colId);
    if (this.existsColumn(colIndex)) {
      if (colIndex > 0) {
        this.setNewColumnIdMovingLeft(colIndex - 1, focusedCell, colDefs);
      }
    } else {
      const colGroup = colDefs.find(
        x => !!x.colsInGroup && x.colsInGroup.findIndex(y => y.id === focusedCell.colId) !== -1);
      const indexInColGroup = colGroup.colsInGroup.findIndex(x => x.id === focusedCell.colId);
      if (indexInColGroup === 0) {
        const colGroupIndex = colDefs.findIndex(x => x.id === colGroup.id);
        if (colGroupIndex > 0) {
          this.setNewColumnIdMovingLeft(colGroupIndex - 1, focusedCell, colDefs);
        }
      } else {
        focusedCell.colId = colGroup.colsInGroup[indexInColGroup - 1].id;
        focusedCell.params = colGroup.colsInGroup[indexInColGroup - 1].params;
      }
    }
  }

  private moveFocusedCellRight(focusedCell: Cell, colDefs: ColDef[]): void {
    const colIndex = colDefs.findIndex(x => x.id === focusedCell.colId);
    if (this.existsColumn(colIndex)) {
      if (colIndex < colDefs.length - 1) {
        this.setNewColumnIdMovingRight(colIndex + 1, focusedCell, colDefs);
      }
    } else {
      const colGroup = colDefs.find(
        x => !!x.colsInGroup && x.colsInGroup.findIndex(y => y.id === focusedCell.colId) !== -1);
      const indexInColGroup = colGroup.colsInGroup.findIndex(x => x.id === focusedCell.colId);
      if (indexInColGroup === colGroup.colsInGroup.length - 1) {
        const colGroupIndex = colDefs.findIndex(x => x.id === colGroup.id);
        if (colGroupIndex < colDefs.length - 1) {
          this.setNewColumnIdMovingRight(colGroupIndex + 1, focusedCell, colDefs);
        }
      } else {
        focusedCell.colId = colGroup.colsInGroup[indexInColGroup + 1].id;
        focusedCell.params = colGroup.colsInGroup[indexInColGroup + 1].params;
      }
    }
  }

  private setNewColumnIdMovingRight(newColumnId: number, focusedCell: Cell, colDefs: ColDef[]): void {
    const newColumn = colDefs[newColumnId];
    this.setNewColumnId(newColumn, 0, focusedCell);
  }

  private setNewColumnIdMovingLeft(newColumnId: number, focusedCell: Cell, colDefs: ColDef[]): void {
    const newColumn = colDefs[newColumnId];
    this.setNewColumnId(newColumn, newColumn.colsInGroup?.length - 1, focusedCell);
  }

  private setNewColumnId(column: ColDef, colGroupIndex: number, focusedCell: Cell): void {
    const newColumn = !!column.colsInGroup && column.colsInGroup.length > 0 ?
      column.colsInGroup[colGroupIndex] : column;
    focusedCell.colId = newColumn.id;
    focusedCell.params = newColumn.params;
  }

  private existsColumn(index: number): boolean {
    return index !== -1;
  }
}
