<div class="kros-modal-header">
  <h4 class="kros-modal-title">{{'BUILDING_PROGRESS.EXPORT_REMAINING_BUDGET' | translate }}</h4>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onCloseClick()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div class="kros-modal-body">
  <form
    [formGroup]="exportForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="form-container">
      <div class="form-group checkbox">
        <input
          id="exportZeroRemainingBudgetItems"
          class="form-control"
          type="checkbox"
          formControlName="exportZeroRemainingBudgetItems"
          data-test="export-zero-balance-items-input"
        >
        <label for="exportZeroRemainingBudgetItems">{{ "BUILDING_PROGRESS.EXPORT_ZERO_REMAINING_BUDGET_ITEMS" |
          translate }}</label>
      </div>

      <div class="form-group checkbox">
        <input
          id="isNotSingleSheetExport"
          class="form-control"
          type="checkbox"
          formControlName="isNotSingleSheetExport"
          data-test="export-objects-on-separate-sheets-input"
        >
        <label for="isNotSingleSheetExport">{{ "BUILDING_PROGRESS.EXPORT_PER_OBJECTS" | translate }}</label>
      </div>
    </div>
  </form>

  <div class="header-data-edit">
    <span>{{'PROJECTS.DETAIL.HEADER_DATA_SETTINGS' | translate}}</span>

    <i
      class="material-icons no-selection ga-pv-export-header-data-open"
      data-test="edit-header-data"
      krosTooltip="{{'PROJECTS.DETAIL.EDIT_HEADER_DATA_SETTINGS' | translate}}"
      (click)="onHeaderDataEdit()"
    >edit</i>
  </div>
</div>

<div class="kros-modal-footer">
  <button
    class="btn btn-primary btn-submit"
    [disabled]="!exportForm.valid"
    id="ga-export"
    data-test="draw-sheet-export-button"
    type="button"
    (click)="onSubmit()"
  >{{'BUILDING_PROGRESS.EXPORT_ACTION' | translate}}</button>
</div>
