<div class="timeline-wrapper">
  <container-element [ngSwitch]="type">
    <ng-container *ngSwitchCase="timelineType.OneLine">
      <ng-container *ngTemplateOutlet="oneLine"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="timelineType.TwoLine">
      <ng-container *ngTemplateOutlet="twoLine"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="timelineType.ThreeLine">
      <ng-container *ngTemplateOutlet="threeLine"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="timelineType.FourLine">
      <ng-container *ngTemplateOutlet="fourLine"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="timelineType.Comments">
      <ng-container *ngTemplateOutlet="comments"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="timelineType.Table">
      <ng-container *ngTemplateOutlet="twoLine"></ng-container>
      <ng-container *ngTemplateOutlet="twoLine"></ng-container>
      <ng-container *ngTemplateOutlet="twoLine"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="timelineType.NLines">
      <ng-container *ngTemplateOutlet="nLines"></ng-container>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="threeLine"></ng-container>
    </ng-container>
  </container-element>
</div>

<ng-template #oneLine>
  <div class="timeline-item sm">
    <div class="animated-background sm"></div>
  </div>
  <div class="timeline-item-divider"></div>
</ng-template>

<ng-template #twoLine>
  <div class="timeline-item sm">
    <div class="animated-background sm"></div>
  </div>
  <div class="timeline-item-divider"></div>
  <div class="timeline-item sm">
    <div class="animated-background sm"></div>
  </div>
  <div class="timeline-item-divider"></div>
</ng-template>

<ng-template #threeLine>
  <div class="timeline-item">
    <div class="animated-background">
      <div class="background-masker header-top"></div>
      <div class="background-masker subheader-bottom"></div>
      <div class="background-masker content-top"></div>
      <div class="background-masker content-first-end"></div>
      <div class="background-masker content-second-line"></div>
      <div class="background-masker content-second-end"></div>
      <div class="background-masker content-third-end"></div>
    </div>
  </div>
</ng-template>

<ng-template #fourLine>
  <div class="timeline-item">
    <div class="animated-background">
      <div class="background-masker header-line"></div>
      <div class="background-masker content-first-line"></div>
      <div class="background-masker content-first-left"></div>
      <div class="background-masker content-second-line"></div>
      <div class="background-masker content-second-left"></div>
      <div class="background-masker content-third-line"></div>
      <div class="background-masker content-third-left"></div>
      <div class="background-masker content-fourth-line"></div>
      <div class="background-masker content-fourth-left"></div>
    </div>
  </div>
</ng-template>

<ng-template #comments>
  <div class="timeline-item">
    <div class="animated-background">
      <div class="background-masker header-top"></div>
      <div class="background-masker subheader-bottom"></div>
      <div class="background-masker content-top"></div>
      <div class="background-masker content-first-end"></div>
      <div class="background-masker content-second-line"></div>
      <div class="background-masker content-second-end"></div>
      <div class="background-masker content-third-end"></div>
    </div>
  </div>

  <div class="timeline-item sm">
    <div class="animated-background sm"></div>
  </div>
  <div class="timeline-item-divider"></div>
  <div class="timeline-item sm">
    <div class="animated-background sm"></div>
  </div>
</ng-template>

<ng-template #nLines>
  <div
    *ngFor="let value of linesArray"
    class="timeline-item sm"
  >
    <div class="animated-background sm"></div>
  </div>
  <div class="timeline-item-divider"></div>
</ng-template>
