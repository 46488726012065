<ng-container *ngIf="selectors.uploadingData$ | async as stavba">
  <p
    data-test="upload-building-strip"
    [innerHTML]="(isInDocuments ?
            ('DOCUMENTS.UPLOAD_BUILDING.SELECT_FOLDER' | translate | formatString: stavba.originalName) :
            ('DOCUMENTS.UPLOAD_BUILDING.SELECT_PROJECT' | translate | formatString: stavba.originalName )
            ) | safeHtml"
  >
  </p>

  <button
    type="button"
    class="btn btn-link btn-close"
    krosTooltip="{{ 'COMMON.ZRUSIT' | translate }}"
    (click)="close(stavba.originalName)"
  >
    <i
      class="material-icons"
      data-test="upload-building-strip-close-icon"
    >close</i>
  </button>
</ng-container>
