import { Component, Input, OnInit } from '@angular/core';

import { filter } from 'rxjs/operators';

import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';
import { View } from '@kros-sk/ssw-shared-legacy';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

import { BuildingProgressSelectorsService } from '../../../../store/building-progress';
import { BuildingProgressViewsService, ViewGroup, ViewType } from '../../../../building-progress/services/building-progress-views.service';
import { ColorCodesService } from '../../../../building-progress/services/color-codes.service';
import { getAppInsightsPrefix } from '../../../../building-progress/shared/building-progress-utils';

@Component({
  selector: 'app-view-picker',
  templateUrl: './view-picker.component.html',
  styleUrls: ['./view-picker.component.scss']
})
export class ViewPickerComponent extends UnsubscribeComponent implements OnInit {
  @Input() isContractor = false;

  views: View[] = [];

  private selectedPeriodIds: number[] = [];

  get isViewActive(): boolean {
    return this.viewsService.currentView.viewType !== ViewType.standard;
  }

  constructor(
    public viewsService: BuildingProgressViewsService,
    private appInsightsService: AppInsightsBaseService,
    private buildingProgressSelectorService: BuildingProgressSelectorsService,
    private colorService: ColorCodesService
  ) {
    super();
  }

  ngOnInit(): void {
    this.views = this.viewsService.getViews();
    this.subs.sink = this.buildingProgressSelectorService.selectedView$.subscribe(selectedView => {
      if (selectedView) {
        this.viewsService.currentView = this.views.find(v => v.viewType === selectedView.viewType);
      }
    });
    this.subs.sink = this.buildingProgressSelectorService.periods$.pipe(filter(p => !!p && p.length > 0))
      .subscribe(p => {
        this.selectedPeriodIds = p.filter(p => p.isMarkedInFilter).map(p => p.id);

        if (this.viewsService.currentView.viewType === ViewType.drawnSelected ||
          this.viewsService.currentView.viewType === ViewType.undrawnSelected ||
          this.viewsService.currentView.viewType === ViewType.withComment) {
          this.changeView(this.viewsService.currentView, this.selectedPeriodIds);
        }
      });
  }

  selectView(view: View): void {
    if (view.viewType === ViewType.colorCodes) {
      this.colorService.openColorCodesDialogView((colorCodes: string[]) => {
        this.changeView(view, colorCodes);
      });
    } else {
      if (!this.viewsService.currentView || view.viewType !== this.viewsService.currentView.viewType) {
        if (view.viewType === ViewType.drawnSelected ||
          view.viewType === ViewType.undrawnSelected ||
          view.viewType === ViewType.withComment) {
          this.changeView(view, this.selectedPeriodIds);
        } else {
          this.changeView(view);
        }
      }
    }
  }

  getAllPeriodsTypeViews(): View[] {
    return this.views.filter(v => v.group === ViewGroup.allPeriods);
  }

  getSelectedPeriodsTypeViews(): View[] {
    return this.views.filter(v => v.group === ViewGroup.selectedPeriods);
  }

  getRecapsTypeViews(): View[] {
    return this.views.filter(v => v.group === ViewGroup.recaps);
  }

  private changeView(view: View, data?: any): void {
    this.viewsService.currentView = view;
    this.viewsService.changeView(view.viewType, data);
    this.appInsightsService.trackEvent(`${getAppInsightsPrefix(this.isContractor)}views`, { viewType: view.viewType.toString() });
  }
}
