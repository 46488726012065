import { Component, EventEmitter, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { areEmailsEqual } from '@kros-sk/ssw-cdk';

import { ItemSharing } from '../../../models';
import { PermissionChangedDto } from '../../models/permission-changed-dto.model';
import { SharingPermissionValues } from '../../models/sharing-permission.interface';
import { UserSharingListItemBaseComponent } from '../user-sharing-list-item-base/user-sharing-list-item-base.component';

@Component({
  selector: 'kros-user-sharing-list-item',
  templateUrl: './user-sharing-list-item.component.html',
  styleUrls: ['./user-sharing-list-item.component.scss']
})
export class UserSharingListItemComponent extends UserSharingListItemBaseComponent {

  @Input() itemSharing: ItemSharing;

  @Output() unconfirmedPermissionDeleted = new EventEmitter<ItemSharing>();

  constructor() {
    super();

    this.sharingHelperService.resetState$
      .pipe(takeUntilDestroyed())
      .subscribe(email => {
        if (areEmailsEqual(email, this.itemSharing.email)) {
          this.cancelChanges();
        }
      });
  }

  get readonlyRoleName(): string {
    return this.permissionListService.getReadOnlyRoleName(
      this.sharingMode, this.itemSharing.permissionType, this.itemSharing.additionalPermissionType
    );
  }

  get readonlyRoleTooltip(): string {
    return this.permissionListService.getReadOnlyRoleTooltip(
      this.sharingMode, this.itemSharing.permissionType, this.itemSharing.additionalPermissionType
    );
  }

  getDefaultDropdownValues(): SharingPermissionValues {
    return { permission: this.itemSharing.permissionType, additionalPermission: this.itemSharing.additionalPermissionType };
  }

  deletePermission(): void {
    if (this.unconfirmedSharing) {
      this.unconfirmedPermissionDeleted.emit(this.itemSharing);
    } else {
      const changedPermission = this.createPermissionChangedDto(
        { permission: this.itemSharing.permissionType, additionalPermission: this.itemSharing.additionalPermissionType });

      this.deletedPermission.emit(changedPermission);
    }

    this.unsaved = true;
    this.deleted = true;
  }

  onPermissionChanged(permission: SharingPermissionValues): void {
    if (this.unconfirmedSharing) {
      this.itemSharing.permissionType = permission.permission;
      this.itemSharing.additionalPermissionType = permission.additionalPermission;
    } else {
      const changedPermission = this.createPermissionChangedDto(permission);
      this.itemSharing.permissionType = permission.permission;
      this.itemSharing.additionalPermissionType = permission.additionalPermission;
      this.unsaved = true;

      this.changedPermission.emit(changedPermission);
    }
  }

  private createPermissionChangedDto(permission: SharingPermissionValues): PermissionChangedDto {
    return {
      oldPermission: this.itemSharing.permissionType,
      newPermission: permission.permission,
      oldAdditionalPermission: this.itemSharing.additionalPermissionType,
      newAdditionalPermission: permission.additionalPermission,
      email: this.itemSharing.email,
      isRegisteredUser: this.itemSharing.isRegisteredUser
    };
  }
}
