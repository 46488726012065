import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

/**
 * Interface for the KrosAppInsightsService.
 * This service provides methods for interacting with the Application Insights service.
 */

@Injectable()
export abstract class AppInsightsBaseService {
  /**
   * Disable app insights logging on demand.
   */
  abstract disabled: boolean;

  /**
   * An observable that emits a boolean value indicating whether the user context is defined.
   */
  abstract isUserContextDefined$: Observable<boolean>;

  /**
   * Track custom event.
   * @param name custom event name
   * @param properties custom properties
   */
  abstract trackEvent(name: string, properties?: { [key: string]: any; }): void;

  /**
   * Track exception event.
   * @param error error instance to log
   * @param handler handler name
   * @param properties custom properties
   */
  abstract trackException(error: Error, handler: string, properties?: { [key: string]: any; }): void;

  /**
   * Set the authenticated user id and the account id.
   * Used for identifying a specific signed-in user.
   * Parameters must not contain whitespace or ,;=|
   *
   * The method will only set the `authenticatedUserId` and `accountId` in the current page view.
   * To set them for the whole session, you should set `storeInCookie = true`
   * @param authenticatedUserId an id that uniquely identifies a user of your app
   * @param [accountId] an optional account id, if your app groups users into accounts
   * @param [storeInCookie=false] true if you want to set `authenticatedUserId` and `accountId` for the whole session
   */
  abstract setAuthenticatedUserContext(authenticatedUserId: string, accountId?: string, storeInCookie?: boolean): void;

  /**
   * Clears the authenticated user id and account id. The associated cookie is cleared, if present.
   */
  abstract clearAuthenticatedUserContext(): void;

  /**
   * Start tracking event. you need to call stopTrackingEvent to complete tracking and save duration of event.
   * @param name event name.
   */
  abstract startTrackingEvent(name: string): void;

  /**
   * Stop tracking event. Event name needs to be same as startTrackingEvent.
   * @param name event name.
   */
  abstract stopTrackingEvent(name: string): void;
}

