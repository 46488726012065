<div
  class="sharing-list-card"
  data-test="sharing-list-item"
>
  <div
    class="card-content"
    [class.unsaved]="unsaved"
  >
    <div
      class="header"
      (click)="toggleExpand()"
    >
      <div class="left-div">
        <div class="toggle-expand">
          <i class="material-icons">{{expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}</i>
        </div>

        <div class="name-info">
          <kros-titan-avatar
            class="avatar-s"
            [email]="itemSharing.email"
            [icon]="itemSharing.isRegisteredUser ? '' : 'person_add_disabled'"
          ></kros-titan-avatar>
          <div class="user">
            <span
              class="user-mail"
              [class.deleted-right]="deleted"
            >
              {{ itemSharing.email }}
            </span>
            <span
              *ngIf="!unsaved && !itemSharing.isRegisteredUser"
              class="additional-info unregistered-text"
            >
              {{ 'SHARING.UNACTIVATED_USER' | translate }}
            </span>
            <span
              *ngIf="unsaved"
              class="additional-info unsaved-text"
              data-test="sharing-list-item-unsaved"
            >
              {{ 'SHARING.NOT_SAVED' | translate }}
            </span>
          </div>
        </div>
      </div>
      <div class="right-div">
        <ng-container *ngIf="!readonly">
          <i
            class="material-icons delete-button no-selection"
            (click)="$event.stopPropagation(); deletePermission()"
            data-test="delete-sharing-button"
          >clear</i>
        </ng-container>
      </div>
    </div>

    <div
      [@expandCollapseAnimation]="this.expanded"
      *ngIf="this.expanded"
      class="expanded-part"
    >
      <ng-container *ngIf="sharingMode === newSharingComboMode.EditUserProject">
        <div *ngFor="let permissionItem of permissionItems; let i = index">
          <div
            class="permission-item"
            data-test="permission-item"
          >
            <div class="permission-name">{{ permissionItem.name }}</div>

            <div>
              <ng-container *ngIf="!isReadOnly(permissionItem); else readonlyRole">
                <div class="permission-item-buttons">
                  <kros-user-sharing-dropdown
                    [sharingMode]="getSharingMode(permissionItem)"
                    [defaultValues]="getDefaultDropdownValues(permissionItem)"
                    [disabled]="deleted || !hasLicense(permissionItem)"
                    [compactMode]="true"
                    menuDataTestValue="{{ i }}"
                    [permissionType]="getDefaultDropdownValues(permissionItem).permission"
                    [additionalPermissionType]="getDefaultDropdownValues(permissionItem).additionalPermission"
                    (permissionChanged)="onPermissionChanged($event, permissionItem)"
                    krosTooltip="{{getLicenseTooltip(permissionItem)}}"
                  ></kros-user-sharing-dropdown>
                </div>
              </ng-container>

              <ng-template #readonlyRole>
                <span
                  class="readonly-role-name"
                  krosTooltip="{{getReadonlyRoleTooltip(permissionItem)}}"
                >{{ getReadonlyRoleName(permissionItem) }}</span>
              </ng-template>
            </div>
          </div>

          <div
            *ngIf="permissionItem.canShowWarning && permissionItem.warningText"
            class="warning-text"
          >
            <span>{{ permissionItem.warningText }}</span>
          </div>
        </div>
      </ng-container>

      <div
        *ngIf="unconfirmedSharing"
        class="message-div"
      >
        <textarea
          class="form-control message"
          placeholder="{{ 'SHARING.USER_MESSAGE_PLACEHOLDER' | translate }}"
          rows="3"
          [(ngModel)]="itemSharing.userMessage"
        ></textarea>
      </div>
    </div>
  </div>
</div>
