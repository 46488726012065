import { Injectable } from '@angular/core';

import { Cell, InfoModalComponent, isSummaryItem, TranslateService } from '@kros-sk/ssw-shared-legacy';
import { KrosModalService } from '@kros-sk/components';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

import { BuildingProgressActionAccessService } from './building-progress-action-access.service';
import { BuildingProgressDispatchersService, BuildingProgressSelectorsService } from '../../store/building-progress';
import { BuildingProgressItem, BuildingProgressModel } from '../models/construction-data.model';
import { BuildingProgressPercentageCompleteModel } from '../models/building-progress-percentage-complete.model';
import { CompleteDialogComponent } from '../components/complete-dialog/complete-dialog.component';
import { getAppInsightsPrefix } from '../shared/building-progress-utils';
import { PermissionHelper } from '../helpers';

@Injectable()
export class BuildingProgressPercentageCompleteService {
  private selectedItemIds = new Set<number>();

  constructor(
    private appInsightsService: AppInsightsBaseService,
    private buildingProgressDispatchersService: BuildingProgressDispatchersService,
    private buildingProgressSelectorsService: BuildingProgressSelectorsService,
    private buildingProgressActionAccessService: BuildingProgressActionAccessService,
    private permissionHelper: PermissionHelper,
    private translateService: TranslateService,
    private krosModalService: KrosModalService
  ) {
    this.buildingProgressSelectorsService.selectedItemIds$.subscribe(
      itemIds => {
        if (itemIds && itemIds instanceof Set) {
          this.selectedItemIds = itemIds;
        }
      }
    );
  }

  canComplete(constructionData: BuildingProgressModel): boolean {
    return constructionData?.items.length > 0 && constructionData.periods.some(p => p.id);
  }

  completePercentage(
    constructionData: BuildingProgressModel,
    projectId: number,
    focusedCell: Cell,
    showSuccessToast?: boolean
  ): void {
    if (this.canComplete(constructionData)) {
      const item = this.getFocusedItemOrRootItem(constructionData.items, focusedCell);

      if (item) {
        this.openCompleteDialog(
          constructionData,
          item,
          projectId,
          this.areItemsSelected(),
          showSuccessToast
        );
      }
    }
  }

  private completePercentageIfCan(
    percentageCompleteModel: BuildingProgressPercentageCompleteModel,
    constructionData: BuildingProgressModel,
    containsSectionOrObject: boolean,
    showSuccessToast?: boolean
  ): void {
    if ((percentageCompleteModel.selectedBudgetItemIds.length === 0 && !containsSectionOrObject) ||
      (this.buildingProgressActionAccessService.checkForAccessToBulkOperations())) {
      this.buildingProgressDispatchersService.completePercentage(percentageCompleteModel, constructionData, showSuccessToast);

      if (percentageCompleteModel.selectedBudgetItemIds.length === 0) {
        this.appInsightsService.trackEvent(getAppInsightsPrefix(this.permissionHelper.isContractor) + 'percentage-complete');
      } else {
        this.appInsightsService.trackEvent(
          getAppInsightsPrefix(this.permissionHelper.isContractor) + 'selection-percentage-complete'
        );
      }

      if (percentageCompleteModel.selectedBudgetItemIds.length) {
        this.buildingProgressDispatchersService.clearMultiSelectItems();
      }
    }
  }

  private itemIsCompleted(item: BuildingProgressItem): boolean {
    if (item.restAmount === 0 && item.restTotalPrice === 0 && item.additionalAmount === 0 && item.additionalTotalPrice === 0) {
      this.krosModalService.openCentered(
        InfoModalComponent,
        {
          body: this.translateService.translate('BUILDING_PROGRESS.COMPLETE.ITEM_HAS_ZERO_BALANCE'),
        },
        {
          closeOnBackdropClick: false,
          fullscreenOnMobile: false,
          showMobileArrowBack: false
        }
      );

      return true;
    }

    return false;
  }

  openCompleteDialog(
    constructionData: BuildingProgressModel,
    actualItem: BuildingProgressItem,
    projectId: number,
    areItemsSelected: boolean,
    showSuccessToast?: boolean
  ): void {
    const modalRef = this.krosModalService.openCentered(
      CompleteDialogComponent,
      {
        areItemsSelected,
        itemType: actualItem.itemType,
        period: constructionData.periods[constructionData.periods.length - 1]
      },
      {
        closeOnBackdropClick: false
      }
    );

    modalRef.afterClosed$
      .subscribe(result => {
        if (result.type === 'submit') {
          const percentageCompleteModel = this.getCompletePercentageModel(projectId, !result.data.setZeroRestToOverdrawnItems);
          let containsSection = false;
          if (areItemsSelected) {
            percentageCompleteModel.selectedBudgetItemIds = Array.from(this.selectedItemIds.values());
            containsSection = this.isSelectedSectionOrObject(constructionData);
          } else {
            if (!this.itemIsCompleted(actualItem)) {
              percentageCompleteModel.actualBudgetItemId = actualItem.id;
              containsSection = isSummaryItem(actualItem);
            }
          }
          this.completePercentageIfCan(percentageCompleteModel, constructionData, containsSection, showSuccessToast);
        }
      });
  }

  private isSelectedSectionOrObject(constructionData: BuildingProgressModel): boolean {
    return constructionData.items.some(p => this.selectedItemIds.has(p.id) && isSummaryItem(p));
  }

  private getFocusedItemOrRootItem(items: BuildingProgressItem[], focusedCell: Cell): BuildingProgressItem {
    return focusedCell ? items[focusedCell.rowIndex] : this.getRootItem(items);
  }

  private getRootItem(items: BuildingProgressItem[]): BuildingProgressItem {
    return items.find(i => i.itemType === 'S');
  }

  private areItemsSelected(): boolean {
    return this.selectedItemIds && this.selectedItemIds.size > 0;
  }

  private getCompletePercentageModel(projectId: number, onlyIncompletedItems: boolean): BuildingProgressPercentageCompleteModel {
    return {
      projectId,
      actualBudgetItemId: undefined,
      selectedBudgetItemIds: [],
      onlyIncompletedItems
    };
  }
}
