export enum BudgetApprovalColumn {
  ItemType = 'itemType',
  Code = 'code',
  Description = 'description',
  TotalPrice = 'totalPrice',
  ApprovedChanges = 'approvedChanges',
  ApprovedNegativeTotalPrice = 'approvedNegativeTotalPrice',
  ApprovedPositiveTotalPrice = 'approvedPositiveTotalPrice',
  ApprovedTotalPrice = 'approvedTotalPrice',
  ApprovedPercentage = 'approvedPercentage',
  ApprovedAbsoluteChangeTotalPrice = 'approvedAbsoluteChangeTotalPrice',
  ApprovedAbsoluteChangePercentage = 'approvedAbsoluteChangePercentage',
  ApprovedActualTotalPrice = 'approvedActualTotalPrice',
  NotApprovedChanges = 'notApprovedChanges',
  NotApprovedNegativeTotalPrice = 'notApprovedNegativeTotalPrice',
  NotApprovedPositiveTotalPrice = 'notApprovedPositiveTotalPrice',
  NotApprovedTotalPrice = 'notApprovedTotalPrice',
  NotApprovedPercentage = 'notApprovedPercentage',
  NotApprovedAbsoluteChangeTotalPrice = 'notApprovedAbsoluteChangeTotalPrice',
  NotApprovedAbsoluteChangePercentage = 'notApprovedAbsoluteChangePercentage',
  NotApprovedActualTotalPrice = 'NotApprovedActualTotalPrice'
}
