import { Injectable } from '@angular/core';

import { ShepherdService } from 'angular-shepherd';
import { Subject } from 'rxjs';

import { KrosAppInsightsService } from '@kros-sk/core';

import { DeviceDetectorService, DeviceType } from '../services';

const finishedTourStoragePrefix = 'tour-finished-';

@Injectable()
/**
 * @deprecated Use KrosTourService for better alternative.
 */
export class KrosShepherdService {

  actualStep: string;
  tourClosed$ = new Subject<string>();

  get isActive(): boolean {
    return this.active;
  }

  get isSetTour(): boolean {
    return !!this.tourName;
  }

  get currentStepId(): string {
    return this.shepherdService.tourObject.getCurrentStep()?.id;
  }

  private isOpen: boolean;
  private preventDefinitiveClosure: boolean;
  private tourName: string;
  private isTourInitialized: boolean;
  private canShowTours: { [tourName: string]: boolean; } = {};
  private active = false;

  constructor(
    private shepherdService: ShepherdService,
    private appInsightsService: KrosAppInsightsService,
    private deviceDetectorService: DeviceDetectorService
  ) {
  }

  initTour(tourName: string, steps: any[], modal: boolean, userId: number): void {
    if (tourName !== this.tourName) {
      this.initTourBase(modal, userId);
      this.tourName = tourName;
      this.shepherdService.tourName = tourName;
      (this.shepherdService.defaultStepOptions as any).classes = 'tour-' + tourName;
      this.shepherdService.addSteps(steps);
    }
  }

  clearTour(tourName: string): void {
    if (tourName === this.tourName) {
      this.tourName = undefined;
      this.active = false;
      this.hide();
    }
  }

  hide(): void {
    if (this.isOpen && this.shepherdService.isActive) {
      this.isOpen = false;
      this.preventDefinitiveClosure = true;
      this.shepherdService.cancel();
      this.preventDefinitiveClosure = false;
    }
  }

  canShowTour(tourName: string, userId: number): boolean {
    if (this.deviceDetectorService.deviceType === DeviceType.Mobile || tourName !== this.tourName) {
      return false;
    }

    if (this.canShowTours[tourName] === undefined) {
      this.canShowTours[tourName] = !localStorage.getItem(this.getFinishedTourStorageKey(tourName, userId));
    }

    return this.canShowTours[tourName];
  }

  next(): void {
    if (this.isOpen && this.isActive && this.shepherdService.isActive) {
      this.shepherdService.next();
    }
  }

  complete(): void {
    this.shepherdService.complete();
  }

  show(modal?: boolean, stepId?: string): void {
    if (this.isOpen && this.shepherdService.tourObject.getCurrentStep()?.isOpen()) {
      this.shepherdService.hide();
    }

    this.active = true;
    this.isOpen = true;

    if (modal !== undefined) {
      this.shepherdService.modal = modal;
    }

    if (stepId) {
      this.shepherdService.show(stepId);
    } else {
      this.shepherdService.start();
    }
  }

  private initTourBase(modal: boolean, userId: number): void {
    if (!this.isTourInitialized) {
      this.isTourInitialized = true;
      this.shepherdService.modal = modal;
      this.shepherdService.confirmCancel = false;
      this.shepherdService.defaultStepOptions = {
        classes: '',
        scrollTo: false,
        cancelIcon: {
          enabled: true
        }
      };
      this.shepherdService.onTourFinish = (p): void => this.close(p, userId);
    }
  }

  private close(completeOrCancel: string, userId: number): void {
    if (!this.preventDefinitiveClosure) {
      this.appInsightsService.trackEvent('TOUR-' + completeOrCancel, { name: this.tourName, step: this.actualStep });
      localStorage.setItem(this.getFinishedTourStorageKey(this.tourName, userId), 'true');
      this.canShowTours[this.tourName] = false;
      this.active = false;
      this.isOpen = false;
      this.tourClosed$.next(this.actualStep);
    }
  }

  private getFinishedTourStorageKey(tourName: string, userId: number): string {
    return `${finishedTourStoragePrefix}${tourName}-${userId}`;
  }
}
