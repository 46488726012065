import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { KrosModalRef } from '@kros-sk/components';

import { ExportChangeSheetModel } from '../../models/budget-approval-export.model';

@Component({
  selector: 'app-export-change-sheet-settings',
  templateUrl: './export-change-sheet-settings.component.html',
  styleUrls: ['./export-change-sheet-settings.component.scss']
})
export class ExportChangeSheetSettingsComponent implements OnInit {
  changeSheetSettings: ExportChangeSheetModel;
  settingsForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalRef: KrosModalRef
  ) {
    this.changeSheetSettings = this.modalRef.data.changeSheetSettings;
  }

  ngOnInit(): void {
    this.initializeSettingsForm();
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  onSubmit(): void {
    this.copySettingsFromControl();
    this.modalRef.submit({ changeSheetSettings: this.changeSheetSettings });
  }

  private initializeSettingsForm(): void {
    this.settingsForm = this.formBuilder.group({
      isBoqIncluded: [this.changeSheetSettings.isBoqIncluded]
    });
  }

  private copySettingsFromControl(): void {
    const formControls = this.settingsForm.controls;
    this.changeSheetSettings.isBoqIncluded = formControls.isBoqIncluded.value;
  }
}
