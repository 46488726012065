<div
  class="kros-modal-body"
  data-test="modal-body"
>
  <div class="lines">
    <p
      [innerHTML]="data.body"
      data-test="modal-content"
    ></p>
  </div>
</div>

<div class="confirm-buttons">
  <button
    class="btn btn-primary btn-submit"
    id="ga-close"
    type="button"
    data-test="modal-close-button"
    (click)="onCloseClick()"
  >{{'COMMON.CLOSE' | translate}}</button>
</div>
