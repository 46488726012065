<div class="card">
  <cdk-table
    *ngIf="!(loading$ | async) else contentLoader"
    class="table"
    [dataSource]="dataSource"
    multiTemplateDataRows
  >
    <ng-container
      cdkColumnDef="code"
      sticky
    >
      <cdk-header-cell *cdkHeaderCellDef>
        <div>{{ 'BUILDING_PROGRESS.TABLE.ITEM_CODE' | translate }}</div>
      </cdk-header-cell>

      <cdk-cell *cdkCellDef="let row">
        <div
          class="column-code"
          [ngClass]="getCodeClass(row)"
          krosTooltip="{{ row.code }}"
        >{{row.code}}</div>
      </cdk-cell>
    </ng-container>

    <ng-container
      cdkColumnDef="description"
      sticky
    >
      <cdk-header-cell
        class="span-three"
        *cdkHeaderCellDef
      >
        <div>{{ 'BUILDING_PROGRESS.TABLE.DESCRIPTION' | translate }}</div>
      </cdk-header-cell>

      <cdk-cell
        class="span-three"
        *cdkCellDef="let row"
      >
        <div [ngClass]="getDescriptionClass(row)">{{row.description}}</div>
      </cdk-cell>
    </ng-container>

    <ng-container cdkColumnDef="completedPercentage">
      <cdk-header-cell *cdkHeaderCellDef>
        <div>%</div>
      </cdk-header-cell>

      <cdk-cell *cdkCellDef="let row">
        <kros-pie-chart [percentage]="row.completedPercentage"></kros-pie-chart>
      </cdk-cell>
    </ng-container>

    <ng-container cdkColumnDef="partlyCompletedTotalPrice">
      <cdk-header-cell
        class="align-right"
        *cdkHeaderCellDef
      >
        <div>{{ 'BUILDING_PROGRESS.TABLE.COMPLETED_UNTIL' | translate }} {{ dateBeforeLastPeriod |
          date:'dd.MM.yyyy' }}</div>
      </cdk-header-cell>

      <cdk-cell
        class="align-right"
        *cdkCellDef="let row"
      >
        <div>{{row.partlyCompletedTotalPrice | formatNumber: decimalPlaces.totalPrice: appLocation}}</div>
      </cdk-cell>
    </ng-container>

    <ng-container cdkColumnDef="totalPrice">
      <cdk-header-cell
        class="span-one align-right"
        *cdkHeaderCellDef
      >
        <div>{{ 'BUILDING_PROGRESS.TABLE.CONTRACT_PRICE' | translate }}</div>
      </cdk-header-cell>

      <cdk-cell
        class="span-one align-right"
        *cdkCellDef="let row"
      >
        <div>{{row.totalPrice | formatNumber: decimalPlaces.totalPrice: appLocation}}</div>
      </cdk-cell>
    </ng-container>

    <ng-container cdkColumnDef="completedTotalPrice">
      <cdk-header-cell
        class="span-one align-right"
        *cdkHeaderCellDef
      >
        <div>{{ 'BUILDING_PROGRESS.TABLE.UNTIL_NOW_BUILT' | translate }}</div>
      </cdk-header-cell>

      <cdk-cell
        class="span-one align-right"
        *cdkCellDef="let row"
      >
        <div>{{row.completedTotalPrice | formatNumber: decimalPlaces.totalPrice: appLocation}}</div>
      </cdk-cell>
    </ng-container>

    <ng-container cdkColumnDef="restTotalPrice">
      <cdk-header-cell
        class="span-one align-right"
        *cdkHeaderCellDef
      >
        <div>{{ 'BUILDING_PROGRESS.TABLE.REST' | translate }}</div>
      </cdk-header-cell>

      <cdk-cell
        class="span-one align-right"
        *cdkCellDef="let row"
      >
        <div>{{row.restTotalPrice | formatNumber: decimalPlaces.totalPrice: appLocation}}</div>
      </cdk-cell>
    </ng-container>

    <ng-container cdkColumnDef="additionalTotalPrice">
      <cdk-header-cell
        class="span-one align-right"
        *cdkHeaderCellDef
      >
        <div>{{ 'BUILDING_PROGRESS.DASHBOARD.ADDITIONAL' | translate }}</div>
      </cdk-header-cell>

      <cdk-cell
        class="span-one align-right"
        *cdkCellDef="let row"
      >
        <div>{{row.additionalTotalPrice | formatNumber: decimalPlaces.totalPrice: appLocation}}</div>
      </cdk-cell>
    </ng-container>

    <ng-container cdkColumnDef="notCompletedTotalPrice">
      <cdk-header-cell
        class="span-one align-right"
        *cdkHeaderCellDef
      >
        <div>{{ 'BUILDING_PROGRESS.DASHBOARD.NOT_COMPLETED' | translate }}</div>
      </cdk-header-cell>

      <cdk-cell
        class="span-one align-right"
        *cdkCellDef="let row"
      >
        <div>{{row.notCompletedTotalPrice | formatNumber: decimalPlaces.totalPrice: appLocation}}</div>
      </cdk-cell>
    </ng-container>

    <cdk-header-row *cdkHeaderRowDef="columnsToDisplay; sticky: true;"></cdk-header-row>

    <cdk-row
      *cdkRowDef="let row; columns: columnsToDisplay;"
      [class.font-weight-bold]="isSummaryItem(row)"
      [class.font-blue]="isConstructionOrBuildingObject(row)"
    ></cdk-row>
  </cdk-table>
</div>

<ng-template #contentLoader>
  <kros-timeline [type]="timelineType.Table"></kros-timeline>
</ng-template>
