import { Component, ContentChild, ElementRef, Renderer2, TemplateRef, ViewChild } from '@angular/core';

export enum Position {
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right'
}

@Component({
  selector: 'kros-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss']
})
export class SidePanelComponent {
  @ViewChild('topPanelContainer') topPanelContainer: ElementRef<HTMLElement>;
  @ViewChild('bottomPanelContainer') bottomPanelContainer: ElementRef<HTMLElement>;
  @ViewChild('leftPanelContainer') leftPanelContainer: ElementRef<HTMLElement>;
  @ViewChild('rightPanelContainer') rightPanelContainer: ElementRef<HTMLElement>;
  @ViewChild('contentContainer') contentContainer: ElementRef<HTMLElement>;

  @ContentChild('content') content: TemplateRef<ElementRef>;
  @ContentChild('topPanel') topPanel: TemplateRef<ElementRef>;
  @ContentChild('bottomPanel') bottomPanel: TemplateRef<ElementRef>;
  @ContentChild('leftPanel') leftPanel: TemplateRef<ElementRef>;
  @ContentChild('rightPanel') rightPanel: TemplateRef<ElementRef>;

  isTopPanelHidden = true;
  isBottomPanelHidden = true;
  isLeftPanelHidden = true;
  isRightPanelHidden = true;
  openedPanelCount = 0;

  constructor(
    private renderer: Renderer2
  ) { }

  showPanel(position: Position, size: number): void {
    switch (position) {
      case Position.Top:
        this.renderer.setStyle(this.topPanelContainer.nativeElement, 'max-height', size + 'px');
        if (this.isTopPanelHidden) {
          this.openedPanelCount++;
        }
        this.isTopPanelHidden = false;
        break;
      case Position.Bottom:
        this.renderer.setStyle(this.bottomPanelContainer.nativeElement, 'max-height', size + 'px');
        if (this.isBottomPanelHidden) {
          this.openedPanelCount++;
        }
        this.isBottomPanelHidden = false;
        break;
      case Position.Left:
        this.renderer.setStyle(this.leftPanelContainer.nativeElement, 'max-width', size + 'px');
        if (this.isLeftPanelHidden) {
          this.openedPanelCount++;
        }
        this.isLeftPanelHidden = false;
        break;
      case Position.Right:
        this.renderer.setStyle(this.rightPanelContainer.nativeElement, 'max-width', size + 'px');
        if (this.isRightPanelHidden) {
          this.openedPanelCount++;
        }
        this.isRightPanelHidden = false;
        break;
      default:
        break;
    }
  }

  hidePanel(position: Position): void {
    switch (position) {
      case Position.Top:
        this.renderer.setStyle(this.topPanelContainer.nativeElement, 'max-height', 0);
        this.isTopPanelHidden = true;
        break;
      case Position.Bottom:
        this.renderer.setStyle(this.bottomPanelContainer.nativeElement, 'max-height', 0);
        this.isBottomPanelHidden = true;
        break;
      case Position.Left:
        this.renderer.setStyle(this.leftPanelContainer.nativeElement, 'max-width', 0);
        this.isLeftPanelHidden = true;
        break;
      case Position.Right:
        this.renderer.setStyle(this.rightPanelContainer.nativeElement, 'max-width', 0);
        this.isRightPanelHidden = true;
        break;
      default:
        break;
    }

    this.openedPanelCount = Math.max(0, this.openedPanelCount - 1);
  }

  resizePanel(position: Position, size: number): void {
    switch (position) {
      case Position.Top:
        if (!this.isTopPanelHidden) {
          this.renderer.setStyle(this.topPanelContainer.nativeElement, 'max-height', size + 'px');
        }
        break;
      case Position.Bottom:
        if (!this.isBottomPanelHidden) {
          this.renderer.setStyle(this.bottomPanelContainer.nativeElement, 'max-height', size + 'px');
        }
        break;
      case Position.Left:
        if (!this.isLeftPanelHidden) {
          this.renderer.setStyle(this.leftPanelContainer.nativeElement, 'max-width', size + 'px');
        }
        break;
      case Position.Right:
        if (!this.isRightPanelHidden) {
          this.renderer.setStyle(this.rightPanelContainer.nativeElement, 'max-width', size + 'px');
        }
        break;
      default:
        break;
    }
  }
}
