import { Component, Input, ViewChild } from '@angular/core';

import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { TranslateService } from '@kros-sk/ssw-shared-legacy';

import { BuildingProgressPeriod } from '../../../../building-progress/models/building-progress-period.model';
import { PeriodDatePipe } from '../../../..//building-progress/modules/periods/pipes/period-date.pipe';

@Component({
  selector: 'app-period-range-dropdown',
  templateUrl: './period-range-dropdown.component.html',
  styleUrls: ['./period-range-dropdown.component.scss']
})
export class PeriodRangeDropdownComponent {
  @Input() periods: BuildingProgressPeriod[];

  @ViewChild('dropdown') dropdown: NgbDropdown;

  get filterCount(): number {
    return this.periods?.filter(p => this.isChecked(p.id)).length ?? 0;
  }

  get filterDescription(): string {
    const periods = this.periods?.filter(p => this.isChecked(p.id)) ?? [];

    if (periods.length === 1) {
      return `${this.periodDatePipe.transform(periods[0].dateFrom, periods[0].dateTo)}`;
    } else if (periods.length > 1) {
      const filterCount = periods.length;
      const selectedPeriodsText = this.translateService.translate('BUILDING_PROGRESS.EXPORT_SELECTED_PERIODS');
      const filterCountText = `(${filterCount})`;
      return `${selectedPeriodsText} ${filterCountText}`;
    }

    return this.translateService.translate('BUILDING_PROGRESS.EXPORT_PERIODS_NOT_SELECTED');
  }

  constructor(
    private translateService: TranslateService,
    private periodDatePipe: PeriodDatePipe
  ) { }

  addOrRemovePeriodToFilter(periodId: number): void {
    const period = this.periods?.find(p => p.id === periodId);
    period.isMarkedInFilter = !period.isMarkedInFilter;
  }

  isChecked(periodId: number): boolean {
    return this.periods?.find(p => p.id === periodId).isMarkedInFilter ?? false;
  }

  clearFilter(): void {
    this.periods.forEach((p: BuildingProgressPeriod) => p.isMarkedInFilter = false);
  }
}
