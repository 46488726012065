import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import * as buildingProgressSettingsActions from './building-progress-data-settings.actions';
import { BuildingProgressState } from './building-progress.state';
import { DataSettings } from '../../building-progress/models/data-settings.model';

@Injectable()
export class BuildingProgressDataSettingsDispatchersService {

  constructor(
    private store: Store<BuildingProgressState>
  ) { }

  loadDataSettings(): void {
    this.store.dispatch(
      buildingProgressSettingsActions.loadDataSettingsStart()
    );
  }

  saveDataSettings(dataSettings: DataSettings): void {
    this.store.dispatch(
      buildingProgressSettingsActions.saveDataSettings(dataSettings)
    );
  }
}
