import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, map, Observable, throwError } from 'rxjs';

import { DocumentShareResultType, DocumentSharingResultInfo, TranslateService } from '@kros-sk/ssw-shared-legacy';

import { DocumentSharing } from '../../models/document-sharing/document-sharing.model';
import { DocumentSharingViewModel } from '../../models/document-sharing/document-sharing-view-model';
import { environment } from '../../../../environments/environment';

const documentSharingApi = '/api/documentService/DocumentsSharing';
const documentSharingListEndpoint = '/documentsSharingList';
const documentShareEndpoint = '/documentsShare';

@Injectable()
export class DocumentSharingService {

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
  ) { }

  private get documentSharingApi(): string {
    return environment.appUrls.titanGatewayUrl + documentSharingApi;
  }

  getDocumentSharingList(documentIds: string[]): Observable<DocumentSharing[]> {
    return this.http.post<DocumentSharing[]>(this.documentSharingApi + documentSharingListEndpoint, documentIds)
      .pipe(
        map(res => {
          res.forEach(p => p.additionalPermissionType = p.documentPermissionType);
          return res;
        }),
        catchError(this.handleShareError.bind(this))
      );
  }

  shareDocument(shareViewModel: DocumentSharingViewModel): Observable<DocumentSharingResultInfo> {
    return this.http.post<DocumentSharingViewModel>(this.documentSharingApi + documentShareEndpoint, shareViewModel)
      .pipe(
        catchError(this.handleShareError.bind(this))
      );
  }

  private handleShareError(error: HttpErrorResponse): Observable<never> {
    const info: DocumentSharingResultInfo = error.error;
    let errorMessage: string;

    switch (info.result) {
      case DocumentShareResultType.DocumentDoesNotExists:
        errorMessage = this.translateService.translate('DOCUMENTS.ERROR.SHARING_DOCUMENTS_NOT_EXIST');
        break;
      case DocumentShareResultType.NoPermissionForChange:
        errorMessage = this.translateService.translate('DOCUMENTS.ERROR.SHARING_NO_PERMISSION');
        break;
      case DocumentShareResultType.MultipleEditsForbidden:
        errorMessage = this.translateService.translate('DOCUMENTS.ERROR.SHARING_MULTIPLE_EDITS');
        break;
      case DocumentShareResultType.OwnerChangeForbidden:
        errorMessage = this.translateService.translate('DOCUMENTS.ERROR.SHARING_OWNER_CHANGE');
        break;
      default:
        errorMessage = this.translateService.translate('SHARING.UNKNOWN_ERROR');
        break;
    }

    console.error(
      `Backend returned code ${error.status}, ` +
      `error message: ${errorMessage}`
    );

    return throwError(() => new Error(errorMessage));
  }
}
