import { Component, inject, OnInit } from '@angular/core';

import { MarketingContentHelper } from '@kros-sk/ssw-shared-legacy';

import { ActionInProgress } from './enums';
import { BudgetApprovalHelper } from './helpers';
import { BudgetApprovalSelectorsService } from '../store/budget-approval';
import { BuildingProgressActionAccessService } from '../building-progress/services/building-progress-action-access.service';
import { BuildingProgressMarketingContentHelper } from '../building-progress/helpers';

@Component({
  selector: 'app-budget-approval',
  templateUrl: './budget-approval.component.html',
  styleUrls: ['./budget-approval.component.scss'],
  providers: [
    BuildingProgressMarketingContentHelper,
    {
      provide: MarketingContentHelper,
      useExisting: BuildingProgressMarketingContentHelper
    }
  ]
})
export class BudgetApprovalComponent implements OnInit {

  private budgetApprovalSelectorsService = inject(BudgetApprovalSelectorsService);
  private buildingProgressActionAccessService = inject(BuildingProgressActionAccessService);
  private budgetApprovalHelper = inject(BudgetApprovalHelper);

  actionInProgress$ = this.budgetApprovalSelectorsService.actionInProgress$;
  actionInProgress = ActionInProgress;

  ngOnInit(): void {
    this.budgetApprovalHelper.setTitleAndNavbar();

    this.buildingProgressActionAccessService.openMarketingDialogFreeOrToExpire(true, true);
  }
}
