export const MaxRoundDecimalPlacesCount = 8;
export const IndexDecimalPlacesCount = 5;

export function round(num: number, precision: number): number {
  return Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision);
}

export function ceil(num: number, precision: number): number {
  return Math.ceil(num * Math.pow(10, precision)) / Math.pow(10, precision);
}

export function divide(numerator: number, denominator: number): number {
  return numerator && denominator ? numerator / denominator : 0;
}
