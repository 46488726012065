export enum DeviceType {
  DESKTOP,
  MOBILE
}

export enum Breakpoints {
  PHONE = 1024,
  TABLET = 1344,
  DESKTOP = 1600
}
