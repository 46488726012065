import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

import { DirectivesModule } from '../directives/directives.module';
import { ToastComponent } from './components/toast/toast.component';
import { ToastsComponent } from './components/toasts/toasts.component';

@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    NgbToastModule
  ],
  declarations: [
    ToastComponent,
    ToastsComponent
  ],
  exports: [
    ToastsComponent
  ]
})

export class ToastModule {
}
