import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'kros-bottom-navbar',
  templateUrl: './bottom-navbar.component.html',
  styleUrls: ['./bottom-navbar.component.scss']
})
export class BottomNavbarComponent {
}
