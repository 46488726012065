import { Component, OnInit } from '@angular/core';

import { take } from 'rxjs/operators';

import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { BuildingProgressInvoiceService } from '../../../building-progress/services/building-progress-invoice.service';
import { DetailHelper, InvoiceHelper } from '../../../building-progress/helpers';
import { InvoiceDetails } from '../../../building-progress/models/building-progress-invoice/building-progress-invoice-details.model';
import { PeriodInvoices } from '../../../building-progress/models/building-progress-invoice/building-progress-period-invoices.model';

@Component({
  selector: 'app-building-progress-invoice-mobile',
  templateUrl: './building-progress-invoice-mobile.component.html',
  styleUrls: ['./building-progress-invoice-mobile.component.scss']
})
export class BuildingProgressInvoiceMobileComponent extends UnsubscribeComponent implements OnInit {

  periodsInvoices: PeriodInvoices[];

  constructor(
    public detail: DetailHelper,
    public invoiceHelper: InvoiceHelper,
    private invoiceService: BuildingProgressInvoiceService
  ) {
    super();
  }

  ngOnInit(): void {
    this.setInvoices();
    this.subs.sink = this.invoiceHelper.invoiceCreated$.subscribe(() => {
      this.setInvoices();
    });
  }

  onCreateInvoiceClick(): void {
    this.invoiceHelper.createInvoiceForCompany(this.detail.constructionData);
  }

  onOpenInvoiceClick(invoiceDetail: InvoiceDetails): void {
    this.invoiceHelper.openInvoiceApp(invoiceDetail.url);
  }

  private setInvoices(): void {
    this.invoiceService.getAllProjectInvoices(this.detail.projectId).pipe(take(1)).subscribe(periodsInvoices => {
      this.periodsInvoices = periodsInvoices;
    });
  }
}
