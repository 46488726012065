import { inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { featureFlagsActions } from './feature-flags.actions';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsDispatchersService {

  private store = inject(Store);

  loadFeatures(): void {
    this.store.dispatch(featureFlagsActions.loadFeatures());
  }
}
