import { inject, Injectable } from '@angular/core';

import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { BuildingObject } from '@kros-sk/ssw-budget/shared/data-access/models';

import { adapterBuildingObjects, BuildingObjectsState } from './building-objects.state';

export const buildingObjectsFeatureKey = 'building-objects';
export const selectBuildingObjectsFeature = createFeatureSelector<BuildingObjectsState>(buildingObjectsFeatureKey);

const selectBuildingObjects = createSelector(
  selectBuildingObjectsFeature,
  adapterBuildingObjects.getSelectors().selectAll
);

const selectAreBuildingObjectsFullyLoaded = createSelector(
  selectBuildingObjectsFeature,
  state => state.areBuildingObjectsFullyLoaded
);

const selectCanLoadBuildingObjectsState = createSelector(
  selectBuildingObjectsFeature,
  state => state.canLoadState
);

const selectSelectedBuildingObject = createSelector(
  selectBuildingObjectsFeature,
  state => state.selected
);

const selectBuildingObjectIsSaving = createSelector(
  selectBuildingObjectsFeature,
  state => state.isSaving
);

const selectSelectBuildingObjectAfterCreate = createSelector(
  selectBuildingObjectsFeature,
  state => state.selectAfterCreate
);

@Injectable({
  providedIn: 'root'
})
export class BuildingObjectsSelectorsService {
  private store = inject(Store<BuildingObjectsState>);

  buildingObjects$: Observable<BuildingObject[]> = this.store.select(selectBuildingObjects);
  buildingObjectIsSaving$: Observable<boolean> = this.store.select(selectBuildingObjectIsSaving);
  areBuildingObjectsFullyLoaded$: Observable<boolean> = this.store.select(selectAreBuildingObjectsFullyLoaded);
  canLoadBuildingObjectsState$: Observable<boolean> = this.store.select(selectCanLoadBuildingObjectsState);
  selectBuildingObjectAfterCreate$: Observable<boolean> = this.store.select(selectSelectBuildingObjectAfterCreate);
  selectedBuildingObject$: Observable<BuildingObject> = this.store.select(selectSelectedBuildingObject);
}
