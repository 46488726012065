<div
  class="settings-module"
  *ngIf="(!settingsIsLoading || !isOpenedProject) else loading"
>
  <div class="header-line">
    <h4>{{ 'SETTINGS.BUILDING_PROGRESS' | translate }}</h4>

    <button
      class="btn btn-outline-primary"
      type="button"
      (click)="navigateTo(routerLink)"
      data-test="close-settings-button"
    >
      {{ 'SETTINGS.CLOSE' | translate }}
      <i class="material-icons">clear</i>
    </button>
  </div>

  <ng-container *ngIf="isOpenedProject && isExist && !settingsIsLoading && hasPermission; else noProject">
    <div
      [formGroup]="settingsForm"
      class="scrollable-content"
    >
      <div class="card">
        <h4>{{ 'COMMON.CURRENCY' | translate }}</h4>
        <div class="info-item">
          <span
            class="value"
            data-test="currency-code"
          >{{settingsForm?.value.currencyCode}}</span>
        </div>
      </div>

      <div class="card">
        <h4>{{ 'SETTINGS.ROUND_FORMAT.TITLE' | translate }}</h4>
        <div class="info-item">
          <span class="title">{{ 'SETTINGS.ROUND_FORMAT.AMOUNT' | translate }}:</span>

          <span
            class="value"
            data-test="decimal-places-amount"
          >{{settingsForm?.value.amount}}</span>
        </div>

        <div class="info-item">
          <span class="title">{{ 'SETTINGS.ROUND_FORMAT.UNIT_PRICE' | translate }}:</span>

          <span
            class="value"
            data-test="decimal-places-unit-price"
          >{{settingsForm?.value.unitPrice}}</span>
        </div>

        <div class="info-item">
          <span class="title">{{ 'SETTINGS.ROUND_FORMAT.TOTAL_PRICE' | translate }}:</span>

          <span
            class="value"
            data-test="decimal-places-total-price"
          >{{settingsForm?.value.totalPrice}}</span>
        </div>

        <div class="info-item">
          <span class="title">{{ 'SETTINGS.ROUND_FORMAT.BUILDING_OBJECT_TOTAL_PRICE' |
            translate}}:</span>

          <span
            class="value"
            data-test="decimal-places-object-total-price"
          >{{settingsForm?.value.buildingObjectTotalPrice}}</span>
        </div>
      </div>

      <div class="card">
        <h4>{{ 'SETTINGS.VAT.VAT' | translate }}</h4>

        <div
          *ngIf="isVatRateDefined(vatRateType.Reduced) || isVatRateDefined(vatRateType.ReducedTransferred)"
          class="info-item"
        >
          <span class="title">
            {{ getVatResName(vatRateType.Reduced) | translate }}:
            <i
              class="material-icons tooltip-icon"
              [krosTooltip]="'SETTINGS.VAT.INFO' | translate"
            >info</i>
          </span>

          <div class="value">
            <kros-form-field class="vat-value">
              <input
                #reducedVatRate
                krosControl
                krosNumbersOnly
                (input)="handleInput($event)"
                [digitsGrouping]="true"
                [minFractionDigits]="1"
                [maxFractionDigits]="1"
                [minValue]="0"
                [maxValue]="100"
                [formControl]="settingsForm.controls['reducedVatRate']"
                type="text"
                class="form-control text-right vat-input"
                data-test="vat-rate-reduced"
              >
            </kros-form-field>

            <span>&nbsp;%</span>
          </div>
        </div>

        <div
          *ngIf="isVatRateDefined(vatRateType.Basic) || isVatRateDefined(vatRateType.BasicTransferred)"
          class="info-item"
        >
          <span class="title">{{ getVatResName(vatRateType.Basic) | translate }}:
            <i
              class="material-icons tooltip-icon"
              [krosTooltip]="'SETTINGS.VAT.INFO' | translate"
            >info</i>
          </span>

          <div class="value">
            <kros-form-field class="vat-value">
              <input
                #basicVatRate
                krosControl
                krosNumbersOnly
                (input)="handleInput($event)"
                [digitsGrouping]="true"
                [minFractionDigits]="1"
                [maxFractionDigits]="1"
                [minValue]="0"
                [maxValue]="100"
                [formControl]="settingsForm.controls['basicVatRate']"
                type="text"
                class="form-control text-right vat-input"
                data-test="vat-rate-basic"
              >
            </kros-form-field>

            <span>&nbsp;%</span>
          </div>
        </div>

        <div
          *ngIf="isCzechVersion && isVatRateDefined(vatRateType.ReducedTransferred)"
          class="info-item"
        >
          <span class="title">{{ getVatResName(vatRateType.ReducedTransferred) | translate }}:</span>

          <span
            class="value"
            data-test="vat-rate-reduced-transferred"
          >{{getVatRate(vatRateType.ReducedTransferred) | number : digitsInfoVat : appLocation}}&nbsp;%</span>
        </div>

        <div
          *ngIf="isCzechVersion && isVatRateDefined(vatRateType.BasicTransferred)"
          class="info-item"
        >
          <span class="title">{{ getVatResName(vatRateType.BasicTransferred) | translate }}:</span>

          <span
            class="value"
            data-test="vat-rate-basic-transferred"
          >{{getVatRate(vatRateType.BasicTransferred) | number : digitsInfoVat : appLocation}}&nbsp;%</span>
        </div>

        <div
          *ngIf="isVatRateDefined(vatRateType.Zero)"
          class="info-item"
        >
          <span class="title">{{ getVatResName(vatRateType.Zero) | translate }}:</span>

          <span
            class="value"
            data-test="vat-rate-zero"
          >{{getVatRate(vatRateType.Zero) | number : digitsInfoVat : appLocation}}&nbsp;%</span>
        </div>

        <div
          *ngIf="!isCzechVersion"
          class="info-item"
        >
          <span class="title">{{ 'SETTINGS.VAT.TRANSFERRED_VAT' | translate }}:</span>

          <div class="value toggle">
            <div class="form-group">
              <kros-toggle
                formControlName="isTransferredVat"
                data-test="is-transfered-vat-toggle"
              ></kros-toggle>

              <span
                class="toggle-description"
                data-test="is-transfered-vat-state"
              >{{'COMMON.' + (settingsForm?.value.isTransferredVat ?
                'ENABLED' : 'DISABLED') | translate}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <h4>{{ 'SETTINGS.PERIOD_APPROVAL.TITLE' | translate }}</h4>

        <div class="info-item">
          <span
            class="value"
            data-test="currency-code"
          >{{ 'SETTINGS.PERIOD_APPROVAL.INFO' | translate }}</span>
        </div>

        <div class="form-group radio">
          <div>
            <label>
              <input
                type="radio"
                formControlName="isMultiStageApprovalModeEnabled"
                [value]="false"
                data-test="is-multi-stage-approval-mode-enabled-false"
              >
              <span>{{ 'SETTINGS.PERIOD_APPROVAL.SINGLESTAGE' | translate }}</span>
            </label>
          </div>

          <div>
            <label>
              <input
                type="radio"
                formControlName="isMultiStageApprovalModeEnabled"
                [value]="true"
                data-test="is-multi-stage-approval-mode-enabled-true"
              >
              <span>{{ 'SETTINGS.PERIOD_APPROVAL.MULTISTAGE' | translate }}</span>
            </label>
          </div>
        </div>
      </div>

      <div class="card">
        <h4>{{ 'SETTINGS.RETENTION' | translate }}</h4>

        <div class="info-item">
          <div class="value">
            <kros-form-field class="vat-value">
              <input
                #retainagePercentage
                krosControl
                krosNumbersOnly
                (input)="handleInput($event)"
                [digitsGrouping]="true"
                [minFractionDigits]="1"
                [maxFractionDigits]="1"
                [minValue]="0"
                [maxValue]="100"
                [formControl]="settingsForm.controls['retainagePercentage']"
                type="text"
                class="form-control text-right vat-input"
                data-test="retainage-percentage"
              >
            </kros-form-field>

            <span>
              &nbsp;%
              <ng-container *ngIf="showRetainageCalculationType">
                &nbsp;{{ 'SETTINGS.OF' | translate }}&nbsp;
              </ng-container>
            </span>

            <select
              *ngIf="showRetainageCalculationType"
              id="retainageCalculationType"
              class="form-control retainage-including-vat-select"
              formControlName="retainageCalculationType"
              data-test="retainage-calculation-type"
            >
              <option [value]="retainageCalculationType.PriceWithVAT">
                {{'SETTINGS.PRICE_WITH_VAT' | translate}}
              </option>

              <option [value]="retainageCalculationType.Price">
                {{'SETTINGS.PRICE_WITHOUT_VAT' | translate}}
              </option>
            </select>
          </div>
        </div>

        <h4 class="retainage2-title">{{ 'SETTINGS.RETENTION2' | translate }}</h4>

        <div class="info-item">
          <div class="value">
            <kros-form-field class="vat-value">
              <input
                #retainage2Percentage
                krosControl
                krosNumbersOnly
                (input)="handleInput($event)"
                [digitsGrouping]="true"
                [minFractionDigits]="1"
                [maxFractionDigits]="1"
                [minValue]="0"
                [maxValue]="100"
                [formControl]="settingsForm.controls['retainage2Percentage']"
                type="text"
                class="form-control text-right vat-input"
                data-test="retainage2-percentage"
              >
            </kros-form-field>

            <span>
              &nbsp;%
              <ng-container *ngIf="showRetainage2CalculationType">
                &nbsp;{{ 'SETTINGS.OF' | translate }}&nbsp;
              </ng-container>
            </span>

            <select
              *ngIf="showRetainage2CalculationType"
              id="retainage2CalculationType"
              class="form-control retainage-including-vat-select"
              formControlName="retainage2CalculationType"
              data-test="retainage2-calculation-type"
            >
              <option [value]="retainage2CalculationType.PriceWithVAT">
                {{'SETTINGS.PRICE_WITH_VAT' | translate}}
              </option>

              <option [value]="retainage2CalculationType.Price">
                {{'SETTINGS.PRICE_WITHOUT_VAT' | translate}}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div
        *ngIf="(authSelectorsService.featureFlags$ | async).isAutoCompletedItemsEnabled"
        class="card"
      >
        <h4>{{ 'SETTINGS.COMPLETE' | translate }}</h4>

        <div class="info-item">
          <span class="title">{{ 'SETTINGS.COMPLETE_ALL' | translate }}:
            <i
              class="material-icons tooltip-icon"
              [krosTooltip]="'SETTINGS.COMPLETE_INFO' | translate"
            >info</i>
          </span>

          <div class="value toggle">
            <div class="form-group">
              <kros-toggle
                formControlName="isAutoCompleteItemsEnabled"
                data-test="is-auto-complete-items-enabled-toggle"
              ></kros-toggle>

              <span
                class="toggle-description"
                data-test="is-auto-complete-items-enabled-state"
              >{{'COMMON.' + (settingsForm?.value.isAutoCompleteItemsEnabled ?
                'ENABLED_PLURAL' : 'DISABLED_PLURAL') | translate}}</span>
            </div>
          </div>
        </div>
      </div>

      <ssw-open-api-settings></ssw-open-api-settings>
    </div>
  </ng-container>

  <div
    class="detail-footer"
    *ngIf="canSaveSettings || isEditedOpenApi"
  >
    <div class="top-gradient"></div>

    <div
      class="form-confirmation"
      *ngIf="isEdited || isEditedOpenApi"
    >
      <button
        class="btn btn-primary btn-submit"
        type="button"
        (click)="onSave()"
        data-test="save-settings-button"
      >
        <div class="save-btn">
          <div class="save-btn-portal"></div>
          <div class="save-text">{{ 'COMMON.ULOZIT' | translate }}</div>
        </div>
      </button>
    </div>
  </div>
</div>

<ng-template #noProject>
  <p>{{!isOpenedProject
    ? ('SETTINGS.BUILDING_PROGRESS_NO_PROJECT' | translate)
    : !hasPermission ? ('SETTINGS.BUILDING_PROGRESS_NO_PERMISSION' | translate) : ('SETTINGS.BUILDING_PROGRESS_EMPTY' |
    translate)}}
  </p>

  <ssw-open-api-settings></ssw-open-api-settings>
</ng-template>

<ng-template #loading>
  <kros-timeline [type]="timelineType.ThreeLine"></kros-timeline>
</ng-template>
