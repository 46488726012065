<ng-container *ngIf="subcontractorsSelectorsService.selectedSubcontractor$ | async; else emptyContent">
  <ng-container *ngIf="detail.currentNavigation === 'table'">
    <app-subcontractors-toolbar
      [hasItems]="detail.constructionData?.items.length > 0"
      [canEditSubcontractors]="canEditSubcontractors"
      [canOpenBoq]="canOpenBoq"
      [isOwnerOrContributor]="isOwnerOrContributor"
      [view]="detail.currentView"
      (shareClick)="onShareClick()"
      (openCommentsClick)="detail.onOpenComments()"
      (openPeriodsClick)="panelHelper.onOpenPeriods()"
      (addItemsClick)="onAddItemsClick()"
      (removeItemsClick)="onRemoveItemsClick()"
      (openTransferExecutionsClick)="onOpenTransferExecutionsClick()"
      (openBoqClick)="onOpenBoqClick()"
      (openNotesClick)="onOpenNotesClick()"
      (exportToExcelClick)="exportHelper.onOpenExportToExcel(itemIdsForExportTable, detail.selectedPeriods, detail.summaryViewActive, detail.projectId, detail.contractorId)"
      (exportInvoiceDetailsClick)="exportHelper.onOpenExportInvoiceDetails(detail.constructionData, detail.contractorId)"
      (exportAmountsUniXmlClick)="exportHelper.onOpenExportAmountsUniXml(detail.constructionData, detail.contractorId)"
      (exportRemainingBudgetClick)="exportHelper.onOpenExportRemainingBudget(detail.constructionData, detail.contractorId)"
      (dashboardClick)="onDashboardClick()"
    ></app-subcontractors-toolbar>

    <app-building-progress-filter
      *ngIf="permissionHelper.hasPermission && detail.isFilterVisible && !panelHelper.isMobileDevice"
      [showSearch]="false"
      [isSummaryPanelButtonVisible]="false"
      (refreshClick)="detail.onRefresh()"
    ></app-building-progress-filter>

    <ng-container *ngIf="detail.areConstructionDataLoaded; else contentLoader">
      <div class="data-container">
        <kros-side-panel>
          <ng-template #rightPanel>
            <app-comments-panel
              *ngIf="panelHelper.rightPanelType === buildingProgressPanelEnum.Comments"
              [data]="panelHelper.commentData"
              [readOnly]="areCommentsReadOnly"
              (close)="panelHelper.onCloseComments()"
              (clickComment)="onClickComment($event)"
            ></app-comments-panel>

            <app-periods-panel
              *ngIf="panelHelper.rightPanelType === buildingProgressPanelEnum.Periods"
              [searchModel]="detail.currentSearchModel"
              [projectId]="detail.projectId"
              [permission]="permissionHelper.permission"
              [readonly]="licenseHelper.isReadonlyLicense"
              [canAdd]="false"
              canOwnerContributorApprove="true"
              [contractorId]="(subcontractorsSelectorsService.selectedSubcontractor$ | async).id"
              (close)="panelHelper.onClosePeriods()"
            ></app-periods-panel>

            <app-subcontractors-item-period-details
              *ngIf="panelHelper.rightPanelType === buildingProgressPanelEnum.Details"
            ></app-subcontractors-item-period-details>

            <app-subcontractors-budget-item-notes
              *ngIf="panelHelper.rightPanelType === buildingProgressPanelEnum.Notes"></app-subcontractors-budget-item-notes>
          </ng-template>

          <ng-template #bottomPanel>
            <app-budget-boq
              *ngIf="panelHelper.bottomPanelType === buildingProgressPanelEnum.Boq"
              [data]="panelHelper.boqBudgetItem"
              [decimalPlaces$]="buildingProgressSelectorsService.constructionDataDecPlaces$"
              [boqItems$]="buildingProgressSelectorsService.boqItems$"
              [boqItemsIsLoading$]="buildingProgressSelectorsService.boqItemsIsLoading$"
              (closePanelClicked)="panelHelper.onCloseBoq()"
            ></app-budget-boq>

            <app-boq-periods
              *ngIf="panelHelper.bottomPanelType === buildingProgressPanelEnum.BoqPeriods"
              [data]="panelHelper.boqBudgetItem"
              [isReadOnly]="true"
            ></app-boq-periods>
          </ng-template>

          <ng-template #content>
            <ng-container *ngIf="detail.areConstructionDataLoaded; else contentLoader">
              <ng-container *ngTemplateOutlet="noItems"></ng-container>

              <kros-data-table
                *ngIf="detail.constructionData?.items.length > 0"
                [itemHeight]="50"
                [data]="detail.constructionData"
                [itemCount]="detail.itemCount"
                [config]="detail.dataTableConfig"
                [focusedCell]="detail.focusedCell"
                [hiddenColumns$]="buildingProgressSelectorsService.hiddenColumns$"
                [forceChangeDetection]="tableChangeDetectionService.forceTableChangeDetection"
                (cellFocused)="onCellFocused($event)"
                (itemCheckChanged)="onItemCheckChanged($event.item, $event.state)"
                (headerCheckChanged)="onHeaderCheckChanged($event)"
                (openCommentsClick)="detail.onOpenComments($event)"
              ></kros-data-table>
            </ng-container>
          </ng-template>
        </kros-side-panel>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngTemplateOutlet="dashboard"></ng-container>
</ng-container>

<ng-template #emptyContent>
  <ng-container *ngIf="(subcontractorsSelectorsService.subcontractors$ | async).length > 0 else noItems">
    <p class="empty-content text-center">
      <span class="font-weight-bold">{{ 'SUBCONTRACTORS.NO_ITEMS' | translate }}</span>
      <span
        *ngIf="canEditSubcontractors"
        class="sub-text"
      >{{ 'SUBCONTRACTORS.NO_ITEMS_SUBTEXT' | translate }}</span>
    </p>
  </ng-container>
</ng-template>

<ng-template #contentLoader>
  <kros-timeline [type]="timelineType.Table"></kros-timeline>
</ng-template>

<ng-template #noItems>
  <div
    *ngIf="detail.constructionData?.items.length === 0 && detail.currentNavigation === 'table'"
    class="no-items text-center"
  >
    <h5>{{ 'COMMON.NO_ITEMS_MESSAGE' | translate }}</h5>
  </div>
</ng-template>

<ng-template #dashboard>
  <app-building-progress-dashboard
    *ngIf="detail.currentNavigation === 'dashboard' && detail.projectId"
    [projectId]="detail.projectId"
    [subcontractorsMode]="true"
    (closeClick)="detail.currentNavigation = 'table'"
  ></app-building-progress-dashboard>
</ng-template>
