import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  ApplicationModel,
  ApplicationOpenerService,
  ApplicationType,
  DocumentListInfoModel,
  DocumentsPermissionsService,
  DocumentType,
  FolderTreeNode,
  Project,
  TranslateService
} from '@kros-sk/ssw-shared-legacy';
import { KrosModalService } from '@kros-sk/components';
import { PermissionType } from '@kros-sk/ssw-shared/permission';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { ContentTypes } from '../../shared/content-types';
import { DocumentPanelDialogComponent } from '../document-panel-dialog/document-panel-dialog.component';
import { DocumentsSelectorsService } from '../../../store/documents';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-document-list-toolbar',
  templateUrl: './document-list-toolbar.component.html',
  styleUrls: ['./document-list-toolbar.component.scss']
})
export class DocumentListToolbarComponent extends UnsubscribeComponent implements OnInit {

  openDocumentApplications: ApplicationModel[];
  openDocumentApplicationsList: ApplicationModel[];
  markedDocument: DocumentListInfoModel;
  markedDocumentsCount: number;

  @Input() project: Project;
  @Input() selectedDocumentId: string;
  @Input() isUploadingMode: boolean;
  @Input() currentFolder: FolderTreeNode;
  @Input() canAddDocumentMenu: boolean;

  @Output() openNewTabClick = new EventEmitter();
  @Output() openApplicationClick = new EventEmitter<string>();
  @Output() fileAddVersionSelected = new EventEmitter();
  @Output() downLoadDocumentClick = new EventEmitter();
  @Output() renameFolderClick = new EventEmitter();
  @Output() shareDocumentClick = new EventEmitter();
  @Output() deleteDocumentsClick = new EventEmitter();
  @Output() showDocumentUploader = new EventEmitter();
  @Output() showDocumentVersionUploader = new EventEmitter();
  @Output() folderOpened = new EventEmitter();
  @Output() uploadBuildingClick = new EventEmitter();
  @Output() setDocumentDescriptionClick = new EventEmitter();

  get getSelectedDocumentContentType(): string {
    return this.isMarkedDocument ? this.getSelectedDocument.contentType : '';
  }

  get parentId(): string {
    return this.currentFolder?.id ?? '';
  }

  get isSelectedFolder(): boolean {
    return this.isMarkedDocument ? this.getSelectedDocument.type === DocumentType.Folder : false;
  }

  get isMarkedDocument(): boolean {
    return this.markedDocumentsCount > 0 && !!this.markedDocument;
  }

  get isCzechVersion(): boolean {
    return environment.location === 'cz';
  }

  get canOpenOnNewTab(): boolean {
    return !this.isUploadingMode && this.documentsPermissionsService.canOpenOnNewTab();
  }

  get canRenameFolder(): boolean {
    return !this.isUploadingMode && this.documentsPermissionsService.canRenameFolder;
  }

  get canAddDocumentVersion(): boolean {
    return !this.isUploadingMode && this.isSingleDocumentSelected &&
      this.documentsPermissionsService.hasMinimumPermissionType(
        this.getSelectedDocument.permissionType, PermissionType.Contributor) &&
      this.versioningContentTypes.some(type => type === this.getSelectedDocument.contentType);
  }

  get versioningContentTypes(): string[] {
    return [ContentTypes.ifc, ContentTypes.pdf].concat(ContentTypes.drawings);
  }

  get canDownload(): boolean {
    return !this.isUploadingMode && this.documentsPermissionsService.canDownload;
  }

  get canShare(): boolean {
    return !this.isUploadingMode && this.documentsPermissionsService.canShare(this.project) && this.isMarkedDocument;
  }

  get canDelete(): boolean {
    return !this.isUploadingMode && this.documentsPermissionsService.canDelete;
  }

  get isSingleDocumentSelected(): boolean {
    return this.isMarkedDocument && this.markedDocumentsCount === 1;
  }

  get hasDescription(): boolean {
    return !this.isUploadingMode &&
      this.isSingleDocumentSelected &&
      this.getSelectedDocument.contentType === ContentTypes.construction;
  }

  private get getSelectedDocument(): DocumentListInfoModel {
    return this.markedDocument;
  }

  constructor(
    private applicationOpenerService: ApplicationOpenerService,
    private documentsPermissionsService: DocumentsPermissionsService,
    private documentsSelectorsService: DocumentsSelectorsService,
    private translateService: TranslateService,
    private krosModalService: KrosModalService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.documentsSelectorsService.selectMarkedDocuments$.
      subscribe(docs => this.markedDocumentsCount = docs.length);

    this.subs.sink = this.documentsSelectorsService.selectMarkedDocument$
      .subscribe(doc => {
        this.markedDocument = doc;
        if (doc) {
          this.updateOpenDocumentApplications(doc.contentType);
        }
      });

    this.setUpdateOpenDocumentApplications();
  }

  onOpenPanel(): void {
    this.krosModalService.openCentered(
      DocumentPanelDialogComponent,
      {},
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: false,
        showMobileArrowBack: false
      },
      NaN,
      NaN,
      undefined,
      'kros-modal-panel-left');
  }

  openOnNewTab(): void {
    this.openNewTabClick.emit();
  }

  openDocumentInApplication(appId: string): void {
    this.openApplicationClick.emit(appId);
  }

  onfileAddVersionSelected(): void {
    this.fileAddVersionSelected.emit();
  }

  onDownloadDocumentClick(): void {
    this.downLoadDocumentClick.emit();
  }

  onRenameFolderClick(): void {
    this.renameFolderClick.emit();
  }

  onShareDocumentClick(): void {
    this.shareDocumentClick.emit();
  }

  onDeleteDocumentsClick(): void {
    this.deleteDocumentsClick.emit();
  }

  onShowDocumentUploader(): void {
    this.showDocumentUploader.emit();
  }

  onShowDocumentVersionUploader(): void {
    this.showDocumentVersionUploader.emit();
  }

  onSetDocumentDescriptionClick(): void {
    this.setDocumentDescriptionClick.emit();
  }

  openFolder(): void {
    this.folderOpened.emit();
  }

  getDisplayName(app: ApplicationModel): string {
    return this.applicationOpenerService.getApplicationDisplayName(app, this.translateService.currentLang);
  }

  uploadBuilding(): void {
    this.uploadBuildingClick.emit();
  }

  private updateOpenDocumentApplications(contentType: string): void {
    if (this.openDocumentApplicationsList && this.openDocumentApplicationsList.length > 0) {
      this.openDocumentApplications = this.openDocumentApplicationsList.filter(
        app => (app.applicationType | ApplicationType.DocumentOpen) === app.applicationType &&
          app.allowedDocumentTypes.indexOf(contentType) > -1);
    }
  }

  private setUpdateOpenDocumentApplications(): void {
    this.subs.sink = this.applicationOpenerService.getApplicationList()
      .subscribe(list => this.openDocumentApplicationsList = list);
  }
}
