import { Action } from '@ngrx/store';

export class History {
  public past: Action[];
  public future: Action[];
}

export class HistoryState {
  public history: History;
  public isInProgress: boolean;
}

export const initialHistoryState: HistoryState = {
  history: {
    past: [],
    future: []
  },
  isInProgress: false
};

export const undoRedoKey = 'undo-redo';
