import { Component, EventEmitter, Input, Output } from '@angular/core';

import { PermissionType } from '@kros-sk/ssw-shared/permission';

import { ItemSharing } from '../../../models';
import { NewSharingComboMode } from '../../models/sharing-combo-mode.enum';
import { PermissionChangedDto } from '../../models/permission-changed-dto.model';
import { sharingSortFn } from '../../../budget/helpers';

@Component({
  selector: 'kros-user-sharing-list',
  templateUrl: './user-sharing-list.component.html',
  styleUrls: ['./user-sharing-list.component.scss']
})
export class UserSharingListComponent {

  @Input() unconfirmedSharings: ItemSharing[];
  @Input()
  set confirmedSharings(sharings: ItemSharing[]) {
    this._confirmedSharings = [...sharings];
    this._confirmedSharings.sort(sharingSortFn);
  }
  @Input() sharingMode: NewSharingComboMode;
  @Input() readonly = false;

  @Output() unconfirmedPermissionDeleted = new EventEmitter<ItemSharing>();
  @Output() deletedPermission = new EventEmitter<PermissionChangedDto>();
  @Output() changedPermission = new EventEmitter<PermissionChangedDto>();

  newSharingComboMode = NewSharingComboMode;

  private _confirmedSharings: ItemSharing[];

  constructor() { }

  get filterConfirmedSharings(): ItemSharing[] {
    if (this.readonly) {
      return this._confirmedSharings;
    } else {
      return this._confirmedSharings.filter(s => s.permissionType !== PermissionType.Owner);
    }
  }

  onDeletedPermission(permissionDto: PermissionChangedDto): void {
    this.deletedPermission.emit(permissionDto);
  }

  onUnconfirmedPermissionDeleted(sharing: ItemSharing): void {
    this.unconfirmedPermissionDeleted.emit(sharing);
  }

  onChangedPermission(permissionDto: PermissionChangedDto): void {
    this.changedPermission.emit(permissionDto);
  }
}
