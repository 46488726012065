import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { BehaviorSubject, catchError, Observable, of, switchMap, take, tap } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { BudgetPermission, BudgetPermissionType } from '@kros-sk/ssw-shared/permission';
import { BudgetSettings, ToastService, TranslateService } from '@kros-sk/ssw-shared-legacy';

import { SettingService } from './setting.service';

const budgetService = '/budgetService/';
const projectApi = '/api/projects/';
const budgetApi = `${budgetService}Budget`;
const budgetPermissionsApi = '/permissionService/budgetPermissions';

@Injectable()
export class BudgetSettingService {

  get budgetSettings$(): BehaviorSubject<BudgetSettings> {
    return this._budgetSettings$;
  }

  get hasPermission(): boolean {
    return !!this.permission && this.permission.permissionType > BudgetPermissionType.None;
  }

  get canSaveSettings(): boolean {
    return !!this.permission && this.permission.permissionType > BudgetPermissionType.Reader;
  }

  isExist: boolean;
  isLoading: boolean;

  private _budgetSettings$ = new BehaviorSubject<BudgetSettings>(undefined);
  private gatewayApi = `${this.appConfig.appUrls.titanGatewayUrl}${projectApi}`;
  private getBudgetSettingsEndpoint = `${budgetApi}/settings`;
  private setBudgetSettingsEndpoint = `${budgetApi}/settingsSet`;
  private getExistBudgetEndpoint = `${budgetApi}/exist`;
  private getBudgetPermissionEndpoint = `${budgetPermissionsApi}/permission`;
  private permission: BudgetPermission;

  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private settingService: SettingService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private http: HttpClient
  ) {
  }

  loadBudgetSettings(): void {
    this.http.get<BudgetSettings>(this.gatewayApi + this.settingService.projectId + this.getBudgetSettingsEndpoint)
      .pipe(take(1))
      .subscribe(s => {
        this._budgetSettings$.next(s);
        this.isLoading = false;
      });
  }

  checkBudget(): Observable<boolean> {
    this.isLoading = true;
    return this.http.get<BudgetPermission>(this.gatewayApi + this.settingService.projectId + this.getBudgetPermissionEndpoint)
      .pipe(
        tap(p => this.permission = p),
        switchMap(() => this.hasPermission
          ? this.http.get<boolean>(this.gatewayApi + this.settingService.projectId + this.getExistBudgetEndpoint)
          : of(false)),
        tap(r => {
          this.isExist = r;
          this.isLoading = r;
        }));
  }

  setBudgetSettings(settings: BudgetSettings): Observable<void> {
    return this.http.patch<void>(this.gatewayApi + this.settingService.projectId + this.setBudgetSettingsEndpoint, settings)
      .pipe(
        catchError(this.settingService.handleError.bind(this.settingService)),
        tap(() => {
          this._budgetSettings$.next(settings);
          this.toastService.open(this.translateService.translate('SETTINGS.VAT.SET_SETTINGS_SUCCESS'));
        }));
  }
}
