import { Component } from '@angular/core';

@Component({
  selector: 'app-budget-approval-container',
  templateUrl: './budget-approval-container.component.html',
  styleUrls: ['./budget-approval-container.component.scss']
})
export class BudgetApprovalContainerComponent {
  constructor() { }
}
