import { ErrorHandler, inject, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

@Injectable()
export class GlobalErrorHandlingInterceptor implements ErrorHandler {

  private appInsightsService = inject(AppInsightsBaseService);

  handleError(error: any): void {
    console.error(error);
    if (!(error instanceof HttpErrorResponse)) {
      // Log exception into app insights
      this.appInsightsService.trackException(error, 'GlobalErrorHandlingInterceptor');
    }
  }
}
