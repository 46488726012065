import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { KrosShepherdService } from './kros-shepherd.service';
import { KrosTourService } from '../kros-tour/kros-tour.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    KrosShepherdService,
    KrosTourService
  ]
})

export class KrosTourModule { }
