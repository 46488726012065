import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BuildingProgressActionAccessService } from '../../../building-progress/services/building-progress-action-access.service';

@Component({
  selector: 'app-budget-changes-toolbar',
  templateUrl: './budget-changes-toolbar.component.html',
  styleUrls: ['./budget-changes-toolbar.component.scss']
})
export class BudgetChangesToolbarComponent {
  @Input() canOpenBoq: boolean;

  @Output() backClick = new EventEmitter<void>();
  @Output() showBudgetChangesStructureClick = new EventEmitter<void>();
  @Output() exportClick = new EventEmitter<void>();
  @Output() openBoqClick = new EventEmitter<void>();

  constructor(
    private buildingProgressActionAccessService: BuildingProgressActionAccessService
  ) { }

  export(): void {
    if (this.buildingProgressActionAccessService.checkForAccessToExport(true)) {
      this.exportClick.emit();
    }
  }

  openBoq(): void {
    this.openBoqClick.emit();
  }

  onBackClick(): void {
    this.backClick.emit();
  }

  showBudgetChangesStructure(): void {
    this.showBudgetChangesStructureClick.emit();
  }
}
