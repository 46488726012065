import { ArrayDataSource } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';

import { filter, switchMap } from 'rxjs';

import {
  DocumentPermissionType,
  DocumentsExplorerDispatchersService,
  FolderTreeNode,
  getNodeLevel,
  Project,
  TranslateService
} from '@kros-sk/ssw-shared-legacy';
import { PermissionType } from '@kros-sk/ssw-shared/permission';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { DocumentsSelectorsService } from '../../../store/documents';
import { FolderNode } from '../../models/folder-node';

@Component({
  selector: 'app-document-explorer-tree',
  templateUrl: './document-explorer-tree.component.html',
  styleUrls: ['./document-explorer-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentExplorerTreeComponent extends UnsubscribeComponent implements OnInit {
  treeControl = new FlatTreeControl<FolderNode>(node => node.level, node => node.isExpandable);
  dataSource: ArrayDataSource<FolderNode>;

  @Input() projectDetail: Project;

  @Output() nodeSelected: EventEmitter<void> = new EventEmitter<void>();

  private selectedNode: FolderNode;
  private treeData: FolderNode[] = [];

  constructor(
    private documentsExplorerDispatchers: DocumentsExplorerDispatchersService,
    private documentsSelectorsService: DocumentsSelectorsService,
    private cd: ChangeDetectorRef,
    private translateService: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.documentsSelectorsService.folderHierarchy$.pipe(
      filter(nodes => !!nodes),
      switchMap(nodes => {
        this.adaptToTreeDataSource(nodes);
        return this.documentsSelectorsService.currentFolderId$;
      }),
    ).subscribe(id => this.navigateToTreeNode(id));
  }

  selectNode(node: FolderNode): void {
    if (!this.isSelected(node) && this.projectDetail?.id) {
      this.selectedNode = node;
      node.isExpanded = true;

      if (node.data.id !== '') {
        this.documentsExplorerDispatchers.loadFolder(node.data, this.projectDetail.id);
      } else {
        this.documentsExplorerDispatchers.loadRoot(this.projectDetail.id);
      }

      this.nodeSelected.emit();
    }
  }

  isSelected(node: FolderNode): boolean {
    return node && node.data.id === this.selectedNode?.data.id;
  }

  shouldRender(node: FolderNode): boolean {
    let parent: FolderNode = node;

    do {
      parent = parent.parent;
      if (parent) {
        if (!parent.isExpanded) {
          return false;
        }
      } else {
        return true;
      }
    }
    while (parent);

    return node.isExpanded;
  }

  private adaptToTreeDataSource(folderTreeNodes: FolderTreeNode[]): void {
    this.treeData = [];

    const root: FolderNode = {
      parent: null,
      data: {
        id: '',
        parentId: '',
        name: this.translateService.translate('DOCUMENTS.DOKUMENTY'),
        permissionType: PermissionType.Shared,
        documentPermissionType: DocumentPermissionType.None
      },
      children: [],
      level: 0,
      isExpandable: false,
      isExpanded: true
    };

    this.setChildren(root, folderTreeNodes);
    this.dataSource = new ArrayDataSource(this.treeData);

    if (!this.selectedNode) {
      this.selectedNode = root;
    }

    this.cd.detectChanges();
  }

  private setChildren(node: FolderNode, folderTreeNodes: FolderTreeNode[]): void {
    this.treeData.push(node);

    node.children = folderTreeNodes.filter(f => f.parentId === node.data.id).map(function (d) {
      return {
        parent: node,
        data: d,
        children: [],
        level: node.level + 1,
        isExpandable: false,
        isExpanded: true
      };
    });

    node.isExpandable = node.children?.length > 0;
    node.children.forEach(c => this.setChildren(c, folderTreeNodes));
  }

  private navigateToTreeNode(id: string): void {
    if (this.treeData.length > 1) {
      this.selectedNode = this.treeData.find(n => n.data.id === id);
      this.treeControl.expansionModel.select(this.selectedNode);

      let node = this.selectedNode;
      if (node) {
        while ((node = node.parent)) {
          node.isExpanded = true;
        }
      }

      this.cd.detectChanges();
    }
  }

  getNodeLevel(node: FolderNode): number {
    return getNodeLevel(node.level);
  }
}
