<div class="kros-modal-body">
  <div class="img-pckg">
    <img
      src={{licenseConfig.imgPath}}
      alt="license-agreement"
    >
  </div>
  <h4>{{licenseConfig.heading}}</h4>

  <p
    class="h5"
    *ngFor="let info of licenseConfig.infoTexts"
    [innerHTML]="info"
  ></p>
</div>

<div class="kros-modal-footer">
  <button
    class="btn btn-primary btn-submit"
    id="ga-license-agreement-confirm"
    type="button"
    data-test="license-agreement-confirm-button"
    (click)="confirm()"
  >{{ 'LICENSE_AGREEMENT.CONFIRM' | translate }}</button>
</div>
