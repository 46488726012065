<div class="kros-modal-header">
  <h4 class="kros-modal-title">{{ 'DOCUMENTS.FOLDER_RENAME' | translate}}</h4>
  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onCloseClick()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<form
  [formGroup]="nameForm"
  (ngSubmit)="onSubmit()"
>
  <div class="kros-modal-body">
    <div class="panel-content">
      <div class="form-group">
        <input
          krosAutofocus
          id="name"
          class="form-control"
          [class.is-invalid]="name.invalid && name.touched"
          type="text"
          formControlName="name"
          maxlength="100"
          (focus)="$event.target.select()"
          data-test="rename-folder-input"
          required
        >
        <div
          *ngIf="name.errors?.required"
          class="invalid-feedback"
        >
          {{ 'COMMON.POVINNE' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="kros-modal-footer">
    <button
      class="btn btn-submit btn-primary"
      type="button"
      [disabled]="!nameForm.valid || !changesMade"
      (click)="onSubmit()"
      data-test="rename-folder-submit-button"
    >{{ 'DOCUMENTS.RENAME' | translate }}</button>
    <button
      class="btn btn-outline-secondary btn-cancel"
      type="button"
      (click)="onNoClick()"
    >{{ 'COMMON.ZRUSIT' | translate }}</button>
  </div>
</form>
