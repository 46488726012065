import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { DeviceDetectorService, DeviceType } from '../services';
import { KrosModalRef } from '../kros-modal/services/kros-modal-ref';
import { KrosModalService } from '../kros-modal/services/kros-modal.service';
import { NavigationService } from './service/navigation.service';

export enum NavItem {
  Menu = 1,
  Apps = 2,
}

@Component({
  selector: 'kros-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationComponent implements OnDestroy, OnInit {

  @ViewChild('appsDesktop', { read: TemplateRef, static: true }) modalContentDesktop: TemplateRef<void>;
  @ViewChild('appsMobile', { read: TemplateRef, static: true }) modalContentMobile: TemplateRef<void>;

  @HostBinding('class.hidden') hidden: boolean;
  @HostBinding('class.always') alwaysHidden: boolean;

  brandZone: TemplateRef<void>;
  menu: TemplateRef<void>;
  logo: TemplateRef<void>;
  secondaryNav: TemplateRef<void>;
  profile: TemplateRef<void>;
  appSelector: TemplateRef<void>;
  isMobile: boolean;
  active = NavItem.Menu;
  navItem: typeof NavItem = NavItem;

  private subs = new SubSink();
  private openedModal: KrosModalRef;

  constructor(
    private router: Router,
    private deviceDetector: DeviceDetectorService,
    private navService: NavigationService,
    private modalService: KrosModalService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.deviceDetector.deviceType$.subscribe(
      (type) => {
        this.isMobile = type === DeviceType.Mobile;
        this.hide();
        this.detectChanges();
      }
    );
    this.subs.sink = this.navService.hidden$.subscribe(hidden => {
      this.alwaysHidden = hidden === 'always';
      this.hidden = !!hidden;
      this.detectChanges();
    });
    this.subs.sink = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.hide();
    });
  }

  ngOnDestroy(): void {
    this.hide();
    this.subs.unsubscribe();
  }

  @HostListener('click', ['$event.target'])
  toggleAppSelector(targetElement: HTMLElement): void {
    this.hide();
    const hostElement = this.findHostElement(targetElement);
    if (hostElement) {
      if (this.isMobile && this.active === NavItem.Menu && !this.menu) {
        this.active = NavItem.Apps;
      }
      this.openedModal = this.modalService.openConnectedToElement(
        this.isMobile ? this.modalContentMobile : this.modalContentDesktop,
        hostElement,
        null,
        { addModalToBrowsersHistory: false, showMobileArrowBack: false, },
        this.isMobile ? 'cdk-overlay-dark-backdrop' : null,
        this.isMobile ? 'app-selector-sidebar' : 'app-selector-overlay');
    }
  }

  hide(): void {
    if (this.openedModal) {
      this.openedModal.cancel();
    }
  }

  private findHostElement(el: HTMLElement): HTMLElement | null {
    if (el === null || el.tagName.toLowerCase() === 'kros-navigation') {
      return null;
    }
    if (el.classList.contains('brand')) {
      return el;
    }
    return this.findHostElement(el.parentElement);
  }

  private detectChanges(): void {
    if (!this.cdRef['destroyed']) {
      // settimeout is needed because we want to trigger CD immediately.
      // When changing master to detail routes on mobile device main
      // navbar is not hiding properly.
      setTimeout(() => this.cdRef.markForCheck());
    }
  }
}
