<div class="panel-wrapper">
  <div class="panel-header">
    <h4 class="panel-header-caption">
      {{ 'BUILDING_PROGRESS.BUILDING' | translate }}
    </h4>

    <div class="panel-header-actions">
      <button
        ngbAutoFocus
        class="btn btn-link btn-close"
        type="button"
        (click)="onCloseClick()"
      >
        <span krosMaterialIcon>close</span>
      </button>
    </div>
  </div>

  <div class="divider-horizontal"></div>

  <div class="panel-content">
    <app-budget-structure-panel-container
      class="panel-container"
      [data$]="selector.constructionData$"
      [dataTestCollapse]="'building-progress-panel-collapse'"
      [hasPermission]="permissionHelper.hasPermission"
      [title]="'BUILDING_PROGRESS.BUILDING' | translate"
      (panelClosed)="onCloseClick()"
    ></app-budget-structure-panel-container>
  </div>
</div>
