<div
  class="colors"
  [ngClass]="isExtraColors ? 'extraColors' : 'basicColors'"
>
  <div
    class="color ga-color-code-set"
    *ngFor="let color of colors"
    (click)="selectColor(color)"
    data-test="palette-color"
  >
    <div
      [ngStyle]="{'background-color': color}"
      [ngClass]="color === '#FFFFFF' ? 'border' : ''"
    >
      <i
        *ngIf="isColorSelected(color)"
        class="material-icons"
      >done</i>
    </div>
  </div>
</div>
