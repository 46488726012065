<div class="kros-modal-header">
  <h4 class="kros-modal-title">{{'BUDGET_APPROVAL.EXPORT.RANGE' | translate}}</h4>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onCloseClick()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div class="kros-modal-body">
  <app-budget-approval-structure
    [selectedItemIds]="selectedItems"
    [indeterminateItemIds]="indeterminateItems"
    [isDefaultExpanded]="true"
    [hasTooltips]="false"
    [colorizedObjects]="false"
    [showTypes]="['S', 'O']"
    [data]="data"
  ></app-budget-approval-structure>
</div>

<div class="kros-modal-footer">
  <button
    class="btn btn-primary btn-submit"
    id="ga-export-range"
    type="button"
    [disabled]="selectedItems.size === 0"
    (click)="onSubmit()"
  >{{'COMMON.APPLY' | translate}}</button>
</div>
