import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

import { detectDeviceType } from '../utils-fn/device-type.utils';
import { DeviceDetectorState } from '../data-access/store/device-detector.state';
import { DeviceType } from '../types/device-types';

@Injectable({ providedIn: 'root' })
export class DeviceDetectorService {

  constructor(
    private readonly platform: Platform,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  detect(): DeviceDetectorState {
    return {
      platform: this.detectPlatform(),
      deviceType: this.detectDeviceType(),
    };
  }

  private detectPlatform(): (keyof Platform)[] | null {
    const matches = Object.entries(this.platform).filter(([key, value]) => value === true);
    return matches ? matches.map((m) => m[0] as keyof Platform) : null;
  }

  detectDeviceType(): DeviceType {
    return detectDeviceType(this.document);
  }
}
