<ng-container *ngIf="canShowUpdateInfo && isVisible">
  <p
    *ngIf="showCountDown && countDown else infoWithoutCountdown"
    [innerHtml]="'MAINTENANCE.MAINTENANCE_IS_PLANNED_COUNTDOWN' + (isMobile ? '_MOBILE' : '') | translate | stringFormat: [countDown.minutes, countDown.seconds]"
  >
  </p>

  <ng-template #infoWithoutCountdown>
    <p [innerHtml]="maintenance | maintenanceDate"></p>
  </ng-template>
</ng-container>

<button
  *ngIf="!showCountDown && isVisible"
  type="button"
  class="btn btn-link"
  (click)="isClosed = true"
>
  <i class="material-icons">close</i>
</button>
