import { DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';

import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxNumberBoxModule } from 'devextreme-angular/ui/number-box';
import { LetDirective } from '@ngrx/component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { StoreModule } from '@ngrx/store';

import { AlertDialogModule, GenericInfoDialogModule, LicenseModule } from '@kros-sk/ssw-cdk';
import {
  AnalyticsModule,
  AppInsightsUserContextHelper,
  BudgetBoqModule,
  BudgetStructureModule,
  ChartModule,
  ColorCodeModule,
  DataTableModule,
  DirectivesModule,
  LicenseInfoContentHelper,
  LicenseService,
  MarketingContentHelper,
  PipesModule,
  SetTypeService,
  LicenseModule as SharedLicenseModule,
  SidePanelModule,
  StatisticsModule,
  TranslateModule,
  UndoRedoDispatchersService,
  UndoRedoSelectorsService
} from '@kros-sk/ssw-shared-legacy';
import { ApprovalOrderService } from '@kros-sk/ssw-building-progress/approval-order';
import { AvatarModule, KrosDropdownModule, KrosInputsModule } from '@kros-sk/components';
import { BudgetSharedModule } from '@kros-sk/ssw-budget/shared/data-access/store';
import {
  BuildingObjectsModule,
  BuildingObjectsUploadButtonComponent,
  BuildingObjectsUploadModalComponent
} from '@kros-sk/ssw-budget/building-objects';
import { CompanyModule } from '@kros-sk/ssw-shared/company';
import { provideAppInsights } from '@kros-sk/core/application-insights';
import { SettingsModule } from '@kros-sk/ssw-shared/settings';

import { BoqEditationService } from './services/boq-editation.service';
import { BoqService, UISettingsService } from './services';
import { BoqVisualHelper } from './helpers/boq-visual.helper';
import { BuildingProgressActionAccessService } from './services/building-progress-action-access.service';
import {
  BuildingProgressBoqEffectsService, BuildingProgressCommentsDispatchersService, BuildingProgressDataSettingsDispatchersService,
  BuildingProgressDispatchersService,
  BuildingProgressEffectsService,
  BuildingProgressPartialItemsDispatchersService,
  BuildingProgressPartialItemsSelectorsService,
  BuildingProgressPeriodDispatchersService,
  BuildingProgressSelectorsService,
  reducer
} from '../store/building-progress';
import { BuildingProgressComponent } from './building-progress.component';
import { BuildingProgressContainerComponent } from './containers/building-progress-container/building-progress-container.component';
import { BuildingProgressCreateComponent } from './components/building-progress-create/building-progress-create.component';
import { BuildingProgressCreateInvoiceComponent } from
  './components/building-progress-create-invoice/building-progress-create-invoice.component';
import { BuildingProgressDashboardComponent } from './shared/components/building-progress-dashboard/building-progress-dashboard.component';
import {
  BuildingProgressDashboardTableComponent
} from './shared/components/building-progress-dashboard-table/building-progress-dashboard-table.component';
import { BuildingProgressDeleteItemsService } from './services/building-progress-delete-items.service';
import { BuildingProgressDetailComponent } from './components/building-progress-detail/building-progress-detail.component';
import {
  BuildingProgressDetailMobileToolbarComponent
} from './components/building-progress-detail-mobile-toolbar/building-progress-detail-mobile-toolbar.component';
import {
  BuildingProgressDetailToolbarComponent
} from './components/building-progress-detail-toolbar/building-progress-detail-toolbar.component';
import {
  BuildingProgressDetailToolbarContainerComponent
} from './components/building-progress-detail-toolbar-container/building-progress-detail-toolbar-container.component';
import { BuildingProgressEditingService } from './services/building-progress-editing.service';
import { BuildingProgressExportDispatchersService } from '../store/building-progress/building-progress-export-dispatchers.service';
import { buildingProgressFeatureKey } from '../store/building-progress/building-progress-selectors.helper';
import { BuildingProgressInvoiceLicenseInfoComponent } from
  './components/building-progress-invoice-license-info/building-progress-invoice-license-info.component';
import { BuildingProgressInvoiceMobileComponent }
  from './components/building-progress-invoice-mobile/building-progress-invoice-mobile.component';
import { BuildingProgressInvoicePickerComponent } from
  './components/building-progress-invoice-picker/building-progress-invoice-picker.component';
import {
  BuildingProgressMarketingContentHelper,
  BuildingProgressPanelHelper,
  DetailHelper,
  DetailMobileHelper,
  PeriodHelper,
  PermissionHelper,
  VisualHelper
} from './helpers';
import { BuildingProgressMobileItemComponent } from './components/building-progress-mobile-item/building-progress-mobile-item.component';
import { BuildingProgressMobileTableComponent } from './components/building-progress-mobile-table/building-progress-mobile-table.component';
import { BuildingProgressPanelDialogComponent } from './components/building-progress-panel-dialog/building-progress-panel-dialog.component';
import {
  BuildingProgressPartialItemDialogComponent
} from './components/building-progress-partial-item-dialog/building-progress-partial-item-dialog.component';
import {
  BuildingProgressPartialItemSplitterComponent
} from './components/building-progress-partial-item-splitter/building-progress-partial-item-splitter.component';
import { BuildingProgressPartialItemsService } from './services/building-progress-partial-items.service';
import {
  BuildingProgressPartialItemTableComponent
} from './components/building-progress-partial-item-table/building-progress-partial-item-table.component';
import { BuildingProgressPercentageCompleteService } from './services/building-progress-percentage-complete.service';
import { BuildingProgressPeriodService } from './services/building-progress-periods/building-progress-period.service';
import { BuildingProgressPieChartComponent } from './shared/components/building-progress-pie-chart/building-progress-pie-chart.component';
import { BuildingProgressRoutingModule } from './building-progress-routing.module';
import { BuildingProgressService } from './services/building-progress.service';
import {
  BuildingProgressSharingDialogComponent
} from './components/building-progress-sharing-dialog/building-progress-sharing-dialog.component';
import { BuildingProgressSharingService } from './services/building-progress-sharing.service';
import {
  BuildingProgressSummaryPanelComponent
} from './components/building-progress-summary-panel/building-progress-summary-panel.component';
import { BuildingProgressTableService } from './services/building-progress.table.service';
import { BuildingProgressViewsService } from './services/building-progress-views.service';
import { CommentsPanelModule } from './modules/comments-panel/comments-panel.module';
import { CompleteDialogComponent } from './components/complete-dialog/complete-dialog.component';
import { DetailsService } from './services/details.service';
import { EditingPanelHelper } from './helpers/editing-panel.helper';
import { environment } from '../../environments/environment';
import { ExportModule } from './modules/export/export.module';
import { FilterModule } from './modules/filter/filter.module';
import { InvoiceHelper } from './helpers/invoice.helper';
import { ItemNotesComponent } from './components/item-notes/item-notes.component';
import { ItemPeriodDetailsComponent } from './components/item-period-details/item-period-details.component';
import { NotesService } from './services/notes.service';
import { PercentageDrawDialogComponent } from './components/percentage-draw-dialog/percentage-draw-dialog.component';
import { PeriodsModule } from './modules/periods/periods.module';
import { PhotoGalleryOpenerService } from './services/photo-gallery-opener.service';
import { RangeModalModule } from './shared/range-modal/range-modal.module';
import { SharedModule } from '../shared/shared.module';
import { SubcontractorsDispatchersService, SubcontractorsSelectorsService } from '../store/subcontractors';
import { TableChangeDetectionService } from './services/table-change-detection.service';
import { TableConfigBudgetColumnsService } from './services/table-config-budget-columns.service';
import { TableConfigColumnsService } from './services/table-config-columns.service';
import { TotalPriceSetDialogComponent } from './components/total-price-set-dialog/total-price-set-dialog.component';
import { VatRateUpdateModalComponent } from './components/vat-rate-update-modal/vat-rate-update-modal.component';
import { SubcontractorsModule } from './modules/subcontractors/subcontractors.module';

@NgModule({
  declarations: [
    BuildingProgressComponent,
    BuildingProgressContainerComponent,
    BuildingProgressDetailComponent,
    BuildingProgressDetailToolbarComponent,
    BuildingProgressInvoicePickerComponent,
    BuildingProgressPanelDialogComponent,
    BuildingProgressSharingDialogComponent,
    CompleteDialogComponent,
    PercentageDrawDialogComponent,
    TotalPriceSetDialogComponent,
    ItemPeriodDetailsComponent,
    BuildingProgressDetailToolbarContainerComponent,
    BuildingProgressMobileTableComponent,
    BuildingProgressMobileItemComponent,
    BuildingProgressDetailMobileToolbarComponent,
    BuildingProgressCreateInvoiceComponent,
    BuildingProgressInvoiceLicenseInfoComponent,
    BuildingProgressInvoiceMobileComponent,
    BuildingProgressSummaryPanelComponent,
    BuildingProgressCreateComponent,
    ItemNotesComponent,
    BuildingProgressPartialItemDialogComponent,
    BuildingProgressPartialItemTableComponent,
    BuildingProgressPartialItemSplitterComponent,
    VatRateUpdateModalComponent
  ],
  imports: [
    KrosDropdownModule,
    AlertDialogModule,
    AnalyticsModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    SharedModule,
    DataTableModule,
    StoreModule.forFeature(buildingProgressFeatureKey, reducer),
    SidePanelModule,
    KrosInputsModule,
    NgxChartsModule,
    PipesModule,
    ChartModule,
    BudgetStructureModule,
    BudgetBoqModule,
    LicenseModule.forRoot(LicenseService),
    ColorCodeModule,
    BuildingProgressRoutingModule,
    PeriodsModule,
    FilterModule,
    AvatarModule,
    CommentsPanelModule,
    ExportModule,
    RangeModalModule,
    BuildingProgressDashboardComponent,
    BuildingProgressPieChartComponent,
    BuildingProgressDashboardTableComponent,
    BuildingObjectsUploadModalComponent,
    BuildingObjectsUploadButtonComponent,
    GenericInfoDialogModule,
    SharedLicenseModule,
    StatisticsModule.forRoot(() => environment.appUrls.titanGatewayUrl),
    SubcontractorsModule,
    DxNumberBoxModule,
    DxDataGridModule,
    CompanyModule.forRoot(() => environment.appUrls.titanGatewayUrl),
    SettingsModule,
    LetDirective,
    BuildingObjectsModule,
    BudgetSharedModule
  ],
  providers: [
    {
      provide: MarketingContentHelper,
      useClass: BuildingProgressMarketingContentHelper
    },
    BuildingProgressPartialItemsService,
    BuildingProgressDispatchersService,
    BuildingProgressPartialItemsDispatchersService,
    BuildingProgressPartialItemsSelectorsService,
    BuildingProgressPeriodDispatchersService,
    BuildingProgressEditingService,
    BuildingProgressPercentageCompleteService,
    BuildingProgressDeleteItemsService,
    BuildingProgressPeriodService,
    BuildingProgressSelectorsService,
    BuildingProgressService,
    BuildingProgressSharingService,
    BuildingProgressActionAccessService,
    BuildingProgressEffectsService,
    BuildingProgressBoqEffectsService,
    BuildingProgressViewsService,
    DetailHelper,
    DetailMobileHelper,
    PermissionHelper,
    DetailsService,
    DatePipe,
    Overlay,
    UndoRedoSelectorsService,
    UndoRedoDispatchersService,
    BoqService,
    BoqEditationService,
    BuildingProgressPanelHelper,
    PeriodHelper,
    VisualHelper,
    BuildingProgressTableService,
    TableConfigColumnsService,
    TableConfigBudgetColumnsService,
    EditingPanelHelper,
    TableChangeDetectionService,
    PhotoGalleryOpenerService,
    BoqVisualHelper,
    BuildingProgressDataSettingsDispatchersService,
    BuildingProgressCommentsDispatchersService,
    SubcontractorsDispatchersService,
    SubcontractorsSelectorsService,
    InvoiceHelper,
    BuildingProgressExportDispatchersService,
    NotesService,
    ApprovalOrderService,
    SetTypeService,
    UISettingsService,
    provideAppInsights(() => ({
      instrumentationKey: environment.applicationInsightsKey,
      aiCloudRole: 'Kros.Ssw.FE.BuildingProgress',
      samplingPercentage: 0,
      customConfig: { enableAutoRouteTracking: true }
    })),
    AppInsightsUserContextHelper,
    LicenseInfoContentHelper
  ]
})
export class BuildingProgressModule {
}
