import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { filter } from 'rxjs/operators';

import {
  BudgetStructureService,
  Cell,
  DataTableComponent,
  DetailBaseComponent,
  isSummaryItem,
  PanelHelper,
  Position,
  SidePanelComponent,
  TranslateService
} from '@kros-sk/ssw-shared-legacy';
import { KrosModalService } from '@kros-sk/components';

import { BudgetApprovalDispatchersService, BudgetApprovalSelectorsService } from '../../../store/budget-approval';
import { BudgetApprovalItemPanelDialogComponent } from '../budget-approval-item-panel-dialog/budget-approval-item-panel-dialog.component';
import { BudgetChangeModel } from '../../models';
import { BudgetChangeTableService } from '../../services';
import { environment } from '../../../../environments/environment';
import { ExportHelper, RelationHelper } from '../../helpers';
import { LicenseHelper } from '../../../building-progress/helpers';
import { ProjectsSelectorsService } from '../../../store/projects';

export const fixedColumnWidth = 724;

@Component({
  selector: 'app-budget-changes-detail',
  templateUrl: './budget-changes-detail.component.html',
  styleUrls: ['./budget-changes-detail.component.scss'],
  providers: [BudgetChangeTableService, RelationHelper, LicenseHelper]
})
export class BudgetChangesDetailComponent extends DetailBaseComponent implements OnInit, OnDestroy {

  @ViewChild(SidePanelComponent) sidePanel: SidePanelComponent;

  @ViewChild(DataTableComponent) set tableComponent(value: DataTableComponent) {
    this.table = value;

    if (this.table && this.scrollElementToViewParams) {
      this.scrollElementToView(
        this.scrollElementToViewParams.elementId,
        this.scrollElementToViewParams.elementIdForColumnWidth);
      this.scrollElementToViewParams = undefined;
    }
  }

  @Input() tableId: string;

  private budgetObjectId: number;
  private previousRowIndex: number;

  get canOpenBoq(): boolean {
    return !!this.tableData && !!this.focusedCell;
  }

  constructor(
    public panelHelper: PanelHelper,
    public selector: BudgetApprovalSelectorsService,
    public tableService: BudgetChangeTableService,
    private activatedRoute: ActivatedRoute,
    private budgetStructureService: BudgetStructureService,
    private dispatcher: BudgetApprovalDispatchersService,
    private projectsSelectorsService: ProjectsSelectorsService,
    private exportHelper: ExportHelper,
    private licenseHelper: LicenseHelper,
    private router: Router,
    private krosModalService: KrosModalService,
    private translateService: TranslateService
  ) {
    super(environment.location);
  }

  ngOnInit(): void {
    this.budgetObjectId = +this.activatedRoute.snapshot.paramMap.get('budgetObjectId');
    this.projectId = +this.activatedRoute.parent.snapshot.paramMap.get('projectId');

    this.subs.sink = this.selector.budgetChangeData$.subscribe(p => this.setData(p));
    this.subs.sink = this.projectsSelectorsService.projectDetail$
      .pipe(filter(p => !!p))
      .subscribe(p => this.setProjectId(p.id));
    this.subs.sink = this.budgetStructureService.setFocusToBudgetItemRequested$
      .pipe(filter(result => result.tableId === this.tableId))
      .subscribe(result => this.setFocusToItemById(result.id));
    this.subs.sink = this.panelHelper.loadBoqItems$
      .subscribe(i => this.dispatcher.loadBoqItems(this.projectId, i));

    this.dispatcher.loadSharingList(this.projectId);
    this.dispatcher.loadBudgetApprovalData(this.projectId);
    this.dispatcher.loadBudgetChangeData(this.projectId, this.budgetObjectId);
    this.fixedColumnWidth = fixedColumnWidth;

    this.panelHelper.init(this.showPanel.bind(this), this.hidePanel.bind(this), this.resizePanel.bind(this));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.tableService.destroy();
    this.licenseHelper.destroy();
    this.panelHelper.onCloseBoq();
  }

  private setFocusToItemById(id: number): void {
    this.focusedCell = this.getFocusedCell(this.focusedCell?.colId ?? 'description',
      this.tableData.items.findIndex(i => i.id === id),
      this.focusedCell?.params);

    super.focusCell();
  }

  private getFocusedCell(colId: string, rowIndex: number, params?: any): Cell {
    return { colId, rowIndex, params: params ?? { periodId: 0, periodColumn: '' } };
  }

  onCellFocused(event: any): void {
    super.onCellFocused(event);

    if (this.previousRowIndex !== this.focusedCell.rowIndex) {
      this.panelHelper.loadBoq(this.tableData?.items[this.focusedCell.rowIndex]);
      this.previousRowIndex = this.focusedCell.rowIndex;
    }
    this.setFocusToStructureItemById();
  }

  private setFocusToStructureItemById(): void {
    if (this.focusedCell && this.hasData) {
      const item = this.tableData.items[this.focusedCell.rowIndex];

      if (isSummaryItem(item)) {
        this.budgetStructureService.setFocusToStructureItemById(item.id, this.tableId);
      } else {
        this.budgetStructureService.setFocusToStructureItemById(item.parentId, this.tableId);
      }
    }
  }

  private setData(data: BudgetChangeModel): void {
    this.tableData = data;
    this.dataTableConfig = this.hasData ?
      this.tableService.getTableConfig(this.tableData, this.budgetObjectId) :
      undefined;

    if (this.tableData?.items.length <= this.focusedCell?.rowIndex) {
      this.focusedCell.rowIndex = this.tableData.items.length - 1;
    }

    this.focusCell();
    setTimeout(() => {
      this.setFocusToStructureItemById();
    });
  }

  private setProjectId(projectId: number): void {
    if (this.projectId && this.projectId !== projectId) {
      this.router.navigate(['projects/project-detail', projectId, 'budget-approval']);
    }
  }

  onBackClick(): void {
    this.router.navigate(['projects/project-detail', this.projectId, 'budget-approval']);
  }

  onShowBudgetChangesStructure(): void {
    this.krosModalService.openCentered(
      BudgetApprovalItemPanelDialogComponent,
      {
        title: this.translateService.translate('BUDGET_CHANGES.STRUCTURE'),
        tableId: this.tableId,
        selectorData: this.selector.budgetApprovalData$
      },
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: false,
        showMobileArrowBack: false
      },
      NaN,
      NaN,
      undefined,
      'kros-modal-panel-left'
    );
  }

  onExportClick(): void {
    this.exportHelper.exportBudgetApprovalBudget(this.projectId, this.budgetObjectId);
  }

  onOpenBoqClick(): void {
    this.panelHelper.openBoq(this.tableData.items[this.focusedCell.rowIndex]);
    this.scrollFocusedCellIntoView(300);
  }

  private showPanel(position: Position, size: number): void {
    this.sidePanel?.showPanel(position, size);
  }

  private hidePanel(position: Position): void {
    this.sidePanel?.hidePanel(position);
  }

  private resizePanel(position: Position, size: number): void {
    this.sidePanel?.resizePanel(position, size);
  }
}
