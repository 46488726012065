<ng-container *ngIf="viewModel$ | async as viewModel">
  <div
    *ngIf="viewModel.isLoggedIn"
    class="account"
    id="account"
  >
    <kros-avatar
      class="avatar-s avatar-text"
      data-test="user-profile"
      triggers="manual"
      (click)="showUserSideBar()"
      [options]="{text: getNameFromEmail(viewModel.user?.email)}"
    >
    </kros-avatar>
  </div>
</ng-container>

<ng-template #entityUserSelectorModal>
  <kros-user-shell [hide]="hide">
    <ng-container *ngTemplateOutlet="entitySelector; context: { hide: hide }"></ng-container>
  </kros-user-shell>
</ng-template>
