import { Component, EventEmitter, Input, Output } from '@angular/core';

import { formatNumberValue } from '@kros-sk/ssw-shared-legacy';

import { BuildingProgressSummaryPanelModel } from '../../../building-progress/models/building-progress-summary-panel.model';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-building-progress-summary-panel',
  templateUrl: './building-progress-summary-panel.component.html',
  styleUrls: ['./building-progress-summary-panel.component.scss']
})
export class BuildingProgressSummaryPanelComponent {
  @Input() model: BuildingProgressSummaryPanelModel;

  @Output() close: EventEmitter<any> = new EventEmitter();

  onClose(): void {
    this.close.emit();
  }

  formatNumber(value: number): string {
    return this.model.decimalPlaces === undefined || isNaN(value) ? '' :
      formatNumberValue(value, this.model.decimalPlaces, environment.location);
  }
}
