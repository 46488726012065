import { Subscription } from 'rxjs';

import { KrosModalService } from '@kros-sk/components';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

import { getAppInsightsPrefix } from '../../../shared/building-progress-utils';
import { PermissionHelper } from '../../../helpers';
import {
  ProjectHeaderDataDialogComponent
} from '../../../../shared/components/project-header-data-dialog/project-header-data-dialog.component';
import { ProjectsDispatchersService } from '../../../../store/projects';

/**
 * Open header settings edit dialog
 * @param modalService KrosModalService instance
 * @param projectsDispatcherService ProjectsDispatcherService instance
 * @param appInsightsService AppInsightsBaseService instance
 * @param projectId id of the project
 * @param isContractor true if the user is contractor false otherwise
 * @param subcontractorId id of the subcontractor for whom we want to edit header settings
 * @returns subscription
 */
export const openHeaderSettingsDialog = (
  modalService: KrosModalService,
  projectsDispatcherService: ProjectsDispatchersService,
  permissionHelper: PermissionHelper,
  appInsightsService: AppInsightsBaseService,
  projectId: number,
  isContractor: boolean,
  subcontractorId?: number
): Subscription => {
  appInsightsService.trackEvent(`${getAppInsightsPrefix(isContractor)}export-header-data-open`);
  const modalRef = modalService.openCentered(
    ProjectHeaderDataDialogComponent,
    {
      subcontractorId,
      projectId,
      canEdit: !permissionHelper.isLessThanContributor || permissionHelper.isContractor
    },
    {
      addModalToBrowsersHistory: false
    }
  );

  return modalRef.afterClosed$
    .subscribe(result => {
      if (result.type === 'submit' && result.data.project) {
        projectsDispatcherService.editProjectDetail(result.data.project, subcontractorId);
        appInsightsService.trackEvent(
          `${getAppInsightsPrefix(isContractor)}export-header-data-edit`,
          result.data.project
        );
      }
    });
};
