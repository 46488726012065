import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter, Observable, throwError, withLatestFrom } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { AuthSelectorsService } from '@kros-sk/auth';
import { Project, ToastService, ToastType, TranslateService } from '@kros-sk/ssw-shared-legacy';

import { BuildingProgressBasePathSegment } from '../model';

const projectListEndpoint = '/projectExList';

@Injectable()
export class SettingService {

  get previousUrl(): string {
    return !!this._previousUrl ? this._previousUrl : '/';
  }

  projectId: number;

  private _previousUrl: string;
  private settingRouterLink: string;

  private get projectApi(): string {
    return this.appConfig.appUrls.titanGatewayUrl + '/api/projectService/Projects';
  }

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any,
    private toastService: ToastService,
    private translateService: TranslateService,
    authSelectorsService: AuthSelectorsService,
    router: Router
  ) {
    router.events
      .pipe(
        filter((e: any) => e instanceof NavigationEnd && !e.urlAfterRedirects.startsWith('/settings')),
        withLatestFrom(authSelectorsService.featureFlags$)
      ).subscribe(([e, ff]) => {
        this._previousUrl = e.urlAfterRedirects;
        this.settingRouterLink = this.previousUrl.includes(BuildingProgressBasePathSegment)
          ? BuildingProgressBasePathSegment
          : this.previousUrl.includes('budget/')
            ? 'budget'
            : 'general';
      });
  }

  getSettingsRouterLink(projectId: string): string[] {
    return ['settings', projectId, this.settingRouterLink];
  }

  getProjects(): Observable<Project[]> {
    return this.http.get<Project[]>(this.projectApi + projectListEndpoint);
  }

  handleError(error: HttpErrorResponse): Observable<never> {
    this.toastService.openAndCloseOthersByType(
      this.translateService.translate('SETTINGS.VAT.SET_SETTINGS_ERROR'),
      ToastType.Error,
      { delay: 3000 });
    return throwError(() => error);
  }
}
