import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { catchError, Observable, throwError } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { ToastService, ToastType, TranslateService } from '@kros-sk/ssw-shared-legacy';

import { ApprovalOrder } from '../models';

const gatewayApi = '/api/projects/';
const buildingProgress = '/buildingProgressService';
const loadApprovalOrderListEndpoint = '/BuildingProgress/approvalOrderList';
const saveApprovalOrderListEndpoint = '/BuildingProgress/approvalOrderListEdit';

@Injectable()
export class ApprovalOrderService {

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    @Inject(APP_CONFIG) private appConfig: any,
    private toastService: ToastService
  ) { }

  loadApprovalOrder(projectId: number): Observable<ApprovalOrder[]> {
    return this.http.get<ApprovalOrder[]>(this.getEndpointPath(projectId, loadApprovalOrderListEndpoint))
      .pipe(catchError(err => this.handleErr(err)));
  }

  saveApprovalOrder(approvalOrders: ApprovalOrder[], projectId: number): Observable<any> {
    return this.http.post(this.getEndpointPath(projectId, saveApprovalOrderListEndpoint), { approvalOrders })
      .pipe(catchError(err => this.handleErr(err)));
  }

  private handleErr(err: HttpErrorResponse): Observable<never> {
    const errorMessage = this.translateService.translate(err.status === 403
      ? 'BUILDING_PROGRESS.NO_PERMISSION'
      : 'BUILDING_PROGRESS.ERROR.GENERAL_ERROR'
    );
    this.toastService.open(errorMessage, ToastType.Error);

    return throwError(() => new Error(err.message));
  }

  private get gatewayApi(): string {
    return this.appConfig.appUrls.titanGatewayUrl + gatewayApi;
  }

  private getEndpointPath(projectId: number, endpoint: string): string {
    return this.gatewayApi + projectId + buildingProgress + endpoint;
  }
}
