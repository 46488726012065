import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ToastService, ToastType, TranslateService } from '@kros-sk/ssw-shared-legacy';

import { BudgetModel } from '../../../../budget-approval/models';
import { BuildingProgressPeriod } from '../../../../building-progress/models/building-progress-period.model';
import { BuildingProgressSharing } from '../../../../building-progress/models/building-progress-sharing/building-progress-sharing.model';
import {
  BuildingProgressSharingResultInfo
} from '../../../../building-progress/models/building-progress-sharing/building-progress-sharing-result-info.model';
import { BuildingProgressSharingService } from '../../../../building-progress/services/building-progress-sharing.service';
import { DeleteItemModel } from '../../../../building-progress/models/item-list-delete.model';
import { PeriodDateInterval } from '../../../../building-progress/models/period-date-interval.model';
import { Subcontractor, SubcontractorsSharingViewModel } from '../models';
import { environment } from '../../../../../environments/environment';

const projectsApi = '/api/projects';
const buildingProgressService = '/buildingProgressService';
const subcontractorsController = buildingProgressService + '/contractors';
const subcontractorsListEndpoint = '/list';
const createSubcontractorEndpoint = '/create';
const removeSubcontractorEndpoint = '/delete';
const editSubcontractorEndpoint = '/edit';
const budgetItemListCreateEndpoint = '/budgetItemListCreate';
const budgetDataEndpoint = '/budget/data';
const sharingListEndpoint = '/sharing/contractorSharingList';
const shareEndpoint = '/sharing/contractorShare';
const itemListDeleteEndpoint = '/itemListDelete';
const subcontractorsPeriodsEndpoint = '/periods/contractorPeriodList';
const periodListSetEndpoint = '/periods/contractorPeriodListSet';

@Injectable({
  providedIn: 'root'
})
export class SubcontractorsService {

  private get projectsApi(): string {
    return environment.appUrls.titanGatewayUrl + projectsApi;
  }

  constructor(
    private http: HttpClient,
    private buildingProgressSharingService: BuildingProgressSharingService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) { }

  loadSubcontractors(projectId: number): Observable<Subcontractor[]> {
    return this.http.get<Subcontractor[]>(`${this.projectsApi}/${projectId}${subcontractorsController}${subcontractorsListEndpoint}`);
  }

  loadDialogItemsList(projectId: number): Observable<BudgetModel> {
    return this.http.get<BudgetModel>(`${this.projectsApi}/${projectId}${buildingProgressService}${budgetDataEndpoint}`);
  }

  createSubcontractor(projectId: number, name: string): Observable<number> {
    return this.http.post<number>(
      `${this.projectsApi}/${projectId}${subcontractorsController}${createSubcontractorEndpoint}`,
      { name }
    );
  }

  editSubcontractor(projectId: number, contractorData: Subcontractor): Observable<number> {
    return this.http.put<number>(
      `${this.projectsApi}/${projectId}${subcontractorsController}${editSubcontractorEndpoint}`,
      contractorData
    );
  }

  removeSubcontractor(projectId: number, contractorId: number): Observable<number> {
    return this.http.delete<number>(
      `${this.projectsApi}/${projectId}${subcontractorsController}${removeSubcontractorEndpoint}/${contractorId}`
    );
  }

  addSubcontractorItems(projectId: number, contractorId: number, budgetItemIds: number[]): Observable<any> {
    return this.http.post(
      `${this.projectsApi}/${projectId}${subcontractorsController}${budgetItemListCreateEndpoint}`,
      {
        contractorId,
        budgetItemIds: budgetItemIds.filter(id => id > 0),
        partialBudgetItemIds: budgetItemIds.filter(id => id < 0).map(id => -id)
      }
    );
  }

  removeSubcontractorItems(projectId: number, contractorId: number, deleteItemsModel: DeleteItemModel): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(deleteItemsModel)
    };
    return this.http.delete(
      `${this.projectsApi}/${projectId}/contractors/${contractorId}` +
      `${buildingProgressService}/buildingProgress${itemListDeleteEndpoint}`,
      options
    );
  }

  getSharingList(projectId: number, contractorId?: number): Observable<BuildingProgressSharing[]> {
    return this.http.get<BuildingProgressSharing[]>(
      `${this.projectsApi}/${projectId}${buildingProgressService}${sharingListEndpoint}${contractorId ? '/' + contractorId : ''}`
    ).pipe(map((res) => {
      res.forEach(p => p.additionalPermissionType = p.buildingProgressPermissionType);
      return res;
    }));
  }

  share(projectId: number, shareViewModel: SubcontractorsSharingViewModel): Observable<BuildingProgressSharingResultInfo> {
    return this.http.post<BuildingProgressSharingResultInfo>(
      `${this.projectsApi}/${projectId}${buildingProgressService}${shareEndpoint}`,
      shareViewModel)
      .pipe(
        catchError(this.buildingProgressSharingService.handleShareError.bind(this))
      );
  }

  getSubcontractorsPeriods(projectId: number): Observable<BuildingProgressPeriod[]> {
    return this.http.get<BuildingProgressPeriod[]>(
      `${this.projectsApi}/${projectId}${buildingProgressService}${subcontractorsPeriodsEndpoint}`,
    );
  }

  setSubcontractorsPeriods(
    projectId: number,
    contractorId: number,
    dateFrom: string,
    dateTo: string,
    areDetailsIncluded: boolean,
    targetPeriod?: PeriodDateInterval): Observable<any> {
    return this.http.put(
      `${this.projectsApi}/${projectId}${buildingProgressService}${periodListSetEndpoint}`,
      {
        contractorId,
        periods: [{ dateFrom, dateTo }],
        areDetailsIncluded,
        ...(targetPeriod && { dateFrom: targetPeriod.dateFrom, dateTo: targetPeriod.dateTo })
      }
    ).pipe(
      tap((res) => this.toastService.open(
        this.translateService.translate(res.notEditedPeriodItemsCount === 0
          ? 'SUBCONTRACTORS.TRANSFER_EXECUTIONS_SUCCESS'
          : 'SUBCONTRACTORS.TRANSFER_EXECUTIONS_PARTIAL_SUCCESS'),
        ToastType.Info
      ))
    );
  }
}
