import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BlobDownloadResponseModel } from '@azure/storage-blob';
import { catchError, forkJoin, map, Observable, of, switchMap, throwError, timeout } from 'rxjs';

import { BuildingProgressPermission } from '@kros-sk/ssw-shared/permission';
import { BuildingProgressSettings } from '@kros-sk/ssw-shared/settings';
import {
  DocumentStorageService,
  getExportFileNameExtension,
  getExportMimeType,
  getQuery,
  getSearchQuery,
  QueueActiveState,
  SearchModel,
  SetColorCodeModel,
  SetColorCodeResult,
  ToastService,
  ToastType,
  TranslateService,
} from '@kros-sk/ssw-shared-legacy';
import { downloadFile } from '@kros-sk/ssw-cdk';

import { BlobAccessDataModel } from '../models/blob-access-data.model';
import {
  BuildingProgressAmountsUniXmlExportModel,
  BuildingProgressConfirmationProtocolExportModel,
  BuildingProgressInvoiceDetailExportModel,
  BuildingProgressItemDrawSheetModel,
  BuildingProgressRemainingBudgetModel,
  BuildingProgressTableExportModel
} from '../models/building-progress-export.model';
import { BuildingProgressCommentModel, CommentPositionModel } from '../modules/comments-panel/comments-panel/comment.model';
import { BuildingProgressDeleteItem } from '../models/building-progress-delete-item.model';
import { BuildingProgressEditModel } from '../models/building-progress-edit.model';
import { BuildingProgressItem, BuildingProgressModel, PeriodItemsEditResult } from '../models/construction-data.model';
import { BuildingProgressPercentageCompleteModel } from '../models/building-progress-percentage-complete.model';
import { BuildingProgressSetModel } from '../models/building-progress-set.model';
import { BuildingProgrressEditPercentageModel } from '../models/building-progress-edit-percentage.model';
import { DataSettings } from '../models/data-settings.model';
import { DeleteItemModel } from '../models/item-list-delete.model';
import { environment } from '../../../environments/environment';
import { InvoicingExportSettings } from '../models/invoicing-export-settings.model';
import { ItemDrawSheetExportSettings } from '../models/item-draw-sheet-export-settings.model';
import { RemainingBudgetExportSettings } from '../models/remaining-budget-export-settings.model';
import { SetAutocalculatedAmountModel } from '../models/set-autocalculated-amount.model';
import { SummaryDashboardModel } from '../../building-progress/models/summary-dashboard.model';
import { TableExportSettings } from '../models/table-export-settings.model';
import { TotalPriceSetModel } from '../models/total-price-set.model';

const buildingProgressApi = '/api/buildingProgressService';
const gatewayApi = '/api/projects/';
const contractorsApi = '/contractors/';
const constructionDataEndpoint = '/buildingProgress/data';
const permissionEndpoint = '/buildingProgress/permission/';
const periodItemEditEndpoint = '/periodItems/periodItemEdit';
const periodItemSetEndpoint = '/periodItems/periodItemSet';
const percentageSetEndpoint = '/periodItems/percentageSet';
const percentageCompleteEndpoint = '/periodItems/percentageComplete/';
const totalPriceSetEndpoint = '/periodItems/totalPriceSet';
const setAutocalculatedAmountEndpoint = '/periodItems/autoCalculatedAmountSet/';
const notCompletedItemsEndpoint = '/periodItems/notCompletedSet';
const commentsController = '/comments';
const exportController = '/export';
const exportSettingsController = '/export';
const commentsListEndpoint = commentsController + '/list';
const commentPositionsEndpoint = commentsController + '/positionList';
const commentsCreateEndpoint = commentsController + '/create';
const commentsEditEndpoint = commentsController + '/edit';
const commentsDeleteEndpoint = commentsController + '/delete/';
const commentsPublishEndpoint = commentsController + '/publish';
const getTableExportSettingsEndpoint = exportSettingsController + '/tableExportSettings';
const exportTableEndpoint = exportController + '/tableExport';
const getInvoicingExportSettingsEndpoint = exportSettingsController + '/invoicingDataExportSettings';
const exportInvoicingDataEndpoint = exportController + '/invoicingDataExport';
const exportConfirmationProtocolXmlEndpoint = exportController + '/xmlConfirmationProtocolExport';
const exportAmountsUniXmlEndpoint = exportController + '/uniXmlPeriodListExport';
const exportItemDrawSheet = exportController + '/itemDrawSheetExport';
const exportRemainingBudget = exportController + '/remainingBudgetExport';
const loadExportItemDrawSheetSettings = exportController + '/itemDrawSheetExportSettings';
const loadExportRemainingBudgetSettings = exportController + '/remainingBudgetExportSettings';
const summaryDashboardEndpoint = '/buildingProgress/summaryDashboard/';
const colorCodeSetEndpoint = '/budget/colorCodeSet';
const itemListDeleteEndpoint = '/buildingProgress/itemListDelete';
const loadSettingsEndpoint = '/buildingProgress/settings/';
const hasDocumentsEndpoint = '/PeriodDocuments/exists';
const getDataSettingsEndpoint = '/BuildingProgress/dataSettings';
const saveDataSettingsEndpoint = '/BuildingProgress/dataSettingsEdit';
const buildingProgress = '/buildingProgressService';
const budgetApi = '/budgetService';
const budgetHasItemsEndpoint = '/budget/hasItems';
const budgetCreationStartEndpoint = '/buildingProgressBudget/creationStart';
const budgetCreationActiveStateEndpoint = '/buildingProgressBudget/activeState';
const vatRateUpdateEndpoint = '/buildingProgress/reducedVatRateSet';

const exportTimeOutInMillisecond = 300000;

@Injectable()
export class BuildingProgressService {

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private documentStorageService: DocumentStorageService,
    private translateService: TranslateService,
    private toastService: ToastService
  ) { }

  private get buildingProgressApi(): string {
    return environment.appUrls.titanGatewayUrl + buildingProgressApi;
  }

  private get gatewayApi(): string {
    return environment.appUrls.titanGatewayUrl + gatewayApi;
  }

  getConstructionData(projectId: number, searchModel: SearchModel, hierarchyCode?: string, contractorId?: number)
    : Observable<BuildingProgressModel> {
    const queryString = hierarchyCode
      ? getQuery('hierarchyCode', hierarchyCode)
      : getSearchQuery(
        searchModel.searchMode.toString(),
        searchModel.searchText,
        searchModel.filter,
        ['Description', 'Code'],
        '?',
        searchModel.queryParams
      );
    return this.http.get<BuildingProgressModel>(
      this.getEndpointPath(projectId, `${constructionDataEndpoint}${queryString}`, contractorId),
      { params: { 'ui-culture': this.translateService.uiCulture } });
  }

  getPermissionType(projectId: number): Observable<BuildingProgressPermission> {
    return this.http.get<any>(this.buildingProgressApi + permissionEndpoint + projectId);
  }

  getComments(projectId: number, contractorId?: number): Observable<BuildingProgressCommentModel[]> {
    return this.http.get<BuildingProgressCommentModel[]>(this.getEndpointPath(projectId, commentsListEndpoint, contractorId));
  }

  getCommentPositions(projectId: number, contractorId?: number): Observable<CommentPositionModel[]> {
    return this.http.get<CommentPositionModel[]>(this.getEndpointPath(projectId, commentPositionsEndpoint, contractorId));
  }

  editConstructionData(editModel: BuildingProgressEditModel): Observable<PeriodItemsEditResult> {
    return this.http.put<PeriodItemsEditResult>(this.getEndpointPath(editModel.projectId, periodItemEditEndpoint), editModel);
  }

  setConstructionData(editModel: BuildingProgressSetModel): Observable<PeriodItemsEditResult> {
    return this.http.put<PeriodItemsEditResult>(this.getEndpointPath(editModel.projectId, periodItemSetEndpoint), editModel);
  }

  editPercentage(editModel: BuildingProgrressEditPercentageModel): Observable<PeriodItemsEditResult> {
    return this.http.put<PeriodItemsEditResult>(this.getEndpointPath(editModel.projectId, percentageSetEndpoint), editModel);
  }

  createComment(comment: BuildingProgressCommentModel, contractorId?: number): Observable<BuildingProgressCommentModel> {
    return this.http.post<BuildingProgressCommentModel>(
      this.getEndpointPath(comment.projectId, commentsCreateEndpoint, contractorId), comment);
  }

  updateComment(comment: BuildingProgressCommentModel): Observable<BuildingProgressCommentModel> {
    return this.http.put<BuildingProgressCommentModel>(this.getEndpointPath(comment.projectId, commentsEditEndpoint), comment);
  }

  deleteComment(commentId: number, projectId: number, contractorId?: number): Observable<any> {
    return this.http.delete<BuildingProgressCommentModel>(
      this.getEndpointPath(projectId, commentsDeleteEndpoint + commentId, contractorId));
  }

  publishComments(projectId: number, contractorId?: number): Observable<any> {
    return this.http.post(
      this.getEndpointPath(projectId, commentsPublishEndpoint, contractorId),
      {
        projectId,
        datePublished: this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm')
      });
  }

  setAutocalculatedAmount(autocalculatedAmountModel: SetAutocalculatedAmountModel): Observable<PeriodItemsEditResult> {
    return this.http.put<PeriodItemsEditResult>(this.buildingProgressApi + setAutocalculatedAmountEndpoint, autocalculatedAmountModel);
  }

  completePercentage(percentageCompleteModel: BuildingProgressPercentageCompleteModel): Observable<PeriodItemsEditResult> {
    return this.http.put<PeriodItemsEditResult>(
      this.getEndpointPath(percentageCompleteModel.projectId, percentageCompleteEndpoint), percentageCompleteModel);
  }

  getTableExportSettings(): Observable<TableExportSettings> {
    return this.http.get<TableExportSettings>(this.buildingProgressApi + getTableExportSettingsEndpoint);
  }

  exportTable(exportModel: BuildingProgressTableExportModel, uiCulture: string, projectName: string): Observable<any> {
    return this.http.post(
      this.getEndpointPath(exportModel.projectId, exportTableEndpoint, exportModel.subcontractorId),
      exportModel,
      { params: { 'ui-culture': uiCulture } })
      .pipe(
        timeout(exportTimeOutInMillisecond),
        switchMap(result => this.documentStorageService.downloadDocument(result)),
        switchMap((resp: BlobDownloadResponseModel) => resp.blobBody),
        map(blob => {
          this.downloadFile(blob, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            projectName + ' - ' + this.translateService.translate('BUILDING_PROGRESS.TITLE') + '.xlsx');
        })
      );
  }

  getInvoicingSettings(): Observable<InvoicingExportSettings> {
    return this.http.get<InvoicingExportSettings>(this.buildingProgressApi + getInvoicingExportSettingsEndpoint);
  }

  exportInvoicingData(
    exportModel: BuildingProgressInvoiceDetailExportModel,
    uiCulture: string,
    projectName: string
  ): Observable<any> {
    return this.http.post(
      this.getEndpointPath(exportModel.projectId, exportInvoicingDataEndpoint, exportModel.subcontractorId),
      exportModel,
      { params: { 'ui-culture': uiCulture } })
      .pipe(
        timeout(exportTimeOutInMillisecond),
        switchMap(result => this.documentStorageService.downloadDocument(result)),
        switchMap((resp: BlobDownloadResponseModel) => resp.blobBody),
        map(blob => this.downloadFile(blob, getExportMimeType(exportModel.exportFileType),
          projectName + ' - ' + this.translateService.translate('BUILDING_PROGRESS.EXPORT_INVOICE_DETAILS')
          + getExportFileNameExtension(exportModel.exportFileType)))
      );
  }

  exportAmountsUniXml(exportModel: BuildingProgressAmountsUniXmlExportModel, uiCulture: string): Observable<any> {
    return this.http.post<BlobAccessDataModel[]>(
      this.getEndpointPath(exportModel.projectId, exportAmountsUniXmlEndpoint, exportModel.subcontractorId),
      exportModel,
      { params: { 'ui-culture': uiCulture } })
      .pipe(
        timeout(exportTimeOutInMillisecond),
        map((data) => {
          data.forEach(result => {
            this.documentStorageService.downloadDocument(result).then(response => {
              response.blobBody.then(blobBody => this.downloadFile(blobBody, 'application/xml', result.name + '.xml'));
            });
          });
        }));
  }

  exportConfirmationProtocolXml(
    exportModel: BuildingProgressConfirmationProtocolExportModel,
    uiCulture: string
  ): Observable<any> {
    return this.http.post<{ name: string; blobUri: string }>(
      this.getEndpointPath(exportModel.projectId, exportConfirmationProtocolXmlEndpoint),
      exportModel,
      { params: { 'ui-culture': uiCulture } })
      .pipe(
        timeout(exportTimeOutInMillisecond),
        switchMap(result => forkJoin([of(result.name), this.documentStorageService.downloadDocument(result)])),
        switchMap(resp => forkJoin([of(resp[0]), resp[1].blobBody])),
        map(resp => {
          this.downloadFile(resp[1], 'application/xml', resp[0]);
        })
      );
  }

  exportItemDrawSheet(exportModel: BuildingProgressItemDrawSheetModel, uiCulture: string, projectName: string): Observable<any> {
    return this.http.post<BlobDownloadResponseModel>(
      this.getEndpointPath(exportModel.projectId, exportItemDrawSheet),
      exportModel,
      { params: { 'ui-culture': uiCulture } })
      .pipe(
        timeout(exportTimeOutInMillisecond),
        switchMap(result => this.documentStorageService.downloadDocument(result)),
        switchMap((resp: BlobDownloadResponseModel) => resp.blobBody),
        map(blob => {
          this.downloadFile(blob, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            projectName + ' - ' + this.translateService.translate('BUILDING_PROGRESS.EXPORT_ITEM_DRAW_SHEET') + '.xlsx');
        })
      );
  }

  exportRemainingBudget(exportModel: BuildingProgressRemainingBudgetModel, uiCulture: string, projectName: string): Observable<any> {
    return this.http.post<BlobDownloadResponseModel>(
      this.getEndpointPath(exportModel.projectId, exportRemainingBudget, exportModel.subcontractorId),
      exportModel,
      { params: { 'ui-culture': uiCulture } })
      .pipe(
        timeout(exportTimeOutInMillisecond),
        switchMap(result => this.documentStorageService.downloadDocument(result)),
        switchMap((resp: BlobDownloadResponseModel) => resp.blobBody),
        map(blob => {
          this.downloadFile(blob, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            projectName + ' - ' + this.translateService.translate('BUILDING_PROGRESS.EXPORT_REMAINING_BUDGET') + '.xlsx');
        })
      );
  }

  private downloadFile(blob: Blob, type: string, fileName: string): void {
    const file = new Blob([blob], { type });
    downloadFile(window.URL.createObjectURL(file), fileName);
  }

  getSummaryDashboardData(projectId: number, contractorId?: number): Observable<SummaryDashboardModel> {
    return this.http.get<SummaryDashboardModel>(this.getEndpointPath(projectId, summaryDashboardEndpoint, contractorId));
  }

  totalPriceSet(totalPriceSetModel: TotalPriceSetModel): Observable<PeriodItemsEditResult> {
    return this.http.put<PeriodItemsEditResult>(
      this.getEndpointPath(totalPriceSetModel.projectId, totalPriceSetEndpoint), totalPriceSetModel);
  }

  colorCodeSet(setColorCodeModel: SetColorCodeModel): Observable<SetColorCodeResult> {
    return this.http.patch<SetColorCodeResult>(this.buildingProgressApi + colorCodeSetEndpoint, setColorCodeModel);
  }

  itemListDelete(projectId: number, deleteItemsModel: DeleteItemModel): Observable<BuildingProgressDeleteItem> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', }),
      body: JSON.stringify(deleteItemsModel)
    };
    return this.http.delete<BuildingProgressDeleteItem>(this.getEndpointPath(projectId, itemListDeleteEndpoint), options);
  }

  loadSettings(projectId: number): Observable<BuildingProgressSettings> {
    return this.http.get<BuildingProgressSettings>(this.getEndpointPath(projectId, loadSettingsEndpoint))
      .pipe(catchError(err => this.handleErr(err)));
  }

  hasBuildingProgressDocuments(projectId: number): Observable<boolean> {
    return this.http.get<boolean>(this.getEndpointPath(projectId, hasDocumentsEndpoint));
  }

  loadDataSettings(): Observable<DataSettings> {
    return this.http.get<DataSettings>(this.buildingProgressApi + getDataSettingsEndpoint);
  }

  saveDataSettings(dataSettings: DataSettings): Observable<void> {
    return this.http.patch<void>(this.buildingProgressApi + saveDataSettingsEndpoint, dataSettings);
  }

  loadExportItemDrawSheetSettings(projectId: number): Observable<ItemDrawSheetExportSettings> {
    return this.http.get<ItemDrawSheetExportSettings>(this.getEndpointPath(projectId, loadExportItemDrawSheetSettings));
  }

  loadRemainingBudgetExportSettings(projectId: number): Observable<RemainingBudgetExportSettings> {
    return this.http.get<RemainingBudgetExportSettings>(this.getEndpointPath(projectId, loadExportRemainingBudgetSettings));
  }

  setIsNotCompletedItemsState(
    projectId: number, budgetItemIds: number[], isNotCompleted: boolean
  ): Observable<{ tableItems: BuildingProgressItem[] }> {
    return this.http.post<{ tableItems: BuildingProgressItem[] }>(
      this.getEndpointPath(projectId, notCompletedItemsEndpoint), { budgetItemIds, isNotCompleted }
    );
  }

  loadBudgetHasItems(projectId: number): Observable<boolean> {
    return this.http.get<boolean>(environment.appUrls.titanGatewayUrl + gatewayApi + projectId + budgetApi + budgetHasItemsEndpoint);
  }

  startBudgetCreation(projectId: number): Observable<any> {
    return this.http.post(
      environment.appUrls.titanGatewayUrl + gatewayApi + projectId + budgetCreationStartEndpoint,
      null,
      { responseType: 'text' });
  }

  loadBudgetCreationActiveState(projectId: number): Observable<QueueActiveState> {
    return this.http.get<QueueActiveState>(
      environment.appUrls.titanGatewayUrl + gatewayApi + projectId + budgetCreationActiveStateEndpoint);
  }

  updateVatRate(projectId: number): Observable<void> {
    return this.http.patch<void>(this.getEndpointPath(projectId, vatRateUpdateEndpoint), null);
  }

  private getEndpointPath(projectId: number, endpoint: string, contractorId?: number): string {
    return this.gatewayApi + projectId + (contractorId ? contractorsApi + contractorId : '') + buildingProgress + endpoint;
  }

  private handleErr(err: HttpErrorResponse): Observable<never> {
    let errorMessage = this.translateService.translate('BUILDING_PROGRESS.ERROR.GENERAL_ERROR');
    if (err.status === 403) {
      errorMessage = this.translateService.translate('BUILDING_PROGRESS.NO_PERMISSION');
    }
    this.toastService.open(errorMessage, ToastType.Error);
    return throwError(() => new Error(err.message));
  }
}
