import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, LOCALE_ID, OnInit, Output } from '@angular/core';

import { InputCommand, InputCommandType } from '@kros-sk/components';
import { TranslateService, VatRateType, VatRateTypePipe } from '@kros-sk/ssw-shared-legacy';

import { addMonths, getEndOfMonth, getMonthList, getStartOfMonth, setMonthToDate } from '../../../../core/utils';
import { BuildingProgressActionAccessService } from '../../../services/building-progress-action-access.service';
import { BuildingProgressPeriod } from '../../../../building-progress/models/building-progress-period.model';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-period-date-picker',
  templateUrl: './period-date-picker.component.html',
  styleUrls: ['./period-date-picker.component.scss'],
  providers: [
    { provide: LOCALE_ID, useValue: environment.defaultLanguage },
  ]
})
export class PeriodDatePickerComponent implements OnInit {
  datepickerCommander = new EventEmitter<InputCommand>();
  datePickerExtended = false;
  editMode = false;
  months: { monthIndex: number, monthName: string }[];
  periodForm: UntypedFormGroup;
  _selectedPeriod: BuildingProgressPeriod;
  _vatRates: { [key in VatRateType]?: number };
  vatRateType = VatRateType;

  @Input() lastPeriod: BuildingProgressPeriod;

  @Input()
  get selectedPeriod(): BuildingProgressPeriod {
    return this._selectedPeriod;
  }

  set selectedPeriod(period: BuildingProgressPeriod) {
    this._selectedPeriod = period;
    this.editMode = !!this._selectedPeriod;
  }

  @Input()
  get vatRates(): any {
    return this._vatRates;
  }

  set vatRates(vatRates: any) {
    this._vatRates = vatRates;
  }

  @Output() save = new EventEmitter<{ from: Date; to: Date; basicVatRate: number; reducedVatRate: number }>();
  @Output() close = new EventEmitter();

  get canSave(): boolean {
    return this.from.valid && this.to.valid;
  }

  get isVatRateReadOnly(): boolean {
    return this.actionAccessService.isLicenseFree;
  }

  get nextMonthDate(): Date {
    return this.lastPeriod ? addMonths(new Date(this.lastPeriod.dateFrom), 1) : new Date();
  }

  get from(): AbstractControl {
    return this.periodForm.controls['from'];
  }

  get to(): AbstractControl {
    return this.periodForm.controls['to'];
  }

  get month(): AbstractControl {
    return this.periodForm.controls['month'];
  }

  get basicVatRate(): AbstractControl {
    return this.periodForm.controls['basicVatRate'];
  }

  get reducedVatRate(): AbstractControl {
    return this.periodForm.controls['reducedVatRate'];
  }

  get digitsInfoVat(): string {
    return '1.0-2';
  }

  get isCzechVersion(): boolean {
    return this.appLocation === 'cz';
  }

  get appLocation(): string {
    return environment.location;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private vatRatePipeType: VatRateTypePipe,
    private actionAccessService: BuildingProgressActionAccessService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.initMonths();
    this.initForm();
    this.datePickerExtended = this.editMode;
  }

  private initMonths(): void {
    this.months = getMonthList(this.translateService.uiCulture);
  }

  private initForm(): void {
    const nextMonthDate = this.nextMonthDate;
    let fromDate: Date;
    let toDate: Date;

    if (this.editMode) {
      fromDate = new Date(this.selectedPeriod.dateFrom);
      toDate = new Date(this.selectedPeriod.dateTo);
    } else {
      fromDate = getStartOfMonth(nextMonthDate);
      toDate = getEndOfMonth(nextMonthDate);
    }

    this.periodForm = this.editMode
      ? this.formBuilder.group({
        from: [fromDate, Validators.required],
        to: [toDate, Validators.required],
        month: [nextMonthDate.getMonth()],
        basicVatRate: [this.selectedPeriod.basicVatRate, Validators.required],
        reducedVatRate: [this.selectedPeriod.reducedVatRate, Validators.required],
      })
      : this.formBuilder.group({
        from: [fromDate, Validators.required],
        to: [toDate, Validators.required],
        month: [nextMonthDate.getMonth()],
      });

    if (this.isVatRateReadOnly && this.editMode) {
      this.reducedVatRate.disable();
      this.basicVatRate.disable();
    }
  }

  toggleDatePickerExtended(): void {
    this.datePickerExtended = !this.datePickerExtended;
    if (this.datePickerExtended) {
      this.month.setValue(-1);
    } else {
      this.setPeriodRangeToFormControls(-1);
    }
  }

  isVatRateDefined(type: VatRateType): boolean {
    return this.vatRates[type] >= 0;
  }

  handleVatInput(event: any): void {
    event.target.value = event.target.value.replace(/\./g, ',');
  }

  getVatResName(type: VatRateType): string {
    if (this.isCzechVersion) {
      return this.vatRatePipeType.transform(type);
    } else {
      return Object.keys(this.vatRates).length === 1 ? 'SETTINGS.VAT.VAT' : this.vatRatePipeType.transform(type);
    }
  }

  getVatRate(type: VatRateType): number {
    switch (type) {
      case VatRateType.BasicTransferred:
        return this.selectedPeriod.basicVatRate;
      case VatRateType.ReducedTransferred:
        return this.selectedPeriod.reducedVatRate;
      default:
        return this.vatRates[type];
    }
  }

  private setPeriodRangeToFormControls(monthIndex: number): void {
    if (monthIndex >= 0) {
      const date = setMonthToDate(this.nextMonthDate, monthIndex);
      this.from.setValue(getStartOfMonth(date));
      this.to.setValue(getEndOfMonth(date));
    } else {
      this.from.setValue(null);
      this.from.markAsUntouched();
      this.to.setValue(null);
      this.to.markAsUntouched();
    }
  }

  onCloseEditMode(): void {
    this.close.emit();
    this.datepickerCommander.emit({
      type: InputCommandType.CLOSE_OVERLAY
    });
  }

  onMonthChange(): void {
    this.setPeriodRangeToFormControls(this.month.value);
  }

  onSave(): void {
    this.save.emit({
      from: this.from.value,
      to: this.to.value,
      basicVatRate: this.basicVatRate?.value ?? 0,
      reducedVatRate: this.reducedVatRate?.value ?? 0
    });
  }
}
