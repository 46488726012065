import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

import { take } from 'rxjs/operators';

import { KrosModalRef, KrosModalService } from '@kros-sk/components';
import { TranslateService } from '@kros-sk/ssw-shared-legacy';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { BuildingProgressCreateInvoiceModel } from '../../models/building-progress-invoice/building-progress-create-invoice.model';
import { BuildingProgressPeriod } from '../../models/building-progress-period.model';
import { RangeModalComponent } from '../../shared/range-modal/range-modal.component';

@Component({
  selector: 'app-building-progress-create-invoice',
  templateUrl: './building-progress-create-invoice.component.html'
})
export class BuildingProgressCreateInvoiceComponent extends UnsubscribeComponent implements OnInit {

  initialModel: BuildingProgressCreateInvoiceModel;
  periods: BuildingProgressPeriod[];
  invoiceForm: UntypedFormGroup;

  get buildingRangeLabel(): string {
    return this.buildingObjectIds?.length === 0 ? this.translateService.translate('BUILDING_PROGRESS.WHOLE_BUILDING') :
      `${this.translateService.translate('BUILDING_PROGRESS.SELECTED_OBJECTS')} (${this.buildingObjectIds.length})`;
  }

  private rangeSelectedItems: Set<number> = new Set<number>();
  private rangeIndeterminateItems: Set<number> = new Set<number>();

  private get buildingObjectIds(): number[] {
    return this.invoiceForm?.controls?.buildingObjectIds.value;
  }

  constructor(
    private datePipe: DatePipe,
    private formBuilder: UntypedFormBuilder,
    private krosModalService: KrosModalService,
    private modalRef: KrosModalRef,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initialModel = this.modalRef.data.invoiceModel;
    this.periods = this.modalRef.data.periods;
    this.initForm();
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  openRangeModal(): void {
    const modalRef = this.krosModalService.openCentered(
      RangeModalComponent,
      {
        selectedItems: this.rangeSelectedItems,
        indeterminateItems: this.rangeIndeterminateItems
      },
      {
        addModalToBrowsersHistory: false,
        closeOnEscClick: true
      }
    );

    this.subs.sink = modalRef.afterClosed$.pipe(take(1))
      .subscribe(result => {
        if (result.type === 'submit') {
          this.rangeSelectedItems = result.data.selectedItems;
          this.rangeIndeterminateItems = result.data.indeterminateItems;
          const selectedBuildingObjectIds =
            Array.from(this.rangeSelectedItems).filter(id => !this.rangeIndeterminateItems.has(id));
          this.invoiceForm.get('buildingObjectIds').setValue(selectedBuildingObjectIds);
        }
      });
  }

  onSubmit(): void {
    this.modalRef.submit(this.getInvoiceModel());
  }

  private getInvoiceModel(): BuildingProgressCreateInvoiceModel {
    return {
      periodIds: this.invoiceForm.controls.periodIds.value,
      buildingObjectIds: this.buildingObjectIds,
      projectId: this.initialModel.projectId,
      invoiceDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      companyId: this.initialModel.companyId
    };
  }

  private initForm(): void {
    this.invoiceForm = this.formBuilder.group(
      {
        periodIds: [[this.initialModel.periodIds[0]]],
        buildingObjectIds: [[]],
      }, { validators: this.formValidator }
    );
  }

  private formValidator(formGroup: AbstractControl): ValidationErrors {
    const periodSelected = !!formGroup.get('periodIds').value && formGroup.get('periodIds').value.length > 0;
    return periodSelected ? null : { periodSelected };
  }

}
