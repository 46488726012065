<div class="panel-wrapper">
  <div class="panel-header">
    <div class="panel-header-actions">
      <i
        class="material-icons"
        (click)="onCloseClick()"
      >close</i>
    </div>
  </div>

  <div class="divider-horizontal"></div>

  <div class="panel-content">
    <div class="collapsible-content">
      <app-budget-structure
        class="building-tree"
        [title]="data.title"
        [data$]="data.selectorData"
        [tableId]="data.tableId"
        (nodeSelected)="onCloseClick()"
      ></app-budget-structure>
    </div>
  </div>
</div>
