import { Component, DestroyRef, ElementRef, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Action } from '@ngrx/store';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { areEmailsEqual, UserService } from '@kros-sk/ssw-cdk';
import { BuildingObjectsUploadModalComponent } from '@kros-sk/ssw-budget/building-objects';
import {
  DecimalPlaces,
  InfoModalComponent,
  isOwnersEmail,
  ProjectDetail,
  TranslateService,
  UndoRedoDispatchersService,
  UndoRedoSelectorsService
} from '@kros-sk/ssw-shared-legacy';
import { KrosModalService, KrosShepherdService } from '@kros-sk/components';
import { PermissionType } from '@kros-sk/ssw-shared/permission';

import { BuildingProgressActionAccessService } from '../../../building-progress/services/building-progress-action-access.service';
import { BuildingProgressDispatchersService, BuildingProgressSelectorsService } from '../../../store/building-progress';
import { BuildingProgressPanelDialogComponent } from '../building-progress-panel-dialog/building-progress-panel-dialog.component';
import { BuildingProgressSharing } from '../../../building-progress/models/building-progress-sharing/building-progress-sharing.model';
import { DetailHelper, LicenseHelper, PermissionHelper } from '../../../building-progress/helpers';
import { environment } from '../../../../environments/environment';
import {
  hasApproverRight,
  hasApproverViewRight,
  hasContractorFullRight,
  hasContractorRight
} from '../../../building-progress/shared/building-progress-utils';
import { ProjectsSelectorsService } from '../../../store/projects';
import { SelectedView } from '../../../building-progress/models/selected-view.model';
import { ViewType } from '../../services/building-progress-views.service';

@Component({
  selector: 'app-building-progress-detail-toolbar',
  templateUrl: './building-progress-detail-toolbar.component.html',
  styleUrls: ['./building-progress-detail-toolbar.component.scss']
})
export class BuildingProgressDetailToolbarComponent implements OnInit {

  buildingProgressSelectorService = inject(BuildingProgressSelectorsService);
  protected permissionHelper = inject(PermissionHelper);
  protected detail = inject(DetailHelper);
  private destroyRef = inject(DestroyRef);
  private buildingProgressDispatcherService = inject(BuildingProgressDispatchersService);
  private projectsSelectorsService = inject(ProjectsSelectorsService);
  private userService = inject(UserService);
  private buildingProgressActionAccessService = inject(BuildingProgressActionAccessService);
  private undoRedoSelectorsService = inject(UndoRedoSelectorsService);
  private undoRedoDispatchersService = inject(UndoRedoDispatchersService);
  private appInsightsService = inject(AppInsightsBaseService);
  private translateService = inject(TranslateService);
  private krosModalService = inject(KrosModalService);
  private krosShepherdService = inject(KrosShepherdService);
  private licenseHelper = inject(LicenseHelper);

  @ViewChild('toolbar') toolbar: ElementRef<HTMLElement>;
  @ViewChild(BuildingObjectsUploadModalComponent) buildingObjectsUploadModal: BuildingObjectsUploadModalComponent;

  @Input() canComplete: boolean;
  @Input() canExport: boolean;
  @Input() canExportTable: boolean;
  @Input() isConstructionData: boolean;
  @Input() isViewFilterActive: boolean;
  @Input() view: SelectedView;
  @Input() canOpenBoq: boolean;

  @Output() shareBuildingProgressClick = new EventEmitter<void>();
  @Output() openCommentsClick = new EventEmitter<void>();
  @Output() openPeriodsClick = new EventEmitter<{ isApproveMode: boolean }>();
  @Output() exportToExcelClick = new EventEmitter<void>();
  @Output() exportInvoiceDetailsClick = new EventEmitter<void>();
  @Output() exportAmountsUniXmlClick = new EventEmitter<void>();
  @Output() exportConfirmationProtocolClick = new EventEmitter<void>();
  @Output() percentageDrawClick = new EventEmitter<void>();
  @Output() percentageCompleteItemClick = new EventEmitter<void>();
  @Output() dashboardClick = new EventEmitter<void>();
  @Output() totalPriceSetClick = new EventEmitter<void>();
  @Output() openColorCodesClick = new EventEmitter<HTMLElement>();
  @Output() openNotesClick = new EventEmitter<void>();
  @Output() deleteItemClick = new EventEmitter<void>();
  @Output() openGalleryClick = new EventEmitter<void>();
  @Output() openBoqClick = new EventEmitter<void>();
  @Output() createInvoiceClick = new EventEmitter<void>();
  @Output() exportItemDrawSheetClick = new EventEmitter<void>();
  @Output() exportRemainingBudgetClick = new EventEmitter<void>();
  @Output() setSelectedItemsNotCompleted = new EventEmitter<boolean>();
  @Output() exportToAnotherProject = new EventEmitter<void>();

  areColorDeleteFunctionsAvailable: boolean;
  areFunctionsAvailable: boolean;
  buildingProgressSharings: BuildingProgressSharing[];
  projectId: number;
  project: ProjectDetail;
  isOwner = false;
  ownerMail = '';
  notOwnerUsers = [];
  exportMenuInMorePositions = [
    new ConnectionPositionPair(
      { originX: 'end', originY: 'top' },
      { overlayX: 'start', overlayY: 'top' },
      0, -6)
  ];

  private presentAction: Action;
  private futureAction: Action;
  private decimalPlaces: DecimalPlaces;
  private selectedItemIds: Set<number>;
  private tableIsBusy: boolean;
  private isUndoable: boolean;
  private isRedoable: boolean;
  private isUndoRedoInProgress: boolean;

  get canUndo(): boolean {
    return this.isUndoable && this.canUndoRedo;
  }

  get canRedo(): boolean {
    return this.isRedoable && this.canUndoRedo;
  }

  private get canUndoRedo(): boolean {
    return !this.isUndoRedoInProgress && !this.tableIsBusy;
  }

  get isContributor(): boolean {
    return this.existsBuildingProgressSharings && this.myBuildingProgressPermission?.permissionType === PermissionType.Contributor;
  }

  get isReader(): boolean {
    return this.existsBuildingProgressSharings && this.myBuildingProgressPermission?.permissionType === PermissionType.Reader;
  }

  get isApprover(): boolean {
    return this.existsBuildingProgressSharings && hasApproverRight(this.myBuildingProgressPermission?.additionalPermissionType);
  }

  get isContractorWithoutPrices(): boolean {
    return this.permissionHelper.isContractor && !this.permissionHelper.isContractorFull;
  }

  get isApproverView(): boolean {
    return this.existsBuildingProgressSharings && hasApproverViewRight(this.myBuildingProgressPermission?.additionalPermissionType);
  }

  get isContractor(): boolean {
    return this.myBuildingProgressPermission?.permissionType === PermissionType.Shared &&
      hasContractorRight(this.myBuildingProgressPermission?.additionalPermissionType);
  }

  get isContractorFull(): boolean {
    return this.myBuildingProgressPermission?.permissionType === PermissionType.Shared &&
      hasContractorFullRight(this.myBuildingProgressPermission?.additionalPermissionType);
  }

  private get myBuildingProgressPermission(): BuildingProgressSharing {
    return this.buildingProgressSharings.find(p => areEmailsEqual(p.email, this.userService.getUserEmail()));
  }

  get canShowPeriods(): boolean {
    return (this.isConstructionData || this.isViewFilterActive) &&
      (!this.areFunctionsAvailable && !this.areColorDeleteFunctionsAvailable);
  }

  get canShowComplete(): boolean {
    return this.canComplete && (this.isOwner || this.isContributor || this.isContractor) &&
      (this.areFunctionsAvailable || !this.isViewFilterActive);
  }

  get canShowExport(): boolean {
    return this.isConstructionData && this.canExport && !this.areFunctionsAvailable &&
      !this.areColorDeleteFunctionsAvailable && !this.isViewFilterActive && !this.isSummaryView;
  }

  get canShowExportButton(): boolean {
    return this.canShowExport && (!this.isContractor || this.isContractorFull);
  }

  get canShowExportTable(): boolean {
    return this.canExportTable && !this.areFunctionsAvailable && !this.areColorDeleteFunctionsAvailable;
  }

  get canShowExportConfirmationProtocol(): boolean {
    return this.canShowExport && !this.isContractor && this.isSkVersion;
  }

  get canShowExportToAnotherProject(): boolean {
    return this.canShowExport && (this.isOwner || this.isContributor || this.isContractor);
  }

  get canShowExportContractorSelectedItems(): boolean {
    return !this.permissionHelper.isContractor || this.selectedItemsCount === 0;
  }

  get canShowDashboard(): boolean {
    return this.isConstructionData && (!this.areFunctionsAvailable &&
      !this.areColorDeleteFunctionsAvailable) && !this.isViewFilterActive;
  }

  get canShowMore(): boolean {
    return this.isConstructionData && !this.areFunctionsAvailable && !this.areColorDeleteFunctionsAvailable &&
      (!this.permissionHelper.isReader || this.permissionHelper.isContractor) ||
      (this.canExport && this.selectedItemsCount > 0 && !this.isContractorWithoutPrices);
  }

  get canDelete(): boolean {
    return this.isConstructionData && (this.isOwner || this.isContributor) && this.areColorDeleteFunctionsAvailable &&
      !this.isViewFilterActive;
  }

  get canDeleteInMore(): boolean {
    return (this.isOwner || this.isContributor) && !this.isViewFilterActive;
  }

  get canShowColorCodes(): boolean {
    return (this.isOwner || this.isContributor || this.isContractor || this.isApprover || this.isApproverView)
      && this.areColorDeleteFunctionsAvailable;
  }

  get existsBuildingProgressSharings(): boolean {
    return this.buildingProgressSharings && this.buildingProgressSharings.length > 0;
  }

  get isWarningVisible(): boolean {
    return this.decimalPlaces && this.decimalPlaces.totalPrice !== this.decimalPlaces.buildingObjectTotalPrice;
  }

  get selectedItemsCount(): number {
    return !!this.selectedItemIds ? this.selectedItemIds.size : 0;
  }

  get canShowGallery(): boolean {
    return !this.areFunctionsAvailable && !this.areColorDeleteFunctionsAvailable;
  }

  get canShowNotes(): boolean {
    return this.isConstructionData && !this.areFunctionsAvailable && !this.areColorDeleteFunctionsAvailable &&
      this.isReader && !this.canShowMore && this.selectedItemsCount === 0;
  }

  get canShowInvoice(): boolean {
    return !this.areFunctionsAvailable &&
      !this.areColorDeleteFunctionsAvailable &&
      this.isConstructionData &&
      this.isSkVersion &&
      this.permissionHelper.isOwner;
  }

  get canShowBuildingObjectsUploadButton(): boolean {
    return !this.isViewFilterActive && this.selectedItemsCount === 0;
  }

  get isSummaryView(): boolean {
    return this.view.viewType === ViewType.buildingObjectsSummary || this.view.viewType === ViewType.sectionsSummary;
  }

  get isSchoolLicense(): boolean {
    return this.licenseHelper.isSchoolLicense;
  }

  private get isSkVersion(): boolean {
    return environment.location === 'sk';
  }

  ngOnInit(): void {
    this.buildingProgressSelectorService.constructionDataDecPlaces$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(res => this.decimalPlaces = res);
    this.projectsSelectorsService.projectDetail$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((project: ProjectDetail) => {
        if (project) {
          this.project = project;
          this.projectId = project.id;
          this.isOwner = isOwnersEmail(project.owner);
          this.ownerMail = this.isOwner ? '' : project.owner;
          this.buildingProgressDispatcherService.loadSharingList(this.projectId);
        }
      });
    this.buildingProgressSelectorService.sharingList$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(sharingList => {
        this.buildingProgressSharings = sharingList;

        if (this.buildingProgressSharings) {
          this.notOwnerUsers = this.buildingProgressSharings
            .filter(p => p.permissionType !== PermissionType.Owner)
            .map(p => {
              return { email: p.email, isRegistered: p.isRegisteredUser };
            });
        }
      });
    this.buildingProgressSelectorService.areFunctionsAvailableFor$(this.userService.getUserEmail())
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => this.areFunctionsAvailable = _);
    this.buildingProgressSelectorService.areDeleteColorFunctionsAvailableFor$(this.userService.getUserEmail())
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => this.areColorDeleteFunctionsAvailable = _);
    this.undoRedoSelectorsService.presentAction$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(p => this.presentAction = p);
    this.undoRedoSelectorsService.futureAction$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(p => this.futureAction = p);
    this.undoRedoSelectorsService.isUndoable$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(p => this.isUndoable = p);
    this.undoRedoSelectorsService.isRedoable$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(p => this.isRedoable = p);
    this.undoRedoSelectorsService.isInProgress$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(p => this.isUndoRedoInProgress = p);
    this.buildingProgressSelectorService.tableIsBusy$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(p => this.tableIsBusy = p);
    this.buildingProgressSelectorService.selectedItemIds$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(itemIds => {
      if (itemIds && itemIds instanceof Set) {
        this.selectedItemIds = itemIds;
      }
    });
  }

  undo(): void {
    if (this.canUndo) {
      this.undoRedoDispatchersService.undo(this.presentAction);
      this.appInsightsService.trackEvent('PV-undo');
    }
  }

  redo(): void {
    if (this.canRedo) {
      this.undoRedoDispatchersService.redo(this.futureAction);
      this.appInsightsService.trackEvent('PV-redo');
    }
  }

  onOpenPanel(): void {
    this.krosModalService.openCentered(BuildingProgressPanelDialogComponent,
      {},
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: false,
        showMobileArrowBack: false
      },
      NaN, NaN, undefined, 'kros-modal-panel-left'
    );
  }

  shareBuildingProgress(): void {
    if (this.buildingProgressActionAccessService.checkForAccessToShareBuildingProgress()) {
      this.shareBuildingProgressClick.emit();
    }
  }

  openPeriods(): void {
    this.krosShepherdService.next();
    this.openPeriodsClick.emit();
  }

  approvePeriod(): void {
    this.openPeriodsClick.emit();
  }

  delete(): void {
    if (this.buildingProgressActionAccessService.checkForAccessCauseOfReadonlyOrFreeLicense()) {
      this.deleteItemClick.emit();
    }
  }

  percentageDraw(): void {
    if (this.buildingProgressActionAccessService.checkForAccessToEdit() && this.buildingProgressActionAccessService.checkForFreeOwner()) {
      this.percentageDrawClick.emit();
    }
  }

  percentageComplete(): void {
    if (this.buildingProgressActionAccessService.checkForAccessToEdit()) {
      this.percentageCompleteItemClick.emit();
    }
  }

  exportToExcel(): void {
    if (this.buildingProgressActionAccessService.checkForAccessToExport()) {
      this.exportToExcelClick.emit();
    }
  }

  exportInvoiceDetails(): void {
    if ((this.buildingProgressActionAccessService.checkForAccessToExportInvoiceDetails()) ||
      this.buildingProgressActionAccessService.checkForAccessToExport()) {
      this.exportInvoiceDetailsClick.emit();
    }
  }

  exportAmountsUniXml(): void {
    if (this.buildingProgressActionAccessService.checkForAccessToExport(false, true)) {
      this.exportAmountsUniXmlClick.emit();
    }
  }

  exportConfirmationProtocol(): void {
    if (this.buildingProgressActionAccessService.checkForAccessToExport(false, true)) {
      this.exportConfirmationProtocolClick.emit();
    }
  }

  exportItemDrawSheet(): void {
    if (this.buildingProgressActionAccessService.checkForAccessToExport(false, true)) {
      this.exportItemDrawSheetClick.emit();
    }
  }

  exportRemainingBudget(): void {
    if (this.buildingProgressActionAccessService.checkForAccessToExport()) {
      this.exportRemainingBudgetClick.emit();
    }
  }

  onExportToAnotherProject(): void {
    if (this.buildingProgressActionAccessService.checkForAccessToExport()) {
      this.exportToAnotherProject.emit();
    }
  }

  openColorCodes(): void {
    if (this.buildingProgressActionAccessService.checkForAccessToColorCodes()) {
      this.openColorCodesClick.emit(this.toolbar.nativeElement);
    }
  }

  onWarningClick(): void {
    this.krosModalService.openCentered(
      InfoModalComponent,
      {
        body: this.translateService.translate('BUILDING_PROGRESS.WARNING.DECIMAL_PLACES'),
      },
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: false,
        showMobileArrowBack: false
      }
    );
    this.appInsightsService.trackEvent('PV-warning-inconsistent-rounding');
  }

  totalPriceSet(): void {
    if (this.buildingProgressActionAccessService.checkForAccessToEdit() && this.buildingProgressActionAccessService.checkForFreeOwner()) {
      this.totalPriceSetClick.emit();
    }
  }

  setSelectedItemsNotCompletedIfCan(state: boolean): void {
    if (this.buildingProgressActionAccessService.checkForAccessCauseOfReadonlyOrFreeLicense()) {
      this.setSelectedItemsNotCompleted.emit(state);
    }
  }

  onOpenCreateInvoice(): void {
    if (this.buildingProgressActionAccessService.checkForAccessCauseOfReadonlyOrFreeLicense()) {
      this.createInvoiceClick.emit();
    }
  }

  onOpenGallery(): void {
    if (this.buildingProgressActionAccessService.checkForFreeOwner()) {
      this.openGalleryClick.emit();
    }
  }

  onUploadBudgetToBuildingProgress(): void {
    this.buildingObjectsUploadModal.onOpen();
  }
}
