import { Directive, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

import { Markable } from './markable.interface';
import { MarkAllAsTouchedInputDirective } from './mark-touched-on-invalidinput.directive';

@Directive({
  selector: '[markableAsInvalid]'
})
export class MarkableAsInvalidDirective implements OnInit, Markable {
  constructor(
    @Optional() private markAllAsTouched: MarkAllAsTouchedInputDirective,
    @Self() private controlDirective: NgControl
  ) {}

  ngOnInit(): void {
    if (this.markAllAsTouched) {
      this.markAllAsTouched.registerMarkable(this);
    }
  }

  isInvalid(): boolean {
    if (!this.controlDirective) return false;
    return this.controlDirective.status === 'INVALID';
  }

  markAsTouched(): void {
    this.controlDirective.control.markAsTouched();
    this.controlDirective.control.updateValueAndValidity();
  }
}
