import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter, switchMap, take } from 'rxjs';

import { ApplicationType, UserService } from '@kros-sk/ssw-cdk';
import { ApprovalHelper } from '@kros-sk/ssw-shared/utils/building-progress';
import { PermissionType } from '@kros-sk/ssw-shared/permission';
import { TimelineType, UserSharingService } from '@kros-sk/ssw-shared-legacy';

import { ApplicationPermission, ProjectOwnerEditViewModel } from '../../models';
import { BuildingProgressSettingService } from '../../services';
import { environment } from '../../../../environments/environment';
import { hasContractorRight } from '../../../building-progress/shared/building-progress-utils';
import { ProjectsDispatchersService, ProjectsSelectorsService } from '../../../store/projects';

@Component({
  selector: 'app-project-members-owner-detail',
  templateUrl: './project-members-owner-detail.component.html',
  styleUrls: ['./project-members-owner-detail.component.scss']
})
export class ProjectMembersOwnerDetailComponent implements OnInit {

  private multiStageApprovalSettingService = inject(BuildingProgressSettingService);
  private projectsDispatchersService = inject(ProjectsDispatchersService);
  private projectsSelectorsService = inject(ProjectsSelectorsService);
  private userSharingService = inject(UserSharingService);
  private userService = inject(UserService);
  private approvalHelper = inject(ApprovalHelper);
  private destroyRef = inject(DestroyRef);

  newOwnerEmail: string;
  projectId: number;
  originalOwner: string;
  candidates: ApplicationPermission[];
  candidateEmails: string[];
  timelineType = TimelineType;
  isLoaded = false;
  isReadOnly = true;

  get isSkVersion(): boolean {
    return environment.location === 'sk';
  }

  get isOwnerChanged(): boolean {
    return this.newOwnerEmail !== undefined;
  }

  get ownerEmail(): string {
    return this.newOwnerEmail !== undefined
      ? this.newOwnerEmail
      : this.originalOwner;
  }

  private isMultiStageApprovalModeEnabled = false;

  ngOnInit(): void {
    this.projectsSelectorsService.projectDetail$
      .pipe(
        filter(project => !!project),
        switchMap(project => {
          this.projectId = project.id;
          this.isReadOnly = project.permissionType !== PermissionType.Owner;
          this.originalOwner = this.isReadOnly ? project.owner : this.userService.getUserEmail();
          this.loadMembersContent(project.id);

          return this.multiStageApprovalSettingService.loadIsMultiStageApprovalMode(this.projectId);
        }),
        takeUntilDestroyed(this.destroyRef))
      .subscribe(result => this.isMultiStageApprovalModeEnabled = result);
    this.projectsSelectorsService.applicationPermissions$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(permissions => {
        this.candidates = permissions?.filter(s => s.isRegisteredUser && !this.isContractor(s)).sort(this.sort);
        this.candidateEmails = this.candidates.map(c => c.email);
        this.newOwnerEmail = undefined;
        this.isLoaded = true;
      });
  }

  loadMembersContent(projectId: number): void {
    this.projectsDispatchersService.loadProjectApplicationPermissions(projectId);
  }

  onOwnerChanged(email: string): void {
    this.newOwnerEmail = email;
  }

  onSave(): void {
    if (this.isMultiStageApprovalModeEnabled && this.isApprover(this.candidates.find(c => c.email === this.newOwnerEmail))) {
      this.approvalHelper.showApprovalCancellationDialog().pipe(take(1)).subscribe(result => {
        if (result) {
          this.changeOwner();
        }
      });
    } else {
      this.changeOwner();
    }
  }

  onCancel(): void {
    this.newOwnerEmail = undefined;
  }

  private sort(left: ApplicationPermission, right: ApplicationPermission): number {
    const lEmail = left.email.toLowerCase();
    const rEmail = right.email.toLowerCase();

    return lEmail === rEmail ? 0 : lEmail < rEmail ? -1 : 1;
  }

  private changeOwner(): void {
    const viewModel: ProjectOwnerEditViewModel = {
      projectId: this.projectId,
      newOwnerEmail: this.newOwnerEmail
    };

    this.isLoaded = false;
    this.userSharingService.sendAnalyticsNotificationPermissionChange();
    this.projectsDispatchersService.editOwner(viewModel);
  }

  private isContractor(appPermission: ApplicationPermission): boolean {
    const permission = appPermission.permissions.find(p => p.applicationType === ApplicationType.BuildingProgress);
    return permission.permissionType === PermissionType.Shared && hasContractorRight(permission.additionalPermissionType);
  }

  private isApprover(appPermission: ApplicationPermission): boolean {
    return appPermission.permissions.some(p => this.approvalHelper.isApprover(p.additionalPermissionType, p.applicationType));
  }
}
