import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { ChangeDetectorRef, Component, HostBinding, NgZone, OnDestroy, OnInit, SkipSelf } from '@angular/core';

import { SubSink } from 'subsink';

import { NavigationService } from '../../kros-navigation/service/navigation.service';

@Component({
  selector: 'kros-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss']
})
export class MobileNavComponent implements OnDestroy, OnInit {

  @HostBinding('class') className = 'mobile-nav-scroll-up';

  private subs = new SubSink();

  constructor(
    private navService: NavigationService,
    private scrollDispatcher: ScrollDispatcher,
    private zone: NgZone,
    @SkipSelf() private cdRef: ChangeDetectorRef // to detect changes on parent (hostBinding needs parent's change detection for effect)
  ) { }

  ngOnInit(): void {
    this.navService.hide();
    this.hideNavbarOnScroll();
  }

  ngOnDestroy(): void {
    this.navService.show();
    this.subs.unsubscribe();
  }

  private hideNavbarOnScroll(): void {
    let lastScrollPosition = 0;
    let lastScroll = false;

    this.subs.sink = this.scrollDispatcher.scrolled().subscribe((x: CdkScrollable) => {
      if (!x) return;

      const scrollTop = x.getElementRef().nativeElement.scrollTop;

      if (lastScrollPosition < scrollTop || (lastScrollPosition === scrollTop && lastScroll)) {
        lastScroll = true;
      } else lastScroll = false;

      if (scrollTop <= 0) { // ios is scrolling to minus for visual effect...
        lastScroll = false;
      }

      if (lastScroll) {
        this.className = 'mobile-nav-scroll-down';
      } else {
        this.className = 'mobile-nav-scroll-up';
      }

      lastScrollPosition = scrollTop;
      this.zone.onMicrotaskEmpty.emit(null); // there is no change when on real mobile, so we add one
      this.cdRef.markForCheck();
    });
  }
}
