import { Component, ViewChild } from '@angular/core';

import { Position, SidePanelComponent } from '@kros-sk/ssw-shared-legacy';
import { TimelineType } from '@kros-sk/ssw-shared-legacy';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { BuildingProgressCommentModel } from '../../../building-progress/modules/comments-panel/comments-panel/comment.model';
import { BuildingProgressPanelEnum } from '../../..//building-progress/models/building-progress-panel.enum';
import { BuildingProgressPanelHelper, DetailHelper, PeriodHelper, VisualHelper } from '../../../building-progress/helpers';
import { BuildingProgressSelectorsService } from '../../../store/building-progress';
import { SelectedView } from '../../../building-progress/models/selected-view.model';
import { ViewType } from '../../../building-progress/services/building-progress-views.service';

@Component({ template: '' })
export abstract class BuildingProgressDetailBaseComponent extends UnsubscribeComponent {
  @ViewChild(SidePanelComponent) sidePanel: SidePanelComponent;

  timelineType = TimelineType;
  buildingProgressPanelEnum = BuildingProgressPanelEnum;

  constructor(
    protected buildingProgressSelectorsService: BuildingProgressSelectorsService,
    public detail: DetailHelper,
    protected periodHelper: PeriodHelper,
    protected visualHelper: VisualHelper,
    protected panelHelper: BuildingProgressPanelHelper
  ) {
    super();
  }

  onClickComment(comment: BuildingProgressCommentModel): void {
    const colId = this.periodHelper.getPeriodColumnName(comment.periodColumn) + comment.periodId;
    const rowIndex = this.detail.constructionData.items.findIndex(i => i.id === comment.budgetItemId);

    if (!this.detail.focusedCell || this.detail.focusedCell.colId !== colId || this.detail.focusedCell.rowIndex !== rowIndex) {
      this.detail.focusedCell = this.detail.getFocusedCell(colId, rowIndex,
        { periodId: comment.periodId, periodColumn: comment.periodColumn });

      this.visualHelper.scrollElementToView(this.detail.getFocusedCellElementId(), false, this.detail.getFocusedCellElementId());
      this.detail.cellFocusedCore();
    }
  }

  onViewChanged(selectedView: SelectedView): void {
    switch (selectedView.viewType) {
      case ViewType.colorCodes:
      case ViewType.drawnSelected:
      case ViewType.undrawnSelected:
      case ViewType.withComment:
        this.detail.specialViewChanged(selectedView);
        break;
      default:
        this.detail.selectedViewChanged(selectedView);
        break;
    }
  }

  protected showPanel(position: Position, size: number): void {
    this.sidePanel?.showPanel(position, size);
    this.visualHelper.scrollFocusedCellIntoView(300);
  }

  protected hidePanel(position: Position): void {
    this.sidePanel?.hidePanel(position);
  }

  protected resizePanel(position: Position, size: number): void {
    this.sidePanel?.resizePanel(position, size);
  }
}
