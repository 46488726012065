import { Component, OnInit, ViewChild } from '@angular/core';

import { filter } from 'rxjs/operators';

import {
  BudgetStructureService,
  Cell,
  DataTableComponent,
  DetailBaseComponent,
  formatNumberValue,
  isConstructionOrBuildingObject,
  isSummaryItem
} from '@kros-sk/ssw-shared-legacy';
import { KrosModalRef } from '@kros-sk/components';
import { UserService } from '@kros-sk/ssw-cdk';

import { BudgetApprovalDispatchersService, BudgetApprovalSelectorsService } from '../../../store/budget-approval';
import { BudgetChangeItem, BudgetModel, ChangeSheetItem, ChangeSheetRelationEditModel } from '../../models';
import { BudgetTableService } from '../../services';
import { environment } from '../../../../environments/environment';
import { ProjectsSelectorsService } from '../../../store/projects';

@Component({
  selector: 'app-relation-dialog',
  templateUrl: './relation-dialog.component.html',
  styleUrls: ['./relation-dialog.component.scss'],
})
export class RelationDialogComponent extends DetailBaseComponent implements OnInit {
  @ViewChild(DataTableComponent) set tableComponent(value: DataTableComponent) {
    this.table = value;

    if (this.table && this.scrollElementToViewParams) {
      this.scrollElementToView(
        this.scrollElementToViewParams.elementId,
        this.scrollElementToViewParams.elementIdForColumnWidth);
      this.scrollElementToViewParams = undefined;
    }
  }

  panelCollapsed = false;
  isTabletStructureShown = false;
  projectId: number;
  item: ChangeSheetItem | BudgetChangeItem;

  private areFunctionsAvailable: boolean;

  get formattedItemAmount(): string {
    return isNaN(this.item.amount) || !this.tableData
      ? ''
      : formatNumberValue(this.item.amount, this.tableData.decimalPlaces.amount, environment.location);
  }

  constructor(
    public tableService: BudgetTableService,
    public selectorService: BudgetApprovalSelectorsService,
    private modalRef: KrosModalRef,
    private budgetStructureService: BudgetStructureService,
    private dispatchersService: BudgetApprovalDispatchersService,
    private projectsSelectorsService: ProjectsSelectorsService,
    private userService: UserService
  ) {
    super(environment.location);
    this.item = this.modalRef.data.item;

    this.subs.sink = this.projectsSelectorsService.projectDetail$
      .pipe(filter(p => !!p))
      .subscribe(p => this.dispatchersService.loadSharingList(p.id));

    this.subs.sink = this.selectorService.areFunctionsAvailableFor$(this.userService.getUserEmail())
      .subscribe(_ => this.areFunctionsAvailable = _);
  }

  ngOnInit(): void {
    this.subs.sink = this.projectsSelectorsService.projectDetail$
      .pipe(filter(p => !!p))
      .subscribe(p => {
        this.projectId = p.id;
        this.loadData(p.id);
      });
    this.subs.sink = this.selectorService.budgetData$
      .pipe(filter(p => !!p))
      .subscribe(p => this.setData({
        ...p,
        items: p.items.map(item => {
          return { ...item, hasRelation: item.id === this.item.originalId };
        })
      }));
    this.subs.sink = this.budgetStructureService.setFocusToBudgetItemRequested$
      .subscribe(result => this.setFocusToItemById(result.id));
  }

  loadData(projectId: number): void {
    this.dispatchersService.loadBudgetData(projectId);
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  onSubmit(): void {
    const editModel: ChangeSheetRelationEditModel = {
      projectId: this.projectId,
      budgetItemId: this.item.id,
      originalId: this.tableData.items[this.focusedCell.rowIndex].id,
    };
    this.modalRef.submit(editModel);
  }

  onItemDblClick(): void {
    if (this.areFunctionsAvailable) {
      this.onSubmit();
    }
  }

  private setData(data: BudgetModel): void {
    this.tableData = data;
    this.dataTableConfig = this.hasData ? this.tableService.getTableConfig(this.tableData) : undefined;

    this.focusedCell = {
      colId: 'budget-description',
      rowIndex: this.getInitialFocusedRowIndex(data)
    };
    this.focusCell();
    setTimeout(() => this.setFocusToStructureItemById());
  }

  private setFocusToItemById(id: number): void {
    this.focusedCell = this.getFocusedCell(
      this.focusedCell?.colId ?? 'budget-budget-',
      this.tableData.items.findIndex(i => i.id === id)
    );

    this.focusCell();
  }

  private getFocusedCell(colId: string, rowIndex: number): Cell {
    return { colId, rowIndex };
  }

  onCellFocused(event: any): void {
    super.onCellFocused(event);

    this.setFocusToStructureItemById();
  }

  private setFocusToStructureItemById(): void {
    if (this.focusedCell && this.hasData) {
      const item = this.tableData.items[this.focusedCell.rowIndex];

      if (isSummaryItem(item)) {
        this.budgetStructureService.setFocusToStructureItemById(item.id);
      } else {
        this.budgetStructureService.setFocusToStructureItemById(item.parentId);
      }
    }
  }

  private getInitialFocusedRowIndex(data: BudgetModel): number {
    if (!!this.item.originalId) {
      return data.items.findIndex(item => item.hasRelation);
    } else {
      let rowIndex = 0;
      for (let i = data.items.length - 1; i >= 0; i--) {
        const item = data.items[i];
        if (item.itemOrder <= this.item.itemOrder && isConstructionOrBuildingObject(item)) {
          rowIndex = i;
          break;
        }
      }
      return rowIndex;
    }
  }
}
