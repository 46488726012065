<div
  *ngIf="(deviceDetectorService.deviceType$ | async) === deviceType.Desktop else mobileContainer"
  class="detail-desktop-container"
>
  <div
    class="content-container"
    *ngIf="detail.currentNavigation === 'table'"
  >
    <div class="toolbar-wrapper">
      <app-building-progress-detail-toolbar-container
        *ngIf="permissionHelper.hasPermission"
      >
      </app-building-progress-detail-toolbar-container>
    </div>

    <div class="content-wrapper">
      <div class="table-view">
        <div class="table-wrapper">
          <app-building-progress-filter
            *ngIf="permissionHelper.hasPermission && detail.isFilterVisible && !panelHelper.isMobileDevice"
            [isSummaryPanelButtonVisible]="!hidePrices"
            (refresh)="detail.onRefresh()"
            (summaryPanel)="detail.onSummaryPanel()"
          ></app-building-progress-filter>

          <div class="data-container">
            <kros-side-panel>
              <ng-template #rightPanel>
                <app-comments-panel
                  *ngIf="panelHelper.rightPanelType === buildingProgressPanelEnum.Comments"
                  [data]="panelHelper.commentData"
                  [readOnly]="areCommentsReadOnly()"
                  (close)="panelHelper.onCloseComments()"
                  (clickComment)="onClickComment($event)"
                ></app-comments-panel>

                <app-periods-panel
                  *ngIf="panelHelper.rightPanelType === buildingProgressPanelEnum.Periods"
                  [searchModel]="detail.currentSearchModel"
                  [projectId]="detail.projectId"
                  [permission]="permissionHelper.permission"
                  [readonly]="licenseHelper.isReadonlyLicense"
                  (close)="panelHelper.onClosePeriods()"
                  (periodDeleted)="periodHelper.onPeriodDeleted()"
                ></app-periods-panel>

                <app-item-period-details
                  *ngIf="panelHelper.rightPanelType === buildingProgressPanelEnum.Details"></app-item-period-details>

                <app-budget-item-notes
                  *ngIf="panelHelper.rightPanelType === buildingProgressPanelEnum.Notes"></app-budget-item-notes>
              </ng-template>

              <ng-template #bottomPanel>
                <app-budget-boq
                  *ngIf="panelHelper.bottomPanelType === buildingProgressPanelEnum.Boq"
                  [data]="panelHelper.boqBudgetItem"
                  [decimalPlaces$]="buildingProgressSelectorsService.constructionDataDecPlaces$"
                  [boqItems$]="buildingProgressSelectorsService.boqItems$"
                  [boqItemsIsLoading$]="buildingProgressSelectorsService.boqItemsIsLoading$"
                  (closePanelClicked)="panelHelper.onCloseBoq()"
                >
                </app-budget-boq>

                <app-boq-periods
                  *ngIf="panelHelper.bottomPanelType === buildingProgressPanelEnum.BoqPeriods"
                  [data]="panelHelper.boqBudgetItem"
                ></app-boq-periods>
              </ng-template>

              <ng-template #content>
                <ng-container *ngIf="permissionHelper.hasPermission else noPermission">
                  <ng-container *ngIf="!detail.isEmptyContent else emptyContent">
                    <ng-container *ngIf="detail.areConstructionDataLoaded; else contentLoader">
                      <kros-data-table
                        *ngIf="detail.constructionData?.items.length > 0 else noItems"
                        [itemHeight]="buildingProgressTableService.averageLineHeight"
                        [data]="detail.constructionData"
                        [itemCount]="detail.itemCount"
                        [config]="detail.dataTableConfig"
                        [focusedCell]="detail.focusedCell"
                        [forceChangeDetection]="tableChangeDetectionService.forceTableChangeDetection"
                        [hiddenColumns$]="buildingProgressSelectorsService.hiddenColumns$"
                        [appInsightsPrefix]="appInsightsPrefix"
                        (cellFocused)="onCellFocused($event)"
                        (scrollFocusedCellToView)="onScrollFocusedCellToView($event)"
                        (itemCheckChanged)="onItemCheckChanged($event.item, $event.state)"
                        (headerCheckChanged)="onHeaderCheckChanged($event)"
                        (openCommentsClick)="detail.onOpenComments($event)"
                      ></kros-data-table>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-template>
            </kros-side-panel>
          </div>

          <div
            *ngIf="detail.isSummaryPanelVisible && detail.constructionData?.items.length > 0 && !hidePrices"
            class="summary-panel"
          >
            <app-building-progress-summary-panel
              [model]="getSummaryPanelData()"
              (close)="detail.onSummaryPanel()"
            >
            </app-building-progress-summary-panel>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!panelHelper.isMobileDevice && detail.currentNavigation === 'dashboard' && detail.projectId">
    <ng-container *ngTemplateOutlet="dashboard"></ng-container>
  </ng-container>
</div>

<ng-template #mobileContainer>
  <div
    class="detail-mobile-container"
    data-test="detail-mobile"
  >
    <div
      *ngIf="detail.isMobileSearchVisible && detail.currentNavigation === 'table'"
      class="search"
    >
      <kros-search-input [dataTestPrefix]="'mobile-'"></kros-search-input>
    </div>

    <app-building-progress-detail-mobile-toolbar
      *ngIf="detail.currentNavigation === 'table'"
      [searchVisible]="permissionHelper.hasPermission && !detail.isEmptyContent && detail.constructionData?.items.length > 0"
      (searchClicked)="onSearchClicked()"
    >
    </app-building-progress-detail-mobile-toolbar>

    <ng-container *ngIf="detail.areConstructionDataLoaded; else contentLoader">
      <ng-container *ngIf="permissionHelper.hasPermission else noPermission">
        <ng-container *ngIf="!detail.isEmptyContent else emptyContent">
          <app-building-progress-mobile-table
            *ngIf="detail.constructionData?.items.length > 0 else noItems"
            class="mobile-table"
            [selectedPeriod]="buildingProgressSelectorsService.period$ | async"
            [showSelection]="detail.isMobileSelectionVisible"
            [ngClass]="{'hidden': detail.currentNavigation === 'dashboard' || detail.currentNavigation === 'invoice'}"
            [hidePrices]="hidePrices"
          ></app-building-progress-mobile-table>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="panelHelper.isMobileDevice">
      <ng-container *ngIf="detail.currentNavigation === 'dashboard' && detail.projectId">
        <ng-container *ngTemplateOutlet="dashboard"></ng-container>
      </ng-container>

      <app-building-progress-invoice-mobile *ngIf="detail.currentNavigation === 'invoice' && detail.projectId">
      </app-building-progress-invoice-mobile>
    </ng-container>

    <kros-bottom-navbar *ngIf="!detail.isMobileSelectionVisible">
      <div
        *ngIf="detail.currentNavigation !== 'invoice' &&
                permissionHelper.hasPermission && !detail.isEmptyContent && !detail.isViewOrSearchActive
                    && (!permissionHelper.isContractor || permissionHelper.isContractorFull)"
        class="navbar-action"
        data-test="mobile-navbar-action-dashboard"
        [class.selected]="detail.currentNavigation === 'dashboard'"
        (click)="onNavigationClick('dashboard')"
      >
        <i class="material-icons">dashboard</i>
        <span>{{ 'BUILDING_PROGRESS.DASHBOARD.DASHBOARD' | translate }}</span>
      </div>

      <div
        *ngIf="treeOrTableVisible && permissionHelper.hasPermission && !detail.isEmptyContent"
        class="navbar-action"
        data-test="mobile-navbar-action-tree"
        [class.selected]="detail.currentNavigation === 'tree'"
        (click)="onNavigationClick('tree')"
      >
        <i class="material-icons">account_tree</i>
        <span>{{ 'COMMON.TREE' | translate }}</span>
      </div>

      <div
        class="navbar-action"
        data-test="mobile-navbar-action-table"
        [class.selected]="detail.currentNavigation === 'table'"
        (click)="onNavigationClick('table')"
      >
        <i class="material-icons">leaderboard</i>
        <span>{{ 'BUILDING_PROGRESS.EXECUTIONS' | translate }}</span>
      </div>

      <div
        *ngIf="canShowInvoice"
        class="navbar-action"
        data-test="mobile-navbar-action-invoice"
        [class.selected]="detail.currentNavigation === 'invoice'"
        (click)="onNavigationClick('invoice')"
      >
        <i class="material-icons">description</i>
        <span>{{ 'BUILDING_PROGRESS.INVOICE.INVOICES' | translate }}</span>
      </div>

      <div
        *ngIf="treeOrTableVisible && permissionHelper.hasPermission && !permissionHelper.isContractor && !detail.isEmptyContent"
        class="navbar-action"
        data-test="mobile-navbar-action-photogallery"
        (click)="onOpenPhotoGallery()"
      >
        <i class="material-icons">collections</i>
        <span>{{ 'BUILDING_PROGRESS.PHOTO_GALLERY_ABBR' | translate }}</span>
      </div>
    </kros-bottom-navbar>
  </div>
</ng-template>

<ng-template #contentLoader>
  <kros-timeline [type]="timelineType.Table"></kros-timeline>
</ng-template>

<ng-template #emptyContent>
  <ng-container *ngIf="!permissionHelper.isContractor else noItems">
    <ssw-building-progress-create
      [projectId]="detail.projectId"
      [isDemo]="isDemo"
    >
    </ssw-building-progress-create>
  </ng-container>
</ng-template>

<ng-template #noPermission>
  <div class="empty-content text-center">
    <p data-test="empty-content">{{ 'BUILDING_PROGRESS.NO_PERMISSION' | translate }}</p>

    <button
      *ngIf="canShowOpenDemoLink()"
      class="btn btn-link no-selection"
      (click)="onOpenDemo()"
    >{{ 'BUILDING_PROGRESS.TRY_IN_DEMO' | translate }}</button>
  </div>
</ng-template>

<ng-template #noItems>
  <div class="no-items text-center">
    <h5>{{ 'COMMON.NO_ITEMS_MESSAGE' | translate }}</h5>
  </div>
</ng-template>

<ng-template #dashboard>
  <app-building-progress-dashboard
    [projectId]="detail.projectId"
    (closeClick)="detail.currentNavigation = 'table'"
  ></app-building-progress-dashboard>
</ng-template>
