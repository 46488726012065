import { ChangeDetectionStrategy, Component,
  EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, Validators } from '@angular/forms';

import { debounceTime, map, switchMap } from 'rxjs';
import { SubSink } from 'subsink';

import { ApiSearchService } from '../api-search/api-search.service';
import { AutocompleteConfig, InputCommandType, KrosFormsService } from '../../inputs';
import { DeviceDetectorService, DeviceType } from '../../services';

@Component({
  selector: 'kros-town-selector',
  templateUrl: './town-selector.component.html',
  styleUrls: ['./town-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TownSelectorComponent,
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TownSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor, OnChanges {
  @Input() required = false;
  @Input() showAllErrors = false;

  @Output() newCity: EventEmitter<{ city: string, postCode: string }> = new EventEmitter<{ city: string, postCode: string }>();

  @ViewChild('optionTemplate', { static: true }) optionTemplate: TemplateRef<any>;
  autocompleteConfig: AutocompleteConfig;

  cityForm = new UntypedFormControl('');

  private subs = new SubSink();

  constructor(
    private formService: KrosFormsService,
    private deviceDetector: DeviceDetectorService,
    private apiSearchService: ApiSearchService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes['showAllErrors']?.currentValue && changes['showAllErrors'].currentValue !== changes['showAllErrors'].previousValue) {
      this.cityForm.markAllAsTouched();
      this.formService.triggerInputCommand('city', { type: InputCommandType.DETECT_CHANGES });
    }
  }

  ngOnInit(): void {
    if (this.required) this.cityForm.setValidators(Validators.required);
    this.autocompleteConfig = {
      searchMethod: (text$) => text$.pipe(
        debounceTime(200),
        switchMap(term => this.apiSearchService.fetchAddressItems(term, 'CityName').pipe(
          map(addresses => {
            return addresses.map(address => {
              return { ...address, postCode: address.postCode.replace(/^(.{3})(.*)$/, '$1 $2') };
            });
          })
        ))
      ),
      inputFormatter: (x: any): string => {
        this.newCity.emit({ city: x.city, postCode: x.postCode });
        return x.city;
      },
      minOptionsShown: 0,
      optionTemplate: this.optionTemplate,
      openOnFocus: true,
      optionHeight: 42
    };
    if (this.deviceDetector.deviceType === DeviceType.Mobile) {
      // open to top, because of mobile keyboard
      this.autocompleteConfig.positions = [
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom'
        }
      ];
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  writeValue(name: any): void {
    this.cityForm.setValue(name,
      {
        emitEvent: false
      });
    this.formService.triggerInputCommand('city', { type: InputCommandType.DETECT_CHANGES });
  }

  registerOnChange(fn: any): void {
    this.subs.add(this.cityForm.valueChanges.subscribe(fn));
  }

  registerOnTouched(fn: any): void { }
}
