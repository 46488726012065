<div
  class="settings-container"
  cdkScrollable
>
  <div
    class="settings-container-center"
    cdkScrollable
  >
    <div class="kros-grid">
      <div class="sidebar-wrapper">
        <aside>
          <h1>{{ 'SETTINGS.SETTINGS' | translate }}</h1>

          <a
            class="menu-item"
            [routerLink]="['general']"
            [routerLinkActive]="['selected-item']"
            data-test="sidebar-general-settings"
          >{{ 'SETTINGS.GENERAL' | translate }}</a>

          <a
            class="menu-item"
            [routerLink]="['budget']"
            [routerLinkActive]="['selected-item']"
            data-test="sidebar-budget-settings"
          >{{ 'SETTINGS.BUDGET' | translate }}</a>

          <a
            class="menu-item"
            [routerLink]="['building-progress']"
            [routerLinkActive]="['selected-item']"
            data-test="sidebar-building-progress-settings"
          >{{ 'SETTINGS.BUILDING_PROGRESS' | translate }}</a>
        </aside>
      </div>

      <div class="content-wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
