<div class="kros-modal-header">
  <h4 class="kros-modal-title">{{'SHARING.BUILDING_PROGRESS.APPROVERS_ORDER' | translate }}</h4>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onClose()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div class="kros-modal-body">
  <div>{{ 'SHARING.BUILDING_PROGRESS.APPROVERS_ORDER_INFO' | translate }}</div>

  <bp-approval-order-table
    #approvalOrderTableRef
    [projectId]="projectId"
    [isReadOnly]="isTableReadOnly"
    (orderChanged)="onOrderChanged($event)"
  ></bp-approval-order-table>
</div>

<div class="kros-modal-footer">
  <button
    class="btn btn-primary btn-save"
    data-test="approval-order-save-button"
    type="button"
    [disabled]="!canSave"
    (click)="onSave()"
  >{{'COMMON.ULOZIT' | translate }}</button>
</div>
