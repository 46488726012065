import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { formatNumber } from '@angular/common';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';

import { combineLatest, Observable } from 'rxjs';

import {
  BillOfQuantitiesItemType,
  DecimalPlaces,
  isArrowKey,
  isDownKey,
  isItem,
  isUpDownKey,
  ProjectDetail,
  TimelineType,
  TranslateService
} from '@kros-sk/ssw-shared-legacy';
import { BuildingProgressPermission, PermissionType } from '@kros-sk/ssw-shared/permission';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { BoqEditationService } from '../../../../building-progress/services/boq-editation.service';
import { BoqItemViewModel } from '../../../../building-progress/models/boq-edit.view-model';
import { BoqPeriodItem } from '../../../../building-progress/models/boq-period-item.model';
import { BoqVisualHelper } from '../../../../building-progress/helpers/boq-visual.helper';
import { BuildingProgressActionAccessService } from '../../../../building-progress/services/building-progress-action-access.service';
import { BuildingProgressDispatchersService, BuildingProgressSelectorsService } from '../../../../store/building-progress';
import { BuildingProgressItem } from '../../../../building-progress/models/construction-data.model';
import { BuildingProgressPanelHelper } from '../../../../building-progress/helpers';
import { BuildingProgressPeriod } from '../../../../building-progress/models/building-progress-period.model';
import { environment } from '../../../../../environments/environment';
import { getResultAmount } from '../../../../building-progress/helpers/boq.helper';
import { PeriodDatePipe } from '../../../../building-progress/modules/periods/pipes/period-date.pipe';
import { ProjectsSelectorsService } from '../../../../store/projects';

@Component({
  selector: 'app-boq-periods',
  templateUrl: './boq-periods.component.html',
  styleUrls: ['./boq-periods.component.scss']
})
export class BoqPeriodsComponent extends UnsubscribeComponent implements OnInit, OnDestroy {
  @Input() data: BuildingProgressItem;
  @Input() isReadOnly = false;

  items: BoqPeriodItem[];
  isLoading = true;
  periods: BuildingProgressPeriod[];
  permission: BuildingProgressPermission;
  timelineType = TimelineType;
  editingBoqBudgetItemIds: number[];
  currentPeriod: BuildingProgressPeriod;
  form = this.fb.array([]);

  private decimalPlaces: DecimalPlaces;
  private periodId: number;
  private resetOriginalItems = false;
  private isClickOutside = false;
  private lastCheckedItemId: number;

  get readonly(): boolean {
    if (!this.isReadOnly && this.currentPeriod && this.permission && this.isItem) {
      return this.permission.permissionType === PermissionType.Reader ||
        this.currentPeriod.isLocked ||
        this.currentPeriod.isApproved ||
        this.currentPeriod.isApprovalRequested;
    } else {
      return true;
    }
  }

  get isAccessDisabled(): boolean {
    return this.actionAccessService.checkForAccessToBoqControl();
  }

  get isBoqLoading(): boolean {
    return this.isLoading ||
      (this.items?.length > 0 && this.editingBoqBudgetItemIds && this.editingBoqBudgetItemIds.includes(this.items[0].budgetItemId));
  }

  get isItem(): boolean {
    return this.data && isItem(this.data);
  }

  get headerText(): string {
    if (this.currentPeriod && this.periods) {
      return this.translateService.translate('BILL_OF_QUANTITIES.PERIOD_BILL_OF_QUANTITIES') +
        ' ' + this.getPeriodText(this.currentPeriod.id);
    } else {
      return this.translateService.translate('BILL_OF_QUANTITIES.BILL_OF_QUANTITIES');
    }
  }

  get editedBoqItems$(): Observable<BoqItemViewModel[]> {
    return this.buildingProgressSelectorsService.editedBoqItems$;
  }

  get deletedBoqItemIds$(): Observable<number[]> {
    return this.buildingProgressSelectorsService.deletedBoqItemIds$;
  }

  get resultAmount(): number {
    return this.boqEditationService.resultAmount;
  }

  get isDeleteDisabled(): boolean {
    const periodId = this.items[this.boqVisualHelper.focusedCell?.rowIndex]?.periodId;
    return this.readonly || this.items.length === 0 || this.isDisabled(periodId);
  }

  get hasItems(): boolean {
    return this.items?.length && !this.isLoading;
  }

  get hasAccess(): boolean {
    return this.actionAccessService.hasAccessToBoq;
  }

  constructor(
    public boqVisualHelper: BoqVisualHelper,
    private actionAccessService: BuildingProgressActionAccessService,
    private buildingProgressDispatchersService: BuildingProgressDispatchersService,
    private buildingProgressSelectorsService: BuildingProgressSelectorsService,
    private datePipe: PeriodDatePipe,
    private projectsSelectorsService: ProjectsSelectorsService,
    private panelHelper: BuildingProgressPanelHelper,
    private translateService: TranslateService,
    private fb: UntypedFormBuilder,
    private boqEditationService: BoqEditationService,
    private elementRef: ElementRef,
  ) {
    super();
  }

  @HostListener('document:mousedown', ['$event'])
  clickOut(event: MouseEvent): void {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (event.target && clickedInside) {
      this.isClickOutside = !clickedInside;
    } else {
      if (!this.isClickOutside && this.boqVisualHelper.focusedCell) {
        this.isClickOutside = true;
        const boqRowIndex = this.boqVisualHelper.focusedCell.rowIndex;
        const expression = this.form.at(boqRowIndex)?.value;
        const expressionChanged = expression !== '' && this.items[boqRowIndex]?.expression !== expression;
        const boqEdited = this.boqEditationService.hasEdits || expressionChanged;

        if (boqEdited) {
          event.preventDefault();
        }
        if (expressionChanged) {
          this.edit(boqRowIndex);
        }
        this.boqEditationService.saveBoqItemsWithQuestion((submit: boolean) => {
          if (!submit && this.data) {
            this.panelHelper.loadBoqByPanelType(this.data.id);
          }
          this.isClickOutside = false;
          if (boqEdited && (event.target as HTMLElement)?.click) {
            (event.target as HTMLElement)?.click();
          }
        });
      }
    }
  }

  ngOnInit(): void {
    this.subs.sink = combineLatest(
      [this.buildingProgressSelectorsService.periodId$, this.buildingProgressSelectorsService.constructionData$])
      .subscribe(([periodId, constructionData]) => {
        this.periodId = periodId;
        this.periods = constructionData.periods;
        this.currentPeriod = this.periods?.find(p => p.id === this.periodId);
      });
    this.subs.sink = this.buildingProgressSelectorsService.boqPeriodItems$
      .subscribe(items => this.reloadFormData(items));
    this.subs.sink = this.buildingProgressSelectorsService.constructionDataDecPlaces$
      .subscribe(resp => this.decimalPlaces = resp);
    this.subs.sink = this.projectsSelectorsService.projectDetail$
      .subscribe((project: ProjectDetail) => {
        if (project) {
          this.boqEditationService.projectId = project.id;
        }
      });
    this.subs.sink = this.buildingProgressSelectorsService.permission$
      .subscribe(permission => this.permission = permission);
    this.subs.sink = this.buildingProgressSelectorsService.boqPeriodItemsIsLoading$
      .subscribe(resp => {
        this.isLoading = resp;
        if (!resp) {
          this.setOriginalItems(this.items);
        }
      });
    this.subs.sink = this.buildingProgressSelectorsService.resetOriginalItems$
      .subscribe(resp => {
        this.resetOriginalItems = resp;
        if (resp) {
          this.setOriginalItems(this.items);
        }
      });
    this.subs.sink = this.buildingProgressSelectorsService.editingBoqBudgetItemIds$
      .subscribe(editingBoqBudgetItemIds => {
        this.editingBoqBudgetItemIds = editingBoqBudgetItemIds;
        if (!editingBoqBudgetItemIds || editingBoqBudgetItemIds.length === 0) {
          this.boqVisualHelper.scrollToFocusedCell(this.hasItems);
        }
      });
    this.boqEditationService.init();
  }

  onDestroy(): void {
    this.boqEditationService.destroy();
  }

  private reloadFormData(items: BoqPeriodItem[]): void {
    const sortedItems = items.sort((p, r) => p.itemOrder - r.itemOrder);
    this.setOriginalItems(sortedItems);
    this.items = sortedItems;
    this.boqEditationService.resultAmount = getResultAmount(items, this.periodId);
  }

  private setOriginalItems(items: BoqPeriodItem[]): void {
    if (!this.isLoading &&
      items.length &&
      (!this.boqEditationService.originalBudgetItem ||
        this.resetOriginalItems ||
        this.boqEditationService.originalBudgetItem?.id !== items[0].budgetItemId)) {
      this.boqEditationService.setOriginalItems(items, this.periodId, this.data);
      this.boqVisualHelper.setFocusedCellOnLoad(items, this.hasItems);
      this.form = this.fb.array([]);
      items.forEach(item => {
        this.form.push(new UntypedFormControl(item.expression));
      });
    }
  }

  getCellClass(item: BoqPeriodItem): string {
    switch (item.boqItemType) {
      case BillOfQuantitiesItemType.Note:
        return (this.readonly || this.isDisabled(item.periodId)) ? 'color-gray' : 'color-orange';
      case BillOfQuantitiesItemType.Subtotal:
        return 'color-blue';
      case BillOfQuantitiesItemType.Total:
        return 'color-red';
      default:
        if (this.readonly || this.isDisabled(item.periodId)) {
          return 'color-gray';
        }
    }
  }

  getFormattedAmount(amount: number): string {
    const decPlaces = this.decimalPlaces?.amount;
    return decPlaces ? formatNumber(amount, environment.location, `1.${decPlaces}-${decPlaces}`) : `${amount}`;
  }

  getExpressionFormControl(index: number): UntypedFormControl {
    return this.form.at(index) as UntypedFormControl;
  }

  onCloseClick(): void {
    this.boqEditationService.saveBoqItemsWithQuestion(() => this.panelHelper.onCloseBoq());
  }

  onResizePanelClick(): void {
    this.panelHelper.resizeBoq();
  }

  onItemCheckChanged(event: any, item: BoqPeriodItem): void {
    if (this.actionAccessService.isLicenseFree || this.actionAccessService.isLicenseReadOnly) {
      event.checked = !event.checked;
    } else {
      this.buildingProgressDispatchersService.markBoq(item, event.checked ? this.periodId : null);
      this.lastCheckedItemId = event.checked ? item.id : undefined;
    }
  }

  onItemCheck(event: any, item: BoqPeriodItem): void {
    if (event.shiftKey && this.lastCheckedItemId !== undefined) {
      this.getShiftMarkedItems(item.id).forEach(i =>
        this.buildingProgressDispatchersService.markBoq(i, this.periodId));
      this.lastCheckedItemId = item.id;
    } else if (event.shiftKey) {
      this.buildingProgressDispatchersService.markBoq(item, event.target.checked ? this.periodId : null);
      this.lastCheckedItemId = event.target.checked ? item.id : undefined;
    }
  }

  onSaveClick(): void {
    if (this.actionAccessService.checkForAccessToBoq()) {
      this.boqEditationService.saveBoqItems(this.periodId, this.data);
    }
  }

  isPeriodIdNull(periodId: number): boolean {
    return !periodId;
  }

  isExpression(item: BoqPeriodItem): boolean {
    return item.boqItemType === BillOfQuantitiesItemType.Expression;
  }

  isNote(item: BoqPeriodItem): boolean {
    return item.boqItemType === BillOfQuantitiesItemType.Note;
  }

  isDisabled(periodId: number): boolean {
    return !(this.isPeriodIdNull(periodId) || this.periodId === periodId);
  }

  onCellClick(rowIndex: number, colId: number): void {
    if (!this.readonly && this.actionAccessService.checkForAccessToBoq()) {
      this.boqVisualHelper.focusedCell = { colId, rowIndex };
    }
  }

  isExpressionOrNote(item: BoqPeriodItem): boolean {
    return !!item && (item.boqItemType === BillOfQuantitiesItemType.Expression || item.boqItemType === BillOfQuantitiesItemType.Note);
  }

  onExpressionChange(target: HTMLInputElement, index: number): void {
    const item = this.items[index];
    if (this.isPeriodIdNull(item.periodId)) {
      const selectionStart = target.selectionStart;
      this.buildingProgressDispatchersService.markBoq(item, this.periodId);
      this.boqVisualHelper.setFocusedCell(index, this.hasItems, selectionStart);
    }
  }

  edit(index: number, refocusCell: boolean = false): void {
    this.boqEditationService.edit(this.items, this.items[index], this.getExpressionFormControl(index)?.value, refocusCell);
  }

  onCellKeyDown(event: any, rowIndex: number, colId: number): void {
    const previousFocusedRowIndex = this.boqVisualHelper.focusedCell.rowIndex;

    if (!this.readonly && this.actionAccessService.checkForAccessToBoq()) {
      this.boqVisualHelper.focusedCell = { colId, rowIndex };
    }

    if (!this.isAccessDisabled) {
      const isInput = event.target.tagName.toLowerCase() === 'input';

      if ((isInput && isUpDownKey(event.key)) || (!isInput && isArrowKey(event.key))) {
        event.preventDefault();
        this.boqVisualHelper.focusCell(this.items.length, event.key.toLowerCase(), event.shiftKey);
      }

      if (isDownKey(event.key) &&
        previousFocusedRowIndex === this.boqVisualHelper.focusedCell.rowIndex &&
        this.boqVisualHelper.focusedCell.rowIndex === this.items.length - 1) {
        event.preventDefault();
        this.onCreate(true);
      }
    }
  }

  onDelete(): void {
    if (this.actionAccessService.checkForAccessToBoq()) {
      const item = this.items[this.boqVisualHelper.focusedCell.rowIndex];
      this.form.removeAt(this.boqVisualHelper.focusedCell.rowIndex);
      const editedBoqItems = this.boqEditationService.recalculateSums({ ...item, quantity: 0 }, this.items);
      this.buildingProgressDispatchersService.deleteBoq(item.id, editedBoqItems);
      const rowIndex = this.boqVisualHelper.focusedCell.rowIndex;
      this.boqVisualHelper.setFocusedCell(this.items.length === rowIndex ? rowIndex - 1 : rowIndex, this.hasItems);
    }
  }

  onCreate(isInsertUnder = false): void {
    if (this.actionAccessService.checkForAccessToBoq()) {
      const order = this.items[this.boqVisualHelper.focusedCell?.rowIndex]?.itemOrder ?? 1;
      const index = this.boqVisualHelper.focusedCell.rowIndex +
        (isInsertUnder ? 1 : 0);
      this.buildingProgressDispatchersService.createBoq(this.data.id, order, isInsertUnder);
      this.form.insert(index, new UntypedFormControl(''));
      this.boqVisualHelper.setFocusedCell(index, this.hasItems);
      this.buildingProgressDispatchersService.markBoq(this.items[index], this.periodId);
    }
  }

  private getShiftMarkedItems(itemId: number): BoqPeriodItem[] {
    const selectedIndex = this.items.findIndex(i => i.id === itemId);
    const lastSelectedIndex = this.items.findIndex(i => i.id === this.lastCheckedItemId);

    return ((selectedIndex < lastSelectedIndex)
      ? this.getMarkedItemsByIndex(selectedIndex, lastSelectedIndex)
      : this.getMarkedItemsByIndex(lastSelectedIndex, selectedIndex)
    ).filter(mi => mi && !(mi.id === itemId || mi.id === this.lastCheckedItemId));
  }

  private getMarkedItemsByIndex(startIndex: number, lastIndex: number): any[] {
    return this.items.slice(startIndex, lastIndex + 1)
      .filter(item => ![BillOfQuantitiesItemType.Subtotal, BillOfQuantitiesItemType.Total].includes(item.boqItemType));
  }

  private getPeriodText(periodId: number): string {
    const period = this.periods?.find(p => p.id === periodId);

    if (this.isPeriodIdNull(periodId) || !period) {
      return '';
    }
    return this.datePipe.transform(period.dateFrom, period.dateTo);
  }
}
