import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { KrosNavigationModule } from '@kros-sk/components';
import { PATH_QM_AREA, PATH_QM_EXTRACTION, PATH_QM_STRUCTURE } from '@kros-sk/ssw-shared/constants';

@Component({
  standalone: true,
  selector: 'qm-navigation',
  templateUrl: './quantity-manager-navigation.component.html',
  styleUrls: ['./quantity-manager-navigation.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    KrosNavigationModule
  ]
})
export class QuantityManagerNavigationComponent {
  @Input() routePrefix: string;
  @Input() isNarrowNavbar: boolean;
  @Input() areNavigationsVisible: boolean;
  @Input() isAreaTabVisible: boolean;
  @Input() isBudgetTabVisible: boolean;

  pathQmStructure = PATH_QM_STRUCTURE;
  pathQmArea = PATH_QM_AREA;
  pathQmExtraction = PATH_QM_EXTRACTION;
}
