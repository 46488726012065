export interface BuildingProgressSharingResultInfo {
  result: BuildingProgressSharingResultInfoEnum;
  message: string;
}

export enum BuildingProgressSharingResultInfoEnum {
  UserWithEmailNotFound = 1,
  NoPermissionForSharingBuildingProgress = 2,
  MultipleSharingActions = 3
}
