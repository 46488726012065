import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { LoadUserIdGuard } from './load-user-id.guard';
import { UISettingsService } from './ui-settings.service';
import { UserService } from './user.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    UserService,
    LoadUserIdGuard,
    UISettingsService
  ],
  exports: []
})
export class UserModule {
  public static forRoot(
    gatewayUrl: string
  ): ModuleWithProviders<UserModule> {
    return {
      ngModule: UserModule,
      providers: [
        { provide: 'gatewayUrl', useValue: gatewayUrl }
      ]
    };
  }
}
