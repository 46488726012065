import {
  APP_BOOTSTRAP_LISTENER,
  Inject,
  InjectionToken,
  Type
} from '@angular/core';

import { EffectSources } from '@ngrx/effects';

export const BOOTSTRAP_EFFECTS = new InjectionToken('Bootstrap Effects');

export function bootstrapEffects(effectArrays: Type<any>[], sources: EffectSources) {
  return (): void => {
    const effects = effectArrays.reduce((accumulator, effects) => accumulator.concat(effects), []);
    effects.forEach(effect => sources.addEffects(effect));
  };
}

export function createInstances(...instances: any[]): any[] {
  return instances;
}

export function provideBootstrapEffects(effects: Type<any>[]): any[] {
  return [
    effects,
    {
      provide: BOOTSTRAP_EFFECTS,
      multi: true,
      useFactory: createInstances,
      deps: effects
    },
    {
      provide: APP_BOOTSTRAP_LISTENER,
      multi: true,
      useFactory: bootstrapEffects,
      deps: [[new Inject(BOOTSTRAP_EFFECTS)], EffectSources]
    }
  ];
}
