import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter } from 'rxjs/operators';

import { StorageService, } from '@kros-sk/ssw-cdk';

import { BudgetApprovalHelper } from '../../helpers';
import { budgetChangesTableId } from '../../constants/budget-approval-constants';
import { PermissionHelper } from '../../../building-progress/helpers';
import { ProjectsSelectorsService } from '../../../store/projects';

const budgetChangesPanelCollapsedStorageItem = 'budgetChangesPanelCollapsed';

@Component({
  selector: 'app-budget-changes',
  templateUrl: './budget-changes.component.html',
  styleUrls: ['./budget-changes.component.scss'],
  providers: [PermissionHelper]
})
export class BudgetChangesComponent implements OnInit {

  private destroyRef = inject(DestroyRef);
  private storageService = inject(StorageService);
  private router = inject(Router);
  private projectsSelectorsService = inject(ProjectsSelectorsService);
  private budgetApprovalHelper = inject(BudgetApprovalHelper);

  panelCollapsed = false;
  projectId: number;
  tableId = budgetChangesTableId;

  ngOnInit(): void {
    this.budgetApprovalHelper.setTitleAndNavbar();

    this.panelCollapsed = JSON.parse(this.storageService.getItemFromLocalStorage(budgetChangesPanelCollapsedStorageItem));
    this.projectsSelectorsService.projectDetail$.pipe(filter(p => !!p), takeUntilDestroyed(this.destroyRef))
      .subscribe(p => this.projectId = p.id);
  }

  onPanelCollapsed(collapsed: boolean): void {
    this.panelCollapsed = collapsed;
    this.storageService.setItemToLocalStorage(budgetChangesPanelCollapsedStorageItem, this.panelCollapsed.toString());
  }

  onBackClick(): void {
    this.router.navigate(['projects/project-detail', this.projectId, 'budget-approval']);
  }
}
