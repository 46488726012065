import { CdkTreeModule } from '@angular/cdk/tree';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { LetDirective } from '@ngrx/component';
import { StoreModule } from '@ngrx/store';

import {
  AnalyticsModule,
  AppInsightsUserContextHelper,
  BudgetBoqModule,
  BudgetStructureModule,
  DataTableModule,
  ImageUploadButtonModule,
  PipesModule,
  SidePanelModule,
  TranslateModule
} from '@kros-sk/ssw-shared-legacy';
import { provideAppInsights } from '@kros-sk/core/application-insights';

import { BudgetApprovalApproveService } from './services/budget-approval-approve.service';
import { BudgetApprovalBoqService } from './services/budget-approval-boq.service';
import { BudgetApprovalComponent } from './budget-approval.component';
import { BudgetApprovalContainerComponent } from './containers/budget-approval-container/budget-approval-container.component';
import { BudgetApprovalCreateComponent } from './components/budget-approval-create/budget-approval-create.component';
import { BudgetApprovalDetailComponent } from './components/budget-approval-detail/budget-approval-detail.component';
import { BudgetApprovalDifferencesComponent } from './components/budget-approval-differences/budget-approval-differences.component';
import {
  BudgetApprovalDifferencesToolbarComponent
} from './components/budget-approval-differences-toolbar/budget-approval-differences-toolbar.component';
import {
  BudgetApprovalDispatchersService, budgetApprovalFeatureKey, budgetApprovalReducer, BudgetApprovalSelectorsService
} from '../store/budget-approval';
import { BudgetApprovalExportComponent } from './components/budget-approval-export/budget-approval-export.component';
import { BudgetApprovalFilterComponent } from './components/budget-approval-filter/budget-approval-filter.component';
import { BudgetApprovalFilterService, BudgetApprovalService, ChangeSheetService } from './services';
import { BudgetApprovalHelper, ExportHelper } from './helpers';
import {
  BudgetApprovalItemPanelDialogComponent
} from './components/budget-approval-item-panel-dialog/budget-approval-item-panel-dialog.component';
import { BudgetApprovalRangeModalComponent } from './components/budget-approval-range-modal/budget-approval-range-modal.component';
import { BudgetApprovalStructureComponent } from './components/budget-approval-structure/budget-approval-structure.component';
import { BudgetApprovalToolbarComponent } from './components/budget-approval-toolbar/budget-approval-toolbar.component';
import { BudgetChangePanelContainerComponent } from './containers/budget-change-panel-container/budget-change-panel-container.component';
import { BudgetChangesComponent } from './components/budget-changes/budget-changes.component';
import { BudgetChangesDetailComponent } from './components/budget-changes-detail/budget-changes-detail.component';
import { BudgetChangesToolbarComponent } from './components/budget-changes-toolbar/budget-changes-toolbar.component';
import { ChangeSheetComponent } from './components/change-sheet/change-sheet.component';
import { ChangeSheetContainerComponent } from './containers/change-sheet-container/change-sheet-container.component';
import { ChangeSheetDetailComponent } from './components/change-sheet-detail/change-sheet-detail.component';
import { ChangeSheetPanelContainerComponent } from './containers/change-sheet-panel-container/change-sheet-panel-container.component';
import { ChangeSheetToolbarComponent } from './components/change-sheet-toolbar/change-sheet-toolbar.component';
import { environment } from '../../environments/environment';
import { ExportChangeSheetSettingsComponent } from './components/export-change-sheet-settings/export-change-sheet-settings.component';
import { ExportRangeComponent } from './components/export-range/export-range.component';
import { ExportSummarySettingsComponent } from './components/export-summary-settings/export-summary-settings.component';
import { IfBudgetApprovalFunctionsPermissionDirective } from './directives/if-budget-approval-functions-permission.directive';
import { RelationDialogComponent } from './components/relation-dialog/relation-dialog.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    BudgetApprovalComponent,
    BudgetApprovalContainerComponent,
    BudgetApprovalDetailComponent,
    BudgetApprovalItemPanelDialogComponent,
    BudgetApprovalToolbarComponent,
    BudgetApprovalFilterComponent,
    ChangeSheetComponent,
    ChangeSheetContainerComponent,
    ChangeSheetPanelContainerComponent,
    ChangeSheetDetailComponent,
    RelationDialogComponent,
    ChangeSheetToolbarComponent,
    BudgetChangesComponent,
    BudgetChangePanelContainerComponent,
    BudgetChangesDetailComponent,
    BudgetApprovalExportComponent,
    ExportSummarySettingsComponent,
    BudgetApprovalRangeModalComponent,
    ExportRangeComponent,
    BudgetApprovalStructureComponent,
    IfBudgetApprovalFunctionsPermissionDirective,
    BudgetChangesToolbarComponent,
    ExportChangeSheetSettingsComponent,
    BudgetApprovalDifferencesComponent,
    BudgetApprovalDifferencesToolbarComponent,
    BudgetApprovalCreateComponent
  ],
  exports: [
    BudgetApprovalComponent
  ],
  providers: [
    BudgetApprovalBoqService,
    BudgetApprovalService,
    BudgetApprovalDispatchersService,
    BudgetApprovalSelectorsService,
    BudgetApprovalFilterService,
    BudgetApprovalApproveService,
    ChangeSheetService,
    ExportHelper,
    BudgetApprovalHelper,
    provideAppInsights(() => ({
      instrumentationKey: environment.applicationInsightsKey,
      aiCloudRole: 'Kros.Ssw.FE.BudgetApproval',
      samplingPercentage: 0,
      customConfig: { enableAutoRouteTracking: true }
    })),
    AppInsightsUserContextHelper
  ],
  imports: [
    AnalyticsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    SharedModule,
    DataTableModule,
    PipesModule,
    CdkTreeModule,
    StoreModule.forFeature(budgetApprovalFeatureKey, budgetApprovalReducer),
    SidePanelModule,
    BudgetStructureModule,
    BudgetBoqModule,
    ImageUploadButtonModule,
    LetDirective
  ]
})
export class BudgetApprovalModule {
}
