import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, ViewChild } from '@angular/core';

import { AvatarOptions } from './avatar.interface';
import { getHashCode, getInitials, stripCompanyTypeExtension } from '../tools/string/string-tools';

@Component({
  selector: 'kros-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent {

  @Input() options: AvatarOptions;

  @ViewChild('image', { read: ElementRef, static: true }) image: ElementRef;

  private colorPaletteClasses = ['avatar-red', 'avatar-orange', 'avatar-yellow', 'avatar-dark-green', 'avatar-green', 'avatar-dark-blue',
    'avatar-blue', 'avatar-dark-purple', 'avatar-purple', 'avatar-gray', 'avatar-silver'];

  @HostBinding('class')
  get avatarColor(): string {
    return this.isImageProvided() ? '' : this.colorPaletteClasses[Math.abs(getHashCode(this.identifier)) % this.colorPaletteClasses.length];
  }

  get textToDisplay(): string {
    const strippedText = stripCompanyTypeExtension(this.text);
    return getInitials(strippedText, this.options.initialsMaxLength);
  }

  isImageProvided(): boolean {
    return this.image.nativeElement.children.length !== 0;
  }

  private get text(): string {
    return this.options.text;
  }

  private get identifier(): string {
    if (this.options?.identifier) {
      return this.options.identifier;
    } else {
      return this.options.text;
    }
  }

}
