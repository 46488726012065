import { Platform } from '@angular/cdk/platform';

import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { detectDeviceType } from '../../utils-fn/device-type.utils';
import { deviceDetectorActions } from './device-detector.actions';
import { DeviceType } from '../../types/device-types';

export interface DeviceDetectorState {
  deviceType: DeviceType;
  platform: (keyof Platform)[] | null;
}

const INITIAL_STATE: DeviceDetectorState = {
  deviceType: detectDeviceType(),
  platform: null,
};

const deviceDetectorStoreReducer = createReducer(
  INITIAL_STATE,
  on(deviceDetectorActions.detected, (state, { detectedDevice }): DeviceDetectorState => {
    return {
      ...state,
      ...detectedDevice,
    };
  })
);

export const deviceDetectorStoreFeature = createFeature({
  name: 'deviceDetector',

  reducer: deviceDetectorStoreReducer,

  extraSelectors: ({ selectPlatform }) => ({
    selectIsMobileOS: createSelector(selectPlatform, (platform) => {
      if (!platform) {
        return false;
      }

      return ['ANDROID', 'IOS'].filter((x) => platform.includes(x as keyof Platform)).length > 0;
    }),
  }),
});
