import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import {
  BootstrapModule,
  BudgetBoqModule,
  DirectivesModule,
  TimelineModule,
  TranslateModule
} from '@kros-sk/ssw-shared-legacy';
import { KrosFormFieldModule, KrosInputsModule } from '@kros-sk/components';
import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { BoqPeriodsComponent } from './boq-periods/boq-periods.component';
import { PeriodDatePickerComponent } from './period-date-picker/period-date-picker.component';
import { PeriodDatePipe } from '../../../building-progress/modules/periods/pipes/period-date.pipe';
import { PeriodDeleteDialogComponent } from './period-delete-dialog/period-delete-dialog.component';
import { PeriodFilterDropdownComponent } from './period-filter-dropdown/period-filter-dropdown.component';
import { PeriodOperationTypePipe } from './pipes/period-operation-type.pipe';
import { PeriodRangeControlComponent } from './period-range-control/period-range-control.component';
import { PeriodRangeDropdownComponent } from './period-range-dropdown/period-range-dropdown.component';
import { PeriodsComponent } from './periods/periods.component';
import { PeriodsDialogComponent } from './periods-dialog/periods-dialog.component';
import { PeriodSelectButtonComponent } from './period-select-button/period-select-button.component';
import { PeriodsHistoryDialogComponent } from './periods-history-dialog/periods-history-dialog.component';
import { PeriodsPanelComponent } from './periods-panel/periods-panel.component';

@NgModule({
  declarations: [
    PeriodsDialogComponent,
    PeriodsPanelComponent,
    PeriodsComponent,
    PeriodDatePickerComponent,
    PeriodDeleteDialogComponent,
    PeriodFilterDropdownComponent,
    PeriodRangeControlComponent,
    PeriodRangeDropdownComponent,
    PeriodSelectButtonComponent,
    PeriodDatePipe,
    BoqPeriodsComponent,
    PeriodsHistoryDialogComponent,
    PeriodOperationTypePipe
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ReactiveFormsModule,
    BootstrapModule,
    DirectivesModule,
    KrosInputsModule,
    KrosFormFieldModule,
    TimelineModule,
    BudgetBoqModule,
    CdkTableModule,
    TooltipDirective
  ],
  providers: [
    PeriodDatePipe
  ],
  exports: [
    PeriodsPanelComponent,
    PeriodsDialogComponent,
    PeriodsComponent,
    PeriodDatePickerComponent,
    PeriodDeleteDialogComponent,
    PeriodFilterDropdownComponent,
    PeriodRangeControlComponent,
    PeriodRangeDropdownComponent,
    PeriodDatePipe,
    PeriodSelectButtonComponent,
    BoqPeriodsComponent
  ]
})
export class PeriodsModule { }
