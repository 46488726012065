import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { formatNumber } from '@angular/common';

import { Observable } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { BillOfQuantitiesItemType, BoqItem, DecimalPlaces } from '../../models';
import { BoqCell } from '../models';
import { BoqTableKeyboardHandlingService } from '../boq-table-keyboard-handling.service';
import { IBudgetItem } from '../../budget/helpers';
import { isArrowKey } from '../../data-table/helpers';
import { PanelHelper } from '../panel.helper';
import { TimelineType } from '../../timeline/timeline-type.enum';

const columnCount = 2;

@Component({
  selector: 'app-budget-boq',
  templateUrl: './budget-boq.component.html',
  styleUrls: ['./budget-boq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BudgetBoqComponent extends UnsubscribeComponent implements OnInit {
  @Input() data: IBudgetItem;
  @Input() decimalPlaces$: Observable<DecimalPlaces>;
  @Input() boqItems$: Observable<BoqItem[]>;
  @Input() boqItemsIsLoading$: Observable<boolean>;

  @Output() closePanelClicked = new EventEmitter<void>();

  items: BoqItem[];
  isLoading = true;
  timelineType = TimelineType;

  private decimalPlaces: DecimalPlaces;
  private focusedCell: BoqCell;

  get resultAmount(): number {
    return this.data.amount;
  }

  get canShowBoqItems(): boolean {
    return this.data && this.items?.length > 0;
  }

  get appLocation(): string {
    return this.appConfig.location;
  }

  constructor(
    private changeDetector: ChangeDetectorRef,
    private panelHelper: PanelHelper,
    private boqKeyboardHandlingService: BoqTableKeyboardHandlingService,
    @Inject(APP_CONFIG) private appConfig: any
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.decimalPlaces$
      .subscribe(resp => this.decimalPlaces = resp);

    this.subs.sink = this.boqItems$
      .subscribe(i => this.items = i);

    this.subs.sink = this.boqItemsIsLoading$
      .subscribe(resp => {
        this.isLoading = resp;
        this.changeDetector.detectChanges();
      });
  }

  getCellClass(item: BoqItem): string {
    switch (item.boqItemType) {
      case BillOfQuantitiesItemType.Note:
        return 'color-orange';
      case BillOfQuantitiesItemType.Subtotal:
        return 'color-blue';
      case BillOfQuantitiesItemType.Total:
        return 'color-red';
    }
  }

  getFormattedAmount(amount: number): string {
    const decPlaces = this.decimalPlaces?.amount;
    return decPlaces ? formatNumber(amount, this.appLocation, `1.${decPlaces}-${decPlaces}`) : `${amount}`;
  }

  onCloseClick(): void {
    this.closePanelClicked.emit();
  }

  onResizePanelClick(): void {
    this.panelHelper.resizeBoq();
  }

  isNote(item: BoqItem): boolean {
    return item.boqItemType === BillOfQuantitiesItemType.Note;
  }

  isRowFocused(rowIndex: number): boolean {
    if (this.focusedCell) {
      return rowIndex === this.focusedCell.rowIndex;
    }
    return false;
  }

  onCellClick(rowIndex: number, colId: number): void {
    this.focusedCell = { colId, rowIndex };
  }

  isCellFocused(rowIndex: number, colId: number): boolean {
    if (this.focusedCell) {
      return this.isRowFocused(rowIndex) && this.focusedCell.colId === colId;
    }
    return false;
  }

  onCellKeyDown(event: KeyboardEvent): void {
    if (isArrowKey(event.key) && this.focusedCell) {
      event.preventDefault();
      this.focusedCell = this.boqKeyboardHandlingService.handleTableMovement(
        this.focusedCell, this.items.length, columnCount, event.key.toLowerCase(), event.shiftKey);
      setTimeout(() =>
        document.getElementById(this.getExpressionId(this.focusedCell.rowIndex)).
          scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' }), 0);
    }
  }

  getExpressionId(index: number): string {
    return 'col-expression-' + index;
  }
}
