import { Component, EventEmitter, Output } from '@angular/core';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

import { BudgetApprovalFilterService } from '../../services';
import { BuildingProgressActionAccessService } from '../../../building-progress/services/building-progress-action-access.service';
import { ColumnGroup } from '../../../shared/models/column-group.model';

@Component({
  selector: 'app-budget-approval-filter',
  templateUrl: './budget-approval-filter.component.html',
  styleUrls: ['./budget-approval-filter.component.scss']
})
export class BudgetApprovalFilterComponent {
  columnGroups: ColumnGroup[] = [];

  @Output() columnGroupsCheckChanged = new EventEmitter<ColumnGroup[]>();

  constructor(
    private appInsightsService: AppInsightsBaseService,
    private filterService: BudgetApprovalFilterService,
    private buildingProgressActionAccessService: BuildingProgressActionAccessService
  ) {
    this.initColumnGroups();
  }

  onDefaultViewClick(): void {
    this.appInsightsService.trackEvent('BA-default-view');
    this.filterService.resetFilter();
    this.onColumnGroupsCheckChanged(this.filterService.getColumnGroups());
  }

  onColumnGroupsCheckChanged(columnGroups: ColumnGroup[]): void {
    const onlyDefaultChecked = this.filterService.areOnlyDefaultGroupsChecked(columnGroups);

    if (onlyDefaultChecked || this.buildingProgressActionAccessService.checkForAccessCauseOfReadonlyOrFreeLicense(true)) {
      this.columnGroups = columnGroups;
      this.filterService.saveColumnGroups(this.columnGroups);
      this.columnGroupsCheckChanged.emit(this.columnGroups);
    } else {
      this.initColumnGroups();
    }
  }

  private initColumnGroups(): void {
    if (this.buildingProgressActionAccessService.isLicenseFree || this.buildingProgressActionAccessService.isLicenseReadOnly) {
      this.filterService.saveColumnGroups(this.filterService.getDefaultColumnGroups());
    }
    this.columnGroups = this.filterService.getColumnGroups();
    this.columnGroupsCheckChanged.emit(this.columnGroups);
  }
}
