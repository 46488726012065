import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  DestroyRef,
  ElementRef,
  HostListener,
  inject,
  Inject,
  Input,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';

import { filter } from 'rxjs/operators';

import { APP_CONFIG } from '@kros-sk/app-config';
import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { AuthSelectorsService } from '@kros-sk/auth';
import { DeviceDetectorService, DeviceType } from '@kros-sk/components';
import { RegistrationNotificationService } from '@kros-sk/ssw-cdk';

import { ApplicationModel, ApplicationType, PreparingExternalApplicationModel } from '../application-opener/models';
import { ApplicationOpenerService } from '../application-opener';
import { DocumentTitleService } from './document-title-service/document-title.service';
import { DropdownNavItem } from './shared/dropdown-nav-item.model';
import { NavbarService } from './shared/navbar.service';
import { NewsData } from './shared/news-data.model';
import { ProjectCreateService } from '../project-info';
import { TranslateService } from '../translate/translate.service';

const quantityManager = 'vymery';
const budget = 'budget';

@Component({
  selector: 'kros-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  private appConfig = inject(APP_CONFIG);
  private applicationOpenerService = inject(ApplicationOpenerService);
  private appInsightsService = inject(AppInsightsBaseService);
  private authSelectorsService = inject(AuthSelectorsService, { optional: true });
  private changeDetectorRef = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);
  private deviceDetector = inject(DeviceDetectorService);
  private documentTitleService = inject(DocumentTitleService);
  private navbarService = inject(NavbarService);
  private notificationService = inject(RegistrationNotificationService);
  private projectCreateService = inject(ProjectCreateService);
  private renderer = inject(Renderer2);
  private router = inject(Router);
  private titleService = inject(Title);
  private translateService = inject(TranslateService);

  @ContentChild('customCzLogo') customCzLogo: TemplateRef<ElementRef>;
  @ContentChild('titleButton') titleButton: TemplateRef<ElementRef>;
  @ContentChild('additionalMenuItems') additionalMenuItems: TemplateRef<ElementRef>;
  @ContentChild('additinalSecondaryItems') additinalSecondaryItems: TemplateRef<ElementRef>;
  @ContentChild('addBeta') addBeta: TemplateRef<ElementRef>;
  @ContentChild('menu') menu: TemplateRef<ElementRef>;
  @ViewChild('languageButton', { static: false }) languageButton: ElementRef;

  @Input() loadAppsImmediately: boolean;
  @Input() appIdFilter: string;
  @Input() isAnonymousMode = false;
  @Input() useProjectSelector = true;

  newsData: NewsData;
  krosApplications: ApplicationModel[] = [];
  applications: ApplicationModel[] = [];
  languages: any;
  selectedLanguage: any;
  title = '';
  documentTitles: string[];
  backButtonUrl: string;
  desktopDropdownItems: DropdownNavItem[];
  mobileDropdownItems: DropdownNavItem[];
  isMobile: boolean;
  showKrosIcon: boolean;
  homeApplicationUrl: string;
  projectsLabel: string;

  get isCzechVersion(): boolean {
    return this.appConfig.location === 'cz';
  }

  get activeMobileDropdownItem(): DropdownNavItem {
    return this.mobileDropdownItems?.find(item => item.selected);
  }

  get activeDesktopDropdownItem(): DropdownNavItem {
    return this.desktopDropdownItems?.find(item => item.selected);
  }

  get hasTwoLine(): boolean {
    return this.documentTitles?.length === 2;
  }

  get isKrosAppsLoading(): boolean {
    return this.krosApplications.length > 0;
  }

  get isShowKrosIcon(): boolean {
    return this.showKrosIcon;
  }

  get isAuthorized(): boolean {
    return !!this.authSelectorsService;
  }

  get canShowChat(): boolean {
    return !this.isUnloading && (window as any).HubSpotConversations?.widget?.status()?.loaded;
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(): void {
    this.isUnloading = true;
  }

  private isUnloading = false;
  private widgetHandlerAdded = false;

  constructor(
    @Inject('homeUrlSelector') private homeUrlSelector: (appConfig: any) => string,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.languages = this.translateService.languageOptions;
    this.title = this.titleService.getTitle();

    if (this.languages.length === 1) {
      this.selectedLanguage = this.languages[0];
    } else {
      this.setSelectedLanguage();
    }
  }

  ngOnInit(): void {
    this.translateService.langChanged.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.setSelectedLanguage());
    this.documentTitleService.titleChanged.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(newTitles => this.documentTitles = newTitles);
    this.navbarService.titleChanged.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(title => {
        this.title = title;
        this.changeDetectorRef.detectChanges();
      });
    this.navbarService.backButtonUrlChanged.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(backButtonUrl => this.backButtonUrl = backButtonUrl);
    this.navbarService.desktopDropdownItemsChanged.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(items => this.desktopDropdownItems = items);
    this.navbarService.mobileDropdownItemsChanged.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(items => {
        this.mobileDropdownItems = items;
        this.changeDetectorRef.detectChanges();
      });
    this.navbarService.newsDataChanged.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(data => {
        if (data) {
          this.newsData = data;
          this.showKrosIcon = ['PV', 'ZPV', 'Vymery', 'Budget'].includes(data.modul);
          this.changeDetectorRef.detectChanges();
        }
      });
    this.deviceDetector.deviceType$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(type => this.isMobile = type === DeviceType.Mobile);
    this.notificationService.userRegistrationCompleted$.pipe(filter(p => p.registrationCompleted), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.loadApps());
    this.navbarService.homeApplicationUrlChanged$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(url => this.homeApplicationUrl = url);
    this.navbarService.projectsLabelChanged$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(label => this.projectsLabel = label);

    if (this.loadAppsImmediately && !this.isAnonymousMode) {
      this.loadApps();
    }
  }

  getAppName(app: ApplicationModel): string {
    return this.selectedLanguage ? this.applicationOpenerService.getApplicationDisplayName(app, this.selectedLanguage.id) : '';
  }

  onMobileDropdownItemClick(item: DropdownNavItem): void {
    if (item.url) {
      this.openUrl(item);
    } else {
      item.onClick();
    }
  }

  openUrl(item: DropdownNavItem): void {
    if (!item.canShow || item.canShow()) {
      this.router.navigate([item.url]);
    }
  }

  navigateToHomeOrBack(event: PointerEvent): void {
    if (this.backButtonUrl) {
      this.router.navigate([this.backButtonUrl]);
    } else {
      window.location.href = this.homeApplicationUrl ?? this.homeUrlSelector(this.appConfig);
    }
    event.stopPropagation();
  }

  onSetLanguage(lang): void {
    this.selectedLanguage = lang;
    this.translateService.use(lang.id);
  }

  openApp(app: ApplicationModel): void {
    if (app.applicationType === ApplicationType.RedirectOnly) {
      this.navigateToUrl(app.infoUrl);
    } else if (app.applicationType === ApplicationType.Other) {
      this.openExternalApp(app);
    }
  }

  onCreateProject(): void {
    if (this.appIdFilter === quantityManager) {
      this.projectCreateService.createAndOpenProject('project', 'QM-create-project');
    } else if (this.appIdFilter === budget) {
      this.projectCreateService.createAndOpenProject('project', 'B-create-project');
    } else {
      this.projectCreateService.createAndOpenProject('projects/project-detail', 'P-create-project-navbar');
    }
  }

  onLoadProjects(): void {
    this.navbarService.loadProjects();
  }

  onShowChat(): void {
    const hubSpotConversations = (window as any).HubSpotConversations;
    if (hubSpotConversations) {
      this.appInsightsService.trackEvent('P-chat-open');
      this.showOrHideChatButton(true);
      hubSpotConversations.widget?.open();

      if (!this.widgetHandlerAdded) {
        hubSpotConversations.on('widgetClosed', () => {
          this.showOrHideChatButton(false);
        });
        this.widgetHandlerAdded = true;
      }
    }
  }

  private showOrHideChatButton(show: boolean): void {
    const divElement = this.document.body.querySelector('div#hubspot-messages-iframe-container');

    if (divElement) {
      if (show && !divElement.classList.contains('shown')) {
        this.renderer.addClass(divElement, 'shown');
      } else if (!show && divElement.classList.contains('shown')) {
        this.renderer.removeClass(divElement, 'shown');
      }
    }
  }

  private openExternalApp(app: ApplicationModel): void {
    const data: PreparingExternalApplicationModel = {
      applicationId: app.id,
      projectId: 0,
      documentVersionId: undefined,
      folderId: undefined
    };
    this.applicationOpenerService.openExternalApplication(data);
  }

  private navigateToUrl(url: string): void {
    if (url) {
      const win = window.open(url, '_blank');
      if (win) {
        if (win.opener) {
          win.opener = null;
        }
        win.focus();
      }
    }
  }

  private loadApps(): void {
    this.applicationOpenerService.getApplicationList().pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((apps: ApplicationModel[]) => {
        this.applications = this.filterApps(apps);
        this.krosApplications = this.filterKrosApplications(apps);
      });
  }

  private filterKrosApplications(apps: ApplicationModel[]): ApplicationModel[] {
    return apps.filter(app => this.filterApp(app) && app.isKrosApplication);
  }

  private filterApps(apps: ApplicationModel[]): ApplicationModel[] {
    return apps.filter(app => this.filterApp(app) && !app.isKrosApplication);
  }

  private filterApp(app: ApplicationModel): boolean {
    return (app.applicationType === ApplicationType.Other || app.applicationType === ApplicationType.RedirectOnly) &&
      (!this.appIdFilter || !this.appIdFilter.includes(app.id));
  }

  private setSelectedLanguage(): void {
    if (this.translateService.currentLang) {
      this.selectedLanguage = this.languages.find(language => language.id === this.translateService.currentLang);
    }
  }
}
