import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegistrationNotificationService {
  demoProjectCreated$ = new BehaviorSubject<boolean>(false);
  demoProjectNavigated$ = new Subject<string>();
  userRegistrationCompleted$ = new BehaviorSubject<UserRegistrationCompletedParams>(
    { registrationCompleted: false, demoProjectRequested: undefined });

  notifyRegistrationCompleted(demoProjectRequested: boolean): void {
    this.userRegistrationCompleted$.next({ registrationCompleted: true, demoProjectRequested });
  }

  notifyDemoProjectCreated(): void {
    this.demoProjectCreated$.next(true);
  }

  navigateDemoProject(application: string): void {
    this.demoProjectNavigated$.next(application);
  }
}

export class UserRegistrationCompletedParams {
  registrationCompleted: boolean;
  demoProjectRequested: boolean;
}
