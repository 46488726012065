import { HttpStatusCode } from '@angular/common/http';

import {
  ApplicationLicense,
  InvoicingModuleType,
  License,
  LicenseProblem,
  LicensesState,
  PackageType, WarehouseModuleType,
} from './license.model';
import { ApplicationType } from '../base';

export function emptyLicense(): License {
  return {
    applicationType: ApplicationType.Unknown,
    type: PackageType.Trial,
    daysValid: 999,
    modules: [],
    autoRenew: false,
    validUntil: null,
    nextPaymentDate: null,
    customerNumber: null,
    trialExists: false,
    isAcademicLicense: false
  };
}

export function emptyApplicationLicense(): ApplicationLicense {
  return {
    validUntil: new Date(),
    applicationType: ApplicationType.Unknown,
    package: PackageType.Trial,
    daysValid: 30,
    modules: [],
    trialExists: false,
  };
}

/**
 * Empty license state
 */
export function emptyLicenseState(): LicensesState {
  return {
    currentLicense: null,
    allLicenses: [],
    userCount: null,
    licensesResponseStatus: HttpStatusCode.Ok
  };
}

/**
 * License status in case of failure.
 * Agreed with analysts...
 */
export function errorLicenseState(): LicensesState {
  const modules = Object.keys(InvoicingModuleType)
    .filter(key => typeof InvoicingModuleType[key] === 'number')
    .map(key => +InvoicingModuleType[key])
    .concat(Object.keys(WarehouseModuleType)
      .filter(key => typeof WarehouseModuleType[key] === 'number')
      .map(key => +WarehouseModuleType[key]));
  const license: License = {
    applicationType: ApplicationType.Unknown,
    type: PackageType.Flexi,
    daysValid: 999,
    modules,
    validUntil: null,
    autoRenew: false,
    nextPaymentDate: null,
    customerNumber: null,
    trialExists: false,
    isAcademicLicense: false
  };

  return {
    currentLicense: {...license, usedCompanyIds: [], problemCode: LicenseProblem.None},
    allLicenses: [license],
    userCount: null,
    licensesResponseStatus: null
  };
}
