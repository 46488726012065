import { Inject, Injectable, Optional } from '@angular/core';

import { catchError } from 'rxjs/operators';
import { TranslateService as CoreTranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';

import { RegistrationNotificationService, UserService } from '@kros-sk/ssw-cdk';
import { APP_CONFIG } from '@kros-sk/app-config';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  langChanged = new Subject<LangChangeEvent>();

  constructor(
    private translateService: CoreTranslateService,
    notificationService: RegistrationNotificationService,
    @Optional() private userService: UserService,
    @Inject(APP_CONFIG) private appConfig: any,
    @Inject('defaultLanguage') private defaultLanguage: ((appConfig: any) => string),
    @Inject('languageOptions') public languageOptions: any
  ) {
    this.translateService.addLangs(this.languageOptions.map(_ => _.id));

    this.translateService.onLangChange
      .subscribe(e => this.langChanged.next(e));

    this.translateService.setDefaultLang(this.defaultLanguage(this.appConfig));
    if (!this.userService) {
      this.translateService.use(this.defaultLanguage(this.appConfig));
    }

    notificationService.userRegistrationCompleted$
      .subscribe(p => {
        if (p.registrationCompleted) {
          this.setSavedOrDefault();
        }
      });
  }

  get currentLang(): string {
    return this.translateService.currentLang;
  }

  get translationsForCurrentLang(): any {
    return this.translateService.translations[this.currentLang];
  }

  get uiCulture(): string {
    let culture = 'sk-SK';
    switch (this.translateService.currentLang) {
      case 'sk':
        culture = 'sk-SK';
        break;
      case 'cz':
        culture = 'cs-CZ';
        break;
      case 'en':
        culture = 'en-GB';
        break;
    }
    return culture;
  }

  use(lang): Observable<any> {
    if (lang) {
      this.userService?.setUserLanguage(lang);
    }
    return this.translateService.use(lang);
  }

  private setSavedOrDefault(): void {
    this.userService?.getUserLanguage()
      .pipe(
        catchError(this.onError.bind(this))
      )
      .subscribe(lang => {
        this.translateService.use(lang ? lang : this.defaultLanguage(this.appConfig));
      });
  }

  private onError(e: any): void {
    this.translateService.use(
      this.translateService.currentLang ? this.translateService.currentLang : this.defaultLanguage(this.appConfig)
    );
  }

  /**
   * Use Core translation service to translate words
   *
   * @param word string to translate
   */
  translate(word: string): string {
    return this.translateService.instant(word);
  }

  translateWithParam(word: string, value: string): string {
    return this.translateService.instant(word).replace('{{value}}', value);
  }
}
