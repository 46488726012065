import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { createHttpRetryErrorForLog, httpRetryAndLog, HttpRetryResult, KrosAppInsightsService } from '@kros-sk/core';
import { UserFeatureFlags } from '@kros-sk/models';

import * as FeatureFlagsConstants from './feature-flags-constants';
import { HandleMethodErrors } from '../../../shared/decorators/handle-errors/handle-errors-decorator';
import {
  RequestsWithErrorHandling
} from '../../../shared/request-with-error-handling/requests-with-error-handling.service';

@Injectable()
export class FeatureFlagsService extends RequestsWithErrorHandling {
  private appConfig = inject(APP_CONFIG);

  private readonly urlPostfix = '/FeatureFlags';

  constructor(
    http: HttpClient,
    private appInsightsService: KrosAppInsightsService,
  ) {
    super(http);
  }

  @HandleMethodErrors({ status: ['401', '504'] })
  loadFeaturesState(): Observable<UserFeatureFlags> {
    return this.getFeatureFlagsFromServer();
  }

  private getFeatureFlagsFromServer(): Observable<UserFeatureFlags> {
    const serverUrl = `${this.appConfig.gatewayApiUrl}${this.urlPostfix}`;

    return this.httpRequest<any[]>('get', serverUrl).pipe(
      httpRetryAndLog({
        retryOnStatus: /504/,
        retryDelays: [300, 600],
        loggerFn: this.logHttpRetryException('getFeatureFlagsFromServer', 'GET')
      }),
      map((response: any) => response.values ?? response),
      map(flags => {
        const userFeatureFlag = new UserFeatureFlags();
        Object.entries(flags).forEach(flag => {
          this.setUserFeatures(userFeatureFlag, flag);
        });
        return userFeatureFlag;
      })
    );
  }

  private logHttpRetryException = (apiMethod: string, httpRequestMethod: string) => {
    return (log: HttpRetryResult): void => {
      const handler = 'FeatureFlagsService';
      this.appInsightsService.trackException(createHttpRetryErrorForLog(handler, apiMethod, httpRequestMethod), handler, log);
    };
  };

  private setUserFeatures(userFeatureFlag: UserFeatureFlags, [flagName, flagValue]): UserFeatureFlags {
    const features = userFeatureFlag;

    if (flagValue) {
      if (flagName === FeatureFlagsConstants.IKROS_TEAM) {
        features.iKrosTeam = true;
      }

      if (flagName === FeatureFlagsConstants.PARTNERS) {
        features.partners = true;
      }

      if (flagName === FeatureFlagsConstants.LICENSES_UNLIMITED) {
        features.licensesUnlimited = true;
      }

      if (flagName === FeatureFlagsConstants.IGNORE_MAINTENANCE) {
        features.ignoreMaintenance = true;
      }

      if (flagName === FeatureFlagsConstants.SPREADSHEET_FUNCTIONS) {
        features.spreadsheetFunctions = true;
      }

      if (flagName === FeatureFlagsConstants.WEBASSEMBLY) {
        features.webassembly = true;
      }

      if (flagName === FeatureFlagsConstants.USE_WEBSOCKET_RECALC) {
        features.useWebsocketRecalc = true;
      }

      if (flagName === FeatureFlagsConstants.HIDE_BANK_ACCOUNTS) {
        features.hideBankAccounts = true;
      }

      if (flagName === FeatureFlagsConstants.API_SETTINGS) {
        features.apiSettings = true;
      }

      if (flagName === FeatureFlagsConstants.IS_AUTO_COMPLETED_ITEMS_ENABLED) {
        features.isAutoCompletedItemsEnabled = true;
      }

      if (flagName === FeatureFlagsConstants.PDF_ROTATE_WITHOUT_MEASUREMENTS) {
        features.pdfRotateWithoutMeasurements = true;
      }

      if (flagName === FeatureFlagsConstants.BIM_CCI) {
        features.bimCci = true;
      }

      if (flagName === FeatureFlagsConstants.BIM_DATA_EXTRACTION) {
        features.bimDataExtraction = true;
      }

      if (flagName === FeatureFlagsConstants.BIM_COMPARE) {
        features.bimCompare = true;
      }

      if (flagName === FeatureFlagsConstants.BIM_VIEW_NEW) {
        features.bimViewNew = true;
      }

      if (flagName === FeatureFlagsConstants.BUDGET_GROUPING) {
        features.budgetGrouping = true;
      }

      if (flagName === FeatureFlagsConstants.BUDGET_TEST) {
        features.budgetTest = true;
      }

      if (flagName === FeatureFlagsConstants.SSW_TEST) {
        features.sswTest = true;
      }

      if (flagName === FeatureFlagsConstants.MY_DOCUMENTS_FETCH_RECEIPTS) {
        features.myDocumentsFetchReceipts = true;
      }

      if (flagName === FeatureFlagsConstants.INVOICING_GRID) {
        features.invoicingGrid = true;
      }

      if (flagName === FeatureFlagsConstants.INVOICING_GRID_SUMMARY) {
        features.invoicingGridSummary = true;
      }

      if (flagName === FeatureFlagsConstants.OPEN_APP_IN_SAME_TAB) {
        features.openAppInSameTab = true;
      }

      if (flagName === FeatureFlagsConstants.BUDGET_MINI) {
        features.budgetMini = true;
      }

      if (flagName === FeatureFlagsConstants.BUDGET_PROFI) {
        features.budgetProfi = true;
      }

      if (flagName === FeatureFlagsConstants.ARCHIVE_BULK_DOWNLOAD) {
        features.archiveBulkDownload = true;
      }

      if (flagName === FeatureFlagsConstants.INVOICING_GRID_STATE_FUNCTIONS) {
        features.invoicingGridStateFunctions = true;
      }

      if (flagName === FeatureFlagsConstants.LEFT_NAVIGATION_PANEL) {
        features.leftNavigationPanel = true;
      }

      if (flagName === FeatureFlagsConstants.INVOICING_EXPENSES) {
        features.invoicingExpenses = true;
      }

      if (flagName === FeatureFlagsConstants.DECIMAL_VAT_RATE) {
        features.decimalVatRate = true;
      }

      if (flagName === FeatureFlagsConstants.INVOICING_NOTIFICATION_CENTRE) {
        features.invoicingNotificationCentre = true;
      }

      if (flagName === FeatureFlagsConstants.PLATFORM_MAINTENANCE) {
        features.platformMaintenance = true;
      }

      if (flagName === FeatureFlagsConstants.PROFORMA_INVOICES_GRID) {
        features.proformaInvoicesGrid = true;
      }

      if (flagName === FeatureFlagsConstants.MY_DOCUMENTS_ROUNDING) {
        features.myDocumentsRounding = true;
      }

      if (flagName === FeatureFlagsConstants.LUCKYSHEET_FILTER) {
        features.luckysheetFilter = true;
      }

      if (flagName === FeatureFlagsConstants.PRICE_LIST_SEARCH) {
        features.priceListSearch = true;
      }

      if (flagName === FeatureFlagsConstants.PRICE_LIST_MO) {
        features.priceListMO = true;
      }
    }

    return features;
  }
}

export function featureFlags(): UserFeatureFlags {
  return new UserFeatureFlags();
}
