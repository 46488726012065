import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AnalyticsService, TranslateService } from '@kros-sk/ssw-shared-legacy';
import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { KrosModalRef, KrosModalService } from '@kros-sk/components';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import {
  BuildingProgressConfirmationProtocolExportModel
} from '../../../models/building-progress-export.model';
import { BuildingProgressPeriod } from '../../../models/building-progress-period.model';
import { getAppInsightsPrefix } from '../../../shared/building-progress-utils';
import { PermissionHelper } from '../../../helpers';
import { RangeModalComponent } from '../../../shared/range-modal/range-modal.component';

@Component({
  selector: 'app-building-progress-export-confirmation-protocol',
  templateUrl: './building-progress-export-confirmation-protocol.component.html',
  styleUrls: ['./building-progress-export-confirmation-protocol.component.scss']
})
export class BuildingProgressExportConfirmationProtocolComponent extends UnsubscribeComponent implements OnInit {
  exportModel: BuildingProgressConfirmationProtocolExportModel;
  periods: BuildingProgressPeriod[];
  exportForm: UntypedFormGroup;

  private rangeSelectedItems: Set<number> = new Set<number>();
  private rangeIndeterminateItems: Set<number> = new Set<number>();

  get isContractor(): boolean {
    return this.permissionHelper.isContractor;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private krosModalService: KrosModalService,
    private modalRef: KrosModalRef,
    private translateService: TranslateService,
    private appInsightsService: AppInsightsBaseService,
    protected analyticsService: AnalyticsService,
    private permissionHelper: PermissionHelper
  ) {
    super();

    this.exportModel = this.modalRef.data.exportModel;
    this.periods = this.modalRef.data.periods.map(p => (
      {
        id: p.id,
        dateFrom: p.dateFrom,
        dateTo: p.dateTo,
        isMarkedInFilter: false,
        isApprovalRequested: p.isApprovalRequested,
        isApproved: p.isApproved
      } as BuildingProgressPeriod));
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  get arePeriodsEmpty(): boolean {
    return this.periods?.length === 0;
  }

  get rangeLabel(): string {
    const buildingObjectIdsLength = this.exportForm.get('buildingObjectIds').value.length;
    return buildingObjectIdsLength === 0 ? this.translateService.translate('BUILDING_PROGRESS.WHOLE_BUILDING') :
      `${this.translateService.translate('BUILDING_PROGRESS.SELECTED_OBJECTS')} (${buildingObjectIdsLength})`;
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  openRangeModal(): void {
    const modalRef = this.krosModalService.openCentered(
      RangeModalComponent,
      {
        selectedItems: this.rangeSelectedItems,
        indeterminateItems: this.rangeIndeterminateItems
      },
      {
        addModalToBrowsersHistory: false,
        closeOnEscClick: true
      }
    );

    this.subs.sink = modalRef.afterClosed$
      .subscribe(result => {
        if (result.type === 'submit') {
          this.rangeSelectedItems = result.data.selectedItems;
          this.rangeIndeterminateItems = result.data.indeterminateItems;
          const selectedBuildingObjectIds = [];
          this.rangeSelectedItems.forEach(id => {
            if (!this.rangeIndeterminateItems.has(id)) {
              selectedBuildingObjectIds.push(id);
            }
          });
          this.exportForm.get('buildingObjectIds').setValue(selectedBuildingObjectIds);
        }
      });
  }

  onSubmit(): void {
    this.copyExportModelFromFormControl();
    this.sendAnalyticsNotification();
    this.modalRef.submit(this.exportModel);
  }

  private initializeForm(): void {
    this.exportForm = this.formBuilder.group({
      buildingObjectIds: [[]],
      periodId: [this.exportModel.periodId, [Validators.required]]
    });
  }

  private copyExportModelFromFormControl(): void {
    const formControls = this.exportForm.controls;
    this.exportModel.periodId = formControls.periodId.value;
    this.exportModel.buildingObjectIds = formControls.buildingObjectIds.value;
  }

  private sendAnalyticsNotification(): void {
    this.appInsightsService.trackEvent(`${getAppInsightsPrefix(this.isContractor)}export-confirmation-protocol`, {
      periodId: this.exportForm.controls.periodId.value
    });
  }
}
