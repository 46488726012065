<div
  class="kros-modal-body"
  [class.max-width]="!data.noMaxWidth"
  data-test="modal-body"
>
  <div class="lines">
    <p
      [innerHtml]="data.body"
      data-test="modal-content"
    ></p>
    <span
      *ngIf="data.warning"
      class="warning"
    >
      <p
        [innerHtml]="data.warning"
        data-test="modal-content-warning"
      ></p>
    </span>
  </div>

  <div class="confirm-buttons">
    <button
      cdkFocusInitial
      class="btn btn-submit"
      [ngClass]="data.warning ? 'btn-danger' : 'btn-primary'"
      type="button"
      (click)="onYesClick()"
      data-test="delete"
    >{{
      data.confirmButton ? data.confirmButton : ('COMMON.CONTINUE' | translate) }}</button>

    <button
      class="btn btn-outline-secondary"
      type="button"
      data-test="cancel"
      (click)="onNoClick()"
    >{{ data.cancelButton ? data.cancelButton : ('COMMON.ZRUSIT' | translate ) }}</button>
  </div>
</div>
