import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-document-list-container',
  templateUrl: './document-list-container.component.html',
  styleUrls: ['./document-list-container.component.scss']
})
export class DocumentListContainerComponent {
  @Input() documentId: string;

  constructor() { }
}
