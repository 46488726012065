/* eslint-disable max-lines */
import { Injectable, inject } from '@angular/core';

import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, concatMap, debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';

import { ApplicationType, UserService } from '@kros-sk/ssw-cdk';
import {
  AsyncOperationState,
  ToastService,
  TranslateService,
  undoRedoActions,
  UndoRedoDispatchersService
} from '@kros-sk/ssw-shared-legacy';

import * as uploadBuidlingObjectsActions from '@kros-sk/ssw-budget/building-objects';
import * as buildingProgressActions from './building-progress.actions';
import * as buildingProgressBoqActions from './building-progress-boq.actions';
import {
  BoqService,
  BuildingProgressDeleteItemsService,
  BuildingProgressService,
  BuildingProgressSharingService,
  DetailsService
} from '../../building-progress/services';
import { BuildingProgressBoqEffectsService } from './building-progress-boq.effects.service';
import { BuildingProgressEffectsService } from './building-progress.effects.service';
import { BuildingProgressSelectorsService } from './building-progress-selectors.service';
import { createEmptyBoqRecords } from '../../building-progress/helpers/boq.helper';
import { getGroupedEditModels } from './building-progress-reducer.helper';
import { NoteDeleteModel } from '../../building-progress/models/note.model';
import { NotesService } from '../../building-progress/services/notes.service';

@Injectable()
export class BuildingProgressEffects {

  private toastService = inject(ToastService);
  private translateService = inject(TranslateService);

  getConstructionData$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.loadConstructionDataStart),
      switchMap(payload => this.buildingProgressService.getConstructionData(
        payload.projectId,
        payload.searchModel,
        payload.hierarchyCode,
        payload.contractorId)
        .pipe(
          switchMap(constructionData => of(buildingProgressActions.loadHasDocumentsStart({ projectId: payload.projectId }),
            buildingProgressActions.loadConstructionDataSuccess({
              constructionData: constructionData
                ? constructionData
                : this.effectsService.createEmptyConstructionData(payload.projectId),
              contractorId: payload.contractorId
            })
          )),
          catchError(error => of(buildingProgressActions.loadConstructionDataError({
            error,
            constructionData: error.status === 403
              ? this.effectsService.createEmptyConstructionData(payload.projectId) : undefined
          })))
        ))
    );
  });

  getSelectedConstructionData$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.loadSelectedConstructionDataStart),
      switchMap(
        payload => this.buildingProgressService.getConstructionData(
          payload.projectId,
          payload.searchModel,
          undefined,
          payload.contractorId
        ).pipe(
          switchMap(constructionData => of(buildingProgressActions.loadHasDocumentsStart({ projectId: payload.projectId }),
            buildingProgressActions.loadSelectedConstructionDataSuccess({
              constructionData: constructionData
                ? constructionData
                : this.effectsService.createEmptyConstructionData(payload.projectId),
              contractorId: payload.contractorId
            })
          )
          ),
          catchError(error => of(buildingProgressActions.loadSelectedConstructionDataError({
            error,
            constructionData: error.status === 403
              ? this.effectsService.createEmptyConstructionData(payload.projectId) : undefined
          })))
        ))
    );
  });

  editConstructionData$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.editConstructionDataStart),
      tap(payload => this.actualEditPayloads.push(payload)),
      debounceTime(1000),
      concatMap(() => {
        const editPayloads = [...this.actualEditPayloads];
        this.actualEditPayloads = [];

        return from(getGroupedEditModels(editPayloads.map(p => p.editModel)))
          .pipe(concatMap(groupedEditModel =>
            this.buildingProgressService.editConstructionData(groupedEditModel).pipe(
              map(result => this.effectsService.editConstructionData(editPayloads, groupedEditModel, result)),
              catchError(error => of(buildingProgressActions.editConstructionDataError({ error })))
            )));
      }));
  });

  redoEditConstructionData$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.editConstructionDataRedo),
      concatMap((payload) =>
        this.buildingProgressService.editConstructionData(payload.editModel).pipe(
          map(result => {
            this.undoRedoDispatchers.redoSuccess();
            return buildingProgressActions.editConstructionDataSuccess(
              { editModel: payload.editModel, editedItems: result.tableItems, isUndoRedoOperation: true });
          }),
          catchError(error => of(buildingProgressActions.editConstructionDataError({ error })))
        )
      ));
  });

  setConstructionData$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.setConstructionDataStart),
      concatMap(payload => this.buildingProgressService.setConstructionData(payload.editModel).pipe(
        map((editedItems) => {
          this.undoRedoDispatchers.undoSuccess();
          return buildingProgressActions.setConstructionDataSuccess(
            {
              editModel: payload.editModel,
              editedItems: editedItems.tableItems
            });
        }),
        catchError(error => of(buildingProgressActions.setConstructionDataError({ error })))
      ))
    );
  });

  editPercentage$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.editPercentageStart),
      concatMap(payload => this.buildingProgressService.editPercentage(payload.editModel).pipe(
        map(result => this.effectsService.editPercentage(payload, result)),
        catchError(error => of(buildingProgressActions.editPercentageError({ error })))
      ))
    );
  });

  getPermission$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.loadPermissionStart),
      switchMap(payload => this.buildingProgressService.getPermissionType(payload.projectId).pipe(
        map(permission => buildingProgressActions.loadPermissionSuccess(
          { permission: { ...permission, projectId: payload.projectId } })),
        catchError(error => of(buildingProgressActions.loadPermissionError({ error })))
      ))
    );
  });

  getSharingList$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.loadSharingListStart),
      switchMap(payload => this.buildingProgressSharingService.getSharingList(payload.projectId).pipe(
        map(sharingList => buildingProgressActions.loadSharingListSuccess({ sharingList })),
        catchError(error => of(buildingProgressActions.loadSharingListError({ error })))
      ))
    );
  });

  setAutocalculatedAmount$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.setAutocalculatedAmountStart),
      concatMap(payload => this.buildingProgressService.setAutocalculatedAmount(payload.autocalculatedAmount).pipe(
        map(result => this.effectsService.setAutocalculatedAmount(payload, result)),
        catchError(error => of(buildingProgressActions.setAutocalculatedAmountError({ error })))
      ))
    );
  });

  percentageComplete$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.completePercentageStart),
      concatMap(payload => this.buildingProgressService.completePercentage(payload.percentageCompleteModel).pipe(
        map(result => this.effectsService.completePercentageSuccess(payload, result)),
        catchError(error => of(buildingProgressActions.completePercentageError({ error })))
      ))
    );
  });

  undo$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(undoRedoActions.undo),
      map(action => {
        this.effectsService.dispatchUndoAction(action);
        return undoRedoActions.undoInProgress();
      })
    );
  });

  redo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(undoRedoActions.redo),
      map(action => {
        this.effectsService.dispatchRedoAction(action);
        return undoRedoActions.redoInProgress();
      })
    );
  });

  loadBoqPeriodItems$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressBoqActions.loadBoqPeriodItemsStart),
      switchMap(payload => this.boqService.getBoqPeriodItems(payload.projectId, payload.budgetItemId, payload.contractorId).pipe(
        map(resp => {
          const items = resp.length ? resp : createEmptyBoqRecords(payload.budgetItemId);
          return buildingProgressBoqActions.loadBoqPeriodItemsSuccess({ items });
        })
      ))
    );
  });

  loadBoqItems$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressBoqActions.loadBoqItemsStart),
      switchMap(payload => this.boqService.getBoqItems(payload.projectId, payload.budgetItemId, payload.contractorId).pipe(
        map(resp => buildingProgressBoqActions.loadBoqItemsSuccess({ items: resp }))
      ))
    );
  });

  setBoq$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressBoqActions.setBoqStart),
      concatLatestFrom(() => this.buildingProgressSelectorsService.deletedBoqItemIds$),
      concatMap(([payload, deletedBoqItems]) => this.boqEffectsService.setBoq(payload, deletedBoqItems))
    );
  });

  totalPriceSet$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.totalPriceSetStart),
      concatMap(payload => this.buildingProgressService.totalPriceSet(payload.priceSetModel).pipe(
        map(result => this.effectsService.totalPriceSetSuccess(payload, result)),
        catchError(error => of(buildingProgressActions.totalPriceSetError({ error })))
      ))
    );
  });

  setColorCodes$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.setColorCodesStart),
      switchMap(payload => this.buildingProgressService.colorCodeSet(payload.setColorCodeModel).pipe(
        map(result => this.effectsService.setColorCodesSuccess(payload, payload.setColorCodeModel, result)),
        catchError(error => of(buildingProgressActions.setColorCodesError({ error })))
      ))
    );
  });

  deleteItems$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.deleteItemsStart),
      switchMap(payload => this.buildingProgressService.itemListDelete(payload.projectId, payload.deleteItemsModel).pipe(
        map(resp => buildingProgressActions.deleteItemsSuccess({ buildingProgressDeleteItem: resp })),
        catchError(error => {
          if (error.status === 409) {
            this.buildingProgressDeleteItemsService.openInfoDialog();
          }
          return of(buildingProgressActions.deleteItemsError({ error }));
        })
      ))
    );
  });

  loadSettings$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.loadSettingsStart),
      switchMap(payload => this.buildingProgressService.loadSettings(payload.projectId).pipe(
        map(settings => buildingProgressActions.loadSettingsSuccess({ settings })),
        catchError(error => of(buildingProgressActions.loadSettingsError({ error })))
      ))
    );
  });

  loadLicenseAgreement$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.loadLicenseAgreementStart),
      switchMap(() => this.userService.getLicenseAgreement(ApplicationType.BuildingProgress).pipe(
        map(licenseAgreement => buildingProgressActions.loadLicenseAgreementSuccess({ licenseAgreement })),
        catchError(error => of(buildingProgressActions.loadLicenseAgreementError({ error })))
      ))
    );
  });

  confirmLicenseAgreement$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.confirmLicenseAgreementStart),
      switchMap(() => this.userService.confirmLicenseAgreement(ApplicationType.BuildingProgress).pipe(
        map(() => buildingProgressActions.confirmLicenseAgreementSuccess()),
      ))
    );
  });

  openItemPeriodDetailsPanel$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.openPeriodDetailsPanel),
      map(payload => payload.period.detailId ?
        buildingProgressActions.loadItemPeriodDetailsStart({ detailId: payload.period.detailId }) :
        buildingProgressActions.loadItemPeriodDetailsSuccess({ details: this.detailsService.getEmptyDetails() })
      )
    );
  });

  loadItemPeriodDetails$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.loadItemPeriodDetailsStart),
      switchMap(payload => this.detailsService.getItemPeriodDetails(payload.detailId).pipe(
        map(details => buildingProgressActions.loadItemPeriodDetailsSuccess({ details })),
        catchError(error =>
          this.effectsService.handleDetailsErrorAndGetAction(error, buildingProgressActions.loadItemPeriodDetailsError))
      ))
    );
  });

  saveItemPeriodDetails$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.savePeriodDetailsStart),
      switchMap(payload => {
        let request = this.detailsService.createItemPeriodDetails({
          ...payload.details,
          periodId: payload.period.periodId,
          budgetItemId: payload.itemId,
          userId: this.userService.userId
        });

        if (!!payload.period.detailId) {
          request = !!payload.details.note ?
            this.detailsService.editItemPeriodDetails({
              ...payload.details, id: payload.period.detailId, userId: this.userService.userId
            }) :
            this.detailsService.deleteItemPeriodDetails(payload.period.detailId);
        }
        return request
          .pipe(
            map(newDetailId => buildingProgressActions.savePeriodDetailsSuccess({
              details: payload.details,
              newDetailId: !payload.period.detailId ?
                newDetailId :
                !payload.details.note ?
                  null :
                  payload.period.detailId
            })),
            catchError(error =>
              this.effectsService.handleDetailsErrorAndGetAction(error, buildingProgressActions.savePeriodDetailsError))
          );
      })
    );
  });

  loadTableExportSettings$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.loadTableExportSettingsStart),
      switchMap(payload => this.buildingProgressService.getTableExportSettings().pipe(
        map(settings => buildingProgressActions.loadTableExportSettingsSuccess({ tableExportSettings: settings })),
        catchError(error =>
          this.effectsService.handlePeriodErrorAndGetAction(error, buildingProgressActions.loadItemPeriodDetailsError))
      ))
    );
  });

  hasDocuments$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.loadHasDocumentsStart),
      switchMap(payload => this.buildingProgressService.hasBuildingProgressDocuments(payload.projectId)
        .pipe(
          map(hasDocuments => buildingProgressActions.loadHasDocumentsSuccess({ hasDocuments })),
          catchError(error => of(buildingProgressActions.loadHasDocumentsError({ error })))
        ))
    );
  });

  loadItemDrawSheetExportSettings$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.loadItemDrawSheetExportSettingsStart),
      switchMap(payload => this.buildingProgressService.loadExportItemDrawSheetSettings(payload.projectId).pipe(
        map(settings => buildingProgressActions.loadItemDrawSheetExportSettingsSuccess({ ...settings })),
        catchError(error =>
          this.effectsService.handlePeriodErrorAndGetAction(error, buildingProgressActions.loadItemDrawSheetExportSettingsError))
      ))
    );
  });

  loadRemainingBudgetExportSettings$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.loadRemainingBudgetExportSettingsStart),
      switchMap(payload => this.buildingProgressService.loadRemainingBudgetExportSettings(payload.projectId).pipe(
        map(settings => buildingProgressActions.loadRemainingBudgetExportSettingsSuccess({ ...settings })),
        catchError(error => of(buildingProgressActions.loadRemainingBudgetExportSettingsError({ error })))
      ))
    );
  });

  setItemsNotCompletedStart$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.setItemsNotCompletedStart),
      switchMap(payload =>
        this.buildingProgressService.setIsNotCompletedItemsState(payload.projectId, payload.budgetItemIds, payload.notCompleted).pipe(
          map(data => buildingProgressActions.setItemsNotCompletedSuccess({ editedItems: data.tableItems })),
          catchError(error => of(buildingProgressActions.setItemsNotCompletedError({ error })))
        )),
    );
  });

  setItemsNotCompletedSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.setItemsNotCompletedSuccess),
      map(_ => buildingProgressActions.clearMultiSelectItems())
    );
  });

  setItemsNotCompletedError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.setItemsNotCompletedError),
      tap(payload => this.effectsService.handleError(
        payload.error,
        undefined,
        { 403: 'BUILDING_PROGRESS.ERROR.CANNOT_SET_NOT_COMPLETED' }))
    );
  }, { dispatch: false });

  loadBudgetHasItems$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.loadBudgetHasItemsStart),
      switchMap(payload =>
        this.buildingProgressService.loadBudgetHasItems(payload.projectId).pipe(
          map(budgetHasItems => buildingProgressActions.loadBudgetHasItemsSuccess({
            projectId: payload.projectId,
            budgetHasItems
          })),
          catchError(error => of(buildingProgressActions.loadBudgetHasItemsError({ error })))
        )),
    );
  });

  loadBudgetHasItemsSuccess$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.loadBudgetHasItemsSuccess),
      filter(payload => payload.budgetHasItems),
      map(payload => buildingProgressActions.loadBudgetCreationActiveStateStart({ projectId: payload.projectId })),
    );
  });

  startBudgetCreation$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.startBudgetCreationStart),
      switchMap(payload =>
        this.buildingProgressService.startBudgetCreation(payload.projectId).pipe(
          map(() => buildingProgressActions.startBudgetCreationSuccess()),
          catchError(error => of(buildingProgressActions.startBudgetCreationError({ error })))
        )),
    );
  });

  loadBudgetCreationActiveState$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.loadBudgetCreationActiveStateStart),
      switchMap(payload =>
        this.buildingProgressService.loadBudgetCreationActiveState(payload.projectId).pipe(
          map(budgetCreationState => buildingProgressActions.loadBudgetCreationActiveStateSuccess(
            { isProcessing: budgetCreationState?.state <= AsyncOperationState.InProgress })),
          catchError(error => of(buildingProgressActions.startBudgetCreationError({ error })))
        )),
    );
  });

  loadItemNotes$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.loadNotesStart),
      switchMap(payload => this.notesService.getBudgetItemNotes(payload.projectId, payload.contractorId).pipe(
        map(notes => buildingProgressActions.loadNotesSuccess({ notes })),
        catchError(error =>
          this.effectsService.handleDetailsErrorAndGetAction(error, buildingProgressActions.loadItemPeriodDetailsError))
      ))
    );
  });

  saveBuildingProgressNote$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.saveBuildingProgressNoteStart),
      switchMap((payload) => (!!payload.note.note
        ? this.notesService.setNote(payload.projectId, payload.note)
        : this.notesService.deleteBuildingProgressNote(
          payload.projectId,
          { budgetItemId: payload.note.budgetItemId, applicationType: payload.note.applicationType } as NoteDeleteModel)
      ).pipe(
        map(note => buildingProgressActions.saveBuildingProgressNoteSuccess(
          {
            note: {
              budgetItemId: payload.note.budgetItemId,
              applicationType: payload.note.applicationType,
              projectId: payload.projectId,
              note: !!payload.note.note ? payload.note.note : null
            }
          })),
        catchError(error =>
          this.effectsService.handleDetailsErrorAndGetAction(error, buildingProgressActions.saveBuildingProgressNoteError))
      )
      )
    );
  });

  loadConstructionDataSuccess$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.loadConstructionDataSuccess, buildingProgressActions.loadSelectedConstructionDataSuccess),
      filter(payload => payload.constructionData.items.length > 0),
      map(payload => buildingProgressActions.loadNotesStart(
        { projectId: payload.constructionData.projectId, contractorId: payload.contractorId }))
    );
  });

  loadSettingsOnConstructionDataSuccess$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.loadConstructionDataSuccess),
      concatLatestFrom(() => this.buildingProgressSelectorsService.settings$),
      filter(([payload, settings]) => payload.constructionData.items.length > 0 && !settings),
      map(([payload]) => buildingProgressActions.loadSettingsStart({ projectId: payload.constructionData.projectId }))
    );
  });

  updateVatRate$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.updateVatRateStart),
      switchMap(payload => this.buildingProgressService.updateVatRate(payload.projectId).pipe(
        switchMap(() => [
          buildingProgressActions.loadSettingsStart({ projectId: payload.projectId }),
          buildingProgressActions.loadPeriodsStart({ projectId: payload.projectId, keepSelectedPeriods: false })
        ]),
        catchError(error => of(buildingProgressActions.updateVatRateError({ error })))
      ))
    );
  });

  uploadBuildingObjectsSuccess$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(uploadBuidlingObjectsActions.uploadBuildingObjectsSuccess),
      concatLatestFrom(() => this.buildingProgressSelectorsService.constructionData$),
      filter(([_, data]) => !!data?.projectId),
      map(([_, data]) => {
        this.toastService.open(this.translateService.translate('BUDGET.UPLOAD_TO_BUILDING_PROGRESS_SUCCESSFULL'));
        return buildingProgressActions.loadConstructionDataStart({
          projectId: data.projectId,
          searchModel: { searchMode: 'all' }
        });
      })
    );
  });

  private actualEditPayloads: any[] = [];

  constructor(
    private actions$: Actions,
    private boqService: BoqService,
    private buildingProgressService: BuildingProgressService,
    private buildingProgressDeleteItemsService: BuildingProgressDeleteItemsService,
    private buildingProgressSelectorsService: BuildingProgressSelectorsService,
    private buildingProgressSharingService: BuildingProgressSharingService,
    private detailsService: DetailsService,
    private effectsService: BuildingProgressEffectsService,
    private boqEffectsService: BuildingProgressBoqEffectsService,
    private userService: UserService,
    private undoRedoDispatchers: UndoRedoDispatchersService,
    private notesService: NotesService
  ) { }
}
