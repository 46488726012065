<kros-toasts
  aria-live="polite"
  aria-atomic="true"
></kros-toasts>

<kros-update-info
  *ngIf="featureFlagsLoaded"
  maintenanceUrl="maintenance"
  [showCountDownLimitInMinutes]="10"
  (showInfoAboutOldVersion)="showInfoAboutOldVersion()"
  [isVisible]="showUpdateInfo"
></kros-update-info>

<div
  class="language-version-div"
  [ngClass]="{'czech': isCzechVersion}"
>
  <ng-container *ngIf="{
      licenseInfo: currentLicenseInfo$ | async,
      licenseDetail: licenseDetail$ | async,
      budgetLicense: budgetLicense$ | async,
      desktopLicense: desktopLicense$ | async } as vm">
    <kros-license-info
      *ngIf="!isEmptyPage && !!vm.licenseInfo"
      [moreInfoUrl]="licenseService.moreInfoUrl"
      [orderUrl]="licenseService.orderUrl(vm.licenseInfo.license, vm.licenseDetail?.licenseNumber, vm.budgetLicense, vm.desktopLicense)"
      [isAutomaticRenewalLicense]="vm.licenseDetail?.AutomaticRenewalLicense"
      [licenseInfo]="vm.licenseInfo"
      [appPrefix]="licenseService.appPrefix"
    ></kros-license-info>
  </ng-container>

  <kros-info-message></kros-info-message>

  <kros-navbar
    *ngIf="!isEmptyPage"
    [appIdFilter]="['buildingprogress', 'vymery', 'budget']"
  >
    <ng-template #additionalMenuItems>
      <ng-container *ngIf="isBuildingProgress">
        <kros-menu-item
          class="main-menu-item tablet-hidden"
          [class.main-active-link]="isWholeBuilding"
          data-test="top-navbar-building-progress"
        >
          <a [routerLink]="[projectRoutePrefix, 'building-progress']">
            {{ 'BUILDING_PROGRESS.WHOLE_BUILDING' | translate }}
          </a>
        </kros-menu-item>

        <kros-menu-item
          *ngIf="!buildingProgressPermissionHelper.isContractor && !buildingProgressPermissionHelper.isApprover"
          class="main-menu-item tablet-hidden"
          routerLinkActive="main-active-link"
          data-test="top-navbar-subcontractors"
        >
          <a [routerLink]="[projectRoutePrefix, 'building-progress', 'subcontractors']">
            {{ 'PROJECTS.DETAIL.SUBCONTRACTORS' | translate }}
          </a>
        </kros-menu-item>
      </ng-container>

      <ng-container *ngIf="isQuantityManager">
        <qm-navigation
          *ngIf="ff$ | async as ff"
          [routePrefix]="routePrefix"
          [isNarrowNavbar]="false"
          [areNavigationsVisible]="true"
          [isAreaTabVisible]="isAreaTabVisible"
          [isBudgetTabVisible]="ff?.bimDataExtraction || ff?.bimCci"
        ></qm-navigation>
      </ng-container>
    </ng-template>

    <ng-template #additinalSecondaryItems>
      <ng-container>
        <div
          class="tablet-hidden secondary-nav-icon-item api-settings-button"
          krosTooltip="{{'COMMON.SETTINGS' | translate}}"
          [routerLink]="settingsRouterLink"
          data-test="nav-settings"
        >
          <i class="material-icons">settings</i>
        </div>
      </ng-container>
    </ng-template>
  </kros-navbar>

  <div class="main-content d-flex">
    <div class="container-fluid p-0">
      <div
        class="progress"
        *ngIf="isLoading"
      >
        <div class="progress-bar indeterminate">
        </div>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<kros-device-logger applicationName="Titan"></kros-device-logger>
