import { createAction, props } from '@ngrx/store';

import { BudgetItem } from '@kros-sk/ssw-shared-legacy';
import { BudgetObjectsUpload, BuildingObject } from '@kros-sk/ssw-budget/shared/data-access/models';

import { CreatedBuildingObject } from '../models';

export const budgetKey = '[BuildingObjects]';

export const selectBuildingObject = createAction(`${budgetKey} Select building object`, props<{ buildingObject: BuildingObject }>());

export const createEmptyBuildingObject = createAction(
  `${budgetKey} Create empty building object`,
  props<{ item: CreatedBuildingObject }>());
export const deleteEmptyBuildingObject = createAction(`${budgetKey} Delete empty building object`);
export const editEmptyBuildingObject = createAction(
  `${budgetKey} Edit empty building object`,
  props<{ propertyName: string, value: any }>());

export const selectBuildingObjectAfterCreate = createAction(`${budgetKey} Select building object after create`);

export const moveBuildingObjectLevelUp = createAction(`${budgetKey} Move building object level up`);
export const moveBuildingObjectLevelDown = createAction(
  `${budgetKey} Move building object level down`,
  props<{ itemBefore: BuildingObject, buildingObjects: BuildingObject[] }>());

export const uploadBuildingObjectsStart = createAction(
  `${budgetKey} Upload building objects start`,
  props<{ buildingObjectIds: string[] }>());
export const uploadBuildingObjectsSuccess = createAction(
  `${budgetKey} Upload building objects success`,
  props<{ buildingObjectsUploadDates: BudgetObjectsUpload }>());
export const uploadBuildingObjectsError = createAction(
  `${budgetKey} Upload building objects error`,
  props<{ error: any }>());

export const loadBuildingObjectsStateStart = createAction(`${budgetKey} Load building objects state start`);
export const loadBuildingObjectsStateSuccess = createAction(
  `${budgetKey} Load building objects state success`,
  props<{ buildingObjects: BuildingObject[] }>());
export const loadBuildingObjectsStateError = createAction(
  `${budgetKey} Load building objects state error`,
  props<{ error: any }>());

export const unsetAreBuildingObjectsFullyLoaded = createAction(`${budgetKey} Unset are building objects fully loaded`);

export const patchBuildingObjectsItemTypeStart = createAction(
  `${budgetKey} Patch building objects item type.`,
  props<{ item: BudgetItem, buildingObjects: BuildingObject[], isUndoRedoOperation:boolean, isUndo:boolean}>()
);
