import { Inject, Injectable, Optional } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter, map, Observable, ReplaySubject, Subject } from 'rxjs';

import { APPLICATION_MODULE_ID } from './application-module.token';
import { ApplicationModule } from './application-module.enum';

@Injectable({ providedIn: 'root' })
export class ApplicationModuleIdProviderService {

  applicationModuleId$: Observable<ApplicationModule>;

  private appModuleId: Subject<ApplicationModule> = new ReplaySubject(1);

  constructor(
    private router: Router,
    @Optional() @Inject(APPLICATION_MODULE_ID) applicationModuleId: ApplicationModule
  ) {
    this.applicationModuleId$ = this.appModuleId;

    if (applicationModuleId) {
      this.appModuleId.next(applicationModuleId);
    } else {
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.extractModuleNameFromUrl()),
      ).subscribe((moduleId: ApplicationModule) => {
        this.appModuleId.next(moduleId);
      });
    }
  }

  private extractModuleNameFromUrl(): ApplicationModule {
    const currentUrl = this.router.routerState.snapshot.url;

    if (currentUrl.endsWith('projects')) {
      return ApplicationModule.Projects;

    } else if (/project-detail\/\d+\/dashboard/.test(currentUrl)) {
      return ApplicationModule.ProjectDashboard;

    } else if (/project-detail\/\d+\/documents/.test(currentUrl)) {
      return ApplicationModule.Documents;

    } else if (/project-detail\/\d+\/quantity-manager/.test(currentUrl)) {
      return ApplicationModule.QuantityManager;

    } else if (/project-detail\/\d+\/budget-approval/.test(currentUrl)) {
      return ApplicationModule.BudgetApproval;

    } else if (/project-detail\/\d+\/budget/.test(currentUrl) || /project\/\d+\/budget/.test(currentUrl)) {
      return ApplicationModule.Budget;

    } else if (/project-detail\/\d+\/building-progress/.test(currentUrl)) {
      return ApplicationModule.BuildingProgress;

    } else if (/project-detail\/\d+\/members/.test(currentUrl)) {
      return ApplicationModule.ProjectSharing;

    } else if (/projects\/\d+\/photo-gallery/.test(currentUrl)) {
      return ApplicationModule.BuildingProgress;

    } else if (/projects\/\d+\/construction/.test(currentUrl)) {
      return ApplicationModule.DocumentViewer;
    }

    return ApplicationModule.None;
  }
}
