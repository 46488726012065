import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { filter } from 'rxjs';

import { DecimalPlaces, GridComponent } from '@kros-sk/ssw-shared-legacy';

import { BudgetApprovalPartialItemsTableService } from './budget-approval-partial-items-table.service';
import { BuildingProgressItem } from '../../../building-progress/models/construction-data.model';
import {
  BuildingProgressPartialItemsDispatchersService,
  BuildingProgressPartialItemsSelectorsService
} from '../../../store/building-progress';
import {
  PartialBudgetItemChangedProperty,
  PartialBudgetTableItem
} from '../../../building-progress/models/building-progress-partial-budget-items.model';

@Component({
  selector: 'ssw-building-progress-partial-item-table',
  templateUrl: './building-progress-partial-item-table.component.html',
  styleUrls: ['./building-progress-partial-item-table.component.scss'],
  providers: [BudgetApprovalPartialItemsTableService]
})
export class BuildingProgressPartialItemTableComponent extends GridComponent<number, PartialBudgetTableItem>
  implements OnInit {

  @ViewChild('dataDataGridRef', { static: false }) dataDataGrid: DxDataGridComponent;

  @Input() buildingProgressItem: BuildingProgressItem;

  partialItemsData$ = this.partialItemsSelectorsService.partialItemsData$;
  decimalPlaces: DecimalPlaces;
  formatPatternAmount: string;
  formatPatternPercentage: string;

  constructor(
    private partialItemsSelectorsService: BuildingProgressPartialItemsSelectorsService,
    private partialItemsDispatchersService: BuildingProgressPartialItemsDispatchersService,
    private tableService: BudgetApprovalPartialItemsTableService,
  ) {
    super();
    this.dataSource = [];
  }

  ngOnInit(): void {
    this.tableService.buildingProgressItemData = this.buildingProgressItem;
    this.partialItemsSelectorsService.partialItemsData$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(partialItemsData => {
        const newData = this.tableService.transformStoreItemsToTableItems(partialItemsData.items);
        if (this.dataSource.length === newData.length) {
          setTimeout(() => {
            this.dataSource.forEach((_, index) => {
              Object.assign(this.dataSource[index], newData[index]);
            });
          }, 0);
        } else {
          this.dataSource = this.tableService.transformStoreItemsToTableItems(partialItemsData.items);
        }
      });
    this.partialItemsSelectorsService.decimalPlaces$.pipe(filter(decimalPlaces => !!decimalPlaces), takeUntilDestroyed(this.destroyRef))
      .subscribe(decimalPlaces => {
        this.decimalPlaces = decimalPlaces;
        this.formatPatternAmount = this.getFormatPattern('amount');
        this.formatPatternPercentage = this.getFormatPattern('percentage');
      });
  }

  onSaving(e): void {
    const change = e.changes[0];

    if (change) {
      Object.entries(change.data).forEach(([key, value]) => {
        this.partialItemsDispatchersService.updatePartialItem(
          this.tableService.getMaxPrecision(value.toString(), this.getPrecision(key)),
          +change.key - 1,
          PartialBudgetItemChangedProperty[this.tableService.firstCaseUp(key)]);
        this.partialItemsDispatchersService.recalculatePartialItems();
      });
    }
  }

  saveEditData(): void {
    this.dataDataGrid.instance.saveEditData();
  }

  onEditingStart(e: any): void {
    e.cancel = e.key === 1;
  }

  onCellPrepared($event: any): void {
    if ($event.columnIndex > 2 && $event.rowIndex > 0) {
      $event.cellElement.classList.add('editable-cell-background');
    }
  }

  onEditorPreparing(e: any): void {
    if (e.editorName === 'dxNumberBox') {
      e.editorOptions.step = 0;
    }
  }

  onFocusedCellChanged($event: any): void {
    const focusOverlayElement: HTMLElement | null = document.querySelector('.dx-datagrid-focus-overlay');
    if (focusOverlayElement) {
      if ($event.columnIndex > 2 && $event.rowIndex > 0) {
        focusOverlayElement.style.display = 'block';
      } else {
        focusOverlayElement.style.display = 'none';
      }
    }
  }

  protected get dataComponent(): DxDataGridComponent {
    return this.dataDataGrid;
  }

  protected get isLastRowFocused(): boolean {
    throw new Error('Method not implemented.');
  }

  protected get selectedItemId(): number {
    throw new Error('Method not implemented.');
  }

  protected selectRow(itemId: number, columnIndex?: number): void {
    throw new Error('Method not implemented.');
  }

  protected dispatchActionSelectItem(item: any): void {
    throw new Error('Method not implemented.');
  }

  private getFormatPattern(field: string): string {
    return '#0.' + Array(this.getPrecision(field)).fill('0').join('');
  }

  private getPrecision(field: string): number {
    switch (field) {
      case 'amount':
        return this.decimalPlaces.amount;
      case 'percentage':
        return this.decimalPlaces.percentage;
    }
    return 2;
  }
}
