import { inject, Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, filter, map, Observable, of, switchMap } from 'rxjs';
import { concatLatestFrom } from '@ngrx/operators';

import { BudgetItem } from '@kros-sk/ssw-shared-legacy';
import { UserService } from '@kros-sk/ssw-cdk';

import { budgetSharedActions } from './budget-shared-actions';
import { BudgetSharedSelectorsService } from './budget-shared-selectors.service';
import { BudgetSharedService } from './budget-shared.service';

@Injectable()
export class BudgetSharedEffects {

  private actions$ = inject(Actions);
  private budgetService = inject(BudgetSharedService);
  private sharedSelectors = inject(BudgetSharedSelectorsService);
  private userService = inject(UserService);

  editProject$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(budgetSharedActions.editProjectDetailsStart),
      switchMap(payload => this.budgetService.editProject(payload.project).pipe(
        map(() => budgetSharedActions.editProjectDetailsSuccess({ project: payload.project })),
        catchError(error => of(budgetSharedActions.editProjectDetailsError({ error })))
      ))
    );
  });

  getBudgetSettings$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(budgetSharedActions.getBudgetSettingsStart),
      concatLatestFrom(() => this.sharedSelectors.projectId$),
      switchMap(([_, projectId]) => this.budgetService.getBudgetSettings(projectId).pipe(
        map(settings => budgetSharedActions.getBudgetSettingsSuccess({ settings })),
        catchError(error => of(budgetSharedActions.getBudgetSettingsError(error)))
      ))
    );
  });

  setBudgetUiSettings$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(budgetSharedActions.setBudgetUISettingsStart),
      concatLatestFrom(() => this.sharedSelectors.projectId$),
      switchMap(([payload, projectId]) =>
        this.budgetService.saveBudgetUiSettings(projectId, payload.settings, this.userService.userId, payload.settingsName).pipe(
          map(() => budgetSharedActions.setBudgetUISettingsSuccess({ settingsName: payload.settingsName, settings: payload.settings })),
          catchError(error => of(budgetSharedActions.setBudgetUISettingsError({ error })))
        ))
    );
  });

  getBudgetUiSettings$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(budgetSharedActions.getBudgetUISettingsStart),
      concatLatestFrom(payload => [this.sharedSelectors.projectId$, this.sharedSelectors.getUiSettings(payload.settingsName)]),
      filter(([payload, __, settings]) => !settings || payload.forceLoad),
      concatMap(([payload, projectId]) =>
        this.budgetService.getBudgetUiSettings(payload.projectId ?? projectId, this.userService.userId, payload.settingsName)
          .pipe(
            map(response => budgetSharedActions.getBudgetUISettingsSuccess({ settings: response, settingsName: payload.settingsName })),
            catchError(error => of(budgetSharedActions.getBudgetUISettingsError({ error, settingsName: payload.settingsName })))
          )
      )
    );
  });

  resolveBudget$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(budgetSharedActions.resolveBudget),
      switchMap(payload => this.budgetService.getBudgets(payload.projectId).pipe(
        map(budgets => {
          const mainBudget = budgets.find(b => b.isMain);
          return budgetSharedActions.setBudget({ budget: mainBudget });
        })
      ))
    );
  });
}

export const getGroupIds = (itemIds: string[], loadedParentIds: string[], items: BudgetItem[]): string[] => {
  return itemIds.length
    ? itemIds.filter(i => !loadedParentIds.includes(i))
    : items.filter(i => i.hasChildren && !loadedParentIds.includes(i.id)).map(i => i.id);
};
