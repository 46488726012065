import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { AutomaticAddress } from '@kros-sk/models';

import { SearchBaseService } from '../../companies-register-search';

@Injectable({
  providedIn: 'root'
})
export class ApiSearchService extends SearchBaseService<AutomaticAddress> {

  constructor(
    http: HttpClient,
    router: Router,
    @Inject(APP_CONFIG) appConfig: any
  ) {
    super(http, router, appConfig);
  }

  fetchAddressItems(
    searchText: string = '',
    searchFields: string = '',
    isStreet: boolean = false,
    searchIfEmptySearchText: boolean = false
  ): Observable<AutomaticAddress[]> {
    const filter = isStreet ? '$filter=IsStreet' : '$filter=not IsStreet';
    return this.fetchItems(0, filter , searchText, searchIfEmptySearchText, 'addresses', searchFields, true);
  }

  protected mapApiModelToItem(address: any): AutomaticAddress {
    return {
      street: address.StreetName,
      city: address.CityName,
      postCode: address.PostCode
    } as AutomaticAddress;
  }
}
