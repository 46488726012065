import { ChangeDetectionStrategy, Component, inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { locale } from 'devextreme/localization';
import { take } from 'rxjs';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { ApprovalHelper } from '@kros-sk/ssw-shared/utils/building-progress';
import { DynamicLocaleId, SetTypeService, TranslateModule, TranslateService } from '@kros-sk/ssw-shared-legacy';
import { KrosModalRef } from '@kros-sk/components';

import { ApprovalOrderDispatchersService } from '../../data-access';
import { ApprovalOrderTableComponent } from '../approval-order-table/approval-order-table.component';

@Component({
  selector: 'bp-approval-order-dialog',
  standalone: true,
  templateUrl: './approval-order-dialog.component.html',
  styleUrls: ['./approval-order-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    ApprovalOrderTableComponent
  ],
  providers: [
    SetTypeService,
    {
      provide: LOCALE_ID,
      useClass: DynamicLocaleId,
      deps: [TranslateService]
    }
  ]
})
export class ApprovalOrderDialogComponent implements OnInit {

  @ViewChild('approvalOrderTableRef', { static: false }) approvalOrderTable: ApprovalOrderTableComponent;

  canSave = false;

  protected projectId: number;
  protected isTableReadOnly: boolean;

  private appInsightsService = inject(AppInsightsBaseService);
  private approvalOrderDispatchersService = inject(ApprovalOrderDispatchersService);
  private approvalHelper = inject(ApprovalHelper);
  private modalRef = inject(KrosModalRef);

  ngOnInit(): void {
    this.projectId = this.modalRef.data.projectId;
    this.isTableReadOnly = this.modalRef.data.isTableReadOnly;
    this.initApprovalOrder();
    locale('sk-SK');
  }

  onOrderChanged(isChanged: boolean): void {
    this.canSave = isChanged;
  }

  onSave(): void {
    this.approvalHelper.showApprovalCancellationDialog().pipe(take(1)).subscribe(result => {
      if (result) {
        this.approvalOrderTable?.saveEditData();
        this.modalRef.submit();
        this.appInsightsService.trackEvent('PV-approval-order-save-button');
      }
    });
  }

  onClose(): void {
    this.modalRef.cancel();
  }

  private initApprovalOrder(): void {
    this.approvalOrderDispatchersService.clearApprovalOrder();
    this.approvalOrderDispatchersService.loadApprovalOrder(this.projectId);
  }
}
