export enum ApplicationModule {
  None = 0,
  Projects = 1,
  ProjectDashboard = 2,
  Documents = 3,
  Budget = 4,
  BudgetApproval = 5,
  BuildingProgress = 6,
  QuantityManager = 7,
  Misha = 8,
  Bimprix = 9,
  ProjectReport = 10,
  DocumentViewer = 11,
  ProjectSharing = 12,
}
