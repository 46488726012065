import { createAction, props } from '@ngrx/store';

import {
  DocumentVersionDto,
  FolderTreeNode,
  TemporaryUploadInfoModel,
  UploadFileInfoModel,
  UploadVersionInfoModel
} from '@kros-sk/ssw-shared-legacy';

export const clearState = createAction('[Documents] Clear documents state');

export const loadFolderHierarchyStart = createAction('[Documents] Load folder hierarchy start', props<{ projectId: number }>());
export const loadFolderHierarchySuccess = createAction(
  '[Documents] Load folder hierarchy success',
  props<{ folderHierarchy: FolderTreeNode[] }>()
);
export const loadFolderHierarchyError = createAction('[Documents] Load folder hierarchy error', props<{ error: any }>());

export const loadUploadingDataStart = createAction('[Documents] Load uploading data start', props<{ temporaryId: string }>());
export const loadUploadingDataSuccess = createAction(
  '[Documents] Load uploading data success',
  props<{ uploadingData: TemporaryUploadInfoModel }>()
);
export const loadUploadingDataError = createAction('[Documents] Load uploading data error', props<{ error: any }>());
export const clearUploadingData = createAction('[Documents] Clear uploading data');

export const uploadBuildingStart = createAction('[Documents] Upload building start',
  props<{ fileInfo: UploadFileInfoModel, accessKey: string }>());
export const uploadBuildingSuccess = createAction('[Documents] Upload building success', props<{ documentId: string }>());
export const uploadBuildingError = createAction('[Documents] Upload building error', props<{ error: any }>());

export const uploadBuildingVersionStart = createAction(
  '[Documents] Upload building version start',
  props<{ versionInfo: UploadVersionInfoModel, accessKey: string, projectId: number, folderId: string }>()
);
export const uploadBuildingVersionSuccess = createAction('[Documents] Upload building version success', props<{ documentId: string }>());
export const uploadBuildingVersionError = createAction('[Documents] Upload building version error', props<{ error: any }>());

export const loadDocumentVersionStart = createAction('[Documents] Load document version start', props<{ documentId: string }>());
export const loadDocumentVersionSuccess = createAction(
  '[Documents] Load document version success',
  props<{ documentVersionList: DocumentVersionDto[] }>()
);
export const loadDocumentVersionError = createAction('[Documents] Load document version error', props<{ error: any }>());

export const setDescriptionStart = createAction(
  '[Documents] Set document description start', props<{ documentId: string, documentVersionId: string, description: string }>());
export const setDescriptionSuccess = createAction(
  '[Documents] Set document description success', props<{ documentId: string, documentVersionId: string, description: string }>());
export const setDescriptionError = createAction('[Documents] Set document description error', props<{ error: any }>());
