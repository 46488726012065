import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

import { AutocompleteComponent } from './autocomplete.component';
import { AutocompleteContentDirective } from './autocomplete-content.directive';
import { AutocompleteDirective } from './autocomplete.directive';
import { KrosOptionComponent } from './option.component';

const DECLARATIONS = [
  AutocompleteComponent,
  AutocompleteDirective,
  AutocompleteContentDirective,
  KrosOptionComponent
];

@NgModule({
  declarations: [
    ...DECLARATIONS
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbDatepickerModule
  ],
  exports: [
    ...DECLARATIONS
  ]
})
export class AutocompleteModule { }
