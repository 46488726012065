<div class="kros-modal-header">
  <h5 class="modal-title">
    {{ 'BUILDING_PROGRESS.DRAW.BY_TOTAL_PRICE' | translate}}&nbsp;&nbsp;&nbsp;&nbsp;{{periodDateText}}
  </h5>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onCloseClick()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<form
  [formGroup]="totalPriceDrawForm"
  (ngSubmit)="onSubmit()"
>
  <div class="kros-modal-body">
    <div class="form-inline">
      <input
        class="form-control total-price-input text-right"
        krosAutoselect
        type="text"
        formControlName="totalPrice"
      >
      {{currency}}
    </div>
  </div>

  <div class="kros-modal-footer">
    <button
      class="btn btn-primary"
      type="submit"
      data-test="confirmation"
    >{{ 'BUILDING_PROGRESS.DRAW.SELECTED' | translate}}</button>
  </div>
</form>
