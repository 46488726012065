export const DECIMAL_VAT_RATE = 'DecimalVatRate';
export const IKROS_TEAM = 'iKrosTeam';
export const PARTNERS = 'Partners';
export const LICENSES_UNLIMITED = 'LicensesUnlimited';
export const IGNORE_MAINTENANCE = 'IgnoreMaintenance';
export const SPREADSHEET_FUNCTIONS = 'SpreadsheetFunctions';
export const WEBASSEMBLY = 'Webassembly';
export const USE_WEBSOCKET_RECALC = 'UseWebsocketRecalc';
export const HIDE_BANK_ACCOUNTS = 'HideBankAccounts';
export const API_SETTINGS = 'ApiSettings';
export const IS_AUTO_COMPLETED_ITEMS_ENABLED = 'IsAutoCompletedItemsEnabled';
export const PDF_ROTATE_WITHOUT_MEASUREMENTS = 'PdfRotateWithoutMeasurements';
export const BIM_CCI = 'BimCci';
export const BIM_DATA_EXTRACTION = 'BimDataExtraction';
export const BIM_COMPARE = 'BimCompare';
export const BIM_VIEW_NEW = 'BimViewNew';
export const SSW_TEST = 'SSWTest';
export const MY_DOCUMENTS_FETCH_RECEIPTS = 'MyDocumentsFetchReceipts';
export const BUDGET_GROUPING = 'BudgetGrouping';
export const BUDGET_TEST = 'BudgetTest';
export const INVOICING_GRID = 'InvoicingGrid';
export const INVOICING_GRID_SUMMARY = 'InvoicingGridSummary';
export const OPEN_APP_IN_SAME_TAB = 'OpenAppInSameTab';
export const BUDGET_MINI = 'BudgetMini';
export const BUDGET_PROFI = 'BudgetProfi';
export const ARCHIVE_BULK_DOWNLOAD = 'ArchiveBulkDownload';
export const LEFT_NAVIGATION_PANEL = 'LeftNavigationPanel';
export const INVOICING_GRID_STATE_FUNCTIONS = 'InvoicingGridStateFunctions';
export const INVOICING_EXPENSES = 'InvoicingExpenses';
export const INVOICING_NOTIFICATION_CENTRE = 'InvoicingNotificationCentre';
export const PLATFORM_MAINTENANCE = 'PlatformMaintenance';
export const PROFORMA_INVOICES_GRID = 'ProformaInvoicesGrid';
export const MY_DOCUMENTS_ROUNDING = 'MyDocumentsRounding';
export const LUCKYSHEET_FILTER = 'LuckysheetFilter';
export const PRICE_LIST_SEARCH = 'PriceListSearch';
export const PRICE_LIST_MO = 'PriceListMO';
