import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { ApplicationType, StorageService } from '@kros-sk/ssw-cdk';
import { BrowserTabService, NavbarService, StatisticsService } from '@kros-sk/ssw-shared-legacy';

import { BuildingProgressActionAccessService } from './services/building-progress-action-access.service';
import { BuildingProgressSelectorsService } from '../store/building-progress';
import { DetailHelper, PermissionHelper } from './helpers';
import { environment } from '../../environments/environment';

const buildProgPanelCollapsedStorageItem = 'buildProgPanelCollapsed';

@Component({
  selector: 'app-building-progress',
  templateUrl: './building-progress.component.html',
  styleUrls: ['./building-progress.component.scss']
})
export class BuildingProgressComponent implements OnInit {

  public detail = inject(DetailHelper);
  public permissionHelper = inject(PermissionHelper);
  public selectors = inject(BuildingProgressSelectorsService);
  private destroyRef = inject(DestroyRef);
  private navbarService = inject(NavbarService);
  private storageService = inject(StorageService);
  private statisticsService = inject(StatisticsService);
  private buildingProgressActionAccessService = inject(BuildingProgressActionAccessService);
  private browserTabService = inject(BrowserTabService);

  panelCollapsed = false;
  deleting = false;

  ngOnInit(): void {
    this.browserTabService.setTitle('BUILDING_PROGRESS.TITLE');
    this.navbarService.changeTitle('BUILDING_PROGRESS.TITLE');
    this.navbarService.setNewsData({
      app: 'Titan',
      isTest: environment.news.isTest.toString(),
      modul: 'PV'
    });

    this.buildingProgressActionAccessService.openMarketingDialogFreeOrToExpire(false, true);

    this.statisticsService.visit(ApplicationType.BuildingProgress).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
    this.selectors.isDeletingItems$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(p => this.deleting = p);
    this.panelCollapsed = JSON.parse(this.storageService.getItemFromLocalStorage(buildProgPanelCollapsedStorageItem));
  }

  onPanelCollapsed(collapsed: boolean): void {
    this.panelCollapsed = collapsed;
    this.storageService.setItemToLocalStorage(buildProgPanelCollapsedStorageItem, this.panelCollapsed.toString());
  }
}
