<div class="kros-modal-body">
  <div>{{ "BUILDING_PROGRESS.INVOICE.INSUFFICIENT_RIGHTS_INFO" | translate }} </div>

  <a
    href="https://www.kros.sk/fakturacia/cennik/"
    target="_blank"
  >{{ "BUILDING_PROGRESS.INVOICE.INVOICE_PACKAGE_MORE_INFO" | translate }}</a>

  <div class="order">{{ "BUILDING_PROGRESS.INVOICE.ORDER_INVOICE_PACKAGE_INFO" | translate }}</div>

  <a
    href="https://eshoptest.kros.sk/companies"
    target="_blank"
    (click)="onClose()"
  >{{ "BUILDING_PROGRESS.INVOICE.ORDER_INVOICE_PACKAGE" | translate }}</a>
</div>

<div class="kros-modal-footer">
  <button
    class="btn btn-primary btn-submit"
    data-test="invoice-license-close-button"
    type="button"
    (click)="onClose()"
  >{{ 'COMMON.CLOSE' | translate }}</button>
</div>
