import { Component, inject, Input, OnInit } from '@angular/core';

import { DeviceInfoService } from '../data-access/services/device-info.service';

@Component({
  selector: 'kros-device-logger',
  templateUrl: './device-logger.component.html'
})
export class DeviceLoggerComponent implements OnInit {

  @Input() applicationName!: string;

  private deviceInfoService = inject(DeviceInfoService);

  ngOnInit(): void {
    this.deviceInfoService.setApplicationName(this.applicationName);
  }
}
