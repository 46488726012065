import { createEntityAdapter } from '@ngrx/entity';

import { DocumentListInfoModel, DocumentVersionDto, FolderTreeNode } from '../models';
import {
  DeletedDocument,
  DeletedDocumentsState,
  DeletedDocumentsVersionState,
  DeletedDocumentVersion,
  DocumentListState,
  DocumentVersionState,
  FolderHierarchyState,
  MarkedDocumentsState
} from './documents-explorer.state';

export const adapterDocumentList = createEntityAdapter<DocumentListInfoModel>();
export const documentListInitialState: DocumentListState = adapterDocumentList.getInitialState({});

export const adapterMarkedDocuments = createEntityAdapter<string>({
  selectId: markedDocument => markedDocument
});
export const markedDocumentsInitialState: MarkedDocumentsState = adapterMarkedDocuments.getInitialState({});

export const adapterDocumentVersionList = createEntityAdapter<DocumentVersionDto>();
export const documentVersionInitialState: DocumentVersionState = adapterDocumentVersionList.getInitialState({});

export const adapterDeletedDocuments = createEntityAdapter<DeletedDocument>({
  selectId: deletedDocument => deletedDocument.document.id
});
export const adapterDeletedDocumentsVersion = createEntityAdapter<DeletedDocumentVersion>({
  selectId: deletedDocumentVersion => deletedDocumentVersion.document.id
});
export const deletedDocumentsInitialState: DeletedDocumentsState = adapterDeletedDocuments.getInitialState({});
export const deletedDocumentsVersionInitialState: DeletedDocumentsVersionState = adapterDeletedDocumentsVersion.getInitialState({});

export const adapterFolderHierarchy = createEntityAdapter<FolderTreeNode>();
export const folderHierarchyInitialState: FolderHierarchyState = adapterFolderHierarchy.getInitialState({});
