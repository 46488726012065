export enum BillOfQuantitiesItemType {
  Note = 1,
  Expression = 2,
  Subtotal = 3,
  Total = 4
}

export interface BoqItem {
  id: number;
  boqId?: number;
  projectId?: number;
  boqItemType: BillOfQuantitiesItemType;
  expression: string;
  quantity: number;
  group1?: string;
  group2?: string;
  group3?: string;
  budgetItemId?: number;
  isActual?: boolean;
}
