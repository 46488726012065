import { Injectable } from '@angular/core';

import { filter, map, Observable } from 'rxjs';

import { BuildingProgressPermissionType} from '@kros-sk/ssw-shared/permission';

import { BuildingProgressSelectorsService } from '../../store/building-progress';
import { hasContractorRight } from '../shared/building-progress-utils';

@Injectable()
export class MembersGuard {
  constructor(private buildingProgressSelectorsService: BuildingProgressSelectorsService) { }

  canActivate(): Observable<boolean> {
    return this.buildingProgressSelectorsService.permission$.pipe(
      filter(p => !!p),
      map(p => (p.buildingProgressPermissionType | BuildingProgressPermissionType.Approver) !== p.buildingProgressPermissionType &&
        !hasContractorRight(p.buildingProgressPermissionType)
      )
    );
  }
}
