import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { InfoModalComponent, ToastService, TranslateService } from '@kros-sk/ssw-shared-legacy';
import { KrosModalService } from '@kros-sk/components';

import * as actions from './budget-approval.actions';
import { BudgetApprovalBoqService } from '../../budget-approval/services/budget-approval-boq.service';
import {
  BudgetApprovalDifferencesModel,
  BudgetApprovalItem,
  BudgetApprovalModel,
  BudgetChangeModel,
  ChangeSheetModel,
  DeleteBuildingObjectResult
} from '../../budget-approval/models';
import { BudgetApprovalDispatchersService } from './budget-approval-dispatchers.service';
import { BudgetApprovalService, ChangeSheetService } from '../../budget-approval/services';
import { BuildingProgressSharingService } from '../../building-progress/services';

@Injectable()
export class BudgetApprovalEffects {
  getBudgetApproval$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadBudgetApprovalDataStart),
      switchMap(payload =>
        this.budgetApprovalService.getBudgetApprovalData(payload.projectId, payload.hierarchyCode).pipe(
          map((result) => actions.loadBudgetApprovalDataSuccess({
            budgetApprovalData: result ?? this.createEmptyBudgetApprovalModel()
          })),
          catchError(error => of(actions.loadBudgetApprovalDataError({ error })))
        )
      )
    );
  });

  getChangeSheet$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadChangeSheetDataStart),
      switchMap(payload =>
        this.changeSheetService.getChangeSheetData(payload.projectId, payload.buildingObjectId).pipe(
          map((result: ChangeSheetModel) => actions.loadChangeSheetDataSuccess(
            { changeSheetData: { ...result, projectId: payload.projectId } })),
          catchError(error => of(actions.loadChangeSheetDataError({ error })))
        )
      )
    );
  });

  getDifferences$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadDifferencesDataStart),
      switchMap(payload =>
        this.changeSheetService.getDifferencesData(payload.projectId).pipe(
          map((result: BudgetApprovalDifferencesModel) => actions.loadDifferencesDataSuccess(
            { differencesData: result })),
          catchError(error => of(actions.loadDifferencesDataError({ error })))
        )
      )
    );
  });

  approveChangeSheet$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.approveChangeSheetStart),
      switchMap(payload =>
        this.changeSheetService.postApprove(payload.changeSheetApproveModel).pipe(
          map((approveResult: BudgetApprovalItem[]) => {
            return actions.approveChangeSheetSuccess({ approveResult });
          }),
          tap((action) => this.budgetApprovalService.navigateToBuildingProgress(
            payload.changeSheetApproveModel.projectId,
            action.approveResult.find(r => r.id === payload.changeSheetApproveModel.buildingObjectIds[0]).hierarchyCode
          )),
          catchError(error => of(actions.approveChangeSheetError({ error })))
        )
      )
    );
  });

  cancelApprovedChangeSheet$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.cancelApprovedChangeSheetStart),
      switchMap(payload =>
        this.changeSheetService.postCancelApproved(payload.changeSheetApproveModel).pipe(
          map((approveResult: BudgetApprovalItem[]) => {
            this.toastService.open(this.translateService.translate('BUDGET_APPROVAL.APPROVE.CHANGE_SHEET_APPROVED_WAS_CANCELED'));
            return actions.cancelApprovedChangeSheetSuccess({ approveResult });
          }),
          catchError(error => {
            if (error.status === 409) {
              this.openInfoDialog(this.translateService.translate('BUDGET_APPROVAL.APPROVE.CHANGE_SHEET_CANNOT_BE_CANCELED'));
            }

            return of(actions.cancelApprovedChangeSheetError({ error }));
          })
        )
      )
    );
  });

  deleteBuildingObject$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.deleteBuildingObjectStart),
      switchMap(payload =>
        this.budgetApprovalService.deleteBuildingObject(payload.projectId, payload.buildingObjectId).pipe(
          map((deleteResult: DeleteBuildingObjectResult) => {
            this.dispatchers.loadDifferencesData(payload.projectId);
            return actions.deleteBuildingObjectSuccess({ deleteResult });
          }),
          catchError(error => of(actions.deleteBuildingObjectError({ error })))
        )
      )
    );
  });

  getBudgetChange$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadBudgetChangeDataStart),
      switchMap(payload =>
        this.budgetApprovalService.getBudgetChangesData(payload.projectId, payload.buildingObjectId).pipe(
          map((result: BudgetChangeModel) => actions.loadBudgetChangeDataSuccess(
            { budgetChangeData: { ...result, projectId: payload.projectId } })),
          catchError(error => of(actions.loadBudgetChangeDataError({ error })))
        )
      )
    );
  });

  getBudgetData$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadBudgetDataStart),
      switchMap(payload =>
        this.changeSheetService.getBudgetDataForRelation(payload.projectId).pipe(
          map(budgetData => actions.loadBudgetDataSuccess({ budgetData })),
          catchError(error => of(actions.loadBudgetDataError({ error })))
        )
      )
    );
  });

  editChangeSheetItemRelation$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.editChangeSheetItemRelationStart),
      switchMap(payload =>
        this.changeSheetService.editRelation(payload.editModel).pipe(
          map(() => {
            if (payload.reloadChangesData) {
              return actions.loadBudgetChangeDataStart({
                projectId: payload.editModel.projectId,
                buildingObjectId: payload.buildingObjectId,
                setLoading: false
              });
            } else {
              return actions.editChangeSheetItemRelationSuccess({
                budgetItemId: payload.editModel.budgetItemId,
                originalId: payload.editModel.originalId
              });
            }
          }),
          catchError(error => of(actions.editChangeSheetItemRelationError({ error })))
        )
      )
    );
  });

  deleteChangeSheetRelation$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.deleteChangeSheetRelationStart),
      switchMap(payload =>
        this.changeSheetService.deleteRelation(payload.deleteModel.projectId, payload.deleteModel.budgetItemIds).pipe(
          map(() => actions.deleteChangeSheetRelationSuccess({ deleteModel: payload.deleteModel })),
          catchError(error => of(actions.deleteChangeSheetRelationError({ error })))
        )
      )
    );
  });

  getSharingList$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadSharingListStart),
      switchMap(payload => this.buildingProgressSharingService.getSharingList(payload.projectId).pipe(
        map(sharingList => actions.loadSharingListSuccess({ sharingList })),
        catchError(error => of(actions.loadSharingListError({ error })))
      ))
    );
  });

  loadBoqItems$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadBoqItemsStart),
      switchMap(payload => this.boqService.getBoqItems(payload.projectId, payload.budgetItemId).pipe(
        map(resp => actions.loadBoqItemsSuccess({ items: resp }))
      ))
    );
  });

  constructor(
    private actions$: Actions,
    private boqService: BudgetApprovalBoqService,
    private budgetApprovalService: BudgetApprovalService,
    private buildingProgressSharingService: BuildingProgressSharingService,
    private dispatchers: BudgetApprovalDispatchersService,
    private changeSheetService: ChangeSheetService,
    private krosModalService: KrosModalService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) { }

  private createEmptyBudgetApprovalModel(): BudgetApprovalModel {
    return {
      projectId: 0, items: [], decimalPlaces: {
        amount: 0,
        percentage: 0,
        totalPrice: 0,
        totalRubble: 0,
        totalWeight: 0,
        unitPrice: 0,
        buildingObjectTotalPrice: 0,
      }
    };
  }

  private openInfoDialog(infoText: string): void {
    this.krosModalService.openCentered(
      InfoModalComponent,
      {
        body: infoText
      },
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: false,
        showMobileArrowBack: false
      },
      NaN,
      NaN,
      undefined,
      'no-min-width'
    );
  }
}
