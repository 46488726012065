import { Injectable } from '@angular/core';

import { filter } from 'rxjs';

import { areEmailsEqual } from '@kros-sk/ssw-cdk';
import { AuthSelectorsService } from '@kros-sk/auth';
import { PermissionType } from '@kros-sk/ssw-shared/permission';

import { ContentTypes } from '../../upload';
import { DocumentListInfoModel } from '../models';
import { DocumentPermissionType, PermissionTypeInterface } from '../../models';
import { DocumentsExplorerSelectorsService } from '../store';
import { DocumentType } from '../enums';

@Injectable()
export class DocumentsPermissionsService {
  userEmail: string;

  private documents: DocumentListInfoModel[];
  private markedDocuments = [];

  private get selectedItemsCount(): number {
    return this.markedDocuments.length;
  }

  constructor(
    private authSelectorsService: AuthSelectorsService,
    private documentsExplorerSelectorsService: DocumentsExplorerSelectorsService
  ) {
    this.documentsExplorerSelectorsService.documents$.subscribe(docs => this.documents = docs);
    this.documentsExplorerSelectorsService.selectMarkedDocuments$.subscribe(docs => this.markedDocuments = docs);
    this.authSelectorsService.currentUser$.pipe(filter(user => !!user)).subscribe(p => this.userEmail = p.email);
  }

  private get isSingleDocumentSelected(): boolean {
    return this.selectedItemsCount === 1;
  }

  documentWithContentTypeIsSelected(contentType: string[]): boolean {
    if (this.documents) {
      return this.documents.find(doc =>
        this.isDocumentMarked(doc.id) && contentType.indexOf(doc.contentType) !== -1) !== undefined;
    }
    return false;
  }

  private isDocumentMarked(documentId: string): boolean {
    return this.markedDocuments.filter(docId => docId === documentId).length > 0;
  }

  checkMarkedPermissionType(minimumPermissionType: PermissionType): boolean {
    return this.documents
      .filter(doc => this.isDocumentMarked(doc.id))
      .every(doc => this.hasMinimumPermissionType(doc.permissionType, minimumPermissionType));
  }

  checkMarkedPermissionTypes(minimumPermissionType: PermissionType, requiredPermissionType: DocumentPermissionType): boolean {
    return this.documents
      .filter(doc => this.isDocumentMarked(doc.id))
      .every(doc => this.hasMinimumPermissionType(doc.permissionType, minimumPermissionType) &&
        this.hasDocumentPermissionType(doc.documentPermissionType, requiredPermissionType));
  }

  getDocumentPermissionType(name: string): PermissionType {
    const document = this.documents
      .find(doc => doc.originalName === name);

    return document
      ? document.permissionType
      : PermissionType.Unidentified;
  }

  hasMinimumPermissionType(permissionType: PermissionType, minimumPermissionType: PermissionType): boolean {
    return permissionType >= minimumPermissionType;
  }

  hasDocumentPermissionType(documentPermissionType: DocumentPermissionType, requiredPermissionType: DocumentPermissionType): boolean {
    // eslint-disable-next-line no-bitwise
    return (documentPermissionType | requiredPermissionType) === documentPermissionType;
  }

  canOpenDrawingFile(): boolean {
    return this.canOpenSpecificFileTypes(ContentTypes.drawings);
  }

  canOpenPdfFile(): boolean {
    return this.canOpenSpecificFileTypes([ContentTypes.pdf]);
  }

  canOpenIfc(): boolean {
    return this.canOpenSpecificFileType(ContentTypes.ifc);
  }

  canOpenImages(): boolean {
    return this.canOpenSpecificFileTypes(ContentTypes.images);
  }

  private canOpenSpecificFileType(fileType: string): boolean {
    return this.canOpenSpecificFileTypes([fileType]);
  }

  canOpenDrawing(): boolean {
    return this.canOpenSpecificFileTypes(ContentTypes.drawings.concat(ContentTypes.pdf));
  }

  get canDownload(): boolean {
    return this.isSingleDocumentSelected &&
      !this.isFolderSelected() &&
      this.checkMarkedPermissionTypes(PermissionType.Reader, DocumentPermissionType.Downloader);
  }

  get canRenameFolder(): boolean {
    return this.isSingleDocumentSelected &&
      this.isFolderSelected() &&
      (this.checkMarkedPermissionType(PermissionType.Owner) ||
        (this.checkMarkedPermissionType(PermissionType.Contributor) && this.checkMarkedAuthor()));
  }

  get canDelete(): boolean {
    return this.selectedItemsCount > 0 &&
      (this.checkMarkedPermissionType(PermissionType.Owner) || (this.checkMarkedPermissionType(PermissionType.Contributor) &&
        this.checkMarkedAuthor() && this.areSelectedSingleVersionDocuments()));
  }

  canShare(project: PermissionTypeInterface): boolean {
    return this.hasMinimumPermissionType(project.permissionType, PermissionType.Owner);
  }

  canOpenOnNewTab(): boolean {
    return this.isSingleDocumentSelected && this.documentWithContentTypeIsSelected(ContentTypes.images.concat(ContentTypes.pdf));
  }

  canOpenDocumentOnNewTab(): boolean {
    return this.isSingleDocumentSelected && this.documentWithContentTypeIsSelected(ContentTypes.images);
  }

  canOpenConstruction(): boolean {
    return this.isSingleDocumentSelected && this.documentWithContentTypeIsSelected([ContentTypes.construction]);
  }

  private canOpenSpecificFileTypes(fileTypes: string[]): boolean {
    return this.isSingleDocumentSelected && this.documentWithContentTypeIsSelected(fileTypes);
  }

  private checkMarkedAuthor(): boolean {
    return this.documents
      .filter(doc => this.isDocumentMarked(doc.id))
      .every(doc => areEmailsEqual(doc.createdByEmail, this.userEmail));
  }

  private isFolderSelected(): boolean {
    return !!this.documents
      .filter(doc => this.isDocumentMarked(doc.id))
      .find(doc => doc.type === DocumentType.Folder);
  }

  private areSelectedSingleVersionDocuments(): boolean {
    return this.documents.filter(doc => this.isDocumentMarked(doc.id) && doc.versionsCount > 1).length === 0;
  }
}
