<app-panel-content
  [collapsed]="panelCollapsed"
  [headerText]="'BUDGET_CHANGES.STRUCTURE' | translate"
  (panelCollapsed)="onPanelCollapsed($event)"
>
  <ng-container
    class="panel-top"
    ngProjectAs="panelTop"
  >
    <div
      class="panel-top"
      [ngClass]="{'collapsed': panelCollapsed}"
    >
      <button
        class="btn btn-outline-primary"
        type="button"
        (click)="onBackClick()"
        data-test="budget-approval-button-back-to-list"
      >
        <i class="material-icons">chevron_left</i>
        {{ panelCollapsed ? '' : ('CHANGE_SHEET.BACK_TO_LIST' | translate) }}
      </button>
    </div>
  </ng-container>

  <ng-container ngProjectAs="panel">
    <app-budget-change-panel-container [tableId]="tableId"></app-budget-change-panel-container>
  </ng-container>

  <ng-container ngProjectAs="content">
    <app-budget-changes-detail [tableId]="tableId"></app-budget-changes-detail>
  </ng-container>
</app-panel-content>
