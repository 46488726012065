import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'kros-color-palette',
  templateUrl: './color-palette.component.html',
  styleUrls: ['./color-palette.component.scss']
})
export class ColorPaletteComponent implements OnInit {
  multiselectDictionary: { [color: string]: boolean; } = {};

  @Input() colors: string[];
  @Input() selectedColor: string;
  @Input() multiselect = false;
  @Input() isExtraColors = false;

  @Output() colorSelect = new EventEmitter<string>();
  @Output() colorDeselect = new EventEmitter<string>();

  ngOnInit(): void {
    if (this.multiselect) {
      this.colors.forEach(color => {
        this.multiselectDictionary[color] = false;
      });
    }
  }

  selectColor(color: string): void {
    if (this.multiselect) {
      if (this.multiselectDictionary[color]) {
        this.colorDeselect.emit(color);
      } else {
        this.colorSelect.emit(color);
      }

      this.multiselectDictionary[color] = !this.multiselectDictionary[color];
    } else {
      this.selectedColor = color;
      this.colorSelect.emit(color);
    }
  }

  isColorSelected(color: string): boolean {
    if (this.multiselect) {
      return this.multiselectDictionary[color];
    } else {
      return this.selectedColor === color;
    }
  }
}
