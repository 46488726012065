import { createFeature } from '@ngrx/store';

import { FeatureFlags } from '../types/feature-flags.types';
import { featureFlagsStoreReducer } from './feature-flags.reducers';

export interface FeatureFlagsState {
  featureFlags: FeatureFlags;
  featuresLoaded: boolean;
}

export const featureFlagsStoreFeature = createFeature({
  name: 'featureFlags',
  reducer: featureFlagsStoreReducer,
});
