import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { CompareVersionDialogComponent } from './compare-version-dialog/compare-version-dialog.component';
import { DirectivesModule } from '../directives';
import { TranslateModule } from '../translate';
import { VersionsComboComponent } from './compare-version-dialog/versions-combo/versions-combo.component';
import { VersionService } from './version.service';

@NgModule({
  declarations: [
    VersionsComboComponent,
    CompareVersionDialogComponent
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    NgbDropdownModule,
    TranslateModule.forChild()
  ],
  exports: [
    VersionsComboComponent,
    CompareVersionDialogComponent
  ],
  providers: [
    VersionService
  ]
})
export class VersionModule { }
