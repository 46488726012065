<div class="toolbar-actions">
  <div class="actions left">
    <button
      *ngIf="haveDifferences && !isMultiSelect"
      class="btn toolbar-action no-selection gray-toolbar-action ga-ba-differences red"
      type="button"
      (click)="showDifferences()"
      krosTooltip="{{ 'BUDGET_APPROVAL.DIFFERENT_PRICES' | translate }}"
      data-test="budget-approval-differences"
    >
      <i class="material-icons icon-button ga-ba-differences">sell</i>
      <span class="toolbar-action-text">{{ 'BUDGET_APPROVAL.DIFFERENT_PRICES' | translate }}</span>
    </button>

    <span
      *ngIf="isBudgetApprovalData && isMultiSelect"
      class="selected-items"
    >{{'COMMON.SELECTED' | translate}}({{ multiSelectCount }}):</span>

    <button
      *ngIf="isBudgetApprovalData && !isMultiSelect"
      class="btn toolbar-action no-selection gray-toolbar-action ga-ba-show-change-sheet-items"
      type="button"
      (click)="showChangeSheetItems()"
      krosTooltip="{{ 'BUDGET_APPROVAL.OPEN_BUDGET' | translate }}"
      data-test="budget-approval-show-change-sheet-items"
    >
      <i class="material-icons icon-button ga-ba-show-change-sheet-items">list_alt</i>
      <span class="toolbar-action-text">{{ 'BUDGET_APPROVAL.OPEN_BUDGET' | translate }}</span>
    </button>

    <button
      *ifBudgetApprovalFunctionsPermission="isBudgetApprovalData"
      class="btn toolbar-action no-selection gray-toolbar-action ga-ba-change-sheet-approve"
      type="button"
      (click)="onApprove()"
      krosTooltip="{{ 'BUDGET_APPROVAL.APPROVE.APPROVE_CHANGE_SHEET' | translate }}"
      data-test="budget-approval-approve"
    >
      <i class="material-icons icon-button ga-ba-change-sheet-approve">done_all</i>
      <span class="toolbar-action-text">{{ 'BUDGET_APPROVAL.APPROVE.APPROVE_CHANGE_SHEET' | translate }}</span>
    </button>

    <button
      *ifBudgetApprovalFunctionsPermission="isBudgetApprovalData && !isMultiSelect"
      class="btn toolbar-action no-selection gray-toolbar-action ga-ba-export"
      type="button"
      (click)="export()"
      krosTooltip="{{ 'BUDGET_APPROVAL.EXPORT.EXPORT' | translate }}"
      data-test="budget-approval-export"
    >
      <i class="material-icons icon-button ga-ba-export">save_alt</i>
      <span class="toolbar-action-text">{{ 'BUDGET_APPROVAL.EXPORT.EXPORT' | translate }}</span>
    </button>

    <div
      *ifBudgetApprovalFunctionsPermission="isBudgetApprovalData && !isMultiSelect"
      ngbDropdown
      container="body"
      class="toolbar-action no-selection gray-toolbar-action dropdown-action"
    >
      <button
        ngbDropdownToggle
        class="btn dropdown-toggle"
        type="button"
        data-test="budget-approval-more-dropdown"
      >
        <div
          class="dropdown-toggle-content"
          krosTooltip="{{ 'COMMON.MORE' | translate }}"
        >
          <i class="material-icons icon-button">more_vert</i>
          <span class="toolbar-action-text">{{ 'COMMON.MORE' | translate }}</span>
        </div>
      </button>
      <div
        ngbDropdownMenu
        aria-labelledby="moreDropdown"
        display="dynamic"
      >
        <button
          ngbDropdownItem
          class="ga-ba-building-object-delete"
          type="button"
          (click)="delete()"
          krosTooltip="{{ 'COMMON.DELETE' | translate }}"
          position="before"
          data-test="budget-approval-delete"
        >
          <i class="material-icons icon-button ga-ba-building-object-delete">delete</i>
          <span>{{ 'COMMON.DELETE' | translate }}</span>
        </button>

        <button
          ngbDropdownItem
          class="ga-ba-change-sheet-cancel-approved"
          type="button"
          krosTooltip="{{ 'BUDGET_APPROVAL.APPROVE.CANCEL_APPROVED_CHANGE_SHEET' | translate }}"
          position="before"
          data-test="budget-approval-cancel-approved"
          (click)="onCancelApprovedClick()"
        >
          <i class="material-icons icon-button ga-ba-change-sheet-cancel-approved">remove_done</i>
          <span>{{ 'BUDGET_APPROVAL.APPROVE.CANCEL_APPROVED_CHANGE_SHEET' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
