import { InjectionToken } from '@angular/core';

export const KROS_IDENTITY_SERVER_CONFIG = new InjectionToken<KrosIdentityServerConfig>('KROS_IDENTITY_SERVER_CONFIG');

export class KrosIdentityServerConfig {
  clientUrl: string;
  url: string;
  applicationId?: string;
  externalLoginRoutes?: string[];
  postLogoutUrl?: string;
}
