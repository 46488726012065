import { Injectable } from '@angular/core';

import { Action, Store } from '@ngrx/store';

import * as undoRedoActions from './undo-redo.actions';
import { HistoryState } from './undo-redo.state';

@Injectable()
export class UndoRedoDispatchersService {

  constructor(
    private store: Store<HistoryState>
  ) { }

  undo(presentAction: Action): void {
    this.store.dispatch(
      undoRedoActions.undo({ payload: { presentAction } })
    );
  }

  redo(futureAction: Action): void {
    this.store.dispatch(
      undoRedoActions.redo({ payload: { futureAction } })
    );
  }

  undoSuccess(): void {
    this.store.dispatch(
      undoRedoActions.undoSuccess()
    );
  }

  redoSuccess(): void {
    this.store.dispatch(
      undoRedoActions.redoSuccess()
    );
  }

  clearUndoRedoStacks(): void {
    this.store.dispatch(undoRedoActions.clearUndoRedoStack());
  }
}
