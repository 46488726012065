import { Action } from '@ngrx/store';

import * as buildingProgressActions from '../building-progress/building-progress.actions';
import * as buildingProgressBoqActions from '../building-progress/building-progress-boq.actions';

export const undoableBuildingProgressActions: Action[] = [
  buildingProgressActions.editConstructionDataProgress,
  buildingProgressActions.editPercentageSuccess,
  buildingProgressActions.setAutocalculatedAmountSuccess,
  buildingProgressActions.completePercentageSuccess,
  buildingProgressBoqActions.setBoqSuccess,
  buildingProgressActions.totalPriceSetSuccess,
  buildingProgressActions.setColorCodesSuccess
];

export const needClearStackBuildingProgressActions: Action[] = [
  buildingProgressActions.deletePeriodSuccess,
  buildingProgressActions.setIsApprovalRequestedPeriodSuccess,
  buildingProgressActions.setIsLockedPeriodSuccess,
  buildingProgressActions.deleteItemsSuccess,
  buildingProgressActions.setItemsNotCompletedSuccess
];
