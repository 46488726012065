import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { DataCollectorService } from './data-access/services/device-collector.service';
import { DeviceInfoService } from './data-access/services/device-info.service';
import { DeviceInterceptorService } from './data-access/services/device-interceptor.service';
import { DeviceLoggerComponent } from './ui/device-logger.component';
import { DeviceSamplerService } from './data-access/services/device-sampler.service';

@NgModule({
  declarations: [
    DeviceLoggerComponent
  ],
  providers: [
    DeviceSamplerService,
    DataCollectorService,
    DeviceInfoService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DeviceInterceptorService,
      multi: true
    }
  ],
  exports: [
    DeviceLoggerComponent
  ]
})
export class DeviceLoggerModule { }
