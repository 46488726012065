import {
  booleanAttribute,
  Directive,
  DoCheck,
  ElementRef,
  Host,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Self,
} from '@angular/core';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';

import { NgrxFormControlDirective } from 'ngrx-forms';
import { Subject } from 'rxjs';

import { Forbidable } from '../mixins/forbidable-mixin';
import { KROS_INPUT_VALUE_ACCESSOR } from './kros-input-value-accessor';
import { KrosFormFieldControl } from './kros-form-field-control';
import { Scrollable } from '../mixins/scrollable-mixin';

let nextUniqueId = 0;

const KrosControlBase = Scrollable(Forbidable(class {}));

@Directive({
  selector: 'input[krosControl], textarea[krosControl]',
  exportAs: 'krosControl',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[id]': 'id',
    '[attr.id]': 'id',
    '[disabled]': 'disabled',
    '[required]': 'required',
    '[attr.aria-required]': 'required',
    '(focus)': 'focusChanged(true)',
  },
  providers: [{ provide: KrosFormFieldControl, useExisting: KrosControl }],
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['krosForbiddenCharacters']
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class KrosControl
  extends KrosControlBase
  implements KrosFormFieldControl<any>, OnDestroy, DoCheck {

  protected _uid = `kros-input-${ nextUniqueId++ }`;
  protected _id: string;
  protected _disabled = false;
  protected _required: boolean | undefined;

  private _inputValueAccessor: { value: any };
  focused = false;

  readonly stateChanges = new Subject<void>();
  readonly focusChanges = new Subject<boolean>();
  errorState: boolean = this.ngControl?.control?.invalid || this.ngrxControl?.state?.isInvalid;

  @Input()
  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value || this._uid;
  }

  @Input()
  get disabled(): boolean {
    if (this.ngControl && this.ngControl.disabled !== null) {
      return this.ngControl.disabled;
    }
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;

    // Browsers may not fire the blur event if the input is disabled too quickly.
    // Reset from here to ensure that the element doesn't become stuck.
    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }

  @Input()
  get required(): boolean {
    return this._required ?? (this.ngControl?.control?.hasValidator(Validators.required) ?? false);
  }

  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
  }

  @Input()
  get value(): string {
    return this._inputValueAccessor.value;
  }

  set value(value: any) {
    if (value !== this.value) {
      this._inputValueAccessor.value = value;
      this.stateChanges.next();
    }
  }

  @Input({transform: booleanAttribute}) stopScroll = false;

  get elementRef(): ElementRef {
    return this._elementRef;
  }

  constructor(
    @Host() protected _elementRef: ElementRef<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() @Self() @Inject(KROS_INPUT_VALUE_ACCESSOR) inputValueAccessor: any,
    @Optional() @Self() public ngrxControl: NgrxFormControlDirective<any>,
  ) {
    super();

    if (ngControl && ngControl.valueAccessor) this.trimValueAccessor(ngControl.valueAccessor);

    const element = this._elementRef.nativeElement;
    this._inputValueAccessor = inputValueAccessor || element;

    // Force setter to be called in case id was not specified.
    this.id = this.id;
  }

  ngDoCheck(): void {
    if (this.ngControl || this.ngrxControl) {
      // We need to re-evaluate this on every change detection cycle, because there are some
      // error triggers that we can't subscribe to (e.g. parent form submissions). This means
      // that whatever logic is in here has to be super lean or we risk destroying the performance.
      this.updateErrorState();
    }
  }

  ngOnDestroy(): void {
    this.focusChanges.complete();
    this.stateChanges.complete();
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: any): boolean {
    return this.isCharacterAllowed(event.key);
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: any): void {
    this.focusChanged(false);
    this._elementRef.nativeElement.value = this.removeForbiddenCharacters(value);
  }

  /** Callback for the cases where the focused state of the input changes. */
  focusChanged(isFocused: boolean): void {
    if (isFocused !== this.focused) {
      this.focused = isFocused;
      this.focusChanges.next(isFocused);
      this.stateChanges.next();
    }
    if (isFocused && !this.stopScroll) {
      this.scrollToViewportTop();
    }
  }

  private updateErrorState(): void {
    this.errorState = this.ngControl?.control?.invalid || this.ngrxControl?.state?.isInvalid;
  }

  private trimValueAccessor(valueAccessor: ControlValueAccessor): void {
    const original = valueAccessor.registerOnChange;

    valueAccessor.registerOnChange = (fn: (_: unknown) => void): any => {
      return original.call(valueAccessor, (value: unknown) => {
        const newValue = this.removeForbiddenCharacters(value as string);
        return fn(newValue);
      });
    };
  }
}
