export enum ApplicationType {
  BuildingProgress = 1,
  Documents = 2,
  Bim = 3,
  Budget = 4,
  QuantityManager = 5,
  Misha = 6,
  Stavario = 7,
  Buildary = 8
}
