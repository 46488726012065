import { Component, DestroyRef, ElementRef, inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { KrosModalRef } from '@kros-sk/components';

import { DynamicLocaleId, TranslateService } from '../../../translate';
import { ProjectDialogComponent } from '../project-dialog/project-dialog.component';

@Component({
  selector: 'kros-project-info-dialog',
  templateUrl: './project-info-dialog.component.html',
  styleUrls: ['../project-dialog/project-dialog.component.scss', './project-info-dialog.component.scss'],
  providers: [
    {
      provide: LOCALE_ID,
      useClass: DynamicLocaleId,
      deps: [TranslateService]
    }
  ]
})
export class ProjectInfoDialogComponent extends ProjectDialogComponent implements OnInit {

  private destroyRef = inject(DestroyRef);
  protected modalRef = inject(KrosModalRef);
  protected translateService = inject(TranslateService);

  @ViewChild('closeButton') closeButton: ElementRef<HTMLElement>;
  @ViewChild('nameInput') nameInput: ElementRef<HTMLElement>;

  ngOnInit(): void {
    this.data = this.modalRef.data.data;

    super.ngOnInit();

    if (this.data.disableForm) {
      this.editForm.disable();

      if (this.data.canEditApiCode) {
        this.apiCode.enable();
      }
    }

    this.project = this.data.projectDetail;
    this.fillForm();
    this.detectFormChanges();
  }

  detectFormChanges(): void {
    this.editForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.changesMade = true);
  }

  onSubmit(): void {
    if (this.editForm.valid && this.editForm.dirty) {
      this.copyProjectFromFormControl();
      this.modalRef.submit(this.project);
    }
  }

  protected onInitializeForm(): void {
    setTimeout(() => {
      if (this.data.disableForm) {
        this.closeButton?.nativeElement.focus();
      } else {
        this.nameInput?.nativeElement.focus();
      }
    });
  }

  private fillForm(): void {
    if (this.project) {
      this.editForm.patchValue({
        place: this.project.place,
        customerName: this.project.customerName,
        customerId: this.project.customerId,
        customerVatId: this.project.customerVatId,
        authorName: this.project.authorName,
        authorId: this.project.authorId,
        authorVatId: this.project.authorVatId,
        contractorName: this.project.contractorName,
        contractorId: this.project.contractorId,
        contractorVatId: this.project.contractorVatId,
        designerName: this.project.designerName,
        designerId: this.project.designerId,
        designerVatId: this.project.designerVatId,
        constructionManager: this.project.constructionManager,
        apiCode: this.project.apiCode,
        dateStarted: this.getDate(this.project.dateStarted),
        dateEnded: this.getDate(this.project.dateEnded),
        contractNumber: this.project.contractNumber,
        contractDate: this.getDate(this.project.contractDate)
      });
    }
  }

  private getDate(date: Date): Date {
    return date ? new Date(date) : null;
  }
}
