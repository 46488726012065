import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

import { CompanyInfoModule } from '../company-info';
import { CreateCompanyEditorComponent } from './create-company-editor/create-company-editor.component';

@NgModule({
  declarations: [
    CreateCompanyEditorComponent
  ],
  imports: [
    CommonModule,
    CompanyInfoModule,
    NgbAlertModule,
    ReactiveFormsModule,
  ],
  exports: [
    CreateCompanyEditorComponent
  ]
})
export class CreateCompanyEditorModule { }
