<div
  class="panel-container"
  [ngClass]="{'collapsed': collapsed}"
>
  <div class="header">
    <h5 class="header-text">{{ 'DOCUMENTS.FOLDERS' | translate }}</h5>

    <div
      class="collapse-button"
      (click)="toggleCollapsePanel()"
    >
      <span class="material-icons icon-button">
        {{ collapsed ? 'keyboard_double_arrow_right' : 'keyboard_double_arrow_left' }}
      </span>
    </div>

    <div
      *ngIf="collapsed"
      class="collapsed-text"
    >{{ 'DOCUMENTS.FOLDERS' | translate }}</div>
  </div>

  <div class="explorer">
    <app-document-explorer-tree
      class="explorer-tree"
      [projectDetail]="projectDetail"
      (nodeSelected)="onNodeSelected()"
    ></app-document-explorer-tree>
  </div>
</div>
