import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ExportColumnSelection } from './export-column-selection.model';

@Component({
  selector: 'app-export-colums-selection',
  templateUrl: './export-colums-selection.component.html',
  styleUrls: ['./export-colums-selection.component.scss']
})
export class ExportColumsSelectionComponent implements OnInit {

  @Input() columns: ExportColumnSelection[] = [];
  @Input() form: FormGroup;

  constructor(
    private formBuiler: FormBuilder
  ) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.columns.forEach(column => {
      this.form.addControl(column.id, this.formBuiler.control(column.checked));
      if (column.children) {
        let checkedChildrenCount = 0;
        column.children.forEach(child => {
          this.form.addControl(child.id, this.formBuiler.control(child.checked));
          if (child.checked) {
            checkedChildrenCount++;
          }
        });
        if (checkedChildrenCount === column.children.length) {
          this.form.get(column.id).setValue(true);
        } else if (checkedChildrenCount > 0) {
          column.indeterminate = true;
        }
      }
    });
  }

  selectAll(select: boolean): void {
    this.columns.forEach(column => {
      column.indeterminate = false;
      this.form.get(column.id).setValue(select);
      if (column.children) {
        column.children.forEach(child => {
          this.form.get(child.id).setValue(select);
        });
      }
    });
  }

  onColumnCheckChanged(value: boolean, column: ExportColumnSelection): void {
    if (!!column.children) {
      column.indeterminate = false;
      column.children.forEach(child => {
        this.form.get(child.id).setValue(value);
      });
    }
  }

  onChildCheckChanged(value: boolean, column: ExportColumnSelection): void {
    column.indeterminate =
      column.children.some(c => !this.form.get(c.id).value) && column.children.some(c => !!this.form.get(c.id).value);
    this.form.get(column.id).setValue(!column.indeterminate ? value : false);
  }
}
