export class PeriodHistory {
  public id: number;
  public projectId: number;
  public periodId: number;
  public userId: number;
  public operationType: PeriodOperationType;
  public dateChanged: Date;
  public dateFrom: Date;
  public dateTo: Date;
  public email: string;
}

export enum PeriodOperationType {
  Create = 1,
  Edit = 2,
  Delete = 3,
  Lock = 4,
  Unlock = 5,
  SetApprovalRequested = 6,
  CancelApprovalRequested = 7,
  SetApproved = 8,
  CancelApproved = 9,
  PartiallyApproved = 10
}
