import {
  Component,
  ContentChild,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter, switchMap, take } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';

import { areEmailsEqual } from '@kros-sk/ssw-cdk';
import { AuthSelectorsService } from '@kros-sk/auth';

import { ItemSharing } from '../../../models';
import { NewSharingComboMode, SharingPermissionValues } from '../../models';
import { PermissionChangedDto } from '../../models/permission-changed-dto.model';
import { SharingActionType } from '../../models/sharing-action-type.enum';
import { TimelineType } from '../../../timeline/timeline-type.enum';
import { UserSharingHelpersService } from '../../services/user-sharing-helpers.service';
import { UserSharingService } from '../../services/user-sharing.service';

@Component({
  selector: 'kros-user-sharing-dialog-base',
  templateUrl: './user-sharing-dialog-base.component.html',
  styleUrls: ['./user-sharing-dialog-base.component.scss']
})

export class UserSharingDialogBaseComponent implements OnInit {

  @ContentChild('customButton') customButton: TemplateRef<ElementRef>;

  @Input() set initialCompactMode(value: boolean) {
    this.compacted = value;
  }
  @Input() checkPermissionsBeforeSubmit: (sharings: ItemSharing[]) => Observable<boolean> = () => of(true);
  @Input() shareNewPermissions: (sharings: ItemSharing[]) => Observable<void>;
  @Input() submitEditedPermissions: () => Observable<boolean>;
  @Input() getSharing: (unconfirmedSharing: ItemSharing) => any;
  @Input() loadedItems = false;
  @Input() editItems: any[] = [];
  @Input() needRefresh = false;
  @Input() readonly;
  @Input() sharings: ItemSharing[] = [];
  @Input() otherAlreadySharedEmails: string[] = [];
  @Input() unconfirmedSharings: ItemSharing[] = [];
  @Input() sharingMode: NewSharingComboMode;
  @Input() defaultPermissions: SharingPermissionValues;
  @Input() title = '';
  @Input() dataTestPrefix = '';
  @Input() warningText: string;

  @Output() submitSharing = new EventEmitter<boolean>();
  @Output() unconfirmedSharingChange = new EventEmitter<ItemSharing[]>();

  compacted = true;
  changesMade = false;
  timelineType = TimelineType;

  protected canShowApprovalOrder = false;

  private userEmail: string;
  private authSelectorService = inject(AuthSelectorsService);
  private sharingHelperService = inject(UserSharingHelpersService);
  private userSharingService = inject(UserSharingService);
  private destroyRef = inject(DestroyRef);

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (this.changesMade) {
      event.preventDefault();
    } else {
      this.onCloseClick();
    }
  }

  ngOnInit(): void {
    this.authSelectorService.currentUser$.pipe(filter(user => !!user), takeUntilDestroyed(this.destroyRef))
      .subscribe(user => this.userEmail = user?.email);
  }

  getExistingSharingEmails(): string[] {
    return this.sharings.map(s => s.email)
      .concat(this.unconfirmedSharings.map(u => u.email))
      .concat(this.otherAlreadySharedEmails);
  }

  onCloseClick(): void {
    this.submitSharing.emit(this.needRefresh);
  }

  onUnconfirmedPermissionDeleted(sharing: ItemSharing): void {
    this.unconfirmedSharings.splice(this.unconfirmedSharings.indexOf(sharing), 1);
    this.changesMade = (!!this.editItems && this.editItems.length > 0) || this.unconfirmedSharings.length > 0;
  }

  onChangedPermission(permission: PermissionChangedDto): void {
    const existingSharingChanges = this.userSharingService.editUser(this.editItems, permission, SharingActionType.Edit);
    this.changesMade = existingSharingChanges || this.unconfirmedSharings.length > 0;

    if (!existingSharingChanges) {
      this.sharingHelperService.cancelChanges(true);
    }
  }

  onDeletedPermission(permission: PermissionChangedDto): void {
    this.changesMade = this.userSharingService.editUser(this.editItems, permission, SharingActionType.Delete);
  }

  onShared(newSharings: ItemSharing[]): void {
    newSharings = newSharings.filter(s => !areEmailsEqual(s.email, this.userEmail));

    if (newSharings.length > 0) {
      this.unconfirmedSharingChange.emit(this.unconfirmedSharings.concat(newSharings));
      this.compacted = false;
      this.changesMade = true;
    }
  }

  submitChanges(): void {
    this.checkPermissionsBeforeSubmit(this.getSharings())
      .pipe(
        filter(checkResult => checkResult),
        switchMap(() =>
          forkJoin({
            newPermission: this.shareNewPermissionIfNeed().pipe(take(1)),
            editedPermission: this.submitEditedPermissions().pipe(take(1))
          })
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((result) => {
        if (result.editedPermission) {
          this.changesMade = false;
          this.submitSharing.emit(true);
        }
      });
  }

  cancelChanges(): void {
    this.userSharingService.undoChanges(this.sharings, this.editItems);
    this.sharingHelperService.cancelChanges(false);
    this.changesMade = false;
    this.editItems = [];
    this.unconfirmedSharingChange.emit([]);
  }

  private shareNewPermissionIfNeed(): Observable<any> {
    if (this.unconfirmedSharings.length > 0) {
      return this.shareNewPermissions(this.getSharings());
    }

    return of(false);
  }

  private getSharings(): ItemSharing[] {
    return this.unconfirmedSharings.map(s => this.getSharing(s));
  }
}
