<div class="data-container">
  <div class="data">
    <div
      class="number"
      data-test="summary-panel-total-price"
    >{{ formatNumber(model.totalPrice) }}</div>

    <div class="description"> {{ 'BUILDING_PROGRESS.SUMMARY_PANEL.TOTAL_PRICE' | translate }}</div>
  </div>

  <div class="data">
    <kros-pie-chart
      [percentage]="model.percentage"
      [size]="40"
      data-test="summary-panel-pie-chart"
    >
    </kros-pie-chart>
  </div>

  <div class="{{ model.periodDate === '' ? 'noData' : 'data' }}">
    <div
      class="number"
      data-test="summary-panel-partly-completed-total-price"
    >{{ formatNumber(model.partlyCompletedTotalPrice) }}</div>

    <div class="description"> {{ 'BUILDING_PROGRESS.SUMMARY_PANEL.PARTLY_COMPLETED_TOTAL_PRICE' | translate }} {{
      model.periodDate }} </div>
  </div>

  <div class="data">
    <div
      class="number"
      data-test="summary-panel-current-period"
    >{{ formatNumber(model.actualPeriodAmount) }}</div>

    <div class="description highlight"> {{ 'BUILDING_PROGRESS.SUMMARY_PANEL.CURRENT_PERIOD' | translate }}</div>
  </div>

  <div class="data">
    <div
      class="number"
      data-test="summary-panel-completed-total-price"
    >{{ formatNumber(model.completetedTotalPrice) }}</div>

    <div class="description"> {{ 'BUILDING_PROGRESS.SUMMARY_PANEL.COMPLETED_TOTAL_PRICE' | translate }}</div>
  </div>

  <div class="data">
    <div
      class="number"
      data-test="summary-panel-rest-total-price"
    >{{ formatNumber(model.restTotalPrice) }}</div>

    <div class="description"> {{ 'BUILDING_PROGRESS.SUMMARY_PANEL.REST_TOTAL_PRICE' | translate }}</div>
  </div>

  <div class="data">
    <div
      class="number"
      data-test="summary-panel-overdrawn-total-price"
    >{{ formatNumber(model.overdrawnTotalPrice) }}</div>

    <div class="description"> {{ 'BUILDING_PROGRESS.SUMMARY_PANEL.OVERDRAWN_TOTAL_PRICE' | translate }}</div>
  </div>

  <div class="data">
    <div
      class="number"
      data-test="summary-panel-not-completed-total-price"
    >{{ formatNumber(model.notCompletedTotalPrice) }}</div>

    <div class="description">{{ 'BUILDING_PROGRESS.SUMMARY_PANEL.NOT_COMPLETED_TOTAL_PRICE' | translate }}</div>
  </div>

  <button
    class="btn btn-link btn-close"
    type="button"
    (click)="onClose()"
    data-test="summary-panel-close"
  >
    <i class="material-icons">close</i>
  </button>
</div>
