import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { AnalyticsService, LicenseAgreementComponent, LicenseAgreementConfig, TranslateService } from '@kros-sk/ssw-shared-legacy';
import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { KrosModalRef, KrosModalService } from '@kros-sk/components';

import { BuildingProgressDispatchersService, BuildingProgressSelectorsService } from '../../store/building-progress';
import { environment } from '../../../environments/environment';
import { LicenseHelper } from '../helpers';

@Injectable()
export class LicenseAgreementService {
  private modalRef: KrosModalRef;

  constructor(
    private appInsightsService: AppInsightsBaseService,
    private analyticsService: AnalyticsService,
    private krosModalService: KrosModalService,
    private buildingProgressDispatchersService: BuildingProgressDispatchersService,
    private buildingProgressSelectorsService: BuildingProgressSelectorsService,
    private licenseHelper: LicenseHelper,
    private translateService: TranslateService,
  ) { }

  get infoTexts(): string[] {
    const locationSuffix = environment.location.toUpperCase();
    return [
      this.translateService.translate('LICENSE_AGREEMENT.APP_BUILDING_PROGRESS_CUSTOMER_PACKAGE_' + locationSuffix),
      this.translateService.translate('LICENSE_AGREEMENT.APP_BUILDING_PROGRESS_READ_ONLY_' + locationSuffix)
    ];
  }

  openLicenceAgreementDialogIfCan(isOwner: boolean): Observable<any> {
    return new Observable(observer => {
      this.buildingProgressSelectorsService.licenseAgreement$
        .pipe(take(1))
        .subscribe(licenseAgreement => {
          if (isOwner && !licenseAgreement && this.licenseHelper.isFullLicense) {
            const licenseConfig: LicenseAgreementConfig = {
              heading: this.translateService.translate('LICENSE_AGREEMENT.AGREEMENT'),
              infoTexts: this.infoTexts,
              imgPath: 'assets/images/license.svg'
            };
            this.appInsightsService.trackEvent('PV-license-agreement-open');
            this.analyticsService.raiseEvent('Priebeh výstavby', 'Otvoriť Podmienky používania PV',
              'Zobraziť okno pri načítaní PV pred akceptáciou Podmienok používania PV');
            this.modalRef = this.krosModalService.openCentered(
              LicenseAgreementComponent,
              { licenseConfig },
              {
                closeOnBackdropClick: false,
                closeOnEscClick: false,
                addModalToBrowsersHistory: false,
                showMobileArrowBack: false
              }
            );

            this.modalRef.afterClosed$.pipe(take(1)).subscribe(result => {
              if (result.type === 'submit') {
                this.appInsightsService.trackEvent('PV-license-agreement-confirm');
                this.buildingProgressDispatchersService.confirmLicenseAgreement();
                observer.next();
              }
              this.modalRef = undefined;
            });
          } else {
            observer.next();
          }
        });
    });
  }

  closeLicenceAgreementDialog(): void {
    if (this.modalRef) {
      this.modalRef.cancel();
    }
  }
}
