import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { KrosModalRef, KrosModalService } from '@kros-sk/components';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

import { BuildingProgressRemainingBudgetModel } from '../../../models/building-progress-export.model';
import { getAppInsightsPrefix } from '../../../shared/building-progress-utils';
import { openHeaderSettingsDialog } from '../building-progress-export-helpers/building-progress-export-helpers';
import { PermissionHelper } from '../../../helpers';
import { ProjectsDispatchersService } from '../../../../store/projects';

@Component({
  selector: 'app-building-progress-export-remaining-budget',
  templateUrl: './building-progress-export-remaining-budget.component.html',
  styleUrls: ['./building-progress-export-remaining-budget.component.scss']
})

export class BuildingProgressExportRemainingBudgetComponent extends UnsubscribeComponent {
  protected exportForm = new FormGroup({
    exportZeroRemainingBudgetItems: new FormControl<boolean>(false, Validators.required),
    isNotSingleSheetExport: new FormControl<boolean>(true, Validators.required),
  });

  get isContractor(): boolean {
    return this.permissionHelper.isContractor;
  }

  constructor(
    private modalRef: KrosModalRef<{ exportModel: BuildingProgressRemainingBudgetModel }>,
    private krosModalService: KrosModalService,
    private projectsDispatcherService: ProjectsDispatchersService,
    private appInsightsService: AppInsightsBaseService,
    private permissionHelper: PermissionHelper
  ) {
    super();
    this.exportForm.controls.exportZeroRemainingBudgetItems.setValue(this.modalRef.data.exportModel.exportZeroRemainingBudgetItems);
    this.exportForm.controls.isNotSingleSheetExport.setValue(!this.modalRef.data.exportModel.isSingleSheetExport);
  }

  onSubmit(): void {
    this.sendAnalyticsNotification();
    this.modalRef.submit(
      {
        exportModel: {
          exportZeroRemainingBudgetItems: this.exportForm.value.exportZeroRemainingBudgetItems,
          isSingleSheetExport: !this.exportForm.value.isNotSingleSheetExport,
          projectId: this.modalRef.data.exportModel.projectId,
          subcontractorId: this.modalRef.data.exportModel.subcontractorId
        }
      }
    );
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  onHeaderDataEdit(): void {
    this.subs.sink = openHeaderSettingsDialog(
      this.krosModalService,
      this.projectsDispatcherService,
      this.permissionHelper,
      this.appInsightsService,
      this.modalRef.data.exportModel.projectId,
      this.isContractor,
      this.modalRef.data.exportModel.subcontractorId
    );
  }

  private sendAnalyticsNotification(): void {
    this.appInsightsService.trackEvent(`${getAppInsightsPrefix(this.isContractor)}export-remaining-budget`);
  }
}
