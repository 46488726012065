import { createAction, props } from '@ngrx/store';

import {
  BuildingProgressCommentModel, CommentPositionModel
} from '../../building-progress/modules/comments-panel/comments-panel/comment.model';

export const loadCommentsStart = createAction(
  '[BuildingProgress] Load comments start', props<{ projectId: number, contractorId?: number }>());
export const loadCommentsSuccess = createAction(
  '[BuildingProgress] Load comments success', props<{ comments: BuildingProgressCommentModel[] }>());
export const loadCommentsError = createAction('[BuildingProgress] Load comments error', props<{ error: any }>());

export const loadCommentPositionsStart = createAction(
  '[BuildingProgress] Load comment positions start', props<{ projectId: number, contractorId?: number }>());
export const loadCommentPositionsSuccess = createAction(
  '[BuildingProgress] Load comment positions success', props<{ commentPositions: CommentPositionModel[] }>());
export const loadCommentPositionsError = createAction('[BuildingProgress] Load comment positions error', props<{ error: any }>());

export const createCommentStart = createAction(
  '[BuildingProgress] Create comment start', props<{ comment: BuildingProgressCommentModel, contractorId?: number }>());
export const createCommentSuccess = createAction(
  '[BuildingProgress] Create comment success', props<{ comment: BuildingProgressCommentModel }>());
export const createCommentError = createAction('[BuildingProgress] Create comment error', props<{ error: any }>());

export const updateCommentStart = createAction(
  '[BuildingProgress] Update comment start', props<{ comment: BuildingProgressCommentModel }>());
export const updateCommentSuccess = createAction(
  '[BuildingProgress] Update comment success', props<{ comment: BuildingProgressCommentModel }>());
export const updateCommentError = createAction('[BuildingProgress] Update comment error', props<{ error: any }>());

export const deleteCommentStart = createAction(
  '[BuildingProgress] Delete comment start', props<{ projectId: number, commentId: number, contractorId?: number }>());
export const deleteCommentSuccess = createAction('[BuildingProgress] Delete comment success', props<{ commentId: number }>());
export const deleteCommentError = createAction('[BuildingProgress] Delete comment error', props<{ error: any }>());

export const publishCommentsStart = createAction(
  '[BuildingProgress] Publish comment start', props<{ projectId: number, contractorId?: number }>());
export const publishCommentsSuccess = createAction('[BuildingProgress] Publish comment success');
export const publishCommentsError = createAction('[BuildingProgress] Publish comment error', props<{ error: any }>());
