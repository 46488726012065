import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { TranslateModule } from '@ngx-translate/core';

import { TimelineModule, TimelineType } from '@kros-sk/ssw-shared-legacy';

import { DashboardPricesDto } from '../../../models/summary-dashboard.model';
import { environment } from '../../../../../environments/environment';

@Component({
  standalone: true,
  selector: 'app-building-progress-pie-chart',
  templateUrl: './building-progress-pie-chart.component.html',
  styleUrls: ['./building-progress-pie-chart.component.scss'],
  imports: [
    CommonModule,
    TimelineModule,
    NgxChartsModule,
    TranslateModule
  ]
})
export class BuildingProgressPieChartComponent {
  viewPie: [number, number] = [200, 200];
  timelineType = TimelineType;

  @Input() colorScheme: any;
  @Input() data: DashboardPricesDto;
  @Input() dataGraph: any[];
  @Input() digitsInfo: string;
  @Input() isDataLoaded: boolean;
  @Input() title: string;
  @Input() totalPriceLabel: string;
  @Input() currencySymbol: string;

  get appLocation(): string {
    return environment.location;
  }
}
