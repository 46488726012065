<div
  *ngIf="!(isProcessing$ | async) else isProcessing"
  class="budget-create-wrapper"
>
  <h4>{{'BUILDING_PROGRESS.IS_EMPTY' | translate}}</h4>

  <ng-container *ngrxLet="selectors.budgetHasItems$ as hasBudget">
    <ng-container *ngIf="hasBudgetAccess else emptyContentUseK4">
      <div class="buttons">
        <button
          class="btn btn-outline-primary"
          (click)="onStartBudgetCreation(hasBudget)"
          id="uploadConstruction"
          data-test="upload-construction-button"
        >
          <i class="material-icons">add_business</i><br>

          <span [innerHTML]="hasBudget ? ('BUILDING_PROGRESS.UPLOAD_CONSTRUCTION' | translate | safeHtml) : ('BUILDING_PROGRESS.CREATE_BUDGET' | translate | safeHtml)"></span>
        </button>
      </div>

      <div
        *ngIf="!hasBudget"
        class="empty-budget"
        data-test="empty-content"
      >
        <p>{{ 'BUILDING_PROGRESS.EMPTY_CONTENT' | translate }}&nbsp;{{emptyContentDescription | translate}}</p>
      </div>
    </ng-container>

    <div class="link">
      <button
        *ngIf="canShowOpenDemoLink()"
        class="btn btn-link no-selection"
        (click)="onOpenDemo()"
      >{{ 'BUILDING_PROGRESS.TRY_IN_DEMO' | translate }}</button>
    </div>
  </ng-container>
</div>

<ng-template #isProcessing>
  <kros-timeline [type]="timelineType.Table"></kros-timeline>
</ng-template>

<ng-template #emptyContentUseK4>
  <p class="empty-content use-k4">
    <span class="text-center">
      {{ 'BUILDING_PROGRESS.EMPTY_CONTENT_K4' | translate }}
      <a
        target="_blank"
        class="btn-link"
        href="{{emptyContentLink}}"
      >{{ emptyContentDescription | translate }}</a>
    </span>
  </p>
</ng-template>
