import { inject, Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, filter, tap } from 'rxjs/operators';
import { concatLatestFrom } from '@ngrx/operators';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import {
  BudgetItemPositionType,
  BuildingObjectType,
  isBuildingObjectTypeChangeSheet,
  isConstruction,
  ToastService,
  TranslateService
} from '@kros-sk/ssw-shared-legacy';
import {
  budgetItemsSharedActions,
  budgetSharedActions,
  BudgetSharedSelectorsService,
  buildingObjectsSharedActions,
  getItemPosition,
  showErrorToast
} from '@kros-sk/ssw-budget/shared/data-access/store';
import {
  BudgetObjectsUpload,
  BuildingObject,
  MoveItemsPositions
} from '@kros-sk/ssw-budget/shared/data-access/models';

import * as actions from './building-objects.actions';
import { BudgetItemOperationType } from '../models';
import { BuildingObjectsSelectorsService } from './building-objects-selectors.service';
import { BuildingObjectsService } from './building-objects.service';
import { BuildingObjectsTableService } from '../../features';

@Injectable()
export class BuildingObjectsEffects {

  private actions$ = inject(Actions);
  private budgetService = inject(BuildingObjectsService);
  private selectors = inject(BuildingObjectsSelectorsService);
  private sharedSelectors = inject(BudgetSharedSelectorsService);
  private buildingObjectsTableService = inject(BuildingObjectsTableService);
  private toastService = inject(ToastService);
  private translateService = inject(TranslateService);
  private appInsightsService = inject(AppInsightsBaseService);

  editBudgetItemInProgress$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(budgetItemsSharedActions.editBudgetItemInProgress),
      filter((payload) => payload.isBuildingObject),
      concatLatestFrom(() => this.selectors.buildingObjects$),
      map(([payload, buildingObjects]) =>
        budgetItemsSharedActions.editBudgetItemSuccess({
          changedBudgetItems: payload.changedBudgetItems,
          storedAction: {
            item: payload.item,
            propertyName: payload.propertyName,
            propertyValue: payload.propertyValue,
            isBuildingObject: payload.isBuildingObject,
            hierarchyItems: payload.hierarchyItems
          },
          isUndoRedoOperation: !!payload.isUndoRedoOperation,
          isUndo: payload.isUndo,
          oldValue: buildingObjects.find(i => i.id === payload.item.id)[payload.propertyName],
          changedAmountCalculationType: false
        })),
    );
  });

  setBudget$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(budgetSharedActions.setBudget),
      tap(() => this.buildingObjectsTableService.onResetState())
    );
  }, { dispatch: false });

  loadBuildingObjects$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingObjectsSharedActions.loadBuildingObjectsStart),
      concatLatestFrom(() => [this.sharedSelectors.projectId$, this.sharedSelectors.budgetId$]),
      switchMap(([payload, projectId, budgetId]) =>
        this.budgetService.getBuildingObjects(projectId, budgetId).pipe(
          map(objects =>
            buildingObjectsSharedActions.loadBuildingObjectsSuccess({ objects, preventForceFocus: payload.preventForceFocus })),
          catchError(error => of(buildingObjectsSharedActions.loadBuildingObjectsError({ error })))
        ))
    );
  });

  loadBuildingObjectsSuccess$: Observable<void> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingObjectsSharedActions.loadBuildingObjectsSuccess),
      concatLatestFrom(() => this.selectors.buildingObjects$),
      filter(([payload, buildingObjects]) => buildingObjects.length > 0 && !payload.preventForceFocus),
      map(([payload, buildingObjects]) => this.buildingObjectsTableService.onLoaded(buildingObjects))
    );
  }, { dispatch: false });

  createBuildingObject$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingObjectsSharedActions.createCustomBuildingObjectStart),
      concatLatestFrom(() => [this.sharedSelectors.projectId$, this.sharedSelectors.budgetId$]),
      switchMap(([payload, projectId, budgetId]) => this.budgetService.createBuildingObject(projectId, budgetId, payload.createData).pipe(
        map(changedBudgetItems => buildingObjectsSharedActions.createCustomBuildingObjectSuccess({ changedBudgetItems })),
        catchError(error => [buildingObjectsSharedActions.createCustomBuildingObjectError({ error })])))
    );
  });

  createCustomBuildingObjectSuccess$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingObjectsSharedActions.createCustomBuildingObjectSuccess),
      concatLatestFrom(() => [this.selectors.buildingObjects$, this.selectors.selectBuildingObjectAfterCreate$]),
      filter(([payload]) => payload.changedBudgetItems.createdItems.length > 0),
      switchMap(([payload, buildingObjects, selectBuildingObjectAfterCreate]) => {
        const toSelect = buildingObjects.find(p => p.id === payload.changedBudgetItems.createdItems[0].tableItem.id);
        this.buildingObjectsTableService.itemCreated(toSelect);
        return selectBuildingObjectAfterCreate
          ? [
            buildingObjectsSharedActions.selectBuildingObjectInBudgetAfterCreate({
              buildingObject: toSelect,
              parentIds: this.buildingObjectsTableService.getParentIds(buildingObjects, toSelect.parentId)
            }),
            actions.selectBuildingObject({ buildingObject: toSelect })
          ]
          : [actions.selectBuildingObject({ buildingObject: toSelect })];
      })
    );
  });

  moveBuildingObjectsSuccess$: Observable<any> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingObjectsSharedActions.moveBuildingObjectsSuccess),
      filter(p => !p.isUndoRedoOperation && p.changedBudgetItems.createdItems.length > 0),
      map(payload => this.buildingObjectsTableService.itemMoved(payload.changedBudgetItems.createdItems[0].tableItem))
    );
  }, { dispatch: false });

  moveBuildingObjects$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingObjectsSharedActions.moveBuildingObjectsStart),
      concatLatestFrom(() => [this.sharedSelectors.projectId$, this.selectors.buildingObjects$, this.sharedSelectors.budgetId$]),
      switchMap(([payload, projectId, buildingObjects, budgetId]) => this.budgetService.moveBuildingObject(
        projectId,
        budgetId,
        payload.currentItem,
        payload.positionType,
        payload.item,
        payload.isUndo ? payload.buildingObjects : []
      ).pipe(
        map(changedBudgetItems => {
          const objectIndex = buildingObjects.findIndex(item => item.id === payload.item.id);
          const sourceDataPositions: MoveItemsPositions<BuildingObject> = new Map(
            [getItemPosition(buildingObjects, objectIndex)]
          );

          return buildingObjectsSharedActions.moveBuildingObjectsSuccess(
            {
              changedBudgetItems,
              sourceDataPositions,
              storedAction: {
                currentItem: payload.currentItem,
                positionType: payload.positionType,
                item: payload.item
              },
              isUndoRedoOperation: payload.isUndoRedoOperation,
              isUndo: payload.isUndo,
              buildingObjects
            });
        }),
        catchError(error => {
          showErrorToast(
            isBuildingObjectTypeChangeSheet(payload.currentItem) ? 'BUDGET.ERROR.MOVING_CHANGE_SHEETS' : 'BUDGET.ERROR.MOVING_OBJECTS',
            this.toastService,
            this.translateService);
          return of(buildingObjectsSharedActions.moveBuildingObjectsError({ error }));
        })
      ))
    );
  });

  moveBuildingObjectLevelUp$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.moveBuildingObjectLevelUp),
      concatLatestFrom(() => [this.selectors.selectedBuildingObject$, this.selectors.buildingObjects$]),
      map(([_, selectedBuildingObject, buildingObjects]) =>
        [selectedBuildingObject, buildingObjects.find(p => p.id === selectedBuildingObject.parentId)]),
      filter(([selectedBuildingObject, parent]) => parent && !isConstruction(parent)),
      map(([selectedBuildingObject, parent]) => {
        return buildingObjectsSharedActions.moveBuildingObjectsStart({
          currentItem: parent,
          positionType: BudgetItemPositionType.AfterCurrentItem,
          item: selectedBuildingObject,
          buildingObjects: []
        });
      })
    );
  });

  moveBuildingObjectLevelDown$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.moveBuildingObjectLevelDown),
      concatLatestFrom(() => this.selectors.selectedBuildingObject$),
      filter(([payload, selectedBuildingObject]) => payload.itemBefore && payload.itemBefore.id !== selectedBuildingObject.parentId),
      map(([payload, selectedBuildingObject]) => {
        return buildingObjectsSharedActions.moveBuildingObjectsStart({
          currentItem: payload.itemBefore,
          positionType: BudgetItemPositionType.AsChild,
          item: selectedBuildingObject,
          buildingObjects: payload.buildingObjects
        });
      })
    );
  });

  uploadBuildingObjectsStart$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.uploadBuildingObjectsStart),
      concatLatestFrom(() => [this.sharedSelectors.projectId$, this.sharedSelectors.budgetId$]),
      switchMap(([payload, projectId, budgetId]) => {
        return this.budgetService.uploadBuildingObjects(projectId, budgetId, payload.buildingObjectIds).pipe(
          map((result) => actions.uploadBuildingObjectsSuccess({ buildingObjectsUploadDates: result })),
          catchError(error => of(actions.uploadBuildingObjectsError({ error })))
        );
      })
    );
  });

  uploadBuildingObjectsSuccess$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.uploadBuildingObjectsSuccess),
      tap((payload) => this.trackBuildingObjectsUpload(payload.buildingObjectsUploadDates)),
      map(() => actions.loadBuildingObjectsStateStart())
    );
  });

  loadBuildingObjectStates$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadBuildingObjectsStateStart),
      concatLatestFrom(() => [this.sharedSelectors.projectId$, this.selectors.buildingObjects$]),
      delay(2000),
      switchMap(([_, projectId, buildingObjects]) => {
        const hierarchyCodes = buildingObjects.map(p => p.hierarchyCode);
        const budgetApprovalStates$ = this.budgetService.getBudgetApprovalBuildingObjectsState(projectId, hierarchyCodes);
        const buildingProgressStates$ = this.budgetService.getBuildingProgressBuildingObjectsState(projectId, hierarchyCodes);
        return forkJoin([of(buildingObjects), budgetApprovalStates$, buildingProgressStates$]);
      }),
      map(([buildingObjects, budgetApprovalStates, buildingProgressStates]) => {
        const objectsWithState = buildingObjects.map(buildingObject => {
          const budgetApprovalState = budgetApprovalStates.find(s => s.hierarchyCode === buildingObject.hierarchyCode);
          const buildingProgressState = buildingProgressStates.find(s => s.hierarchyCode === buildingObject.hierarchyCode);
          const isBudgetApprovalState = budgetApprovalState &&
            budgetApprovalState.isExisting &&
            budgetApprovalState.budgetItemEditHistory &&
            budgetApprovalState.budgetItemEditHistory.operationType !== BudgetItemOperationType.HierarchyCompletionCreated;
          const isBuildingProgressState = buildingProgressState &&
            buildingProgressState.isExisting &&
            buildingProgressState.budgetItemEditHistory &&
            buildingProgressState.budgetItemEditHistory.operationType !== BudgetItemOperationType.HierarchyCompletionCreated;
          return {
            ...buildingObject,
            state: isBuildingProgressState
              ? 'BP'
              : (isBudgetApprovalState
                ? 'BA'
                : ''),
            email: isBuildingProgressState
              ? buildingProgressState.budgetItemEditHistory.email
              : (isBudgetApprovalState
                ? budgetApprovalState.budgetItemEditHistory.email
                : ''),
            dateChanged: isBuildingProgressState
              ? buildingProgressState.budgetItemEditHistory.dateChanged
              : (isBudgetApprovalState
                ? budgetApprovalState.budgetItemEditHistory.dateChanged
                : null)
          };
        });
        return actions.loadBuildingObjectsStateSuccess({ buildingObjects: objectsWithState });
      }),
      catchError(error => of(buildingObjectsSharedActions.loadBuildingObjectsError({ error })))
    );
  });

  loadBuildingObjectsStateSuccess$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadBuildingObjectsStateSuccess),
      map(() => actions.unsetAreBuildingObjectsFullyLoaded())
    );
  });

  patchBuildingObjectsItemType$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.patchBuildingObjectsItemTypeStart),
      concatLatestFrom(() => [this.sharedSelectors.projectId$, this.sharedSelectors.budgetId$]),
      switchMap(([payload, projectId, budgetId]) => {
        const buildingObjectTypeMap = payload.buildingObjects.reduce((map, b) => {
          map[b.id] = b.itemType === 'ZL'
            ? BuildingObjectType.ChangeSheet
            : BuildingObjectType.Normal;
          return map;
        }, {} as {[key:number]:BuildingObjectType});
        return this.budgetService.setItemTypes(projectId, budgetId, { buildingObjectTypeMap }).pipe(
          map(changedBudgetItems => {
            return budgetItemsSharedActions.editBudgetItemInProgress({
              changedBudgetItems,
              item: payload.item,
              propertyName: 'itemType',
              propertyValue: null,
              isBuildingObject: true,
              hierarchyItems: payload.buildingObjects,
              isUndoRedoOperation: payload.isUndoRedoOperation,
              isUndo: payload.isUndo
            });
          }));
      }));
  });

  private trackBuildingObjectsUpload (buildingObjectsUpload: BudgetObjectsUpload): void {
    if (buildingObjectsUpload.createdItems?.length > 0) {
      this.appInsightsService.trackEvent(
        'P-upload-building-object-create',
        { operationCount: buildingObjectsUpload.createdItems.length.toString(), statisticPropertyNames: 'operationCount' });
    }
    if (buildingObjectsUpload.editedItems?.length > 0) {
      this.appInsightsService.trackEvent(
        'P-upload-building-object-edit',
        { operationCount: buildingObjectsUpload.editedItems.length.toString(), statisticPropertyNames: 'operationCount' });
    }
  }
}
