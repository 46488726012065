import { Inject, Injectable } from '@angular/core';

import { BehaviorSubject, map, Observable, share } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

@Injectable({
  providedIn: 'root'
})
export class BlazorLoadingService {

  private isLoadedSubject: BehaviorSubject<BlazorLoadingState>;

  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private appInsights: AppInsightsBaseService
  ) {
    this.isLoadedSubject = new BehaviorSubject<BlazorLoadingState>(BlazorLoadingState.Loading);
  }

  get isLoaded$(): Observable<boolean> {
    return this.isLoadedSubject.asObservable().pipe(
      map(state => state === BlazorLoadingState.Loaded),
      share()
    );
  }

  get finishedLoading$(): Observable<boolean> {
    return this.isLoadedSubject.asObservable().pipe(
      map(state => state === BlazorLoadingState.Loaded || state === BlazorLoadingState.Failed),
      share()
    );
  }

  loadBlazorRecalculate(): void {
    if (document.getElementById('blazorScript')) return;
    const blazorScript = document.createElement('script');
    blazorScript.id = 'blazorScript';
    blazorScript.type = 'text/javascript';
    blazorScript.defer = true;
    blazorScript.setAttribute('autostart', 'false');
    blazorScript.src = this.appConfig.webAssembly.blazorWebassemblyUrl;
    blazorScript.onload = (): void => {
      const blazorStartedEventHandler = (): void => {
        this.isLoadedSubject.next(BlazorLoadingState.Loaded);
        window.removeEventListener('blazorStarted', blazorStartedEventHandler);
      };
      const blazorStartErrorEventHandler = (): void => {
        this.appInsights.trackException(new Error('Blazor starting error'), 'BlazorLoadingService');
        this.isLoadedSubject.next(BlazorLoadingState.Failed);
        console.error('Blazor starting error');
        window.removeEventListener('blazorStartError', blazorStartErrorEventHandler);
      };
      window.addEventListener('blazorStarted', blazorStartedEventHandler);
      window.addEventListener('blazorStartError', blazorStartErrorEventHandler);

      const loadBlazor = document.createElement('script');
      loadBlazor.text = `Blazor.start({loadBootResource: function(type, name, defaultUri, integrity) {
        return ${this.appConfig.webAssembly.blazorFrameworkUrl} + name;}}).then(function () {
          const blazorStartedEvent = new Event('blazorStarted');
          window.dispatchEvent(blazorStartedEvent);
        }).catch(function () {
          const blazorStartErrorEvent = new Event('blazorStartError');
          window.dispatchEvent(blazorStartErrorEvent);
        })`;
      document.body.appendChild(loadBlazor);
    };


    blazorScript.onerror = (err): void => {
      this.appInsights.trackException(new Error('Blazor loading error'), 'BlazorLoadingService', {errorDetails: err});
      console.error('Blazor loading error');
      this.isLoadedSubject.next(BlazorLoadingState.Failed);
    };


    document.body.appendChild(blazorScript);
  }
}

enum BlazorLoadingState {
  Loading,
  Loaded,
  Failed
}
