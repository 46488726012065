import { Component } from '@angular/core';

import { KrosModalRef } from '@kros-sk/components';

import { SharingPermission } from '../../models/sharing-permission.interface';

@Component({
  selector: 'kros-user-sharing-permission-modal',
  templateUrl: './user-sharing-permission-modal.component.html',
  styleUrls: ['./user-sharing-permission-modal.component.scss']
})
export class UserSharingPermissionModalComponent {
  permissionList: SharingPermission[] = [];
  selectedPermission: SharingPermission;

  constructor(
    private modalRef: KrosModalRef
  ) {
    this.permissionList = this.modalRef.data.permissionList;
    this.selectedPermission = this.modalRef.data.selectedPermission;
  }

  isPermissionSelected(permission: SharingPermission): boolean {
    return this.selectedPermission.permissions.permission === permission.permissions.permission
      && this.selectedPermission.permissions.additionalPermission === permission.permissions.additionalPermission;
  }

  onPermissionChanged(permission: SharingPermission): void {
    this.modalRef.submit(permission);
  }
}
