import { EnvironmentProviders, makeEnvironmentProviders, ModuleWithProviders, NgModule } from '@angular/core';

import { ALLOWED_ANALYTICS_ACTIONS, KrosAnalyticsService } from './services/kros-analytics.service';
import { AnalyticsModalTitleDirective } from './directives/analytics-modal-title.directive';
import { AnalyticsOnClickDirective } from './directives/analytics-on-click.directive';

const DECLARATIONS = [
  AnalyticsOnClickDirective,
  AnalyticsModalTitleDirective,
];

export function provideKrosAnalytics(allowedActions: string[] = []): EnvironmentProviders {
  return makeEnvironmentProviders([
    KrosAnalyticsService,
    {
      provide: ALLOWED_ANALYTICS_ACTIONS,
      useValue: allowedActions,
    }
  ]);
}

/**
 * Application Insights App usage Analytics
 */
@NgModule({
  declarations: [
    ...DECLARATIONS,
  ],
  imports: [],
  exports: [
    ...DECLARATIONS,
  ],
})
export class KrosAnalyticsModule {
  static forRoot(allowedActions: string[] = []): ModuleWithProviders<KrosAnalyticsModule> {
    return {
      ngModule: KrosAnalyticsModule,
      providers: [
        KrosAnalyticsService,
        {
          provide: ALLOWED_ANALYTICS_ACTIONS,
          useValue: allowedActions,
        }
      ],
    };
  }
}
