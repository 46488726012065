<form id="form"
      *ngIf="companyForm"
      [formGroup]="companyForm">
  <div class="company-info">
    <kros-company-info [enableExtendedValidations]="false"
                       [showAllErrors]="showAllErrors"
                       formControlName="companyInfo"
                       (registrationCourtTextTranslations)="setRegistrationCourtText($event)">
    </kros-company-info>
  </div>

  <div class="warning"
       *ngIf="errorMessage !== '' && companyForm.invalid">
    <ngb-alert [type]="'danger'"
               [dismissible]="false">
      <strong>Upozornenie!</strong><br> {{ errorMessage }}
    </ngb-alert>
  </div>

  <div class="button">
    <button class="btn btn-primary"
            data-test="button-submit-create-company"
            id="submitButton"
            (click)="submit()"
            type="button">
      Dokončiť
    </button>
  </div>
</form>
