<div class="kros-modal-header">
  <h4 class="modal-title">{{ 'BUDGET_APPROVAL.EXPORT.CHANGE_APPROVAL_DETAILS' | translate }}</h4>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onCloseClick()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div class="kros-modal-body">
  <div class="form-container">
    <form [formGroup]="exportForm">
      <div
        class="export-group"
      >
        <div class="flex margin">
          <div class="form-group checkbox no-margin">
            <input
              id="isSummaryIncluded"
              type="checkbox"
              formControlName="isSummaryIncluded"
              data-test="is-summary-included-checkbox"
            >
            <label
              class="no-selection"
              for="isSummaryIncluded"
            >{{ 'BUDGET_APPROVAL.EXPORT.EXPORT_SUMMARY_CHANGE_SHEETS' | translate }}</label>
          </div>

          <div class="form-group sub-group select no-margin">
            <app-export-range
              [selectedIds]="exportModel.exportSummaryViewModel.changeSheetIds"
              [budgetApprovalData]="budgetApprovalData"
              (changeSheetIdsSelected)="onSummaryChangeSheetsSelected($event)"
            ></app-export-range>
          </div>
        </div>

        <div
          class="options-text"
          container="body"
          (click)="openSummarySettingsModal()"
          data-test="open-summary-settings-button"
        >{{'COMMON.SETTINGS' | translate | lowercase}}
        </div>
      </div>

      <div
        class="export-group"
        [class.checked]="getChangeSheetsIncludedControlValue()"
      >
        <div class="flex margin">
          <div class="form-group checkbox no-margin">
            <input
              id="isChangeSheetIncluded"
              type="checkbox"
              formControlName="isChangeSheetIncluded"
              data-test="is-change-sheet-included-checkbox"
            >
            <label
              class="no-selection"
              for="isChangeSheetIncluded"
            >{{ 'BUDGET_APPROVAL.EXPORT.EXPORT_CHANGE_SHEETS' | translate }}</label>
          </div>

          <div
            *ngIf="!singleBudgetItemExport"
            class="form-group sub-group select no-margin"
          >
            <app-export-range
              [selectedIds]="exportModel.exportChangeSheetViewModel.changeSheetIds"
              [budgetApprovalData]="budgetApprovalData"
              (changeSheetIdsSelected)="onChangeSheetsSelected($event)"
            ></app-export-range>
          </div>
        </div>

        <div
          class="options-text"
          container="body"
          (click)="openChangeSheetsSettingsModal()"
          data-test="open-change-sheets-settings-button"
        >{{'COMMON.SETTINGS' | translate | lowercase}}
        </div>
      </div>

      <div class="export-group flex no-padding-top-bottom">
        <div>
          <div
            class="form-group checkbox elements-width"
            [tooltipDisabled]="canExportLogo"
            [krosTooltip]="'BUILDING_PROGRESS.EXPORT_LOGO_NOT_EXIST' | translate"
          >
            <input
              id="isLogoIncluded"
              type="checkbox"
              formControlName="isLogoIncluded"
              data-test="is-logo-included-checkbox"
            >

            <label
              class="no-selection"
              for="isLogoIncluded"
            >{{'BUILDING_PROGRESS.EXPORT_USE_LOGO' | translate}}</label>
          </div>

          <div
            class="form-group checkbox elements-width"
            [tooltipDisabled]="canExportStamp"
            [krosTooltip]="'BUILDING_PROGRESS.EXPORT_STAMP_NOT_EXIST' | translate"
          >
            <input
              id="isStampIncluded"
              type="checkbox"
              formControlName="isStampIncluded"
              data-test="is-stamp-included-checkbox"
            >

            <label
              class="no-selection"
              for="isStampIncluded"
            >{{'BUILDING_PROGRESS.EXPORT_USE_STAMP' | translate}}</label>
          </div>
        </div>

        <div
          class="options-text"
          (click)="onHeaderDataEdit()"
          data-test="edit-header-data"
        >{{'PROJECTS.DETAIL.HEADER_DATA_SETTINGS' | translate}}
        </div>
      </div>

      <div class="note">
        <p
          [innerHTML]="('BUILDING_PROGRESS.EXPORT_LOGO_STAMP_INFO' | translate | formatString: '<i class=\'material-icons\'>settings</i>') | safeHtml">
        </p>
      </div>
    </form>
  </div>
</div>

<div class="kros-modal-footer">
  <button
    class="btn btn-primary btn-submit"
    id="ga-export"
    type="button"
    data-test="export-button"
    [disabled]="!isOneCategorySelected()"
    (click)="export()"
  >{{'BUILDING_PROGRESS.EXPORT_ACTION' | translate}}</button>
</div>
