import { Component, Input } from '@angular/core';

@Component({
  selector: 'kros-spin-loader',
  templateUrl: './spin-loader.component.html',
  styleUrls: ['./spin-loader.component.scss']
})
export class SpinLoaderComponent {
  @Input() title: string;
  @Input() description: string;

  constructor() { }
}
