<div class="kros-modal-header">
  <h4 class="kros-modal-title">{{'BUILDING_PROGRESS.EXPORT_LIST_OF_DONE_WORKS' |
    translate}}</h4>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onCloseClick()"
    data-test="completed-works-settings-close-button"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div class="kros-modal-body">
  <form [formGroup]="settingsForm">
    <div class="form-container">
      <div class="form-group checkbox">
        <input
          id="areCompletedWorksZeroItemsIncluded"
          type="checkbox"
          formControlName="areCompletedWorksZeroItemsIncluded"
          data-test="are-completed-works-zero-items-included-checkbox"
        >
        <label for="areCompletedWorksZeroItemsIncluded">{{'BUILDING_PROGRESS.EXPORT_LIST_OF_DONE_WORKS_ZERO_ITEMS_ALSO'
          | translate}}</label>
      </div>

      <div class="form-group checkbox">
        <input
          id="isBoqIncluded"
          type="checkbox"
          formControlName="isBoqIncluded"
          data-test="is-boq-included-checkbox"
        >
        <label for="isBoqIncluded">{{'BUILDING_PROGRESS.EXPORT_LIST_OF_DONE_WORKS_BILL_OF_QUANTITIES_ALSO'
          | translate}}</label>
      </div>

      <div class="form-group checkbox">
        <input
          id="areDetailsIncluded"
          type="checkbox"
          formControlName="areDetailsIncluded"
          data-test="are-details-included-checkbox"
        >
        <label for="areDetailsIncluded">{{'BUILDING_PROGRESS.EXPORT_COMPLETED_WORKS_DETAILS'
          | translate}}</label>
      </div>

      <div class="form-group checkbox">
        <input
          id="areNotesIncluded"
          type="checkbox"
          formControlName="areNotesIncluded"
          data-test="are-notes-included-checkbox"
        >
        <label for="areNotesIncluded">{{'BUILDING_PROGRESS.EXPORT_COMPLETED_WORKS_NOTES'
          | translate}}</label>
      </div>

      <div class="form-group checkbox">
        <input
          id="isCompletedWorksObjects"
          type="checkbox"
          formControlName="isCompletedWorksObjects"
          data-test="is-completed-works-objects-checkbox"
        >
        <label for="isCompletedWorksObjects">{{'BUILDING_PROGRESS.EXPORT_PER_OBJECTS' | translate}}</label>
      </div>

      <div class="form-group checkbox">
        <input
          id="areColorCodesIncluded"
          type="checkbox"
          formControlName="areColorCodesIncluded"
          data-test="are-color-codes-included-checkbox"
        >
        <label for="areColorCodesIncluded">{{'BUILDING_PROGRESS.EXPORT_COLOR_CODES' | translate}}</label>
      </div>

      <div class="form-group checkbox">
        <input
          id="displayAllDecimalPlaces"
          type="checkbox"
          formControlName="displayAllDecimalPlaces"
          data-test="display-all-decimal-places-checkbox"
        >
        <label for="displayAllDecimalPlaces">{{'BUILDING_PROGRESS.EXPORT_DISPLAY_ALL_DECIMAL_PLACES' |
          translate}}</label>
      </div>

      <app-export-colums-selection
        [form]="settingsForm"
        [columns]="columns"
      ></app-export-colums-selection>
    </div>
  </form>
</div>

<div class="kros-modal-footer">
  <button
    class="btn btn-primary btn-submit"
    type="button"
    (click)="onSubmit()"
    data-test="completed-works-settings-confirm-button"
  >{{'COMMON.APPLY'| translate}}</button>
</div>
