import { createAction, props } from '@ngrx/store';

import { DocumentListInfoModel } from '../../document-explorer';
import { DocumentVersionDeleteModel, FolderModel } from '../models';

export const createFolderStart = createAction('[Documents] Create folder start', props<{ folder: FolderModel }>());
export const createFolderSuccess = createAction('[Documents] Create folder success', props<{ projectId: number }>());
export const createFolderError = createAction('[Documents] Create folder error', props<{ error: any }>());

export const deleteDocument = createAction('[Documents] Delete document', props<{ projectId: number, documentId: string }>());
export const deleteDocumentSuccess = createAction('[Documents] Delete document success', props<{ documentId: string }>());

export const deleteDocuments = createAction(
  '[Documents] Delete multiple documents',
  props<{ projectId: number, documents: DocumentListInfoModel[] }>()
);
export const deleteDocumentsSuccess = createAction('[Documents] Delete multiple documents success', props<{ documentIds: string[] }>());

export const deleteDocumentVersion = createAction('[Documents] Delete version of document',
  props<{ projectId: number, documentId: string, documentVersionId: string }>());
export const deleteDocumentVersionSuccess = createAction('[Documents] Delete version of document success',
  props<{ documentId: string, documentVersionId: string, documentVersionDelete: DocumentVersionDeleteModel }>());
export const deleteDocumentVersionError = createAction('[Documents] Delete version of document error', props<{ error: any }>());

export const softDeleteDocumentVersion = createAction(
  '[Documents] Soft delete document version',
  props<{ projectId: number, documentId: string, documentVersionId: string }>()
);
export const softDeleteDocumentVersionSuccess = createAction(
  '[Documents] Soft delete document version success',
  props<{ documentId: string, documentVersionId: string }>()
);
export const softDeleteDocumentVersionError = createAction('[Documents] Soft delete document version error', props<{ error: any }>());

export const softDeleteDocument = createAction('[Documents] Soft delete document', props<{ projectId: number, documentId: string }>());
export const softDeleteDocumentSuccess = createAction('[Documents] Soft delete document success', props<{ documentId: string }>());
export const softDeleteDocumentError = createAction('[Documents] Soft delete document error', props<{ error: any }>());

export const softDeleteDocuments = createAction(
  '[Documents] Soft delete multiple documents',
  props<{ projectId: number, documents: DocumentListInfoModel[] }>()
);
export const softDeleteDocumentsSuccess = createAction(
  '[Documents] Soft delete multiple documents success',
  props<{ documents: DocumentListInfoModel[] }>()
);
export const softDeleteDocumentsError = createAction('[Documents] Soft delete multiple documents error', props<{ error: any }>());

export const undoDeleteDocumentVersion = createAction('[Documents] Undo delete document version', props<{ documentVersionId: string }>());

export const undoDeleteDocuments = createAction('[Documents] Undo delete document', props<{ projectId: number, documentIds: string[] }>());

export const renameFolderStart = createAction(
  '[Documents] Rename folder start', props<{ projectId: number, documentId: string, documentName: string }>());
export const renameFolderSuccess = createAction('[Documents] Rename folder success', props<{ documentId: string, documentName: string }>());
export const renameFolderError = createAction('[Documents] Rename folder error', props<{ error: any }>());
