<div
  ngbDropdown
  #dropdown="ngbDropdown"
  autoClose="outside"
>
  <button
    ngbDropdownToggle
    class="btn btn-link ga-ba-filter-column-toggle"
    type="button"
    data-test="filter-column-toggle"
  >
    {{ 'BUDGET_APPROVAL.COLUMN_FILTER.SHOW_COLUMNS' | translate }}
    <i class="material-icons ga-ba-filter-column-toggle">expand_more</i>
  </button>

  <div ngbDropdownMenu>
    <div
      ngbDropdownItem
      class="form-group checkbox"
      *ngFor="let columnGroup of columnGroups"
    >
      <input
        id="check-{{columnGroup.id}}"
        type="checkbox"
        [checked]="columnGroup.checked"
        (change)="addOrRemoveColumnGroupToFilter(columnGroup)"
        data-test="filter-column-check"
      >
      <label for="check-{{columnGroup.id}}">{{ columnGroup.title | translate }}</label>
    </div>
  </div>
</div>
