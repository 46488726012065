import { Pipe, PipeTransform } from '@angular/core';

import { formatString } from '@kros-sk/ssw-cdk';

@Pipe({
  name: 'formatString'
})
export class FormatStringPipe implements PipeTransform {

  constructor() { }

  transform(str: string, val: any | any[]): string {
    if (val instanceof Array) {
      return formatString(str, ...val);
    }

    return formatString(str, val);
  }
}
