import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Inject, Injectable } from '@angular/core';

import { APP_CONFIG } from '@kros-sk/app-config';
import { ApplicationType, EshopCompany, PackageName, PackageType, PurchaseType } from '@kros-sk/models';

@Injectable({
  providedIn: 'root'
})
export class EshopCartLinkService {

  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private sanitizer: DomSanitizer
  ) { }

  getUrl(
    product: ApplicationType,
    packageType: PackageType,
    purchaseType: PurchaseType = PurchaseType.year,
    otherParams: { [key: string]: number | boolean } = null,
    loginAction = true,
    packageName = PackageName.Package,
    companyData?: EshopCompany,
    encryptedCustomerNumber?: string
  ): SafeUrl {
    if (encryptedCustomerNumber) {
      return `${this.appConfig.eShopUrl}/renew?product=${product}&license=${encryptedCustomerNumber}`;
    }

    let url = `${this.appConfig.eShopUrl}/cart?product=${product}&${packageName}=${packageType}&purchaseType=${purchaseType}`;
    if (otherParams) {
      const others = Object.keys(otherParams)
        .filter(key => typeof otherParams[key] === 'number' || 'boolean')
        .reduce((prev, currentKey) => prev.concat(`&${currentKey}=${otherParams[currentKey]}`), '');
      url = url.concat(others);
    }
    if (loginAction) {
      url = url.concat('&action=login');
    }
    if (companyData) {
      const cartCompanyString = JSON.stringify(companyData);
      url = url.concat(`&company=${encodeURIComponent(btoa(this.toBinary(cartCompanyString)))}`);
    }
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  private toBinary(data: string): string {
    const codeUnits = Uint16Array.from({ length: data.length },
      (element, index) => data.charCodeAt(index));
    const charCodes = new Uint8Array(codeUnits.buffer);

    let result = '';

    charCodes.forEach(char => {
      result += String.fromCharCode(char);
    });
    return result;
  }
}
