import { Component } from '@angular/core';

import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

import { AnimateCells } from '../models';
import { IId } from '../../budget/helpers';
import { TableBaseComponent } from './table-base.component';

@Component({ template: '' })
export abstract class GridComponent<TId extends string | number, T extends IId<TId>> extends TableBaseComponent<TId, T> {

  protected abstract get dataComponent(): DxDataGridComponent;

  getItemBeforeSelected(): T {
    const selectedRowIndex = this.dataComponent?.instance.getRowIndexByKey(this.selectedItemId);
    const itemBeforeId = this.dataComponent?.instance.getKeyByRowIndex(selectedRowIndex - 1);
    return this.dataSource.find(p => p.id === itemBeforeId);
  }

  protected focusAndAnimateCells(animateCells: AnimateCells<TId>): void {
    this.select(
      this.dataSource.find(p => p.id === animateCells.cells[0].id),
      true,
      this.dataComponent?.instance.getVisibleColumns().findIndex(p => p.dataField === animateCells.cells[0].propertyName)
    );
    this.animateCells(animateCells.cells);
  }

  protected getNewIdAfterDelete(selectedId: TId): TId {
    const length = this.dataComponent?.instance.getVisibleRows().length;
    let rowIndex = this.dataComponent?.instance.getRowIndexByKey(selectedId);
    rowIndex = rowIndex >= length ? rowIndex - 1 : rowIndex + 1;
    return rowIndex === -1 ? undefined : this.dataComponent?.instance.getKeyByRowIndex(rowIndex);
  }
}
