import { Injectable } from '@angular/core';

import { createFeatureSelector, createSelector, MemoizedSelector, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { DownServiceType, ErrorState, ServiceDownState } from '@kros-sk/models';

import { errorsStateSlice } from './errors-store.module';

const selectErrorsFeature = createFeatureSelector<ErrorState>(errorsStateSlice);

const selectServicesDown = createSelector(
  selectErrorsFeature,
  state => state.serviceDown
);

const selectConcreteServiceDown = (type: DownServiceType): MemoizedSelector<ErrorState, ServiceDownState | null> => createSelector(
  selectServicesDown,
  (services) => services[type] ?? null
);

@Injectable({providedIn: 'root'})
export class ErrorsSelectorService {
  constructor(private store: Store<any>) {
  }

  selectConcreteServiceDown$(type: DownServiceType): Observable<ServiceDownState | null> {
    return this.store.select(selectConcreteServiceDown(type));
  }
}
