<div class="toolbar-actions">
  <div class="actions left">
    <app-period-select-button
      *ngIf="!detail.isMobileSelectionVisible"
      [hasConstructionData]="detail.itemCount > 0"
      [periods]="detail.constructionData?.periods"
      [selectedPeriodId]="buildingProgressSelectorsService.periodId$ | async"
      [searchModel]="detail.currentSearchModel"
      [projectId]="detail.projectId"
      [permission]="permissionHelper.permission"
      [readonly]="licenseHelper.isReadonlyLicense"
      (periodSelected)="periodHelper.onPeriodSelected($event)"
    ></app-period-select-button>

    <ng-container *ngIf="detail.isMobileSelectionVisible">
      <button
        id="selection-close"
        class="btn toolbar-action no-selection gray-toolbar-action ga-selection-close"
        type="button"
        (click)="detail.isMobileSelectionVisible = false"
        data-test="selection-close-button"
      >
        <i class="material-icons-outlined icon-button ga-selection-close">arrow_back_ios</i>
      </button>

      <div
        class="selected-count"
        data-test="selection-count"
      >
        {{ detail.selectedItemIds.size }}
      </div>
    </ng-container>
  </div>

  <div></div>

  <div class="actions right">
    <button
      *ngIf="!detail.isMobileSelectionVisible && searchVisible"
      class="btn toolbar-action no-selection gray-toolbar-action ga-search-show"
      type="button"
      (click)="onSearchClicked()"
      data-test="mobile-navbar-action-search"
    >
      <i class="material-icons-outlined icon-button ga-search-show">search</i>
      <span>{{ 'COMMON.SEARCH' | translate }}</span>
    </button>

    <button
      id="selection"
      *ngIf="!detail.isMobileSelectionVisible && canShowSelection"
      class="btn toolbar-action no-selection gray-toolbar-action ga-selection-show"
      type="button"
      (click)="selectionClicked()"
      data-test="selection-show-button"
    >
      <i class="material-icons-outlined icon-button ga-selection-show">check_box</i>
      <span>{{ 'COMMON.SELECT' | translate }}</span>
    </button>

    <ng-container *ngIf="detail.isMobileSelectionVisible">
      <button
        class="btn toolbar-action no-selection gray-toolbar-action percentage-draw ga-percentage-draw"
        type="button"
        [disabled]="!areItemsSelected"
        (click)="percentageDraw()"
        krosTooltip="{{ 'BUILDING_PROGRESS.DRAW.PERCENTAGE' | translate }}"
        data-test="building-progress-percentage-draw"
      >
        <i class="material-icons icon-button ga-percentage-draw">forward_10</i>
        <span class="toolbar-action-text">{{ 'BUILDING_PROGRESS.DRAW.PERCENTAGE' | translate }}</span>
      </button>

      <button
        class="btn toolbar-action no-selection gray-toolbar-action percentage-complete ga-percentage-complete"
        type="button"
        [disabled]="!areItemsSelected"
        (click)="percentageComplete()"
        krosTooltip="{{ 'BUILDING_PROGRESS.COMPLETE.COMPLETE' | translate }}"
        data-test="building-progress-percentage-complete"
      >
        <i class="material-icons icon-button ga-percentage-complete">insert_chart_outlined</i>
        <span class="toolbar-action-text">{{ 'BUILDING_PROGRESS.COMPLETE.COMPLETE' | translate }}</span>
      </button>
    </ng-container>
  </div>
</div>
