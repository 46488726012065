import { catchError, MonoTypeOperatorFunction, of, throwError } from 'rxjs';

import { ItemSearchFailed, SearchDownMessage } from '@kros-sk/models';

export function searchErrorHandler<T extends ItemSearchFailed>(defaultValue?: T): MonoTypeOperatorFunction<T[]> {
  return source$ => source$.pipe(catchError(error => {
    if (isAllowedSearchErrorCode(error.status)) {
      let response = {hasFailed: true, errorMessage: SearchDownMessage};
      if (defaultValue) {
        response = {
          ...defaultValue,
          ...response
        };
      }
      return of([response] as T[]);
    }
    return throwError(error);
  }));
}

export const trackedSearchErrorCodes = [0, 500, 502, 503, 504];

export function isAllowedSearchErrorCode(code: number): boolean {
  return trackedSearchErrorCodes.includes(code);
}
