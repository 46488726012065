import { ChangeDetectionStrategy, Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { formatNumber, parseNumericValue, roundNumber } from '../../../tools';
import { InputMode } from '../input.interface';
import { KrosInputBase } from '../../inputs.common.base.component';
import { KrosInputComponent } from '../input.component';

@Component({
  selector: 'kros-numeric-input',
  templateUrl: './numeric-input.component.html',
  styleUrls: ['../input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => KrosNumericInputComponent),
      multi: true
    },

    {
      provide: KrosInputBase,
      useExisting: forwardRef(() => KrosNumericInputComponent)
    }
  ]
})
export class KrosNumericInputComponent extends KrosInputComponent implements ControlValueAccessor, OnInit {

  private lastKnownValueOutside: any;

  ngOnInit(): void {
    super.ngOnInit();
    this.setDefaults();
    this.setFormControlFromMainControlValue(this.mainControl.value);
  }

  selectAllText(event: Event): void {
    if (this.options.selectAllOnClick && this.platform.SAFARI) {
      this.input.nativeElement.select();
      event.preventDefault();
    }
  }
  doBlur(event: FocusEvent): void {
    if ((!this.hovered && !this.autocomplete.optionHovered) || this.forceBlur) {
      let value = this.formControl.value;
      if (!this.currentOptions.allowedValues?.includes(value)) {
        let wasNull = false;
        if (value === null) {
          value = 0;
          wasNull = true;
        }
        const numberValue = this.checkNumberMinMax(this.parseNumericValue(value.toString()));
        const newValue = roundNumber(
          numberValue,
          this.currentOptions.numericInput?.maxFractionDigits,
        );

        if (value !== newValue || wasNull) {
          this.handleValueEmits(newValue);
        }
        this.reformatValue(newValue);
      }
      super.doBlur(event);
    } else {
      this.mainControl.markAsTouched();
      this.focused = false;
    }
  }

  protected setFormControlFromMainControlValue(value: any): void {
    if (this.currentOptions.allowedValues?.includes(value)) {
      this.formControl.setValue(value, { emitEvent: false }); // never emit event to avoid cycling
    } else {
      this.reformatValue(value || 0);
    }
    this.lastKnownValueOutside = value;
  }

  protected handleValueEmits(value: any): void {
    let outputValue = value;
    let splitted = [];
    const caretPosition = this.formsService.getCaretPosition(this.input.nativeElement);
    const stringValue = this.hasValue(value) ? value.toString() : '';
    if (!this.currentOptions.allowedValues?.includes(value)) {
      if (stringValue.length && !/^-?[0-9\s]*[,\.]$/i.test(stringValue) && stringValue !== '-') {
        const numberValue = this.checkNumberMinMax(this.parseNumericValue(stringValue));
        const newValue = roundNumber(
          numberValue,
          this.currentOptions.numericInput?.maxFractionDigits,
        );
        if (value !== newValue) {
          splitted = stringValue.replace(/\./gi, ',').replace(/\s/gi, '').split(',');
          outputValue = newValue;
          const formatedValue = formatNumber(
            newValue,
            this.currentOptions.numericInput?.digitsGrouping,
            splitted.length > 1 ?
              Math.min(
                splitted[1].length,
                this.currentOptions.numericInput?.maxFractionDigits
              ) :
              0,
            this.currentOptions.numericInput?.maxFractionDigits
          );
          this.formControl.setValue(formatedValue, { emitEvent: false });
          const spaceCount = formatedValue.split(/\s/g).length - 1;
          this.formsService.setCaretPosition(this.input.nativeElement, caretPosition + spaceCount);
        }
      } else if (stringValue === '') {
        if (value === 0) {
          outputValue = value;
        } else if (value !== null) {
          outputValue = null;
        }
      }
    }

    const newNumberValue = this.hasValue(outputValue) ? Number(outputValue.toString().replace(',', '.')) : null;

    if (
      stringValue !== '-' &&
      !stringValue.endsWith(',') &&
      !stringValue.endsWith('.') &&
      (!splitted[1]?.endsWith('0') || newNumberValue !== this.lastKnownValueOutside) &&
      newNumberValue !== this.lastKnownValueOutside
    ) {
      this.lastKnownValueOutside = newNumberValue;
      super.handleValueEmits(outputValue);
      // super.handleValueEmits sets number to formControl so we need to replace . with , again
      if (this.formControl.value) {
        this.formControl.setValue(
          this.formControl.value.toString().replace(/\./, ','),
          { emitEvent: false },
        );
        this.formsService.setCaretPosition(this.input.nativeElement, caretPosition);
      }
    }
  }

  private reformatValue(value: number): void {
    this.formControl.setValue(formatNumber(
      value,
      this.currentOptions.numericInput?.digitsGrouping,
      this.currentOptions.numericInput?.minFractionDigits,
      this.currentOptions.numericInput?.maxFractionDigits,
    ), { emitEvent: false });
  }

  private setDefaults(): void {
    if (!this.currentOptions.inputMode) {
      this.currentOptions.inputMode = InputMode.DECIMAL;
    }
    if (!this.currentOptions.numericInput) {
      this.currentOptions.numericInput = {};
    }
    if (!this.hasValue(this.currentOptions.numericInput?.minFractionDigits)) {
      this.currentOptions.numericInput.minFractionDigits = 2;
    }
    if (!this.hasValue(this.currentOptions.numericInput?.maxFractionDigits)) {
      this.currentOptions.numericInput.maxFractionDigits = 6;
    }
    if (
      typeof this.currentOptions.numericInput?.digitsGrouping === 'undefined'
      || this.currentOptions.numericInput?.digitsGrouping === null
    ) {
      this.currentOptions.numericInput.digitsGrouping = true;
    }
  }

  private checkNumberMinMax(value: number): number {
    let newValue = value;
    if (this.currentOptions.numericInput?.minValue || this.currentOptions.numericInput?.minValue === 0) {
      if (this.currentOptions.numericInput.minValue >= 0 && newValue < 0) {
        newValue = -newValue;
      }
      newValue = Math.max(newValue, this.currentOptions.numericInput.minValue);
    }
    if (this.currentOptions.numericInput?.maxValue) {
      newValue = Math.min(newValue, this.currentOptions.numericInput.maxValue);
    }
    return newValue;
  }

  private parseNumericValue(value: string): number {
    if (!/^-?[0-9\s]*,?[0-9\s]*$/i.test(value)) {
      if (!value) {
        value = '0';
      } else {
        const isMinus = value.substr(0, 1) === '-';
        value = value.replace(/\./gi, ',').replace(/[^0-9,\s]/gi, '');
        if (isMinus) {
          value = '-' + value;
        }
        if (!value) { // only text was entered
          value = '0';
        }
      }
    }
    return parseNumericValue(value);
  }

  private hasValue(value: any): boolean {
    return value || value === 0;
  }
}
