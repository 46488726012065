import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { inject, Injectable } from '@angular/core';

import { from, Observable } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { KrosAppInsightsService } from '@kros-sk/core';

import { KrosAuthService } from '../services/auth/kros-auth.service';

const MAX_ALLOWED_TIMESHIFT_IN_SECONDS = 60;

@Injectable()
export class KrosAuthGuardService {
  private appConfig = inject(APP_CONFIG);

  private isTimeChecked = false;

  constructor(
    private authService: KrosAuthService,
    private router: Router,
    private logger: KrosAppInsightsService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return from(this.checkAuthentication());
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return from(this.checkAuthentication());
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> {
    return from(this.checkAuthentication());
  }

  private async checkAuthentication(): Promise<boolean | UrlTree> {
    // client time verification
    // ToDo: nájsť request, ktorý by toto nastavoval
    const timeShift = this.getShiftTimeInSeconds();
    if (Math.abs(timeShift) > MAX_ALLOWED_TIMESHIFT_IN_SECONDS) return this.timeOutOfSyncRedirect(timeShift);

    // token validity verification
    const tokenIsValid = await this.authService.ensureValidToken();
    if (tokenIsValid) {
      return true;
    }
    this.authService.startAuthentication();
    return false;
  }

  private getShiftTimeInSeconds(): number {
    if (!this.appConfig.verifyClientTime || this.isTimeChecked) return 0;
    let timeShift = 0;

    if (
      (this.appConfig.telemetryInfo.serverResponseTime instanceof Date) &&
      (this.appConfig.telemetryInfo.clientResponseProcessingTime instanceof Date)
    ) {
      timeShift = (
        this.appConfig.telemetryInfo.clientResponseProcessingTime.getTime() - this.appConfig.telemetryInfo.serverResponseTime.getTime()
      ) / 1000;
    }

    this.isTimeChecked = true;
    return timeShift;
  }

  private timeOutOfSyncRedirect(shift: number): UrlTree {
    this.logger?.trackException(new Error('Time not synchronized'), 'Time shift', { shift } );
    return this.router.createUrlTree(['/time-not-synchronized'], { queryParams: { shift } });
  }
}
