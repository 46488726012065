import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormGuardDirective } from './form-guard.directive';
import { FormGuardSaverDirective } from './form-guard-saver.directive';

const DECLARATIONS = [
  FormGuardDirective,
  FormGuardSaverDirective,
];

@NgModule({
  declarations: [
    ...DECLARATIONS,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ...DECLARATIONS,
  ]
})
export class KrosFormGuardModule { }
