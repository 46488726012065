import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { DeviceDetectorState } from './device-detector.state';

export const deviceDetectorActions = createActionGroup({
  source: 'Device Detector',
  events: {
    Initialized: emptyProps(),

    Detected: props<{ detectedDevice: Partial<DeviceDetectorState> }>(),
  },
});
