export enum SignaRGroups {
  CompanyGroup = 'company',
  AppCompanyGroup = 'appcompany',
  AppCompanyUserGroup = 'appcompanyuser',
  AppCompanyUserIdGroup = 'appcompanyuserid'
}

export enum SignalRActionType {
  None = 0,
  Create = 1,
  Update = 2,
  Delete = 3,
  Indexed = 4,
  DeletedAll = 5,
  IndexVerificationFailed = 6,
  // the other 3 types (which include "confirmation") capture unicast messages
  CreateConfirmation = 7,
  UpdateConfirmation = 8,
  DeleteConfirmation = 9,
  Restore = 10,
  RestoreConfirmation = 11,
  AddAttachment = 12,
  DeleteAttachment = 13,
  CreateDiscussionMessage = 14
}

export interface ReconnectionNotifierSettings {
  reconnectingAdmitDelayMs: number;
  reconnectingNotificationHandler: () => void;
  reconnectedNotificationHandler: () => void;
}

export enum ConnectionEvent {
  InitialState,
  Reconnecting,
  ReconnectingAdmitTime,
  Reconnected
}
