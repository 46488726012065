import { Directive, Input, OnInit, TemplateRef } from '@angular/core';

import { NavigationComponent } from './navigation.component';

@Directive({
  selector: '[krosNavItem]',
  exportAs: 'krosNavItem'
})
export class KrosNavItemDirective implements OnInit {

  @Input('krosNavItem') navArea: 'appSelector' | 'brandZone' | 'logo' | 'menu' | 'secondaryNav' | 'profile';

  @Input('krosNavItemClear') clear = false;

  constructor(
    private tpl: TemplateRef<any>,
    private nav: NavigationComponent
  ) {
  }

  ngOnInit(): void {
    if (this.clear) {
      this.nav[this.navArea] = null;
    } else {
      this.nav[this.navArea] = this.tpl;
    }
  }
}
