import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { KrosModalRef, KrosModalService } from '@kros-sk/components';

import { Tag } from './tag.model';
import { TagType } from './tags.enum';
import { TagsDialogComponent } from './tags-dialog/tags-dialog.component';
import { TagCreateDialogComponent } from './tag-create-dialog/tag-create-dialog.component';
import { TagSettingsDialogComponent } from './tag-settings-dialog/tag-settings-dialog.component';

@Injectable()
export class TagsModalActionService {
  modalRef: KrosModalRef;
  tagsEdited: Subject<{ newActiveTags: Tag[], deletedSomeTags: boolean }>;
  tagCreated: Subject<{ tag: Tag, setTagToProject: boolean }>;
  tagEdited: Subject<Tag>;
  tagsListUpdated: Observable<Tag[]>;

  constructor(
    private krosModalService: KrosModalService,
  ) { }

  showProjectTagsModal(
    isOwner: boolean,
    tagsList: Tag[],
    userTags: Tag[],
    element?: HTMLElement
  ): void {
    this.tagsEdited = new Subject<{ newActiveTags: Tag[], deletedSomeTags: boolean }>();
    this.tagCreated = new Subject<{ tag: Tag, setTagToProject: boolean }>();
    this.tagEdited = new Subject<Tag>();

    const dialogData = {
      disabledTagIds: isOwner ? [] : tagsList.filter(t => t.type === TagType.Project).map(t => t.id),
      tags: tagsList,
      activeTags: [...userTags]
    };
    // ToDo: dočasne vypnuté pripnutie modálu na tlačidlo, pretože máme problém vo firemnej knižnici
    element = null;
    this.modalRef = (element
      ? this.krosModalService.openConnectedToElement(TagsDialogComponent, element, dialogData)
      : this.krosModalService.openCentered(TagsDialogComponent, dialogData)
    );

    this.modalRef.afterClosed$.subscribe(result => {
      if (
        result.type === 'submit' &&
        result.data.hasOwnProperty('openCreateNewTagModal')
      ) {
        setTimeout(() => this.showCreateNewTagModal(tagsList.filter(t => t.type === TagType.User)), 1);
      } else if (
        result.type === 'submit' &&
        result.data.hasOwnProperty('openTagSettingsModal')
      ) {
        setTimeout(() => this.showTagSettingsModal(tagsList), 0);
      } else if (
        result.type === 'submit' &&
        result.data.hasOwnProperty('newActiveTags')
      ) {
        this.tagsEdited.next(result.data);
      }
    });
  }

  showCreateNewTagModal(alreadyExistingTags: Tag[]): void {
    this.modalRef = this.krosModalService.openCentered(TagCreateDialogComponent, { alreadyExistingTags });

    this.modalRef.afterClosed$.subscribe(result => {
      if (result.type === 'submit') {
        this.tagCreated.next({ tag: result.data.tag, setTagToProject: true });
      }
    });
  }

  showTagSettingsModal(tags: Tag[]): void {
    this.modalRef = this.krosModalService.openCentered(
      TagSettingsDialogComponent,
      {
        tags,
        tagCreatedSubj: this.tagCreated,
        tagEditedSubj: this.tagEdited,
        tagsListUpdatedObs: this.tagsListUpdated
      },
      {
        addModalToBrowsersHistory: false
      }
    );
  }
}
