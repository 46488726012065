import { DataSource } from '@angular/cdk/table';
import { QueryList } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { SortableTableHeaderDirective, SortEvent } from '../directives/sortable-table-header/sortable-table-header.directive';

export class TableDataSource<T> extends DataSource<T> {
  public data: T[];

  private rawData: T[];
  private dataSubject: BehaviorSubject<T[]>;
  private lastSortEvent: SortEvent;

  constructor(
    private appLocation: string
  ) {
    super();

    this.rawData = [];
    this.data = [];
    this.dataSubject = new BehaviorSubject<T[]>([]);
    this.appLocation = this.getCulture(appLocation);
  }

  private getCulture(appLocation: string): string {
    return appLocation === 'cz' ? 'cs' : appLocation;
  }

  setData(data: T[]): void {
    this.rawData = data;
    this.sortData(data);
  }

  setDataWithSort(data: T[], headers: QueryList<SortableTableHeaderDirective>): void {
    if (this.lastSortEvent) {
      this.rawData = data;
      this.sort(this.lastSortEvent, headers);
    } else {
      this.setData(data);
    }
  }

  connect(): Observable<T[]> {
    return this.dataSubject;
  }

  disconnect(): void { }

  sort({ column, direction }: SortEvent, headers: QueryList<SortableTableHeaderDirective>): void {
    this.lastSortEvent = { column, direction };
    headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    if (direction === '' || column === '') {
      this.sortData([...this.rawData]);
    } else {
      const sortedDocuments = [...this.rawData].sort((a, b) => {
        const aValue = a[column];
        const bValue = b[column];
        let result = 0;

        if (typeof aValue === 'string' && typeof bValue === 'string') {
          result = aValue.localeCompare(bValue, this.appLocation, { sensitivity: 'accent' });
        } else {
          if (aValue > bValue) {
            result = 1;
          } else if (aValue < bValue) {
            result = -1;
          }
        }

        return direction === 'asc' ? result : -result;
      });
      this.sortData(sortedDocuments);
    }
  }

  private sortData(data: T[]): void {
    this.data = data;
    this.dataSubject.next(this.data);
  }
}
