<div class="kros-modal-header">
  <div
    *ngIf="modalTitleKey"
    class="modal-title"
  >
    <h4>{{ modalTitleKey | translate }}</h4>
  </div>

  <button
    class="btn btn-link close"
    type="button"
    (click)="onClose()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div class="kros-modal-body">
  <div class="item-list">
    <div
      class="item-row"
      *ngFor="let item of items"
      [class.selected]="isSelectedItem(item, selectedItem)"
      (click)="onSelectItem(item)"
    >
      {{ getItemName(item) }}
    </div>
  </div>
</div>

<div class="kros-modal-footer">
  <button
    class="btn btn-primary"
    type="button"
    (click)="onSubmit()"
  >{{ modalSubmitKey | translate }}
  </button>
</div>
