import { Component, DestroyRef, inject, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter, mergeMap, skip, take } from 'rxjs/operators';

import {
  DeleteDocumentService,
  DocumentCreateService,
  DocumentListInfoModel,
  DocumentModalEditService,
  DocumentsExplorerDispatchersService,
  DocumentsPermissionsService,
  FolderTreeNode,
  InfoModalComponent,
  ProjectDetail,
  SelectionService,
  SortableTableHeaderDirective,
  TemporaryUploadInfoModel,
  TimelineType,
  TranslateService
} from '@kros-sk/ssw-shared-legacy';
import { downloadFile, formatString, UserService } from '@kros-sk/ssw-cdk';
import { KrosModalService } from '@kros-sk/components';
import { PermissionType } from '@kros-sk/ssw-shared/permission';

import { ConnectorService } from '../../../core/services/connector.service';
import { DocumentModalService } from '../../services/document-modal.service';
import { DocumentsDispatchersService, DocumentsSelectorsService } from '../../../store/documents';
import { DocumentService } from '../../services/document/document.service';
import { DocumentsOpenerService } from '../../services/documents-opener.service';
import { DocumentTourService } from '../../services/document-tour.service';
import { ProjectsSelectorsService } from '../../../store/projects';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
  providers: [DocumentTourService]
})
export class DocumentListComponent implements OnInit, OnDestroy {
  areDocumentsLoaded = false;
  isUploadSnackbarOpen = false;
  project: ProjectDetail;
  timelineType = TimelineType;
  mobileQuery: MediaQueryList;
  selection = inject(SelectionService);
  connectorService = inject(ConnectorService);
  projectsSelectorsService = inject(ProjectsSelectorsService);
  tourService = inject(DocumentTourService);
  documentModalService = inject(DocumentModalService);
  documentModalEditService = inject(DocumentModalEditService);
  userService = inject(UserService);

  @Input() documentId: string;

  @ViewChildren(SortableTableHeaderDirective) headers: QueryList<SortableTableHeaderDirective>;

  protected isUploadingMode: boolean;
  protected currentFolder: FolderTreeNode;

  protected get isMinimumContributorInCurrentFolder(): boolean {
    return this.documentsPermissionsService.hasMinimumPermissionType(
      this.currentFolder ? this.currentFolder.permissionType : this.rootDocumentPermission,
      PermissionType.Contributor);
  }

  private get hasUploadBuildingPermission(): boolean {
    const documentPermissionType = this.documentsPermissionsService.getDocumentPermissionType(this.uploadingData.name);

    return documentPermissionType === PermissionType.Unidentified
      ? this.isMinimumContributorInCurrentFolder
      : documentPermissionType >= PermissionType.Contributor;
  }

  private get canUploadBuilding(): boolean {
    return this.isUploadingMode && this.hasUploadBuildingPermission;
  }

  private uploadInfo: TemporaryUploadInfoModel;
  private documents: DocumentListInfoModel[];
  private uploadingData: TemporaryUploadInfoModel;
  private rootDocumentPermission: PermissionType;

  private destroyRef = inject(DestroyRef);
  private documentCreateService = inject(DocumentCreateService);
  private deleteDocumentService = inject(DeleteDocumentService);
  private documentsDispatchersService = inject(DocumentsDispatchersService);
  private documentsExplorerDispatchersService = inject(DocumentsExplorerDispatchersService);
  private documentsOpenerService = inject(DocumentsOpenerService);
  private documentsSelectorsService = inject(DocumentsSelectorsService);
  private documentsPermissionsService = inject(DocumentsPermissionsService);
  private documentService = inject(DocumentService);
  private translateService = inject(TranslateService);
  private krosModalService = inject(KrosModalService);

  ngOnInit(): void {
    this.projectsSelectorsService.projectDetail$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(p => this.project = p);
    this.documentsSelectorsService.documents$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(docs => this.documents = docs);
    this.documentsSelectorsService.uploadingData$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(p => this.uploadInfo = p);
    this.documentsSelectorsService.currentFolder$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(f => this.currentFolder = f);
    this.documentsSelectorsService.isUploadingMode$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(p => this.isUploadingMode = p);
    this.documentsSelectorsService.uploadingData$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(p => this.uploadingData = p);
    this.documentsSelectorsService.rootDocumentPermission$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(permission => this.rootDocumentPermission = permission);
  }

  ngOnDestroy(): void {
    this.deleteDocumentService.dismissDeleteToast();
    this.tourService.clear();
  }

  onOpenDocumentInApplication(appId: string): void {
    this.documentsOpenerService.openApplication(appId, this.selection.getSelectedDocument().currentVersionId);
  }

  openDocument(document: DocumentListInfoModel): void {
    this.documentsOpenerService.openDocument(document);
    this.documentsExplorerDispatchersService.clearAllDocuments();
  }

  onCreateDocument(): void {
    this.documentCreateService.onCreateDocument();
  }

  onCreateFolder(): void {
    this.documentCreateService.onCreateFolder();
  }

  onRenameFolder(): void {
    this.deleteDocumentService.dismissDeleteToast();
    this.documentModalEditService.onRenameFolder(this.selection.getSelectedDocument(), this.project.id);
  }

  onDeleteDocuments(): void {
    this.deleteDocumentService.onDeleteDocument(this.project.id, 'DOC');
    this.closeUploadSnackbar();
  }

  onDownloadDocument(): void {
    if (this.selection.isSelectedSingleDocument) {
      this.downloadSingleDocument();
    }
  }

  private downloadSingleDocument(): void {
    const doc = this.selection.getSelectedDocument();
    if (doc) {
      this.documentService.getDocumentUrl(doc.currentVersionId)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(resp => downloadFile(resp, doc.name));
    }
  }

  onFolderOpened(): void {
    this.openDocument(this.selection.getSelectedDocument());
  }

  onUploadBuilding(): void {
    if (this.canUploadBuilding) {
      this.uploadBuildingCore();
    } else {
      this.krosModalService.openCentered(
        InfoModalComponent,
        {
          body: formatString(this.translateService.translate('DOCUMENTS.UPLOAD_BUILDING.NO_PERMISSION'), this.uploadingData.name)
        },
        {
          closeOnBackdropClick: false,
          fullscreenOnMobile: false,
          showMobileArrowBack: false
        },
        NaN,
        NaN,
        undefined,
        'no-min-width'
      );
    }
  }

  uploadBuildingCore(): void {
    const originalDocument = this.documents.find(p => p.name === this.uploadInfo.name);
    this.documentModalService.onUpload(this.project.id, this.uploadInfo, originalDocument);
    this.documentsSelectorsService.showSharing$
      .pipe(
        filter(p => p),
        take(1),
        mergeMap(() => this.documentsSelectorsService.documents$),
        skip(1),
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this.onShareDocument());
  }

  onShareDocument(): void {
    this.openDocumentSharingForDocuments(this.selection.getSelectedDocuments());
  }

  onShowDocumentUploader(): void {
    this.deleteDocumentService.dismissInfoToast();
    this.isUploadSnackbarOpen = true;
  }

  closeUploadSnackbar(): void {
    this.isUploadSnackbarOpen = false;
  }

  onOpenOnNewTab(): void {
    this.documentsOpenerService.openOnNewTab(this.selection.getSelectedDocument());
  }

  openVersionModal(document: DocumentListInfoModel): void {
    this.documentsDispatchersService.loadDocumentVersions(document.id);
    this.documentModalService.onOpenVersionModal(document, this.project.id);
  }

  dismissDeleteToast(): void {
    this.deleteDocumentService.dismissDeleteToast();
  }

  onSetDocumentDescriptionClick(): void {
    const document = this.selection.getSelectedDocument();
    this.documentModalService.onSetDocumentDescription(
      document, this.documentsPermissionsService.hasMinimumPermissionType(document.permissionType, PermissionType.Contributor)
    );
  }

  private openDocumentSharingForDocuments(documents: DocumentListInfoModel[]): void {
    this.documentModalService.openDocumentSharingModalForDocuments(documents);
  }
}
