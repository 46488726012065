<ng-container>
  <div class="validation-messages">
    <div *ngIf="info">
      <ng-container *ngTemplateOutlet="info"></ng-container>
    </div>

    <div *ngIf="formErrors?.required">
      <ng-container *ngIf="customMessages?.required">
        {{ customMessages.required }}
      </ng-container>
      <ng-container *ngIf="!customMessages?.required">
        <span i18n="@@kros-validation-messages.mandatory">
          This attribute is manadatory!
        </span>
      </ng-container>
    </div>

    <div *ngIf="formErrors?.email || formErrors?.invalidEmail">
      <ng-container *ngIf="customMessages?.email">
        {{ customMessages.email }}
      </ng-container>
      <ng-container *ngIf="!customMessages?.email">
        <span i18n="@@kros-validation-messages.email">
          Enter valid email please!
        </span>
      </ng-container>
    </div>

    <div *ngIf="formErrors?.minlength">
      <ng-container *ngIf="customMessages?.minlength">
        {{ customMessages.minLength }}
      </ng-container>
      <ng-container *ngIf="!customMessages?.minlength">
        <span i18n="@@kros-validation-messages.min-length">
          Minimal length of value is {{formErrors.minlength.requiredLength}}!
        </span>
      </ng-container>
    </div>

    <div *ngIf="formErrors?.maxlength">
      <ng-container *ngIf="customMessages?.maxlength">
        {{ customMessages.minlength }}
      </ng-container>
      <ng-container *ngIf="!customMessages?.maxlength">
        <span i18n="@@kros-validation-messages.max-length">
          Maximal length of value is {{formErrors.maxlength.requiredLength}}!
        </span>
      </ng-container>
    </div>

    <div *ngIf="formErrors?.invalidIban">
      <ng-container *ngIf="customMessages?.iban">
        {{ customMessages.iban }}
      </ng-container>
      <ng-container *ngIf="!customMessages?.iban">
        <span i18n="@@kros-validation-messages.iban">
          Enter valid IBAN please!
        </span>
      </ng-container>
    </div>

    <div *ngIf="formErrors?.overMaxQuantity">
      <span i18n="@@kros-validation-messages.over-max">
        The quantity in warehouse is bigger than the maximum quantity.
      </span>
    </div>

    <div *ngIf="formErrors?.underMinQuantity">
      <span i18n="@@kros-validation-messages.under-min">
        The quantity in warehouse is smaller than the minimum quantity.
      </span>
    </div>

    <div *ngIf="formErrors?.underPurchasePrice">
        <span *ngIf="!formErrors?.underPurchasePrice.isDiscountUsed"
          i18n="@@kros-validation-messages.under-purchase-price">
          The price is lower than the <b>current</b> purchase price.
        </span>
        <span *ngIf="formErrors?.underPurchasePrice.isDiscountUsed"
          i18n="@@kros-validation-messages.under-purchase-price-after-discount">
          The price after discount is lower than the <b>current</b> purchase price.
        </span>
    </div>

    <div *ngIf="formErrors?.derivedMeasureUnitNameNotUnique">
      <span i18n="@@kros-validation-messages.name-unique">
        The name must be unique.
      </span>
    </div>

    <div *ngIf="formErrors?.whiteSpaces">
      <ng-container *ngIf="customMessages?.whiteSpaces">
        {{ customMessages.whiteSpaces }}
      </ng-container>
      <ng-container *ngIf="!customMessages?.whiteSpaces">
        <span i18n="@@kros-validation-messages.white-spaces">
          The field must not contain only spaces.
        </span>
      </ng-container> 
    </div>

    <ng-container *ngFor="let error of formErrorsArray">
      <div *ngIf="!isNativeError(error.name)">
        <ng-container *ngIf="customMessages && customMessages[error.name]">
          {{ customMessages[error.name] }}
        </ng-container>
        <ng-container *ngIf="!customMessages || !customMessages[error.name]">
          {{error.name}} - <span i18n="@@kros-validation-messages.unknown-error">unknown error</span>!
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>
