import { inject, Injectable, InjectionToken, Injector } from '@angular/core';

import { ApplicationInsights, IConfig, IConfiguration, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { Observable, ReplaySubject, take } from 'rxjs';

import { AppInsightsBaseService } from './app-insights-base.service';

export interface AppInsightsConfig {
  instrumentationKey: string | Observable<string>;
  aiCloudRole: string;
  samplingPercentage: number;
  customConfig?: IConfiguration & IConfig;
}

export const GET_APP_INSIGHTS_CONFIG_TOKEN = new InjectionToken<(injector: Injector) => AppInsightsConfig>('GET_CONFIG_TOKEN');

/**
 * No-operation implementation of the IAppInsightsService interface.
 * This service provides methods for interacting with the Application Insights service,
 * but the methods do not perform any operations.
 */
@Injectable()
export class NoopAppInsightsService implements AppInsightsBaseService {
  /**
   * Indicates whether Application Insights logging is disabled. Always true for this service.
   */
  disabled = true;

  /**
   * An observable that emits a boolean value indicating whether the user context is defined.
   * Always emits false for this service.
   */
  isUserContextDefined$: Observable<boolean> = new ReplaySubject<boolean>();

  /**
   * Tracks a custom event. Does nothing in this service.
   */
  trackEvent(name: string, properties?: { [key: string]: any }): void { }

  /**
   * Tracks an exception event. Does nothing in this service.
   */
  trackException(error: Error, handler: string, properties?: { [key: string]: any }): void { }

  /**
   * Sets the authenticated user id and the account id. Does nothing in this service.
   */
  setAuthenticatedUserContext(authenticatedUserId: string, accountId?: string, storeInCookie?: boolean): void { }

  /**
   * Clears the authenticated user id and account id. Does nothing in this service.
   */
  clearAuthenticatedUserContext(): void { }

  /**
   * Start tracking event. you need to call stopTrackingEvent to complete tracking and save duration of event.
   * @param name event name.
   */
  startTrackingEvent(name: string): void { }

  /**
   * Stop tracking event. Event name needs to be same as startTrackingEvent.
   * @param name event name.
   */
  stopTrackingEvent(name: string): void { }
}

@Injectable()
export class AppInsightsService implements AppInsightsBaseService {
  private getConfig = inject(GET_APP_INSIGHTS_CONFIG_TOKEN);
  private appInsights: ApplicationInsights | null = null;
  private aiCloudRole = '';
  private userContextDefined = new ReplaySubject<boolean>();

  /**
   * Disable app insights logging on demand.
   */
  disabled = false;
  isUserContextDefined$: Observable<boolean> = this.userContextDefined;

  constructor(injector: Injector) {
    const config = this.getConfig(injector);

    if (typeof config.instrumentationKey === 'string') {
      this.initAppInsights(config.instrumentationKey, config.aiCloudRole, config.samplingPercentage, config.customConfig);
    } else {
      config.instrumentationKey
        .pipe(take(1))
        .subscribe(instrumentationKey => {
          console.log('initAppInsights');
          this.initAppInsights(
            instrumentationKey,
            config.aiCloudRole,
            config.samplingPercentage,
            config.customConfig
          );
        });
    }
  }

  /**
   * Initialize Application Insights instance.
   * @param instrumentationKey obtained from Azure Portal
   * @param aiCloudRole application identifier, i.e. 'Kros.Esw.FE.Invoicing'
   * @param samplingPercentage - The percentage of telemetry items that should be sampled.
   * @param customConfig - Optional custom configuration for Application Insights.
   */
  private initAppInsights(
    instrumentationKey: string,
    aiCloudRole: string,
    samplingPercentage: number,
    customConfig?: IConfiguration & IConfig
  ): void {
    if (!instrumentationKey) return;

    this.aiCloudRole = aiCloudRole;
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
        disableAjaxTracking: true,
        ...customConfig,
        // samplingPercentage,
      },
    });
    this.appInsights.loadAppInsights();
    this.appInsights.addTelemetryInitializer(this.telemetryInitializer);
  }

  /**
   * Track custom event.
   * @param name custom event name
   * @param properties custom properties
   */
  trackEvent(name: string, properties?: { [key: string]: any }): void {
    if (!this.appInsights || this.disabled) return;
    this.appInsights.trackEvent({ name }, properties);
  }

  /**
   * Track exception event.
   * @param error error instance to log
   * @param handler handler name
   * @param properties custom properties
   */
  trackException(error: Error, handler: string, properties?: { [key: string]: any }): void {
    if (!this.appInsights || this.disabled) return;
    this.appInsights.trackException({ exception: error }, { ...properties, handler });
  }

  /**
   * Set the authenticated user id and the account id.
   * Used for identifying a specific signed-in user.
   * Parameters must not contain whitespace or ,;=|
   *
   * The method will only set the `authenticatedUserId` and `accountId` in the current page view.
   * To set them for the whole session, you should set `storeInCookie = true`
   * @param authenticatedUserId an id that uniquely identifies a user of your app
   * @param [accountId] an optional account id, if your app groups users into accounts
   * @param [storeInCookie=false] true if you want to set `authenticatedUserId` and `accountId` for the whole session
   */
  setAuthenticatedUserContext(authenticatedUserId: string, accountId?: string, storeInCookie?: boolean): void {
    this.appInsights?.setAuthenticatedUserContext(authenticatedUserId, accountId, storeInCookie);
    this.userContextDefined.next(true);
  }

  /**
   * Clears the authenticated user id and account id. The associated cookie is cleared, if present.
   */
  clearAuthenticatedUserContext(): void {
    this.appInsights?.clearAuthenticatedUserContext();
    this.userContextDefined.next(false);
  }

  /**
   * Start tracking event. you need to call stopTrackingEvent to complete tracking and save duration of event.
   * @param name event name.
   */
  startTrackingEvent(name: string): void {
    if (!this.appInsights || this.disabled) return;
    this.appInsights.startTrackEvent(name);
  }

  /**
   * Stop tracking event. Event name needs to be same as startTrackingEvent.
   * @param name event name.
   */
  stopTrackingEvent(name: string): void {
    if (!this.appInsights || this.disabled) return;
    this.appInsights.stopTrackEvent(name);
  }

  // function for filtering and expanding data sent to Azure
  private telemetryInitializer = (item: ITelemetryItem): boolean => {
    if (item.tags) {
      // cloud role name
      item.tags['ai.cloud.role'] = this.aiCloudRole;
    }
    if (item.data) {
      // custom data
      item.data['krosEswAppVersion'] = (window as any).eswAppVersion?.date;
      // referer domain
      item.data['refererDomain'] = (window as any).location?.hostname;
    }
    return true;
  };
}
