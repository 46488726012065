import { AbstractControl } from '@angular/forms';

import { DateInterval } from '@kros-sk/models';

const oneDayMs = 1000 * 60 * 60 * 24;

/**
 * Formatting date-only information for server, without TimeZone info.
 * Api expects for plain date time part with exactly this format: T00:00:00.
 */
export function formatPlainDateForApi(date: Date): string {
  if (!date) return null;
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}T00:00:00`;
}

/**
 * Parses JSON date and resets time info to 0:00:00.000Z.
 */
export function parseUtcDate(jsonDate?: '', keepHours?: boolean): null;
export function parseUtcDate(jsonDate: string, keepHours?: boolean): Date;
export function parseUtcDate(jsonDate?: string, keepHours = false): Date | null {
  if (!jsonDate) {
    return null;
  }

  if (!jsonDate.includes('+') && !jsonDate.includes('Z') && !jsonDate.includes('GMT')) {
    jsonDate += 'Z';
  }
  const date = new Date(jsonDate);
  if (!keepHours) date.setUTCHours(0, 0, 0, 0);
  return date;
}

export function formatUtcDateForServer(date: Date): string {
  return date ? date.toISOString().slice(0, 19) : null;
}

export function getLocalDateString(date: Date): string {
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}T00:00:00.000Z`;
}

/**
 * Calculates difference between two dates counting days.
 * Function ignores time information.
 */
export function daysDiff(soonerDate: Date, laterDate: Date): number {
  const d1 = resetUtcTime(soonerDate);
  const d2 = resetUtcTime(laterDate);
  return (d2.getTime() - d1.getTime()) / oneDayMs;
}

/**
 * Checks if date is valid
 */
export function dateIsValid(date?: Date): boolean {
  return date != null && date instanceof Date && isFinite(date.getTime());
}

/**
 * Adds days to date and returns new instance of Date object.
 */
export function addDays(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

/**
 * Adds months to date and returns new instance of Date object.
 * WARNING: result date can overflow in days into next month in case that final month has less days as is day in source date
 * Adding 1 month to 31.1.2021 will result in 3.3.2021
 * @param date Date to which the months will be added
 * @param months Months count
 */
export function addMonths(date: Date, months: number): Date {
  return new Date(date.setMonth(date.getMonth() + months));
}

/**
 * Adds months to date and returns new instance of Date object.
 * @param date Date to which the months will be added
 * @param months Months count
 * @param timeZone If it should shift timezone
 */
export function addMonthsWithoutDaysOverflow(date: Date, months: number, timeZone: boolean = true): Date {
  const endDate = new Date(date.getTime());
  const originalTimeZoneOffset = endDate.getTimezoneOffset();
  endDate.setMonth(endDate.getMonth() + months);

  while (monthDiff(date, endDate) > months) {
    endDate.setDate(endDate.getDate() - 1);
  }

  if (timeZone) {
    const endTimeZoneOffset = endDate.getTimezoneOffset();
    const diff = endTimeZoneOffset - originalTimeZoneOffset;
    const finalDate = diff ? endDate.setMinutes(endDate.getMinutes() - diff) : endDate;

    return new Date(finalDate);
  }

  return endDate;
}

/**
 * Returns new Date object with clear UTC time info.
 */
export function resetUtcTime(date: Date): Date {
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return new Date(`${year}-${month}-${day}T00:00:00.000Z`);
}

/**
 * Returns the ISO week of the date
 */
export function getWeek(value: Date): number {
  const date = new Date(value.getTime());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  const week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
}

/**
 * Returns the Quarter of the date
 */
export function getQuarter(date: Date): number {
  const month = date.getMonth() + 1;
  return Math.ceil(month / 3);
}

/**
 * current year as from-to interval
 */
export function currentYearInterval(): DateInterval {
  const curYear = new Date().getFullYear();
  return {
    from: resetUtcTime(new Date(curYear, 0, 1)),
    to: resetUtcTime(new Date(curYear, 11, 31)),
  };
}

/**
 * Returns a value whether provided dates have equal date parts.
 * @param date1 Date to be compared.
 * @param date2 Date to be compared.
 */
export function sameDateParts(date1: Date, date2: Date): boolean {
  if (!date1 && !date2) {
    return true;
  }
  return (
    !!(!date1 || date2) &&
    !!(date1 || !date2) &&
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

/**
 * Parses date string in format returned from ekasa api.
 * @param date A date string in format 'day.month.year hour:minute:second'.
 */
export function parseEkasaApiDate(date: string): string {
  const dateTimeComponents = date.split(' ');
  const dayMonthYearComponents = dateTimeComponents[0].split('.');
  const hourMinuteSecondComponents = dateTimeComponents[1].split(':');

  return formatUtcDateForServer(new Date(Date.UTC(
    parseInt(dayMonthYearComponents[2], 10),
    parseInt(dayMonthYearComponents[1], 10) - 1,
    parseInt(dayMonthYearComponents[0], 10),
    parseInt(hourMinuteSecondComponents[0], 10),
    parseInt(hourMinuteSecondComponents[1], 10),
    parseInt(hourMinuteSecondComponents[2], 10)
  )));
}

/**
 * Format date to ekasa api format string ('day.month.year hour:minute:second').
 * @param date Date object to format.
 */
export function formatEkasaApiDate(date: Date): string {
  return (
    `${toZeroPaddedString(date.getDate())}.${toZeroPaddedString(date.getMonth() + 1)}.${date.getFullYear()}` +
    ` ${toZeroPaddedString(date.getHours())}:${toZeroPaddedString(date.getMinutes())}:${toZeroPaddedString(date.getSeconds())}`
  );
}

export function areDateIntervalsSame(interval1: DateInterval, interval2: DateInterval): boolean {
  return areDatesSame(interval1.from, interval2.from) && areDatesSame(interval1.to, interval2.to);
}

/**
 * Removes time part from date.
 * @param date Date.
 */
export function stripTime(date?: Date): Date {
  if (!dateIsValid(date)) return null;
  const newDate = new Date(date.getTime());
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

/**
 * Returns a string with time set to the last millisecond of the date specified.
 * @param date Date.
 */
export function getDayEndString(date: Date): string {
  date.setUTCHours(23, 59, 59, 999);
  return date.toISOString();
}

function areDatesSame(date1: Date, date2: Date): boolean {
  if (date1 && date2) {
    return daysDiff(date1, date2) === 0;
  } else if (!date1 && !date2) {
    return true;
  }
  return false;
}

export function toZeroPaddedString(value: number, decimalPlacesCount = 2): string {
  return value.toString().padStart(decimalPlacesCount, '0');
}

function monthDiff(from: Date, to: Date): number {
  const years = to.getFullYear() - from.getFullYear();
  const months = to.getMonth() - from.getMonth();
  return 12 * years + months;
}

export function doNotAllowEmptyDate(control: AbstractControl): void {
  if (!control.value) {
    control.setValue(new Date());
  }
}

/**
 * Add minutes to date
 */
export function dateAddMinutes(date: Date, minutes: number): Date {
  return new Date(date.valueOf() + (1000 * 60 * minutes));
}

/**
 * Sub minutes from date
 */
export function dateSubMinutes(date: Date, minutes: number): Date {
  return dateAddMinutes(date, -minutes);
}

/**
 * Calculate duration in seconds between specified times
 */
export function calcDurationInSec(start: Date, end: Date): number {
  return (end.valueOf() - start.valueOf()) / 1000;
}

export function getMonthName(month: number): string {
  if (month < 1 || month > 12) {
    return '';
  }

  return ['Január', 'Február', 'Marec', 'Apríl', 'Máj', 'Jún', 'Júl',
    'August', 'September', 'Október', 'November', 'December'][month - 1];
}

export function dateInFuture(date: Date): boolean {
  const tomorrow = addDays(new Date(), 1);
  tomorrow.setHours(0, 0, 0, 0);
  return date >= tomorrow;
}

export function dateTooOld(date: Date): boolean {
  const lastSupportedDate = new Date(2012,12,31, 0, 0, 0, 0);
  return date <= lastSupportedDate;
}
