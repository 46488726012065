<ul class="list-group">
  <li
    class="list-group-item"
    [ngClass]="{'selected': isInProjectMembers}"
    (click)="onMembersClick()"
  >
    <i class="icon-button material-icons"> people</i>

    <span class="item-text">
      {{ 'SHARING.MEMBERS_OF_THE_PROJECT' | translate }}
    </span>
  </li>

  <li
    class="list-group-item"
    [ngClass]="{'selected': !isInProjectMembers}"
    (click)="onOwnerClick()"
  >
    <i
      class="icon-button material-icons"
      data-test="open-project-owner"
    >emoji_people</i>

    <span class="item-text">
      {{ 'SHARING.OWNER' | translate }}
    </span>
  </li>
</ul>
