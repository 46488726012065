import { Component, Input } from '@angular/core';

import { BudgetApprovalSelectorsService } from '../../../store/budget-approval';

@Component({
  selector: 'app-change-sheet-panel-container',
  templateUrl: './change-sheet-panel-container.component.html',
  styleUrls: ['./change-sheet-panel-container.component.scss']
})
export class ChangeSheetPanelContainerComponent {
  @Input() tableId: string;

  constructor(
    public selector: BudgetApprovalSelectorsService
  ) { }
}
