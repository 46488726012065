import { Injectable } from '@angular/core';

import {
  DataTableConfigModel,
  getMultiselectSelectedAndIndeterminateItems,
  isConstructionOrBuildingObject,
  isSection
} from '@kros-sk/ssw-shared-legacy';

import { BuildingProgressModel } from '../../../../building-progress/models/construction-data.model';
import { getColumnsMaxNumber } from '../../../../building-progress/helpers';
import { SubcontractorSelectItem } from '../models';
import { TableConfigBudgetColumnsService } from '../../../../building-progress/services/table-config-budget-columns.service';

@Injectable()
export class SelectItemsDialogTableService {
  averageLineHeight: number;
  selectedItemIds = new Set<number>();

  private indeterminateItemIds = new Set<number>();
  private config: DataTableConfigModel;
  private constructionData: BuildingProgressModel;

  constructor(
    private budgetColumnsService: TableConfigBudgetColumnsService
  ) { }

  getTableConfig(
    constructionData: BuildingProgressModel,
    isReadonly: boolean,
    useBudgetPartialItemActionColumn = false
  ): DataTableConfigModel {
    const columnsMaxNumber = getColumnsMaxNumber(constructionData);
    this.averageLineHeight = columnsMaxNumber.averageLineHeight;
    this.constructionData = constructionData;
    this.config = {
      isReadonly,
      itemsOffset: columnsMaxNumber.itemsOffset,
      isMultiSelect: true,
      fixedWidth: true,
      colDefs: this.budgetColumnsService.getBudgetColumns(
        constructionData,
        columnsMaxNumber,
        false,
        false,
        false,
        useBudgetPartialItemActionColumn),
      rowClassGetter: (data: SubcontractorSelectItem): string => {
        let classString = '';
        classString += isConstructionOrBuildingObject(data) ? 'font-blue font-strong' : '';
        classString += isSection(data) ? ' font-strong' : '';
        classString += data.originalId ? ' font-brown' : '';
        return classString;
      },
      isItemSelectedGetter: (item: SubcontractorSelectItem): boolean => {
        return this.selectedItemIds.has(item.id);
      },
      isItemIndeterminateGetter: (item: SubcontractorSelectItem): boolean => {
        return this.indeterminateItemIds.has(item.id);
      }
    };

    return this.config;
  }

  multiSelectItem(id: number, state: boolean): void {
    const selection = getMultiselectSelectedAndIndeterminateItems(
      this.constructionData.items,
      this.selectedItemIds,
      this.indeterminateItemIds,
      id,
      state
    );
    this.selectedItemIds = selection.selectedItems;
    this.indeterminateItemIds = selection.indeterminateItems;
  }

  multiSelectAllitems(): void {
    const itemIds = this.constructionData.items.length > 0 ? this.constructionData.items.map(item => item.id) : [];
    const itemIdsSet = new Set<number>(itemIds);
    this.selectedItemIds = itemIdsSet;
    this.indeterminateItemIds.clear();
  }

  clearMultiSelectItems(): void {
    this.selectedItemIds.clear();
    this.indeterminateItemIds.clear();
  }
}
