export type Constructor<T> = new (...args: any[]) => T;

/**
 * Checks if value is an Object
 * @param val
 */
export function isObject(val): boolean {
  if (val === null) { return false;}
  return ( (typeof val === 'function') || (typeof val === 'object') );
}

export function compareObjects(obj1: any, obj2: any): boolean {
  // just one of them is undefined
  if (obj1 === undefined && obj2 !== undefined) return false;
  if (obj1 !== undefined && obj2 === undefined) return false;

  // just one of them is null
  if (obj1 === null && obj2 !== null) return false;
  if (obj1 !== null && obj2 === null) return false;

  // both are objects
  if (obj1 !== null && typeof obj1 === 'object' && obj2 !== null && typeof obj2 === 'object') {
    return JSON.stringify(obj1, getSortedObjectKeys(obj1)) === JSON.stringify(obj2, getSortedObjectKeys(obj2));
  }

  // all other
  return obj1 === obj2;
}

/**
 * Gets sorted keys (property names) of provided object.
 * @param obj A source object to get keys from.
 */
export function getSortedObjectKeys(obj: object): string[] {
  const ret = new Set<string>();
  JSON.stringify(obj, (k, v) => { ret.add(k); return v; });
  return Array.from(ret).sort();
}

/**
 * Check if object is empty
 *
 * @param obj
 * @returns
 */
export function isObjectEmpty(obj: object): boolean {
  return Object.keys(obj).length === 0;
}

/**
 * Array like filter for objects. Remove properties from object that do not fulfill filter function
 *
 * @param o
 * @param filterFn
 * @returns
 */
export function objectFilter<T extends object>(
  o: T,
  filterFn: (value: any, key: string) => boolean
): Partial<T> {

  const entries = Object.entries(o);

  const filtered = entries.filter(([ key, value ]) => filterFn(value, key));

  return Object.fromEntries(filtered) as Partial<T>;
}

/**
 * Function creates generic object from JSON string with lowercase keys.
 *
 * @param jsonString
 */
export function parseObjectWithLowercaseKeys<T>(jsonString: string): T {
  const obj = JSON.parse(jsonString);
  const result = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = key.charAt(0).toLowerCase() + key.slice(1);
      const value = obj[key];

      if (Array.isArray(value)) {
        result[newKey] = value.map(item => {
          if (typeof item === 'object' && item !== null) {
            return parseObjectWithLowercaseKeys(JSON.stringify(item));
          } else {
            return item;
          }
        });
      } else if (typeof value === 'object' && value !== null) {
        result[newKey] = parseObjectWithLowercaseKeys(JSON.stringify(value));
      } else {
        result[newKey] = value;
      }
    }
  }

  return result as T;
}
