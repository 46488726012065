import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { CurrencyFormatPipe } from './currency/currency.pipe';
import { FileSizePipe } from './file-size/file-size.pipe';
import { IbanPipe } from './iban/iban.pipe';
import { LicenseEshopRedirectPipe } from './license-eshop-redirect/license-eshop-redirect.pipe';
import { LicenseInfoFormatPipe } from './license-info/license-info-format.pipe';
import { MaintenanceDatePipe } from './maintenance-date/maintenance-date.pipe';
import { MaxLengthPipe } from './max-length/max-length.pipe';
import { NumberFormatPipe } from './number-format/number-format.pipe';
import { PaymentFormatPipe } from './payment-format/payment-format.pipe';
import { SearchDownTooltipPipe } from './search-down-tooltip/search-down-tooltip.pipe';
import { StringFormatPipe } from './string-format/string-format.pipe';
import { ValueColorPipe } from './value-color/value-color.pipe';

const pipes = [
  CurrencyFormatPipe,
  FileSizePipe,
  IbanPipe,
  MaxLengthPipe,
  NumberFormatPipe,
  PaymentFormatPipe,
  StringFormatPipe,
  ValueColorPipe,
  MaintenanceDatePipe,
  SearchDownTooltipPipe,
  LicenseInfoFormatPipe,
  LicenseEshopRedirectPipe
];

@NgModule(
  {
    declarations: [
      ...pipes
    ],
    exports: [
      ...pipes
    ],
    providers: [
      DatePipe
    ]
  }
)
export class PipesModule {
}
