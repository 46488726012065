import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import {
  Cell,
  DataTableComponent,
  getFocusedCellElementId
} from '@kros-sk/ssw-shared-legacy';
import { scrollElementIntoParentView } from '@kros-sk/ssw-cdk';

import { BuildingProgressItem } from '../models/construction-data.model';
import { RefocusItemModel } from '../models/refocus-item.model';

@Injectable()
export class VisualHelper {

  focusedCell: Cell;

  get table(): DataTableComponent {
    return this._table;
  }
  set table(value: DataTableComponent) {
    this._table = value;
    if (this._table && this.scrollElementToViewParams) {
      this.scrollElementToView(
        this.scrollElementToViewParams.elementId,
        this.scrollElementToViewParams.ignoreOffset,
        this.scrollElementToViewParams.elementIdForColumnWidth,
        this.scrollElementToViewParams.scrollArgumets);
      this.scrollElementToViewParams = undefined;
    }
  }

  private _table: DataTableComponent;
  private scrollElementToViewParams: any;

  scrollElementToView(
    elementId: string,
    ignoreOffset: boolean,
    elementIdForColumnWidth?: string,
    scrollArguments?: ScrollIntoViewOptions
  ): Observable<void> {
    const body = document.getElementsByTagName('body')[0];
    const tabletBreakpoint = 1344;
    const fixedAllColumnWidth = 612;
    const fixedMeasureUnitColumnWidth = 530;

    if (this.focusedCell && !document.getElementById(getFocusedCellElementId(this.focusedCell))) {
      if (this.table) {
        this.table.viewPort.scrollToIndex(this.focusedCell.rowIndex, 'auto');
      } else {
        this.scrollElementToViewParams = {
          elementId,
          ignoreOffset,
          elementIdForColumnWidth,
          scrollArguments
        };
        return of(undefined);
      }
    }

    return scrollElementIntoParentView(
      elementId,
      this.table ? this.table.tableElement.nativeElement : body,
      200,
      60,
      ignoreOffset ? 0 : body.clientWidth < tabletBreakpoint ? fixedMeasureUnitColumnWidth : fixedAllColumnWidth,
      elementIdForColumnWidth,
      scrollArguments);
  }

  scrollFocusedCellIntoView(delay: number = 0): void {
    setTimeout(() => {
      if (this.focusedCell) {
        this.scrollElementToView(getFocusedCellElementId(this.focusedCell), false);
      }
    }, delay);
  }

  refocusFocusedCell(): void {
    if (!!this.focusedCell) {
      setTimeout(() => document.getElementById(getFocusedCellElementId(this.focusedCell))?.focus(), 0);
    }
  }

  getFocusedCellFromRefocusModel(refocusModel: RefocusItemModel, items: BuildingProgressItem[]): Cell {
    if (this.focusedCell) {
      const itemIndex = items.findIndex(i => i.id === refocusModel.item.id);

      if (itemIndex !== -1) {
        return {
          colId: this.focusedCell.colId,
          params: this.focusedCell.params,
          rowIndex: itemIndex
        };
      } else {
        for (const parentItemId of refocusModel.parentItemIds) {
          const parentItemIndex = items.findIndex(i => i.id === parentItemId);

          if (parentItemIndex !== -1) {
            return {
              colId: this.focusedCell.colId,
              params: this.focusedCell.params,
              rowIndex: parentItemIndex
            };
          }
        }
      }
    }

    return undefined;
  }
}
