import { Component, OnInit } from '@angular/core';

import { DeviceDetectorService, DeviceType, KrosModalRef } from '@kros-sk/components';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { BuildingProgressSelectorsService } from '../../../store/building-progress';
import { PermissionHelper } from '../../../building-progress/helpers';

@Component({
  selector: 'app-building-progress-panel-dialog',
  templateUrl: './building-progress-panel-dialog.component.html',
  styleUrls: ['./building-progress-panel-dialog.component.scss']
})
export class BuildingProgressPanelDialogComponent extends UnsubscribeComponent implements OnInit {

  constructor(
    public selector: BuildingProgressSelectorsService,
    public permissionHelper: PermissionHelper,
    private modalRef: KrosModalRef,
    private deviceDetector: DeviceDetectorService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.deviceDetector.deviceType$.subscribe((type: DeviceType) => {
      if (type === DeviceType.Desktop) {
        this.modalRef.cancel();
      }
    });
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }
}
