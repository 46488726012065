import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { AllowedPartnersSelectorComponent } from './allowed-partners-selector/allowed-partners-selector.component';
import { CompanySearchComponent } from './company-search/company-search.component';
import { CompanySearchService } from './services/company-search/company-search.service';
import { EshopModule } from '../eshop/eshop.module';
import { FreePartnersSearchComponent } from './free-partners-search/free-partners-search.component';
import { KrosInputsModule } from '../inputs';
import { PartnersSearchComponent } from './partners-search/partners-search.component';
import { SearchErrorHandlingModule } from '../search-error-handling/search-error-handling.module';
import { SelectPartnerOptionComponent } from './select-partner-option/select-partner-option.component';

const COMPONENTS = [
  AllowedPartnersSelectorComponent,
  CompanySearchComponent,
  FreePartnersSearchComponent,
  SelectPartnerOptionComponent,
  PartnersSearchComponent
];

@NgModule({
  imports: [
    CommonModule,
    EshopModule,
    KrosInputsModule,
    NgbTypeaheadModule,
    ReactiveFormsModule,
    SearchErrorHandlingModule,
    TooltipDirective
  ],
  declarations: [ ...COMPONENTS ],
  exports: [ ...COMPONENTS ],
  providers: [
    CompanySearchService
  ]
})
export class CompaniesRegisterSearchModule { }
