<div class="list-container">
  <ng-container *ngIf="!isEmptyContent">
    <ng-container *ngIf="selectors.isDocumentsListLoaded$ | async; else contentLoader">
      <div class="table-div">
        <table
          class="table"
          cdk-table
          [dataSource]="dataSource"
        >
          <ng-container cdkColumnDef="check">
            <th
              cdk-header-cell
              *cdkHeaderCellDef
              id="check"
            >
              <input
                type="checkbox"
                [checked]="selection.areMarkedAll()"
                (change)="selection.markDismarkAllDocuments()"
              >
            </th>
            <td
              cdk-cell
              *cdkCellDef="let document"
            >
              <input
                type="checkbox"
                (change)="selection.markDocument(document, $event)"
                [checked]="selection.isRowMarked(document.id)"
                data-test="document-row-checkbox"
              >
            </td>
          </ng-container>

          <ng-container cdkColumnDef="originalName">
            <th
              cdk-header-cell
              *cdkHeaderCellDef
              id="name"
              data-test="document-name-header"
              sortable="originalName"
              (sort)="onSort($event)"
            >{{ 'DOCUMENTS.NAZOV' | translate | uppercase }}</th>
            <td
              cdk-cell
              *cdkCellDef="let document"
            >
              <div class="document-name-div">
                <app-document-icon
                  class="no-selection"
                  [document]="document"
                  krosTooltip="{{ document.isShared && document.type === 1 ? ('SHARING.SHARED' | translate) : '' }}"
                  data-test="document-icon"
                >
                </app-document-icon>
                <span
                  class="document-name no-selection"
                  data-test="document-name"
                >
                  {{ document.originalName }}
                </span>
              </div>
            </td>
          </ng-container>

          <ng-container cdkColumnDef="version">
            <th
              cdk-header-cell
              *cdkHeaderCellDef
              id="version"
              data-test="document-version-header"
            ></th>
            <td
              cdk-cell
              *cdkCellDef="let document"
              data-test="document-version"
            >
              <span
                *ngIf="document.versionsCount > 1"
                class="badge badge-pill version"
                data-test="document-badge"
                (click)="onOpenVersionModal(document)"
              >
                v{{document.currentVersionOrder}}
              </span>
            </td>
          </ng-container>

          <ng-container cdkColumnDef="sharing">
            <th
              cdk-header-cell
              *cdkHeaderCellDef
              id="sharing"
              data-test="document-sharing-header"
            ></th>
            <td
              cdk-cell
              *cdkCellDef="let document"
            >
              <kros-avatar-list
                *ngIf="isDocumentSharingVisible(document)"
                class="avatars"
                [ownerMail]="sharingsOwnerMail"
                [notOwnerUsers]="document.sharings"
                [maxAvatarsLimit]="mobileQuery.matches ? 1 : 2"
                data-test="sharing-avatars"
                (click)="openDocumentAccess(document)"
              ></kros-avatar-list>
            </td>
          </ng-container>

          <ng-container cdkColumnDef="author">
            <th
              cdk-header-cell
              *cdkHeaderCellDef
              id="author"
              data-test="document-author-header"
              sortable="createdByEmail"
              (sort)="onSort($event)"
            >{{ 'DOCUMENTS.AUTHOR' | translate | uppercase }}</th>
            <td
              cdk-cell
              *cdkCellDef="let document"
            ><span class="document-author no-selection">{{ getAuthor(document) }}</span>
            </td>
          </ng-container>

          <ng-container cdkColumnDef="size">
            <th
              cdk-header-cell
              *cdkHeaderCellDef
              id="size"
              data-test="document-size-header"
              sortable="size"
              (sort)="onSort($event)"
            >{{ 'DOCUMENTS.SIZE' | translate | uppercase }}</th>
            <td
              cdk-cell
              *cdkCellDef="let document"
            ><span class="no-selection">{{ getDocumentSize(document) | fileSize }}</span>
            </td>
          </ng-container>

          <ng-container cdkColumnDef="dateSaved">
            <th
              cdk-header-cell
              *cdkHeaderCellDef
              id="dateSaved"
              data-test="document-date-saved-header"
              sortable="dateSaved"
              (sort)="onSort($event)"
            >{{ 'DOCUMENTS.POSLEDNA_ZMENA' | translate | uppercase }}</th>
            <td
              cdk-cell
              data-test="date-saved"
              *cdkCellDef="let document"
            ><span class="no-selection">{{document.dateSaved | date:'dd.MM.yyyy HH:mm' }} </span>
            </td>
          </ng-container>

          <tr
            cdk-header-row
            *cdkHeaderRowDef="columns"
          ></tr>
          <tr
            cdk-row
            *cdkRowDef="let document; columns: columns"
            (click)="selection.selectDocument(document, $event)"
            (dblclick)="onOpenDocument(document)"
            (press)="selection.addDocumentToSelected(document)"
            [ngClass]="{'selected':document && selection.isRowMarked(document.id)}"
            data-test="document-row"
          ></tr>
        </table>
      </div>
    </ng-container>

    <ng-template #contentLoader>
      <kros-timeline [type]="timelineType.Table"></kros-timeline>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="isEmptyContent">
    <div
      class="empty-content"
      data-test="empty-content"
    >
      <ng-container *ngIf="(canAddDocuments$ | async); else (noAccess ? noPermission: emptyFolder)">
        <h4>{{ 'DOCUMENTS.EMPTY_CONTENT_HEADER' | translate }}</h4>

        <div class="buttons">
          <button
            class="btn btn-outline-primary"
            (click)="onCreateFolder()"
            data-test="create-folder-button"
          >
            <i class="material-icons-outlined">folder</i><br>

            <span>{{ 'DOCUMENTS.EMPTY_CONTENT_ADD_FOLDER' | translate }}</span>
          </button>

          <ng-container *ngIf="isUploadingMode; else createDocument">
            <button
              class="btn btn-outline-primary"
              (click)="onUploadBuilding()"
              data-test="upload-building-button"
            >
              <i class="material-icons">upload</i><br>

              <span>{{'DOCUMENTS.UPLOAD_BUILDING.UPLOAD_BUILDING' | translate}}</span>
            </button>
          </ng-container>

          <ng-template #createDocument>
            <button
              class="btn btn-outline-primary"
              (click)="onCreateDocument()"
              data-test="create-document-button"
            >
              <i class="material-icons">upload_file</i><br>

              <span>{{ 'DOCUMENTS.EMPTY_CONTENT_LOAD_DOCUMENT' | translate }}</span>
            </button>
          </ng-template>
        </div>
      </ng-container>

      <ng-template #emptyFolder>
        <p>{{'DOCUMENTS.EMPTY_FOLDER' | translate }}</p>
      </ng-template>

      <ng-template #noPermission>
        <p>{{'DOCUMENTS.NO_PERMISSION' | translate }}</p>
      </ng-template>

      <br>

      <p
        *ngIf="showOpenDocumentsDemoLink()"
        class="link no-selection"
        (click)="onOpenDocumentsDemo()"
      >{{ 'DOCUMENTS.EMPTY_CONTENT_TRY_DOCUMENTS_IN_DEMO' | translate }}</p>

      <p
        *ngIf="showOpenQuantityManagerDemoLink()"
        class="link no-selection"
        (click)="onOpenQuantityManagerDemo()"
      >{{ 'DOCUMENTS.EMPTY_CONTENT_TRY_AREA_IN_DEMO' | translate }}</p>
    </div>
  </ng-container>
</div>
