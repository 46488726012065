import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { GenericInfoDialogComponent } from './generic-info-dialog.component';

@NgModule({
  declarations: [
    GenericInfoDialogComponent
  ],
  imports: [
    CommonModule,
    NgbModalModule
  ],
  exports: [
    GenericInfoDialogComponent
  ]
})
export class GenericInfoDialogModule { }
