import { Directive, OnDestroy, OnInit } from '@angular/core';

import { KrosFormFieldComponent } from '../kros-form-field/kros-form-field.component';

@Directive({
  selector: 'i[krosSuffix], i[krosIconSuffix]',
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class KrosSuffix implements OnInit, OnDestroy {
  private readonly formFieldSuffixClass = 'kros-form-field-infix-right';

  constructor(private formField: KrosFormFieldComponent) { }

  ngOnInit(): void {
    this.formField.addClass(this.formFieldSuffixClass);
  }

  ngOnDestroy(): void {
    this.formField.removeClass(this.formFieldSuffixClass);
  }
}
