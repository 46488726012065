import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  TemplateRef,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

import { DeviceDetectorService } from '../../services/device-detector/device-detector.service';
import { KrosModalRef, ModalContent } from '../services/kros-modal-ref';
import { MobileHeightSettings } from '../../directives/mobile-height/mobile-height.directive';

@Component({
  selector: 'kros-modal',
  templateUrl: './kros-modal.component.html',
  styleUrls: ['./kros-modal.component.scss']
})
export class KrosModalComponent implements OnInit {

  renderMethod: 'template' | 'component' | 'text' = 'component';
  // Component / Template / String to be displayed as modal
  content: ModalContent;
  // data to be passed in as parameter
  context: any;
  addGradient = false;
  isContentRendered = false;

  setSmallScreen: boolean;
  showMobileArrowBack: boolean;
  fullscreenOnMobile: boolean;
  allowFocusAutocapture: boolean;
  modalMobileHeightSettings: MobileHeightSettings = {
    heightPropertyName: 'max-height'
  };
  contentMobileHeightSettings: MobileHeightSettings = {
    heightPropertyName: 'max-height',
    offset: '7rem'
  };

  @ViewChild('componentSlot', {read: ViewContainerRef, static: true}) componentSlot: ViewContainerRef;

  @Output() componentLoaded = new EventEmitter<ComponentRef<any>>();

  constructor(
    private modalRef: KrosModalRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private deviceDetector: DeviceDetectorService
  ) { }

  ngOnInit(): void {
    this.showMobileArrowBack = this.modalRef.settings.showMobileArrowBack;
    this.fullscreenOnMobile = this.modalRef.settings.fullscreenOnMobile;
    this.allowFocusAutocapture = this.modalRef.settings.allowFocusAutocapture;
    this.setSmallScreen = this.modalRef.settings.setSmallScreen;
    this.addGradient = this.modalRef.settings.addGradient ?? false;
    this.setMobileHeightSettings();
    this.content = this.modalRef.content;

    if (typeof this.content === 'string') {
      this.renderMethod = 'text';
    }

    if (this.content instanceof TemplateRef) {
      this.renderMethod = 'template';
      // Bind data and methods to submit / cancel to template
      this.context = {
        data: this.modalRef.data,
        cancel: this.modalRef.cancel.bind(this.modalRef),
        accept: this.modalRef.submit.bind(this.modalRef)
      };
    }

    if (this.content instanceof Type) {
      this.renderMethod = 'component';
      const cmpFactory = this.componentFactoryResolver.resolveComponentFactory(
        this.content
      );
      const component = this.componentSlot.createComponent(
        cmpFactory,
        0,
        this.injector
      );
      this.componentLoaded.emit(component as ComponentRef<any>);
    }

    this.isContentRendered = true;
  }

  close(): void {
    this.modalRef.cancel();
  }

  smallScreenForDektop(): boolean {
    return document.documentElement.clientHeight < 1200 && this.setSmallScreen;
  }

  get isIOSOrIPadOS(): boolean {
    return this.deviceDetector.isIOSOrIPadOS;
  }

  private setMobileHeightSettings(): void {
    this.modalMobileHeightSettings.offset = this.fullscreenOnMobile ? '' : '3.5rem';
    this.contentMobileHeightSettings.offset = this.showMobileArrowBack ? '7rem' : '';
  }
}
