import { FormArray, FormGroup, UntypedFormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { InputCommand, InputCommandType } from './inputs.common.interface';

interface TargetedInputCommand {
  command: InputCommand;
  targets: string[];
}

@Injectable({ providedIn: 'root' })
export class KrosFormsService {
  private inputCommandsSubject = new Subject<TargetedInputCommand>();

  get inputCommandsTrigger(): Observable<TargetedInputCommand> {
    return this.inputCommandsSubject.asObservable();
  }

  triggerInputCommand(targets: string | string[], command: InputCommand): void {
    setTimeout(() => {
      if (!Array.isArray(targets)) {
        targets = [targets];
      }
      this.inputCommandsSubject.next({ targets, command });
    }, 0);
  }

  getCaretPosition(el: HTMLInputElement | HTMLTextAreaElement): number {
    let caretPos = 0;
    if (el.selectionStart || el.selectionStart === 0) {
      caretPos = el.selectionStart;
    }
    return caretPos;
  }

  setCaretPosition(el: HTMLInputElement | HTMLTextAreaElement, pos: number): void {
    if (el.setSelectionRange) {
      el.setSelectionRange(pos, pos);
    }
  }

  focusFirstErrorInForm(
    formGroup: UntypedFormGroup,
    controlNamesMapping: Map<string, string> = null,
    parentControlName?: string,
    parentControlIndex?: number
  ): void {
    function focusFirstErrorInFormInternal(
      context: KrosFormsService,
      formGroup: UntypedFormGroup,
      controlNamesMapping: Map<string, string> = null,
      parentControlName?: string,
      parentControlIndex?: number
    ): void {
      for (const key of Object.keys(formGroup.controls)) {
        if (stop) break;
        if (formGroup.controls[key] instanceof FormArray) {
          const formArray: FormArray = formGroup.controls[key] as FormArray;
          for (const [rowIndex, formArrayRow] of formArray.controls.entries()) {
            if (stop) break;
            focusFirstErrorInFormInternal(context, formArrayRow as FormGroup, null, key, rowIndex);
          }
        } else {
          if (formGroup.controls[key].invalid) {
            const mappedControlName: string = (controlNamesMapping) ? controlNamesMapping.get(key) : undefined;
            const controlName: string = parentControlName
              ? `${parentControlName}-${key}-${parentControlIndex}`
              : (mappedControlName ? mappedControlName : key);
            context.triggerInputCommand(controlName, { type: InputCommandType.FOCUS_INPUT });
            stop = true;
          }
        }
      }
    }

    let stop = false;
    focusFirstErrorInFormInternal(this, formGroup, controlNamesMapping, parentControlName, parentControlIndex);
  }

  blurInput(controlName: string): void {
    this.triggerInputCommand(controlName, { type: InputCommandType.DO_BLUR });
    this.triggerInputCommand(controlName, { type: InputCommandType.DETECT_CHANGES });
  }

  markFormGroupTouched(formGroup: UntypedFormGroup): void {
    (Object as any).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      control.updateValueAndValidity();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  clearFormStatus(form: UntypedFormGroup): void {
    form.markAsPristine();
    form.markAsUntouched();
  }
}
