<div class="kros-modal-mobile-arrow-back">
  <button
    class="btn btn-link arrow-back"
    tabindex="-1"
    (click)="close()"
  >
    <i class="material-icons secondary arrow-back-icon">arrow_back</i>
  </button>
  <hr class="hr-divider" />
</div>
<div class="kros-modal-header">
  <h4 class="modal-title">{{ 'SETTINGS.API.GENERATING_TOKEN' | translate }}</h4>
  <button
    class="btn btn-link close"
    tabindex="-1"
    (click)="close()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div class="kros-modal-body">
  <ng-container *ngIf="!inProgress;else skeleton">
    <p>{{ 'SETTINGS.API.GENERATED_TOKEN_SUCCESS' | translate }}{{copiedFromResult ?
      ('SETTINGS.API.AND_COPIED_TO_CLIPPBOARD' | translate) : ''}}.</p>
    <p class="text-danger">{{ 'SETTINGS.API.GENERATED_TOKEN_WARNING' | translate }}</p>
    <kros-form-field>
      <kros-label>{{ 'SETTINGS.API.GENERATED_TOKEN' | translate }}</kros-label>
      <input
        krosControl
        disabled
        [formControl]='tokenControl'
        class='form-control input-ellipsis'
        type='text'
      >
    </kros-form-field>
  </ng-container>
</div>

<div class="kros-modal-footer">
  <button
    type="button"
    class="btn btn-link btn-close"
    [disabled]="inProgress"
    (click)="close()"
  >{{ 'COMMON.CLOSE' | translate }}</button>

  <button
    type="button"
    class="btn btn-primary btn-submit"
    data-test="online-payments-connect-submit-button"
    [disabled]="inProgress"
    (click)="copyToken()"
  >
    <i
      *ngIf="copiedByUser"
      class="material-icons success"
    >done</i>
    {{ (copiedByUser ? 'SETTINGS.API.COPIED' : 'SETTINGS.API.COPY_TOKEN') | translate }}
  </button>
</div>

<ng-template #skeleton>
  <div class="skeleton-paragraph animate"></div>
  <div class="skeleton-paragraph animate"></div>
  <div class="skeleton-label animate"></div>
  <div class="skeleton-input animate"></div>
</ng-template>
