import { InjectionToken } from '@angular/core';

import { Observable } from 'rxjs';

import { KrosCompany } from '@kros-sk/models';

export const KROS_COMPANY_SELECTOR = new InjectionToken<KrosCompanySelector>('Kros Company Selector Service');

export interface KrosCompanySelector {

  /**
   * Currently loaded company entity
   */
  get currentCompany$(): Observable<KrosCompany | null>
}
