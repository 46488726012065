import { ActivatedRoute } from '@angular/router';
import { Component, inject, OnInit } from '@angular/core';

import { BrowserTabService, NavbarService } from '@kros-sk/ssw-shared-legacy';
import { getDecodedValue, StorageService } from '@kros-sk/ssw-cdk';

import { environment } from '../../environments/environment';

const docExplorerPanelCollapsedStorageItem = 'docExplorerPanelCollapsed';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  private route = inject(ActivatedRoute);
  private navbarService = inject(NavbarService);
  private storageService = inject(StorageService);
  private browserTabService = inject(BrowserTabService);

  documentId: string;
  panelCollapsed = false;

  ngOnInit(): void {
    this.browserTabService.setTitle(environment.location === 'cz' ? 'BIM Platforma' : 'DOCUMENTS.TITLE');
    this.navbarService.changeTitle(environment.location === 'cz' ? 'DOCUMENTS.TITLE_CZ' : 'DOCUMENTS.TITLE');
    this.navbarService.setNewsData({
      app: 'Titan',
      isTest: environment.news.isTest.toString(),
      modul: 'Dokumenty'
    });

    const documentId = this.route.snapshot.paramMap.get('id');
    this.panelCollapsed = JSON.parse(this.storageService.getItemFromLocalStorage(docExplorerPanelCollapsedStorageItem));

    if (documentId) {
      this.documentId = getDecodedValue(documentId);
    }
  }

  onPanelCollapsed(collapsed: boolean): void {
    this.panelCollapsed = collapsed;
    this.storageService.setItemToLocalStorage(docExplorerPanelCollapsedStorageItem, this.panelCollapsed.toString());
  }
}
