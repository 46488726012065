import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import * as actions from './license.actions';
import { LicenseState } from './license.state';

@Injectable()
export class LicenseDispatchersService {

  constructor(private store: Store<LicenseState>) { }

  loadMishaLicense(): void {
    this.store.dispatch(actions.loadMishaLicenseStart());
  }

  loadBuildingProgressLicense(returnToBuildingProgress?: boolean, returnToBudgetApproval?: boolean): void {
    this.store.dispatch(actions.loadBuildingProgressLicenseStart({ returnToBuildingProgress, returnToBudgetApproval }));
  }

  loadBudgetLicense(): void {
    this.store.dispatch(actions.loadBudgetLicenseStart());
  }

  loadQuantityManagerLicense(): void {
    this.store.dispatch(actions.loadQuantityManagerLicenseStart());
  }

  loadDesktopLicense(): void {
    this.store.dispatch(actions.loadDesktopLicenseStart());
  }

  loadLicenseDetail(): void {
    this.store.dispatch(actions.loadLicenseDetailStart());
  }

  activateTrialBuildingProgressLicense(isBudgetApproval?: boolean): void {
    this.store.dispatch(actions.activateTrialBuildingProgressLicense({ isBudgetApproval }));
  }

  activateBudgetTrialLicense(): void {
    this.store.dispatch(actions.activateTrialBudgetLicense());
  }

  activateQuantityManagerTrialLicense(): void {
    this.store.dispatch(actions.activateTrialQuantityManagerLicense());
  }
}
