import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { TagInputModule } from 'ngx-chips';

import {
  AnalyticsService,
  AvatarsModule,
  BootstrapModule,
  BottomNavbarComponent,
  CompanySearchModule,
  DirectivesModule,
  FileSizeService,
  LicenseModule,
  licenseReducer,
  LoaderModule,
  ModalsModule,
  PipesModule,
  ProjectInfoModule,
  TimelineModule,
  ToastModule,
  TranslateModule,
  UserSharingModule
} from '@kros-sk/ssw-shared-legacy';
import {
  CompaniesRegisterSearchModule,
  KrosComponentsModule,
  DirectivesModule as KrosDirectivesModule,
  KrosInputsModule,
  KrosModalModule
} from '@kros-sk/components';
import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { ColumnFilterDropdownComponent } from './column-filter-dropdown/column-filter-dropdown.component';
import { FilterComponent } from './components/filter/filter.component';
import { ItemDetailsComponent } from './components/item-details/item-details.component';
import { NotesComponent } from './components/notes/notes.component';
import { PanelContentComponent } from './components/panel-content/panel-content.component';
import { ProjectHeaderDataDialogComponent } from './components/project-header-data-dialog/project-header-data-dialog.component';
import { UploadBuildingStripComponent } from './components/upload-building-strip/upload-building-strip.component';
import { UserDataStatisticsComponent } from './components/user-data-statistics/user-data-statistics.component';

@NgModule({
  imports: [
    CdkTableModule,
    CommonModule,
    DirectivesModule,
    FormsModule,
    AvatarsModule,
    LicenseModule,
    LoaderModule,
    PipesModule,
    ReactiveFormsModule,
    BootstrapModule,
    StoreModule.forFeature('license', licenseReducer),
    TimelineModule,
    ToastModule,
    TranslateModule.forChild(),
    CdkTreeModule,
    KrosComponentsModule,
    KrosDirectivesModule,
    KrosInputsModule,
    UserSharingModule,
    CompaniesRegisterSearchModule,
    ModalsModule,
    ProjectInfoModule,
    CompanySearchModule,
    BottomNavbarComponent,
    TooltipDirective
  ],
  declarations: [
    UserDataStatisticsComponent,
    FilterComponent,
    ColumnFilterDropdownComponent,
    ItemDetailsComponent,
    PanelContentComponent,
    ProjectHeaderDataDialogComponent,
    UploadBuildingStripComponent,
    NotesComponent
  ],
  exports: [
    CdkTableModule,
    CommonModule,
    DirectivesModule,
    AvatarsModule,
    LicenseModule,
    LoaderModule,
    BootstrapModule,
    TagInputModule,
    UserDataStatisticsComponent,
    TimelineModule,
    ToastModule,
    KrosComponentsModule,
    KrosModalModule,
    ModalsModule,
    FilterComponent,
    ColumnFilterDropdownComponent,
    ItemDetailsComponent,
    PanelContentComponent,
    UserSharingModule,
    ProjectHeaderDataDialogComponent,
    UploadBuildingStripComponent,
    PipesModule,
    CompanySearchModule,
    BottomNavbarComponent,
    NotesComponent,
    TooltipDirective
  ],
  providers: [
    AnalyticsService,
    FileSizeService
  ]
})
export class SharedModule {
}
