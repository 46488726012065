import { Breakpoints, DeviceType } from '../types/device-types';

export function isMobile(width: number): boolean {
  return width <= Breakpoints.PHONE;
}

export function detectDeviceType(document?: Document): DeviceType {
  document ??= window?.document;
  const width = document?.documentElement?.clientWidth ?? 0;

  return isMobile(width) ? DeviceType.MOBILE : DeviceType.DESKTOP;
}
