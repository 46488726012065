import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Injector,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { Platform } from '@angular/cdk/platform';

import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { AutocompleteComponent } from '../../autocomplete/autocomplete.component';
import { DeviceDetectorService, GlobalEventsService } from '../../services';
import { InputCommand, InputCommandType } from '../../inputs/inputs.common.interface';
import { KrosFormsService } from '../../inputs/forms.service';
import { KrosInputBase } from '../inputs.common.base.component';
import { TextareaOptions } from './textarea.interface';

@Component({
  selector: 'kros-textarea-input',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => KrosTextareaComponent),
      multi: true
    },
    {
      provide: KrosInputBase,
      useExisting: forwardRef(() => KrosTextareaComponent)
    }
  ]
})
export class KrosTextareaComponent extends KrosInputBase implements OnInit, AfterViewInit, OnDestroy {
  @Input() options: TextareaOptions;

  @Output() selectItem: EventEmitter<any> = new EventEmitter();

  @ViewChild('autocomplete', { static: false }) autocomplete: AutocompleteComponent;

  currentOptions: TextareaOptions;
  formControl: UntypedFormControl;
  keyPress = new EventEmitter<KeyboardEvent>();
  autocompleteOptions: readonly any[] = [];
  showFooterTemplate = true;

  private userChange: boolean;
  private ignoreAutocompleteOnFocus: boolean;
  private optionRemoval: boolean;
  private initialScroll: number;
  private rect: DOMRect;
  private autocompleteTrigger$ = new Subject();
  private keyPressSubscription = new SubSink();
  private showSearchIndicatorSubject = new BehaviorSubject<boolean>(false);
  private mutationObserver;
  showSearchIndicator$: Observable<boolean> = this.showSearchIndicatorSubject.asObservable();

  @HostListener('focusout') focusout = (): void => {
    setTimeout(() => {
      this.input.nativeElement.scrollLeft = 0; // Firefox fix, don't ask
    }, 0);

    setTimeout(() => {
      if (this.optionRemoval) {
        this.optionRemoval = false;
      } else if (this.platform.IOS) {
        this.autocompleteOptions = [];
        this.detectChanges();
        this.input.nativeElement.blur();
      }
    }, 500); // timeout is avoiding bluring input before selecting drodpown option
  };

  @HostListener('keydown', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === 'Tab') {
      this.input.nativeElement.focus();
      this.hovered = false;
    }
  }

  constructor(
    protected cd: ChangeDetectorRef,
    protected globalEventsService: GlobalEventsService,
    protected injector: Injector,
    protected formsService: KrosFormsService,
    protected deviceDetector: DeviceDetectorService,
    private platform: Platform,
    private scrollDispatcher: ScrollDispatcher
  ) {
    super(cd, globalEventsService, injector, formsService, deviceDetector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initFormControl();
    this.listenForFormControlChanges();
    if (this.currentOptions.autocomplete) {
      this.initAutocomplete();
    }
    this.listenForMainControlChanges();

    // used mutation observer, because resizeObserver is missing in current typescript ("typescript": "^4.1.5") and
    // it is not supported by IOS < 13
    // on resize style attribute is changed, so close only if it changed
    this.mutationObserver = new MutationObserver(mutations => {
      if (mutations.find(x => x.attributeName === 'style')) {
        this.formsService.triggerInputCommand(this.options.name, {
          type: InputCommandType.CLOSE_AUTOCOMPLETE,
          data: true,
        });
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.mutationObserver.disconnect();
  }

  ngAfterViewInit(): void {
    this.subs.sink = fromEvent(this.container.nativeElement, 'autoCompleteOptionsStateChanged')
      .pipe(filter((event: CustomEvent) => event.detail.action === 'opened'))
      .subscribe((_: CustomEvent) => this.detectChanges());

    this.mutationObserver.observe(this.input.nativeElement, { attributes: true });

  }

  doBlur(event: FocusEvent): void {
    if (this.autocomplete && (this.forceBlur || (!this.hovered && !this.autocomplete?.optionHovered))) {
      this.autocomplete.closeModal$.next(true);
      super.doBlur(event);
      this.mainControl.markAsTouched();
      this.formControl.markAsTouched();
      this.showSearchIndicatorSubject.next(false);
      this.keyPressSubscription.unsubscribe();
      this.forceBlur = false;
    } else {
      this.mainControl.markAsTouched();
      this.formControl.markAsTouched();
      this.focused = false;
    }
  }

  doFocus(event: FocusEvent): void {
    super.doFocus(event);
    // when user selects text for multiple lines, scroll it to top
    this.input.nativeElement.scrollTo(0, 0);
    if (this.ignoreAutocompleteOnFocus) {
      this.ignoreAutocompleteOnFocus = false;
    } else if (this.currentOptions.autocomplete?.openOnFocus) {
      this.autocompleteTrigger$.next(this.mainControl.value);
    }

    this.keyPressSubscription.sink = this.globalEventsService.listenEvent('document:keydown').subscribe((evt) => {
      this.keyPress.emit(evt as KeyboardEvent);
      this.detectChanges();
    });
    // timeout to give time to scroll input to correct position before start listening to scroll event...
    setTimeout(() => this.subscribeScrollDispatcher(), 500);
  }

  selectedItem(event: any): void {
    this.forceBlur = true;
    this.doBlur(new FocusEvent('blur'));
    this.selectItem.emit(event);
  }

  protected handleCommand(command: InputCommand): void {
    if (command.type === InputCommandType.CLOSE_AUTOCOMPLETE && this.currentOptions.autocomplete) {
      this.autocompleteOptions = [];
      this.showFooterTemplate = false;
      this.detectChanges();
      this.input.nativeElement.blur();
    } else if (command.type === InputCommandType.REMOVE_AUTOCOMPLETE_OPTION) {
      if (typeof command.data?.value !== 'undefined') {
        this.autocompleteOptions = this.autocompleteOptions.filter((item) => {
          if (command.data.comparator) {
            return !command.data.comparator(item, command.data.value);
          }
          return item !== command.data.value;
        });
        this.ignoreAutocompleteOnFocus = true;
        this.optionRemoval = true;
        this.detectChanges();
        this.input.nativeElement.focus();
      } else {
        console.error('No value passed to REMOVE_AUTOCOMPLETE_OPTION command!');
      }
    } else if (command.type === InputCommandType.DETECT_CHANGES) {
      this.formControl.setValue(this.mainControl.value, { emitEvent: false }); // never emit event to avoid cycling
      this.detectChanges();
    } else if (command.type === InputCommandType.DETECT_RESIZE) {
      this.initialScroll = null;
      this.detectChanges();
    } else {
      super.handleCommand(command);
    }
  }

  private initFormControl(): void {
    this.formControl = new UntypedFormControl({
      value: this.mainControl.value,
      disabled: this.mainControl.disabled
    }, { updateOn: this.mainControl.updateOn });
  }

  private listenForFormControlChanges(): void {
    this.subs.sink = this.formControl.valueChanges.subscribe((newValue) => {
      this.mainControl.markAsTouched();
      this.formControl.markAsTouched();
      this.handleValueEmits(newValue);
    });
  }

  private listenForMainControlChanges(): void {
    // listen changes form outside
    this.subs.sink = this.mainControl.valueChanges.subscribe((newValue) => {
      if (this.formControl.disabled && this.mainControl.enabled) {
        this.formControl.enable({ emitEvent: false });
      } else if (this.formControl.enabled && this.mainControl.disabled) {
        this.formControl.disable({ emitEvent: false });
      }
      if (this.userChange) {
        this.userChange = false;
      } else {
        this.setFormControlFromMainControlValue(newValue);
      }
    });
  }

  private setFormControlFromMainControlValue(value: any): void {
    value = this.removeForbiddenCharacters(value);
    this.formControl.setValue(value, { emitEvent: false }); // never emit event to avoid cycling
  }

  protected handleValueEmits(newValue: string): void {
    const caretPosition = this.formsService.getCaretPosition(this.input.nativeElement);
    const transformedValue = this.removeForbiddenCharacters(newValue);
    this.formControl.setValue(transformedValue, { emitEvent: false });
    this.formsService.setCaretPosition(this.input.nativeElement, caretPosition);
    if (transformedValue !== this.mainControl.value) {
      this.userChange = true;
      this.mainControl.setValue(transformedValue);
      this.mainControl.markAsDirty();
    }
  }

  private initAutocomplete(): void {
    const mainCtrlChanges = this.mainControl.valueChanges.pipe(
      filter(term => term.length && this.userChange),
      debounceTime(300),
      distinctUntilChanged(),
    );
    const changes$ = merge(mainCtrlChanges, this.autocompleteTrigger$.asObservable());
    this.subs.sink = this.currentOptions.autocomplete.searchMethod(changes$.pipe(
      filter(() => !!this.autocomplete),
      tap((value: string) => {
        this.autocompleteOptions = [];
        this.showFooterTemplate = false;
        this.autocomplete.reset();
        if (this.focused && this.showSearchIndicator(value)) {
          this.showSearchIndicatorSubject.next(true);
        }
      })
    ))
      .subscribe(options => {
        this.showSearchIndicatorSubject.next(false);
        this.autocompleteOptions = !this.focused ? [] : options; // Stops dropdown from opening, if user quickly tabs through the input
        this.showFooterTemplate = this.focused;
        const action = this.autocompleteOptions.length === 0 ? 'noOptions' : 'optionsAdded';
        this.autocomplete.emitAutoCompleteOptionsStateChanged(action);
        this.detectChanges();
        this.autocomplete.setActiveOptions(this.mainControl.value, this.currentOptions.autocomplete?.valueAttribute);
        this.detectChanges();
      });
  }

  private showSearchIndicator(value: string): boolean {
    if (this.currentOptions.autocomplete.searchIndicatorShownFrom) {
      return value.length >= this.currentOptions.autocomplete.searchIndicatorShownFrom;
    }
    return true;
  }

  private subscribeScrollDispatcher(): void {
    this.keyPressSubscription.sink = this.scrollDispatcher.scrolled().subscribe((x: CdkScrollable) => {
      let scrollTop = window.pageYOffset || window.scrollY;
      if (x) {
        scrollTop = x.getElementRef().nativeElement.scrollTop;
      }
      if (!this.initialScroll) {
        this.initialScroll = scrollTop;
        this.rect = this.container.nativeElement.getBoundingClientRect();
      }
      this.hideIfNotVisible(scrollTop);
    });
  }

  private hideIfNotVisible(scrollHeight: number): void {
    if (this.focused) {
      if (!this.platform.IOS &&
        (
          this.rect.top + this.initialScroll < scrollHeight - this.rect.height ||
          this.rect.top + this.initialScroll > scrollHeight + this.deviceDetector.windowHeight ||
          this.isOverlapped()
        )) {
        this.autocompleteOptions = [];
        this.detectChanges();
        this.input.nativeElement.blur();
      }
    }
  }
}
