<app-upload-building-strip *ngIf="(documentsSelectorsService.isUploadingMode$ | async)">
</app-upload-building-strip>

<div class="project-list-wrapper">
  <app-project-panel-container
    *ngIf="filterShown"
    class="project-filters"
    [filterIndex]="filterIndex"
    (projectCreated)="onCreateProject()"
    (filterChanged)="onFilterChanged($event)"
  ></app-project-panel-container>
  <div
    class="project-list"
    [class.filter-hidden]="!filterShown"
  >
    <app-project-toolbar
      class="toolbar-actions"
      [selectedProject]="selectedProject"
      [projects]="dataSource.data"
      [filterIndex]="filterIndex"
      [tagsList]="tagsList"
      [activeFilterTagIds]="activeFilterTagIds"
      (demoProjectPublish)="onPublishDemoProject()"
      (projectOpened)="onOpenProject($event)"
      (projectShared)="onShareProject($event)"
      (projectCopy)="onCopyProject($event)"
      (projectDeleted)="onDeleteProject($event)"
      (projectRemovedSharing)="onRemoveProjectSharing($event)"
      (projectDetailOpened)="onOpenProjectDetail($event)"
      (projectTagsOpened)="onOpenProjectTags($event.project, $event.element)"
      (filterChanged)="onFilterChanged($event)"
      (tagFilterChanged)="onTagFilterChanged($event)"
    ></app-project-toolbar>

    <div class="new-button-container tablet-visible">
      <button
        class="btn btn-primary btn-new"
        type="button"
        (click)="onCreateProject()"
      >
        <i class="icon material-icons">add</i>
        <span class="text">{{'PROJECTS.ZOZNAM.NEW_PROJECT' | translate}}</span>
      </button>
    </div>

    <div class="projects">
      <div
        class="projects-table"
        [class.filter-hidden]="!filterShown"
      >
        <ng-container *ngIf="isCreatingDemoOrCopyingProject">
          <div class="timeline-wrapper">
            <div class="timeline-item shadow">
              <h2 class="demo-project-header">{{ projectCopyLabel }}
              </h2>
              <div class="animated-background">
                <div class="background-masker header-top"></div>
                <div class="background-masker subheader-bottom"></div>
                <div class="background-masker content-top"></div>
                <div class="background-masker content-first-end"></div>
                <div class="background-masker content-second-line"></div>
                <div class="background-masker content-second-end"></div>
                <div class="background-masker content-third-end"></div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!isExampleCardVisible() && !isCreatingDemoOrCopyingProject">
          <ng-container *ngIf="areProjectsLoaded; else contentLoader">

            <div class="table-div">
              <table
                class="table"
                cdk-table
                [dataSource]="dataSource"
              >

                <ng-container cdkColumnDef="name">
                  <th
                    cdk-header-cell
                    *cdkHeaderCellDef
                    id="name"
                    sortable="name"
                    (sort)="onSort($event)"
                    data-test="project-list-name"
                  >{{ 'PROJECTS.ZOZNAM.NAME' | translate | uppercase }}</th>
                  <td
                    cdk-cell
                    *cdkCellDef="let project"
                  >
                    <div
                      class="tablet-hidden"
                      data-test="desktop-name-column-content"
                    >
                      <div class="project-name">
                        <span>{{ project.name }}</span>

                        <kros-tags
                          [tags]="project.userTags"
                          (tagClicked)="onOpenProjectTags(project)"
                        ></kros-tags>
                      </div>
                    </div>

                    <div
                      class="project-mobile"
                      data-test="mobile-name-column-content"
                      (click)="$event.stopPropagation(); onOpenProject(project);"
                    >
                      <div>
                        <div class="project-name">
                          <span data-test="project-name">
                            {{ project.name }}
                          </span>
                        </div>

                        <kros-tags [tags]="project.userTags"></kros-tags>

                        <div class="project-owner-date-created">
                          {{ getProjectOwner(project.owner) }}&nbsp;
                          <!--                                                    |&nbsp;{{ project.dateCreated | date: 'dd. MM. yyyy' }}-->

                          <div
                            class="sharing"
                            *ngIf="project.sharings.length > 0"
                          >
                            <kros-avatar-list
                              class="avatars"
                              [notOwnerUsers]="project.sharings"
                              [maxAvatarsLimit]="2"
                            ></kros-avatar-list>
                          </div>
                        </div>
                      </div>

                      <button
                        [krosDropdownTriggerFor]="projectMenu"
                        id="projectMenu"
                        class="btn no-padding"
                        type="button"
                        (click)="$event.stopPropagation()"
                      >
                        <i class="material-icons">more_vert</i>
                      </button>

                      <ng-template #projectMenu>
                        <div
                          kros-dropdown
                          (click)="$event.stopPropagation()"
                        >
                          <ng-container *ngIf="isOwnersEmail(project.owner)">
                            <button
                              kros-dropdown-item
                              class="share"
                              type="button"
                              (click)="onShareProject(project)"
                            >
                              <i class="navbar-mat-icon material-icons">person_add</i>
                              <span> {{'PROJECTS.ZOZNAM.SHARE' | translate}} </span>
                            </button>
                          </ng-container>

                          <button
                            kros-dropdown-item
                            class="tags"
                            type="button"
                            (click)="onOpenProjectTags(project)"
                          >
                            <i class="navbar-mat-icon material-icons">label</i>
                            <span> {{'PROJECTS.ZOZNAM.TAGS' | translate}} </span>
                          </button>

                          <div class="divider-horizontal"></div>

                          <button
                            kros-dropdown-item
                            class="info"
                            type="button"
                            (click)="onOpenProjectDetail(project)"
                          >
                            <i class="navbar-mat-icon material-icons">info</i>
                            <span> {{'PROJECTS.ZOZNAM.PROJECT_INFO' | translate}}
                            </span>
                          </button>

                          <ng-container *ngIf="isOwnersEmail(project.owner)">
                            <div class="divider-horizontal"></div>
                            <button
                              kros-dropdown-item
                              type="button"
                              data-test="more-project-copy-button"
                              (click)="onCopyProject(project)"
                            >
                              <i class="navbar-mat-icon material-icons">file_copy</i>
                              <span>{{'PROJECTS.ZOZNAM.COPY_PROJECT' | translate}}</span>
                            </button>
                          </ng-container>

                          <ng-container *ngIf="isOwnersEmail(project.owner)">
                            <button
                              kros-dropdown-item
                              class="delete"
                              type="button"
                              (click)="onDeleteProject(project)"
                            >
                              <i class="navbar-mat-icon material-icons">delete</i>
                              <span>{{'PROJECTS.ZOZNAM.DELETE' | translate}}</span>
                            </button>
                          </ng-container>
                        </div>
                      </ng-template>
                    </div>
                  </td>
                </ng-container>

                <ng-container cdkColumnDef="sharing">
                  <th
                    cdk-header-cell
                    *cdkHeaderCellDef
                    id="sharing"
                  ></th>
                  <td
                    cdk-cell
                    *cdkCellDef="let project"
                  >
                    <kros-avatar-list
                      *ngIf="showAvatarList(project.buildingProgressPermissionType)"
                      class="avatars"
                      [notOwnerUsers]="project.sharings"
                      [maxAvatarsLimit]="2"
                      (click)="onShareProject(project)"
                    ></kros-avatar-list>
                  </td>
                </ng-container>

                <ng-container cdkColumnDef="owner">
                  <th
                    cdk-header-cell
                    *cdkHeaderCellDef
                    id="owner"
                    sortable="owner"
                    (sort)="onSort($event)"
                    data-test="project-list-owner"
                  >{{ 'PROJECTS.ZOZNAM.OWNER' | translate | uppercase }}</th>
                  <td
                    cdk-cell
                    *cdkCellDef="let project"
                  > <span krosTooltip="{{ getOwnerTooltip(project.owner) }}">{{
                      getProjectOwner(project.owner) }}</span>
                  </td>
                </ng-container>

                <ng-container cdkColumnDef="dateCreated">
                  <th
                    cdk-header-cell
                    *cdkHeaderCellDef
                    id="dateCreated"
                    sortable="dateCreated"
                    (sort)="onSort($event)"
                    data-test="project-list-date-created"
                  >{{ 'PROJECTS.ZOZNAM.DATE_CREATED' | translate | uppercase }} </th>
                  <td
                    cdk-cell
                    *cdkCellDef="let project"
                  > {{ project.dateCreated | date: 'dd. MM. yyyy' }}</td>
                </ng-container>

                <tr
                  cdk-header-row
                  *cdkHeaderRowDef="['name', 'sharing', 'owner', 'dateCreated']"
                ></tr>
                <tr
                  cdk-row
                  *cdkRowDef="let project; columns: ['name', 'sharing', 'owner', 'dateCreated']"
                  id="row-{{project.id}}"
                  (click)="selectProject(project)"
                  (dblclick)="onOpenProject(project)"
                  (tap)="selectProject(project)"
                  [ngClass]="{'selected':project && selectedProject && project.id === selectedProject.id}"
                  data-test="project-row"
                  krosAnalytic
                  eventCategory="projekt"
                  eventAction="otvorit-projekt"
                  eventLabel="{{project.name}}"
                  [analyticDbClick]="true"
                ></tr>
              </table>
            </div>
          </ng-container>

          <ng-template #contentLoader>
            <kros-timeline [type]="timelineType.Table"></kros-timeline>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="isExampleCardVisible() && !isCreatingDemoOrCopyingProject">
          <div class="example-page">
            <h2 *ngIf="filterIndex === 1 && activeFilterTagIds.length === 0; else emptyFilter">
              {{ 'PROJECTS.ZOZNAM.ZACNITE_ZALOZENIM' | translate }}
            </h2>
            <ng-template #emptyFilter>
              <h2>
                {{ 'PROJECTS.ZOZNAM.EMPTY_FILTER' | translate }}
              </h2>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>

  </div>
</div>
