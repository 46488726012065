import { ActivatedRoute, Router } from '@angular/router';
import { Component, DestroyRef, HostListener, inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { catchError, filter, map, Observable, of, Subject, switchMap, take, timeout } from 'rxjs';

import {
  adminDemoProjectUserId,
  RegistrationNotificationService,
  StorageService,
  UserService
} from '@kros-sk/ssw-cdk';
import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { ApprovalHelper } from '@kros-sk/ssw-shared/utils/building-progress';
import { BuildingProgressPermissionType, PermissionType } from '@kros-sk/ssw-shared/permission';
import {
  isOwnersEmail,
  Project,
  ProjectCreateService,
  ProjectFilter,
  SortableTableHeaderDirective,
  SortEvent,
  TableDataSource,
  Tag,
  TagEditModel,
  TagsModalActionService,
  TimelineType,
  ToastService,
  ToastType,
  TranslateService
} from '@kros-sk/ssw-shared-legacy';
import { KrosModalCloseEvent } from '@kros-sk/components';

import { ConnectorService } from '../../../core/services/connector.service';
import { DocumentsDispatchersService, DocumentsSelectorsService } from '../../../store/documents';
import { environment } from '../../../../environments/environment';
import { hasContractorRight } from '../../../building-progress/shared/building-progress-utils';
import { ProjectListHelper } from '../../helpers/project-list.helper';
import { ProjectsDispatchersService, ProjectsSelectorsService } from '../../../store/projects';
import { ProjectSharingService } from '../../services/project-sharing.service';
import { ProjectsService } from '../../services/projects.service';
import { ProjectTourService } from '../../services/project-tour.service';

const selectedProjectIdKey = 'selectedProjectId';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
  providers: [ProjectListHelper, ProjectTourService]
})
export class ProjectListComponent implements OnInit, OnDestroy {

  protected documentsSelectorsService = inject(DocumentsSelectorsService);
  private appInsightsService = inject(AppInsightsBaseService);
  private connectorService = inject(ConnectorService);
  private documentsDispatchersService = inject(DocumentsDispatchersService);
  private notificationService = inject(RegistrationNotificationService);
  private projectService = inject(ProjectsService);
  private projectCreateService = inject(ProjectCreateService);
  private projectSharingService = inject(ProjectSharingService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private toastService = inject(ToastService);
  private translateService = inject(TranslateService);
  private storage = inject(StorageService);
  private userService = inject(UserService);
  private tourService = inject(ProjectTourService);
  private approvalHelper = inject(ApprovalHelper);
  private projectListHelper = inject(ProjectListHelper);
  private dispatchersService = inject(ProjectsDispatchersService);
  private selectorsService = inject(ProjectsSelectorsService);
  private tagModalActionService = inject(TagsModalActionService);
  private destroyRef = inject(DestroyRef);
  private filterChangedSubject = new Subject<ProjectFilter>();

  @ViewChildren(SortableTableHeaderDirective) headers: QueryList<SortableTableHeaderDirective>;

  areProjectsLoaded = false;
  dataSource: TableDataSource<Project>;
  isCreatingDemoProject = false;
  isCopyingProject = false;
  selectedProject: Project;
  filterIndex = ProjectFilter.All;
  filterShown = true;
  timelineType = TimelineType;
  tagsList: Tag[] = [];
  activeFilterTagIds: number[] = [];

  get isCreatingDemoOrCopyingProject(): boolean {
    return this.isCopyingProject || this.isCreatingDemoProject;
  }

  get projectCopyLabel(): string {
    return this.translateService.translate(
      this.isCopyingProject ? 'PROJECTS.ZOZNAM.PROJECT_COPYING' : 'PROJECTS.ZOZNAM.DEMO_PROJECT_CREATING');
  }

  ngOnInit(): void {
    this.projectListHelper.setTitleAndNavbar();
    this.dataSource = new TableDataSource<Project>(environment.location);

    this.setSelectedProject(undefined);
    this.connectorService.connect(this.userService.getUserIdentityId() + '');
    if (this.route.snapshot.data.isUploadMode) {
      this.documentsDispatchersService.loadUploadingData(this.route.snapshot.paramMap.get('temporaryId'));
    }
    this.activeFilterTagIds = this.projectListHelper.getActiveFilterTagIds();
    this.notificationService.userRegistrationCompleted$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((p) => {
        if (p.registrationCompleted) {
          if (p.demoProjectRequested) {
            this.demoProjectIsCreating();
          } else {
            this.refreshProjectsOnInit();
          }
        }
      });
    this.selectorsService.tagsList$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((tagsList) => this.tagsList = tagsList);
    this.selectorsService.newTagCreated$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((newTag: Tag) => {
        if (newTag && this.selectedProject) {
          const tags = [...this.selectedProject.userTags];
          tags.push(newTag);
          this.onProjectTagEdit(this.selectedProject.id, tags, false, isOwnersEmail(this.selectedProject.owner));
          this.dispatchersService.clearNewTagCreated();
        }
      });
    this.filterChangedSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(filter => this.onFilterChanged(filter));

    this.projectListHelper.setMobileDropdownNavItems(this.filterChangedSubject);
    this.dispatchersService.loadTagsList();
  }

  private demoProjectIsCreating(): void {
    this.isCreatingDemoProject = true;
    this.notificationService.demoProjectCreated$
      .pipe(
        timeout({ each: 20000, with: () => this.getRefreshProjects(() => this.isCreatingDemoProject = false) }),
        take(2),
        filter(p => !!p),
        switchMap(() => this.getRefreshProjects(() => this.isCreatingDemoProject = false)),
        takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.tourService.clear();
  }

  @HostListener('document:keydown', ['$event']) onKeyDownHandler(event: KeyboardEvent): void {
    const isModalOpened = document.body.classList.contains('modal-opened');
    if (event.key && this.selectedProject && !isModalOpened) {
      const projectIndex = this.projectListHelper.getIndexOfProject(this.selectedProject, this.dataSource.data);
      switch (event.key.toLowerCase()) {
        case 'enter':
          this.onOpenProject(this.selectedProject);
          break;
        case 'arrowup':
          if (projectIndex > 0) {
            this.selectProject(this.dataSource.data[projectIndex - 1]);
          }
          break;
        case 'arrowdown':
          if (projectIndex < this.dataSource.data.length - 1) {
            this.selectProject(this.dataSource.data[projectIndex + 1]);
          }
          break;
      }
    }
  }

  private refreshProjectsOnInit(): void {
    this.refreshProjects(() => {
      this.onSort({ column: '', direction: '' });

      let selectedProjectId = +this.route.snapshot.queryParamMap.get('projectId');
      if (selectedProjectId === undefined) {
        selectedProjectId = +this.storage.getItemFromSessionStorage(selectedProjectIdKey);
      }
      if (selectedProjectId > 0) {
        this.setSelectedProject(this.dataSource.data.find(p => p.id === selectedProjectId));
        this.projectListHelper.scrollIntoSelectedProject(selectedProjectId);
      }
    });
  }

  onCreateProject(): void {
    this.projectCreateService.onCreateProject('P-create-project-side-panel')
      .pipe(
        take(1),
        filter(p => !!p)
      ).subscribe(project => {
        this.selectProjectAndResetFilter(project);
      });
  }

  onCopyProject(project: Project): void {
    if (project.permissionType !== PermissionType.Owner) {
      this.projectListHelper.openCannotCopyProjectDialog();
    } else {
      this.isCopyingProject = true;
      this.projectService.copyProject(project).pipe(take(1)).subscribe((p) => {
        this.refreshProjects(() => {
          this.isCopyingProject = false;
          this.selectProjectAndResetFilter(p);
        });
        this.appInsightsService.trackEvent('P-project-copy');
      });
    }
  }

  onDeleteProject(project: Project): void {
    if (!project.isDemo || (this.userService.userId === adminDemoProjectUserId && this.dataSource.data.filter(p => p.isDemo).length > 1)) {
      this.projectService.getProjectState(project.id).pipe(take(1)).subscribe(state => {
        this.projectListHelper.openDeleteProjectDialog(
          project,
          state.hasQuantityManagerVersions,
          (p: Project) => this.onDeleteProjectCore(p));
      });
    } else {
      this.projectListHelper.openCannotDeleteDemoProjectDialog();
    }
  }

  private onDeleteProjectCore(project: Project): void {
    this.projectService.deleteProject(project.id).pipe(take(1)).subscribe({
      next: () => this.deleteProjectFromDataSource(project),
      error: () => catchError(this.handleError.bind(this))
    });
  }

  onRemoveProjectSharing(project: Project): void {
    this.projectService.getProjectState(project.id)
      .pipe(
        take(1),
        switchMap((state) => {
          if (state.isMultiStageApprovalModeEnabled && this.approvalHelper.isApprover(project.buildingProgressPermissionType)) {
            this.projectListHelper.openCannotRemoveProjectSharingDialog();
            return of(null);
          } else {
            return this.projectListHelper.openRemoveProjectSharingDialog(project).afterClosed$;
          }
        }),
        filter((result: KrosModalCloseEvent<any>) => result?.type === 'submit'),
        switchMap(() => this.projectSharingService.removeSharing(project.id)),
        takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.deleteProjectFromDataSource(project));
  }

  getProjectOwner(owner: string): string {
    return isOwnersEmail(owner) ? this.translateService.translate('PROJECTS.ZOZNAM.ME') : owner;
  }

  getOwnerTooltip(owner: string): string {
    return this.translateService.translate('PROJECTS.ZOZNAM.VLASTNIK_PROJEKTU') + ': ' + this.getProjectOwner(owner);
  }

  onShareProject(project: Project): void {
    if (project) {
      this.router.navigate(['projects/project-detail', project.id, 'members']);
    }
  }

  isExampleCardVisible(): boolean {
    return this.areProjectsLoaded ? this.dataSource.data.length === 0 : false;
  }

  private refreshProjects(callback?: Function): void {
    this.getRefreshProjects(callback).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  private getRefreshProjects(callback?: Function): Observable<any> {
    return this.projectService.getProjects(this.activeFilterTagIds)
      .pipe(take(1), map((projects: Project[]) => {
        this.dataSource.setDataWithSort(this.projectListHelper.getFilteredProjects(projects, this.filterIndex), this.headers);
        if (callback) {
          callback();
        }
        this.areProjectsLoaded = true;
        this.tourService.runTourIfCan();
      }));
  }

  private deleteProjectFromDataSource(project: Project): void {
    const projects = [...this.dataSource.data];
    const projectIndex = this.projectListHelper.getIndexOfProject(project, this.dataSource.data);
    projects.splice(projectIndex, 1);
    this.dataSource.setData(projects);
    this.selectDefaultProject();
  }

  private handleError(error: Error): void {
    this.toastService.open(error.message, ToastType.Error);
  }

  onSort({ column, direction }: SortEvent): void {
    this.dataSource.sort({ column, direction }, this.headers);
  }

  selectProject(project: Project): void {
    if (!this.selectedProject || (project && project.id !== this.selectedProject.id)) {
      this.setSelectedProject(project);
      this.storage.setItemToSessionStorage(selectedProjectIdKey, this.selectedProject.id.toString());
    }
  }

  onPublishDemoProject(): void {
    this.userService.publishDemoProject().pipe(take(1)).subscribe();
  }

  onOpenProject(project: Project): void {
    this.documentsSelectorsService.isUploadingMode$.pipe(take(1))
      .subscribe(value => this.projectListHelper.openProject(project, value));
  }

  onFilterChanged(projectFilter: ProjectFilter): void {
    this.filterIndex = projectFilter;
    this.projectService.getProjects(this.activeFilterTagIds)
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((projects: Project[]) => {
        this.dataSource.setData(this.projectListHelper.getFilteredProjects(projects, this.filterIndex));
        if (this.selectedProject) {
          const projectToSelect = this.dataSource.data.find(p => p.id === this.selectedProject.id);
          this.setSelectedProject(projectToSelect ? projectToSelect : undefined);
        }
        if (!this.selectedProject) {
          this.selectDefaultProject();
        }
        this.onSort({ column: '', direction: '' });
        this.areProjectsLoaded = true;
      });
  }

  onTagFilterChanged(tagIds: number[]): void {
    this.appInsightsService.trackEvent('P-tag-filter-change');
    this.activeFilterTagIds = tagIds;
    this.storage.setItemToLocalStorage(this.projectListHelper.tagsFilterStorageItemKey, JSON.stringify(this.activeFilterTagIds));
    this.refreshProjects();
  }

  private selectDefaultProject(): void {
    if (this.dataSource.data.length > 0) {
      this.selectProject(this.dataSource.data[0]);
    } else {
      this.setSelectedProject(undefined);
      this.storage.removeItemFromSessionStorage(selectedProjectIdKey);
    }
  }

  onOpenProjectDetail(project: Project): void {
    this.projectListHelper.openProjectDetail(project, this.dataSource.data);
  }

  isOwnersEmail(email: string): boolean {
    return isOwnersEmail(email);
  }

  onProjectTagEdit(projectId: number, tags: Tag[], deletedSomeTags: boolean, isOwner: boolean): void {
    const tagEditModel: TagEditModel = this.projectListHelper.getTagEditModel(projectId, tags, isOwner);
    this.projectService.editProjectTag(tagEditModel).pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.activeFilterTagIds.length > 0 && deletedSomeTags) {
          this.refreshProjects();
        } else {
          this.dataSource.setData(this.projectListHelper.getProjectsWithEditedTags(this.dataSource.data, projectId, tags));
        }
        if (this.selectedProject && this.selectedProject.id === tagEditModel.projectId) {
          this.selectedProject.userTags = this.projectListHelper.getSortedTags(tags);
        }
      });
  }

  onOpenProjectTags(project: Project, element?: HTMLElement): void {
    this.tagModalActionService.tagsListUpdated = this.selectorsService.tagsList$;
    this.tagModalActionService.showProjectTagsModal(isOwnersEmail(project.owner), this.tagsList, project.userTags, element);
    this.tagModalActionService.tagsEdited.pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((result: { newActiveTags: Tag[], deletedSomeTags: boolean }) => {
        this.appInsightsService.trackEvent('P-project-tags-edit');
        this.onProjectTagEdit(project.id, result.newActiveTags, result.deletedSomeTags, isOwnersEmail(project.owner));
      });
    this.tagModalActionService.tagCreated.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result: { tag: Tag, setTagToProject: boolean }) => {
        this.appInsightsService.trackEvent('P-tag-create', { label: result.tag.name });
        this.dispatchersService.createNewTag(result);
      });
    this.tagModalActionService.tagEdited.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((tag: Tag) => {
        if (this.selectedProject) {
          this.appInsightsService.trackEvent('P-tag-edit', { label: tag.name });
          this.dataSource.setData(this.projectListHelper.getProjectsAfterTagEdited(this.dataSource.data, tag));
          this.dispatchersService.editTag(tag);
        }
      });
  }

  showAvatarList(buildingProgressPermissionType: BuildingProgressPermissionType): boolean {
    // eslint-disable-next-line no-bitwise
    return (buildingProgressPermissionType | BuildingProgressPermissionType.Approver) !== buildingProgressPermissionType &&
      !hasContractorRight(buildingProgressPermissionType);
  }

  private setSelectedProject(project: Project): void {
    this.selectedProject = project;
    this.storage.setItemToSessionStorage('settings-project-id', this.selectedProject?.id?.toString() ?? '0');
  }

  private selectProjectAndResetFilter(project: Project): void {
    this.selectProject(project);
    this.activeFilterTagIds = [];
    this.storage.removeItemFromLocalStorage(this.projectListHelper.tagsFilterStorageItemKey);
    this.onFilterChanged(ProjectFilter.All);
  }
}
