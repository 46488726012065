import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnDestroy, Renderer2 } from '@angular/core';

import { filter } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { DisableElementType } from '@kros-sk/models';
import { ErrorsSelectorService } from '@kros-sk/core';
import { KrosEswSignalRService } from '@kros-sk/auth';

@Directive({
  selector: '[krosDisableElement]'
})
export class DisableElementDirective implements AfterViewInit, OnDestroy {

  @Input({
    transform: (value: DisableElementType[] | string): DisableElementType[] => {
      if (Array.isArray(value)) {
        return value;
      }

      return [DisableElementType.SearchNotWorking, DisableElementType.SignalRNotWorking];
    }
  }) krosDisableElement: DisableElementType[] = [DisableElementType.SearchNotWorking, DisableElementType.SignalRNotWorking];

  private stopClickPropagation = false;
  private subs = new SubSink();
  private reasonsForDisabling = new Set<DisableElementType>();

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private signalRService: KrosEswSignalRService,
    private errorsStateSelector: ErrorsSelectorService
  ) {
  }

  ngAfterViewInit(): void {

    this.subs.sink = this.signalRService.isSignalRUnavailable.pipe(
      filter((signalRUnavailable) => signalRUnavailable !== null)
    ).subscribe(signalRUnavailable => {
      if (!this.krosDisableElement.includes(DisableElementType.SignalRNotWorking)) return;
      else if (!signalRUnavailable) this.enable(DisableElementType.SignalRNotWorking);
      else {
        this.disable(DisableElementType.SignalRNotWorking);
      }
    });

    this.subs.sink = this.errorsStateSelector.selectConcreteServiceDown$('search').pipe(filter(error => !!error))
      .subscribe(error => {
        if (!this.krosDisableElement.includes(DisableElementType.SearchNotWorking)) {
          return;
        }
        if (error.isDown) {
          this.disable(DisableElementType.SearchNotWorking);
        } else {
          this.enable(DisableElementType.SearchNotWorking);
        }
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private disable(type: DisableElementType): void {
    this.checkIfElementDisabled();
    this.reasonsForDisabling.add(type);
    this.renderer.addClass(this.nativeElement, 'no-pointer-events');
    this.renderer.addClass(this.nativeElement, 'disabled');
    this.renderer.setAttribute(this.nativeElement, 'disabled', 'true');
    this.stopClickPropagation = true;
  }

  private enable(type: DisableElementType): void {
    this.reasonsForDisabling.delete(type);
    if (this.reasonsForDisabling.size === 0) {
      this.renderer.removeClass(this.nativeElement, 'no-pointer-events');
      this.renderer.removeClass(this.nativeElement, 'disabled');
      this.renderer.removeAttribute(this.nativeElement, 'disabled');
      this.stopClickPropagation = false;
    }
  }

  @HostListener('click', ['$event'])
  onClick(e: MouseEvent): void {
    if (this.stopClickPropagation) {
      e.stopPropagation();
    }
  }

  private get nativeElement(): HTMLElement {
    return this.element.nativeElement;
  }

  private checkIfElementDisabled(): void {
    if (this.element.nativeElement.disabled || this.element.nativeElement.classList.contains('disabled')) {
      this.reasonsForDisabling.add(DisableElementType.CodeSpecific);
    }
  }
}
