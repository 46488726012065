<kros-breadcrumb>
</kros-breadcrumb>

<app-document-list-toolbar
  *ngIf="project"
  (openNewTabClick)="onOpenOnNewTab()"
  (openApplicationClick)="onOpenDocumentInApplication($event)"
  (fileAddVersionSelected)="onShowDocumentUploader()"
  (downLoadDocumentClick)="onDownloadDocument()"
  (renameFolderClick)="onRenameFolder()"
  (shareDocumentClick)="onShareDocument()"
  (deleteDocumentsClick)="onDeleteDocuments()"
  (showDocumentUploader)="onShowDocumentUploader()"
  (showDocumentVersionUploader)="onShowDocumentUploader()"
  (folderOpened)="onFolderOpened()"
  (uploadBuildingClick)="onUploadBuilding()"
  (setDocumentDescriptionClick)="onSetDocumentDescriptionClick()"
  [project]="project"
  [selectedDocumentId]="selection.getSelectedDocumentId"
  [isUploadingMode]="isUploadingMode"
  [currentFolder]="currentFolder"
  [canAddDocumentMenu]="isMinimumContributorInCurrentFolder"
></app-document-list-toolbar>

<kros-document-explorer
  [documentId]="documentId"
  [connectorService]="connectorService"
  [connectionId]="userService.getUserIdentityId() + ''"
  [tourService]="tourService"
  [documentModalService]="documentModalService"
  [projectDetail$]="projectsSelectorsService.projectDetail$"
  (openDocument)="openDocument($event)"
  (openVersionModal)="openVersionModal($event)"
  (dismissDeleteToast)="dismissDeleteToast()"
  (createDocument)="onCreateDocument()"
  (createFolder)="onCreateFolder()"
  (uploadBuilding)="onUploadBuilding()"
  [isUploadingMode]="isUploadingMode"
></kros-document-explorer>

<kros-upload-snackbar
  [ngClass]="{'isHidden': !isUploadSnackbarOpen}"
  (closeSnackbar)="closeUploadSnackbar()"
></kros-upload-snackbar>
