import { HttpClient, HttpContext, HttpContextToken } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { FeatureFlags, IFeatureFlagsService } from '@kros-sk/core/feature-flags';

const featureFlagsUrlPostfix = '/FeatureFlags';

@Injectable()
export class SswFeatureFlagsApiService implements IFeatureFlagsService {

  private readonly config = inject(APP_CONFIG);
  private readonly http = inject(HttpClient);

  loadFeatureFlags(): Observable<FeatureFlags> {
    const serverUrl = `${this.config.gatewayApiUrl}${featureFlagsUrlPostfix}`;
    return this.http.get<FeatureFlags>(serverUrl, {
      context: new HttpContext().set(new HttpContextToken(() => []), ['401', '504'])
    });
  }
}
