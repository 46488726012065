<div class="kros-modal-header">
  <h4 class="kros-modal-title">{{'BUILDING_PROGRESS.EXPORT_CONFIRMATION_PROTOCOL_NDS' | translate}}</h4>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onCloseClick()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div class="kros-modal-body">
  <form
    [formGroup]="exportForm"
    (ngSubmit)="onSubmit()"
  >
    <div
      class="export-periods-empty-info"
      *ngIf="arePeriodsEmpty else exportSettings"
    >
      {{'BUILDING_PROGRESS.EXPORT_PERIODS_EMPTY_INFO' | translate}}
    </div>

    <ng-template #exportSettings>
      <div class="form-container">
        <div class="form-group period">
          <label class="caption">{{'BUILDING_PROGRESS.PERIODS.PERIOD' | translate}}:</label>
          <select
            id="period"
            class="form-control"
            formControlName="periodId"
            data-test="period-select"
          >
            <option
              *ngFor="let period of periods"
              [value]="period.id"
            >{{ period.dateFrom | periodDate : period.dateTo }}</option>
          </select>
        </div>

        <div class="form-group range">
          <label class="caption">{{'BUILDING_PROGRESS.RANGE' | translate}}:</label>

          <div>
            <button
              class="titan-dropdown-toggle btn"
              type="button"
              (click)="openRangeModal()"
              data-test="export-range-button"
            >
              <span>{{rangeLabel}}</span>
              <i class="material-icons">
                expand_more
              </i>
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </form>
</div>

<div class="kros-modal-footer">
  <button
    *ngIf="!arePeriodsEmpty"
    class="btn btn-primary btn-submit"
    id="ga-export"
    data-test="confirmation-protocol-export-button"
    type="button"
    [disabled]="exportForm.invalid"
    (click)="onSubmit()"
  >{{'BUILDING_PROGRESS.EXPORT_ACTION' | translate}}</button>
</div>
