import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { APP_CONFIG } from '@kros-sk/app-config';
import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { DeviceDetectorService, DeviceType, KrosModalService } from '@kros-sk/components';
import { FeatureFlags, HasFeatureFlagFacade } from '@kros-sk/core/feature-flags';
import {
  formatString,
  License,
  LicenseSelectorsService as LicenseCdkSelectorsService,
  LicenseType
} from '@kros-sk/ssw-cdk';

import { ButtonConfig } from '../non-paid-package-config.model';
import { LicenseEventService } from '../license-event.service';
import { LicenseInfo } from '../license-info.model';
import { LicenseInfoContentHelper } from './license-info-content-provider';
import { MarketingModalComponent } from '../marketing-modal/marketing-modal.component';
import { PluralisationByCountPipe } from '../../pipes';
import { TranslateService } from '../../translate';

@Component({
  selector: 'kros-license-info',
  templateUrl: './license-info.component.html',
  styleUrls: ['./license-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    LicenseInfoContentHelper
  ]
})
export class LicenseInfoComponent implements OnInit {

  translateService = inject(TranslateService);
  private destroyRef = inject(DestroyRef);
  private pluralisationByCountPipe = inject(PluralisationByCountPipe);
  private deviceDetector = inject(DeviceDetectorService);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private appInsightsService = inject(AppInsightsBaseService);
  private licenseEventService = inject(LicenseEventService);
  private krosModalService = inject(KrosModalService);
  private contentHelper = inject(LicenseInfoContentHelper, { optional: true });
  private appConfig = inject(APP_CONFIG);
  private licenseCdkSelectorsService = inject(LicenseCdkSelectorsService, { optional: true });
  private readonly featureFlagFacade = inject(HasFeatureFlagFacade, { optional: true });

  @Input() moreInfoUrl: string;
  @Input() orderUrl: string;
  @Input() renewUrl: string;
  @Input() licenseInfo: LicenseInfo;
  @Input() additionalButton: ButtonConfig;
  @Input() appPrefix: string;
  @Input() isAutomaticRenewalLicense: boolean;

  isHidden = false;
  isMobile = false;

  get canActivateTrial(): boolean {
    return this.license && this.license.licenseType === LicenseType.Free && this.license.canActivateTrial;
  }

  get canShowLicenseInfo(): boolean {
    return this.license &&
      this.canShowLicenseInfoInBudget &&
      this.canShowLicenseInfoInQuantityManager &&
      this.canShowLicenseInfoInMisha &&
      !this.isSchoolLicense &&
      (this.license.licenseType === LicenseType.Free ||
        this.license.licenseType === LicenseType.Trial ||
        this.license.licenseType === LicenseType.Readonly ||
        (this.license.licenseType === LicenseType.Full && this.license.remainingDays <= 30));
  }

  get canShowMoreInfo(): boolean {
    return !(this.isMobile || this.isFullLicense || this.isReadOnlyLicense);
  }

  get isSchoolLicense(): boolean {
    return this.license && this.license.isSchool;
  }

  get license(): License {
    return this.licenseInfo?.license;
  }

  get trialButtonText(): string {
    return formatString(this.translateService.translate('LICENSE_INFO.TRY_FOR_X_DAYS'),
      '' + this.licenseInfo.license.trialDuration,
      this.pluralisationByCountPipe.transform(this.licenseInfo.license.trialDuration, 'LICENSE_INFO.DAY'));
  }

  private get canShowLicenseInfoInBudget(): boolean {
    return this.appPrefix !== 'B' ||
      (!this.isAutomaticRenewalLicense && this.appConfig.location === 'sk') ||
      (this.appConfig.location === 'cz' && (this.isBudgetMini || this.isSchoolLicense));
  }

  private get canShowLicenseInfoInQuantityManager(): boolean {
    return (this.appPrefix !== 'QM') || !this.isAutomaticRenewalLicense;
  }

  private get canShowLicenseInfoInMisha(): boolean {
    return (this.appPrefix !== 'MI') || !this.isAutomaticRenewalLicense;
  }

  private get isFullLicense(): boolean {
    return this.license && this.license.licenseType === LicenseType.Full;
  }

  private get isReadOnlyLicense(): boolean {
    return this.license && this.license.licenseType === LicenseType.Readonly;
  }

  private budgetLicense: License;
  private desktopLicense: License;
  private isBudgetMini: boolean;

  ngOnInit(): void {
    this.deviceDetector.deviceType$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(type => {
      this.isMobile = type === DeviceType.Mobile;
      this.changeDetectorRef.detectChanges();
    });
    this.translateService.langChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.detectChanges());
    this.licenseCdkSelectorsService?.budgetLicense$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(budgetLicense => this.budgetLicense = budgetLicense);
    this.licenseCdkSelectorsService?.desktopLicense$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(desktopLicense => this.desktopLicense = desktopLicense);
    this.featureFlagFacade?.featureFlags$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((ff: FeatureFlags) => this.isBudgetMini = ff.BudgetMini);
  }

  onOpenOrderUrl(): void {
    this.appInsightsService.trackEvent(this.appPrefix + '-order-clicked');
    if (this.appConfig.location === 'sk' &&
      this.appPrefix === 'PV' &&
      this.license?.licenseType <= LicenseType.Trial &&
      this.budgetLicense?.licenseType <= LicenseType.Trial &&
      this.desktopLicense?.licenseType === LicenseType.Free) {
      this.krosModalService.openCentered(
        MarketingModalComponent,
        {
          contentHelper: this.contentHelper,
        },
        { allowFocusAutocapture: false }
      );
    } else {
      window.open(
        this.renewUrl !== undefined && (this.license.licenseType === LicenseType.Readonly || this.isFullLicense)
          ? this.renewUrl
          : this.orderUrl,
        '_blank');
    }
  }

  onActivateTrial(): void {
    this.licenseInfo.activateTrialLicense();
  }

  onCloseButtonClick(): void {
    this.isHidden = true;
    this.licenseEventService.closeLicenseInfo();
    this.detectChanges();
  }

  private detectChanges(): void {
    if (!this.changeDetectorRef['destroyed']) {
      this.changeDetectorRef.detectChanges();
    }
  }
}
