import { Injectable } from '@angular/core';

import { take } from 'rxjs/operators';

import { BudgetApprovalService } from '.';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { BuildingObjectType, InfoModalComponent, QuestionModalComponent, TranslateService } from '@kros-sk/ssw-shared-legacy';
import { formatString } from '@kros-sk/ssw-cdk';
import { KrosModalService } from '@kros-sk/components';

import { ApprovalType } from '../enums';
import { BudgetApprovalDispatchersService } from '../../store/budget-approval';
import { BudgetApprovalItem } from '../models';
import { BuildingProgressActionAccessService } from '../../building-progress/services/building-progress-action-access.service';

@Injectable()
export class BudgetApprovalApproveService {

  constructor(
    private krosModalService: KrosModalService,
    private translateService: TranslateService,
    private appInsightService: AppInsightsBaseService,
    private budgetApprovalDispatchersService: BudgetApprovalDispatchersService,
    private buildingProgressActionAccessService: BuildingProgressActionAccessService,
    private budgetApprovalService: BudgetApprovalService
  ) { }

  changeApprovalBuildingObjects(
    projectId: number,
    selectedItem: BudgetApprovalItem,
    childs: BudgetApprovalItem[],
    cancelApproved: boolean
  ): void {
    const requestApprovalType = cancelApproved ? ApprovalType.Approved : ApprovalType.NotApproved;

    if (selectedItem !== undefined && this.buildingProgressActionAccessService.checkForFreeOwner(true)) {
      if (selectedItem.approvalType !== requestApprovalType) {
        if (selectedItem.approvalType === ApprovalType.Approved) {
          this.budgetApprovalService.navigateToBuildingProgress(projectId, selectedItem.hierarchyCode);
        } else {
          const isNotApprovedChangeSheet = selectedItem.approvalType === ApprovalType.NotApproved;
          this.openInfoDialog(this.translateService.translate(`BUDGET_APPROVAL.APPROVE.${isNotApprovedChangeSheet
            ? 'ONLY_APPROVED_CAN_BE_CANCELED'
            : 'FUNCTION_AVAILABLE_ONLY_ON_CHANGE_SHEET'}`));
        }
      } else if (childs.length && childs.some(ch => ch.approvalType === requestApprovalType)) {
        this.changeApprovalWithQuestion(
          this.translateService.translate(`BUDGET_APPROVAL.APPROVE.${cancelApproved
            ? 'CANCEL_APPROVED_WITH_CHILD'
            : 'APPROVED_WITH_CHILD'}`),
          selectedItem.description,
          projectId,
          [selectedItem.id, ...childs.map(i => i.id)],
          cancelApproved);
      } else {
        this.changeApprovalWithQuestion(
          this.translateService.translate(`BUDGET_APPROVAL.APPROVE.${cancelApproved
            ? 'CANCEL_APPROVED_QUESTION'
            : 'APPROVE_QUESTION'}`),
          selectedItem.description,
          projectId,
          [selectedItem.id],
          cancelApproved);
      }
    }
  }

  approveBuildingObjects(
    projectId: number,
    selectedItems: BudgetApprovalItem[],
  ): void {
    const approvalItemIds = [];
    let onlyNormalObjects = true;
    let firstToNavigate = null;
    selectedItems.forEach(i => {
      if (i.buildingObjectType !== BuildingObjectType.Normal && !firstToNavigate) {
        onlyNormalObjects = false;
        firstToNavigate = i.hierarchyCode;
      }
      if (i.buildingObjectType !== BuildingObjectType.Normal && i.approvalType === ApprovalType.NotApproved) {
        approvalItemIds.push(i.id);
      }
    });

    if (approvalItemIds.length === 0) {
      if (onlyNormalObjects) {
        this.openInfoDialog(this.translateService.translate('BUDGET_APPROVAL.APPROVE.FUNCTION_AVAILABLE_ONLY_ON_CHANGE_SHEET'));
      } else {
        this.budgetApprovalService.navigateToBuildingProgress(projectId, firstToNavigate);
      }
    } else {
      this.changeApprovalWithQuestion(this.translateService.translate('BUDGET_APPROVAL.APPROVE.APPROVE_QUESTION_SELECTED'),
        '',
        projectId,
        approvalItemIds,
        false);
    }
  }

  private changeApprovalWithQuestion(
    questionText: string,
    description: string,
    projectId: number,
    buildingObjectIds: number[],
    cancelApproved: boolean
  ): void {
    if (this.buildingProgressActionAccessService.checkForAccessToApproveAndDeleteChangeSheet()) {
      const modalRef = this.krosModalService.openCentered(
        QuestionModalComponent,
        {
          body: formatString(questionText, '<b>' + description + '</b>'),
          confirmButton: this.translateService.translate(`BUDGET_APPROVAL.APPROVE.${cancelApproved
            ? 'CANCEL_APPROVED_CHANGE_SHEET'
            : 'APPROVE'}`)
        },
        {
          closeOnBackdropClick: false,
          fullscreenOnMobile: false,
          showMobileArrowBack: false
        },
        NaN,
        NaN,
        undefined,
        'no-min-width',
      );

      modalRef.afterClosed$.pipe(take(1)).subscribe(result => {
        if (result.type === 'submit') {
          this.changeApprovalObject(projectId, buildingObjectIds, cancelApproved);
        }
      });
    }
  }

  private openInfoDialog(infoText: string): void {
    this.krosModalService.openCentered(
      InfoModalComponent,
      {
        body: infoText
      },
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: false,
        showMobileArrowBack: false
      },
      NaN,
      NaN,
      undefined,
      'no-min-width'
    );
  }

  private changeApprovalObject(projectId: number, buildingObjectIds: number[], cancelApproved: boolean): void {
    if (cancelApproved) {
      this.appInsightService.trackEvent('BA-change-sheet-cancel-approved', { budgetObjectId: '' + buildingObjectIds[0] });
      this.budgetApprovalDispatchersService.cancelApprovedChangeSheet({ projectId, buildingObjectIds });
    } else {
      this.appInsightService.trackEvent('BA-change-sheet-approve', { budgetObjectId: '' + buildingObjectIds.join(', ') });
      this.budgetApprovalDispatchersService.approveChangeSheet({ projectId, buildingObjectIds });
    }
  }
}
