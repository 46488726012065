import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import {
  BudgetBoqModule,
  BudgetStructureModule,
  DataTableModule,
  SidePanelModule,
  TranslateModule
} from '@kros-sk/ssw-shared-legacy';
import { KrosInputsModule } from '@kros-sk/components';

import {
  BuildingProgressDashboardComponent
} from '../../../building-progress/shared/components/building-progress-dashboard/building-progress-dashboard.component';
import { CommentsPanelModule } from '../comments-panel/comments-panel.module';
import { ExportHelper, LicenseHelper, PermissionHelper } from '../../../building-progress/helpers';
import { ExportModule } from '../export/export.module';
import { FilterModule } from '../filter/filter.module';
import { PeriodsModule } from '../periods/periods.module';
import { SharedModule } from '../../../shared/shared.module';
import { SubcontractorsComponent } from './subcontractors.component';
import { SubcontractorsDetailComponent } from './subcontractors-detail/subcontractors-detail.component';
import {
  SubcontractorsEffects,
  subcontractorsReducer
} from '../../../store/subcontractors';
import { SubcontractorSelectDialogComponent } from './subcontractor-select-dialog/subcontractor-select-dialog.component';
import { SubcontractorsItemNotesComponent } from './subcontractors-item-notes/subcontractors-item-notes.component';
import {
  SubcontractorsItemPeriodDetailsComponent
} from './subcontractors-item-period-details/subcontractors-item-period-details.component';
import { SubcontractorsListComponent } from './subcontractors-list/subcontractors-list.component';
import { SubcontractorsPanelContainerComponent } from './subcontractors-panel-container/subcontractors-panel-container.component';
import { SubcontractorsRenameDialog } from './subcontractor-rename-dialog/subcontractor-rename-dialog.component';
import { SubcontractorsRoutingModule } from './subcontractors-routing.module';
import {
  SubcontractorsSelectItemsDialogComponent
} from './subcontractors-select-items-dialog/subcontractors-select-items-dialog.component';
import { SubcontractorsService } from './services/subcontractors.service';
import { SubcontractorsSharingDialogComponent } from './subcontractors-sharing-dialog/subcontractors-sharing-dialog.component';
import { SubcontractorsToolbarComponent } from './subcontractors-toolbar/subcontractors-toolbar.component';
import { TransferExecutionsDialogComponent } from './transfer-executions-dialog/transfer-executions-dialog.component';

@NgModule({
  declarations: [
    SubcontractorsComponent,
    SubcontractorsToolbarComponent,
    SubcontractorsDetailComponent,
    SubcontractorsPanelContainerComponent,
    SubcontractorsSelectItemsDialogComponent,
    SubcontractorSelectDialogComponent,
    SubcontractorsListComponent,
    SubcontractorsSharingDialogComponent,
    TransferExecutionsDialogComponent,
    SubcontractorsItemPeriodDetailsComponent,
    SubcontractorsRenameDialog,
    SubcontractorsItemNotesComponent
  ],
  imports: [
    CommonModule,
    SubcontractorsRoutingModule,
    SharedModule,
    TranslateModule.forChild(),
    ReactiveFormsModule,
    DataTableModule,
    StoreModule.forFeature('subcontractors', subcontractorsReducer),
    EffectsModule.forFeature([SubcontractorsEffects]),
    BudgetStructureModule,
    KrosInputsModule,
    SidePanelModule,
    PeriodsModule,
    FilterModule,
    BudgetBoqModule,
    CommentsPanelModule,
    ExportModule,
    BuildingProgressDashboardComponent
  ],
  providers: [
    SubcontractorsService,
    LicenseHelper,
    PermissionHelper,
    ExportHelper
  ]
})
export class SubcontractorsModule { }
