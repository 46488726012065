import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import config from 'devextreme/core/config';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { licenseKey } from '../../../devextreme-license';

if (environment.production) {
  enableProdMode();
}

// configure DevExtreme license
config({ licenseKey });
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
