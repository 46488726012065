import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

import { AnalyticsService } from '../../analytics';
import { Cell, ColDef, ColDefTextAlign, EditConfig } from '../data-table-config.model';
import { formatNumberValue } from '../../core';

const firstVisibleInGroupClass = 'first-visible-in-group';

const isFirstVisbleColInGroup = (colDef: ColDef, groupColDef: ColDef): boolean => {
  const firstVisible: ColDef = groupColDef.colsInGroup?.find(c => !c.isHidden);
  return firstVisible?.id === colDef.id ? true : false;
};

export const getCellClass = (
  colDef: ColDef,
  rowIndex: number,
  item: any,
  focusedCell: Cell,
  isItemRowSelected: boolean,
  isLastInGroup?: boolean,
  groupColDef?: ColDef
): string => {
  let classString = '';

  classString += colDef.textAlign === ColDefTextAlign.Right ? 'text-right' : '';
  classString += colDef.textAlign === ColDefTextAlign.Center ? ' text-centered' : '';
  classString += colDef.cellClassGetter ? ' ' + colDef.cellClassGetter(item, rowIndex) : '';
  classString += colDef.isFixed ? ' fixed' : '';
  classString += ' ' + colDef.id;

  if (focusedCell) {
    if (focusedCell.colId === colDef.id && focusedCell.rowIndex === rowIndex) {
      classString += ' focused-cell';
    } else if (focusedCell.colId !== colDef.id && focusedCell.rowIndex === rowIndex) {
      classString += ' focused';
    }
  }

  if (isItemRowSelected) {
    classString += ' selected-item';
  }

  if (isLastInGroup) {
    classString += ' last-in-group';
  }

  if (groupColDef && isFirstVisbleColInGroup(colDef, groupColDef)) {
    classString += ` ${firstVisibleInGroupClass}`;
  }

  return classString;
};

export const getHeaderCellClass = (colDef: ColDef, isLastInGroup?: boolean, groupColDef?: ColDef): string => {
  let classString = '';
  classString += colDef.cellClassGetter ? ' ' + colDef.cellClassGetter() : '';
  classString += ' ' + colDef.id;
  classString += colDef.isFixed ? ' fixed' : '';

  if (isLastInGroup) {
    classString += ' last-in-group';
  }

  if (groupColDef && isFirstVisbleColInGroup(colDef, groupColDef)) {
    classString += ` ${firstVisibleInGroupClass}`;
  }

  return classString;
};

export const getCellStyle = (colDef: ColDef, parentCol: ColDef): any => {
  const ret = {};
  if (colDef.width) {
    let width = colDef.width;
    if (parentCol && isOnlyVisibleInGroup(colDef, parentCol)) {
      width = Math.floor(width / 1.5);
    }
    ret['width'] = width + 'px';
  }
  if (colDef.minWidth) {
    ret['min-width'] = colDef.minWidth + 'px';
  }
  if (colDef.width || colDef.minWidth) {
    ret['white-space'] = 'normal';
  }
  if (colDef.left) {
    ret['left'] = colDef.left + 'px';
  }

  return ret;
};

export const getColumnStyle = (colDef: ColDef, fixedWidth: boolean, item?: any): any => {
  const ret = {};
  if (colDef.isFixed && colDef.left !== undefined) {
    ret['left'] = colDef.left + 'px';
  }
  if (item && colDef.backgroundColorCode) {
    const colorCode = colDef.backgroundColorCode(item);
    if (colorCode && colorCode !== '#FFFFFF') {
      ret['backgroundColor'] = colorCode;
    }
  }

  if (fixedWidth) {
    if (colDef.minWidth) {
      ret['width'] = colDef.minWidth + 'px';
    }
    if (colDef.width) {
      ret['width'] = colDef.width + 'px';
    }
  }

  return ret;
};

export const getFormattedValue = (value: any, colDef: ColDef, editMode: boolean, item: any): string => {
  return colDef.format ? colDef.format(value, editMode, item) : value;
};

export const isColumnEditable = (editConfig: EditConfig): boolean => {
  return !!editConfig &&
    (editConfig.onChange || editConfig.onEnter || editConfig.onFocusOut) &&
    ((!editConfig.columnEditableGetter) || (!!editConfig.columnEditableGetter && editConfig.columnEditableGetter()));
};

export const getCellValueForInput = (colDef: ColDef, item: any): string => {
  return getFormattedValue(colDef.valueGetter ? colDef.valueGetter(item) : item[colDef.id], colDef, true, item);
};

export const editValue = (
  value: string,
  colDef: ColDef,
  item: any,
  analyticsService: AnalyticsService,
  appInsightsService: AppInsightsBaseService,
  appInsightsPrefix: string
): boolean => {
  const oldValue = getCellValueForInput(colDef, item) ?? '';

  if (colDef.valueSetter && oldValue !== value) {
    sendAnalyticsNotification(analyticsService, appInsightsService, appInsightsPrefix, colDef.id, item.itemType);
    colDef.valueSetter(item, value);
    return true;
  }

  return false;
};

const sendAnalyticsNotification = (
  analyticsService: AnalyticsService,
  appInsightsService: AppInsightsBaseService,
  appInsightsPrefix: string,
  columnId: string,
  itemType: string
): void => {
  analyticsService.raiseEvent('Priebeh výstavby', 'Editácia bunky pre položku typu "' + itemType + '"',
    'Pre stĺpcec "' + columnId + '"');
  appInsightsService.trackEvent(appInsightsPrefix + 'edit', { columnId, itemType });
};

export const isArrowKey = (key: string): boolean => {
  return [
    'arrowup',
    'arrowdown',
    'arrowleft',
    'arrowright'
  ].includes(key?.toLowerCase());
};

export const isLeftRightKey = (key: string): boolean => {
  return [
    'arrowleft',
    'arrowright'
  ].includes(key?.toLowerCase());
};

export const isUpDownKey = (key: string): boolean => {
  return [
    'arrowup',
    'arrowdown'
  ].includes(key?.toLowerCase());
};

export const isUpKey = (key: string): boolean => {
  return key?.toLowerCase() === 'arrowup';
};

export const isDownKey = (key: string): boolean => {
  return key?.toLowerCase() === 'arrowdown';
};

export const isControlKey = (key: string): boolean => {
  return key?.toLowerCase() === 'control';
};

export const isEscapeKey = (key: string): boolean => {
  return key?.toLowerCase() === 'escape';
};

export const isDeleteKey = (key: string): boolean => {
  return key?.toLowerCase() === 'delete';
};

export const isDeleteOrBackspace = (key: string): boolean => {
  return [
    'delete',
    'backspace'
  ].includes(key?.toLowerCase());
};

export const isEnterKey = (key: string): boolean => {
  return key?.toLowerCase() === 'enter';
};

export const isTabKey = (key: string): boolean => {
  return key?.toLowerCase() === 'tab';
};

const hasOnlyOneVisibleColumn = (colDef: ColDef): boolean => {
  return colDef.colsInGroup && colDef.colsInGroup.filter(c => !c.isHidden).length === 1;
};

const isOnlyVisibleInGroup = (colDef: ColDef, parentCol: ColDef): boolean => {
  return hasOnlyOneVisibleColumn(parentCol) && !colDef.isHidden;
};

export const getTitle = (colDef: ColDef): string => {
  return hasOnlyOneVisibleColumn(colDef) && colDef.titleShort ? colDef.titleShort : colDef.title;
};

export function getTextCellHeight(
  textLength: number,
  level: number,
  isLargeFont?: boolean,
  width?: number,
  verticalPaddingPlusBorder = 11,
  additionalInfoHeight = 0
): number {
  const averageWidthOfOneSign = isLargeFont ? 9 : 7;
  const heightOfOneLine = isLargeFont ? 22.22 : 16.67;
  const columnWidth = width ?? 400;
  const levelPadding = 10;

  const descriptionTextWidth = columnWidth - ((level + 1) * levelPadding);
  const lineCount = Math.floor((textLength * averageWidthOfOneSign) / descriptionTextWidth) + 1;

  return Math.max(25, lineCount * heightOfOneLine) + verticalPaddingPlusBorder + additionalInfoHeight;
}

export function getColumnWidth(value: number, decimalPlaces: number, appLocation: string, canBeBold: boolean): number {
  const formattedValue = value !== null ? formatNumberValue(value, decimalPlaces, appLocation) : '';
  return formattedValue.length * (canBeBold ? 10 : 7);
}

export const getFocusedCellElementId = (focusedCell?: Cell): string | undefined => {
  return focusedCell ? (focusedCell.colId + '-row-' + focusedCell.rowIndex) : undefined;
};
