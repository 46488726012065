<div
  data-test="open-api-row"
  class="card list-item"
>
  <div class="editor-container">
    <div class="header">
      <ssw-open-api-settings-list-header [subscription]="subscription"></ssw-open-api-settings-list-header>
    </div>
  </div>
</div>
