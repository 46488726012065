import { Component, OnDestroy } from '@angular/core';

import { SubSink } from 'subsink';

@Component({ template: '' })
export abstract class UnsubscribeComponent implements OnDestroy {
  protected subs = new SubSink();

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
