<kros-input #itemInput
  [options]="{
    type: 'text',
    name: 'city',
    formControl: cityForm,
    label: 'COMMON.ADDRESS.CITY' | translate,
    testName: 'company-info-town-select',
    autocomplete: autocompleteConfig,
    autocompleteOff: true,
    maxLength: 255
  }">
</kros-input>

<ng-template #optionTemplate  let-r="result">
  <div class="address-container">
    <div class="ellipsis" data-test="option">
      {{r.city}}
    </div>
    <div class="ellipsis" data-test="option">
      {{r.postCode}}
    </div>
  </div>
</ng-template>
