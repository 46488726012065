import { inject, Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { KrosModalRef, KrosModalService, MessageTypes } from '@kros-sk/components';

import { ArrowMovement } from '../../enums/arrow-movement.enum';
import { BoqItem } from '../../models';
import { boqTagName, getTagNameByMeasurementColumnIndex, substituteExpressionByValuesForHeightDependsFields } from './boq.helpers';
import { RecalculateBoqItems } from '../models';
import { TranslateService } from '../../translate';

const idFieldName = 'measurementId';
const boqIdField = 'id';
const boqNewIdField = 'boqId';
const expressionFieldName = 'expression';

@Injectable()
export class BoqEditService {
  private krosModalService = inject(KrosModalService);
  private translateService = inject(TranslateService);

  insertMeasurementToCurrentRow$ = new Subject<{ value: (Object | string)[], preventFocus: boolean }>();
  insertMeasurementAsNewRows$ = new Subject<{ value: (Object | string)[] }>();
  insertBoqToCurrentRow$ = new Subject<Object>();
  insertBoqToRow$ = new Subject<Object>();
  insertBoqAsNewRow$ = new Subject<Object>();
  moveInMatchedBoqs$ = new Subject<ArrowMovement>();
  insertMatchedBoq$ = new Subject<void>();
  resized$ = new Subject<void>();

  insertMeasurement(values: (Object | string)[], preventFocus: boolean, canInsertAsNewRows: boolean): void {
    if (canInsertAsNewRows) {
      this.insertMeasurementAsNewRows$.next({ value: values.map(p => this.wrapMeasurementValue(p)) });
    } else {
      this.insertMeasurementToCurrentRow$.next({
        value: values.map(p => this.wrapMeasurementValue(p)),
        preventFocus
      });
    }
  }

  insertBoq(value: Object): void {
    this.insertBoqToCurrentRow$.next(this.wrapBoqValue(value));
  }

  insertToBoq(value: Object, canInsertAsNewRows: boolean): void {
    if (canInsertAsNewRows) {
      this.insertBoqAsNewRow$.next(this.wrapBoqValue(value));
    } else {
      this.insertBoqToRow$.next(this.wrapBoqValue(value));
    }
  }

  moveInMatchedBoqs(arrowMovement: ArrowMovement): void {
    this.moveInMatchedBoqs$.next(arrowMovement);
  }

  insertMatchedBoq(): void {
    this.insertMatchedBoq$.next();
  }

  onResize(): void {
    this.resized$.next();
  }

  openModal(hasExpression?: boolean, canShowDeleteMessage = true): KrosModalRef {
    if (!hasExpression) {
      return this.krosModalService.openModalMessageBoxModalRef({
        caption: this.translateService.translate('BILL_OF_QUANTITIES.CAN_DELETE'),
        message: canShowDeleteMessage ? this.translateService.translate('BILL_OF_QUANTITIES.DELETE_MESSAGE') : undefined,
        messageType: MessageTypes.Danger,
        acceptButton: this.translateService.translate('BILL_OF_QUANTITIES.REMOVE_ROW'),
        cancelButton: this.translateService.translate('COMMON.CLOSE')
      });
    } else {
      return this.krosModalService.openModalMessageBoxModalRef({
        icon: '',
        caption: this.translateService.translate('BILL_OF_QUANTITIES.SAVE_QUESTION'),
        messageType: MessageTypes.Primary,
        acceptButton: this.translateService.translate('COMMON.ULOZIT'),
        cancelButton: this.translateService.translate('COMMON.NOT_SAVE')
      });
    }
  }

  getRecalculatedRow(data: RecalculateBoqItems, row: BoqItem): BoqItem {
    const usedIds = row.expression.match(/>(\d+)</gi)?.map(p => +p.match(/\d+/));
    if (usedIds?.some(i => data.items.some(p => p.measurementId === i))) {
      if (data.needReplaceTags) {
        row = { ...row, expression: substituteExpressionByValuesForHeightDependsFields(data.items, row.expression) };
      }
      return row;
    } else if (usedIds?.some(i => data.boqs.some(b => b.id === i))) {
      return row;
    }
    return undefined;
  }

  getBoqValue(values: (Object | string)[]): Object | string {
    const groupColumns = ['group1', 'group2', 'group3'];
    const value = values.find(p => typeof p !== 'string');
    groupColumns.forEach(group => {
      if (!values.every(row => row[group] === values[0][group])) {
        value[group] = '';
      }
    });
    return value;
  }

  private wrapMeasurementValue(value: Object | string): Object | string {
    if (typeof value !== 'string') {
      const ret = { ...value };
      const tagName = getTagNameByMeasurementColumnIndex(value['column']);

      if (tagName) {
        ret[expressionFieldName] = this.wrapWithTag(value[idFieldName], tagName);
        return ret;
      }
    }

    return value;
  }

  private wrapWithTag(value: number, tagName): string {
    return `<${tagName}>${value}</${tagName}>`;
  }

  private wrapBoqValue(value: Object): Object {
    const ret = { ...value };
    ret[boqNewIdField] = ret[boqIdField];
    ret[expressionFieldName] = this.wrapWithBoqTag(value[boqIdField]);
    delete ret[boqIdField];

    return ret;
  }

  private wrapWithBoqTag(value: number): string {
    return `<${boqTagName}>${value}</${boqTagName}>`;
  }
}
