import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { formatString } from '@kros-sk/ssw-cdk';
import { KrosModalService, MessageTypes } from '@kros-sk/components';
import { TranslateService } from '@kros-sk/ssw-shared-legacy';


import { BuildingProgressPeriod } from '../models/building-progress-period.model';
import { PeriodDatePipe } from '../../building-progress/modules/periods/pipes/period-date.pipe';

@Injectable()
export class DetailMobileHelper {
  constructor(
    private krosModalService: KrosModalService,
    private translateService: TranslateService,
    private periodDatePipe: PeriodDatePipe
  ) { }

  public showNoLastPeriodSelectedMessage(selectedPeriodId: number, periods: BuildingProgressPeriod[]): Observable<boolean> {
    const lastPeriod = periods[periods.length - 1];
    if (selectedPeriodId === lastPeriod.id) {
      return of(true);
    } else {
      return this.krosModalService.openModalMessageBox(
        {
          caption: '',
          message: formatString(
            this.translateService.translate('BUILDING_PROGRESS.WARNING.DRAW_FROM_NOT_SELECTED_PERIOD'),
            [`(${this.periodDatePipe.transform(lastPeriod.dateFrom, lastPeriod.dateTo)})`]
          ),
          messageType: MessageTypes.Primary,
          acceptButton: this.translateService.translate('COMMON.CONTINUE'),
          cancelButton: this.translateService.translate('COMMON.ZRUSIT')
        },
        {
          closeOnBackdropClick: false,
          fullscreenOnMobile: false,
          showMobileArrowBack: false
        }
      ).pipe(map(result => result.type === 'submit' && result.data === 'accept'));
    }
  }
}
