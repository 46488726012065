import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MobileNavComponent } from '../kros-mobile-navigation/mobile-nav/mobile-nav.component';

@NgModule({
  declarations: [MobileNavComponent],
  imports: [
    CommonModule,
  ],
  exports: [MobileNavComponent],
})
export class KrosMobileNavigationModule { }
