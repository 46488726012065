import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { ApplicationType, License, LicenseType, UserIds } from '@kros-sk/ssw-cdk';
import { BudgetPermissionType, BuildingProgressPermissionType, PermissionType } from '@kros-sk/ssw-shared/permission';
import { FeatureFlags, HasFeatureFlagFacade } from '@kros-sk/core/feature-flags';

import { DocumentPermissionType, ItemSharing } from '../../models';
import { ItemSharingMultiple } from '../models/item-sharing-multiple.model';
import { NewSharingComboMode } from '../models/sharing-combo-mode.enum';
import { PermissionsUtils } from './permissions-utils';
import { SharingPermission, SharingPermissionMultipleItem, SharingPermissionValues } from '../models/sharing-permission.interface';
import { TranslateService } from '../../translate';

@Injectable()
export class PermissionListService {

  private translateService = inject(TranslateService);
  private permissionsUtils = inject(PermissionsUtils);
  private readonly featureFlagFacade = inject(HasFeatureFlagFacade);

  private get hasBudgetAccess(): boolean {
    return this.isBudgetMini || this.licenses[ApplicationType.Budget]?.isSchool;
  }

  private isBudgetMini = true;
  private licenses: { [K in ApplicationType]?: License; } = {};

  constructor(
  ) {
    this.featureFlagFacade.featureFlags$.pipe(takeUntilDestroyed())
      .subscribe((ff: FeatureFlags) => this.isBudgetMini = ff.BudgetMini);
  }

  setLicense(applicationType: ApplicationType, license: License): void {
    this.licenses[applicationType] = license;
  }

  getSharingPermissionByValues(
    sharingMode: NewSharingComboMode,
    indeterminateRight: boolean,
    values: SharingPermissionValues,
    permissionType?: PermissionType,
    additionalPermissionType?: BuildingProgressPermissionType
  ): SharingPermission {
    return this.getPermissionList(sharingMode, indeterminateRight, permissionType, additionalPermissionType).find(
      p => p.permissions.permission === values.permission &&
        p.permissions.additionalPermission === values.additionalPermission);
  }

  getPermissionList(
    sharingMode: NewSharingComboMode,
    indeterminateRight: boolean,
    permissionType?: PermissionType,
    additionalPermissionType?: BuildingProgressPermissionType
  ): SharingPermission[] {
    switch (sharingMode) {
      case NewSharingComboMode.AddUserProjectDocuments:
        return this.permissionsUtils.addUserProjectDocumentsPermissions();
      case NewSharingComboMode.EditUserDocuments:
        return this.permissionsUtils.editUserDocumentsPermissions(indeterminateRight);
      case NewSharingComboMode.EditUserAllDocuments:
        return this.permissionsUtils.editUserDocumentsPermissions(indeterminateRight, true);
      case NewSharingComboMode.AddUserBuildingProgress:
        return this.permissionsUtils.addUserBuildingProgressPermissions(permissionType, additionalPermissionType);
      case NewSharingComboMode.EditUserBuildingProgress:
        return this.permissionsUtils.editBuildingProgressPermissions(permissionType, additionalPermissionType);
      case NewSharingComboMode.AddUserContractors:
        return this.permissionsUtils.addUserContractorsPermissions();
      case NewSharingComboMode.AddBudget:
        return this.permissionsUtils.addBudgetPermissions(this.hasBudgetAccess);
      case NewSharingComboMode.EditBudget:
        return this.permissionsUtils.editBudgetPermissions(this.hasBudgetAccess);
      default:
        return null;
    }
  }

  getPermissionMultipleList(sharingMode: NewSharingComboMode): SharingPermissionMultipleItem[] {
    switch (sharingMode) {
      case NewSharingComboMode.EditUserProject:
        return this.permissionsUtils.editUserProjectPermissions(this.hasBudgetAccess);
      default:
        return null;
    }
  }

  getApplicationSharingComboMode(applicationType: ApplicationType): NewSharingComboMode {
    switch (applicationType) {
      case ApplicationType.BuildingProgress:
        return NewSharingComboMode.EditUserBuildingProgress;
      case ApplicationType.Documents:
        return NewSharingComboMode.EditUserAllDocuments;
      case ApplicationType.Budget:
        return NewSharingComboMode.EditBudget;
      default:
        return NewSharingComboMode.Undefined;
    }
  }

  hasLicense(applicationType: ApplicationType): boolean {
    switch (applicationType) {
      case ApplicationType.BuildingProgress:
        return this.isLicenseValid(ApplicationType.BuildingProgress);
      case ApplicationType.Budget:
        return this.isLicenseValid(ApplicationType.Budget);
      case ApplicationType.QuantityManager:
        return this.isLicenseValid(ApplicationType.QuantityManager);
      default:
        return true;
    }
  }

  hasFreeOrReadOnlyLicense(applicationType: ApplicationType): boolean {
    switch (applicationType) {
      case ApplicationType.Budget:
      case ApplicationType.BuildingProgress:
        return this.licenses[applicationType]?.licenseType === LicenseType.Readonly ||
          this.licenses[applicationType]?.licenseType === LicenseType.Free;
      default:
        return true;
    }
  }

  getLicenseTooltip(applicationType: ApplicationType): string {
    if ((applicationType === ApplicationType.Budget && this.hasBudgetOrQuantityManagerLicense()) || this.hasLicense(applicationType)) {
      return '';
    } else {
      switch (applicationType) {
        case ApplicationType.BuildingProgress:
          return this.licenses[ApplicationType.BuildingProgress]?.licenseType === LicenseType.Free
            ? this.translateService.translate('BUILDING_PROGRESS.LICENSE_INFO_FREE_TEXT')
            : this.translateService.translate('BUILDING_PROGRESS.LICENSE_INFO_READONLY_TEXT');
        case ApplicationType.Budget:
          return this.licenses[ApplicationType.Budget]?.licenseType === LicenseType.Free
            ? this.translateService.translate('BUDGET.LICENSE.INFO_FREE_TEXT')
            : this.translateService.translate('BUDGET.LICENSE.INFO_READONLY_TEXT');
        default:
          return '';
      }
    }
  }

  getDefaultApplicationPermissions(permissionItems: SharingPermissionMultipleItem[]): SharingPermissionValues[] {
    return permissionItems.map(item => {
      return {
        applicationType: item.applicationType,
        permission: this.hasLicense(item.applicationType) ? PermissionType.Reader : PermissionType.NoAccess,
        additionalPermission: this.getAdditionalPermission(item.applicationType)
      };
    });
  }

  getNewMultipleSharings(users: UserIds[], message: string, applicationPermissions: SharingPermissionValues[]): ItemSharingMultiple[] {
    return users.map(user => {
      return {
        email: user.email,
        isRegisteredUser: user.isRegisteredUser,
        userMessage: !!message ? message : undefined,
        permissions: applicationPermissions.map(permission => ({
          applicationType: permission.applicationType,
          permissionType: permission.permission,
          additionalPermissionType: permission.additionalPermission
        }))
      };
    });
  }

  getNewSharings(
    users: UserIds[], message: string, permissionType: PermissionType, additionalPermissionType: BuildingProgressPermissionType
  ): ItemSharing[] {
    return users.map(user => {
      return {
        email: user.email,
        permissionType,
        additionalPermissionType,
        isRegisteredUser: user.isRegisteredUser,
        userMessage: message ? message : undefined
      };
    });
  }

  getReadOnlyRoleName(
    sharingMode: NewSharingComboMode, permissionType: PermissionType, additionalPermissionType: BuildingProgressPermissionType
  ): string {
    if (permissionType === PermissionType.Owner) {
      return this.translateService.translate('SHARING.OWNER');
    } else {
      return this.getSharingPermissionByValues(
        sharingMode,
        permissionType === PermissionType.Unidentified,
        { permission: permissionType, additionalPermission: additionalPermissionType },
        permissionType,
        additionalPermissionType
      )?.name ?? '';
    }
  }

  getReadOnlyRoleTooltip(
    sharingMode: NewSharingComboMode, permissionType: PermissionType, additionalPermissionType: BuildingProgressPermissionType
  ): string {
    if (permissionType !== PermissionType.Owner) {
      return this.getSharingPermissionByValues(
        sharingMode,
        permissionType === PermissionType.Unidentified,
        { permission: permissionType, additionalPermission: additionalPermissionType },
        permissionType,
        additionalPermissionType
      )?.description ?? '';
    } else {
      return '';
    }
  }

  private getAdditionalPermission(applicationType: ApplicationType): number {
    switch (applicationType) {
      case ApplicationType.BuildingProgress:
        return BuildingProgressPermissionType.None;
      case ApplicationType.Documents:
        return DocumentPermissionType.None;
      case ApplicationType.Budget:
        return BudgetPermissionType.None;
    }
  }

  private hasBudgetOrQuantityManagerLicense(): boolean {
    return this.hasLicense(ApplicationType.Budget) || this.hasLicense(ApplicationType.QuantityManager);
  }

  private isLicenseValid(applciationType: ApplicationType): boolean {
    return this.licenses[applciationType] &&
      this.licenses[applciationType].licenseType !== LicenseType.Readonly &&
      this.licenses[applciationType].licenseType !== LicenseType.Free;
  }
}
