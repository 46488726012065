import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';

const externalApplicationApi = '/api/externalApplicationsService/';
const uiSettingsEndpoint = '/ui-settings/';

@Injectable()
export class ExternalApplicationService {

  private http = inject(HttpClient);
  private appConfig = inject(APP_CONFIG);

  getUiSettings(userId: number, settingsName: string): Observable<any> {
    return this.http.get<any>(`${this.getExternalApplicationApi()}${userId}${uiSettingsEndpoint}${settingsName}`);
  }

  saveUiSettings(userId: number, settingsName: string, value: string): Observable<any> {
    return this.http.put(`${this.getExternalApplicationApi()}${userId}${uiSettingsEndpoint}${settingsName}`, value);
  }

  private getExternalApplicationApi(): string {
    return this.appConfig.gatewayApiUrl + externalApplicationApi;
  }
}
