import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter } from 'rxjs';

import { ApplicationModule, ApplicationModuleIdProviderService } from '../application-module';

@Injectable()
export class UserProfileService {
  private appModuleIdProviderService = inject(ApplicationModuleIdProviderService);

  applicationModulId: ApplicationModule;

  constructor() {
    this.appModuleIdProviderService?.applicationModuleId$.pipe(
      filter(Boolean),
      takeUntilDestroyed()
    ).subscribe((applicationModulId) => this.applicationModulId = applicationModulId);
  }
}
