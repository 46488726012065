import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { of, switchMap, tap } from 'rxjs';

import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { DocumentListInfoModel, FolderTreeNode } from '../../models';
import {
  DocumentService,
  DocumentsExplorerDispatchersService,
  DocumentsExplorerSelectorsService
} from '../../../document-explorer';

@Component({
  selector: 'kros-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbComponent extends UnsubscribeComponent implements OnInit {

  parentFolders: DocumentListInfoModel[];
  currentFolderName: string;

  private parentId: string;

  constructor(
    private documentService: DocumentService,
    private documentsExplorerSelectors: DocumentsExplorerSelectorsService,
    private documentsExplorerDispatchers: DocumentsExplorerDispatchersService,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.documentsExplorerSelectors.currentFolder$.pipe(
      tap(currentFolder => this.currentFolderName = currentFolder?.name),
      switchMap(currentFolder => {
        if (!!currentFolder) {
          if (this.parentFolders?.length && this.parentId === currentFolder.parentId) {
            return of(this.parentFolders);
          } else {
            this.parentId = currentFolder.parentId;
            return this.documentService.getDocumentParentFolderList(currentFolder.id);
          }
        } else {
          this.parentId = undefined;
          return of([]);
        }
      })
    ).subscribe(p => {
      this.parentFolders = p;
      this.cd.detectChanges();
    });
  }

  openRoot(): void {
    this.documentsExplorerDispatchers.loadRoot();
  }

  openFolder(folder: DocumentListInfoModel): void {
    const node = { ...folder } as FolderTreeNode;
    this.documentsExplorerDispatchers.loadFolder(node);
  }
}
