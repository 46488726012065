import { createEntityAdapter } from '@ngrx/entity';

import { BoqItem } from '@kros-sk/ssw-shared-legacy';

import { BoqItemsState } from './budget-approval.state';

export const adapterBoqItems = createEntityAdapter<BoqItem>();
export const boqItemsInitialState: BoqItemsState = adapterBoqItems.getInitialState({
  isLoading: false
});
