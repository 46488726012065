import { Component } from '@angular/core';

@Component({
  selector: 'kros-circular-spinner',
  templateUrl: './circular-spinner.component.html',
  styleUrls: ['./circular-spinner.component.scss']
})
export class CircularSpinnerComponent {

}
