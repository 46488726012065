import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

import { filter, mergeMap } from 'rxjs/operators';
import { from, interval } from 'rxjs';
import { SubSink } from 'subsink';

import { APP_CONFIG } from '@kros-sk/app-config';
import { Maintenance } from '@kros-sk/models';

import { MaintenanceService } from '../../services/maintenance.service';

@Component({
  selector: 'kros-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaintenanceComponent implements OnInit, OnDestroy {

  maintenance: Maintenance;
  storageUrl = this.appConfig.staticContentStorage + '/images/maintenance/';

  private subs = new SubSink();

  constructor(
    private maintenanceService: MaintenanceService,
    @Inject(APP_CONFIG) private appConfig: any,
    private changeDetector: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private swUpdate: SwUpdate
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.maintenanceService.getMaintenanceFromServer()
      .subscribe(p => {
        if (p) {
          this.maintenance = p;
          this.changeDetector.detectChanges();
          this.periodicallyCheckForEndOfMaintenanceAndRedirectOnEnds();
        } else {
          this.updateWebappAndReload();
        }
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private periodicallyCheckForEndOfMaintenanceAndRedirectOnEnds(): void {
    this.subs.sink = interval(0.5 * 60 * 1000).pipe(
      mergeMap(() => this.maintenanceService.getMaintenanceFromServer()),
      filter(p => !p)
    ).subscribe(() => this.updateWebappAndReload());
  }

  private updateWebappAndReload(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.activateUpdate().then(() => {
        this.reloadApp();
      });
    } else {
      this.reloadApp();
    }
  }

  private reloadApp(): void {
    if (this.activatedRoute.snapshot.data.redirectUrl) {
      location.href = this.activatedRoute.snapshot.data.redirectUrl;
    } else {
      from(this.router.navigate(['/'])).subscribe(() => window.location.reload());
    }
  }
}
