import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import {
  CompaniesRegisterSearchModule,
  isEnterKey,
  KrosFormFieldModule,
  KrosInputsModule,
  KrosModalModule,
  KrosModalRef
} from '@kros-sk/components';
import { CompanySearchValidationOptions } from '@kros-sk/models';

@Component({
  selector: 'kros-single-input-modal',
  standalone: true,
  templateUrl: './single-input-modal.component.html',
  styleUrls: ['./single-input-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    KrosFormFieldModule,
    KrosModalModule,
    ReactiveFormsModule,
    TranslateModule,
    CompaniesRegisterSearchModule,
    KrosInputsModule
  ]
})
export class SingleInputModalComponent implements OnInit {

  form: FormGroup;
  modalTitleKey: string;
  modalTextKey: string;
  modalSubmitKey: string;
  defaultValue: any;
  labelKey: string;
  placeholderKey: string;
  requiredErrorTooltipKey: string;
  useCompanyInput: boolean;
  showErrors = false;
  companySearchOptions: CompanySearchValidationOptions = {
    validators: [Validators.required],
  };

  get value(): AbstractControl {
    return this.form.get('valueControl');
  }

  constructor(
    private modalRef: KrosModalRef
  ) {
    this.modalTitleKey = this.modalRef.data.modalTitleKey;
    this.modalTextKey = this.modalRef.data.modalTextKey;
    this.modalSubmitKey = this.modalRef.data.modalSubmitKey;
    this.defaultValue = this.modalRef.data.defaultValue;
    this.labelKey = this.modalRef.data.labelKey;
    this.placeholderKey = this.modalRef.data.placeholderKey;
    this.requiredErrorTooltipKey = this.modalRef.data.requiredErrorTooltipKey;
    this.useCompanyInput = this.modalRef.data.useCompanyInput ?? false;
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      valueControl: new FormControl(this.defaultValue ?? '', Validators.required)
    });
  }

  onKeyDown(event: KeyboardEvent): void {
    if (isEnterKey(event)) {
      this.onSubmit();
    }
  }

  onClose(): void {
    this.modalRef.cancel();
  }

  onSubmit(): void {
    this.form.markAllAsTouched();
    if (!this.value.hasError('required')) {
      this.modalRef.submit({
        value: this.value.value
      });
    } else if (this.useCompanyInput) {
      this.showErrors = true;
    }
  }
}
