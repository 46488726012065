import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, Observable, of, switchMap, withLatestFrom } from 'rxjs';

import * as listActions from '../../../store/subcontractors/subcontractors.actions';
import * as partialItemsActions from './building-progress-partial-items.actions';
import { BuildingProgressPartialItemsSelectorsService } from './building-progress-partial-items-selectors.service';
import { BuildingProgressPartialItemsService } from '../../../building-progress/services/building-progress-partial-items.service';

@Injectable()
export class BuildingProgressPartialItemsEffectsService {

  recalculatePartialItems$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(partialItemsActions.recalculatePartialItems),
      withLatestFrom(this.partialItemsSelectorsService.partialItemsData$, this.partialItemsSelectorsService.decimalPlaces$,
        this.partialItemsSelectorsService.partialItemAmount$),
      switchMap(([_, partialItems, decimalPlaces, partialItemAmount]) =>
        this.partialItemsService.recalculatePartialItems(partialItems, decimalPlaces, partialItemAmount).pipe(
          map((partialBudgetItems) => partialItemsActions.recalculatePartialItemSuccess({ partialBudgetItems })),
          catchError(error => of(partialItemsActions.recalculatePartialItemsError({ error })))
        ))
    );
  });

  savePartialItems$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(partialItemsActions.savePartialItems),
      withLatestFrom(this.partialItemsSelectorsService.partialItemsData$, this.partialItemsSelectorsService.projectId$),
      switchMap(([payload, partialItems, projectId]) =>
        this.partialItemsService.savePartialItems(partialItems, projectId, payload.originalItemIdOfPartialItem).pipe(
          switchMap(() => of(partialItemsActions.savePartialItemSuccess(), listActions.loadDialogItemsListStart({ projectId }))),
          catchError(error => of(partialItemsActions.savePartialItemsError({ error })))
        ))
    );
  });

  constructor(
    private actions$: Actions,
    private partialItemsSelectorsService: BuildingProgressPartialItemsSelectorsService,
    private partialItemsService: BuildingProgressPartialItemsService
  ) { }
}
