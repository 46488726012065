<div class="members-wrapper">
  <div class="tablet-visible">
    <div class="toolbar-actions">
      <div class="actions left">
        <button
          class="btn toolbar-action gray-toolbar-action no-selection open-panel"
          type="button"
          (click)="onOpenPanel()"
          krosTooltip="{{ 'SHARING.SELECTION' | translate }}"
        >
          <i class="material-icons icon-button">list</i>
        </button>
      </div>
    </div>
  </div>

  <app-project-members-panel-container
    class="members-panel"
    (projectMembersSelected)="onProjectMembersSelected($event)"
  >
  </app-project-members-panel-container>
  <div class="content-wrapper">
    <ng-container *ngIf="isProjectMembersSelected else ownerDetail">
      <app-new-project-members-detail></app-new-project-members-detail>
    </ng-container>

    <ng-template #ownerDetail>
      <app-project-members-owner-detail>
      </app-project-members-owner-detail>
    </ng-template>
  </div>
</div>
