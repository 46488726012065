import { ActivatedRoute } from '@angular/router';
import { Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { distinctUntilChanged, filter, Observable } from 'rxjs';

import {
  DropdownNavItem,
  NavbarService,
  Project,
  ProjectCreateService,
  ProjectDetail,
  TranslateService,
  UndoRedoDispatchersService
} from '@kros-sk/ssw-shared-legacy';
import { FeatureFlags, HasFeatureFlagFacade } from '@kros-sk/core/feature-flags';
import { StorageService, UserService } from '@kros-sk/ssw-cdk';

import { CanComponentDeactivate } from '../../../core/guards/can-deactivate.guard';
import { ConnectorService } from '../../../core/services/connector.service';
import { DocumentsSelectorsService } from '../../../store/documents';
import { environment } from '../../../../environments/environment';
import { LicenseHelper } from '../../../building-progress/helpers';
import { ProjectsDispatchersService, ProjectsSelectorsService } from '../../../store/projects';
import { ProjectsService } from '../../services/projects.service';

const projNavPanelCollapsedStorageItem = 'projNavPanelCollapsed';
const projectDetailUrl = 'projects/project-detail/';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit, OnDestroy, CanComponentDeactivate {

  public documentsSelectorsService = inject(DocumentsSelectorsService);
  private destroyRef = inject(DestroyRef);
  private navbarService = inject(NavbarService);
  private projectsService = inject(ProjectsService);
  private projectsDispatchersService = inject(ProjectsDispatchersService);
  private undoRedoDispatchersService = inject(UndoRedoDispatchersService);
  private projectsSelectorsService = inject(ProjectsSelectorsService);
  private translateService = inject(TranslateService);
  private route = inject(ActivatedRoute);
  private storageService = inject(StorageService);
  private userService = inject(UserService);
  private connectorService = inject(ConnectorService);
  private projectCreateService = inject(ProjectCreateService);
  private licenseHelper = inject(LicenseHelper);
  private readonly featureFlagFacade = inject(HasFeatureFlagFacade);

  documentId: string;
  editMode = false;
  project: ProjectDetail;
  panelCollapsed = false;
  isDestroyed = false;
  projects: Project[];

  get isInDocuments(): boolean {
    return this.route.firstChild?.snapshot?.data?.isInDocuments;
  }

  private get hasBudgetAccess(): boolean {
    return this.isBudgetMini || this.licenseHelper.isSchoolLicense;
  }

  private isBudgetMini = false;

  ngOnInit(): void {
    this.panelCollapsed = JSON.parse(this.storageService.getItemFromLocalStorage(projNavPanelCollapsedStorageItem));
    this.connectorService.connect(this.userService.getUserIdentityId() + '');
    let projectId = -1;
    this.navbarService.changeBackButtonUrl('projects');

    this.route.paramMap.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(paramMap => {
        const projectIdParam = +paramMap.get('projectId');
        if (!isNaN(projectIdParam) && this.project?.id !== projectIdParam) {
          projectId = projectIdParam;
          this.loadData(projectIdParam);
          this.undoRedoDispatchersService.clearUndoRedoStacks();
        }
      });

    this.projectsSelectorsService.projectDetail$
      .pipe(
        filter(i => i?.id === projectId),
        distinctUntilChanged((prev, curr) => prev?.id === curr?.id),
        takeUntilDestroyed(this.destroyRef))
      .subscribe((project: ProjectDetail) => {
        this.project = project;
        const isCreatedNewProject = !this.projects?.some(p => p.id === this.project.id);
        this.setMobileDropdownNavItems(this.removeLastIdFromPath(this.route.firstChild.routeConfig.path));
        this.setDesktopDropdownNavItems(this.removeLastIdFromPath(this.route.firstChild.routeConfig.path), isCreatedNewProject);
      });

    this.navbarService.loadProjects$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.setDesktopDropdownNavItems(this.removeLastIdFromPath(this.route.firstChild.routeConfig.path), true));

    this.featureFlagFacade.featureFlags$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((ff: FeatureFlags) => this.isBudgetMini = ff.BudgetMini);
  }

  ngOnDestroy(): void {
    this.navbarService.changeBackButtonUrl(undefined);
    this.navbarService.setDesktopDropdown(undefined);
    this.navbarService.setMobileDropdown(undefined);
    this.isDestroyed = true;
  }

  loadData(projectId: number): void {
    this.projectsDispatchersService.loadProjectDetail(projectId);
  }

  setDesktopDropdownNavItems(childPath: string, canReloadProject?: boolean): void {
    if (!this.projects || canReloadProject) {
      this.projectsService.getProjects()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(projects => {
          this.projects = projects;
          this.setDesktopDropdown(childPath);
        });
    } else {
      this.setDesktopDropdown(childPath);
    }
  }

  private setDesktopDropdown(childPath: string): void {
    this.navbarService.setDesktopDropdown(this.projects.map(p => ({
      url: `${projectDetailUrl}${p.id}/${childPath}`,
      title: p.name,
      selected: p.id === this.project.id
    })));
  }

  setMobileDropdownNavItems(selected: string): void {
    const data: DropdownNavItem[] = [
      {
        icon: 'dashboard',
        selected: selected === 'dashboard',
        url: projectDetailUrl + this.project.id + '/dashboard',
        title: 'DASHBOARD.TITLE'
      },
      {
        icon: 'description',
        selected: selected === 'documents',
        url: projectDetailUrl + this.project.id + '/documents',
        title: environment.location === 'cz' ? 'DOCUMENTS.TITLE_CZ' : 'DOCUMENTS.TITLE'
      },
      // { dočasne skryté kým nebudú ZPV prispôsobené na mobile
      //     icon: 'published_with_changes',
      //     selected: selected.startsWith('budget-approval'),
      //     url: projectDetailUrl + this.project.id + '/budget-approval',
      //     title: 'BUDGET_APPROVAL.TITLE'
      // },
      ...(this.hasBudgetAccess ? [
        {
          customIcon: 'assets/icons/icon-budget.svg',
          selected: selected === 'budget',
          url: projectDetailUrl + this.project.id + '/budget',
          title: 'BUDGET.TITLE'
        }
      ] : []),
      {
        icon: 'home_work',
        customIcon: environment.location === 'sk' ? 'assets/icons/icon-building-progress.svg' : undefined,
        selected: selected === 'building-progress',
        url: projectDetailUrl + this.project.id + '/building-progress',
        title: 'BUILDING_PROGRESS.TITLE'
      },
      {
        icon: 'people',
        selected: selected === 'members',
        url: projectDetailUrl + this.project.id + '/members',
        title: 'SHARING.TITLE'
      },
      {
        icon: 'home',
        selected: selected === '/projects',
        url: '/projects',
        title: 'PROJECTS.TITLE'
      },
      {
        icon: 'add',
        onClick: this.onCreateProject.bind(this),
        title: 'PROJECTS.ZOZNAM.NEW_PROJECT'
      },
      {
        icon: 'poll',
        onClick: this.openProjectReport.bind(this),
        title: 'PROJECTS.DETAIL.INFO.PROJECT_REPORTS'
      }
    ];

    this.navbarService.setMobileDropdown(data);
  }

  openProjectReport(): void {
    window.open(environment.appUrls.projectReportUrl, '_blank');
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.editMode) {
      return confirm(this.translateService.translate('PROJECTS.DETAIL.INFO.ZMENY_SA_ZRUSIA'));
    }
    return true;
  }

  toggleCollapsePanel(): void {
    this.panelCollapsed = !this.panelCollapsed;
    this.storageService.setItemToLocalStorage(projNavPanelCollapsedStorageItem, this.panelCollapsed.toString());
  }

  onCreateProject(): void {
    this.projectCreateService.createAndOpenProject('projects/project-detail', 'P-create-project-side-panel');
  }

  private removeLastIdFromPath(path: string): string {
    const idIndex = path.lastIndexOf(':id');
    return idIndex === -1 ? path : path.substring(0, idIndex);
  }
}
