import { CalculationType } from '@kros-sk/ssw-shared-legacy';

export class BuildingProgressEditModel {
  projectId: number;
  periodId: number;
  editedPeriodItems: BuildingProgressEditedPeriodItem[];
}

export class BuildingProgressEditedPeriodItem {
  budgetItemId: number;
  changedProperty: BuildingProgressChangedProperty;
  amount: number;
  totalPrice: number;
  percentage: number;
  oldValue?: number;
  calculationType?: CalculationType;
}

export enum BuildingProgressChangedProperty {
  Amount = 0,
  TotalPrice = 1,
  Percentage = 2
}
