<div
  class="card form-edit"
  data-test="open-api-settings-row"
>
  <div
    class="editor-container"
    data-test="open-api-settings-row"
  >
    <div class="header">
      <ssw-open-api-settings-list-header
        [subscription]="subscription"
        [isOpened]="true"
        (deleteClicked)="delete()"
      >
      </ssw-open-api-settings-list-header>
    </div>

    <form
      krosFormGuard
      class="editor"
      [formGroup]="subscriptionForm"
    >
      <div>
        <kros-form-field>
          <kros-label>{{ 'SETTINGS.API.CONNECTION_NAME' | translate }}</kros-label>

          <input
            krosControl
            focusableInput
            markableAsInvalid
            [formControl]="subscriptionForm.controls.name"
            class="form-control"
            data-test="name-input"
            type="text"
            maxLength="255"
            placeholder="{{ 'SETTINGS.API.CONNECTION_NAME_PLACEHOLDER' | translate }}"
            (input)="handleInput()"
          >

          <kros-input-error *ngIf="subscriptionForm.controls.name.hasError('required')">
            {{ 'COMMON.REQUIRED_ITEM' | translate }}
          </kros-input-error>
        </kros-form-field>
      </div>

      <div>
        <kros-form-field>
          <kros-label>{{ 'SETTINGS.API.TOKEN_PREVIEW' | translate }}</kros-label>

          <input
            krosControl
            [formControl]="subscriptionForm.controls.secretHint"
            class="form-control input-ellipsis"
            data-test="secretHint-input"
            type="text"
            placeholder="{{ 'SETTINGS.API.TOKEN_PREVIEW_PLACEHOLDER' | translate }}"
          >
        </kros-form-field>
      </div>

      <div>
        <button
          class="btn btn-primary btn-generate"
          type="button"
          (click)="generateToken()"
          [disabled]="tokenGenInProgress"
        >
          <div
            *ngIf="tokenGenInProgress"
            class="spinner-border"
            krosTooltip="{{ 'SETTINGS.API.GENERATING' | translate }}"
            position="below"
          >
          </div>

          <div>
            {{ 'SETTINGS.API.GENERATE_TOKEN' | translate }}
          </div>
        </button>
      </div>

      <div>
        <a
          aria-label='OpenApi - swagger'
          [href]="openApiSwaggerUrl"
          target="_blank"
          rel="noreferrer"
        >{{ 'SETTINGS.API.DOCUMENTATION_FOR_PROGRAMMERS' | translate }}</a>
      </div>

      <button
        class="btn btn-outline-danger btn-delete"
        type="button"
        *ngIf="subscription.clientId"
        (click)="delete()"
      >{{ 'COMMON.DELETE' | translate }}</button>
    </form>
  </div>
</div>
