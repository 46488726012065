<div class="boq-container">
  <app-boq-panel-header
    [headerText]="headerText"
    (closeHeaderClicked)="onCloseClick()"
    (resizePanelClicked)="onResizePanelClick()"
  ></app-boq-panel-header>

  <div class="boq-content">
    <div class="table-container">
      <ng-container *ngIf="!isBoqLoading; else loading">
        <ng-container *ngIf="isItem; else noItems">
          <table class="table">
            <thead>
              <tr>
                <th
                  scope="col"
                  class="marking"
                >{{'BILL_OF_QUANTITIES.DRAWN' | translate}}</th>
                <th
                  scope="col"
                  class="period"
                >{{'BUILDING_PROGRESS.PERIODS.PERIOD' | translate}}</th>
                <th
                  scope="col"
                  class="expression"
                >{{ 'BILL_OF_QUANTITIES.CALCULATION' | translate }}</th>
                <th
                  scope="col"
                  class="quantity"
                >{{ 'BILL_OF_QUANTITIES.QUANTITY' | translate }}</th>
              </tr>
            </thead>

            <tbody>
              <tr
                *ngFor="let item of items; let i = index"
                data-test="boq-row"
                [ngClass]="getCellClass(item)"
                [class.focused]="boqVisualHelper.isRowFocused(i)"
              >
                <td
                  tabindex="0"
                  class="marking"
                  [class.focused-cell]="boqVisualHelper.isCellFocused(i, 0)"
                  (click)="onCellClick(i, 0)"
                  (keydown)="onCellKeyDown($event, i , 0)"
                >
                  <input
                    type="checkbox"
                    *ngIf="isExpression(item) || isNote(item)"
                    [checked]="!isPeriodIdNull(item.periodId)"
                    [disabled]="readonly || isDisabled(item.periodId) || isAccessDisabled || data.isNotCompleted"
                    (change)="onItemCheckChanged($event.target, item)"
                    (click)="onItemCheck($event, item)"
                  >
                </td>

                <td
                  tabindex="0"
                  [class.focused-cell]="boqVisualHelper.isCellFocused(i, 1)"
                  (click)="onCellClick(i, 1)"
                  (keydown)="onCellKeyDown($event, i, 1)"
                >{{ getPeriodText(item.periodId) }}</td>

                <td
                  tabindex="0"
                  [id]="boqVisualHelper.getExpressionId(i)"
                  [class.focused-cell]="boqVisualHelper.isCellFocused(i, 2)"
                  (click)="onCellClick(i, 2)"
                  (keydown)="onCellKeyDown($event, i, 2)"
                >
                  <ng-container *ngIf="isExpressionOrNote(item) && !readonly && !isDisabled(item.periodId) && hasAccess && !data.isNotCompleted;
                                         else noEdit">
                    <input
                      [id]="boqVisualHelper.getExpressionContentId(i)"
                      type="text"
                      class="cell-input"
                      autocomplete="off"
                      [formControl]="getExpressionFormControl(i)"
                      (focusout)="edit(i)"
                      (input)="onExpressionChange($event.target, i)"
                      (keyup.enter)="edit(i, true)"
                    >
                  </ng-container>

                  <ng-template #noEdit>
                    <span>{{ item.expression }}</span>
                  </ng-template>
                </td>

                <td
                  tabindex="0"
                  class="text-right"
                  [class.focused-cell]="boqVisualHelper.isCellFocused(i, 3)"
                  (click)="onCellClick(i, 3)"
                  (keydown)="onCellKeyDown($event, i, 3)"
                >
                  <ng-container *ngIf="!isNote(item)">{{ getFormattedAmount(item.quantity) }}
                  </ng-container>
                </td>
              </tr>
            </tbody>

            <tfoot>
              <tr data-test="boq-summary-row">
                <td
                  class="text-right"
                  colspan="3"
                  [class.disabled]="readonly || data.isNotCompleted"
                >{{ 'BILL_OF_QUANTITIES.ITEM_EXECUTION' | translate }}</td>
                <td
                  class="quantity-info-value text-right"
                  [class.disabled]="readonly || data.isNotCompleted"
                >{{ getFormattedAmount(resultAmount) }}</td>
              </tr>
            </tfoot>
          </table>
        </ng-container>
      </ng-container>

      <ng-template #loading>
        <kros-timeline [type]="timelineType.TwoLine"></kros-timeline>
      </ng-template>

      <ng-template #noItems>
        <p
          class="no-items"
          data-test="no-boq-items"
        >{{ 'BILL_OF_QUANTITIES.NO_BOQ_ITEMS' | translate }}</p>
      </ng-template>
    </div>

    <div class="controls-container">
      <div class="action-controls">
        <button
          id="ga-boq-add"
          class="btn btn-action"
          type="button"
          data-test="boq-add-button"
          (click)="onCreate()"
          [disabled]="readonly || data.isNotCompleted"
        >
          <i class="material-icons">add</i>
          <span>{{ 'BILL_OF_QUANTITIES.ADD_ROW' | translate }}</span>
        </button>

        <button
          id="ga-boq-delete"
          class="btn btn-action"
          type="button"
          data-test="boq-delete-button"
          (click)="onDelete()"
          [disabled]="isDeleteDisabled || data.isNotCompleted"
        >
          <i class="material-icons">delete</i>
          <span>{{ 'BILL_OF_QUANTITIES.REMOVE_ROW' | translate }}</span>
        </button>
      </div>

      <button
        id="ga-boq-save"
        class="btn btn-primary btn-save"
        type="button"
        data-test="boq-save-button"
        (click)="onSaveClick()"
        [disabled]="readonly || (!(editedBoqItems$ | async)?.length && !(deletedBoqItemIds$ | async)?.length) || data.isNotCompleted"
      >{{'COMMON.ULOZIT' | translate}}</button>
    </div>
  </div>
</div>
