import { Injectable } from '@angular/core';

import { BuildingProgressPermissionType, PermissionType } from '@kros-sk/ssw-shared/permission';
import {
  ColDef,
  DataTableConfigModel,
  isConstructionOrBuildingObject,
  isSection,
  isSummaryItem
} from '@kros-sk/ssw-shared-legacy';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { BuildingProgressItem, BuildingProgressModel } from '../../building-progress/models/construction-data.model';
import { BuildingProgressPeriod } from '../models/building-progress-period.model';
import { BuildingProgressSelectorsService } from '../../store/building-progress';
import { CommentPositionModel } from '../modules/comments-panel/comments-panel/comment.model';
import { getColumnsMaxNumber } from '../helpers/table-config-columns.helper';
import { hasContractorRight } from '../shared/building-progress-utils';
import { TableChangeDetectionService } from './table-change-detection.service';
import { TableConfigColumnsService } from './table-config-columns.service';

@Injectable()
export class BuildingProgressTableService extends UnsubscribeComponent {
  averageLineHeight: number;

  private commentPositions: CommentPositionModel[];
  private visiblePeriods: Set<number>;
  private selectedItemIds = new Set<number>();
  private indeterminateItemIds = new Set<number>();
  private hiddenColumns = new Set<string>();
  private config: DataTableConfigModel;

  constructor(
    private buildingProgressSelectorsService: BuildingProgressSelectorsService,
    private tableConfigService: TableConfigColumnsService,
    private tableChangeDetectionService: TableChangeDetectionService
  ) {
    super();
  }

  init(): void {
    this.subs.sink = this.buildingProgressSelectorsService.selectedItemIds$.subscribe(
      itemIds => {
        if (itemIds && itemIds instanceof Set) {
          this.selectedItemIds = itemIds;
        }
      }
    );

    this.subs.sink = this.buildingProgressSelectorsService.indeterminateItemIds$.subscribe(
      itemIds => {
        if (itemIds && itemIds instanceof Set) {
          this.indeterminateItemIds = itemIds;
        }
      }
    );

    this.subs.sink = this.buildingProgressSelectorsService.commentPositions$
      .subscribe((commentPositions: CommentPositionModel[]) => {
        this.setCommentPositions(commentPositions);
        this.tableChangeDetectionService.forceTableChanges();
      });

    this.subs.sink = this.buildingProgressSelectorsService.periodId$
      .subscribe(id => {
        this.setSelectedPeriodId(id);
      });
  }

  destroy(): void {
    this.subs.unsubscribe();
  }

  getTableConfig(
    projectId: number,
    permissionType: PermissionType,
    buildingProgressPermissionType: BuildingProgressPermissionType,
    constructionData: BuildingProgressModel,
    isReadonly: boolean,
    isViewFilterActive: boolean,
    isFullOrTrialLicense: boolean,
    hasSubcontractorColumn: boolean = true,
    hasPeriods: boolean = true
  ): DataTableConfigModel {
    const columnsMaxNumber = getColumnsMaxNumber(constructionData);
    this.averageLineHeight = columnsMaxNumber.averageLineHeight;
    this.config = {
      isReadonly,
      itemsOffset: columnsMaxNumber.itemsOffset,
      isMultiSelect: true,
      fixedWidth: true,
      colDefs: this.tableConfigService
        .getTableColumnsDefinition(
          projectId,
          constructionData,
          columnsMaxNumber,
          this.tableConfigService,
          hasSubcontractorColumn,
          hasPeriods,
          isFullOrTrialLicense
        ),
      rowClassGetter: (data: BuildingProgressItem): string => {
        let classString = '';
        classString += isConstructionOrBuildingObject(data) ? 'font-blue font-strong' : '';
        classString += isSection(data) ? ' font-strong' : '';
        classString += data.originalId ? ' font-brown' : '';
        return classString;
      },
      rowEditableGetter: (data: BuildingProgressItem, colDef: ColDef): boolean =>
        this.canEdit(permissionType, buildingProgressPermissionType) &&
        (!!data.originalId || !isSummaryItem(data) || (colDef.id.startsWith('percentage-') && !isViewFilterActive))
        && !data.isNotCompleted,
      hasCommentsGetter: (colDef: ColDef, data: BuildingProgressItem): boolean => {
        return this.commentPositions.some(p =>
          p.budgetItemId === data.id && p.periodId === colDef.params?.periodId && p.periodColumn === colDef.params?.periodColumn
        );
      },
      isColumnVisible: (colDef: ColDef): boolean => {
        if (this.hiddenColumns?.size > 0 && this.hiddenColumns.has(colDef.id)) {
          return false;
        }
        if (this.isPartlyCompletedColumn(colDef)) {
          return this.isPartlyCompletedColumnVisible(constructionData.periods);
        }
        if ((this.visiblePeriods?.size > 0) && colDef.params) {
          return this.visiblePeriods.has(colDef.params.periodId);
        }
        return true;
      },
      isItemSelectedGetter: (item: BuildingProgressItem): boolean => {
        if (this.selectedItemIds) {
          return this.selectedItemIds.has(item.id);
        } else {
          return false;
        }
      },
      isItemIndeterminateGetter: (item: BuildingProgressItem): boolean => {
        if (this.indeterminateItemIds) {
          return this.indeterminateItemIds.has(item.id);
        } else {
          return false;
        }
      }
    };

    this.setDetailsColumn(this.tableConfigService.selectedPeriodId, this.config.colDefs);
    this.setHiddenColumns();

    return this.config;
  }

  setFilter(periods: Set<number>): void {
    this.visiblePeriods = periods;
  }

  hideColumns(hiddenColumns: Set<string>): void {
    this.hiddenColumns = hiddenColumns;
  }

  setSelectedPeriodId(selectedPeriodId: number): void {
    this.tableConfigService.selectedPeriodId = selectedPeriodId;

    if (this.config) {
      this.setDetailsColumn(selectedPeriodId, this.config.colDefs);
    }
  }

  setCommentPositions(commentPositions: CommentPositionModel[]): void {
    this.commentPositions = commentPositions;
  }

  isPartlyCompletedColumnVisible(periods: BuildingProgressPeriod[]): boolean {
    return this.visiblePeriods?.size === 0 ||
      (this.visiblePeriods?.size > 0 && this.visiblePeriods?.has(periods[periods.length - 1].id));
  }

  private canEdit(permissionType: PermissionType, buildingProgressPermissionType: BuildingProgressPermissionType): boolean {
    return permissionType === PermissionType.Owner || permissionType === PermissionType.Contributor ||
      hasContractorRight(buildingProgressPermissionType);
  }

  private isPartlyCompletedColumn(colDef: ColDef): boolean {
    return ['partlyCompletedPercentage', 'partlyCompletedAmount', 'partlyCompletedTotalPrice'].some(id => id === colDef.id);
  }

  private setDetailsColumn(selectedPeriodId: number, colDefs: Array<ColDef>): void {
    colDefs.forEach(colDef => {
      if (colDef.params?.periodIndex >= 0) {
        colDef.colsInGroup = colDef.colsInGroup.filter(colInGroup => colInGroup.id !== 'details-' + colDef.params.periodId);
      }
    });

    const selectedColGroup = colDefs.find(colDef => colDef.id === 'period-' + selectedPeriodId);
    selectedColGroup?.colsInGroup.push(this.tableConfigService.getDetailsColumn(selectedPeriodId, selectedColGroup.params.periodIndex));
  }

  private setHiddenColumns(): void {
    if (this.hiddenColumns) {
      this.config?.colDefs?.forEach(gc => {
        gc.isHidden = this.hiddenColumns.has(gc.id);
        gc.colsInGroup?.forEach(c => {
          c.isHidden = this.hiddenColumns.has(c.id);
        });
      });
    }
  }

}
