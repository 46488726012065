import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { Component, inject, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter, take } from 'rxjs/operators';

import { ApplicationType, LicenseDispatchersService, LicenseSelectorsService } from '@kros-sk/ssw-cdk';
import { BuildingProgressPermission, PermissionType } from '@kros-sk/ssw-shared/permission';
import { ConstructionDiaryHelper } from '@kros-sk/ssw-shared/construction-diary';
import { PermissionListService, ProjectCreateService } from '@kros-sk/ssw-shared-legacy';

import { BuildingProgressSelectorsService } from '../../../store/building-progress';
import { environment } from '../../../../environments/environment';
import { PermissionHelper } from '../../../building-progress/helpers';

const budgetApprovalRoute = 'budget-approval';
const buildingProgressRoute = 'building-progress';
const quantityManagerRoute = 'quantity-manager';

@Component({
  selector: 'app-project-detail-navigation',
  templateUrl: './project-detail-navigation.component.html',
  styleUrls: ['./project-detail-navigation.component.scss']
})
export class ProjectDetailNavigationComponent {

  protected permissionHelper = inject(PermissionHelper);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private buildingProgressSelectorsService = inject(BuildingProgressSelectorsService);
  private licenseSelectorsService = inject(LicenseSelectorsService);
  private projectCreateService = inject(ProjectCreateService);
  private permissionListService = inject(PermissionListService);
  private licenseDispatchersService = inject(LicenseDispatchersService);
  private constructionDiaryHelper = inject(ConstructionDiaryHelper);

  private permission: BuildingProgressPermission;
  private projectId: number;

  @Input() collapsed: boolean;

  navigationTitle: string;
  isInBuildingProgress: boolean;
  isInBudgetApproval: boolean;
  isInQuantityManager: boolean;

  get isSlovakVersion(): boolean {
    return environment.location === 'sk';
  }

  constructor() {
    this.route.url.pipe(takeUntilDestroyed()).subscribe(() => this.setNavigationTitle());
    this.route.paramMap.pipe(takeUntilDestroyed())
      .subscribe(p => this.projectId = +p.get('projectId'));
    this.router.events.pipe(filter(event => event instanceof ActivationEnd), takeUntilDestroyed())
      .subscribe(() => this.setNavigationTitle());
    this.buildingProgressSelectorsService.permission$.pipe(takeUntilDestroyed())
      .subscribe(permission => this.permission = permission);
    this.licenseSelectorsService.returnToBuildingProgress$
      .pipe(filter(Boolean), take(1), takeUntilDestroyed())
      .subscribe(() => this.onShowBuildingProgress());
    this.licenseSelectorsService.returnToBudgetApproval$
      .pipe(filter(Boolean), take(1), takeUntilDestroyed())
      .subscribe(() => this.onShowBudgetApproval());
    this.licenseSelectorsService.budgetLicense$.pipe(filter(l => !!l), take(1), takeUntilDestroyed())
      .subscribe(license => this.permissionListService.setLicense(ApplicationType.Budget, license));
    this.licenseSelectorsService.buildingProgressLicense$.pipe(filter(l => !!l), take(1), takeUntilDestroyed())
      .subscribe(license => this.permissionListService.setLicense(ApplicationType.BuildingProgress, license));
    this.licenseDispatchersService.loadBudgetLicense();
  }

  canShowBuildingProgress(): boolean {
    return this.permission &&
      (this.permission.permissionType === PermissionType.Reader ||
        this.permission.permissionType === PermissionType.Contributor ||
        this.permission.permissionType === PermissionType.Owner);
  }

  onShowBuildingProgress(): void {
    this.router.navigate(['projects/project-detail', this.projectId, buildingProgressRoute]);
  }

  onShowBudgetApproval(): void {
    this.router.navigate(['projects/project-detail', this.projectId, budgetApprovalRoute]);
  }

  onShowQuantityManager(): void {
    this.router.navigate(['projects/project-detail', this.projectId, quantityManagerRoute]);
  }

  openProjectReport(): void {
    window.open(environment.appUrls.projectReportUrl, '_blank');
  }

  onCreateProject(): void {
    this.projectCreateService.createAndOpenProject('projects/project-detail', 'P-create-project-side-panel');
  }

  onOpenStavario(): void {
    this.constructionDiaryHelper.openStavario();
  }

  onOpenBuildary(): void {
    this.constructionDiaryHelper.openBuildary();
  }

  private setNavigationTitle(): void {
    this.navigationTitle = this.route.snapshot.firstChild.data.title;
    this.isInBuildingProgress = this.route.snapshot.firstChild.routeConfig.path.startsWith(buildingProgressRoute);
    this.isInBudgetApproval = this.route.snapshot.firstChild.routeConfig.path.startsWith(budgetApprovalRoute);
    this.isInQuantityManager = this.route.snapshot.firstChild.routeConfig.path.startsWith(quantityManagerRoute);
  }
}
