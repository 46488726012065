import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { getDocumentIdParameter } from '@kros-sk/ssw-cdk';

import { DocumentListInfoModel, RootDocumentPermissionModel } from '../models';

const documentApi = '/api/documentService/Documents';
const documentParentFolderListEndpoint = '/documentParentFolderList';
const folderDocumentListEndpoint = '/folderDocumentList/';
const rootDocumentListEndpoint = '/rootDocumentList/';
const rootDocumentPermissionEndpoint = '/rootDocumentPermission/';
const contentTypeEndpoint = '/documentContentType';

@Injectable()
export class DocumentService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any
  ) { }

  private get apiDocumentUrl(): string {
    return this.appConfig.appUrls.titanGatewayUrl + documentApi;
  }

  getRoot(projectId: number): Observable<DocumentListInfoModel[]> {
    return this.http.get<DocumentListInfoModel[]>(this.apiDocumentUrl + rootDocumentListEndpoint + projectId);
  }

  getRootDocumentPermission(projectId: number): Observable<RootDocumentPermissionModel> {
    return this.http.get<RootDocumentPermissionModel>(this.apiDocumentUrl + rootDocumentPermissionEndpoint + projectId);
  }

  getFolder(projectId: number, parentId: string): Observable<DocumentListInfoModel[]> {
    return this.http.get<DocumentListInfoModel[]>(
      this.apiDocumentUrl + folderDocumentListEndpoint + projectId + '?parentId=' + encodeURI(parentId));
  }

  getDocumentParentFolderList(documentId: string): Observable<DocumentListInfoModel[]> {
    return this.http.get<DocumentListInfoModel[]>(
      this.apiDocumentUrl + documentParentFolderListEndpoint + getDocumentIdParameter(documentId));
  }

  getDocumentContentType(documentId: string): Observable<string> {
    return this.http.get<string>(
      this.apiDocumentUrl + contentTypeEndpoint + getDocumentIdParameter(documentId),
      { responseType: 'text' as 'json' });
  }
}
