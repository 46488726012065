import { Injectable } from '@angular/core';

import { createFeatureSelector, createSelector, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { adapterSharingList } from './subcontractors.adapters';
import { adapterSubcontractorsSharingList, SubcontractorsState } from '../../store/subcontractors';
import { BudgetModel } from '../../budget-approval/models';
import { BuildingProgressPeriod } from '../../building-progress/models/building-progress-period.model';
import { BuildingProgressSharing } from '../../building-progress/models/building-progress-sharing/building-progress-sharing.model';
import { Subcontractor } from '../../building-progress/modules/subcontractors/models';

const selectSubcontractorsFeature = createFeatureSelector<SubcontractorsState>('subcontractors');

const selectSubcontractors = createSelector(
  selectSubcontractorsFeature,
  state => state.subcontractors
);

const selectSelectedSubcontractor = createSelector(
  selectSubcontractorsFeature,
  state => state.selectedSubcontractor
);

const selectDialogItemsList = createSelector(
  selectSubcontractorsFeature,
  state => state.dialogItemsList
);

const selectDialogItemsListLoading = createSelector(
  selectSubcontractorsFeature,
  state => state.dialogItemsListLoading
);

const selectSharingListEntities = createSelector(
  selectSubcontractorsFeature,
  state => state.sharingList
);

const selectSharingList = createSelector(
  selectSharingListEntities,
  adapterSharingList.getSelectors().selectAll
);

const selectSubcontractorsSharingListEntities = createSelector(
  selectSubcontractorsFeature,
  state => state.subcontractorsSharingList
);

const selectSubcontractorsSharingList = createSelector(
  selectSubcontractorsSharingListEntities,
  adapterSubcontractorsSharingList.getSelectors().selectAll
);

const selectSubcontractorsPeriods = createSelector(
  selectSubcontractorsFeature,
  state => state.subcontractorsPeriods
);

@Injectable()
export class SubcontractorsSelectorsService {
  constructor(private store: Store<SubcontractorsState>) { }

  get subcontractors$(): Observable<Subcontractor[]> {
    return this.store.select(selectSubcontractors);
  }

  get selectedSubcontractor$(): Observable<Subcontractor> {
    return this.store.select(selectSelectedSubcontractor);
  }

  get dialogItemsList$(): Observable<BudgetModel> {
    return this.store.select(selectDialogItemsList);
  }

  get dialogItemsListLoading$(): Observable<boolean> {
    return this.store.select(selectDialogItemsListLoading);
  }

  get sharingList$(): Observable<BuildingProgressSharing[]> {
    return this.store.select(selectSharingList);
  }

  get subcontractorsSharingList$(): Observable<BuildingProgressSharing[]> {
    return this.store.select(selectSubcontractorsSharingList);
  }

  get subcontractorsPeriods$(): Observable<BuildingProgressPeriod[]> {
    return this.store.select(selectSubcontractorsPeriods);
  }
}
