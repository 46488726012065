export enum PartialBudgetItemChangedProperty {
  None = 0,
  Amount = 1,
  Percentage = 2
}

export interface PartialBudgetTableItem {
  id: number;
  code: string;
  description: string;
  measureUnit: string;
  amount: number;
  percentage: number;
  changedProperty: PartialBudgetItemChangedProperty;
}

export interface PartialBudgetItem {
  amount: number;
  previousAmount?: number;
  percentage: number;
  changedProperty: PartialBudgetItemChangedProperty;
}

export interface PartialBudgetItems {
  budgetItemId: number;
  counter: number;
  items: PartialBudgetItem[]
}

export interface PartialBudgetItemApiModel {
  amount: number
}

export interface PartialBudgetItemsApiModel {
  budgetItemId: number,
  partialBudgetItemId?: number,
  partialBudgetItems: PartialBudgetItemApiModel[];
}

export interface PartialBudgetItemMergeApiModel {
  budgetItemId: number,
}
