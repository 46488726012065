import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { DatePipe, TitleCasePipe } from '@angular/common';

import { NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { dateIsValid, daysDiff, getNgbDateStruct, sameDateParts } from '../../tools';
import { DatePickerLocalizationService, DatepickerParserFormatter } from './datepicker-parser-formatter.service';
import { DatepickerOptions } from './datepicker.interface';
import { DeviceDetectorService, GlobalEventsService } from '../../services';
import { InputCommand, InputCommandType } from '../inputs.common.interface';
import { KrosFormsService } from '../forms.service';
import { KrosInputBase } from '../inputs.common.base.component';
import { KrosModalRef } from '../../kros-modal/services/kros-modal-ref';
import { KrosModalService } from '../../kros-modal/services/kros-modal.service';

@Component({
  selector: 'kros-datepicker-input',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NgbDateParserFormatter,
      useClass: DatepickerParserFormatter,
    },
    {
      provide: NgbDatepickerI18n,
      useClass: DatePickerLocalizationService
    },
    DatepickerParserFormatter,
    DatePipe,
    TitleCasePipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => KrosDatepickerComponent),
      multi: true
    },
    {
      provide: KrosInputBase,
      useExisting: forwardRef(() => KrosDatepickerComponent)
    }
  ],
})
export class KrosDatepickerComponent extends KrosInputBase implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild('ngDatePicker') private readonly ngbDatePicker: NgbInputDatepicker;
  @ViewChild('desktopDatepicker', { static: false }) desktopDatepicker: TemplateRef<any>;
  @ViewChild('dateInput', { static: false }) dateInput: ElementRef;

  @Input() options: DatepickerOptions;

  currentOptions: DatepickerOptions;
  validationError = false; // true when date is not in valid format
  formControl: UntypedFormControl; // displayed input - formatted as local string
  formControlNgb = new UntypedFormControl(); // ngbDatepicker or native mobile datepicker
  private modal: KrosModalRef;
  private modalCloseSub: Subscription;
  private userChange: boolean;
  private ngbHovered: boolean;

  @HostListener('keydown', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if ((event.key === 'Tab' || event.key === 'Enter') && this.modal) {
      this.closeModal();
    }
  }

  constructor(
    public deviceDetector: DeviceDetectorService,
    private parserFormatter: DatepickerParserFormatter,
    private modalService: KrosModalService,
    protected cd: ChangeDetectorRef,
    protected globalEventsService: GlobalEventsService,
    protected injector: Injector,
    protected formsService: KrosFormsService,
  ) {
    super(cd, globalEventsService, injector, formsService, deviceDetector);
  }

  get width(): number {
    if (this.container?.nativeElement) {
      if (this.container.nativeElement.offsetWidth - 56 < 200) {
        return 200;
      }
      return this.container.nativeElement.offsetWidth - 56;
    }
    return 0;
  }

  get minDate(): NgbDateStruct {
    if (this.currentOptions.minDate) {
      return this.currentOptions.minDate instanceof Date
        ? getNgbDateStruct(this.currentOptions.minDate)
        : this.currentOptions.minDate;
    }
    return getNgbDateStruct(new Date('1900-01-01'));
  }

  get maxDate(): NgbDateStruct {
    if (this.currentOptions.maxDate) {
      return this.currentOptions.maxDate instanceof Date
        ? getNgbDateStruct(this.currentOptions.maxDate)
        : this.currentOptions.maxDate;
    }

    return getNgbDateStruct(new Date('9999-12-31'));
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (!this.currentOptions.markDisabled) {
      this.currentOptions.markDisabled = (date): boolean => false;
    }

    this.initFormControls();

    // listen manual user input (updates on blur - no debounce is needed)
    this.subs.sink = this.formControl.valueChanges.subscribe((newValue) => {
      this.userChange = true;
      if (this.parse(newValue, true) || (!newValue && newValue !== 0)) {
        this.validationError = false;
        this.setValue(this.parse(newValue));
      } else {
        this.validationError = true;
      }
    });

    // listen changes form outside
    this.subs.sink = this.mainControl.valueChanges.subscribe((newValue) => {
      if (this.formControl.disabled && this.mainControl.enabled) {
        this.formControl.enable({emitEvent: false});
      } else if (this.formControl.enabled && this.mainControl.disabled) {
        this.formControl.disable({emitEvent: false});
      }
      if (this.userChange) {
        this.userChange = false;
      } else {
        if (typeof newValue === 'string') {
          newValue = new Date(newValue);
        }
        this.setValue(newValue, false); // never emit event to avoid cycling
      }
    });
  }

  setTodayClick(): void {
    this.setValue(new Date());
  }

  clearDateClick(): void {
    this.setValue(null);
  }

  parse = (value, exact?: boolean): NgbDateStruct => {
    return this.parserFormatter.parse(value, exact);
  };

  setValue(value: NgbDateStruct | Date, emitEvent = true, fromNgb?: boolean): void {
    const origValue = this.mainControl.value;
    const dateValue = this.validateInput(value instanceof Date ? value : this.getJsDate(value), origValue);
    const ngbStruct = getNgbDateStruct(dateValue);
    const userFormat = this.parserFormatter.format(ngbStruct);
    const outputValue = this.getJsDate(ngbStruct);

    if (this.formControl.value !== userFormat || this.userChange) {
      if (!this.deviceDetector.isMobileOS()) {
        this.mainControl.setValue(outputValue, { emitEvent });
        this.formControl.setValue(userFormat, { emitEvent: false });
        this.formControlNgb.setValue(ngbStruct ?? getNgbDateStruct(new Date()), { emitEvent: false });
        this.userChange = false;
      } else {
        this.userChange = true;
        this.mainControl.setValue(outputValue, { emitEvent });
        this.formControl.setValue(userFormat, { emitEvent: false });
        this.formControlNgb.setValue(this.getDayPart(dateValue), { emitEvent: false });
        this.userChange = false;
      }
    }

    if (!sameDateParts(origValue, outputValue)) {
      this.mainControl.markAsDirty();
    }

    if (fromNgb) {
      // close modal if date is selected from ngb datepicker
      this.input.nativeElement.focus();
      this.closeModal();
    }
  }

  doFocus(event: FocusEvent): void {
    super.doFocus(event);
    this.showModal();
  }

  doBlur(event: FocusEvent): void {
    if (this.ngbHovered) {
      this.input.nativeElement.focus();
    } else {
      this.validationError = false;
      this.setValue(this.parse(this.formControl.value) || null);
      super.doBlur(event);
      this.mainControl.markAsTouched();
      this.closeModal();
    }
  }

  doClick(): void {
    this.showModal();
  }

  mouseEnterNgb(): void {
    this.ngbHovered = true;
  }

  mouseLeaveNgb(): void {
    this.ngbHovered = false;
  }

  private showModal(): void {
    if (!this.deviceDetector.isMobileOS() && !this.modal) {
      this.modal = this.modalService.openConnectedToElementCustomPositioned(
        this.desktopDatepicker,
        this.input.nativeElement,
        this.getPositions(),
        {},
        'no-backdrop',
        ['no-min-width', 'no-padding', 'datepicker', ...(this.currentOptions.highlightToday ? ['highlight-today'] : [])],
        { showMobileArrowBack: false, fullscreenOnMobile: false, allowFocusAutocapture: false, addModalToBrowsersHistory: false }
      );
      this.modalCloseSub = this.modal.afterClosed$.subscribe(() => {
        this.modal = null;
        this.closeModal();
        this.detectChanges();
      });
    }
  }

  private closeModal(): void {
    if (this.modal) {
      this.modal.cancel();
      this.modal = null;
    }
    this.ngbHovered = false;
    this.mainControl.markAsTouched();
    if (this.modalCloseSub) {
      this.modalCloseSub.unsubscribe();
    }
  }

  private getDayPart(date: Date): string {
    if (date) {
      return date.getFullYear() +
        '-' + (date.getMonth() + 1).toString().padStart(2, '0') +
        '-' + date.getDate().toString().padStart(2, '0');
    }
    return '';
  }

  protected handleCommand(command: InputCommand): void {
    if (command.type === InputCommandType.CLOSE_OVERLAY) {
      this.closeModal();
    } else {
      super.handleCommand(command);
    }
  }

  private getJsDate(date: NgbDateStruct): Date {
    if (!date || !date.year) {
      return null;
    }
    const ret = new Date();
    ret.setFullYear(date.year, date.month - 1, date.day);
    ret.setHours(0, 0, 0, 0);
    return ret;
  }

  private initFormControls(): void {
    this.formControl = new UntypedFormControl( // displayed input - formatted as local string
      {
        value: this.parserFormatter.format(
          getNgbDateStruct(this.mainControl.value)
        ),
        disabled: this.mainControl.disabled
      }
    );
    if (!this.deviceDetector.isMobileOS()) {
      this.formControlNgb.setValue(
        getNgbDateStruct(this.mainControl.value),
        { emitEvent: false }
      );
    } else {
      const dateValue = this.mainControl.value;
      this.formControlNgb.setValue(this.getDayPart(dateValue), { emitEvent: false });
      this.subs.sink = this.formControlNgb.valueChanges.subscribe((newDate) => {
        this.setValue(newDate ? new Date(newDate) : null);
      });
    }
  }

  private validateInput(value: Date, origValue: Date): Date {
    if (this.currentOptions.required && !dateIsValid(value)) {
      return new Date();
    }

    if (value) {
      if (this.minDate && daysDiff(value, this.getJsDate(this.minDate)) > 0) {
        if (this.currentOptions.required) {
          return this.getJsDate(this.minDate);
        }
        return null;
      }

      const maxDiff = daysDiff(this.getJsDate(this.maxDate), value);
      if (isNaN(maxDiff)) return origValue;
      if (maxDiff > 0) return this.getJsDate(this.maxDate);
    }

    return value;
  }

  private getPositions(): ConnectionPositionPair[] {
    if (this.currentOptions.positions && this.currentOptions.positions.length > 0) {
      return this.currentOptions.positions;
    }
    return [{
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top'
    }];
  }
}
