import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter, of, switchMap } from 'rxjs';

import { KrosModalService, UserShellBase } from '@kros-sk/components';
import { License, LicenseSelectorsService as LicenseCdkSelectorsService, LicenseDispatchersService, LicenseType } from '@kros-sk/ssw-cdk';

import { ApplicationModule, ApplicationModuleIdProviderService } from '../../../application-module';
import {
  LicenseInfoContentHelper,
  LicenseSelectorsService,
  LicenseUrlService,
  MarketingModalComponent
} from '../../../license';
import { TranslateService } from '../../../translate';
import { UserProfileService } from '../../user-profile.service';

@Component({
  selector: 'kros-user-shell',
  templateUrl: './user-shell.component.html',
  styleUrls: ['./user-shell.component.scss'],
  providers: [
    LicenseInfoContentHelper
  ]
})
export class UserShellComponent extends UserShellBase implements OnInit {

  private destroyRef = inject(DestroyRef);
  private licenseSelectorsService = inject(LicenseSelectorsService, { optional: true });
  private licenseDispatchersService = inject(LicenseDispatchersService, { optional: true });
  private licenseCdkSelectorsService = inject(LicenseCdkSelectorsService, { optional: true });
  private userProfileService = inject(UserProfileService);
  private appModuleIdProviderService = inject(ApplicationModuleIdProviderService);
  private licenseUrlService = inject(LicenseUrlService, { optional: true });
  private contentHelper = inject(LicenseInfoContentHelper, { optional: true });
  private krosModalService = inject(KrosModalService);
  private translateService = inject(TranslateService);

  license: License;
  showRenewalLicense = true;

  private licenseNumber: string;
  private isInBuildingProgressOrBudgetApproval: boolean;
  private isInQuantityManager: boolean;
  private isInBudget: boolean;
  private existValid: boolean;
  private isAutomaticRenewalLicense: boolean;
  private budgetLicense: License;
  private desktopLicense: License;

  get accountUrl(): string {
    return this.isCzechVersion
      ? this.appConfig.eShopUrl
      : `${this.appConfig.eShopUrl}/account/products/overview`;
  }

  get accountButtonText(): string {
    return this.isCzechVersion
      ? this.translateService.translate('USER_PROFILE.CLIENT_PORTAL_URS')
      : this.translateService.translate('USER_PROFILE.KROS_ACCOUNT');
  }

  get changePasswordUrl(): string {
    return this.isCzechVersion
      ? `${this.appConfig.eShopUrl}/internal/profile/`
      : this.userSettingsUrl;
  }

  get isCzechVersion(): boolean {
    return this.appConfig.location === 'cz';
  }

  get isLicenseEnding(): boolean {
    return !!this.license &&
      (this.license.remainingDays <= 30 ||
        this.license.licenseType <= LicenseType.Trial ||
        this.license.licenseType === LicenseType.Readonly);
  }

  get isSchoolLicense(): boolean {
    return this.license
      ? this.license.isSchool
      : (this.desktopLicense
        ? this.desktopLicense.isSchool
        : false);
  }

  get licenseUrl(): string {
    if ((this.isInBuildingProgressOrBudgetApproval || this.isInQuantityManager || this.isInBudget)
      && this.license.licenseType <= LicenseType.Trial) {
      switch (true) {
        case this.isInBudget:
          return this.orderUrl;
        case this.isInQuantityManager:
          return this.orderUrl;
        case this.isInBuildingProgressOrBudgetApproval:
          return this.licenseUrlService?.getBuildingProgressUrl(
            this.existValid,
            this.budgetLicense,
            this.licenseNumber,
            this.desktopLicense);
      }
    }
    if (this.isInBuildingProgressOrBudgetApproval) {
      return this.isLicenseEnding ? this.renewUrl : this.expandUrl;
    }
    if (this.isInQuantityManager) {
      return this.orderUrl;
    }
    if (this.isInBudget) {
      return this.orderUrl;
    }
    if (this.isLicenseEnding) {
      return this.orderUrl;
    } else {
      return this.expandUrl;
    }
  }

  private get orderUrl(): string {
    if (this.isInQuantityManager || this.isInBudget) {
      return this.licenseUrlService?.orderUrl(this.existValid, this.license, this.licenseNumber);
    }
    const packageUrl = this.isInBudget ? '-3&dbSetType=1' : '-2&dbSetType=1&dbViewer=1&action=login';
    return (this.license.licenseType === LicenseType.Readonly || this.license.licenseType === LicenseType.Full)
      ? this.renewUrl
      : `${this.appConfig.eShopUrl}/cart?product=6&purchaseType=2&package=${packageUrl}`;
  }

  private get renewUrl(): string {
    return `${this.appConfig.eShopUrl}/cart?product=6&type=23&licenseNumber=${this.licenseNumber}&action=login`;
  }

  private get expandUrl(): string {
    return `${this.appConfig.eShopUrl}/cart?product=6&type=31&licenseNumber=${this.licenseNumber}&action=login`;
  }

  ngOnInit(): void {
    this.isInBuildingProgressOrBudgetApproval = [ApplicationModule.BuildingProgress, ApplicationModule.BudgetApproval]
      .includes(this.userProfileService.applicationModulId);
    this.isInQuantityManager = this.userProfileService.applicationModulId === ApplicationModule.QuantityManager;
    this.isInBudget = this.userProfileService.applicationModulId === ApplicationModule.Budget;

    this.appModuleIdProviderService.applicationModuleId$.pipe(
      switchMap(id => this.licenseSelectorsService?.getLicenseInfoByModule(id) ?? of(null)),
      filter(l => !!l?.license),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(l => {
      this.license = l.license;
      if (this.license.isSchool) {
        this.showRenewalLicense = false;
      }
    });

    this.licenseSelectorsService?.licenseDetail$.pipe(filter(Boolean), takeUntilDestroyed(this.destroyRef))
      .subscribe(licenseDetail => {
        this.isAutomaticRenewalLicense = licenseDetail.isAutomaticRenewal;
        this.licenseNumber = licenseDetail.licenseNumber;
        this.existValid = licenseDetail.existValid;
        if ((this.isInBudget || this.isInQuantityManager) && licenseDetail && this.isFullLicenseToExpire()) {
          this.showRenewalLicense = false;
        }
      });

    this.showRenevalLicenseInMisha();

    this.licenseCdkSelectorsService?.budgetLicense$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(budgetLicense => this.budgetLicense = budgetLicense);

    this.licenseCdkSelectorsService?.desktopLicense$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(desktopLicense => {
        if (!desktopLicense) {
          this.licenseDispatchersService?.loadDesktopLicense();
        }
        this.desktopLicense = desktopLicense;
      });
  }

  onClickEshopLink(): void {
    if (this.appConfig.location === 'sk' &&
      this.isInBuildingProgressOrBudgetApproval &&
      this.license?.licenseType <= LicenseType.Trial &&
      this.budgetLicense?.licenseType <= LicenseType.Trial &&
      !this.licenseNumber) {
      this.krosModalService.openCentered(
        MarketingModalComponent,
        {
          contentHelper: this.contentHelper,
        },
        { allowFocusAutocapture: false }
      );
    } else {
      window.open(this.licenseUrl, '_blank');
    }
  }

  private showRenevalLicenseInMisha(): void {
    if (this.isAutomaticRenewalLicense && this.isFullLicenseToExpire()) {
      this.showRenewalLicense = false;
    }
  }

  private isFullLicenseToExpire(): boolean {
    return this.license?.licenseType === LicenseType.Full && this.license?.remainingDays <= 30;
  }
}
