import { Component, OnInit, ViewChild } from '@angular/core';

import { filter } from 'rxjs/operators';

import {
  BudgetStructureService,
  Cell,
  DataTableComponent,
  DetailBaseComponent,
  isSummaryItem
} from '@kros-sk/ssw-shared-legacy';
import { KrosModalRef } from '@kros-sk/components';

import { environment } from '../../../../../environments/environment';
import { SelectItemsDialogTableService } from '../services/select-items-dialog-table.service';
import { SubcontractorsDispatchersService, SubcontractorsSelectorsService } from '../../../../store/subcontractors';

@Component({
  selector: 'app-subcontractors-select-items-dialog',
  templateUrl: './subcontractors-select-items-dialog.component.html',
  styleUrls: ['./subcontractors-select-items-dialog.component.scss'],
  providers: [SelectItemsDialogTableService]
})
export class SubcontractorsSelectItemsDialogComponent extends DetailBaseComponent implements OnInit {
  @ViewChild(DataTableComponent) set tableComponent(value: DataTableComponent) {
    this.table = value;

    if (this.table && this.scrollElementToViewParams) {
      this.scrollElementToView(
        this.scrollElementToViewParams.elementId,
        this.scrollElementToViewParams.elementIdForColumnWidth);
      this.scrollElementToViewParams = undefined;
    }
  }

  panelCollapsed = false;
  isTabletStructureShown = false;
  projectId: number;
  constractorName: string;
  dataLoaded: boolean;

  constructor(
    public tableService: SelectItemsDialogTableService,
    public selectorsService: SubcontractorsSelectorsService,
    private dispatchersService: SubcontractorsDispatchersService,
    private budgetStructureService: BudgetStructureService,
    private modalRef: KrosModalRef,
  ) {
    super(environment.location);
    this.projectId = this.modalRef.data.projectId;
    this.constractorName = this.modalRef.data.contractorName;
  }

  ngOnInit(): void {
    this.dispatchersService.loadDialogItemsList(this.projectId);

    this.subs.sink = this.selectorsService.dialogItemsList$.pipe(
      filter((dialogItems) => dialogItems !== undefined)
    ).subscribe((dialogItems) => {
      this.tableData = dialogItems;
      this.dataTableConfig = this.tableService.getTableConfig(this.tableData, false, true);
      this.onScrollFocusedCellToView(true);
      this.dataLoaded = true;
      this.tableService.clearMultiSelectItems();
    });
    this.subs.sink = this.budgetStructureService.setFocusToBudgetItemRequested$
      .subscribe(result => this.setFocusToItemById(result.id));
  }

  onItemCheckChanged(item: any, state: boolean): void {
    this.focusedCell = {
      ...this.focusedCell,
      rowIndex: this.tableData.items.findIndex(i => i.id === item.id)
    };
    this.tableService.multiSelectItem(item.id, state);
  }

  onHeaderCheckChanged(state: boolean): void {
    if (state) {
      this.tableService.multiSelectAllitems();
    } else {
      this.tableService.clearMultiSelectItems();
    }
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  onSubmit(): void {
    this.modalRef.submit({ selectedItemIds: Array.from(this.tableService.selectedItemIds.values()) });
  }

  onCellFocused(event: any): void {
    super.onCellFocused(event);

    this.setFocusToStructureItemById();
  }

  private setFocusToStructureItemById(): void {
    if (this.focusedCell && this.hasData) {
      const item = this.tableData.items[this.focusedCell.rowIndex];

      if (isSummaryItem(item)) {
        this.budgetStructureService.setFocusToStructureItemById(item.id);
      } else {
        this.budgetStructureService.setFocusToStructureItemById(item.parentId);
      }
    }
  }

  private setFocusToItemById(id: number): void {
    this.focusedCell = this.getFocusedCell(
      this.focusedCell?.colId ?? 'budget-budget-',
      this.tableData.items.findIndex(i => i.id === id)
    );

    this.focusCell();
  }

  private getFocusedCell(colId: string, rowIndex: number): Cell {
    return { colId, rowIndex };
  }
}
