import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import {
  Tag,
  TagsService,
  TagType,
  ToastService,
  ToastType,
  TranslateService
} from '@kros-sk/ssw-shared-legacy';
import { UserService } from '@kros-sk/ssw-cdk';

import * as listActions from './projects.actions';
import { BuildingProgressDispatchersService } from '../building-progress';
import { ProjectsDispatchersService } from './projects-dispatchers.service';
import { ProjectSharingService } from '../../projects/services/project-sharing.service';
import { ProjectsService } from '../../projects/services/projects.service';

@Injectable()
export class ProjectsEffects {

  private actions$ = inject(Actions);
  private buildingProgressDispatcherService = inject(BuildingProgressDispatchersService);
  private projectsDispatchersService = inject(ProjectsDispatchersService);
  private projectService = inject(ProjectsService);
  private projectSharingService = inject(ProjectSharingService);
  private userService = inject(UserService);
  private toastService = inject(ToastService);
  private translateService = inject(TranslateService);
  private tagsService = inject(TagsService);

  getProject$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.loadProjectDetailStart),
      switchMap(payload => {
        return this.projectService.getProjectDetail(payload.projectId).pipe(
          map(projectDetail => listActions.loadProjectDetailSuccess({ projectDetail })),
          catchError(error => {
            this.handleError(error);
            return of(listActions.loadProjectDetailError({ error }));
          })
        );
      })
    );
  });

  editProject$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.editProjectDetailStart),
      switchMap(payload => {
        return this.projectService.editProject(payload.projectDetail, payload.subcontractorId).pipe(
          map(projectDetail => listActions.loadProjectDetailSuccess({ projectDetail })),
          catchError(error => {
            this.handleError(error);
            return of(listActions.editProjectDetailError({ error }));
          })
        );
      })
    );
  });

  editOwner$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.editOwnerStart),
      switchMap(payload => {
        return this.projectSharingService.editOwner(payload.viewModel).pipe(
          map(() => {
            this.projectsDispatchersService.loadProjectDetail(payload.viewModel.projectId);
            this.projectsDispatchersService.loadProjectApplicationPermissions(payload.viewModel.projectId);
            this.buildingProgressDispatcherService.loadPermission(payload.viewModel.projectId);

            return listActions.editOwnerSuccess();
          }),
          catchError(error => of(listActions.editOwnerError({ error })))
        );
      })
    );
  });

  getApplicationPermissions$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.loadApplicationPermissionsStart),
      switchMap(payload => {
        return this.projectSharingService.getApplicationPermissions(payload.projectId).pipe(
          map(applicationPermissions => listActions.loadApplicationPermissionsSuccess({ applicationPermissions })),
          catchError(error => of(listActions.loadApplicationPermissionsError({ error })))
        );
      })
    );
  });

  shareApplications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.shareApplications),
      switchMap(payload => {
        return this.projectSharingService.shareApplications(payload.viewModel).pipe(
          map(() => this.projectsDispatchersService.loadProjectApplicationPermissions(payload.viewModel.projectId)),
        );
      })
    );
  }, { dispatch: false });

  getTagsList$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.loadTagsListStart),
      switchMap(() => {
        return this.projectService.getTagsList().pipe(
          map(tagsList => listActions.loadTagsListSuccess({ tagsList })),
          catchError(error => of(listActions.loadTagsListError({ error })))
        );
      })
    );
  });

  createNewTag$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.createNewTagStart),
      switchMap(payload => {
        return this.tagsService.createTag(payload.tag).pipe(
          map(tagId => {
            const tag = {
              ...payload.tag,
              id: tagId,
              type: TagType.User
            } as Tag;
            return listActions.createNewTagSuccess({ tag, setTagToProject: payload.setTagToProject });
          }),
          catchError(error => of(listActions.createNewTagError({ error })))
        );
      })
    );
  });

  editTag$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.editTagStart),
      switchMap(payload => {
        return this.tagsService.editTag(payload.tag).pipe(
          map(() => {
            const tag = {
              ...payload.tag,
              type: TagType.User
            } as Tag;
            return listActions.editTagSuccess({ tag });
          }),
          catchError(error => of(listActions.editTagError({ error })))
        );
      })
    );
  });

  private handleError(error: HttpErrorResponse): void {
    if (error.status === 403) {
      const message = this.translateService.translate('PROJECTS.ERROR.USER') +
        this.userService.getUserEmail() +
        this.translateService.translate('PROJECTS.ERROR.NO_PERMISSION');
      this.toastService.open(message, ToastType.Error);
    } else if (error.status === 404) {
      const message = this.translateService.translate('PROJECTS.ERROR.PROJECT_NOT_EXISTS');
      this.toastService.open(message, ToastType.Error);
    }
  }
}
