export const environment = {
  production: true,
  bimUserTokenName: 'kut',
  zipSettings: {
    enabled: true,
    minimumSize: 10, // VELKOST V MB
    extensionsToZip: ['ifc'],
    excludedExtensions: ['jpg', 'jpeg', 'png', 'gif', 'wav', 'avi', 'zip', 'rar', '7z', 'pdf', 'bmp', 'dwg', 'dxf']
  },
  defaultLanguage: 'cz',
  applicationDomain: '2',
  location: 'cz',
  currencySymbol: 'Kč',
  identityServer: {
    url: 'https://login.kros.wtf',
    clientUrl: 'https://area-cz.kros.wtf',
    clientId: 'TitanWebApp.New',
    scope: 'openid email Titan Connector Area Bim',
    applicationId: 'PLATFORMA',
    externalLoginRoutes: [
      'Callida'
    ]
  },
  gatewaysApiUrl: [
    'https://ssw-testlab-titangateway-api.azurewebsites.net',
    'https://ssw-testlab-bimgateway-api.azurewebsites.net',
    'https://localhost:5150'
  ],
  gatewayApiUrl: 'https://ssw-testlab-titangateway-api.azurewebsites.net',
  searchApiUrl: 'https://ssw-testlab-titangateway-api.azurewebsites.net/api',
  staticContentStorage: 'https://sswtestlabsa.blob.core.windows.net',
  openApiSwaggerUrl: 'https://ssw-testlab-openapigateway-api.azurewebsites.net/swagger',
  applicationInsightsKey: '05b0d55f-dd6e-4177-8847-8d182d813bf4',
  appUrls: {
    launcherUrl: 'http://localhost:4201',
    connectorServiceUrl: 'https://ssw-testlab-connector-api.azurewebsites.net',
    documentViewerUrl: 'http://localhost:4204',
    projectReportUrl: 'http://localhost:4205',
    titanGatewayUrl: 'https://ssw-testlab-titangateway-api.azurewebsites.net',
    quantityManagerAppUrl: 'http://localhost:4206',
    budgetAppUrl: 'http://localhost:4207',
    bimGatewayUrl: 'https://ssw-testlab-bimgateway-api.azurewebsites.net',
    bimServerUrl: 'https://ssw-testlab-bimserver-api.azurewebsites.net',
    bimWorksUrl: 'https://dev.kros.bim.works',
    mishaAppUrl: 'http://localhost:4209'
  },
  analyticContainerId: 'UA-144492276-1',
  news: {
    widgetKey: '3rwty0',
    isTest: true
  },
  eShopUrl: 'https://app-test.urs.cz',
  demoDocumentName: 'RD_1 základy.pdf'
};
