import { inject, Injectable } from '@angular/core';

import { StorageService } from '@kros-sk/ssw-cdk';

const DEVICE_SAMPLE_KEY = 'device-sample';

interface DeviceSamplerInfo {
  lastTimestamp: number;
}

@Injectable()
export class DeviceSamplerService {

  private sampleInterval = 10 * 60 * 1000;
  private storageService = inject(StorageService);
  private lastTimestamp = 0;

  constructor() {
    this.initSampler();
  }

  getSamplerInfo(): DeviceSamplerInfo {
    return {
      lastTimestamp: this.lastTimestamp
    };
  }

  initSampler(): void {
    const samplerInfoString = this.storageService.getItemFromLocalStorage(DEVICE_SAMPLE_KEY);
    if (samplerInfoString) {
      const samplerInfo = JSON.parse(samplerInfoString);
      this.lastTimestamp = samplerInfo.lastTimestamp;
    } else {
      this.lastTimestamp = new Date().getTime() - this.sampleInterval;
      this.storageService.setItemToLocalStorage(DEVICE_SAMPLE_KEY, JSON.stringify(this.getSamplerInfo()));
    }
  }

  resetLastTakenTimestamp(): void {
    this.lastTimestamp = new Date().getTime();
    this.storageService.setItemToLocalStorage(DEVICE_SAMPLE_KEY, JSON.stringify(this.getSamplerInfo()));
  }

  checkLastTakenTimestamp(): boolean {
    const currentTimestamp = new Date().getTime();
    const invalidationTimestamp = this.lastTimestamp;
    return ((currentTimestamp - (invalidationTimestamp + this.sampleInterval)) > 0);
  }
}
