import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { BuildingObject } from '@kros-sk/ssw-budget/shared/data-access/models';

export interface BuildingObjectsState extends EntityState<BuildingObject> {
  isSaving: boolean;
  selectAfterCreate: boolean;
  selected: BuildingObject;
  canLoadState: boolean;
  areBuildingObjectsFullyLoaded: boolean;
}

export const adapterBuildingObjects = createEntityAdapter<BuildingObject>();

export const initialBuildingObjectsState: BuildingObjectsState = adapterBuildingObjects.getInitialState({
  isSaving: false,
  selectAfterCreate: false,
  selected: null,
  canLoadState: false,
  areBuildingObjectsFullyLoaded: false
});
