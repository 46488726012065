import { AbstractControl, FormArray, FormGroup, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';

import { isObject } from '../object/object-tools';

/**
 * Gets a list of all errors that are on the given control and its sub-controls
 * @param control Control / Form on which errors are checked
 * @param parentControlName Name of the parent control
 * @param parentControlIndex Index of the row of the parentControl
 * @return ValidationErrors | null Validators returned or null if not found
 */
export function getAllFormErrors(
  control: AbstractControl,
  parentControlName?: string,
  parentControlIndex?: number
): ValidationErrors | null {
  if (control instanceof UntypedFormControl) {
    // Return FormControl errors or null
    return control.errors ?? null;
  }

  if (control instanceof UntypedFormGroup) {
    const groupErrors = control.errors;
    // Form group can contain errors itself, in that case add'em
    let formErrors = groupErrors ? { groupErrors } : {};
    Object.keys(control.controls).forEach(key => {
      if (control.controls[key] instanceof FormArray) {
        const formArray: FormArray = control.controls[key] as FormArray;
        formArray.controls.forEach((formArrayRow: FormGroup, formRowIndex: number) => {
          formErrors = {
            ...formErrors,
            ...getAllFormErrors(formArrayRow, key, formRowIndex)
          };
        });
      } else {
        // Recursive call of the FormGroup fields
        const error: ValidationErrors | null = getAllFormErrors(control.get(key));
        if (error !== null && error !== undefined) {
          // Only add error if not null
          if (parentControlName) {
            formErrors[`${parentControlName}-${key}-${parentControlIndex}`] = error;
          } else {
            formErrors[key] = error;
          }
        }
      }
    });
    // Return FormGroup errors or null
    return Object.keys(formErrors).length > 0 ? formErrors : null;
  }

  return null;
}

/**
 * Checks if the control/form has any not "optional" errors. Optional errors are considered to have "optional" in the list of errors.
 * This special error is added by the optionalValidator. If all errors are "optional", control/form is considered to be valid.
 * If there is any not "optional" error, control/form is invalid
 *
 * @param control Control/form on which errors are checked
 */
export function hasAnyFormControlNotOptionalError(control: AbstractControl): boolean {
  const allErrors = getAllFormErrors(control);

  if (!allErrors) {
    return false; // no errors
  }

  return hasAnyNotOptionalValidationErrors(allErrors);
}

/**
 * Checks if any of controls errors is not optional.
 * If there is any not optional error, form is invalid, otherwise it's considered as valid
 * @param errors list of all errors to check
 */
function hasAnyNotOptionalValidationErrors(errors: ValidationErrors): boolean {
  const keys = Object.keys(errors);
  let ret = false;

  for (const key of keys) {
    if (!hasOptionalFlag(errors[key])) {
      ret = true;
      break;
    }
  }

  return ret;
}

/**
 * Recursively goes through the entire error tree and checks that all leaves in the tree have an "optional" error.
 * If at least one tree letter is found that does not have it, the branch is considered invalid
 * @param validationError
 */
function hasOptionalFlag(validationError): boolean {
  if (!validationError) {
    return false;
  }
  const keys = Object.keys(validationError);
  let ret = false;

  for (const key of keys) {
    if (isObject(validationError[key])) {
      // nested controls: companyForm -> companyInfo -> address -> street, ...
      ret = hasOptionalFlag(validationError[key]);
      if (!ret) {
        break; // if found first control with not optional errors get out of the for
      }
    } else {
      // control's errors
      if (key.toLowerCase() === 'optional') {
        ret = true; // to get out of the for
        break;
      }
    }
  }

  return ret;
}
