<ngb-toast
  class="text-light"
  [delay]="0"
  [autohide]="false"
>

  <div class="header">
    <span class="uploaded-count">{{ zipFiles == true ?
      ('DOCUMENTS.PREPARATION' | translate) :
      (('DOCUMENTS.UPLOADED' | translate) + ' ' + finishedCount + ' / ' + (uploaderService.filesToUpload?.length +
      uploaderService.skippedFiles?.length))
      }}</span>
    <div class="header-buttons">
      <button
        class="minimize-button btn"
        type="button"
        (click)="minimized = !minimized"
      >
        <span krosMaterialIcon>{{ minimized ? 'expand_less' : 'expand_more' }}</span>
      </button>
      <button
        class="btn"
        type="button"
        [disabled]="!isUploadCompleted()"
        (click)="close()"
      >
        <span krosMaterialIcon>close</span>
      </button>
    </div>
  </div>

  <div
    class="file-list"
    [ngClass]="{'minimized': minimized }"
    data-test="upload-file-list"
  >
    <div
      class="file-item"
      *ngIf="zipFiles"
    >
      <i
        krosMaterialIcon
        class="uploading-icon"
      >hourglass_empty</i>
      <h3>
        <p>
          {{ 'DOCUMENTS.PREPARATION' | translate }}
        </p>
        <div class="progress">
          <div class="progress-bar indeterminate">
          </div>
        </div>
      </h3>
    </div>
    <ng-container *ngIf="!zipFiles">
      <div
        class="file-item"
        *ngFor="let item of uploaderService.skippedFiles"
      >
        <i
          krosMaterialIcon
          class="error-icon"
        >error</i>
        <h3
          data-toggle="tooltip"
          title="{{item?.fileName}}"
        >
          {{item?.fileName}}
        </h3>
        <p>
          {{ item?.reason }}
        </p>
      </div>
      <div
        class="file-item"
        *ngFor="let item of uploaderService.filesToUpload"
      >

        <i
          krosMaterialIcon
          class="done-icon"
        >
          {{ (progress[item?.id] === 100 ? 'cloud_done' : 'cloud_upload' ) }}
        </i>

        <h3
          data-toggle="tooltip"
          title="{{item?.originalFileName}}"
        >
          {{item?.originalFileName}}
        </h3>

        <p>
          {{ 'DOCUMENTS.UPLOAD_STATE' | translate }}
          <b>{{progress[item?.id]}}%</b>
        </p>

        <div class="progress">
          <div
            class="progress-bar"
            role="progressbar"
            [style.width.%]="progress[item?.id]"
            aria-valuenow="progress"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
    </ng-container>
  </div>
</ngb-toast>
