<div class="kros-modal-header">
  <h5 class="modal-title">
    <span *ngIf="!constractorName else addingItemsTitle">{{ 'SUBCONTRACTORS.SELECT_SUBCONTRACTOR_ITEMS' | translate
      }}</span>

    <ng-template #addingItemsTitle>{{ 'SUBCONTRACTORS.ADD_SUBCONTRACTOR_ITEMS' | translate }}
      {{constractorName}}</ng-template>
  </h5>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    data-test="modal-close-button"
    (click)="onCloseClick()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div
  class="kros-modal-body"
  data-test="subcontractors-select-items-modal-body"
>
  <app-panel-content
    [collapsed]="panelCollapsed"
    (panelCollapsed)="panelCollapsed = !panelCollapsed"
  >
    <ng-container ngProjectAs="panel">
      <app-budget-structure
        *ngIf="!dataLoaded || (dataLoaded && hasData)"
        class="building-tree"
        [data$]="selectorsService.dialogItemsList$"
      ></app-budget-structure>
    </ng-container>

    <ng-container ngProjectAs="content">
      <div class="content-container">
        <app-budget-structure
          *ngIf="isTabletStructureShown"
          class="building-tree"
          [data$]="selectorsService.dialogItemsList$"
          (nodeSelected)="isTabletStructureShown = false"
        ></app-budget-structure>

        <div
          class="toolbar-table-container"
          *ngIf="!(selectorsService.dialogItemsListLoading$ | async) else contentLoader"
        >
          <div class="toolbar-actions">
            <div class="actions left">
              <span class="selected-items">{{'COMMON.SELECTED_ITEMS' |
                translate}}({{tableService.selectedItemIds.size}}):</span>

              <button
                *ngIf="hasData"
                class="btn btn-primary toolbar-action ga-pv-subcontractor-select-items"
                type="button"
                data-test="subcontractor-select-items-button"
                [disabled]="tableService.selectedItemIds.size === 0"
                (click)="onSubmit()"
              >{{ 'COMMON.' + (constractorName ? 'ADD' : 'CONTINUE') | translate }}</button>
            </div>
          </div>

          <kros-data-table
            *ngIf="hasData && dataTableConfig && !isTabletStructureShown; else allItemsAlreadyAssigned"
            [itemHeight]="tableService.averageLineHeight"
            [data]="tableData"
            [itemCount]="itemCount"
            [config]="dataTableConfig"
            [focusedCell]="focusedCell"
            (cellFocused)="onCellFocused($event)"
            (itemCheckChanged)="onItemCheckChanged($event.item, $event.state)"
            (headerCheckChanged)="onHeaderCheckChanged($event)"
          ></kros-data-table>
        </div>
      </div>
    </ng-container>
  </app-panel-content>
</div>

<ng-template #contentLoader>
  <kros-timeline [type]="timelineType.Table"></kros-timeline>
</ng-template>

<ng-template #allItemsAlreadyAssigned>
  <p class="empty-content text-center">
    <span class="sub-text">{{ 'SUBCONTRACTORS.ALL_ITEMS_ASSIGNED' | translate }}</span>
  </p>
</ng-template>
