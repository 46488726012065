import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Maintenance } from '@kros-sk/models';

import { DeviceDetectorService, DeviceType } from '../../services';
import { stringFormat } from '../pipe-functions';

const MAINTENANCE_IS_PLANNED = 'MAINTENANCE.MAINTENANCE_IS_PLANNED';
const MAINTENANCE_IS_PLANNED_TWO_DATES = 'MAINTENANCE.MAINTENANCE_IS_PLANNED_TWO_DATES';

@Pipe({
  name: 'maintenanceDate'
})
export class MaintenanceDatePipe implements PipeTransform {

  private get isMobile(): boolean {
    return this.deviceDetectorService.deviceType === DeviceType.Mobile;
  }

  private get deviceSuffix(): string {
    return this.isMobile ? '_MOBILE' : '';
  }

  constructor(
    private datePipe: DatePipe,
    private deviceDetectorService: DeviceDetectorService,
    private translateService: TranslateService
  ) { }

  transform(maintenance: Maintenance): string {
    if (this.maintenanceHasTwoDates(maintenance)) {
      return stringFormat(
        this.translateService.instant(MAINTENANCE_IS_PLANNED_TWO_DATES + this.deviceSuffix),
        ...[
          this.datePipe.transform(maintenance.dateFrom, 'dd.MM.yyyy HH:mm'),
          this.datePipe.transform(maintenance.dateTo, 'dd.MM.yyyy HH:mm')
        ]
      );
    }

    return stringFormat(
      this.translateService.instant(MAINTENANCE_IS_PLANNED + this.deviceSuffix),
      ...[
        this.datePipe.transform(maintenance.dateFrom, 'dd.MM.yyyy'),
        this.datePipe.transform(maintenance.dateFrom, 'HH:mm'),
        this.datePipe.transform(maintenance.dateTo, 'HH:mm')
      ]
    );
  }

  private maintenanceHasTwoDates(maintenance: Maintenance): boolean {
    if (!maintenance || !maintenance.dateFrom || !maintenance.dateTo) {
      return false;
    }
    return maintenance.dateFrom.getDate() !== maintenance.dateTo.getDate()
      || maintenance.dateFrom.getMonth() !== maintenance.dateTo.getMonth()
      || maintenance.dateFrom.getFullYear() !== maintenance.dateTo.getFullYear();
  }
}
