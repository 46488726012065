<ng-container *ngIf="content else simpleItem">
  <ng-container *ngIf="isMobile else desktopContent">
    <button type="button"
            class="menu-dropdown-toggle"
            [class.main-active-child-link]="activeChild"
            (click)="toggleMobileSubMenu()">
      <ng-container *ngTemplateOutlet="contentButtonText"></ng-container>
      <span class="arrow-mobile">
        <i [@rotatedState]="mobileSubmenuState"
           class="material-icons">keyboard_arrow_down</i>
      </span>
    </button>
    <!-- hidden must be used to detect active child link (elements must be present in DOM) -->
    <div [hidden]="isCollapsed">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </ng-container>
</ng-container>
<ng-template #simpleItem>
  <ng-content></ng-content>
</ng-template>

<ng-template #desktopContent>
  <div>
    <button type="button"
            class="menu-dropdown-toggle"
            [class.main-active-child-link]="activeChild"
            (click)="openDesktopSubMenu()">
      <ng-container *ngTemplateOutlet="contentButtonText"></ng-container>
      <i class="material-icons arrow-down">keyboard_arrow_down</i>
    </button>
    <!-- this is very important workaround to detect activeChild property -->
    <div class="d-none">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
    <!-- end of important workaround -->
  </div>
</ng-template>

<ng-template #contentButtonText>
  <ng-content select="span"></ng-content>
</ng-template>
