import { Component, EventEmitter, inject, Input, Output } from '@angular/core';

import { getTagName } from '../tag-utils';
import { Tag } from '../tag.model';
import { TranslateService } from '../../translate';

@Component({
  selector: 'kros-tags-dropdown',
  templateUrl: './tags-dropdown.component.html',
  styleUrls: ['./tags-dropdown.component.scss']
})
export class TagsDropdownComponent {
  private readonly translate = inject(TranslateService);

  @Input() tags: Tag[] = [];
  @Input() hiddenTags: Tag[] = [];

  @Output() tagSelected = new EventEmitter();

  constructor() { }

  isTagVisible(tag: Tag): boolean {
    return !this.hiddenTags.some(t => t.id === tag.id);
  }

  selectTag(tag: Tag): void {
    this.tagSelected.emit(tag);
  }

  getTagName(key: string): string {
    return getTagName(key, this.translate);
  }
}
