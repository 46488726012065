import { ProjectDetail, Tag } from '@kros-sk/ssw-shared-legacy';

import { ApplicationPermission } from '../../projects/models';

export interface ProjectsState {
  projectDetail: ProjectDetail;
  applicationPermissions: ApplicationPermission[];
  isProjectMembersTabSelected: boolean;
  tagsList: Tag[];
  newTagCreated: Tag
}

export const initialProjectsState: ProjectsState = {
  projectDetail: null,
  applicationPermissions: [],
  isProjectMembersTabSelected: true,
  tagsList: [],
  newTagCreated: null
};
