<div class="kros-modal-header">
  <h4 class="kros-modal-title">{{'BUILDING_PROGRESS.EXPORT_INVOICE_DETAILS' | translate}}</h4>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onCloseClick()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div class="kros-modal-body">
  <form
    [formGroup]="exportForm"
    (ngSubmit)="onSubmit()"
  >
    <div
      class="export-periods-empty-info"
      *ngIf="arePeriodsEmpty else exportSettings"
    >
      {{'BUILDING_PROGRESS.EXPORT_PERIODS_EMPTY_INFO' | translate}}
    </div>

    <ng-template #exportSettings>
      <div class="form-container">
        <div class="form-group">
          <app-period-range-control
            [periods]="periods"
            [tooltipSinglePeriod]="'BUILDING_PROGRESS.EXPORT_SINGLE_PERIOD_TOOLTIP' | translate"
            [tooltipPeriodRange]="'BUILDING_PROGRESS.EXPORT_PERIOD_RANGE_TOOLTIP' | translate"
            [periodRangeTitle]="'BUILDING_PROGRESS.EXPORT_PERIOD_RANGE' | translate"
            [singlePeriodTitle]="'BUILDING_PROGRESS.EXPORT_SINGLE_PERIOD' | translate"
            formControlName="periodIds"
          ></app-period-range-control>
        </div>

        <div class="form-group range">
          <div class="form-group">
            <label class="caption">{{'BUILDING_PROGRESS.RANGE' | translate}}:</label>

            <div>
              <button
                class="titan-dropdown-toggle btn"
                type="button"
                (click)="openRangeModal()"
                data-test="export-range-button"
              >
                <span>{{rangeLabel}}</span>

                <i class="material-icons">
                  expand_more
                </i>
              </button>
            </div>
          </div>
        </div>

        <div
          class="export-group flex no-padding-bottom"
        >
          <div class="form-group checkbox no-margin elements-width">
            <input
              id="isIncludedConfirmationProtocol"
              type="checkbox"
              formControlName="isIncludedConfirmationProtocol"
              data-test="is-included-confirmation-protocol-checkbox"
            >

            <label
              class="no-selection"
              for="isIncludedConfirmationProtocol"
            >{{'BUILDING_PROGRESS.EXPORT_CONFIRMATION_PROTOCOL' |
              translate}}</label>
          </div>

          <div class="invoice elements-width property-padding">
            <label>{{'BUILDING_PROGRESS.INVOICE.INVOICE_NUMBER' | translate}}</label>

            <input
              class="form-control"
              formControlName="invoiceNumber"
              maxlength="20"
              data-test="invoice-number-input"
            >
          </div>
        </div>

        <div
          class="export-group"
        >
          <div class="flex">
            <div class="form-group checkbox no-margin elements-width">
              <input
                id="isIncludedBuildingObjectsSummary"
                type="checkbox"
                formControlName="isIncludedBuildingObjectsSummary"
                data-test="is-included-building-objects-summary-checkbox"
              >
              <label
                class="no-selection"
                for="isIncludedBuildingObjectsSummary"
              >{{'BUILDING_PROGRESS.EXPORT_SUMMARY' |
                translate}}</label>
            </div>

            <div
              class="options-text property-padding"
              container="body"
              (click)="openSummarySettingsModal()"
              data-test="open-summary-settings-button"
              >{{'COMMON.SETTINGS' | translate | lowercase}}
            </div>
          </div>
        </div>

        <div
          class="export-group flex"
        >
          <div class="form-group checkbox no-margin elements-width">
            <input
              id="isIncludedCoverSheet"
              type="checkbox"
              formControlName="isIncludedCoverSheet"
              data-test="is-included-cover-sheet-checkbox"
            >
            <label
              class="no-selection"
              for="isIncludedCoverSheet"
            >{{'BUILDING_PROGRESS.EXPORT_COVER_SHEET' | translate}}</label>
          </div>

          <div class="form-group sub-group select no-margin property-padding">
            <select
              id="coverSheetExportType"
              class="form-control"
              formControlName="coverSheetExportType"
              data-test="cover-sheet-export-type-select"
            >
              <option [value]="coverSheetExportTypeEnum.Summary">
                {{'BUILDING_PROGRESS.EXPORT_COVER_SHEET_SUMMARY' | translate}}</option>
              <option [value]="coverSheetExportTypeEnum.SummaryAndBuildingObjects">
                {{'BUILDING_PROGRESS.EXPORT_COVER_SHEET_SUMMARY_AND_OBJECT' | translate}}</option>
            </select>
          </div>
        </div>

        <div
          class="export-group"
        >
          <div class="flex">
            <div class="form-group checkbox elements-width">
              <input
                id="isIncludedCompletedWorks"
                type="checkbox"
                formControlName="isIncludedCompletedWorks"
                data-test="is-included-completed-works-checkbox"
              >

              <label
                class="no-selection"
                for="isIncludedCompletedWorks"
              >{{'BUILDING_PROGRESS.EXPORT_LIST_OF_DONE_WORKS' |
                translate}}</label>
            </div>

            <div
              class="options-text property-padding"
              container="body"
              (click)="openCompletedWorksSettingsModal()"
              data-test="open-completed-works-settings-button"
              >{{'COMMON.SETTINGS' | translate| lowercase}}
            </div>
          </div>
        </div>

        <div class="dropdown-separator">
        </div>

        <div
          class="export-group no-padding-bottom"
        >
          <div class="flex-space-between">
            <div
              class="form-group checkbox elements-width"
              [tooltipDisabled]="canExportLogo"
              [krosTooltip]="'BUILDING_PROGRESS.EXPORT_LOGO_NOT_EXIST' | translate"
            >
              <input
                id="isLogoIncluded"
                type="checkbox"
                formControlName="isLogoIncluded"
                data-test="is-logo-included-checkbox"
              >

              <label
                class="no-selection"
                for="isLogoIncluded"
              >{{'BUILDING_PROGRESS.EXPORT_USE_LOGO' | translate}}</label>
            </div>

            <label
              *ngIf="!isCzechVersion && !isContractor"
              data-test="vat-transferring-setting"
            >{{'SETTINGS.VAT.TRANSFERRED_VAT' | translate}}: {{'COMMON.' +
              (settings?.isTransferredVat ?
              'ENABLED' : 'DISABLED') | translate}}
            </label>
          </div>
        </div>

        <div
          class="export-group"
        >
          <div class="flex">
            <div
              class="form-group checkbox elements-width"
              [tooltipDisabled]="canExportStamp"
              [krosTooltip]="'BUILDING_PROGRESS.EXPORT_STAMP_NOT_EXIST' | translate"
            >
              <input
                id="isStampIncluded"
                type="checkbox"
                formControlName="isStampIncluded"
                data-test="is-stamp-included-checkbox"
              >

              <label
                class="no-selection"
                for="isStampIncluded"
              >{{'BUILDING_PROGRESS.EXPORT_USE_STAMP' | translate}}</label>
            </div>
          </div>
        </div>

        <div
          *ngIf="isCzechVersion || isContractor"
          class="note"
        >
          <p
            [innerHTML]="('BUILDING_PROGRESS.EXPORT_LOGO_STAMP_INFO' | translate | formatString: '<i class=\'material-icons\'>settings</i>') | safeHtml">
          </p>
        </div>

        <div
          *ngIf="!isCzechVersion && !isContractor"
          class="note"
        >
          <p
            [innerHTML]="('BUILDING_PROGRESS.EXPORT_LOGO_STAMP_AND_TRANSFERRING_VAT_SETTING_INFO' | translate
              | formatString: '<i class=\'material-icons\'>settings</i>') | safeHtml">
          </p>
        </div>

        <div class="dropdown-separator">
        </div>

        <div class="flex-space-between">
          <div class="export-date">
            <kros-datepicker-input
              data-test="export-datepicker"
              [options]="{
                formControl: exportForm.get('exportDate'),
                name: 'exportDate',
                label: ('BUILDING_PROGRESS.EXPORT_DATE' | translate),
                icon: 'date_range',
                iconPosition: 'right',
                noClearButton: true,
                noTodayButton: true,
                positions: [{
                  originX: 'start',
                  originY: 'top',
                  overlayX: 'start',
                  overlayY: 'bottom'
                }],
                errors: {
                  required: 'BUILDING_PROGRESS.ERROR.FILL_DATE' | translate
                }
              }"
            ></kros-datepicker-input>
          </div>

          <div
            class="options-text align-flex-end"
            container="body"
            (click)="onHeaderDataEdit()"
            data-test="edit-header-data"
            >{{'PROJECTS.DETAIL.HEADER_DATA_SETTINGS' | translate}}
          </div>
        </div>
      </div>
    </ng-template>
  </form>
</div>

<div class="kros-modal-footer">
  <ng-container *ngIf="!arePeriodsEmpty else singleExportButton">
    <button
      class="btn btn-primary btn-submit btn-pdf-export"
      data-test="export-pdf-button"
      type="button"
      [disabled]="!exportForm.valid"
      (click)="onSubmit(exportFileType.Pdf)"
    >{{'BUILDING_PROGRESS.EXPORT_ACTION' | translate}} PDF</button>

    <button
      class="btn btn-primary btn-submit"
      data-test="export-xlsx-button"
      type="button"
      [disabled]="!exportForm.valid"
      (click)="onSubmit(exportFileType.Xlsx)"
    >{{'BUILDING_PROGRESS.EXPORT_ACTION' | translate}} Excel</button>
  </ng-container>

  <ng-template #singleExportButton>
    <button
      class="btn btn-primary btn-submit"
      id="ga-export"
      data-test="invoice-export-button"
      type="button"
      [disabled]="!exportForm.valid"
      (click)="onSubmit(exportFileType.Xlsx)"
    >{{'BUILDING_PROGRESS.EXPORT_ACTION' | translate}}</button>
  </ng-template>
</div>
