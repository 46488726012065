import { Pipe, PipeTransform } from '@angular/core';

import { formatNumberValue } from '../core';

@Pipe({
  name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {

  transform(value: number, decimalPlaces: number, appLocation: string): unknown {
    return formatNumberValue(value, decimalPlaces, appLocation);
  }
}
