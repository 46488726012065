import { Action, ActionCreator, createReducer, on, ReducerTypes } from '@ngrx/store';

import * as listEditActions from './documents-edit.actions';
import {
  adapterDeletedDocuments,
  adapterDeletedDocumentsVersion,
  adapterDocumentList,
  adapterDocumentVersionList,
  adapterMarkedDocuments,
  documentsExplorerReducers,
  DocumentsState,
  initialDocumentsState
} from '../../document-explorer';

export const documentsEditReducers: ReducerTypes<DocumentsState, ActionCreator[]>[] = [
  on(listEditActions.renameFolderSuccess, (state, payload) => {
    const document = Object.values(state.documentList.entities).find(d => d.id === payload.documentId);

    return {
      ...state,
      documentList: document ? adapterDocumentList.updateOne(
        { id: document.id, changes: { originalName: payload.documentName, name: payload.documentName } },
        state.documentList
      ) : state.documentList
    };
  }),
  on(listEditActions.softDeleteDocumentSuccess, (state, payload) => {
    const documents = Object.values(state.documentList.entities);
    const deletedDocument = documents.find(d => d.id === payload.documentId);
    const docIndex = documents.indexOf(deletedDocument);

    return {
      ...state,
      documentList: adapterDocumentList.removeOne(payload.documentId, state.documentList),
      deletedDocuments: adapterDeletedDocuments.setMany([{ docIndex, document: deletedDocument }], state.deletedDocuments),
      markedDocuments: adapterMarkedDocuments.removeAll(state.markedDocuments)
    };
  }),
  on(listEditActions.softDeleteDocumentsSuccess, (state, payload) => {
    const documents = Object.values(state.documentList.entities);
    const deletedDocuments = [];

    payload.documents.forEach(document => {
      const deletedDocument = documents.find(d => d.id === document.id);
      const docIndex = documents.indexOf(deletedDocument);

      deletedDocuments.push({ docIndex, document });
    });

    return {
      ...state,
      documentList: adapterDocumentList.removeMany(payload.documents.map(d => d.id), state.documentList),
      deletedDocuments: adapterDeletedDocuments.setMany(deletedDocuments, state.deletedDocuments),
      markedDocuments: adapterMarkedDocuments.removeAll(state.markedDocuments)
    };
  }),
  on(listEditActions.deleteDocumentSuccess, (state, payload) => {
    return {
      ...state,
      deletedDocuments: adapterDeletedDocuments.removeOne(payload.documentId, state.deletedDocuments)
    };
  }),
  on(listEditActions.undoDeleteDocumentVersion, (state, payload) => {
    return {
      ...state,
      deletedDocumentsVersion: adapterDeletedDocumentsVersion.removeOne(payload.documentVersionId, state.deletedDocumentsVersion)
    };
  }),
  on(listEditActions.softDeleteDocumentVersionSuccess, (state, payload) => {
    const documentVersionList = Object.values(state.documentVersionList.entities);
    const deletedDocument = documentVersionList.find(d => d.id === payload.documentVersionId);
    const docIndex = documentVersionList.indexOf(deletedDocument);

    return {
      ...state,
      documentVersionList: adapterDocumentVersionList.removeOne(payload.documentVersionId, state.documentVersionList),
      deletedDocumentsVersion: adapterDeletedDocumentsVersion
        .setOne({ docIndex, document: deletedDocument }, state.deletedDocumentsVersion)
    };
  }),
  on(listEditActions.deleteDocumentVersionSuccess, (state, payload) => {
    return {
      ...state,
      documentList: adapterDocumentList.updateOne(
        {
          id: payload.documentId,
          changes: {
            createdBy: payload.documentVersionDelete.currentVersionCreatedBy,
            currentVersionId: payload.documentVersionDelete.currentVersionId,
            currentVersionOrder: payload.documentVersionDelete.currentVersionOrder,
            description: payload.documentVersionDelete.currentVersionDescription,
            versionsCount: payload.documentVersionDelete.versionsCount,
            size: payload.documentVersionDelete.currentVersionSize,
            totalSize: payload.documentVersionDelete.documentTotalSize
          }
        },
        state.documentList),
      deletedDocumentsVersion: adapterDeletedDocumentsVersion.removeOne(
        payload.documentVersionId,
        state.deletedDocumentsVersion),
      markedDocuments: adapterMarkedDocuments.removeAll(state.markedDocuments)
    };
  }),
  on(listEditActions.deleteDocumentsSuccess, (state, payload) => {
    return {
      ...state,
      deletedDocuments: adapterDeletedDocuments.removeMany(payload.documentIds, state.deletedDocuments)
    };
  }),
  on(listEditActions.undoDeleteDocuments, (state, payload) => {
    const documents = Object.values(state.documentList.entities);
    const deletedDocuments = Object.values(state.deletedDocuments.entities).filter(d => payload.documentIds.includes(d.document.id));

    deletedDocuments.forEach(doc => documents.splice(doc.docIndex, 0, doc.document));

    return {
      ...state,
      documentList: adapterDocumentList.setAll(documents, state.documentList),
      deletedDocuments: adapterDeletedDocuments.removeMany(deletedDocuments.map(d => d.document.id), state.deletedDocuments)
    };
  }),
];

export function documentsEditReducer(state: DocumentsState | undefined, action: Action): any {
  const reducer = createReducer(initialDocumentsState, ...documentsEditReducers, ...documentsExplorerReducers);
  return reducer(state, action);
}
