import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';

import { CompanyDetails, CreateCompanyModel } from './models';

const userServiceApi = '/api/userService';
const companyApi = '/Company';
const companyListEndpoint = '/list';
const companyCreateEndpoint = '/create';
const companyDeleteEndpoint = '/delete';

@Injectable()
export class CompanyService {

  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private http: HttpClient,
    @Inject('gatewayUrlSelector') private gatewayUrlSelector: (appConfig: any) => string
  ) { }

  getCompanies(): Observable<CompanyDetails[]> {
    return this.http.get<CompanyDetails[]>(this.getCompanyApi() + companyListEndpoint);
  }

  createCompany(data: CreateCompanyModel): Observable<string> {
    return this.http.post<string>(this.getCompanyApi() + companyCreateEndpoint, data);
  }

  deleteCompany(id: number): Observable<any> {
    return this.http.delete(this.getCompanyApi() + companyDeleteEndpoint + '/' + id);
  }

  private getUserServiceApi(): string {
    return this.gatewayUrlSelector(this.appConfig) + userServiceApi;
  }

  private getCompanyApi(): string {
    return this.getUserServiceApi() + companyApi;
  }
}
