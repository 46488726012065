import { Injectable } from '@angular/core';

import { delay, finalize } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable()
export class AdBlockCheckerService {

  private wasChecked = false;
  private blockerIsActive = false;

  isAdBlockEnabled(): Observable<boolean> {
    if (this.wasChecked) {
      return of(this.blockerIsActive);
    } else {
      return this.checkForActive();
    }
  }

  private checkForActive(): Observable<boolean> {
    const testAd = document.createElement('div');
    testAd.innerHTML = '&nbsp;';
    testAd.className = 'adsbox';
    document.body.appendChild(testAd);

    return of(testAd.offsetHeight === 0)
      .pipe(
        delay(100),
        finalize(() => {
          this.blockerIsActive = testAd.offsetHeight === 0;
          this.wasChecked = true;
          testAd.remove();
        })
      );
  }

}
