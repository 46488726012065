import { Component, OnInit } from '@angular/core';

import { map, of, switchMap } from 'rxjs';

import { ApplicationType } from '@kros-sk/ssw-cdk';

import { BuildingProgressActionAccessService } from '../../../building-progress/services/building-progress-action-access.service';
import { BuildingProgressPanelEnum } from '../../../building-progress/models/building-progress-panel.enum';
import { BuildingProgressPanelHelper, LicenseHelper, PermissionHelper } from '../../../building-progress/helpers';
import { BuildingProgressSelectorsService } from '../../../store/building-progress';
import { EditingPanelHelper } from '../../../building-progress/helpers/editing-panel.helper';
import { Note } from '../../../building-progress/models/note.model';

@Component({
  selector: 'app-budget-item-notes',
  templateUrl: './item-notes.component.html',
  styleUrls: ['./item-notes.component.scss']
})
export class ItemNotesComponent implements OnInit {

  note$ = this.panelHelper.reloadNotesPanelData$.pipe(
    switchMap((id) => id === -1 ? this.getEmptyNote$ : this.getNote$(id))
  );

  private getEmptyNote$ = of<Note>(null).pipe(map(() => this.getNote(null, -1)));

  constructor(
    private buildingProgressSelectorsService: BuildingProgressSelectorsService,
    private permissionHelper: PermissionHelper,
    private licenseHelper: LicenseHelper,
    private editingPanelHelper: EditingPanelHelper,
    private actionAccessService: BuildingProgressActionAccessService,
    public panelHelper: BuildingProgressPanelHelper
  ) { }

  ngOnInit(): void {
    this.editingPanelHelper.initPanelType(BuildingProgressPanelEnum.Notes);
    this.panelHelper.reloadNotesData();
  }

  areNotesReadOnly(): boolean {
    return this.permissionHelper.areNotesReadOnly || this.licenseHelper.isFreeOrReadonlyLicense;
  }

  onEditingChanged(editing: boolean): void {
    this.editingPanelHelper.isEditing = editing;
  }

  onNoteClicked(): void {
    if (!this.permissionHelper.areNotesReadOnly) {
      this.actionAccessService.checkForAccessCauseOfReadonlyOrFreeLicense();
    }
  }

  private getNote$ = (id: number): any => this.buildingProgressSelectorsService.getBuildingProgressNote$(id).pipe(
    map((note) => this.getNote(note, id)
    ));

  private getNote(note: any, id: number): Note {
    return id === -1
      ? null
      : note ?? {
        applicationType: ApplicationType.BuildingProgress,
        budgetItemId: id,
        note: null,
        projectId: this.panelHelper.projectId
      };
  }
}
