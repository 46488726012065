<div class="kros-modal-body info-dialog">
  <h4
    *ngIf="data.header"
    class="header"
  >{{ data.header }}</h4>

  <p
    *ngIf="data.description"
    class="description default-font-family font-weight-bold"
  >{{ data.description }}</p>

  <p
    *ngIf="data.secondaryDescription"
    class="secondary-description default-font-family"
  >{{ data.secondaryDescription }}</p>

  <button
    *ngIf="data.closeButtonText"
    class="btn btn-primary btn-submit"
    type="button"
    data-test="close-dialog-button"
    (click)="onClose()"
  >{{ data.closeButtonText }}</button>
</div>
