import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';

import { MarketingContentHelper } from '@kros-sk/ssw-shared-legacy';
import { provideBootstrapEffects } from '@kros-sk/ssw-cdk';

import {
  ConstructionDiaryDispatchersService,
  ConstructionDiaryEffects,
  constructionDiaryFeatureState,
  ConstructionDiarySelectorsService
} from './data-access';
import {
  ConstructionDiaryHelper,
  ConstructionDiaryLicenseHelper,
  ConstructionDiaryMarketingContentHelper,
  ExternalApplicationService
} from './utils';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(constructionDiaryFeatureState)
  ],
  providers: [
    ConstructionDiaryDispatchersService,
    ConstructionDiarySelectorsService,
    ConstructionDiaryLicenseHelper,
    ConstructionDiaryHelper,
    ExternalApplicationService,
    provideBootstrapEffects([ConstructionDiaryEffects]),
    {
      provide: MarketingContentHelper,
      useClass: ConstructionDiaryMarketingContentHelper
    }
  ]
})
export class ConstructionDiaryModule { }
