import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { KrosAuthService } from '../services/auth/kros-auth.service';

@Component({
  selector: 'kros-login-redirect',
  standalone: true,
  imports: [CommonModule],
  template: '',
  styles: '',
})
export class LoginRedirectComponent implements OnInit {
  protected authService = inject(KrosAuthService);
  protected router = inject(Router);

  ngOnInit(): void {
    // Ševo: https://authts.github.io/oidc-client-ts/classes/UserManager.html#signinRedirectCallback
    // It is recommended to use UserManager.signinCallback instead of UserManager.signinRedirectCallback.
    this.authService.manager.signinCallback()
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        const redirectUrl = localStorage.getItem('redirectUrl') ?? '/';
        this.router.navigateByUrl(redirectUrl, { replaceUrl: true }).then(() => {
          localStorage.removeItem('redirectUrl');
        });
      });
  }
}
