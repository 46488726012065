<div
  class="comment-container"
  [id]="'comment-' + comment.id"
  [ngClass]="{
        'active': active,
        'parent-active': parentAnswering,
        'answer': newAnswerComment || answerComment,
        'answer-last': isLastAnswer,
        'has-answers': comment.answers?.length > 0
    }"
  (click)="onCommentClick(comment)"
  (focusout)="onCommentFocusOut()"
>
  <div class="left">
    <kros-titan-avatar
      class="avatar-xs"
      [email]="comment.email"
    ></kros-titan-avatar>
  </div>

  <div class="right">
    <div class="comment-header">
      <div>
        <div
          class="email"
          data-test="user-email"
        >
          {{comment.email}}
        </div>
      </div>

      <div class="icons">
        <span
          *ngIf="!comment.datePublished && !newComment && !newAnswerComment && !editing && !readOnly"
          krosMaterialIcon
          id="ga-comments-delete"
          class="delete-button-icon"
          data-test="comment-delete-button"
          krosTooltip="{{ 'COMMENTS.DELETE' | translate }}"
          position="before"
          (click)="onDeleteClick(comment)"
        >delete</span>
      </div>
    </div>

    <div class="comment-content">
      <div
        data-test="comment-text"
        *ngIf="!newComment && !newAnswerComment && !editing"
        (click)="onCommentTextClick()"
      >{{comment.comment}}</div>

      <div
        *ngIf="newComment || newAnswerComment || editing"
        class="form-group"
      >
        <textarea
          krosAutofocus
          class="form-control"
          data-test="comment-textarea"
          placeholder="{{ 'COMMENTS.PLACEHOLDER' | translate }}"
          [(ngModel)]="comment.comment"
        ></textarea>
      </div>
    </div>

    <div
      *ngIf="isAnswerButtonVisible() || comment.datePublished"
      class="comment-date-and-actions"
    >
      <div class="date">
        <span *ngIf="comment.datePublished">{{comment.datePublished | date:'dd.MM.yyyy, HH:mm'}}</span>
      </div>

      <button
        *ngIf="isAnswerButtonVisible()"
        class="btn btn-link btn-new"
        data-test="comment-answer-button"
        type="button"
        (click)="onAnswerClick()"
      >{{'COMMENTS.ANSWER' | translate}}</button>
    </div>

    <div
      class="edit-buttons"
      *ngIf="newComment || newAnswerComment || editing"
    >
      <button
        id="ga-comments-cancel"
        class="btn btn-link btn-cancel"
        data-test="comment-cancel-button"
        type="button"
        [disabled]="loading"
        (click)="onCancelClick()"
      >{{'COMMENTS.CANCEL' | translate}}</button>

      <button
        id="ga-comments-ok"
        class="btn btn-outline-primary btn-new"
        data-test="comment-ok-button"
        type="button"
        [disabled]="comment.comment?.length === 0 || loading"
        (mousedown)="onSaveClick(comment)"
      >{{'COMMENTS.OK' | translate}}</button>
    </div>

    <div class="answers-container">
      <div class="answers">
        <kros-comment
          *ngFor="let answer of comment.answers; let last = last;"
          [comment]="answer"
          [loading]="loading"
          [focusedIds]="focusedIds"
          [readOnly]="readOnly"
          [showAnswerButton]="showAnswerButton"
          [answerComment]="true"
          [parentAnswering]="answering"
          [answerShowAnswerButton]="canAnswer() && last"
          [isLastAnswer]="last && !answering"
          [createAnswer]="createAnswer"
          (deleteComment)="onDeleteClick(answer)"
          (saveComment)="onSaveClick($event)"
          (saveAnswerComment)="onSaveAnswer($event)"
          (clickComment)="onCommentClick($event)"
          (clickAnswer)="onAnswerClick()"
        ></kros-comment>
      </div>
    </div>

    <div class="answer-container">
      <kros-comment
        *ngIf="answering"
        [comment]="answerCommentData"
        [loading]="loading"
        [newAnswerComment]="true"
        [isLastAnswer]="true"
        (cancelComment)="onCancelAnswer()"
        (saveComment)="onSaveAnswer($event)"
      ></kros-comment>
    </div>
  </div>
</div>
