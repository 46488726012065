import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { CompaniesRegisterSearchModule } from '@kros-sk/components';

import { CompanySearchComponent } from './company-search.component';

@NgModule({
  imports: [
    CommonModule,
    CompaniesRegisterSearchModule,
    ReactiveFormsModule
  ],
  declarations: [
    CompanySearchComponent
  ],
  exports: [
    CompanySearchComponent
  ]
})
export class CompanySearchModule { }
