import { Injectable } from '@angular/core';

import { BoqCell } from './models';

@Injectable()
export class BoqTableKeyboardHandlingService {

  handleTableMovement(focusedCell: BoqCell, itemCount: number, columnCount: number, key: string, shiftKey): BoqCell {
    switch (key) {
      case 'arrowup':
        if (focusedCell.rowIndex > 0) {
          focusedCell.rowIndex--;
        }
        break;
      case 'arrowdown':
      case 'enter':
        if (focusedCell.rowIndex < itemCount - 1) {
          focusedCell.rowIndex++;
        }
        break;
      case 'arrowleft':
        if (focusedCell.colId > 0) {
          focusedCell.colId--;
        }
        break;
      case 'arrowright':
        if (focusedCell.colId < columnCount - 1) {
          focusedCell.colId++;
        }
        break;
      case 'tab':
        if (!shiftKey && focusedCell.colId < columnCount - 1) {
          focusedCell.colId++;
        } else if (shiftKey && focusedCell.colId > 0) {
          focusedCell.colId--;
        }
        break;
    }
    return focusedCell;
  }
}
