<div class="kros-modal-header">
  <p
    class="h4">{{'SETTINGS.VAT.VAT_UPDATE.TITLE' | translate}}</p>
</div>

<div class="kros-modal-body">
  <p
    class="h5"
    [innerHTML]="'SETTINGS.VAT.VAT_UPDATE.MESSAGE' | translate"
  ></p>
</div>

<div class="kros-modal-footer">
  <button
    class="btn btn-primary"
    (click)="onSubmit()"
    >{{ 'COMMON.UNDERSTAND' | translate}}</button>
</div>
