<app-panel-content
  [collapsed]="panelCollapsed"
  [headerText]="'PROJECTS.DETAIL.SUBCONTRACTORS' | translate"
  (panelCollapsed)="onPanelCollapsed($event)"
>
  <ng-container ngProjectAs="panel">
    <app-subcontractors-panel-container [tableId]="tableId"></app-subcontractors-panel-container>
  </ng-container>

  <ng-container ngProjectAs="content">
    <app-subcontractors-detail [tableId]="tableId"></app-subcontractors-detail>
  </ng-container>
</app-panel-content>
