import { Injectable } from '@angular/core';

import { StorageService } from '@kros-sk/ssw-cdk';

import { BudgetApprovalColumn } from '../models/budget-approval-column.enum';
import { ColumnGroup } from '../../shared/models/column-group.model';

const filterVisibilityStorageItem = 'budgetApprovalFilterVisibility';
const columnGroupsVisibilityStorageItem = 'budgetApprovalColumnGroupsVisibility';
const defaultColumnGroups: ColumnGroup[] = [
  {
    id: 'totalPriceChange',
    title: 'BUDGET_APPROVAL.COLUMN_FILTER.TOTAL_PRICE_CHANGE',
    columnIds: [
      BudgetApprovalColumn.ApprovedTotalPrice,
      BudgetApprovalColumn.NotApprovedTotalPrice
    ],
    checked: true
  },
  {
    id: 'totalPriceChangePercent',
    title: 'BUDGET_APPROVAL.COLUMN_FILTER.TOTAL_PRICE_CHANGE_PERCENT',
    columnIds: [
      BudgetApprovalColumn.ApprovedPercentage,
      BudgetApprovalColumn.NotApprovedPercentage
    ],
    checked: true
  },
  {
    id: 'positiveNegativeChanges',
    title: 'BUDGET_APPROVAL.COLUMN_FILTER.POSITIVE_NEGATIVE_CHANGES',
    columnIds: [
      BudgetApprovalColumn.ApprovedNegativeTotalPrice,
      BudgetApprovalColumn.ApprovedPositiveTotalPrice,
      BudgetApprovalColumn.NotApprovedNegativeTotalPrice,
      BudgetApprovalColumn.NotApprovedPositiveTotalPrice
    ],
    checked: false
  },
  {
    id: 'absoluteChange',
    title: 'BUDGET_APPROVAL.COLUMN_FILTER.ABSOLUTE_CHANGE',
    columnIds: [
      BudgetApprovalColumn.ApprovedAbsoluteChangeTotalPrice,
      BudgetApprovalColumn.NotApprovedAbsoluteChangeTotalPrice
    ],
    checked: false
  },
  {
    id: 'absoluteChangePercent',
    title: 'BUDGET_APPROVAL.COLUMN_FILTER.ABSOLUTE_CHANGE_PERCENT',
    columnIds: [
      BudgetApprovalColumn.ApprovedAbsoluteChangePercentage,
      BudgetApprovalColumn.NotApprovedAbsoluteChangePercentage
    ],
    checked: false
  }
];

@Injectable()
export class BudgetApprovalFilterService {

  constructor(
    private storageService: StorageService
  ) {
  }

  saveFilterVisibility(visible: boolean): void {
    this.storageService.setItemToLocalStorage(filterVisibilityStorageItem, visible.toString());
  }

  getFilterVisibility(): boolean {
    return JSON.parse(this.storageService.getItemFromLocalStorage(filterVisibilityStorageItem));
  }

  getDefaultColumnGroups(): ColumnGroup[] {
    return [...defaultColumnGroups];
  }

  getColumnGroups(): ColumnGroup[] {
    const visibility = JSON.parse(this.storageService.getItemFromLocalStorage(columnGroupsVisibilityStorageItem));
    if (visibility) {
      return defaultColumnGroups.map(group => ({ ...group, checked: !!visibility[group.id] }));
    } else {
      const ret = this.getDefaultColumnGroups();
      this.saveColumnGroups(ret);
      return ret;
    }
  }

  saveColumnGroups(columnGrops: ColumnGroup[]): void {
    const visibility: any = {};
    columnGrops.forEach(group => visibility[group.id] = group.checked);
    this.storageService.setItemToLocalStorage(columnGroupsVisibilityStorageItem, JSON.stringify(visibility));
  }

  resetFilter(): void {
    this.saveColumnGroups(defaultColumnGroups);
  }

  areOnlyDefaultGroupsChecked(columnGroups: ColumnGroup[]): boolean {
    return !columnGroups.some(group =>
      group.checked &&
      defaultColumnGroups.some(defaultGroup => defaultGroup.id === group.id && !defaultGroup.checked)
    );
  }
}
