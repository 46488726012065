export class DividerPosition {
  dividerType: DividerType;
  index: number;
  caretPosition: number;
  value: string;
}

export enum DividerType {
  Start = 0,
  Middle = 1,
  End = 2
}
