export const PAGE_NOT_FOUND_QUERY_KEY = 'type';

export enum PageNotFoundParam {
  pageNotFound = 'pageNotFound',
  companyDeleted = 'companyDeleted',
  companyNotFound = 'companyNotFound',
  licenseUserCountExceeded = 'licenseUserCountExceeded',
  licenseCompaniesCountExceeded = 'licenseCompaniesCountExceeded',
  licenseNotLoaded = 'licenseNotLoaded',
  noPermission = 'noPermission',
  userDeletedFromCompany = 'userDeletedFromCompany',
}

export interface PageNotFoundVisualData {
  imgSrc: string;
  heading?: string;
  textLines: string [];
  actionLogout?: boolean;
}

export type PageNotFoundVisualDataSource = Record<keyof typeof PageNotFoundParam, PageNotFoundVisualData>;
