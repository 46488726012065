<div class="tree">
  <cdk-tree
    [dataSource]="dataSource"
    [treeControl]="treeControl"
  >
    <cdk-tree-node
      *cdkTreeNodeDef="let node"
      class="tree-node"
      [style.display]="shouldRender(node) ? 'flex' : 'none'"
    >
      <div
        class="tree-row level-{{getNodeLevel(node)}}"
        (click)="selectNode(node)"
        [ngClass]="{'selected': isSelected(node)}"
        [attr.data-test]="'tree-row-level-' + getNodeLevel(node)"
      >
        <span
          krosMaterialIcon
          cdkTreeNodeToggle
          class="expander"
          *ngIf="node.isExpandable else emptyColumn"
          (click)="node.isExpanded = !node.isExpanded"
        >{{treeControl.isExpanded(node) ? 'arrow_drop_down' : 'arrow_right'}}</span>

        <ng-template #emptyColumn>
          <div></div>
        </ng-template>

        <span
          krosMaterialIcon
          class="folder"
          [attr.aria-label]="'toggle ' + node.data.name"
        >folder</span>

        <span
          class="node-text"
          data-test="folder-tree-name"
        >{{ node.data.name }}</span>
      </div>
    </cdk-tree-node>
  </cdk-tree>
</div>
