import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter } from 'rxjs';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

import { BuildingProgressActionAccessService } from '../../../building-progress/services/building-progress-action-access.service';
import { BuildingProgressSelectorsService } from '../../../store/building-progress';
import { DetailHelper, LicenseHelper, PeriodHelper, PermissionHelper } from '../../../building-progress/helpers';

@Component({
  selector: 'app-building-progress-detail-mobile-toolbar',
  templateUrl: './building-progress-detail-mobile-toolbar.component.html',
  styleUrls: ['./building-progress-detail-mobile-toolbar.component.scss']
})
export class BuildingProgressDetailMobileToolbarComponent implements OnInit {

  buildingProgressSelectorsService = inject(BuildingProgressSelectorsService);
  detail = inject(DetailHelper);
  licenseHelper = inject(LicenseHelper);
  periodHelper = inject(PeriodHelper);
  permissionHelper = inject(PermissionHelper);
  selectedPeriodId: number;

  @Input() searchVisible: boolean;
  @Output() percentageDrawClick = new EventEmitter<void>();
  @Output() searchClicked = new EventEmitter<void>();

  get areItemsSelected(): boolean {
    return this.detail.selectedItemIds.size !== 0;
  }

  get canShowSelection(): boolean {
    return !this.buildingProgressActionAccessService.isLicenseFree &&
      (this.permissionHelper.isContributor || this.permissionHelper.isOwner || this.permissionHelper.isContractor) &&
      this.detail.constructionData?.periods.length > 0;
  }

  private appInsightsService = inject(AppInsightsBaseService);
  private buildingProgressActionAccessService = inject(BuildingProgressActionAccessService);
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.buildingProgressSelectorsService.periodId$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(id => this.selectedPeriodId = id);
  }

  percentageDraw(): void {
    if (this.buildingProgressActionAccessService.checkForAccessToEdit() &&
      this.buildingProgressActionAccessService.checkForFreeOwner() &&
      this.detail.checkLastPeriodStateIsUnset()) {
      this.detail.mobile.showNoLastPeriodSelectedMessage(this.selectedPeriodId, this.detail.constructionData.periods)
        .pipe(filter(result => !!result), takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.detail.onPercentageDraw(true));
    }
  }

  percentageComplete(): void {
    if (this.buildingProgressActionAccessService.checkForAccessToEdit() &&
      this.detail.checkLastPeriodStateIsUnset()) {
      this.detail.mobile.showNoLastPeriodSelectedMessage(this.selectedPeriodId, this.detail.constructionData.periods)
        .pipe(filter(result => !!result), takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.detail.onPercentageComplete(true));
    }
  }

  onSearchClicked(): void {
    this.searchClicked.emit();
  }

  selectionClicked(): void {
    this.detail.isMobileSelectionVisible = true;
    this.appInsightsService.trackEvent('PV-mobile-selection-clicked');
  }
}
