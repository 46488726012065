import { Injectable, OnDestroy } from '@angular/core';

import Step from 'shepherd.js/src/types/step';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { offset } from '@floating-ui/dom';
import { ShepherdService } from 'angular-shepherd';
import { SubSink } from 'subsink';

import { AuthSelectorsService } from '@kros-sk/auth';
import { KrosAppInsightsService } from '@kros-sk/core';

import { DeviceDetectorService, DeviceType } from '../services';

@Injectable()
/**
 * Service supporting shepherdService API to show tour across app for the user. This service should be application wide singleton.
 */
export class KrosTourService implements OnDestroy {

  get currentStepId(): string {
    return this.shepherdService.tourObject?.getCurrentStep()?.id ?? '';
  }

  /**
   * Emits true when tour is initialized. Emits false when tour is finished.
   */
  get isTourActive$(): Observable<boolean> {
    return this.isTourActive.asObservable();
  }

  private userId: number;
  private subs = new SubSink();
  private isTourActive = new BehaviorSubject<boolean>(false);

  /**
   * Create instance.
   * @param shepherdService 3-party service creating modal windows.
   * @param appInsightsService Used to track cancel/complete to AI
   * @param deviceDetectorService To force hide on mobile devices.
   * @param authSelector To get userId used to track in AI.
   */
  constructor(
    private shepherdService: ShepherdService,
    private appInsightsService: KrosAppInsightsService,
    private deviceDetectorService: DeviceDetectorService,
    private authSelector: AuthSelectorsService
  ) {
    this.subs.sink = this.authSelector.currentUser$.subscribe(user => this.userId = user?.userId);
    this.subs.sink = this.deviceDetectorService.deviceType$.pipe(
      filter(_ => this.shepherdService.isActive)
    ).subscribe((deviceType: DeviceType): void => {
      if (deviceType === DeviceType.Mobile) {
        this.shepherdService.hide();
      } else {
        this.show(this.currentStepId);
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Create tour object and prepares to show steps.
   * Calling this method makes isTourActive$ to emit true to indicate some tour is initialized.
   * @param tourName Name which is used in storage and AI to track tour.
   * @param steps Steps with step options to show to user.
   * @param defaultStepOptions Defaults that will apply to all steps of tour.
   * @param modal If tour should display as modal window.
   */
  initTour(tourName: string, steps: Step.StepOptions[], defaultStepOptions?: Step.StepOptions, modal: boolean = false): void {
    this.isTourActive.next(true);
    this.shepherdService.modal = modal;
    this.shepherdService.confirmCancel = false;
    this.shepherdService.defaultStepOptions = {
      ...defaultStepOptions,
      classes: 'esw',
      floatingUIOptions: {
        middleware: [offset(12)]
      },
      scrollTo: false,
      cancelIcon: {
        enabled: true
      },
      when: {
        show(): void {
          const currentStepElement: HTMLElement = this.getElement();
          const footer: Element = currentStepElement.querySelector('.shepherd-footer');
          const progress: HTMLSpanElement = document.createElement('span');
          progress.className = 'shepherd-progress caption';
          progress.innerText = `${this.getTour().steps.indexOf(this.getTour().getCurrentStep()) + 1}/${this.getTour().steps.length}`;
          footer.insertBefore(progress, currentStepElement.querySelector('.shepherd-button:first-child'));
        }
      }
    };
    this.shepherdService.onTourFinish = (p: string): void => this.tourFinish(p);
    this.shepherdService.tourName = tourName;
    this.shepherdService.addSteps(steps);
  }

  /**
   * Check localStorage if tour was finished before.
   * @param tourName String to be seached in localStorage.
   */
  isTourFinished(tourName: string): boolean {
    return !!localStorage.getItem(this.getFinishedTourStorageKey(tourName));
  }

  /**
   * Next step.
   */
  next(): void {
    this.shepherdService.next();
  }

  /**
   * Previous step.
   */
  back(): void {
    this.shepherdService.back();
  }

  /**
   * Cancel tour. This will cause tour to finish.
   */
  cancel(): void {
    this.shepherdService.cancel();
  }

  /**
   * Hide tour.
   */
  hide(): void {
    this.shepherdService.hide();
  }

  /**
   * Complete tour. This will cause tour to finish.
   */
  complete(): void {
    this.shepherdService.complete();
  }

  /**
   * Show tour.
   * @param stepId When truthy, start tour from step. If stepId is last step id, tour won't show.
   */
  show(stepId?: string): void {
    if (this.deviceDetectorService.deviceType === DeviceType.Mobile) return;
    if (stepId) {
      if (this.isLastStep(stepId)) return;
      this.shepherdService.show(stepId);
    } else {
      this.shepherdService.start();
    }
  }

  /**
   * When tour finishes isTourActive$ emits false to indicate tour was finished and options can be replaced with new tour options.
   * Save to localStorage that tour was finished. Track event to AI that tour was finished and canceled or completed.
   * @param completeOrCancel If the tour was canceled or completed.
   * @private
   */
  private tourFinish(completeOrCancel: string): void {
    this.appInsightsService.trackEvent('TOUR-' + completeOrCancel, { name: this.shepherdService.tourName, step: this.currentStepId });
    localStorage.setItem(this.getFinishedTourStorageKey(this.shepherdService.tourName), 'true');
    this.isTourActive.next(false);
  }

  private getFinishedTourStorageKey(tourName: string): string {
    return `${tourName}-${this.userId}`;
  }

  private isLastStep(stepId: string): boolean {
    const lastOne: number = this.shepherdService.tourObject.steps.length - 1;
    if (lastOne > 0) {
      return this.shepherdService.tourObject.steps[lastOne].id === stepId;
    }
    return true;
  }
}
