import { createActionGroup, props } from '@ngrx/store';

import { BudgetItem, ChangedBudgetItems } from '@kros-sk/ssw-shared-legacy';

import { budgetSharedKey } from './budget-shared-actions';

export const otherBudgetSharedActions = createActionGroup({
  source: budgetSharedKey,
  events: {
    'Create other budget section start': props<{
      otherBudgetProjectId: number,
      itemBefore: BudgetItem,
      sectionId: number,
      canResetAmount: boolean
    }>(),
    'Create other budget section success': props<{ changedBudgetItems: ChangedBudgetItems, lastSelectedItemId: string }>(),
    'Create other budget section error': props<{ error: any }>(),

    'Create other budget building object start': props<{
      otherBudgetProjectId: number,
      itemBefore: BudgetItem,
      buildingObjectId: number,
      canResetAmount: boolean
    }>(),
    'Create other budget building object success': props<{ changedBudgetItems: ChangedBudgetItems, lastSelectedItemId: string }>(),
    'Create other budget building object error': props<{ error: any }>(),

    'Create other budget items start': props<{
      otherBudgetProjectId: number,
      itemBefore: BudgetItem,
      items: BudgetItem[],
      canResetAmount: boolean
    }>(),
    'Create other budget items success': props<{ changedBudgetItems: ChangedBudgetItems, lastSelectedItemId: string }>(),
    'Create other budget items error': props<{ error: any }>(),
  }
});

export const otherBudgetResetActions = [
  otherBudgetSharedActions.createOtherBudgetItemsSuccess,
  otherBudgetSharedActions.createOtherBudgetSectionSuccess,
  otherBudgetSharedActions.createOtherBudgetBuildingObjectSuccess,
];
