import { OpenApiSubscriptionApiModel } from './open-api.model';

export function emptyOpenApiSubscriptionApiModel(): OpenApiSubscriptionApiModel {
  return {
    clientId: '',
    name: '',
    webHook: '',
    webHookSecret: '',
    secretHint: '',
    scope: ''
  };
}
