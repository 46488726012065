import { Pipe, PipeTransform } from '@angular/core';

import { License, LicenseType } from '@kros-sk/ssw-cdk';

import { TranslateService } from '../translate';

@Pipe({
  name: 'licenseEshopRedirect',
  pure: false
})
export class LicenseEshopRedirectPipe implements PipeTransform {

  constructor(
    private translateService: TranslateService
  ) { }

  transform(license: License): string {
    if (license.licenseType === LicenseType.Free || license.licenseType === LicenseType.Trial) {
      return this.translateService.translate('LICENSE_INFO.ORDER');
    } else if (license.licenseType !== LicenseType.Full) {
      return this.translateService.translate('LICENSE_INFO.RENEW');
    } else if (license.remainingDays <= 30) {
      return this.translateService.translate('LICENSE_INFO.PROLONG');
    } else {
      return this.translateService.translate('LICENSE_INFO.EXTEND');
    }
  }
}
