<ng-template #modal>
  <div class="kros-modal-header">
    <h4 class="kros-modal-title">{{ 'BUDGET.UPLOADING_TO_BUILDING_PROGRESS' | translate }}</h4>

    <button
      class="btn btn-link close"
      type="button"
      tabindex="-1"
      (click)="onClose()"
    >
      <i class="material-icons">close</i>
    </button>
  </div>

  <div class="kros-modal-body">
    <budget-building-objects-upload-toolbar
      (uploadBuildingObjects)="onUploadBuildingObjects()"
    ></budget-building-objects-upload-toolbar>

    <budget-building-objects-upload-table
      [project]="project"
      [launchedFrom]="launchedFrom"
    ></budget-building-objects-upload-table>
  </div>

  <div class="kros-modal-footer">
    <button
      class="btn btn-outline-secondary"
      type="button"
      data-test="budget-building-object-upload-cancel"
      (click)="onClose()"
    >{{'COMMON.CLOSE' | translate}}</button>
  </div>
</ng-template>
