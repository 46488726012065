import { InjectionToken } from '@angular/core';

import { Observable } from 'rxjs';

export class FeatureFlags {
  [key: string]: boolean;
}

export interface IFeatureFlagsService {
  loadFeatureFlags(): Observable<FeatureFlags>;
}

export const FEATURE_FLAGS_SERVICE = new InjectionToken<IFeatureFlagsService>('Feature flags service');
