import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import * as explorerActions from './documents-explorer.actions';
import { DocumentListInfoModel, FolderTreeNode } from '../models';
import { DocumentsState } from './documents-explorer.state';
import { ItemSharing } from '../../models';

@Injectable()
export class DocumentsExplorerDispatchersService {
  constructor(
    private store: Store<DocumentsState>
  ) { }

  loadDocumentsList(projectId: number): void {
    this.store.dispatch(
      explorerActions.loadDocumentsListStart({ projectId })
    );
  }

  loadRootDocumentPermission(projectId: number): void {
    this.store.dispatch(
      explorerActions.loadRootDocumentPermissionStart({ projectId })
    );
  }

  loadFolderList(projectId: number, folderId: string): void {
    this.store.dispatch(
      explorerActions.loadFolderListStart({ projectId, folderId })
    );
  }

  loadEmptyList(): void {
    this.store.dispatch(
      explorerActions.loadEmptyList()
    );
  }

  markDocument(documentId: string): void {
    this.store.dispatch(
      explorerActions.markDocument({ documentId })
    );
  }

  markDocuments(documents: string[]): void {
    this.store.dispatch(
      explorerActions.markDocuments({ documents })
    );
  }

  clearDocuments(documents: string[]): void {
    this.store.dispatch(
      explorerActions.clearMarkedDocuments({ documents })
    );
  }

  clearAllDocuments(): void {
    this.store.dispatch(
      explorerActions.clearAllMarkedDocuments()
    );
  }

  updateSharings(isShared: boolean, sharings: ItemSharing[], documents: DocumentListInfoModel[]): void {
    this.store.dispatch(
      explorerActions.updateSharings({ isShared, sharings, documents })
    );
  }

  setCurrentFolder(currentFolder: FolderTreeNode): void {
    this.store.dispatch(explorerActions.setCurrentFolder({ currentFolder }));
  }

  reloadCurrentFolder(projectId?: number): void {
    this.store.dispatch(explorerActions.reloadCurrentFolder({ projectId }));
  }

  setProjectId(projectId: number): void {
    this.store.dispatch(explorerActions.setProjectId({ projectId }));
  }

  loadFolder(folder: FolderTreeNode, projectId?: number): void {
    this.store.dispatch(explorerActions.loadFolder({ folder, projectId }));
  }

  clearCurrentFolder(): void {
    this.store.dispatch(explorerActions.clearCurrentFolder());
  }

  loadRoot(projectId?: number): void {
    this.store.dispatch(explorerActions.loadRoot({ projectId }));
  }
}
