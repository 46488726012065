<app-panel-content
  [collapsed]="panelCollapsed"
  [headerText]="'CHANGE_SHEET.STRUCTURE' | translate"
  (panelCollapsed)="onPanelCollapsed($event)"
>
  <ng-container
    class="panel-top"
    ngProjectAs="panelTop"
  >
    <div
      class="panel-top"
      [ngClass]="{'collapsed': panelCollapsed}"
    >
      <button
        class="btn btn-outline-primary"
        type="button"
        (click)="onBackClick()"
        data-test="budget-approval-button-back-to-list"
      >
        <i class="material-icons">chevron_left</i>
        {{ panelCollapsed ? '' : ('CHANGE_SHEET.BACK_TO_LIST' | translate) }}
      </button>
    </div>
  </ng-container>

  <ng-container ngProjectAs="panel">
    <app-change-sheet-panel-container [tableId]="tableId"></app-change-sheet-panel-container>
  </ng-container>

  <ng-container ngProjectAs="content">
    <app-change-sheet-container [tableId]="tableId"></app-change-sheet-container>
  </ng-container>
</app-panel-content>

<ng-container *ngIf="actionInProgress$ | async as action">
  <kros-spin-loader
    [title]="('BUDGET_APPROVAL.APPROVE.' +
            (action === actionInProgress.approve ? 'APPROVE_IN_PROGRESS_TITLE' : 'CANCEL_APPROVAL_IN_PROGRESS_TITLE' )
            ) | translate"
    [description]="('BUDGET_APPROVAL.APPROVE.' +
            (action === actionInProgress.approve ? 'APPROVE_IN_PROGRESS_DESCRIPTION' : 'CANCEL_APPROVAL_IN_PROGRESS_DESCRIPTION' )
            ) | translate"
  ></kros-spin-loader>
</ng-container>
