import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';

import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { BoqEditComponent } from './boq-edit/boq-edit.component';
import { BoqEditHelper, BoqEditService, BoqEditVisualHelper } from './boq-edit';
import { BoqPanelHeaderComponent } from './boq-panel-header/boq-panel-header.component';
import { BoqTableKeyboardHandlingService } from './boq-table-keyboard-handling.service';
import { BudgetBoqComponent } from './budget-boq/budget-boq.component';
import { DirectivesModule } from '../directives';
import { TimelineModule } from '../timeline/timeline.module';
import { TranslateModule } from '../translate';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    CdkTableModule,
    ReactiveFormsModule,
    TimelineModule,
    DirectivesModule,
    DxButtonModule,
    DxToolbarModule,
    TooltipDirective
  ],
  declarations: [
    BoqPanelHeaderComponent,
    BudgetBoqComponent,
    BoqEditComponent
  ],
  exports: [
    BoqPanelHeaderComponent,
    BudgetBoqComponent,
    BoqEditComponent
  ],
  providers: [
    BoqTableKeyboardHandlingService,
    BoqEditService,
    BoqEditHelper,
    BoqEditVisualHelper
  ]
})
export class BudgetBoqModule { }
