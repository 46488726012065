import {
  BudgetItem,
  codeFieldName,
  CostType,
  emptyKey,
  isConstruction,
  isMaterial,
  isSummaryItem,
  itemTypeFieldName
} from '@kros-sk/ssw-shared-legacy';

export const unitSupplyIndexedFieldName = 'unitSupplyIndexed';
export const totalSupplyFieldName = 'totalSupply';
export const unitAssemblyIndexedFieldName = 'unitAssemblyIndexed';
export const totalAssemblyFieldName = 'totalAssembly';
export const descriptionFieldName = 'description';
export const uneditableDataFields = ['costType', 'totalRubble', 'totalStandardHours', 'totalWeight'];
export const uneditableBuildingObjectDataFields = ['selection', 'itemType'];

export const onEditingStart = (e: any, isAddingNewItem: boolean, canChangeItemType: boolean): void => {
  const data: BudgetItem = e.data;

  if (((isSummaryItem(data) && e.key !== emptyKey) &&
    e.column.dataField !== codeFieldName &&
    e.column.dataField !== descriptionFieldName &&
    (canChangeItemType ? e.column.dataField !== itemTypeFieldName : true)) ||
    (e.column.dataField === itemTypeFieldName && e.key !== emptyKey && !canChangeItemType) ||
    ([unitAssemblyIndexedFieldName, totalAssemblyFieldName].includes(e.column.dataField) &&
      (isMaterial(data) || data.costType === CostType.MNosny)) ||
    (isAddingNewItem && e.key !== emptyKey) ||
    isConstruction(data)) {
    e.cancel = true;
  }
};

export const onFocusedCellChanging = (e: any, canCancelFocusedCellChanging: boolean): void => {
  if (canCancelFocusedCellChanging && e.newRowIndex !== e.prevRowIndex) {
    e.cancel = true;
    return;
  }

  e.isHighlighted = true;
};
