import { CdkTableModule } from '@angular/cdk/table';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';

import {
  ChartModule,
  DecimalPlaces,
  IBudgetItem,
  isConstructionOrBuildingObject,
  isSummaryItem,
  PipesModule,
  TimelineModule,
  TimelineType
} from '@kros-sk/ssw-shared-legacy';
import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { BuildingProgressItem } from '../../../models/construction-data.model';
import { DashboardDatasource } from './dashboard-datasource';
import { environment } from '../../../../../environments/environment';
import {
  setColorIfAdditionOrChangeSheet,
  setColorIfAdditionOrChangeSheetAllItems,
  setColorIfMaterial
} from '../../../helpers';
import { SummaryDashboardTableModel } from '../../../models/summary-dashboard.model';

const columns = ['code', 'description', 'completedPercentage', 'totalPrice', 'partlyCompletedTotalPrice',
  'completedTotalPrice', 'restTotalPrice', 'additionalTotalPrice', 'notCompletedTotalPrice'];

@Component({
  standalone: true,
  selector: 'app-building-progress-dashboard-table',
  templateUrl: './building-progress-dashboard-table.component.html',
  styleUrls: ['./building-progress-dashboard-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TimelineModule,
    CdkTableModule,
    TooltipDirective,
    TranslateModule,
    PipesModule,
    ChartModule
  ]
})
export class BuildingProgressDashboardTableComponent implements OnInit {
  columnsToDisplay = [...columns];
  dataSource: DashboardDatasource;
  loading$: Observable<boolean>;
  decimalPlaces: DecimalPlaces;
  dateBeforeLastPeriod: Date;
  timelineType = TimelineType;

  @Input() data$: Observable<SummaryDashboardTableModel>;

  @Output() headerRowLoaded = new EventEmitter<HTMLElement>();

  get appLocation(): string {
    return environment.location;
  }

  ngOnInit(): void {
    this.data$.subscribe(data => {
      this.decimalPlaces = data.decimalPlaces;

      if (data.periods.length > 1) {
        this.columnsToDisplay = [...columns];
        this.dateBeforeLastPeriod = new Date(data.periods[data.periods.length - 1].dateFrom);
        this.dateBeforeLastPeriod.setDate(this.dateBeforeLastPeriod.getDate() - 1);
      } else {
        this.columnsToDisplay = columns.filter(column => column !== 'partlyCompletedTotalPrice');
      }
    });
    this.reloadData();
  }

  getHeader(): void {
    setTimeout(() => {
      const element = document.getElementsByTagName('cdk-header-row')?.item(0) as HTMLElement;
      if (element) {
        this.headerRowLoaded.next(element);
      }
    }, 0);
  }

  isConstructionOrBuildingObject(item: IBudgetItem): boolean {
    return isConstructionOrBuildingObject(item);
  }

  isSummaryItem(item: IBudgetItem): boolean {
    return isSummaryItem(item);
  }

  getCodeClass(item: BuildingProgressItem): string {
    return setColorIfAdditionOrChangeSheetAllItems(item);
  }

  getDescriptionClass(item: BuildingProgressItem): string {
    return setColorIfAdditionOrChangeSheet(item) + setColorIfMaterial(item) + ` level-${item.level}`;
  }

  reloadData(): void {
    this.dataSource = new DashboardDatasource(this.data$);
    this.loading$ = this.dataSource.loading().pipe(tap(_ => this.getHeader()));
    this.dataSource.load();
  }
}
