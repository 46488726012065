import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { InputOptions, InputType } from '../../../inputs';

@Component({
  selector: 'kros-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SearchComponent,
      multi: true
    }
  ]
})
export class SearchComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() searchParamName: string;

  searchInputSettings: InputOptions;
  searchFormControl: UntypedFormControl;

  private subs = new SubSink();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.searchFormControl = new UntypedFormControl(this.route.snapshot.queryParams[this.searchParamName]);
    this.searchInputSettings = {
      type: InputType.TEXT,
      name: 'search',
      formControl: this.searchFormControl,
      className: 'list-search-input',
      placeholder: 'Hľadať',
      testName: 'transactions-search',
      maxLength: 255,
      icon: 'search',
      iconPosition: 'left'
    };

    this.subs.sink = this.route.queryParams.subscribe(query => {
      const searchTerm = query[this.searchParamName] ?? '';
      this.searchFormControl.setValue(searchTerm);
    });

    this.subs.sink = this.searchFormControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
    ).subscribe(searchTerm => {
      const { [this.searchParamName]: _, ...paramsWithoutTerm } = this.route.snapshot.queryParams;
      const paramsWithTerm = { ...this.route.snapshot.queryParams, page: 1, [this.searchParamName]: searchTerm };
      this.router.navigate([], {
        queryParams: searchTerm ? paramsWithTerm : paramsWithoutTerm,
        relativeTo: this.route
      });
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  registerOnChange(fn): void {
    fn(this.searchFormControl.value);
    this.subs.sink = this.searchFormControl.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn): void { }

  writeValue(obj): void {
    if (!this.route.snapshot.queryParams[this.searchParamName]) {
      this.searchFormControl.setValue(obj);
    }
  }
}
