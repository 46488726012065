import { Component, OnInit } from '@angular/core';

import { ColDef } from '@kros-sk/ssw-shared-legacy';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

import { BuildingProgressDataSettingsDispatchersService } from '../../../../store/building-progress';
import { BuildingProgressSelectorsService } from '../../../../store/building-progress';
import { BuildingProgressTableService } from '../../../services/building-progress.table.service';
import { DataSettings } from '../../../models/data-settings.model';
import { DetailHelper } from '../../../helpers';

@Component({
  selector: 'app-building-progress-column-filter',
  templateUrl: './building-progress-column-filter.component.html'
})
export class BuildingProgressColumnFilterComponent extends UnsubscribeComponent implements OnInit {
  readonly ALL_COLUMNS_TITLE = 'BUILDING_PROGRESS.ALL_COLUMNS';
  readonly COLUMN_SELECTION_TITLE = 'BUILDING_PROGRESS.COLUMN_SELECTION';
  readonly UNGROUPED_COLUMNS_LABEL = 'BUILDING_PROGRESS.ITEM_DATA';

  get filterColumns(): ColDef[] {
    return this.getFilterColumns();
  }

  private dataSettings: DataSettings;

  constructor(
    private appInsightsService: AppInsightsBaseService,
    private buildingProgressTableService: BuildingProgressTableService,
    private detail: DetailHelper,
    private dispatcherService: BuildingProgressDataSettingsDispatchersService,
    private selectorsService: BuildingProgressSelectorsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.selectorsService.dataSettings$.subscribe(result => this.dataSettings = result);
  }

  onColumnVisibilityChange(hiddenColumnIds: string[]): void {
    this.dispatcherService.saveDataSettings({
      hiddenColumns: this.getHiddenColumnsToSet(hiddenColumnIds),
      isSummaryPanelVisible: this.getSummaryPanelVisible()
    });
  }

  onFilterClose(hiddenColumns: string[]): void {
    const hiddenColumnsToSet = this.getHiddenColumnsToSet(hiddenColumns);
    this.dispatcherService.saveDataSettings(
      { hiddenColumns: hiddenColumnsToSet, isSummaryPanelVisible: this.getSummaryPanelVisible() });
    this.appInsightsService.trackEvent('PV-column-filter', { hiddenColumns: hiddenColumnsToSet.toString() });
  }

  private getFilterColumns(): ColDef[] {
    let filterColumnIds: string[] = [
      'productionClassification',
      'contractor',
      'customClassification',
      'partlyCompleted',
      'completed',
      'rest',
      'additional',
      'notCompleted'
    ];
    if (!this.detail.constructionData?.periods?.length ||
      !this.buildingProgressTableService.isPartlyCompletedColumnVisible(this.detail.constructionData.periods)) {
      filterColumnIds = filterColumnIds.filter(id => id !== 'partlyCompleted');
    }
    return this.detail.dataTableConfig?.colDefs ? this.detail.dataTableConfig.colDefs.filter(c => filterColumnIds.includes(c.id)) : [];
  }

  private getHiddenColumnsToSet(newHiddenColumns: string[]): string[] {
    const filterColumns = this.filterColumns;
    return newHiddenColumns.concat(
      this.dataSettings.hiddenColumns?.filter(hiddenColumn =>
        !newHiddenColumns.some(column => column === hiddenColumn) &&
        !filterColumns.some(column => column.id === hiddenColumn || column.colsInGroup?.some(col => col.id === hiddenColumn))
      )
    );
  }

  private getSummaryPanelVisible(): boolean {
    return this.detail.isSummaryPanelVisible ?? this.dataSettings.isSummaryPanelVisible;
  }
}
