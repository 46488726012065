<ng-container>
  <div
    ngbDropdown
    class="tablet-hidden"
  >
    <button
      ngbDropdownToggle
      class="btn dropdown-toggle-arrow-hidden"
      type="button"
      data-toggle="tooltip"
      krosTooltip="{{ 'NAVBAR.HELP' | translate }}"
    >
      <i class="material-icons">help_outline</i>
    </button>
    <div ngbDropdownMenu>
      <ng-container [ngTemplateOutlet]="menu"></ng-container>
    </div>
  </div>

  <div
    ngbDropdown
    class="tablet-visible dropright"
    container="body"
    placement="bottom"
    display="dynamic"
  >
    <ng-container [ngTemplateOutlet]="menu"></ng-container>
  </div>
</ng-container>

<ng-template #menu>
  <button
    *ngIf="isSlovakVersion"
    ngbDropdownItem
    class="btn link"
    type="button"
    (click)="onShowSupport()"
    krosAnalytic
    eventCategory="{{eventCategory}}"
    eventAction="{{showSupportAction}}"
  >
    <i class="material-icons-outlined navbar-mat-icon">support_agent</i>

    <span>{{ 'NAVBAR.SUPPORT' | translate }}</span>
  </button>

  <button
    *ngIf="helpService.showVideoTutorialChanged$ | async"
    ngbDropdownItem
    class="btn link"
    type="button"
    (click)="onShowVideoTutorials()"
    krosAnalytic
    eventCategory="{{eventCategory}}"
    eventAction="{{showVideoTutorialsAction}}"
  >
    <i class="material-icons-outlined navbar-mat-icon">video_library</i>
    <span>{{ 'NAVBAR.VIDEO_TUTORIALS' | translate }}</span>
  </button>

  <button
    *ngIf="canShowFaq"
    ngbDropdownItem
    class="btn link"
    type="button"
    (click)="onShowFaq()"
    krosAnalytic
    eventCategory="{{eventCategory}}"
    eventAction="{{showFaqAction}}"
  >
    <i class="material-icons-outlined navbar-mat-icon">quiz</i>
    <span>{{ 'NAVBAR.FAQ' | translate }}</span>
  </button>

  <button
    *ngIf="helpService.showHelpChanged$ | async"
    ngbDropdownItem
    class="btn link"
    type="button"
    (click)="onShowHelp()"
    krosAnalytic
    eventCategory="{{eventCategory}}"
    eventAction="{{showHelpAction}}"
  >
    <i class="material-icons-outlined navbar-mat-icon">help_outline</i>
    <span>{{ 'NAVBAR.HELP' | translate }}</span>
  </button>

  <button
    *ngIf="canShowTour"
    ngbDropdownItem
    class="btn link"
    type="button"
    (click)="onShowTour()"
    krosAnalytic
    eventCategory="{{eventCategory}}"
    eventAction="{{showTourAction}}"
  >
    <i class="material-icons-outlined navbar-mat-icon">chat_bubble_outlined</i>
    <span>{{ 'NAVBAR.GUIDE' | translate }}</span>
  </button>

  <button
    *ngIf="helpService.showIfcManualChanged$ | async"
    ngbDropdownItem
    class="btn link"
    type="button"
    (click)="onShowIfcManual()"
    krosAnalytic
    eventCategory="{{eventCategory}}"
    eventAction="{{showIfcManualAction}}"
  >
    <i class="material-icons-outlined navbar-mat-icon">text_snippet</i>
    <span>{{ (isSlovakVersion ? 'NAVBAR.IFC_MANUAL' : 'NAVBAR.BIM_MODEL_CREATION') | translate }}</span>
  </button>

  <button
    *ngIf="helpService.showContactsChanged$ | async"
    ngbDropdownItem
    class="btn link"
    type="button"
    (click)="onShowContacts()"
    krosAnalytic
    eventCategory="{{eventCategory}}"
    eventAction="{{showContactsAction}}"
  >
    <i class="material-icons-outlined navbar-mat-icon">call</i>
    <span>{{ 'NAVBAR.CONTACTS' | translate }}</span>
  </button>

  <button
    *ngIf="canShowCookieSettings"
    ngbDropdownItem
    class="btn link"
    type="button"
    (click)="onShowCookieSettings()"
    krosAnalytic
    eventCategory="{{eventCategory}}"
    eventAction="{{showCookieSettingsAction}}"
  >
    <i class="material-icons-outlined navbar-mat-icon">cookie</i>
    <span>{{ 'NAVBAR.COOKIE_SETTINGS' | translate }}</span>
  </button>
</ng-template>
