<div class="kros-modal-header">
  <h4 class="modal-title">
    {{ 'DOCUMENTS.VERSION' | translate }}
  </h4>
</div>

<div
  *ngIf="items"
  class="kros-modal-body documents"
>
  <div
    class="document"
    *ngFor="let item of items"
    [ngClass]="{'has-description': hasDescription(item.description)}"
    (click)="hasDescription(item.description) ? onClickVersion(item.order) : false"
  >
    <span
      class="order"
      [attr.data-test]="'document-description-order-' + item.order"
    >v{{item.order}}</span>
    <i
      *ngIf="hasDescription(item.description)"
      [attr.data-test]="'document-description-icon-' + item.order"
      class="material-icons"
      krosTooltip="{{ 'DOCUMENTS.HAS_DESCRIPTION' | translate }}"
      position="after"
    >description</i>
    <span
      class="document-title"
      [attr.data-test]="'document-description-originalName-' + item.order"
      krosTooltip="{{item.originalName}}"
    >{{item.originalName}}</span>
    <span
      *ngIf="item.isCurrent"
      class="actual-shown"
      [attr.data-test]="'document-version-actual-shown-' + item.order"
    >{{ 'DOCUMENTS.LATEST_VERSION' | translate }}</span>

    <span class="author">
      {{ getAuthor(item.email) }}
    </span>
    <span class="creation-date">
      {{item.dateCreated | date: "dd.MM.yyyy HH:mm"}}
    </span>
    <i
      *ngIf="isConstruction"
      [attr.data-test]="'document-view-version-icon-' + item.order"
      class="material-icons view-version"
      krosTooltip="{{ 'DOCUMENTS.VIEW_BUDGET' | translate }}"
      position="before"
      (click)="onOpenVersion(item.id)"
    >visibility</i>
    <i
      *ngIf="isConstruction && canDeleteDocumentVersion"
      [attr.data-test]="'document-delete-version-icon-' + item.order"
      class="material-icons delete-version"
      krosTooltip="{{ 'DOCUMENTS.DELETE_DOCUMENT_VERSION' | translate }}"
      position="before"
      (click)="onDeleteVersion(item.id)"
    >delete</i>
    <textarea
      *ngIf="isClickedItems(item.order)"
      class="description"
      rows="3"
      disabled
      data-test="description-textarea"
    >{{item.description}}</textarea>
  </div>
</div>

<div class="kros-modal-footer">
  <button
    class="btn btn-outline-secondary"
    data-test="document-version-close-button"
    type="button"
    (click)="onCancelClick()"
  >{{ 'DOCUMENTS.CLOSE' | translate }}</button>
</div>
