import { Inject, Injectable, Optional } from '@angular/core';

import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { HttpTransportType } from '@microsoft/signalr';

import { APP_CONFIG } from '@kros-sk/app-config';
import { ApplicationType, SignaRGroups } from '@kros-sk/models';
import { KrosAppInsightsService } from '@kros-sk/core';

import { AuthSelectorsService } from '../auth/store/auth-selectors.service';
import { KrosAuthService } from '../auth/services/auth/kros-auth.service';

@Injectable({
  providedIn: 'root'
})
export class KrosEswSignalRService {

  private readonly connectionEstablished = new BehaviorSubject<boolean>(false);
  public readonly connectionEstablished$ = this.connectionEstablished.asObservable();

  private hubConnection: signalR.HubConnection;
  private companyId: number;
  private userId: string;
  private isSignalRConnected = new BehaviorSubject<boolean>(true);
  private blockForSignalR = new BehaviorSubject<boolean>(null);
  private logger: signalR.ILogger;

  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private authSelectorService: AuthSelectorsService,
    private authService: KrosAuthService,
    @Optional() private appInsightsService: KrosAppInsightsService
  ) {
    this.subscribeToUser();
  }

  get connectionId(): string {
    return this.hubConnection?.connectionId;
  }

  get isConnected(): Observable<boolean> {
    return this.isSignalRConnected.asObservable();
  }

  get isSignalRUnavailable(): Observable<boolean> {
    return this.blockForSignalR.asObservable();
  }

  register(
    applicationId: ApplicationType,
    companyId: number,
    logger: signalR.ILogger = null
  ): void {
    if (this.hubConnection) {
      // Stop existing connection with old company Id
      this.hubConnection.stop().then(() => this.isSignalRConnected.next(false));
    }
    if (!companyId) return;
    this.companyId = companyId;

    let hubConnectionBuilder = new signalR.HubConnectionBuilder()
      .withUrl(`${this.appConfig.signalrHubUrl}/companies/${this.companyId}/signalR?appType=${applicationId.toString()}`, {
        accessTokenFactory: () => this.authService.token,
        // transport None should mean to use all available transfer types - if ws is not available
        // server sent events are used
        transport: HttpTransportType.None,
        headers: { applicationType: applicationId.toString() }
      })
      .withAutomaticReconnect();

    if (logger) {
      hubConnectionBuilder = hubConnectionBuilder.configureLogging(logger);
      this.logger = logger;
    }

    this.hubConnection = hubConnectionBuilder.build();

    fromEvent(window, 'beforeunload').pipe(take(1)).subscribe(_ =>
      this.hubConnection.stop(), // async without callback
    );

    this.hubConnection.start()
      .then(() => this.isSignalRConnected.next(true))
      .catch(() => {
        this.blockForSignalR.next(true);
      });

    this.hubConnection.onreconnecting(() => {
      this.blockForSignalR.next(true);
      this.isSignalRConnected.next(false);
    });

    this.hubConnection.onreconnected(() => {
      this.blockForSignalR.next(false);
      this.isSignalRConnected.next(true);
    });
    this.registerListeningToNotificationsOnHub(applicationId, this.userId);

    this.connectionEstablished.next(true);
  }

  /**
   * Register handler to listening on 'eventName' events
   *
   * Is realy important to pass the same instance of listener method into signalR regiser and unregister methods
   * because if not, unregister won't work (simple reference comparism in microsoft/signalr library)
   * Improper register / unregisterIt causes multiple registrations and thus multiple calls of listener method
   *
   * If 'this' is used in listener call from signalR service, 'this' won't have context of object,
   * where the listener is registered, but it will have context of caller
   * Arrow functions or 'this' binding (with hack) can help
   *
   * @param eventName Event name to be listened on it
   * @param listener Listener method, which is invoked when 'eventName' notification is recieved
   */
  registerListener(eventName: string, listener: (...args: any[]) => void): void {
    this.hubConnection.on(eventName, listener);
    this.debugLog(`Registered listener for event '${eventName}'.`);
  }

  /**
   * Registers listener for provided event name.
   * @param eventName Event name to be listened on it.
   * @returns Observable which emits values when event fires.
   */
  registerListener$<T extends Array<any> = Array<any>>(eventName: string): Observable<T> {
    return new Observable<T>(observer => {
      const listener = (...args: T): void => observer.next(args);

      try {
        this.hubConnection.on(eventName, listener);
        this.debugLog(`Registered listener for event '${eventName}'.`);
      } catch (e) {
        observer.error(e);
      }

      return (): void => {
        this.hubConnection.off(eventName, listener);
        this.debugLog(`Unregistered listener for event '${eventName}'.`);
        observer.complete();
      };
    });
  }

  /**
   * Unregister listening to 'eventName' event
   *
   * Is realy important to pass the same instance of listener method into signalR register and unregister methods
   * because if not, unregister won't work (simple reference comparism in microsoft/signalr library)
   * Improper register / unregisterIt causes multiple registrations and thus multiple calls of listener method
   *
   * If 'this' is used in listener call from signalR service, 'this' won't have context of object,
   * where the listener is registered, but it will have context of caller
   * Arrow functions or 'this' binding (with hack) can help
   *
   * @param eventName Event name to be listened on it
   * @param listener Listener method, which has to be unregistered from listening on events.
   */
  unregisterListener(eventName: string, listener: (...args: any[]) => void): void {
    this.hubConnection.off(eventName, listener);
    this.debugLog(`Unregistered listener for event '${eventName}'.`);
  }

  unregisterAllListeners(eventName: string): void {
    this.hubConnection.off(eventName);
    this.debugLog(`Unregistered all listeners for event '${eventName}'.`);
  }

  private debugLog(message: string): void {
    if (this.logger) this.logger.log(signalR.LogLevel.Debug, message);
  }

  private subscribeToUser(): void {
    this.authSelectorService.currentUser$.pipe(
      filter(user => user != null),
      map((user) => user.email)
    ).subscribe(email => this.userId = email);
  }

  private registerListeningToNotificationsOnHub(applicationId: ApplicationType, userId: string): void {
    this.hubConnection.on('RequestToRegister', () => {
      this.hubConnection.invoke('RegisterToGroupAsync', SignaRGroups.CompanyGroup, applicationId.toString()).catch(error => {
        this.handlehubConnectionInvokeError(error);
      });
      this.hubConnection.invoke('RegisterToGroupAsync', SignaRGroups.AppCompanyGroup, applicationId.toString()).catch(error => {
        this.handlehubConnectionInvokeError(error);
      });
      if (userId) {
        this.hubConnection.invoke('RegisterToGroupAsync', SignaRGroups.AppCompanyUserGroup, applicationId.toString()).catch(error => {
          this.handlehubConnectionInvokeError(error);
        });
        this.hubConnection.invoke('RegisterToGroupAsync', SignaRGroups.AppCompanyUserIdGroup, applicationId.toString()).catch(error => {
          this.handlehubConnectionInvokeError(error);
        });
      }
    });
  }

  private handlehubConnectionInvokeError(error): void {
    const unauthirizedErrorMessage = 'Failed to invoke \'RegisterToGroupAsync\' because user is unauthorized';
    if (error.message === unauthirizedErrorMessage) {
      this.appInsightsService?.trackEvent('KrosEswSignalRService', { message: `Handled error ${unauthirizedErrorMessage}` });
    } else {
      throw error;
    }
  }
}
