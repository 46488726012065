/**
 * Function for parsing number from varying value types.
 * For string parsing it considers sk-SK locale using parseStringToNumber function.
 * For non-null object and udefined values it throws exception.
 */
export function parseNumericValue(value: any): number {
  switch (typeof (value)) {
    case 'number': {
      if (Number.isNaN(value)) return 0;
      return value;
    }
    case 'string': return parseStringToNumber(value);
    case 'object': {
      if (value == null) return 0;
      throw (new Error('Can not parse numeric value from object.'));
    }
    case 'undefined': throw (new Error('Can not parse numeric value from undefined.'));
    default: return 0;
  }
}

/**
 * Function for parsing number from string, with sk-SK locale strings support.
 */
export function parseStringToNumber(value: string): number {
  if (value == null) {
    return 0;
  }

  const matchesNumber = value.match(/\d+/g);

  const n = value
    .replace(/\s/g, '')
    .replace(/,/g, '.');

  const result = Number.parseFloat(n);
  if (Number.isNaN(result) && !matchesNumber) {
    return 0;
  }
  return Number.isNaN(result) ? +matchesNumber : result;
}

/**
 * Function for formatting numbers in default locale - currently sk-SK.
 * @param value Formatted value.
 * @param digitsGrouping Digits grouping - thousands, millions etc.
 * @param minFractionDigits Minimum fraction digits count.
 * @param maxFractionDigits Maximum fraction digits count.
 */
export function formatNumber(
  value: number,
  digitsGrouping: boolean = true,
  minFractionDigits: number = 2,
  maxFractionDigits: number = 6,
): string {
  return value.toLocaleString('sk-SK', {
    style: 'decimal',
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: maxFractionDigits,
    useGrouping: digitsGrouping
  }
  );
}

/**
 * Rounding number to optional precision.
 * @param n Number to round.
 * @param fractionDigits Fraction digits count = rounding precision.
 * @param type Rounding type. Default 'round'.
 */
export function roundNumber(
  n: number,
  fractionDigits: number,
  type: 'round' | 'down' | 'up' = 'round'
): number {
  const pow = Math.pow(10, fractionDigits);
  switch (type) {
    case 'round': return Math.round((n + n * Number.EPSILON * 0.5) * pow) / pow;
    case 'down': return Math.floor(n * pow) / pow;
    case 'up': return Math.ceil(n * pow) / pow;
    default: throw new Error('roundNumber: Invalid rounding type.');
  }
}
