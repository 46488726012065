import { Injectable } from '@angular/core';

import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AnonymousCredential, BlobDownloadResponseModel, BlockBlobClient, newPipeline } from '@azure/storage-blob';

import { AzureCredentials } from '../models/azure-blob-storage.models';

@Injectable()
export class AzureBlobStorageService {

  constructor() { }

  downloadBlob(azureCredentials: AzureCredentials): Observable<Blob> {
    return from(this.downloadBlobFromAzureBlobStorage(this.getBlockBlobClient(azureCredentials))).pipe(
      switchMap(resp => resp.blobBody)
    );
  }

  uploadBlob(
    azureCredentials: AzureCredentials,
    file: File,
    inProgress: BehaviorSubject<boolean>
  ): Observable<boolean> {
    return from(this.uploadBlobToBlobStorage(this.getBlockBlobClient(azureCredentials), file, inProgress)).pipe(
      map(_ => true),
      catchError(_ => of(false))
    );
  }

  deleteBlob(azureCredentials: AzureCredentials): Observable<boolean> {
    return from(this.deleteBlobFromAzureBlobServices(this.getBlockBlobClient(azureCredentials)));
  }

  private downloadBlobFromAzureBlobStorage(blockBlobClient: BlockBlobClient): Promise<BlobDownloadResponseModel> {
    return blockBlobClient.download();
  }

  private uploadBlobToBlobStorage(
    blockBlobClient: BlockBlobClient,
    file: File,
    inProgress: BehaviorSubject<boolean>
  ): Promise<any> {
    return blockBlobClient.uploadBrowserData(
      file,
      {
        onProgress: (progress) => {
          // eslint-disable-next-line eqeqeq
          inProgress.next(progress.loadedBytes != file.size);
        },
        blobHTTPHeaders: {
          blobContentType: file.type
        }
      }
    );
  }

  private deleteBlobFromAzureBlobServices(blockBlobClient: BlockBlobClient): Promise<any> {
    return blockBlobClient.delete();
  }

  private getBlockBlobClient(blobAccessData: AzureCredentials): BlockBlobClient {
    return new BlockBlobClient(blobAccessData.blobUri, newPipeline(new AnonymousCredential()));
  }
}
