import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { TranslateLoader as CoreTranslateLoader, TranslateModule as CoreTranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { TranslateService } from './translate.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json?cacheBusting=' + new Date().getTime());
}

/**
 * Internationalization library for Angular.
 *
 * @export
 */
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CoreTranslateModule.forRoot({
      loader: {
        provide: CoreTranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    CoreTranslateModule
  ]
})
export class TranslateModule {

  /**
   * Use this method in your root module to provide the TranslateService.
   *
   * @param defaultLanguage Default language.
   * @param languageOptions List of supported languages.
   * @returns TranslateModule for using in root module.
   */
  public static forRoot(
    defaultLanguage: ((appConfig: any) => string),
    languageOptions: any
  ): ModuleWithProviders<TranslateModule> {
    return {
      ngModule: TranslateModule,
      providers: [
        TranslateService,
        {
          provide: 'defaultLanguage',
          useValue: defaultLanguage
        },
        {
          provide: 'languageOptions',
          useValue: languageOptions
        }
      ]
    };
  }

  /**
   * Use this method in your other (none root) modules to import directive|pipe.
   *
   * @returns TranslateModule for using in none root module.
   */
  public static forChild(): ModuleWithProviders<TranslateModule> {
    return CoreTranslateModule.forChild({
      loader: {
        provide: CoreTranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    });
  }
}
