import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { BillOfQuantitiesItemType, BoqItem } from '@kros-sk/ssw-shared-legacy';

import { BoqEditViewModel } from '../../building-progress/models/boq-edit.view-model';
import { BoqExpressionQuantityResult, BoqExpressionViewModel } from '../models/boq-expression.view-model';
import { BoqPeriodItem } from '../../building-progress/models/boq-period-item.model';
import { environment } from '../../../environments/environment';
import { PeriodItemsEditResult } from '../../building-progress/models/construction-data.model';

const gatewayApi = '/api/projects/';
const contractorsApi = '/contractors/';
const buildingProgress = '/buildingProgressService';
const boqItemsEndpoint = '/budget/boqItemList';
const boqPeriodItemsEndpoint = '/periodItems/boqItemList';
const boqSetEndpoint = '/periodItems/boqSet';
const calculateExpressionEndpoint = '/periodItems/boqExpressionCalculate';

@Injectable()
export class BoqService {

  constructor(
    private http: HttpClient,
  ) { }

  private get gatewayApi(): string {
    return environment.appUrls.titanGatewayUrl + gatewayApi;
  }

  private get buildingProgressApi(): string {
    return environment.appUrls.titanGatewayUrl + '/api/buildingProgressService';
  }

  getBoqItems(projectId: number, budgetItemId: number, contractorId?: number): Observable<BoqItem[]> {
    return this.http.get<BoqItem[]>(`${this.getEndpointPath(projectId, boqItemsEndpoint, contractorId)}/${budgetItemId}`);
  }

  getBoqPeriodItems(projectId: number, budgetItemId: number, contractorId?: number): Observable<BoqPeriodItem[]> {
    return this.http.get<BoqPeriodItem[]>(`${this.getEndpointPath(projectId, boqPeriodItemsEndpoint, contractorId)}/${budgetItemId}`);
  }

  calculateExpression(viewModel: BoqExpressionViewModel): Observable<BoqExpressionQuantityResult> {
    if (!viewModel.expression.trim()) {
      return of({
        quantity: 0,
        type: BillOfQuantitiesItemType.Expression
      } as BoqExpressionQuantityResult);
    }

    return this.http.post<BoqExpressionQuantityResult>(this.buildingProgressApi + calculateExpressionEndpoint, viewModel);
  }

  setBoqItems(viewModel: BoqEditViewModel): Observable<PeriodItemsEditResult> {
    return this.http.put<PeriodItemsEditResult>(this.buildingProgressApi + boqSetEndpoint, viewModel);
  }

  private getEndpointPath(projectId: number, endpoint: string, contractorId?: number): string {
    return this.gatewayApi + projectId + (contractorId ? contractorsApi + contractorId : '') + buildingProgress + endpoint;
  }
}
