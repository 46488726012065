import { Injectable } from '@angular/core';

import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { BoqItem, DecimalPlaces } from '@kros-sk/ssw-shared-legacy';
import { PermissionType } from '@kros-sk/ssw-shared/permission';

import { ActionInProgress, ApprovalType } from '../../budget-approval/enums';
import { adapterBoqItems } from './budget-approval.adapters';
import {
  BudgetApprovalDifferencesModel, BudgetApprovalModel, BudgetChangeModel, BudgetModel, ChangeSheetModel
} from '../../budget-approval/models';
import { BudgetApprovalState } from './budget-approval.state';
import { BuildingProgressSharing } from '../../building-progress/models/building-progress-sharing/building-progress-sharing.model';

export const budgetApprovalFeatureKey = 'budget-approval';

const selectBudgetApprovalDataFeature = createFeatureSelector<BudgetApprovalState>(budgetApprovalFeatureKey);

const selectBudgetApprovalData = createSelector(
  selectBudgetApprovalDataFeature,
  state => state.budgetApprovalData
);

const selectBudgetApprovalDataLoading = createSelector(
  selectBudgetApprovalDataFeature,
  state => state.budgetApprovalDataLoading
);

const selectBudgetApprovalDataDecPlaces = createSelector(
  selectBudgetApprovalDataFeature,
  state => state.budgetApprovalData?.decimalPlaces
);

const selectChangeSheetData = createSelector(
  selectBudgetApprovalDataFeature,
  state => state.changeSheetData
);

const selectChangeSheetDataLoading = createSelector(
  selectBudgetApprovalDataFeature,
  state => state.changeSheetDataLoading
);

const selectBudgetChangeData = createSelector(
  selectBudgetApprovalDataFeature,
  state => state.budgetChangeData
);

const selectDifferencesData = createSelector(
  selectBudgetApprovalDataFeature,
  state => state.differencesData
);

const selectHaveDifferences = createSelector(
  selectBudgetApprovalDataFeature,
  state => state.differencesData?.items.length > 0
);

const selectBudgetChangeDataLoading = createSelector(
  selectBudgetApprovalDataFeature,
  state => state.budgetChangeDataLoading
);

const selectBudgetData = createSelector(
  selectBudgetApprovalDataFeature,
  state => state.budgetData
);

const selectBudgetDataLoading = createSelector(
  selectBudgetApprovalDataFeature,
  state => state.budgetDataLoading
);

const selectChangeSheetSelectedItems = createSelector(
  selectBudgetApprovalDataFeature,
  state => state.selectedChangeSheetItemIds
);

const selectIsChangeSheetApproved = createSelector(
  selectBudgetApprovalDataFeature,
  (state: BudgetApprovalState, props) =>
    state.budgetApprovalData?.items.some(i => i.id === props.budgetObjectId && i.approvalType === ApprovalType.Approved)
);

const selectCanShowFunctions = createSelector(
  selectBudgetApprovalDataFeature,
  (state: BudgetApprovalState, props) => canUserSeeFuncs(state.sharingList.entities[props.userEmail ?? ''])
);

const selectActionInProgress = createSelector(
  selectBudgetApprovalDataFeature,
  state => state.actionInProgress
);

const selectBoqItemsEntities = createSelector(
  selectBudgetApprovalDataFeature,
  state => state.boqItems
);

const selectBoqItems = createSelector(
  selectBoqItemsEntities,
  adapterBoqItems.getSelectors().selectAll
);

const selectBoqItemsIsLoading = createSelector(
  selectBudgetApprovalDataFeature,
  state => state.boqItems.isLoading
);

const canUserSeeFuncs = (userSharing: BuildingProgressSharing): boolean =>
  userSharing?.permissionType >= PermissionType.Contributor;

const selectSelectedItemIds = createSelector(
  selectBudgetApprovalDataFeature,
  state => state.selectedItemsIds
);

@Injectable()
export class BudgetApprovalSelectorsService {
  constructor(private store: Store<BudgetApprovalState>) { }

  get selectedItemsIds$(): Observable<Set<number>> {
    return this.store.select(selectSelectedItemIds);
  }

  get budgetApprovalData$(): Observable<BudgetApprovalModel> {
    return this.store.select(selectBudgetApprovalData);
  }

  get budgetApprovalDataLoading$(): Observable<boolean> {
    return this.store.select(selectBudgetApprovalDataLoading);
  }

  get budgetApprovalDataDecPlaces$(): Observable<DecimalPlaces> {
    return this.store.select(selectBudgetApprovalDataDecPlaces);
  }

  get changeSheetData$(): Observable<ChangeSheetModel> {
    return this.store.select(selectChangeSheetData);
  }

  get changeSheetDataLoading$(): Observable<boolean> {
    return this.store.select(selectChangeSheetDataLoading);
  }

  get budgetChangeData$(): Observable<BudgetChangeModel> {
    return this.store.select(selectBudgetChangeData);
  }

  get budgetChangeDataLoading$(): Observable<boolean> {
    return this.store.select(selectBudgetChangeDataLoading);
  }

  get differencesData$(): Observable<BudgetApprovalDifferencesModel> {
    return this.store.select(selectDifferencesData);
  }

  get haveDifferences$(): Observable<boolean> {
    return this.store.select(selectHaveDifferences);
  }

  get budgetData$(): Observable<BudgetModel> {
    return this.store.select(selectBudgetData);
  }

  get budgetDataLoading$(): Observable<boolean> {
    return this.store.select(selectBudgetDataLoading);
  }

  get selectedChangeSheetItems$(): Observable<Set<number>> {
    return this.store.select(selectChangeSheetSelectedItems);
  }

  get actionInProgress$(): Observable<ActionInProgress> {
    return this.store.select(selectActionInProgress);
  }

  get boqItems$(): Observable<BoqItem[]> {
    return this.store.select(selectBoqItems);
  }

  get boqItemsIsLoading$(): Observable<boolean> {
    return this.store.select(selectBoqItemsIsLoading);
  }

  isChangeSheetApproved$(budgetObjectId: number): Observable<boolean> {
    return this.store.select(selectIsChangeSheetApproved, { budgetObjectId });
  }

  areFunctionsAvailableFor$(userEmail: string): Observable<boolean> {
    return this.store.select(selectCanShowFunctions, { userEmail });
  }
}
