import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable, tap } from 'rxjs';

import { Project, ToastService, ToastType, TranslateService } from '@kros-sk/ssw-shared-legacy';

import { BuildingProgressCopyPeriodsModel } from '../../../models';
import { environment } from '../../../../../environments/environment';

const gatewayApi = '/api/projects/';
const buildingProgress = '/buildingProgressService';
const periodsCopyEndpoint = '/periodItems/listCopy';

@Injectable()
export class BuildingProgressExportToAnotherProjectService {
  private get gatewayApi(): string {
    return environment.appUrls.titanGatewayUrl + gatewayApi;
  }

  private http = inject(HttpClient);
  private toastService = inject(ToastService);
  private translateService = inject(TranslateService);
  private readonly getProjectListEndpoint = `${environment.appUrls.titanGatewayUrl}/api/buildingProgressProjectList`;

  getProjects(): Observable<Project[]> {
    return this.http.get<Project[]>(this.getProjectListEndpoint);
  }

  copyPeriodItems(copyPeriodsModel: BuildingProgressCopyPeriodsModel, sourceProjectId: number): Observable<any> {
    return this.http.put(this.gatewayApi + sourceProjectId + buildingProgress + periodsCopyEndpoint, copyPeriodsModel)
      .pipe(
        tap(() => this.toastService.open(
          this.translateService.translate('BUILDING_PROGRESS.EXPORT_AMOUNTS_TO_ANOTHER_PROJECT_TRANSFER_SUCCESS'),
          ToastType.Info
        ))
      );
  }
}
