import { CommonModule, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { PieChartComponent } from './pie-chart/pie-chart.component';

@NgModule({
  declarations: [
    PieChartComponent
  ],
  imports: [
    CommonModule,
    TooltipDirective
  ],
  exports: [
    PieChartComponent
  ],
  providers: [
    PercentPipe
  ]
})
export class ChartModule {
}
