import { CdkMenuTrigger, PARENT_OR_NEW_MENU_STACK_PROVIDER } from '@angular/cdk/menu';
import { Directive } from '@angular/core';

@Directive({
  selector: '[krosDropdownTriggerFor]',
  exportAs: 'krosDropdownTriggerFor',
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['menuTemplateRef: krosDropdownTriggerFor'],
  // eslint-disable-next-line
  outputs: ['opened: cdkMenuOpened', 'closed: cdkMenuClosed'],
  providers: [
    { provide: CdkMenuTrigger, useExisting: KrosDropdownTrigger },
    PARENT_OR_NEW_MENU_STACK_PROVIDER,
  ],
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class KrosDropdownTrigger extends CdkMenuTrigger {}
