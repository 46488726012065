import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import {
  BudgetItemPositionType,
  BuildingObjectType,
  ChangedBudgetItems,
  isBuildingObjectTypeChangeSheet
} from '@kros-sk/ssw-shared-legacy';
import {
  BudgetObjectsUpload,
  BuildingObject,
  BuildingObjectCreate,
  BuildingObjectsItemType
} from '@kros-sk/ssw-budget/shared/data-access/models';

import { BuildingObjectOverwriteState } from '../models';

const budgetService = '/budgetService/';
const buildingProgressService = '/buildingProgressService/';
const budgetApprovalService = '/budgetApprovalService/';
const projectApi = '/api/projects/';
const buildingObjectApi = `${budgetService}BuildingObject`;

@Injectable({
  providedIn: 'root'
})
export class BuildingObjectsService {
  private appConfig = inject(APP_CONFIG);
  private http = inject(HttpClient);

  private readonly gatewayApi = `${this.appConfig.appUrls.titanGatewayUrl}${projectApi}`;
  private readonly buildingObjectsEndpoint = `${buildingObjectApi}/list`;
  private readonly buildingObjectCreateEndpoint = `${buildingObjectApi}/create`;
  private readonly moveBuildingObjectEndpoint = `${buildingObjectApi}/move`;
  private readonly uploadBuildingObjectEndpoint = `${buildingObjectApi}/buildingProgressCreate`;
  private readonly buildingProgressObjectOverwriteStateEndpoint = `${buildingProgressService}budget/buildingObjectOverwriteStateList`;
  private readonly budgetApprovalObjectOverwriteStateEndpoint = `${budgetApprovalService}budget/buildingObjectOverwriteStateList`;
  private readonly itemTypeListSetEndpoint = `${buildingObjectApi}/itemTypeListSet`;

  getBuildingObjects(projectId: number, budgetId: number,): Observable<BuildingObject[]> {
    return this.http.get<BuildingObject[]>(this.getBudgetApi(projectId, budgetId) + this.buildingObjectsEndpoint);
  }

  getBudgetApprovalBuildingObjectsState(projectId: number, hierarchyCodes: string[]): Observable<BuildingObjectOverwriteState[]> {
    return this.http.post<BuildingObjectOverwriteState[]>(
      this.getOverwriteStateApi(this.budgetApprovalObjectOverwriteStateEndpoint),
      { projectId, hierarchyCodes, isBudgetItemHistoryIncluded: true }
    );
  }

  getBuildingProgressBuildingObjectsState(projectId: number, hierarchyCodes: string[]): Observable<BuildingObjectOverwriteState[]> {
    return this.http.post<BuildingObjectOverwriteState[]>(
      this.getOverwriteStateApi(this.buildingProgressObjectOverwriteStateEndpoint),
      { projectId, hierarchyCodes, isBudgetItemHistoryIncluded: true }
    );
  }

  createBuildingObject(projectId: number, budgetId: number, data: BuildingObjectCreate): Observable<ChangedBudgetItems> {
    return this.http.post<ChangedBudgetItems>(this.getBudgetApi(projectId, budgetId) + this.buildingObjectCreateEndpoint, data);
  }

  moveBuildingObject(
    projectId: number,
    budgetId: number,
    currentItem: BuildingObject,
    positionType: BudgetItemPositionType,
    item: BuildingObject,
    buildingObjects: BuildingObject[]
  ): Observable<ChangedBudgetItems> {
    const buildingObjectTypeMap = buildingObjects.reduce((buildingObjectsMap, o) => {
      if (o.id) {
        buildingObjectsMap[o.id] = isBuildingObjectTypeChangeSheet(o)
          ? BuildingObjectType.ChangeSheet
          : BuildingObjectType.Normal;
        return buildingObjectsMap;
      }
    }, {} as Record<string, BuildingObjectType>);
    const data = {
      currentItem: {
        id: currentItem.id,
        buildingObjectId: currentItem.id,
        itemType: currentItem.itemType
      },
      positionType,
      buildingObjectId: item.id,
      buildingObjectTypeMap
    };
    return this.http.post<ChangedBudgetItems>(this.getBudgetApi(projectId, budgetId) + this.moveBuildingObjectEndpoint, data);
  }

  uploadBuildingObjects(
    projectId: number,
    budgetId: number,
    selectedIds: string[] = []
  ): Observable<BudgetObjectsUpload> {
    const data = { buildingObjectIds: selectedIds.map(id => parseInt(id, 10)) };
    return this.http.post<BudgetObjectsUpload>(this.getBudgetApi(projectId, budgetId) + this.uploadBuildingObjectEndpoint, data);
  }

  setItemTypes(
    projectId :number,
    budgetId :number,
    data: BuildingObjectsItemType
  ): Observable<ChangedBudgetItems> {
    return this.http.patch<ChangedBudgetItems>(this.getBudgetApi(projectId, budgetId) + this.itemTypeListSetEndpoint, data);
  }

  private getBudgetApi(projectId: number, budgetId: number): string {
    return this.gatewayApi + projectId + '/budgets/' + budgetId;
  }

  private getOverwriteStateApi(overwriteStateEndpoint: string): string {
    return `${this.appConfig.appUrls.titanGatewayUrl}/api${overwriteStateEndpoint}`;
  }
}
