@if (canShowLicenseInfo) {
<div
  class="license-info"
  [ngClass]="{'hidden': isHidden}"
>
  <div class="license-info-wrapper">
    <div class="text">
      <p>{{ licenseInfo | licenseInfoText: isMobile : canActivateTrial }}</p>

      @if (canShowMoreInfo) {
      <a
        class="btn btn-sm more-info"
        target="_blank"
        rel="noopener"
        href="{{moreInfoUrl}}"
      >{{ 'LICENSE_INFO.MORE_INFO' | translate }}</a>
      }
    </div>

    <div class="buttons">
      <button
        class="btn btn-sm btn-orange btn-order"
        type="button"
        (click)="onOpenOrderUrl()"
      >{{ license | licenseEshopRedirect }}</button>

      @if (canActivateTrial) {
      <button
        class="btn btn-sm btn-outline-white btn-white ga-license-activate-trial"
        type="button"
        (click)="onActivateTrial()"
      >{{ trialButtonText }}</button>
      }

      @if (additionalButton && !isMobile) {
      <button
        class="btn btn-sm btn-outline-white btn-white btn-additional"
        type="button"
        (click)="additionalButton.action()"
      >{{ additionalButton.label }}</button>
      }
    </div>
  </div>

  <div>
    <button
      type="button"
      class="btn btn-close material-icons"
      (click)="onCloseButtonClick()"
    >close</button>
  </div>
</div>
}
