import { Injectable } from '@angular/core';

import { take } from 'rxjs/operators';

import {
  InfoModalComponent,
  isAdditionOrChangeSheet,
  ToastService,
  ToastType,
  TranslateService
} from '@kros-sk/ssw-shared-legacy';
import { KrosModalService } from '@kros-sk/components';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

import { ApprovalType } from '../enums';
import { BudgetApprovalExportBudgetModel } from '../models/budget-approval-export-budget.model';
import { BudgetApprovalExportComponent } from '../components/budget-approval-export/budget-approval-export.component';
import { BudgetApprovalExportModel, BudgetApprovalExportSettings } from '../models/budget-approval-export.model';
import { BudgetApprovalModel } from '../models';
import { BudgetApprovalService } from '../services';

@Injectable()
export class ExportHelper {
  filterVisible = false;

  constructor(
    private appInsights: AppInsightsBaseService,
    private budgetApprovalService: BudgetApprovalService,
    private krosModalService: KrosModalService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) { }

  exportBudgetApprovalDifferences(projectId: number): void {
    this.appInsights.trackEvent('BA-differences-prices-export');
    this.budgetApprovalService.exportBudgetApprovalDifferences(projectId)
      .pipe(take(1))
      .subscribe({ error: (err) => this.handleErr(err) });
  }

  exportBudgetApprovalBudget(projectId: number, budgetItemId: number): void {
    const exportModel: BudgetApprovalExportBudgetModel = {
      projectId,
      budgetItemId,
      isSingleSheetExport: true
    };

    this.appInsights.trackEvent('BA-budget-changes-export');
    this.budgetApprovalService.exportBudgetApprovalBudget(exportModel)
      .pipe(take(1))
      .subscribe({ error: (err) => this.handleErr(err) });
  }

  openBudgetApprovalExport(data: BudgetApprovalModel, projectId: number, budgetItemId?: number): void {
    if (data.items.some(i => isAdditionOrChangeSheet(i))) {
      this.budgetApprovalService.getXlsxExportSettings().pipe(take(1)).subscribe(
        settings => {
          const exportModel = this.getXlsxExportModel(data, settings, projectId, budgetItemId);

          const modalRef = this.krosModalService.openCentered(
            BudgetApprovalExportComponent,
            {
              exportModel,
              budgetApprovalData: data,
              budgetItemId
            },
            {
              closeOnBackdropClick: false
            }
          );

          modalRef.afterClosed$.pipe(take(1)).subscribe(result => {
            if (result.type === 'submit') {
              this.exportBudgetApproval(result.data.exportModel);
            }
          });
        }
      );
    } else {
      this.krosModalService.openCentered(
        InfoModalComponent,
        { body: this.translateService.translate('BUDGET_APPROVAL.EXPORT.NO_CHANGE_SHEET') },
        {
          closeOnBackdropClick: false,
          fullscreenOnMobile: false,
          showMobileArrowBack: false
        },
        NaN,
        NaN,
        undefined,
        'no-min-width'
      );
    }
  }

  private exportBudgetApproval(exportModel: BudgetApprovalExportModel): void {
    this.appInsights.trackEvent('BA-export');
    this.budgetApprovalService.exportBudgetApproval(exportModel)
      .pipe(take(1))
      .subscribe({ error: (err) => this.handleErr(err) });
  }

  private getXlsxExportModel(
    data: BudgetApprovalModel,
    savedSettings: BudgetApprovalExportSettings,
    projectId: number,
    budgetItemId?: number): BudgetApprovalExportModel {
    let selectedIds: number[] = [];
    let isExportTypeByIdWithChildren = false;
    const changeSheets = data.items.filter(s => s.approvalType === ApprovalType.Approved ||
      s.approvalType === ApprovalType.NotApproved);

    if (budgetItemId) {
      selectedIds = [budgetItemId];
      isExportTypeByIdWithChildren = true;
    } else {
      const unapprovedChangeSheetIds = changeSheets.filter(s => s.approvalType === ApprovalType.NotApproved).map(s => s.id);

      if (unapprovedChangeSheetIds.length > 0) {
        selectedIds = unapprovedChangeSheetIds;
      } else {
        selectedIds = changeSheets.map(s => s.id);
      }
    }

    return {
      projectId,
      isSummaryIncluded: false,
      exportSummaryViewModel: {
        isTotalPriceChangesIncluded: savedSettings.summaryExportSettings.isTotalPriceChangesIncluded,
        isTotalPriceChangesPercentageIncluded: savedSettings.summaryExportSettings.isTotalPriceChangesPercentageIncluded,
        isPositiveAndNegativeChangesIncluded: savedSettings.summaryExportSettings.isPositiveAndNegativeChangesIncluded,
        isAbsoluteChangesIncluded: savedSettings.summaryExportSettings.isAbsoluteChangesIncluded,
        isAbsoluteChangesPercentageIncluded: savedSettings.summaryExportSettings.isAbsoluteChangesPercentageIncluded,
        changeSheetIds: changeSheets.map(s => s.id)
      },
      isChangeSheetIncluded: false,
      exportChangeSheetViewModel: {
        changeSheetIds: selectedIds,
        isBoqIncluded: savedSettings.changeSheetExportSettings.isBoqIncluded,
        isExportByIdWithChildren: isExportTypeByIdWithChildren
      },
      exportGeneralViewModel: {
        isLogoIncluded: savedSettings.generalExportSettings.isLogoIncluded,
        isStampIncluded: savedSettings.generalExportSettings.isStampIncluded
      }
    };
  }

  private handleErr(err: any): void {
    if (err.status === 404) {
      this.toastService.open(this.translateService.translate('BUDGET_APPROVAL.EXPORT.NO_DATA'), ToastType.Error);
    } else {
      this.toastService.open(err.message, ToastType.Error);
    }
  }
}
