<div
  *ngIf="!onlyCreateFolder else createFolderButton"
  ngbDropdown
  container="body"
>
  <button
    class="btn toolbar-action green-toolbar-action no-selection"
    type="button"
    ngbDropdownToggle
    krosTooltip="{{ 'DOCUMENTS.ADD' | translate }}"
    data-test="upload-button-menu"
  >
    <i class="material-icons icon-button">add</i>
    <span class="toolbar-action-text">{{ 'DOCUMENTS.ADD' | translate }}</span>
  </button>
  <ul ngbDropdownMenu>
    <button
      ngbDropdownItem
      class="btn link"
      type="button"
      [disabled]="!uploaderService.uploadCompleted"
      (click)="createFolder()"
      data-test="add-folder-button"
      analytics
      eventCategory="sprava-suborov"
      eventAction="pridat-priecinok"
    >
      <i class="material-icons icon-button">folder</i>
      <span>{{ 'DOCUMENTS.PRIDAT_PRIECINOK' | translate }}</span>
    </button>

    <kros-upload-button
      [disabled]="!uploaderService.uploadCompleted"
      [projectId]="projectId"
      [parentId]="parentId"
      (filesSelected)="onFilesSelected()"
    >
    </kros-upload-button>

    <kros-upload-version-button
      *ngIf="canAddDocumentVersion"
      [disabled]="!uploaderService.uploadCompleted"
      [projectId]="projectId"
      [documentId]="selection.getSelectedDocumentId"
      [parentId]="parentId"
      [contentType]="contentType"
      (fileAddVersionSelected)="onFileAddVersionSelected()"
    >
    </kros-upload-version-button>
  </ul>
</div>

<ng-template #createFolderButton>
  <button
    class="btn toolbar-action no-selection gray-toolbar-action"
    type="button"
    (click)="createFolder()"
    krosTooltip="{{ 'DOCUMENTS.PRIDAT_PRIECINOK' | translate }}"
    data-test="add-folder-button"
    analytics
    eventCategory="sprava-suborov"
    eventAction="pridat-priecinok"
  >
    <i class="material-icons icon-button">folder</i>
    <span class="toolbar-action-text">{{ 'DOCUMENTS.PRIDAT_PRIECINOK' | translate }}</span>
  </button>
</ng-template>
