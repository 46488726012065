import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import * as buildingProgressCommentsActions from './building-progress-comments.actions';
import { BuildingProgressCommentModel } from '../../building-progress/modules/comments-panel/comments-panel/comment.model';
import { BuildingProgressState } from './building-progress.state';

@Injectable()
export class BuildingProgressCommentsDispatchersService {

  constructor(
    private store: Store<BuildingProgressState>
  ) { }

  loadComments(projectId: number, contractorId?: number): void {
    this.store.dispatch(
      buildingProgressCommentsActions.loadCommentsStart({ projectId, contractorId })
    );
  }

  loadCommentPositions(projectId: number, contractorId?: number): void {
    this.store.dispatch(
      buildingProgressCommentsActions.loadCommentPositionsStart({ projectId, contractorId })
    );
  }

  createComment(comment: BuildingProgressCommentModel, contractorId?: number): void {
    this.store.dispatch(
      buildingProgressCommentsActions.createCommentStart({ comment, contractorId })
    );
  }

  updateComment(comment: BuildingProgressCommentModel): void {
    this.store.dispatch(
      buildingProgressCommentsActions.updateCommentStart({ comment })
    );
  }

  deleteComment(projectId: number, commentId: number, contractorId?: number): void {
    this.store.dispatch(
      buildingProgressCommentsActions.deleteCommentStart({ projectId, commentId, contractorId })
    );
  }

  publishComments(projectId: number, contractorId?: number): void {
    this.store.dispatch(
      buildingProgressCommentsActions.publishCommentsStart({ projectId, contractorId })
    );
  }
}
