import { Component } from '@angular/core';

import { KrosModalRef } from '@kros-sk/components';

@Component({
  selector: 'app-building-progress-invoice-license-info',
  templateUrl: './building-progress-invoice-license-info.component.html',
  styleUrls: ['./building-progress-invoice-license-info.component.scss']
})
export class BuildingProgressInvoiceLicenseInfoComponent {

  constructor(
    private modalRef: KrosModalRef,
  ) { }

  onClose(): void {
    this.modalRef.cancel();
  }
}
