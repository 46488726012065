<kros-user-sharing-dialog-base
  [initialCompactMode]="initialCompactMode"
  [sharings]="sharings"
  [loadedItems]="loadedItems"
  [editItems]="editItems"
  [otherAlreadySharedEmails]="otherAlreadySharedEmails"
  [unconfirmedSharings]="unconfirmedSharings"
  [needRefresh]="needRefresh"
  [submitEditedPermissions]="submitEditedPermissionsFunc"
  [shareNewPermissions]="shareNewPermissionsFunc"
  [getSharing]="getSharingFunc"
  [readonly]="readonly"
  [sharingMode]="sharingMode"
  [defaultPermissions]="defaultPermissions"
  [title]="title"
  [dataTestPrefix]="dataTestPrefix"
  (unconfirmedSharingChange)="onUnconfirmedSharingChange($event)"
  (submitSharing)="onSubmitSharing($event)"
>
</kros-user-sharing-dialog-base>
