import { createAction, props } from '@ngrx/store';

import { License, LicenseDetail } from '../../license';

export const loadMishaLicenseStart = createAction('[MishaLicense] Load license start');
export const loadMishaLicenseSuccess = createAction(
  '[MishaLicense] Load license success',
  props<{ mishaLicense: License }>());
export const loadMishaLicenseError = createAction('[MishaLicense] Load license error', props<{ error: any }>());

export const loadBuildingProgressLicenseStart = createAction('[BuildingProgressLicense] Load license start',
  props<{ returnToBuildingProgress?: boolean, returnToBudgetApproval?: boolean }>());
export const loadBuildingProgressLicenseSuccess = createAction('[BuildingProgressLicense] Load license success',
  props<{ buildingProgressLicense: License, returnToBuildingProgress?: boolean, returnToBudgetApproval?: boolean }>());
export const loadBuildingProgressLicenseError = createAction('[BuildingProgressLicense] Load license error', props<{ error: any }>());

export const loadLicenseDetailStart = createAction('[License] Load license detail start');
export const loadLicenseDetailSuccess = createAction(
  '[License] Load license detail success',
  props<{ licenseDetail: LicenseDetail }>());
export const loadLicenseDetailError = createAction('[License] Load license number error', props<{ error: any }>());

export const loadBudgetLicenseStart = createAction('[BudgetLicense] Load license start');
export const loadBudgetLicenseSuccess = createAction(
  '[BudgetLicense] Load license success',
  props<{ budgetLicense: License }>());
export const loadBudgetLicenseError = createAction('[QuantityManagerLicense] Load license error', props<{ error: any }>());

export const loadQuantityManagerLicenseStart = createAction('[QuantityManagerLicense] Load license start');
export const loadQuantityManagerLicenseSuccess = createAction(
  '[QuantityManagerLicense] Load license success',
  props<{ quantityManagerLicense: License }>());
export const loadQuantityManagerLicenseError = createAction('[QuantityManagerLicense] Load license error', props<{ error: any }>());

export const loadDesktopLicenseStart = createAction('[DesktopLicense] Load license start');
export const loadDesktopLicenseSuccess = createAction(
  '[DesktopLicense] Load license success',
  props<{ desktopLicense: License }>());
export const loadDesktopLicenseError = createAction('[DesktopLicense] Load license error', props<{ error: any }>());

export const activateTrialBuildingProgressLicense = createAction(
  '[BuildingProgressLicense] Activate trial license',
  props<{ isBudgetApproval?: boolean }>());

export const activateTrialBudgetLicense = createAction('[BudgetLicense] Activate trial license');

export const activateTrialQuantityManagerLicense = createAction('[QuantityManagerLicense] Activate trial license');
