<ul class="list-group">
  <li class="list-group-item sub-header">
    <h5>{{ 'PROJECTS.FILTER.FILTER_PROJECTS' | translate }}</h5>
  </li>

  <li
    class="list-group-item"
    (click)="changeFilter(1)"
    [class.selected]="filterIndex === 1"
    krosAnalytic
    eventCategory="projekt-filtre"
    eventAction="vsetky-projekty"
    data-test="project-filter-all-projects"
  >
    <i class="icon-button material-icons">home</i>

    <span class="filter-text">
      {{ 'PROJECTS.FILTER.ALL_PROJECTS' | translate }}
    </span>
  </li>
  <li
    class="list-group-item"
    (click)="changeFilter(2)"
    [class.selected]="filterIndex === 2"
    krosAnalytic
    eventCategory="projekt-filtre"
    eventAction="moje-projekty"
    data-test="project-filter-my-project"
  >
    <i class="icon-button material-icons">perm_identity</i>

    <span class="filter-text">
      {{ 'PROJECTS.FILTER.MY_PROJECTS' | translate }}
    </span>
  </li>
  <li
    class="list-group-item"
    (click)="changeFilter(3)"
    [class.selected]="filterIndex === 3"
    krosAnalytic
    eventCategory="projekt-filtre"
    eventAction="zdielane-so-mnou"
    data-test="project-filter-shared"
  >
    <i class="icon-button material-icons">people</i>

    <span class="filter-text">
      {{ 'PROJECTS.FILTER.SHARED_WITH_ME' | translate }}
    </span>
  </li>

  <ng-container *ngIf="isSlovakVersion">
    <li class="list-group-item sub-header">
      <h5>{{ 'CONSTRUCTION_DIARY.CONSTRUCTION_DIARY' | translate }}</h5>
    </li>

    <li
      class="list-group-item"
      (click)="onOpenStavario()"
    >
      <i
        class="icon-button custom-icon"
        data-test="open-stavario"
      >
        <img
          [src]="'assets/icons/icon-stavario.svg'"
          alt="Stavario icon"
        >
      </i>

      <span class="filter-text">
        {{ 'CONSTRUCTION_DIARY.STAVARIO' | translate }}
      </span>
    </li>

    <li
      class="list-group-item"
      (click)="onOpenBuildary()"
    >
      <i
        class="icon-button custom-icon"
        data-test="open-buildary"
      >
        <img
          [src]="'assets/icons/icon-buildary.svg'"
          alt="Buildary icon"
        >
      </i>

      <span class="filter-text">
        {{ 'CONSTRUCTION_DIARY.BUILDARY' | translate }}
      </span>
    </li>
  </ng-container>

  <li class="list-group-item sub-header">
    <h5>{{ 'PROJECTS.ZOZNAM.OTVORIT' | translate }}</h5>
  </li>

  <li
    class="list-group-item"
    (click)="openProjectReport()"
  >
    <i
      class="icon-button material-icons-outlined"
      data-test="open-project-report"
    >poll</i>

    <span class="filter-text">
      {{ 'PROJECTS.DETAIL.INFO.PROJECT_REPORTS' | translate }}
    </span>
  </li>

  <ng-container *ngIf="(authSelectorsService.featureFlags$ | async)?.platformMaintenance">
    <li
      class="list-group-item"
      [routerLink]="['platform-maintenance']"
    >
      <i
        class="icon-button material-icons-outlined"
      >settings</i>

      <span class="filter-text">
        {{ 'PROJECTS.PLATFORM_MAINTENANCE' | translate }}
      </span>
    </li>
  </ng-container>
</ul>
