import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import {
  BudgetStructureService,
  IBudgetItem,
  isConstructionOrBuildingObject,
  isSummaryItem
} from '@kros-sk/ssw-shared-legacy';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { BuildingProgressDispatchersService, BuildingProgressSelectorsService } from '../../../store/building-progress';
import { BuildingProgressItem } from '../../../building-progress/models/construction-data.model';
import { BuildingProgressPeriod } from '../../../building-progress/models/building-progress-period.model';
import { CommentPositionModel } from '../../../building-progress/modules/comments-panel/comments-panel/comment.model';
import { DetailHelper, setColorIfAdditionOrChangeSheet, setColorIfMaterial } from '../../../building-progress/helpers';

@Component({
  selector: 'app-building-progress-mobile-table',
  templateUrl: './building-progress-mobile-table.component.html',
  styleUrls: ['./building-progress-mobile-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuildingProgressMobileTableComponent extends UnsubscribeComponent implements OnInit {
  commentPositions: CommentPositionModel[];
  currentItemIndex: number;
  showDetail = false;

  @Input() selectedPeriod: BuildingProgressPeriod;
  @Input() hidePrices = false;

  @Input() set showSelection(value: boolean) {
    if (this._showSelection && !value) {
      this.dispatchersService.clearMultiSelectItems();
    }
    this._showSelection = value;
  }

  get showSelection(): boolean {
    return this._showSelection;
  }

  get columns(): string[] {
    const cols = ['description'];
    if (this.detail.hasPeriods && !this.hidePrices) {
      cols.push('price');
    }
    return cols;
  }

  private _showSelection: boolean;
  private selectedItemIds = new Set<number>();
  private indeterminateItemIds = new Set<number>();

  constructor(
    public detail: DetailHelper,
    private budgetStructureService: BudgetStructureService,
    private dispatchersService: BuildingProgressDispatchersService,
    private selectorsService: BuildingProgressSelectorsService,
    private changeDetectionRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.budgetStructureService.setFocusToBudgetItemRequested$
      .subscribe(result => {
        setTimeout(() => {
          const itemIndex = this.detail.constructionData.items.findIndex(i => i.id === result.id);
          document.getElementById('row-' + itemIndex).scrollIntoView({
            block: 'center',
            inline: 'nearest',
            behavior: 'smooth'
          });
        }, 0);
      });

    this.subs.sink = this.selectorsService.selectedItemIds$.subscribe(
      itemIds => {
        if (itemIds && itemIds instanceof Set) {
          this.selectedItemIds = itemIds;
        }
      }
    );

    this.subs.sink = this.selectorsService.indeterminateItemIds$.subscribe(
      itemIds => {
        if (itemIds && itemIds instanceof Set) {
          this.indeterminateItemIds = itemIds;
        }
      }
    );

    this.subs.sink = this.selectorsService.commentPositions$
      .subscribe((commentPositions: CommentPositionModel[]) => this.commentPositions = commentPositions);

    this.subs.sink = this.selectorsService.constructionData$.subscribe(() => this.changeDetectionRef.detectChanges());
  }

  trackBy(_, item: IBudgetItem): number {
    return item.id;
  }

  isConstructionOrBuildingObject(item: IBudgetItem): boolean {
    return isConstructionOrBuildingObject(item);
  }

  isSummaryItem(item: IBudgetItem): boolean {
    return isSummaryItem(item);
  }

  getDescriptionClass(item: BuildingProgressItem): string {
    return setColorIfAdditionOrChangeSheet(item) + setColorIfMaterial(item);
  }

  hasComments(item: BuildingProgressItem): boolean {
    return this.selectedPeriod && this.commentPositions.some(p => p.budgetItemId === item.id && p.periodId === this.selectedPeriod.id);
  }

  onItemClick(item: BuildingProgressItem): void {
    if (this.showSelection) {
      this.setItemChecked(!this.isItemCheckChecked(item), item);
    } else {
      this.currentItemIndex = this.detail.constructionData.items.indexOf(item);
      this.detail.focusedCell = {
        ...this.detail.focusedCell,
        rowIndex: this.currentItemIndex
      };
      this.showDetail = !this.showDetail;
    }
  }

  onDescriptionHeaderClick(): void {
    if (this.showSelection) {
      this.onHeaderCheckChanged();
    }
  }

  isHeaderCheckChecked(): boolean {
    return this.selectedItemIds.size + this.indeterminateItemIds.size === this.detail.constructionData.items.length;
  }

  isHeaderCheckIndeterminate(): boolean {
    return this.selectedItemIds.size > 0 || this.indeterminateItemIds.size > 0;
  }

  onHeaderCheckChanged(): void {
    let checked = true;
    if (this.selectedItemIds.size + this.indeterminateItemIds.size !== 0) {
      checked = false;
    }

    if (checked) {
      this.dispatchersService.multiSelectAllitems();
    } else {
      this.dispatchersService.clearMultiSelectItems();
    }
  }

  isItemCheckChecked(item: BuildingProgressItem): boolean {
    return this.selectedItemIds.has(item.id);
  }

  isItemCheckIndeterminate(item: BuildingProgressItem): boolean {
    return this.indeterminateItemIds.has(item.id);
  }

  onItemCheckChanged(event: any, item: BuildingProgressItem): void {
    this.setItemChecked(event.target.checked, item);
    event.target.checked = false;
  }

  private setItemChecked(checked: boolean, item: BuildingProgressItem): void {
    let checkedToSet = checked;
    if (isSummaryItem(item) && this.indeterminateItemIds.has(item.id)) {
      checkedToSet = false;
    }

    this.dispatchersService.multiSelectItem(item.id, checkedToSet);
  }
}
