/* eslint-disable max-lines */
import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { filter, take } from 'rxjs/operators';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { BudgetStructureService, DataTableComponent } from '@kros-sk/ssw-shared-legacy';
import { KrosModalService } from '@kros-sk/components';

import { BuildingProgressActionAccessService } from '../../../../building-progress/services/building-progress-action-access.service';
import {
  BuildingProgressCommentsDispatchersService,
  BuildingProgressDataSettingsDispatchersService, BuildingProgressDispatchersService,
  BuildingProgressSelectorsService
} from '../../../../store/building-progress';
import { BuildingProgressDeleteItemsService } from '../../../../building-progress/services/building-progress-delete-items.service';
import {
  BuildingProgressDetailBaseComponent
} from '../../../../building-progress/components/building-progress-detail/building-progress-detail-base.component';
import { BuildingProgressItem, BuildingProgressModel } from '../../../../building-progress/models/construction-data.model';
import {
  BuildingProgressPanelHelper,
  DetailHelper,
  ExportHelper,
  getColumnsMaxNumber,
  LicenseHelper,
  PeriodHelper,
  PermissionHelper,
  VisualHelper
} from '../../../../building-progress/helpers';
import { BuildingProgressTableService } from '../../../../building-progress/services/building-progress.table.service';
import { BuildingProgressTourService } from '../../../services/building-progress-tour.service';
import { BuildingProgressViewsService, ViewType } from '../../../../building-progress/services/building-progress-views.service';
import { ColorCodesService } from '../../../../building-progress/services/color-codes.service';
import { PeriodsHelperService } from '../../periods/services/periods-helper.service';
import { ProjectsSelectorsService } from '../../../../store/projects';
import { RemoveItemsService } from '../services/remove-items.service';
import { SelectedView } from '../../../../building-progress/models/selected-view.model';
import { Subcontractor } from '../models';
import { SubcontractorsDispatchersService, SubcontractorsSelectorsService } from '../../../../store/subcontractors';
import {
  SubcontractorsSelectItemsDialogComponent
} from '../subcontractors-select-items-dialog/subcontractors-select-items-dialog.component';
import { SubcontractorsSharingDialogComponent } from '../subcontractors-sharing-dialog/subcontractors-sharing-dialog.component';
import { TableChangeDetectionService } from '../../../../building-progress/services/table-change-detection.service';
import { TransferExecutionsDialogComponent } from '../transfer-executions-dialog/transfer-executions-dialog.component';

@Component({
  selector: 'app-subcontractors-detail',
  templateUrl: './subcontractors-detail.component.html',
  styleUrls: ['./subcontractors-detail.component.scss', '../../../shared/building-progress-table.scss'],
  providers: [
    ColorCodesService,
    PermissionHelper,
    RemoveItemsService,
    LicenseHelper,
    BuildingProgressTourService,
    PeriodsHelperService
  ]
})
export class SubcontractorsDetailComponent extends BuildingProgressDetailBaseComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableComponent) set table(value: DataTableComponent) {
    this.visualHelper.table = value;
  }

  @Input() tableId: string;

  get canEditSubcontractors(): boolean {
    return (this.permissionHelper.isOwner || this.permissionHelper.isContributor) &&
      !this.licenseHelper.isReadonlyLicense;
  }

  get canOpenBoq(): boolean {
    return !!this.detail.focusedCell;
  }

  get isOwnerOrContributor(): boolean {
    return this.permissionHelper.isOwner || this.permissionHelper.isContributor;
  }

  get areCommentsReadOnly(): boolean {
    return (!this.permissionHelper.isOwner && !this.permissionHelper.isContributor) || this.licenseHelper.isReadonlyLicense;
  }

  get itemIdsForExportTable(): number[] {
    return this.detail.isViewOrSearchActive && this.detail.isConstructionData
      ? this.detail.constructionData.items.map(i => i.id)
      : [];
  }

  private selectedSubcontractor: Subcontractor;
  private lastSelectedView: SelectedView;

  constructor(
    public buildingProgressSelectorsService: BuildingProgressSelectorsService,
    public buildingProgressTableService: BuildingProgressTableService,
    public subcontractorsDispatchersService: SubcontractorsDispatchersService,
    public subcontractorsSelectorsService: SubcontractorsSelectorsService,
    public detail: DetailHelper,
    public panelHelper: BuildingProgressPanelHelper,
    public permissionHelper: PermissionHelper,
    public licenseHelper: LicenseHelper,
    public exportHelper: ExportHelper,
    public tableChangeDetectionService: TableChangeDetectionService,
    protected periodHelper: PeriodHelper,
    protected visualHelper: VisualHelper,
    private activatedRoute: ActivatedRoute,
    private appInsightsService: AppInsightsBaseService,
    private budgetStructureService: BudgetStructureService,
    private buildingProgressActionAccessService: BuildingProgressActionAccessService,
    private buildingProgressDeleteItemsService: BuildingProgressDeleteItemsService,
    private buildingProgressDataSettingsDispatcher: BuildingProgressDataSettingsDispatchersService,
    private buildingProgressCommentsDispatchersService: BuildingProgressCommentsDispatchersService,
    private buildingProgressDispatchersService: BuildingProgressDispatchersService,
    private krosModalService: KrosModalService,
    private projectSelectorsService: ProjectsSelectorsService,
    private removeItemsService: RemoveItemsService,
    private viewsService: BuildingProgressViewsService
  ) {
    super(buildingProgressSelectorsService, detail, periodHelper, visualHelper, panelHelper);
  }

  ngOnInit(): void {
    this.buildingProgressDataSettingsDispatcher.loadDataSettings();

    this.subs.sink = this.projectSelectorsService.projectDetail$
      .pipe(filter(p => !!p))
      .subscribe(p => {
        this.detail.projectId = p.id;
        this.detail.projectName = p.name;
        this.exportHelper.setProjectData(p.name);
        this.buildingProgressDispatchersService.loadSettings(this.detail.projectId);
        this.loadData();
      });

    this.subs.sink = this.subcontractorsSelectorsService.selectedSubcontractor$
      .pipe(filter(s => !!s))
      .subscribe(subcontractor => {
        this.selectedSubcontractor = subcontractor;
        this.detail.contractorId = subcontractor.id;
        this.loadData();
        this.panelHelper.onCloseBoq();
        this.panelHelper.setContractorId(subcontractor.id);
      });

    this.subs.sink = this.buildingProgressSelectorsService.constructionData$
      .pipe(filter(p => p && p.projectId === this.detail.projectId))
      .subscribe(p => {
        this.setConstructionData(p);
        if (p.budgetItemId) {
          this.detail.setFocusToItemById(p.budgetItemId);
          this.detail.hierarchyCode = undefined;
        }
        this.detail.isMobileSelectionVisible = false;
      });

    this.subs.sink = this.budgetStructureService.setFocusToBudgetItemRequested$
      .pipe(filter(result => result.tableId === this.tableId))
      .subscribe(result => {
        this.detail.setFocusToItemById(result.id);
      });

    this.subs.sink = this.buildingProgressSelectorsService.periods$.pipe(filter(p => !!p && p.length > 0))
      .subscribe(p => {
        const markedPeriodIds = p.filter(p => p.isMarkedInFilter).map(p => p.id);
        this.detail.selectedPeriodsChanged(markedPeriodIds);
        this.buildingProgressTableService.setFilter(new Set<number>(markedPeriodIds));
        this.tableChangeDetectionService.forceTableChanges();
      });

    this.subs.sink = this.buildingProgressSelectorsService.periodDetails$.pipe(filter(d => !!d))
      .subscribe(details => {
        this.panelHelper.openDetails(details.itemId);
      });

    this.subs.sink = this.buildingProgressSelectorsService.hiddenColumns$.subscribe((hiddenColumns: string[]) => {
      this.buildingProgressTableService.hideColumns(new Set(hiddenColumns));
      this.tableChangeDetectionService.forceTableChanges();
    });
    this.subs.sink = this.buildingProgressSelectorsService.selectedView$.subscribe(selectedView => {
      if (selectedView && (selectedView.viewType === ViewType.colorCodes ||
        this.lastSelectedView?.viewType !== selectedView.viewType)) {
        this.lastSelectedView = selectedView;
        this.onViewChanged(this.lastSelectedView);
      }
    });
    this.subs.sink = this.buildingProgressSelectorsService.periods$.subscribe(p => {
      if (p && this.lastSelectedView && this.lastSelectedView.viewType !== ViewType.colorCodes) {
        const markedPeriodIds = p.filter(p => p.isMarkedInFilter).map(p => p.id);
        this.detail.selectedPeriodsChanged(markedPeriodIds);

        switch (this.lastSelectedView.viewType) {
          case ViewType.drawnSelected:
          case ViewType.undrawnSelected:
          case ViewType.withComment:
            this.detail.specialViewChanged(this.lastSelectedView);
            break;
          default:
            break;
        }
      }
    });

    this.buildingProgressTableService.init();
    this.panelHelper.init(this.detail.projectId, this.getItemIdByRowIndex.bind(this),
      this.showPanel.bind(this), this.hidePanel.bind(this), this.resizePanel.bind(this));
    this.periodHelper.init(this.lastPeriodId.bind(this));
  }

  ngOnDestroy(): void {
    this.detail.destroy();
    this.licenseHelper.destroy();
    this.permissionHelper.destroy();
    this.periodHelper.destroy();
    this.panelHelper.destroy();
    this.buildingProgressTableService.destroy();
    this.buildingProgressDispatchersService.clearMultiSelectItems();
    super.ngOnDestroy();
  }

  private loadData(): void {
    if (this.detail.projectId && this.detail.contractorId) {
      this.detail.focusedCell = undefined;
      this.detail.areConstructionDataLoaded = false;
      this.buildingProgressDispatchersService.clearMultiSelectItems();
      this.buildingProgressDispatchersService.loadConstructionData(
        this.detail.projectId,
        {
          filter: this.viewsService.currentView.filter,
          searchText: '',
          searchMode: 'all'
        },
        undefined,
        this.detail.contractorId);
      this.buildingProgressDispatchersService.loadPeriods(this.detail.projectId, false, this.detail.contractorId);
      this.buildingProgressDispatchersService.loadWholeBuildingPeriods(this.detail.projectId);
      this.subcontractorsDispatchersService.loadSubcontractorsPeriods(this.detail.projectId);
      this.loadCommentPositions();
    }
  }

  private setConstructionData(constructionData: BuildingProgressModel): void {
    if (!this.detail.dataTableConfig) {
      this.setDataTableConfig(constructionData);
    } else if (!this.periodHelper.arePeriodsEqual(this.detail.constructionData, constructionData)) {
      if (constructionData.periods.length === 0) {
        this.viewsService.setDefaultView();
      }
      this.setDataTableConfig(constructionData);
    } else {
      this.reloadItemOffsets(constructionData);
    }

    this.detail.constructionData = constructionData;

    if (!this.detail.areConstructionDataLoaded) {
      this.panelHelper.openPeriodsAndSelectPeriod(this.detail.constructionData, this.activatedRoute);
    }

    if (!!this.detail.refocusItemModel) {
      if (!!this.detail.constructionData && this.detail.constructionData.items?.length > 0) {
        this.detail.focusedCell = this.visualHelper.getFocusedCellFromRefocusModel(
          this.detail.refocusItemModel, this.detail.constructionData.items);
        if (this.detail.focusedCell) {
          this.visualHelper.scrollElementToView(this.detail.getFocusedCellElementId(), false);
          this.detail.cellFocusedCore(true, true);
        }
      } else {
        this.detail.focusedCell = undefined;
      }

      this.detail.refocusItemModel = undefined;
    }

    if (!this.detail.focusedCell && !!this.detail.constructionData && this.detail.constructionData.items?.length > 0) {
      this.detail.focusedCell = this.detail.getFocusedCell('description', 0);
    }

    if (!this.visualHelper.table?.editHelper.editingCell) {
      this.visualHelper.refocusFocusedCell();
    }

    this.detail.areConstructionDataLoaded = true;
  }

  private getItemIdByRowIndex(rowIndex: number): number {
    return (rowIndex >= 0) && (this.detail.constructionData.items.length > rowIndex)
      ? this.detail.constructionData.items[rowIndex].id
      : null;
  }

  private lastPeriodId(): number {
    return this.detail.constructionData.periods.length > 0
      ? this.detail.constructionData.periods[this.detail.constructionData.periods.length - 1].id
      : null;
  }

  private loadCommentPositions(): void {
    this.buildingProgressCommentsDispatchersService.loadCommentPositions(this.detail.projectId, this.detail.contractorId);
  }

  onCellFocused($event: any): void {
    this.detail.focusedCell = $event.cell;
    this.detail.cellFocusedCore(true, $event.cantLostFocus, this.detail.constructionData.items[$event.cell.rowIndex]);
  }

  onItemCheckChanged(item: BuildingProgressItem, state: boolean): void {
    this.detail.focusedCell = {
      ...this.detail.focusedCell,
      rowIndex: this.detail.constructionData.items.findIndex(i => i.id === item.id)
    };
    this.buildingProgressDispatchersService.multiSelectItem(item.id, state);
  }

  onHeaderCheckChanged(state: boolean): void {
    if (state) {
      this.buildingProgressDispatchersService.multiSelectAllitems();
    } else {
      this.buildingProgressDispatchersService.clearMultiSelectItems();
    }
  }

  onShareClick(): void {
    const modalRef = this.krosModalService.openCentered(
      SubcontractorsSharingDialogComponent,
      {
        projectId: this.detail.projectId,
        contractorId: this.selectedSubcontractor.id,
        contractorName: this.selectedSubcontractor.name
      },
      { closeOnBackdropClick: false }
    );

    this.subs.sink = modalRef.afterClosed$
      .subscribe(result => {
        if (result.data) {
          this.subcontractorsDispatchersService.loadSharingList(this.detail.projectId, this.selectedSubcontractor.id);
        }
      });
  }

  onAddItemsClick(): void {
    const modalRef = this.krosModalService.openCentered(
      SubcontractorsSelectItemsDialogComponent,
      { projectId: this.detail.projectId, contractorName: this.selectedSubcontractor.name },
      { closeOnBackdropClick: false },
      NaN,
      NaN,
      undefined,
      'no-max-width'
    );

    modalRef.afterClosed$.pipe(take(1)).subscribe(result => {
      if (result.type === 'submit') {
        this.subcontractorsDispatchersService.addSubcontractorItems(
          this.detail.projectId,
          this.detail.contractorId,
          result.data.selectedItemIds
        );
      }
      this.appInsightsService.trackEvent('PV-add-items-to-subcontractor');
    });
  }

  onRemoveItemsClick(): void {
    this.buildingProgressSelectorsService.selectedItemIds$.pipe(take(1))
      .subscribe(selectedIds => {
        const actualItem = this.detail.constructionData.items[this.detail.focusedCell.rowIndex];
        const ids = selectedIds && selectedIds.size > 0
          ? Array.from(selectedIds.values())
          : this.buildingProgressDeleteItemsService.getItemWithChildrenIds(actualItem, this.detail.constructionData.items);

        this.removeItemsService.openRemoveItemsQuestionDialog(
          ids,
          actualItem.itemType,
          actualItem.description,
          selectedIds && selectedIds.size > 0,
          this.detail.constructionData.periods,
          this.detail.constructionData.items,
          this.detail.projectId,
          this.detail.contractorId
        );
      });
  }

  onOpenTransferExecutionsClick(): void {
    this.buildingProgressDispatchersService.loadWholeBuildingPeriods(this.detail.projectId);
    const modalRef = this.krosModalService.openCentered(
      TransferExecutionsDialogComponent,
      {
        projectId: this.detail.projectId,
        contractorName: this.selectedSubcontractor.name,
        periods: this.detail.constructionData.periods,
        wholeBuildingPeriods$: this.buildingProgressSelectorsService.wholeBuildingPeriods$
      },
      { closeOnBackdropClick: false }
    );

    modalRef.afterClosed$.pipe(take(1)).subscribe(result => {
      if (result.type === 'submit') {
        if (this.buildingProgressActionAccessService.checkForAccessToTransferSubcontractorExecutions()) {
          this.subcontractorsDispatchersService.setSubcontractorsPeriods(
            this.detail.projectId,
            result.data.all ? null : this.selectedSubcontractor.id,
            result.data.dateFrom,
            result.data.dateTo,
            result.data.areDetailsIncluded,
            result.data.targetPeriod
          );
          this.appInsightsService.trackEvent('PV-transfer-executions');
        }
      }
    });
  }

  onOpenBoqClick(): void {
    this.panelHelper.openBoq(this.detail.projectId, this.detail.constructionData.items[this.detail.focusedCell.rowIndex]);
  }

  private setDataTableConfig(constructionData: BuildingProgressModel): void {
    this.detail.dataTableConfig = this.buildingProgressTableService.getTableConfig(
      this.detail.projectId,
      this.permissionHelper.permissionType,
      this.permissionHelper.buildingProgressPermissionType,
      constructionData,
      this.licenseHelper.isReadonlyLicense,
      this.detail.isViewOrSearchActive,
      this.licenseHelper.isTrialOrFullLicense,
      false
    );
  }

  private reloadItemOffsets(constructionData: BuildingProgressModel): void {
    const columnsMaxNumber = getColumnsMaxNumber(constructionData);
    this.buildingProgressTableService.averageLineHeight = columnsMaxNumber.averageLineHeight;
    this.detail.dataTableConfig.itemsOffset = columnsMaxNumber.itemsOffset;
  }

  onDashboardClick(): void {
    this.detail.currentNavigation = 'dashboard';
    this.panelHelper.onCloseBoq();
  }

  onOpenNotesClick(): void {
    this.panelHelper.openSubcontractorNotes(
      this.detail.projectId, this.detail.constructionData.items[this.detail.focusedCell.rowIndex]);
  }
}
