import { Injectable } from '@angular/core';

import { createFeatureSelector, createSelector, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { ProjectDetail, Tag } from '@kros-sk/ssw-shared-legacy';

import { ApplicationPermission } from '../../projects/models';
import { ProjectsState } from './projects.state';

const selectProjectsFeature = createFeatureSelector<ProjectsState>('projects');

const selectProjectDetail = createSelector(
  selectProjectsFeature,
  state => state.projectDetail
);

const selectApplicationPermissions = createSelector(
  selectProjectsFeature,
  state => state.applicationPermissions
);

const selectIsProjectMembersTabSelected = createSelector(
  selectProjectsFeature,
  state => state.isProjectMembersTabSelected
);

const selectTagsList = createSelector(
  selectProjectsFeature,
  state => state.tagsList
);

const selectNewTagCreated = createSelector(
  selectProjectsFeature,
  state => state.newTagCreated
);

@Injectable()
export class ProjectsSelectorsService {
  constructor(private store: Store<ProjectsState>) { }

  get projectDetail$(): Observable<ProjectDetail> {
    return this.store.select(selectProjectDetail);
  }

  get applicationPermissions$(): Observable<ApplicationPermission[]> {
    return this.store.select(selectApplicationPermissions);
  }

  get isProjectMembersTabSelected$(): Observable<boolean> {
    return this.store.select(selectIsProjectMembersTabSelected);
  }

  get tagsList$(): Observable<Tag[]> {
    return this.store.select(selectTagsList);
  }

  get newTagCreated$(): Observable<Tag> {
    return this.store.select(selectNewTagCreated);
  }
}
