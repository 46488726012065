import { createActionGroup, props } from '@ngrx/store';

import { BudgetItemPositionType, ChangedBudgetItems } from '@kros-sk/ssw-shared-legacy';
import {
  BuildingObject,
  BuildingObjectCreate,
  MoveBuildingObjectActionModel,
  MoveItemsPositions
} from '@kros-sk/ssw-budget/shared/data-access/models';

import { budgetSharedKey } from './budget-shared-actions';

export const buildingObjectsSharedActions = createActionGroup({
  source: budgetSharedKey,
  events: {
    'Load building objects start': props<{ preventForceFocus?: boolean }>(),
    'Load building objects success': props<{ objects: BuildingObject[], preventForceFocus?: boolean }>(),
    'Load building objects error': props<{ error: any }>(),

    'Create custom building object start': props<{ createData: BuildingObjectCreate; parentId: string; }>(),
    'Create custom building object success': props<{ changedBudgetItems: ChangedBudgetItems }>(),
    'Create custom building object error': props<{ error?: any }>(),

    'Select building object in budget after create': props<{ buildingObject: BuildingObject, parentIds: string[] }>(),

    'Move building objects start': props<{
      currentItem: BuildingObject,
      positionType: BudgetItemPositionType,
      item: BuildingObject,
      buildingObjects: BuildingObject[],
      isUndoRedoOperation?: boolean,
      isUndo?: boolean
    }>(),
    'Move building objects success': props<{
      changedBudgetItems: ChangedBudgetItems,
      sourceDataPositions: MoveItemsPositions<BuildingObject>,
      storedAction: MoveBuildingObjectActionModel,
      isUndoRedoOperation?: boolean,
      isUndo: boolean,
      buildingObjects: BuildingObject[]
    }>(),
    'Move building objects error': props<{ error: any }>(),

    'Delete building object start': props<{ itemId: number, newId?: string }>(),
    'Delete building object success': props<{ budgetId: number, changedBudgetItems: ChangedBudgetItems, newId: string }>(),
    'Delete building object error': props<{ error: any }>(),
  }
});
