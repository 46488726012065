import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { APP_CONFIG } from '@kros-sk/app-config';
import { ApplicationType, License, LicenseType } from '@kros-sk/ssw-cdk';

import { ApplicationModule, ApplicationModuleIdProviderService } from '../application-module';

@Injectable()
export class LicenseUrlService {

  private appProjectModule: ApplicationModule;

  get moreInfoUrl(): string {
    return this.isCzVersion
      ? this.appProjectModule === ApplicationModule.QuantityManager
        ? 'https://app.urs.cz/products/vymery'
        : 'https://www.urs.cz/spv'
      : this.skMoreInfoUrl;
  }

  get appPrefix(): string {
    switch (this.appProjectModule) {
      case ApplicationModule.QuantityManager:
        return 'QM';
      case ApplicationModule.Budget:
        return 'B';
      case ApplicationModule.Misha:
        return 'MI';
      default:
        return 'PV';
    }
  }

  get contactUs(): string {
    const email = this.isCzVersion
      ? 'info@urs.cz'
      : 'stavebneriesenie@kros.sk';
    return this.getContactUsElement(email);
  }

  get contactsUrl(): string {
    return this.isCzVersion
      ? 'https://www.urs.cz/kontakty'
      : 'https://www.kros.sk/kontakt/cenkros/';
  }

  get contactUsStavario(): string {
    return this.getContactUsElement('stavario@kros.sk');
  }

  get contactUsBuildary(): string {
    return this.getContactUsElement('buildary@kros.sk');
  }

  get krosCompanyPrefix(): string {
    return this.isCzVersion
      ? ''
      : 'KROS ';
  }

  get isCzVersion(): boolean {
    return this.appConfig.location === 'cz';
  }

  get skMoreInfoStavarioUrl(): string {
    return 'https://www.kros.sk/stavebny-dennik/stavario/';
  }

  get skMoreInfoBuildaryUrl(): string {
    return 'https://www.kros.sk/stavebny-dennik/buildary/';
  }

  get skMoreInfoBuyWithK4Url(): string {
    return 'https://www.kros.sk/cenkros/';
  }

  private get skMoreInfoUrl(): string {
    switch (this.appProjectModule) {
      case ApplicationModule.QuantityManager:
        return 'https://www.kros.sk/vymery/';
      case ApplicationModule.Budget:
        return 'https://www.kros.sk/stavebny-rozpocet/';
      case ApplicationModule.Misha:
        return 'https://www.kros.sk/cennikova-databaza/';
      default:
        return 'https://www.kros.sk/priebeh-vystavby/';
    }
  }

  private appConfig = inject(APP_CONFIG);
  private applicationModuleIdProviderService = inject(ApplicationModuleIdProviderService);

  constructor() {
    this.applicationModuleIdProviderService.applicationModuleId$.pipe(takeUntilDestroyed())
      .subscribe(appProjectModule => this.appProjectModule = appProjectModule);
  }

  orderUrl(existValid: boolean, license?: License, licenseNumber?: string, budgetLicense?: License, desktopLicense?: License): string {
    return this.isCzVersion ? this.getCzOrderUrl() : this.getSkOrderUrl(existValid, license, licenseNumber, budgetLicense, desktopLicense);
  }

  orderConstructionDiaryUrl(product: ApplicationType, license?: License): string {
    switch (product) {
      case ApplicationType.Stavario:
        if (license && license.licenseType === LicenseType.Readonly) {
          return `${this.appConfig.eShopUrl}/cart?product=33&type=23&action=login`;
        }
        return this.skMoreInfoStavarioUrl;
      case ApplicationType.Buildary:
        return this.skMoreInfoBuildaryUrl;
    }
  }

  getBuildingProgressUrl(existValid: boolean, budgetLicense?: License, licenseNumber?: string, desktopLicense?: License): string {
    if ((this.isValidLicense(budgetLicense) || this.isValidLicense(desktopLicense)) && licenseNumber) {
      return this.getExpandUrl(licenseNumber);
    }
    return this.showRenewUrl(budgetLicense, licenseNumber) || this.showRenewUrl(desktopLicense, licenseNumber)
      ? this.getRenewUrl(licenseNumber)
      : existValid
        ? this.getExpandUrl(licenseNumber)
        : 'https://www.kros.sk/cenkros/cennik/';
  }

  getContactsUrlElement(text: string): string {
    return `<a target="_blank" href="${this.contactsUrl}"><u>${text}</u></a>`;
  }

  private getCzOrderUrl(): string {
    return this.appProjectModule === ApplicationModule.QuantityManager
      ? 'https://app.urs.cz/products/vymery'
      : 'https://www.urs.cz';
  }

  private getSkOrderUrl(
    existValid: boolean,
    license?: License,
    licenseNumber?: string,
    budgetLicense?: License,
    desktopLicense?: License): string {
    switch (this.appProjectModule) {
      case ApplicationModule.Budget:
        return this.getBudgetOrderUrl(existValid, license, licenseNumber);
      case ApplicationModule.QuantityManager:
        return this.getQuantityManagerOrderUrl(existValid, license, licenseNumber);
      case ApplicationModule.BuildingProgress:
      case ApplicationModule.BudgetApproval:
        return this.getBuildingProgressUrl(existValid, budgetLicense, licenseNumber, desktopLicense);
      default:
        return 'https://www.kros.sk/cenkros/cennik/';
    }
  }

  private getQuantityManagerOrderUrl(existValid: boolean, license?: License, licenseNumber?: string): string {
    if (this.isValidLicense(license) && licenseNumber) {
      return this.getExpandUrl(licenseNumber);
    }
    return this.showRenewUrl(license, licenseNumber)
      ? this.getRenewUrl(licenseNumber)
      : existValid
        ? this.getExpandUrl(licenseNumber)
        : this.getOrderUrl('package=-2&dbSetType=1&measurement=1');
  }

  private getBudgetOrderUrl(existValid: boolean, license?: License, licenseNumber?: string): string {
    if (this.isValidLicense(license) && licenseNumber) {
      return this.getExpandUrl(licenseNumber);
    }
    return this.showRenewUrl(license, licenseNumber)
      ? this.getRenewUrl(licenseNumber)
      : existValid
        ? this.getExpandUrl(licenseNumber)
        : this.getOrderUrl('package=-3&dbSetType=1');
  }

  private getRenewUrl(licenseNumber?: string): string {
    return `${this.appConfig.eShopUrl}/cart?product=6&type=23&licenseNumber=${licenseNumber}&action=login`;
  }

  private getOrderUrl(packageUrl: string): string {
    return `${this.appConfig.eShopUrl}/cart?product=6&purchaseType=2&${packageUrl}`;
  }

  private getExpandUrl(licenseNumber?: string): string {
    return `${this.appConfig.eShopUrl}/cart?product=6&type=31&licenseNumber=${licenseNumber}&action=login`;
  }

  private getContactUsElement(email: string): string {
    return `<a class="contact-us" href="mailTo:${email}">${email}</a>`;
  }

  private isValidLicense(license?: License): boolean {
    return license && license.licenseType === LicenseType.Full && license.remainingDays > 30;
  }

  private showRenewUrl(license?: License, licenseNumber?: string): boolean {
    return license &&
      licenseNumber &&
      (license.licenseType === LicenseType.Readonly ||
        (license.licenseType === LicenseType.Full && license.remainingDays < 30));
  }
}
