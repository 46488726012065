import { emptyPartner } from '../partners';
import { KrosCompany } from './company.model';
import { OssTaxState, VatPayerType } from '../base';

export function emptyCompany(): KrosCompany {
  const company = {
    ...emptyPartner(),
    vatPayerType: VatPayerType.NonPayer,
    ossTaxState: OssTaxState.NonRegistered,
    registrationCourtTextTranslations: { ['sk-SK']: ''},
    isDefault: false,
  };
  delete company.isFree;
  return company as KrosCompany;
}
