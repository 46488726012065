<div class="toolbar-actions">
  <div class="actions left">
    <ng-container *ngIf="selectedItemsCount > 0 else defaultToolbar">
      <span class="selected-items">{{ 'COMMON.SELECTED_ITEMS' | translate }}({{selectedItemsCount}}):</span>

      <button
        *ngIf="canEditSubcontractors && !isSummaryView"
        class="btn toolbar-action no-selection gray-toolbar-action ga-subcontractors-remove-items"
        type="button"
        krosTooltip="{{ 'SUBCONTRACTORS.REMOVE_FROM_SUBCONTRACTOR' | translate }}"
        data-test="subcontractors-remove-items"
        (click)="removeItems()"
      >
        <i class="material-icons icon-button ga-subcontractors-remove-items">remove_circle_outline</i>
        <span class="toolbar-action-text">{{ 'SUBCONTRACTORS.REMOVE_FROM_SUBCONTRACTOR' | translate }}</span>
      </button>
    </ng-container>

    <ng-template #defaultToolbar>
      <div
        *ngIf="selectorsService.selectedSubcontractor$ | async"
        class="selected-subcontractor"
      >
        <div
          class="name"
          krosTooltip="{{(selectorsService.selectedSubcontractor$ | async)?.name}}"
          data-test="selected-subcontractor-name"
        >
          {{(selectorsService.selectedSubcontractor$ | async)?.name}}
        </div>
      </div>

      <button
        *ngIf="hasItems"
        class="btn toolbar-action no-selection gray-toolbar-action ga-subcontractors-periods"
        type="button"
        krosTooltip="{{ 'SUBCONTRACTORS.PERIODS' | translate }}"
        data-test="subcontractors-periods"
        (click)="openPeriods()"
      >
        <i class="material-icons icon-button ga-subcontractors-periods">fact_check</i>
        <span class="toolbar-action-text">{{ 'SUBCONTRACTORS.PERIODS' | translate }}</span>
      </button>

      <button
        *ngIf="canOpenBoq && !isSummaryView"
        class="btn toolbar-action no-selection gray-toolbar-action ga-open-boq-panel"
        type="button"
        (click)="openBoq()"
        krosTooltip="{{ 'BUILDING_PROGRESS.BILL_OF_QUANTITIES' | translate }}"
        data-test="building-progress-open-boq-panel"
      >
        <i class="material-icons icon-button  ga-open-boq-panel">square_foot</i>
        <span class="toolbar-action-text">{{ 'BUILDING_PROGRESS.BILL_OF_QUANTITIES' | translate }}</span>
      </button>

      <button
        *ngIf="canEditSubcontractors && !isSummaryView"
        class="btn toolbar-action no-selection gray-toolbar-action dropdown-action"
      >
        <div
          ngbDropdown
          container="body"
        >
          <button
            ngbDropdownToggle
            class="btn dropdown-toggle"
            type="button"
            data-test="subcontractor-items-dropdown"
          >
            <div
              class="dropdown-toggle-content"
              krosTooltip="{{ 'SUBCONTRACTORS.ITEMS' | translate }}"
            >
              <i class="material-icons-outlined icon-button">table_rows</i>
              <span class="toolbar-action-text">{{ 'SUBCONTRACTORS.ITEMS' | translate }}</span>
              <i class="material-icons navbar-mat-icon dropdown-toggle-icon">expand_more</i>
            </div>
          </button>

          <div
            ngbDropdownMenu
            aria-labelledby="permissionDropdown"
            display="dynamic"
          >
            <button
              ngbDropdownItem
              type="button"
              (click)="addItems()"
              data-test="subcontractor-add-items"
            >
              <i class="material-icons">add_circle_outline</i>
              <span>{{ 'SUBCONTRACTORS.ADD_ITEMS' | translate }}</span>
            </button>

            <button
              *ngIf="hasItems"
              ngbDropdownItem
              type="button"
              (click)="removeItems()"
              data-test="subcontractor-remove-items"
            >
              <i class="material-icons">remove_circle_outline</i>
              <span>{{ 'SUBCONTRACTORS.REMOVE_ITEMS' | translate }}</span>
            </button>
          </div>
        </div>
      </button>

      <button
        *ngIf="hasItems && isOwnerOrContributor"
        class="btn toolbar-action no-selection gray-toolbar-action ga-transfer-subcontractor-executions"
        type="button"
        krosTooltip="{{ 'SUBCONTRACTORS.TRANSFER_EXECUTIONS' | translate }}"
        data-test="transfer-subcontractor-executions"
        (click)="openTransferExecutions()"
      >
        <i class="material-icons icon-button ga-transfer-subcontractor-executions">swap_vert</i>
        <span class="toolbar-action-text">{{ 'SUBCONTRACTORS.TRANSFER_EXECUTIONS' | translate }}</span>
      </button>

      <app-building-progress-export-dropdown
        *ngIf="(canShowExport || canShowExportTable) && isOwnerOrContributor && detail.areConstructionDataLoaded"
        [canShowExport]="canShowExport"
        [canShowExportTable]="canShowExportTable"
        (exportInvoiceDetails)="exportInvoiceDetails()"
        (exportToExcel)="exportToExcel()"
        (exportAmountsUniXml)="exportAmountsUniXml()"
        (exportRemainingBudget)="exportRemainingBudget()"
      ></app-building-progress-export-dropdown>

      <button
        *ngIf="detail.areConstructionDataLoaded && !detail.isViewOrSearchActive"
        class="btn toolbar-action no-selection gray-toolbar-action"
        type="button"
        (click)="dashboardClick.emit()"
        krosTooltip="{{ 'BUILDING_PROGRESS.DASHBOARD.DASHBOARD' | translate }}"
        data-test="building-progress-subcontractors-dashboard"
      >
        <i class="material-icons icon-button ga-dashboard">toll</i>
        <span class="toolbar-action-text">{{ 'BUILDING_PROGRESS.DASHBOARD.DASHBOARD' | translate }}</span>
      </button>

      <button
        class="btn toolbar-action no-selection gray-toolbar-action"
        type="button"
        (click)="openNotesClick.emit()"
        krosTooltip="{{ 'NOTES.NOTE' | translate }}"
        data-test="building-progress-subcontractors-notes"
      >
        <i class="material-icons icon-button">sticky_note_2</i>
        <span class="toolbar-action-text">{{ 'NOTES.NOTE' | translate }}</span>
      </button>
    </ng-template>
  </div>

  <div></div>

  <div class="actions right">

    <button
      *ngIf="canShare"
      class="btn toolbar-action no-selection gray-toolbar-action ga-subcontractors-share"
      type="button"
      krosTooltip="{{ 'BUILDING_PROGRESS.SHARE' | translate }}"
      data-test="subcontractors-share"
      (click)="share()"
    >
      <i class="material-icons icon-button ga-subcontractors-share">person_add</i>
    </button>

    <ng-container *ngIf="notOwnerUsers.length > 0 && !permissionHelper.isContractor">
      <kros-avatar-list
        class="avatars"
        [ownerMail]="ownerMail"
        [notOwnerUsers]="notOwnerUsers"
        [maxAvatarsLimit]="2"
        (click)="share()"
        data-test="sharing-avatars"
      ></kros-avatar-list>
    </ng-container>

    <button
      id="comment-period"
      class="btn toolbar-action no-selection gray-toolbar-action"
      type="button"
      krosTooltip="{{ 'BUILDING_PROGRESS.OPEN_COMMENTS' | translate }}"
      (click)="openComments()"
      data-test="subcontractors-show-comment-button"
    >
      <i class="material-icons icon-button">comment</i>
    </button>
  </div>
</div>
