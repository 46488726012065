import { CdkMenuItem, CdkMenuItemRadio } from '@angular/cdk/menu';
import { Component } from '@angular/core';
import { InputModalityDetector } from '@angular/cdk/a11y';
import { Platform } from '@angular/cdk/platform';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[kros-dropdown-item-radio]',
  exportAs: 'krosDropdownItemRadio',
  template: '<ng-content></ng-content>',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.kros-dropdown-item]': 'true',
    '[class.kros-dropdown-item-radio]': 'true',
    '[class.kros-dropdown-item-checked]': '!!checked',
    '[attr.disabled]': 'disabled || null',
  },
  providers: [{ provide: CdkMenuItem, useExisting: KrosDropdownItemRadioComponent }],
})
export class KrosDropdownItemRadioComponent extends CdkMenuItemRadio {

  constructor(
    private platform: Platform,
    private inputModalityDetector: InputModalityDetector,
  ) {
    super();
  }
  /** Place focus on the element. */
  focus(): void {
    // Fix for safari, where it's not possible to click on scrolled item
    // because it's focused as it's selected by keyboard.
    if (this.inputModalityDetector.mostRecentModality === 'keyboard') {
      return this._elementRef.nativeElement.focus();
    }
    if (!this.platform.SAFARI) {
      this._elementRef.nativeElement.focus();
    }
  }
}
