import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import {
  ApplicationLicense,
  ApplicationType,
  errorLicenseState,
  License,
  LicenseApiModel,
  LicenseProblem,
  LicensesState,
  RecommendedAction,
  UnassignedLicenseApiModel,
  UsedCompaniesInLicenseApiModel,
} from '@kros-sk/models';
import { createHttpRetryErrorForLog, httpRetryAndLog, HttpRetryResult, KrosAppInsightsService } from '@kros-sk/core';

import { APPLICATION_TYPE_CONFIG } from '../../kros-auth.config';
import { HandleMethodErrors } from '../../../shared/decorators/handle-errors/handle-errors-decorator';
import { RequestsWithErrorHandling } from '../../../shared/request-with-error-handling/requests-with-error-handling.service';

@Injectable()
export class LicensesService extends RequestsWithErrorHandling {

  constructor(
    protected http: HttpClient,
    private appInsightsService: KrosAppInsightsService,
    @Inject(APP_CONFIG) private readonly appConfig: any,
    @Inject(APPLICATION_TYPE_CONFIG) private applicationType: ApplicationType,
  ) {
    super(http);
  }

  private static createLicence(appLicense: ApplicationLicense, apiLicense: LicenseApiModel): License {
    return {
      applicationType: appLicense.applicationType,
      type: appLicense.package,
      daysValid: appLicense.daysValid,
      modules: appLicense.modules,
      validUntil: appLicense.validUntil,
      autoRenew: apiLicense.autoRenew,
      nextPaymentDate: apiLicense.nextPaymentDate,
      customerNumber: apiLicense.customerNumber,
      trialExists: appLicense.trialExists,
      isAcademicLicense: apiLicense.isAcademicLicense,
    };
  }

  @HandleMethodErrors({ status: ['401', '402', '500', '502', '503', '504'] })
  loadCompanyLicense(companyId: number): Observable<LicenseApiModel> {
    return this.httpRequest<LicenseApiModel>(
      'get',
      this.getLicensesApiUrl(companyId)
    ).pipe(
      httpRetryAndLog({
        retryOnStatus: /^(500|502|503|504)$/,
        retryDelays: [300, 600, 1200],
        loggerFn: this.logHttpRetryException('loadCompanyLicense', 'GET')
      }),
    );
  }

  assignLicense(companyId: number, customerNumber: string): Observable<LicenseApiModel> {
    return this.httpRequest<LicenseApiModel>(
      'post',
      this.getLicensesApiUrl(companyId),
      { customerNumber }
    );
  }

  startTrial(companyId: number): Observable<LicenseApiModel> {
    return this.httpRequest<LicenseApiModel>(
      'post',
      `${this.getLicensesApiUrl(companyId)}/activateTrial`,
    );
  }

  @HandleMethodErrors({ status: ['500', '502', '503', '504'] })
  getUsedCompaniesForLicense(companyId: number): Observable<UsedCompaniesInLicenseApiModel> {
    return this.httpRequest<UsedCompaniesInLicenseApiModel>(
      'get',
      `${this.getLicensesApiUrl(companyId)}/usedCompanies`
    ).pipe(
      httpRetryAndLog({
        retryOnStatus: /^(500|502|503|504)$/,
        retryDelays: [300, 600, 1200],
        loggerFn: this.logHttpRetryException('getUsedCompaniesForLicense', 'GET')
      }),
    );
  }

  removeCompaniesFromLicense(
    companyId: number,
    companyIdsToRemove: number[],
    currentlyUsedCompanies: number[],
    customerNumber: string): Observable<LicenseApiModel> {
    return this.httpRequest<LicenseApiModel>(
      'post',
      `${this.getLicensesApiUrl(companyId)}/usedCompanies`,
      {customerNumber, companiesToRemove: companyIdsToRemove, currentlyUsedCompanies}
    );
  }

  @HandleMethodErrors({ status: ['401', '500', '502', '503', '504'] })
  getUnassignedLicenses(companyId: number): Observable<UnassignedLicenseApiModel[]> {
    return this.httpRequest<UnassignedLicenseApiModel[]>(
      'get',
      this.getLicensesApiUrl(companyId) + '/unassigned'
    ).pipe(
      httpRetryAndLog({
        retryOnStatus: /^(500|502|503|504)$/,
        retryDelays: [300, 600, 1200],
        loggerFn: this.logHttpRetryException('getUnassignedLicenses', 'GET')
      }),
    );
  }

  mapApiLicense(apiLicense: LicenseApiModel): LicensesState {
    const allLicenses = this.getAllApplicationLicenses(apiLicense);
    const currentLicense = this.getCurrentLicense(allLicenses, this.applicationType);
    return {
      currentLicense: {
        ...currentLicense,
        usedCompanyIds: apiLicense.usedCompanyIds ?? [],
        problemCode: apiLicense.problemCode
      },
      allLicenses,
      userCount: apiLicense.userCount,
      licensesResponseStatus: HttpStatusCode.Ok,
    } as LicensesState;
  }

  getAllApplicationLicenses(apiLicense: LicenseApiModel): License[] {
    return apiLicense.applicationLicenses.map(appLicense => LicensesService.createLicence(appLicense, apiLicense));
  }

  getCurrentLicense(allLicenses: License[], applicationType: ApplicationType): License {
    return allLicenses.find(lic => lic.applicationType === applicationType);
  }

  createErrorLicenseIfNeeded(err: any): LicensesState {
    if (err instanceof HttpErrorResponse && err.status === 402) {
      if (err.error?.problemCode === LicenseProblem.CompanyCountExceeded) {
        return this.createErrorLicenseForExceededCompaniesCount(err);
      } else {
        return this.createErrorLicenseForExceededUserCount(err);
      }
    }
    return null;
  }

  private createErrorLicenseForExceededUserCount(error: HttpErrorResponse): LicensesState {
    const state = this.getErrorLicenseState(error.status);
    state.userCount = {
      licenseLimit: error.error?.licenseLimit ?? 0,
      accountantsCount: error.error?.accountantsCount ?? 0,
      otherUsersCount: error.error?.otherUsersCount ?? 0,
      ownerEmail: error.error?.ownerEmail ?? '',
      recommendedAction: RecommendedAction.CountExceededUser
    };
    return state;
  }

  private createErrorLicenseForExceededCompaniesCount(error: HttpErrorResponse): LicensesState {
    const state = this.getErrorLicenseState(error.status);
    return {
      ...state,
      currentLicense: {...state.currentLicense, problemCode: error.error.problemCode},
      userCount: {
        licenseLimit: 0,
        accountantsCount: 0,
        otherUsersCount: 0,
        ownerEmail: error.error.ownerEmail,
        recommendedAction: RecommendedAction.NoAction
      }
    };
  }

  private getErrorLicenseState(errStatus: HttpStatusCode): LicensesState {
    const state: LicensesState = errorLicenseState();
    state.currentLicense.applicationType = +this.applicationType;
    state.allLicenses[0].applicationType = +this.applicationType;
    state.licensesResponseStatus = errStatus;
    return state;
  }

  private getLicensesApiUrl(companyId: number): string {
    return `${this.appConfig.gatewayApiUrl}/companies/${companyId}/licenses`;
  }

  private logHttpRetryException = (apiMethod: string, httpRequestMethod: string) => {
    return (log: HttpRetryResult): void => {
      const handler = 'LicensesService';
      this.appInsightsService.trackException(createHttpRetryErrorForLog(handler, apiMethod, httpRequestMethod), handler, log);
    };
  };
}
