import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { LetDirective } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';

import { KrosFormFieldModule } from '../kros-inputs/kros-form-field/kros-form-field.module';
import { PipesModule } from '../pipes';
import { SmsActivationModalComponent } from './sms-activation-modal/sms-activation-modal.component';
import { SmsActivationService } from './service/sms-activation.service';

const DECLARATIONS = [
  SmsActivationModalComponent
];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LetDirective,
    TranslateModule,
    KrosFormFieldModule,
    PipesModule
  ],
  exports: [...DECLARATIONS]
})
export class LicenseActivationModule {
  public static forRoot(): ModuleWithProviders<LicenseActivationModule> {
    return {
      ngModule: LicenseActivationModule,
      providers: [
        SmsActivationService,
      ]
    };
  }
}
