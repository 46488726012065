import { ActionCreator, createFeature, createReducer, on, ReducerTypes } from '@ngrx/store';

import { permissionActions } from './permission.actions';
import { permissionFeatureKey, permissionInitialState, PermissionState } from './permission.state';

const reducers: ReducerTypes<PermissionState, ActionCreator[]>[] = [
  on(permissionActions.loadBuildingProgressPermissionSuccess, (state: PermissionState, payload): PermissionState => ({
    ...state,
    buildingProgressPermission: payload.buildingProgressPermission
  })),
  on(permissionActions.loadBudgetPermissionSuccess, (state: PermissionState, payload): PermissionState => ({
    ...state,
    budgetPermission: payload.budgetPermission
  }))
];

const reducer = createReducer(permissionInitialState, ...reducers);

export const permissionFeatureState = createFeature({
  name: permissionFeatureKey,
  reducer
});
