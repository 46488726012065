import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { catchError, map } from 'rxjs/operators';
import { combineLatest, Observable, of, Subject } from 'rxjs';

import {
  AnalyticsService,
  isOwnersEmail,
  ItemSharing,
  NewSharingComboMode,
  SharingActionType,
  SharingPermissionValues,
  ToastService,
  ToastType,
  TranslateService,
  UserSharingHelpersService,
  UserSharingService,
} from '@kros-sk/ssw-shared-legacy';
import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { AuthSelectorsService } from '@kros-sk/auth';
import { BuildingProgressPermissionType, PermissionType } from '@kros-sk/ssw-shared/permission';
import { KrosModalRef } from '@kros-sk/components';

import { BuildingProgressDispatchersService, BuildingProgressSelectorsService } from '../../../../store/building-progress';
import {
  BuildingProgressSharingActionViewModel
} from '../../../../building-progress/models/building-progress-sharing/building-progress-sharing-action.view-model';
import { ProjectsSelectorsService } from '../../../../store/projects';
import { SubcontractorsDispatchersService, SubcontractorsSelectorsService } from '../../../../store/subcontractors';
import { SubcontractorsService } from '../services/subcontractors.service';
import { SubcontractorsSharingViewModel } from '../models';

@Component({
  selector: 'app-subcontractors-sharing-dialog',
  templateUrl: './subcontractors-sharing-dialog.component.html'
})
export class SubcontractorsSharingDialogComponent implements OnInit {
  readonly: boolean;
  sharings: ItemSharing[] = [];
  loadedItems: boolean;
  editItems: any[];
  otherAlreadySharedEmails: string[] = [];
  unconfirmedSharings: ItemSharing[] = [];
  needRefresh: boolean;
  title: string;
  dataTestPrefix = 'subcontractors';
  sharingMode = NewSharingComboMode.AddUserContractors;
  defaultPermissions: SharingPermissionValues = {
    permission: PermissionType.Shared,
    additionalPermission: BuildingProgressPermissionType.ContractorFull,
  };
  shareNewPermissionsFunc = this.shareNewPermissions.bind(this);
  submitEditedPermissionsFunc = this.submitEditedPermissions.bind(this);
  getSharingFunc = this.getSharing.bind(this);

  get initialCompactMode(): boolean {
    return this.readonly ? false : this.sharings?.length === 1;
  }

  protected authSelectorService = inject(AuthSelectorsService);
  protected projectsSelectorService = inject(ProjectsSelectorsService);
  protected buildingProgressDispatchersService = inject(BuildingProgressDispatchersService);
  protected buildingProgressSelectorsService = inject(BuildingProgressSelectorsService);
  protected sharingHelperService = inject(UserSharingHelpersService);
  protected userSharingService = inject(UserSharingService);
  protected toastService = inject(ToastService);
  protected appInsightsService = inject(AppInsightsBaseService);
  protected analyticsService = inject(AnalyticsService);
  protected modalRef = inject(KrosModalRef);

  private dispatchersService = inject(SubcontractorsDispatchersService);
  private selectorsService = inject(SubcontractorsSelectorsService);
  private subcontractorsService = inject(SubcontractorsService);
  private translateService = inject(TranslateService);
  private destroyRef = inject(DestroyRef);
  private contractorId: number;
  private projectId: number;

  ngOnInit(): void {
    this.projectId = this.modalRef.data.projectId;

    this.projectsSelectorService.projectDetail$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(detail => this.readonly = !isOwnersEmail(detail.owner));

    this.contractorId = this.modalRef.data.contractorId;
    this.title = this.translateService.translate('BUILDING_PROGRESS.SHARING') + ' ' +
      this.translateService.translate('COMMON.FOR') + ' ' + this.modalRef.data.contractorName;

    this.dispatchersService.loadSharingList(this.projectId, this.contractorId);
    this.dispatchersService.loadSubcontractorsSharingList(this.projectId);
    this.buildingProgressDispatchersService.loadSharingList(this.projectId);

    this.selectorsService.sharingList$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(sharingList => this.afterSharingsLoad(sharingList));

    combineLatest([this.selectorsService.subcontractorsSharingList$, this.buildingProgressSelectorsService.sharingList$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([subcontractorsSharingList, sharingList]) =>
        this.otherAlreadySharedEmails = subcontractorsSharingList.concat(sharingList).map(sharing => sharing.email)
      );
  }

  onUnconfirmedSharingChange(unconfirmedSharings): void {
    this.unconfirmedSharings = unconfirmedSharings;
  }

  onSubmitSharing(result: boolean): void {
    this.modalRef.submit(result);
  }

  private shareNewPermissions(sharings: ItemSharing[]): Observable<void> {
    const sharedNewPermissions = new Subject<void>();

    if (sharings.length > 0) {
      const viewModel: SubcontractorsSharingViewModel = {
        projectId: this.projectId,
        sharingActions: this.userSharingService.createUserAddViewModels(sharings, this.sharings)
          .map(p => {
            const ret = p as BuildingProgressSharingActionViewModel;
            ret.buildingProgressPermissionType = ret.additionalPermissionType;
            return ret;
          }),
        contractorId: this.contractorId
      };

      this.sendAnalyticsNotification(viewModel);

      if (viewModel.sharingActions.length > 0) {
        this.subcontractorsService.share(this.projectId, viewModel)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: () => {
              this.sharings = this.unconfirmedSharings.map(s => this.getSharing(s))
                .concat(this.sharings);
              this.unconfirmedSharings = [];
              this.needRefresh = viewModel.sharingActions.filter(sa => sa.actionType !== SharingActionType.Edit).length > 0;
            }, error: (err) => {
              this.toastService.open(err.message, ToastType.Error);
            }, complete: () => sharedNewPermissions.next()
          });
      } else {
        sharedNewPermissions.next();
      }
    }

    return sharedNewPermissions;
  }

  private submitEditedPermissions(): Observable<boolean> {
    this.editItems = this.userSharingService.filterChanges(this.editItems);

    if (this.editItems.length > 0) {
      const viewModel: SubcontractorsSharingViewModel = {
        projectId: this.projectId,
        sharingActions: this.userSharingService.createUserEditViewModels(this.editItems)
          .map(p => {
            const ret = p as BuildingProgressSharingActionViewModel;
            ret.buildingProgressPermissionType = ret.additionalPermissionType;
            return ret;
          }),
        contractorId: this.contractorId
      };

      this.sendAnalyticsNotification(viewModel);
      return this.subcontractorsService.share(this.projectId, viewModel).pipe(
        map(() => {
          this.userSharingService.deleteLocalShareItems(this.sharings, this.editItems);
          this.sharings = [...this.sharings];
          this.editItems = [];
          this.sharingHelperService.cancelChanges(false);
          this.needRefresh = viewModel.sharingActions.length > 0;
          return true;
        }),
        catchError((err) => {
          this.toastService.open(err.message, ToastType.Error);
          return of(false);
        })
      );
    } else {
      return of(true);
    }
  }

  private getSharing(unconfirmedSharing: ItemSharing): any {
    return {
      email: unconfirmedSharing.email,
      isRegisteredUser: unconfirmedSharing.isRegisteredUser,
      permissionType: unconfirmedSharing.permissionType,
      additionalPermissionType: unconfirmedSharing.additionalPermissionType,
      buildingProgressPermissionType: unconfirmedSharing.additionalPermissionType,
      userMessage: unconfirmedSharing.userMessage
    };
  }

  private sendAnalyticsNotification(model: SubcontractorsSharingViewModel): void {
    this.sendAnalyticsNotificationCore(model, SharingActionType.Add, 'Zdielanie, pridať práva');
    this.sendAnalyticsNotificationCore(model, SharingActionType.Edit, 'Zdielanie, upraviť práva');
    this.sendAnalyticsNotificationCore(model, SharingActionType.Delete, 'Zdielanie, zmazať práva');
  }

  private sendAnalyticsNotificationCore(
    model: SubcontractorsSharingViewModel,
    actionType: SharingActionType,
    eventAction: string): void {
    if (model.sharingActions.some(e => e.actionType === actionType)) {
      const count = model.sharingActions.filter(e => e.actionType === actionType).length;

      this.appInsightsService.trackEvent('PV-shared-to-subcontractor', { count: count.toString() });
      this.analyticsService.raiseEvent('Dodávatelia', eventAction,
        'Počet: ' + count.toString());
    }
  }

  private afterSharingsLoad(sharingList: ItemSharing[]): void {
    this.loadedItems = true;
    this.editItems = [];
    this.sharings = JSON.parse(JSON.stringify(sharingList));
  }
}
