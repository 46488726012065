import { YearsFilter } from '../components/year-filter.model';

export interface SearchParamsBase {
  fulltext: string;
}

/** @deprecated Because YearsFilter is deprecated. */
export interface SearchParams extends SearchParamsBase {
  year: YearsFilter;
}

export interface PageApi<T> {
  PageNumber: number;
  PageSize: number;
  TotalPages: number;
  TotalCount: number;
  Results: T[];
}

export interface SearchPage<T> extends PageInfo {
  items: T[];
}

export interface PaginatedSearchParams {
  page: number;
  pageSize: number;
}

export interface PageInfo {
  pageSize: number;
  pageNumber: number;
  totalPages: number;
  totalCount: number
}

export interface ItemSearchFailed {
  errorMessage?: string;
  hasFailed?: boolean;
}

export const SearchDownMessage = 'Vyhľadávanie je momentálne nedostupné, skúste neskôr.';
