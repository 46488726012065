import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { DataCollectorService } from './device-collector.service';

@Injectable()
export class DeviceInterceptorService implements HttpInterceptor {

  private dataCollectorService = inject(DataCollectorService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.dataCollectorService.logDeviceData();
    return next.handle(req);
  }
}
