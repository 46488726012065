import {
  Component, ComponentFactoryResolver, ComponentRef, Injector,
  Input, OnChanges, OnInit, Type, ViewChild, ViewContainerRef
} from '@angular/core';

import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kros-component-renderer',
  templateUrl: './component-renderer.component.html',
  styleUrls: ['./component-renderer.component.scss'],
})
export class ComponentRendererComponent implements OnInit, OnChanges {
  componentRef: ComponentRef<any>;

  @Input() component: Type<any>;
  @Input() data: any;
  @Input() dropdownRef: NgbDropdown;
  @Input() params: any;

  @ViewChild('componentSlot', { read: ViewContainerRef, static: true }) componentSlot: ViewContainerRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector
  ) { }

  ngOnInit(): void {
    const cmpFactory = this.componentFactoryResolver.resolveComponentFactory(this.component);
    this.componentSlot.clear();
    this.componentRef = this.componentSlot.createComponent(cmpFactory, 0, this.injector);
    this.componentRef.instance.data = this.data;
    this.componentRef.instance.dropdownRef = this.dropdownRef;
    this.componentRef.instance.params = this.params;
  }

  ngOnChanges(): void {
    if (this.componentRef) {
      this.componentRef.instance.data = this.data;
    }
  }
}
