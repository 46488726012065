export const ContentTypes = {
  drawings: [
    'image/vnd.dwg'
  ],
  images: [
    'image/bmp',
    'image/gif',
    'image/jpeg',
    'image/png'
  ],
  ifc: 'application/ifc',
  pdf: 'application/pdf',
  zip: 'application/zip',
  construction: 'application/construction'
};

export const AppSupportContentTypes = {
  qm: [
    'image/vnd.dwg',
    'application/ifc',
    'application/pdf'
  ]
};

export const contentTypeToDocumentType = (contentType: string): string => {
  if (ContentTypes.drawings.includes(contentType)) {
    return 'cad';
  }

  if (contentType === ContentTypes.pdf) {
    return 'pdf';
  }

  if (contentType === ContentTypes.ifc) {
    return 'ifc';
  }

  return contentType;
};
