import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { BehaviorSubject, catchError, Observable, of, switchMap, throwError } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { ApplicationType, getDocumentIdParameter } from '@kros-sk/ssw-cdk';

import {
  DocumentCreatedModel, DocumentVersionDeleteModel, FolderModel, MultipleDocumentsDeleteModel, UploadVersionInfoModel
} from '../models';
import { DocumentStorageService, FileInfo, UploadFileInfoModel, UploadFilePreparationModel, UploadProgress } from '../../upload';
import { RenameFolderModel } from '../models';
import { TranslateService } from '../../translate';

const documentApi = '/api/documentService/Documents';
const documentVersionController = '/documentService/DocumentVersions';
const documentListAccessDataCreateEndpoint = '/documentListAccessDataCreate';
const versionAccessDataCreateEndpoint = '/versionAccessDataCreate';
const versionCreateEndpoint = '/versionCreate/';
const documentListCreateEndpoint = '/documentListCreate';
const folderCreateEndpoint = '/folderCreate';
const folderRenameEndpoint = '/folderRename';
const documentDeleteEndpoint = '/documentDelete/';
const documentsDeleteEndpoint = '/documentsDelete/';
const documentDeleteAllowedEndpoint = '/documentDeleteAllowed/';
const documentsDeleteAllowedEndpoint = '/documentsDeleteAllowed/';
const documentVersionDeleteEndpoint = '/delete';

@Injectable()
export class DocumentEditService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any,
    private documentStorageService: DocumentStorageService,
    private translateService: TranslateService
  ) { }

  private get apiDocumentUrl(): string {
    return this.appConfig.appUrls.titanGatewayUrl + documentApi;
  }

  private get gatewayApi(): string {
    return this.appConfig.appUrls.titanGatewayUrl + '/api/projects/';
  }

  uploadDocuments(
    file: FileInfo[],
    uploadFileInfo: UploadFileInfoModel[],
    onProgress: BehaviorSubject<UploadProgress>,
    isVersionMode: boolean,
    documentId: string
  ): Observable<any[]> {
    if (!isVersionMode) {
      return this.uploadDocumentsCore(file, uploadFileInfo, onProgress);
    } else {
      return this.uploadVersionCore(file, uploadFileInfo, onProgress, documentId);
    }
  }

  deleteDocument(projectId: number, documentId: string): Observable<any> {
    return this.http.delete(this.apiDocumentUrl + documentDeleteEndpoint + projectId + getDocumentIdParameter(documentId))
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  deleteDocuments(documentDeleteViewModel: MultipleDocumentsDeleteModel): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', }),
      body: JSON.stringify(documentDeleteViewModel)
    };

    return this.http.delete(this.apiDocumentUrl + documentsDeleteEndpoint, options)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  deleteDocumentVersion(projectId: number, documentId: string, documentVersionId: string): Observable<DocumentVersionDeleteModel> {
    return this.http.delete(
      this.gatewayApi + projectId +
      documentVersionController +
      documentVersionDeleteEndpoint +
      getDocumentIdParameter(documentId) +
      '&documentVersionId=' + encodeURIComponent(documentVersionId))
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  isDeleteDocumentAllowed(projectId: number, documentId: string): Observable<any> {
    return this.http.get(this.apiDocumentUrl +
      documentDeleteAllowedEndpoint +
      projectId +
      getDocumentIdParameter(documentId));
  }

  isDeleteDocumentsAllowed(documentDeleteViewModel: MultipleDocumentsDeleteModel): Observable<any> {
    return this.http.post(this.apiDocumentUrl + documentsDeleteAllowedEndpoint, documentDeleteViewModel);
  }

  private uploadDocumentsCore(
    file: FileInfo[],
    uploadFileInfo: UploadFileInfoModel[],
    onProgress: BehaviorSubject<UploadProgress>
  ): Observable<any[]> {
    let validationResult: any[];
    const preparationDocuments: UploadFilePreparationModel[] = [];
    uploadFileInfo.forEach(element => {
      const preparationDocument: UploadFilePreparationModel = {
        ...element,
        applicationType: ApplicationType.Documents
      };
      preparationDocuments.push(preparationDocument);
    });
    return this.http.post<any>(this.apiDocumentUrl + documentListAccessDataCreateEndpoint, preparationDocuments)
      .pipe(
        switchMap(resp => {
          const promises: Promise<any>[] = [];
          validationResult = resp;
          resp.filter(x => x.isValid).forEach(element => {
            const fileInfo = uploadFileInfo.find(x => x.id === element.id);
            const f = file.find(x => x.id === element.id);
            fileInfo.blobName = element.blobAccessData.name;

            promises.push(this.documentStorageService.uploadFileToBlobStorage(
              f.file,
              fileInfo.id,
              element.blobAccessData,
              onProgress));
          });

          return Promise.all(promises);
        }),
        switchMap(() => {
          return this.publishUploadDocument(
            uploadFileInfo.filter(fileInfo =>
              validationResult.some(uploaded => uploaded.id === fileInfo.id && uploaded.isValid)),
            validationResult.filter(x => x.isValid));
        }),
        switchMap(() => of(validationResult))
      );
  }

  private uploadVersionCore(
    file: FileInfo[],
    uploadFileInfo: UploadFileInfoModel[],
    onProgress: BehaviorSubject<UploadProgress>,
    documentId: string
  ): Observable<any[]> {
    let validationResult: any[];
    const versionInfo: UploadVersionInfoModel = {
      documentId,
      name: uploadFileInfo[0].name,
      size: uploadFileInfo[0].size,
      originalName: uploadFileInfo[0].originalName,
      blobName: '',
      dateCreated: new Date(),
      description: ''
    };

    return this.http.post<any>(this.apiDocumentUrl + versionAccessDataCreateEndpoint, versionInfo)
      .pipe(
        switchMap(resp => {
          const promises: Promise<any>[] = [];
          validationResult = [resp];
          versionInfo.blobName = resp.blobAccessData.name;

          promises.push(this.documentStorageService.uploadFileToBlobStorage(file[0].file,
            versionInfo.originalName,
            resp.blobAccessData,
            onProgress));

          return Promise.all(promises);
        }),
        switchMap(() => {
          return this.publishUploadVersion(versionInfo, validationResult[0]);
        }),
        switchMap(() => of(validationResult))
      );
  }

  publishUploadVersion(versionInfo: UploadVersionInfoModel, uploadedFile?: any): Observable<any> {
    return this.http.post(
      this.apiDocumentUrl + versionCreateEndpoint, versionInfo)
      .pipe(
        catchError(error => {
          if (uploadedFile) {
            this.documentStorageService.deleteBlob(uploadedFile.blobAccessData).then();
          }
          return this.handleError(error);
        })
      );
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      let errorMessage: string = this.translateService.translate('DOCUMENTS.DOCUMENT_GENERALERROR');

      switch (error.status) {
        case 403:
          errorMessage = this.translateService.translate('DOCUMENTS.DOCUMENT_FORBBIDEN');
          break;

        case 404:
          errorMessage = this.translateService.translate('DOCUMENTS.DOCUMENT_NOT_EXIST');
          break;

        case 409:
          errorMessage = this.translateService.translate('DOCUMENTS.ADRESAR_NIE_JE_PRAZDNY');
          break;
      }

      console.error(
        `Backend returned code ${error.status}, ` +
        `error message: ${errorMessage}, ` +
        `body was: ${error.error}`);

      return throwError(() => new Error(errorMessage));
    }
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  createFolder(folder: FolderModel): Observable<any> {
    return this.http.post(this.apiDocumentUrl + folderCreateEndpoint, folder);
  }

  renameFolder(projectId: number, documentId: string, documentName: string): Observable<any> {
    const renameFolderModel: RenameFolderModel = {
      projectId,
      folderId: documentId,
      name: documentName,
      date: new Date()
    };

    return this.http.patch(this.apiDocumentUrl + folderRenameEndpoint, renameFolderModel)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  publishUploadDocument(fileInfo: UploadFileInfoModel[], uploadedFiles: any[] = []): Observable<DocumentCreatedModel[]> {
    return this.http.post<DocumentCreatedModel[]>(
      this.apiDocumentUrl + documentListCreateEndpoint, fileInfo)
      .pipe(
        catchError(error => {
          uploadedFiles.forEach(element => {
            this.documentStorageService.deleteBlob(element.blobAccessData).then();
          });

          return this.handleError(error);
        })
      );
  }
}
