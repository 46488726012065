import { Component, OnInit } from '@angular/core';

import { map, of, switchMap } from 'rxjs';

import { ApplicationType } from '@kros-sk/ssw-cdk';

import { BuildingProgressPanelHelper } from '../../../../building-progress/helpers';
import { BuildingProgressSelectorsService } from '../../../../store/building-progress';
import { Note } from '../../../../building-progress/models/note.model';

@Component({
  selector: 'app-subcontractors-budget-item-notes',
  templateUrl: './subcontractors-item-notes.component.html',
  styleUrls: ['./subcontractors-item-notes.component.scss']
})
export class SubcontractorsItemNotesComponent implements OnInit {
  note$ = this.panelHelper.reloadNotesPanelData$.pipe(
    switchMap((id) => id === -1 ? this.getEmptyNote$ : this.getNote$(id))
  );

  private getEmptyNote$ = of<Note>(null).pipe(map(() => this.getNote(null, -1)));

  constructor(
    public panelHelper: BuildingProgressPanelHelper,
    private buildingProgressSelectorsService: BuildingProgressSelectorsService
  ) { }

  ngOnInit(): void {
    this.panelHelper.reloadNotesData();
  }

  private getNote$ = (id: number): any => this.buildingProgressSelectorsService.getBuildingProgressNote$(id).pipe(
    map((note) => this.getNote(note, id)
    ));

  private getNote(note: any, id: number): Note {
    return id === -1
      ? null
      : note ?? {
        applicationType: ApplicationType.BuildingProgress,
        budgetItemId: id,
        note: null,
        projectId: this.panelHelper.projectId
      };
  }
}
