<dx-data-grid
  #dataDataGridRef
  *ngIf="decimalPlaces"
  class="dx-data-grid partial-item-table"
  data-test="partial-item-table"
  keyExpr="id"
  [dataSource]="dataSource"
  [showBorders]="true"
  [showColumnLines]="true"
  [showRowLines]="true"
  [allowColumnReordering]="false"
  [allowColumnResizing]="true"
  [columnResizingMode]="'widget'"
  [columnAutoWidth]="true"
  [hoverStateEnabled]="true"
  [wordWrapEnabled]="true"
  [loadPanel]="{ text: 'COMMON.PREPARING_DATA' | translate }"
  (onSaving)="onSaving($event)"
  (onCellPrepared)="onCellPrepared($event)"
  (onFocusedCellChanged)="onFocusedCellChanged($event)"
  (onEditingStart)="onEditingStart($event)"
  (onEditorPreparing)="onEditorPreparing($event)"
>
  <dxo-scrolling mode="standard"></dxo-scrolling>

  <dxo-keyboard-navigation
    [editOnKeyPress]="true"
    [enterKeyAction]="'moveFocus'"
    [enterKeyDirection]="'row'"
  ></dxo-keyboard-navigation>

  <dxo-editing
    mode="cell"
    [allowUpdating]="true"
    [confirmDelete]="false"
    [selectTextOnEditStart]="true"
    [startEditAction]="'click'"
  ></dxo-editing>

  <dxi-column
    [caption]="'BUILDING_PROGRESS.PARTIAL_ITEMS.ITEM_CODE' | translate"
    dataField="code"
    dataType="string"
    width="120"
    [allowEditing]="false"
    [allowSorting]="false"
  >
  </dxi-column>

  <dxi-column
    [caption]="'BUILDING_PROGRESS.PARTIAL_ITEMS.DESCRIPTION' | translate"
    dataField="description"
    dataType="string"
    [allowEditing]="false"
    [allowSorting]="false"
  >
  </dxi-column>

  <dxi-column
    [caption]="'BUILDING_PROGRESS.PARTIAL_ITEMS.MEASURE_UNIT' | translate"
    dataField="measureUnit"
    dataType="string"
    width="60"
    [allowSorting]="false"
    [allowEditing]="false"
  >
  </dxi-column>

  <dxi-column
    [caption]="'BUILDING_PROGRESS.PARTIAL_ITEMS.AMOUNT' | translate"
    dataField="amount"
    dataType="number"
    width="150"
    [allowEditing]="true"
    [allowSorting]="false"
    [editorOptions]="{ format: formatPatternAmount }"
    alignment="right"
  >
    <dxo-format
      type="fixedPoint"
      [precision]="decimalPlaces.amount"></dxo-format>
  </dxi-column>

  <dxi-column
    [caption]="'BUILDING_PROGRESS.PARTIAL_ITEMS.PERCENTAGE' | translate"
    dataField="percentage"
    dataType="number"
    width="150"
    alignment="right"
    [allowEditing]="true"
    [allowSorting]="false"
    [editorOptions]="{ format: formatPatternPercentage }"
  >
    <dxo-format
      type="fixedPoint"
      [precision]="decimalPlaces.percentage"></dxo-format>
  </dxi-column>

  <dxo-grouping
    [autoExpandAll]="true"
    [contextMenuEnabled]="true"></dxo-grouping>
</dx-data-grid>
