import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable()
export class ProgressBarService {

  change = new Subject<boolean>();
  activeProgresses: any[];

  constructor() {
    this.activeProgresses = [];
  }

  showProgress(): any {
    const pb = {};
    this.activeProgresses.push(pb);

    setTimeout(() => {
      this.sendMessage();
    }, 200);

    return pb;
  }

  hideProgress(pb): void {
    const index = this.activeProgresses.indexOf(pb);
    if (index > -1) {
      this.activeProgresses.splice(index, 1);
    }
    setTimeout(() => {
      this.sendMessage();
    });
  }

  sendMessage(): void {
    this.change.next(this.activeProgresses.length > 0);
  }
}
