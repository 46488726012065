<ng-container [formGroup]="companyInfoForm">
  <div formGroupName="address">
    <div class="company-info-row">
      <label class="alternative">Názov firmy</label>
      <kros-company-search formControlName="businessName"
                           [showAllErrors]="showAllErrors"
                           (blur)="onTouchedFn()"
                           data-test="company-info-name"
                           [validationOptions]="companySearchOptions">
      </kros-company-search>
    </div>

    <div class="company-info-row">
      <kros-street-selector [required]="enableExtendedValidations" formControlName="street" (newAddress)="setNewAddress($event)"></kros-street-selector>
    </div>

    <div class="company-info-row">
      <div class="company-info-container">
        <div class="zip">
          <kros-post-code-selector [required]="enableExtendedValidations" formControlName="postCode" (newPostCode)="setNewCity($event)"></kros-post-code-selector>
        </div>

        <div class="city">
          <kros-town-selector [required]="enableExtendedValidations" formControlName="city" (newCity)="setNewCity($event)"></kros-town-selector>
        </div>
      </div>
    </div>

    <div class="company-info-row country-selector">
      <kros-country-selector [required]="true" formControlName="country"></kros-country-selector>
    </div>
  </div>

  <div class="company-info-row">
    <div class="company-info-container">
      <div class="half-width">
        <kros-input [options]="{
          type: 'text',
          formControl: companyInfoForm.controls.registrationId,
          name: 'registrationId',
          label: 'IČO',
          testName: 'company-info-registration-id',
          maxLength: 20,
          errors: {
            required: 'Táto položka je povinná.'
          }
        }">
        </kros-input>
      </div>

      <div class="half-width">
        <kros-input [options]="{
          type: 'text',
          formControl: companyInfoForm.controls.taxId,
          name: 'taxId',
          label: 'DIČ',
          testName: 'company-info-tax-id',
          maxLength: 20
        }">
        </kros-input>
      </div>
    </div>
  </div>

  <div class="company-info-row">
    <div class="company-info-container">
      <div class="half-width">
        <label class="alternative" for="vatPayerType">Typ platiteľa</label>
        <select class="form-control long-select"
                id="vatPayerType"
                formControlName="vatPayerType"
                (blur)="onTouchedFn()"
                data-test="company-info-vat-select">
          <option *ngFor="let vatOption of vatPayerOptions"
                  [value]=vatOption.value
                  data-test="company-info-vat-option">
            {{ vatOption.label }}
          </option>
        </select>
      </div>

      <div *ngIf="displayVatId" class="half-width">
        <kros-input [options]="{
          type: 'text',
          formControl: companyInfoForm.controls.vatId,
          name: 'vatId',
          label: 'IČ DPH',
          testName: 'company-info-vat-id',
          maxLength: 20,
          placeholder: this.companyInfoForm.value.taxId ? 'SK' + this.companyInfoForm.value.taxId : ''
        }">
        </kros-input>
      </div>
    </div>
  </div>

</ng-container>
