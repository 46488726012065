import { Component, EventEmitter, inject, Input, Output } from '@angular/core';

import { Subscription } from 'rxjs';

import { FileUploader } from 'ng2-file-upload';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

import { DocumentEditService } from '../../services/document-edit.service';
import { UploaderService } from '../../../upload';
import { VersionService } from '../../../version';

@Component({
  selector: 'kros-upload-version-button',
  templateUrl: './upload-version-button.component.html',
  styleUrls: ['./upload-version-button.component.scss']
})
export class UploadVersionButtonComponent {

  fileUploaderService = inject(UploaderService);
  private appInsightsService = inject(AppInsightsBaseService);
  private documentEditService = inject(DocumentEditService);
  private versionService = inject(VersionService);

  @Input() disabled = false;
  @Input() projectId: number;
  @Input() documentId: string;
  @Input() parentId: string;
  @Input() contentType: string;
  @Input() toolbarAction = false;

  @Output() fileAddVersionSelected = new EventEmitter<void>();

  uploader: FileUploader;
  private uploadSubscription: Subscription;

  initializeUploader(fileInputElement: any): void {
    if (this.fileUploaderService.isUploaderReady) {
      fileInputElement.value = '';
      fileInputElement.accept = this.getAcceptFileExtensions(this.contentType);
      this.uploader = this.fileUploaderService.configure(this.projectId, this.parentId);
      fileInputElement.click();
    }
  }

  private getAcceptFileExtensions(contentType: string): string {
    const extension = '.' + contentType.substring(contentType.indexOf('/') + 1);
    return extension.lastIndexOf('.') > 0 ? extension.substring(extension.lastIndexOf('.')) : extension;
  }

  async onFileAddVersionSelected(): Promise<any> {
    this.fileAddVersionSelected.emit();
    this.uploadSubscription = this.fileUploaderService.fileUploadReady.subscribe(uploadFiles => {
      this.appInsightsService.trackEvent(
        'DOC-upload-version',
        {
          documentId: this.documentId,
          documentNames: this.fileUploaderService.filesToUpload.map(file => file.originalFileName)
        }
      );
      this.documentEditService.uploadDocuments(
        this.fileUploaderService.filesToUpload, uploadFiles, this.fileUploaderService.progressItem, true, this.documentId)
        .subscribe(
          (response) => {
            this.fileUploaderService.handleUploadDocumentsSuccess(response);
            this.versionService.clearVersionList();
          },
          (err) => this.fileUploaderService.handleUploadDocumentsError(err, uploadFiles)
        );
      this.uploadSubscription.unsubscribe();
    });
    this.fileUploaderService.onFileSelected();
  }
}
