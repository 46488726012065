import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';

import { filter, take } from 'rxjs/operators';

import { KrosModalService } from '@kros-sk/components';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

import { BuildingProgressSelectorsService } from '../../../../store/building-progress';
import { LicenseHelper, PermissionHelper } from '../../../../building-progress/helpers';
import { ProjectsSelectorsService } from '../../../../store/projects';
import { Subcontractor } from '../models';
import { SubcontractorsDispatchersService, SubcontractorsSelectorsService } from '../../../../store/subcontractors';
import { SubcontractorSelectDialogComponent } from '../subcontractor-select-dialog/subcontractor-select-dialog.component';
import {
  SubcontractorsSelectItemsDialogComponent
} from '../subcontractors-select-items-dialog/subcontractors-select-items-dialog.component';

@Component({
  selector: 'app-subcontractors-panel-container',
  templateUrl: './subcontractors-panel-container.component.html',
  styleUrls: ['./subcontractors-panel-container.component.scss']
})
export class SubcontractorsPanelContainerComponent extends UnsubscribeComponent implements OnInit {
  @Input() tableId: string;

  get canEditSubcontractors(): boolean {
    return (this.permissionHelper.isOwner || this.permissionHelper.isContributor) &&
      !this.licenseHelper.isReadonlyLicense;
  }

  private projectId: number;
  private subcontractors: Subcontractor[];

  constructor(
    public buildingProgressSelectorsService: BuildingProgressSelectorsService,
    public selectorsService: SubcontractorsSelectorsService,
    private activatedRoute: ActivatedRoute,
    private appInsightsService: AppInsightsBaseService,
    private krosModalService: KrosModalService,
    private projectsSelectorsService: ProjectsSelectorsService,
    private dispatchersService: SubcontractorsDispatchersService,
    private permissionHelper: PermissionHelper,
    private licenseHelper: LicenseHelper
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.projectsSelectorsService.projectDetail$
      .pipe(filter(p => !!p))
      .subscribe(p => {
        this.projectId = p.id;
        this.dispatchersService.loadSubcontractors(
          this.projectId,
          +this.activatedRoute.snapshot.paramMap.get('contractorId')
        );
      });

    this.subs.sink = this.selectorsService.subcontractors$
      .subscribe(subcontractors => this.subcontractors = subcontractors);
  }

  openSelectItemsDialog(): void {
    const modalRef = this.krosModalService.openCentered(
      SubcontractorsSelectItemsDialogComponent,
      { projectId: this.projectId },
      { closeOnBackdropClick: false },
      NaN,
      NaN,
      undefined,
      'no-max-width'
    );

    modalRef.afterClosed$.pipe(take(1)).subscribe(result => {
      if (result.type === 'submit') {
        setTimeout(() => {
          this.openSubcontractorSelectDialog(result.data.selectedItemIds);
        });
      }
    });
  }

  onSubcontractorSelected(subcontractor: Subcontractor): void {
    this.dispatchersService.selectSubcontractor(subcontractor);
  }

  private openSubcontractorSelectDialog(itemIds: number[]): void {
    const modalRef = this.krosModalService.openCentered(
      SubcontractorSelectDialogComponent,
      { subcontractors: this.subcontractors },
      { closeOnBackdropClick: false }
    );

    modalRef.afterClosed$.pipe(take(1)).subscribe(result => {
      if (result.type === 'submit') {
        this.dispatchersService.createSubcontractorItems(this.projectId, result.data.subcontractor, itemIds);
        this.appInsightsService.trackEvent('PV-new-subcontractor');
      }
    });
  }
}
