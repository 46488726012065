import { AbstractControlDirective, NgControl } from '@angular/forms';
import { Directive, ElementRef } from '@angular/core';

import { NgrxFormControlDirective } from 'ngrx-forms';
import { Observable } from 'rxjs';

/** An interface which allows a control to work inside a `KrosFormField`. */
@Directive()
export abstract class KrosFormFieldControl<T> {
  /** The value of the control. */
  value: T | null;

  /**
   * Stream that emits whenever the state of the control changes such that the parent `KrosFormField`
   * needs to run change detection.
   */
  readonly stateChanges: Observable<void>;

  /**
   * Stream that emits whenever focus of the control (input, textarea) changes.
   */
  readonly focusChanges: Observable<boolean>;

  /** The element ID for this control. */
  readonly id: string;

  /** Gets the AbstractControlDirective for this control. */
  readonly ngControl: NgControl | AbstractControlDirective | null;

  readonly ngrxControl: NgrxFormControlDirective<any>;

  /** Whether the control is focused. */
  readonly focused: boolean;

  /** Whether the control is required. */
  readonly required: boolean;

  /** Whether the control is disabled. */
  readonly disabled: boolean;

  /** Whether the control is in an error state. */
  readonly errorState: boolean;

  /** HTML element reference. */
  readonly elementRef: ElementRef;
}
