import { inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { Budget } from '@kros-sk/ssw-budget/shared/data-access/models';
import { BudgetItem, ProjectDetail } from '@kros-sk/ssw-shared-legacy';

import { budgetItemsSharedActions } from './budget-items-shared-actions';
import { budgetSharedActions } from './budget-shared-actions';
import { BudgetSharedState } from './budget-shared-state';

@Injectable({
  providedIn: 'root'
})
export class BudgetSharedDispatchersService {
  private store = inject(Store<BudgetSharedState>);

  setProject(project: ProjectDetail): void {
    this.store.dispatch(budgetSharedActions.setProject({ project }));
  }

  resolveBudget(projectId: number): void {
    this.store.dispatch(budgetSharedActions.resolveBudget({ projectId }));
  }

  editProjectDetails(project: ProjectDetail): void {
    this.store.dispatch(budgetSharedActions.editProjectDetailsStart({ project }));
  }

  getBudgetSettings(): void {
    this.store.dispatch(budgetSharedActions.getBudgetSettingsStart());
  }

  getBudgetUiSettings(settingsName: string, projectId?: number, forceLoad?: boolean): void {
    this.store.dispatch(budgetSharedActions.getBudgetUISettingsStart({ projectId, settingsName, forceLoad }));
  }

  setBudgetUiSettings(settings: any, settingsName: string): void {
    this.store.dispatch(budgetSharedActions.setBudgetUISettingsStart({ settings, settingsName }));
  }

  setBudget(budget: Budget): void {
    this.store.dispatch(budgetSharedActions.setBudget({ budget }));
  }

  loadBudgetItemsSuccess(budgetId: number, items: BudgetItem[]): void {
    this.store.dispatch(budgetItemsSharedActions.loadBudgetItemsSuccess({ budgetId, items, isInitializing: false }));
  }
}
