import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { BehaviorSubject, catchError, map, Observable, of, switchMap, take, tap } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { BuildingProgressPermission, BuildingProgressPermissionType, PermissionType } from '@kros-sk/ssw-shared/permission';
import { ToastService, TranslateService } from '@kros-sk/ssw-shared-legacy';

import { BuildingProgressSettings, BuildingProgressSettingsEditModel, ExistBudgetResult } from '../model';
import { SettingService } from './';

const buildingProgressService = '/buildingProgressService/';
const projectApi = '/api/projects/';
const buildingProgressApi = `${buildingProgressService}buildingProgress`;
const budgetApi = `${buildingProgressService}budget`;

@Injectable()
export class BuildingProgressSettingService {

  get buildingProgressSettings$(): BehaviorSubject<BuildingProgressSettings> {
    return this._buildingProgressSettings$;
  }

  get isExist(): boolean {
    return this.existBudgetResult?.exists;
  }

  get hasPermission(): boolean {
    return !!this.permission && (this.permission.permissionType > PermissionType.Shared || this.isContractor());
  }

  get canSaveSettings(): boolean {
    return !!this.permission && this.permission.permissionType > PermissionType.Reader;
  }

  isLoading: boolean;

  private _buildingProgressSettings$ = new BehaviorSubject<BuildingProgressSettings>(undefined);
  private gatewayApi = `${this.appConfig.appUrls.titanGatewayUrl}${projectApi}`;
  private getBuildingProgressSettingsEndpoint = `${buildingProgressApi}/settings/`;
  private editBuildingProgressSettingsEndpoint = `${buildingProgressApi}/settingsEdit`;
  private checkExistBuildingProgressEndpoint = `${budgetApi}/exist`;
  private getPermissionEndpoint = `${buildingProgressApi}/permission`;
  private existBudgetResult: ExistBudgetResult;
  private permission: BuildingProgressPermission;

  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private settingService: SettingService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private http: HttpClient
  ) {
  }

  loadBuildingProgressSettings(): void {
    this.http.get<BuildingProgressSettings>(
      this.getGatewayApi() + this.getBuildingProgressSettingsEndpoint)
      .pipe(take(1))
      .subscribe(s => {
        this._buildingProgressSettings$.next(s);
        this.isLoading = false;
      });
  }

  checkBuildingProgress(): Observable<boolean> {
    this.isLoading = true;
    return this.http.get<BuildingProgressPermission>(
      this.gatewayApi + this.settingService.projectId + this.getPermissionEndpoint + `/${this.settingService.projectId}`)
      .pipe(
        tap(p => this.permission = p),
        switchMap(() => this.hasPermission
          ? this.http.get<ExistBudgetResult>(
            this.getGatewayApi() + this.checkExistBuildingProgressEndpoint + `/${this.settingService.projectId}`)
          : of({ projectId: 0, exists: false, hasPermission: this.hasPermission, ownerEmail: '' })),
        tap(r => {
          this.existBudgetResult = r;
          this.isLoading = r.exists;
        }),
        map(r => r.exists));
  }

  editSettings(settings: BuildingProgressSettingsEditModel): Observable<void> {
    return this.http.patch<void>(this.getGatewayApi() + this.editBuildingProgressSettingsEndpoint, settings)
      .pipe(
        catchError(this.settingService.handleError.bind(this.settingService)),
        tap(() => {
          this.loadBuildingProgressSettings();
          this.toastService.open(this.translateService.translate('SETTINGS.VAT.SET_SETTINGS_SUCCESS'));
        }));
  }

  private getGatewayApi(): string {
    return this.gatewayApi + this.settingService.projectId;
  }

  private isContractor(): boolean {
    const permissionType = this.permission.buildingProgressPermissionType;
    return (permissionType | BuildingProgressPermissionType.Contractor) === permissionType;
  }
}
