import { Component, EventEmitter, inject, Input, Output } from '@angular/core';

import { getTagName } from '../tag-utils';
import { Tag } from '../tag.model';
import { TranslateService } from '../../translate';

@Component({
  selector: 'kros-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent {
  private readonly translate = inject(TranslateService);

  @Input() tags: Tag[];
  @Input() showTagIcon: boolean;

  @Output() tagClicked = new EventEmitter();

  onTagClick(): void {
    this.tagClicked.emit();
  }

  getTagName(key: string): string {
    return getTagName(key, this.translate);
  }
}
