export class UserFeatureFlags {
  iKrosTeam = false;
  partners = false;
  deliveryNotes = false;
  licensesUnlimited = false;
  budgetGrouping = false;
  budgetTest = false;
  budgetMini = false;
  budgetProfi = false;
  ignoreMaintenance = false;
  spreadsheetFunctions = false;
  webassembly = false;
  useWebsocketRecalc = false;
  hideBankAccounts = false;
  apiSettings = false;
  isAutoCompletedItemsEnabled = false;
  pdfRotateWithoutMeasurements = false;
  bimCci = false;
  bimDataExtraction = false;
  bimCompare = false;
  bimViewNew = false;
  sswTest = false;
  myDocumentsFetchReceipts = false;
  invoicingGrid = false;
  invoicingGridSummary = false;
  openAppInSameTab = false;
  multiStageApprovalModeEnabled = false;
  archiveBulkDownload = false;
  leftNavigationPanel = false;
  invoicingGridStateFunctions = false;
  invoicingExpenses = false;
  decimalVatRate = false;
  invoicingNotificationCentre = false;
  platformMaintenance = false;
  proformaInvoicesGrid = false;
  myDocumentsRounding = false;
  luckysheetFilter = false;
  priceListSearch = false;
  priceListMO = false;
}

export interface FeatureFlagsRoutingGuardConfig {
  flagName: string;
  redirectTo: string;
}
