import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { take } from 'rxjs';

import { adminDemoProjectUserId, UnsubscribeComponent, UserService } from '@kros-sk/ssw-cdk';
import { isOwnersEmail, Project, ProjectFilter, Tag, TagsFilterDialogComponent, TranslateService } from '@kros-sk/ssw-shared-legacy';
import { KrosModalService, MessageTypes } from '@kros-sk/components';

import { ProjectListHelper } from '../../helpers/project-list.helper';

@Component({
  selector: 'app-project-toolbar',
  templateUrl: './project-toolbar.component.html',
  styleUrls: ['./project-toolbar.component.scss']
})
export class ProjectToolbarComponent extends UnsubscribeComponent implements OnChanges {
  @Input() selectedProject: Project;
  @Input() projects: Project[];
  @Input() tagsList: Tag[] = [];
  @Input() activeFilterTagIds: number[] = [];
  @Input() filterIndex: ProjectFilter = ProjectFilter.None;

  @Output() demoProjectPublish = new EventEmitter<void>();
  @Output() projectOpened = new EventEmitter<Project>();
  @Output() projectShared = new EventEmitter<Project>();
  @Output() projectCopy = new EventEmitter<Project>();
  @Output() projectDeleted = new EventEmitter<Project>();
  @Output() projectRemovedSharing = new EventEmitter<Project>();
  @Output() projectDetailOpened = new EventEmitter<Project>();
  @Output() projectTagsOpened = new EventEmitter<{ project: Project, element: HTMLElement }>();
  @Output() filterChanged = new EventEmitter<ProjectFilter>();
  @Output() tagFilterChanged = new EventEmitter<number[]>();

  isOldestDemoProjectSelected: boolean;

  constructor(
    private krosModalService: KrosModalService,
    private translateService: TranslateService,
    private userService: UserService,
    private projectListHelper: ProjectListHelper,
  ) {
    super();
  }

  get isProjectSelected(): Project {
    return this.selectedProject;
  }

  get isDemoAdminUser(): boolean {
    return this.userService.userId === adminDemoProjectUserId;
  }

  get isOwnerMe(): boolean {
    return this.isProjectSelected && isOwnersEmail(this.selectedProject.owner);
  }

  get isActiveFilterTags(): boolean {
    return this.activeFilterTagIds.length > 0;
  }

  ngOnChanges(): void {
    this.isOldestDemoProjectSelected = this.getIsOldestDemoProjectSelected();
  }

  openProject(): void {
    this.projectOpened.emit(this.selectedProject);
  }

  shareProject(): void {
    this.projectShared.emit(this.selectedProject);
  }

  copyProject(): void {
    this.projectCopy.emit(this.selectedProject);
  }

  deleteProject(): void {
    this.projectDeleted.emit(this.selectedProject);
  }

  removeProjectSharing(): void {
    this.projectRemovedSharing.emit(this.selectedProject);
  }

  openProjectDetail(): void {
    this.projectDetailOpened.emit(this.selectedProject);
  }

  onPublishDemoProject(): void {
    this.krosModalService.openModalMessageBox({
      acceptButton: this.translateService.translate('COMMON.YES'),
      cancelButton: this.translateService.translate('COMMON.NO'),
      message: this.translateService.translate('PROJECTS.ZOZNAM.PUBLISH_DEMO_PROJECT_MESSAGE'),
      caption: this.translateService.translate('PROJECTS.ZOZNAM.PUBLISH_DEMO_PROJECT'),
      messageType: MessageTypes.Primary
    }).pipe(take(1)).subscribe(result => {
      if (result?.data === 'accept') {
        this.demoProjectPublish.emit();
      }
    });
  }

  openProjectTags(element: HTMLElement): void {
    this.projectTagsOpened.emit({ project: this.selectedProject, element });
  }

  onFilterChanged(projectFilter: ProjectFilter): void {
    this.filterChanged.emit(projectFilter);
  }

  onTagFilterChanged(tags: number[]): void {
    this.tagFilterChanged.emit(tags);
  }

  onTagFilterButtonClick(): void {
    const dialogData = {
      tags: this.tagsList,
      activeFilterTagIds: this.activeFilterTagIds
    };

    const modalRef = this.krosModalService.openCentered(TagsFilterDialogComponent, dialogData);

    modalRef.afterClosed$.subscribe(result => {
      if (result.type === 'submit') {
        if (result.data?.hasOwnProperty('cancelTagsFilter')) {
          this.tagFilterChanged.emit([]);
        } else if (result.data?.hasOwnProperty('filteredTagIds')) {
          this.tagFilterChanged.emit(result.data.filteredTagIds);
        }
      }
    });
  }

  private getIsOldestDemoProjectSelected(): boolean {
    const oldestDemoProject = this.projectListHelper.getOldestDemoProject(this.projects);
    return (!!oldestDemoProject && this.selectedProject?.id === oldestDemoProject.id);
  }
}
