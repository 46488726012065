/**
 * OSS - one-stop shop, https://www.financnasprava.sk/sk/podnikatelia/dane/dan-z-pridanej-hodnoty/one-stop-shop
 */
export enum OssTaxState {
  /**
   * Is not registered for OSS
   */
  NonRegistered = 0,

  /**
   * Is registered for OSS
   */
  Registered = 1,

  /**
   * Registered vat payer by §7/7a, same as NonPayer, but...
   */
  RejectedByUser = 2
}
