<announcekit class="secondary-nav-icon-item"
             [widget]="url"
             [user]="{'id': userId}"
             [lang]="lang"
             [data]="{
               'app': appName,
               'companyId': companyId,
               'licenseType': licenseType,
               'isLicenseValid': isLicenseValid,
               'daysValid': daysValid,
               'isTest': isTest,
               'modul': modul
             }"
             [widgetStyle]="widgetStyle">
  <ng-content></ng-content>
</announcekit>
