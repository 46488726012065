import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { SubcontractorsDispatchersService } from '.';

import { GenericInfoDialogComponent, UserService } from '@kros-sk/ssw-cdk';
import { KrosModalService } from '@kros-sk/components';
import { ToastService, ToastType, TranslateService } from '@kros-sk/ssw-shared-legacy';

import * as listActions from './subcontractors.actions';
import { BudgetModel } from '../../budget-approval/models';
import { RemoveItemsService } from '../../building-progress/modules/subcontractors/services/remove-items.service';
import { SubcontractorsService } from '../../building-progress/modules/subcontractors/services/subcontractors.service';

@Injectable()
export class SubcontractorsEffects {
  getSubcontractors$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.loadSubcontractorsStart),
      switchMap(payload => {
        return this.subcontractorsService.loadSubcontractors(payload.projectId).pipe(
          map(subcontractors => listActions.loadSubcontractorsSuccess({
            subcontractors,
            selectedContractorId: payload.selectedContractorId
          })),
          catchError(error => {
            this.handleError(error);
            return of(listActions.loadSubcontractorsError({ error }));
          })
        );
      })
    );
  });

  getDialogItemsList$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.loadDialogItemsListStart),
      switchMap(payload => {
        return this.subcontractorsService.loadDialogItemsList(payload.projectId).pipe(
          map((dialogItemsList: BudgetModel) => {
            dialogItemsList.items = dialogItemsList.items.flatMap((item) =>
              item.partialBudgetItems?.length > 0
                ? item.partialBudgetItems.map((partialItem) =>
                  ({ ...item, originalItemIdOfPartialItem: item.id, id: -partialItem.id }))
                : [item]
            );
            return listActions.loadDialogItemsListSuccess({ dialogItemsList });
          }),
          catchError(error => {
            this.handleError(error);
            return of(listActions.loadDialogItemsListError({ error }));
          })
        );
      })
    );
  });

  createSubcontractorItems$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.createSubcontractorItemsStart),
      switchMap(payload => {
        return this.subcontractorsService.createSubcontractor(payload.projectId, payload.name).pipe(
          switchMap(result => {
            return this.subcontractorsService.addSubcontractorItems(payload.projectId, result, payload.itemIds).pipe(
              map(() => {
                this.dispatchersService.loadSubcontractors(payload.projectId);
                this.dispatchersService.selectSubcontractor({ id: result, name: payload.name });
                return listActions.createSubcontractorItemsSuccess();
              })
            );
          }),
          catchError(error => {
            this.handleError(error);
            return of(listActions.loadDialogItemsListError({ error }));
          })
        );
      })
    );
  });

  addSubcontractorItems$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.addSubcontractorItemsStart),
      switchMap(payload => {
        return this.subcontractorsService.addSubcontractorItems(payload.projectId, payload.contractorId, payload.itemIds).pipe(
          map(() => listActions.addSubcontractorItemsSuccess()),
          catchError(error => {
            this.handleError(error);
            return of(listActions.addSubcontractorItemsError({ error }));
          })
        );
      })
    );
  });

  removeSubcontractorItems$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.removeSubcontractorItemsStart),
      switchMap(payload => {
        return this.subcontractorsService.removeSubcontractorItems(
          payload.projectId, payload.contractorId, { budgetItemIds: payload.itemIds }
        ).pipe(
          map(() => listActions.removeSubcontractorItemsSuccess()),
          catchError(error => {
            if (error.status === 409) {
              this.removeItemsService.openInfoDialog();
            } else {
              this.handleError(error);
            }
            return of(listActions.removeSubcontractorItemsError({ error }));
          })
        );
      })
    );
  });

  getSharingList$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.loadSharingListStart),
      switchMap(payload => this.subcontractorsService.getSharingList(payload.projectId, payload.contractorId).pipe(
        map(sharingList => listActions.loadSharingListSuccess({ sharingList })),
        catchError(error => of(listActions.loadSharingListError({ error })))
      ))
    );
  });

  getSubcontractorsSharingList$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.loadSubcontractorsSharingListStart),
      switchMap(payload => this.subcontractorsService.getSharingList(payload.projectId).pipe(
        map(sharingList => listActions.loadSubcontractorsSharingListSuccess({ sharingList })),
        catchError(error => of(listActions.loadSubcontractorsSharingListError({ error })))
      ))
    );
  });

  getSubcontractorsPeriods$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.loadSubcontractorsPeriodsStart),
      switchMap(payload => this.subcontractorsService.getSubcontractorsPeriods(payload.projectId).pipe(
        map(periods => listActions.loadSubcontractorsPeriodsSuccess({ periods })),
        catchError(error => of(listActions.loadSubcontractorsPeriodsError({ error })))
      ))
    );
  });

  setSubcontractorsPeriods$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.setSubcontractorsPeriodsStart),
      switchMap(payload => this.subcontractorsService.setSubcontractorsPeriods(
        payload.projectId, payload.contractorId, payload.dateFrom, payload.dateTo, payload.areDetailsIncluded, payload.targetPeriod
      ).pipe(
        catchError(error => {
          if (error.status === 400) {
            this.toastService.open(
              this.translateService.translate('SUBCONTRACTORS.TRANSFER_CANNOT'),
              ToastType.Error
            );
          } else {
            this.handleError(error);
          }
          return of();
        })
      ))
    );
  }, { dispatch: false });

  removeSubcontractor$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.removeSubcontractorStart),
      switchMap(payload => {
        return this.subcontractorsService.removeSubcontractor(payload.projectId, payload.contractorId).pipe(
          map(_ => listActions.removeSubcontractorSuccess(
            { projectId: payload.projectId, selectedContractorId: payload.selectedContractorId }
          )),
          catchError(error => {
            this.handleError(error);
            return of(listActions.removeSubcontractorError({ error }));
          })
        );
      })
    );
  });

  removeSubcontractorError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.removeSubcontractorError),
      tap(payload => {
        if (payload.error?.status === 409) {
          this.krosModalService.openCentered(
            GenericInfoDialogComponent,
            {
              header: this.translateService.translate('SUBCONTRACTORS.DELETE_CONTRACTOR'),
              description: this.translateService.translate('SUBCONTRACTORS.DELETE_CONTRACTOR_CANNOT'),
              secondaryDescription: this.translateService.translate('SUBCONTRACTORS.DELETE_CONTRACTOR_CANNOT_INFO'),
              closeButtonText: this.translateService.translate('COMMON.CLOSE')
            },
            {
              closeOnBackdropClick: false,
              fullscreenOnMobile: false,
              showMobileArrowBack: false
            },
            NaN,
            NaN,
            undefined,
            'no-min-width'
          );
        }
      })
    );
  }, { dispatch: false });

  editSubcontractor$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.editSubcontractorStart),
      switchMap(payload => {
        return this.subcontractorsService.editSubcontractor(payload.projectId, payload.contractorData).pipe(
          map(_ => listActions.editSubcontractorSuccess(
            { projectId: payload.projectId, selectedContractorId: payload.selectedContractorId }
          )),
          catchError(error => {
            this.handleError(error);
            return of(listActions.editSubcontractorError({ error }));
          })
        );
      })
    );
  });

  removeOrEditSubcontractorSuccess$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.removeSubcontractorSuccess, listActions.editSubcontractorSuccess),
      map(payload => listActions.loadSubcontractorsStart(
        { projectId: payload.projectId, selectedContractorId: payload.selectedContractorId }
      ))
    );
  });

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private subcontractorsService: SubcontractorsService,
    private dispatchersService: SubcontractorsDispatchersService,
    private removeItemsService: RemoveItemsService,
    private krosModalService: KrosModalService
  ) { }

  private handleError(error: HttpErrorResponse): void {
    if (error.status === 403) {
      const message = this.translateService.translate('PROJECTS.ERROR.USER') +
        this.userService.getUserEmail() +
        this.translateService.translate('PROJECTS.ERROR.NO_PERMISSION');
      this.toastService.open(message, ToastType.Error);
    } else if (error.status === 404) {
      const message = this.translateService.translate('PROJECTS.ERROR.PROJECT_NOT_EXISTS');
      this.toastService.open(message, ToastType.Error);
    }
  }
}
