import { ComponentRef, TemplateRef, Type } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';

import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

/**
 * Close types with data to be returned as result
 */
export interface KrosModalCloseEvent<T = any> {
  type: 'submit' | 'cancel' | 'backdrop' | 'redirect';
  data: T;
}

/**
 * Modal can take TemplateRef / Component / string to be displayed within
 */
export type ModalContent = TemplateRef<any> | Type<any> | string;

/**
 * Additional settings
 */
export interface KrosModalSettings {
  /** Determines whether the modal should be closed after clicking on the backdrop */
  closeOnBackdropClick: boolean;

  /** Allows to hide default mobile modal header with arrow-back button */
  showMobileArrowBack: boolean;

  /** Determines whether modal should be fullscreen on mobile or not */
  fullscreenOnMobile: boolean;

  /** Determines whether modal should auto capture focus */
  allowFocusAutocapture: boolean;

  /** Include modal in browser's history as "#modal" and close it on step back */
  addModalToBrowsersHistory: boolean;

  /** Determines whether the modal should be closed after pressing Escape */
  closeOnEscClick: boolean;

  /** Allows to hide X button to close modal/messagebox */
  showXButton: boolean;

  /** If user's screen is smaller as modal height + 215px and not use openCentered() set this properties */
  setSmallScreen?: boolean;

  /** Adds a gradient to the bottom of the modal window. TODO: If we want - Add a new property to set the gradient position */
  addGradient?: boolean;
}

export function getDefaultKrosModalSettings(): KrosModalSettings {
  return {
    closeOnBackdropClick: true,
    closeOnEscClick: true,
    showMobileArrowBack: true,
    fullscreenOnMobile: true,
    allowFocusAutocapture: true,
    addModalToBrowsersHistory: false,
    showXButton: true,
    addGradient: false
  };
}

export class KrosModalRef<T = any> {
  beforeClose$;
  afterClosed$;
  componentRef: ComponentRef<any>;

  private beforeClose = new Subject<KrosModalCloseEvent<T>>();
  private afterClosed = new Subject<KrosModalCloseEvent<T>>();

  constructor(
    public overlay: OverlayRef,
    public content: ModalContent,
    public settings: KrosModalSettings,
    public data: T,
  ) {
    this.beforeClose$ = this.beforeClose.asObservable().pipe(take(1));
    this.afterClosed$ = this.afterClosed.asObservable().pipe(take(1));
    if (this.settings.closeOnBackdropClick) {
      // Click on backdrop acts as close, if is this behavior enabled
      overlay.backdropClick().pipe(take(1)).subscribe(_ => this.close('backdrop', null));
    }
  }

  submit(data?: T): void {
    this.close('submit', data);
  }

  cancel(result: KrosModalCloseEvent['type'] = 'cancel'): void {
    this.close(result, null);
  }

  redirect(data?: T): void {
    this.close('redirect', data);
  }

  private close(type: KrosModalCloseEvent['type'], data?: T): void {
    const closeValue = { type, data };

    this.beforeClose.next(closeValue);
    this.overlay.dispose();

    // timeout is needed to keep modal chains working
    // otherwise it will open modal in the same moment as it is closing the old one
    setTimeout(() => this.afterClosed.next(closeValue), 50);
  }
}
