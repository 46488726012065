<div class="main-wrapper">
  <div class="button-container">
    <button
      class="btn btn-primary btn-submit"
      data-test="create-invoice-mobile-button"
      type="button"
      (click)="onCreateInvoiceClick()"
    >+ {{ 'BUILDING_PROGRESS.INVOICE.NEW_INVOICE' | translate }}</button>
  </div>

  <div class="invoices-container">
    <ng-container *ngFor="let period of periodsInvoices">
      <div class="period-row">
        {{period.dateFrom | periodDate : period.dateTo }}
      </div>

      <div
        *ngFor="let invoiceDetail of period.invoiceDetails; let last = last"
        class="invoice-row"
        [ngClass]="{ 'last-row': last }"
        data-test="invoice-row"
      >
        <div class="invoice-number">{{invoiceDetail.documentNumber}}</div>

        <div class="invoice-separator">|</div>

        <div class="invoice-date">{{invoiceDetail.invoiceDate | date: 'dd.MM.yyyy'}}</div>

        <i
          data-test="invoice-open"
          (click)="onOpenInvoiceClick(invoiceDetail)"
          class="material-icons invoice-opener"
        >open_in_new
        </i>
      </div>
    </ng-container>
  </div>
</div>
