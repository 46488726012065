import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { KrosModalService } from '@kros-sk/components';
import { TranslateService } from '@kros-sk/ssw-shared-legacy';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { ApprovalType } from '../../enums';
import { BudgetApprovalModel } from '../../models';
import { BudgetApprovalRangeModalComponent } from '../budget-approval-range-modal/budget-approval-range-modal.component';

@Component({
  selector: 'app-export-range',
  templateUrl: './export-range.component.html',
  styleUrls: ['./export-range.component.scss']
})
export class ExportRangeComponent extends UnsubscribeComponent implements OnInit {

  rangeLabel: string;

  @Input() selectedIds: number[] = [];
  @Input() budgetApprovalData: BudgetApprovalModel;

  @Output() changeSheetIdsSelected = new EventEmitter<number[]>();

  constructor(
    private krosModalService: KrosModalService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.setRangeLabel(new Set(this.selectedIds));
  }

  openRangeModal(): void {
    const modalRef = this.krosModalService.openCentered(
      BudgetApprovalRangeModalComponent,
      {
        budgetApprovalData: this.budgetApprovalData,
        selectedIds: new Set(this.selectedIds)
      },
      {
        addModalToBrowsersHistory: false
      }
    );

    this.subs.sink = modalRef.afterClosed$
      .subscribe(result => {
        if (result.type === 'submit' && result.data) {
          this.setRangeLabel(result.data.changeSheetIds);
          this.changeSheetIdsSelected.emit(result.data.changeSheetIds);
        }
      });
  }

  setRangeLabel(changeSheetIds: Set<number>): void {
    const changeSheets = this.budgetApprovalData.items.filter(i => i.approvalType === ApprovalType.Approved ||
      i.approvalType === ApprovalType.NotApproved);

    if (changeSheetIds.size === changeSheets.length) {
      this.rangeLabel = this.translateService.translate('BUDGET_APPROVAL.EXPORT.ALL_CHANGE_SHEETS');
    } else {
      const unapprovedChangeSheets = changeSheets.filter(s => s.approvalType === ApprovalType.NotApproved);
      const approvedChangeSheets = changeSheets.filter(s => s.approvalType === ApprovalType.Approved);
      let foundMatch = false;

      if (changeSheetIds.size === unapprovedChangeSheets.length &&
        unapprovedChangeSheets.every(s => changeSheetIds.has(s.id))) {
        this.rangeLabel = this.translateService.translate('BUDGET_APPROVAL.EXPORT.UNAPPROVED_CHANGE_SHEETS');
        foundMatch = true;
      }

      if (!foundMatch && changeSheetIds.size === approvedChangeSheets.length &&
        approvedChangeSheets.every(s => changeSheetIds.has(s.id))) {
        this.rangeLabel = this.translateService.translate('BUDGET_APPROVAL.EXPORT.APPROVED_CHANGE_SHEETS');
        foundMatch = true;
      }

      if (!foundMatch) {
        this.rangeLabel = this.translateService.translate('BUDGET_APPROVAL.EXPORT.SELECTED_CHANGE_SHEETS') +
          `(${changeSheetIds.size})`;
      }
    }
  }
}
