<app-budget-approval-container data-test="budget-approval-container"></app-budget-approval-container>

<ng-container *ngIf="actionInProgress$ | async as action">
  <kros-spin-loader
    [title]="('BUDGET_APPROVAL.APPROVE.' +
            (action === actionInProgress.approve ? 'APPROVE_IN_PROGRESS_TITLE' : 'CANCEL_APPROVAL_IN_PROGRESS_TITLE' )
            ) | translate"
    [description]="('BUDGET_APPROVAL.APPROVE.' +
            (action === actionInProgress.approve ? 'APPROVE_IN_PROGRESS_DESCRIPTION' : 'CANCEL_APPROVAL_IN_PROGRESS_DESCRIPTION' )
            ) | translate"
  ></kros-spin-loader>
</ng-container>
