<div class="kros-modal-header">
  <div class="modal-title">
    <h4>{{ 'TAGS.MY_TAGS' | translate }}</h4>
    <span>({{ 'TAGS.DISPLAYED_ONLY_TO_YOU' | translate }})</span>
  </div>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onCloseClick()"
  >
    <i class="material-icons secondary">close</i>
  </button>

  <button
    class="btn btn-outline-primary btn-new-tag"
    type="button"
    id="ga-tag-settings-new-tag"
    data-test="tag-settings-new-tag"
    (click)="onCreateNewTagClick()"
  >{{ 'TAGS.CREATE_NEW_TAG' | translate }}</button>
</div>

<div class="kros-modal-body">
  <div
    *ngFor="let tag of tags"
    class="tag-row ga-tag-settings-edit-tag"
    data-test="tag-settings-edit-tag"
    (click)="onTagEditClick(tag)"
  >
    <kros-tag
      [color]="tag.textColorCode"
      [backgroundColor]="tag.colorCode"
    >{{ getTagName(tag.name) }}</kros-tag>

    <button
      class="btn btn-link action-button"
      type="button"
      data-test="tag-settings-btn-edit"
    >
      <i class="material-icons">edit</i>
    </button>
  </div>
</div>

<div class="kros-modal-footer">
  <button
    class="btn btn-primary"
    type="button"
    id="ga-tag-settings-close"
    data-test="tag-settings-close"
    (click)="onCloseClick()"
  >{{ 'COMMON.CLOSE' | translate }}</button>
</div>
