<div class="content-container">
  <ng-container *ngIf="!(isLoading$ | async) else loading">
    <ng-container *ngIf="hasItems; else noItems">
      <cdk-table
        cdk-table
        class="table"
        [dataSource]="items"
      >
        <ng-container
          *ngIf="allowMultiSelection"
          [cdkColumnDef]="selectionColumnName"
        >
          <cdk-header-cell *cdkHeaderCellDef>
            <input
              type="checkbox"
              [krosIndeterminate]="editHelper.indeterminate"
              [formControl]="editHelper.selectAllFormControl"
              (change)="editHelper.onSelectAll($event.target.checked)"
            />
          </cdk-header-cell>

          <cdk-cell
            tabindex="0"
            *cdkCellDef="let row"
            [id]="visualHelper.getId(items, row, selectionColumnName)"
          >
            <input
              type="checkbox"
              [formControl]="editHelper.getFormControl(items, row, selectionColumnName)"
              (change)="editHelper.onSelectionChange()"
            />
          </cdk-cell>

          <cdk-footer-cell *cdkFooterCellDef>
          </cdk-footer-cell>
        </ng-container>

        <ng-container
          *ngFor="let col of columnsConfig.columns; let ci = index"
          [cdkColumnDef]="col.name"
        >
          <cdk-header-cell *cdkHeaderCellDef>
            <div>{{col.titleKey | translate}}</div>
          </cdk-header-cell>

          <cdk-cell
            tabindex="0"
            *cdkCellDef="let row"
            [id]="visualHelper.getId(items, row, col.name)"
            [ngClass]="{'focused-cell': visualHelper.isCellSelected(items, row, col.name)}"
            (mousedown)="selectCell(row, col.name)"
            (keydown)="onCellKeyDown($event)"
          >
            <ng-container *ngIf="!editHelper.isExpression(col) else expression">
              <input
                class="cell-input"
                type="text"
                autocomplete="off"
                *ngIf="col.editable else noEdit"
                [readonly]="readonly"
                [formControl]="editHelper.getFormControl(items, row, col.name)"
                [maxlength]="col.maxLength"
                (focusout)="!areMatchedBoqs && doEdit(row, col.name, true)"
              />

              <ng-template #noEdit>
                {{ col.valueGetter ? col.valueGetter(row[col.name]) : row[col.name] }}
              </ng-template>
            </ng-container>

            <ng-template #expression>
              <p
                tabindex="0"
                contenteditable
                class="cell-input"
                [readonly]="readonly"
                (keydown)="expressionKeyDown($event, row)"
                [formControl]="editHelper.getFormControl(items, row, col.name)"
                (focusout)="!areMatchedBoqs && doEdit(row, col.name, true)"
                (mousedown)="trySelectMeasurementOrBoq($event, row)"
              ></p>
            </ng-template>
          </cdk-cell>

          <cdk-footer-cell *cdkFooterCellDef>
            <div [attr.data-test]="'boq-edit-footer-' + col.name">
              {{columnsConfig.footerRow.valueGetter(ci,items)}}
            </div>
          </cdk-footer-cell>
        </ng-container>

        <cdk-header-row *cdkHeaderRowDef="visualHelper.columns; sticky: true;"></cdk-header-row>

        <cdk-row
          *cdkRowDef="let item; columns: visualHelper.columns; let i = index"
          [id]="visualHelper.getRowId(items, item)"
          [ngClass]="{focused: i === visualHelper.rowIndex && !readonly}"
          [class]="visualHelper.getCellClass(item.boqItemType)"
        ></cdk-row>

        <cdk-footer-row *cdkFooterRowDef="visualHelper.columns; let i = index"></cdk-footer-row>
      </cdk-table>

      <ng-container *ngTemplateOutlet="showAllBoqMeasurementsButton"></ng-container>
    </ng-container>
  </ng-container>

  <div
    class="controls-container"
    *ngIf="showControls"
  >
    <dx-toolbar>
      <ng-container *ngFor="let item of additionalToolbarItemsBefore">
        <dxi-item
          location="before"
          widget="dxButton"
          locateInMenu="auto"
          showText="always"
        >
          <div *dxTemplate>
            <ng-container *ngTemplateOutlet="item"></ng-container>
          </div>
        </dxi-item>
      </ng-container>

      <dxi-item
        location="before"
        widget="dxButton"
        locateInMenu="auto"
        showText="always"
      >
        <div *dxTemplate>
          <dx-button
            class="ignore-click-out"
            icon="add"
            text="{{ 'BILL_OF_QUANTITIES.ADD_ROW' | translate }}"
            hint="{{ 'BILL_OF_QUANTITIES.ADD_ROW' | translate }}"
            [disabled]="readonly"
            (onClick)="onCreate()"
            data-test="boq-add-row-button"
          >
          </dx-button>
        </div>
      </dxi-item>

      <dxi-item
        location="before"
        widget="dxButton"
        locateInMenu="auto"
        showText="always"
      >
        <div *dxTemplate>
          <dx-button
            class="ignore-click-out"
            icon="trash"
            text="{{ 'BILL_OF_QUANTITIES.REMOVE_ROW' | translate }}"
            hint="{{ 'BILL_OF_QUANTITIES.REMOVE_ROW' | translate }}"
            [disabled]="!items?.length || readonly"
            (onClick)="onDelete()"
            data-test="boq-delete-row-button"
          >
          </dx-button>
        </div>
      </dxi-item>

      <ng-container *ngFor="let item of additionalToolbarItems">
        <dxi-item
          location="before"
          widget="dxButton"
          locateInMenu="auto"
          showText="always"
        >
          <div *dxTemplate>
            <ng-container *ngTemplateOutlet="item"></ng-container>
          </div>
        </dxi-item>
      </ng-container>
    </dx-toolbar>

    <div
      #additionalButtonsRef
      id="additional-buttons"
      class="controls-right"
      [class.small-panel]="isSmallPanel"
    >
      <ng-container *ngTemplateOutlet="additionalButtons"></ng-container>

      <button
        *ngIf="(!readonly && (edited || editHelper.isAffected))"
        class="btn btn-outline-secondary"
        type="button"
        (click)="onCancel()"
        data-test="boq-cancel-button"
      >{{'COMMON.ZRUSIT' | translate}}</button>

      <button
        *ngIf="canShowDefaultSave && !readonly"
        class="btn btn-primary btn-save"
        type="button"
        [disabled]="!canSave"
        (click)="onSave()"
        data-test="boq-save-button"
      >{{'COMMON.ULOZIT' | translate}}</button>
    </div>
  </div>
</div>

<ng-template #loading>
  <kros-timeline [type]="timelineType.Table"></kros-timeline>
</ng-template>

<ng-template #noItems>
  <p
    class="no-items"
    data-test="no-boq-items"
  >{{ 'BILL_OF_QUANTITIES.NO_BOQ_ITEMS' | translate }}</p>
</ng-template>
