<div class="toolbar-actions">
  <div class="actions left">
    <button
      class="btn toolbar-action gray-toolbar-action no-selection"
      data-test="building-object-upload"
      (click)="onUpload()"
    >
      <i class="material-icons icon-button">add_business</i>

      <span class="toolbar-action-text">{{ 'BUILDING_OBJECTS.TOOLBAR.UPLOAD' | translate }}</span>
    </button>
  </div>
</div>
