import { Component, inject, OnInit } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';

import { filter, switchMap } from 'rxjs/operators';

import {
  AnalyticsService,
  ApplicationBaseComponent,
  ApplicationModuleIdProviderService,
  getProjectIdFromUrl,
  LicenseSelectorsService,
  LicenseUrlService
} from '@kros-sk/ssw-shared-legacy';
import { LicenseSelectorsService as LicenseCdkSelectorsService, StorageService } from '@kros-sk/ssw-cdk';
import { SettingService } from '@kros-sk/ssw-shared/settings';

import { environment } from '../environments/environment';
import { PermissionHelper } from './building-progress/helpers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends ApplicationBaseComponent implements OnInit {

  protected appInsightCloudRole = 'Kros.Ssw.FE.Titan';

  private licenseSelectorsService = inject(LicenseSelectorsService);
  private licenseCdkSelectorsService = inject(LicenseCdkSelectorsService);
  private analyticsService = inject(AnalyticsService);
  private storage = inject(StorageService);
  private settingService = inject(SettingService);
  private applicationModuleIdProviderService = inject(ApplicationModuleIdProviderService);
  private titleService = inject(Title);

  selectedLanguage: any;
  projectId: number;
  licenseService = inject(LicenseUrlService);
  buildingProgressPermissionHelper = inject(PermissionHelper);
  currentLicenseInfo$ = this.applicationModuleIdProviderService.applicationModuleId$.pipe(
    switchMap(id => this.licenseSelectorsService.getLicenseInfoByModule(id))
  );
  desktopLicense$ = this.licenseCdkSelectorsService.desktopLicense$;
  budgetLicense$ = this.licenseCdkSelectorsService.budgetLicense$;
  licenseDetail$ = this.licenseSelectorsService.licenseDetail$;
  isAreaTabVisible: boolean;
  ff$ = this.authSelectorsService.featureFlags$;
  routePrefix: string;

  get projectRoutePrefix(): string {
    return `/projects/project-detail/${this.projectId}`;
  }

  get isBuildingProgress(): boolean {
    return this.router.url.includes('building-progress') && !this.router.url.includes('settings');
  }

  get isQuantityManager(): boolean {
    return this.router.url.includes('quantity-manager');
  }

  get isWholeBuilding(): boolean {
    const urlWithoutQuery = this.router.url.split('?')[0];
    return urlWithoutQuery.endsWith('building-progress');
  }

  get settingsRouterLink(): string[] {
    return this.settingService.getSettingsRouterLink(this.projectIdRouterLink);
  }

  private get projectIdRouterLink(): string {
    return `${this.projectId > 0 ? this.projectId : +this.settingsProjectId}`;
  }

  private get settingsProjectId(): string {
    return !!sessionStorage ? this.storage.getItemFromSessionStorage('settings-project-id') ?? '0' : '0';
  }

  ngOnInit(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.projectId = getProjectIdFromUrl(this.router.url, 'project-detail');
        this.onChangeRoute();
      });

    this.titleService.setTitle(this.isCzechVersion ? 'BIM platforma' : 'KROS platforma');

    super.ngOnInit();
  }

  private checkIsAreaTabVisible(): void {
    this.isAreaTabVisible = !this.router.url.includes('secondDocumentVersionId');
  }

  private onChangeRoute(): void {
    if (this.isQuantityManager) {
      const currentUrlItems = this.router.url.split('/');
      currentUrlItems.splice(0, 1);
      currentUrlItems.splice(currentUrlItems.length - 1);
      this.routePrefix = currentUrlItems.join('/');
      this.checkIsAreaTabVisible();
    }
  }

  protected init(): void {
    this.analyticsService.analyticContainerId = environment.analyticContainerId;
    this.analyticsService.bindAnalytic(document, this.router, this.notifyUrls);
    this.translateService.langChanged.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.selectedLanguage = this.translateService.languageOptions.filter(
          language => language.id === this.translateService.currentLang
        )[0];
      });
  }

  private notifyUrls(event: NavigationEnd): boolean {
    return event.urlAfterRedirects.includes('/projects');
  }
}
