import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '../translate';

@Pipe({ name: 'pluralisationByCount' })
export class PluralisationByCountPipe implements PipeTransform {
  constructor(
    private translateService: TranslateService
  ) { }

  transform(count: number, translateKeyBase: string): string {
    let key: string;

    if (count === 1) {
      key = translateKeyBase;
    } else if (count <= 4) {
      key = translateKeyBase + 'S_TO_4';
    } else {
      key = translateKeyBase + 'S';
    }

    return this.translateService.translate(key);
  }
}
