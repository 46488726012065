import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Actions, ofType } from '@ngrx/effects';
import { DxTextAreaModule } from 'devextreme-angular';
import { DxTreeListComponent, DxTreeListModule } from 'devextreme-angular/ui/tree-list';
import { filter, Observable, shareReplay, switchMap, take } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

import { BudgetSharedDispatchersService } from '@kros-sk/ssw-budget/shared/data-access/store';
import { BudgetTreeComponent, onFocusedCellChanging, uneditableBuildingObjectDataFields } from '@kros-sk/ssw-budget/shared/features/tree';
import { BuildingObject } from '@kros-sk/ssw-budget/shared/data-access/models';
import {
  codeFieldName,
  emptyKey,
  isConstruction,
  isConstructionOrBuildingObject,
  ProjectDetail,
  TranslateService
} from '@kros-sk/ssw-shared-legacy';
import { KrosModalService, MessageTypes } from '@kros-sk/components';

import { BuildingObjectsDispatchersService, BuildingObjectsSelectorsService, uploadBuildingObjectsSuccess } from '../../../data-access';

@Component({
  selector: 'budget-building-objects-upload-table',
  templateUrl: './building-objects-upload-table.component.html',
  styleUrls: ['./building-objects-upload-table.component.scss'],
  standalone: true,
  imports: [CommonModule, DxTreeListModule, DxTextAreaModule, TranslateModule]
})
export class BuildingObjectsUploadTableComponent extends BudgetTreeComponent<BuildingObject> implements OnInit {

  protected selectors = inject(BuildingObjectsSelectorsService);
  private krosModalService = inject(KrosModalService);
  private dispatchers = inject(BuildingObjectsDispatchersService);
  private sharedDispatchers = inject(BudgetSharedDispatchersService);
  private translateService = inject(TranslateService);
  private storeActions = inject(Actions);

  @ViewChild('dataTreeListRef', { static: false }) dataTreeList: DxTreeListComponent;

  @Input() set project(project: ProjectDetail) {
    if (project) {
      this.sharedDispatchers.setProject(project);
      this.sharedDispatchers.resolveBudget(project.id);
      this.dispatchers.loadBuildingObjects();
      this.sharedDispatchers.getBudgetSettings();
    }
  }
  @Input() launchedFrom: 'budget' | 'building-progress';

  dataSource$: Observable<BuildingObject[]>;
  settings$ = this.sharedSelectors.settings$;
  selectedBuildingObject: BuildingObject;
  focusedRowIndex = 0;

  protected get dataComponent(): DxTreeListComponent {
    return this.dataTreeList;
  }

  protected get isLastRowFocused(): boolean {
    return this.dataSource.length - 1 === this.focusedCell?.rowIndex;
  }

  protected get selectedItemId(): string {
    return this.selectedBuildingObject?.id;
  }

  ngOnInit(): void {
    if (this.launchedFrom === 'building-progress') {
      this.selectors.canLoadBuildingObjectsState$.pipe(filter(Boolean), takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.dispatchers.loadBuildingObjectsState());
    } else if (this.launchedFrom === 'budget') {
      this.dispatchers.loadBuildingObjectsState();
    }

    const fullyLoadedDataSource$ = this.selectors.areBuildingObjectsFullyLoaded$.pipe(
      filter(Boolean),
      shareReplay(1),
      takeUntilDestroyed(this.destroyRef)
    );

    this.dataSource$ = fullyLoadedDataSource$.pipe(
      switchMap(() => this.selectors.buildingObjects$),
      takeUntilDestroyed(this.destroyRef)
    );

    fullyLoadedDataSource$.pipe(
      switchMap(() => this.selectors.buildingObjects$),
      take(1),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((dataSource) => {
      this.dataSource = dataSource;
      const rootObject = dataSource.find(buildingObject => buildingObject.parentId === null)?.id;
      this.expandRows([rootObject]);
    });

    this.storeActions.pipe(ofType(uploadBuildingObjectsSuccess), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.deselectAll());
  }

  onCellPrepared(e: any): void {
    if (e.rowType === 'data') {
      const data: BuildingObject = e.data;
      const element: HTMLElement = e.cellElement;
      const id = `${e.column.dataField}-row-${e.data.id}`;
      if (!element.classList.contains(id)) {
        element.classList.add(id);
      }
      this.styleBuildingObjectCell(element, data, e.column.dataField);
      if (e.column.dataField === 'itemType') {
        element.classList.add('text-center');
      }
      if (e.column.dataField === 'description') {
        element.classList.add('level-' + data.level);
      }
      if (e.column.dataField === 'state') {
        if (e.value === 'BA') {
          element.classList.add('badge-ba');
        } else if (e.value === 'BP') {
          element.classList.add('badge-bp');
        }
      }
    }
  }

  onCellClick(e: any): void {
    if (e.key === emptyKey && !uneditableBuildingObjectDataFields.includes(e.column.dataField)) {
      e.component.closeEditCell();
      setTimeout(() => e.component.editCell(e.rowIndex, e.columnIndex), 100);
    }
  }

  onRowPrepared(e: any): void {
    if (e.rowType === 'data') {
      if (isConstruction(e.data) || e.data.id === emptyKey) {
        e.rowElement.classList.add('hide-drag-button');
      }
      const id = `row-${e.data.id}`;
      if (!e.rowElement.classList.contains(id)) {
        e.rowElement.classList.add(id);
      }
    }
  }

  uploadBuildingObjects(): void {
    const data = this.dataTreeList.instance.getSelectedRowsData('all');
    if (data.length > 0) {
      this.dispatchers.uploadBuildingObjects(data.map((item: BuildingObject) => item.id));
    } else {
      this.openNothingSelectedDialog();
    }
  }

  onSelectionChange(event: any): void {
    const selectedIndex = event.currentSelectedRowKeys[0];
    const unselectedIndex = event.currentDeselectedRowKeys[0];
    if (selectedIndex) {
      this.dataTreeList.instance.selectRows(this.getAllDescendants(selectedIndex), true);
    } else if (unselectedIndex) {
      this.dataTreeList.instance.deselectRows(this.getAllDescendants(unselectedIndex));
    }
  }

  onFocusedCellChanging(e: any): void {
    onFocusedCellChanging(e, this.isAddingNewItem);
  }

  private getAllDescendants(nodeId): number[] {
    const allDescendants = [];
    const dataSource = this.dataSource;
    function getChildren(currentNodeId): void {
      const children = dataSource?.filter(o => o.parentId === currentNodeId) ?? [];
      for (const child of children) {
        allDescendants.push(child);
        getChildren(child.id);
      }
    }
    getChildren(nodeId);
    return allDescendants.map(object => object.id);
  }

  protected getState(value: string): string {
    return value === 'BA'
      ? this.translateService.translate('BUILDING_OBJECTS.BUDGET_APPROVAL')
      : value === 'BP'
        ? this.translateService.translate('BUILDING_OBJECTS.BUILDING_PROGRESS')
        : '';
  }

  protected dispatchActionSelectItem(item: any): void { }

  protected selectRow(itemId: string, columnIndex?: number): void {
    this.focusedRowKey = itemId;
    this.focusedRowIndex = this.dataComponent.instance.getRowIndexByKey(itemId);
    this.dataTreeList.instance.selectRowsByIndexes([this.focusedRowIndex]);
    const row: any = this.dataTreeList.instance.getVisibleRows().find(r => r.rowIndex === this.focusedRowIndex);
    if (this.focusedRowIndex === row?.rowIndex) {
      const cell = columnIndex !== undefined
        ? row.cells.find(c => c.columnIndex === columnIndex)
        : this.dataTreeList.focusedColumnIndex > -1 && row.key !== emptyKey
          ? row.cells.find(c => c.columnIndex === this.dataTreeList.focusedColumnIndex)
          : row.cells.find(c => c.column.dataField === codeFieldName);
      this.focusedCell = { rowIndex: row.rowIndex, columnIndex: cell.columnIndex };
      this.dataTreeList.instance.focus(cell.cellElement);
    }
  }

  protected checkFocusedRow(e: any): void {
    const rowIndex = e.component.getRowIndexByKey(this.selectedItemId);
    if (!e.component.isRowFocused(this.selectedItemId) && rowIndex > -1) {
      this.selectRow(this.selectedItemId);
    }
  }

  private styleBuildingObjectCell(element: HTMLElement, data: BuildingObject, dataField: string): void {
    if (isConstructionOrBuildingObject(data) && dataField !== 'email' && dataField !== 'dateChanged') {
      element.classList.add('font-blue', 'font-bold', 'large-font');
    }
    if (dataField === 'itemType') {
      element.classList.add('text-center');
    } else if (dataField === 'description' || dataField === 'code') {
      element.classList.add('level-' + data.level);
    }
  }

  private openNothingSelectedDialog(): void {
    this.krosModalService.openModalMessageBoxModalRef({
      icon: 'warning',
      caption: this.translateService.translate('BUDGET.UPLOAD_TO_BUILDING_PROGRESS'),
      message: this.translateService.translate('BUILDING_OBJECTS.SELECTED_ITEM_REQUIRED'),
      messageType: MessageTypes.Warning,
      acceptButton: this.translateService.translate('COMMON.CLOSE')
    });
  }

  private deselectAll(): void {
    this.dataTreeList?.instance.deselectAll();
  }
}
