<div class="kros-modal-header">
  <h4 class="kros-modal-title">{{'BUILDING_PROGRESS.EXPORT_ITEM_DRAW_SHEET' | translate }}</h4>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onCloseClick()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div class="kros-modal-body">
  <form
    [formGroup]="exportForm"
    (ngSubmit)="onSubmit()"
  >
    <div
      class="export-periods-empty-info"
      *ngIf="periods.length === 0 else exportSettings"
    >
      {{'BUILDING_PROGRESS.EXPORT_PERIODS_EMPTY_INFO' | translate}}
    </div>

    <ng-template #exportSettings>
      <div class="form-container">
        <div class="form-group period-selector">
          <app-period-range-control
            [periods]="periods"
            [tooltipSinglePeriod]="'BUILDING_PROGRESS.EXPORT_SINGLE_PERIOD_ITEM_DRAW_SHEET_TOOLTIP' | translate"
            [tooltipPeriodRange]="'BUILDING_PROGRESS.EXPORT_PERIOD_RANGE_ITEM_DRAW_SHEET_TOOLTIP' | translate"
            [periodRangeTitle]="'BUILDING_PROGRESS.EXPORT_PERIOD_RANGE' | translate"
            [singlePeriodTitle]="'BUILDING_PROGRESS.EXPORT_SINGLE_PERIOD' | translate"
            formControlName="periodIds"
          ></app-period-range-control>
        </div>

        <div class="form-group">
          <label for="contractorName">{{ "BUILDING_PROGRESS.EXPORT_ON_BEHALF_OF_CONTRACTOR" | translate }}</label>
          <input
            id="contractorName"
            class="form-control"
            type="text"
            formControlName="contractorName"
            data-test="contractor-name-input"
          >
        </div>

        <div class="form-group">
          <label for="supervisorName">{{ "BUILDING_PROGRESS.EXPORT_CONSTRUCTION_SUPERVISOR" | translate }}</label>
          <input
            id="supervisorName"
            class="form-control"
            type="text"
            formControlName="supervisorName"
            data-test="supervisor-name-input"
          >
        </div>

        <div class="form-group export-group checkbox">
          <input
            id="areDetailsIncluded"
            class="form-control"
            type="checkbox"
            formControlName="areDetailsIncluded"
            data-test="are-details-included-checkbox"
          >
          <label for="areDetailsIncluded">{{ "BUILDING_PROGRESS.EXPORT_DETAILS" | translate }}</label>
        </div>

        <div class="flex-space-between">
          <div class="export-date">
            <kros-datepicker-input
              data-test="export-datepicker"
              [options]="{
                formControl: exportForm.get('exportDate'),
                name: 'exportDate',
                label: ('BUILDING_PROGRESS.DATE' | translate),
                icon: 'date_range',
                iconPosition: 'right',
                noClearButton: true,
                noTodayButton: true,
                positions: [{
                  originX: 'start',
                  originY: 'top',
                  overlayX: 'start',
                  overlayY: 'bottom'
                }]
              }"
            ></kros-datepicker-input>
          </div>

          <div class="header-data-edit">
            <span>{{'PROJECTS.DETAIL.HEADER_DATA_SETTINGS' | translate}}</span>
            <i
              class="material-icons no-selection ga-pv-export-header-data-open"
              data-test="edit-header-data"
              krosTooltip="{{'PROJECTS.DETAIL.EDIT_HEADER_DATA_SETTINGS' | translate}}"
              (click)="onHeaderDataEdit()"
            >edit</i>
          </div>
        </div>
      </div>
    </ng-template>
  </form>
</div>

<div class="kros-modal-footer">
  <button
    class="btn btn-primary btn-submit"
    [disabled]="!exportForm.valid"
    id="ga-export"
    data-test="draw-sheet-export-button"
    type="button"
    (click)="onSubmit()"
  >{{'BUILDING_PROGRESS.EXPORT_ACTION' | translate}}</button>
</div>
