import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { KrosInputsModule } from '../inputs';
import { PostCodeSelectorComponent } from './post-code-selector/post-code-selector.component';
import { StreetSelectorComponent } from './street-selector/street-selector.component';
import { TownSelectorComponent } from './town-selector/town-selector.component';

@NgModule({
  declarations: [
    PostCodeSelectorComponent,
    TownSelectorComponent,
    StreetSelectorComponent
  ],
  imports: [
    CommonModule,
    KrosInputsModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  exports: [
    PostCodeSelectorComponent,
    TownSelectorComponent,
    StreetSelectorComponent
  ]
})
export class AddressSelectorModule { }
