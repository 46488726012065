import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { BootstrapModule } from '../bootstrap';
import { ChartModule } from '../chart';
import { ColumnFilterComponent } from './column-filter/column-filter.component';
import { ColumnIconComponent } from './column-icon/column-icon.component';
import { ComponentRendererComponent } from './component-renderer/component-renderer.component';
import { DataTableComponent } from './data-table.component';
import { DataTableKeyboardHandlingService } from './helpers';
import { DirectivesModule } from '../directives';
import { PipesModule } from '../pipes';
import { TranslateModule } from '../translate/translate.module';

@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    FormsModule,
    BootstrapModule,
    TranslateModule.forChild(),
    ScrollingModule,
    ChartModule,
    PipesModule,
    TooltipDirective
  ],
  declarations: [
    DataTableComponent,
    ComponentRendererComponent,
    ColumnIconComponent,
    ColumnFilterComponent
  ],
  exports: [
    DataTableComponent,
    ColumnFilterComponent
  ],
  providers: [
    DataTableKeyboardHandlingService
  ]
})
export class DataTableModule { }
