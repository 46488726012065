import { createAction, props } from '@ngrx/store';

import { BuildingProgressPermission } from '@kros-sk/ssw-shared/permission';
import { BuildingProgressSettings } from '@kros-sk/ssw-shared/settings';
import {
  SearchModel,
  SetColorCodeModel,
  SetColorCodeResult
} from '@kros-sk/ssw-shared-legacy';

import { BuildingProgressDeleteItem } from '../../building-progress/models/building-progress-delete-item.model';
import { BuildingProgressEditModel } from '../../building-progress/models/building-progress-edit.model';
import {
  BuildingProgressItem,
  BuildingProgressModel,
  BuildingProgressPeriodItem
} from '../../building-progress/models/construction-data.model';
import { BuildingProgressPercentageCompleteModel } from '../../building-progress/models/building-progress-percentage-complete.model';
import { BuildingProgressPeriod } from '../../building-progress/models/building-progress-period.model';
import { BuildingProgressSetModel } from '../../building-progress/models/building-progress-set.model';
import { BuildingProgressSharing } from '../../building-progress/models/building-progress-sharing/building-progress-sharing.model';
import { BuildingProgrressEditPercentageModel } from '../../building-progress/models/building-progress-edit-percentage.model';
import { DeleteItemModel } from '../../building-progress/models/item-list-delete.model';
import { ItemDetails } from '../../building-progress/models/item-period-details.model';
import { ItemDrawSheetExportSettings } from '../../building-progress/models/item-draw-sheet-export-settings.model';
import { Note, NoteSetModel } from '../../building-progress/models/note.model';
import { RemainingBudgetExportSettings } from '../../building-progress/models/remaining-budget-export-settings.model';
import { SelectedView } from '../../building-progress/models/selected-view.model';
import { SetAutocalculatedAmountModel } from '../../building-progress/models/set-autocalculated-amount.model';
import { TableExportSettings } from '../../building-progress/models/table-export-settings.model';
import { TotalPriceSetModel } from '../../building-progress/models/total-price-set.model';

export const loadConstructionDataStart = createAction(
  '[BuildingProgress] Load construction data start',
  props<{ projectId: number, searchModel: SearchModel, hierarchyCode?: string, contractorId?: number }>());
export const loadConstructionDataSuccess = createAction(
  '[BuildingProgress] Load construction data success', props<{ constructionData: BuildingProgressModel, contractorId?: number }>());
export const loadConstructionDataError = createAction(
  '[BuildingProgress] Load construction data error', props<{ error: any, constructionData: BuildingProgressModel }>());

export const loadSelectedConstructionDataStart = createAction(
  '[BuildingProgress] Load selected construction data start',
  props<{ projectId: number, searchModel: SearchModel, contractorId?: number }>());
export const loadSelectedConstructionDataSuccess = createAction(
  '[BuildingProgress] Load selected construction data success',
  props<{ constructionData: BuildingProgressModel, contractorId?: number }>());
export const loadSelectedConstructionDataError = createAction(
  '[BuildingProgress] Load selected construction data error', props<{ error: any, constructionData: BuildingProgressModel }>());

export const editConstructionDataStart = createAction('[BuildingProgress] Edit construction data start',
  props<{ editModel: BuildingProgressEditModel, isUndoRedoOperation: boolean, showSuccessToast?: boolean }>());
export const editConstructionDataProgress = createAction(
  '[BuildingProgress] Edit construction data in progress',
  props<{
    editModel: BuildingProgressEditModel,
    unmarkedBoqItemIds: number[],
    isUndoRedoOperation: boolean
  }>());
export const editConstructionDataSuccess = createAction(
  '[BuildingProgress] Edit construction data success',
  props<{ editModel: BuildingProgressEditModel, editedItems: BuildingProgressItem[], isUndoRedoOperation: boolean }>());
export const editConstructionDataRedo = createAction('[BuildingProgress] Edit construction data redo',
  props<{ editModel: BuildingProgressEditModel, isUndoRedoOperation: boolean }>());
export const editConstructionDataError = createAction('[BuildingProgress] Edit construction data error', props<{ error: any }>());

export const setConstructionDataStart = createAction('[BuildingProgress] Set construction data start',
  props<{ editModel: BuildingProgressSetModel }>());
export const setConstructionDataSuccess = createAction('[BuildingProgress] Set construction data success',
  props<{ editedItems: BuildingProgressItem[], editModel: BuildingProgressSetModel }>());
export const setConstructionDataError = createAction('[BuildingProgress] Set construction data error', props<{ error: any }>());

export const editPercentageStart = createAction('[BuildingProgress] Edit percentage start',
  props<{
    editModel: BuildingProgrressEditPercentageModel,
    constructionData?: BuildingProgressModel,
    oldValues?: BuildingProgressSetModel,
    isUndoRedoOperation: boolean,
    showSuccessToast?: boolean
  }>());
export const editPercentageSuccess = createAction(
  '[BuildingProgress] Edit percentage success',
  props<{
    editedItems: BuildingProgressItem[],
    editModel: BuildingProgrressEditPercentageModel,
    oldValues: BuildingProgressSetModel,
    isUndoRedoOperation: boolean
  }>());
export const editPercentageError = createAction('[BuildingProgress] Edit percentage error', props<{ error: any }>());

export const loadPeriodsStart = createAction('[BuildingProgress] Load periods start',
  props<{ projectId: number, keepSelectedPeriods: boolean, contractorId?: number, wholeBuilding?: boolean }>());
export const loadPeriodsSuccess = createAction('[BuildingProgress] Load periods success',
  props<{ periods: BuildingProgressPeriod[], keepSelectedPeriods: boolean, wholeBuilding?: boolean }>());
export const loadPeriodsError = createAction('[BuildingProgress] Load periods error', props<{ error: any }>());

export const loadPermissionStart = createAction('[BuildingProgress] Load permission start', props<{ projectId: number }>());
export const loadPermissionSuccess = createAction(
  '[BuildingProgress] Load permission success', props<{ permission: BuildingProgressPermission }>());
export const loadPermissionError = createAction('[BuildingProgress] Load permission error', props<{ error: any }>());

export const loadSharingListStart = createAction('[BuildingProgress] Load sharing list start', props<{ projectId: number }>());
export const loadSharingListSuccess = createAction(
  '[BuildingProgress] Load sharing list success', props<{ sharingList: BuildingProgressSharing[] }>());
export const loadSharingListError = createAction('[BuildingProgress] Load sharing list error', props<{ error: any }>());

export const setAutocalculatedAmountStart = createAction(
  '[BuildingProgress] Set autocalculated amount start',
  props<{
    autocalculatedAmount: SetAutocalculatedAmountModel,
    periodItem?: BuildingProgressPeriodItem,
    oldValues?: BuildingProgressSetModel,
    isUndoRedoOperation: boolean
  }>());
export const setAutocalculatedAmountSuccess = createAction(
  '[BuildingProgress] Set autocalculated amount success',
  props<{
    editedItems: BuildingProgressItem[],
    autocalculatedAmount: SetAutocalculatedAmountModel,
    oldValues: BuildingProgressSetModel,
    isUndoRedoOperation: boolean
  }>());
export const setAutocalculatedAmountError = createAction('[BuildingProgress] Set autocalculated amount error', props<{ error: any }>());

export const completePercentageStart = createAction(
  '[BuildingProgress] Complete percentage start',
  props<{
    percentageCompleteModel: BuildingProgressPercentageCompleteModel,
    constructionData?: BuildingProgressModel,
    oldValues?: BuildingProgressSetModel,
    isUndoRedoOperation: boolean,
    showSuccessToast?: boolean
  }>());
export const completePercentageSuccess = createAction(
  '[BuildingProgress] Complete percentage success', props<{
    editedItems: BuildingProgressItem[],
    percentageCompleteModel: BuildingProgressPercentageCompleteModel,
    oldValues: BuildingProgressSetModel,
    isUndoRedoOperation: boolean
  }>());
export const completePercentageError = createAction('[BuildingProgress] Complete percentage error', props<{ error: any }>());

export const createPeriodStart = createAction(
  '[BuildingProgress] Create period start',
  props<{ projectId: number, dateFrom: Date, dateTo: Date, searchModel: SearchModel, addToWholeBuildingPeriods?: boolean }>());
export const createPeriodSuccess = createAction(
  '[BuildingProgress] Create period success', props<{ id: number, dateFrom: Date, dateTo: Date, addToWholeBuildingPeriods?: boolean }>());
export const createPeriodError = createAction('[BuildingProgress] Create period error', props<{ error: any, loading: boolean }>());

export const editPeriodStart = createAction(
  '[BuildingProgress] Edit period start', props<{
    id: number,
    projectId: number,
    dateFrom: Date,
    dateTo: Date,
    basicVatRate: number,
    reducedVatRate: number,
    searchModel: SearchModel }>());
export const editPeriodSuccess = createAction(
  '[BuildingProgress] Edit period success', props<{
    id: number,
    dateFrom: Date,
    dateTo: Date,
    basicVatRate: number,
    reducedVatRate: number,
    items: BuildingProgressItem[] }>());
export const editPeriodError = createAction('[BuildingProgress] Edit period error', props<{ error: any, loading: boolean }>());

export const deletePeriodStart = createAction(
  '[BuildingProgress] Delete period start', props<{
    id: number,
    deleteDocuments: boolean,
    projectId: number,
    searchModel: SearchModel
  }>());
export const deletePeriodSuccess = createAction(
  '[BuildingProgress] Delete period success', props<{ id: number }>());
export const deletePeriodError = createAction('[BuildingProgress] Delete period error', props<{ error: any }>());

export const setApprovedPeriodStart = createAction(
  '[BuildingProgress] Set approved period start', props<{
    projectId: number,
    periodId: number,
    isApprovalRequested: boolean,
    isApproved: boolean,
    isMultiStageApprovalModeEnabled: boolean }>());
export const setApprovedPeriodSuccess = createAction(
  '[BuildingProgress] Set approved period success', props<{
    projectId: number,
    periodId: number,
    isApprovalRequested: boolean,
    isApproved: boolean,
    isMultiStageApprovalModeEnabled: boolean }>());
export const setApprovedPeriodError = createAction('[BuildingProgress] Set approved period error', props<{ error: any }>());

export const cancelApprovedPeriodStart = createAction(
  '[BuildingProgress] Cancel approved period start',
  props<{ periodId: number, projectId: number, searchModel: SearchModel, contractorId?: number }>());
export const cancelApprovedPeriodSuccess = createAction(
  '[BuildingProgress] Cancel approved period success', props<{ periodId: number }>());
export const cancelApprovedPeriodError = createAction('[BuildingProgress] Cancel approved period error', props<{ error: any }>());

export const setIsApprovalRequestedPeriodStart = createAction(
  '[BuildingProgress] Set approval requested period start', props<{ projectId: number, periodId: number }>());
export const setIsApprovalRequestedPeriodSuccess = createAction(
  '[BuildingProgress] Set approval requested period success', props<{ periodId: number }>());
export const setIsApprovalRequestedPeriodError = createAction('[BuildingProgress] Set approved period error', props<{ error: any }>());

export const cancelIsApprovalRequestedPeriodStart = createAction(
  '[BuildingProgress] Cancel approval requested period start',
  props<{ projectId: number, periodId: number, searchModel: SearchModel, contractorId?: number }>());
export const cancelIsApprovalRequestedPeriodSuccess = createAction(
  '[BuildingProgress] Cancel approval requested period success', props<{ id: number }>());
export const cancelIsApprovalRequestedPeriodError = createAction(
  '[BuildingProgress] Cancel approval requested period error', props<{ error: any }>());

export const cancelIsApprovalRequestedPeriods = createAction(
  '[BuildingProgress] Cancel approval requested periods',
  props<{ projectId: number }>());

export const setIsLockedPeriodStart = createAction(
  '[BuildingProgress] Set lock period start', props<{ projectId: number, periodId: number }>());
export const setIsLockedPeriodSuccess = createAction(
  '[BuildingProgress] Set lock period success', props<{ periodId: number }>());
export const setIsLockedPeriodError = createAction('[BuildingProgress] Set lock period error', props<{ error: any }>());

export const cancelIsLockedPeriodStart = createAction(
  '[BuildingProgress] Cancel lock period start', props<{ periodId: number, projectId: number, searchModel: SearchModel }>());
export const cancelIsLockedPeriodSuccess = createAction(
  '[BuildingProgress] Cancel lock period success', props<{ periodId: number }>());
export const cancelIsLockedPeriodError = createAction('[BuildingProgress] Cancel lock period error', props<{ error: any }>());

export const selectPeriodId = createAction(
  '[BuildingProgress] Select period id', props<{ id: number }>());

export const markPeriodInFilter = createAction(
  '[BuildingProgress] Mark period in filter', props<{ id: number, isMarked: boolean }>());
export const clearAllMarkedPeriodsInFilter = createAction(
  '[BuildingProgress] Clear all marked periods in filter', props<{ periods: BuildingProgressPeriod[] }>());

export const multiSelectItems = createAction(
  '[BuildingProgress] Select building progress item', props<{ id: number, state: boolean }>());
export const clearMultiSelectItems = createAction(
  '[BuildingProgress] Clear selected building progress items');
export const multiSelectAllItems = createAction(
  '[BuildingProgress] Select all building progress items');

export const totalPriceSetStart = createAction(
  '[BuildingProgress] Set total price start',
  props<{
    priceSetModel: TotalPriceSetModel,
    constructionData?: BuildingProgressModel,
    oldValues?: BuildingProgressSetModel,
    isUndoRedoOperation: boolean
  }>());
export const totalPriceSetSuccess = createAction(
  '[BuildingProgress] Set toal price success', props<{
    editedItems: BuildingProgressItem[],
    priceSetModel: TotalPriceSetModel,
    oldValues: BuildingProgressSetModel,
    isUndoRedoOperation: boolean
  }>());
export const totalPriceSetError = createAction('[BuildingProgress] Set total price error', props<{ error: any }>());

export const setColorCodesStart = createAction(
  '[BuildingProgress] Set color codes start', props<{
    setColorCodeModel: SetColorCodeModel,
    constructionData?: BuildingProgressModel,
    oldValues?: SetColorCodeModel,
    isUndo?: boolean,
    isUndoRedoOperation: boolean
  }>());
export const setColorCodesSuccess = createAction(
  '[BuildingProgress] Set color codes success', props<{
    setColorCodeResult: SetColorCodeResult,
    setColorCodeModel: SetColorCodeModel,
    constructionData: BuildingProgressModel,
    oldValues: SetColorCodeModel,
    isUndoRedoOperation: boolean
  }>());
export const setColorCodesError = createAction('[BuildingProgress] Set color codes error', props<{ error: any }>());

export const deleteItemsStart = createAction('[BuildingProgress] Delete items start', props<{
  projectId: number,
  deleteItemsModel: DeleteItemModel
}>());
export const deleteItemsSuccess = createAction('[BuildingProgress] Delete items success', props<{
  buildingProgressDeleteItem: BuildingProgressDeleteItem
}>());
export const deleteItemsError = createAction('[BuildingProgress] Delete items error', props<{ error: any }>());

export const loadSettingsStart = createAction(
  '[BuildingProgress] Load settings start', props<{ projectId: number }>());
export const loadSettingsSuccess = createAction(
  '[BuildingProgress] Load settings success', props<{ settings: BuildingProgressSettings }>());
export const loadSettingsError = createAction('[BuildingProgress] Load settings error', props<{ error: any }>());

export const loadLicenseAgreementStart = createAction('[BuildingProgress] Load license agreement start');
export const loadLicenseAgreementSuccess = createAction(
  '[BuildingProgress] Load license agreement success', props<{ licenseAgreement: boolean }>());
export const loadLicenseAgreementError = createAction('[BuildingProgress] Load license agreement error', props<{ error: any }>());

export const confirmLicenseAgreementStart = createAction('[BuildingProgress] Confirm license agreement start');
export const confirmLicenseAgreementSuccess = createAction('[BuildingProgress] Confirm license agreement success');

export const openPeriodDetailsPanel = createAction(
  '[BuildingProgress] Open period details panel', props<{ itemId: number, period: BuildingProgressPeriodItem }>());
export const closePeriodDetailsPanel = createAction('[BuildingProgress] Close period details panel');

export const loadItemPeriodDetailsStart = createAction(
  '[BuildingProgress] Load item period details start', props<{ detailId: number }>());
export const loadItemPeriodDetailsSuccess = createAction(
  '[BuildingProgress] Load item period details success', props<{ details: ItemDetails }>());
export const loadItemPeriodDetailsError = createAction('[BuildingProgress] Load item period details error', props<{ error: any }>());

export const savePeriodDetailsStart = createAction(
  '[BuildingProgress] Save item period details start',
  props<{ itemId: number, period: BuildingProgressPeriodItem, details: ItemDetails }>());
export const savePeriodDetailsSuccess = createAction(
  '[BuildingProgress] Save item period details success', props<{ details: ItemDetails, newDetailId: number }>());
export const savePeriodDetailsError = createAction('[BuildingProgress] Save item period details error', props<{ error: any }>());

export const changeSelectedView = createAction('[BuildingProgress] Change selected view', props<{ selectedView: SelectedView }>());

export const loadTableExportSettingsStart = createAction('[BuildingProgress] Load table export settings start');
export const loadTableExportSettingsSuccess = createAction(
  '[BuildingProgress] Load table export settings success', props<{ tableExportSettings: TableExportSettings }>());
export const loadTableExportSettingsError = createAction('[BuildingProgress] Load table export settings error', props<{ error: any }>());

export const changeTableExportSettings = createAction(
  '[BuildingProgress] Change table export settings',
  props<{ tableExportSettings: TableExportSettings }>());

export const loadHasDocumentsStart = createAction(
  '[BuildingProgress] Load has documents start',
  props<{ projectId: number }>());
export const loadHasDocumentsSuccess = createAction(
  '[BuildingProgress] Load has documents success', props<{ hasDocuments: boolean }>());
export const loadHasDocumentsError = createAction('[BuildingProgress] Load has documents error', props<{ error: any }>());

export const setHasPeriodInvoices = createAction(
  '[BuildingProgress] Set has period invoices', props<{ ids: number[], hasInvoices: boolean }>());

export const loadItemDrawSheetExportSettingsStart = createAction(
  '[BuildingProgress] Load item draw sheet export settings start',
  props<{ projectId: number }>());
export const loadItemDrawSheetExportSettingsSuccess = createAction(
  '[BuildingProgress] Load item draw sheet export settings success', props<ItemDrawSheetExportSettings>());
export const loadItemDrawSheetExportSettingsError = createAction(
  '[BuildingProgress] Load item draw sheet export settings error', props<{ error: any }>());

export const loadRemainingBudgetExportSettingsStart = createAction(
  '[BuildingProgress] Load remaining budget export settings start',
  props<{ projectId: number }>());
export const loadRemainingBudgetExportSettingsSuccess = createAction(
  '[BuildingProgress] Load remaining budget export settings success', props<RemainingBudgetExportSettings>());
export const loadRemainingBudgetExportSettingsError = createAction(
  '[BuildingProgress] Load remaining budget export settings error', props<{ error: any }>());

export const setItemsNotCompletedStart = createAction(
  '[BuildingProgress] Set items not completed start',
  props<{ projectId: number, budgetItemIds: number[], notCompleted: boolean }>());
export const setItemsNotCompletedSuccess = createAction(
  '[BuildingProgress] Set items not completed success', props<{ editedItems: BuildingProgressItem[] }>());
export const setItemsNotCompletedError = createAction('[BuildingProgress] Set items not completed error', props<{ error: any }>());

export const loadBudgetHasItemsStart = createAction(
  '[BuildingProgress] Load budget has items start', props<{ projectId: number }>());
export const loadBudgetHasItemsSuccess = createAction(
  '[BuildingProgress] Load budget has items success', props<{ projectId: number, budgetHasItems: boolean }>());
export const loadBudgetHasItemsError = createAction('[BuildingProgress] Load budget has items error', props<{ error: any }>());

export const startBudgetCreationStart = createAction(
  '[BuildingProgress] Start budget creation start', props<{ projectId: number }>());
export const startBudgetCreationSuccess = createAction('[BuildingProgress] Start budget creation success');
export const startBudgetCreationError = createAction('[BuildingProgress] Start budget creation error', props<{ error?: any }>());

export const loadBudgetCreationActiveStateStart = createAction(
  '[BuildingProgress] Load budget creation active state start', props<{ projectId: number }>());
export const loadBudgetCreationActiveStateSuccess = createAction(
  '[BuildingProgress] Load budget creation active state success', props<{ isProcessing: boolean }>());
export const loadBudgetCreationActiveStateError = createAction(
  '[BuildingProgress] Load budget creation active state error', props<{ error: any }>());

export const loadNotesStart = createAction(
  '[BuildingProgress] Load notes start', props<{ projectId: number, contractorId?: number }>());
export const loadNotesSuccess = createAction(
  '[BuildingProgress] Load notes success', props<{ notes: Note[] }>());
export const loadNotesError = createAction('[BuildingProgress] Load notes error', props<{ error: any }>());

export const saveBuildingProgressNoteStart = createAction(
  '[BuildingProgress] Save building progress note start', props<{ projectId: number, note: NoteSetModel }>());
export const saveBuildingProgressNoteSuccess = createAction(
  '[BuildingProgress] Save building progress note success', props<{ note: Note }>());
export const saveBuildingProgressNoteError = createAction(
  '[BuildingProgress] Save building progress note error', props<{ error: any }>());

export const updateVatRateStart = createAction(
  '[BuildingProgress] Update vat rate start', props<{ projectId: number }>());
export const updateVatRateError = createAction(
  '[BuildingProgress] Update vat rate error', props<{ error: any }>());
