import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AnnounceKitModule, KrosDropdownModule, KrosNavigationModule } from '@kros-sk/components';
import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { AnalyticsModule } from '../analytics';
import { AvatarsModule } from '../avatars';
import { DirectivesModule } from '../directives';
import { DocumentTitleService } from './document-title-service/document-title.service';
import { HelpButtonComponent } from './help-button/help-button.component';
import { HelpService } from './shared/help.service';
import { NavbarComponent } from './navbar.component';
import { NavbarService } from './shared/navbar.service';
import { NewsComponent } from './news/news.component';
import { ProjectInfoModule } from '../project-info';
import { TranslateModule } from '../translate';
import { UserProfileModule } from '../user-profile/user-profile.module';
import { VideoTutorialService } from './shared/video-tutorial.service';

@NgModule({
  declarations: [
    HelpButtonComponent,
    NavbarComponent,
    NewsComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
    AnalyticsModule,
    TranslateModule.forChild(),
    NgbModule,
    AvatarsModule,
    DirectivesModule,
    AnnounceKitModule,
    KrosNavigationModule,
    KrosDropdownModule,
    ProjectInfoModule,
    UserProfileModule,
    TooltipDirective
  ],
  providers: [
    DocumentTitleService,
    NavbarService,
    HelpService,
    VideoTutorialService
  ],
  exports: [
    NavbarComponent,
    NewsComponent,
    KrosNavigationModule
  ]
})

export class NavbarModule {
  public static forRoot(
    location: ((appConfig: any) => string),
    homeUrlSelector: ((appConfig: any) => string)
  ): ModuleWithProviders<NavbarModule> {
    return {
      ngModule: NavbarModule,
      providers: [
        { provide: 'location', useValue: location },
        { provide: 'homeUrlSelector', useValue: homeUrlSelector }
      ]
    };
  }
}
