<div
  *ngIf="mainControl"
  [class.error]="showError || validationError"
  [class.input-wrapper]="true"
  [ngClass]="currentOptions.className"
  [krosTooltip]="showError || currentOptions.info ? validationMessagesTemplate : null"
  [position]="currentOptions.tooltipPlacement ? currentOptions.tooltipPlacement : 'above'"
  [tooltipShowManually]="true"
  #tooltip="krosTooltip"
  (mouseenter)="mouseEnter()"
  (mouseleave)="mouseLeave()"
  #container
>
  <label [attr.for]="uniqueId" *ngIf="currentOptions.label else fakeLabel">
    {{currentOptions.label}}
  </label>
  <ng-template #fakeLabel>
    <div *ngIf="currentOptions.fakeLabel" class="fake-label"></div>
  </ng-template>

  <input
    type="date"
    [attr.autocomplete]="currentOptions.autocompleteOff ? 'chrome-off' : 'on'"
    [attr.id]="deviceDetector.isMobileOS() ? uniqueId : ''"
    [attr.disabled]="mainControl.disabled ? 'disabled' : null"
    *ngIf="deviceDetector.isMobileOS()"
    class="form-control datepicker hidden-date"
    [ngClass]="currentOptions.inputClass"
    [formControl]="formControlNgb"
    [class.with-icon]="currentOptions.icon"
    [class.icon-right]="currentOptions.iconPosition === 'right'"
    [class.with-validation]="showError || currentOptions.info"
    [required]="currentOptions.required"
    [attr.data-test]="currentOptions.testName"
    (blur)="doBlur($event)"
    #dateInput
  />

  <input
    type="text"
    [attr.autocomplete]="currentOptions.autocompleteOff ? 'chrome-off' : 'on'"
    [attr.id]="deviceDetector.isMobileOS() ? '' : uniqueId"
    [attr.tab-index]="deviceDetector.isMobileOS() ? -1 : 0"
    class="form-control datepicker"
    [ngClass]="currentOptions.inputClass"
    [class.with-icon]="currentOptions.icon"
    [class.icon-right]="currentOptions.iconPosition === 'right'"
    [class.with-validation]="showError || currentOptions.info"
    [placeholder]="currentOptions.placeholder ? currentOptions.placeholder : ''"
    [formControl]="formControl"
    [required]="currentOptions.required"
    [attr.data-test]="currentOptions.testName"
    (focus)="doFocus($event)"
    (blur)="doBlur($event)"
    (click)="doClick()"
    #input
  />

  <ng-template #desktopDatepicker>
    <ngb-datepicker
      (mouseenter)="mouseEnterNgb()"
      (mouseleave)="mouseLeaveNgb()"
      [formControl]="formControlNgb"
      [startDate]="formControlNgb.value"
      navigation="arrows"
      [footerTemplate]="footerTemplate"
      (dateSelect)="setValue($event, true, true)"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [markDisabled]="currentOptions.markDisabled"
    ></ngb-datepicker>

    <ng-template #footerTemplate>
      <hr class="datepicker" [style.width.px]="width" />
      <div class="footer">
        <button *ngIf="!currentOptions.noTodayButton" type="button" class="btn btn-sm btn-outline-primary today-button" (click)="setTodayClick()" i18n="@@kros-datepicker.today-button.text">Today</button>
        <button *ngIf="!currentOptions.noClearButton && !currentOptions.required" type="button" class="btn btn-sm btn-outline-primary today-button" (click)="clearDateClick()" i18n="@@kros-datepicker.clear-button-button.text">
          Clear
        </button>
      </div>
    </ng-template>
  </ng-template>

  <span
    class="material-icons input-icon"
    *ngIf="currentOptions.icon"
    [class.no-label]="!currentOptions.fakeLabel && !currentOptions.label"
    [class.icon-right]="currentOptions.iconPosition === 'right'"
    [class.lg-input]="currentOptions.inputClass && currentOptions.inputClass.indexOf('form-control-lg') !== -1"
    [class.sm-input]="currentOptions.inputClass && currentOptions.inputClass.indexOf('form-control-sm') !== -1"
  >
    {{currentOptions.icon}}
  </span>

  <span
    *ngIf="showError"
    class="material-icons error-icon"
    [class.no-label]="!currentOptions.fakeLabel && !currentOptions.label"
    [class.lg-input]="currentOptions.inputClass && currentOptions.inputClass.indexOf('form-control-lg') !== -1"
    [class.sm-input]="currentOptions.inputClass && currentOptions.inputClass.indexOf('form-control-sm') !== -1"
  >warning</span>

  <span
    *ngIf="currentOptions.info"
    class="material-icons info-icon"
    [class.no-label]="!currentOptions.fakeLabel && !currentOptions.label"
    [class.lg-input]="currentOptions.inputClass && currentOptions.inputClass.indexOf('form-control-lg') !== -1"
    [class.sm-input]="currentOptions.inputClass && currentOptions.inputClass.indexOf('form-control-sm') !== -1"
  >info</span>
</div>

<ng-template #defaultAutocompleteTemplate let-r="result">
  <div
    [style.width.px]="width + 12"
    class="option"
    data-test="option"
  >
    {{r}}
  </div>
</ng-template>

<ng-template #validationMessagesTemplate>
  <kros-validation-messages
    #validationMessages
    *ngIf="showError || currentOptions.info"
    [formErrors]="mainControl?.errors"
    [info]="currentOptions.info"
    [customMessages]="currentOptions.errors"
  ></kros-validation-messages>
</ng-template>
