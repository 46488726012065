<div class="range">
  <button
    class="titan-dropdown-toggle btn"
    type="button"
    (click)="openRangeModal()"
    data-test="export-ba-range-button"
  >
    <span>{{rangeLabel}}</span>
    <span krosMaterialIcon>
      expand_more
    </span>
  </button>
</div>
