import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { GlobalEventsService } from '../global-events/global-events.service';

export enum DeviceType {
  Desktop,
  Mobile
}

/**
* @deprecated Use fromDeviceDetector selector from libs/shared/device-detector instead.
* Example:
* ```typescript
* import { fromDeviceDetector } from '@kros-sk/device-detector';
*
* protected isMobile$ = this.store.select(fromDeviceDetector.selectIsMobile);
* ```
*/
@Injectable({ providedIn: 'root' })
export class DeviceDetectorService {

  deviceType: DeviceType;
  deviceType$: Observable<DeviceType>;
  windowResized$: Observable<UIEvent>;
  readonly phoneMaxBreakpoint = 1024;
  readonly tabletMaxBreakpoint = 1344;
  readonly desktopMaxBreakpoint = 1600;

  private deviceTypeSubject: BehaviorSubject<DeviceType>;
  private windowResizedSubject: Subject<UIEvent>;

  constructor(
    private globalEventsService: GlobalEventsService,
    private platform: Platform
  ) {
    this.deviceType = this.isMobile(this.windowWidth) ? DeviceType.Mobile : DeviceType.Desktop;
    this.deviceTypeSubject = new BehaviorSubject(this.deviceType);
    this.deviceType$ = this.deviceTypeSubject.asObservable();
    this.windowResizedSubject = new Subject();
    this.windowResized$ = this.windowResizedSubject.asObservable();
    this.setVhUnit();
    this.globalEventsService.listenEvent('window:resize').subscribe((event) => {
      this.windowResized(event);
      this.setVhUnit();
    });
    if (this.platform.IOS) {
      document.body.classList.add('ios-device');
    }
  }

  get windowHeight(): number {
    return window.innerHeight; // counts height without address bar
  }

  get windowWidth(): number {
    return document.documentElement.clientWidth; // counts width without scrollbar
  }

  isMobileOS(): boolean {
    return this.platform.ANDROID || this.platform.IOS;
  }

  get isIOSOrIPadOS(): boolean {
    return /^iP/.test(navigator.platform);
  }

  get isIOS(): boolean {
    return this.platform.IOS;
  }

  get isMacOs(): boolean {
    return /(mac)/.test(navigator.platform.toLowerCase());
  }

  get isWindows(): boolean {
    return /(win)/.test(navigator.platform.toLowerCase());
  }

  get isSafari(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  get isFirefox(): boolean {
    return /(firefox|minefield)/i.test(navigator.userAgent);
  }

  private windowResized(event: any): void {
    let currentDevice = DeviceType.Desktop;

    if (this.isMobile(this.windowWidth)) {
      currentDevice = DeviceType.Mobile;
    }

    if (currentDevice !== this.deviceType) {
      this.deviceType = currentDevice;
      this.deviceTypeSubject.next(this.deviceType);
    }

    this.windowResizedSubject.next(event);
  }

  private isMobile(innerWidth: number): boolean {
    return innerWidth <= this.phoneMaxBreakpoint;
  }

  private isDesktop(innerWidth: number): boolean {
    return innerWidth > this.tabletMaxBreakpoint;
  }

  private setVhUnit(): void {
    const vh = this.windowHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
}
