import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { APP_CONFIG } from '@kros-sk/app-config';
import {
  OpenApiGenerateTokenResponse,
  OpenApiSubscriptionApiModel,
  OpenApiSubscriptionCreateApiModel,
  OpenApiSubscriptionCreateResponse,
  OpenApiSubscriptionCreateWithTokenResponse,
  OpenApiSubscriptionUpdateApiModel
} from '@kros-sk/models';
import { RequestsWithErrorHandling } from '@kros-sk/auth';

const subscriptionsListUrl = '/api/externalApplicationsService/openApiSubscriptions';

@Injectable({
  providedIn: 'root'
})
export class OpenApiService extends RequestsWithErrorHandling {

  subscriptions: OpenApiSubscriptionApiModel[] = [];

  subscriptionsS = new ReplaySubject<OpenApiSubscriptionApiModel[]>();
  subscriptions$ = this.subscriptionsS.asObservable();

  loadInProgressBS = new BehaviorSubject<boolean>(false);
  loadInProgress$ = this.loadInProgressBS.asObservable();

  constructor(
    http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any
  ) {
    super(http);
  }

  loadSubscriptions(): Observable<OpenApiSubscriptionApiModel[]> {
    this.loadInProgressBS.next(true);
    return this.httpRequest<OpenApiSubscriptionApiModel[]>(
      'GET',
      this.appConfig.appUrls.titanGatewayUrl + subscriptionsListUrl + '/list',
    ).pipe(
      tap(result => {
        this.subscriptions = result;
        this.subscriptionsS.next(this.subscriptions);
        this.loadInProgressBS.next(false);
      }),
      catchError(e => {
        this.subscriptionsS.next([]);
        this.loadInProgressBS.next(false);
        throw e;
      })
    );
  }

  deleteSubscription(guid: string): Observable<any> {
    return this.httpRequest(
      'DELETE',
      this.appConfig.appUrls.titanGatewayUrl + subscriptionsListUrl + '/delete',
      {
        clientId: guid,
      }
    ).pipe(
      tap(_ => {
        this.subscriptions = this.subscriptions.filter(s => s.clientId !== guid);
        this.subscriptionsS.next(this.subscriptions);
      })
    );
  }

  updateSubscription(value: OpenApiSubscriptionUpdateApiModel): Observable<any> {
    return this.httpRequest(
      'PUT',
      this.appConfig.appUrls.titanGatewayUrl + subscriptionsListUrl + '/edit',
      { name: value.name, clientId: value.clientId }
    ).pipe(
      tap(_ => {
        const index = this.subscriptions.findIndex(s => s.clientId === value.clientId);
        this.subscriptions[index] = {
          ...this.subscriptions[index],
          name: value.name,
          webHook: value.webHook,
          webHookSecret: value.webHookSecret,
          scope: value.scope
        };
        this.subscriptionsS.next(this.subscriptions);
      })
    );
  }

  createSubscription(value: OpenApiSubscriptionCreateApiModel): Observable<OpenApiSubscriptionCreateResponse> {
    return this.httpRequest<OpenApiSubscriptionCreateResponse>(
      'POST',
      this.appConfig.appUrls.titanGatewayUrl + subscriptionsListUrl + '/create',
      value
    ).pipe(
      tap(response => {
        this.subscriptions.push({
          ...value,
          clientId: response.clientId,
          secretHint: ''
        });
        this.subscriptionsS.next(this.subscriptions);
      })
    );
  }

  createSubscriptionWithToken(value: OpenApiSubscriptionCreateApiModel): Observable<OpenApiSubscriptionCreateWithTokenResponse> {
    return this.httpRequest<OpenApiSubscriptionCreateWithTokenResponse>(
      'POST',
      this.appConfig.appUrls.titanGatewayUrl + subscriptionsListUrl + '/create',
      { ...value, shouldCreateToken: true }
    ).pipe(
      tap(response => {
        this.subscriptions.push({
          ...value,
          clientId: response.clientId,
          secretHint: response.secretHint
        });
        this.subscriptionsS.next(this.subscriptions);
      })
    );
  }

  generateToken(id: any): Observable<OpenApiGenerateTokenResponse> {
    return this.httpRequest<OpenApiGenerateTokenResponse>(
      'POST',
      this.appConfig.appUrls.titanGatewayUrl + subscriptionsListUrl + '/tokenCreate',
      { clientId: id }
    ).pipe(
      tap(response => {
        const index = this.subscriptions.findIndex(s => s.clientId === id);
        this.subscriptions[index] = {
          ...this.subscriptions[index],
          secretHint: response.secretHint,
        };
        this.subscriptionsS.next(this.subscriptions);
      })
    );
  }
}
