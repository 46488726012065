import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ExternalApplicationOpenerComponent } from '@kros-sk/ssw-shared-legacy';
import { KrosAuthGuardService, LoginRedirectSswComponent } from '@kros-sk/auth';
import { LoadUserIdGuard } from '@kros-sk/ssw-cdk';
import { MaintenanceComponent } from '@kros-sk/components';

import { RootRerouteComponent } from './core/components/root-reroute/root-reroute.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'projects',
    pathMatch: 'full'
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent
  },
  {
    path: 'callida',
    component: RootRerouteComponent,
    canActivate: [KrosAuthGuardService, LoadUserIdGuard]
  },
  {
    path: 'projects',
    loadChildren: (): any => import('./projects/projects.module').then(m => m.ProjectsModule),
    canActivate: [KrosAuthGuardService, LoadUserIdGuard],
    canActivateChild: [KrosAuthGuardService, LoadUserIdGuard],
  },
  {
    path: 'settings',
    loadChildren: (): any => import('@kros-sk/ssw-shared/settings').then(m => m.SettingsModule),
    canActivate: [KrosAuthGuardService, LoadUserIdGuard]
  },
  {
    path: 'open-application/:error',
    component: ExternalApplicationOpenerComponent,
    canActivate: [KrosAuthGuardService]
  },
  {
    path: 'callback',
    component: RootRerouteComponent
  },
  {
    path: 'oidc/login-redirect',
    component: LoginRedirectSswComponent
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
