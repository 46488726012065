import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { DeviceDetectorService, DeviceType, KrosModalRef } from '@kros-sk/components';
import { IBudgetModel } from '@kros-sk/ssw-shared-legacy';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { BudgetApprovalSelectorsService } from '../../../store/budget-approval';

@Component({
  selector: 'app-budget-approval-item-panel-dialog',
  templateUrl: './budget-approval-item-panel-dialog.component.html',
  styleUrls: ['./budget-approval-item-panel-dialog.component.scss']
})
export class BudgetApprovalItemPanelDialogComponent extends UnsubscribeComponent implements OnInit {
  data: {
    title: string,
    tableId: string;
    selectorData: Observable<IBudgetModel>;
  };
  projectId: number;

  constructor(
    public selector: BudgetApprovalSelectorsService,
    private modalRef: KrosModalRef,
    private deviceDetector: DeviceDetectorService
  ) {
    super();
    this.data = modalRef.data;
  }

  ngOnInit(): void {
    this.subs.sink = this.deviceDetector.deviceType$.subscribe((type: DeviceType) => {
      if (type === DeviceType.Desktop) {
        this.modalRef.cancel();
      }
    });
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }
}
