import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GlobalEventsListenerService {

  // private registeredEvents: { [eventName: string]: Subject<Event> } = {};

  private renderer: Renderer2;

  constructor(private rendererFactory2: RendererFactory2) {
    this.renderer = this.rendererFactory2.createRenderer(null, null);
  }

  // listenEvent(eventName: string): Observable<Event> {
  //   if (this.registeredEvents[eventName]) {
  //     return this.registeredEvents[eventName].asObservable();
  //   }
  //
  //   this.registeredEvents[eventName] = new Subject();
  //   const [context, event] = eventName.split(':');
  //   this.renderer.listen(context, event, (evt) => {
  //     this.registeredEvents[eventName].next(evt);
  //   });
  //   return this.registeredEvents[eventName].asObservable();
  // }

  listenEvent(eventName: string): Observable<Event> {
    const [context, event] = eventName.split(':');

    return new Observable<Event>(subscriber => {
      return this.renderer.listen(context, event, e => {
        subscriber.next(e);
      });
    });
  }
}
