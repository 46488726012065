import { Component, OnInit } from '@angular/core';

import { DeviceDetectorService, DeviceType, KrosModalRef } from '@kros-sk/components';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

@Component({
  selector: 'app-project-members-panel-dialog',
  templateUrl: 'project-members-panel-dialog.component.html',
  styleUrls: ['project-members-panel-dialog.component.scss']
})
export class ProjectMembersPanelDialogComponent extends UnsubscribeComponent implements OnInit {

  constructor(
    private modalRef: KrosModalRef,
    private deviceDetector: DeviceDetectorService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.deviceDetector.deviceType$.subscribe((type: DeviceType) => {
      if (type === DeviceType.Desktop) {
        this.modalRef.cancel();
      }
    });
  }

  onProjectMembersSelected(): void {
    this.modalRef.submit();
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }
}
