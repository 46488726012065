import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BudgetApprovalSelectorsService } from '../../../store/budget-approval';

@Component({
  selector: 'app-panel-content',
  templateUrl: './panel-content.component.html',
  styleUrls: ['./panel-content.component.scss']
})
export class PanelContentComponent {

  @Input() collapsed = false;
  @Input() headerText: string;

  @Output() panelCollapsed: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public selector: BudgetApprovalSelectorsService
  ) { }

  toggleCollapsePanel(): void {
    this.collapsed = !this.collapsed;
    this.panelCollapsed.emit(this.collapsed);
  }
}
