import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { formatString, License, LicenseType } from '@kros-sk/ssw-cdk';

import { TranslateService } from '../translate';

@Pipe({
  name: 'licenseInfoFormat'
})
export class LicenseInfoFormatPipe implements PipeTransform {

  constructor(
    private translateService: TranslateService
  ) { }

  transform(license: License): string {
    if (license.licenseType === LicenseType.Free) {
      return this.translateService.translate('LICENSE_INFO.FREE_TEXT_SHORT');
    } else if (license.licenseType === LicenseType.Trial) {
      return formatString(
        this.translateService.translate('LICENSE_INFO.TRIAL_TEXT_SHORT'),
        license.remainingDays,
        this.dayCountPluralisation(license.remainingDays));
    } else if (license.licenseType === LicenseType.Full && license.isSchool) {
      return this.translateService.translate('LICENSE_INFO.SCHOOL_VERSION');
    } else if (license.licenseType === LicenseType.Full && license.remainingDays <= 30) {
      return formatString(
        this.translateService.translate('LICENSE_INFO.ENDING_TEXT'),
        license.remainingDays,
        this.dayCountPluralisation(license.remainingDays));
    } else if (license.licenseType === LicenseType.Full) {
      return formatString(
        this.translateService.translate('LICENSE_INFO.VALID_UNTIL'),
        formatDate(license.expirationDate, 'dd.MM.yyyy',
          'sk'));
    } else {
      return this.translateService.translate('LICENSE_INFO.READONLY_TEXT_SHORT');
    }
  }

  dayCountPluralisation(count: number): string {
    if (count === 1) {
      return this.translateService.translate('LICENSE_INFO.DAY');
    } else if (count <= 4) {
      return this.translateService.translate('LICENSE_INFO.DAYS_TO_4');
    } else {
      return this.translateService.translate('LICENSE_INFO.DAYS');
    }
  }
}
