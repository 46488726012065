/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { BudgetItem } from '@kros-sk/ssw-shared-legacy';
import { BuildingObject } from '@kros-sk/ssw-budget/shared/data-access/models';

@Injectable({
  providedIn: 'root'
})
export class BuildingObjectsTableService {

  private _resetState$ = new Subject<void>();
  private _expandRows$ = new Subject<string[]>();
  private _focusItem$ = new Subject<BuildingObject>();
  private _itemCreated$ = new Subject<BuildingObject>();
  private _itemMoved$ = new Subject<BudgetItem>();
  private _deleteEmptyRow$ = new Subject<void>();

  resetState$ = this._resetState$.asObservable();
  expandRows$ = this._expandRows$.asObservable();
  focusItem$ = this._focusItem$.asObservable();
  itemCreated$ = this._itemCreated$.asObservable();
  itemMoved$ = this._itemMoved$.asObservable();
  deleteEmptyRow$ = this._deleteEmptyRow$.asObservable();

  constructor() { }

  onResetState(): void {
    this._resetState$.next();
  }

  onLoaded = (items: BuildingObject[]): void => {
    let expandItems = items.filter(i => i.itemType === 'S');
    if (expandItems.length === 0) {
      expandItems = items.filter(i => i.itemType === 'O');
    }
    this._expandRows$.next(expandItems.map(i => i.id));
    this._focusItem$.next(items[0]);
  };

  getParentIds(buildingObjects: BuildingObject[], parentId: string): string[] {
    const parentIds = [];
    while (parentId) {
      parentIds.push(parentId);
      parentId = buildingObjects.find(o => o.id === parentId)?.parentId;
    }
    return parentIds;
  }

  itemCreated(item: BuildingObject): void {
    this._itemCreated$.next(item);
  }

  itemMoved(item: BudgetItem): void {
    this._itemMoved$.next(item);
  }

  deleteEmptyRow(): void {
    this._deleteEmptyRow$.next();
  }
}
