import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { createHttpRetryErrorForLog, httpRetryAndLog, HttpRetryResult } from '@kros-sk/core';
import { HandleMethodErrors, RequestsWithErrorHandling } from '@kros-sk/auth';

import { AzureCredentials, KrosBlobStorageApiBlobUpload } from '../models/azure-blob-storage.models';

@Injectable()
export class KrosApiBlobStorageService extends RequestsWithErrorHandling {

  private appInsightsService = inject(AppInsightsBaseService);

  constructor(
    httpClient: HttpClient
  ) {
    super(httpClient);
  }

  @HandleMethodErrors({ status: ['400', '401', '403', '500', '502', '503', '504'] })
  getDownloadToken(krosCredentialsEndPoint: string): Observable<AzureCredentials> {
    return this.httpRequest<AzureCredentials>('get', krosCredentialsEndPoint).pipe(
      httpRetryAndLog({
        retryOnStatus: /^(500|502|503|504|0)$/,
        retryDelays: [300, 600, 1200],
        loggerFn: this.logHttpRetryException('getDownloadToken', 'GET')
      })
    );
  }

  @HandleMethodErrors({ status: ['401', '500', '502', '503', '504'] })
  getUploadToken(krosCredentialsEndPoint: string, mimeType: string, OriginalName: string): Observable<AzureCredentials> {
    const body = {
      mimeType,
      OriginalName
    } as KrosBlobStorageApiBlobUpload;
    return this.httpRequest<AzureCredentials>('post', krosCredentialsEndPoint, body).pipe(
      httpRetryAndLog({
        retryOnStatus: /^(500|502|503|504)$/,
        retryDelays: [300, 600, 1200],
        loggerFn: this.logHttpRetryException('getUploadToken', 'POST')
      })
    );
  }

  @HandleMethodErrors({ status: ['500', '502', '503', '504'] })
  confirmUpload(apiEndpoint: string, confirmationObject: any): Observable<any> {
    return this.httpRequest('put', apiEndpoint, confirmationObject).pipe(
      httpRetryAndLog({
        retryOnStatus: /^(500|502|503|504)$/,
        retryDelays: [300, 600, 1200],
        loggerFn: this.logHttpRetryException('confirmUpload', 'PUT')
      })
    );
  }

  @HandleMethodErrors({ status: ['500', '502', '503', '504'] })
  delete(apiEndpoint: string): Observable<any> {
    return this.httpRequest('delete', apiEndpoint).pipe(
      httpRetryAndLog({
        retryOnStatus: /^(500|502|503|504|0)$/,
        retryDelays: [300, 600, 1200],
        loggerFn: this.logHttpRetryException('delete', 'DELETE')
      })
    );
  }

  private logHttpRetryException = (apiMethod: string, httpRequestMethod: string) => {
    return (log: HttpRetryResult): void => {
      const handler = 'KrosApiBlobStorageService';
      this.appInsightsService.trackException(createHttpRetryErrorForLog(handler, apiMethod, httpRequestMethod), handler, log);
    };
  };
}
