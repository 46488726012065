import {
  AfterViewInit,
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[contenteditable][formControlName], [contenteditable][formControl]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContenteditableValueAccessor),
      multi: true,
    },
  ],
})
export class ContenteditableValueAccessor implements ControlValueAccessor, AfterViewInit, OnDestroy, OnInit {

  @Input() readonly = false;

  private observer = new MutationObserver(() => {
    setTimeout(() => {
      this.onChange(
        ContenteditableValueAccessor.processValue(
          this.elementRef.nativeElement.innerHTML,
        ),
      );
    });
  });

  constructor(
    @Inject(ElementRef) private readonly elementRef: ElementRef,
    @Inject(Renderer2) private readonly renderer: Renderer2,
  ) { }

  private onTouched = (): void => { };

  private onChange: (value: string) => void = () => { };

  ngOnInit(): void {
    this.setDisabledState(this.readonly);
  }

  ngAfterViewInit(): void {
    this.observer.observe(this.elementRef.nativeElement, {
      characterData: true,
      childList: true,
      subtree: true,
    });
  }

  ngOnDestroy(): void {
    this.observer?.disconnect();
  }

  @HostListener('input')
  onInput(): void {
    this.observer.disconnect();
    this.onChange(
      ContenteditableValueAccessor.processValue(
        this.elementRef.nativeElement.innerHTML,
      ),
    );
  }

  @HostListener('blur')
  onBlur(): void {
    this.onTouched();
  }

  writeValue(value: string | null): void {
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'innerHTML',
      ContenteditableValueAccessor.processValue(value),
    );
  }

  registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.renderer.setAttribute(
      this.elementRef.nativeElement,
      'contenteditable',
      String(!disabled),
    );
  }

  private static processValue(value: unknown): string {
    return String(value == null ? '' : value);
  }
}
