import { HttpStatusCode } from '@angular/common/http';

import { ApplicationType } from '../base';

export interface LicenseApiModel {
  licenseType: LicenseType;
  applicationLicenses: ApplicationLicense[];
  autoRenew: boolean;
  nextPaymentDate: Date;
  customerNumber: string;
  userCount: UserCount;
  usedCompanyIds: number[];
  problemCode: LicenseProblem;
  isAcademicLicense: boolean;
}

export interface ApplicationLicense {
  applicationType: ApplicationType;
  validUntil: Date;
  daysValid: number;
  package: PackageType;
  modules: ModuleType[];
  trialExists: boolean;
}

export interface UnassignedLicenseApiModel extends LicenseApiModel {
  companyName: string;
  companyRegistrationId: string;
  validUntil: Date;
  package: PackageType;
  remainingCompaniesCount: number;
}

export interface License {
  applicationType: ApplicationType;
  type: PackageType;
  daysValid: number;
  validUntil: Date;
  modules: ModuleType[];
  autoRenew: boolean;
  nextPaymentDate: Date;
  customerNumber: string;
  trialExists: boolean;
  isAcademicLicense: boolean;
}

export interface CurrentLicense extends License {
  usedCompanyIds: number[];
  problemCode: LicenseProblem;
}

export interface UnassignedLicenseWithCompanies {
  companyName: string;
  companyRegistrationId: string;
  customerNumber: string;
  validUntil: Date;
  packageName: string;
  remainingCompaniesCount: number;
  assignedCompaniesNames: string[];
  isAcademicLicense: boolean;
}

export interface UsedCompaniesInLicenseApiModel {
  companyName: string;
  companyRegistrationId: string;
  customerNumber: string;
  validUntil: Date;
  usedCompanyIds: number[];
  packageType: PackageType;
  companiesCount: number;
  isAcademicLicense: boolean;
}

export interface UserCount {
  accountantsCount: number;
  licenseLimit: number;
  otherUsersCount: number;
  ownerEmail: string;
  recommendedAction: RecommendedAction;
}

export enum PackageName {
  Package = 'package',
  WarehousePackage = 'warehousePackage',
  DigitalOfficePackage = 'digitalOfficePackage'
}

export enum LicenseProblem {
  None,
  CompanyCountExceeded
}

export enum PackageType {
  Free,
  Pro,
  Flexi,
  Trial = 99
}

export type ModuleType = InvoicingModuleType | WarehouseModuleType;

export enum InvoicingModuleType {
  PriceOffers = 3,
  DeliveryNotes = 4,
  RegularInvoices = 5,
  NoAds = 6,
  Orders = 7,
  OpenApi = 8
}

export enum WarehouseModuleType {
  OpenApi = 2
}

export enum EshopTransactionType {
  LicensePurchase = 1,
  LicenseRenewal = 23,
  LicenseExtension = 31
  // no value in eshop request = New Purchase
}

export enum LicenseType {
  ProjectManagement = ApplicationType.ProjectManagement,
  Platform = 125,
}

export enum PurchaseType {
  month = 4,
  year = 2 // 12 months
}

export enum RecommendedAction {
  NoAction = 0,
  CountExceededOwner = 1,
  CountExceededUser = 2,
}

export interface LicensesState {
  currentLicense: CurrentLicense | null;
  allLicenses: License[];
  userCount: UserCount | null;
  licensesResponseStatus: HttpStatusCode;
}

export enum EshopApplicationNumber {
  Invoicing = 0,
  Warehouse = 1,
  DigitalOffice = 2,
}
