import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, map, Observable, throwError } from 'rxjs';

import { TranslateService } from '@kros-sk/ssw-shared-legacy';

import { BuildingProgressSharing } from '../models/building-progress-sharing/building-progress-sharing.model';
import {
  BuildingProgressSharingResultInfo, BuildingProgressSharingResultInfoEnum
} from '../models/building-progress-sharing/building-progress-sharing-result-info.model';
import { BuildingProgressSharingViewModel } from '../models/building-progress-sharing/building-progress-sharing.view-model';
import { environment } from '../../../environments/environment';

const gatewayApi = '/api/projects/';
const buildingProgressSharingApi = '/buildingProgressService/sharing';
const buildingProgressSharingListEndpoint = '/sharingList/';
const buildingProgressShareEndpoint = '/share';

@Injectable()
export class BuildingProgressSharingService {

  private get gatewayApi(): string {
    return environment.appUrls.titanGatewayUrl + gatewayApi;
  }

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
  ) { }

  getSharingList(projectId: number): Observable<BuildingProgressSharing[]> {
    return this.http.get<BuildingProgressSharing[]>(
      this.getEndpointPath(projectId, buildingProgressSharingListEndpoint) + projectId)
      .pipe(map((res) => {
        res.forEach(p => p.additionalPermissionType = p.buildingProgressPermissionType);
        return res;
      }));
  }

  share(shareViewModel: BuildingProgressSharingViewModel): Observable<BuildingProgressSharingResultInfo> {
    return this.http.post<BuildingProgressSharingResultInfo>(
      this.getEndpointPath(shareViewModel.projectId, buildingProgressShareEndpoint), shareViewModel)
      .pipe(
        catchError(this.handleShareError.bind(this))
      );
  }

  public handleShareError(error: HttpErrorResponse): Observable<never> {
    const info: BuildingProgressSharingResultInfo = error.error;
    let errorMessage: string;

    switch (info.result) {
      case BuildingProgressSharingResultInfoEnum.UserWithEmailNotFound:
        errorMessage = this.translateService.translate('BUILDING_PROGRESS.ERROR.EMAIL_NOT_EXISTS');
        break;
      case BuildingProgressSharingResultInfoEnum.NoPermissionForSharingBuildingProgress:
        errorMessage = this.translateService.translate('BUILDING_PROGRESS.ERROR.SHARING_INSSUFICIENT_RIGTHS');
        break;
      case BuildingProgressSharingResultInfoEnum.MultipleSharingActions:
        errorMessage = this.translateService.translate('BUILDING_PROGRESS.ERROR.MULTIPLE_SHARING_ACTION');
        break;
      default:
        errorMessage = this.translateService.translate('SHARING.UNKNOWN_ERROR');
        break;
    }

    console.error(
      `Backend returned code ${error.status}, ` +
      `error message: ${errorMessage}`
    );

    return throwError(() => new Error(errorMessage));
  }

  private getEndpointPath(projectId: number, endpoint: string): string {
    return this.gatewayApi + projectId + buildingProgressSharingApi + endpoint;
  }
}
