export interface BuildingObjectOverwriteState {
  hierarchyCode: string,
  isExisting: boolean,
  isAllowed: boolean,
  isActive: boolean,
  isAddition: boolean,
  budgetItemEditHistory?: BudgetItemEditHistory
}

export interface BudgetItemEditHistory {
    operationType: BudgetItemOperationType;
    dateChanged: Date;
    email: string;
}

export enum BudgetItemOperationType {
  Created = 1,
  Edited = 2,
  HierarchyCompletionCreated = 3
}
