import { Component, Input, ViewChild } from '@angular/core';

import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { BuildingProgressDispatchersService } from '../../../../store/building-progress';
import { BuildingProgressPeriod } from '../../../../building-progress/models/building-progress-period.model';
import { PeriodDatePipe } from '../../../../building-progress/modules/periods/pipes/period-date.pipe';

@Component({
  selector: 'app-period-filter-dropdown',
  templateUrl: './period-filter-dropdown.component.html',
  styleUrls: ['./period-filter-dropdown.component.scss']
})
export class PeriodFilterDropdownComponent {
  @Input() periods: BuildingProgressPeriod[];

  @ViewChild('dropdown') dropdown: NgbDropdown;

  get filterCount(): number {
    return this.periods?.filter(p => this.isChecked(p.id)).length ?? 0;
  }

  get filterDescription(): string {
    const periods = this.periods?.filter(p => this.isChecked(p.id)) ?? [];

    if (periods.length > 0) {
      const filterCount = periods.length - 1;
      const periodText = this.periodDatePipe.transform(periods[0].dateFrom, periods[0].dateTo);
      const filterCountText: string = filterCount > 0 ? ` (+${filterCount})` : '';
      return `${periodText} ${filterCountText}`;
    }

    return '';
  }

  constructor(
    private periodDatePipe: PeriodDatePipe,
    private dispatcherService: BuildingProgressDispatchersService
  ) { }

  addOrRemovePeriodToFilter(periodId: number): void {
    if (this.isChecked(periodId)) {
      this.dispatcherService.markPeriodInFilter(periodId, false);
    } else {
      this.dispatcherService.markPeriodInFilter(periodId, true);
    }
  }

  isChecked(periodId: number): boolean {
    return this.periods?.find(p => p.id === periodId).isMarkedInFilter ?? false;
  }

  clearFilter(): void {
    this.dispatcherService.clearAllMarkedPeriodsInFilter(this.periods);
    this.dropdown.close();
  }
}
