<kros-navigation>
  <button
    *krosNavItem="'brandZone'"
    class="app-title btn link"
    type="button"
    (click)="navigateToHomeOrBack($event)"
  >
    <span
      *ngIf="isCzechVersion else slovakAppButton"
      class="cz-display-flex"
    >
      @if (customCzLogo) {
      <ng-container *ngTemplateOutlet="customCzLogo"></ng-container>
      } @else {
      <img
        class="platform-logo"
        src="shared-assets/images/bim.svg"
        data-test="navbar-cz-brand-img"
      >
      }

      <i
        class="material-icons"
        data-test="navbar-cz-first-chevron-right"
      >chevron_right</i>

      <img
        *ngIf="isShowKrosIcon"
        class="k4-logo"
        src="shared-assets/images/kros4.svg"
        data-test="navbar-cz-brand-kros-img"
      >

      <h4 data-test="navbar-brand-cz-title">{{ title | translate }}</h4>
      <ng-container *ngTemplateOutlet="addBeta"></ng-container>
      <i
        *ngIf="isShowKrosIcon"
        class="material-icons tablet-hidden"
        data-test="navbar-cz-second-chevron-right"
      >chevron_right</i>
    </span>

    <ng-template #slovakAppButton>
      <span class="cz-hide">
        <h4 data-test="navbar-brand-title">{{ title | translate | lowercase }}</h4>

        <ng-container *ngTemplateOutlet="addBeta"></ng-container>
      </span>
    </ng-template>
  </button>

  <ng-container *ngIf="applications && !isAnonymousMode">
    <div *krosNavItem="'appSelector'">
      <div *ngIf="krosApplications">
        <ng-container *ngTemplateOutlet="appsKrosButton"></ng-container>
      </div>

      <ng-container *ngTemplateOutlet="appsButton"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="menu || !isMobile || !!mobileDropdownItems?.length || additionalMenuItems  else clearMenu">
    <ng-container *ngIf="menu else anotherMenu">
      <div
        class="menu-root"
        *krosNavItem="'menu'"
      >
        <ng-container *ngTemplateOutlet="useProjectSelector ? projects : projectLabel"></ng-container>

        <ng-container *ngTemplateOutlet="menu"></ng-container>
      </div>
    </ng-container>

    <ng-template #anotherMenu>
      <div
        *krosNavItem="'menu'"
        class="menu-root"
      >
        <div class="tablet-visible">
          <kros-menu-item
            *ngFor="let item of mobileDropdownItems"
            (click)="onMobileDropdownItemClick(item)"
            [class.main-active-link]="item.selected"
          >
            <a class=".link">
              <i
                class="material-icons"
                [ngClass]="{'custom-icons': item.customIcon}"
              >
                <ng-container *ngIf="item.customIcon; else materialIcon">
                  <img [src]="item.customIcon">
                </ng-container>

                <ng-template #materialIcon>{{ item.icon }}</ng-template>
              </i>
              {{ item.title | translate }}
            </a>
          </kros-menu-item>
        </div>

        <ng-container *ngTemplateOutlet="useProjectSelector ? projects : projectLabel"></ng-container>

        <ng-container *ngTemplateOutlet="additionalMenuItems"></ng-container>

        <div
          class="document-title tablet-hidden"
          [ngClass]="{'two-line': hasTwoLine}"
          data-test="navbar-document-title"
        >
          <ng-container *ngIf="documentTitles?.length == 2 else documentTitle">
            <p *ngFor="let documentTitle of documentTitles">{{ documentTitle }}</p>
          </ng-container>

          <ng-template #documentTitle>
            <ng-container *ngIf="documentTitles?.length > 0">
              {{ documentTitles[0] }}
            </ng-container>
          </ng-template>

          <ng-container *ngTemplateOutlet="titleButton"></ng-container>
        </div>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #clearMenu>
    <ng-container *krosNavItem="'menu'; clear: true"></ng-container>
  </ng-template>

  <div
    *krosNavItem="'secondaryNav'"
    class="right-nav"
  >
    <button
      *ngIf="canShowChat"
      ngbDropdownItem
      class="tablet-hidden chat-button"
      type="button"
      (click)="onShowChat()"
      krosAnalytic
      eventAction="showChat"
      data-test="navbar-chat-button"
      krosTooltip="{{ 'NAVBAR.CHAT_QUESTION' | translate }}"
    >
      <i class="material-icons-outlined navbar-mat-icon chat-icon">chat</i>
      <span>{{ 'NAVBAR.CHAT_QUESTION' | translate }}</span>
    </button>

    <kros-help-buton
      class="tablet-hidden"
      data-test="navbar-help-button"
    ></kros-help-buton>

    <div class="tablet-visible">
      <div *ngIf="additinalSecondaryItems">
        <ng-container *ngTemplateOutlet="additinalSecondaryItems"></ng-container>
      </div>

      <div class="dropdown-separator"></div>
    </div>

    <button
      class="tablet-visible btn right-margin"
      [krosDropdownTriggerFor]="mobile_menu"
    >
      <i class="material-icons">more_vert</i>
    </button>

    <div
      *ngIf="newsData"
      class="tablet-hidden"
    >
      <ng-container *ngTemplateOutlet="krosNews"></ng-container>
    </div>

    <div
      class="tablet-hidden right-margin langMenuDesktop"
      ngbDropdown
      *ngIf="selectedLanguage && languages.length > 1"
      display="dynamic"
    >
      <button
        ngbDropdownToggle
        id="langMenuDesktop"
        class="btn dropdown-toggle-arrow-hidden"
        type="button"
      >
        <img
          class="flag mr-2"
          [src]="'shared-assets/icons/flags/'+selectedLanguage.flag+'.png'"
        >
        <span class="text-uppercase">{{selectedLanguage.id}}</span>
      </button>

      <div
        ngbDropdownMenu
        aria-labelledby="langMenuDesktop"
      >
        <ng-container *ngTemplateOutlet="langMenu"></ng-container>
      </div>
    </div>

    <div
      *ngIf="additinalSecondaryItems"
      class="tablet-hidden"
    >
      <div class="secondary-items">
        <ng-container *ngTemplateOutlet="additinalSecondaryItems"></ng-container>
      </div>
    </div>
  </div>

  <ng-container *ngIf="selectedLanguage && isAuthorized && !isAnonymousMode">
    <ng-container *krosNavItem="'profile'">
      <kros-user-profile [showCompany]="false"></kros-user-profile>
    </ng-container>
  </ng-container>
</kros-navigation>

<ng-template #appsKrosButton>
  <ul
    *ngIf="isKrosAppsLoading"
    class="app-selector"
  >
    <ng-container *ngFor="let app of krosApplications">
      <li
        (click)="openApp(app)"
        krosTooltip="{{ getAppName(app) }}"
        krosAnalytic
        eventCategory='aplikacie'
        eventAction="{{app.displayName.sk}}"
      >
        <div>
          <img
            class="appIcon"
            src="{{app.iconUrl}}"
            krosAnalytic
            eventCategory='aplikacie'
            eventAction="{{app.displayName.sk}}"
            [attr.data-test]="'app-selector-icon-' + app.displayName.sk"
          >
        </div>
        <span class="caption">{{getAppName(app)}}</span>
      </li>
    </ng-container>
  </ul>

  <hr *ngIf="isKrosAppsLoading">
</ng-template>

<ng-template #appsButton>
  <ul class="app-selector">
    <ng-container *ngFor="let app of applications">
      <li
        (click)="openApp(app)"
        krosTooltip="{{ getAppName(app) }}"
        krosAnalytic
        eventCategory='aplikacie'
        eventAction="{{app.displayName.sk}}"
      >
        <div>
          <img
            class="appIcon"
            src="{{app.iconUrl}}"
            krosAnalytic
            eventCategory='aplikacie'
            eventAction="{{app.displayName.sk}}"
            [attr.data-test]="'app-selector-icon-' + app.displayName.sk"
          >
        </div>
        <span class="caption">{{getAppName(app)}}</span>
      </li>
    </ng-container>
  </ul>
</ng-template>

<ng-template #langMenu>
  <button
    ngbDropdownItem
    class="btn link"
    type="button"
    *ngFor="let lang of languages"
    (click)="onSetLanguage(lang)"
    krosAnalytic
    eventCategory="jazykova-mutacia"
    eventAction="{{lang.title}}"
  >
    <span>
      <span class="navbar-mat-icon flag">
        <img
          [src]="'shared-assets/icons/flags/'+lang.flag+'.png'"
          [attr.data-test]="'app-selector-icon-' + lang.title"
        >
      </span>

      <span>{{lang.title}}</span>
    </span>
  </button>
</ng-template>

<ng-template #krosNews>
  <kros-news
    [newsData]="newsData"
    data-test="news"
  ></kros-news>
</ng-template>

<ng-template #mobile_menu>
  <div kros-dropdown>
    <button
      kros-dropdown-header-item
      *ngIf="selectedLanguage && languages.length > 1"
      type="button"
    >
      <i class="material-icons">language</i>
      <div>{{ 'NAVBAR.LANGUAGE' | translate }}</div>
    </button>

    <div
      *ngIf="selectedLanguage && languages.length > 1"
      krosDropdownGroup
    >
      <button
        kros-dropdown-item-radio
        *ngFor="let lang of languages"
        type="button"
        (cdkMenuItemTriggered)="onSetLanguage(lang)"
        [cdkMenuItemChecked]="lang == this.selectedLanguage"
      >
        <span>
          <span>{{lang.id | uppercase }}</span>
          -
          <span>{{lang.title}}</span>
        </span>
      </button>
    </div>

    <div class="dropdown-separator"></div>

    <div *ngIf="newsData">
      <ng-container *ngTemplateOutlet="krosNews"></ng-container>
    </div>

    <button
      *ngIf="canShowChat"
      ngbDropdownItem
      class="btn link"
      type="button"
      (click)="onShowChat()"
      krosAnalytic
      eventAction="showChat"
      data-test="navbar-chat-button"
    >
      <i class="material-icons-outlined chat-icon-mobile">chat</i>
      <span>{{ 'NAVBAR.CHAT' | translate }}</span>
    </button>
    <kros-help-buton></kros-help-buton>
  </div>
</ng-template>

<ng-template #projectLabel>
  <span class="project-label">{{ activeDesktopDropdownItem?.title }}</span>
</ng-template>

<ng-template #projects>
  <p
    class="projects-label"
    *ngIf="!!projectsLabel"
  >{{ projectsLabel | translate }}</p>

  <kros-menu-item
    *ngIf="desktopDropdownItems"
    class="tablet-hidden"
    (click)="onLoadProjects()"
  >
    <span
      krosTooltip="{{ activeDesktopDropdownItem?.title }}"
      data-test="selected-project-name"
    >
      {{ activeDesktopDropdownItem?.title }}
    </span>

    <ng-container *krosSubMenuItem>
      <div class="buttons">
        <button
          class="btn btn-primary btn-new"
          data-test="navbar-new-project-button"
          type="button"
          (click)="onCreateProject()"
        >
          <i class="material-icons icon-button">add</i>
          <span>{{'PROJECTS.ZOZNAM.NEW_PROJECT' | translate}}</span>
        </button>
      </div>

      <kros-menu-item
        *ngFor="let item of desktopDropdownItems"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="main-active-link"
      >
        <a
          krosTooltip="{{ item.title }}"
          routerLink="{{ item.url }}"
        >
          {{ item.title }}
        </a>
      </kros-menu-item>
    </ng-container>
  </kros-menu-item>
</ng-template>
