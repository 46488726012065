<div class="toolbar-container">
  <app-budget-approval-toolbar
    *ngIf="hasPermission"
    [isBudgetApprovalData]="hasData"
    [haveDifferences]="selectorService.haveDifferences$ | async"
    (exportClick)="onExportClick()"
    (deleteClick)="onDeleteClick()"
    (showChangeSheetItemsClick)="onShowChangeSheetItemsClick()"
    (approveClick)="onApproveClick()"
    (cancelApprovedClick)="onCancelApprovedClick()"
    (showDifferencesClick)="onShowDifferencesClick()"
  ></app-budget-approval-toolbar>
</div>

<div class="filter-container">
  <app-budget-approval-filter
    *ngIf="hasPermission && isFilterVisible"
    (columnGroupsCheckChanged)="onColumnGroupsCheckChanged($event)"
  ></app-budget-approval-filter>
</div>

<ng-container *ngIf="!(selectorService.budgetApprovalDataLoading$ | async) else contentLoader">
  <ng-container *ngIf="hasPermission else noPermission">
    <kros-data-table
      *ngIf="hasData else emptyContent"
      [itemHeight]="tableService.averageLineHeight"
      [data]="tableData"
      [itemCount]="itemCount"
      [config]="dataTableConfig"
      [focusedCell]="focusedCell"
      (cellFocused)="onCellFocused($event)"
      (scrollFocusedCellToView)="onScrollFocusedCellToView($event)"
      (itemCheckChanged)="onItemCheckChanged($event.item, $event.state)"
      (headerCheckChanged)="onHeaderCheckChanged($event)"
      (itemDblClick)="onItemDblClick($event)"
    ></kros-data-table>
  </ng-container>
</ng-container>

<ng-template #emptyContent>
  <ng-container *ngIf="!permissionHelper.isContractor else noItems">
    <ssw-budget-approval-create
      [projectId]="projectId"
      [isDemo]="isDemo"
    ></ssw-budget-approval-create>
  </ng-container>
</ng-template>

<ng-template #contentLoader>
  <kros-timeline [type]="timelineType.Table"></kros-timeline>
</ng-template>

<ng-template #noPermission>
  <div class="empty-content text-center">
    <p data-test="empty-content">{{ 'BUDGET_APPROVAL.NO_PERMISSION' | translate }}</p>

    <button
      *ngIf="canShowOpenDemoLink()"
      type="button"
      class="btn btn-link no-selection"
      (click)="onOpenDemo()"
    >{{ 'BUDGET_APPROVAL.TRY_IN_DEMO' | translate }}</button>
  </div>
</ng-template>

<ng-template #noItems>
  <div class="no-items text-center">
    <h5>{{ 'COMMON.NO_ITEMS_MESSAGE' | translate }}</h5>
  </div>
</ng-template>
