import { DecimalPlaces, VatRateType } from '@kros-sk/ssw-shared-legacy';

export class BuildingProgressSettings {
  projectId: number;
  currencyCode: string;
  isTransferredVat: boolean;
  vatRates: { [key in VatRateType]?: number };
  decimalPlaces: DecimalPlaces;
  retainagePercentage: number;
  retainageCalculationType: RetainageCalculationType;
  retainage2Percentage: number;
  retainage2CalculationType: RetainageCalculationType;
  isAutoCompleteItemsEnabled: boolean;
  isVatRateUpdated: boolean;
  isMultiStageApprovalModeEnabled: boolean;
}

export enum RetainageCalculationType {
  Undefined = 0,
  Price = 1,
  PriceWithVAT = 2
}

export const BuildingProgressBasePathSegment = 'building-progress';
