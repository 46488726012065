import { Injectable } from '@angular/core';

import { SubSink } from 'subsink';

import { Cell } from '@kros-sk/ssw-shared-legacy';
import { getFocusedCellElementId } from '@kros-sk/ssw-shared-legacy';

import { BuildingProgressDispatchersService, BuildingProgressPeriodDispatchersService } from '../../store/building-progress';
import { BuildingProgressModel, BuildingProgressPeriodColumn } from '../models/construction-data.model';
import { BuildingProgressPeriod } from '../models/building-progress-period.model';
import { BuildingProgressSelectorsService } from '../../store/building-progress/building-progress-selectors.service';
import { VisualHelper } from './visual.helper';

@Injectable()
export class PeriodHelper {

  selectedPeriodId: number;

  private lastPeriodId: Function;
  private subs = new SubSink();

  get focusedCell(): Cell {
    return this.visualHelper.focusedCell;
  }

  set focusedCell(value: Cell) {
    this.visualHelper.focusedCell = value;
  }

  constructor(
    private periodDispatchersService: BuildingProgressPeriodDispatchersService,
    private visualHelper: VisualHelper,
    private buildingProgressDispatchersService: BuildingProgressDispatchersService,
    private buildingProgressSelectorsService: BuildingProgressSelectorsService
  ) {
  }

  init(lastPeriodId: Function): void {
    this.lastPeriodId = lastPeriodId;
    this.subs.sink = this.buildingProgressSelectorsService.periodId$
      .subscribe(id => {
        if (id && id !== this.selectedPeriodId) {
          this.selectPeriod(id);
        }
      });
  }

  destroy(): void {
    this.subs.unsubscribe();
  }

  getSelectedOrLastPeriodId(): number {
    return this.selectedPeriodId > 0 ? this.selectedPeriodId : this.lastPeriodId();
  }

  getPeriodColumnName(periodColumn: BuildingProgressPeriodColumn): string {
    switch (periodColumn) {
      case BuildingProgressPeriodColumn.Percentage:
        return 'percentage-';
      case BuildingProgressPeriodColumn.Amount:
        return 'amount-';
      case BuildingProgressPeriodColumn.TotalPrice:
        return 'totalPrice-';
      default:
        return 'percentage-';
    }
  }

  setSelectedPeriod(): void {
    this.selectedPeriodId = this.focusedCell && this.focusedCell.params ? this.focusedCell.params.periodId : undefined;
    this.periodDispatchersService.selectPeriodId(this.selectedPeriodId);
  }

  private selectPeriod(periodId: number): void {
    this.selectedPeriodId = periodId;

    const rowIndex = (this.focusedCell ? this.focusedCell.rowIndex : 0);
    this.focusedCell = {
      colId: this.getColId(periodId),
      rowIndex,
      params: { periodId, periodColumn: BuildingProgressPeriodColumn.Percentage }
    };
    this.visualHelper.scrollElementToView(this.getElementId(periodId, rowIndex), false, this.getElementIdForColumnWidth(periodId));
  }

  private getColId(periodId: number): string {
    return periodId === undefined ? 'description' : 'percentage-' + periodId;
  }

  private getElementId(periodId: number, rowIndex: number): string {
    return periodId === undefined
      ? this.getFocusedCellElementId()
      : ('details-') + periodId + '-row-' + rowIndex;
  }

  private getElementIdForColumnWidth(periodId: number): string {
    return periodId === undefined
      ? this.getFocusedCellElementId()
      : 'head-grouped-col-period-' + periodId;
  }

  private getFocusedCellElementId(): string {
    return getFocusedCellElementId(this.focusedCell);
  }

  arePeriodsEqual(constructionData: BuildingProgressModel, newConstructionData: BuildingProgressModel): boolean {
    let ret = true;
    if (!!constructionData) {
      if (constructionData.periods.length !== newConstructionData.periods.length) {
        ret = false;
      } else {
        for (const p of newConstructionData.periods) {
          const oldPeriod = constructionData.periods.find(x => x.id === p.id);
          if (!oldPeriod || !this.arePeriodEqual(oldPeriod, p)) {
            ret = false;
            break;
          }
        }
      }
    }

    return ret;
  }

  private arePeriodEqual(firstPeriod: BuildingProgressPeriod, secondPeriod: BuildingProgressPeriod): boolean {
    return firstPeriod.id === secondPeriod.id &&
      firstPeriod.dateFrom === secondPeriod.dateFrom &&
      firstPeriod.dateTo === secondPeriod.dateTo &&
      firstPeriod.isApprovalRequested === secondPeriod.isApprovalRequested &&
      firstPeriod.isApproved === secondPeriod.isApproved &&
      firstPeriod.isLocked === secondPeriod.isLocked &&
      firstPeriod.hasDocuments === secondPeriod.hasDocuments &&
      firstPeriod.isMultiStageApprovalInProgress === secondPeriod.isMultiStageApprovalInProgress &&
      firstPeriod.hasInvoices === secondPeriod.hasInvoices;
  }

  onPeriodDeleted(): void {
    this.selectedPeriodId = 0;
  }

  onPeriodSelected(period: BuildingProgressPeriod): void {
    this.periodDispatchersService.selectPeriodId(period.id);
  }

  markPeriod(periods: BuildingProgressPeriod[], markedPeriodId: number): void {
    this.buildingProgressDispatchersService.clearAllMarkedPeriodsInFilter(periods);
    this.buildingProgressDispatchersService.markPeriodInFilter(markedPeriodId, true);
  }
}
