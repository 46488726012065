import { ActionCreator, ReducerTypes, createReducer, on } from '@ngrx/store';

import * as actions from './info-message.actions';
import { InfoMessageState, initialInfoMessageState } from './info-message.state';

const infoMessageReducers: ReducerTypes<InfoMessageState, ActionCreator[]>[] = [
  on(actions.loadMessagesSuccess, (state, payload) => {
    return {
      ...state,
      messages: payload.messages
    };
  }),
  on(actions.closeMessage, (state, payload) => {
    const hasMessageInteraction = state.messagesInteractions.find(m => m.message === payload.message);

    const messages = state.messages.map(m => m.message !== payload.message ? m : { ...m, closed: true });

    const messagesInteractions = !hasMessageInteraction
      ? [...state.messagesInteractions, { message: payload.message, closeDate: new Date() }]
      : state.messagesInteractions.map(m => m.message !== payload.message ? m : { ...m, closeDate: new Date() });

    return {
      ...state,
      messages,
      messagesInteractions
    };
  })
];

export const reducer = createReducer(
  initialInfoMessageState,
  ...infoMessageReducers
);
