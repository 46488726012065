import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { KrosModalRef } from '@kros-sk/components';
import { TranslateService } from '@kros-sk/ssw-shared-legacy';

@Component({
  selector: 'app-subcontractor-rename-dialog',
  templateUrl: './subcontractor-rename-dialog.component.html',
  styleUrls: ['./subcontractor-rename-dialog.component.scss']
})
export class SubcontractorsRenameDialog implements OnInit {
  subcontractorName = '';
  form = new FormGroup({
    subcontractorName: new FormControl('', Validators.required)
  });
  errors: { [key: string]: string } = {};

  constructor(
    private modalRef: KrosModalRef,
    private translateService: TranslateService
  ) {
    this.subcontractorName = this.modalRef.data.subcontractorName;
  }

  ngOnInit(): void {
    this.form.controls.subcontractorName.setValue(this.subcontractorName);

    this.errors = {
      required: this.translateService.translate('SUBCONTRACTORS.SUBCONTRACTOR_NAME_REQUIRED'),
    };
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  onSubmit(): void {
    this.modalRef.submit({ subcontractorName: this.form.controls.subcontractorName.value });
  }
}
