import { Budget } from '@kros-sk/ssw-budget/shared/data-access/models';
import { BudgetSettings, ProjectDetail } from '@kros-sk/ssw-shared-legacy';

export interface BudgetSharedState {
  project: ProjectDetail;
  budget: Budget;
  settingsState: BudgetSettingsState;
  uiSettings: Record<string, SettingsState>;
}

export const initialBudgetSharedState: BudgetSharedState = {
  project: null,
  budget: null,
  settingsState: {
    settings: null,
    isLoaded: false
  },
  uiSettings: {}
};

export interface BudgetSettingsState {
  settings: BudgetSettings;
  isLoaded: boolean;
}

export interface SettingsState {
  value: any;
  isLoading: boolean;
}
