import { inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import {
  ApplicationModule,
  ApplicationModuleIdProviderService,
  ApplicationRoute,
  SSW_APPLICATION_ID,
  SswApplicationId
} from '@kros-sk/ssw-shared-legacy';
import { RegistrationNotificationService } from '@kros-sk/ssw-cdk';

export class BudgetApprovalDemoService {

  private moduleId: ApplicationModule;
  private appModuleIdProviderService = inject(ApplicationModuleIdProviderService);
  private registrationNotificationService = inject(RegistrationNotificationService);
  private application = inject(SSW_APPLICATION_ID);

  constructor() {
    this.appModuleIdProviderService.applicationModuleId$.pipe(takeUntilDestroyed())
      .subscribe(moduleId => this.moduleId = moduleId);
  }

  onOpenDemo(): void {
    this.registrationNotificationService.navigateDemoProject(ApplicationRoute.BudgetApproval);
  }

  canShowOpenDemoLink(isDemo: boolean): boolean {
    return this.moduleId === ApplicationModule.BudgetApproval &&
      this.application === SswApplicationId.Titan &&
      !isDemo;
  }
}
