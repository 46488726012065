import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { filter, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { CompanySearchValidationOptions, KrosCompany, VatPayerType } from '@kros-sk/models';

import { getAllFormErrors } from '../../tools';
import { InputCommandType, KrosFormsService } from '../../inputs';
import { KrosValidators } from '../../validators';

@Component({
  selector: 'kros-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CompanyInfoComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: CompanyInfoComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyInfoComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {

  // If visual validations on fields are enabled or not
  @Input() enableExtendedValidations: boolean;
  @Input() requiredCountry: boolean;
  @Input() showAllErrors = false;

  @Output() registrationCourtTextTranslations: EventEmitter<{[key: string]: string}> = new EventEmitter<{[key: string]: string}>();

  companyInfoForm: UntypedFormGroup;
  nameControl: AbstractControl;
  streetControl: AbstractControl;
  postCodeControl: AbstractControl;
  cityControl: AbstractControl;
  countryControl: AbstractControl;
  onChangeFn: (next: any) => void;
  companySearchOptions: CompanySearchValidationOptions = {
    validators: Validators.required
  };

  onTouchedFn: () => void;
  vatPayerOptions = [
    { label: 'Neplatiteľ DPH', value: VatPayerType.NonPayer },
    { label: 'Platiteľ DPH', value: VatPayerType.Payer },
    { label: 'Registrovaný podľa §7, 7a', value: VatPayerType.RegisteredVatPayer7 }];

  private subs = new SubSink();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private formService: KrosFormsService,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get displayVatId(): boolean {
    return +this.companyInfoForm.value.vatPayerType === VatPayerType.Payer
      || +this.companyInfoForm.value.vatPayerType === VatPayerType.RegisteredVatPayer7;
  }

  writeValue(val: any): void {
    this.companyInfoForm.patchValue({
      address: val.address,
      registrationId: val.registrationId,
      taxId: val.taxId,
      vatId: val.vatId,
      vatPayerType: val.vatPayerType,
    }, {
      emitEvent: false,
    });
    this.formService.triggerInputCommand(
      ['street', 'city','postCode', 'registrationId', 'taxId'],
      {type: InputCommandType.DETECT_CHANGES});
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
    this.listenOnFormChange();
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  validate(): ValidationErrors | null {
    return this.companyInfoForm.valid ? null : { ...getAllFormErrors(this.companyInfoForm) };
  }

  setCompany($event: KrosCompany): void {
    this.companyInfoForm.patchValue({
      address: $event.address,
      registrationId: $event.registrationId,
      taxId: $event.taxId ?? '',
      vatId: $event.vatId ?? '',
      vatPayerType: $event.vatPayerType ? $event.vatPayerType :
        ($event.vatId !== null) ? VatPayerType.Payer : VatPayerType.NonPayer,
    });
    this.registrationCourtTextTranslations.emit($event.registrationCourtTextTranslations);
  }

  setNewCity(event): void {
    this.companyInfoForm.controls.address.get('postCode').patchValue(event.postCode);
    this.companyInfoForm.controls.address.get('city').patchValue(event.city);
  }

  setNewAddress(event): void {
    this.companyInfoForm.controls.address.get('postCode').patchValue(event.postCode);
    this.companyInfoForm.controls.address.get('city').patchValue(event.city);
    this.companyInfoForm.controls.address.get('street').patchValue(event.street);
  }

  private createForm(): void {
    this.companyInfoForm = this.formBuilder.group({
      address: this.formBuilder.group({
        businessName: ['', Validators.required],
        street: ['', this.enableExtendedValidations ? KrosValidators.optionalValidator(Validators.required) : []],
        postCode: ['', this.enableExtendedValidations ? KrosValidators.optionalValidator(Validators.required) : []],
        city: ['', this.enableExtendedValidations ? KrosValidators.optionalValidator(Validators.required) : []],
        country: ['', this.enableExtendedValidations ? KrosValidators.optionalValidator(Validators.required) : []],
      }),
      registrationId: [''],
      taxId: [''],
      vatId: [''],
      vatPayerType: VatPayerType.NonPayer,
    });

    this.nameControl = this.companyInfoForm.get('address').get('businessName');
    this.streetControl = this.companyInfoForm.get('address').get('street');
    this.postCodeControl = this.companyInfoForm.get('address').get('postCode');
    this.cityControl = this.companyInfoForm.get('address').get('city');
    this.countryControl = this.companyInfoForm.get('address').get('country');
  }

  private listenOnFormChange(): void {
    this.subs.add(this.companyInfoForm.valueChanges.pipe(
      tap(form => {
        if (form.address.businessName?.id === -1) {
          // if there is empty company i need only its name
          const newValue = {
            ...form,
            address: {
              ...form.address,
              businessName: form.address.businessName.address.businessName,
            },
          };
          this.companyInfoForm.patchValue(newValue);
        } else if (form.address.businessName?.id > -1) {
          // if there is company with address its chosen from autocomplete
          this.setCompany(form.address.businessName);
        }
      }),
      filter(form => form.address.businessName.id === undefined), // i want to notify only with string company name
    ).subscribe(next => {
      this.onChangeFn(next);
    }));
  }
}
