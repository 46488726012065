import { Injectable } from '@angular/core';

import { take } from 'rxjs';

import { BuildingProgressSettings } from '@kros-sk/ssw-shared/settings';
import { KrosModalService } from '@kros-sk/components';
import { VatRateType } from '@kros-sk/ssw-shared-legacy';

import { BuildingProgressDispatchersService } from '../../store/building-progress';
import { environment } from '../../../environments/environment';
import { PermissionHelper } from '../helpers';
import { VatRateUpdateModalComponent } from '../components/vat-rate-update-modal/vat-rate-update-modal.component';

@Injectable()
export class VatRateUpdateService {
  constructor(
    private buildingProgressDispatchersService: BuildingProgressDispatchersService,
    private krosModalService: KrosModalService,
    private permissionHelper: PermissionHelper
  ) { }

  updateVatRateIfCan(settings: BuildingProgressSettings): void {
    if (!settings.isVatRateUpdated && environment.location === 'cz' && this.permissionHelper.isAtLeastContributor &&
      this.hasNotUpdatedReducedVatRate(settings)) {
      this.krosModalService.openCentered(
        VatRateUpdateModalComponent,
        undefined,
        {
          closeOnBackdropClick: false,
          fullscreenOnMobile: true,
          addModalToBrowsersHistory: false,
          closeOnEscClick: false,
          showMobileArrowBack: false
        }).afterClosed$.pipe(take(1)).subscribe(() => this.buildingProgressDispatchersService.updateVatRate(settings.projectId));
    }
  }

  private hasNotUpdatedReducedVatRate(settings: BuildingProgressSettings): boolean {
    return (settings.vatRates[VatRateType.Reduced] > 0 && settings.vatRates[VatRateType.Reduced] !== 12) ||
      (settings.vatRates[VatRateType.ReducedTransferred] > 0 && settings.vatRates[VatRateType.ReducedTransferred] !== 12);
  }
}
