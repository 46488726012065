import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { CommentsComponent } from '@kros-sk/ssw-shared-legacy';
import { UnsubscribeComponent, UserService } from '@kros-sk/ssw-cdk';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

import { BuildingProgressCommentModel, CommentComponentModel } from './comment.model';
import { BuildingProgressCommentsDispatchersService, BuildingProgressSelectorsService } from '../../../../store/building-progress';
import { BuildingProgressPeriod } from '../../../../building-progress/models/building-progress-period.model';
import { getAppInsightsPrefix } from '../../../../building-progress/shared/building-progress-utils';
import { PeriodDatePipe } from '../../../../building-progress/modules/periods/pipes/period-date.pipe';
import { PermissionHelper } from '../../../../building-progress/helpers';

@Component({
  selector: 'app-comments-panel',
  templateUrl: './comments-panel.component.html',
  styleUrls: ['./comments-panel.component.scss']
})
export class CommentsPanelComponent extends UnsubscribeComponent implements OnInit {

  @ViewChild(CommentsComponent) commentsComponent: CommentsComponent;

  @Input() readOnly: boolean;
  @Input()
  set data(data: CommentComponentModel) {
    this._data = data;
    this.setFocusedComments();
  }

  get data(): CommentComponentModel {
    return this._data;
  }

  @Output() close = new EventEmitter();
  @Output() clickComment = new EventEmitter<BuildingProgressCommentModel>();

  get editing(): boolean {
    return this.commentsComponent?.editing;
  }

  get focusedCommentIds(): number[] {
    return this.focusedComments.map(c => c.id);
  }

  newComment: BuildingProgressCommentModel;
  comments: BuildingProgressCommentModel[];
  periodName: string;

  private _data: CommentComponentModel;
  private focusedComments: BuildingProgressCommentModel[] = [];
  private appInsightsPrefix: string;

  private get userEmail(): string {
    return this.userService.getUserEmail();
  }

  constructor(
    public selectorsService: BuildingProgressSelectorsService,
    private userService: UserService,
    private commentsDispatchersService: BuildingProgressCommentsDispatchersService,
    private periodDatePipe: PeriodDatePipe,
    private appInsightsService: AppInsightsBaseService,
    private permissionHelper: PermissionHelper
  ) {
    super();

    this.appInsightsPrefix = getAppInsightsPrefix(this.permissionHelper.isContractor);
  }

  createAnswer = (comment: BuildingProgressCommentModel): BuildingProgressCommentModel => {
    return {
      projectId: comment.projectId,
      budgetItemId: comment.budgetItemId,
      periodId: comment.periodId,
      periodColumn: comment.periodColumn,
      parentId: comment.parentId ?? comment.id,
      comment: '',
      email: this.userEmail
    };
  };

  ngOnInit(): void {
    this.subs.sink = this.selectorsService.period$
      .subscribe((period: BuildingProgressPeriod) => {
        this.periodName = period
          ? this.periodDatePipe.transform(period.dateFrom, period.dateTo)
          : undefined;
      });

    this.subs.sink = this.selectorsService.comments$
      .subscribe((comments: BuildingProgressCommentModel[]) => {
        if (comments) {
          this.comments = comments.map(c => ({ ...c } as BuildingProgressCommentModel));
          this.newComment = undefined;

          setTimeout(() => {
            // ked sa otvoril panel s komentarmi, oznacil sa spravny komentar,
            // ale nezascroloval sa aby ho bolo vidno (ak je ich viac). dovod je, ze v danom case este nebol vykresleny.
            this.setFocusedComments();
          }, 0);
        }
      });

    this.commentsDispatchersService.loadComments(this.data.projectId, this.data.contractorId);
  }

  showNewComment(): void {
    this.newComment = {
      email: this.userEmail,
      projectId: this.data.projectId,
      budgetItemId: this.data.budgetItemId,
      periodId: this.data.periodId,
      periodColumn: this.data.periodColumn,
      comment: ''
    } as BuildingProgressCommentModel;
  }

  onCloseClick(): void {
    this.close.emit();
  }

  onCancelNewComment(): void {
    this.newComment = undefined;
    this.appInsightsService.trackEvent(this.appInsightsPrefix + 'comments-cancel');
  }

  onSaveNewComment(comment: BuildingProgressCommentModel): void {
    this.commentsDispatchersService.createComment(comment, this.data.contractorId);
    this.appInsightsService.trackEvent(this.appInsightsPrefix + 'comments-ok');
  }

  onSaveComment(comment: BuildingProgressCommentModel): void {
    this.commentsDispatchersService.updateComment(comment);
  }

  onDeleteComment(comment: BuildingProgressCommentModel): void {
    this.commentsDispatchersService.deleteComment(comment.projectId, comment.id, this.data.contractorId);
    this.appInsightsService.trackEvent(this.appInsightsPrefix + 'comments-delete');
  }

  onClickComment(comment: BuildingProgressCommentModel): void {
    this.clickComment.emit(comment);
    this.focusedComments = [this.commentsComponent.findRootComment(comment) as BuildingProgressCommentModel];
  }

  onPublish(): void {
    this.commentsDispatchersService.publishComments(this.data.projectId, this.data.contractorId);
  }

  private setFocusedComments(): void {
    if (this.data.budgetItemId && this.data.periodId && this.data.periodColumn !== undefined && this.comments) {
      this.focusedComments = this.comments.filter(
        c => c.budgetItemId === this.data.budgetItemId && c.periodId === this.data.periodId &&
          c.periodColumn === this.data.periodColumn
      );

      if (this.focusedComments.length > 0 && this.data.scrollToComment) {
        this.commentsComponent?.scrollCommentIntoView(this.focusedComments[this.focusedComments.length - 1].id);
      }
    }
  }
}
