<button
  class="btn btn-link ga-select-period"
  type="button"
  data-test="building-progress-period-select"
  (click)="openPeriodsDialog()"
  *ngIf="canAddPeriod || periods?.length > 0"
>
  <ng-container *ngIf="periods?.length > 0; else newPeriod">
    <span
      class="ga-select-period"
      data-test="period-date"
    >{{selectedPeriodDate}}</span>
    <i class="material-icons ga-select-period">expand_more</i>
  </ng-container>
</button>

<ng-template #newPeriod>
  <ng-container *ngIf="canAddPeriod">
    <i class="material-icons">add</i>
    <span>{{ 'BUILDING_PROGRESS.PERIODS.NEW_PERIOD' | translate }}</span>
  </ng-container>
</ng-template>
