import { inject, Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

import { AzureCredentials, ConfirmationObject } from '../models/azure-blob-storage.models';
import { KrosBlobStorageService } from './kros-blob-storage.service';

const userServiceApi = '/api/userService';

@Injectable()
export class ImageService {

  private appConfig = inject(APP_CONFIG);
  private krosBlobStorage = inject(KrosBlobStorageService);
  private appInsightsService = inject(AppInsightsBaseService);

  downloadImage(controllerName: string, inProgress: BehaviorSubject<boolean>): Observable<Blob | null> {
    this.appInsightsService.trackEvent('U-download-image', { imageType: controllerName });
    const endpoint = `${this.appConfig.gatewayApiUrl}${userServiceApi}/${controllerName}`;
    return this.krosBlobStorage.downloadBlob(endpoint, inProgress);
  }

  uploadImage(file: File, controllerName: string, inProgress: BehaviorSubject<boolean>): Observable<boolean> {
    this.appInsightsService.trackEvent('U-upload-image', { imageType: controllerName });
    const endpoint = `${this.appConfig.gatewayApiUrl}${userServiceApi}/${controllerName}`;
    return this.krosBlobStorage.uploadBlob(endpoint,
      file,
      inProgress,
      (azureCredentials, originalName) => this.getConfirmationObject(azureCredentials, originalName)
    );
  }

  deleteImage(controllerName: string): Observable<boolean> {
    this.appInsightsService.trackEvent('U-delete-image', { imageType: controllerName });
    const endpoint = `${this.appConfig.gatewayApiUrl}${userServiceApi}/${controllerName}`;
    return this.krosBlobStorage.deleteBlob(endpoint);
  }

  private getConfirmationObject(azureCredentials: AzureCredentials, originalName: string): ConfirmationObject {
    return {
      blobName: azureCredentials.name,
      originalName
    } as ConfirmationObject;
  }
}
