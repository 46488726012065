import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';

import { KrosApiBlobStorageService } from './kros-api-blob-storage.service';
import { AzureBlobStorageService } from './azure-blob-storage.service';
import { AzureCredentials } from '../models/azure-blob-storage.models';

@Injectable()
export class KrosBlobStorageService {

  constructor(
    private azureBlobStorage: AzureBlobStorageService,
    private krosApiBlobService: KrosApiBlobStorageService,
  ) { }

  downloadBlob(krosCredentialsEndPoint: string, inProgress: BehaviorSubject<boolean>): Observable<Blob | null> {
    return this.krosApiBlobService.getDownloadToken(krosCredentialsEndPoint).pipe(
      switchMap(azureCredentials => {
        if (!azureCredentials.blobUri) {
          return of(null);
        } else {
          inProgress.next(true);
          return this.azureBlobStorage.downloadBlob(azureCredentials);
        }
      }),
      catchError(_ => of(null)),
      finalize(() => inProgress.next(false))
    );
  }

  uploadBlob(
    krosCredentialsEndPoint: string,
    file: File,
    inProgress: BehaviorSubject<boolean>,
    getConfirmationObject: (azureCredentials: AzureCredentials, originalName: string) => any
  ): Observable<boolean> {
    let credentials: AzureCredentials;
    return this.krosApiBlobService.getUploadToken(krosCredentialsEndPoint, file.type, file.name).pipe(
      switchMap(azureCredentials => {
        credentials = azureCredentials;
        return this.azureBlobStorage.uploadBlob(azureCredentials, file, inProgress);
      }),
      switchMap(result => {
        if (result) {
          return this.publishUploadBlob(krosCredentialsEndPoint, credentials, getConfirmationObject(credentials, file.name));
        }

        return of(result);
      })
    );
  }

  deleteBlob(apiEndpoint: string): Observable<any> {
    return this.krosApiBlobService.delete(apiEndpoint).pipe(
      map(_ => true),
      catchError(this.handleError.bind(this))
    );
  }

  private publishUploadBlob(
    apiEndpoint: string,
    azureCredentials: AzureCredentials,
    confirmationObject: any
  ): Observable<any> {
    return this.krosApiBlobService.confirmUpload(apiEndpoint, confirmationObject).pipe(
      map(_ => true),
      catchError(error => {
        this.azureBlobStorage.deleteBlob(azureCredentials).subscribe();
        return this.handleError(error);
      })
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      const errorMessage = 'Something bad happened; please try again later';

      console.error(
        `Backend returned code ${error.status}, ` +
        `error message: ${errorMessage}, ` +
        `body was: ${JSON.stringify(error.error)}`);

      return throwError(errorMessage);
    }
    return throwError('Something bad happened; please try again later.');
  }
}
