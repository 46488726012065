import { CommonModule } from '@angular/common';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AnalyticsModule, HttpConfigInterceptor, ProgressBarModule, TranslateModule } from '@kros-sk/ssw-shared-legacy';

import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { HammerConfig } from './configurations/hammer.config';
import { NavbarMockComponent } from './mocks/navbar-mock.component';
import { RootRerouteComponent } from './components/root-reroute/root-reroute.component';
import { SharedModule } from '../shared/shared.module';
import { UserDataStatisticsService } from './services/user-data-statistics/user-data-statistics.service';

@NgModule({
  declarations: [
    NavbarMockComponent,
    RootRerouteComponent
  ],
  imports: [
    AnalyticsModule,
    CommonModule,
    RouterModule,
    SharedModule,
    TranslateModule.forChild(),
    ProgressBarModule
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    CanDeactivateGuard,
    UserDataStatisticsService,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. Import Core modules in the AppModule only.');
    }
  }
}
