<button
  class="btn toolbar-action no-selection gray-toolbar-action dropdown-action export-excel"
  krosTooltip="{{ 'BUILDING_PROGRESS.EXPORT' | translate }}"
  type="button"
  [krosDropdownTriggerFor]="export_menu"
  data-test="building-progress-export-dropdown"
>
  <div class="dropdown-toggle-content">
    <i class="material-icons icon-button">save_alt</i>
    <span class="toolbar-action-text">{{ 'BUILDING_PROGRESS.EXPORT' | translate }}</span>
    <i class="material-icons navbar-mat-icon dropdown-toggle-icon">expand_more</i>
  </div>
</button>

<ng-template #export_menu>
  <div kros-dropdown>
    <button
      *ngIf="canShowExport"
      kros-dropdown-item
      type="button"
      (click)="exportInvoiceDetails.emit()"
      data-test="building-progress-export-invoice-details-button"
    >
      {{ 'BUILDING_PROGRESS.EXPORT_INVOICE_DETAILS' | translate }}
    </button>

    <button
      *ngIf="canShowExportTable"
      kros-dropdown-item
      type="button"
      (click)="exportToExcel.emit()"
      data-test="building-progress-export-table-button"
    >
      {{ 'BUILDING_PROGRESS.EXPORT_TABLE' | translate }}
    </button>

    <ng-container *ngIf="canShowExport || canShowExportItemDrawSheet || canShowExportConfirmationProtocol || canShowExportToAnotherProject">
      <div class="divider-horizontal"></div>

      <button
        type="button"
        kros-dropdown-item
        [krosDropdownTriggerFor]="other_exports_menu"
        data-test="building-progress-export-other-exports-button"
      >
        {{ 'BUILDING_PROGRESS.EXPORT_OTHER_EXPORTS' | translate }}
      </button>
    </ng-container>
  </div>
</ng-template>

<ng-template #other_exports_menu>
  <div kros-dropdown>
    <button
      *ngIf="canShowExport"
      kros-dropdown-item
      type="button"
      (click)="exportAmountsUniXml.emit()"
      data-test="building-progress-export-amounts-unixml-button"
    >
      {{ 'BUILDING_PROGRESS.EXPORT_AMOUNTS_UNIXML' | translate }}
    </button>

    <button
      *ngIf="canShowExport"
      kros-dropdown-item
      type="button"
      (click)="exportRemainingBudget.emit()"
      data-test="building-progress-export-export-budget-balance"
    >
      {{ 'BUILDING_PROGRESS.EXPORT_REMAINING_BUDGET' | translate }}
    </button>

    <button
      *ngIf="canShowExportItemDrawSheet"
      kros-dropdown-item
      type="button"
      (click)="exportItemDrawSheet.emit()"
      data-test="building-progress-export-item-draw-sheet-button"
    >
      {{ 'BUILDING_PROGRESS.EXPORT_ITEM_DRAW_SHEET' | translate }}
    </button>

    <button
      *ngIf="canShowExportConfirmationProtocol"
      kros-dropdown-item
      type="button"
      (click)="exportConfirmationProtocol.emit()"
      data-test="building-progress-export-confirmation-protocol-button"
    >
      {{ 'BUILDING_PROGRESS.EXPORT_CONFIRMATION_PROTOCOL_NDS' | translate }}
    </button>

    <ng-container *ngIf="canShowExportToAnotherProject">
      <div
        *ngIf="canShowExport || canShowExportItemDrawSheet || canShowExportConfirmationProtocol"
        class="divider-horizontal top-margin"
      ></div>

      <button
        *ngIf="canShowExportToAnotherProject"
        kros-dropdown-item
        type="button"
        (click)="exportToAnotherProject.emit()"
        data-test="building-progress-export-to-another-project-button"
      >
        {{ 'BUILDING_PROGRESS.EXPORT_AMOUNTS_TO_ANOTHER_PROJECT' | translate }}
      </button>
    </ng-container>
  </div>
</ng-template>
