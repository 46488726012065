<div [krosTooltip]="tooltipTemplate"
     [tooltipShowManually]="true"
     [position]="getDisplayedMessages() === 'error' ? 'above-left' : hintTooltipPlacement"
     #tooltip="krosTooltip"
     (mouseenter)="showTooltip()"
     (mouseleave)="closeTooltip()">
  <label [attr.for]="control?.id"
         [id]="labelId"
         *ngIf="hasLabel">
    <ng-content select="kros-label"></ng-content>
  </label>

  <div class="input-container" #inputContainer>
    <div class="kros-form-field-icon-prefix">
      <ng-content select="[krosPrefix], [krosIconPrefix]"></ng-content>
    </div>

    <ng-content></ng-content>

    <ng-content select="kros-input-loader"></ng-content>

    <div class="kros-form-field-icon-suffix">
      <ng-content select="[krosSuffix], [krosIconSuffix]"></ng-content>
    </div>
  </div>

  <ng-template #tooltipTemplate>
    <ng-container [ngSwitch]="getDisplayedMessages()">
      <div *ngSwitchCase="'error'">
        <ng-content select="kros-input-error, [krosInputError]"></ng-content>
      </div>

      <div *ngSwitchCase="'hint'">
        <ng-content select="kros-input-hint, [krosInputHint]"></ng-content>
      </div>
    </ng-container>
  </ng-template>
</div>
