import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private hiddenBS = new BehaviorSubject<boolean | 'always'>(false);

  /**
   * navigation panel's status changes
   */
  hidden$ = this.hiddenBS.asObservable();

  constructor() { }

  /**
   * shows main navigation panel after it was hidden by this.hide
   */
  show(): void {
    this.hiddenBS.next(false);
  }

  /**
   * hides main navigation panel on small resolutions
   * @param always - hides also on large resolutions when true
   */
  hide(always?: boolean): void {
    this.hiddenBS.next(always ? 'always' : true);
  }
}
