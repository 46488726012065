import { Observable } from 'rxjs';

import { ToastService } from './services/toast.service';

export class ToastRef {
  constructor(
    private afterAction: Observable<void>,
    private callBack: Observable<any>,
    private toast: any,
    private toastService: ToastService
  ) { }

  afterDismissed(): Observable<any> {
    return this.callBack;
  }

  onAction(): Observable<void> {
    return this.afterAction;
  }

  dismiss(): void {
    this.toastService.close(this.toast);
  }
}
