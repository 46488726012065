import { inject, Injectable } from '@angular/core';

import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { map, Observable, of } from 'rxjs';

import { ApprovalOrder } from '../models';
import { ApprovalOrderState } from './approval-order.state';

export const buildingProgressFeatureKey = 'approval-order';

const selectBuildingProgressFeature = createFeatureSelector<ApprovalOrderState>(buildingProgressFeatureKey);

const selectApprovalOrder = createSelector(
  selectBuildingProgressFeature,
  state => state.approvalOrders
);

@Injectable({
  providedIn: 'root'
})
export class ApprovalOrderSelectorsService {
  private store = inject(Store<ApprovalOrderState>);

  approvalOrder$: Observable<ApprovalOrder[]> = this.store.select(selectApprovalOrder);

  getIsLastApprover(userId: number, isMultiStageApprovalEnabled: boolean, isContractor: boolean): Observable<boolean> {
    if (isMultiStageApprovalEnabled && !isContractor) {
      return this.approvalOrder$.pipe(map(data =>
        data.find(d => d.userId === userId)?.order === Math.max(...data.map(d => d.order))
      ));
    } else {
      return of(true);
    }
  }
}
