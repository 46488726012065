import { APOSTROPHE, COMMA } from '@angular/cdk/keycodes';
import { AbstractControl, Validator } from '@angular/forms';

const keyMap: { [code: number]: string } = {
  [APOSTROPHE]: '`',
  [COMMA]: ','
};

export class EmailValidator implements Validator {

  invalidEmails: string[] = [];
  validEmails: string[] = [];
  private emailPattern = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,4}$');
  private splitPattern: RegExp;

  constructor(keyCodes: number[]) {
    this.createSplitPattern(keyCodes);
  }

  validate(control: AbstractControl): { [key: string]: any } {
    if (!this.splitPattern || !control.value) { return null; }
    this.invalidEmails.splice(0, this.invalidEmails.length);
    this.validEmails.splice(0, this.validEmails.length);
    this.validateEmails(control.value);

    return this.invalidEmails && this.invalidEmails.length > 0
      ? { invalidEmails: this.invalidEmails.length }
      : null;
  }

  validateEmails(value: string): void {
    const possibleEmails: string[] = this.extractEmails(value);

    possibleEmails.forEach(element => {
      if (!this.emailPattern.test(element)) {
        this.invalidEmails.push(element);
      } else {
        this.validEmails.push(element);
      }
    });
  }

  private extractEmails(source: string): string[] {
    const emails: string[] = [];
    source.trim()
      .split(this.splitPattern)
      .forEach(email => {
        if (!this.isEmptyOrWhiteSpaces(email)) {
          emails.push(email);
        }
      });

    return emails;
  }

  private isEmptyOrWhiteSpaces(value: string): boolean {
    return value === null || value.match(/^ *$/) !== null;
  }

  private createSplitPattern(keyCodes: number[]): void {
    const regex = '[' + keyCodes.map(function (c) {
      return keyMap[c] ? keyMap[c] : String.fromCharCode(c);
    }).join('') + ']';
    this.splitPattern = new RegExp(regex);
  }
}

export function validateEmails(internValidator: Validator) {
  return (control: AbstractControl): { [key: string]: any } => {
    return internValidator.validate(control);
  };
}
