import { Component } from '@angular/core';

import { KrosModalRef } from '@kros-sk/components';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent {
  data: { body: string };

  constructor(
    private modalRef: KrosModalRef
  ) {
    this.data = this.modalRef.data;
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }
}
