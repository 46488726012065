import { PAGE_NOT_FOUND_QUERY_KEY, PageNotFoundParam } from '@kros-sk/models';

export function getCurrentCompanyIdFromUrl(url: string): number {
  const currentUrlItems = url.split('/');
  if (currentUrlItems.length > 2 && currentUrlItems[0] === '' &&
    currentUrlItems[1] === 'company' && !isNaN(+currentUrlItems[2])) {
    return parseInt(currentUrlItems[2], 10);
  }
  return 0;
}

/**
 * Returns company ID parsed from url or null if company ID was not found.
 * @param url string - the URL from which to get the company ID
 */
export function getCurrentCompanyIdFromFullUrl(url: string): number {
  if (!url) {
    return null;
  }

  const currentUrlSegments = url.split('/');
  const companyKeywordUrlSegment = currentUrlSegments.findIndex(segment => segment === 'company');

  if (companyKeywordUrlSegment < 0 || companyKeywordUrlSegment >= currentUrlSegments.length - 1) {
    return null;
  }

  const companyIdStr: string = currentUrlSegments[companyKeywordUrlSegment + 1];

  if (companyIdStr && Number.isInteger(+companyIdStr)) {
    return parseInt(companyIdStr, 10);
  }
  return null;
}

export function navigatingToNotFound(url: string, notFound: string = '/not-found', queryParams?: PageNotFoundParam[]): boolean {
  if (url.includes(notFound)) {
    if (queryParams && queryParams.length > 0) {
      return queryParams.some(p => url.includes(`${PAGE_NOT_FOUND_QUERY_KEY}=${p}`));
    }
    return true;
  }
  return false;
}

export function getCorrectRouteForCompanyLoad(
  currentUrl: string,
  companyId: number,
  routeSuffixSegmentApplicableFn: (segment: string) => boolean,
): (string | number)[] {
  const routeSuffix = currentUrl.replace(/\/company\/\d+\/?|\/company/, '').replace(/\/create[\w\-\/]*/, '');
  const route = ['/company', companyId];
  if (routeSuffix) {
    const segments = routeSuffix.split('?')[0].split('/');
    let index = 0;
    let segment = segments[index];
    while (segment && routeSuffixSegmentApplicableFn(segment)) {
      route.push(segment);
      segment = segments[++index];
    }
  }

  return route;
}

/**
 * Returns document detail ID parsed from url or 0 if document ID was not found.
 * @param url string - the URL from which to get the company ID
 */
export function getDocumentIdFromUrl(url: string): number {
  const urlItems = url.split('/');
  const index = urlItems.findIndex(segment => segment === 'detail');
  const idIndex = index + 1;
  if (index > -1 && urlItems[idIndex] && !isNaN(+urlItems[idIndex])) {
    return parseInt(urlItems[idIndex], 10);
  }
  return 0;
}
