import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import * as buildingProgressActions from './building-progress.actions';
import { BuildingProgressState } from './building-progress.state';
import { TableExportSettings } from '../../building-progress/models/table-export-settings.model';

@Injectable()
export class BuildingProgressExportDispatchersService {
  constructor(
    private store: Store<BuildingProgressState>
  ) { }

  loadTableExportSettings(): void {
    this.store.dispatch(
      buildingProgressActions.loadTableExportSettingsStart()
    );
  }

  changeTableExportSettings(tableExportSettings: TableExportSettings): void {
    this.store.dispatch(
      buildingProgressActions.changeTableExportSettings({ tableExportSettings })
    );
  }

  loadItemDrawSheetExportSettings(projectId: number): void {
    this.store.dispatch(buildingProgressActions.loadItemDrawSheetExportSettingsStart({ projectId }));
  }

  loadRemainingBudgetExportSettings(projectId: number): void {
    this.store.dispatch(buildingProgressActions.loadRemainingBudgetExportSettingsStart({ projectId }));
  }
}
