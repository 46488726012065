import { Action, createReducer, on } from '@ngrx/store';

import * as actions from './license.actions';
import { initialLicenseState, LicenseState } from './license.state';

const reducer = createReducer(
  initialLicenseState,
  on(actions.clearState, state => {
    return {
      ...initialLicenseState
    };
  }),
  on(actions.setMishaLicenseInfo, (state, payload) => {
    return {
      ...state,
      mishaLicenseInfo: payload.mishaLicenseInfo
    };
  }),
  on(actions.setBuildingProgressLicenseInfo, (state, payload) => {
    return {
      ...state,
      buildingProgressLicenseInfo: payload.buildingProgressLicenseInfo
    };
  }),
  on(actions.setBudgetLicenseInfo, (state, payload) => {
    return {
      ...state,
      budgetLicenseInfo: payload.budgetLicenseInfo
    };
  }),
  on(actions.setQuantityManagerLicenseInfo, (state, payload) => {
    return {
      ...state,
      quantityManagerLicenseInfo: payload.quantityManagerLicenseInfo
    };
  }),
  on(actions.clearMishaLicenseInfo, state => {
    return {
      ...state,
      mishaLicenseInfo: undefined
    };
  }),
  on(actions.clearBuildingProgressLicenseInfo, state => {
    return {
      ...state,
      buildingProgressLicenseInfo: undefined
    };
  }),
  on(actions.clearBudgetLicenseInfo, state => {
    return {
      ...state,
      budgetLicenseInfo: undefined
    };
  }),
  on(actions.clearQuantityManagerLicenseInfo, state => {
    return {
      ...state,
      quantityManagerLicenseInfo: undefined
    };
  }),
  on(actions.setLicenseDetail, (state, payload) => {
    return {
      ...state,
      licenseNumber: payload.licenseDetail.licenseNumber,
      isAutomaticRenewalLicense: payload.licenseDetail.isAutomaticRenewal
    };
  })
);

export function licenseReducer(state: LicenseState | undefined, action: Action): any {
  return reducer(state, action);
}
