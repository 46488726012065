import { TemplateRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { TooltipPosition } from '@kros-sk/ui/tooltip';

export interface CommonOptions {
  /**
   * handling formControl
   */
  formControl?: UntypedFormControl;

  /**
   * name of formControl
   */
  name?: string;

  /**
   * user text displayed as input label
   */
  label?: string;

  /**
   * keep space above input like the label is defined
   * nonsense when label is used
   */
  fakeLabel?: boolean;

  /**
   * Defines a unique identifier (ID) for input element.
   */
  id?: string;

  /**
   * placeholder passed to html input
   */
  placeholder?: string;

  /**
   * icon shown inside input
   */
  icon?: string;

  /**
   * template for label setting button
   */
  labelSettingTemplate?: TemplateRef<any>;

  /**
   * stop scrolling when inputs are in the modal window
   */
  stopScrollInModal?: boolean;

  /**
   * icon position
   * left is default - not needed
   */
  iconPosition?: 'left' | 'right';

  /**
   * class applied on html input element
   */
  inputClass?: string;

  /**
   * class applied on root element
   */
  className?: string;

  /**
   * custom error messages
   */
  errors?: {
    [validatorName: string]: string
  };

  /**
   * info tooltip template
   */
  info?: TemplateRef<any>;

  /**
   * krosTooltip placement - 'above' by default
   */
  tooltipPlacement?: TooltipPosition;

  /**
   * name passed to data-test attribute on input element
   */
  testName?: string;

  /**
   * show validation messages on untouched formcontrols
   */
  validateUntouched?: boolean;

  /**
   * turn off native html autocomplete
   */
  autocompleteOff?: boolean;

  /**
   * select entire content of input on click
   */
  selectAllOnClick?: boolean;

  /**
   * automatically scrolls and focuses given input at load
   */
  autoFocus?: boolean;

  /**
   * removes padding for footer template
   */
  removeFooterPadding?: boolean;
}

export interface InputCommand {
  /**
   * type of invoked action
   */
  type: InputCommandType;

  /**
   * payload for the action
   */
  data?: FocusInputOptions | any;
}

export enum InputCommandType {
  FOCUS_INPUT = 'FOCUS_INPUT',
  ENSURE_IN_VIEW = 'ENSURE_IN_VIEW',
  CHANGE_OPTIONS = 'CHANGE_OPTIONS',
  DETECT_CHANGES = 'DETECT_CHANGES',
  DETECT_CHANGES_AND_DISABLED_STATE = 'DETECT_CHANGES_AND_DISABLED_STATE',
  CLOSE_OVERLAY = 'CLOSE_OVERLAY',
  DO_BLUR = 'DO_BLUR',
  DETECT_RESIZE = 'DETECT_RESIZE', // only for inputs with hide if not visible
  CLOSE_AUTOCOMPLETE = 'CLOSE_AUTOCOMPLETE', // only for inputs with autocomplete
  REMOVE_AUTOCOMPLETE_OPTION = 'REMOVE_AUTOCOMPLETE_OPTION', // only for inputs with autocomplete
  TYPE_STRING = 'TYPE_STRING',
  APPEND_STRING = 'APPEND_STRING',
}

export interface FocusInputOptions {
  /**
   * whether the browser should scroll to the element when it is focused
   */
  preventScroll: boolean;
}
