import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { OpenApiSubscriptionApiModel } from '@kros-sk/models';

import { BuildingProgressBasePathSegment } from '../../../model';
import { getSettingsNavigateCommands } from '../../../services';

@Component({
  selector: 'ssw-open-api-settings-list-header',
  templateUrl: './open-api-settings-list-header.component.html',
  styleUrls: ['./open-api-settings-list-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenApiSettingsListHeaderComponent {
  @Input() subscription: OpenApiSubscriptionApiModel;
  @Input() isOpened = false;

  @Output() deleteClicked = new EventEmitter();

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  navigate(): void {
    const commands = getSettingsNavigateCommands(
      this.router.parseUrl(this.router.url), this.subscription.clientId, BuildingProgressBasePathSegment);
    this.router.navigate(commands, { relativeTo: this.activatedRoute });
  }

  delete(event: MouseEvent): void {
    event.stopPropagation();
    this.deleteClicked.emit();
  }
}
