import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NgrxFormsModule } from 'ngrx-forms';

import { FocusOnAfterInitDirective, KrosFormFieldModule } from '../kros-inputs';
import { KrosAutocompleteModule } from '../form/autocomplete';
import { KrosInputsModule } from '../inputs';
import { VatRateComponent } from './vat-rate.component';
import { VatRateComponentV2 } from './vat-rate.component--v2';
import { VatRateComponentV2Decimal } from './vat-rate.component--v2-decimal';
import { VatRateOnlyDecimalDirective } from './vat-rate-only-decimal.directive';
import { VatRateOnlyDirective } from './vat-rate-only.directive';

@NgModule({
  declarations: [
    VatRateComponent,
    VatRateComponentV2,
    VatRateComponentV2Decimal,
    VatRateOnlyDirective,
    VatRateOnlyDecimalDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    KrosInputsModule,
    KrosFormFieldModule,
    NgrxFormsModule,
    KrosAutocompleteModule,
    FocusOnAfterInitDirective
  ],
  exports: [
    VatRateComponent,
    VatRateComponentV2,
    VatRateComponentV2Decimal,
  ],
})
export class VatRateModule { }
