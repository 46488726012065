import { Injectable } from '@angular/core';

import { SubSink } from 'subsink';

import { BuildingProgressPermission, BuildingProgressPermissionType, PermissionType } from '@kros-sk/ssw-shared/permission';

import { BuildingProgressSelectorsService } from '../../store/building-progress/building-progress-selectors.service';
import {
  hasApproverRight,
  hasApproverViewRight,
  hasContractorFullRight,
  hasContractorRight,
  hasExporterRight,
} from '../shared/building-progress-utils';

@Injectable()
export class PermissionHelper {

  permission: BuildingProgressPermission;

  private subs = new SubSink();

  get isOwner(): boolean {
    return this.permission?.permissionType >= PermissionType.Owner;
  }

  get isLessThanContributor(): boolean {
    return this.permission?.permissionType < PermissionType.Contributor;
  }

  get isAtLeastContributor(): boolean {
    return this.permission?.permissionType >= PermissionType.Contributor;
  }

  get isContributor(): boolean {
    return this.permission?.permissionType === PermissionType.Contributor;
  }

  get isContractor(): boolean {
    return this.permission?.permissionType === PermissionType.Shared &&
      hasContractorRight(this.permission.buildingProgressPermissionType);
  }

  get isContractorFull(): boolean {
    return this.permission?.permissionType === PermissionType.Shared &&
      hasContractorFullRight(this.permission.buildingProgressPermissionType);
  }

  get isReader(): boolean {
    if (!this.permission) {
      return true;
    }

    return this.permission.permissionType < PermissionType.Reader ||
      (this.permission.permissionType === PermissionType.Reader &&
        this.permission.buildingProgressPermissionType === BuildingProgressPermissionType.None);
  }

  get isApprover(): boolean {
    return hasApproverRight(this.permission?.buildingProgressPermissionType) ||
      hasApproverViewRight(this.permission?.buildingProgressPermissionType);
  }

  get isExporter(): boolean {
    return hasExporterRight(this.permission?.buildingProgressPermissionType);
  }

  get hasPermission(): boolean {
    return this.permission && this.permission.permissionType > PermissionType.NoAccess;
  }

  get areCommentsReadOnly(): boolean {
    return !this.permission ||
      (this.permission.permissionType === PermissionType.Reader &&
        (this.permission.buildingProgressPermissionType | BuildingProgressPermissionType.Commenter) !==
        this.permission.buildingProgressPermissionType);
  }

  get areDetailsReadOnly(): boolean {
    return !this.isOwner && !this.isContributor && !this.isContractor;
  }

  get areNotesReadOnly(): boolean {
    return !this.isOwner && !this.isContributor && !this.isContractor;
  }

  get permissionType(): PermissionType {
    return this.permission?.permissionType;
  }

  get buildingProgressPermissionType(): BuildingProgressPermissionType {
    return this.permission?.buildingProgressPermissionType;
  }

  constructor(
    buildingProgressSelectorsService: BuildingProgressSelectorsService
  ) {
    this.subs.sink = buildingProgressSelectorsService.permission$
      .subscribe((permission: BuildingProgressPermission) => this.permission = permission);
  }

  destroy(): void {
    this.subs.unsubscribe();
  }
}
