import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AvatarModule as KrosAvatarModule } from '@kros-sk/components';
import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { AvatarComponent } from './avatar/avatar.component';
import { AvatarListComponent } from './avatar-list/avatar-list.component';
import { DirectivesModule } from '../directives';

@NgModule({
  declarations: [
    AvatarComponent,
    AvatarListComponent
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    TooltipDirective,
    KrosAvatarModule
  ],
  exports: [
    AvatarComponent,
    AvatarListComponent
  ]
})
export class AvatarsModule { }
