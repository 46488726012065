<div
  class="tree-container"
  *ngIf="areDataLoaded else loading"
>
  <h4
    class="header-text"
    *ngIf="hasTitle"
  >{{ title }}</h4>

  <ng-container *ngIf="hasCheckboxSelection">
    <div class="buttons easy-select">
      <button
        class="btn btn-outline-primary"
        type="button"
        (click)="onSelectAllAditionsClick()"
      >{{ 'BUILDING_PROGRESS.EXPORT_SELECT_ONLY_ADDITIONS' | translate }}</button>

      <button
        class="btn btn-outline-primary"
        type="button"
        (click)="onSelectAllExceptAditionsClick()"
      >{{ 'BUILDING_PROGRESS.EXPORT_SELECT_ALL_EXCEPT_ADDITIONS' | translate }}</button>
    </div>
  </ng-container>

  <div class="tree">
    <cdk-tree
      [dataSource]="dataSource"
      [treeControl]="treeControl"
    >
      <cdk-tree-node
        *cdkTreeNodeDef="let node"
        class="tree-node"
        [ngClass]="{
                    'addition-color': isColoredAsAdditionOrChangeSheet(node.data),
                    'visible': shouldRender(node),
                    'hidden': !shouldRender(node)
                }"
      >
        <div
          id="{{ node.data.id }}"
          class="tree-row level-{{getNodeLevel(node)}} no-selection"
          (click)="selectNode(node)"
          [ngClass]="{'selected': isSelected(node), 'object': colorizedObjects && isConstructionOrBuildingObject(node.data)}"
          [attr.data-test]="'tree-row-level-' + getNodeLevel(node)"
        >
          <input
            type="checkbox"
            class="checkbox"
            *ngIf="hasCheckboxSelection"
            [checked]="isItemCheckChecked(node)"
            [krosIndeterminate]="isItemCheckIndeterminate(node)"
            (change)="onItemCheckChanged($event.target, node)"
          >

          <ng-container *ngIf="!hasCheckboxSelection">
            <span
              #expandColumn
              krosMaterialIcon
              cdkTreeNodeToggle
              class="expander"
              data-test="expander-icon"
              *ngIf="node.isExpandable else emptyColumn"
              (click)="node.isExpanded = !node.isExpanded"
            >{{treeControl.isExpanded(node) ? 'arrow_drop_down' : 'arrow_right'}}</span>
          </ng-container>

          <ng-template #emptyColumn>
            <div></div>
          </ng-template>

          <span class="empty-column"></span>

          <span
            class="node-text"
            krosTooltip="{{hasTooltips ? node.data.code + ' - ' + node.data.description : ''}}"
            data-test="node-text"
            [innerHTML]="node.data.code + ' - ' + node.data.description | safeHtml"
          ></span>
        </div>
      </cdk-tree-node>
    </cdk-tree>
  </div>
</div>

<ng-template #loading>
  <kros-timeline [type]="timelineType.TwoLine"></kros-timeline>
</ng-template>
