import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { catchError, Observable, throwError } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';

import { DocumentShareResultType, DocumentSharingResultInfo, DocumentUserSharing } from '../models';
import { TranslateService } from '../../translate';

const documentSharingApi = '/api/documentService/DocumentsSharing';
const documentUserSharingListEndpoint = '/documentsUserSharingList';

@Injectable()
export class DocumentSharingService {

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    @Inject(APP_CONFIG) private appConfig: any
  ) { }

  private get documentSharingApi(): string {
    return this.appConfig.appUrls.titanGatewayUrl + documentSharingApi;
  }

  getDocumentUserSharingList(documentIds: string[]): Observable<DocumentUserSharing[]> {
    return this.http.post<DocumentUserSharing[]>(this.documentSharingApi + documentUserSharingListEndpoint, documentIds)
      .pipe(
        catchError(this.handleShareError.bind(this))
      );
  }

  private handleShareError(error: HttpErrorResponse): Observable<never> {
    const info: DocumentSharingResultInfo = error.error;
    let errorMessage: string;

    switch (info.result) {
      case DocumentShareResultType.DocumentDoesNotExists:
        errorMessage = this.translateService.translate('DOCUMENTS.ERROR.SHARING_DOCUMENTS_NOT_EXIST');
        break;
      case DocumentShareResultType.NoPermissionForChange:
        errorMessage = this.translateService.translate('DOCUMENTS.ERROR.SHARING_NO_PERMISSION');
        break;
      case DocumentShareResultType.MultipleEditsForbidden:
        errorMessage = this.translateService.translate('DOCUMENTS.ERROR.SHARING_MULTIPLE_EDITS');
        break;
      case DocumentShareResultType.OwnerChangeForbidden:
        errorMessage = this.translateService.translate('DOCUMENTS.ERROR.SHARING_OWNER_CHANGE');
        break;
      default:
        errorMessage = this.translateService.translate('SHARING.UNKNOWN_ERROR');
        break;
    }

    console.error(
      `Backend returned code ${error.status}, ` +
      `error message: ${errorMessage}`
    );

    return throwError(() => new Error(errorMessage));
  }
}
