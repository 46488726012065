export const languageOptions = [
  {
    id: 'en',
    title: 'English',
    flag: 'gb'
  },
  {
    id: 'cz',
    title: 'Čeština',
    flag: 'cz'
  },
  {
    id: 'sk',
    title: 'Slovenčina',
    flag: 'sk'
  }
];
