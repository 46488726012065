import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SidePanelComponent } from './side-panel.component';

@NgModule({
  declarations: [
    SidePanelComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SidePanelComponent
  ]
})
export class SidePanelModule { }
