import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

import { AnalyticsService, KrosApiBlobStorageService } from '@kros-sk/ssw-shared-legacy';
import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { KrosModalRef, KrosModalService } from '@kros-sk/components';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { BudgetApprovalExportModel } from '../../models/budget-approval-export.model';
import { BudgetApprovalModel } from '../../models';
import { environment } from '../../../../environments/environment';
import {
  ExportChangeSheetSettingsComponent
} from '../export-change-sheet-settings/export-change-sheet-settings.component';
import { ExportSummarySettingsComponent } from '../export-summary-settings/export-summary-settings.component';
import { PermissionHelper } from '../../../building-progress/helpers';
import {
  ProjectHeaderDataDialogComponent
} from '../../../shared/components/project-header-data-dialog/project-header-data-dialog.component';
import { ProjectsDispatchersService } from '../../../store/projects';

const userServiceApi = '/api/userService';

@Component({
  selector: 'app-budget-approval-export',
  templateUrl: './budget-approval-export.component.html',
  styleUrls: ['./budget-approval-export.component.scss']
})
export class BudgetApprovalExportComponent extends UnsubscribeComponent implements OnInit {

  exportModel: BudgetApprovalExportModel;
  budgetApprovalData: BudgetApprovalModel;
  singleBudgetItemExport = false;
  canExportLogo = true;
  canExportStamp = true;

  exportForm: UntypedFormGroup;

  constructor(
    private analyticsService: AnalyticsService,
    private appInsightsService: AppInsightsBaseService,
    private projectsDispatcherService: ProjectsDispatchersService,
    private permissionHelper: PermissionHelper,
    private formBuilder: UntypedFormBuilder,
    private krosModalService: KrosModalService,
    private modalRef: KrosModalRef,
    private krosApiBlobService: KrosApiBlobStorageService,
  ) {
    super();

    this.exportModel = modalRef.data.exportModel;
    this.budgetApprovalData = modalRef.data.budgetApprovalData;
    this.singleBudgetItemExport = !!modalRef.data.budgetItemId;
  }

  ngOnInit(): void {
    this.exportForm = this.formBuilder.group(
      {
        isSummaryIncluded: [this.exportModel.isSummaryIncluded],
        isChangeSheetIncluded: [this.exportModel.isChangeSheetIncluded],
        isLogoIncluded: [this.exportModel.exportGeneralViewModel.isLogoIncluded],
        isStampIncluded: [this.exportModel.exportGeneralViewModel.isStampIncluded]
      },
      { validators: this.formValidator }
    );

    this.subs.sink = this.exportForm.get('isStampIncluded').valueChanges
      .subscribe(v => {
        if (v && !this.canExportStamp) {
          this.exportForm.get('isStampIncluded').setValue(false);
        }
      });
    this.subs.sink = this.exportForm.get('isLogoIncluded').valueChanges
      .subscribe(v => {
        if (v && !this.canExportLogo) {
          this.exportForm.get('isLogoIncluded').setValue(false);
        }
      });
    this.subs.sink = this.krosApiBlobService.getDownloadToken(`${environment.gatewayApiUrl}${userServiceApi}/Logo`)
      .subscribe(azureCredentials => this.canExportLogo = azureCredentials?.name !== '');
    this.subs.sink = this.krosApiBlobService.getDownloadToken(`${environment.gatewayApiUrl}${userServiceApi}/Stamp`)
      .subscribe(azureCredentials => this.canExportStamp = azureCredentials?.name !== '');
  }

  isOneCategorySelected(): boolean {
    if (this.exportForm) {
      return this.exportForm.get('isSummaryIncluded').value ||
        this.exportForm.get('isChangeSheetIncluded').value;
    } else {
      return false;
    }
  }

  private formValidator(formGroup: AbstractControl): ValidationErrors {
    const atLeastOneExportChecked =
      formGroup.get('isSummaryIncluded').value ||
      formGroup.get('isChangeSheetIncluded').value;
    return atLeastOneExportChecked ? null : { atLeastOneExportChecked: false };
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  onHeaderDataEdit(): void {
    this.appInsightsService.trackEvent('BA-export-header-data-open');
    const modalRef = this.krosModalService.openCentered(
      ProjectHeaderDataDialogComponent,
      {
        projectId: this.exportModel.projectId,
        canEdit: !this.permissionHelper.isLessThanContributor
      },
      {
        addModalToBrowsersHistory: false
      }
    );

    this.subs.sink = modalRef.afterClosed$
      .subscribe(result => {
        if (result.type === 'submit' && result.data.project) {
          this.appInsightsService.trackEvent('BA-export-header-data-edit', result.data.project);
          this.projectsDispatcherService.editProjectDetail(result.data.project);
        }
      });
  }

  export(): void {
    this.copyPropertiesFromControl();
    this.sendAnalyticsNotification();
    this.modalRef.submit({ exportModel: this.exportModel });
  }

  getSummaryIncludedControlValue(): boolean {
    return this.exportForm.get('isSummaryIncluded').value;
  }

  getChangeSheetsIncludedControlValue(): boolean {
    return this.exportForm.get('isChangeSheetIncluded').value;
  }

  openSummarySettingsModal(): void {
    const modalRef = this.krosModalService.openCentered(
      ExportSummarySettingsComponent,
      {
        summarySettings: this.exportModel.exportSummaryViewModel
      },
      {
        addModalToBrowsersHistory: false
      }
    );

    this.subs.sink = modalRef.afterClosed$
      .subscribe(result => {
        if (result.type === 'submit' && result.data) {
          this.exportModel.exportSummaryViewModel = result.data.summarySettings;
          this.exportForm.get('isSummaryIncluded').setValue(true);
        }
      });
  }

  openChangeSheetsSettingsModal(): void {
    const modalRef = this.krosModalService.openCentered(
      ExportChangeSheetSettingsComponent,
      {
        changeSheetSettings: this.exportModel.exportChangeSheetViewModel
      },
      {
        addModalToBrowsersHistory: false
      }
    );

    this.subs.sink = modalRef.afterClosed$
      .subscribe(result => {
        if (result.type === 'submit' && result.data) {
          this.exportModel.exportChangeSheetViewModel = result.data.changeSheetSettings;
          this.exportForm.get('isChangeSheetIncluded').setValue(true);
        }
      });
  }

  onSummaryChangeSheetsSelected(changeSheetIds: Set<number> | number[]): void {
    this.exportModel.exportSummaryViewModel.changeSheetIds = Array.from(changeSheetIds);
  }

  onChangeSheetsSelected(changeSheetIds: Set<number> | number[]): void {
    this.exportModel.exportChangeSheetViewModel.changeSheetIds = Array.from(changeSheetIds);
  }

  private copyPropertiesFromControl(): void {
    const formControls = this.exportForm.controls;
    this.exportModel.isSummaryIncluded = formControls.isSummaryIncluded.value;
    this.exportModel.isChangeSheetIncluded = formControls.isChangeSheetIncluded.value;
    this.exportModel.exportGeneralViewModel.isLogoIncluded = formControls.isLogoIncluded.value;
    this.exportModel.exportGeneralViewModel.isStampIncluded = formControls.isStampIncluded.value;
  }

  private sendAnalyticsNotification(): void {
    this.appInsightsService.trackEvent('BA-export-podkladov-zmien', {
      isChangeSheetIncluded: '' + this.exportModel.isChangeSheetIncluded,
      isBoQIncluded: '' + this.exportModel.exportChangeSheetViewModel.isBoqIncluded,
      isSummaryIncluded: '' + this.exportModel.isSummaryIncluded,
      isTotalPriceChangesIncluded: '' + this.exportModel.exportSummaryViewModel.isTotalPriceChangesIncluded,
      isTotalPriceChangesPercentageIncluded: '' + this.exportModel.exportSummaryViewModel.isTotalPriceChangesPercentageIncluded,
      isPositiveAndNegativeChangesIncluded: '' + this.exportModel.exportSummaryViewModel.isPositiveAndNegativeChangesIncluded,
      isAbsoluteChangesIncluded: '' + this.exportModel.exportSummaryViewModel.isAbsoluteChangesIncluded,
      isAbsoluteChangesPercentageIncluded: '' + this.exportModel.exportSummaryViewModel.isAbsoluteChangesPercentageIncluded,
      isLogoIncluded: '' + this.exportModel.exportGeneralViewModel.isLogoIncluded,
      isStampIncluded: '' + this.exportModel.exportGeneralViewModel.isStampIncluded,
      statisticPropertyNames: 'isLogoIncluded, isStampIncluded'
    });

    this.raiseCheckboxAnalyticsEvent(this.exportModel.isSummaryIncluded,
      'rekapituláciu zmien', '"Rekapitulácia zmien"');
    this.raiseCheckboxAnalyticsEvent(this.exportModel.exportSummaryViewModel.isTotalPriceChangesIncluded,
      'cenu zmeny celkom', '"Cena zmeny celkom"');
    this.raiseCheckboxAnalyticsEvent(this.exportModel.exportSummaryViewModel.isTotalPriceChangesPercentageIncluded,
      'cenu zmeny celkom/pôv. cenu stavby [%]', '"Cena zmeny celkom/Pôv. cena stavby [%]"');
    this.raiseCheckboxAnalyticsEvent(this.exportModel.exportSummaryViewModel.isPositiveAndNegativeChangesIncluded,
      'rozdelenie zmien na záporné a kladné', '"Rozdelenie zmien na záporné a kladné"');
    this.raiseCheckboxAnalyticsEvent(this.exportModel.exportSummaryViewModel.isAbsoluteChangesIncluded,
      'absolútnu zmenu', '"Absolútna zmena"');
    this.raiseCheckboxAnalyticsEvent(this.exportModel.exportSummaryViewModel.isAbsoluteChangesPercentageIncluded,
      'absolútnu zmenu/pôv. cenu stavby [%]', '"Absolútna zmena/Pôv. cena stavby [%]"');
    this.raiseCheckboxAnalyticsEvent(this.exportModel.isChangeSheetIncluded,
      'rozpočty zmenových listov', '"Rozpočty zmenových listov"');
    this.raiseCheckboxAnalyticsEvent(this.exportModel.exportChangeSheetViewModel.isBoqIncluded,
      's výkazom výmer', '"S výkazom výmer"');
    this.raiseCheckboxAnalyticsEvent(this.exportModel.exportGeneralViewModel.isLogoIncluded,
      'použiť logo', '"Použiť logo"');
    this.raiseCheckboxAnalyticsEvent(this.exportModel.exportGeneralViewModel.isStampIncluded,
      'použiť pečiatku', '"Použiť pečiatku"');
  }

  private raiseCheckboxAnalyticsEvent(canSendStats: boolean, eventAction: string, eventLabel: string): void {
    if (canSendStats) {
      this.analyticsService.raiseEvent('ZBV', 'Exportovať ' + eventAction,
        'Zaškrtnutý checkbox ' + eventLabel + ' v okne exportu "Podklady pre schvalovanie zmien"');
    }
  }
}
