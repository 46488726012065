import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';

import { SubSink } from 'subsink';

import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { CommonOptions, InputCommand, InputCommandType } from './inputs.common.interface';
import { DeviceDetectorService, DeviceType, GlobalEventsService } from '../services';
import { getScrollableParent } from '../tools';
import { KrosFormsService } from './forms.service';
import { KrosValidationMessagesComponent } from './validationMessages/validationMessages.component';

@Directive()
export abstract class KrosInputBase implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit {
  @Input() options: CommonOptions;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur: EventEmitter<FocusEvent> = new EventEmitter();

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() focus: EventEmitter<FocusEvent> = new EventEmitter();

  @ViewChild('tooltip', { static: false }) tooltip: TooltipDirective;

  @ViewChild('validationMessages', { static: false }) validationMessages: KrosValidationMessagesComponent;

  @ViewChild('input', { static: false }) input: ElementRef;

  @ViewChild('container', { static: false }) container: ElementRef;

  subs = new SubSink();
  focused: boolean;
  hovered: boolean;
  mainControl: UntypedFormControl;
  currentOptions: CommonOptions;
  uniqueId: string;

  protected forceBlur: boolean;

  constructor(
    protected cd: ChangeDetectorRef,
    protected globalEventsService: GlobalEventsService,
    protected injector: Injector,
    protected formsService: KrosFormsService,
    protected deviceDetector: DeviceDetectorService
  ) {}

  ngOnInit(): void {
    if (!this.options) {
      this.options = {};
    }
    this.currentOptions = this.options;
    if (this.currentOptions.formControl) {
      this.mainControl = this.currentOptions.formControl;
    } else {
      this.mainControl = this.injector.get(NgControl).control as UntypedFormControl;
    }

    if (this.currentOptions.id) {
      this.uniqueId = this.currentOptions.id;
    } else {
      this.uniqueId = new Date().valueOf() + '-' + this.currentOptions.name;
    }

    this.subs.sink = this.mainControl.statusChanges.subscribe((status) => {
      this.detectChanges();
      if (this.validationMessages) {
        this.validationMessages.detectChanges();
      }
      if (this.mainControl.errors && (this.focused || this.hovered)) {
        this.tooltip.show();
      }
    });

    // need to detect changes when window is resized to update dropdown size
    this.subs.sink = this.globalEventsService.listenEvent('window:resize').subscribe(() => {
      // update input position when software keyboard is opened
      if (this.focused && this.deviceDetector.deviceType === DeviceType.Mobile) {
        this.ensureInView(false);
      }
      this.detectChanges();
    });

    this.subs.sink = this.formsService.inputCommandsTrigger.subscribe((command) => {
      if (command.targets.includes(this.currentOptions.name)) {
        this.handleCommand(command.command);
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.options.autoFocus) {
      this.formsService.triggerInputCommand(this.options.name, { type: InputCommandType.FOCUS_INPUT });
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get width(): number {
    if (this.container?.nativeElement) {
      return this.container.nativeElement.offsetWidth - 45;
    }
    return 0;
  }

  get showError(): boolean {
    return this.mainControl.errors
      && (this.mainControl.touched || this.currentOptions.validateUntouched);
  }

  registerOnTouched(fn: any): void {
    // not needed
  }

  registerOnChange(fn: any): void {
    // not needed
  }

  writeValue(value: any): void {
    // not needed
  }

  doFocus(event: FocusEvent): void {
    if (this.options.selectAllOnClick) {
      this.input.nativeElement.select();
    }
    if ((this.deviceDetector.deviceType === DeviceType.Mobile || this.isOverlapped()) && !this.options.stopScrollInModal) {
      this.ensureInView(false);
    }
    this.tooltip.show();
    this.focus.emit(event);
    this.focused = true;
  }

  doBlur(event: FocusEvent): void {
    if (document.activeElement === this.input.nativeElement && !this.hovered) {
      this.input.nativeElement.blur();
    }
    this.focused = false;
    if (!this.hovered) {
      this.tooltip.hide();
    }
    this.blur.emit(event);
    if (this.deviceDetector.deviceType === DeviceType.Mobile) {
      this.scrollOnePixel();
    }
  }

  mouseLeave(): void {
    this.hovered = false;
    if (!this.focused) {
      this.tooltip.hide();
    }
  }

  mouseEnter(): void {
    this.hovered = true;
    if (this.tooltip) {
      this.tooltip.show();
    }
  }

  protected handleCommand(command: InputCommand): void {
    if (command.type === InputCommandType.CHANGE_OPTIONS) {
      Object.keys(command.data).forEach((key) => {
        this.currentOptions[key] = command.data[key];
      });
      this.detectChanges();
      if (command.data.info && (this.focused || this.hovered)) {
        this.tooltip.show();
      } else if (command.data.info === null) {
        this.tooltip.hide();
      }
    } else if (command.type === InputCommandType.DETECT_CHANGES) {
      this.detectChanges();
    } else if (command.type === InputCommandType.FOCUS_INPUT) {
      if (this.input) {
        if (command.data && command.data.preventScroll) {
          this.input.nativeElement.focus({ preventScroll: true });
        } else {
          this.ensureInView(true);
        }
        this.detectChanges();
      }
    } else if (command.type === InputCommandType.ENSURE_IN_VIEW) {
      if (this.input) {
        this.ensureInView(false);
      }
    } else if (command.type === InputCommandType.DO_BLUR) {
      if (this.input) {
        this.forceBlur = true;
        this.doBlur(command.data);
      }
    } else if (command.type === InputCommandType.TYPE_STRING) {
      if (this.input && command.data?.text) {
        this.mainControl.setValue(command.data.text);
      }
    } else if (command.type === InputCommandType.APPEND_STRING) {
      if (this.input && command.data?.text) {
        this.mainControl.setValue(this.mainControl.value + command.data.text);
      }
    }
  }

  protected removeForbiddenCharacters(value: any): any {
    if (value && typeof value === 'string') {
      const emojiRegex = new RegExp(
        [
          /[\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|/,
          /\uD83D[\uDC00-\uDFFF]|[\u20AD-\u26FF]|\uD83E[\uDD10-\uDDFF]|/,
          /[\u200B-\u200F\uFEFF\uFE0F]/
        ].map(r => r.source).join(''),
        'g'
      );

      return value.replace(emojiRegex,'');
    }
    return value;
  }

  protected isOverlapped(): boolean {
    const inputRect = this.input.nativeElement.getBoundingClientRect();
    const margin = inputRect.height * 0.3;
    const elTop = this.getElementFromPoint(inputRect.x, inputRect.top + margin);
    if (!elTop) {
      return false;
    }
    const elBottom = this.getElementFromPoint(inputRect.x, inputRect.bottom - margin);
    if (!elBottom) {
      return false;
    }
    return !elTop.contains(this.input.nativeElement) || !elBottom.contains(this.input.nativeElement);
  }

  protected detectChanges(): void {
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }

  /**
   * this method hides from elementFromPoint cdk overlay related elements to not have false positive result
   */
  private getElementFromPoint(x, y): Element {
    const origStyles: { [idOrClass: string]: string } = {};
    document.querySelectorAll('[class*="cdk-overlay"]').forEach((el: HTMLElement) => {
      if (el.id) {
        origStyles[el.id] = el.style.pointerEvents;
      } else {
        origStyles[el.className] = el.style.pointerEvents;
      }
      el.style.pointerEvents = 'none';
    });
    const element = document.elementFromPoint(x, y);
    document.querySelectorAll('[class*="cdk-overlay"]').forEach((el: HTMLElement) => {
      if (el.id) {
        el.style.pointerEvents = origStyles[el.id];
      } else {
        el.style.pointerEvents = origStyles[el.className];
      }
    });
    return element;
  }

  private ensureInView(doFocus: boolean): void {
    const isModalOpened = document.body.classList.contains('modal-opened');
    if (isModalOpened) {
      document.body.classList.remove('modal-opened');
    }
    const scrollableParent = getScrollableParent(this.container.nativeElement);
    if (scrollableParent) {
      scrollableParent.element.scrollTop = 0;
      const element = this.container.nativeElement;
      const elementBounding = element.getBoundingClientRect();
      const eTop = elementBounding.top - scrollableParent.element.offsetTop;
      scrollableParent.element.scrollTop = eTop - scrollableParent.unscrollableTop;

      if (doFocus) this.input.nativeElement.focus({ preventScroll: true });
    } else {
      if (doFocus) {
        this.input.nativeElement.focus();
      } else {
        this.input.nativeElement.scrollIntoView();
      }
    }
    if (isModalOpened) {
      document.body.classList.add('modal-opened');
    }
  }

  private scrollOnePixel(): void {
    setTimeout(() => {
      const scrollableParent = getScrollableParent(this.container.nativeElement);
      if (scrollableParent) {
        scrollableParent.element.scrollTop -= 1;
      } else {
        this.input.nativeElement.scrollTop -= 1;
      }
    });
  }
}
