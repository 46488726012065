import { formatNumber } from '@angular/common';

export const getThousandsSeparator = (appLocation: string): string => {
  return formatNumber(1111, appLocation).replace(/1/g, '');
};

export const getFloatFromString = (value: string, thousandsSeparator: string): number => {
  return parseFloat(getNumericString(value, thousandsSeparator));
};

export const formatNumberValue = (value: any, decimalPlaces: number, appLocation: string, editMode = false): string => {
  let returnValue = value;
  if (returnValue !== undefined && returnValue !== null) {
    if (editMode) {
      const valueDecimalPlaces = String(value).split('.')[1]?.length ?? 0;
      returnValue = formatNumber(returnValue, appLocation,
        `1.${Math.max(decimalPlaces, valueDecimalPlaces)}-${Math.max(decimalPlaces, valueDecimalPlaces)}`);
    } else {
      returnValue = formatNumber(returnValue, appLocation, `1.${decimalPlaces}-${decimalPlaces}`);
    }
  }

  return returnValue;
};

export const isLocalNumber = (value: string, appLocation: string): boolean => {
  return !isNaN(Number(getNumericString(value, getThousandsSeparator(appLocation))));
};

export const isLikeNumber = (value: any): boolean =>
  isNumber(('' + value).replace(',', '.'));

export const isNumber = (value: any): boolean => {
  value = '' + value;
  return isStringEmpty(value) ? false : !isNaN(Number(value));
};

export const getNumericString = (value: string, thousandsSeparator: string): string => {
  return value?.replace(/,/g, '.').replace(new RegExp('\\' + thousandsSeparator, 'g'), '') ?? '';
};

const isStringEmpty = (value: string): boolean => {
  return value.trim() === '';
};
