import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';

import { InfoMessage } from '../model/info-message';

@Injectable()
export class InfoMessageApiService {
  private readonly infoMessageEndpoint = 'api/InfoMessage';

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any
  ) { }

  loadInfoMessages(): Observable<InfoMessage[]> {
    return this.http.get<InfoMessage[]>(`${this.appConfig.gatewayApiUrl}/${this.infoMessageEndpoint}`);
  }
}
