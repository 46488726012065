import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { DxAccordionModule, DxTabPanelModule } from 'devextreme-angular';
import { LetDirective } from '@ngrx/component';

import { KrosComponentsModule, KrosDatepickerModule, KrosInputsModule, ToastModule } from '@kros-sk/components';
import { KrosDeviceDetectorProviderModule } from '@kros-sk/device-detector';
import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { AnalyticsModule } from '../analytics';
import { BootstrapModule } from '../bootstrap';
import { CompanySearchModule } from '../company-search';
import { DirectivesModule } from '../directives';
import { ProjectCreateDialogComponent, ProjectHeaderDataPanelComponent } from './components';
import { ProjectCreateService } from './services';
import { ProjectInfoDialogComponent } from './components/project-info-dialog/project-info-dialog.component';
import { TagsModule } from '../tags';
import { TranslateModule } from '../translate';

@NgModule({
  declarations: [
    ProjectCreateDialogComponent,
    ProjectHeaderDataPanelComponent,
    ProjectInfoDialogComponent
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    LetDirective,
    ToastModule,
    TranslateModule.forChild(),
    AnalyticsModule,
    CommonModule,
    DirectivesModule,
    TagsModule,
    CompanySearchModule,
    BootstrapModule,
    TooltipDirective,
    DxTabPanelModule,
    KrosDatepickerModule,
    KrosDeviceDetectorProviderModule,
    DxAccordionModule,
    KrosComponentsModule,
    KrosInputsModule
  ],
  providers: [
    ProjectCreateService
  ],
  exports: [
    ProjectHeaderDataPanelComponent,
    ProjectCreateDialogComponent,
    ProjectInfoDialogComponent
  ]
})
export class ProjectInfoModule { }
