<app-item-details
  [data]="itemPeriodDetails$"
  [isNew]="isNew"
  [label]="periodName"
  [info]="info"
  [loading]="itemPeriodDetailsLoading"
  [readOnly]="areDetailsReadOnly()"
  [canShowDetails]="panelHelper.isFocusedCellInPeriod"
  [canNotShowDetailsLabel]="'BUILDING_PROGRESS.DETAILS.NOT_SELECTED_PERIOD' | translate"
  (editing)="onEditingChanged($event)"
  (save)="panelHelper.onSaveDetails($event)"
  (close)="panelHelper.onCloseDetails()"
></app-item-details>
