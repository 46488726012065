import { createSelector } from '@ngrx/store';

import { featureFlagsStoreFeature } from './feature-flags.state';

const {
  selectFeatureFlags,
  selectFeaturesLoaded,
  selectFeatureFlagsState
} = featureFlagsStoreFeature;

/**
 * Check if a feature flag is enabled
 * @param featureFlag
 */
const selectIsFeatureFlagEnabled = (featureFlag: string) => createSelector(
  selectFeatureFlags,
  (flags) => flags[featureFlag] ?? false
);

export const fromFeatureFlags = {
  selectFeatureFlagsState,
  selectFeaturesLoaded,
  selectFeatureFlags,

  selectIsFeatureFlagEnabled,
};
