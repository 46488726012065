
import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import * as buildProgressDataSettingsActions from './building-progress-data-settings.actions';
import { BuildingProgressService } from '../../building-progress/services';

@Injectable()
export class BuildingProgressDataSettingsEffects {

  loadDataSettings$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildProgressDataSettingsActions.loadDataSettingsStart),
      switchMap(() => this.buildingProgressService.loadDataSettings().pipe(
        map(settings => buildProgressDataSettingsActions.loadDataSettingsSuccess(
          { hiddenColumns: settings.hiddenColumns, isSummaryPanelVisible: settings.isSummaryPanelVisible })),
        catchError(error => {
          return of(buildProgressDataSettingsActions.loadDataSettingsError({ error }));
        })
      ))
    );
  });

  saveDataSettings$: Observable<void> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildProgressDataSettingsActions.saveDataSettings),
      switchMap(payload => this.buildingProgressService.saveDataSettings(payload))
    );
  }, { dispatch: false });

  constructor(
    private actions$: Actions,
    private buildingProgressService: BuildingProgressService
  ) { }
}
