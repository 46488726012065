<div class="kros-modal-header">
  <h4 class="kros-modal-title">
    {{'BUILDING_PROGRESS.PARTIAL_ITEMS.SPLIT' | translate }} ({{ itemAmount }})</h4>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onClose()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div class="kros-modal-body">
  <app-building-progress-partial-item-splitter></app-building-progress-partial-item-splitter>

  <ssw-building-progress-partial-item-table
    #partialItemsTableRef
    [buildingProgressItem]="buildingProgressItem"
  ></ssw-building-progress-partial-item-table>
</div>

<div class="kros-modal-footer">
  <button
    class="btn btn-primary btn-new"
    data-test="split-budget-items-button"
    type="button"
    (click)="onSavePartialItems()"
  >
    <span class="text">
      {{'BUILDING_PROGRESS.PARTIAL_ITEMS.SPLIT' | translate }}</span>
  </button>
</div>
