import { CdkMenuItem } from '@angular/cdk/menu';
import { Component } from '@angular/core';
import { InputModalityDetector } from '@angular/cdk/a11y';
import { Platform } from '@angular/cdk/platform';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[kros-dropdown-item]',
  exportAs: 'krosDropdownItem',
  templateUrl: './kros-dropdown-item.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'kros-dropdown-item',
    '[class.kros-dropdown-item-open]': 'isMenuOpen()',
    '[attr.disabled]': 'disabled || null',
  },
  providers: [
    { provide: CdkMenuItem, useExisting: KrosDropdownItemComponent },
  ],
})
export class KrosDropdownItemComponent extends CdkMenuItem {

  constructor(
    private platform: Platform,
    private inputModalityDetector: InputModalityDetector,
  ) {
    super();
  }

  /** Place focus on the element. */
  focus(): void {
    // Fix for safari, where it's not possible to click on scrolled item
    // because it's focused as it's selected by keyboard.
    if (this.inputModalityDetector.mostRecentModality === 'keyboard') {
      return this._elementRef.nativeElement.focus();
    }
    if (!this.platform.SAFARI) {
      this._elementRef.nativeElement.focus();
    }
  }

}

