import { APOSTROPHE, COMMA, ENTER, FF_SEMICOLON, SEMICOLON, SPACE } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnChanges } from '@angular/core';

import { Subscription } from 'rxjs';

import { UnsubscribeComponent, UserIds } from '@kros-sk/ssw-cdk';

import { EmailValidator, ExistingSharingValidator, validateEmails, validateExistingSharings } from '../../../validators';
import { NewSharingComboMode } from '../../models/sharing-combo-mode.enum';
import { ToastService, ToastType } from '../../../toast';
import { TranslateService } from '../../../translate';
import { UserSharingService } from '../../services/user-sharing.service';

@Component({
  template: '',
  selector: 'kros-user-sharing-input-base',
})
export class UserSharingInputBaseComponent extends UnsubscribeComponent implements OnChanges {
  readonly separatorKeysCodes: number[] = [APOSTROPHE, COMMA, ENTER, SEMICOLON, SPACE, FF_SEMICOLON];

  isInProgress = false;
  validators = [];
  inputItems = [];
  inputText: string;
  message: string;
  errorMessages = {
    invalidEmails: this.translateService.translate('SHARING.INVALID_EMAIL'),
    existingEmail: this.translateService.translate('SHARING.EXISTING_EMAIL')
  };

  @Input() sharingMode: NewSharingComboMode;
  @Input() existingEmails: string[];

  get wasModified(): boolean {
    return this.wasInputModified;
  }

  private set wasModified(value: boolean) {
    this.wasInputModified = value;
    if (value) {
      this.isInProgress = false;
    }
  }

  protected readonly emailsToProcess: string[] = [];
  protected readonly emailsReady = new EventEmitter<boolean>();
  protected emailFormatValidator = new EmailValidator(this.separatorKeysCodes);
  protected existingSharingsValidator = new ExistingSharingValidator();
  protected emailsReadySubscription: Subscription;
  protected users: UserIds[] = [];
  private wasInputModified = false;

  constructor(
    protected translateService: TranslateService,
    protected userSharingService: UserSharingService,
    protected toastService: ToastService
  ) {
    super();
    this.validators = [validateEmails(this.emailFormatValidator), validateExistingSharings(this.existingSharingsValidator)];
  }

  ngOnChanges(): void {
    this.existingSharingsValidator.existingSharings = this.existingEmails;
  }

  onTextChange(tag: string): void {
    this.inputText = tag;
    if (tag?.length > 0) {
      this.wasModified = true;
    }
    if (this.inputText?.length === 0) {
      this.emailFormatValidator.invalidEmails = [];
    }
  }

  onSubmit(): void {
    this.isInProgress = true;
    this.addEmailAddress();

    if (this.emailFormatValidator.invalidEmails.length === 0 && this.hasProcessedOrProcessingEmails()) {
      this.processSharing();
    }
  }

  onCloseClick(): void {
    this.resetInput();
  }

  protected resetInput(): void {
    this.wasModified = false;
    this.message = '';
    this.inputItems = [];
    this.users = [];
    this.inputText = '';
  }

  protected submitSharing(): void { }

  private hasProcessedOrProcessingEmails(): boolean {
    return this.users.length > 0 || this.emailsToProcess.length > 0;
  }

  private addEmailAddress(): void {
    const emailsCopy = [];

    if (this.inputItems && (this.inputItems.length > 0)) {
      Object.keys(this.inputItems).forEach(item => {
        emailsCopy.push(this.inputItems[item].value);
      });
    }

    if (this.inputText.length > 0) {
      this.emailFormatValidator.validateEmails(this.inputText);

      this.emailFormatValidator.validEmails.forEach(e => {
        if (this.existingSharingsValidator.validateEmail(e)) {
          emailsCopy.push(e);
        }
      });
    }

    if (emailsCopy.length > 0) {
      this.addUserList(emailsCopy);
    }
  }

  private addUserList(emails: string[]): void {
    this.emailsToProcess.push(...emails);

    this.subs.sink = this.userSharingService.getUserIdsToShare(this.users, emails).subscribe(
      userIds => {
        this.users = userIds;
        this.removeEmailToProcess(emails);
      },
      () => {
        if (this.emailsReadySubscription) {
          this.emailsReadySubscription.unsubscribe();
        }
        this.toastService.open(this.translateService.translate('SHARING.UNKNOWN_ERROR'), ToastType.Error);
      }
    );
  }

  private removeEmailToProcess(emails: string[]): void {
    emails.forEach(email =>
      this.emailsToProcess.splice(this.emailsToProcess.indexOf(email))
    );

    if (this.emailsToProcess.length === 0) {
      this.emailsReady.emit(true);
    }
  }

  private processSharing(): void {
    if (this.emailsToProcess.length === 0) {
      this.submitSharing();
    } else {
      this.emailsReadySubscription = this.emailsReady.subscribe(() => {
        this.submitSharing();
      });
    }
  }
}
