<div class="kros-modal-header">
  <h4 class="kros-modal-title">{{'PROJECTS.DETAIL.HEADER_DATA_SETTINGS' | translate}}</h4>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onCancel()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div class="kros-modal-body">
  <form
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
  >
    <kros-project-header-data-panel
      [parentForm]="form"
      [disabled]="form.disabled"
    >
    </kros-project-header-data-panel>
  </form>
</div>

<div class="kros-modal-footer">
  <button
    class="btn btn-primary btn-submit"
    id="ga-header-data-save"
    data-test="header-data-save-button"
    type="submit"
    [disabled]="!form.valid || !form.dirty"
    (click)="onSubmit()"
  >{{'COMMON.ULOZIT' | translate}}</button>

  <button
    class="btn btn-outline-secondary btn-cancel"
    id="ga-header-data-close"
    type="button"
    (click)="onCancel()"
  >{{ 'COMMON.ZRUSIT' | translate }}</button>
</div>
