import { Injectable } from '@angular/core';

import { filter } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';

import { LicenseDispatchersService, LicenseSelectorsService, UnsubscribeComponent } from '@kros-sk/ssw-cdk';

@Injectable()
export class BudgetLicenseGuard extends UnsubscribeComponent {
  private subject = new Subject<boolean>();
  private canShowApp: boolean;

  constructor(
    private licenseDispatchersService: LicenseDispatchersService,
    licenseSelectorsService: LicenseSelectorsService
  ) {
    super();
    this.subs.sink = licenseSelectorsService.budgetLicense$
      .pipe(filter(p => !!p))
      .subscribe(() => {
        this.canShowApp = true;
        this.subject.next(true);
      });
  }

  resolve(): Observable<boolean> {
    if (this.canShowApp) {
      return of(true);
    }
    this.licenseDispatchersService.loadBudgetLicense();
    this.licenseDispatchersService.loadBuildingProgressLicense();
    this.licenseDispatchersService.loadLicenseDetail();

    return this.subject;
  }
}
