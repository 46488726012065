import { Directive, ElementRef, inject, Input } from '@angular/core';

import { Constructor, getScrollableParent } from '../../tools';
import { DeviceDetectorService, DeviceType } from '../../services';
import { KrosFormFieldComponent } from '../kros-form-field/kros-form-field.component';

export interface CanScroll {
  scrollToViewportTop(): void;
}

type CanScrollCtor = Constructor<CanScroll>;

export function Scrollable<TBase extends Constructor<{}>>(base: TBase): CanScrollCtor & TBase {

  @Directive()
  class Scrollable extends base {
    @Input() stopScrollInModal = false;

    private deviceDetector = inject(DeviceDetectorService);
    private inputEl = inject(ElementRef<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>);
    private containerEl = inject(KrosFormFieldComponent, { optional: true })?.elementRef;

    scrollToViewportTop(): void {
      if (!this.stopScrollInModal && (this.deviceDetector.deviceType === DeviceType.Mobile || this.isOverlapped())) {
        this.ensureInView();
      }
    }

    private isOverlapped(): boolean {
      const inputRect = this.inputEl.nativeElement.getBoundingClientRect();
      const margin = inputRect.height * 0.3;
      const elTop = this.getElementFromPoint(inputRect.x, inputRect.top + margin);
      if (!elTop) {
        return false;
      }
      const elBottom = this.getElementFromPoint(inputRect.x, inputRect.bottom - margin);
      if (!elBottom) {
        return false;
      }
      return !elTop.contains(this.inputEl.nativeElement)
        || !elBottom.contains(this.inputEl.nativeElement);
    }

    /**
     * this method hides from elementFromPoint cdk overlay related elements to not have false positive result
     */
    private getElementFromPoint(x, y): Element {
      const origStyles: { [idOrClass: string]: string } = {};
      document.querySelectorAll('[class*="cdk-overlay"]').forEach((el: HTMLElement) => {
        if (el.id) {
          origStyles[el.id] = el.style.pointerEvents;
        } else {
          origStyles[el.className] = el.style.pointerEvents;
        }
        el.style.pointerEvents = 'none';
      });
      const element = document.elementFromPoint(x, y);
      document.querySelectorAll('[class*="cdk-overlay"]').forEach((el: HTMLElement) => {
        if (el.id) {
          el.style.pointerEvents = origStyles[el.id];
        } else {
          el.style.pointerEvents = origStyles[el.className];
        }
      });
      return element;
    }

    private ensureInView(): void {
      const isModalOpened = document.body.classList.contains('modal-opened');
      if (isModalOpened) {
        document.body.classList.remove('modal-opened');
      }
      const scrollableParent = this.containerEl?.nativeElement && getScrollableParent(this.containerEl.nativeElement);
      if (scrollableParent) {
        scrollableParent.element.scrollTop = 0;
        const element = this.containerEl.nativeElement;
        const elementBounding = element.getBoundingClientRect();
        const eTop = elementBounding.top - scrollableParent.element.offsetTop;
        scrollableParent.element.scrollTop = eTop - scrollableParent.unscrollableTop;
      } else {
        this.inputEl.nativeElement.scrollIntoView();
      }
      if (isModalOpened) {
        document.body.classList.add('modal-opened');
      }
    }
  }

  return Scrollable;
}
