import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { PermissionType } from '@kros-sk/ssw-shared/permission';

import * as listActions from './documents-explorer.actions';
import { DocumentListInfoModel } from '../models';
import { DocumentService, DocumentSharingService } from '../services';
import { DocumentsExplorerDispatchersService } from './documents-explorer-dispatchers.service';
import { DocumentsExplorerSelectorsService } from './documents-explorer-selectors.service';

@Injectable()
export class DocumentsExplorerEffects {
  getDocuments$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.loadDocumentsListStart),
      switchMap(payload => {
        return this.documentsService.getRoot(payload.projectId).pipe(
          switchMap(documentsList => {
            this.documentsDispatchersService.loadRootDocumentPermission(payload.projectId);
            return this.getDocumentListWithSharings(documentsList).pipe(
              map(documentListWithSharings =>
                listActions.loadDocumentsListSuccess({ documentsList: documentListWithSharings }))
            );
          }),
          catchError(error => of(listActions.loadDocumentsListError({ error })))
        );
      })
    );
  });

  getRootDocumentPermission$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.loadRootDocumentPermissionStart),
      switchMap(payload => {
        return this.documentsService.getRootDocumentPermission(payload.projectId).pipe(
          map(rootPermission => listActions.loadRootDocumentPermissionSuccess({ permission: rootPermission.permissionType }))
        );
      })
    );
  });

  getFolderDocuments$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.loadFolderListStart),
      switchMap(payload => {
        return this.documentsService.getFolder(payload.projectId, payload.folderId).pipe(
          switchMap(documentsList => {
            return this.getDocumentListWithSharings(documentsList).pipe(
              map(documentListWithSharings => listActions.loadFolderListSuccess({ documentsList: documentListWithSharings }))
            );
          }),
          catchError(error => of(listActions.loadFolderListError({ error })))
        );
      })
    );
  });

  reloadCurrentFolder$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.reloadCurrentFolder),
      concatLatestFrom(() => [this.documentSelectorsService.currentFolder$, this.documentSelectorsService.projectId$]),
      map(([payload, currentFolder, currentProjectId]) => {
        const projectId = payload.projectId ?? currentProjectId;
        if (currentFolder) {
          return listActions.loadFolderListStart({ projectId, folderId: currentFolder.id });
        } else {
          return listActions.loadRoot({ projectId });
        }
      })
    );
  });

  loadRoot$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.loadRoot),
      concatLatestFrom(() => this.documentSelectorsService.projectId$),
      switchMap(([payload, currentProjectId]) => {
        return [
          listActions.clearCurrentFolder(),
          listActions.clearAllMarkedDocuments(),
          listActions.loadDocumentsListStart({ projectId: payload.projectId ?? currentProjectId })
        ];
      })
    );
  });

  loadFolder$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(listActions.loadFolder),
      concatLatestFrom(() => [this.documentSelectorsService.projectId$, this.documentSelectorsService.currentFolder$]),
      switchMap(([payload, currentProjectId, currentFolder]) => {
        if (!currentFolder || currentFolder.id !== payload.folder.id) {
          return [
            listActions.clearAllMarkedDocuments(),
            listActions.setCurrentFolder({ currentFolder: payload.folder }),
            listActions.loadFolderListStart({ projectId: payload.projectId ?? currentProjectId, folderId: payload.folder.id })];
        } else {
          return [
            listActions.clearAllMarkedDocuments(),
            listActions.loadEmptyList()];
        }
      })
    );
  });

  constructor(
    private actions$: Actions,
    private documentsDispatchersService: DocumentsExplorerDispatchersService,
    private documentsService: DocumentService,
    private documentSharingService: DocumentSharingService,
    private documentSelectorsService: DocumentsExplorerSelectorsService
  ) { }

  private getDocumentListWithSharings(documentsList: DocumentListInfoModel[]): Observable<DocumentListInfoModel[]> {
    const documentIds = documentsList.filter(d => d.permissionType !== PermissionType.Shared).map(d => d.id);
    return (documentIds.length > 0 ?
      this.documentSharingService.getDocumentUserSharingList(documentIds) : of([])
    ).pipe(
      map(documentUserSharings => {
        documentsList = documentsList.map(document => {
          const documentUserSharing = documentUserSharings.find(sharing => sharing.documentId === document.id);
          return {
            ...document,
            sharings: !documentUserSharing ? [] : documentUserSharing.sharings
              .filter(sharing => sharing.permissionType !== PermissionType.Owner)
              .map(sharing => ({ email: sharing.email, isRegistered: sharing.isRegisteredUser }))
          };
        });
        return documentsList;
      })
    );
  }
}
