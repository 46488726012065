import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';

import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { CompanySearchValidationOptions } from '@kros-sk/models';
import { Partner } from '@kros-sk/models';

@Component({
  selector: 'app-company-search',
  templateUrl: './company-search.component.html',
  styleUrls: ['./company-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompanySearchComponent),
      multi: true
    }
  ]
})
export class CompanySearchComponent extends UnsubscribeComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() formControlName: string;
  @Input() maxlength = 100;
  @Input() placeholder = ' ';
  @Input() validationOptions: CompanySearchValidationOptions;
  @Input() errors: { [key: string]: string } = {};

  @Output() selectedPartner: EventEmitter<Partner> = new EventEmitter();

  form: UntypedFormGroup;
  companyControl: UntypedFormControl;

  get validators(): ValidatorFn[] | ValidatorFn {
    return this.validationOptions?.validators ? this.validationOptions.validators : Validators.maxLength(this.maxlength);
  }

  get errorsArray(): { id: string, text: string }[] {
    return Object.keys(this.errors).map(key => ({ id: key, text: this.errors[key] }));
  }

  constructor(
    private formBuilder: UntypedFormBuilder
  ) {
    super();
  }

  private onChange: (value: any) => void = (_) => { };

  ngOnInit(): void {
    this.companyControl = new UntypedFormControl('', this.validators);
    this.form = this.formBuilder.group(this.companyControl);
  }

  writeValue(value: any): void {
    if (value) {
      this.companyControl.setValue(value, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.subs.sink = this.companyControl.valueChanges.subscribe((value: Partner) => {
      this.onChange(value.address?.businessName);
      this.selectedPartner.emit(value);
    });
  }

  registerOnTouched(fn: any): void { }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.companyControl.disable();
    } else {
      this.companyControl.enable();
    }
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : { invalidForm: { valid: false } };
  }
}
