import { Component, inject } from '@angular/core';

import { KrosModalRef } from '@kros-sk/components';

import { getTagName } from '../tag-utils';
import { Tag } from '../tag.model';
import { TagType } from '../tags.enum';
import { TranslateService } from '../../translate';

@Component({
  selector: 'kros-tags-dialog',
  templateUrl: './tags-dialog.component.html',
  styleUrls: ['./tags-dialog.component.scss']
})
export class TagsDialogComponent {
  private readonly translate = inject(TranslateService);

  disabledTagIds: number[] = [];
  tags: Tag[] = [];
  activeTags: Tag[] = [];
  originalActiveTagIds: number[] = [];
  activeTagsChanged: boolean;
  deletedTagIds: Set<number> = new Set<number>();

  constructor(
    private modalRef: KrosModalRef
  ) {
    this.disabledTagIds = this.modalRef.data.disabledTagIds;
    this.tags = this.modalRef.data.tags;
    this.activeTags = this.modalRef.data.activeTags;
    this.originalActiveTagIds = this.activeTags.map(t => t.id);
  }

  isTagActive(tag: Tag): boolean {
    return this.activeTags?.some(t => t.id === tag.id);
  }

  isTagDisabled(tag: Tag): boolean {
    return this.disabledTagIds.some(tagId => tagId === tag.id);
  }

  isLastProjectTag(tag: Tag, nextTag?: Tag): boolean {
    return tag.type === TagType.Project && nextTag && nextTag.type !== TagType.Project;
  }

  toggleTag(tag: Tag): void {
    if (this.isTagActive(tag)) {
      this.activeTags = this.activeTags.filter(t => t.id !== tag.id);
      if (this.originalActiveTagIds.some(id => id === tag.id)) {
        this.deletedTagIds.add(tag.id);
      }
    } else {
      if (tag.type === TagType.Project) {
        this.activeTags = this.activeTags.filter(t => t.type !== TagType.Project);
      }
      this.activeTags.push(tag);
      this.deletedTagIds.delete(tag.id);
    }
    this.activeTagsChanged = true;
  }

  getTagName(key: string): string {
    return getTagName(key, this.translate);
  }

  onNewTagClick(): void {
    this.modalRef.submit({ openCreateNewTagModal: true });
  }

  onTagSettingsClick(): void {
    this.modalRef.submit({ openTagSettingsModal: true });
  }

  onSubmitTagsClick(): void {
    this.modalRef.submit({ newActiveTags: this.activeTags, deletedSomeTags: this.deletedTagIds.size > 0 });
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }
}
