import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable()
export class UserSharingHelpersService {
  changesCanceled$ = new Subject<boolean>();
  resetState$ = new Subject<string>();

  cancelChanges(onlyConfirmedSharings: boolean): void {
    this.changesCanceled$.next(onlyConfirmedSharings);
  }

  resetState(userEmail: string): void {
    this.resetState$.next(userEmail);
  }
}
