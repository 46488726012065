<span class="title">{{ label | translate }}:&nbsp;&nbsp;</span>

<kros-tag
  *ngFor="let tag of displayedTags"
  [color]="tag.textColorCode"
  [backgroundColor]="tag.colorCode"
  [canDelete]="true"
  (tagDeleted)="onTagDeleted(tag)"
>{{ getTagName(tag.name) }}</kros-tag>

<div *ngIf="maxTagsLimit > 0 && maxTagsLimit < activeTags.length">
  <span>+&nbsp;{{ activeTags.length - maxTagsLimit }}</span>
</div>

<kros-tags-dropdown
  *ngIf="isAddTagButtonVisible"
  [tags]="tags"
  [hiddenTags]="activeTags"
  (tagSelected)="onTagSelected($event)"
></kros-tags-dropdown>
