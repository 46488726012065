<ng-container *ngIf="currentUser$ | async as user">
  @if (isMobile) {
  <kros-mobile-nav>
    <span ngProjectAs="arrow">
      <button
        class="btn btn-link nav-btn-left"
        type="button"
        (click)="hide()"
      >
        <i class="material-icons">arrow_back</i>
      </button>
    </span>
  </kros-mobile-nav>
  }

  <div class="user-modal-content">
    <div class="kros-user">
      <kros-avatar
        class="avatar-m avatar-text"
        [options]="{text: getNameFromEmail(user.email)}"
      >
      </kros-avatar>

      <div class="user-info">
        <h4 class="user-row ellipsis">
          {{user.name === undefined ? user.email : user.name}}</h4>
        <div class="email ellipsis">
          {{user.email}}
        </div>
      </div>

      <button
        class="btn btn-link close"
        (click)="hide()"
      >
        <i class="material-icons secondary">close</i>
      </button>
    </div>

    @if (license) {
    <div
      class="license-row"
      [class.is-ending]="isLicenseEnding"
    >
      <div class="license-info">{{ license | licenseInfoFormat }}</div>

      @if (showRenewalLicense && !isCzechVersion) {
      <a
        class="eshop-link"
        target="_blank"
        (click)="onClickEshopLink()"
      >
        {{ license | licenseEshopRedirect }}
      </a>
      }
    </div>
    }

    @if (isMobile) {
    <ng-content></ng-content>
    }

    @if (!isSchoolLicense) {
    <div class="kros-account">
      <a
        class="btn btn-primary"
        target="_blank"
        rel="noopener"
        (click)="hide()"
        [href]="accountUrl"
      >
        {{ accountButtonText }}
      </a>
    </div>
    }

    <div class="user-settings">
      <a
        class="btn btn-link settings-button"
        data-test="my-account-button"
        [href]="changePasswordUrl"
      >
        {{ 'USER_PROFILE.CHANGE_PASSWORD' | translate }}
      </a>

      <a
        class="btn btn-link settings-button"
        data-test="user-logout-button"
        (click)="logout()"
      >
        {{ 'USER_PROFILE.LOGOUT' | translate }}
      </a>
    </div>
  </div>
</ng-container>
