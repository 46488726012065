import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DataTableModule, SearchInputModule, TranslateModule } from '@kros-sk/ssw-shared-legacy';

import { BuildingProgressColumnFilterComponent } from './building-progress-column-filter/building-progress-column-filter.component';
import { BuildingProgressFilterComponent } from './building-progress-filter/building-progress-filter.component';
import { PeriodsModule } from '../periods/periods.module';
import { SharedModule } from '../../../shared/shared.module';
import { ViewPickerComponent } from './view-picker/view-picker.component';

@NgModule({
  declarations: [
    BuildingProgressFilterComponent,
    BuildingProgressColumnFilterComponent,
    ViewPickerComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    SharedModule,
    SearchInputModule,
    DataTableModule,
    PeriodsModule
  ],
  exports: [
    BuildingProgressFilterComponent,
    SearchInputModule
  ]
})
export class FilterModule { }
