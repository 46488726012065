import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { TemplateRef } from '@angular/core';

import { Observable } from 'rxjs';

import { CommonOptions } from '../inputs.common.interface';
import { NumericInputOptions } from '../../directives';

export enum InputType {
  TEXT = 'text',
  EMAIL = 'email',
  NUMBER = 'number',
  PASSWORD = 'password',
  URL = 'url'
}

export enum InputMode {
  TEXT = 'text',
  EMAIL = 'email',
  DECIMAL = 'decimal',
  NUMERIC = 'numeric',
  TEL = 'tel',
  SEARCH = 'search',
  URL = 'url'
}

export interface InputOptions extends CommonOptions {
  /**
   * type passed to html input
   */
  type?: InputType;

  /**
   * inputMode passed to html input. This allows a browser to display an appropriate virtual keyboard.
   */
  inputMode?: InputMode;

  /**
   * only numeric input relevant options
   */
  numericInput?: NumericInputOptions;

  /**
   * allowed alphabetical values (not chars) in kros-digits-input and kros-numeric-input
   */
  allowedValues?: (string | null)[];

  /**
   * max length passed to html input
   */
  maxLength?: number;

  /**
   * min length passed to html input
   */
  minLength?: number;

  /**
   * autocomplete options
   */
  autocomplete?: AutocompleteConfig;

  /**
   * transform new value before event emit
   */
  transformValue?: <T>(value: T) => T;

  /**
   * forbidden characters
   * it's important to double escape characters:
   * string [\\x00-\\x1F\\x7F-\\x9F\\\\\\/\\#\\? ] is equal to regex /[\x00-\x1F\x7F-\x9F\\\/\#\? ]/
   * | (or) is used to concat partial strings in case of string array
   */
  forbiddenCharacters?: string | string[];

  /**
   * shows a tooltip when input value is longer than the input
   */
  longTextTooltip?: boolean;

  /**
   * shows and hides info icon inside the input
   */
  hideInfoIcon?: boolean;

  /**
   * set the input readonly
   */
   readonly?: boolean;
}

export interface AutocompleteConfig {
  /**
   * obtain string value shown in html input
   */
  inputFormatter?: (item: any) => string;

  /**
   * search logic
   */
  searchMethod: (text: Observable<string>) => Observable<readonly any[]>;

  /**
   * custom option template
   * DO NOT FORGET to set width to 100% of the input!!! or other width when custom visual is needed
   */
  optionTemplate?: TemplateRef<OptionTemplateContext>;

  /**
   * Template shown under scrollable options list
   */
  optionsListFooterTemplate?: TemplateRef<any>;

  /**
   * open suggestions on focus
   */
  openOnFocus?: boolean;

  /**
   * class name applied to parent option element
   */
  optionClass?: string;

  /**
   * maximal number of options shown without scrolling dropdown
   */
  maxOptionsShown?: number;

  /**
   * minimal number of options shown
   * this is used to determine when dropdown should be shown above input
   */
  minOptionsShown?: number;

  /**
   * height of option element
   */
  optionHeight?: number;

  /**
   * positions to be used for flexibleConnectedOverlay
   */
  positions?: ConnectionPositionPair[];

  /**
   * obtain string value from object
   */
  valueAttribute?: string;

  /**
   * whether to show spinner during autocomplete search
   */
  showSearchIndicator?: boolean;

  /**
   * Search progress indicator position
   * right is default for undefined value
   */
  searchIndicatorPosition?: 'left' | 'right';
  /**
   * If Given, search indicator will not be shown
   * until text is at least 'searchIndicatorShownFrom' long
   */
  searchIndicatorShownFrom?: number;
}

export interface OptionTemplateContext {
    /**
     * option value
     */
    result: any;
    index?: number;
}
