<div
  class="settings-module"
  *ngIf="!budgetSettingsIsLoading || !isOpenedProject else loading"
>
  <div class="header-line">
    <div class="title">
      <h4>{{ 'SETTINGS.BUDGET' | translate }}</h4>

      <i
        class="material-icons tooltip-icon"
        [krosTooltip]="'SETTINGS.BUDGET_INFO' | translate"
      >info</i>
    </div>

    <button
      class="btn btn-outline-primary"
      type="button"
      [routerLink]="routerLink"
      data-test="close-settings-button"
    >
      {{ 'SETTINGS.CLOSE' | translate }}
      <i class="material-icons">clear</i>
    </button>
  </div>

  <ng-container *ngIf="isOpenedProject && isExist && hasPermission; else noProject">
    <div
      [formGroup]="settingsForm"
      class="scrollable-content"
    >
      <div class="card">
        <h4>{{ 'COMMON.CURRENCY' | translate }}</h4>

        <span
          class="value"
          data-test="currency-code"
        >{{ settingsForm?.value.currencyCode }}</span>
      </div>

      <div class="card">
        <h4>{{ 'SETTINGS.ROUND_FORMAT.TITLE' | translate }}</h4>

        <div class="info-item">
          <span class="title">{{ 'SETTINGS.ROUND_FORMAT.AMOUNT' | translate }}:</span>

          <div class="value">
            <select
              class="form-control text-right vat-value round-input"
              formControlName="amountDecimalPlacesCount"
              [value]='1'
              data-test="edit-amount-round-format-select-action"
            >
              <option
                *ngFor="let range of round_range"
                [value]="range"
              >{{range}}</option>
            </select>
          </div>
        </div>

        <div class="info-item">
          <span class="title">{{ 'SETTINGS.ROUND_FORMAT.UNIT_PRICE' | translate }}:</span>

          <div class="value">
            <select
              class="form-control text-right vat-value round-input"
              formControlName="unitPriceDecimalPlacesCount"
              data-test="edit-unit-price-round-format-select-action"
            >
              <option
                *ngFor="let range of round_range"
                [value]="range"
              >{{range}}</option>
            </select>
          </div>
        </div>

        <div class="info-item">
          <span class="title">{{ 'SETTINGS.ROUND_FORMAT.TOTAL_PRICE' | translate }}:</span>

          <div class="value">
            <select
              class="form-control text-right vat-value round-input"
              formControlName="totalPriceDecimalPlacesCount"
              data-test="edit-total-price-format-select-action"
            >
              <option
                *ngFor="let range of round_range"
                [value]="range"
              >{{range}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="card">
        <h4>{{ 'SETTINGS.VAT.VAT' | translate }}</h4>

        <div class="info-item">
          <span class="title">{{ 'SETTINGS.VAT.TYPE.BASIC' | translate }}</span>

          <div class="value">
            <kros-form-field class="vat-value">
              <input
                #basicVatRate
                krosControl
                krosNumbersOnly
                (input)="handleInput($event)"
                [digitsGrouping]="true"
                [minFractionDigits]="1"
                [maxFractionDigits]="1"
                [minValue]="0"
                [maxValue]="100"
                [formControl]="settingsForm.controls['basicVatRate']"
                type="text"
                class="form-control text-right vat-input"
                data-test="vat-rate-basic"
              >
            </kros-form-field>

            <span>&nbsp;%</span>
          </div>
        </div>

        <div class="info-item">
          <span class="title">{{ 'SETTINGS.VAT.TYPE.REDUCED' | translate }}</span>

          <div class="value">
            <kros-form-field class="vat-value">
              <input
                #reducedVatRate
                krosControl
                krosNumbersOnly
                (input)="handleInput($event)"
                [digitsGrouping]="true"
                [minFractionDigits]="1"
                [maxFractionDigits]="1"
                [minValue]="0"
                [maxValue]="100"
                [formControl]="settingsForm.controls['reducedVatRate']"
                type="text"
                class="form-control text-right vat-input"
                data-test="vat-rate-reduced"
              >
            </kros-form-field>

            <span>&nbsp;%</span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="detail-footer"
      *ngIf="canSaveSettings"
    >
      <div class="top-gradient"></div>

      <div
        class="form-confirmation"
        *ngIf="isEditedBudgetRate || isEditedRoundFormat"
      >
        <button
          class="btn btn-primary btn-submit"
          type="button"
          (click)="onSave()"
          data-test="save-settings-button"
        >
          <div class="save-btn">
            <div class="save-btn-portal"></div>

            <div class="save-text">{{ 'COMMON.ULOZIT' | translate }}</div>
          </div>
        </button>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #noProject>
  <p>{{!isOpenedProject
    ? ('SETTINGS.BUDGET_NO_PROJECT' | translate)
    : !hasPermission ? ('SETTINGS.BUDGET_NO_PERMISSION' | translate) : ('SETTINGS.BUDGET_EMPTY' | translate)}}
  </p>
</ng-template>

<ng-template #loading>
  <kros-timeline [type]="timelineType.ThreeLine"></kros-timeline>
</ng-template>
