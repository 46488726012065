/* eslint-disable max-lines */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  forwardRef,
  Host,
  Inject,
  InjectionToken,
  Input,
  NgZone,
  OnDestroy,
  Optional,
  QueryList,
  ViewContainerRef,
} from '@angular/core';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ConnectionPositionPair,
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayRef,
  ScrollStrategy,
  ViewportRuler,
} from '@angular/cdk/overlay';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { DOWN_ARROW, ENTER, ESCAPE, hasModifierKey, TAB, UP_ARROW } from '@angular/cdk/keycodes';
import { TemplatePortal } from '@angular/cdk/portal';

import {
  defer,
  filter,
  fromEvent,
  merge,
  Observable,
  race,
  skipUntil,
  Subject,
  Subscription, switchMap,
  takeUntil,
  timer,
} from 'rxjs';
import { delay, take, tap } from 'rxjs/operators';

import {
  getOptionScrollPosition,
  KrosAutocompleteOptionComponent,
} from '../autocomplete-option/kros-autocomplete-option.component';
import {
  KROS_AUTOCOMPLETE_DEFAULT_OPTIONS,
  KrosAutocompleteComponent,
  KrosAutocompleteDefaultOptions
} from '../autocomplete/kros-autocomplete.component';
import { KROS_FORM_FIELD, KrosFormFieldComponent } from '../../../kros-inputs';

/** Injection token that determines the scroll handling while the autocomplete panel is open. */
export const KROS_AUTOCOMPLETE_SCROLL_STRATEGY = new InjectionToken<() => ScrollStrategy>(
  'kros-autocomplete-scroll-strategy',
);

/** @docs-private */
export function KROS_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY(overlay: Overlay): () => ScrollStrategy {
  return () => overlay.scrollStrategies.reposition();
}

/** @docs-private */
export const KROS_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER = {
  provide: KROS_AUTOCOMPLETE_SCROLL_STRATEGY,
  deps: [Overlay],
  useFactory: KROS_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY,
};

@Directive({
  selector: 'input[krosAutocomplete],textarea[krosAutocomplete]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '(keydown)': 'handleKeydown($event)',
    '(blur)': '_onTouched()',
    '(click)': 'handleClick()',
    '(focus)': 'handleFocus()',
    '(input)': 'handleInput($event.target.value)',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => KrosAutocompleteDirective),
      multi: true,
    },
  ],
})
export class KrosAutocompleteDirective implements AfterViewInit, OnDestroy, ControlValueAccessor {

  @Input('krosAutocomplete') autocomplete: KrosAutocompleteComponent;

  /**
   * Whether the autocomplete is disabled. When disabled, the element will
   * act as a regular input and the user won't be able to open the panel.
   */
  @Input('krosAutocompleteDisabled')
  get autocompleteDisabled(): boolean {
    return this.autocompleteDisabled_;
  }

  set autocompleteDisabled(value: BooleanInput) {
    this.autocompleteDisabled_ = coerceBooleanProperty(value);
  }

  get origin(): HTMLInputElement {
    return this.host.nativeElement;
  }

  /** Whether or not the autocomplete panel is open. */
  get panelOpen(): boolean {
    return this.overlayAttached && this.autocomplete.showPanel;
  }

  private overlayAttached = false;

  private overlayRef: OverlayRef | null;
  private portal: TemplatePortal;
  private destroy$ = new Subject<void>();
  private autocompleteDisabled_ = false;
  private scrollStrategy: () => ScrollStrategy;
  private readonly defaultVisibleOptionsCount = 3;
  private readonly itemHeight = 40;
  /**
   * Whether the autocomplete can open the next time it is focused. Used to prevent a focused,
   * closed autocomplete from being reopened if the user switches to another browser tab and then
   * comes back.
   */
  private _canOpenOnNextFocus = true;

  /** The subscription for closing actions (some are bound to document). */
  private _closingActionsSubscription: Subscription;

  /** Strategy that is used to position the panel. */
  private _positionStrategy: FlexibleConnectedPositionStrategy;

  /** Stream of keyboard events that can close the panel. */
  private readonly _closeKeyEventStream = new Subject<void>();
  private viewportSubscription = Subscription.EMPTY;

  readonly optionsClick: Observable<any> = defer(() => {
    const options = this.autocomplete ? this.autocomplete.options : null;

    if (options) {
      return options.changes.pipe(
        switchMap((options: QueryList<KrosAutocompleteOptionComponent>) => {
          const clicks$ = options.map(option => option.click$);
          return merge(...clicks$);
        }),
      );
    }

    return this.zone.onStable.pipe(
      take(1),
      switchMap(() => this.optionsClick),
    );
  }) as Observable<any>;

  /**
   * Event handler for when the window is blurred. Needs to be an
   * arrow function in order to preserve the context.
   */
  private _windowBlurHandler = (): void => {
    // If the user blurred the window while the autocomplete is focused, it means that it'll be
    // refocused when they come back. In this case we want to skip the first focus event, if the
    // pane was closed, in order to avoid reopening it unintentionally.
    this._canOpenOnNextFocus =
      this._document.activeElement !== this.host.nativeElement || this.panelOpen;
  };

  onChange: (value: any) => void = () => {};
  _onTouched = (): void => {};

  constructor(
    private host: ElementRef<HTMLInputElement>,
    private vcr: ViewContainerRef,
    private overlay: Overlay,
    private zone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    private viewportRuler: ViewportRuler,
    @Inject(KROS_AUTOCOMPLETE_SCROLL_STRATEGY) scrollStrategy: any,
    @Optional() @Inject(DOCUMENT) private _document: any,
    @Optional() @Inject(KROS_FORM_FIELD) @Host() private _formField: KrosFormFieldComponent,
    @Optional() @Inject(KROS_AUTOCOMPLETE_DEFAULT_OPTIONS) private defaults?: KrosAutocompleteDefaultOptions,
  ) {
    this.scrollStrategy = scrollStrategy;
  }

  ngAfterViewInit(): void {
    const window = this._getWindow();

    if (typeof window !== 'undefined') {
      this.zone.runOutsideAngular(() => window.addEventListener('blur', this._windowBlurHandler));
    }
  }

  ngOnDestroy(): void {
    const window = this._getWindow();

    if (typeof window !== 'undefined') {
      window.removeEventListener('blur', this._windowBlurHandler);
    }

    this.viewportSubscription.unsubscribe();
    this.close();
    this.destroy$.next();
    this._closeKeyEventStream.complete();
  }

  writeValue(value: any): void {
    Promise.resolve(null).then(() => this.assignOptionValue(value));
  }

  registerOnChange(fn: (value: any) => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.host.nativeElement.disabled = isDisabled;
  }

  handleClick(): void {
    if (this.canOpen() && !this.panelOpen) {
      this.openDropdown();
    }
  }

  handleFocus(): void {
    if (!this._canOpenOnNextFocus) {
      this._canOpenOnNextFocus = true;
    } else if (this.canOpen() && !this.panelOpen) {
      this.openDropdown();
    }
  }

  handleInput(value: any): void {
    this.onChange(value);
  }

  openDropdown(): void {
    if (this.panelOpen) return;

    let overlayRef = this.overlayRef;

    if (!overlayRef) {
      this.portal = new TemplatePortal(this.autocomplete.rootTemplate, this.vcr);
      overlayRef = this.overlay.create({
        width: this.origin.offsetWidth,
        maxHeight: this.defaults?.optionsVisibleCount ?
          this.itemHeight * this.defaults.optionsVisibleCount : this.itemHeight * this.defaultVisibleOptionsCount,
        backdropClass: '',
        scrollStrategy: this.scrollStrategy(),
        positionStrategy: this.getOverlayPosition(),
        panelClass: this.defaults?.overlayPanelClass,
        minWidth: this.defaults?.minWidth
      });
      this.overlayRef = overlayRef;

      // we want to also do proper close when user closes overlay by scrolling away. in that case panel is detached,
      // but overlayRef is not destroyed. so we need to subscribe to detachments and close overlay when it happens.
      race(
        this.overlayRef.detachments().pipe(take(1)),
        this.overlayClickOutside(this.overlayRef, this.origin)
      ).subscribe(() => {
        this.close();
      });
      this.viewportSubscription = this.viewportRuler.change().subscribe(() => {
        if (this.panelOpen && overlayRef) {
          overlayRef.updateSize({width: this.origin.offsetWidth});
        }
      });
    } else {
      this._positionStrategy.setOrigin(this.origin);
      overlayRef.updateSize({ width: this.origin.offsetWidth });
    }

    if (this.overlayRef && !this.overlayRef.hasAttached()) {
      this.overlayRef.attach(this.portal);
      this._closingActionsSubscription = this.subscribeToClosingActions();
    }

    this.autocomplete.setVisibility();
    this.autocomplete.isOpen_ = this.overlayAttached = true;
    this.resetActiveItem();

    this.optionsClick
      .pipe(takeUntil(this.overlayRef.detachments()))
      .subscribe((value: any) => {
        this.setValueAndClose(value);
      });
  }

  private subscribeToClosingActions(): Subscription {
    const firstStable = this.zone.onStable.pipe(take(1));
    const optionChanges = this.autocomplete.options.changes.pipe(
      tap(() => this._positionStrategy.reapplyLastPosition()),
      // Defer emitting to the stream until the next tick, because changing
      // bindings in here will cause "changed after checked" errors.
      delay(0),
    );

    // When the zone is stable initially, and when the option list changes...
    return (
      merge(firstStable, optionChanges)
        .pipe(
          // create a new stream of panelClosingActions, replacing any previous streams
          // that were created, and flatten it so our stream only emits closing events...
          tap(() => {
            // The `NgZone.onStable` always emits outside of the Angular zone, thus we have to re-enter
            // the Angular zone. This will lead to change detection being called outside of the Angular
            // zone and the `autocomplete.opened` will also emit outside of the Angular.
            this.zone.run(() => {
              this.resetActiveItem();
              this.autocomplete.setVisibility();
              this.changeDetectorRef.detectChanges();

              if (this.panelOpen) {
                this.overlayRef.updatePosition();
              }
            });
          }),
        )
        .subscribe()
    );
  }

  private setValueAndClose(value: any): void {
    this.assignOptionValue(value);
    this.onChange(value);
    this.autocomplete.emitSelectEvent(value);
    this.host.nativeElement.focus();
    this.close();
  }

  private assignOptionValue(value: any): void {
    const toDisplay =
      this.autocomplete && this.autocomplete.displayWith
        ? this.autocomplete.displayWith(value)
        : value;
    this.updateNativeInputValue(toDisplay != null ? toDisplay : '');
  }

  private updateNativeInputValue(value: string): void {
    // If it's used within a `MatFormField`, we should set it through the property so it can go
    // through change detection.
    if (this._formField) {
      this._formField.control.value = value;
    } else {
      this.host.nativeElement.value = value;
    }
  }

  /** The currently active option, coerced to MatOption type. */
  get activeOption(): KrosAutocompleteOptionComponent | null {
    if (this.autocomplete && this.autocomplete.keyManager) {
      return this.autocomplete.keyManager.activeItem;
    }

    return null;
  }

  /** Handle a keyboard event from the menu, delegating to the appropriate action. */
  handleKeydown(event: KeyboardEvent): void {
    const keyCode = event.keyCode;
    const hasModifier = hasModifierKey(event);
    // Close when pressing ESCAPE or ALT + UP_ARROW, based on the a11y guidelines.
    // See: https://www.w3.org/TR/wai-aria-practices-1.1/#textbox-keyboard-interaction
    if (
      (keyCode === ESCAPE && !hasModifierKey(event)) ||
      (keyCode === UP_ARROW && hasModifierKey(event, 'altKey'))
    ) {
      this._closeKeyEventStream.next();
      event.preventDefault();
      this.resetActiveItem();
      this.close();
    }

    if (this.activeOption && keyCode === ENTER && this.panelOpen && !hasModifier) {
      this.activeOption.selectViaInteraction();
      this.resetActiveItem();
      this.close();
      event.preventDefault();
    } else if (this.autocomplete) {
      const prevActiveItem = this.autocomplete.keyManager.activeItem;
      const isArrowKey = keyCode === UP_ARROW || keyCode === DOWN_ARROW;

      if (keyCode === TAB && this.panelOpen) {
        this.resetActiveItem();
        this.close();
      }

      if (isArrowKey && this.canOpen() && !this.panelOpen) {
        this.openDropdown();
        this.resetActiveItem();
      }

      if (isArrowKey || this.autocomplete.keyManager.activeItem !== prevActiveItem) {
        this.autocomplete.keyManager.onKeydown(event);
        this.scrollToOption(this.autocomplete.keyManager.activeItemIndex || 0);
      }
    }
  }

  /** Use defaultView of injected document if available or fallback to global window reference */
  private _getWindow(): Window {
    return this._document?.defaultView || window;
  }

  private close(): void {
    if (this.overlayRef) {
      this.autocomplete.isOpen = this.overlayAttached = false;
      if (this.overlayRef) {
        if (this.overlayRef.hasAttached()) {
          this.overlayRef.detach();
          this._closingActionsSubscription.unsubscribe();
        }
        this.overlayRef?.dispose();
        this.overlayRef = null;
      }
    }
  }

  private getOverlayPosition(): FlexibleConnectedPositionStrategy {
    const positions = [
      new ConnectionPositionPair(
        { originX: 'start', originY: 'bottom' },
        { overlayX: 'start', overlayY: 'top' },
      ),
      new ConnectionPositionPair(
        { originX: 'start', originY: 'top' },
        { overlayX: 'start', overlayY: 'bottom' },
      ),
    ];

    const strategy = this.overlay
      .position()
      .flexibleConnectedTo(this.origin)
      .withPositions(positions)
      .withFlexibleDimensions(false)
      .withPush(false);
    this._positionStrategy = strategy;
    return strategy;
  }

  /**
   * Listening for backdrop click, to prevent immidiate
   * closing when autocomplete is focused programatically, it's
   * necessary to wait until next event loop with skipuntil(timer(0))
   */
  private overlayClickOutside(overlayRef: OverlayRef, origin: HTMLElement): Observable<unknown> {
    return merge(
      fromEvent<MouseEvent>(window, 'mousedown')
        .pipe(
          skipUntil(timer(0)),
          takeUntil(overlayRef.detachments()),
          filter(event => {
            const clickTarget = event.target as HTMLElement;
            const notOrigin = clickTarget !== origin;
            const notOverlay = !!overlayRef && (!overlayRef.overlayElement.contains(clickTarget));
            return notOrigin && notOverlay;
          }),
        ),
      fromEvent(this.host.nativeElement, 'focusout')
        .pipe(
          skipUntil(timer(0)),
          takeUntil(overlayRef.detachments()),
          filter(_ => !document.hasFocus()),
          filter(_ => this.panelOpen))
    );
  }

  /**
   * Resets the active item to -1 so arrow events will activate the
   * correct options, or to 0 if the consumer opted into it.
   */
  private resetActiveItem(): void {
    const autocomplete = this.autocomplete;
    if (autocomplete.autoActiveFirstOption) {
      // Note that we go through `setFirstItemActive`, rather than `setActiveItem(0)`, because
      // the former will find the next enabled option, if the first one is disabled.
      // TODO: (BG) I don't know why options are not yet available in the current tick
      // so setTimeout is needed to select first option
      setTimeout(() => autocomplete.keyManager.setFirstItemActive(), 0);
    } else {
      autocomplete.keyManager.setActiveItem(-1);
    }
  }

  /** Determines whether the panel can be opened. */
  private canOpen(): boolean {
    const element = this.host.nativeElement;
    return !element.readOnly && !element.disabled && !this.autocompleteDisabled;
  }

  /** Scrolls to a particular option in the list. */
  private scrollToOption(index: number): void {
    // Given that we are not actually focusing active options, we must manually adjust scroll
    // to reveal options below the fold. First, we find the offset of the option from the top
    // of the panel. If that offset is below the fold, the new scrollTop will be the offset -
    // the panel height + the option height, so the active option will be just visible at the
    // bottom of the panel. If that offset is above the top of the visible panel, the new scrollTop
    // will become the offset. If that offset is visible within the panel already, the scrollTop is
    // not adjusted.
    const autocomplete = this.autocomplete;

    if (index === 0) {
      // If we've got one group label before the option and we're at the top option,
      // scroll the list to the top. This is better UX than scrolling the list to the
      // top of the option, because it allows the user to read the top group's label.
      autocomplete.setScrollTop(0);
    } else if (autocomplete.panel) {
      const option = autocomplete.options.toArray()[index];

      if (option) {
        const element = option.element;
        const newScrollPosition = getOptionScrollPosition(
          element.offsetTop,
          element.offsetHeight,
          autocomplete.getScrollTop(),
          autocomplete.panel.nativeElement.offsetHeight,
        );

        autocomplete.setScrollTop(newScrollPosition);
      }
    }
  }
}
