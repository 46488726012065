import { Injectable } from '@angular/core';

import { SubSink } from 'subsink';

import { DocumentListInfoModel } from '../models';
import { DocumentsExplorerDispatchersService, DocumentsExplorerSelectorsService } from '../store';
import { DocumentType } from '../enums';

@Injectable()
export class SelectionService {
  lookupDocumentId: string;

  private markedDocuments: string[] = [];
  private data: DocumentListInfoModel[];
  private subs = new SubSink();

  get isSelectedSingleDocument(): boolean {
    return this.selectedItemsCount === 1;
  }

  get getSelectedDocumentId(): string {
    const document = this.selectedItemsCount > 0 ? this.getSelectedDocument() : undefined;
    return document ? document.id : '';
  }

  get isSelectedFile(): boolean {
    return this.isSelectedDocumentType(DocumentType.File);
  }

  get isSelectedFolder(): boolean {
    return this.isSelectedDocumentType(DocumentType.Folder);
  }

  private get selectedItemsCount(): number {
    return this.markedDocuments.length;
  }

  constructor(
    private dispatchers: DocumentsExplorerDispatchersService,
    private selectors: DocumentsExplorerSelectorsService
  ) { }

  init(): void {
    this.subs.sink = this.selectors.selectMarkedDocuments$
      .subscribe(docs => this.markedDocuments = docs);
  }

  setData(data: DocumentListInfoModel[]): void {
    this.data = data;
  }

  destroy(): void {
    this.subs.unsubscribe();
  }

  setFocusedDocument(): void {
    if (this.lookupDocumentId && this.data.length > 0) {
      this.dispatchers.clearAllDocuments();
      this.dispatchers.markDocument(this.lookupDocumentId);
      const element = document.getElementById(this.lookupDocumentId);

      if (element) {
        element.scrollIntoView();
      }

      this.lookupDocumentId = undefined;
    }
  }

  getSelectedDocuments(): DocumentListInfoModel[] {
    return this.data.filter(doc => this.isRowMarked(doc.id));
  }

  getSelectedDocument(): DocumentListInfoModel {
    return this.data.find(doc => this.isRowMarked(doc.id));
  }

  selectDocument(document: DocumentListInfoModel, event: any): void {
    if (event.target && event.target.type !== 'checkbox') {
      if (event.ctrlKey || event.metaKey) {
        this.addDocumentToSelected(document);
      } else if (event.shiftKey && this.selectedItemsCount > 0) {
        const markedDocuments = this.getShiftMarkedDocumentIds(document);
        this.dispatchers.markDocuments(markedDocuments);
      } else {
        this.dispatchers.clearAllDocuments();
        this.dispatchers.markDocument(document.id);
      }
    }
  }

  private getShiftMarkedDocumentIds(document: DocumentListInfoModel): string[] {
    const markedDocuments: string[] = [];
    const selectedDocIndex = this.data.indexOf(document);
    const firstSelectedIndex = this.data.findIndex(x => this.isRowMarked(x.id));
    const reversedIndex = this.data.slice().reverse().findIndex(x => this.isRowMarked(x.id));
    const lastSelectedIndex = reversedIndex >= 0 ? (this.data.length - 1) - reversedIndex : reversedIndex;

    if (selectedDocIndex < lastSelectedIndex) {
      for (let index = selectedDocIndex; index <= lastSelectedIndex; index++) {
        markedDocuments.push(this.data[index].id);
      }
    } else {
      for (let index = firstSelectedIndex; index <= selectedDocIndex; index++) {
        markedDocuments.push(this.data[index].id);
      }
    }

    return markedDocuments;
  }

  addDocumentToSelected(document: DocumentListInfoModel): void {
    this.dispatchers.markDocument(document.id);
  }

  areMarkedAll(): boolean {
    return this.markedDocuments.length === this.data.length;
  }

  markDismarkAllDocuments(): void {
    const ids = [];
    if (!this.areMarkedAll()) {
      this.data.map(({ id }) => ids.push(id));
      this.dispatchers.markDocuments(ids);
    } else {
      this.dispatchers.clearAllDocuments();
    }
  }

  markDocument(document: DocumentListInfoModel, event: any): void {
    if (event.target.checked) {
      this.dispatchers.markDocument(document.id);
    } else {
      this.dispatchers.clearDocuments([document.id]);
    }
  }

  isRowMarked(documentId: string): boolean {
    return this.markedDocuments.some(docId => docId === documentId);
  }

  private isSelectedDocumentType(type: DocumentType): boolean {
    return this.data.some(doc => this.isRowMarked(doc.id) && doc.type === type);
  }
}
