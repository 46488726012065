<div
  *ngIf="mainControl && formControl"
  [class.error]="showError"
  [class.input-wrapper]="true"
  [ngClass]="currentOptions.className"
  [krosTooltip]="showError || currentOptions.info ? validationMessagesTemplate : null"
  [position]="currentOptions.tooltipPlacement ? currentOptions.tooltipPlacement : 'above'"
  [tooltipShowManually]="true"
  #tooltip="krosTooltip"
  (mouseenter)="mouseEnter()"
  (mouseleave)="mouseLeave()"
  #container
>
  <label [attr.for]="uniqueId" *ngIf="currentOptions.label else fakeLabel">
    {{currentOptions.label}}
  </label>
  <ng-template #fakeLabel>
    <div *ngIf="currentOptions.fakeLabel" class="fake-label"></div>
  </ng-template>
  <input
    [attr.id]="uniqueId"
    [attr.name]="currentOptions.name"
    [attr.autocomplete]="currentOptions.autocompleteOff ? 'chrome-off' : 'on'"
    class="form-control nodigits"
    [ngClass]="currentOptions.inputClass"
    [class.with-icon]="currentOptions.icon"
    [class.icon-right]="currentOptions.iconPosition === 'right'"
    [class.with-validation]="showError || currentOptions.info"
    type="{{currentOptions.type}}"
    [attr.inputmode]="currentOptions.inputMode"
    [formControl]="formControl"
    [attr.maxlength]="currentOptions.maxLength ? currentOptions.maxLength : null"
    [attr.minlength]="currentOptions.minLength ? currentOptions.minLength : null"
    (blur)="doBlur($event)"
    (focus)="doFocus($event)"
    (mousedown)="selectAllText($event)"
    (selectItem)="selectedItem($event)"
    [inputFormater]="currentOptions.autocomplete?.inputFormatter"
    [placeholder]="currentOptions.placeholder ? currentOptions.placeholder : ''"
    [krosAutocomplete]="currentOptions.autocomplete ? autocomplete : null"
    [maxOptionsShown]="currentOptions.autocomplete?.optionsListFooterTemplate ? currentOptions.autocomplete?.maxOptionsShown + 1:  currentOptions.autocomplete?.maxOptionsShown"
    [minOptionsShown]="currentOptions.autocomplete?.minOptionsShown"
    [optionHeight]="currentOptions.autocomplete?.optionHeight"
    [positions]="currentOptions.autocomplete?.positions ? currentOptions.autocomplete.positions : null"
    [attr.data-test]="currentOptions.testName"
    [valueAttribute]="currentOptions.autocomplete?.valueAttribute"
    #input
  />

  <kros-autocomplete
    class="dropdown-menu"
    [control]="formControl"
    [keyPress]="keyPress"
    #autocomplete="krosAutocomplete"
    [optionsListFooterTemplate]="currentOptions.autocomplete?.optionsListFooterTemplate"
  >
    <ng-template krosAutocompleteContent>
      <ng-container *ngFor="let option of autocompleteOptions; index as i;">
        <kros-option [ngClass]="currentOptions.autocomplete.optionClass" [index]="i" [value]="option">
          <ng-container *ngTemplateOutlet="
            (this.currentOptions.autocomplete.optionTemplate
              ? this.currentOptions.autocomplete.optionTemplate
              : defaultAutocompleteTemplate
            ); context: {result: option, index: i}">
          </ng-container>
        </kros-option>
      </ng-container>
      <kros-option id="autocomplete-list-footer"
                   [isFooterOption]="true"
                   *ngIf="currentOptions.autocomplete?.optionsListFooterTemplate">
        <ng-container *ngTemplateOutlet="currentOptions.autocomplete?.optionsListFooterTemplate"></ng-container>
      </kros-option>
    </ng-template>
  </kros-autocomplete>

  <ng-container *ngIf="currentOptions.autocomplete && currentOptions.autocomplete.showSearchIndicator">
    <div class="search-indicator"
         [ngClass]="searchIndicatorPositionClass"
         *ngIf="showSearchIndicator$ | async">
      <div class="spinner-border" role="status"></div>
    </div>
  </ng-container>

  <span
    class="material-icons input-icon"
    *ngIf="currentOptions.icon"
    [class.icon-right]="currentOptions.iconPosition === 'right'"
    [class.lg-input]="currentOptions.inputClass && currentOptions.inputClass.indexOf('form-control-lg') !== -1"
    [class.sm-input]="currentOptions.inputClass && currentOptions.inputClass.indexOf('form-control-sm') !== -1"
  >
    {{currentOptions.icon}}
  </span>

  <span
    *ngIf="showError"
    class="material-icons error-icon"
    [class.lg-input]="currentOptions.inputClass && currentOptions.inputClass.indexOf('form-control-lg') !== -1"
    [class.sm-input]="currentOptions.inputClass && currentOptions.inputClass.indexOf('form-control-sm') !== -1"
  >warning</span>

  <span
    *ngIf="currentOptions.info"
    class="material-icons info-icon"
    [class.lg-input]="currentOptions.inputClass && currentOptions.inputClass.indexOf('form-control-lg') !== -1"
    [class.sm-input]="currentOptions.inputClass && currentOptions.inputClass.indexOf('form-control-sm') !== -1"
  >info</span>
</div>

<ng-template #defaultAutocompleteTemplate let-r="result">
  <div
    [style.width.px]="width + 12"
    class="option"
    data-test="option"
  >
    {{r}}
  </div>
</ng-template>

<ng-template #validationMessagesTemplate>
  <kros-validation-messages
    #validationMessages
    *ngIf="showError || currentOptions.info"
    [formErrors]="mainControl?.errors"
    [info]="currentOptions.info"
    [customMessages]="currentOptions.errors"
  ></kros-validation-messages>
</ng-template>
