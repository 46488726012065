<div class="kros-modal-header">
  <h4 class="kros-modal-title">{{'BUILDING_PROGRESS.EXPORT_SUMMARY' | translate}}</h4>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onCloseClick()"
    data-test="summary-settings-close-button"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div class="kros-modal-body">
  <form [formGroup]="settingsForm">
    <div class="form-container">
      <div class="form-group radio">
        <div>
          <label>
            <input
              type="radio"
              formControlName="isIncludedSectionsSummary"
              [value]="false"
              data-test="is-included-sections-summary-false"
            >

            <span>{{ 'BUILDING_PROGRESS.EXPORT_SUMMARY_OBJECTS_GENITIVE' | translate }}</span>
          </label>
        </div>

        <div>
          <label>
            <input
              type="radio"
              formControlName="isIncludedSectionsSummary"
              [value]="true"
              data-test="is-included-sections-summary-true"
            >

            <span>{{ 'BUILDING_PROGRESS.EXPORT_SUMMARY_OBJECTS_AND_SECTIONS_GENITIVE' | translate }}</span>
          </label>
        </div>
      </div>

      <app-export-colums-selection
        [form]="settingsForm"
        [columns]="columns"
      ></app-export-colums-selection>
    </div>
  </form>
</div>

<div class="kros-modal-footer">
  <button
    class="btn btn-primary btn-submit"
    type="button"
    (click)="onSubmit()"
    data-test="summary-settings-confirm-button"
  >{{'COMMON.APPLY'| translate}}</button>
</div>
