import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { catchError, map, take, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';

import {
  AnalyticsService,
  isOwnersEmail,
  ItemSharing,
  ItemSharingEdit,
  NewSharingComboMode,
  SharingActionType,
  SharingPermissionValues,
  ToastService,
  ToastType,
  UserSharingHelpersService,
  UserSharingService
} from '@kros-sk/ssw-shared-legacy';
import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { ApprovalHelper } from '@kros-sk/ssw-shared/utils/building-progress';
import { ApprovalOrderHelper } from '@kros-sk/ssw-building-progress/approval-order';
import { BuildingProgressPermissionType, PermissionType } from '@kros-sk/ssw-shared/permission';
import { KrosModalRef } from '@kros-sk/components';

import { BuildingProgressActionAccessService } from '../../services/building-progress-action-access.service';
import {
  BuildingProgressDispatchersService,
  BuildingProgressPeriodDispatchersService,
  BuildingProgressSelectorsService
} from '../../../store/building-progress';
import {
  BuildingProgressSharingActionViewModel
} from '../../../building-progress/models/building-progress-sharing/building-progress-sharing-action.view-model';
import { BuildingProgressSharingService } from '../../../building-progress/services/building-progress-sharing.service';
import {
  BuildingProgressSharingViewModel
} from '../../../building-progress/models/building-progress-sharing/building-progress-sharing.view-model';
import { ProjectsSelectorsService } from '../../../store/projects';
import { SubcontractorsDispatchersService, SubcontractorsSelectorsService } from '../../../store/subcontractors';

@Component({
  selector: 'app-building-progress-sharing-dialog',
  templateUrl: './building-progress-sharing-dialog.component.html',
  styleUrls: ['./building-progress-sharing-dialog.component.scss']
})
export class BuildingProgressSharingDialogComponent implements OnInit {
  projectId: number;
  readonly: boolean;
  sharings: ItemSharing[] = [];
  loadedItems: boolean;
  editItems: ItemSharingEdit[];
  otherAlreadySharedEmails: string[] = [];
  unconfirmedSharings: ItemSharing[] = [];
  needRefresh: boolean;
  title = 'BUILDING_PROGRESS.SHARING';
  dataTestPrefix = 'building-progress-sharing';
  sharingMode = NewSharingComboMode.AddUserBuildingProgress;
  defaultPermissions: SharingPermissionValues = {
    permission: PermissionType.Reader,
    additionalPermission: BuildingProgressPermissionType.None,
  };
  canShowApprovalOrder = false;
  checkPermissionsBeforeSubmitFunc = this.checkPermissionsBeforeSubmit.bind(this);
  shareNewPermissionsFunc = this.shareNewPermissions.bind(this);
  submitEditedPermissionsFunc = this.submitEditedPermissions.bind(this);
  getSharingFunc = this.getSharing.bind(this);

  get initialCompactMode(): boolean {
    return this.readonly ? false : this.sharings?.length === 1;
  }

  protected projectsSelectorService = inject(ProjectsSelectorsService);
  protected sharingHelperService = inject(UserSharingHelpersService);
  protected userSharingService = inject(UserSharingService);
  protected toastService = inject(ToastService);
  protected appInsightsService = inject(AppInsightsBaseService);
  protected analyticsService = inject(AnalyticsService);
  protected modalRef = inject(KrosModalRef);
  protected isSomeApprover = false;

  private cancelIsApprovalRequested = false;

  private buildingProgressActionAccessService = inject(BuildingProgressActionAccessService);
  private buildingProgressSharingService = inject(BuildingProgressSharingService);
  private buildingProgressDispatchersService = inject(BuildingProgressDispatchersService);
  private buildingProgressPeriodDispatchersService = inject(BuildingProgressPeriodDispatchersService);
  private buildingProgressSelectorService = inject(BuildingProgressSelectorsService);
  private subcontractorsDispatchersService = inject(SubcontractorsDispatchersService);
  private subcontractorsSelectorService = inject(SubcontractorsSelectorsService);
  private approvalHelper = inject(ApprovalHelper);
  private approvalOrderHelper = inject(ApprovalOrderHelper);
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.projectId = this.modalRef.data.projectId;
    this.projectsSelectorService.projectDetail$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(detail => this.readonly = !isOwnersEmail(detail.owner) || this.buildingProgressActionAccessService.isFreeOwner());
    this.buildingProgressDispatchersService.loadSharingList(this.projectId);
    this.subcontractorsDispatchersService.loadSubcontractorsSharingList(this.projectId);

    this.buildingProgressSelectorService.sharingList$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(sharingList => this.afterSharingsLoad(sharingList));
    this.subcontractorsSelectorService.subcontractorsSharingList$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(subcontractorsSharingList => this.otherAlreadySharedEmails = subcontractorsSharingList.map(sharing => sharing.email));

    this.buildingProgressSelectorService.settings$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(settings => this.canShowApprovalOrder = settings?.isMultiStageApprovalModeEnabled);
  }

  onUnconfirmedSharingChange(unconfirmedSharings: ItemSharing[]): void {
    this.unconfirmedSharings = unconfirmedSharings;
  }

  onSubmitSharing(result: boolean): void {
    if (result && this.cancelIsApprovalRequested) {
      this.buildingProgressPeriodDispatchersService.cancelIsApprovalRequestedPeriods(this.projectId);
      this.cancelIsApprovalRequested = false;
    }

    this.modalRef.submit(result);
  }

  protected onApprovalOrder(): void {
    if (this.buildingProgressActionAccessService.checkForAccessCauseOfReadonlyOrFreeLicense()) {
      this.appInsightsService.trackEvent('PV-approval-order-open-dialog');
      this.approvalOrderHelper.openApprovalOrderDialog(this.projectId, this.readonly);
    }
  }

  private setIsSomeApprover(): void {
    this.isSomeApprover = this.sharings.some(p => this.approvalHelper.isApprover(p.additionalPermissionType));
  }

  private checkIsSomeNewOrEditedApprover(newSharings: ItemSharing[]): boolean {
    return newSharings.some(s => this.approvalHelper.isApprover(s.additionalPermissionType)) ||
      this.editItems.some(s =>
        this.approvalHelper.isApprover(s.permissionChange.oldAdditionalPermission) ||
        this.approvalHelper.isApprover(s.permissionChange.newAdditionalPermission));
  }

  private checkPermissionsBeforeSubmit(newSharings: ItemSharing[]): Observable<boolean> {
    if (this.canShowApprovalOrder && this.checkIsSomeNewOrEditedApprover(newSharings)) {
      return this.approvalHelper.showApprovalCancellationDialog()
        .pipe(tap(result => this.cancelIsApprovalRequested = result));
    }

    return of(true);
  }

  private shareNewPermissions(sharings: ItemSharing[]): Observable<void> {
    const sharedNewPermissions = new Subject<void>();

    if (sharings.length > 0) {
      const viewModel: BuildingProgressSharingViewModel = {
        projectId: this.projectId,
        sharingActions: this.userSharingService.createUserAddViewModels(sharings, this.sharings)
          .map(p => {
            const ret = p as BuildingProgressSharingActionViewModel;
            ret.buildingProgressPermissionType = ret.additionalPermissionType;
            return ret;
          }),
      };

      this.sendAnalyticsNotification(viewModel);

      if (viewModel.sharingActions.length > 0) {
        this.buildingProgressSharingService.share(viewModel)
          .pipe(take(1), takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: () => {
              this.sharings = this.unconfirmedSharings.map(s => this.getSharing(s)).concat(this.sharings);
              this.setIsSomeApprover();
              this.unconfirmedSharings = [];
              this.needRefresh = viewModel.sharingActions.filter(sa => sa.actionType !== SharingActionType.Edit).length > 0;
            },
            error: (err) => this.toastService.open(err.message, ToastType.Error),
            complete: () => sharedNewPermissions.next()
          });
      } else {
        sharedNewPermissions.next();
      }
    }

    return sharedNewPermissions;
  }

  private submitEditedPermissions(): Observable<boolean> {
    this.editItems = this.userSharingService.filterChanges(this.editItems);

    const viewModel: BuildingProgressSharingViewModel = {
      projectId: this.projectId,
      sharingActions: this.userSharingService.createUserEditViewModels(this.editItems)
        .map(p => {
          const ret = p as BuildingProgressSharingActionViewModel;
          ret.buildingProgressPermissionType = ret.additionalPermissionType;
          return ret;
        }),
    };

    this.sendAnalyticsNotification(viewModel);
    return this.buildingProgressSharingService.share(viewModel).pipe(
      map(() => {
        this.userSharingService.deleteLocalShareItems(this.sharings, this.editItems);
        this.sharings = [...this.sharings];
        this.setIsSomeApprover();
        this.editItems = [];
        this.sharingHelperService.cancelChanges(false);
        this.needRefresh = viewModel.sharingActions.length > 0;
        return true;
      }),
      catchError((err) => {
        this.toastService.open(err.message, ToastType.Error);
        return of(false);
      })
    );
  }

  private getSharing(unconfirmedSharing: ItemSharing): any {
    return {
      email: unconfirmedSharing.email,
      isRegisteredUser: unconfirmedSharing.isRegisteredUser,
      permissionType: unconfirmedSharing.permissionType,
      additionalPermissionType: unconfirmedSharing.additionalPermissionType,
      buildingProgressPermissionType: unconfirmedSharing.additionalPermissionType,
      userMessage: unconfirmedSharing.userMessage
    };
  }

  private sendAnalyticsNotification(model: BuildingProgressSharingViewModel): void {
    this.sendAnalyticsNotificationCore(model, SharingActionType.Add, 'Zdielanie, pridať práva');
    this.sendAnalyticsNotificationCore(model, SharingActionType.Edit, 'Zdielanie, upraviť práva');
    this.sendAnalyticsNotificationCore(model, SharingActionType.Delete, 'Zdielanie, zmazať práva');
  }

  private sendAnalyticsNotificationCore(
    model: BuildingProgressSharingViewModel,
    actionType: SharingActionType,
    eventAction: string): void {
    if (model.sharingActions.some(e => e.actionType === actionType)) {
      const count = model.sharingActions.filter(e => e.actionType === actionType).length;

      this.appInsightsService.trackEvent('PV-shared-' + actionType.toString(), { count: count.toString() });
      this.analyticsService.raiseEvent('Priebeh výstavby', eventAction,
        'Počet: ' + count.toString());
    }
  }

  private afterSharingsLoad(sharingList: ItemSharing[]): void {
    this.loadedItems = true;
    this.editItems = [];
    this.sharings = JSON.parse(JSON.stringify(sharingList));
    this.setIsSomeApprover();
  }
}
