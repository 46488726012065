<div class="toolbar-actions">
  <div class="actions left">
    <button
      class="btn toolbar-action gray-toolbar-action no-selection"
      type="button"
      (click)="onBackClick()"
      krosTooltip="{{ 'CHANGE_SHEET.BACK_TO_LIST' | translate }}"
    >
      <i class="material-icons icon-button">chevron_left</i>
      <span class="toolbar-action-text">{{ 'CHANGE_SHEET.BACK_TO_LIST' | translate }}</span>
    </button>

    <button
      *ngIf="!permissionHelper.isReader && !permissionHelper.isApprover"
      class="btn toolbar-action gray-toolbar-action no-selection ga-ba-differences-export"
      type="button"
      (click)="onExport()"
      krosTooltip="{{ 'BUDGET_APPROVAL.EXPORT.EXPORT' | translate }}"
      data-test="budget-approval-differences-export"
    >
      <i class="material-icons icon-button ga-ba-differences-export">save_alt</i>
      <span class="toolbar-action-text">{{ 'BUDGET_APPROVAL.EXPORT.EXPORT' | translate }}</span>
    </button>
  </div>
</div>
