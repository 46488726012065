import { DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

import { APP_CONFIG } from '@kros-sk/app-config';
import { KrosAuthService } from '@kros-sk/auth';

export abstract class ConnectorBaseService {
  protected readonly retryCount = 3;
  protected readonly reconnectingTimeout = 90000;
  protected isConnectionActive = false;

  protected get isConnected$(): Observable<boolean> {
    return this.isConnected.asObservable();
  }

  protected destroyRef = inject(DestroyRef);
  protected authService = inject(KrosAuthService);

  private appConfig = inject(APP_CONFIG);
  private connection: HubConnection;
  private connectionId: string;
  private isConnected = new BehaviorSubject<boolean>(false);

  protected getConnection(): HubConnection {
    return this.connection;
  }

  protected getConnectionId(): string {
    return this.connectionId;
  }

  protected connect(hubName: string, hubConnectionId: string, callback?: Function): void {
    if (!this.connection && hubConnectionId) {
      this.connectionId = hubConnectionId;
      this.connection = new HubConnectionBuilder()
        .withUrl(`${this.appConfig.appUrls.connectorServiceUrl}/${hubName}`, {
          accessTokenFactory: () => this.authService.token,
          transport: HttpTransportType.LongPolling,
          headers: { hub: hubName }
        })
        .configureLogging(signalR.LogLevel.Warning)
        .withAutomaticReconnect()
        .build();

      fromEvent(window, 'beforeunload').pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(_ => this.connection.stop().then(() => this.setIsConnected(false)));

      this.connection.onreconnecting(() => this.setIsConnected(false));
      this.connection.onreconnected(() => this.setIsConnected(true));
      this.connection.onclose(() => this.setIsConnected(false));

      this.registerHandlers();
      this.tryConnect(callback);
    }
  }

  protected abstract registerHandlers(): void;

  protected tryConnect(callback?: Function): void {
    this.connectionAttempt(3)
      .then(() => {
        if (callback) {
          callback();
        }
      })
      .catch(error => alert(error.toString()));
  }

  private connectionAttempt(attemptNumber: number): Promise<void> {
    return this.connection.start()
      .then(() => this.setIsConnected(true))
      .catch(error => {
        if (attemptNumber > 0) {
          return this.connectionAttempt(attemptNumber--);
        }

        throw error;
      });
  }

  private setIsConnected(isConnected: boolean): void {
    this.isConnectionActive = isConnected;
    this.isConnected.next(isConnected);
  }
}
