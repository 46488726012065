import { ArrayDataSource } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';

import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import {
  BudgetNavigationItem,
  getMultiselectSelectedAndIndeterminateItems,
  getNodeLevel,
  IBudgetItem,
  IBudgetModel,
  isAdditionOrChangeSheet,
  isConstructionOrBuildingObject,
  isSection,
  isSummaryItem
} from '../../budget/helpers';
import { BudgetStructureService } from '../budget-structure.service';
import { TimelineType } from '../../timeline/timeline-type.enum';

@Component({
  selector: 'app-budget-structure',
  templateUrl: './budget-structure.component.html',
  styleUrls: ['./budget-structure.component.scss']
})
export class BudgetStructureComponent extends UnsubscribeComponent implements OnInit {
  @Input() selectedItemIds = new Set<number>();
  @Input() indeterminateItemIds = new Set<number>();

  @Input() hasCheckboxSelection = false;
  @Input() isDefaultExpanded = false;
  @Input() title: string;
  @Input() hasTooltips = true;
  @Input() colorizedObjects = true;
  @Input() showTypes: string[];
  @Input() data$: Observable<IBudgetModel>;
  @Input() tableId?: string;

  @Output() nodeSelected: EventEmitter<void> = new EventEmitter<void>();
  @Output() nodesCheckboxSelected: EventEmitter<{ selectedItems: Set<number>, indeterminateItems: Set<number>, allSelected: boolean }> =
    new EventEmitter<{ selectedItems: Set<number>, indeterminateItems: Set<number>, allSelected: boolean }>();

  areDataLoaded = false;
  tableData: IBudgetModel;
  dataSource: ArrayDataSource<BudgetNavigationItem>;
  treeControl = new FlatTreeControl<BudgetNavigationItem>(node => node.data.level, node => node.isExpandable);
  timelineType = TimelineType;

  get hasTitle(): boolean {
    return !!this.title;
  }

  private selectedNode: BudgetNavigationItem;
  private treeData: BudgetNavigationItem[] = [];

  constructor(
    private budgetStructureService: BudgetStructureService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.data$
      .subscribe((data: IBudgetModel) => {
        if (!data) {
          this.tableData = undefined;
          this.areDataLoaded = false;
        }
        if (this.isBudgetDataChanged(data)) {
          this.tableData = { ...data, items: data.items ? this.getFilteredItems(data.items) : [] };
          this.setBuildingStructure();

          if (this.hasCheckboxSelection && this.selectedItemIds.size === 0 && this.tableData.items) {
            this.tableData.items.forEach(item => {
              this.selectedItemIds.add(item.id);
            });
          }

          this.areDataLoaded = true;
        }
      });

    this.budgetStructureService.setFocusToStructureItemRequested$
      .pipe(filter(result => result.tableId === this.tableId))
      .subscribe(result => this.setFocusToNode(result.id));
  }

  isBudgetDataChanged(budgetData: IBudgetModel): boolean {
    return (budgetData && !this.tableData) ||
      (this.tableData && budgetData.projectId !== this.tableData.projectId) ||
      (this.tableData && !budgetData.items && !!this.tableData.items) ||
      (this.tableData && budgetData.items && (this.tableData.items.length !== budgetData.items.length ||
        this.tableData.items[0]?.id !== budgetData.items[0]?.id));
  }

  onSelectAllAditionsClick(): void {
    this.resetSelectionIds();
    this.tableData.items.forEach(item => {
      if (isAdditionOrChangeSheet(item)) {
        this.selectedItemIds.add(item.id);
      }
    });
    this.emitCheckboxSelection();
  }

  onSelectAllExceptAditionsClick(): void {
    this.resetSelectionIds();
    this.tableData.items.forEach((item, index) => {
      if (!isAdditionOrChangeSheet(item)) {
        this.selectedItemIds.add(item.id);
      }
    });
    this.emitCheckboxSelection();
  }

  private setBuildingStructure(): void {
    const data = this.tableData.items.filter((item: IBudgetItem) => isSummaryItem(item));
    this.treeData = [];

    if (data?.length) {
      const minLevel = data[0].level;
      data.forEach(node => {
        this.treeData.push({
          data: { ...node, level: node.level - minLevel },
          isExpandable: this.hasChild(node.id),
          isExpanded: this.isDefaultExpanded
        });
      });
    }

    this.dataSource = new ArrayDataSource(this.treeData);
    this.expandRoot();
  }

  private expandRoot(): void {
    const root = this.treeData[0];
    this.treeControl.expand(root);
    if (root) {
      root.isExpanded = true;
    }
  }

  private hasChild(nodeId: number): boolean {
    return this.tableData.items.filter(i => i.parentId === nodeId && isSummaryItem(i)).length > 0;
  }

  private resetSelectionIds(): void {
    this.selectedItemIds.clear();
    this.indeterminateItemIds.clear();
  }

  private getFilteredItems(items: IBudgetItem[]): IBudgetItem[] {
    if (this.showTypes) {
      return items.filter(item => this.showTypes.some(type => type === item.itemType));
    }
    return items;
  }

  shouldRender(node: any): boolean {
    let parent: BudgetNavigationItem = node;

    do {
      parent = this.getParent(parent);
      if (parent) {
        if (!parent.isExpanded) {
          return false;
        }
      } else {
        return true;
      }
    }
    while (parent);

    return node.isExpanded;
  }

  private getParent(item: BudgetNavigationItem): BudgetNavigationItem {
    if (item?.data?.parentId > 0) {
      return this.treeData.find(i => i.data.id === item.data.parentId);
    }
    return null;
  }

  selectNode(node: BudgetNavigationItem): void {
    if (!this.hasCheckboxSelection) {
      if (!this.isSelected(node)) {
        this.selectedNode = node;
        this.budgetStructureService.setFocusToBudgetItemById(node.data.id, this.tableId);
      }

      this.nodeSelected.emit();
    }
  }

  isSelected(node: BudgetNavigationItem): boolean {
    return node && this.selectedNode && node.data.id === this.selectedNode.data.id;
  }

  isConstructionOrBuildingObject(item: IBudgetItem): boolean {
    return isConstructionOrBuildingObject(item);
  }

  isColoredAsAdditionOrChangeSheet(item: IBudgetItem): boolean {
    return !!item && !isSection(item) && isAdditionOrChangeSheet(item);
  }

  private setFocusToNode(itemId: number): void {
    this.selectedNode = this.treeData.find(item => item.data.id === itemId);
    this.expandAllParentNodes(this.selectedNode);
    this.scrollElementToView(itemId.toString());
  }

  private expandAllParentNodes(node: BudgetNavigationItem): void {
    let parent = this.getParent(node);

    while (parent) {
      parent.isExpanded = true;
      parent = this.getParent(parent);
    }
  }

  private scrollElementToView(elementId: string): void {
    const element = document.getElementById(elementId);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
      }, 0);
    }
  }

  getNodeLevel(node: BudgetNavigationItem): number {
    return getNodeLevel(node.data.level);
  }

  isItemCheckChecked(node: BudgetNavigationItem): boolean {
    return this.selectedItemIds.has(node.data.id);
  }

  isItemCheckIndeterminate(node: BudgetNavigationItem): boolean {
    return this.indeterminateItemIds.has(node.data.id);
  }

  onItemCheckChanged(event: any, item: BudgetNavigationItem): void {
    const selection = getMultiselectSelectedAndIndeterminateItems(
      this.tableData.items,
      this.selectedItemIds,
      this.indeterminateItemIds,
      item.data.id,
      event.checked,
      true
    );
    this.selectedItemIds = selection.selectedItems;
    this.indeterminateItemIds = selection.indeterminateItems;
    this.emitCheckboxSelection();
  }

  private emitCheckboxSelection(): void {
    this.nodesCheckboxSelected.emit({
      selectedItems: this.selectedItemIds,
      indeterminateItems: this.indeterminateItemIds,
      allSelected: this.selectedItemIds.size === this.tableData.items.length
    });
  }
}
