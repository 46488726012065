import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { LoginRedirectComponent } from '../login-redirect.component';

@Component({
  selector: 'kros-login-redirect-ssw',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './login-redirect-ssw.component.html',
  styleUrls: ['./login-redirect-ssw.component.scss'],
})
export class LoginRedirectSswComponent extends LoginRedirectComponent {
}
