import { Component } from '@angular/core';

import { KrosModalRef } from '@kros-sk/components';

import { BuildingProgressSelectorsService } from '../../../store/building-progress';

@Component({
  selector: 'app-range-modal',
  templateUrl: './range-modal.component.html',
  styleUrls: ['./range-modal.component.scss']
})
export class RangeModalComponent {
  selectedItems: Set<number>;
  indeterminateItems: Set<number>;
  allSelected: boolean;

  constructor(
    public selector: BuildingProgressSelectorsService,
    private modalRef: KrosModalRef
  ) {
    this.selectedItems = new Set(this.modalRef.data.selectedItems);
    this.indeterminateItems = new Set(this.modalRef.data.indeterminateItems);
    this.allSelected = this.selectedItems.size === 0;
  }

  onRangeSelected(event: { selectedItems: Set<number>, indeterminateItems: Set<number>, allSelected: boolean }): void {
    this.selectedItems = event.selectedItems;
    this.indeterminateItems = event.indeterminateItems;
    this.allSelected = event.allSelected;
  }

  onSubmit(): void {
    this.modalRef.submit({
      selectedItems: this.allSelected ? new Set() : this.selectedItems,
      indeterminateItems: this.allSelected ? new Set() : this.indeterminateItems
    });
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }
}
