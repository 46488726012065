import { CommonModule } from '@angular/common';
import { EnvironmentProviders, makeEnvironmentProviders, NgModule } from '@angular/core';

import { provideState, StoreModule } from '@ngrx/store';

import { errorsReducer } from './errors-store.reducers';

export const errorsStateSlice = 'errors';

export function provideErrorsStore(): EnvironmentProviders {
  return makeEnvironmentProviders([
    CommonModule,
    provideState({ name: errorsStateSlice, reducer: errorsReducer})
  ]);
}

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(errorsStateSlice, errorsReducer)
  ]
})
export class ErrorsStoreModule { }
