import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import {
  DirectivesModule,
  KrosFormFieldModule,
  KrosFormGuardModule,
  KrosInputsModule,
  KrosMobileNavigationModule,
  RequiredLicenseModule,
  ToastModule
} from '@kros-sk/components';
import { TooltipDirective } from '@kros-sk/ui/tooltip';
import { TranslateModule } from '@kros-sk/ssw-shared-legacy';

import {
  OpenApiListItemSkeletonComponent,
  OpenApiSettingsComponent,
  OpenApiSettingsEditComponent,
  OpenApiSettingsListHeaderComponent,
  OpenApiSettingsListItemComponent,
  OpenApiSettingsTokenModalComponent
} from './components';

@NgModule({
  declarations: [
    OpenApiSettingsEditComponent,
    OpenApiSettingsListHeaderComponent,
    OpenApiSettingsListItemComponent,
    OpenApiSettingsTokenModalComponent,
    OpenApiSettingsListItemComponent,
    OpenApiListItemSkeletonComponent,
    OpenApiSettingsComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    KrosMobileNavigationModule,
    RouterModule,
    ReactiveFormsModule,
    KrosInputsModule,
    KrosFormGuardModule,
    DirectivesModule,
    ToastModule,
    RequiredLicenseModule,
    KrosFormFieldModule,
    TooltipDirective
  ],
  exports: [
    OpenApiSettingsEditComponent,
    OpenApiSettingsListHeaderComponent,
    OpenApiSettingsListItemComponent,
    OpenApiSettingsTokenModalComponent,
    OpenApiSettingsListItemComponent,
    OpenApiListItemSkeletonComponent,
    OpenApiSettingsComponent
  ]
})
export class OpenApiSettingsModule { }
