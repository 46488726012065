import { AbstractControl, FormControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter, Observable, Subject, Subscription, take } from 'rxjs';

import { AuthSelectorsService } from '@kros-sk/auth';

import { ArrowMovement } from '../../enums/arrow-movement.enum';
import { BoqColumnConfig } from './boq-table-config.model';
import { BoqEditService } from './boq-edit.service';
import { BoqItem } from '../../models';
import {
  boqTagName,
  expressionFieldName,
  getLastIndexOfDividerChar,
  measurementTags,
  unwrapTags,
  wrapTags
} from './boq.helpers';
import { DividerPosition, DividerType } from '../models';
import { isDownKey, isEnterKey, isEscapeKey, isTabKey, isUpKey } from '../../data-table/helpers';
import { selectionColumnName } from './boq-edit.component';

@Injectable()
export class BoqEditHelper {
  private destroyRef = inject(DestroyRef);
  private boqEditService = inject(BoqEditService);
  private formBuilder = inject(UntypedFormBuilder);
  private platform = inject(Platform);
  private authSelectorService = inject(AuthSelectorsService, { optional: true });

  areMatchedBoqs = false;
  selectAllFormControl: FormControl;
  indeterminate: boolean;

  isAffected$ = new Subject<boolean>();
  itemTypes = [
    { value: 'K', labelKey: 'BUDGET.ITEM_TYPE.WORK' },
    { value: 'M', labelKey: 'BUDGET.ITEM_TYPE.MATERIAL' },
    { value: 'D', labelKey: 'BUDGET.ITEM_TYPE.SECTION' },
  ];

  get isAffected(): boolean {
    return !!this.deletedIds.size || !!this.editedIds.size;
  }

  get boqInserted$(): Observable<void> {
    return this.boqInserted.asObservable();
  }

  get markedIndexes(): number[] {
    return this.forms.controls.map((item, index) => item.get(selectionColumnName).value ? index : -1).filter(p => p > -1);
  }

  get numberOfRows(): number {
    return this.forms.controls.length;
  }

  private deletedIds = new Set<number>();
  private editedIds = new Set<number>();
  private isQuestionOpened = false;
  private lastCaretPosition = -1;
  private lastDividerPosition: DividerPosition;
  private forms = new UntypedFormArray([]);
  private substituteMeasurements: Function;
  private reverseSubstituteMeasurements: Function;
  private substituteBoqs: Function;
  private reverseSubstituteBoqs: Function;
  private boqInserted = new Subject<void>();
  private formChangedSubscription: Subscription;
  private budgetGrouping: boolean;

  constructor() {
    this.authSelectorService?.featureFlags$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(ff => this.budgetGrouping = ff?.budgetGrouping);
  }

  init(
    substituteMeasurements: Function,
    reverseSubstituteMeasurements: Function,
    substituteBoqs: Function,
    reverseSubstituteBoqs: Function): void {
    this.substituteMeasurements = substituteMeasurements;
    this.reverseSubstituteMeasurements = reverseSubstituteMeasurements;
    this.substituteBoqs = substituteBoqs;
    this.reverseSubstituteBoqs = reverseSubstituteBoqs;
  }

  clear(): void {
    this.deletedIds.clear();
    this.editedIds.clear();
    this.isAffected$.next(true);
  }

  onEdit(id: number): void {
    this.editedIds.add(id);
    this.isAffected$.next(true);
  }

  onDelete(ids: number[]): void {
    ids.forEach(id => {
      if (this.editedIds.has(id)) {
        this.editedIds.delete(id);
        this.isAffected$.next(true);
      }

      if (id > 0) {
        this.deletedIds.add(id);
        this.isAffected$.next(true);
      }
    });
    this.indeterminate = false;
  }

  generateNewId(items: BoqItem[]): number {
    return items.length ? ((Math.max(...items.map(p => Math.abs(p.id))) + 1) * -1) : -1;
  }

  saveBoqItemsWithQuestion(isEdited: boolean, hasExpression: boolean, callback?: Function): void {
    if (!this.isQuestionOpened) {
      if (isEdited || this.isAffected) {
        this.saveBoqItemsWithQuestionCore(hasExpression, (result: any) => {
          callback(result.data === 'accept');
        });
      } else if (callback) {
        callback(false);
      }
    }
  }

  append(oldValue: string, appeningValue: string, useLastDivider: boolean): string {
    const trimmed = oldValue.trimEnd();
    const plus = '+';
    const sign = +appeningValue < 0 ? '' : plus;
    if (useLastDivider && this.lastDividerPosition && this.lastDividerPosition.dividerType !== DividerType.End) {
      const startValue = this.getTrimmedEnd(trimmed.substring(0, this.lastDividerPosition.index + 1).trimEnd());
      const endValue = this.getTrimmedStart(trimmed.substring(this.lastDividerPosition.index + 1).trimStart());
      return `${startValue}${this.getSign(startValue, plus)}${appeningValue}${this.getSign(endValue, plus, true)}${endValue}`;
    } else {
      return `${oldValue}${this.getSign(this.getTrimmedEnd(trimmed), sign)}${appeningValue}`;
    }
  }

  unwrapTags(value: string): string {
    let reversedValue = this.reverseSubstituteMeasurements ? this.reverseSubstituteMeasurements(value) : value;
    reversedValue = this.reverseSubstituteBoqs ? this.reverseSubstituteBoqs(reversedValue) : reversedValue;

    return unwrapTags(reversedValue, [...measurementTags, boqTagName]);
  }

  wrapTags(value: string): string {
    const wrappedTagsValue = wrapTags(value, [...measurementTags, boqTagName], this.platform.FIREFOX);
    const substituteValue = this.substituteMeasurements ? this.substituteMeasurements(wrappedTagsValue) : wrappedTagsValue;

    return this.substituteBoqs ? this.substituteBoqs(substituteValue) : substituteValue;
  }

  private saveBoqItemsWithQuestionCore(hasExpression: boolean, callback: Function): void {
    this.isQuestionOpened = true;
    this.boqEditService.openModal(hasExpression).afterClosed$.pipe(take(1)).subscribe(result => {
      this.isQuestionOpened = false;
      callback(result);
    });
  }

  onSelectAll(value: boolean): void {
    this.indeterminate = false;
    this.forms.controls.forEach(control => control.get(selectionColumnName)?.setValue(value));
  }

  onSelectionChange(): void {
    const values = this.forms.controls.reduce((r, a) => {
      const value = a.get(selectionColumnName)?.value;
      r[value] = (r[value] ?? 0) + 1;
      return r;
    }, []);
    this.indeterminate = Object.keys(values).length > 1;
    this.selectAllFormControl.setValue(Object.keys(values).length === 1 && Object.keys(values)[0] === 'true');
  }

  removeBoqPredicate(rowIndex: number): void {
    const control = this.forms.at(rowIndex).get(expressionFieldName);
    if (this.lastDividerPosition.index === -1) {
      if (this.lastDividerPosition.dividerType === DividerType.Start) {
        control.setValue(control.value.substring(this.lastDividerPosition.caretPosition + 1));
      } else {
        control.setValue('');
      }
    } else if (this.lastDividerPosition.dividerType === DividerType.End) {
      control.setValue(control.value.substring(0, this.lastDividerPosition.index + 1));
    } else {
      const divider = this.lastDividerPosition;
      control.setValue(control.value.substring(0, divider.index + 1) + control.value.substring(divider.caretPosition + 1));
    }
  }

  getCaretPosition(): number {
    if (window.getSelection && window.getSelection().getRangeAt) {
      const range = window.getSelection().getRangeAt(0);
      const selectedObj = window.getSelection();
      let rangeCount = 0;
      const childNodes = selectedObj.anchorNode.parentNode.childNodes;
      for (const node in childNodes) {
        if (Object.prototype.hasOwnProperty.call(childNodes, node)) {
          const element = childNodes[node];
          if (element === selectedObj.anchorNode) {
            break;
          }
          if ((element as HTMLElement)?.outerHTML) {
            rangeCount += (element as HTMLElement).outerHTML.length;
          } else if (element.nodeType === 3) {
            rangeCount += element.textContent.length;
          }
        }
      }
      return range.startOffset + rangeCount;
    }
    return -1;
  }

  getDividerValue(value: string): string {
    return value.replace('&nbsp;', ' ').trim().toLowerCase();
  }

  isExpression(col: BoqColumnConfig): boolean {
    return col.name === expressionFieldName;
  }

  getChangedItems(row: BoqItem, changes: Object): any {
    const changedItem = {};
    Object.keys(changes).forEach(column => {
      if (row.hasOwnProperty(column)) {
        const newValue = changes[column];
        if (row[column] !== newValue) {
          changedItem[column] = newValue;
        }
      }
    });
    return changedItem;
  }

  setMatchedBoq(value: boolean): void {
    if (value) {
      this.lastCaretPosition = this.lastDividerPosition?.index === -1 ? 0 : this.lastDividerPosition.index;
    }
    this.areMatchedBoqs = value;
  }

  getDividerPosition(eventKey: string, items: BoqItem[], row: BoqItem): DividerPosition {
    const isBackspace = eventKey === 'Backspace';
    const key = eventKey.length === 1 ? eventKey : '';
    const formValue: string = this.getFormControl(items, row, expressionFieldName).value;
    const caretPosition = this.getCaretPosition() + (isBackspace ? -1 : 0);
    this.lastDividerPosition = this.lastCaretPosition === -1
      ? this.getDividerPositionCore(formValue, key, caretPosition)
      : this.lastDividerPosition;
    if (this.areMatchedBoqs && this.lastCaretPosition === -1) {
      this.lastCaretPosition = this.lastDividerPosition.index === -1 ? 0 : this.lastDividerPosition.index;
    }
    if (this.lastCaretPosition !== -1) {
      const startIndex = this.lastCaretPosition === 0 ? 0 : this.lastCaretPosition + 1;
      this.lastDividerPosition.caretPosition = caretPosition;
      this.lastDividerPosition.value = this.getDividerValue(formValue.substring(startIndex, caretPosition) + key);
    }
    if (this.lastDividerPosition?.value.length < 2) {
      this.resetCarretPostion();
    }
    return this.lastDividerPosition;
  }

  resetCarretPostion(): void {
    this.lastCaretPosition = -1;
  }

  handleMatchedBoqItems(event: KeyboardEvent): boolean {
    if (isDownKey(event.key) && this.areMatchedBoqs) {
      this.boqEditService.moveInMatchedBoqs(ArrowMovement.Down);
    } else if (isUpKey(event.key) && this.areMatchedBoqs) {
      this.boqEditService.moveInMatchedBoqs(ArrowMovement.Up);
    } else if ((isEnterKey(event.key) || isTabKey(event.key)) && this.areMatchedBoqs) {
      this.boqEditService.insertMatchedBoq();
    } else if (isEscapeKey(event.key) && this.areMatchedBoqs) {
      this.boqInserted.next();
    } else {
      return false;
    }
    return true;
  }

  getFormControl(items: BoqItem[], row: BoqItem, column: string): AbstractControl {
    return row ? this.forms.at(items.findIndex(p => p.id === row.id))?.get(column) : undefined;
  }

  generateForms(items: BoqItem[], addCheckbox: boolean): void {
    this.formChangedSubscription?.unsubscribe();
    this.formChangedSubscription = new Subscription();
    this.forms.clear();
    items.forEach(p => this.forms.push(this.generateForm(p, addCheckbox)));
    this.selectAllFormControl = this.formBuilder.control(false);
    this.indeterminate = false;
  }

  getForm(rowIndex: number): AbstractControl {
    return this.forms.at(rowIndex);
  }

  insertForm(rowIndex: number, newItem: BoqItem, addCheckbox: boolean): void {
    this.forms.insert(rowIndex, this.generateForm(newItem, addCheckbox));
  }

  removeForms(rowIndexes: number[]): void {
    rowIndexes.slice().reverse().forEach(rowIndex => this.forms.removeAt(rowIndex));
  }

  patchValue(rowIndex: number, newItem: BoqItem): void {
    this.forms.at(rowIndex)?.patchValue({
      ...newItem,
      expression: this.wrapTags(newItem.expression)
    });
  }

  getNewValue(items: BoqItem[], row: BoqItem, column: string): any {
    return (column === expressionFieldName)
      ? this.unwrapTags(this.getFormControl(items, row, column).value)
      : this.getFormControl(items, row, column)?.value;
  }

  getRow(row: BoqItem, column: string, newValue: any): BoqItem {
    const settedValue = {};
    settedValue[column] = newValue;
    return { ...row, ...settedValue };
  }

  setIsAffected(isAffected: boolean): void {
    this.isAffected$.next(isAffected);
  }

  hasCurrentRowValueChanged(items: BoqItem[], rowIndex: number, canCheck: boolean): boolean {
    const row = items[rowIndex];
    if (!canCheck && row) {
      const newValue = this.getNewValue(items, row, expressionFieldName);
      return row[expressionFieldName] !== newValue;
    }
    return false;
  }

  private getDividerPositionCore(value: string, key: string, caretPosition: number): DividerPosition {
    const valueToCaret = value.substring(0, caretPosition);
    let index = getLastIndexOfDividerChar(value);
    if (valueToCaret.length === value.length && caretPosition > value.length) {
      return {
        index,
        dividerType: DividerType.End,
        value: this.getDividerValue(value.substring(index + 1) + key),
        caretPosition
      };
    }
    index = getLastIndexOfDividerChar(valueToCaret);
    return {
      index,
      dividerType: index === -1 ? DividerType.Start : DividerType.Middle,
      value: this.getDividerValue((index === -1 ? valueToCaret : value.substring(index + 1, caretPosition)) + key),
      caretPosition
    };
  }

  private getTrimmedEnd(value: string): string {
    return value.endsWith('<wbr>') ? value.substring(0, value.lastIndexOf('<wbr>')).trimEnd() : value;
  }

  private getTrimmedStart(value: string): string {
    return value.startsWith('<wbr>') ? value.substring(5).trimStart() : value;
  }

  private getSign(value: string, sign: string, useStartIndex: boolean = false): string {
    const bracket = useStartIndex ? ')' : '(';
    const index = useStartIndex ? 0 : value.length - 1;
    return (value.length && !['+', '-', '*', '/', bracket].includes(value[index])) ? sign : '';
  }

  private generateForm(item: BoqItem, addCheckbox: boolean): UntypedFormGroup {
    let formGroupConfig = {
      ...item,
      expression: this.wrapTags(item.expression),
      selection: addCheckbox ? false : undefined
    };
    if (this.budgetGrouping) {
      formGroupConfig = {
        ...formGroupConfig,
        group1: item.group1,
        group2: item.group2,
        group3: item.group3
      };
    }
    const formGroup = this.formBuilder.group(formGroupConfig);
    let previousExpression = this.wrapTags(item.expression);
    this.formChangedSubscription.add(formGroup.get(expressionFieldName).valueChanges
      .pipe(filter(p => p !== previousExpression))
      .subscribe(p => {
        previousExpression = p;
        this.onEdit(item.id);
      }));
    return formGroup;
  }
}
