import { Injectable, Injector } from '@angular/core';

import {
  CalculationType,
  ColDef,
  ColDefTextAlign,
  ColDefType,
  formatNumberValue,
  getColumnWidth,
  getNodeLevel,
  isItem,
  isSectionOrItem,
  isSummaryItem,
  TranslateService,
} from '@kros-sk/ssw-shared-legacy';

import { BuildingProgressActionAccessService } from './building-progress-action-access.service';
import { BuildingProgressItem, BuildingProgressModel } from '../models/construction-data.model';
import { BuildingProgressPanelHelper } from '../helpers/building-progress-panel.helper';
import { BuildingProgressPartialItemsService } from './building-progress-partial-items.service';
import { environment } from '../../../environments/environment';
import {
  setColorIfAdditionOrChangeSheet,
  setColorIfAdditionOrChangeSheetAllItems,
  setColorIfMaterial,
  setCornerForNote,
  setFontColorIfPartialItem,
  setIconColorIfUnsplitItem
} from '../helpers/table-config-columns.helper';

@Injectable()
export class TableConfigBudgetColumnsService {

  private get appLocation(): string {
    return environment.location;
  }

  constructor(
    private injector: Injector,
    private buildingProgressActionAccessService: BuildingProgressActionAccessService,
    private translateService: TranslateService
  ) { }

  getBudgetColumns(
    constructionData: BuildingProgressModel,
    columnsMaxNumber: any,
    hasSubcontractorColumn: boolean,
    showClassificationsColumns: boolean,
    hidePrices?: boolean,
    useBudgetPartialItemActionColumn = false
  ): Array<ColDef> {
    return [
      {
        id: 'itemType', title: 'BUILDING_PROGRESS.TABLE.ITEM_TYPE', type: ColDefType.Column,
        textAlign: ColDefTextAlign.Center, isFixed: true, width: 20, mobileHidden: true,
        cellClassGetter: (data: BuildingProgressItem): string => setColorIfAdditionOrChangeSheet(data)
      },
      {
        id: 'itemNumber', title: 'BUILDING_PROGRESS.TABLE.ITEM_NUMBER', type: ColDefType.Column,
        textAlign: ColDefTextAlign.Right, isFixed: true, width: 30,
        backgroundColorCode: (data: BuildingProgressItem): string => data.colorCode
      },
      ...(showClassificationsColumns ? [
        {
          id: 'productionClassification', title: 'BUILDING_PROGRESS.TABLE.PRODUCTION_CLASSIFICATION', type: ColDefType.Column,
          isFixed: true, width: 50, valueTooltip: true
        }
      ] : []),
      {
        id: 'code', title: 'BUILDING_PROGRESS.TABLE.ITEM_CODE', type: ColDefType.Column, isFixed: true, width: 110,
        cellClassGetter: (data: BuildingProgressItem): string => setColorIfAdditionOrChangeSheetAllItems(data)
      },
      {
        id: 'description', title: 'BUILDING_PROGRESS.TABLE.DESCRIPTION', type: ColDefType.Column, width: 300,
        cellClassGetter: (data: BuildingProgressItem): string =>
          (data && isSummaryItem(data) ?
            'level-' + getNodeLevel(data.level) + (data.originalId ? ' additional-item-padding' : '') : '') +
          setColorIfAdditionOrChangeSheet(data) + setColorIfMaterial(data) + setCornerForNote(data) + setFontColorIfPartialItem(data)
        ,
        isFixed: true,
        cellActionButton: {
          id: (): string => 'ga-note-open',
          iconName: (): string => 'crop_free',
          tooltip: (): string => 'NOTES.ITEM_NOTE',
          showCondition: (item: BuildingProgressItem): boolean => isSectionOrItem(item),
          showAlways: true,
          action: (item: BuildingProgressItem): void => {
            const panelHelper = this.injector.get(BuildingProgressPanelHelper);
            panelHelper.openNotes(constructionData.projectId, item);
          }
        }
      },
      {
        id: 'completedPercentagePie', title: '%', type: ColDefType.Column, width: 20,
        isFixed: true,
        isPercentageColumn: true,
        valueGetter: (data: BuildingProgressItem): any => data.completedPercentage
      },
      {
        id: 'measureUnit', title: 'BUILDING_PROGRESS.TABLE.MEASURE_UNIT', type: ColDefType.Column,
        cellClassGetter: (data: BuildingProgressItem): string => setColorIfAdditionOrChangeSheet(data),
        isFixed: true, width: 40
      },
      {
        id: 'amount', title: 'BUILDING_PROGRESS.TABLE.AMOUNT', type: ColDefType.Column, minWidth: 80,
        width: getColumnWidth(columnsMaxNumber.amount, constructionData.decimalPlaces.amount, this.appLocation, false),
        format: (value: any, editMode: boolean): string =>
          formatNumberValue(value, constructionData.decimalPlaces.amount, this.appLocation),
        textAlign: ColDefTextAlign.Right,
        valueGetter: (data: BuildingProgressItem): any => isSummaryItem(data)
          ? null
          : data.originalItemIdOfPartialItem
            ? data.partialBudgetItems.find(i => i.id === -data.id).amount
            : data.amount,
        cellClassGetter: (data: BuildingProgressItem): string =>
          !data ? '' :
            ((!data.amount ? 'font-red ' : '') +
              (data.calculationType === CalculationType.BillOfQuantities ? 'font-blue' :
                data.calculationType !== CalculationType.None ? 'font-green' : '')),
        cellActionButton: {
          id: (): string => 'ga-boq-open',
          iconName: (): string => 'square_foot',
          tooltip: (): string => 'BILL_OF_QUANTITIES.COUNTED_FROM_BILL_OF_QUANTITIES',
          showCondition: (item: BuildingProgressItem): boolean =>
            !useBudgetPartialItemActionColumn && item.calculationType === CalculationType.BillOfQuantities,
          action: (item: BuildingProgressItem): void => {
            const panelHelper = this.injector.get(BuildingProgressPanelHelper);
            panelHelper.openBoq(constructionData.projectId, item);
          }
        }
      },
      ...!hidePrices ? [{
        id: 'unitPrice', title: 'BUILDING_PROGRESS.TABLE.UNIT_PRICE', type: ColDefType.Column, minWidth: 60,
        width: getColumnWidth(columnsMaxNumber.unitPrice, constructionData.decimalPlaces.unitPrice, this.appLocation, false),
        format: (value: any, editMode: boolean): string =>
          formatNumberValue(value, constructionData.decimalPlaces.unitPrice, this.appLocation),
        textAlign: ColDefTextAlign.Right,
        valueGetter: (data: BuildingProgressItem): any => data && isSummaryItem(data) ? null : data.unitPrice
      },
      {
        id: 'totalPrice', title: 'BUILDING_PROGRESS.TABLE.TOTAL_PRICE', type: ColDefType.Column, minWidth: 100,
        width: getColumnWidth(columnsMaxNumber.totalPrice, constructionData.decimalPlaces.totalPrice, this.appLocation, true),
        format: (value: any, editMode: boolean): string =>
          formatNumberValue(value, constructionData.decimalPlaces.totalPrice, this.appLocation),
        textAlign: ColDefTextAlign.Right,
        valueGetter: (data: BuildingProgressItem): any =>
          data.originalItemIdOfPartialItem
            ? data.partialBudgetItems.find(i => i.id === -data.id).totalPrice
            : data.totalPrice,
      }] : [],
      ...(hasSubcontractorColumn ? [
        {
          id: 'contractor', title: 'BUILDING_PROGRESS.TABLE.SUBCONTRACTOR', type: ColDefType.Column, width: 80, valueTooltip: true
        }
      ] : []),
      ...(showClassificationsColumns ? [
        {
          id: 'customClassification', title: 'BUILDING_PROGRESS.TABLE.USER_CLASSIFICATION', type: ColDefType.Column,
          width: 160, valueTooltip: true
        }
      ] : []),
      ...(useBudgetPartialItemActionColumn ? [{
        id: 'budgetPartialItemAction', title: '', type: ColDefType.Column, textAlign: ColDefTextAlign.Center, width: 20,
        valueTooltip: true,
        cellClassGetter: (data: BuildingProgressItem): string => setIconColorIfUnsplitItem(data),
        cellActionButton: {
          id: (item: BuildingProgressItem): string => item?.partialBudgetItems?.length > 1 ? 'itemMerge' : 'itemSplit',
          iconName: (item: BuildingProgressItem): string => item?.partialBudgetItems?.length > 1 ? 'merge' : 'alt_route',
          tooltip: (item: BuildingProgressItem): string => item?.partialBudgetItems?.length > 1 ?
            this.translateService.translate('BUILDING_PROGRESS.PARTIAL_ITEMS.MERGE') :
            this.translateService.translate('BUILDING_PROGRESS.PARTIAL_ITEMS.SPLIT'),
          showCondition: (item: BuildingProgressItem): boolean => isItem(item),
          showAlways: true,
          action: (item: BuildingProgressItem): void => {
            if (this.buildingProgressActionAccessService.checkForAccessToPartialItems()) {
              const budgetPartialItemsService = this.injector.get(BuildingProgressPartialItemsService);
              if (item.partialBudgetItems?.length > 1) {
                budgetPartialItemsService.openMergeItemsDialog(item, constructionData.projectId);
              } else {
                budgetPartialItemsService.openSplitItemsDialog(item);
              }
            }
          }
        }
      }] : [])
    ];
  }
}
