import localeCz from '@angular/common/locales/cs';
import localeSk from '@angular/common/locales/sk';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';

import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { localStorageSync } from 'ngrx-store-localstorage';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AlertDialogModule, applicationType, UserModule } from '@kros-sk/ssw-cdk';
import {
  AnalyticsModule,
  ApplicationOpenerModule,
  createUndoRedoActionSaveReducer,
  InfoMessageModule,
  InterceptorsModule,
  languageOptions,
  NavbarModule,
  SSW_APPLICATION_ID,
  SswApplicationId,
  TranslateModule,
  undoRedoKey,
  undoRedoReducer,
  ZIP_SETTINGS_TOKEN
} from '@kros-sk/ssw-shared-legacy';
import { APP_CONFIG } from '@kros-sk/app-config';
import { APPLICATION_ID, KrosTourModule, MaintenanceModule } from '@kros-sk/components';
import { BIM_VIEWER_SETTINGS } from '@kros-sk/ssw-bim-viewer';
import { ConstructionDiaryModule } from '@kros-sk/ssw-shared/construction-diary';
import { DeviceLoggerModule } from '@kros-sk/shared/device-logger';
import { KrosAuthModule } from '@kros-sk/auth';
import { KrosFeatureFlagsProviderModule } from '@kros-sk/core/feature-flags';
import { needClearStackBudgetActions, undoableBudgetActions } from '@kros-sk/ssw-budget/budget';
import { QuantityManagerNavigationComponent } from '@kros-sk/ssw-quantity-manager/qm-navigation';
import { SettingService } from '@kros-sk/ssw-shared/settings';
import { SswFeatureFlagsApiService } from '@kros-sk/ssw-shared/ssw-feature-flags';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { environment } from '../environments/environment';
import { needClearStackBuildingProgressActions, undoableBuildingProgressActions } from './store/undo-redo/undo-redo-actions';
import { ProjectsModule } from './projects/projects.module';
import { SharedModule } from './shared/shared.module';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({ keys: ['projects', 'info-message'], rehydrate: true })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>>
  = [
    localStorageSyncReducer,
    createUndoRedoActionSaveReducer(
      [...undoableBuildingProgressActions, ...undoableBudgetActions],
      [...needClearStackBuildingProgressActions, ...needClearStackBudgetActions]
    )
  ];

const identityServerConfig = {
  applicationId: environment.identityServer.applicationId,
  clientUrl: environment.identityServer.clientUrl,
  externalLoginRoutes: environment.identityServer.externalLoginRoutes,
  url: environment.identityServer.url
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    UserModule.forRoot(environment.appUrls.titanGatewayUrl),
    ApplicationOpenerModule.forRoot(),
    KrosAuthModule.forRoot(
      identityServerConfig,
      environment.identityServer.clientId,
      environment.identityServer.scope,
      applicationType
    ),
    KrosFeatureFlagsProviderModule.forRoot(SswFeatureFlagsApiService),
    AlertDialogModule,
    AnalyticsModule,
    BrowserModule,
    BrowserAnimationsModule,
    InterceptorsModule.forRoot(),
    CoreModule,
    ProjectsModule,
    KrosTourModule,
    MaintenanceModule,
    SharedModule,
    TranslateModule.forRoot(() => environment.defaultLanguage, languageOptions),
    NavbarModule.forRoot(() => environment.location, (_) => 'projects'),
    EffectsModule.forRoot([]),
    StoreModule.forRoot({ router: routerReducer }, { metaReducers }),
    StoreModule.forFeature(undoRedoKey, undoRedoReducer),
    StoreDevtoolsModule.instrument({
      name: 'Titan App',
      maxAge: 40,
      logOnly: environment.production,
      connectInZone: true
    }),
    DeviceLoggerModule,
    InfoMessageModule,
    StoreRouterConnectingModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    QuantityManagerNavigationComponent,
    ConstructionDiaryModule
  ],
  providers: [
    { provide: APPLICATION_ID, useValue: applicationType },
    { provide: SSW_APPLICATION_ID, useValue: SswApplicationId.Titan },
    { provide: APP_CONFIG, useValue: environment },
    Title,
    SettingService,
    {
      provide: BIM_VIEWER_SETTINGS,
      useValue: {
        tokenName: environment.bimUserTokenName,
        useOverlayPointerEvents: true,
        keepMeasurements: true
      }
    },
    {
      provide: ZIP_SETTINGS_TOKEN,
      useValue: environment.zipSettings
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  constructor() {
    registerLocaleData(localeSk, 'sk');
    registerLocaleData(localeCz, 'cz');
  }
}
