import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { KrosModalRef } from '@kros-sk/components';
import { TranslateService } from '@kros-sk/ssw-shared-legacy';

import { BuildingProgressPeriod } from '../../../building-progress/models/building-progress-period.model';
import { PeriodDatePipe } from '../../../building-progress/modules/periods/pipes/period-date.pipe';

@Component({
  selector: 'app-complete-dialog',
  templateUrl: './complete-dialog.component.html'
})
export class CompleteDialogComponent {
  data: { areItemsSelected: boolean, itemType: string, period: BuildingProgressPeriod };
  completeForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalRef: KrosModalRef,
    private translateService: TranslateService,
    private periodDatePipe: PeriodDatePipe
  ) {
    this.data = this.modalRef.data;
    this.completeForm = this.formBuilder.group(
      {
        setZeroRestToOverdrawnItems: [false]
      }
    );
  }

  get buttonText(): string {
    if (this.data.areItemsSelected) {
      return this.translateService.translate('BUILDING_PROGRESS.COMPLETE.COMPLETE_SELECTED');
    } else {
      switch (this.data.itemType) {
        case 'D':
          return this.translateService.translate('BUILDING_PROGRESS.COMPLETE.COMPLETE_PART');
        case 'ZL':
        case 'O':
          return this.translateService.translate('BUILDING_PROGRESS.COMPLETE.COMPLETE_OBJECT');
        case 'S':
          return this.translateService.translate('BUILDING_PROGRESS.COMPLETE.COMPLETE_BUILDING');
        default:
          return this.translateService.translate('BUILDING_PROGRESS.COMPLETE.COMPLETE_ITEM');
      }
    }
  }

  get periodDateText(): string {
    return this.periodDatePipe.transform(this.data.period.dateFrom, this.data.period.dateTo);
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  onSubmit(): void {
    this.modalRef.submit({
      setZeroRestToOverdrawnItems: this.completeForm.controls['setZeroRestToOverdrawnItems'].value
    });
  }
}
