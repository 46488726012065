import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AnalyticsButtonDirective } from './analytics-button-directive/analytics-button.directive';
import { AnalyticsService } from './analytics-service/analytics.service';

@NgModule({
  declarations: [AnalyticsButtonDirective],
  imports: [
    CommonModule,
  ],
  providers: [AnalyticsService],
  exports: [AnalyticsButtonDirective]
})
export class AnalyticsModule {

}
