import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { filter, take } from 'rxjs/operators';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import {
  BudgetStructureService,
  Cell,
  DataTableComponent,
  DetailBaseComponent,
  InfoModalComponent,
  isSummaryItem,
  PanelHelper,
  Position,
  QuestionModalComponent,
  SidePanelComponent,
  TranslateService
} from '@kros-sk/ssw-shared-legacy';
import { KrosModalService } from '@kros-sk/components';

import { BudgetApprovalApproveService } from '../../services/budget-approval-approve.service';
import { BudgetApprovalDispatchersService, BudgetApprovalSelectorsService } from '../../../store/budget-approval';
import { BudgetApprovalItem, BudgetApprovalModel, ChangeSheetItem, ChangeSheetModel } from '../../models';
import {
  BudgetApprovalItemPanelDialogComponent
} from '../budget-approval-item-panel-dialog/budget-approval-item-panel-dialog.component';
import {
  BuildingProgressActionAccessService
} from '../../../building-progress/services/building-progress-action-access.service';
import { ChangeSheetTableService } from '../../services';
import { DeleteRelationViewModel } from '../../models/delete-relation-view-model';
import { environment } from '../../../../environments/environment';
import { ExportHelper, RelationHelper } from '../../helpers';
import { LicenseHelper, PermissionHelper } from '../../../building-progress/helpers';
import { ProjectsSelectorsService } from '../../../store/projects';

const fixedColumnWidth = 724;

@Component({
  selector: 'app-change-sheet-detail',
  templateUrl: './change-sheet-detail.component.html',
  styleUrls: ['./change-sheet-detail.component.scss'],
  providers: [ChangeSheetTableService, RelationHelper, LicenseHelper, PermissionHelper]
})
export class ChangeSheetDetailComponent extends DetailBaseComponent implements OnInit, OnDestroy {

  @ViewChild(SidePanelComponent) sidePanel: SidePanelComponent;

  @ViewChild(DataTableComponent) set tableComponent(value: DataTableComponent) {
    this.table = value;

    if (this.table && this.scrollElementToViewParams) {
      this.scrollElementToView(
        this.scrollElementToViewParams.elementId,
        this.scrollElementToViewParams.elementIdForColumnWidth);
      this.scrollElementToViewParams = undefined;
    }
  }

  @Input() tableId: string;

  isChangeSheetApproved: boolean;

  private budgetApprovalData: BudgetApprovalModel;
  private budgetObjectId: number;
  private selectedItemIds = new Set<number>();
  private items: BudgetApprovalItem[];
  private previousRowIndex: number;

  get canOpenBoq(): boolean {
    return !!this.tableData && !!this.focusedCell;
  }

  constructor(
    public panelHelper: PanelHelper,
    public tableService: ChangeSheetTableService,
    public selector: BudgetApprovalSelectorsService,
    private activatedRoute: ActivatedRoute,
    private budgetStructureService: BudgetStructureService,
    private budgetApprovalApproveService: BudgetApprovalApproveService,
    private buildingProgressActionAccessService: BuildingProgressActionAccessService,
    private dispatcher: BudgetApprovalDispatchersService,
    private krosModalService: KrosModalService,
    private licenseHelper: LicenseHelper,
    private exportHelper: ExportHelper,
    private projectsSelectorsService: ProjectsSelectorsService,
    private router: Router,
    private translateService: TranslateService,
    private appInsightsService: AppInsightsBaseService
  ) {
    super(environment.location);
  }

  ngOnInit(): void {
    this.budgetObjectId = +this.activatedRoute.snapshot.paramMap.get('budgetObjectId');
    this.projectId = +this.activatedRoute.parent.snapshot.paramMap.get('projectId');

    this.subs.sink = this.selector.changeSheetData$.subscribe(p => this.setData(p));
    this.subs.sink = this.projectsSelectorsService.projectDetail$
      .pipe(filter(p => !!p))
      .subscribe(p => this.setProjectId(p.id));
    this.subs.sink = this.budgetStructureService.setFocusToBudgetItemRequested$
      .pipe(filter(result => result.tableId === this.tableId))
      .subscribe(result => this.setFocusToItemById(result.id));
    this.subs.sink = this.selector.selectedChangeSheetItems$
      .subscribe(resp => {
        if (resp && resp instanceof Set) {
          this.selectedItemIds = resp;
        }
      });
    this.subs.sink = this.selector.budgetApprovalData$
      .subscribe(data => {
        if (data) {
          this.budgetApprovalData = data;
        } else {
          if (this.projectId) {
            this.dispatcher.loadBudgetApprovalData(this.projectId);
          }
        }
      });

    this.dispatcher.loadChangeSheetData(this.projectId, this.budgetObjectId);
    this.fixedColumnWidth = fixedColumnWidth;

    this.subs.sink = this.selector.isChangeSheetApproved$(this.budgetObjectId)
      .subscribe(i => this.isChangeSheetApproved = i);
    this.subs.sink = this.selector.budgetApprovalData$
      .pipe(filter(d => !!d))
      .subscribe(i => this.items = i.items);
    this.subs.sink = this.panelHelper.loadBoqItems$
      .subscribe(i => this.dispatcher.loadBoqItems(this.projectId, i));

    this.panelHelper.init(this.showPanel.bind(this), this.hidePanel.bind(this), this.resizePanel.bind(this));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.panelHelper.onCloseBoq();
    this.tableService.destroy();
    this.licenseHelper.destroy();
  }

  private setFocusToItemById(id: number): void {
    this.focusedCell = this.getFocusedCell(this.focusedCell?.colId ?? 'description',
      this.tableData.items.findIndex(i => i.id === id),
      this.focusedCell?.params);

    super.focusCell();
  }

  private getFocusedCell(colId: string, rowIndex: number, params?: any): Cell {
    return { colId, rowIndex, params: params ?? { periodId: 0, periodColumn: '' } };
  }

  onCellFocused(event: any): void {
    super.onCellFocused(event);

    if (this.previousRowIndex !== this.focusedCell.rowIndex) {
      this.panelHelper.loadBoq(this.tableData?.items[this.focusedCell.rowIndex]);
      this.previousRowIndex = this.focusedCell.rowIndex;
    }
    this.setFocusToStructureItemById();
  }

  private setFocusToStructureItemById(): void {
    if (this.focusedCell && this.hasData) {
      const item = this.tableData.items[this.focusedCell.rowIndex];

      if (isSummaryItem(item)) {
        this.budgetStructureService.setFocusToStructureItemById(item.id, this.tableId);
      } else {
        this.budgetStructureService.setFocusToStructureItemById(item.parentId, this.tableId);
      }
    }
  }

  private setData(data: ChangeSheetModel): void {
    this.tableData = data;
    this.dataTableConfig = this.hasData ? this.tableService.getTableConfig(this.tableData) : undefined;

    this.focusCell();
    this.setFocusToStructureItemById();
  }

  private setProjectId(projectId: number): void {
    if (this.projectId && this.projectId !== projectId) {
      this.navigateToBudgetApproval(projectId);
    }
  }

  private navigateToBudgetApproval(projectId: number): void {
    this.router.navigate(['projects/project-detail', projectId, 'budget-approval']);
  }

  onBackClick(): void {
    this.navigateToBudgetApproval(this.projectId);
  }

  onShowChangeSheetItemsDialogClick(): void {
    this.krosModalService.openCentered(
      BudgetApprovalItemPanelDialogComponent,
      {
        title: this.translateService.translate('CHANGE_SHEET.STRUCTURE'),
        tableId: this.tableId,
        selectorData: this.selector.changeSheetData$
      },
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: false,
        showMobileArrowBack: false
      },
      NaN,
      NaN,
      undefined,
      'kros-modal-panel-left'
    );
  }

  onHeaderCheckChanged(state: boolean): void {
    if (state) {
      this.dispatcher.selectAllChangeSheetItems();
    } else {
      this.dispatcher.clearSelectedChangeSheetItems();
    }
  }

  onItemCheckChanged(item: any, state: boolean): void {
    this.dispatcher.selectChangeSheetItem(item.id, state);
  }

  onDeleteRelation(): void {
    if (this.focusedCell) {
      const currentItem: ChangeSheetItem = this.tableData.items[this.focusedCell.rowIndex];
      const selectedHaveRelation: boolean = this.tableData.items.some(item => this.selectedItemIds.has(item.id) && item.originalId);

      if ((this.selectedItemIds.size > 0 && selectedHaveRelation) || (this.selectedItemIds.size === 0 && currentItem?.originalId)) {
        this.openRelationDeleteMessageBox();
      } else {
        this.openNoRelationMessageBox(this.selectedItemIds.size > 0);
      }
    }
  }

  private openRelationDeleteMessageBox(): void {
    let itemIds: number[];
    let messageText: string;

    if (this.selectedItemIds.size > 0) {
      itemIds = [...this.selectedItemIds];
      messageText = this.translateService.translate('CHANGE_SHEET.RELATION.DELETE_MARKED_QUESTION');
    } else {
      itemIds = [this.tableData.items[this.focusedCell.rowIndex].id];
      messageText = this.translateService.translate('CHANGE_SHEET.RELATION.DELETE_QUESTION');
    }

    const modalRef = this.krosModalService.openCentered(
      QuestionModalComponent,
      {
        body: messageText,
        confirmButton: this.translateService.translate('COMMON.YES'),
        cancelButton: this.translateService.translate('COMMON.NO')
      },
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: false,
        showMobileArrowBack: false,
        closeOnEscClick: true
      },
      NaN,
      NaN,
      undefined,
      'no-min-width'
    );

    modalRef.afterClosed$.pipe(take(1)).subscribe(result => {
      if (result.type === 'submit' && this.buildingProgressActionAccessService.checkForAccessToCreateAndDeleteRelation()) {
        if (this.selectedItemIds.size === 0 || this.buildingProgressActionAccessService.checkForAccessToBulkOperations(true)) {
          this.appInsightsService.trackEvent('BA-change-sheet-cancel-relation', result.data);
          this.dispatcher.deleteChangeSheetRelation({
            projectId: this.projectId,
            budgetItemIds: itemIds
          } as DeleteRelationViewModel);
        }
      }
    });
  }

  private openNoRelationMessageBox(areItemsSelected: boolean): void {
    const message = `CHANGE_SHEET.RELATION.${areItemsSelected ? 'NO_RELATION_SELECTED_MESSAGE' : 'NO_RELATION_MESSAGE'}`;
    this.krosModalService.openCentered(
      InfoModalComponent,
      {
        body: this.translateService.translate(message),
      },
      {
        closeOnBackdropClick: false,
        closeOnEscClick: true,
        fullscreenOnMobile: false
      },
      NaN,
      NaN,
      undefined,
      'no-min-width'
    );
  }

  onApprove(): void {
    this.budgetApprovalApproveService.changeApprovalBuildingObjects(
      this.projectId,
      this.items.find(i => i.id === this.budgetObjectId),
      this.getChilds(),
      false);
  }

  onCancelApproved(): void {
    this.budgetApprovalApproveService.changeApprovalBuildingObjects(
      this.projectId,
      this.items.find(i => i.id === this.budgetObjectId),
      this.getChilds(),
      true);
  }

  private getChilds(): BudgetApprovalItem[] {
    const ret = [];
    let actualIndex = this.items.findIndex(i => i.id === this.budgetObjectId) + 1;
    const levelOfSelectedItem = this.items.find(i => i.id === this.budgetObjectId).level;

    while (this.items.length > actualIndex && this.items[actualIndex].level > levelOfSelectedItem) {
      ret.push(this.items[actualIndex++]);
    }

    return ret;
  }

  onExportClick(): void {
    this.exportHelper.openBudgetApprovalExport(this.budgetApprovalData, this.projectId, this.budgetObjectId);
  }

  onOpenBoqClick(): void {
    this.panelHelper.openBoq(this.tableData.items[this.focusedCell.rowIndex]);
    this.scrollFocusedCellIntoView(300);
  }

  private showPanel(position: Position, size: number): void {
    this.sidePanel?.showPanel(position, size);
  }

  private hidePanel(position: Position): void {
    this.sidePanel?.hidePanel(position);
  }

  private resizePanel(position: Position, size: number): void {
    this.sidePanel?.resizePanel(position, size);
  }
}
