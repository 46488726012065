export function removeDiacritics(text: string): string {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function removeWhiteSpace(text: string): string {
  return text.replace(/\s/g, '');
}
export function extractNameFromEmail(text: string): string {
  if (text.includes('@')) {
    const username = text.substring(0, text.indexOf('@'));
    return username
      .split(/[-+_.]/)
      .map((name) => capitalizeFirstLetter(name))
      .join(' ');
  }

  return text;
}

export function capitalizeFirstLetter(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function getHashCode(input: string): number {
  let hash = 0;
  let i = 0;
  const len = input.length;
  while (i < len) {
    // eslint-disable-next-line no-bitwise
    hash = ((hash << 5) - hash + input.charCodeAt(i++)) << 0;
  }
  return hash;
}

export function getInitials(text: string, maxLength = 2): string {
  let initials = '';

  if (text) {
    const names = text.split(/[ -]/).filter((name) => name.length);

    if (names.length > maxLength) {
      for (let i = 0; i < maxLength - 1; i++) {
        initials += names[i][0];
      }
      initials += names[names.length - 1][0];
    } else if (names.length === maxLength) {
      for (const name of names) {
        initials += name[0];
      }
    } else {
      initials = text.substr(0, Math.min(maxLength, text.length));
    }

    initials = initials.toUpperCase();
  }

  return initials;
}

export function stripCompanyTypeExtension(name: string): string {
  return name
    ?.replace(/a\.\s?s\.$/, '')
    .replace(/s\.\s?r\.\s?o\.$/, '')
    .replace(/spol\.\s?s\s?r\.\s?o\.$/, '')
    .replace(/v\.\s?o\.\s?s\.$/, '')
    .replace(/k\.\s?s\.$/, '')
    .replace(/(P|p)lc\.?$/, '')
    .replace(/(L|l)td\.?$/, '').trim();
}

export function camelCaseString(word: string): string {
  return word.charAt(0).toLowerCase() + word.slice(1);
}

export function compareStringsIgnoreCase(string1: string, string2: string): boolean {
  return string1.toLowerCase() === string2.toLowerCase();
}

export function prepareVariableSymbolFromDocumentNumber(documentNumber: string): string {
  if (!documentNumber) {
    return '';
  }

  const variableSymbol = documentNumber.replace(/\D+/g, '');
  if (variableSymbol.length > 10) {
    return variableSymbol.slice(-10);
  }
  return variableSymbol;
}

export const transformToUppercase = (value: string): string => {
  return value?.toUpperCase() || value;
};

export const startsWithIgnoreCaseAndDiacritics = (string: string, searchString: string): boolean =>
  removeDiacritics(string.trim().toLowerCase()).startsWith(removeDiacritics(searchString.toLowerCase()));
