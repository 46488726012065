<div class="avatars-container">
  <ng-container *ngIf="!isEmptyOwnerEmail()">
    <kros-titan-avatar
      [class]="size"
      [email]="ownerMail"
    ></kros-titan-avatar>

    <div class="divider-vertical"></div>
  </ng-container>

  <kros-titan-avatar
    *ngFor="let user of getNotOwnerUsers()"
    [class]="'avatar-list ' + size"
    [email]="user.email"
    [icon]="user.isRegistered ? '' : 'person_add_disabled'"
    data-test="avatar"
  ></kros-titan-avatar>

  <ng-container *ngIf="hasReachedMaxAvatarsLimit()">
    <span class="max-avatars-limit">+{{ getNotOwnerUsersCount() }}</span>
  </ng-container>
</div>
