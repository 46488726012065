import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output
} from '@angular/core';
import { Router } from '@angular/router';

import { filter, take } from 'rxjs/operators';
import { interval, timer } from 'rxjs';
import { SubSink } from 'subsink';

import { Maintenance } from '@kros-sk/models';

import { DeviceDetectorService, DeviceType } from '../../../services';
import { MaintenanceService } from '../../services/maintenance.service';

@Component({
  selector: 'kros-update-info',
  templateUrl: './update-info.component.html',
  styleUrls: ['./update-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateInfoComponent implements OnInit, OnDestroy {

  @Input() maintenanceUrl: string;
  @Input() showCountDownLimitInMinutes: number;
  @Input() isVisible = true;

  @Output() showInfoAboutOldVersion = new EventEmitter<void>();

  isClosed = false;
  maintenance: Maintenance;
  countDown: { minutes: string, seconds: string };

  private subs = new SubSink();

  constructor(
    private maintenanceService: MaintenanceService,
    private changeDetector: ChangeDetectorRef,
    private deviceDetectorService: DeviceDetectorService,
    private router: Router
  ) {
    this.subs.sink = this.maintenanceService.checkForOldVersion().pipe(filter(p => p), take(1))
      .subscribe(() => this.showInfoAboutOldVersion.emit());
  }

  @HostBinding('class.shown') get isShown(): boolean {
    return this.canShowUpdateInfo && (!this.isClosed || this.showCountDown) && this.isVisible;
  }

  get showCountDown(): boolean {
    if (!this.maintenance) {
      return false;
    }
    return this.timeToMaintenanceInMs < (this.showCountDownLimitInMinutes * 60 * 1000);
  }

  get canShowUpdateInfo(): boolean {
    if (!this.maintenance) {
      return false;
    }
    return !this.maintenance.showBeforeDateFromInMinutes ||
      this.maintenance.showBeforeDateFromInMinutes > (this.timeToMaintenanceInMs / (60 * 1000));
  }

  get isMobile(): boolean {
    return this.deviceDetectorService.deviceType === DeviceType.Mobile;
  }

  private get timeToMaintenanceInMs(): number {
    return this.maintenance?.dateFrom.getTime() - Date.now();
  }

  ngOnInit(): void {
    this.loadMaintenanceAndRedirectIfNeed();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private loadMaintenanceAndRedirectIfNeed(): void {
    this.subs.sink = this.maintenanceService.getMaintenanceFromServer().subscribe(maintenance => {
      this.maintenance = maintenance;
      if (maintenance) {
        this.checkAndPlaneToShowUpdateInfo();
        this.redirectToMaintenanceWhenNeed();
        this.changeDetector.detectChanges();
      }
    });
  }

  private checkAndPlaneToShowUpdateInfo(): void {
    const timeToShow = this.timeToMaintenanceInMs - (this.maintenance.showBeforeDateFromInMinutes * 60 * 1000);
    if (timeToShow > 0) {
      this.subs.sink = timer(timeToShow).subscribe(() => this.changeDetector.detectChanges());
    }
  }

  private redirectToMaintenanceWhenNeed(): void {
    if (this.timeToMaintenanceInMs <= 0) {
      this.redirectToMaintenance();
    } else {
      if (this.timeToMaintenanceInMs < (this.showCountDownLimitInMinutes * 60 * 1000)) {
        this.startCountDown();
      } else {
        this.subs.sink = timer(this.timeToMaintenanceInMs - (this.showCountDownLimitInMinutes * 60 * 1000))
          .pipe(take(1)).subscribe(() => this.startCountDown());
      }
      this.subs.sink = timer(this.timeToMaintenanceInMs).pipe(take(1)).subscribe(() => this.redirectToMaintenance());
    }
  }

  private redirectToMaintenance(): void {
    if (this.isVisible) {
      this.router.navigate([this.maintenanceUrl]);
    }
  }

  private startCountDown(): void {
    this.updateCountDown();
    this.subs.sink = interval(1000).subscribe(() => this.updateCountDown());
  }

  private updateCountDown(): void {
    const second = Math.floor(this.timeToMaintenanceInMs / 1000) % 60;
    this.countDown = {
      minutes: `${Math.floor(this.timeToMaintenanceInMs / (1000 * 60)) % 60}`,
      seconds: second >= 10 ? `${second}` : `0${second}`
    };
    this.changeDetector.detectChanges();
  }
}
