import { inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { BudgetItem, BudgetItemPositionType } from '@kros-sk/ssw-shared-legacy';
import { BuildingObject, BuildingObjectCreate } from '@kros-sk/ssw-budget/shared/data-access/models';
import { buildingObjectsSharedActions } from '@kros-sk/ssw-budget/shared/data-access/store';

import * as actions from './building-objects.actions';
import { BuildingObjectsState } from './building-objects.state';
import { CreatedBuildingObject } from '../models';

@Injectable({
  providedIn: 'root'
})
export class BuildingObjectsDispatchersService {

  private store = inject(Store<BuildingObjectsState>);

  loadBuildingObjects(): void {
    this.store.dispatch(buildingObjectsSharedActions.loadBuildingObjectsStart({}));
  }

  moveBuildingObjects(
    currentItem: BuildingObject,
    positionType: BudgetItemPositionType,
    item: BuildingObject,
    isUndoRedoOperation = false,
    isUndo = false,
    buildingObjects: BuildingObject[]
  ): void {
    this.store.dispatch(
      buildingObjectsSharedActions.moveBuildingObjectsStart({
        currentItem, positionType, item, buildingObjects, isUndoRedoOperation, isUndo })
    );
  }

  selectBuildingObject(buildingObject: BuildingObject): void {
    this.store.dispatch(actions.selectBuildingObject({ buildingObject }));
  }

  createEmptyBuildingObject(item: CreatedBuildingObject): void {
    this.store.dispatch(actions.createEmptyBuildingObject({ item }));
  }

  editEmptyBuildingObject(propertyName: string, value: any): void {
    this.store.dispatch(actions.editEmptyBuildingObject({ propertyName, value }));
  }

  deleteEmptyBuildingObject(): void {
    this.store.dispatch(actions.deleteEmptyBuildingObject());
  }

  createCustomBuildingObject(createData: BuildingObjectCreate, parentId: string): void {
    this.store.dispatch(buildingObjectsSharedActions.createCustomBuildingObjectStart({ createData, parentId }));
  }

  selectBuildingObjectAfterCreate(): void {
    this.store.dispatch(actions.selectBuildingObjectAfterCreate());
  }

  moveBuildingObjectLevelUp(): void {
    this.store.dispatch(actions.moveBuildingObjectLevelUp());
  }

  moveBuildingObjectLevelDown(itemBefore: BuildingObject, buildingObjects: BuildingObject[]): void {
    this.store.dispatch(actions.moveBuildingObjectLevelDown({ itemBefore, buildingObjects }));
  }

  uploadBuildingObjects(buildingObjectIds: string[]): void {
    this.store.dispatch(actions.uploadBuildingObjectsStart({ buildingObjectIds }));
  }

  loadBuildingObjectsState(): void {
    this.store.dispatch(actions.loadBuildingObjectsStateStart());
  }

  patchBuildingObjectsItemType(item: BudgetItem, buildingObjects: BuildingObject[], isUndo: boolean): void {
    this.store.dispatch(actions.patchBuildingObjectsItemTypeStart({ item, buildingObjects, isUndoRedoOperation: true, isUndo }));
  }
}
