import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { KrosModalRef } from '@kros-sk/components';

import { BuildingProgressPeriod } from '../../../building-progress/models/building-progress-period.model';
import { PeriodDatePipe } from '../../../building-progress/modules/periods/pipes/period-date.pipe';

@Component({
  selector: 'app-percentage-draw-dialog',
  templateUrl: './percentage-draw-dialog.component.html',
  styleUrls: ['./percentage-draw-dialog.component.scss']
})
export class PercentageDrawDialogComponent {
  data: { period: BuildingProgressPeriod, value: string };
  percentageDrawForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalRef: KrosModalRef,
    private periodDatePipe: PeriodDatePipe
  ) {
    this.data = this.modalRef.data;
    this.percentageDrawForm = this.formBuilder.group(
      {
        percentage: [this.data.value]
      }
    );
  }

  get periodDateText(): string {
    return this.periodDatePipe.transform(this.data.period.dateFrom, this.data.period.dateTo);
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  onSubmit(): void {
    this.modalRef.submit({
      percentage: this.percentageDrawForm.controls['percentage'].value?.toString() ?? '0'
    });
  }
}
