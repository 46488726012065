import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter, switchMap, take } from 'rxjs';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { ApplicationType } from '@kros-sk/ssw-cdk';
import { ApprovalHelper } from '@kros-sk/ssw-shared/utils/building-progress';
import { ApprovalOrderHelper } from '@kros-sk/ssw-building-progress/approval-order';
import {
  isOwnersEmail,
  ItemSharingMultiple,
  NewSharingComboMode,
  PermissionChangedDto,
  PermissionListService,
  ProjectDetail,
  ProjectSharingEdit,
  SharingActionType,
  TimelineType,
  UserSharingHelpersService,
  UserSharingService
} from '@kros-sk/ssw-shared-legacy';

import { ApplicationSharingViewModel } from '../../models';
import { BuildingProgressSettingService } from '../../services';
import { ProjectsDispatchersService, ProjectsSelectorsService } from '../../../store/projects';

@Component({
  selector: 'app-new-project-members-detail',
  templateUrl: 'new-project-members-detail.component.html',
  styleUrls: ['new-project-members-detail.component.scss']
})
export class NewProjectMembersDetailComponent implements OnInit {
  isSaving = false;
  isOwner: boolean;
  projectId: number;
  unconfirmedSharings: ItemSharingMultiple[] = [];
  projectSharings: ItemSharingMultiple[] = [];
  editItems: ProjectSharingEdit[] = [];
  loadedItems = false;
  sharingMode = NewSharingComboMode.EditUserProject;
  timelineType = TimelineType;

  get existingSharingEmails(): string[] {
    return this.projectSharings.map(s => s.email).concat(this.unconfirmedSharings.map(u => u.email));
  }

  get changesMade(): boolean {
    return this.isChangesMade;
  }

  set changesMade(value: boolean) {
    this.isChangesMade = value;
    if (value) {
      this.isSaving = false;
    }
  }

  protected isMultiStageApprovalModeEnabled = false;
  protected isSomeApprover = false;

  private isChangesMade = false;
  private projectsDispatchersService = inject(ProjectsDispatchersService);
  private projectsSelectorsService = inject(ProjectsSelectorsService);
  private sharingHelperService = inject(UserSharingHelpersService);
  private userSharingService = inject(UserSharingService);
  private appInsightsService = inject(AppInsightsBaseService);
  private multiStageApprovalSettingService = inject(BuildingProgressSettingService);
  private approvalHelper = inject(ApprovalHelper);
  private approvalOrderHelper = inject(ApprovalOrderHelper);
  private permissionListService = inject(PermissionListService);
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.projectsSelectorsService.projectDetail$
      .pipe(
        filter(Boolean),
        switchMap((project: ProjectDetail) => {
          this.projectId = project.id;
          this.isOwner = isOwnersEmail(project.owner);
          this.loadedItems = false;
          this.loadMembersContent();
          return this.multiStageApprovalSettingService.loadIsMultiStageApprovalMode(this.projectId);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((s: boolean) => this.isMultiStageApprovalModeEnabled = s);

    this.projectsSelectorsService.applicationPermissions$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(appPermissions => {
        if (appPermissions) {
          this.projectSharings = appPermissions
            .map(appPermission => ({ ...appPermission, permissions: appPermission.permissions.map(p => ({ ...p })) }));
          this.setIsSomeApprover();
          this.loadedItems = true;
          this.clearChangesAndCheckRefresh();
        }
      });
  }

  loadMembersContent(): void {
    this.projectsDispatchersService.loadProjectApplicationPermissions(this.projectId);
  }

  onDeletedPermission(permissionDto: PermissionChangedDto): void {
    this.changesMade = this.userSharingService.editUser(this.editItems, permissionDto, SharingActionType.Delete);
  }

  onUnconfirmedPermissionDeleted(sharing: ItemSharingMultiple): void {
    this.unconfirmedSharings.splice(this.unconfirmedSharings.indexOf(sharing), 1);
    this.changesMade = (!!this.editItems && this.editItems.length > 0) || this.unconfirmedSharings.length > 0;
  }

  onChangedPermission(permissionDto: PermissionChangedDto): void {
    this.changesMade = this.userSharingService.editUser(this.editItems, permissionDto, SharingActionType.Edit);
  }

  onOpenApprovalOrder(): void {
    this.appInsightsService.trackEvent('PV-approval-order-open-dialog');
    this.approvalOrderHelper.openApprovalOrderDialog(
      this.projectId,
      !this.isOwner || this.permissionListService.hasFreeOrReadOnlyLicense(ApplicationType.BuildingProgress));
  }

  onShared(newSharings: ItemSharingMultiple[]): void {
    if (newSharings?.length > 0) {
      this.unconfirmedSharings = this.unconfirmedSharings.concat(newSharings);
      this.changesMade = true;
    }
  }

  onSave(): void {
    this.isSaving = true;
    this.saveProjectSharing();
  }

  onCancel(): void {
    this.userSharingService.undoMultipleChanges(this.projectSharings, this.editItems);
    this.sharingHelperService.cancelChanges(false);
    this.clearChangesAndCheckRefresh();
  }

  private setIsSomeApprover(): void {
    this.isSomeApprover = this.projectSharings.some(p =>
      p.permissions.some(p => this.approvalHelper.isApprover(p.additionalPermissionType, p.applicationType)));
  }

  private checkIsSomeNewOrEditedApprover(): boolean {
    return this.unconfirmedSharings.some(s =>
      s.permissions.some(p => this.approvalHelper.isApprover(p.additionalPermissionType, p.applicationType))) ||
      this.editItems.some(s =>
        this.approvalHelper.isApprover(s.permissionChange.oldAdditionalPermission, s.permissionChange.applicationType) ||
        this.approvalHelper.isApprover(s.permissionChange.newAdditionalPermission, s.permissionChange.applicationType));
  }

  private saveProjectSharing(): void {
    if (this.editItems?.length > 0 || this.unconfirmedSharings?.length > 0) {
      this.editItems = this.userSharingService.filterChanges(this.editItems);
      const viewModel: ApplicationSharingViewModel = {
        projectId: this.projectId,
        permissionActions: this.userSharingService
          .createUserMultipleEditViewModels(this.editItems)
          .concat(this.userSharingService.createUserMultipleAddViewModels(this.unconfirmedSharings)),
      };

      if (this.isMultiStageApprovalModeEnabled && this.checkIsSomeNewOrEditedApprover()) {
        this.approvalHelper.showApprovalCancellationDialog().pipe(take(1)).subscribe(result => {
          if (result) {
            this.changeSharing(viewModel);
          } else {
            this.isSaving = false;
          }
        });
      } else {
        this.changeSharing(viewModel);
      }
    }
  }

  private changeSharing(viewModel: ApplicationSharingViewModel): void {
    this.userSharingService.sendAnalyticsMultipleNotification(viewModel, this.projectSharings);
    this.projectsDispatchersService.shareApplications(viewModel);
  }

  private clearChangesAndCheckRefresh(): void {
    this.editItems.splice(0, this.editItems.length);
    this.unconfirmedSharings = [];
    this.changesMade = false;
  }
}
