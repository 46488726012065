import { createEntityAdapter } from '@ngrx/entity';

import { BoqItem } from '@kros-sk/ssw-shared-legacy';

import {
  BoqItemsState, BoqPeriodItemsState, CommentPositionsState, CommentsState, NotesState, PeriodsState, SharingListState
} from './building-progress.state';
import { BoqPeriodItem } from '../../building-progress/models/boq-period-item.model';
import {
  BuildingProgressCommentModel, CommentPositionModel
} from '../../building-progress/modules/comments-panel/comments-panel/comment.model';
import { BuildingProgressPeriod } from '../../building-progress/models/building-progress-period.model';
import { BuildingProgressSharing } from '../../building-progress/models/building-progress-sharing/building-progress-sharing.model';
import { Note } from '../../building-progress/models/note.model';

export const adapterComments = createEntityAdapter<BuildingProgressCommentModel>();
export const commentsInitialState: CommentsState = adapterComments.getInitialState({});

export const adapterCommentPositions = createEntityAdapter<CommentPositionModel>({
  selectId: commentPosition => `${commentPosition.budgetItemId}_${commentPosition.periodId}_${commentPosition.periodColumn}`
});
export const commentPositionsInitialState: CommentPositionsState = adapterCommentPositions.getInitialState({});

export const adapterPeriods = createEntityAdapter<BuildingProgressPeriod>();
export const periodsInitialState: PeriodsState = adapterPeriods.getInitialState({});

export const adapterSharingList = createEntityAdapter<BuildingProgressSharing>({
  selectId: sharing => sharing.email
});
export const sharingListInitialState: SharingListState = adapterSharingList.getInitialState({});

export const adapterBoqItems = createEntityAdapter<BoqItem>();
export const boqItemsInitialState: BoqItemsState = adapterBoqItems.getInitialState({
  isLoading: false
});

export const adapterBoqPeriodItems = createEntityAdapter<BoqPeriodItem>();
export const boqPeriodItemsInitialState: BoqPeriodItemsState = adapterBoqPeriodItems.getInitialState({
  isLoading: false
});

export const adapterNotes = createEntityAdapter<Note>({
  selectId: note => note.budgetItemId
});
export const notesInitialState: NotesState = adapterNotes.getInitialState({});
