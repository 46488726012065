import { inject, Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { permissionActions } from './permission.actions';
import { PermissionApiService } from './permission-api.service';

@Injectable()
export class PermissionEffects {

  private permissionApiService = inject(PermissionApiService);
  private actions$ = inject(Actions);

  loadBuildingProgressPermission$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(permissionActions.loadBuildingProgressPermissionStart),
      switchMap(payload => this.permissionApiService.getBuildingProgressPermission(payload.projectId)),
      map((buildingProgressPermission) => permissionActions.loadBuildingProgressPermissionSuccess({ buildingProgressPermission }))
    );
  });

  loadBudgetPermission$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(permissionActions.loadBudgetPermissionStart),
      switchMap(payload => this.permissionApiService.getBudgetPermission(payload.projectId)),
      map((budgetPermission) => permissionActions.loadBudgetPermissionSuccess({ budgetPermission }))
    );
  });
}
