import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { TagInputModule } from 'ngx-chips';

import { KrosComponentsModule } from '@kros-sk/components';
import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { AvatarsModule } from '../avatars';
import { BootstrapModule } from '../bootstrap';
import { OwnerSharingComboComponent } from './components/owner-sharing-combo/owner-sharing-combo.component';
import { PermissionListService } from './services/permission-list.service';
import { PermissionsUtils } from './services/permissions-utils';
import { TimelineModule } from '../timeline';
import { TranslateModule } from '../translate';
import { UserSharingDialogBaseComponent } from './components/user-sharing-dialog-base/user-sharing-dialog-base.component';
import { UserSharingDropdownComponent } from './components/user-sharing-dropdown/user-sharing-dropdown.component';
import { UserSharingHelpersService } from './services/user-sharing-helpers.service';
import { UserSharingInputBaseComponent } from './components/user-sharing-input-base/user-sharing-input-base.component';
import { UserSharingInputComponent } from './components/user-sharing-input/user-sharing-input.component';
import { UserSharingListComponent } from './components/user-sharing-list/user-sharing-list.component';
import {
  UserSharingListItemBaseComponent
} from './components/user-sharing-list-item-base/user-sharing-list-item-base.component';
import { UserSharingListItemComponent } from './components/user-sharing-list-item/user-sharing-list-item.component';
import {
  UserSharingListItemMultipleComponent
} from './components/user-sharing-list-item-multiple/user-sharing-list-item-multiple.component';
import {
  UserSharingMultipleInputComponent
} from './components/user-sharing-multiple-input/user-sharing-multiple-input.component';
import {
  UserSharingPermissionModalComponent
} from './components/user-sharing-permission-modal/user-sharing-permission-modal.component';
import { UserSharingService } from './services/user-sharing.service';

@NgModule({
  imports: [
    CommonModule,
    BootstrapModule,
    FormsModule,
    AvatarsModule,
    KrosComponentsModule,
    TagInputModule,
    TranslateModule.forChild(),
    TimelineModule,
    TooltipDirective
  ],
  declarations: [
    UserSharingInputBaseComponent,
    UserSharingInputComponent,
    UserSharingMultipleInputComponent,
    UserSharingListComponent,
    UserSharingListItemBaseComponent,
    UserSharingListItemComponent,
    UserSharingListItemMultipleComponent,
    UserSharingDropdownComponent,
    OwnerSharingComboComponent,
    UserSharingPermissionModalComponent,
    UserSharingDialogBaseComponent
  ],
  exports: [
    UserSharingInputBaseComponent,
    UserSharingInputComponent,
    UserSharingMultipleInputComponent,
    UserSharingListComponent,
    UserSharingListItemBaseComponent,
    UserSharingListItemComponent,
    UserSharingListItemMultipleComponent,
    OwnerSharingComboComponent,
    UserSharingDialogBaseComponent
  ],
  providers: [
    UserSharingService,
    UserSharingHelpersService,
    PermissionListService,
    PermissionsUtils
  ]
})
export class UserSharingModule { }
