import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { AvatarsModule } from '../avatars';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { DirectivesModule } from '../directives';
import { DocumentExplorerComponent } from './components/document-explorer/document-explorer.component';
import { DocumentIconComponent } from './components/document-icon/document-icon.component';
import {
  DocumentService, DocumentSharingService, DocumentsPermissionsService, FileSizeService, SelectionService
} from './services';
import { DocumentsExplorerDispatchersService, DocumentsExplorerSelectorsService } from './store';
import { FileSizePipe } from './pipes/file-size.pipe';
import { TimelineModule } from '../timeline/timeline.module';
import { TranslateModule } from '../translate';
import { UploadNotificationService } from '../upload';

@NgModule({
  declarations: [
    DocumentExplorerComponent,
    DocumentIconComponent,
    FileSizePipe,
    BreadcrumbComponent
  ],
  imports: [
    CommonModule,
    CdkTableModule,
    TranslateModule.forChild(),
    AvatarsModule,
    TimelineModule,
    DirectivesModule,
    TooltipDirective
  ],
  exports: [
    DocumentExplorerComponent,
    FileSizePipe,
    BreadcrumbComponent
  ],
  providers: [
    DocumentsExplorerDispatchersService,
    DocumentsExplorerSelectorsService,
    DocumentService,
    DocumentSharingService,
    FileSizeService,
    DocumentsPermissionsService,
    SelectionService,
    UploadNotificationService
  ]
})
export class DocumentExplorerModule {
}
