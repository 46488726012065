import { Component, OnInit, ViewChild } from '@angular/core';

import { filter } from 'rxjs';
import { locale } from 'devextreme/localization';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { formatNumberValue, SearchModel } from '@kros-sk/ssw-shared-legacy';
import { KrosModalRef } from '@kros-sk/components';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import {
  BuildingProgressDispatchersService,
  BuildingProgressPartialItemsDispatchersService,
  BuildingProgressPartialItemsSelectorsService
} from '../../../store/building-progress';
import { BuildingProgressItem } from '../../../building-progress/models/construction-data.model';
import {
  BuildingProgressPartialItemTableComponent
} from '../building-progress-partial-item-table/building-progress-partial-item-table.component';
import { environment } from '../../../../environments/environment';
import { PartialBudgetItemChangedProperty } from '../../../building-progress/models/building-progress-partial-budget-items.model';
import { Subcontractor } from '../../../building-progress/modules/subcontractors/models';
import { SubcontractorsSelectorsService } from '../../../store/subcontractors';

@Component({
  selector: 'app-building-progress-partial-item-dialog',
  templateUrl: './building-progress-partial-item-dialog.component.html',
  styleUrls: ['./building-progress-partial-item-dialog.component.scss']
})
export class BuildingProgressPartialItemDialogComponent extends UnsubscribeComponent implements OnInit {

  @ViewChild('partialItemsTableRef', { static: false }) partialItemsTable: BuildingProgressPartialItemTableComponent;

  itemAmount: string;
  buildingProgressItem: BuildingProgressItem;

  private projectId: number;
  private emptySearchModel: SearchModel = {
    filter: '',
    searchText: '',
    searchMode: 'all'
  };
  private subcontractor: Subcontractor;

  private get itemAmountValue(): number {
    return this.buildingProgressItem.partialBudgetItems.length === 1
      ? this.buildingProgressItem.partialBudgetItems[0].amount
      : this.buildingProgressItem.amount;
  }

  constructor(
    private appInsightsService: AppInsightsBaseService,
    private modalRef: KrosModalRef,
    private partialItemsDispatchersService: BuildingProgressPartialItemsDispatchersService,
    private partialItemsSelectorsService: BuildingProgressPartialItemsSelectorsService,
    private buildingProgressDispatcherService: BuildingProgressDispatchersService,
    private subcontractorsSelectorService: SubcontractorsSelectorsService,
  ) {
    super();
    this.buildingProgressItem = this.modalRef.data;
  }

  ngOnInit(): void {
    this.initPartialItems();
    this.subs.sink = this.partialItemsSelectorsService.projectId$.subscribe((projectId) => {
      this.projectId = projectId;
    });
    this.subs.sink = this.subcontractorsSelectorService.selectedSubcontractor$
      .subscribe(subcontractor => this.subcontractor = subcontractor);
    this.buildingProgressDispatcherService.loadConstructionData(this.projectId,this.emptySearchModel, null, this.subcontractor?.id);
    this.subs.sink = this.partialItemsSelectorsService.decimalPlaces$.pipe(
      filter(decimalPlaces => !!decimalPlaces)
    ).subscribe(decimalPlaces => {
      this.partialItemsDispatchersService.recalculatePartialItems();
      const amount = formatNumberValue(this.itemAmountValue, decimalPlaces.amount, environment.location);
      this.itemAmount = this.buildingProgressItem.measureUnit
        ? `${amount} ${this.buildingProgressItem.measureUnit}`
        : `${amount}`;
    });
    locale('sk-SK');
  }

  onSavePartialItems(): void {
    this.partialItemsTable?.saveEditData();
    this.partialItemsDispatchersService.savePartialItems(this.buildingProgressItem.originalItemIdOfPartialItem);
    this.modalRef.submit();
    this.appInsightsService.trackEvent('PV-split-budget-items-accept-button');
  }

  onClose(): void {
    this.modalRef.cancel();
  }

  private initPartialItems(): void {
    this.partialItemsDispatchersService.initPartialItems(this.buildingProgressItem);
    this.partialItemsDispatchersService.addPartialItem({
      amount: this.itemAmountValue, percentage: 100, changedProperty: PartialBudgetItemChangedProperty.None
    });
    this.partialItemsDispatchersService.addPartialItem({
      amount: 0, percentage: 50, changedProperty: PartialBudgetItemChangedProperty.Percentage
    });
  }
}
