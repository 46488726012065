import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { CompanyPickerComponent, CreateCompanyModalComponent } from './ui';
import { CompanyService } from './data-access';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CompanyPickerComponent,
    CreateCompanyModalComponent
  ],
  providers: [
    CompanyService
  ],
  exports: [
    CompanyPickerComponent,
    CreateCompanyModalComponent
  ]
})
export class CompanyModule {
  public static forRoot(
    gatewayUrlSelector: (appConfig: any) => string
  ): ModuleWithProviders<CompanyModule> {
    return {
      ngModule: CompanyModule,
      providers: [
        { provide: 'gatewayUrlSelector', useValue: gatewayUrlSelector }
      ]
    };
  }
}
