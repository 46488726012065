import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { catchError, map, Observable, retry, throwError } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { Budget, BudgetData } from '@kros-sk/ssw-budget/shared/data-access/models';
import {
  BudgetEditItemPropertyModel,
  BudgetSettings,
  ChangedBudgetItems,
  getSearchQuery,
  ProjectDetail,
  SearchModel
} from '@kros-sk/ssw-shared-legacy';

const projectApi = '/api/projects/';
const budgetService = '/budgetService/';
const budgetApi = `${budgetService}Budget`;
const budgetItemApi = `${budgetService}BudgetItem`;

@Injectable()
export class BudgetSharedService {
  private appConfig = inject(APP_CONFIG);
  private http = inject(HttpClient);

  private readonly gatewayApi = `${this.appConfig.appUrls.titanGatewayUrl}${projectApi}`;
  private readonly projectDetailEditEndpoint = '/projectService/projects/projectDetailEdit';
  private readonly getBudgetSettingsEndpoint = `${budgetApi}/settings`;
  private readonly uiSettingsEndpoint = '/ui-settings/';
  private readonly listEditApi = `${budgetItemApi}/listEdit`;
  private readonly budgetDataEndpoint = `${budgetApi}/cachedBudgetData`;
  private readonly resetCacheEndpoint = `${budgetApi}/cacheReset`;
  private readonly budgetsEndpoint = `${budgetApi}/list`;

  editProject(project: ProjectDetail): Observable<ProjectDetail> {
    return this.http.put<ProjectDetail>(this.gatewayApi + project.id + this.projectDetailEditEndpoint, project);
  }

  getBudgetSettings(projectId: number): Observable<BudgetSettings> {
    return this.http.get<BudgetSettings>(this.gatewayApi + projectId + this.getBudgetSettingsEndpoint);
  }

  getBudgetUiSettings(projectId: number, tenantId: number, settingsName: string): Observable<{ value: any }> {
    return this.http.get<{ value: any }>(
      `${this.gatewayApi}${projectId}${budgetService}${tenantId}${this.uiSettingsEndpoint}${settingsName}`);
  }

  saveBudgetUiSettings(projectId: number, value: string, tenantId: number, settingsName: string): Observable<any> {
    return this.http.put(
      `${this.gatewayApi}${projectId}${budgetService}${tenantId}${this.uiSettingsEndpoint}${settingsName}`,
      value);
  }

  editBudgetItems(
    projectId: number,
    budgetId: number,
    items: BudgetEditItemPropertyModel[]
  ): Observable<ChangedBudgetItems> {
    return this.http.put<ChangedBudgetItems>(this.getBudgetApi(projectId, budgetId) + this.listEditApi, { items });
  }

  getBudgetData(
    projectId: number,
    budgetId: number,
    groupColumns: string[] = [],
    groupIds: string[] = [],
    canLoadAllChildren = false,
    searchModel?: SearchModel,
    selectedItemIds?: string[]
  ): Observable<BudgetData> {
    const params = {
      projectId,
      groupColumns,
      groupIds: groupIds ?? [],
      canLoadAllChildren,
      occurrenceItemIds: selectedItemIds
    };
    const searchQuery = this.getSearchQuery(searchModel);
    return this.http.post<BudgetData>(this.getBudgetApi(projectId, budgetId) + this.budgetDataEndpoint + searchQuery, params).pipe(
      catchError(error => {
        if (error.status === HttpStatusCode.InternalServerError) {
          return this.resetCachedBudgetData(projectId, budgetId).pipe(map(_ => { throw throwError(() => error); }));
        }
        return throwError(() => error);
      }),
      retry({ count: 1, delay: 1000 })
    );
  }

  resetCachedBudgetData(projectId: number, budgetId: number): Observable<void> {
    return this.http.post<void>(this.getBudgetApi(projectId, budgetId) + this.resetCacheEndpoint, {});
  }

  getBudgets(projectId: number): Observable<Budget[]> {
    return this.http.get<Budget[]>(this.gatewayApi + projectId + this.budgetsEndpoint);
  }

  private getBudgetApi(projectId: number, budgetId: number): string {
    return this.gatewayApi + projectId + '/budgets/' + budgetId;
  }

  private getSearchQuery(searchModel: SearchModel): string {
    return searchModel
      ? getSearchQuery(
        searchModel.searchMode?.toString(),
        searchModel.searchText,
        searchModel.filter,
        ['Description', 'Code', 'MeasureUnit'],
      )
      : '';
  }
}
