import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { KrosModalCloseEvent, KrosModalRef, KrosModalService, MessageTypes } from '@kros-sk/components';
import { OpenApiSubscriptionApiModel } from '@kros-sk/models';
import { TranslateService } from '@kros-sk/ssw-shared-legacy';

import { OpenApiSettingsTokenModalComponent } from './components';

@Injectable({
  providedIn: 'root'
})
export class OpenApiModalService {

  constructor(
    private krosModalService: KrosModalService,
    private translateService: TranslateService
  ) { }

  public showGeneratedToken(apiData: OpenApiSubscriptionApiModel)
    : Observable<KrosModalCloseEvent<OpenApiSubscriptionApiModel>> {
    return this.krosModalService.openCentered<OpenApiSubscriptionApiModel>(
      OpenApiSettingsTokenModalComponent,
      apiData,
      { closeOnEscClick: false, closeOnBackdropClick: false, showMobileArrowBack: false }
    ).afterClosed$;
  }

  public showResetTokenWarningModal(secretHint: string): Observable<boolean> {
    if (!secretHint) {
      return of(true);
    }
    return this.krosModalService.openModalMessageBox({
      icon: 'delete',
      caption: this.translateService.translate('SETTINGS.API.RESET_TOKEN_QUESTION'),
      message: this.translateService.translate('SETTINGS.API.RESET_TOKEN_MESSAGE'),
      messageType: MessageTypes.Danger,
      acceptButton: this.translateService.translate('SETTINGS.API.RESET'),
      cancelButton: this.translateService.translate('COMMON.CLOSE')
    }).pipe(map(modalResponse => modalResponse.data === 'accept'));
  }

  public showDeleteConfirmationModal(): Observable<KrosModalRef<string>> {
    return this.krosModalService.openModalMessageBox({
      icon: 'delete',
      caption: this.translateService.translate('SETTINGS.API.DELETE_CONNECTION_QUESTION'),
      messageType: MessageTypes.Danger,
      acceptButton: this.translateService.translate('COMMON.DELETE'),
      cancelButton: this.translateService.translate('COMMON.CLOSE')
    }, { addModalToBrowsersHistory: false }
    );
  }
}
