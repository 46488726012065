import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'kros-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent {

  @HostBinding('style.color')
  @Input() color: string;

  @HostBinding('style.background-color')
  @Input() backgroundColor: string;

  @Input() canDelete = false;

  @Output() tagDeleted = new EventEmitter();

  constructor() { }

  deleteTag(): void {
    this.tagDeleted.emit();
  }
}
