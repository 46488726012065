import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ItemDetails, ItemPeriodDetailsCreateModel, ItemPeriodDetailsEditModel } from '../models/item-period-details.model';

const detailsApi = '/api/buildingProgressService/details/';

@Injectable()
export class DetailsService {

  constructor(
    private http: HttpClient,
  ) { }

  private get detailsApi(): string {
    return environment.appUrls.titanGatewayUrl + detailsApi;
  }

  getItemPeriodDetails(detailId: number): Observable<ItemDetails> {
    return this.http.get<ItemDetails>(this.detailsApi + detailId);
  }

  editItemPeriodDetails(details: ItemPeriodDetailsEditModel): Observable<any> {
    return this.http.put(this.detailsApi + 'edit', details);
  }

  deleteItemPeriodDetails(detailId: number): Observable<number> {
    return this.http.delete<number>(this.detailsApi + 'delete/' + detailId);
  }

  createItemPeriodDetails(details: ItemPeriodDetailsCreateModel): Observable<number> {
    return this.http.post<number>(this.detailsApi + 'create', details);
  }

  getEmptyDetails(): ItemDetails {
    return { note: '' };
  }
}
