<app-building-progress-detail-toolbar
  [canComplete]="canComplete"
  [canExport]="canExport"
  [canExportTable]="canExportTable"
  [canOpenBoq]="canOpenBoq"
  [isConstructionData]="detail.isConstructionData"
  [isViewFilterActive]="detail.isViewOrSearchActive"
  [view]="detail.currentView"
  (shareBuildingProgressClick)="onShareBuildingProgress()"
  (openCommentsClick)="detail.onOpenComments()"
  (openPeriodsClick)="panelHelper.onOpenPeriods()"
  (exportToExcelClick)="exportHelper.onOpenExportToExcel(itemIdsForExportTable, detail.selectedPeriods, detail.summaryViewActive, detail.projectId)"
  (exportInvoiceDetailsClick)="exportHelper.onOpenExportInvoiceDetails(detail.constructionData)"
  (exportAmountsUniXmlClick)="exportHelper.onOpenExportAmountsUniXml(detail.constructionData)"
  (exportConfirmationProtocolClick)="exportHelper.onOpenConfirmationProtocol(detail.constructionData)"
  (exportItemDrawSheetClick)="exportHelper.onOpenExportItemDrawSheet(detail.constructionData)"
  (exportRemainingBudgetClick)="exportHelper.onOpenExportRemainingBudget(detail.constructionData)"
  (exportToAnotherProject)="exportHelper.onOpenExportToAnotherProject(detail.constructionData)"
  (percentageDrawClick)="detail.onPercentageDraw()"
  (percentageCompleteItemClick)="detail.onPercentageComplete()"
  (dashboardClick)="onDashboardClick()"
  (totalPriceSetClick)="onTotalPriceSet()"
  (openColorCodesClick)="onOpenColorCodes($event)"
  (openNotesClick)="onOpenNotes()"
  (deleteItemClick)="onDelete()"
  (openGalleryClick)="onOpenGalleryClick()"
  (openBoqClick)="onOpenBoqClick()"
  (createInvoiceClick)="invoiceHelper.createInvoiceForCompany(detail.constructionData)"
  (setSelectedItemsNotCompleted)="onSetSelectedItemsNotCompleted($event)"
></app-building-progress-detail-toolbar>
