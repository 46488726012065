import { Component, OnInit } from '@angular/core';

import { areEmailsEqual, UnsubscribeComponent } from '@kros-sk/ssw-cdk';
import {
  DocumentsPermissionsService,
  DocumentVersionDto,
  TimelineType,
  TranslateService
} from '@kros-sk/ssw-shared-legacy';
import { KrosModalRef } from '@kros-sk/components';
import { PermissionType } from '@kros-sk/ssw-shared/permission';

import { DocumentsOpenerService } from '../../services/documents-opener.service';
import { DocumentsSelectorsService } from '../../../store/documents';

@Component({
  selector: 'app-document-version-list-modal',
  templateUrl: './document-version-list-modal.component.html',
  styleUrls: ['./document-version-list-modal.component.scss']
})
export class DocumentVersionListModalComponent extends UnsubscribeComponent implements OnInit {
  documentOriginalName: string;
  documentVersionId: string;
  isConstruction: boolean;
  items: DocumentVersionDto[];
  timelineType = TimelineType;
  itemsClicked: Set<number> = new Set<number>();

  get canDeleteDocumentVersion(): boolean {
    return this.documentsPermissionsService.hasMinimumPermissionType(
      this.documentsPermissionsService.getDocumentPermissionType(this.documentOriginalName), PermissionType.Contributor);
  }

  constructor(
    private modalRef: KrosModalRef,
    private selector: DocumentsSelectorsService,
    private translateService: TranslateService,
    private documentsPermissionsService: DocumentsPermissionsService,
    private documentOpenerService: DocumentsOpenerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.documentOriginalName = this.modalRef.data.documentOriginalName;
    this.documentVersionId = this.modalRef.data.documentVersionId;
    this.isConstruction = this.modalRef.data.isConstruction;
    this.loadVersionData();
  }

  private loadVersionData(): void {
    this.subs.sink = this.selector.selectDocumentVersion$
      .subscribe(docs => this.items = docs);
  }

  onCancelClick(): void {
    this.modalRef.cancel();
  }

  getAuthor(userEmail: string): string {
    if (areEmailsEqual(this.documentsPermissionsService.userEmail, userEmail)) {
      return this.translateService.translate('DOCUMENTS.ME');
    } else if (userEmail) {
      return userEmail;
    }
  }

  hasDescription(description: string): boolean {
    return description !== '';
  }

  onClickVersion(order: number): void {
    if (!this.isClickedItems(order)) {
      this.itemsClicked.add(order);
    } else {
      this.itemsClicked.delete(order);
    }
  }

  isClickedItems(order: number): boolean {
    return this.itemsClicked.has(order);
  }

  onOpenVersion(versionId: string): void {
    this.documentOpenerService.openApplication('construction-viewer', versionId);
  }

  onDeleteVersion(versionId: string): void {
    this.modalRef.submit(versionId);
  }
}
