import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { KrosModalModule, KrosModalRef } from '@kros-sk/components';

@Component({
  selector: 'kros-selection-list-modal',
  standalone: true,
  templateUrl: './selection-list-modal.component.html',
  styleUrls: ['./selection-list-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, KrosModalModule, TranslateModule]
})
export class SelectionListModalComponent {

  items: any[];
  selectedItem: any;
  modalTitleKey: string;
  modalSubmitKey: string;

  getItemName = (item: any): string => item.name;
  isSelectedItem = (item: any, selectedItem: any): boolean => item.id === selectedItem.id;

  constructor(
    private modalRef: KrosModalRef
  ) {
    this.items = this.modalRef.data.items;
    this.selectedItem = this.modalRef.data.defaultSelectedItem ?? this.items[0];
    this.modalTitleKey = this.modalRef.data.modalTitleKey;
    this.modalSubmitKey = this.modalRef.data.modalSubmitKey;
    this.getItemName = this.modalRef.data.getItemName ?? this.getItemName;
    this.isSelectedItem = this.modalRef.data.isSelectedItem ?? this.isSelectedItem;
  }

  onSelectItem(item: any): void {
    this.selectedItem = item;
  }

  onClose(): void {
    this.modalRef.cancel();
  }

  onSubmit(): void {
    this.modalRef.submit({
      selectedItem: this.selectedItem
    });
  }
}
