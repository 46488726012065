import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { ApplicationType, License, LicenseService as LicenseCdkService, LicenseDetail } from '@kros-sk/ssw-cdk';

@Injectable()
export class LicenseService extends LicenseCdkService {

  private readonly buildingProgressLicenseEndpoint = '/api/buildingProgressLicense';
  private readonly buildingProgressLicenseTrialCreateEndpoint = '/api/buildingProgressLicenseTrialCreate';
  private readonly budgetLicenseEndpoint = '/api/budgetLicense';
  private readonly budgetLicenseTrialCreateEndpoint = '/api/budgetLicenseTrialCreate';
  private readonly quantityManagerLicenseEndpoint = '/api/quantityManagerLicense';
  private readonly quantityManagerLicenseTrialCreateEndpoint = '/api/quantityManagerLicenseTrialCreate';
  private readonly stavarioLicenseEndpoint = '/api/stavarioLicense';
  private readonly buildaryLicenseEndpoint = '/api/buildaryLicense';
  private readonly licenseNumberEndpoint = '/api/licenseNumber';
  private readonly licenseDetailEndpoint = '/api/licenseDetail';
  private readonly automaticRenewalEndpoint = '/api/isAutomaticRenewal';
  private readonly mishaLicenseEndpoint = '/api/mishaLicense';
  private readonly mishaLicenseTrialCreateEndpoint = '/api/mishaTrialCreate';
  private readonly desktopLicenseEndpoint = '/api/kros4License';

  private http = inject(HttpClient);
  private appConfig = inject(APP_CONFIG);

  constructor() {
    super();
  }

  getBuildingProgressLicense(): Observable<License> {
    return this.http.get<License>(this.appConfig.appUrls.titanGatewayUrl + this.buildingProgressLicenseEndpoint);
  }

  getBudgetLicense(): Observable<License> {
    return this.http.get<License>(this.appConfig.appUrls.titanGatewayUrl + this.budgetLicenseEndpoint);
  }

  getQuantityManagerLicense(): Observable<License> {
    return this.http.get<License>(this.appConfig.appUrls.titanGatewayUrl + this.quantityManagerLicenseEndpoint);
  }

  getConstructionDiaryLicense(product: ApplicationType): Observable<License> {
    const endpoint = product === ApplicationType.Stavario ? this.stavarioLicenseEndpoint : this.buildaryLicenseEndpoint;
    return this.http.get<License>(this.appConfig.appUrls.titanGatewayUrl + endpoint);
  }

  getDesktopLicense(): Observable<License> {
    return this.http.get<License>(this.appConfig.appUrls.titanGatewayUrl + this.desktopLicenseEndpoint);
  }

  createQuantityManagerTrialLicense(): Observable<any> {
    return this.http.post(this.appConfig.appUrls.titanGatewayUrl + this.quantityManagerLicenseTrialCreateEndpoint, {});
  }

  createBuildingProgressTrialLicense(): Observable<any> {
    return this.http.post(this.appConfig.appUrls.titanGatewayUrl + this.buildingProgressLicenseTrialCreateEndpoint, {});
  }

  createBudgetTrialLicense(): Observable<any> {
    return this.http.post(this.appConfig.appUrls.titanGatewayUrl + this.budgetLicenseTrialCreateEndpoint, {});
  }

  createMishaTrialLicense(): Observable<any> {
    return this.http.post(this.appConfig.appUrls.mishaGatewayUrl + this.mishaLicenseTrialCreateEndpoint, {});
  }

  getMishaLicense(): Observable<License> {
    return this.http.get<License>(this.appConfig.appUrls.mishaGatewayUrl + this.mishaLicenseEndpoint);
  }

  getLicenseDetail(): Observable<LicenseDetail> {
    return this.http.get<LicenseDetail>(this.appConfig.gatewayApiUrl + this.licenseDetailEndpoint, {});
  }
}
