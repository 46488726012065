import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { isValid } from 'iban';

// eslint-disable-next-line max-len
export const emailRegex = /^\w+([-_+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

// email check that only verifies if there is just one @ in the string somewhere in the middle, not first and not last character
// this is used for email validation in the backend when using FluentAssertions
export const emailRegexSimplified = /^[^@]+@[^@]+$/;

export class KrosValidators {

  static email(control: AbstractControl): ValidationErrors | null {
    if (!control.value || control.value === '') {
      return null;
    }

    if (emailRegex.test(control.value)) {
      return null;
    }

    return {
      invalidEmail: true
    };
  }

  static multipleEmails(control: AbstractControl): ValidationErrors | null {
    if (!control.value || control.value === '' || control.value.length === 0) {
      return null;
    }

    const invalidEmails = String(control.value).replace(/[\s,;]+/g, ' ').trim().split(' ').filter(s => !emailRegex.test(s));

    if (invalidEmails?.length) {
      return {
        invalidEmailsList: invalidEmails
      };
    }

    return null;
  }

  static nonEmptyOrWhiteSpace(control: AbstractControl): ValidationErrors | null {
    return KrosValidators.noWhiteSpaces(control) ?? Validators.required(control);
  }

  static noWhiteSpaces(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value && (typeof value === 'string') && (value.trim() === '')) {
      return {
        whiteSpaces: true
      };
    }
    return null;
  }

  static notGreaterThan(limitValue: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if ((typeof value === 'number') && (value <= limitValue)) {
        return {
          notGreaterThan: true
        };
      }
      return Validators.required(control);
    };
  }

  static iban(control: AbstractControl): ValidationErrors | null {
    let result = true;
    const value = control.value;
    if (value && (typeof value === 'string') && (value.trim() !== '')) {
      result = isValid(value);
    }
    return result ? null : { invalidIban: true };
  }

  static ibanOrAccountNumber(control: AbstractControl): ValidationErrors | null {
    let result = true;
    const value = control.value;
    if (value && (typeof value === 'string') && (value.trim() !== '')) {
      result = isValid(value);
      if (!result) {
        result = KrosValidators.accountNumber(control) === null;
      }
    }
    return result ? null : { invalidIbanAccountNumber: true };
  }

  static accountNumber(control: AbstractControl): ValidationErrors | null {
    const result = !control.value ||
      (control.value?.length >= 8 && !!control.value.match(/^(\d+[-]?\d+)*(\/[a-zA-ZA0-9]{0,10})?$/));
    return result ? null : { invalidAccountNumber: true };
  }

  static validUrl(control: AbstractControl): ValidationErrors | null {
    let result = true;
    if (control.value) {
      try {
        const url = new URL(control.value);
        if (url.host === '' && url.origin === '') {
          result = false;
        }
      } catch {
        result = false;
      }
    }

    return result ? null : { invalidUrl: true };
  }

  /**
   * Thanks to the international phone numbering plan (ITU-T E.164),
   * phone numbers cannot contain more than 15 digits.
   * The shortest international phone numbers in use contain 7 digits.
   * @param control Abstract control.
   * @returns Validation error `invalidPhoneNumber` or `null` if control passes validation.
   */
  static internationalPhoneNumber(control: AbstractControl): ValidationErrors | null {
    const invalidCharsRegex = /[^0-9\/\-\+ ]/g;
    const toleratedChars = /[\-\/ ]/g;
    const e164Regex = /^\+\d{7,15}$/;

    if (!control.value || control.value === '') {
      return null;
    }

    if (invalidCharsRegex.test(control.value)) {
      return {
        invalidPhoneNumber: true
      };
    }

    // tolerates formatted numbers such as +421 41/707 10 10, +421 903 123 456, +1 707-123-4567
    const normalizedValue = (control.value as string).replace(toleratedChars, '');
    if (e164Regex.test(normalizedValue)) {
      return null;
    }

    return {
      invalidPhoneNumber: true
    };
  }

  /**
   * Validates file input value
   * @param minFiles minimum count of files
   * @param maxFiles maximum count of files
   * @returns validation error 'requiredFiles' containing requiredCounts
   */
  static requiredFiles(minFiles: number, maxFiles?: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      const fileCount = control.value?.length || 0;
      if (fileCount < minFiles || (maxFiles && fileCount > maxFiles)) {
        return {
          requiredFiles: { minFiles, maxFiles, fileCount }
        };
      }
      return null;
    };
  }

  static optionalValidators(validators: ValidatorFn[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const errors = validators.map(validator => validator(control));

      let res: {[key: string]: any} = {};

      // merge all validation errors
      errors.forEach((errors: ValidationErrors | null) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        res = errors != null ? {...res!, ...errors} : res!;
      });
      return Object.keys(res).length === 0 ? null : { ...res, optional: true };
    };
  }

  static optionalValidator(validator: ValidatorFn): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const errors = validator(control);
      return !errors ? null : { ...errors, optional: true};
    };
  }
}
