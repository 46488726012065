import { ModuleWithProviders, NgModule } from '@angular/core';

import { StatisticsService } from './statistics.service';

@NgModule({
  providers: [
    StatisticsService
  ]
})
export class StatisticsModule {
  public static forRoot(
    gatewayUrlSelector: (appConfig: any) => string
  ): ModuleWithProviders<StatisticsModule> {
    return {
      ngModule: StatisticsModule,
      providers: [
        { provide: 'gatewayUrlSelector', useValue: gatewayUrlSelector }
      ]
    };
  }
}
