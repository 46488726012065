<div class="kros-modal-header">
  <button
    class="btn btn-link close"
    tabindex="-1"
    (click)="onCancel()"
    data-test="non-paid-package-modal-header-close-button"
  ><i class="material-icons secondary">close</i>
  </button>
</div>

<div
  class="kros-modal-body"
  data-test="non-paid-package-modal-body"
>
  <div
    *ngIf="contentHelper.picture"
    class="img-package"
  >
    <img
      [src]="'shared-assets/images/' + contentHelper.picture + '.svg'"
      alt="not-paid-package-inf"
    >

    <div
      *ngIf="contentHelper.pictureLabel?.length"
      class="label-text"
    >{{ contentHelper.pictureLabel }}</div>
  </div>

  <h4 
    *ngIf="contentHelper.heading?.length"
    data-test="non-paid-package-modal-body-heading"
  >{{ contentHelper.heading }}</h4>

  <p
    class="h5"
    data-test="non-paid-package-modal-body-infoText"
    [innerHTML]="contentHelper.infoText | safeHtml"
  ></p>
</div>

<div class="kros-modal-footer">
  <a
    *ngIf="contentHelper.showOrderButton"
    class="btn btn-primary btn-submit"
    (click)="onOpenOrderUrl()"
    data-test="non-paid-package-modal-footer-cancel-button"
  >{{ contentHelper.orderButtonText | translate }}</a>

  <button
    *ngIf="contentHelper.showTrialButton"
    class="btn btn-primary btn-submit ga-license-activate-trial"
    type="button"
    (click)="onSubmit()"
    data-test="non-paid-package-modal-footer-submit-button"
  >{{ 'LICENSE_INFO.TRY_TRIAL' | translate }}</button>
</div>

<span
  *ngIf="contentHelper.footerText?.length"
  class="footer-text"
>{{ contentHelper.footerText }}</span>
