import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';

import { APP_CONFIG } from '@kros-sk/app-config';
import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import {
  ApplicationType,
  formatString,
  License,
  LicenseType
} from '@kros-sk/ssw-cdk';
import {
  ButtonConfig,
  LicenseUrlService,
  MarketingModalComponent,
  NonPaidPackageConfig,
  PluralisationByCountPipe,
  TranslateService
} from '@kros-sk/ssw-shared-legacy';
import { KrosModalService, SmsActivationEndpoints, SmsActivationModalComponent } from '@kros-sk/components';

import { ConstructionDiaryDispatchersService, ConstructionDiarySelectorsService } from '../data-access';

@Injectable()
export class ConstructionDiaryLicenseHelper {

  private translateService = inject(TranslateService);
  private krosModalService = inject(KrosModalService);
  private pluralisationByCountPipe = inject(PluralisationByCountPipe);
  private appConfig = inject(APP_CONFIG);
  private appInsightsService = inject(AppInsightsBaseService);
  private selectors = inject(ConstructionDiarySelectorsService);
  private dispatchers = inject(ConstructionDiaryDispatchersService);
  private urlHelper = inject(LicenseUrlService);

  get isReadonlyLicense(): boolean {
    return this.license?.licenseType === LicenseType.Readonly;
  }

  get isFullLicense(): boolean {
    return this.license?.licenseType === LicenseType.Full;
  }

  get isTrialLicense(): boolean {
    return this.license?.licenseType === LicenseType.Trial;
  }

  get isFreeLicense(): boolean {
    return this.license?.licenseType === LicenseType.Free;
  }

  get isTrialOrFullLicense(): boolean {
    return this.isTrialLicense || this.isFullLicense;
  }

  get isFreeOrReadonlyLicense(): boolean {
    return this.isFreeLicense || this.isReadonlyLicense;
  }

  private get productName(): string {
    if (this.product) {
      return ApplicationType[this.product];
    } else {
      return '';
    }
  }

  private product: ApplicationType;
  private license: License;

  constructor() {
    combineLatest([this.selectors.product$, this.selectors.license$])
      .pipe(takeUntilDestroyed())
      .subscribe(([product, license]) => {
        this.product = product;
        this.license = license;
      });
  }

  openNotAccessDialog(): void {
    this.krosModalService.openCentered<NonPaidPackageConfig>(
      MarketingModalComponent,
      {
        appPrefix: this.productName.toUpperCase(),
        canActivateTrial: this.license.canActivateTrial,
        trialButton: this.getTrialButton(),
        trialDuration: this.license.trialDuration,
        orderUrl: this.urlHelper.orderConstructionDiaryUrl(this.product, this.license),
        isLicenseFree: this.isFreeLicense,
        isLicenseReadonly: this.isReadonlyLicense,
        isLicenseTrial: this.isTrialLicense,
        isLicenseFull: this.isFullLicense
      },
      { allowFocusAutocapture: false }
    );
  }

  private activateTrialLicenseWithSms(): void {
    this.krosModalService.openCentered(
      SmsActivationModalComponent,
      {
        appInsightsPrefix: `${this.productName.toUpperCase()}-`,
        trialDuration: this.license.trialDuration,
        applicationNameKey: `CONSTRUCTION_DIARY.${this.productName.toUpperCase()}`,
        endpoints: {
          tryGetPhoneNumber: `${this.appConfig.gatewayApiUrl}/api/trialLicensePhoneNumber`,
          checkPhoneNumberAvailability: `${this.appConfig.gatewayApiUrl}/api/${this.productName.toLowerCase()}TrialValidate`,
          sendCode: `${this.appConfig.gatewayApiUrl}/api/${this.productName.toLowerCase()}TrialActivate`
        } as SmsActivationEndpoints
      },
      {},
      NaN,
      NaN,
      undefined,
      'small-modal-column-footer'
    ).afterClosed$.pipe(take(1)).subscribe(result => {
      if (result.type === 'submit') {
        this.appInsightsService.trackEvent(`${this.productName.toUpperCase()}-license-activate-trial`);
        this.dispatchers.loadConstructionDiaryLicense(this.product);
      }
    });
  }

  private getTrialButton(): ButtonConfig {
    return {
      label: this.translateTrialText('LICENSE_INFO.TRY_FOR_X_DAYS'),
      action: (): void => this.activateTrialLicenseWithSms()
    };
  }

  private translateTrialText(word: string): string {
    return formatString(
      this.translateService.translate(word),
      '' + this.license.trialDuration,
      this.pluralisationByCountPipe.transform(this.license.trialDuration, 'LICENSE_INFO.DAY'));
  }
}
