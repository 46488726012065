import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { SearchInputComponent } from './search-input.component';
import { TranslateModule } from '../translate';

@NgModule({
  declarations: [
    SearchInputComponent
  ],
  imports: [
    CommonModule,
    NgbDropdownModule,
    TooltipDirective,
    ReactiveFormsModule,
    TranslateModule.forChild()
  ],
  exports: [
    SearchInputComponent
  ]
})
export class SearchInputModule { }
