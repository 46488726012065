import { Directive, HostListener, inject, Input } from '@angular/core';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

import { AnalyticsService } from '../analytics-service/analytics.service';

@Directive({
  selector: '[krosAnalytic]'
})
export class AnalyticsButtonDirective {

  private analyticService = inject(AnalyticsService);
  private appInsightsService = inject(AppInsightsBaseService);

  @Input() eventCategory: string;
  @Input() eventAction: string;
  @Input() eventLabel: string;
  @Input() eventData: string;
  @Input() analyticDbClick = false;

  @HostListener('click')
  onClick(): void {
    if (!this.analyticDbClick) {
      this.appInsightsService.trackEvent(this.eventCategory + this.eventAction, this.eventLabel ? { label: this.eventLabel } : {});
      this.analyticService.raiseEvent(this.eventCategory, this.eventAction, this.eventLabel);
    }
  }

  @HostListener('dblclick')
  onDoubleClick(): void {
    this.appInsightsService.trackEvent(this.eventCategory + this.eventAction, this.eventLabel ? { label: this.eventLabel } : {});
    this.analyticService.raiseEvent(this.eventAction, this.eventAction, this.eventLabel);
  }
}
