import { Pipe, PipeTransform } from '@angular/core';

import { stringFormat } from '../pipe-functions';

@Pipe({
  name: 'stringFormat'
})
export class StringFormatPipe implements PipeTransform {

  constructor() { }

  transform(str: string, val: any | any[]): string {
    if (val instanceof Array) {
      return stringFormat(str, ...val);
    }

    return stringFormat(str, val);
  }
}
