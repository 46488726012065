<span
  *ngIf="canShowChart"
  krosTooltip="{{tooltip}}"
>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    [attr.height]="size"
    [attr.width]="size"
    viewBox="0 0 20 20"
  >
    <circle
      r="10"
      cx="10"
      cy="10"
      [ngClass]="'chart-background'"
    />
    <circle
      r="5"
      cx="10"
      cy="10"
      fill="transparent"
      class="completed-value"
      [ngClass]="completedValueClass"
      [attr.stroke-dasharray]="completedRange"
      stroke-width="10"
      transform="rotate(-90) translate(-20)"
    />
  </svg>
</span>
