<form
  class="project-dialog-form"
  [formGroup]="editForm"
  (ngSubmit)="onSubmit()"
>
  <div class="kros-modal-header">
    <h4 class="kros-modal-title">{{'PROJECTS.ZOZNAM.NEW_PROJECT' | translate}}</h4>

    <button
      class="btn btn-link close"
      type="button"
      tabindex="-1"
      (click)="onCancel()"
    >
      <i class="material-icons secondary">close</i>
    </button>
  </div>

  <div class="kros-modal-body">
    <ng-container *ngrxLet="{
      isMobile: isMobile$
    } as vm">
      @if(vm.isMobile) {
      <dx-accordion
        #accordion
        [collapsible]="true"
        [multiple]="false"
        [animationDuration]="300"
        id="accordion-container"
      >
        <dxi-item title="{{'PROJECTS.DETAIL.BASIC_INFO' | translate}}">
          <div *ngTemplateOutlet="basicInfo; context: { form: editForm }"></div>
        </dxi-item>

        <dxi-item title="{{'PROJECTS.DETAIL.PROJECT_PARTICIPANTS' | translate}}">
          <div *ngTemplateOutlet="participants; context: { form: editForm }"></div>
        </dxi-item>

        <dxi-item title="{{'PROJECTS.DETAIL.CONTRACT_AND_DATES' | translate}}">
          <div *ngTemplateOutlet="contractsAndDates; context: { form: editForm }"></div>
        </dxi-item>

        <dxi-item title="{{'PROJECTS.DETAIL.CONNECTIONS' | translate}}">
          <div *ngTemplateOutlet="connections; context: { form: editForm }"></div>
        </dxi-item>
      </dx-accordion>
      }
      @else {
      <dx-tab-panel
        [tabsPosition]="'left'"
        [animationEnabled]="false"
        [swipeEnabled]="false"
        width="100%"
        height="40rem"
      >
        <dxi-item title="{{'PROJECTS.DETAIL.BASIC_INFO' | translate}}">
          <div *ngTemplateOutlet="basicInfo; context: { form: editForm }"></div>
        </dxi-item>

        <dxi-item title="{{'PROJECTS.DETAIL.PROJECT_PARTICIPANTS' | translate}}">
          <div *ngTemplateOutlet="participants; context: { form: editForm }"></div>
        </dxi-item>

        <dxi-item title="{{'PROJECTS.DETAIL.CONTRACT_AND_DATES' | translate}}">
          <div *ngTemplateOutlet="contractsAndDates; context: { form: editForm }"></div>
        </dxi-item>

        <dxi-item title="{{'PROJECTS.DETAIL.CONNECTIONS' | translate}}">
          <div *ngTemplateOutlet="connections; context: { form: editForm }"></div>
        </dxi-item>
      </dx-tab-panel>
      }
    </ng-container>
  </div>

  <div class="kros-modal-footer">
    @if (isFormDirty) {
    <button
      class="btn btn-cancel btn-outline-secondary"
      type="button"
      (click)="onCancel()"
    >{{'COMMON.ZRUSIT' | translate}}</button>
    }

    <button
      class="btn btn-submit btn-primary"
      data-test="project-create-button"
      type="submit"
      [disabled]="!editForm.valid"
      krosAnalytic
      eventCategory="projekt"
      eventAction="vytvorit-projekt"
      eventLabel="{{name.value}}"
      (click)="onSubmit()"
    >{{'COMMON.ULOZIT' | translate}}</button>
  </div>
</form>


<ng-template
  #basicInfo
  let-formGroup="form"
>
  <div [formGroup]="formGroup">
    <div class="form-group">
      <label
        for="description"
        class="caption"
      >{{'PROJECTS.DETAIL.INFO.NAZOV' | translate}}</label>

      <input
        #nameInput
        id="description"
        class="form-control"
        data-test="description-input"
        [class.is-invalid]="name.invalid && name.touched"
        type="text"
        formControlName="name"
        maxlength="150"
        (focus)="$event.target.select()"
        required
        ngbAutoFocus
      >

      <div
        *ngIf="name.errors?.required"
        class="invalid-feedback"
      >
        {{ 'COMMON.POVINNE' | translate }}
      </div>
    </div>

    <div class="form-group place">
      <label
        for="place"
        class="caption"
      >{{'PROJECTS.DETAIL.INFO.ADRESA' | translate}}</label>

      <input
        id="place"
        class="form-control"
        data-test="place-input"
        type="text"
        formControlName="place"
        maxlength="50"
      >
    </div>
  </div>
</ng-template>

<ng-template
  #participants
  let-formGroup="form"
>
  <kros-project-header-data-panel
    [parentForm]="formGroup"
    [showPlace]="false"
  >
  </kros-project-header-data-panel>
</ng-template>

<ng-template
  #contractsAndDates
  let-formGroup="form"
>
  <div [formGroup]="formGroup">
    <div class="contracts">
      <div class="form-group">
        <label
          for="contractNumber"
          class="caption"
        >{{'PROJECTS.DETAIL.INFO.CONTRACT_NUMBER' | translate}}</label>

        <input
          id="contractNumber"
          class="form-control"
          data-test="contract-number-input"
          type="text"
          formControlName="contractNumber"
          maxlength="100"
        >
      </div>

      <div class="form-group">
        <kros-datepicker-input
          data-test="contracts-datepicker-contract-date"
          [options]="{
                      formControl: formGroup.get('contractDate'),
                      name: 'contractDate',
                      label: ('PROJECTS.DETAIL.INFO.CONTRACT_DATE' | translate),
                      icon: 'date_range',
                      iconPosition: 'right',
                      noTodayButton: true,
                      noClearButton: true
                  }"
        ></kros-datepicker-input>
      </div>
    </div>

    <div class="contracts">
      <div class="form-group">
        <kros-datepicker-input
          data-test="contracts-datepicker-date-started"
          [options]="{
                      formControl: formGroup.get('dateStarted'),
                      name: 'dateStarted',
                      label: ('PROJECTS.DETAIL.INFO.DATE_START' | translate),
                      icon: 'date_range',
                      iconPosition: 'right',
                      noTodayButton: true,
                      noClearButton: true
                  }"
        ></kros-datepicker-input>
      </div>

      <div class="form-group">
        <kros-datepicker-input
          data-test="contracts-datepicker-date-ended"
          [options]="{
                    formControl: formGroup.get('dateEnded'),
                    name: 'dateEnded',
                    label: ('PROJECTS.DETAIL.INFO.DATE_END' | translate),
                    icon: 'date_range',
                    iconPosition: 'right',
                    noTodayButton: true,
                    noClearButton: true
                }"
        ></kros-datepicker-input>
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #connections
  let-formGroup="form"
>
  <div
    class="api-info"
    [formGroup]="formGroup"
  >
    <div class="form-group">
      <label
        for="apiCode"
        class="caption"
      >{{'PROJECTS.DETAIL.INFO.API_CODE' | translate}}</label>

      <input
        id="apiCode"
        class="form-control"
        [class.is-invalid]="apiCode.invalid"
        data-test="apicode-input"
        type="text"
        maxlength="30"
        formControlName="apiCode"
      >

      <div
        *ngIf="apiCode.errors?.pattern"
        class="invalid-feedback"
      >
        {{ 'PROJECTS.DETAIL.INFO.API_CODE_INVALID' | translate }}
      </div>
    </div>

    <div class="tablet-hidden">
      <i
        class="material-icons tooltip-icon"
        krosTooltip="{{'PROJECTS.DETAIL.INFO.API_CODE_TOOLTIP' | translate}}"
      >info</i>
    </div>
  </div>
</ng-template>
