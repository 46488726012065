import { Component } from '@angular/core';
import { EventEmitter, Input, OnInit, Output } from '@angular/core';

import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { BudgetApprovalSelectorsService } from '../../../store/budget-approval';
import { BuildingProgressActionAccessService } from '../../../building-progress/services/building-progress-action-access.service';

@Component({
  selector: 'app-budget-approval-toolbar',
  templateUrl: './budget-approval-toolbar.component.html',
  styleUrls: ['./budget-approval-toolbar.component.scss']
})
export class BudgetApprovalToolbarComponent extends UnsubscribeComponent implements OnInit {
  @Input() isBudgetApprovalData: boolean;
  @Input() haveDifferences: boolean;

  @Output() exportClick = new EventEmitter<void>();
  @Output() deleteClick = new EventEmitter<void>();
  @Output() showChangeSheetItemsClick = new EventEmitter<void>();
  @Output() approveClick = new EventEmitter<void>();
  @Output() cancelApprovedClick = new EventEmitter<void>();
  @Output() showDifferencesClick = new EventEmitter<void>();

  multiSelectCount = 0;

  get isMultiSelect(): boolean {
    return this.multiSelectCount > 0;
  }

  constructor(
    private buildingProgressActionAccessService: BuildingProgressActionAccessService,
    private selectorService: BudgetApprovalSelectorsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.selectorService.selectedItemsIds$.subscribe(ids => {
      this.multiSelectCount = !!ids && ids instanceof Set ? ids.size : 0;
    });
  }

  export(): void {
    if (this.buildingProgressActionAccessService.checkForAccessToExport(true)) {
      this.exportClick.emit();
    }
  }

  delete(): void {
    this.deleteClick.emit();
  }

  showChangeSheetItems(): void {
    this.showChangeSheetItemsClick.emit();
  }

  onApprove(): void {
    this.approveClick.emit();
  }

  onCancelApprovedClick(): void {
    this.cancelApprovedClick.emit();
  }

  showDifferences(): void {
    this.showDifferencesClick.emit();
  }
}
