<ng-container *ngIf="(authSelectorsService.featureFlags$ | async).apiSettings && vm$ | async as vm ">
  <div class="card">
    <h4>{{ 'SETTINGS.API.TITLE' | translate }}</h4>

    <div [innerHtml]="'SETTINGS.API.DESCRIPTION' | translate"></div>

    <div>
      <button
        class="btn btn-link"
        type="button"
        [disabled]="vm.routedId === 'create'"
        (click)="setCreateMode()"
      >
        <i class="material-icons">add</i>
        {{ 'SETTINGS.API.CREATE_API_CONNECTION' | translate }}
      </button>
    </div>
  </div>

  <ng-container *ngIf="!vm.inProgress; else loadingSkeleton">
    <ssw-open-api-settings-edit
      *ngIf="vm.routedId === 'create'"
      [subscription]="emptySubscription"
    >
    </ssw-open-api-settings-edit>

    <ng-container *ngFor="let item of vm.subscriptions; trackBy: trackBy">
      <ssw-open-api-settings-list-item
        *ngIf="item.clientId !== vm.routedId"
        [subscription]="item"
      >
      </ssw-open-api-settings-list-item>

      <ssw-open-api-settings-edit
        *ngIf="item.clientId === vm.routedId"
        [subscription]="item"
      >
      </ssw-open-api-settings-edit>
    </ng-container>

  </ng-container>
</ng-container>

<ng-template #loadingSkeleton>
  <ssw-open-api-list-item-skeleton></ssw-open-api-list-item-skeleton>
</ng-template>
