import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[krosAutoselect]'
})
export class AutoselectDirective implements AfterViewInit {

  constructor(private elementRef: ElementRef<HTMLInputElement>) { }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.select();
  }

}
