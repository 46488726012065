import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  setItemToLocalStorage(key: string, item: string): void {
    localStorage.setItem(key, item);
  }

  getItemFromLocalStorage(key: string): string {
    return localStorage.getItem(key);
  }

  removeItemFromLocalStorage(key: string): void {
    localStorage.removeItem(key);
  }

  setItemToSessionStorage(key: string, item: string): void {
    sessionStorage.setItem(key, item);
  }

  getItemFromSessionStorage(key: string): string {
    return sessionStorage.getItem(key);
  }

  removeItemFromSessionStorage(key: string): void {
    sessionStorage.removeItem(key);
  }

  clearStorages(): void {
    localStorage.clear();
    sessionStorage.clear();
  }

  clearSessionStorage(): void {
    sessionStorage.clear();
  }
}
