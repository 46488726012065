import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { BehaviorSubject, delay, filter, map, Observable, switchMap, take, tap } from 'rxjs';
import { DxTreeListComponent } from 'devextreme-angular/ui/tree-list';
import { Node } from 'devextreme/ui/tree_list';

import { AnimateCells } from '../models';
import { descriptionFieldName, IHasChildren, IItemType, IParentId, isItem, isSection } from '../../budget/helpers';
import { emptyKey } from '../helpers/table.helper';
import { TableBaseComponent } from './table-base.component';

@Component({ template: '' })
export abstract class TreeComponent<TId extends number | string, T extends IParentId<TId> & IItemType> extends TableBaseComponent<TId, T> {
  expandedRowKeys: TId[] = [];
  collapsedRowKeys: TId[] = [];
  onReorder = this.reorder.bind(this);
  onDragChange = this.dragChange.bind(this);
  onDragStart = this.dragStart.bind(this);
  onDragEnd = this.dragEnd.bind(this);
  movingItems: T[] = [];

  protected abstract get dataComponent(): DxTreeListComponent;
  protected canLoadOnExpand = true;

  private toExpand: TId[] = [];
  private toSelect: TId[] = [];
  private isDragInProgress = false;

  getAllNodes(): T[] {
    const rootNode = this.dataComponent.instance.getRootNode();
    return this.getWithChildren(rootNode.key);
  }

  getHierarchyItems(id: TId): T[] {
    const node = this.dataComponent.instance.getNodeByKey(id);

    return [...this.getParents(node), ...this.getWithChildren(id)].filter(n => !!n && n?.level !== 0);
  }

  getWithChildren(id: TId): T[] {
    const node = this.dataComponent.instance.getNodeByKey(id).data;
    const children = this.getAllChildren(id);
    children.push(node);

    return children.filter(ch => !!ch);
  }

  reorder(e: any): void {
    this.isDragInProgress = true;
    e.component.beginCustomLoading();
    const visibleRows = e.component.getVisibleRows();
    if (visibleRows?.length) {
      const toIndex = e.fromIndex > e.toIndex && !e.dropInsideItem ? e.toIndex - 1 : e.toIndex;
      const targetData: IParentId<TId> & IHasChildren = toIndex >= 0 &&
        visibleRows[toIndex] !== undefined ? visibleRows[toIndex].node.data : null;
      const insertInside = e.dropInsideItem || (targetData && e.component.isRowExpanded(targetData.id) && targetData.hasChildren);
      if (visibleRows[toIndex].node) {
        if (this.movingItems.length > 1) {
          this.dispatchMoveItem(visibleRows[toIndex].node.data, this.movingItems, insertInside);
        } else {
          this.dispatchMoveItem(visibleRows[toIndex].node.data, [e.itemData], isSection(e.itemData) ? e.dropInsideItem : insertInside);
        }
      }
    }
  }

  dragChange(e: any): void {
    const toIndex = e.fromIndex > e.toIndex && !e.dropInsideItem ? e.toIndex - 1 : e.toIndex;
    let targetNode = e.component.getVisibleRows()[toIndex]?.node;

    if (this.isDragInProgress || !targetNode || this.cancelDragging(e, targetNode)) {
      e.cancel = true;
      return;
    }
    if (this.movingItems.length < 2) {
      while (targetNode && targetNode.data) {
        if (targetNode.data.id === e.component.getNodeByKey(e.itemData.id)?.data.id) {
          e.cancel = true;
          break;
        }
        targetNode = targetNode.parent;
      }
    }
  }

  onContentReady(e: any): void {
    super.onContentReady(e);

    if (this.toExpand.length && this.dataSource?.findIndex(i => i.id === this.toExpand[0]) > -1) {
      this.expandRows(this.toExpand);
      this.toExpand = [];
    }

    if (this.toSelect.length && this.dataSource?.findIndex(i => i.id === this.toSelect[0]) > -1) {
      this.selectRows(this.toSelect);
      this.toSelect = [];
    }
  }

  onRowCollapsed(e: any): void {
    this.collapsedRowKeys.push(e.key);
  }

  getItemBeforeSelected(): Observable<T> {
    return this.navigateToRow(this.dataComponent?.instance.getNodeByKey(this.selectedItemId).data).pipe(
      take(1),
      map((i) => this.getItemBeforeSelectedCore(i)));
  }

  getParentIds(itemId: TId, canAddRootKey = true): TId[] {
    const ret = [];
    let node = this.dataComponent?.instance.getNodeByKey(itemId);
    if (node) {
      ret.push(node.key);
    }

    while (node?.parent && (canAddRootKey || node.parent.key)) {
      ret.push(node.parent.key);
      node = node.parent;
    }

    return ret;
  }

  protected expandRows(ids: TId[]): void {
    if (!this.dataComponent?.instance || !this.dataSource || (ids.length && this.dataSource.findIndex(i => i.id === ids[0]) === -1)) {
      this.toExpand = [...new Set([...this.toExpand, ...ids])];
    } else {
      this.expandRowsCore(ids).pipe(filter(i => i), take(1)).subscribe();
    }
  }

  protected selectRows(ids: TId[]): void {
    if (!this.dataComponent?.instance || !this.dataSource || (ids.length && this.dataSource.findIndex(i => i.id === ids[0]) === -1)) {
      this.toSelect = [...new Set([...this.toSelect, ...ids])];
    } else {
      this.dataComponent.instance.selectRows(ids, false);
    }
  }

  protected collapseAll(): void {
    this.dataComponent.instance.option('expandedRowKeys', []);
    this.dataComponent.instance.option('autoExpandAll', false);
  }

  protected itemMoved(item: any): void {
    if (item) {
      this.select(item, true);
      this.expandRows([item.parentId]);
    }
    this.isDragInProgress = false;
    this.dataComponent.instance.endCustomLoading();
  }

  protected dragStart(e: any): void {
    if (this.checkLicense() && !this.isDragInProgress) {
      const selectedItems = e.component.getSelectedRowsData().filter(row => isItem(row));
      if (selectedItems.length > 1) {
        this.movingItems = selectedItems;
        const selectedRowElements = this.getSelectedRowElements(selectedItems.map(item => item.id));
        e.component._selectedRowElements = selectedRowElements;
        Promise.resolve().then(() => {
          const draggingTableBody = document.querySelector('.dx-sortable-clone .dx-treelist-table-fixed tbody');
          draggingTableBody.innerHTML = '';
          selectedRowElements.forEach((rowElement: Element, index: number) => {
            const clonedRowElement: Element = rowElement.cloneNode(true) as Element;
            this.setClonedElements(clonedRowElement, selectedItems[index]);
            clonedRowElement.classList.add('dx-state-hover', 'dx-row-focused');
            clonedRowElement.classList.remove('dx-sortable-source');
            draggingTableBody.appendChild(clonedRowElement);
            rowElement.classList.add('dx-sortable-source');
          });
        });
      } else {
        if (this.selectedItemId !== e.itemData.id) {
          this.select(e.itemData, true);
        }
        this.movingItems = [];
      }
    } else {
      e.cancel = true;
    }
  }

  protected dragEnd(e: any): void {
    if (this.movingItems.length > 1) {
      e.component._selectedRowElements.forEach((rowElement: Element) => rowElement.classList.remove('dx-sortable-source'));
    }
  }

  protected focusAndAnimateCells(animateCells: AnimateCells<TId>, timeout = 200): void {
    if (this.dataSource) {
      this.autoNavigateToFocusedRow = false;
      const firstId = animateCells.cells[0].id;
      const parentIds = this.findParentIds(this.dataSource.filter(p => animateCells.cells.map(c => c.id).includes(p.id)));
      let canScrollToCenter = !!this.dataComponent.instance.getNodeByKey(firstId) &&
        this.dataComponent.instance.getRowIndexByKey(firstId) === -1;
      let item: T;
      let canSelect = true;

      this.expandRowsCore(parentIds)
        .pipe(
          tap(i => canSelect = canSelect && i),
          filter(i => i),
          delay(timeout),
          map(() => this.dataSource.find(p => p.id === firstId)),
          filter(i => !!i),
          tap((i) => {
            this.autoNavigateToFocusedRow = true;
            const rowIndex = this.dataComponent.instance.getRowIndexByKey(firstId);
            canScrollToCenter = canScrollToCenter || rowIndex === -1;
            item = i;
            this.dispatchActionSelectItem(item);
          }),
          delay(1),
          switchMap(() => this.navigateToRow(item, true, canScrollToCenter)),
          takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          const propertyName = animateCells.cells[0].propertyName;
          const columnIndex = this.dataComponent?.instance.getVisibleColumns()
            .findIndex(p => p.dataField === (propertyName === 'all' ? descriptionFieldName : propertyName));
          if (canSelect) {
            this.selectRow(item.id, columnIndex);
          }
          this.animateCells(animateCells.cells);
        });
    }
  }

  protected setClonedElements(clonedRowElement: Element, selectedItem: any): void { }

  protected getNewIdAfterDelete(selectedId: TId, ignoredIds: TId[] = []): TId {
    const node = this.dataComponent.instance.getNodeByKey(selectedId);
    if (node === undefined) {
      return emptyKey;
    }
    if (node.key === emptyKey && node.hasChildren) {
      return node.children[0].key;
    }
    let newId = this.getNextId(node.parent, selectedId, ignoredIds);
    if (!newId) {
      newId = this.getPreviousId(node.parent, selectedId, ignoredIds);
    }
    return newId ? newId : this.dataComponent.instance.getRootNode().children[0]?.key;
  }

  private getItemBeforeSelectedCore(selectedRowIndex: number): T {
    const itemBeforeId = this.dataComponent?.instance.getKeyByRowIndex(selectedRowIndex - 1);
    const selectedItemParent = this.dataSource.find(p => p.id === this.selectedItemId).parentId;
    return this.getSiblingBefore(selectedItemParent, itemBeforeId);
  }

  private expandRowsCore(ids: TId[]): Observable<boolean> {
    this.canLoadOnExpand = false;
    const subject = new BehaviorSubject<boolean>(false);
    const expandIds = ids.filter(i => !this.dataComponent?.instance.isRowExpanded(i));
    expandIds.forEach(id => {
      setTimeout(() => this.dataComponent.instance.expandRow(id).then(() => {
        this.canLoadOnExpand = true;
        subject.next(true);
      }));
    });
    if (expandIds.length === 0) {
      this.canLoadOnExpand = true;
      subject.next(true);
    }
    return subject;
  }

  private getNextId(node: Node<any, any>, key: TId, ignoredIds: TId[] = []): TId {
    if (node) {
      const index = node.children.findIndex(i => i.key === key);
      if (index < node.children.length - 1) {
        const nextKey = node.children[index + 1].key;
        return !ignoredIds.some(i => i === nextKey) ? nextKey : this.getNextId(node, nextKey, ignoredIds);
      } else {
        return this.getNextId(node.parent, node.key, ignoredIds);
      }
    }
    return null;
  }

  private getPreviousId(node: Node<any, any>, key: TId, ignoredIds: TId[] = []): TId {
    if (node) {
      const index = node.children.findIndex(i => i.key === key);
      if (index > 0) {
        const previousKey = this.getLastId(node.children[index - 1]);
        return !ignoredIds.some(i => i === previousKey) ? previousKey : this.getPreviousId(node, previousKey, ignoredIds);
      } else {
        return !ignoredIds.some(i => i === node.key) ? node.key : this.getPreviousId(node.parent, node.key, ignoredIds);
      }
    }
    return null;
  }

  private getLastId(node: Node<any, any>): TId {
    if (node.hasChildren && this.dataComponent.instance.isRowExpanded(node.key)) {
      return this.getLastId(node.children[node.children.length - 1]);
    } else {
      return node.key;
    }
  }

  private getSiblingBefore(selectedItemParentId: TId, itemBeforeId: TId): T {
    const itemBefore = this.dataSource.find(p => p.id === itemBeforeId);
    if (!itemBefore?.parentId) {
      return null;
    }

    if (selectedItemParentId === itemBefore.parentId && !isItem(itemBefore)) {
      return itemBefore;
    } else {
      return this.getSiblingBefore(selectedItemParentId, itemBefore.parentId);
    }
  }

  private getSelectedRowElements(keys: number[]): Element[] {
    const rowIndexes = keys.map(key => this.dataComponent?.instance.getRowIndexByKey(key)).sort((a, b) => a - b);
    return rowIndexes.map(rowIndex => this.dataComponent?.instance.getRowElement(rowIndex)[0]);
  }

  private findParentIds(items: IParentId<TId>[], parentIds?: TId[]): TId[] {
    const itemsParent = items.map(p => p.parentId).filter(p => !!p).filter((p, i, a) => a.indexOf(p) === i);
    const ret = [...(parentIds ?? []), ...itemsParent].filter((p, i, a) => a.indexOf(p) === i);
    return items?.length ? this.findParentIds(this.dataSource.filter(p => itemsParent.includes(p.id)), ret) : ret;
  }

  private getAllChildren(nodeKey: any): any[] {
    const children = [];
    const node = this.dataComponent.instance.getNodeByKey(nodeKey);

    node?.children?.forEach(childNode => {
      children.push(childNode.data, ...this.getAllChildren(childNode.key));
    });

    return children;
  }

  private getParents(node: Node<any, any>): any[] {
    const parents = [];
    let parent = node;

    for (let i = 0; i <= node.level; i++) {
      parent = parent.parent;

      if (parent) {
        parents.push(parent.data);
      }
    }

    return parents;
  };
}
