import { NgModule } from '@angular/core';

import {
  NgbButtonsModule,
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbNavModule,
  NgbToastModule,
} from '@ng-bootstrap/ng-bootstrap';

const bootstrapModules = [
  NgbButtonsModule, NgbCollapseModule, NgbDropdownModule, NgbDatepickerModule, NgbNavModule, NgbToastModule
];

@NgModule({
  imports: bootstrapModules,
  exports: bootstrapModules
})
export class BootstrapModule {
}
