import { Injectable } from '@angular/core';

import { KrosAuthService } from '@kros-sk/auth';

import { environment } from '../../../environments/environment';

@Injectable()
export class InitKrosAuthGuard {

  private initialized = false;

  constructor(
    private authService: KrosAuthService,
  ) { }

  canActivate(): boolean | Promise<boolean> {
    if (this.initialized) {
      return true;
    }

    return this.init();
  }

  canActivateChild(): boolean | Promise<boolean> {
    if (this.initialized) {
      return true;
    }

    return this.init();
  }

  private async init(): Promise<boolean> {
    await this.authService.initialize(environment.identityServer.clientId, environment.identityServer.scope, 25);
    this.initialized = true;

    return Promise.resolve(true);
  }
}
