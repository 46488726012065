<kros-column-filter
  data-test="building-progress-column-filter"
  [allowGroupCheck]="false"
  [horizontal]="true"
  [filterColumns]="filterColumns"
  [dropdonwTitleAllColumns]="ALL_COLUMNS_TITLE"
  [dropdownTitleColumnSelection]="COLUMN_SELECTION_TITLE"
  [ungroupedColumnsLabel]="UNGROUPED_COLUMNS_LABEL"
  (columnVisibilityChanged)="onColumnVisibilityChange($event)"
  (filterClosed)="onFilterClose($event)"
>
</kros-column-filter>
