<dx-tree-list
  #dataTreeListRef
  id="gridContainer"
  data-test="object-table"
  [dataSource]="dataSource$ | async"
  keyExpr="id"
  parentIdExpr="parentId"
  hasItemsExpr="hasChildren"
  [showBorders]="true"
  [showColumnLines]="true"
  [showRowLines]="true"
  [allowColumnReordering]="false"
  [allowColumnResizing]="true"
  [columnResizingMode]="'widget'"
  [columnAutoWidth]="true"
  [hoverStateEnabled]="true"
  [wordWrapEnabled]="true"
  [focusedRowEnabled]="true"
  [(focusedRowIndex)]="focusedRowIndex"
  [(focusedRowKey)]="focusedRowKey"
  [noDataText]="(budgetPermission$ | async)?.permissionType === permissionType.Reader && (dataSource$ | async)?.length === 0
    ? ('BUDGET.TABLE.NOT_EXIST' | translate)
    : ('COMMON.PREPARING_DATA' | translate)"
  [loadPanel]="{ text: 'COMMON.PREPARING_DATA' | translate }"
  (onToolbarPreparing)="onToolbarPreparing($event)"
  (onCellPrepared)="onCellPrepared($event)"
  (onRowPrepared)="onRowPrepared($event)"
  (onContentReady)="onContentReady($event)"
  (onCellClick)="onCellClick($event)"
  (onSelectionChanged)="onSelectionChange($event)"
  (onFocusedCellChanging)="onFocusedCellChanging($event)"
>
  <dxo-scrolling mode="standard"></dxo-scrolling>

  <dxo-selection
    [recursive]="false"
    mode="multiple"
  ></dxo-selection>

  <dxo-keyboard-navigation
    [editOnKeyPress]="true"
    [enterKeyAction]="'moveFocus'"
    [enterKeyDirection]="'row'"
  ></dxo-keyboard-navigation>

  <!-- Columns -->

  <dxi-column
    caption=""
    dataField="selection"
    cssClass="selection-column"
    [allowFiltering]="false"
    [allowSorting]="false"
    [allowEditing]="false"
    [allowHiding]="false"
    [allowReordering]="false"
    [allowResizing]="false"
    width="32"
  ></dxi-column>

  <dxi-column
    [caption]="'BUDGET.TABLE.ITEM_TYPE' | translate"
    dataField="itemType"
    dataType="string"
    width="32"
    [allowEditing]="false"
    [allowSorting]="false"
  >
  </dxi-column>

  <dxi-column
    [caption]="'BUDGET.TABLE.CODE' | translate"
    dataField="code"
    dataType="string"
    width="120"
    cellTemplate="validationCellTemplate"
    [allowSorting]="false"
    [editorOptions]="{ maxLength: 50 }"
  > </dxi-column>

  <dxi-column
    [caption]="'BUDGET.TABLE.DESCRIPTION' | translate"
    dataField="description"
    dataType="string"
    width="400"
    cellTemplate="descriptionCellTemplate"
    cssClass="description-column"
    [allowSorting]="false"
  >
  </dxi-column>

  <dxi-column
    [caption]="'BUDGET.TABLE.TOTAL_PRICE' | translate"
    dataField="totalPrice"
    dataType="number"
    width="130"
    [allowSorting]="false"
    [allowEditing]="false"
    [editorOptions]="{ format: '#0.' + '0'.repeat((settings$ | async)?.totalPriceDecimalPlacesCount) }"
  >
    <dxo-format
      type="fixedPoint"
      [precision]="(settings$ | async)?.totalPriceDecimalPlacesCount"
    >
    </dxo-format>
  </dxi-column>

  <dxi-column
    [caption]="'BUDGET.TABLE.UPLOAD_STATE' | translate"
    dataField="state"
    dataType="string"
    width="140"
    cellTemplate="stateCellTemplate"
    [allowSorting]="false"
    [alignment]="'left'"
  >
  </dxi-column>

  <dxi-column
    [caption]="'BUDGET.TABLE.PERSON' | translate"
    dataField="email"
    dataType="string"
    width="190"
    [allowSorting]="false"
    [alignment]="'left'"
  >
  </dxi-column>

  <dxi-column
    [caption]="'BUDGET.TABLE.DATE_CHANGED' | translate"
    dataField="dateChanged"
    dataType="date"
    width="120"
    cellTemplate="cellTemplatedDate"
    [allowSorting]="false"
    [alignment]="'center'"
  >
    <div *dxTemplate="let cell of 'cellTemplatedDate'">
      {{ (cell.value ? cell.value : null) | date: 'dd.MM.yyyy HH:mm' : 'CEST' }}
    </div>
  </dxi-column>

  <div *dxTemplate="let cell of 'textAreaTemplate'">
    <dx-text-area
      #textArea
      [autoResizeEnabled]="true"
      [value]="cell.value"
      (onValueChanged)="onTextAreaValueChanged($event.value, cell)"
      (input)="onTextAreaInput(cell)"
      (onFocusIn)="updateTextAreaHeight(textArea)"
    ></dx-text-area>
  </div>

  <div
    *dxTemplate="let cell of 'validationCellTemplate'"
  >
    <ng-container *ngTemplateOutlet="validationCellCommonTemplate; context: {$implicit: cell}"></ng-container>
  </div>

  <div
    *dxTemplate="let cell of 'descriptionCellTemplate'"
  >
    <div
      class="dx-treelist-icon-container dx-treelist-custom-expander"
      [class.expander-hidden]="!cell.data.hasChildren"
    >
      <div
        class="dx-treelist-empty-space"
        [ngClass]="dataTreeListRef?.instance.isRowExpanded(cell.key) ? 'dx-treelist-expanded' : 'dx-treelist-collapsed'"
        data-test="expander"
      >
        <span></span>
      </div>
    </div>

    <ng-container *ngTemplateOutlet="validationCellCommonTemplate; context: {$implicit: cell}"></ng-container>
  </div>

  <div *dxTemplate="let cell of 'stateCellTemplate'">
    <span class="badge">{{getState(cell.value)}}</span>
  </div>
</dx-tree-list>

<ng-template
  #validationCellCommonTemplate
  let-cell
>
  <div>{{ cell.text }}</div>

  <i
    *ngIf="isInvalidCell(cell)"
    class="material-icons icon-button font-red"
  >priority_high</i>
</ng-template>
