import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { CurrentLicense, License, UserCount, UserFeatureFlags } from '@kros-sk/models';

import { AuthState } from './auth.reducer';
import { KrosUser } from '../services/auth/kros-user.model';

const selectAuthFeature = createFeatureSelector<AuthState>('auth');

const selectCurrentUser = createSelector(
  selectAuthFeature,
  state => state.currentUser
);

const selectIsLoggedIn = createSelector(
  selectAuthFeature,
  state => state.currentUser != null
);

const selectFeatureFlags = createSelector(
  selectAuthFeature,
  state => state.features
);

const selectCurrentLicense = createSelector(
  selectAuthFeature,
  state => state.licenses.currentLicense
);

const selectLicensesResponseStatus = createSelector(
  selectAuthFeature,
  state => state.licenses.licensesResponseStatus
);

const selectUserCount = createSelector(
  selectAuthFeature,
  state => state.licenses.userCount
);

const selectCurrentUserIsOwner = createSelector(
  selectCurrentUser,
  selectUserCount,
  (user, count) => count ? user.email === count.ownerEmail : true
);

const selectAllLicenses = createSelector(
  selectAuthFeature,
  state => state.licenses.allLicenses
);

const selectFeaturesLoaded = createSelector(
  selectAuthFeature,
  state => state.featuresLoaded
);

export const fromAuth = {
  /** @deprecated use fromFeatureFlags or HasFeatureFlagFacade instead */
  selectFeatureFlags,
  selectCurrentUser,
};

@Injectable()
export class AuthSelectorsService {

  constructor(
    private store: Store,
  ) {
  }

  public get currentUser$(): Observable<KrosUser | null> {
    return this.store.select(selectCurrentUser);
  }

  /**
   * Flag indicating that the current user is company owner
   */
  public get currentUserIsOwner$(): Observable<boolean> {
    return this.store.select(selectCurrentUserIsOwner);
  }

  /**
   * Boolean flag indicating user is logged in
   */
  public get isLoggedIn$(): Observable<boolean> {
    return this.store.select(selectIsLoggedIn);
  }

  /**
   * @deprecated use fromFeatureFlags or HasFeatureFlagFacade instead
   */
  public get featureFlags$(): Observable<UserFeatureFlags> {
    return this.store.select(selectFeatureFlags);
  }

  public get currentLicense$(): Observable<CurrentLicense | null> {
    return this.store.select(selectCurrentLicense);
  }

  public get licensesResponseStatus$(): Observable<HttpStatusCode | null> {
    return this.store.select(selectLicensesResponseStatus);
  }

  public get allLicenses$(): Observable<License[] | null> {
    return this.store.select(selectAllLicenses);
  }

  public get userCount$(): Observable<UserCount> {
    return this.store.select(selectUserCount);
  }

  /**
   * Boolean flag indicating that feature flags has already been loaded
   * @deprecated use fromFeatureFlags.selectFeaturesLoaded instead
   */
  public get featureFlagsLoaded$(): Observable<boolean> {
    return this.store.select(selectFeaturesLoaded);
  }
}
