import { Injectable } from '@angular/core';

import { filter, take, takeWhile } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SubSink } from 'subsink';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { BillOfQuantitiesItemType, CalculationType, TranslateService } from '@kros-sk/ssw-shared-legacy';
import { KrosModalService, MessageTypes } from '@kros-sk/components';

import { BoqEditViewModel, BoqItemViewModel } from '../models/boq-edit.view-model';
import { BoqExpressionViewModel } from '../models/boq-expression.view-model';
import { BoqPeriodItem } from '../models/boq-period-item.model';
import { BoqService } from './boq.service';
import { BoqVisualHelper, PermissionHelper } from '../helpers';
import { BuildingProgressDispatchersService, BuildingProgressSelectorsService } from '../../store/building-progress';
import { BuildingProgressItem, BuildingProgressPeriodItem } from '../models/construction-data.model';
import { getAppInsightsPrefix } from '../shared/building-progress-utils';

@Injectable()
export class BoqEditationService {
  originalBudgetItem: BuildingProgressItem;
  originalItems: BoqPeriodItem[] = [];
  projectId: number;
  resultAmount: number;

  get hasEdits(): boolean {
    return this.editedBoqItems?.length > 0 || this.deletedBoqItemIds?.length > 0 || this.isEditInProgress;
  }

  private deletedBoqItemIds: number[] = [];
  private editedBoqItems: BoqItemViewModel[] = [];
  private isEditInProgress$ = new Subject<boolean>();
  private isEditInProgress = false;
  private originalPeriodId: number;
  private subs = new SubSink();
  private questionIsOpened = false;

  constructor(
    private appInsightsService: AppInsightsBaseService,
    private boqService: BoqService,
    private boqVisualHelper: BoqVisualHelper,
    private buildingProgressDispatchersService: BuildingProgressDispatchersService,
    private buildingProgressSelectorsService: BuildingProgressSelectorsService,
    private permissionHelper: PermissionHelper,
    private krosModalService: KrosModalService,
    private translateService: TranslateService
  ) { }

  init(): void {
    this.subs.sink = this.buildingProgressSelectorsService.deletedBoqItemIds$
      .subscribe(deletedBoqItemIds => this.deletedBoqItemIds = deletedBoqItemIds ?? []);
    this.subs.sink = this.buildingProgressSelectorsService.editedBoqItems$
      .subscribe(editedBoqItems => this.editedBoqItems = editedBoqItems ?? []);
  }

  destroy(): void {
    this.subs.unsubscribe();
  }

  edit(items: BoqPeriodItem[], item: BoqPeriodItem, value: string, refocusCell: boolean = false): void {
    if (!!item && item.expression !== value) {
      this.setEditInProgress(true);
      const viewModel: BoqExpressionViewModel = { budgetItemId: item.budgetItemId, expression: value };

      this.boqService.calculateExpression(viewModel).subscribe(result => {
        const modifiedItem: BoqPeriodItem = {
          ...item,
          quantity: result.quantity,
          boqItemType: result.type,
          expression: value
        };
        setTimeout(() => {
          this.buildingProgressDispatchersService.editBoqItems(this.recalculateSums(modifiedItem, items));

          if (refocusCell) {
            this.boqVisualHelper.setFocus();
          }
          this.setEditInProgress(false);
        }, 100);
      });
    }
  }

  setOriginalItems(items: BoqPeriodItem[], periodId: number, budgetItem: BuildingProgressItem): void {
    this.originalItems = items;
    this.originalPeriodId = periodId;
    this.originalBudgetItem = Object.assign({}, budgetItem);
    this.buildingProgressDispatchersService.originalItemsAreReseted();
  }

  saveBoqItems(periodId: number, budgetItem: BuildingProgressItem): void {
    if (this.isEditInProgress) {
      this.isEditInProgress$.pipe(
        filter(result => !result),
        take(1)
      ).subscribe(() => this.saveBoqItemsCore(periodId, budgetItem));
    } else {
      this.saveBoqItemsCore(periodId, budgetItem);
    }
  }

  private saveBoqItemsCore(periodId: number, budgetItem: BuildingProgressItem): void {
    const viewModel = this.createBoqEditViewModel(this.projectId, periodId, budgetItem.id, this.resultAmount);
    const period = budgetItem.periods.find(p => p.periodId === periodId);
    this.setBoq(viewModel, period);
  }

  private setEditInProgress(isEditing: boolean): void {
    this.isEditInProgress = isEditing;
    this.isEditInProgress$.next(isEditing);
  }

  saveBoqItemsWithQuestion(callback?: Function): void {
    if (!this.questionIsOpened) {
      if (this.hasEdits) {
        this.saveBoqWithQuestion((result: any) => {
          if (this.isEditInProgress) {
            this.isEditInProgress$.pipe(
              filter(result => !result),
              take(1)
            ).subscribe(() => {
              this.saveBoqItemsWithQuestionCore(result, callback);
            });
          } else {
            this.saveBoqItemsWithQuestionCore(result, callback);
          }
        });
      } else if (callback) {
        callback(false);
      }
    }
  }

  private saveBoqItemsWithQuestionCore(result: any, callback: Function): void {
    const viewModel = this.createBoqEditViewModel(
      this.projectId,
      this.originalPeriodId,
      this.originalBudgetItem.id,
      this.resultAmount);
    const period = this.originalBudgetItem.periods.find(p => p.periodId === this.originalPeriodId);
    if (result.data === 'accept') {
      this.setBoq(viewModel, period, callback);
    } else {
      this.buildingProgressDispatchersService.cancelBoqEditation();
      if (callback) {
        callback(false);
      }
    }

  }

  private setBoq(viewModel: BoqEditViewModel, period: BuildingProgressPeriodItem, callback?: Function): void {
    this.buildingProgressDispatchersService.setBoq(viewModel, this.originalItems, period);
    this.appInsightsService.trackEvent(getAppInsightsPrefix(this.permissionHelper.isContractor) + 'boq-save');

    if (callback) {
      this.buildingProgressSelectorsService.tableIsBusy$.pipe(takeWhile(busy => busy, true), filter(busy => !busy))
        .subscribe(() => callback(true));
    }
  }

  private saveBoqWithQuestion(callback: Function): void {
    this.questionIsOpened = true;
    this.krosModalService.openModalMessageBoxModalRef({
      icon: '',
      caption: this.translateService.translate('BILL_OF_QUANTITIES.SAVE_QUESTION'),
      messageType: MessageTypes.Primary,
      acceptButton: this.translateService.translate('COMMON.ULOZIT'),
      cancelButton: this.translateService.translate('COMMON.NOT_SAVE')
    }).afterClosed$.pipe(take(1)).subscribe(result => {
      this.questionIsOpened = false;
      callback(result);
    });
  }

  recalculateSums(modifiedItem: BoqPeriodItem, items: BoqPeriodItem[]): BoqPeriodItem[] {
    const modifiedItems = this.getRecalculatedSums(modifiedItem, items);
    modifiedItems.push(modifiedItem);
    return modifiedItems;
  }

  private getRecalculatedSums(modifiedItem: BoqPeriodItem, items: BoqPeriodItem[]): BoqPeriodItem[] {
    const ret: BoqPeriodItem[] = [];

    if (items.findIndex(x => x.boqItemType === BillOfQuantitiesItemType.Subtotal ||
      x.boqItemType === BillOfQuantitiesItemType.Total) >= 0) {
      let total = 0;
      let subtotal = 0;

      items.forEach(i => {
        const item = i.id === modifiedItem.id ? modifiedItem : i;

        if (item.boqItemType === BillOfQuantitiesItemType.Expression) {
          subtotal += item.quantity;
          total += item.quantity;
        } else if (item.boqItemType === BillOfQuantitiesItemType.Total) {
          if (total !== item.quantity) {
            ret.push({
              ...item,
              quantity: total
            });
          }

          subtotal = 0;
        } else if (item.boqItemType === BillOfQuantitiesItemType.Subtotal) {
          if (subtotal !== item.quantity) {
            ret.push({
              ...item,
              quantity: subtotal
            });
          }

          subtotal = 0;
        }
      });
    }

    return ret;
  }

  private createBoqEditViewModel(
    projectId: number,
    periodId: number,
    budgetItemId: number,
    amount: number): BoqEditViewModel {
    return {
      projectId,
      periodId,
      budgetItemId,
      amount,
      deletedIds: this.editedBoqItems.filter(p => p.id > 0 && !p.expression.trim()).map(p => p.id),
      editedItems: this.editedBoqItems.filter(p => p.id > 0 && p.expression.trim()),
      createdItems: this.editedBoqItems.filter(p => p.id < 0 && p.expression.trim()),
      calculationType: CalculationType.BillOfQuantities
    } as BoqEditViewModel;
  }
}
