import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';

import { LicenseUrlService } from '../license';
import { TranslateService } from '../translate/translate.service';

@Injectable()
export class BrowserTabService {

  private title = inject(Title);
  private translateService = inject(TranslateService);
  private urlHelper = inject(LicenseUrlService, { optional: true });

  private titleKey: string;
  private shouldAddPrefix: boolean;

  constructor() {
    this.translateService.langChanged.pipe(takeUntilDestroyed()).subscribe(() => this.setTabTitle());
  }

  setTitle(titleKey: string, shouldAddPrefix = true): void {
    this.titleKey = titleKey;
    this.shouldAddPrefix = shouldAddPrefix;
    this.setTabTitle();
  }

  setFavicon(iconPath: string): void {
    const oldFavicon = document.querySelector('link[rel=icon]');

    const newFavicon = document.createElement('link');
    newFavicon.id = 'favicon';
    newFavicon.type = 'image/x-icon';
    newFavicon.rel = 'icon';
    newFavicon.href = iconPath + '?=' + Math.random();

    if (oldFavicon) {
      document.head.removeChild(oldFavicon);
    }
    document.head.appendChild(newFavicon);
  }

  private setTabTitle(): void {
    const prefix = this.shouldAddPrefix ? (this.urlHelper?.krosCompanyPrefix ?? '') : '';
    this.title.setTitle(prefix + this.translateService.translate(this.titleKey));
  }
}
