import { Component, Input } from '@angular/core';

import { OpenApiSubscriptionApiModel } from '@kros-sk/models';

@Component({
  selector: 'ssw-open-api-settings-list-item',
  templateUrl: './open-api-settings-list-item.component.html',
  styleUrls: ['./open-api-settings-list-item.component.scss']
})
export class OpenApiSettingsListItemComponent {
  @Input() subscription: OpenApiSubscriptionApiModel;
}
