import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { map, switchMap, take, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { PageNotFoundParam } from '@kros-sk/models';

import { DetailRouterConfig, MasterDetailUrlHelperService } from '../master-detail';
import { FormGuardDirective } from './form-guard.directive';
import { navigatingToNotFound } from '../tools';

@Injectable({
  providedIn: 'root'
})
export class FormGuard {

  formMemory: UntypedFormGroup;
  private alreadyActivated: string;

  private formGuardDirectiveInstances: FormGuardDirective[] = [];

  constructor(
    private masterDetailUrlHelper: MasterDetailUrlHelperService,
  ) { }

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    let formGuard: FormGuardDirective;

    if (this.formGuardDirectiveInstances.length > 0) {
      formGuard = this.formGuardDirectiveInstances[0];
    }

    if (formGuard) {
      const data = currentRoute.data as DetailRouterConfig;
      const shouldRememberForm = data ? this.shouldRememberForm(data, currentState, nextState) : false;

      if (navigatingToNotFound(nextState.url, '/not-found', [PageNotFoundParam.companyDeleted, PageNotFoundParam.userDeletedFromCompany])) {
        // if is navigating to not-found
        return true;
      }

      if (this.alreadyActivated) { // browser's second back button click
        setTimeout(() => {
          // history -2 - second back - replace state to preserve previous states
          window.history.replaceState(null, document.title, nextState.url);
          // add history -1 - previous back
          window.history.pushState(null, document.title, this.alreadyActivated);
          // add current url - where we were returned by returned false
          window.history.pushState(null, document.title, currentState.url);
          this.alreadyActivated = null;
        }); // timeout to update history after false is returned and guard blocked navigation
        return false;
      } else {
        this.alreadyActivated = nextState.url; // save next url for potential second back button click
        return formGuard.canLeaveBeforeSave(shouldRememberForm).pipe(
          switchMap(res => {
            if (res.canLeave && res.forceSave) {
              return formGuard.save$.pipe(take(1), map(_ => true));
            } else if (!res.canLeave && res.forceSave) {
              // as we are asked "Are you really sure to leave ?" and at the same time form is in invalid state
              // we intentionally answer go back and repair, and so we trigger all actions tide to save
              // - focus firt invalid
              // - mark all as touched
              // - remove empty derived measure units
              // every register can have its own proces tide to save invalid form
              // we want to trigger the whole proces not just focus first invalid input
              return formGuard.save$.pipe(take(1), map(_ => false));
            }

            return of(res.canLeave);
          }),
          tap(_ => {
            // pipe has finished - was not killed (completed without emit) by new navigation
            this.alreadyActivated = null;
          })
        );
      }
    }

    return true;
  }

  addActiveFormGuardInstance(formGuard: FormGuardDirective): void {
    this.formGuardDirectiveInstances.push(formGuard);
  }

  removeActiveFormGuardInstance(formGuard: FormGuardDirective): void {
    if (this.formGuardDirectiveInstances.length === 0) {
      return;
    }

    const index = this.formGuardDirectiveInstances.findIndex(form => form === formGuard);
    if (index > -1) {
      this.formGuardDirectiveInstances.splice(index, 1);
    }
  }

  /**
   * Used to remember dirty form state and values when changing screen resolution
   */
  shouldRememberForm(detailRouterConfig: DetailRouterConfig, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): boolean {
    return this.masterDetailUrlHelper.isSwitchingDesktopMobile(detailRouterConfig, currentState, nextState);
  }

}
