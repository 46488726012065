import { Directive, inject, Input } from '@angular/core';

import { Observable } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { AuthDispatchersService, AuthSelectorsService, KrosUser } from '@kros-sk/auth';

import { APPLICATION_ID } from '../../partials';
import { DeviceDetectorService, DeviceType } from '../../services/device-detector/device-detector.service';
import { extractNameFromEmail } from '../../tools';

@Directive()
export abstract class UserShellBase {

  @Input() hide: () => void;

  protected appConfig = inject(APP_CONFIG);
  protected authSelectors = inject(AuthSelectorsService);
  protected authDispatchers = inject(AuthDispatchersService);
  protected deviceDetector = inject(DeviceDetectorService);
  protected applicationType = inject(APPLICATION_ID);

  get currentUser$(): Observable<KrosUser> {
    return this.authSelectors.currentUser$;
  }

  get staticUrl(): string {
    return this.appConfig.staticContentStorage;
  }

  get userSettingsUrl(): string {
    return `${this.appConfig.identityServer.url}/Manage/ChangePassword?returnUrl=${this.appConfig.identityServer.clientUrl}`;
  }

  get isMobile(): boolean {
    return this.deviceDetector.deviceType === DeviceType.Mobile;
  }

  getNameFromEmail(email: string): string {
    return extractNameFromEmail(email);
  }

  async logout(): Promise<void> {
    this.authDispatchers.logoutUser();
    this.hide();
  }

}
