import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';

import { CheckLicenseOnClickDirective } from './directives/check-license-on-click.directive';
import { CheckLicenseOnKeydownDirective } from './directives/check-license-on-keydown.directive';
import { CheckLicenseOnUserInputDirective } from './directives/check-license-on-user-input.directive';
import { CheckRequiredLicenseComponent } from './check-required-license/check-required-license.component';
import { CheckRequiredLicenseService } from './services/check-required-license.service';
import { IfLicenseAllowedDirective } from './directives/if-license-allowed.directive';

const components = [
  CheckRequiredLicenseComponent,
  CheckLicenseOnClickDirective,
  CheckLicenseOnUserInputDirective,
  CheckLicenseOnKeydownDirective,
  IfLicenseAllowedDirective,
];

@NgModule({
  declarations: [...components],
  imports: [ CommonModule ],
  exports: [...components],
})
export class RequiredLicenseModule {

  /**
   * Root injector configuration for license checking. Use this only once - in CoreModule.
   * @param checkLicSvc Custom implementation of CheckRequiredLicenseService.
   */
  static forRoot<T extends CheckRequiredLicenseService>(
    checkLicSvc: Type<T>
  ): ModuleWithProviders<RequiredLicenseModule> {
    return {
      ngModule: RequiredLicenseModule,
      providers: [
        {
          provide: CheckRequiredLicenseService,
          useClass: checkLicSvc
        }
      ]
    };
  }

}
