import { Component, ElementRef, inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';

import { AuthSelectorsService } from '@kros-sk/auth';
import { KrosModalRef } from '@kros-sk/components';

import { DynamicLocaleId, TranslateService } from '../../../translate';
import { Project, ProjectDetail } from '../../../models';
import { ProjectDialogComponent } from '../project-dialog/project-dialog.component';

@Component({
  selector: 'kros-project-create-dialog',
  templateUrl: './project-create-dialog.component.html',
  styleUrls: ['../project-dialog/project-dialog.component.scss', './project-create-dialog.component.scss'],
  providers: [
    {
      provide: LOCALE_ID,
      useClass: DynamicLocaleId,
      deps: [TranslateService]
    }
  ]
})
export class ProjectCreateDialogComponent extends ProjectDialogComponent implements OnInit {

  protected modalRef = inject(KrosModalRef);
  protected authSelectorsService = inject(AuthSelectorsService);
  protected translateService = inject(TranslateService);

  @ViewChild('nameInput') nameInput: ElementRef<HTMLElement>;

  ngOnInit(): void {
    this.data = this.modalRef.data;
    this.project = this.initProject(this.data.project);

    super.ngOnInit();
  }

  initProject(project: Project): ProjectDetail {
    return {
      ...project,
      place: '',
      isDemo: false,
      customerName: '',
      customerId: '',
      customerVatId: '',
      contractorName: '',
      contractorId: '',
      contractorVatId: '',
      designerName: '',
      designerId: '',
      designerVatId: '',
      authorName: '',
      authorId: '',
      authorVatId: '',
      constructionManager: '',
      apiCode: '',
      dateStarted: null,
      dateEnded: null,
      contractNumber: '',
      contractDate: null
    };
  }

  onSubmit(): void {
    if (this.editForm.valid) {
      this.copyProjectFromFormControl();
      this.modalRef.submit({ result: true, project: this.project });
    }
  }

  protected onInitializeForm(): void {
    setTimeout(() => this.nameInput?.nativeElement.focus());
  }
}
