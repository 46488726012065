import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import * as buildingProgressPartialItemsActions from './building-progress-partial-items.actions';
import { BuildingProgressItem } from '../../../building-progress/models/construction-data.model';
import { BuildingProgressState } from '../building-progress.state';
import {
  PartialBudgetItem,
  PartialBudgetItemChangedProperty
} from '../../../building-progress/models/building-progress-partial-budget-items.model';

@Injectable()
export class BuildingProgressPartialItemsDispatchersService {

  constructor(
    private store: Store<BuildingProgressState>
  ) { }

  initPartialItems(budgetItem: BuildingProgressItem): void {
    this.store.dispatch(
      buildingProgressPartialItemsActions.initPartialItems({ budgetItem })
    );
  }

  recalculatePartialItems(): void {
    this.store.dispatch(
      buildingProgressPartialItemsActions.recalculatePartialItems()
    );
  }

  addPartialItem(partialBudgetItem: PartialBudgetItem): void {
    this.store.dispatch(
      buildingProgressPartialItemsActions.addPartialItem({ partialBudgetItem })
    );
  }

  addPartialItems(count: number): void {
    for (let i = 0; i < count; i++) {
      this.addPartialItem({
        amount: 0,
        percentage: 0,
        changedProperty: PartialBudgetItemChangedProperty.None
      });
    }
  }

  savePartialItems(originalItemIdOfPartialItem? : number): void {
    this.store.dispatch(
      buildingProgressPartialItemsActions.savePartialItems({ originalItemIdOfPartialItem })
    );
  }

  updatePartialItem(
    changedValue: number,
    rowIndex: number,
    propertyChanged: PartialBudgetItemChangedProperty
  ): void {
    this.store.dispatch(
      buildingProgressPartialItemsActions.updatePartialItem({ changedValue, rowIndex, propertyChanged })
    );
  }

  removeLastPartialItem(): void {
    this.store.dispatch(
      buildingProgressPartialItemsActions.removeLastPartialItem()
    );
  }

  removeLastPartialItems(count: number): void {
    for (let i = 0; i < count; i++) {
      this.removeLastPartialItem();
    }
  }
}
