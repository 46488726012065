<div class="kros-modal-header">
  <h4 class="kros-modal-title">
    <ng-container *ngIf="selectedDocuments.length === 1 else multipleDocuments">
      {{ 'DOCUMENTS.SHARING.CAPTION_SINGLE' | translate }}
      <span
        class="document-name-caption"
        data-test="document-name-caption"
      >{{ selectedDocuments[0].originalName }}</span>
    </ng-container>
    <ng-template #multipleDocuments>
      {{ 'DOCUMENTS.SHARING.CAPTION_MULTIPLE' | translate }}
      <span
        class="document-name-caption"
        data-test="document-name-caption"
      >({{selectedDocuments.length}})</span>
    </ng-template>
  </h4>
  <button
    class="btn btn-link close"
    (click)="onCloseClick()"
    data-test="close-share-document-button"
  >
    <i class="material-icons">close</i>
  </button>
</div>

<div
  class="kros-modal-body"
  [class.compacted]="compacted"
>
  <ng-container *ngIf="!readonly; else readOnlySharingList">
    <div class="sharing-input">
      <kros-user-sharing-input
        [defaultValues]="defaultPermissions"
        [sharingMode]="sharingMode"
        [existingEmails]="getExistingSharingEmails()"
        (shared)="onShared($event)"
      ></kros-user-sharing-input>
    </div>

    <div *ngIf="loadedItems; else timeline">
      <kros-user-sharing-list
        [unconfirmedSharings]="unconfirmedSharings"
        [confirmedSharings]="sharings"
        [sharingMode]="sharingMode"
        (deletedPermission)="onDeletedPermission($event)"
        (unconfirmedPermissionDeleted)="onUnconfirmedPermissionDeleted($event)"
        (changedPermission)="onChangedPermission($event)"
      ></kros-user-sharing-list>
    </div>
  </ng-container>
</div>

<div class="kros-modal-footer">
  <div
    *ngIf="changesMade"
    class="buttons"
  >
    <button
      class="btn btn-primary btn-submit"
      (click)="onSubmitChanges()"
      data-test="document-sharing-submit"
    >{{'COMMON.ULOZIT' | translate }}</button>
    <button
      class="btn btn-outline-secondary btn-cancel"
      (click)="onCancelChanges()"
    >{{ 'COMMON.ZRUSIT' | translate }}</button>
  </div>
</div>

<ng-template #readOnlySharingList>
  <ng-container *ngIf="loadedItems; else timeline">
    <kros-user-sharing-list
      [confirmedSharings]="sharings"
      [sharingMode]="sharingMode"
      [readonly]="true"
    ></kros-user-sharing-list>
  </ng-container>
</ng-template>


<ng-template #timeline>
  <kros-timeline [type]="timelineType.TwoLine"></kros-timeline>
</ng-template>
