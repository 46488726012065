import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { AutocompleteModule } from '../autocomplete/autocomplete.module';
import { DirectivesModule } from '../directives';
import { KrosDatepickerComponent } from './datepicker/datepicker.component';
import { KrosDigitsInputComponent } from './input/digitsInput/digits-input.component';
import { KrosError } from './error/error';
import { KrosFileInputComponent } from './file-input/file-input.component';
import { KrosInputComponent } from './input/input.component';
import { KrosNumericInputComponent } from './input/numericInput/numeric-input.component';
import { KrosOptionsInputComponent } from './options-input/options-input.component';
import { KrosTextareaComponent } from './textarea/textarea.component';
import { KrosValidationMessagesComponent } from './validationMessages/validationMessages.component';
import { MultipleOptionComponent } from './options-input/multiple-option/multiple-option.component';
import { RecalcWatcherModule } from '../directives/recalc-watcher/recalc-watcher.module';
import { SingleOptionComponent } from './options-input/single-option/single-option.component';

@NgModule({
  imports: [
    AutocompleteModule,
    CommonModule,
    DirectivesModule,
    TooltipDirective,
    NgbDatepickerModule,
    ReactiveFormsModule,
    RecalcWatcherModule,
    TranslateModule
  ],
  declarations: [
    KrosDatepickerComponent,
    KrosDigitsInputComponent,
    KrosError,
    KrosFileInputComponent,
    KrosInputComponent,
    KrosNumericInputComponent,
    KrosOptionsInputComponent,
    KrosTextareaComponent,
    KrosValidationMessagesComponent,
    MultipleOptionComponent,
    SingleOptionComponent,
  ],
  exports: [
    CommonModule,
    KrosDatepickerComponent,
    KrosDigitsInputComponent,
    KrosError,
    KrosFileInputComponent,
    KrosInputComponent,
    KrosNumericInputComponent,
    KrosOptionsInputComponent,
    KrosTextareaComponent,
    KrosValidationMessagesComponent,
    MultipleOptionComponent,
    NgbDatepickerModule,
    TooltipDirective,
    ReactiveFormsModule,
    SingleOptionComponent,
  ],
  providers: []
})
export class KrosInputsModule { }
