import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter, map, Observable, switchMap, tap } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { areEmailsEqual, RegistrationNotificationService, UserService } from '@kros-sk/ssw-cdk';
import { PermissionType } from '@kros-sk/ssw-shared/permission';

import {
  ApplicationModule,
  ApplicationModuleIdProviderService,
  ApplicationRoute,
  SSW_APPLICATION_ID,
  SswApplicationId
} from '../../../application-module';
import { ConnectorServiceInterface, DocumentModalServiceInterface, ProjectDetailInterface } from '../../interfaces';
import { DocumentListInfoModel, FolderTreeNode } from '../../models';
import { DocumentService, DocumentsPermissionsService, SelectionService } from '../../services';
import { DocumentsExplorerDispatchersService, DocumentsExplorerSelectorsService } from '../../store';
import { DocumentType } from '../../enums';
import { SortableTableHeaderDirective, SortEvent } from '../../../directives';
import { TableDataSource } from '../../../table-datasource/table-datasource';
import { TimelineType } from '../../../timeline/timeline-type.enum';
import { ToastService, ToastType } from '../../../toast';
import { TourBaseService } from '../../../tour';
import { TranslateService } from '../../../translate';
import { UploadNotificationService } from '../../../upload';

const columns = ['originalName', 'version', 'sharing', 'author', 'size', 'dateSaved'];
@Component({
  selector: 'kros-document-explorer',
  templateUrl: './document-explorer.component.html',
  styleUrls: ['./document-explorer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentExplorerComponent implements OnDestroy, OnInit, AfterViewInit, AfterViewChecked {
  public selection = inject(SelectionService);
  public selectors = inject(DocumentsExplorerSelectorsService);
  private appConfig = inject(APP_CONFIG);
  private application = inject(SSW_APPLICATION_ID);
  private appModuleIdProviderService = inject(ApplicationModuleIdProviderService);
  private destroyRef = inject(DestroyRef);
  private dispatchers = inject(DocumentsExplorerDispatchersService);
  private documentService = inject(DocumentService);
  private documentsExplorerDispatchers = inject(DocumentsExplorerDispatchersService);
  private documentsExplorerSelectors = inject(DocumentsExplorerSelectorsService);
  private documentsPermissionsService = inject(DocumentsPermissionsService);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private media = inject(MediaMatcher);
  private registrationNotificationService = inject(RegistrationNotificationService);
  private translateService = inject(TranslateService);
  private toastService = inject(ToastService);
  private uploadNotificationService = inject(UploadNotificationService);
  private userService = inject(UserService);

  areDocumentsLoaded = false;
  dataSource: TableDataSource<DocumentListInfoModel>;
  project: ProjectDetailInterface;
  timelineType = TimelineType;
  mobileQuery: MediaQueryList;
  noAccess: boolean;
  canAddDocuments$: Observable<boolean> = this.selectors.rootDocumentPermission$.pipe(
    tap(permission => this.noAccess = permission === PermissionType.NoAccess),
    map(permission => this.documentsPermissionsService.hasMinimumPermissionType(
      this.currentFolder?.permissionType ?? permission, PermissionType.Contributor
    ))
  );

  @Input() documentId: string;
  @Input() connectorService: ConnectorServiceInterface;
  @Input() connectionId: string;
  @Input() tourService: TourBaseService;
  @Input() documentModalService: DocumentModalServiceInterface;
  @Input() projectDetail$: Observable<ProjectDetailInterface>;
  @Input() isUploadingMode: boolean;

  @Output() openDocument = new EventEmitter<DocumentListInfoModel>();
  @Output() openVersionModal = new EventEmitter<DocumentListInfoModel>();
  @Output() dismissDeleteToast = new EventEmitter<void>();
  @Output() createDocument = new EventEmitter<void>();
  @Output() createFolder = new EventEmitter<void>();
  @Output() uploadBuilding = new EventEmitter<void>();

  @ViewChildren(SortableTableHeaderDirective) headers: QueryList<SortableTableHeaderDirective>;

  private mobileQueryListener: () => void;
  private currentFolder: FolderTreeNode;
  private currentFolderId: string;
  private moduleId: ApplicationModule;

  get columns(): string[] {
    return ['check'].concat(columns);
  }

  get isEmptyContent(): boolean {
    return !this.project ? true : this.areDocumentsLoaded ? this.dataSource && this.dataSource.data.length === 0 : false;
  }

  get sharingsOwnerMail(): string {
    return this.project?.permissionType !== PermissionType.Owner ? this.project?.owner : undefined;
  }

  ngOnInit(): void {
    this.dataSource = new TableDataSource<DocumentListInfoModel>(this.appConfig.location);
    this.mobileQuery = this.media.matchMedia('(max-width: 1024px)');
    this.mobileQueryListener = (): void => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this.mobileQueryListener);
    this.appModuleIdProviderService.applicationModuleId$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((moduleId) => {
        this.moduleId = moduleId;
        this.changeDetectorRef.detectChanges();
      });
  }

  ngAfterViewInit(): void {
    this.connectorService?.connect(this.connectionId);
    this.selection.init();
    this.connectorService?.documentVersionProcessingFailed$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.refresh());
    this.selectors.isDocumentsListLoaded$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(loaded => {
        this.areDocumentsLoaded = loaded;
        if (loaded) {
          this.tourService?.runTourIfCan();
        }
      });
    this.selectors.documents$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(docs => {
        this.dataSource.setDataWithSort(docs, this.headers);
        this.selection.setData(this.dataSource.data);
        this.changeDetectorRef.detectChanges();
      });

    this.projectDetail$?.pipe(
      map(project => {
        if (project) {
          this.project = project as ProjectDetailInterface;
          this.documentsExplorerDispatchers.setProjectId(this.project.id);
          this.selection.lookupDocumentId = this.documentId;

          if (this.documentId) {
            this.loadDocument();
          } else {
            this.documentsExplorerDispatchers.reloadCurrentFolder(this.project.id);
          }
        }
      }),
      switchMap(() => this.uploadNotificationService.documentsUploaded),
      filter((notification) => this.project?.id === notification.projectId && this.currentFolderId === notification.parentId),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => this.refresh());

    this.documentsExplorerSelectors.currentFolder$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(f => this.currentFolder = f);
    this.documentsExplorerSelectors.currentFolderId$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(f => this.currentFolderId = f);
    this.documentsExplorerSelectors.documents$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.dismissDeleteToast.next());
  }

  ngAfterViewChecked(): void {
    this.selection.setFocusedDocument();
  }

  ngOnDestroy(): void {
    this.dismissDeleteToast.next();
    this.selection.destroy();
    this.tourService?.clear();
  }

  onOpenDocument(document: DocumentListInfoModel): void {
    if (document.type === DocumentType.Folder) {
      this.documentsExplorerDispatchers.loadFolder({ ...document } as FolderTreeNode);
    } else {
      this.openDocument.next(document);
    }

    this.dispatchers.clearAllDocuments();
  }

  onOpenQuantityManagerDemo(): void {
    this.registrationNotificationService.navigateDemoProject(ApplicationRoute.QuantityManager);
  }

  onOpenDocumentsDemo(): void {
    this.registrationNotificationService.navigateDemoProject(ApplicationRoute.Documents);
  }

  showOpenQuantityManagerDemoLink(): boolean {
    return this.moduleId === ApplicationModule.QuantityManager
      && this.application === SswApplicationId.Titan
      && !this.project?.isDemo;
  }

  showOpenDocumentsDemoLink(): boolean {
    return this.moduleId === ApplicationModule.Documents
      && this.application === SswApplicationId.Titan
      && !this.project?.isDemo;
  }

  openDocumentAccess(document: DocumentListInfoModel): void {
    this.openDocumentSharingForDocuments([document]);
  }

  onCreateDocument(): void {
    this.createDocument.next();
  }

  onCreateFolder(): void {
    this.createFolder.next();
  }

  private refresh(): void {
    this.documentsExplorerDispatchers.reloadCurrentFolder(this.project.id);
  }

  getDocumentSize(document: DocumentListInfoModel): number {
    return document.type === DocumentType.Folder ? Number.NaN : document.totalSize;
  }

  getAuthor(document: DocumentListInfoModel): string {
    if (areEmailsEqual(this.documentsPermissionsService.userEmail, document.createdByEmail)) {
      return this.translateService.translate('DOCUMENTS.ME');
    } else if (document.createdByEmail) {
      return document.createdByEmail;
    }
  }

  onSort({ column, direction }: SortEvent): void {
    this.dataSource.sort({ column, direction }, this.headers);
  }

  isDocumentSharingVisible(document: DocumentListInfoModel): boolean {
    return document.isShared && document.permissionType !== PermissionType.Shared;
  }

  onUploadBuilding(): void {
    this.uploadBuilding.next();
  }

  private loadDocument(): void {
    this.documentService.getDocumentParentFolderList(this.documentId).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (folders: DocumentListInfoModel[]) => {
          if (folders.length > 0) {
            const folder = folders[folders.length - 1];
            this.documentsExplorerDispatchers.setCurrentFolder(
              {
                id: folder.id,
                name: folder.name,
                parentId: folder.parentId,
                permissionType: folder.permissionType,
                documentPermissionType: folder.documentPermissionType
              }
            );
          }
          this.refresh();
        },
        error: (error) => {
          if (error.status === 403) {
            const message = this.translateService.translate('DOCUMENTS.ERROR.USER') +
              this.userService.getUserEmail() +
              this.translateService.translate('DOCUMENTS.ERROR.NO_PERMISSION');
            this.toastService.open(message, ToastType.Error);
          }
        }
      });
  }

  onOpenVersionModal(document: DocumentListInfoModel): void {
    this.openVersionModal.next(document);
  }

  private openDocumentSharingForDocuments(documents: DocumentListInfoModel[]): void {
    if (this.documentModalService) {
      this.documentModalService.openDocumentSharingModalForDocuments(documents);
    }
  }
}
