import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BudgetStructureModule, TranslateModule } from '@kros-sk/ssw-shared-legacy';

import { RangeModalComponent } from './range-modal.component';

@NgModule({
  declarations: [
    RangeModalComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    BudgetStructureModule
  ],
  exports: [
    RangeModalComponent
  ]
})
export class RangeModalModule { }
