import { CalculationType } from '@kros-sk/ssw-shared-legacy';

export class BuildingProgressSetModel {
  projectId: number;
  periodId: number;
  periodItems: BuildingProgressSettedPeriodItem[];
  boqItemIds: number[];
}

export class BuildingProgressSettedPeriodItem {
  budgetItemId: number;
  amount: number;
  totalPrice: number;
  percentage: number;
  calculationType: CalculationType;
  changedProperty: BuildingProgressChangedPropertyToAnimate;
}

export enum BuildingProgressChangedPropertyToAnimate {
  Amount = 1,
  TotalPrice = 2,
  Percentage = 4
}
