import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { APP_CONFIG } from '@kros-sk/app-config';
import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { APP_DOMAIN } from '../application-domain/application-domain.token';
import { ApplicationOpenerService } from './application-opener.service';
import { ExternalApplicationOpenerComponent } from './external-application-opener/external-application-opener.component';
import { LoaderModule } from '../loader';
import { TranslateModule } from '../translate';

@NgModule({
  declarations: [
    ExternalApplicationOpenerComponent
  ],
  imports: [
    CommonModule,
    TooltipDirective,
    TranslateModule.forChild(),
    LoaderModule
  ],
  exports: [
    ExternalApplicationOpenerComponent
  ]
})
export class ApplicationOpenerModule {
  public static forRoot(
    applicationDomain?: string
  ): ModuleWithProviders<ApplicationOpenerModule> {
    return {
      ngModule: ApplicationOpenerModule,
      providers: [
        ApplicationOpenerService,
        {
          provide: APP_DOMAIN,
          useFactory: (appConfig: any) =>
            applicationDomain ? applicationDomain : appConfig.location === 'sk' ? '1' : '2',
          deps: [APP_CONFIG]
        }
      ]
    };
  }
}
