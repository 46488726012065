import { ActivatedRouteSnapshot } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter, Observable } from 'rxjs';

import { LicenseDispatchersService } from '@kros-sk/ssw-shared-legacy';
import { LicenseDispatchersService as LicenseDispatchersServiceCdk, LicenseSelectorsService } from '@kros-sk/ssw-cdk';
import { PermissionDispatchersService } from '@kros-sk/ssw-shared/permission';

import { BuildingProgressDispatchersService } from '../../store/building-progress';

@Injectable({
  providedIn: 'root'
})
export class ProjectDetailGuard {

  private buildingProgressDispatchersService = inject(BuildingProgressDispatchersService);
  private licenseDispatchersService = inject(LicenseDispatchersService);
  private licenseDispatchersServiceCdk = inject(LicenseDispatchersServiceCdk);
  private permissionDispatchersService = inject(PermissionDispatchersService);
  private licenseSelectorsService = inject(LicenseSelectorsService);

  private loadingPermission: boolean;
  private projectId: number;
  private lastProjectUsed: number;

  constructor() {
    this.licenseDispatchersServiceCdk.loadBuildingProgressLicense();
    this.licenseDispatchersServiceCdk.loadDesktopLicense();
    this.buildingProgressDispatchersService.loadLicenseAgreement();
    this.licenseSelectorsService.licenseDetail$.pipe(filter(Boolean), takeUntilDestroyed())
      .subscribe(licenseDetail => this.licenseDispatchersService.setLicenseDetail(licenseDetail));
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    this.loadPermission(+route.paramMap.get('projectId'));
    return true;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean> | true {
    this.loadPermission(+childRoute.parent.params.projectId);
    return true;
  }

  private loadPermission(projectId: number): void {
    if (projectId) {
      this.projectId = projectId;
      if (!this.loadingPermission || (this.projectId !== this.lastProjectUsed)) {
        this.lastProjectUsed = this.projectId;
        this.loadingPermission = true;
        this.buildingProgressDispatchersService.loadPermission(this.projectId);
        this.permissionDispatchersService.loadBudgetPermission(this.projectId);
        this.permissionDispatchersService.loadBuildingProgressPermission(this.projectId);
        this.licenseDispatchersServiceCdk.loadLicenseDetail();
      }
    }
  }
}
