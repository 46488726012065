import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

export class AvatarUser {
  email: string;
  isRegistered: boolean;
}

export enum AvatarSize {
  XSMALL = 'avatar-xs',
  SMALL = 'avatar-s',
  MEDIUM = 'avatar-m',
  LARGE = 'avatar-l',
  EXTRA_LARGE = 'avatar-xl'
}

@Component({
  selector: 'kros-avatar-list',
  templateUrl: './avatar-list.component.html',
  styleUrls: ['./avatar-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarListComponent extends UnsubscribeComponent {
  @Input() ownerMail: string;
  @Input() notOwnerUsers: AvatarUser[];
  @Input() maxAvatarsLimit = 5;
  @Input() size: string | AvatarSize = AvatarSize.SMALL;

  @Output() sharingListClick = new EventEmitter();

  constructor() {
    super();
  }

  getNotOwnerUsers(): AvatarUser[] {
    if (this.notOwnerUsers.length > this.maxAvatarsLimit) {
      return this.notOwnerUsers.slice(0, this.maxAvatarsLimit);
    }
    return this.notOwnerUsers;
  }

  hasReachedMaxAvatarsLimit(): boolean {
    return this.notOwnerUsers.length > this.maxAvatarsLimit;
  }

  isEmptyOwnerEmail(): boolean {
    return this.ownerMail === undefined || this.ownerMail === '';
  }

  getNotOwnerUsersCount(): number {
    return this.notOwnerUsers.length - this.maxAvatarsLimit;
  }

  onSharingListClick(): void {
    this.sharingListClick.emit();
  }
}
