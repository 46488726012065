import { EntityAdapter, EntityState } from '@ngrx/entity';

import { BudgetItem, ChangedBudgetItems, IHasChildren, IParentId } from '@kros-sk/ssw-shared-legacy';
import { BuildingObject } from '@kros-sk/ssw-budget/shared/data-access/models';
import { getChanges, makeRootExpandable } from '@kros-sk/ssw-budget/shared/data-access/store';

import { adapterBuildingObjects, BuildingObjectsState } from './building-objects.state';

export const getBuildingObjectChangesAfterCreate = (
  buildingObjects: BuildingObjectsState,
  changedBudgetItems: ChangedBudgetItems
): BuildingObjectsState => {
  return getChangesAfterCreate<BuildingObject>(
    buildingObjects, adapterBuildingObjects, p => createBuildingObjectFromBudgetItem(p), changedBudgetItems) as BuildingObjectsState;
};

export const getBuildingObjectsChanges = (
  buildingObjects: BuildingObjectsState,
  changedBudgetItems: ChangedBudgetItems,
  hasChildren?: boolean,
  canSetHasChildren?: boolean
): BuildingObjectsState => {
  let ret = getChanges<BuildingObject>(
    buildingObjects,
    adapterBuildingObjects,
    {
      editedItems: changedBudgetItems.editedItems.filter(p => p.id === String(p.buildingObjectId)),
      createdItems: changedBudgetItems.createdItems.filter(p => p.tableItem.id === String(p.tableItem.buildingObjectId)),
      deletedItems: changedBudgetItems.deletedItems.filter(p => p.id === String(p.buildingObjectId))
    },
    p => createBuildingObjectFromBudgetItem(p),
    hasChildren) as BuildingObjectsState;
  if (canSetHasChildren) {
    const objects = Object.values(ret.entities);
    ret = adapterBuildingObjects.updateMany(
      changedBudgetItems.editedItems.map(p => ({
        id: p.id,
        changes: setHasChildrenFlag(p, objects.some(i => i.parentId === p.id))
      })),
      ret);
  }
  return ret;
};

const getChangesAfterCreate = <T extends IHasChildren & IParentId<string>>(
  budgetItems: EntityState<T>,
  adapter: EntityAdapter<T>,
  transformItem: (p) => any,
  changedBudgetItems: ChangedBudgetItems
): EntityState<T> => {
  let ret = budgetItems;

  if (changedBudgetItems.createdItems.length) {
    ret = makeRootExpandable<T>(adapter, ret, true);
    const newItem = changedBudgetItems.createdItems[0].tableItem;
    ret = adapter.updateOne({ id: -1, changes: transformItem(newItem) }, ret);
    ret = adapter.updateMany(changedBudgetItems.editedItems.map(p => ({ id: p.id, changes: transformItem(p) })), ret);
  }

  return ret;
};

const createBuildingObjectFromBudgetItem = (budgetItem: BudgetItem): Partial<BuildingObject> => ({
  id: budgetItem.id,
  parentId: budgetItem.parentId,
  level: budgetItem.level,
  itemType: budgetItem.itemType,
  totalPrice: budgetItem.totalPrice,
  code: budgetItem.code,
  description: budgetItem.description
});

const setHasChildrenFlag = (budgetItem: BudgetItem, hasChildren: boolean): any => ({
  id: budgetItem.id,
  hasChildren
});
