import { EntityState } from '@ngrx/entity';

import { BoqItem } from '@kros-sk/ssw-shared-legacy';

import { ActionInProgress } from '../../budget-approval/enums';
import { boqItemsInitialState } from './budget-approval.adapters';
import {
  BudgetApprovalDifferencesModel, BudgetApprovalModel, BudgetChangeModel, BudgetModel, ChangeSheetModel
} from '../../budget-approval/models';
import { BuildingProgressSharing } from '../../building-progress/models/building-progress-sharing/building-progress-sharing.model';
import { sharingListInitialState } from '../building-progress';

export interface BudgetApprovalState {
  budgetData: BudgetModel;
  budgetDataLoading: boolean,
  budgetApprovalData: BudgetApprovalModel;
  budgetApprovalDataLoading: boolean,
  changeSheetData: ChangeSheetModel;
  changeSheetDataLoading: boolean,
  budgetChangeData: BudgetChangeModel;
  differencesData: BudgetApprovalDifferencesModel;
  budgetChangeDataLoading: boolean,
  selectedChangeSheetItemIds: Set<number>;
  selectedItemsIds: Set<number>;
  sharingList: SharingListState;
  actionInProgress: ActionInProgress;
  boqItems: BoqItemsState;
}

export const initialBudgetApprovalState: BudgetApprovalState = {
  budgetData: undefined,
  budgetDataLoading: false,
  budgetApprovalData: undefined,
  budgetApprovalDataLoading: false,
  budgetChangeData: undefined,
  budgetChangeDataLoading: false,
  changeSheetData: undefined,
  differencesData: undefined,
  changeSheetDataLoading: false,
  selectedChangeSheetItemIds: new Set<number>(),
  selectedItemsIds: new Set<number>(),
  sharingList: sharingListInitialState,
  actionInProgress: ActionInProgress.none,
  boqItems: boqItemsInitialState
};

export type SharingListState = EntityState<BuildingProgressSharing>
export interface BoqItemsState extends EntityState<BoqItem> {
  isLoading: boolean;
}
