import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import * as actions from './license.actions';
import { LicenseDispatchersService } from './license-dispatchers.service';
import { LicenseService } from '../../license/services/license.service';

@Injectable()
export class LicenseEffects {

  getMishaLicense$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadMishaLicenseStart),
      switchMap(() => this.licenseService.getMishaLicense().pipe(
        map(mishaLicense => actions.loadMishaLicenseSuccess({ mishaLicense })),
        catchError(error => of(actions.loadMishaLicenseError({ error })))
      ))
    );
  });

  getBuildingProgressLicense$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadBuildingProgressLicenseStart),
      switchMap((payload) => this.licenseService.getBuildingProgressLicense().pipe(
        map(buildingProgressLicense => actions.loadBuildingProgressLicenseSuccess(
          {
            buildingProgressLicense,
            returnToBuildingProgress: payload.returnToBuildingProgress,
            returnToBudgetApproval: payload.returnToBudgetApproval
          })),
        catchError(error => of(actions.loadBuildingProgressLicenseError({ error })))
      ))
    );
  });

  getBudgetLicense$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadBudgetLicenseStart),
      switchMap(() => this.licenseService.getBudgetLicense().pipe(
        map(budgetLicense => actions.loadBudgetLicenseSuccess({ budgetLicense })),
        catchError(error => of(actions.loadBuildingProgressLicenseError({ error })))
      ))
    );
  });

  getQuantityManagerLicense$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadQuantityManagerLicenseStart),
      switchMap(() => this.licenseService.getQuantityManagerLicense().pipe(
        map(quantityManagerLicense => actions.loadQuantityManagerLicenseSuccess({ quantityManagerLicense })),
        catchError(error => of(actions.loadQuantityManagerLicenseError({ error })))
      ))
    );
  });

  getDesktopLicense$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadDesktopLicenseStart),
      switchMap(() => this.licenseService.getDesktopLicense().pipe(
        map(desktopLicense => actions.loadDesktopLicenseSuccess({ desktopLicense })),
        catchError(error => of(actions.loadDesktopLicenseError({ error })))
      ))
    );
  });

  getLicenseDetail$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadLicenseDetailStart),
      switchMap(() => this.licenseService.getLicenseDetail().pipe(
        map(licenseDetail => actions.loadLicenseDetailSuccess({ licenseDetail })),
        catchError(error => of(actions.loadLicenseDetailError({ error })))
      ))
    );
  });

  activateTrialLicense$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.activateTrialBuildingProgressLicense),
      switchMap((payload) => this.licenseService.createBuildingProgressTrialLicense().pipe(
        map(() => this.licenseDispatchersService.loadBuildingProgressLicense(!payload.isBudgetApproval, payload.isBudgetApproval))
      ))
    );
  }, { dispatch: false });

  activateTrialBudgetLicense$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.activateTrialBudgetLicense),
      switchMap(() => this.licenseService.createBudgetTrialLicense().pipe(
        map(() => this.licenseDispatchersService.loadBudgetLicense())
      ))
    );
  }, { dispatch: false });

  activateTrialQuantityManagerLicense$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.activateTrialQuantityManagerLicense),
      switchMap(() => this.licenseService.createQuantityManagerTrialLicense().pipe(
        map(() => this.licenseDispatchersService.loadQuantityManagerLicense())
      ))
    );
  }, { dispatch: false });

  constructor(
    private actions$: Actions,
    private licenseService: LicenseService,
    private licenseDispatchersService: LicenseDispatchersService
  ) { }
}
