import { Injectable } from '@angular/core';

import { BoqCell, BoqTableKeyboardHandlingService } from '@kros-sk/ssw-shared-legacy';

import { BoqPeriodItem } from '../models/boq-period-item.model';

const expressionColumnId = 2;
const columnCount = 4;

@Injectable()
export class BoqVisualHelper {

  focusedCell: BoqCell;

  constructor(
    private boqKeyboardHandlingService: BoqTableKeyboardHandlingService) { }

  isRowFocused(rowIndex: number): boolean {
    if (this.focusedCell) {
      return rowIndex === this.focusedCell.rowIndex;
    }
    return false;
  }

  isCellFocused(rowIndex: number, colId: number): boolean {
    if (this.focusedCell) {
      return this.isRowFocused(rowIndex) && this.focusedCell.colId === colId;
    }
    return false;
  }

  setFocusedCellOnLoad(items: BoqPeriodItem[], canScroll: boolean): void {
    if (items.some(i => i.id !== 0)) {
      const markedItems = items.filter(i => !!i.periodId);
      if (markedItems?.length > 0) {
        const index = items.indexOf(markedItems[markedItems.length - 1]);
        this.focusedCell = { colId: expressionColumnId, rowIndex: index };
        this.scrollToFocusedCell(canScroll);
      } else {
        this.focusedCell = { colId: expressionColumnId, rowIndex: 0 };
      }
    } else {
      this.focusedCell = { colId: expressionColumnId, rowIndex: 0 };
    }
  }

  setFocusedCell(rowIndex: number, canScroll: boolean, selectionStart?: number): void {
    if (canScroll && rowIndex > -1) {
      this.focusedCell = { colId: this.focusedCell?.colId ?? expressionColumnId, rowIndex };
      this.scrollToFocusedCell(canScroll);
      this.setEditFocus(selectionStart);
    }
  }

  focusCell(itemCount: number, key: string, shiftKey: boolean): void {
    if (this.focusedCell) {
      this.focusedCell = this.boqKeyboardHandlingService.handleTableMovement(this.focusedCell, itemCount, columnCount, key, shiftKey);
      const element = document.getElementById(this.getExpressionId(this.focusedCell.rowIndex));

      setTimeout(() => {
        element.scrollIntoView({ block: 'center', inline: 'start' });
      }, 0);

      if (this.focusedCell.colId === expressionColumnId) {
        const inputElement = document.getElementById(this.getExpressionContentId(this.focusedCell.rowIndex));
        if (!!inputElement) {
          inputElement.focus();
        } else {
          element.focus();
        }
      } else {
        element.focus();
      }
    }
  }

  setEditFocus(selectionStart?: number): void {
    setTimeout(() => {
      const element = document.getElementById(this.getExpressionContentId(this.focusedCell.rowIndex)) as HTMLInputElement;
      element?.focus();
      if (selectionStart >= 0) {
        element?.setSelectionRange(selectionStart, selectionStart);
      }
    }, 100);
  }

  setFocus(): void {
    setTimeout(() => document.getElementById(this.getExpressionId(this.focusedCell.rowIndex))?.focus(), 100);
  }

  scrollToFocusedCell(canScroll: boolean): void {
    if (this.focusedCell && canScroll) {
      setTimeout(() => {
        const element = document.getElementById(this.getExpressionId(this.focusedCell.rowIndex));
        element?.scrollIntoView({ block: 'center', inline: 'nearest' });
      }, 100);
    }
  }

  getExpressionId(index: number): string {
    return 'col-expression-' + index;
  }

  getExpressionContentId(index: number): string {
    return 'expression-content-' + index;
  }
}
