<div class="panel-wrapper">
  <div class="panel-header">
    <h4 class="panel-header-caption">{{'BUILDING_PROGRESS.PERIODS.PERIODS' | translate}}</h4>
    <button
      class="btn btn-link btn-close"
      type="button"
      (click)="editMode ? onCloseEditMode() : onCloseClick()"
      data-test="approval-request-panel-close"
    >
      <span krosMaterialIcon>close</span>
    </button>

    <button
      *ngIf="canAddPeriod"
      id="ga-period-add-panel"
      class="btn btn-outline-primary btn-new"
      type="button"
      (click)="onNewPeriod()"
      data-test="building-progress-new"
    >{{'BUILDING_PROGRESS.PERIODS.NEW' | translate}}</button>
  </div>

  <div
    class="panel-content"
    [class.edit-mode]="editMode"
  >
    <app-periods
      [editMode]="editMode"
      [searchModel]="searchModel"
      [projectId]="projectId"
      [permission]="permission"
      [readonly]="readonly"
      [canOwnerContributorApprove]="canOwnerContributorApprove"
      [contractorId]="contractorId"
      [newPeriodStart]="newPeriodStart"
      (editModeChanged)="onEditModeChanged($event)"
      (periodCreated)="onCloseClick()"
      (periodDeleted)="onPeriodDeleted()"
    ></app-periods>

    <div class="btn-align-right">
      <button
        type="button"
        class="btn btn-link"
        data-test="open-period-history"
        (click)="openPeriodHistory()"
      >{{ 'BUILDING_PROGRESS.PERIODS_HISTORY.PERIODS_HISTORY' | translate }}</button>
    </div>
  </div>
</div>
