export function setMonthToDate(date: Date, month: number): Date {
  const year = date.getFullYear();
  const lastDayOfMonth = new Date(0);

  lastDayOfMonth.setFullYear(year, month + 1, 0);
  lastDayOfMonth.setHours(0, 0, 0);

  date.setMonth(month, Math.min(date.getDate(), lastDayOfMonth.getDate()));

  return date;
}

export function getCurrentYear(): number {
  return new Date().getFullYear();
}

export function getCurrentMonth(): number {
  return new Date().getMonth();
}

export function addMonths(date: Date, months: number): Date {
  return new Date(date.getFullYear(), date.getMonth() + months, 1);
}

export function getStartOfMonth(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function getEndOfMonth(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

export function getMonthList(locale: string): { monthIndex: number, monthName: string }[] {
  const monthList = [...Array(12).keys()];
  const formatter = new Intl.DateTimeFormat(locale, { month: 'long' });
  const getMonthName = (monthIndex: number): string => formatter.format(new Date(getCurrentYear(), monthIndex));

  return monthList.map(m => {
    return {
      monthIndex: m,
      monthName: getMonthName(m)
    };
  });
}
