import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { KrosModalModule } from '@kros-sk/components';

import { InfoModalComponent } from './info-modal/info-modal.component';
import { QuestionModalComponent } from './question-modal/question-modal.component';
import { TranslateModule } from '../translate';

@NgModule({
  declarations: [
    QuestionModalComponent,
    InfoModalComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    KrosModalModule
  ],
  exports: [
    QuestionModalComponent,
    InfoModalComponent
  ]
})
export class ModalsModule { }
