import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { ApplicationType } from '@kros-sk/models';

import { APPLICATION_TYPE_CONFIG } from './kros-auth.config';
import { AuthStoreModule } from './store/auth-store.module';
import { configClientId, configFeatureFlagsVersion, configScope } from './injection-tokens';
import { ExpirableCacheService } from '../expirable-cache/expirable-cache.service';
import { FeatureFlagsService } from './services/feature-flags/feature-flags.service';
import { KROS_IDENTITY_SERVER_CONFIG, KrosIdentityServerConfig } from './services/auth/kros-identity-server.config';
import { KrosAuthGuardService } from './guards/kros-auth-guard.service';
import { KrosAuthInterceptor } from './interceptors/kros-auth-interceptor';
import { KrosAuthService } from './services/auth/kros-auth.service';
import { KrosLocaleInterceptor } from './interceptors/kros-locale.interceptor';
import { LicensesService } from './services/licenses/licenses.service';
import { VatIdValidationService } from '../vat-id-validation/vat-id-validation.service';

export enum FeatureFlagsVersion {
  /**
   * Feature flags are not enabled.
   */
  None = 'None',
  /**
   * Feature flags are stored in the database.
   */
  Original = 'Original',
  /**
   * Newer version. Feature flags are stored in dedicated store slice `featureFlags`.
   * Prefered version, should replace `Original` version.
   */
  WithDedicatedStoreSlice = 'WithDedicatedStoreSlice',
}

/**
 * Loading apiConfig file and obtaining User details from UserManager promise
 *
 * @param authService KrosEswAuthService
 * @param clientId string
 * @param scope string
 * @param applicationType ApplicationType
 * @returns Promise<User>
 */
export const authServiceInitializationFactory = (
  authService: KrosAuthService,
  clientId: string,
  scope: string,
  applicationType: ApplicationType
) => {
  return async (): Promise<void> => {
    return authService.initialize(clientId, scope, applicationType);
  };
};

// @dynamic
@NgModule({ imports: [AuthStoreModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class KrosAuthModule {
  public static forRoot(
    identityServerConfig: KrosIdentityServerConfig,
    clientId: string,
    scope: string,
    applicationType: ApplicationType,
    featureFlagsVersion: FeatureFlagsVersion = FeatureFlagsVersion.Original
  ): ModuleWithProviders<KrosAuthModule> {
    return {
      ngModule: KrosAuthModule,
      providers: [
        KrosAuthService,
        KrosAuthGuardService,
        FeatureFlagsService,
        LicensesService,
        ExpirableCacheService,
        VatIdValidationService,
        {
          provide: APPLICATION_TYPE_CONFIG,
          useValue: applicationType
        },
        {
          provide: configClientId,
          useValue: clientId
        },
        {
          provide: configScope,
          useValue: scope
        },
        {
          provide: configFeatureFlagsVersion,
          useValue: featureFlagsVersion
        },
        {
          provide: KROS_IDENTITY_SERVER_CONFIG,
          useValue: identityServerConfig
        },
        {
          provide: APP_INITIALIZER,
          useFactory: authServiceInitializationFactory,
          deps: [
            KrosAuthService,
            configClientId,
            configScope,
            APPLICATION_TYPE_CONFIG
          ],
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: KrosAuthInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: KrosLocaleInterceptor,
          multi: true
        },
      ]
    };
  }
}
