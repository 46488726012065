import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { UploadNotification } from '../models/upload-notification.model';

@Injectable()
export class UploadNotificationService {
  documentsUploaded = new Subject<UploadNotification>();

  notifyDocumentsUploaded(notification: UploadNotification): void {
    this.documentsUploaded.next(notification);
  }
}
