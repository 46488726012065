import { EntityState } from '@ngrx/entity';

import { BudgetModel } from '../../budget-approval/models';
import { BuildingProgressPeriod } from '../../building-progress/models/building-progress-period.model';
import { BuildingProgressSharing } from '../../building-progress/models/building-progress-sharing/building-progress-sharing.model';
import { sharingListInitialState, subcontractorsSharingListInitialState } from './subcontractors.adapters';
import { Subcontractor } from '../../building-progress/modules/subcontractors/models';

export interface SubcontractorsState {
  subcontractors: Subcontractor[];
  selectedSubcontractor: Subcontractor;
  dialogItemsList: BudgetModel;
  dialogItemsListLoading: boolean;
  sharingList: SharingListState;
  subcontractorsSharingList: SubcontractorsSharingListState;
  subcontractorsPeriods: BuildingProgressPeriod[]
}

export const initialSubcontractorsState: SubcontractorsState = {
  subcontractors: [],
  selectedSubcontractor: undefined,
  dialogItemsList: undefined,
  dialogItemsListLoading: false,
  sharingList: sharingListInitialState,
  subcontractorsSharingList: subcontractorsSharingListInitialState,
  subcontractorsPeriods: undefined
};

export type SharingListState = EntityState<BuildingProgressSharing>
export type SubcontractorsSharingListState = EntityState<BuildingProgressSharing>
