<div class="kros-modal-header">
  <h4 class="kros-modal-title">
    {{ 'COMPANY.COMPANY_LIST' | translate }}
  </h4>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onClose()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div
  class="kros-modal-body companies"
  data-test="company-list"
>
  <div
    *ngFor="let company of companies"
    class="even-row"
    data-test="company-row"
  >
    <div
      class="item-container"
      [class.selected]="company.id === currentCompanyId"
      (click)="onSelectCompany(company.id);"
    >
      <kros-avatar
        class="avatar-s avatar-text"
        [options]="createAvatarOptions(company)"
      ></kros-avatar>

      <div
        class="company-name"
        [title]="company.name"
      >{{ company.name }}</div>

      <span
        *ngIf="company.id === currentCompanyId"
        class="material-icons"
      >done</span>
    </div>
  </div>
</div>

<div class="kros-modal-footer">
  <button
    class="btn btn-primary btn-submit"
    id="ga-company-select"
    type="button"
    (click)="onSubmit()"
    data-test="select-company-button"
  >{{ 'COMPANY.SUBMIT' | translate }}</button>
</div>
