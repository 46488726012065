import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { CalculationType, UndoRedoDispatchersService } from '@kros-sk/ssw-shared-legacy';

import * as buildingProgressActions from './building-progress-boq.actions';
import { BoqEditViewModel, BoqItemViewModel } from '../../building-progress/models/boq-edit.view-model';
import { BoqPeriodItem } from '../../building-progress/models/boq-period-item.model';
import { BoqService } from '../../building-progress/services';
import { BuildingProgressDispatchersService } from './building-progress-dispatchers.service';
import { BuildingProgressPeriodDispatchersService } from './building-progress-period-dispatchers.service';
import { BuildingProgressPeriodItem } from '../../building-progress/models/construction-data.model';
import { createEmptyBoqRecords } from '../../building-progress/helpers/boq.helper';

@Injectable()
export class BuildingProgressBoqEffectsService {

  constructor(
    private boqService: BoqService,
    private buildingProgressDispatchersService: BuildingProgressDispatchersService,
    private buildingProgressPeriodDispatchersService: BuildingProgressPeriodDispatchersService,
    private undoRedoDispatchers: UndoRedoDispatchersService
  ) { }

  setBoq(payload: any, deletedBoqItemIds: number[]): Observable<any> {
    let editModel = {
      ...payload.editModel,
      editedItems: [...(payload.editModel.editedItems.filter(p => !deletedBoqItemIds?.includes(p.id)))],
      createdItems: [...(payload.editModel.createdItems.filter(p => !deletedBoqItemIds?.includes(p.id))
        .map(p => ({ ...p, id: p.id > 0 ? p.id : 0 })))],
      deletedIds: [
        ...payload.editModel.deletedIds,
        ...deletedBoqItemIds?.filter(p => p > 0 && !payload.editModel.deletedIds.includes(p))]
    };
    const oldValue = payload.isUndoRedoOperation
      ? undefined
      : this.createUndoBoqEditViewModel(editModel, payload.originalItems, payload.oldPeriodItem);
    return this.boqService.setBoqItems(editModel).pipe(
      map(resp => {
        if (payload.isUndoRedoOperation) {
          this.buildingProgressPeriodDispatchersService.selectPeriodId(
            payload.oldValue ? payload.oldValue.periodId : payload.editModel.periodId);
          this.buildingProgressDispatchersService.loadBoqPeriodItems(editModel.projectId, editModel.budgetItemId);
        }

        if (resp.createdPeriodBoqItems?.length) {
          this.buildingProgressDispatchersService.setBoqIds(resp.createdPeriodBoqItems);
          if (!payload.isUndoRedoOperation) {
            oldValue.deletedIds = resp.createdPeriodBoqItems.map(p => p.id);
            editModel = { ...editModel, createdItems: resp.createdPeriodBoqItems.map(p => ({ ...p, periodId: p.periodId })) };
          }
        }

        this.dispatchUndoRedoSuccess(payload);

        return buildingProgressActions.setBoqSuccess({
          items: resp.tableItems,
          oldValue,
          editModel,
          isUndoRedoOperation: payload.isUndoRedoOperation,
          boqPeriodItems: resp.resetCalculationType ? createEmptyBoqRecords(editModel.budgetItemId) : []
        });
      })
    );
  }

  private createUndoBoqEditViewModel(
    newModel: BoqEditViewModel,
    originalItems: BoqPeriodItem[],
    oldPeriodItem: BuildingProgressPeriodItem): BoqEditViewModel {
    return {
      amount: oldPeriodItem?.amount ?? 0,
      budgetItemId: newModel.budgetItemId,
      calculationType: oldPeriodItem?.calculationType ?? CalculationType.None,
      createdItems: this.getUndoDeleteBoqItems(originalItems, newModel.deletedIds),
      deletedIds: [],
      editedItems: this.getUndoEditedPeriodItems(newModel.editedItems, originalItems),
      periodId: newModel.periodId,
      projectId: newModel.projectId
    } as BoqEditViewModel;
  }

  private getUndoDeleteBoqItems(originalItems: BoqPeriodItem[], deletedIds: number[]): BoqItemViewModel[] {
    return originalItems.filter(p => deletedIds.includes(p.id)).map(p => ({
      id: p.id > 0 ? p.id : 0,
      periodId: p.periodId,
      isFromBudget: false,
      expression: p.expression,
      quantity: p.quantity,
      boqItemType: p.boqItemType,
      itemOrder: p.itemOrder
    } as BoqItemViewModel));
  }

  private getUndoEditedPeriodItems(editedItems: BoqItemViewModel[], originalItems: BoqPeriodItem[]): BoqItemViewModel[] {
    return editedItems.map(i => {
      const item = originalItems.find(o => o.id === i.id);
      if (item) {
        return {
          ...i,
          periodId: item.periodId,
          expression: item.expression,
          quantity: item.quantity,
          boqItemType: item.boqItemType,
          itemOrder: item.itemOrder
        } as BoqItemViewModel;
      }
    });
  }

  private dispatchUndoRedoSuccess(payload: any): void {
    if (payload.isUndoRedoOperation) {
      if (payload.isUndo) {
        this.undoRedoDispatchers.undoSuccess();
      } else {
        this.undoRedoDispatchers.redoSuccess();
      }
    }
  }
}
