import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { MaintenanceService } from './services/maintenance.service';
import { PipesModule } from '../pipes';
import { UpdateInfoComponent } from './components/update-info/update-info.component';

@NgModule({
  declarations: [
    UpdateInfoComponent,
    MaintenanceComponent,
  ],
  imports: [
    CommonModule,
    PipesModule,
    TranslateModule
  ],
  exports: [
    UpdateInfoComponent,
    MaintenanceComponent,
  ],
  providers: [
    MaintenanceService,
  ]
})
export class MaintenanceModule { }
