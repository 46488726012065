export class DocumentSharingResultInfo {
  result: DocumentShareResultType;
  message: string;
}

export enum DocumentShareResultType {
  Ok = 0,
  DocumentDoesNotExists = 1,
  NoPermissionForChange = 2,
  MultipleEditsForbidden = 3,
  OwnerChangeForbidden = 4
}
