import { createFeatureSelector, createSelector } from '@ngrx/store';

import { BuildingObjectType } from '@kros-sk/ssw-shared-legacy';
import { BuildingProgressPermissionType, PermissionType } from '@kros-sk/ssw-shared/permission';

import {
  adapterBoqItems,
  adapterBoqPeriodItems,
  adapterCommentPositions,
  adapterComments,
  adapterPeriods,
  adapterSharingList
} from './building-progress.adapters';
import { BuildingProgressSharing } from '../../building-progress/models/building-progress-sharing/building-progress-sharing.model';
import { BuildingProgressState } from './building-progress.state';
import { hasContractorRight } from '../../building-progress/shared/building-progress-utils';

export const buildingProgressFeatureKey = 'building-progress';

const selectBuildingProgressFeature = createFeatureSelector<BuildingProgressState>(buildingProgressFeatureKey);

export const selectConstructionData = createSelector(
  selectBuildingProgressFeature,
  state => state.constructionData
);

export const selectConstructionDataDecPlaces = createSelector(
  selectBuildingProgressFeature,
  state => state.constructionData?.decimalPlaces
);

const selectConstructionDataPeriods = createSelector(
  selectBuildingProgressFeature,
  state => state.constructionData?.periods
);

export const selectConstructionDataCurrency = createSelector(
  selectBuildingProgressFeature,
  state => state.constructionData?.currency
);

export const selectPermission = createSelector(
  selectBuildingProgressFeature,
  state => state.permission
);

const selectPeriodsEntities = createSelector(
  selectBuildingProgressFeature,
  state => state.periods
);

const selectWholeBuildingPeriodsEntities = createSelector(
  selectBuildingProgressFeature,
  state => state.wholeBuildingPeriods
);

export const selectPeriods = createSelector(
  selectPeriodsEntities,
  adapterPeriods.getSelectors().selectAll
);

export const selectWholeBuildingPeriods = createSelector(
  selectWholeBuildingPeriodsEntities,
  adapterPeriods.getSelectors().selectAll
);

export const selectPeriodId = createSelector(
  selectBuildingProgressFeature,
  state => state.periodId
);

export const selectPeriod = createSelector(
  selectConstructionDataPeriods,
  selectPeriodId,
  (periods, periodId) => periods?.find(p => p.id === periodId)
);

const selectCommentsEntities = createSelector(
  selectBuildingProgressFeature,
  state => state.comments
);

export const selectComments = createSelector(
  selectCommentsEntities,
  adapterComments.getSelectors().selectAll
);

export const selectCommentsOfSelectedPeriod = createSelector(
  selectConstructionData,
  selectComments,
  selectPeriodId,
  (constructionData, comments, periodId) => {
    const budgetItemIds = new Set<number>(constructionData.items.map(i => i.id));
    return comments.filter(comment => comment.periodId === periodId && budgetItemIds.has(comment.budgetItemId));
  }
);

export const selectCommentsLoading = createSelector(
  selectBuildingProgressFeature,
  state => state.commentsLoading
);

const selectCommentPositionsEntities = createSelector(
  selectBuildingProgressFeature,
  state => state.commentPositions
);

export const selectCommentPositions = createSelector(
  selectCommentPositionsEntities,
  adapterCommentPositions.getSelectors().selectAll
);

const selectSharingListEntities = createSelector(
  selectBuildingProgressFeature,
  state => state.sharingList
);

export const selectSharingList = createSelector(
  selectSharingListEntities,
  adapterSharingList.getSelectors().selectAll
);

export const selectInsertedItem = createSelector(
  selectBuildingProgressFeature,
  state => state.insertedItem
);

export const selectPeriodsLoading = createSelector(
  selectBuildingProgressFeature,
  state => state.periodsLoading
);

export const selectCellToFocusAndAnimate = createSelector(
  selectBuildingProgressFeature,
  state => state.cellToFocusAndAnimate
);

export const selectLastEditedItemIds = createSelector(
  selectBuildingProgressFeature,
  state => state.lastEditedItemIds
);

export const selectSelectedItemIds = createSelector(
  selectBuildingProgressFeature,
  state => state.selectedItemIds
);

export const selectIndeterminateItemIds = createSelector(
  selectBuildingProgressFeature,
  state => state.indeterminateItemIds
);

const selectBoqPeriodItemsEntities = createSelector(
  selectBuildingProgressFeature,
  state => state.boqPeriodItems
);

export const selectBoqPeriodItems = createSelector(
  selectBoqPeriodItemsEntities,
  adapterBoqPeriodItems.getSelectors().selectAll
);

export const selectBoqPeriodItemsIsLoading = createSelector(
  selectBuildingProgressFeature,
  state => state.boqPeriodItems.isLoading
);

export const selectResetOriginalItems = createSelector(
  selectBuildingProgressFeature,
  state => state.resetOriginalItems
);

export const selectEditingBoqBudgetItemIds = createSelector(
  selectBuildingProgressFeature,
  state => state.editingBoqBudgetItemIds
);

const selectBoqItemsEntities = createSelector(
  selectBuildingProgressFeature,
  state => state.boqItems
);

export const selectBoqItems = createSelector(
  selectBoqItemsEntities,
  adapterBoqItems.getSelectors().selectAll
);

export const selectBoqItemsIsLoading = createSelector(
  selectBuildingProgressFeature,
  state => state.boqItems.isLoading
);

export const selectEditedBoqItems = createSelector(
  selectBuildingProgressFeature,
  state => state.editedBoqItems
);

export const selectDeletedBoqItemIds = createSelector(
  selectBuildingProgressFeature,
  state => state.deletedBoqItemIds
);

export const selectDeletingItems = createSelector(
  selectBuildingProgressFeature,
  state => state.deletingItems
);

export const selectHasAnyAddition = createSelector(
  selectBuildingProgressFeature,
  state => state.constructionData?.items.some(i => i.buildingObjectType === BuildingObjectType.Addition)
);

export const selectCanShowFunctions = createSelector(
  selectBuildingProgressFeature,
  (state: BuildingProgressState, props) =>
    canUserSeeFuncs(
      state.constructionData?.periods?.length > 0,
      state.selectedItemIds.size > 0,
      state.sharingList.entities[props.userEmail ?? ''])
);

export const selectCanShowColorDeleteFunction = createSelector(
  selectBuildingProgressFeature,
  (state: BuildingProgressState, props) =>
    canUserSeeColorDeleteFuncs(
      state.selectedItemIds.size > 0,
      state.sharingList.entities[props.userEmail ?? ''])
);

export const selectSettings = createSelector(
  selectBuildingProgressFeature,
  state => state.settings
);

export const selectLicenseAgreement = createSelector(
  selectBuildingProgressFeature,
  state => state.licenseAgreement
);

export const selectPeriodDetails = createSelector(
  selectBuildingProgressFeature,
  state => state.periodDetails
);

export const selectItemPeriodDetails = createSelector(
  selectBuildingProgressFeature,
  state => state.periodDetails?.details
);

export const selectSelectedView = createSelector(
  selectBuildingProgressFeature,
  state => state.selectedView
);

export const selectTableExportSettings = createSelector(
  selectBuildingProgressFeature,
  state => state.tableExportSettings
);

export const selectTableIsBusy = createSelector(
  selectBuildingProgressFeature,
  state => state.tableIsBusy
);

export const selectHasDocuments = createSelector(
  selectBuildingProgressFeature,
  state => state.hasDocuments
);

export const selectHiddenColumns = createSelector(
  selectBuildingProgressFeature,
  state => state.dataSettings?.hiddenColumns
);

export const selectItemDrawSheetExportSettings = createSelector(
  selectBuildingProgressFeature,
  state => state.itemDrawSheetExportSettings
);

export const selectRemainingBudgetExportSettings = createSelector(
  selectBuildingProgressFeature,
  state => state.remainingBudgetExportSettings
);

export const selectSummaryPanelVisible = createSelector(
  selectBuildingProgressFeature,
  state => state.dataSettings?.isSummaryPanelVisible
);

export const selectDataSettings = createSelector(
  selectBuildingProgressFeature,
  state => state.dataSettings
);

export const selectBudgetHasItems = createSelector(
  selectBuildingProgressFeature,
  state => state.budgetCreateState.budgetHasItems
);

export const selectBudgetCreationIsProcessing = createSelector(
  selectBuildingProgressFeature,
  state => state.budgetCreateState.isProcessing
);

export const selectBuildingProgressNoteByIdFunction = (budgetItemId: number): any => createSelector(
  selectBuildingProgressFeature,
  state => state.buildingProgressNotes.entities[budgetItemId]
);

const canUserSeeFuncs = (hasAnyPeriod: boolean, areSelectedItemIds: boolean, userSharing: BuildingProgressSharing): boolean =>
  areSelectedItemIds && hasAnyPeriod && !!userSharing &&
  ((userSharing.buildingProgressPermissionType === BuildingProgressPermissionType.Commenter &&
    userSharing.permissionType >= PermissionType.Contributor) ||
    (hasContractorRight(userSharing.buildingProgressPermissionType) && userSharing.permissionType >= PermissionType.Shared));

const canUserSeeColorDeleteFuncs = (areSelectedItemIds: boolean, userSharing: BuildingProgressSharing): boolean =>
  areSelectedItemIds && !!userSharing &&
  (userSharing.buildingProgressPermissionType === BuildingProgressPermissionType.Commenter || isApproverOrApproverView(userSharing)) &&
  ((userSharing.permissionType === PermissionType.Reader && isApproverOrApproverView(userSharing)) ||
    userSharing.permissionType >= PermissionType.Contributor);

const isApproverOrApproverView = (userSharing: BuildingProgressSharing): boolean =>
  (userSharing.buildingProgressPermissionType |
    BuildingProgressPermissionType.Approver) === userSharing.buildingProgressPermissionType ||
  (userSharing.buildingProgressPermissionType |
    BuildingProgressPermissionType.ApproverView) === userSharing.buildingProgressPermissionType;
