import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import * as actions from '.';
import { InfoMessageState } from '.';

@Injectable()
export class InfoMessageDispatchersService {
  constructor(
    private store: Store<InfoMessageState>
  ) { }

  loadInfoMessages(): void {
    this.store.dispatch(
      actions.loadMessages()
    );
  }

  closeInfoMessage(message: string): void {
    this.store.dispatch(
      actions.closeMessage({ message })
    );
  }
}
