<div
  #toolbar
  class="toolbar-actions"
>
  <div class="actions left">
    <div class="tablet-visible">
      <button
        class="btn toolbar-action gray-toolbar-action no-selection open-panel"
        (click)="onOpenPanel()"
        krosTooltip="{{ 'BUILDING_PROGRESS.BUILDING' | translate }}"
      >
        <i class="material-icons icon-button">list</i>
      </button>
    </div>

    <div class="divider-vertical tablet-visible"></div>

    <button
      *ngIf="isWarningVisible"
      class="btn toolbar-action no-selection no-caption warning ga-warning-inconsistent-rounding"
      type="button"
      (click)="onWarningClick()"
      krosTooltip="{{ 'BUILDING_PROGRESS.WARNING.INCONSISTENT_ROUNDING' | translate }}"
    >
      <i class="material-icons icon-button ga-warning-inconsistent-rounding">error_outline</i>
    </button>

    <span
      *ngIf="areFunctionsAvailable || this.areColorDeleteFunctionsAvailable"
      class="selected-items"
    >{{'COMMON.SELECTED_ITEMS' | translate}}({{selectedItemsCount}}):</span>

    <button
      *ngIf="!areFunctionsAvailable && !areColorDeleteFunctionsAvailable"
      class="btn toolbar-action no-selection no-caption gray-toolbar-action ga-undo"
      type="button"
      [ngClass]="{'disabled-toolbar-action': !canUndo}"
      (click)="undo()"
      krosTooltip="{{ 'COMMON.UNDO' | translate }}"
      data-test="building-progress-undo"
    >
      <i class="material-icons icon-button ga-undo">undo</i>
    </button>

    <button
      *ngIf="!areFunctionsAvailable && !areColorDeleteFunctionsAvailable"
      class="btn toolbar-action no-selection no-caption gray-toolbar-action ga-redo"
      type="button"
      [ngClass]="{'disabled-toolbar-action': !canRedo}"
      (click)="redo()"
      krosTooltip="{{ 'COMMON.REDO' | translate }}"
      data-test="building-progress-redo"
    >
      <i class="material-icons icon-button ga-redo">redo</i>
    </button>

    <button
      *ngIf="canShowPeriods"
      id="open-period"
      class="btn toolbar-action no-selection gray-toolbar-action approval-request"
      type="button"
      (click)="isApprover ? approvePeriod() : openPeriods()"
      krosTooltip="{{ 'BUILDING_PROGRESS.PERIODS.PERIODS' | translate }}"
      data-test="approval-request-toolbar-open"
    >
      <i class="material-icons icon-button">fact_check</i>
      <span class="toolbar-action-text">{{ 'BUILDING_PROGRESS.PERIODS.PERIODS' | translate }}</span>
    </button>

    <button
      *ngIf="areFunctionsAvailable && !isSummaryView"
      class="btn toolbar-action no-selection gray-toolbar-action percentage-draw ga-percentage-draw"
      type="button"
      (click)="percentageDraw()"
      krosTooltip="{{ 'BUILDING_PROGRESS.DRAW.PERCENTAGE' | translate }}"
      data-test="building-progress-percentage-draw"
    >
      <i class="material-icons icon-button ga-percentage-draw">forward_10</i>
      <span class="toolbar-action-text">{{ 'BUILDING_PROGRESS.DRAW.PERCENTAGE' | translate }}</span>
    </button>

    <button
      *ngIf="areFunctionsAvailable && (!isContractor || isContractorFull) && !isSummaryView"
      class="btn toolbar-action no-selection gray-toolbar-action total-price-draw ga-total-price-draw"
      type="button"
      (click)="totalPriceSet()"
      krosTooltip="{{ 'BUILDING_PROGRESS.DRAW.TOTAL_PRICE' | translate }}"
      data-test="building-progress-total-price-draw"
    >
      <i class="material-icons icon-button total-price-draw">money</i>
      <span class="toolbar-action-text">{{ 'BUILDING_PROGRESS.DRAW.TOTAL_PRICE' | translate }}</span>
    </button>

    <button
      *ngIf="canShowComplete && !isSummaryView"
      class="btn toolbar-action no-selection gray-toolbar-action percentage-complete ga-percentage-complete"
      type="button"
      (click)="percentageComplete()"
      krosTooltip="{{ 'BUILDING_PROGRESS.COMPLETE.COMPLETE' | translate }}"
      data-test="building-progress-percentage-complete"
    >
      <i class="material-icons icon-button ga-percentage-complete">insert_chart_outlined</i>
      <span class="toolbar-action-text">{{ 'BUILDING_PROGRESS.COMPLETE.COMPLETE' | translate }}</span>
    </button>

    <button
      *ngIf="canOpenBoq && selectedItemsCount === 0 && !isSummaryView"
      class="btn toolbar-action no-selection gray-toolbar-action ga-open-boq-panel"
      type="button"
      (click)="openBoqClick.emit()"
      krosTooltip="{{ 'BUILDING_PROGRESS.BILL_OF_QUANTITIES' | translate }}"
      data-test="building-progress-open-boq-panel"
    >
      <i class="material-icons icon-button  ga-open-boq-panel">square_foot</i>
      <span class="toolbar-action-text">{{ 'BUILDING_PROGRESS.BILL_OF_QUANTITIES' | translate }}</span>
    </button>

    <ng-container *ngIf="areFunctionsAvailable && !isSummaryView">
      <button
        class="btn toolbar-action no-selection gray-toolbar-action"
        type="button"
        (click)="setSelectedItemsNotCompletedIfCan(true)"
        krosTooltip="{{ 'BUILDING_PROGRESS.LOCK_ITEMS' | translate }}"
        data-test="building-progress-lock-items"
      >
        <i class="material-icons icon-button">lock</i>
        <span class="toolbar-action-text">{{ 'BUILDING_PROGRESS.LOCK_ITEMS' | translate }}</span>
      </button>

      <button
        class="btn toolbar-action no-selection gray-toolbar-action"
        type="button"
        (click)="setSelectedItemsNotCompletedIfCan(false)"
        krosTooltip="{{ 'BUILDING_PROGRESS.UNLOCK_ITEMS' | translate }}"
        data-test="building-progress-unlock-items"
      >
        <i class="material-icons icon-button">lock_open</i>
        <span class="toolbar-action-text">{{ 'BUILDING_PROGRESS.UNLOCK_ITEMS' | translate }}</span>
      </button>
    </ng-container>

    <button
      *ngIf="canShowColorCodes"
      class="btn toolbar-action no-selection gray-toolbar-action color-codes ga-color-codes"
      type="button"
      (click)="openColorCodes()"
      krosTooltip="{{ 'BUILDING_PROGRESS.COLOR_CODE' | translate }}"
      data-test="building-progress-color-codes"
    >
      <i class="material-icons icon-button ga-color-codes">palette</i>
      <span class="toolbar-action-text">{{ 'BUILDING_PROGRESS.COLOR_CODE' | translate }}</span>
    </button>

    <button
      *ngIf="canDelete && selectedItemsCount === 0"
      class="btn toolbar-action no-selection gray-toolbar-action delete-item ga-delete"
      type="button"
      (click)="delete()"
      krosTooltip="{{ 'COMMON.DELETE' | translate }}"
      data-test="building-progress-delete"
    >
      <i class="material-icons icon-button ga-delete">delete</i>
      <span class="toolbar-action-text">{{ 'COMMON.DELETE' | translate }}</span>
    </button>

    <app-building-progress-export-dropdown
      *ngIf="(canShowExport || canShowExportTable) && detail.areConstructionDataLoaded && canShowExportContractorSelectedItems"
      [canShowExport]="canShowExportButton"
      [canShowExportTable]="canShowExportTable"
      [canShowExportConfirmationProtocol]="canShowExportConfirmationProtocol"
      [canShowExportItemDrawSheet]="canShowExportButton"
      [canShowExportToAnotherProject]="canShowExportToAnotherProject"
      (exportInvoiceDetails)="exportInvoiceDetails()"
      (exportToExcel)="exportToExcel()"
      (exportAmountsUniXml)="exportAmountsUniXml()"
      (exportConfirmationProtocol)="exportConfirmationProtocol()"
      (exportItemDrawSheet)="exportItemDrawSheet()"
      (exportRemainingBudget)="exportRemainingBudget()"
      (exportToAnotherProject)="onExportToAnotherProject()"
    ></app-building-progress-export-dropdown>

    <button
      *ngIf="canShowInvoice"
      class="btn toolbar-action no-selection gray-toolbar-action ga-invoice"
      type="button"
      (click)="onOpenCreateInvoice()"
      krosTooltip="{{ 'INVOICE.INVOICE' | translate }}"
      data-test="building-progress-invoice"
    >
      <i class="material-icons icon-button ga-invoice">description</i>
      <span class="toolbar-action-text">{{ 'INVOICE.INVOICE' | translate }}</span>
    </button>

    <button
      *ngIf="canShowDashboard && (!isContractor || isContractorFull)"
      class="btn toolbar-action no-selection gray-toolbar-action ga-dashboard"
      type="button"
      (click)="dashboardClick.emit()"
      krosTooltip="{{ 'BUILDING_PROGRESS.DASHBOARD.DASHBOARD' | translate }}"
      data-test="building-progress-dashboard"
    >
      <i class="material-icons icon-button ga-dashboard">toll</i>
      <span class="toolbar-action-text">{{ 'BUILDING_PROGRESS.DASHBOARD.DASHBOARD' | translate }}</span>
    </button>

    <button
      *ngIf="isConstructionData && canShowGallery && !isContractor"
      class="btn toolbar-action no-selection gray-toolbar-action ga-gallery"
      type="button"
      (click)="onOpenGallery()"
      krosTooltip="{{ 'BUILDING_PROGRESS.PHOTO_GALLERY' | translate }}"
      data-test="building-progress-gallery-toolbar-button"
    >
      <i class="material-icons-outlined icon-button ga-gallery">collections</i>
      <span class="toolbar-action-text">{{ 'BUILDING_PROGRESS.PHOTO_GALLERY' | translate }}</span>
    </button>

    <button
      *ngIf="canShowNotes"
      class="btn toolbar-action no-selection gray-toolbar-action"
      type="button"
      (click)="openNotesClick.emit()"
      krosTooltip="{{ 'NOTES.NOTE' | translate }}"
      data-test="building-progress-notes"
    >
      <i class="material-icons icon-button">sticky_note_2</i>
      <span class="toolbar-action-text">{{ 'NOTES.NOTE' | translate }}</span>
    </button>

    <button
      *ngIf="canShowMore"
      class="btn toolbar-action no-selection gray-toolbar-action dropdown-action export-excel"
      krosTooltip="{{ 'COMMON.MORE' | translate }}"
      type="button"
      [krosDropdownTriggerFor]="more_menu"
      data-test="building-progress-more-dropdown"
    >
      <div class="dropdown-toggle-content">
        <i class="material-icons icon-button">more_vert</i>
        <span class="toolbar-action-text">{{ 'COMMON.MORE' | translate }}</span>
      </div>
    </button>

    <budget-building-objects-upload-modal
      [project]="project"
      [budgetHasItems]="buildingProgressSelectorService.budgetHasItems$ | async"
      launchedFrom="building-progress"
    ></budget-building-objects-upload-modal>

    <ng-template #more_menu>
      <div kros-dropdown>
        <ng-container *ngIf="selectedItemsCount === 0">
          <button
            kros-dropdown-item
            type="button"
            (click)="openColorCodes()"
            data-test="building-progress-color-codes-button"
          >
            <i class="material-icons">palette</i>
            <span>{{ 'BUILDING_PROGRESS.COLOR_CODE' | translate }}</span>
          </button>

          <button
            kros-dropdown-item
            type="button"
            (click)="openNotesClick.emit()"
            data-test="building-progress-notes"
          >
            <i class="material-icons">sticky_note_2</i>
            <span class="toolbar-action-text">{{ 'NOTES.NOTE' | translate }}</span>
          </button>
        </ng-container>

        <ng-container *ngIf="selectedItemsCount === 0 && detail.hasPeriods && !isSummaryView && !isApprover && !isApproverView">
          <div class="dropdown-divider"></div>

          <button
            kros-dropdown-item
            type="button"
            (click)="setSelectedItemsNotCompletedIfCan(true)"
            data-test="building-progress-lock-items"
          >
            <i class="material-icons">lock</i>
            <span>{{ 'BUILDING_PROGRESS.LOCK_ITEMS' | translate }}</span>
          </button>

          <button
            kros-dropdown-item
            type="button"
            (click)="setSelectedItemsNotCompletedIfCan(false)"
            data-test="building-progress-unlock-items"
          >
            <i class="material-icons">lock_open</i>
            <span>{{ 'BUILDING_PROGRESS.UNLOCK_ITEMS' | translate }}</span>
          </button>
        </ng-container>

        <button
          *ngIf="selectedItemsCount > 0"
          type="button"
          kros-dropdown-item
          [krosDropdownTriggerFor]="export_menu_in_more"
          [cdkMenuPosition]="exportMenuInMorePositions"
          data-test="building-progress-export-selected-items-dropdown"
        >
          <i class="material-icons">save_alt</i>
          <span>{{ 'BUILDING_PROGRESS.EXPORT' | translate }}</span>
        </button>

        <ng-container *ngIf="canDeleteInMore">
          <div class="dropdown-divider"></div>

          <button
            class="ga-delete"
            kros-dropdown-item
            type="button"
            (click)="delete()"
            data-test="building-progress-delete-items"
          >
            <i class="material-icons ga-delete">delete</i>
            <span>{{ 'COMMON.DELETE' | translate }}</span>
          </button>
        </ng-container>

        <budget-building-objects-upload-button
          *ngIf="canShowBuildingObjectsUploadButton"
          launchedFrom="building-progress"
          [canShowButton]="isSchoolLicense"
        >
          <ng-container ngProjectAs="button">
            <div class="dropdown-divider"></div>

            <button
              kros-dropdown-item
              type="button"
              data-test="upload-budget-to-building-progress"
              (click)="onUploadBudgetToBuildingProgress()"
            >
              <i class="material-icons">add_business</i>

              <span>{{ 'BUDGET.UPLOAD_TO_BUILDING_PROGRESS' | translate }}</span>
            </button>
          </ng-container>
        </budget-building-objects-upload-button>
      </div>
    </ng-template>

    <ng-template #export_menu_in_more>
      <div kros-dropdown class>
        <button
          kros-dropdown-item
          type="button"
          (click)="exportItemDrawSheet()"
          data-test="building-progress-export-item-draw-sheet-button"
        >
          {{ 'BUILDING_PROGRESS.EXPORT_ITEM_DRAW_SHEET' | translate }}
        </button>
      </div>
    </ng-template>
  </div>

  <div class="toolbar-title"></div>

  <div class="actions right">
    <button
      id="share-period"
      *ngIf="isOwner"
      class="btn toolbar-action no-selection gray-toolbar-action"
      type="button"
      krosTooltip="{{ 'BUILDING_PROGRESS.SHARE' | translate }}"
      (click)="shareBuildingProgress()"
      data-test="building-progress-open-sharings"
    >
      <i class="material-icons icon-button">person_add</i>
    </button>

    <ng-container *ngIf="notOwnerUsers.length > 0 && !permissionHelper.isContractor">
      <kros-avatar-list
        class="avatars"
        [ownerMail]="ownerMail"
        [notOwnerUsers]="notOwnerUsers"
        [maxAvatarsLimit]="2"
        (click)="shareBuildingProgress()"
        data-test="sharing-avatars"
      ></kros-avatar-list>
    </ng-container>

    <button
      id="comment-period"
      *ngIf="isConstructionData"
      class="btn toolbar-action no-selection gray-toolbar-action"
      type="button"
      krosTooltip="{{ 'BUILDING_PROGRESS.OPEN_COMMENTS' | translate }}"
      (click)="openCommentsClick.emit()"
      data-test="building-progress-show-comment-button"
    >
      <i class="material-icons icon-button">comment</i>
    </button>
  </div>
</div>
