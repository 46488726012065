import { Component } from '@angular/core';

import { KrosModalRef } from '@kros-sk/components';

@Component({
  selector: 'kros-question-modal',
  templateUrl: './question-modal.component.html',
  styleUrls: ['./question-modal.component.scss']
})
export class QuestionModalComponent {
  data: {
    warning: string,
    body: string,
    confirmButton: string,
    cancelButton: string,
    noMaxWidth: boolean
  };

  constructor(
    private modalRef: KrosModalRef
  ) {
    this.data = this.modalRef.data;
  }

  onNoClick(): void {
    this.modalRef.cancel();
  }

  onYesClick(): void {
    this.modalRef.submit();
  }
}
