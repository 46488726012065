import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { combineLatest, filter } from 'rxjs';

import { isOwnersEmail, ProjectDetail } from '@kros-sk/ssw-shared-legacy';
import { PermissionType } from '@kros-sk/ssw-shared/permission';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { BuildingProgressActionAccessService } from '../../../../building-progress/services/building-progress-action-access.service';
import { BuildingProgressSelectorsService } from '../../../../store/building-progress';
import { DetailHelper, PermissionHelper } from '../../../../building-progress/helpers';
import { ProjectsSelectorsService } from '../../../../store/projects';
import { SelectedView } from '../../../../building-progress/models/selected-view.model';
import { SubcontractorsDispatchersService, SubcontractorsSelectorsService } from '../../../../store/subcontractors';
import { ViewType } from '../../../../building-progress/services/building-progress-views.service';

@Component({
  selector: 'app-subcontractors-toolbar',
  templateUrl: './subcontractors-toolbar.component.html',
  styleUrls: ['./subcontractors-toolbar.component.scss']
})
export class SubcontractorsToolbarComponent extends UnsubscribeComponent implements OnInit {
  @Input() hasItems: boolean;
  @Input() canEditSubcontractors: boolean;
  @Input() canOpenBoq: boolean;
  @Input() isOwnerOrContributor: boolean;
  @Input() view: SelectedView;

  @Output() openCommentsClick = new EventEmitter<void>();
  @Output() openPeriodsClick = new EventEmitter<{ isApproveMode: boolean }>();
  @Output() shareClick = new EventEmitter<void>();
  @Output() addItemsClick = new EventEmitter<void>();
  @Output() removeItemsClick = new EventEmitter<void>();
  @Output() openTransferExecutionsClick = new EventEmitter<void>();
  @Output() openBoqClick = new EventEmitter<void>();
  @Output() exportToExcelClick = new EventEmitter<void>();
  @Output() exportInvoiceDetailsClick = new EventEmitter<void>();
  @Output() openNotesClick = new EventEmitter<void>();
  @Output() exportAmountsUniXmlClick = new EventEmitter<void>();
  @Output() exportRemainingBudgetClick = new EventEmitter<void>();
  @Output() dashboardClick = new EventEmitter<void>();

  isOwner: boolean;
  ownerMail = '';
  notOwnerUsers: { email: string, isRegistered: boolean }[] = [];

  get selectedItemsCount(): number {
    return !!this.selectedItemIds ? this.selectedItemIds.size : 0;
  }

  get canShowExport(): boolean {
    return !this.detail.isEmptyContent && !this.detail.isViewOrSearchActive && !this.isSummaryView && this.selectedItemsCount === 0;
  }

  get canShowExportTable(): boolean {
    return this.detail.areConstructionDataLoaded && this.detail.itemCount !== 0 && this.selectedItemsCount === 0;
  }

  get isSummaryView(): boolean {
    return this.view.viewType === ViewType.buildingObjectsSummary || this.view.viewType === ViewType.sectionsSummary;
  }

  get canShare(): boolean {
    return this.isOwner &&
      !(this.buildingProgressActionAccessService.isLicenseFree || this.buildingProgressActionAccessService.isLicenseReadOnly);
  }

  private selectedItemIds: Set<number>;

  constructor(
    public dispatchersService: SubcontractorsDispatchersService,
    public selectorsService: SubcontractorsSelectorsService,
    protected permissionHelper: PermissionHelper,
    private buildingProgressSelectorService: BuildingProgressSelectorsService,
    private buildingProgressActionAccessService: BuildingProgressActionAccessService,
    private projectsSelectorsService: ProjectsSelectorsService,
    private detail: DetailHelper
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.projectsSelectorsService.projectDetail$
      .subscribe((project: ProjectDetail) => {
        if (project) {
          this.isOwner = isOwnersEmail(project.owner);
          this.ownerMail = this.isOwner ? '' : project.owner;
        }
      });

    this.subs.sink = combineLatest([this.selectorsService.selectedSubcontractor$, this.projectsSelectorsService.projectDetail$])
      .pipe(filter(([s, p]) => !!s && !!p))
      .subscribe(([s, p]) => {
        this.dispatchersService.loadSharingList(p.id, s.id);
      });

    this.subs.sink = this.selectorsService.sharingList$
      .subscribe(sharingList => {
        if (sharingList) {
          this.notOwnerUsers = sharingList
            .filter(p => p.permissionType !== PermissionType.Owner)
            .map(p => {
              return { email: p.email, isRegistered: p.isRegisteredUser };
            });
        }
      });

    this.subs.sink = this.buildingProgressSelectorService.selectedItemIds$.subscribe(itemIds => {
      if (itemIds && itemIds instanceof Set) {
        this.selectedItemIds = itemIds;
      }
    });
  }

  openComments(): void {
    this.openCommentsClick.emit();
  }

  openPeriods(): void {
    if (this.buildingProgressActionAccessService.checkForFreeOwner()) {
      this.openPeriodsClick.emit();
    }
  }

  share(): void {
    this.shareClick.emit();
  }

  addItems(): void {
    this.addItemsClick.emit();
  }

  removeItems(): void {
    this.removeItemsClick.emit();
  }

  openTransferExecutions(): void {
    if (this.buildingProgressActionAccessService.checkForFreeOwner()) {
      this.openTransferExecutionsClick.emit();
    }
  }

  openBoq(): void {
    this.openBoqClick.emit();
  }

  exportToExcel(): void {
    if (this.buildingProgressActionAccessService.checkForAccessToExport()) {
      this.exportToExcelClick.emit();
    }
  }

  exportInvoiceDetails(): void {
    if (this.buildingProgressActionAccessService.checkForAccessToExport()) {
      this.exportInvoiceDetailsClick.emit();
    }
  }

  exportAmountsUniXml(): void {
    if (this.buildingProgressActionAccessService.checkForAccessToExport()) {
      this.exportAmountsUniXmlClick.emit();
    }
  }

  exportRemainingBudget(): void {
    if (this.buildingProgressActionAccessService.checkForAccessToExport()) {
      this.exportRemainingBudgetClick.emit();
    }
  }
}
