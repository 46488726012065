import { InjectionToken } from '@angular/core';

export const ZIP_SETTINGS_TOKEN = new InjectionToken<ZipSettings>('ZipSettings');

export interface ZipSettings {
  enabled: boolean,
  minimumSize: number, // VELKOST V MB
  extensionsToZip: string[],
  excludedExtensions: string[]
}
