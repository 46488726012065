import { createAction, props } from '@ngrx/store';

import { LicenseDetail } from '@kros-sk/ssw-cdk';

import { LicenseInfo } from '../../license';

export const clearState = createAction('[License] Clear license state');

export const setMishaLicenseInfo = createAction(
  '[License] Set mishaLicenseInfo',
  props<{ mishaLicenseInfo: LicenseInfo }>());

export const setBuildingProgressLicenseInfo = createAction(
  '[License] Set buildingProgressLicenseInfo',
  props<{ buildingProgressLicenseInfo: LicenseInfo }>());

export const setBudgetLicenseInfo = createAction(
  '[License] Set budgetLicenseInfo',
  props<{ budgetLicenseInfo: LicenseInfo }>());

export const setQuantityManagerLicenseInfo = createAction(
  '[License] Set quantityManagerLicenseInfo',
  props<{ quantityManagerLicenseInfo: LicenseInfo }>());

export const clearMishaLicenseInfo = createAction('[License] Clear mishaLicenseInfo');

export const clearBuildingProgressLicenseInfo = createAction('[License] Clear buildingProgressLicenseInfo');

export const clearBudgetLicenseInfo = createAction('[License] Clear budgetLicenseInfo');

export const clearQuantityManagerLicenseInfo = createAction('[License] Clear quantityManagerLicenseInfo');

export const setLicenseDetail = createAction(
  '[License] Set license detail',
  props<{ licenseDetail: LicenseDetail }>());
