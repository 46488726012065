import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { ApplicationRoute, TimelineType, TranslateService } from '@kros-sk/ssw-shared-legacy';
import { FeatureFlags, HasFeatureFlagFacade } from '@kros-sk/core/feature-flags';
import { KrosModalService, MessageTypes } from '@kros-sk/components';

import { BuildingProgressActionAccessService } from '../../services/building-progress-action-access.service';
import { BuildingProgressDemoService } from '../../services';
import { BuildingProgressDispatchersService, BuildingProgressSelectorsService } from '../../../store/building-progress';
import { environment } from '../../../../environments/environment';
import { LicenseHelper, PermissionHelper } from '../../helpers';

@Component({
  selector: 'ssw-building-progress-create',
  templateUrl: './building-progress-create.component.html',
  styleUrls: ['./building-progress-create.component.scss']
})
export class BuildingProgressCreateComponent implements OnInit {

  protected selectors = inject(BuildingProgressSelectorsService);
  private destroyRef = inject(DestroyRef);
  private dispatchers = inject(BuildingProgressDispatchersService);
  private appInsightsService = inject(AppInsightsBaseService);
  private budgetDemoService = inject(BuildingProgressDemoService);
  private buildingProgressActionAccessService = inject(BuildingProgressActionAccessService);
  private permisionHelper = inject(PermissionHelper);
  private krosModalService = inject(KrosModalService);
  private translateService = inject(TranslateService);
  private router = inject(Router);
  private licenseHelperService = inject(LicenseHelper);
  private readonly featureFlagFacade = inject(HasFeatureFlagFacade);

  @Input() projectId: number;
  @Input() isDemo: boolean;

  timelineType = TimelineType;

  get hasBudgetAccess(): boolean {
    return this.licenseHelperService.isSchoolLicense || this.isBudgetMini;
  }

  protected isProcessing$ = this.selectors.budgetCreationIsProcessing$;

  protected get emptyContentDescription(): string {
    return environment.location === 'cz' ? 'BUILDING_PROGRESS.SOFTWARE_K4_CZ' : 'BUILDING_PROGRESS.SOFTWARE_K4_SK';
  }

  protected get emptyContentLink(): string {
    return environment.location === 'cz'
      ? 'https://www.urs.cz/software-a-data/kros-4-ocenovani-a-rizeni-stavebni-vyroby'
      : 'https://www.kros.sk/cenkros/';
  }

  private isBudgetMini: boolean;

  ngOnInit(): void {
    this.featureFlagFacade.featureFlags$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((ff: FeatureFlags) => this.isBudgetMini = ff.BudgetMini);
  }

  onStartBudgetCreation(hasBudget: boolean): void {
    if (this.permisionHelper.isAtLeastContributor &&
      this.buildingProgressActionAccessService.checkForAccessCauseOfReadonlyOrFreeLicense(false)) {
      this.startBudgetCreation(hasBudget);
    } else if (this.permisionHelper.isLessThanContributor) {
      this.openNoPermissionDialog();
    }
  }

  protected canShowOpenDemoLink(): boolean {
    return this.budgetDemoService.canShowOpenDemoLink(this.isDemo);
  }

  protected onOpenDemo(): void {
    this.budgetDemoService.onOpenDemo();
  }

  private startBudgetCreation(hasBudget: boolean): void {
    this.appInsightsService.trackEvent('PV-StartBudgetCreation');

    if (hasBudget) {
      this.dispatchers.startBudgetCreation(this.projectId);
    } else {
      this.showBudget();
    }
  }

  private showBudget(): void {
    this.router.navigate(['projects/project-detail', this.projectId, ApplicationRoute.Budget]);
  }

  private openNoPermissionDialog(): void {
    this.krosModalService.openModalMessageBoxModalRef({
      icon: 'warning',
      caption: this.translateService.translate('BUILDING_PROGRESS.NO_EDIT_PERMISSION_CAPTION'),
      message: this.translateService.translate('BUILDING_PROGRESS.NO_EDIT_PERMISSION_MESSAGE'),
      messageType: MessageTypes.Warning,
      acceptButton: this.translateService.translate('COMMON.CLOSE')
    });
  }
}
