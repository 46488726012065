import { HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';

export type HTTPStatusCode = string;

export interface ErrorsOptions {
  status?: HTTPStatusCode[] | HTTPStatusCode;
}

// handle class errors
export function HandleClassErrors(options: ErrorsOptions): Function {
  if (!Array.isArray(options.status)) {
    options.status = [options.status];
  }

  return (target): any => {
    target.prototype.globalCriticalOptions = options;
    target.prototype.currentCriticalOptions = options;
    return target;
  };
}

// handle method errors for status
// WARNING: Pay attention to the case when this decorator decorates the method in the children class that extends
// RequestsWithErrorHandling. Then after the first successful request without an error, something happens with currentCriticalOptions
// and the errorHandler starts showing error toasts even for the status code configured by this decorator
export function HandleMethodErrors(options: ErrorsOptions): Function {
  if (!Array.isArray(options.status)) {
    options.status = [options.status];
  }

  return (target, key, descriptor): any => {
    const originalMethod: Function = descriptor.value;

    descriptor.value = function(this, ...args): any {
      target.currentCriticalOptions = options;
      const result = originalMethod.apply(this, args);
      target.currentCriticalOptions = target.globalCriticalOptions;
      return result;
    };
    return descriptor;
  };
}

/**
 * All requests from this service/class will be ignored by HttpErrorHandlingInterceptor.
 */
export function IgnoreHttpInterceptorOnClass(): Function {
  return HandleClassErrors({ status: '*'});
}

/**
 * Request from this method will be ignored by HttpErrorHandlingInterceptor.
 */
export function IgnoreHttpInterceptor(): Function {
  return HandleMethodErrors({ status: '*'});
}

export function ignoreHttpGlobalErrorHandler(
  status: HTTPStatusCode[] | HTTPStatusCode,
  headers?: HttpHeaders
): Observable<HttpHeaders> {
  const statusAsArray = Array.isArray(status) ? status : [status];

  const newHeaders = (headers || new HttpHeaders())
    .set('kros-handle-status', statusAsArray);

  return of(newHeaders);
}
