import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { APP_DOMAIN } from '../application-domain/application-domain.token';
import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

import {
  ApplicationModel,
  ApplicationOpenerResultState,
  ExternalApplicationModel,
  IfcOpeningModel,
  PreparingExternalApplicationModel
} from './models';
import { InternalApplications } from '../enums';

const applicationApi = '/api/externalApplicationsService/';
const prepareApplicationUrlEndpoint = 'externalApplications/redirectUrl';
const prepareApplicationUrlListEndpoint = 'externalApplications/redirectUrlList';
const loadApplicationListEndpoint = 'externalApplications/applicationList';
const registerStavarioEndpoint = 'externalApplications/stavarioRegister';
const temporaryDataCreateEndpoint = 'launcher/temporaryDataCreate';

@Injectable()
export class ApplicationOpenerService {

  private appConfig = inject(APP_CONFIG);
  private appInsightsService = inject(AppInsightsBaseService);
  private applicationDomain = inject(APP_DOMAIN);
  private http = inject(HttpClient);
  private router = inject(Router);

  private applicationList: ApplicationModel[];

  private get platformApiUrl(): string {
    return this.appConfig.gatewayApiUrl + applicationApi;
  }

  getApplicationList(): Observable<ApplicationModel[]> {
    if (!this.applicationList) {
      const applicationList$ = this.loadApplicationList();

      if (applicationList$) {
        return applicationList$.pipe(
          tap(
            res => this.applicationList = res
          )
        );
      } else {
        return of();
      }
    }
    return of(this.applicationList);
  }

  getApplicationDisplayName(app: ApplicationModel, langId: string): string {
    return app.displayName ? app.displayName[langId] : '';
  }

  openExternalApplication(data: PreparingExternalApplicationModel): void {
    const url = this.router.serializeUrl(this.router.createUrlTree(['open-application']));
    const newWindow = window.open(url, '_blank');
    this.prepareExternalApplication(data)
      .pipe(catchError(this.navigateToError.bind(this, newWindow)))
      .subscribe(
        res => {
          if (res.state === ApplicationOpenerResultState.Success) {
            newWindow.location.href = res.redirectUrl;
          } else {
            this.navigateToError(newWindow);
          }
        }
      );
  }

  openCadApplication(
    appId: string,
    projectId: number,
    openPdf: boolean,
    documentId: string,
    documentVersionId: string,
    measurementId?: number
  ): void {
    let url = this.appConfig.appUrls.launcherUrl + '/launchCad/' + appId + '/' +
      +projectId + '/'
      + (openPdf ? 'pdf' : 'cad') + '/'
      + encodeURIComponent(documentId) + '/'
      + encodeURIComponent(documentVersionId);
    if (measurementId != null) {
      url += `/${measurementId}`;
    }
    window.open(url, '_blank');
  }

  openCadComparator(
    appId: string,
    projectId: number,
    openPdf: boolean,
    documentId: string,
    documentVersionId: string,
    secondDocumentVersionId: string
  ): void {
    const url = this.appConfig.appUrls.launcherUrl + '/compareCad/' + appId + '/' +
      +projectId + '/'
      + (openPdf ? 'pdf' : 'cad') + '/'
      + encodeURIComponent(documentId) + '/'
      + encodeURIComponent(documentVersionId) + '/'
      + encodeURIComponent(secondDocumentVersionId);
    window.open(url, '_blank');
  }

  openComparator(
    appId: string,
    projectId: number,
    documentId: string,
    oldVersionId: string,
    newVersionId: string,
    isPdf: boolean
  ): void {
    if (appId === InternalApplications.bimComparatorApplicationId) {
      const data = {
        appId: InternalApplications.bimComparatorApplicationId,
        documentVersionIds: [oldVersionId, newVersionId],
        projectId,
        documentId
      } as IfcOpeningModel;

      this.appInsightsService.trackEvent('BIMP-compare-documents-open-comparator');
      this.openInternalIfcApplication(data);
    } else if (appId === InternalApplications.quantityComparatorApplicationId) {
      this.appInsightsService.trackEvent('QM-compare-documents-open-comparator');
      this.openCadComparator(appId, projectId, isPdf, documentId, newVersionId, oldVersionId);
    }
  }

  openQuantityManagerWithBoq(projectId: number, boqId?: number): void {
    const url = this.appConfig.appUrls.quantityManagerAppUrl + '/project/' + projectId + '?boqId=' + boqId;
    window.open(url, '_blank');
  }

  openGallery(projectId: number, periodId: number): void {
    let queryParamsString = new HttpParams();
    queryParamsString = queryParamsString.set('periodId', periodId?.toString());
    const url = `${this.appConfig.appUrls.documentViewerUrl}/projects/${projectId}/photo-gallery${periodId ? `?${queryParamsString}` : ''}`;
    window.open(url, '_blank');
  }

  openConstruction(projectId: number, currentVersionId: string): void {
    window.open(
      `${this.appConfig.appUrls.documentViewerUrl}/projects/${projectId}/construction/${encodeURIComponent(currentVersionId)}`, '_blank'
    );
  }

  openInternalIfcApplication(ifcOpeningModel: IfcOpeningModel): void {
    const newWindow = window.open('', '_blank');
    this.createTemporaryData(ifcOpeningModel)
      .subscribe(result => {
        const url = this.appConfig.appUrls.launcherUrl + '/launchIfc/' + result;
        newWindow.location.href = url;
      });
  }

  registerStavario(companyName: string): Observable<ExternalApplicationModel> {
    return this.http.post<ExternalApplicationModel>(this.platformApiUrl + registerStavarioEndpoint, { companyName });
  }

  prepareExternalApplicationList(data: PreparingExternalApplicationModel): Observable<ExternalApplicationModel[]> {
    return this.http.post<ExternalApplicationModel[]>(this.platformApiUrl + prepareApplicationUrlListEndpoint, data);
  }

  private createTemporaryData(ifcOpennigData: IfcOpeningModel): Observable<string> {
    return this.http.post<string>(this.platformApiUrl + temporaryDataCreateEndpoint, ifcOpennigData);
  }

  private navigateToError(window: Window): void {
    const urlError = this.router.serializeUrl(this.router.createUrlTree(['open-application', 'error']));
    window.location.href = urlError;
  }

  private loadApplicationList(): Observable<ApplicationModel[]> {
    return this.http.get<ApplicationModel[]>(
      this.platformApiUrl + loadApplicationListEndpoint + '/' + this.applicationDomain
    );
  }

  private prepareExternalApplication(data: PreparingExternalApplicationModel): Observable<any> {
    return this.http.post(this.platformApiUrl + prepareApplicationUrlEndpoint, data);
  }
}
