import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'kros-titan-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent {

  @Input() email: string;
  @Input() icon: string;

  @HostBinding('class.gray')
  get _isGray(): boolean {
    return !!this.icon;
  }

  get emailFirstLetter(): string {
    if (!this.email) {
      return '';
    }

    return this.email.charAt(0).toUpperCase();
  }
}
