import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({ selector: '[krosIndeterminate]' })
export class IndeterminateCheckboxDirective {
  constructor(private renderer: Renderer2, private element: ElementRef) {
  }

  @Input()
  set krosIndeterminate(value: boolean) {
    if (value) {
      this.renderer.addClass(this.element.nativeElement, 'indeterminate-checkbox');
    } else {
      this.renderer.removeClass(this.element.nativeElement, 'indeterminate-checkbox');
    }
  }
}
