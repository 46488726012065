import { Component, DestroyRef, ElementRef, HostListener, inject, Renderer2 } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { delay, filter, from, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxTextAreaComponent } from 'devextreme-angular/ui/text-area';
import { DxTreeListComponent } from 'devextreme-angular/ui/tree-list';

import { PermissionType } from '@kros-sk/ssw-shared/permission';

import { AnimateCell } from '../models';
import { codeFieldName, emptyKey, isDataFieldRequired, itemTypeFieldName } from '../helpers/table.helper';
import { IId, isBuildingObject } from '../../budget/helpers';
import { SetTypeService } from '../services';

@Component({ template: '' })
export abstract class TableBaseComponent<TId extends string | number, T extends IId<TId>> {
  permissionType = PermissionType;
  isAddingNewItem: boolean;
  isValidField: boolean;
  isInSelection = false;
  selectedRowKeys: TId[] = [];
  focusedCell: { rowIndex: number, columnIndex: number };
  autoNavigateToFocusedRow = true;
  focusedRowKey: TId;

  protected renderer = inject(Renderer2);
  protected elementRef = inject(ElementRef);
  protected setTypeService = inject(SetTypeService, { optional: true });
  protected destroyRef = inject(DestroyRef);

  protected dataSource: T[];
  protected abstract get dataComponent(): DxTreeListComponent | DxDataGridComponent;
  protected abstract get isLastRowFocused(): boolean;
  protected abstract get selectedItemId(): TId;

  protected get isSelected(): boolean {
    return this.selectedRowKeys.length > 0;
  }

  protected get isRowRenderingVirtual(): boolean {
    return this.dataComponent?.scrolling.rowRenderingMode === 'virtual';
  }

  private get isTabInBackground(): boolean {
    return document.hidden || document.visibilityState === 'hidden';
  }

  private canEditItem = true;
  private selectId: TId;
  private itemToSelect: T;

  @HostListener('document:visibilitychange')
  onVisibilityChange(): void {
    if (!this.isTabInBackground && this.itemToSelect) {
      this.select(this.itemToSelect, false, undefined, true, true);
      this.itemToSelect = undefined;
    }
  }

  onToolbarPreparing(e: any): void {
    e.toolbarOptions.visible = false;
  }

  isInvalidCell(cell: any): boolean {
    return !cell.text && isDataFieldRequired(cell.column.dataField, cell.row.data.itemType);
  }

  canShowDeleteButton(cell: any): boolean {
    return this.isAddingNewItem && cell.key === emptyKey;
  }

  onTextAreaValueChanged(value: string, cellInfo: any): void {
    if (cellInfo.value !== value) {
      cellInfo.setValue(value);
      cellInfo.component.updateDimensions();
      if (isBuildingObject(cellInfo.data) && cellInfo.column.dataField === 'description' && this.isValidField) {
        cellInfo.component.saveEditData();
      }
    }
  }

  onTextAreaInput(cellInfo: any): void {
    cellInfo.component.updateDimensions();
  }

  onEditorPreparing(e: any): void {
    if (e.editorName === 'dxNumberBox') {
      e.editorOptions.step = 0;
    }
  }

  onSaving(e: any): void {
    this.save(e.changes[0]);
    e.cancel = true;
  }

  onDeleteEmptyRow(): void {
    if (this.isAddingNewItem) {
      this.selectById(this.getNewIdAfterDelete(emptyKey));
      this.deleteEmpty();
      this.isAddingNewItem = false;
    }
  }

  insertCustomItem(selectedItem: boolean, openMenu = false): void {
    if (this.dataComponent && !this.dataComponent.instance.hasEditData()) {
      const dataSource = this.dataComponent.instance.getDataSource();
      from(this.isAddingNewItem ? dataSource.store().byKey(emptyKey) : of(null)).pipe(
        filter(p => !p && selectedItem),
        take(1),
        tap(() => this.customItem()),
        delay(0),
        switchMap(() => {
          this.isAddingNewItem = true;
          this.dataComponent.instance.selectRowsByIndexes([this.dataComponent.instance.getRowIndexByKey(emptyKey)]);
          return this.dataComponent.instance.navigateToRow(emptyKey);
        }),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(() => {
        const rowIndex = this.dataComponent.instance.getRowIndexByKey(emptyKey);
        if (openMenu) {
          this.dataComponent.instance.editCell(rowIndex, itemTypeFieldName);
          this.setTypeService.openMenu(rowIndex);
        } else {
          this.dataComponent.instance.editCell(rowIndex, codeFieldName);
        }
      });
    }
  }

  onFocusedCellChanged(e: any): void {
    this.focusedCell = { rowIndex: e.row?.rowIndex, columnIndex: e.columnIndex };
    if (this.isAddingNewItem || e.row?.data?.id === emptyKey) {
      e.cancel = true;
    } else if (e.row) {
      this.select(e.row.data, true, e.columnIndex);
    }
  }

  onContentReady(e: any): void {
    this.canEditItem = true;
    if (this.selectId !== undefined) {
      const id = this.selectId;
      setTimeout(() => this.select(this.dataSource.find(i => i.id === id), true, this.focusedCell?.columnIndex, true));
      this.selectId = undefined;
    } else if (this.selectedItemId) {
      this.checkFocusedRow(e);
    }
  }

  updateTextAreaHeight(textAreaComponent: DxTextAreaComponent): void {
    (textAreaComponent.instance as any)._dimensionChanged();
  }

  navigateToRow(item: any, canNavigateToRow = true, canScrollToCenter = false): Observable<number> {
    const rowIndex = this.dataComponent?.instance.getRowIndexByKey(item?.id);

    if (item && rowIndex === -1 && canNavigateToRow && this.isRowRenderingVirtual && this.dataComponent?.instance) {
      return from(this.dataComponent.instance.navigateToRow(item.id))
        .pipe(
          take(1),
          switchMap(() => this.dataComponent?.instance.getRowIndexByKey(item.id) === -1
            ? from(this.dataComponent.instance.navigateToRow(item.id))
            : of(null)),
          tap(() => this.scrollToCenter(item.id)),
          delay(300),
          map(() => this.dataComponent?.instance.getRowIndexByKey(item.id)));
    } else {
      if (canScrollToCenter) {
        return of(rowIndex)
          .pipe(
            tap(() => this.scrollToCenter(item.id)),
            delay(300));
      } else {
        return of(rowIndex);
      }
    }
  }

  refresh(): void {
    setTimeout(() => this.dataComponent.instance.updateDimensions());
  }

  protected checkFocusedRow(e: any): void {
    if (!e.component.isRowFocused(this.selectedItemId)) {
      this.selectRow(this.selectedItemId);
    }
  }

  protected cancelDragging(e: any, targetNode: any): boolean {
    return false;
  }

  protected resetState(): void {
    if (this.dataComponent?.instance) {
      this.dataComponent?.instance.state({
        ...this.dataComponent?.instance.state(),
        expandedRowKeys: [],
        selectedRowKeys: []
      });
    }
  }

  protected select(item: any, dispatchAction = false, columnIndex?: number, canNavigateToRow = false, forceSelect = false): void {
    if (item && (this.selectedItemId !== item.id || forceSelect)) {
      if (dispatchAction) {
        this.dispatchActionSelectItem(item);
      }
      if (this.isTabInBackground) {
        this.itemToSelect = item;
      } else if (this.dataComponent?.instance && !this.isInSelection && this.dataSource?.some(p => p.id === item.id)) {
        this.isInSelection = true;
        this.navigateToRow(item, canNavigateToRow)
          .pipe(take(1))
          .subscribe(rowIndex => {
            if (rowIndex === -1) {
              this.focusedCell = null;
            } else {
              this.selectRow(item.id, columnIndex);
            }
            setTimeout(() => this.isInSelection = false);
          });
      }
    }
  }

  protected dispatchMoveItem(currentItem: T, sourceData: T[], insertInside: boolean): void {
    throw new Error('Method not implemented.');
  }

  protected createCustomItem(change: any): void {
    throw new Error('Method not implemented.');
  }

  protected onEditingStart(e: any): void {
    throw new Error('Method not implemented.');
  }

  protected customItem(): void {
    throw new Error('Method not implemented.');
  }

  protected deleteEmpty(): void {
    throw new Error('Method not implemented.');
  }

  protected selectById(newId: TId): void {
    this.selectId = newId;
  }

  protected checkLicense(): boolean {
    return true;
  }

  protected editItem(change: any): void { }
  protected dispatchActionSelectItem(item: T): void { }
  protected abstract onCellPrepared(e: any): void;
  protected abstract selectRow(itemId: TId, columnIndex?: number): void;
  protected abstract getNewIdAfterDelete(selectedId: TId, ignoredIds?: TId[]): TId;

  protected animateCells(animateCells: AnimateCell<TId>[]): void {
    animateCells
      .map(p => {
        const rows = this.dataComponent.instance.getVisibleRows();
        const prefix = p.propertyName === 'all' ? '' : `${p.propertyName}-`;
        return rows.some(r => r.key === p.id) ? `${prefix}row-${p.id}` : null;
      })
      .filter(p => !!p)
      .forEach(p => {
        const elements = this.elementRef.nativeElement.querySelectorAll('.' + p);
        if (elements) {
          for (const e of elements) {
            this.renderer.removeClass(e, 'animate');
            this.renderer.addClass(e, 'animate');
            setTimeout(() => this.renderer.removeClass(e, 'animate'), 2000);
          }
        }
      });
  }

  private save(change: any): void {
    if (change?.key === emptyKey) {
      if (change.type === 'remove') {
        this.selectById(this.getNewIdAfterDelete(this.selectedItemId));
        this.dataComponent?.instance.cancelEditData();
      } else {
        this.createCustomItem(change);
      }
    } else if (change && change.key && this.canEditItem) {
      this.canEditItem = false;
      this.editItem(change);
    }
  }

  private scrollToCenter(itemId: number): void {
    const rowElement = this.dataComponent.instance.getRowElement(this.dataComponent.instance.getRowIndexByKey(itemId))[0] as HTMLElement;
    const scrollable = this.dataComponent.instance.getScrollable();

    if (!!scrollable && Math.trunc(scrollable.scrollHeight()) - scrollable.scrollTop() > scrollable.clientHeight()) {
      const center = scrollable.clientHeight() / 2;
      const diff = rowElement.getBoundingClientRect().top - (this.elementRef.nativeElement as HTMLElement).offsetTop;
      scrollable.scrollTo({
        left: scrollable.scrollLeft(),
        top: scrollable.scrollTop() + (diff > center ? center : -center)
      });
    }
  }
}
