export enum VatPayerType {
  /**
   * Is not VAT payer
   */
  NonPayer = 0,

  /**
   * Is VAT payer
   */
  Payer = 1,

  /**
   * Registered vat payer by §7/7a, same as NonPayer, but...
   */
  RegisteredVatPayer7 = 2
}

export interface VatSetting {
  id: number;
  vatPayerType: VatPayerType;
  isUnitPriceInclVat: boolean;
  isTotalPriceInclVat: boolean;
}

export class VatRateConstants {
  public static VAT_RATE_HIGHER = 20;
  public static VAT_RATE_LOWER = 10;
  public static VAT_RATE_FREE = 0;
}

export class VatRateStringConstants {
  public static NO_VAT_LONG_FORM = 'N \xa0 \xa0 \xa0 Neobsahuje';
  public static NO_VAT_SHORT_FORM = 'N';
  public static VAT_FREE_LONG_FORM = '0 \xa0 \xa0 \xa0 Oslobodené';
  public static VAT_LOWER_LONG_FORM = `${VatRateConstants.VAT_RATE_LOWER} \xa0 \xa0 Znížená`;
  public static VAT_HIGHER_LONG_FORM = `${VatRateConstants.VAT_RATE_HIGHER} \xa0 \xa0 Základná`;
}

export interface VatLimitResult {
  vatLimitState: VatLimitState,
  remainingSum: number
}

export enum VatLimitState {
  /**
   * Not subject to vat payer registration duty limit (i.e. already a vat payer)
   */
  NotApplicable = 0,

  /**
   * Total sum is less than 80% of the registration duty limit
   */
  BelowWarningThreshold = 1,

  /**
   * Total sum is between 80% and 100% of the registration duty limit
   */
  WarningBelowLimit = 2,

  /**
   * Total sum is more than 100% of the registration duty limit
   */
  LimitExceeded = 3
}
