import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { KrosModalRef } from '@kros-sk/components';

import { ExportSummaryModel } from '../../models/budget-approval-export.model';

@Component({
  selector: 'app-export-summary-settings',
  templateUrl: './export-summary-settings.component.html',
  styleUrls: ['./export-summary-settings.component.scss']
})
export class ExportSummarySettingsComponent implements OnInit {

  summarySettings: ExportSummaryModel;
  settingsForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalRef: KrosModalRef
  ) {
    this.summarySettings = this.modalRef.data.summarySettings;
  }

  ngOnInit(): void {
    this.initializeSettingsForm();
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  onSubmit(): void {
    this.copySettingsFromControl();
    this.modalRef.submit({ summarySettings: this.summarySettings });
  }

  private initializeSettingsForm(): void {
    this.settingsForm = this.formBuilder.group({
      isTotalPriceChangesIncluded: [this.summarySettings.isTotalPriceChangesIncluded],
      isTotalPriceChangesPercentageIncluded: [this.summarySettings.isTotalPriceChangesPercentageIncluded],
      isPositiveAndNegativeChangesIncluded: [this.summarySettings.isPositiveAndNegativeChangesIncluded],
      isAbsoluteChangesIncluded: [this.summarySettings.isAbsoluteChangesIncluded],
      isAbsoluteChangesPercentageIncluded: [this.summarySettings.isAbsoluteChangesPercentageIncluded],
    });
  }

  private copySettingsFromControl(): void {
    const formControls = this.settingsForm.controls;
    this.summarySettings.isTotalPriceChangesIncluded = formControls.isTotalPriceChangesIncluded.value;
    this.summarySettings.isTotalPriceChangesPercentageIncluded = formControls.isTotalPriceChangesPercentageIncluded.value;
    this.summarySettings.isPositiveAndNegativeChangesIncluded = formControls.isPositiveAndNegativeChangesIncluded.value;
    this.summarySettings.isAbsoluteChangesIncluded = formControls.isAbsoluteChangesIncluded.value;
    this.summarySettings.isAbsoluteChangesPercentageIncluded = formControls.isAbsoluteChangesPercentageIncluded.value;
  }
}
