import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import { Observable } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';

/**
 * Adds 'Accept-Language' header to requests.
 */
@Injectable()
export class KrosLocaleInterceptor implements HttpInterceptor {

  constructor(
    @Inject(LOCALE_ID) private localeId: string,
    @Inject(APP_CONFIG) private appConfig: any,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.useInterceptor(request.url)) {
      return next.handle(request);
    }

    const headers = request.headers
      .set('Accept-Language', this.getLocale());
    const localeRequest = request.clone({ headers });

    return next.handle(localeRequest);
  }

  private getLocale(): string {
    if (this.localeId === 'sk') {
      return 'sk-SK';
    } else if (this.localeId.startsWith('en-')) {
      return 'en-GB';
    }
    return this.localeId;
  }

  useInterceptor(url: string): boolean {
    return !url.includes('/assets/i18n/') && url.includes(`${this.appConfig.gatewayApiUrl}`);
  }
}
