import { Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[kros-dropdown-header-item]',
  exportAs: 'krosDropdownHeaderItem',
  template: '<ng-content></ng-content>',
  styleUrls: ['./kros-dropdown-header-item.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'kros-dropdown-header-item'
  },
})
export class KrosDropdownHeaderItemComponent {}
