<ul
  class="list-group"
  [ngClass]="{'collapsed': collapsed}"
>
  <li
    class="list-group-item"
    [routerLink]="['dashboard']"
    [routerLinkActive]="'selected'"
  >
    <i
      class="icon-button material-icons"
      krosTooltip="{{ collapsed ? ('DASHBOARD.TITLE' | translate) : '' }}"
      data-test="project-dashboard-open"
    >dashboard</i>

    <span class="item-text">
      {{ 'DASHBOARD.TITLE' | translate }}
    </span>
  </li>

  <li
    class="list-group-item"
    [routerLink]="['documents']"
    [routerLinkActive]="'selected'"
  >
    <i
      class="icon-button material-icons"
      krosTooltip="{{ collapsed ? ((!isSlovakVersion ? 'DOCUMENTS.TITLE_CZ' : 'DOCUMENTS.TITLE') | translate) : '' }}"
      data-test="project-documents-open"
    >description</i>

    <span class="item-text">
      {{ (!isSlovakVersion ? 'DOCUMENTS.TITLE_CZ' : 'DOCUMENTS.TITLE') | translate }}
    </span>
  </li>

  <li
    class="list-group-item"
    (click)="onShowQuantityManager()"
    [ngClass]="{'selected': isInQuantityManager}"
  >
    <i
      class="icon-button material-icons"
      krosTooltip="{{ collapsed ? ('QUANTITY_MANAGER.TITLE' | translate) : '' }}"
      data-test="open-quantity-manager"
    >square_foot</i>

    <span class="item-text">
      {{ 'QUANTITY_MANAGER.TITLE' | translate }}
    </span>
  </li>

  <li
    class="list-group-item"
    [routerLink]="['budget']"
    [routerLinkActive]="'selected'"
  >
    <i
      class="icon-button custom-icon"
      krosTooltip="{{ collapsed ? ('BUDGET.TITLE' | translate) : '' }}"
      data-test="budget-open"
    >
    <img
      [src]="'assets/icons/icon-budget.svg'"
      alt="Budget icon"
    >
    </i>

    <span class="item-text">
      {{ 'BUDGET.TITLE' | translate }}
    </span>
  </li>

  <ng-container *ngIf="!isSlovakVersion">
    <ng-container *ngTemplateOutlet="budgetApproval"></ng-container>
  </ng-container>

  <li
    class="list-group-item"
    (click)="onShowBuildingProgress()"
    [ngClass]="{'selected': isInBuildingProgress}"
  >
    <i
      class="icon-button"
      [ngClass]="isSlovakVersion ? 'custom-icon' : 'material-icons'"
      krosTooltip="{{ collapsed ? ('BUILDING_PROGRESS.TITLE' | translate) : '' }}"
      data-test="open-building-progress"
    >
      <ng-container *ngIf="isSlovakVersion; else czIcon">
        <img
          [src]="'assets/icons/icon-building-progress.svg'"
          alt="Building progress icon"
        >
      </ng-container>

      <ng-template #czIcon>home_work</ng-template>
    </i>

    <span class="item-text">
      {{ 'BUILDING_PROGRESS.TITLE' | translate }}
    </span>
  </li>

  <ng-container *ngIf="isSlovakVersion">
    <ng-container *ngTemplateOutlet="budgetApproval"></ng-container>
  </ng-container>

  <li
    *ngIf="!permissionHelper.isContractor && !permissionHelper.isApprover"
    class="list-group-item"
    [routerLink]="['members']"
    [routerLinkActive]="'selected'"
  >
    <i
      class="icon-button material-icons"
      krosTooltip="{{ collapsed ? ('SHARING.TITLE' | translate) : '' }}"
      data-test="open-project-sharing"
    >people</i>

    <span class="item-text">
      {{ 'SHARING.TITLE' | translate }}
    </span>
  </li>

  <ng-container *ngIf="isSlovakVersion">
    <li class="list-group-item sub-header">
      <h5>{{ 'CONSTRUCTION_DIARY.CONSTRUCTION_DIARY' | translate }}</h5>
    </li>

    <li
      class="list-group-item"
      (click)="onOpenStavario()"
    >
      <i
        class="icon-button custom-icon"
        krosTooltip="{{ collapsed ? ('CONSTRUCTION_DIARY.STAVARIO' | translate) : '' }}"
        data-test="open-stavario"
      >
        <img
          [src]="'assets/icons/icon-stavario.svg'"
          alt="Stavario icon"
        >
      </i>

      <span class="item-text">
        {{ 'CONSTRUCTION_DIARY.STAVARIO' | translate }}
      </span>
    </li>

    <li
      class="list-group-item"
      (click)="onOpenBuildary()"
    >
      <i
        class="icon-button custom-icon"
        krosTooltip="{{ collapsed ? ('CONSTRUCTION_DIARY.BUILDARY' | translate) : '' }}"
        data-test="open-buildary"
      >
        <img
          [src]="'assets/icons/icon-buildary.svg'"
          alt="Buildary icon"
        >
      </i>

      <span class="item-text">
        {{ 'CONSTRUCTION_DIARY.BUILDARY' | translate }}
      </span>
    </li>
  </ng-container>

  <li class="list-group-item sub-header">
    <h5>{{ 'PROJECTS.ZOZNAM.OTVORIT' | translate }}</h5>
  </li>

  <li
    class="list-group-item"
    [routerLink]="['/projects']"
    id="openProjectList"
  >
    <i
      class="icon-button material-icons"
      krosTooltip="{{ collapsed ? ('PROJECTS.TITLE' | translate) : '' }}"
      data-test="open-project-list"
    >home</i>

    <span class="item-text">
      {{ 'PROJECTS.TITLE' | translate }}
    </span>
  </li>

  <li
    class="list-group-item"
    (click)="onCreateProject()"
  >
    <i
      class="icon-button material-icons"
      krosTooltip="{{ collapsed ? ('PROJECTS.ZOZNAM.NEW_PROJECT' | translate) : '' }}"
      data-test="create-new-project"
    >add</i>

    <span class="item-text">
      {{ 'PROJECTS.ZOZNAM.NEW_PROJECT' | translate }}
    </span>
  </li>

  <li
    class="list-group-item"
    (click)="openProjectReport()"
  >
    <i
      class="icon-button material-icons-outlined"
      krosTooltip="{{ collapsed ? ('PROJECTS.DETAIL.INFO.PROJECT_REPORTS' | translate) : '' }}"
      data-test="open-project-report"
    >poll</i>

    <span class="item-text">
      {{ 'PROJECTS.DETAIL.INFO.PROJECT_REPORTS' | translate }}
    </span>
  </li>
</ul>

<ng-template #budgetApproval>
  <li
    class="list-group-item"
    (click)="onShowBudgetApproval()"
    [ngClass]="{'selected': isInBudgetApproval}"
  >
    <i
      class="icon-button material-icons"
      krosTooltip="{{ collapsed ? ('BUDGET_APPROVAL.TITLE' | translate) : '' }}"
      data-test="open-budget-approval"
    >published_with_changes</i>

    <span class="item-text">
      {{ 'BUDGET_APPROVAL.TITLE' | translate }}
    </span>
  </li>
</ng-template>
