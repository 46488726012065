import { Injectable } from '@angular/core';

import { createFeatureSelector, createSelector, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { infoMessageFeatureKey, InfoMessageState } from '.';

import { InfoMessage } from '../model/info-message';

const selectInfoMessagesFeature = createFeatureSelector<InfoMessageState>(infoMessageFeatureKey);

function getDateNoTime(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
}

function isMessageWithinDate(message: InfoMessage): boolean {
  if (!message.dateFrom || !message.dateTo) {
    return false;
  }

  const dateFrom = new Date(message.dateFrom);
  const dateTo = new Date(message.dateTo);
  const currentDate = new Date();

  if ((currentDate < dateFrom) || (currentDate > dateTo)) {
    return false;
  }

  return true;
}

function isVisibleMessage(state: InfoMessageState, message: InfoMessage): boolean {
  if (!isMessageWithinDate(message)) {
    return false;
  }

  if (!message.showOnceDay) {
    return !message.closed;
  }

  const messageInteraction = state.messagesInteractions.find(mv => mv.message === message.message);

  if (!messageInteraction) {
    return true;
  }

  const closeDateNoTime = getDateNoTime(messageInteraction.closeDate);
  const currentDateNoTime = getDateNoTime(new Date());

  return currentDateNoTime > closeDateNoTime;
}

const selectInfoMessages = createSelector(
  selectInfoMessagesFeature,
  state => state.messages.filter(m => isVisibleMessage(state, m))
);

@Injectable()
export class InfoMessageSelectorsService {

  infoMessages$: Observable<InfoMessage[]> = this.store.select(selectInfoMessages);

  constructor(private store: Store<InfoMessageState>) { }
}
