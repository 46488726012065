import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Observable } from 'rxjs';

import { IBudgetModel } from '../../budget/helpers';

@Component({
  selector: 'app-budget-structure-panel-container',
  templateUrl: './budget-structure-panel-container.component.html',
  styleUrls: ['./budget-structure-panel-container.component.scss']
})
export class BudgetStructurePanelContainerComponent {
  @Input() collapsed = false;
  @Input() data$: Observable<IBudgetModel>;
  @Input() dataTestCollapse = 'budget-structure-panel-collapse';
  @Input() hasPermission = true;
  @Input() title = '';

  @Output() panelClosed: EventEmitter<void> = new EventEmitter<void>();
  @Output() panelCollapsed: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  toggleCollapsePanel(): void {
    this.collapsed = !this.collapsed;
    this.panelCollapsed.emit(this.collapsed);
  }

  onNodeSelected(): void {
    this.panelClosed.emit();
  }
}
