import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { DecimalPlaces, isItem, isNumber } from '@kros-sk/ssw-shared-legacy';
import { PermissionType } from '@kros-sk/ssw-shared/permission';
import { roundNumber } from '@kros-sk/components';
import { StorageService } from '@kros-sk/ssw-cdk';

import { BuildingProgressActionAccessService } from '../../../building-progress/services/building-progress-action-access.service';
import { BuildingProgressChangedProperty } from '../../../building-progress/models/building-progress-edit.model';
import { BuildingProgressEditingService } from '../../../building-progress/services/building-progress-editing.service';
import { BuildingProgressItem, BuildingProgressPeriodItem } from '../../../building-progress/models/construction-data.model';
import { BuildingProgressPeriod } from '../../../building-progress/models/building-progress-period.model';
import { DetailHelper, LicenseHelper, PermissionHelper } from '../../../building-progress/helpers';

@Component({
  selector: 'app-building-progress-mobile-item',
  templateUrl: './building-progress-mobile-item.component.html',
  styleUrls: ['./building-progress-mobile-item.component.scss']
})
export class BuildingProgressMobileItemComponent implements OnInit, OnDestroy {
  @Input()
  set currentItem(value: BuildingProgressItem) {
    if (this._currentItem !== value) {
      this._currentItem = value;
      this.recalculate();
    }
  }

  get currentItem(): BuildingProgressItem {
    return this._currentItem;
  }

  @Input() selectedPeriod: BuildingProgressPeriod;
  @Input() decimalPlaces: DecimalPlaces;

  @Output() toggleDetail = new EventEmitter();

  form: UntypedFormGroup;
  projectId: number;
  isBudgetDataCollapsed = true;
  isPeriodDataCollapsed = false;
  isSummaryDataCollapsed = true;
  budgetDataCollapsedIdKey = 'mobileItemBudgetDataCollapsed';
  periodDataCollapsedIdKey = 'mobileItemPeriodDataCollapsed';
  summaryDataCollapsedIdKey = 'mobileItemSummaryDataCollapsed';
  showDetail = false;
  isPeriodAmountFocused = false;

  private _currentItem: BuildingProgressItem;
  private newSectionObjectPercentage: number;
  private periodPercentageTyped: boolean;

  get isItem(): boolean {
    return isItem(this.currentItem);
  }

  get periodItem(): BuildingProgressPeriodItem {
    return this.selectedPeriod ? this.currentItem.periods.find(p => p.periodId === this.selectedPeriod.id) : undefined;
  }

  get canShowMore(): boolean {
    return this.canShowComplete;
  }

  get canShowComplete(): boolean {
    return !!this.selectedPeriod && (this.permissionHelper.isContributor || this.permissionHelper.isOwner);
  }

  constructor(
    private appInsightsService: AppInsightsBaseService,
    private buildingProgressActionAccessService: BuildingProgressActionAccessService,
    private buildingProgressEditingService: BuildingProgressEditingService,
    private detail: DetailHelper,
    private fb: UntypedFormBuilder,
    private licenseHelper: LicenseHelper,
    private permissionHelper: PermissionHelper,
    private storage: StorageService
  ) { }

  ngOnInit(): void {
    this.projectId = this.detail.projectId;

    const periodItem = this.periodItem;
    this.form = this.fb.group({
      description: { value: this.currentItem.description.replace(/<[^>]*>/ig, ''), disabled: true },
      measureUnit: { value: this.currentItem.measureUnit, disabled: true },
      amount: { value: this.currentItem.amount, disabled: true },
      unitPrice: { value: this.currentItem.unitPrice, disabled: true },
      totalPrice: { value: this.currentItem.totalPrice, disabled: true },
      completedPercentage: { value: this.currentItem.completedPercentage, disabled: true },
      completedAmount: { value: this.currentItem.completedAmount, disabled: true },
      completedTotalPrice: { value: this.currentItem.completedTotalPrice, disabled: true },
      restPercentage: { value: this.currentItem.restPercentage, disabled: true },
      restAmount: { value: this.currentItem.restAmount, disabled: true },
      restTotalPrice: { value: this.currentItem.restTotalPrice, disabled: true },
      additionalPercentage: { value: this.currentItem.additionalPercentage, disabled: true },
      additionalAmount: { value: this.currentItem.additionalAmount, disabled: true },
      additionalTotalPrice: { value: this.currentItem.additionalTotalPrice, disabled: true },
      notCompletedPercentage: { value: this.currentItem.notCompletedPercentage, disabled: true },
      notCompletedAmount: { value: this.currentItem.notCompletedAmount, disabled: true },
      notCompletedTotalPrice: { value: this.currentItem.notCompletedTotalPrice, disabled: true },
      periodPercentage: { value: periodItem?.percentage, disabled: this.currentItem.isNotCompleted || !this.canEdit() },
      periodAmount: {
        value: roundNumber(periodItem?.amount, this.decimalPlaces.amount),
        disabled: this.currentItem.isNotCompleted || !this.canEdit()
      },
      periodTotalPrice: {
        value: periodItem?.totalPrice,
        disabled: !this.isItem || this.currentItem.isNotCompleted || !this.canEdit()
      }
    });

    this.isBudgetDataCollapsed = this.getCollapsedFromStorage(this.budgetDataCollapsedIdKey, this.isBudgetDataCollapsed);
    this.isPeriodDataCollapsed = this.getCollapsedFromStorage(this.periodDataCollapsedIdKey, this.isPeriodDataCollapsed);
    this.isSummaryDataCollapsed = this.getCollapsedFromStorage(this.summaryDataCollapsedIdKey, this.isSummaryDataCollapsed);
  }

  ngOnDestroy(): void {
    this.licenseHelper.destroy();
    this.permissionHelper.destroy();
  }

  private recalculate(): void {
    if (this.form) {
      const periodItem = this.periodItem;
      const periodPercentage = !this.isItem && this.newSectionObjectPercentage !== undefined ?
        this.newSectionObjectPercentage : periodItem.percentage;
      this.form.controls['amount'].setValue(this.currentItem.amount);
      this.form.controls['unitPrice'].setValue(this.currentItem.unitPrice);
      this.form.controls['totalPrice'].setValue(this.currentItem.totalPrice);
      this.form.controls['completedPercentage'].setValue(this.currentItem.completedPercentage);
      this.form.controls['completedAmount'].setValue(this.currentItem.completedAmount);
      this.form.controls['completedTotalPrice'].setValue(this.currentItem.completedTotalPrice);
      this.form.controls['restPercentage'].setValue(this.currentItem.restPercentage);
      this.form.controls['restAmount'].setValue(this.currentItem.restAmount);
      this.form.controls['restTotalPrice'].setValue(this.currentItem.restTotalPrice);
      this.form.controls['additionalPercentage'].setValue(this.currentItem.additionalPercentage);
      this.form.controls['additionalAmount'].setValue(this.currentItem.additionalAmount);
      this.form.controls['additionalTotalPrice'].setValue(this.currentItem.additionalTotalPrice);
      this.form.controls['periodPercentage'].setValue(periodPercentage);
      this.form.controls['periodAmount'].setValue(this.isPeriodAmountFocused ?
        periodItem.amount : roundNumber(periodItem.amount, this.decimalPlaces.amount));
      this.form.controls['periodTotalPrice'].setValue(periodItem.totalPrice);
    }
  }

  toggleShowDetail(): void {
    this.toggleDetail.emit();
  }

  private getCollapsedFromStorage(storageIdKey: string, defaultValue: boolean): boolean {
    const storageValue = this.storage.getItemFromLocalStorage(storageIdKey);
    return storageValue ? storageValue === 'true' : defaultValue;
  }

  onSectionCollapsedChange(collapsed: boolean, storageIdKey: string): void {
    this.storage.setItemToLocalStorage(storageIdKey, collapsed.toString());
  }

  onPeriodAmountFocus(): void {
    this.form.controls['periodAmount'].setValue(this.periodItem.amount);
    this.isPeriodAmountFocused = true;
  }

  onPeriodAmountFocusout(): void {
    this.isPeriodAmountFocused = false;
    this.onSubmitAmount();
  }

  onSubmitPercentage(event?: any): void {
    if (event) {
      this.periodPercentageTyped = true;
    }
    if (!event || event.key === 'Enter') {
      const oldValue = this.newSectionObjectPercentage !== undefined ? this.newSectionObjectPercentage : this.periodItem.percentage;
      if (this.periodPercentageTyped &&
        this.hasValueChanged('periodPercentage', oldValue) &&
        isNumber(this.form.controls['periodPercentage'].value)) {
        const newPeriodPercentageValue = this.form.controls['periodPercentage'].value;
        this.buildingProgressEditingService.editPeriodPercentage(
          newPeriodPercentageValue.toString(),
          this.projectId,
          this.selectedPeriod.id,
          this.currentItem,
          true
        );
        if (!this.isItem) {
          this.newSectionObjectPercentage = newPeriodPercentageValue;
        }
        this.periodPercentageTyped = false;
        this.appInsightsService.trackEvent('PV-edit-percentage-on-mobile');
      } else {
        this.form.controls['periodPercentage'].setValue(roundNumber(this.periodItem.percentage, this.decimalPlaces.amount));
      }
    }
  }

  onSubmitAmount(event?: any): void {
    if (!event || event.key === 'Enter') {
      if (this.hasValueChanged('periodAmount', this.periodItem.amount) && isNumber(this.form.controls['periodAmount'].value)) {
        this.buildingProgressEditingService.editItemPeriod(
          this.form.controls['periodAmount'].value.toString(),
          this.projectId,
          this.selectedPeriod.id,
          this.currentItem,
          BuildingProgressChangedProperty.Amount,
          true
        );
        this.appInsightsService.trackEvent('PV-edit-amount-on-mobile');
      } else {
        this.form.controls['periodAmount'].setValue(roundNumber(this.periodItem.amount, this.decimalPlaces.amount));
      }
    }
  }

  onSubmitTotalPrice(event?: any): void {
    if (!event || event.key === 'Enter') {
      if (this.hasValueChanged('periodTotalPrice', this.periodItem.totalPrice) && isNumber(this.form.controls['periodTotalPrice'].value)) {
        this.buildingProgressEditingService.editItemPeriod(
          this.form.controls['periodTotalPrice'].value.toString(),
          this.projectId,
          this.selectedPeriod.id,
          this.currentItem,
          BuildingProgressChangedProperty.TotalPrice,
          true
        );
        this.appInsightsService.trackEvent('PV-edit-total-price-on-mobile');
      } else {
        this.form.controls['periodTotalPrice'].setValue(roundNumber(this.periodItem.totalPrice, this.decimalPlaces.amount));
      }
    }
  }

  private hasValueChanged(formControlName: string, oldValue: number): boolean {
    const newValue = (this.form.controls[formControlName] !== undefined
      && this.form.controls[formControlName].value !== null)
      ? parseFloat(this.form.controls[formControlName].value.toString())
      : null;
    return newValue !== oldValue || (!this.isItem && (newValue === 0 && oldValue === 0));
  }

  private canEdit(): boolean {
    return !this.licenseHelper.isReadonlyLicense &&
      !this.selectedPeriod?.isLocked &&
      !this.selectedPeriod?.isApprovalRequested &&
      !this.selectedPeriod?.isApproved &&
      (this.permissionHelper.permissionType === PermissionType.Owner ||
        this.permissionHelper.permissionType === PermissionType.Contributor ||
        this.permissionHelper.isContractor);
  }

  onCompleteClick(): void {
    if (this.buildingProgressActionAccessService.checkForAccessToEdit()) {
      if (this.detail.checkLastPeriodStateIsUnset()) {
        this.detail.mobile.showNoLastPeriodSelectedMessage(this.selectedPeriod.id, this.detail.constructionData.periods)
          .subscribe(result => {
            if (result) {
              this.detail.onPercentageComplete(true);
            }
          }
          );
      }
    }
  }
}
