import { Component, Inject, Input } from '@angular/core';

import { APP_CONFIG } from '@kros-sk/app-config';
import { UserService } from '@kros-sk/ssw-cdk';

import { NewsData } from '../shared/news-data.model';
import { TranslateService } from '../../translate';

@Component({
  selector: 'kros-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent {
  @Input() newsData: NewsData;

  get widgetUrl(): string {
    return 'https://announcekit.co/widgets/v2/' + this.appConfig.news.widgetKey;
  }

  get newsUser(): any {
    return {
      id: this.userService.userId
    };
  }

  constructor(
    public translateService: TranslateService,
    private userService: UserService,
    @Inject(APP_CONFIG) private appConfig: any
  ) { }
}
