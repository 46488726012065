import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { DeviceDetectorService } from '@kros-sk/components';

import { LicenseAgreementComponent } from './license-agreement/license-agreement.component';
import { LicenseDispatchersService, LicenseSelectorsService } from './store';
import { LicenseInfoComponent } from './license-info/license-info.component';
import { LicenseInfoTextPipe } from './license-info-text.pipe';
import { LicenseUrlService } from './license-url.service';
import { MarketingModalComponent } from './marketing-modal/marketing-modal.component';
import { NonPaidPackageModalComponent } from './non-paid-package-modal/non-paid-package-modal.component';
import { PipesModule } from '../pipes';
import { TranslateModule } from '../translate';

@NgModule({
  declarations: [
    LicenseAgreementComponent,
    LicenseInfoComponent,
    LicenseInfoTextPipe,
    NonPaidPackageModalComponent,
    MarketingModalComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    TranslateModule.forChild(),
  ],
  exports: [
    LicenseInfoComponent,
    NonPaidPackageModalComponent,
    MarketingModalComponent
  ],
  providers: [
    DeviceDetectorService,
    LicenseInfoTextPipe,
    LicenseUrlService,
    LicenseDispatchersService,
    LicenseSelectorsService,
    DatePipe
  ]
})
export class LicenseModule {
}
