<div class="kros-modal-header">
  <h4 class="kros-modal-title">{{ 'BUILDING_PROGRESS.PERIODS_HISTORY.PERIODS_HISTORY' | translate }}</h4>
  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onCloseClick()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<ng-container *ngIf="dataLoaded else loader">
  <div class="kros-modal-body">
    <table
      *ngIf="hasData; else empty_table"
      class="table"
      cdk-table
      [dataSource]="dataSource"
    >
      <ng-container cdkColumnDef="dateChanged">
        <th
          cdk-header-cell
          *cdkHeaderCellDef
        >
          {{ 'BUILDING_PROGRESS.PERIODS_HISTORY.DATE' | translate | uppercase }}
        </th>

        <td
          cdk-cell
          *cdkCellDef="let row"
        >
          {{ row.dateChanged | date:'dd.MM.yyyy HH:mm': 'CEST' }}
        </td>
      </ng-container>

      <ng-container cdkColumnDef="dateFrom">
        <th
          cdk-header-cell
          *cdkHeaderCellDef
        >
          {{ 'BUILDING_PROGRESS.PERIODS_HISTORY.PERIOD' | translate | uppercase }}
        </th>

        <td
          cdk-cell
          *cdkCellDef="let row"
        >
          {{ row.dateFrom | periodDate: row.dateTo }}
        </td>
      </ng-container>

      <ng-container cdkColumnDef="operationType">
        <th
          cdk-header-cell
          *cdkHeaderCellDef
        >
          {{ 'BUILDING_PROGRESS.PERIODS_HISTORY.OPERATION_TYPE' | translate | uppercase }}
        </th>

        <td
          cdk-cell
          *cdkCellDef="let row"
        >
          {{ row.operationType | periodOperationType }}
        </td>
      </ng-container>

      <ng-container cdkColumnDef="email">
        <th
          cdk-header-cell
          *cdkHeaderCellDef
        >
          {{ 'BUILDING_PROGRESS.PERIODS_HISTORY.EMAIL' | translate | uppercase }}
        </th>

        <td
          cdk-cell
          *cdkCellDef="let row"
        >
          {{ row.email }}
        </td>
      </ng-container>

      <tr
        cdk-header-row
        *cdkHeaderRowDef="columnsToDisplay; sticky: true;"
      ></tr>
      <tr
        cdk-row
        *cdkRowDef="let row; let odd = odd; columns: columnsToDisplay;"
        [class.alternate]="odd"
      ></tr>
    </table>
  </div>
</ng-container>

<ng-template #loader>
  <kros-timeline [type]="timelineType.Table"></kros-timeline>
</ng-template>

<ng-template #empty_table>
  <p>{{ 'BUILDING_PROGRESS.PERIODS_HISTORY.NO_RECORDS' | translate }}</p>
</ng-template>
