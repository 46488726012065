import { Component, inject } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { CreateCompanyApiModel } from '@kros-sk/models';
import { CreateCompanyEditorModule, KrosModalRef } from '@kros-sk/components';

import { CompanyModel } from '../../data-access';

@Component({
  selector: 'ssw-create-company-modal',
  standalone: true,
  imports: [CreateCompanyEditorModule, TranslateModule],
  templateUrl: './create-company-modal.component.html'
})
export class CreateCompanyModalComponent {

  private modalRef = inject(KrosModalRef);

  onCreateCompany(event: CreateCompanyApiModel): void {
    if (event) {
      this.modalRef.submit(this.mapFormModelToApiModel(event));
    }
  }

  onClose(): void {
    this.modalRef.cancel();
  }

  private mapFormModelToApiModel(company: CreateCompanyApiModel): CompanyModel {
    return {
      name: company.name,
      contactName: company.contactName ?? '',
      street: company.street,
      postCode: company.postCode,
      city: company.city,
      country: company.country,
      vatPayerType: company.vatPayerType,
      registrationId: company.registrationId,
      taxId: company.taxId,
      vatId: company.vatId,
      phoneNumber: company.phoneNumber,
      email: company.email,
      web: company.web,
      migrationSystemType: ''
    };
  }
}
