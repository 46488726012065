import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { catchError, Observable, of, Subject, switchMap, throwError } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';

import { ToastService, ToastType } from '../toast';
import { TranslateService } from '../translate';
import { VersionData } from './version-data';

const documentVersionsController = '/api/documentService/DocumentVersions';
const getVersionsEndpoint = documentVersionsController + '/versionList';
const getVersionEndpoint = documentVersionsController + '/version';
const setCurrentVersionEndpoint = documentVersionsController + '/currentVersionSet';

@Injectable()
export class VersionService {

  openVersionDialogEvent = new Subject<void>();
  isLastVersionEvent = new Subject<boolean>();
  canShowVersionButton: boolean;

  private lastVersionId = '';
  private versionList: VersionData[];

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private translateService: TranslateService,
    @Inject(APP_CONFIG) private appConfig: any
  ) { }

  clearVersionList(): void {
    this.versionList = [];
  }

  openVersionDialog(): void {
    this.openVersionDialogEvent.next();
  }

  selectVersion(lastVersionId: string): void {
    this.isLastVersionEvent.next(lastVersionId === this.lastVersionId);
  }

  getDocumentVersionName(documentVersionId: string): Observable<string> {
    return this.getDocumentVersion(documentVersionId)
      .pipe(switchMap(p => of(`${p.originalName} (v${p.order})`)));
  }

  private getDocumentVersion(documentVersionId: string): Observable<VersionData> {
    return this.http.get(
      this.appConfig.appUrls.titanGatewayUrl + getVersionEndpoint + '?documentVersionId=' + encodeURIComponent(documentVersionId))
      .pipe(catchError(this.handleError.bind(this)));
  }

  getVersions(documentId: string): Observable<VersionData[]> {
    if (this.versionList?.length > 0 && this.versionList[0].documentId === documentId) {
      return of(this.versionList);
    } else {
      return this.http.get(
        this.appConfig.appUrls.titanGatewayUrl + getVersionsEndpoint + '?documentId=' + encodeURIComponent(documentId))
        .pipe(
          switchMap((versions: VersionData[]) => {
            this.versionList = versions.sort((v1, v2) => v2.order - v1.order);
            this.lastVersionId = this.versionList[0].id;
            return of(this.versionList);
          }),
          catchError(this.handleError.bind(this))
        );
    }
  }

  setCurrentVersion(documentVersionId: string): Observable<any> {
    const viewModel = { documentVersionId };

    return this.http.patch(this.appConfig.appUrls.titanGatewayUrl + setCurrentVersionEndpoint, viewModel);
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      let errorMessage: string = this.translateService.translate('DOCUMENTS.DOCUMENT_GENERALERROR');

      switch (error.status) {
        case 403:
          errorMessage = this.translateService.translate('DOCUMENTS.DOCUMENT_FORBBIDEN');
          break;

        case 404:
          errorMessage = this.translateService.translate('DOCUMENTS.DOCUMENT_NOT_EXIST');
          break;
      }
      this.toastService.open(errorMessage, ToastType.Error);

      console.error(
        `Backend returned code ${error.status}, ` +
        `error message: ${errorMessage}, ` +
        `body was: ${error.error}`);

      return throwError(() => new Error(errorMessage));
    }
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
