<button
  ngbDropdownItem
  class="dropdown-item btn link"
  type="button"
  [disabled]="disabled"
  (click)="initializeUploader(fileInput)"
  krosAnalytic
  eventCategory="sprava-suborov"
  eventAction="nahrat-dokument"
  data-test="upload-button"
>
  <i class="material-icons icon-button">insert_drive_file</i>
  <span>{{ 'DOCUMENTS.PRIDAT_DOKUMENT' | translate }}</span></button>
<input
  #fileInput
  type="file"
  ng2FileSelect
  multiple
  class="file-upload-input"
  [uploader]="uploader"
  (onFileSelected)="onFilesSelected()"
  data-test="upload-file-input"
/>
