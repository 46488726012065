import { createActionGroup, props } from '@ngrx/store';

import { BudgetPermission, BuildingProgressPermission } from '../models';

export const permissionActions = createActionGroup({
  source: 'Permission',
  events: {
    'Load building progress permission start': props<{ projectId: number }>(),
    'Load building progress permission success': props<{ buildingProgressPermission: BuildingProgressPermission }>(),
    'Load building progress permission error': props<{ error: any }>(),

    'Load budget permission start': props<{ projectId: number }>(),
    'Load budget permission success': props<{ budgetPermission: BudgetPermission }>(),
    'Load budget permission error': props<{ error: any }>()
  }
});
