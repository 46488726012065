import { Injectable } from '@angular/core';

import { formatString } from '@kros-sk/ssw-cdk';
import { MarketingContentHelper } from '@kros-sk/ssw-shared-legacy';

@Injectable()
export class BuildingProgressMarketingContentHelper extends MarketingContentHelper {

  protected get moduleName(): string {
    return this.translateService.translate(this.config.isBudgetApproval
      ? 'LICENSE_INFO.BUDGET_APPROVAL'
      : 'LICENSE_INFO.BUILDING_PROGRESS');
  }

  protected resolveInfoText(): void {
    switch (true) {
      case this.config.isLicenseFree && this.config.canActivateTrial && this.config.isAutomaticLaunch && !this.config.isBudgetApproval:
        this.infoText = `${formatString(
          this.translateService.translate('LICENSE_INFO.MKT_MODAL_TEXT_FREE_CAN_ACTIVATE_AUTOMATIC_TEXT_PV'),
          this.urlHelper.krosCompanyPrefix,
          this.config.trialDuration + '',
          this.pluralisationByCountPipe.transform(this.config.trialDuration, 'LICENSE_INFO.DAY')
        )} ${this.getMoreInfoLink()}`;
        break;
      case this.config.isLicenseFree && this.config.canActivateTrial && this.config.isAutomaticLaunch && this.config.isBudgetApproval:
        this.infoText = `${formatString(
          this.translateService.translate('LICENSE_INFO.MKT_MODAL_TEXT_FREE_CAN_ACTIVATE_AUTOMATIC_TEXT_ZPV'),
          this.urlHelper.krosCompanyPrefix,
          this.config.trialDuration + '',
          this.pluralisationByCountPipe.transform(this.config.trialDuration, 'LICENSE_INFO.DAY')
        )} ${this.getMoreInfoLink()}`;
        break;
      case this.config.isLicenseFree && !this.config.canActivateTrial && this.config.isAutomaticLaunch:
        this.infoText = formatString(
          this.translateService.translate('LICENSE_INFO.MKT_MODAL_TEXT_FREE_CANNOT_ACTIVATE_AUTOMATIC_TEXT'),
          this.urlHelper.krosCompanyPrefix,
          this.moduleName,
          this.urlHelper.contactUs
        );
        this.config.canOpenModal ??= !this.config.isBudgetOrDesktopLicenseFull;
        break;
      case this.config.isLicenseFree && this.config.canActivateTrial && !this.config.isAutomaticLaunch:
        this.infoText = `${formatString(
          this.translateService.translate('LICENSE_INFO.MKT_MODAL_TEXT_FREE_CAN_ACTIVATE_NOT_AUTOMATIC_TEXT'),
          this.urlHelper.krosCompanyPrefix,
          this.moduleName,
          this.config.trialDuration + '',
          this.pluralisationByCountPipe.transform(this.config.trialDuration, 'LICENSE_INFO.DAY')
        )} ${this.getMoreInfoLink()}`;
        break;
      case this.config.isLicenseFree && !this.config.canActivateTrial && !this.config.isAutomaticLaunch:
        this.infoText = formatString(
          this.translateService.translate('LICENSE_INFO.MKT_MODAL_TEXT_FREE_CANNOT_ACTIVATE_TEXT'),
          this.urlHelper.krosCompanyPrefix,
          this.moduleName,
          this.urlHelper.contactUs
        );
        break;
      case this.config.isLicenseReadonly && this.config.isAutomaticLaunch:
        this.infoText = formatString(
          this.translateService.translate('LICENSE_INFO.MKT_MODAL_TEXT_READONLY_AUTOMATIC_TEXT'),
          this.urlHelper.krosCompanyPrefix,
          this.moduleName,
          this.urlHelper.contactUs
        );
        break;
      case this.config.isLicenseReadonly && !this.config.isAutomaticLaunch:
        this.infoText = formatString(
          this.translateService.translate('LICENSE_INFO.MKT_MODAL_TEXT_READONLY_TEXT'),
          this.urlHelper.krosCompanyPrefix,
          this.moduleName,
          this.urlHelper.contactUs
        );
        break;
      case this.config.isLicenseFull && this.config.isLicenseToExpire:
        this.infoText = formatString(
          this.translateService.translate('LICENSE_INFO.MKT_MODAL_TEXT_FULL_EXPIRING_TEXT'),
          this.urlHelper.krosCompanyPrefix,
          this.moduleName,
          this.config.remainingDays + '',
          this.pluralisationByCountPipe.transform(this.config.remainingDays, 'LICENSE_INFO.DAY'),
          this.urlHelper.contactUs
        );
        break;
    }
  }

  protected resolveFooterText(): void {
    if (this.config.isLicenseReadonly || (this.config.isLicenseFull && this.config.isLicenseToExpire)) {
      this.footerText = formatString(
        this.translateService.translate('LICENSE_INFO.DOCUMENTS_AVAILABLE_INFO'),
        this.translateService.translate(this.urlHelper.isCzVersion
          ? 'LICENSE_INFO.DOCUMENTATION_CZ'
          : 'LICENSE_INFO.DOCUMENTATION_SK'),
      );
    }
  }
}
