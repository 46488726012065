<div class="card">
  <ng-container *ngIf="isDataLoaded && data; else loading">
    <h5>{{ title }}</h5>

    <div class="content">
      <ngx-charts-pie-grid
        [scheme]="colorScheme"
        [results]="dataGraph"
        [designatedTotal]="data.totalPrice"
        [view]="viewPie"
        [animations]="false"
      >
      </ngx-charts-pie-grid>

      <div class="info">
        <div class="values total-value">
          <span
            class="value font-weight-bold"
            data-test="pie-chart-total-price"
          >
            {{ data.totalPrice | currency: currencySymbol: 'code': digitsInfo: appLocation }}
          </span>

          <span class="label">{{ totalPriceLabel }}</span>
        </div>

        <div class="values completed-value">
          <span
            class="value font-weight-bold"
            data-test="pie-chart-completed-price"
          >
            {{ data.completedPrice | currency: currencySymbol: 'code': digitsInfo: appLocation }}
          </span>

          <span class="label">{{ 'BUILDING_PROGRESS.DASHBOARD.COMPLETED' | translate }}</span>
        </div>

        <div class="values rest-value">
          <span
            class="value font-weight-bold"
            data-test="pie-chart-rest-price"
          >
            {{ data.restPrice | currency: currencySymbol: 'code': digitsInfo: appLocation }}
          </span>

          <span class="label">{{ 'BUILDING_PROGRESS.DASHBOARD.REST' | translate }}</span>
        </div>

        <div
          class="values additional-value"
          *ngIf="data.additionalPrice"
        >
          <span
            class="value font-weight-bold"
            data-test="pie-chart-additional-price"
          >
            {{ data.additionalPrice | currency: currencySymbol: 'code': digitsInfo: appLocation }}
          </span>

          <span class="label">{{ 'BUILDING_PROGRESS.DASHBOARD.ADDITIONAL' | translate }}</span>
        </div>

        <div
          *ngIf="data.notCompletedPrice"
          class="values not-completed-value"
        >
          <span
            class="value font-weight-bold"
            data-test="pie-chart-not-completed-price"
          >
            {{ data.notCompletedPrice | currency: currencySymbol: 'code': digitsInfo: appLocation }}
          </span>

          <span class="label">{{ 'BUILDING_PROGRESS.DASHBOARD.NOT_COMPLETED' | translate }}</span>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #loading>
  <kros-timeline [type]="timelineType.ThreeLine"></kros-timeline>
</ng-template>
