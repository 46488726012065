import { ActionCreator, createReducer, on, ReducerTypes } from '@ngrx/store';

import { BudgetItem, isBuildingObject, isConstruction, isItem, isSection } from '@kros-sk/ssw-shared-legacy';
import { BulkActionItems } from '@kros-sk/ssw-budget/shared/data-access/models';

import { budgetSharedActions } from './budget-shared-actions';
import { BudgetSharedState, initialBudgetSharedState } from './budget-shared-state';

const budgetLayout = 'budget-layout';
export const budgetBoqLayout = 'budget-boq-layout';
const otherBudgetLayout = 'other-budget-layout';
const tableLayoutSettings = [otherBudgetLayout, budgetLayout, budgetBoqLayout];

export const filterItemsForBulkAction = (
  selectedItems: BudgetItem[],
  isSearchActive = false,
  isPriceEditAction = false
): BulkActionItems => {
  if (isSearchActive) {
    return {
      sectionIds: [],
      buildingObjectIds: [],
      occurrenceItemIds: selectedItems.filter(item => isItem(item)).map(item => item.occurrenceIds[0]),
    };
  }
  if (selectedItems.length === 1 && isConstruction(selectedItems[0]) && isPriceEditAction) {
    return {
      sectionIds: [],
      buildingObjectIds: [+selectedItems[0].id],
      occurrenceItemIds: []
    };
  }
  const selectedItemsDict = selectedItems.reduce((acc, item) => {
    if (!isConstruction(item)) {
      acc[item.id] = item;
    }
    return acc;
  }, {});

  const items = selectedItems.filter(i => !selectedItemsDict[i.parentId]);

  return {
    sectionIds: items.filter(item => isSection(item)).map(item => item.sectionId),
    buildingObjectIds: items.filter(item => isBuildingObject(item)).map(item => item.buildingObjectId),
    occurrenceItemIds: items.filter(item => isItem(item)).map(item => item.occurrenceIds[0])
  };
};

const transformSettings = (settingName: string, settings: any): any =>
  tableLayoutSettings.includes(settingName) ? { ...settings, selectedRowKeys: [] } : settings;

const reducers: ReducerTypes<BudgetSharedState, ActionCreator[]>[] = [
  on(budgetSharedActions.setProject, (state, payload) => {
    return {
      ...initialBudgetSharedState,
      project: payload.project
    };
  }),
  on(budgetSharedActions.editProjectDetailsSuccess, (state, payload): BudgetSharedState => {
    return {
      ...state,
      project: payload.project ?? { ...state.project }
    };
  }),
  on(budgetSharedActions.setBudget, (state, payload) => {
    return {
      ...state,
      budget: payload.budget,
    };
  }),
  on(budgetSharedActions.getBudgetSettingsSuccess, (state, payload): BudgetSharedState => {
    return {
      ...state,
      settingsState: { ...state.settingsState, settings: payload.settings, isLoaded: true }
    };
  }),
  on(budgetSharedActions.getBudgetUISettingsStart, (state, payload): BudgetSharedState => {
    const uiSettings = { ...state.uiSettings };
    uiSettings[payload.settingsName] = { ...uiSettings[payload.settingsName], isLoading: true };
    return {
      ...state,
      uiSettings
    };
  }),
  on(budgetSharedActions.getBudgetUISettingsSuccess,
    budgetSharedActions.setBudgetUISettingsSuccess,
    (state, payload): BudgetSharedState => {
      const uiSettings = { ...state.uiSettings };
      uiSettings[payload.settingsName] = { value: transformSettings(payload.settingsName, payload.settings), isLoading: false };
      return {
        ...state,
        uiSettings
      };
    }),
  on(budgetSharedActions.getBudgetUISettingsError, (state, payload): BudgetSharedState => {
    const uiSettings = { ...state.uiSettings };
    uiSettings[payload.settingsName] = { ...uiSettings[payload.settingsName], isLoading: false };
    return {
      ...state,
      uiSettings
    };
  }),
  on(budgetSharedActions.setIsMainBudget, (state, payload): BudgetSharedState => {
    return {
      ...state,
      budget: state.budget.id === payload.mainBudget.id
        ? { ...state.budget, isMain: false }
        : state.budget.id === payload.budgetId ? { ...state.budget, isMain: true } : state.budget,
    };
  }),
];
export const sharedReducers = createReducer(initialBudgetSharedState, ...reducers);
