import { AfterViewInit, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormGroup } from '@angular/forms';

import { getStringValueFromControl, ProjectDetail } from '@kros-sk/ssw-shared-legacy';
import { KrosModalRef } from '@kros-sk/components';

import { ProjectsDispatchersService, ProjectsSelectorsService } from '../../../store/projects';
import { ProjectsService } from '../../../projects/services/projects.service';

@Component({
  selector: 'app-project-header-data-dialog',
  templateUrl: './project-header-data-dialog.component.html',
  styleUrls: ['./project-header-data-dialog.component.scss']
})
export class ProjectHeaderDataDialogComponent implements OnInit, AfterViewInit {

  private destroyRef = inject(DestroyRef);
  private modalRef = inject(KrosModalRef);
  private projectDispatchersService = inject(ProjectsDispatchersService);
  private projectsSelectorsService = inject(ProjectsSelectorsService);
  private projectsService = inject(ProjectsService);

  project: ProjectDetail;
  form: UntypedFormGroup;
  canEdit: boolean;

  ngOnInit(): void {
    this.canEdit = this.modalRef.data.canEdit;

    if (!this.modalRef.data.subcontractorId) {
      this.projectDispatchersService.loadProjectDetail(this.modalRef.data.projectId);
    }
    this.form = new UntypedFormGroup({});

    if (!this.canEdit) {
      this.form.disable();
    }
  }

  ngAfterViewInit(): void {
    if (this.modalRef.data.subcontractorId) {
      this.projectsService.getProjectDetail(this.modalRef.data.projectId, this.modalRef.data.subcontractorId)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(
          project => {
            this.project = project;
            this.fillForm();
          }
        );
    } else {
      this.projectsSelectorsService.projectDetail$
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((project: ProjectDetail) => {
          this.project = project;
          this.fillForm();
        });
    }
  }

  private fillForm(): void {
    if (this.project) {
      this.form.patchValue({
        place: this.project.place,
        customerName: this.project.customerName,
        customerId: this.project.customerId,
        customerVatId: this.project.customerVatId,
        authorName: this.project.authorName,
        authorId: this.project.authorId,
        authorVatId: this.project.authorVatId,
        contractorName: this.project.contractorName,
        contractorId: this.project.contractorId,
        contractorVatId: this.project.contractorVatId,
        designerName: this.project.designerName,
        designerId: this.project.designerId,
        designerVatId: this.project.designerVatId,
        constructionManager: this.project.constructionManager
      });
    }
  }

  onCancel(): void {
    this.modalRef.cancel();
  }

  onSubmit(): void {
    this.modalRef.submit({ project: this.getEditedProject() });
  }

  private getEditedProject(): ProjectDetail {
    return {
      ...this.project,
      place: getStringValueFromControl(this.form.controls.place),
      customerName: getStringValueFromControl(this.form.controls.customerName),
      customerId: getStringValueFromControl(this.form.controls.customerId),
      customerVatId: getStringValueFromControl(this.form.controls.customerVatId),
      contractorName: getStringValueFromControl(this.form.controls.contractorName),
      contractorId: getStringValueFromControl(this.form.controls.contractorId),
      contractorVatId: getStringValueFromControl(this.form.controls.contractorVatId),
      designerName: getStringValueFromControl(this.form.controls.designerName),
      designerId: getStringValueFromControl(this.form.controls.designerId),
      designerVatId: getStringValueFromControl(this.form.controls.designerVatId),
      authorName: getStringValueFromControl(this.form.controls.authorName),
      authorId: getStringValueFromControl(this.form.controls.authorId),
      authorVatId: getStringValueFromControl(this.form.controls.authorVatId),
      constructionManager: getStringValueFromControl(this.form.controls.constructionManager)
    };
  }
}
