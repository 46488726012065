import { Component, inject } from '@angular/core';

import { BudgetLicenseHelper } from '@kros-sk/ssw-budget/shared/utils/license';
import { BudgetSharedSelectorsService } from '@kros-sk/ssw-budget/shared/data-access/store';
import { IItemType, IParentId, TreeComponent } from '@kros-sk/ssw-shared-legacy';
import { PermissionSelectorsService } from '@kros-sk/ssw-shared/permission';

@Component({ template: '' })
export abstract class BudgetTreeComponent<T extends IParentId<string> & IItemType> extends TreeComponent<string, T> {

  protected permissionSelectors = inject(PermissionSelectorsService);
  protected sharedSelectors = inject(BudgetSharedSelectorsService);
  protected licenseHelper = inject(BudgetLicenseHelper, { optional: true });

  budgetPermission$ = this.permissionSelectors.budgetPermission$;
  canEditBudget$ = this.permissionSelectors.canEditBudget$;

  protected checkLicense(): boolean {
    return this.licenseHelper?.checkTrialFullLicense();
  }
}
