import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { InfoMessageApiService } from './api/info-message.api.service';
import { InfoMessageComponent } from './components/info-message/info-message.component';
import {
  InfoMessageDispatchersService,
  infoMessageFeatureKey,
  InfoMessageSelectorsService,
  reducer
} from './store';
import { InfoMessageEffects } from './store/info-message.effects';

@NgModule({
  declarations: [
    InfoMessageComponent
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(infoMessageFeatureKey, reducer),
    EffectsModule.forFeature([InfoMessageEffects])
  ],
  exports: [
    InfoMessageComponent
  ],
  providers: [
    InfoMessageApiService,
    InfoMessageSelectorsService,
    InfoMessageDispatchersService
  ]
})
export class InfoMessageModule {}
