import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';

import { TagCreationModel } from './tag-creation.model';
import { TagEditViewModel } from './tag-edit.view-model';

const tagApi = '/api/projectService/Tags';
const tagCreateEndpoint = tagApi + '/create';
const tagEditEndpoint = tagApi + '/edit';

@Injectable()
export class TagsService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any
  ) { }

  createTag(creationModel: TagCreationModel): Observable<number> {
    return this.http.post<number>(this.appConfig.appUrls.titanGatewayUrl + tagCreateEndpoint, creationModel);
  }

  editTag(editViewModel: TagEditViewModel): Observable<any> {
    return this.http.put<any>(this.appConfig.appUrls.titanGatewayUrl + tagEditEndpoint, editViewModel);
  }
}
