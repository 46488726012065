import { ActionCreator, createFeature, createReducer, on, ReducerTypes } from '@ngrx/store';

import { constructionDiaryActions } from './construction-diary.actions';
import { constructionDiaryFeatureKey } from './construction-diary-selectors.service';
import { constructionDiaryInitialState, ConstructionDiaryState } from './construction-diary.state';

const reducers: ReducerTypes<ConstructionDiaryState, ActionCreator[]>[] = [
  on(constructionDiaryActions.loadLicenseStart, (state, payload): ConstructionDiaryState => {
    return {
      ...state,
      product: payload.product,
      license: null
    };
  }),
  on(constructionDiaryActions.loadLicenseSuccess, (state, payload): ConstructionDiaryState => {
    return {
      ...state,
      license: payload.license
    };
  })
];

const reducer = createReducer(constructionDiaryInitialState, ...reducers);

export const constructionDiaryFeatureState = createFeature({
  name: constructionDiaryFeatureKey,
  reducer
});
