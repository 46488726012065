import { Directive, Input, OnDestroy } from '@angular/core';

import { Observable } from 'rxjs';
import { SubSink } from 'subsink';

import { Focusable } from './focusable.interface';

@Directive({
  selector: '[focusFirstInvalidInput]'
})
export class FocusFirstInvalidInputDirective implements OnDestroy {
  @Input('focusFirstInvalidInput') set setTrigger$(trigger: Observable<any>) {
    this.subs.sink = trigger?.subscribe(() => this.focusFirstInvalid());
  }

  private focusables: Focusable[] = [];
  private subs = new SubSink();

  constructor(
  ) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  registerFocusable(focusable: Focusable): void {
    this.focusables.push(focusable);
  }

  unregisterFocusable(focusable: Focusable): void {
    this.focusables = this.focusables.filter((foc) => foc !== focusable);
  }

  focusFirstInvalid(): void {
    const firstFocusable = this.focusables.find((focusable: Focusable) =>
      focusable.isInvalid());
    if (firstFocusable) {
      firstFocusable.focus();
    }
  }
}