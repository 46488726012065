import { Platform } from '@angular/cdk/platform';

import { createSelector } from '@ngrx/store';

import { deviceDetectorStoreFeature } from './device-detector.state';
import { DeviceType } from '../../types/device-types';

// !!! doplniť potrebné selektory (ak je možné stav vypočítať, radšej vytvoriť selektor, ako zapisovať do state)

const {
  selectDeviceType,
  selectIsMobileOS,
  selectPlatform
} = deviceDetectorStoreFeature;

const selectIsMobile = createSelector(selectDeviceType, s => s === DeviceType.MOBILE);
const selectIsDesktop = createSelector(selectDeviceType, s => s === DeviceType.DESKTOP);
const selectIsFirefox = createSelector(selectPlatform, s => !!s && s.includes('FIREFOX' as keyof Platform));
const selectIsSafari = createSelector(selectPlatform, s => !!s && s.includes('SAFARI' as keyof Platform));
const selectIsIOS = createSelector(selectPlatform, s => !!s && s.includes('IOS' as keyof Platform));

export const fromDeviceDetector = {
  selectDeviceType,

  selectIsMobile,
  selectIsDesktop,
  selectIsMobileOS,
  selectIsFirefox,
  selectIsSafari,
  selectIsIOS
};
