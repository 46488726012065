import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { NewSharingComboMode } from '../../models/sharing-combo-mode.enum';
import { PermissionChangedDto } from '../../models/permission-changed-dto.model';
import { PermissionListService } from '../../services/permission-list.service';
import { UserSharingHelpersService } from '../../services/user-sharing-helpers.service';

@Component({
  template: '',
  selector: 'kros-user-sharing-input-base',
})
export class UserSharingListItemBaseComponent implements OnInit {

  protected permissionListService = inject(PermissionListService);
  protected sharingHelperService = inject(UserSharingHelpersService);
  protected destroyRef = inject(DestroyRef);

  @Input() sharingMode: NewSharingComboMode;
  @Input() unconfirmedSharing = false;
  @Input() readonly = false;

  @Output() deletedPermission = new EventEmitter<PermissionChangedDto>();
  @Output() changedPermission = new EventEmitter<PermissionChangedDto>();

  unsaved = false;
  deleted = false;

  ngOnInit(): void {
    this.sharingHelperService.changesCanceled$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(onlyConfirmedChanges => {
        const cancelChanges = onlyConfirmedChanges ? !this.unconfirmedSharing : true;

        if (cancelChanges) {
          this.cancelChanges();
        }
      });

    if (this.unconfirmedSharing) {
      this.unsaved = true;
    }
  }

  cancelChanges(): void {
    if (!this.unconfirmedSharing) {
      this.unsaved = false;
      this.deleted = false;
    }
  }
}
