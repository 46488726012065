import { Directive, ElementRef, OnDestroy, OnInit, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

import { Focusable } from './focusable.interface';
import { FocusFirstInvalidInputDirective } from './focus-first-ivalid-input.directive';

@Directive({
  selector: '[focusableInput]'
})
export class FocusableInputDirective implements OnInit, OnDestroy, Focusable {
  private inputElement: HTMLElement;

  constructor(
    @Optional() private focusFirst: FocusFirstInvalidInputDirective,
    private controlDirective: NgControl,
    private element: ElementRef
  ) {}

  ngOnInit(): void {
    if (!this.element) return;
    this.inputElement = this.element.nativeElement.closest('input');

    if (this.focusFirst) {
      this.focusFirst.registerFocusable(this);
    }
  }

  ngOnDestroy(): void {
    if (this.focusFirst) {
      this.focusFirst.unregisterFocusable(this);
    }
  }

  focus(): void {
    if (this.inputElement) {
      this.inputElement.focus();
    }
  }

  isInvalid(): boolean {
    if (!this.controlDirective) return false;
    return this.controlDirective.status === 'INVALID';
  }
}
