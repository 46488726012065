import {
  ChangeDetectionStrategy, Component,
  EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl, Validators } from '@angular/forms';

import { debounceTime, map, switchMap } from 'rxjs';
import { SubSink } from 'subsink';

import { ApiSearchService } from '../api-search/api-search.service';
import { AutocompleteConfig, InputCommandType, KrosFormsService } from '../../inputs';
import { DeviceDetectorService, DeviceType } from '../../services';

@Component({
  selector: 'kros-street-selector',
  templateUrl: './street-selector.component.html',
  styleUrls: ['./street-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: StreetSelectorComponent,
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StreetSelectorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() required = false;
  @Input() showAllErrors = false;

  @Output() newAddress: EventEmitter<{
    city: string,
    postCode: string,
    street: string
  }> = new EventEmitter<{ city: string, postCode: string, street: string }>();

  @ViewChild('optionTemplate', { static: true }) optionTemplate: TemplateRef<any>;
  autocompleteConfig: AutocompleteConfig;

  streetForm = new UntypedFormControl('');

  private subs = new SubSink();

  constructor(
    private formService: KrosFormsService,
    private deviceDetector: DeviceDetectorService,
    private apiSearchService: ApiSearchService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes['showAllErrors']?.currentValue && changes['showAllErrors'].currentValue !== changes['showAllErrors'].previousValue) {
      this.streetForm.markAllAsTouched();
      this.formService.triggerInputCommand('street', { type: InputCommandType.DETECT_CHANGES });
    }
  }

  ngOnInit(): void {
    if (this.required) this.streetForm.setValidators(Validators.required);
    this.autocompleteConfig = {
      searchMethod: (text$) => text$.pipe(
        debounceTime(200),
        switchMap(term => this.apiSearchService.fetchAddressItems(term, 'StreetName', true).pipe(
          map(addresses => {
            return addresses.map(address => {
              return {...address, postCode: address.postCode.replace(/^(.{3})(.*)$/, '$1 $2')};
            });
          })
        ))
      ),
      inputFormatter: (x: any): string => {
        this.newAddress.emit({ city: x.city, postCode: x.postCode, street: x.street });
        return x.street;
      },
      minOptionsShown: 0,
      optionTemplate: this.optionTemplate,
      openOnFocus: true,
      optionHeight: 42
    };
    if (this.deviceDetector.deviceType === DeviceType.Mobile) {
      // open to top, because of mobile keyboard
      this.autocompleteConfig.positions = [
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom'
        }
      ];
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  writeValue(name: any): void {
    this.streetForm.setValue(name,
      {
        emitEvent: false
      });
    this.formService.triggerInputCommand('street', { type: InputCommandType.DETECT_CHANGES });
  }

  registerOnChange(fn: any): void {
    this.subs.add(this.streetForm.valueChanges.subscribe(fn));
  }

  registerOnTouched(fn: any): void { }
}
