<app-filter (defaultClick)="onDefaultViewClick()">
  <ng-template #leftContent>
    <button
      class="btn btn-link btn-refresh ga-refresh"
      type="button"
      krosTooltip="{{ 'BUILDING_PROGRESS.REFRESH' | translate }}"
      (click)="onRefresh()"
      data-test="building-progress-refresh"
    >
      <i class="material-icons ga-refresh">refresh</i>
    </button>

    <div
      class="desktop-filter"
      data-test="desktop-filter"
    >
      <ng-container *ngTemplateOutlet="periodfilterDropdown"></ng-container>
    </div>

    <div
      class="desktop-filter"
      data-test="desktop-filter"
    >
      <ng-container *ngTemplateOutlet="viewPicker"></ng-container>
    </div>

    <div
      class="desktop-filter"
      data-test="desktop-filter"
    >
      <ng-container *ngTemplateOutlet="columnFilterDropdown"></ng-container>
    </div>

    <div
      ngbDropdown
      class="filter-dropdown"
      krosTooltip="{{ 'COMMON.FILTER' | translate }}"
      autoClose="outside"
    >
      <button
        ngbDropdownToggle
        class="btn btn-link btn-filter-dropdown ga-pv-filter-dropdown"
        type="button"
      >
        <i class="material-icons ga-pv-filter-dropdown">filter_alt</i>
      </button>
      <div ngbDropdownMenu>
        <ng-container *ngTemplateOutlet="periodfilterDropdown"></ng-container>
        <ng-container *ngTemplateOutlet="viewPicker"></ng-container>
        <ng-container *ngTemplateOutlet="columnFilterDropdown"></ng-container>
      </div>
    </div>

    <button
      *ngIf="isSummaryPanelButtonVisible"
      class="btn toolbar-action btn-summary-panel ga-functions"
      type="button"
      (click)="onSummaryPanel()"
      data-test="summary-panel-button"
    >
      <div class="btn-summary-panel-content">
        <i class="material-icons ga-functions">functions</i>
        <span class="toolbar-action-text">{{ 'BUILDING_PROGRESS.SUMMARY_DATA' | translate }}</span>
      </div>
    </button>

  </ng-template>

  <ng-template #rightContent>
    <kros-search-input
      [width]="'300px'"
      *ngIf="showSearch"
    ></kros-search-input>
  </ng-template>
</app-filter>

<ng-template #periodfilterDropdown>
  <app-period-filter-dropdown [periods]="periods"></app-period-filter-dropdown>
</ng-template>

<ng-template #viewPicker>
  <app-view-picker [isSubcontractor]="permissionHelper.isContractor"></app-view-picker>
</ng-template>

<ng-template #columnFilterDropdown>
  <app-building-progress-column-filter *ngIf="showColumnFilter">
  </app-building-progress-column-filter>
</ng-template>
