import { Injectable } from '@angular/core';

import { SubSink } from 'subsink';
import { take } from 'rxjs';

import { formatString, UserService } from '@kros-sk/ssw-cdk';
import { KrosShepherdService } from '@kros-sk/components';
import {
  TourStep,
  TourStepButton,
  TourStepButtonType,
  TourStepCustomButton,
  TourStepPosition
} from '@kros-sk/models';

import { TranslateService } from '../translate';

@Injectable()
export class TourBaseService {
  protected subs = new SubSink();
  protected tourName: string;

  get isActive(): boolean {
    return this.krosShepherdService.isActive;
  }

  get userId(): number {
    return this.userService?.userId ?? -1;
  }

  constructor(
    protected krosShepherdService: KrosShepherdService,
    protected translateService: TranslateService,
    private userService: UserService
  ) {
    this.subs.sink = this.translateService.langChanged.subscribe(() => this.reloadSteps());
  }

  protected getSteps(): TourStep[] {
    return [];
  }

  runTourIfCan(canRunTour: boolean = true, canFirstRun: boolean = false): void {
    if (!this.isActive) {
      if (canRunTour) {
        this.krosShepherdService.initTour(this.tourName, this.getSteps(), false, this.userId);
        this.subs.sink = this.krosShepherdService.tourClosed$.subscribe(p => {
          if (p !== 'help' && p !== 'only-help') {
            try {
              this.krosShepherdService.show(false, 'only-help');
            } catch { }
          }
        });

        if (canFirstRun && this.krosShepherdService.canShowTour(this.tourName, this.userId)) {
          this.krosShepherdService.show();
          this.subs.sink = this.krosShepherdService.tourClosed$.pipe(take(1)).subscribe(() => this.saveFirstRun());
        }
      } else {
        this.clear();
      }
    }
  }

  clear(): void {
    this.krosShepherdService.clearTour(this.tourName);
    this.subs.unsubscribe();
  }

  getStep(
    title: string,
    text: string | HTMLElement,
    elementSelector: string,
    buttons?: TourStepButtonType,
    id?: string,
    position?: TourStepPosition,
    customButtons?: TourStepCustomButton[],
    timeout?: number
  ): TourStep {
    return {
      id,
      attachTo: {
        element: elementSelector,
        on: position ?? 'bottom'
      },
      buttons: this.getButtons(buttons, customButtons),
      cancelIcon: {
        enabled: true
      },
      beforeShowPromise: (): Promise<number> => new Promise((resolve) => {
        setTimeout(() => resolve(0), timeout ?? 500);
      }),
      classes: `tour-step-${id} green position-${position ?? 'bottom'}`,
      highlightClass: 'highlight',
      scrollTo: false,
      title,
      text,
      arrow: true,
      when: {
        show: (): void => {
          this.krosShepherdService.actualStep = id ?? elementSelector;
        }
      }
    };
  }

  getStepIndicator(order: number, count: number): string {
    return `<p class="step-indicator">${formatString(this.translateService.translate('GUIDE.STEP_PROGRESS'), order, count)}<p>`;
  }

  protected saveFirstRun(): void { }

  private reloadSteps(): void {
    let currentStepId = '';
    const wasActive = this.isActive;
    if (this.krosShepherdService.actualStep) {
      currentStepId = this.krosShepherdService.currentStepId;
    }
    this.krosShepherdService.clearTour(this.tourName);
    this.krosShepherdService.initTour(this.tourName, this.getSteps(), false, this.userId);
    if (wasActive && currentStepId) {
      this.krosShepherdService.show(false, currentStepId);
    }
  }

  private getButtons(buttons: TourStepButtonType, customButtons: TourStepCustomButton[]): TourStepButton[] {
    let ret: TourStepButton[] = [];

    // eslint-disable-next-line no-bitwise
    if ((buttons | TourStepButtonType.Back) === buttons) {
      ret.push({
        classes: 'btn btn-outline-secondary',
        text: this.translateService.translate('COMMON.BACK'),
        type: 'back'
      });
    }

    // eslint-disable-next-line no-bitwise
    if ((buttons | TourStepButtonType.Next) === buttons) {
      ret.push({
        classes: 'btn btn-primary',
        text: this.translateService.translate('COMMON.NEXT'),
        type: 'next'
      });
    }

    // eslint-disable-next-line no-bitwise
    if ((buttons | TourStepButtonType.Cancel) === buttons) {
      ret.push({
        classes: 'btn btn-outline-secondary',
        text: this.translateService.translate('COMMON.CLOSE'),
        type: 'next'
      });
    }

    if (customButtons) {
      ret = [...ret, ...customButtons.map(p => {
        return {
          action: p.action,
          text: p.text,
          classes: p.isPrimary ? 'btn btn-primary' : 'btn btn-outline-secondary'
        } as TourStepButton;
      })];
    }

    return ret;
  }
}
