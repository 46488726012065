import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Observable, of, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

import { RegistrationNotificationService } from './registration-notification/registration-notification.service';
import { UserService } from './user.service';

@Injectable()
export class LoadUserIdGuard {

  private userService = inject(UserService);
  private appInsightsService = inject(AppInsightsBaseService, { optional: true });
  private registrationNotificationService = inject(RegistrationNotificationService);
  private destroyRef = inject(DestroyRef);

  private loadedUserId: boolean;
  private subject = new Subject<boolean>();

  constructor() {
    this.userService.registerUser()
      .pipe(
        switchMap(p => {
          if (p !== null) {
            this.registrationNotificationService.notifyRegistrationCompleted(p);
            return this.userService.loadUserId();
          }
          return of(-1);
        }),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(userId => {
        if (userId !== -1) {
          this.appInsightsService?.setAuthenticatedUserContext('' + userId);
        }
        this.loadedUserId = true;
        this.subject.next(true);
      });
  }

  canActivate(): Observable<boolean> {
    return this.userIdIsLoaded();
  }

  canActivateChild(): Observable<boolean> {
    return this.userIdIsLoaded();
  }

  private userIdIsLoaded(): Observable<boolean> {
    if (this.loadedUserId) {
      return of(true);
    }

    return this.subject;
  }
}
