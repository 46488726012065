import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EshopCartLinkDirective } from './eshop-cart-link/eshop-cart-link.directive';
import { EswEshopCartLinkDirective } from './esw-eshop-cart-link/esw-eshop-cart-link.directive';

@NgModule({
  declarations: [
    EshopCartLinkDirective,
    EswEshopCartLinkDirective,
  ],
  exports: [
    EshopCartLinkDirective,
    EswEshopCartLinkDirective,
  ],
  imports: [
    CommonModule
  ]
})
export class EshopModule { }
