import { Directive } from '@angular/core';

@Directive({
  selector: '[krosRecalcWatcherMaster]'
})
export class RecalcWatcherMasterDirective {

  constructor() { }

}
