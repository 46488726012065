import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import {
  AnonymousCredential,
  BlobDeleteResponse,
  BlobDownloadResponseModel,
  BlobUploadCommonResponse,
  BlockBlobClient,
  newPipeline
} from '@azure/storage-blob';

import { UploadProgress } from '../models/upload-progress.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentStorageService {

  downloadDocument(blobAccessData: any): Promise<BlobDownloadResponseModel> {
    return this.getBlockBlobClient(blobAccessData).download();
  }

  uploadFileToBlobStorage(
    file: File,
    id: string,
    blobAccessData: any,
    onProgress: BehaviorSubject<UploadProgress>
  ): Promise<BlobUploadCommonResponse> {
    return this.getBlockBlobClient(blobAccessData).uploadData(file, {
      onProgress: (progress) => {
        onProgress.next({
          id,
          progress: Math.round(progress.loadedBytes / (file.size / 100))
        });
      },
      blobHTTPHeaders: {
        blobContentType: file.type
      }
    });
  }

  deleteBlob(blobAccessData: any): Promise<BlobDeleteResponse> {
    return this.getBlockBlobClient(blobAccessData).delete();
  }

  private getBlockBlobClient(blobAccessData: any): BlockBlobClient {
    const pipeline = newPipeline(new AnonymousCredential());

    return new BlockBlobClient(blobAccessData.blobUri, pipeline);
  }
}
