<div class="project-filters">
  <div class="buttons">
    <button
      class="btn btn-primary btn-new"
      data-test="new-project-button"
      type="button"
      (click)="createProject()"
    >
      <span
        krosMaterialIcon
        class="icon"
      >add</span>
      <span class="text">{{'PROJECTS.ZOZNAM.NEW_PROJECT' | translate}}</span>
    </button>
  </div>

  <div class="divider-horizontal"></div>

  <app-project-filters
    class="filters"
    [filterIndex]="filterIndex"
    (filterChanged)='onFilterChanged($event)'
  ></app-project-filters>

  <app-user-data-statistics class="user-statistics"></app-user-data-statistics>
</div>
