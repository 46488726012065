<div class="panel-content">
  <div
    *ngIf="canEditSubcontractors"
    class="buttons"
  >
    <button
      class="btn btn-primary btn-new"
      data-test="new-subcontractor-button"
      type="button"
      (click)="openSelectItemsDialog()"
    >
      <span class="text">{{ 'SUBCONTRACTORS.NEW' | translate }}</span>
    </button>
  </div>

  <div class="divider-horizontal"></div>

  <app-subcontractors-list
    [subcontractors]="selectorsService.subcontractors$ | async"
    [selectedSubcontractor]="selectorsService.selectedSubcontractor$ | async"
    [subcontractorData$]="buildingProgressSelectorsService.constructionData$"
    [tableId]="tableId"
    (selectSubcontractor)="onSubcontractorSelected($event)"
  ></app-subcontractors-list>
</div>
