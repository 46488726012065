import { Injectable } from '@angular/core';

import { createFeatureSelector, createSelector, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import {
  adapterFolderHierarchy,
  DocumentsExplorerSelectorsService,
  documentsFeatureKey,
  DocumentsState,
  FolderTreeNode,
  TemporaryUploadInfoModel
} from '@kros-sk/ssw-shared-legacy';

const selectDocumentsFeature = createFeatureSelector<DocumentsState>(documentsFeatureKey);

const selectFolderHierarchyEntities = createSelector(
  selectDocumentsFeature,
  state => state.folderHierarchy
);

const selectFolderHierarchy = createSelector(
  selectFolderHierarchyEntities,
  adapterFolderHierarchy.getSelectors().selectAll
);

const selectUploadingData = createSelector(
  selectDocumentsFeature,
  state => state.uploadingData
);

const selectIsUploadingMode = createSelector(
  selectDocumentsFeature,
  state => !!state.uploadingData
);

const selectShowSharing = createSelector(
  selectDocumentsFeature,
  state => state.showSharing
);


@Injectable()
export class DocumentsSelectorsService extends DocumentsExplorerSelectorsService {
  constructor(
    protected documentsExplorerStore: Store<DocumentsState>,
    private documentsStore: Store<DocumentsState>
  ) {
    super(documentsExplorerStore);
  }

  get folderHierarchy$(): Observable<FolderTreeNode[]> {
    return this.documentsStore.select(selectFolderHierarchy);
  }

  get isUploadingMode$(): Observable<boolean> {
    return this.documentsStore.select(selectIsUploadingMode);
  }

  get uploadingData$(): Observable<TemporaryUploadInfoModel> {
    return this.documentsStore.select(selectUploadingData);
  }

  get showSharing$(): Observable<boolean> {
    return this.documentsStore.select(selectShowSharing);
  }
}
