import { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';

import { TimelineType } from './timeline-type.enum';

@Component({
  selector: 'kros-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {

  @Input() type: TimelineType;
  @Input() lines: number;

  timelineType = TimelineType;
  linesArray: number[];

  constructor() { }

  ngOnInit(): void {
    if (this.type === TimelineType.NLines) {
      if (!this.lines) {
        throw new Error('You have to provide line parameter when using TimelineType.NLines.');
      } else {
        this.linesArray = Array(this.lines).fill(1);
      }
    }
  }

}
