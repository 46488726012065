import {
  ApplicationOpenerService,
  CalculationType,
  ColumnStatusIcon,
  getTextCellHeight,
  isAdditionOrChangeSheet,
  isConstructionOrBuildingObject,
  isItem,
  isMaterial,
  isSummaryItem,
  TranslateService
} from '@kros-sk/ssw-shared-legacy';
import { PermissionType } from '@kros-sk/ssw-shared/permission';

import { BudgetItem, BudgetModel } from '../../budget-approval/models';
import {
  BuildingProgressItem,
  BuildingProgressModel,
  BuildingProgressPeriodItem
} from '../models/construction-data.model';
import { BuildingProgressPeriod } from '../models/building-progress-period.model';
import { ColumnsConfigModel } from '../models/columns-config.model';
import { InvoiceHelper } from './invoice.helper';

export function getAutoCalculatedClass(item: BuildingProgressPeriodItem | BuildingProgressItem): string {
  return isAutoCalculatedItem(item) ? 'font-green' : '';
}

export function getPeriodCellClass(
  periodIndex: number, periodId: number, selectedPeriodId: number, rowindex: number, colName?: string): string {
  return (periodId === selectedPeriodId ? ' selected' : '') +
    (' period-index-' + periodIndex + '-row-' + rowindex) +
    ' ' + colName;
}

export function getPeriodCalculationTypeClass(periodItem: BuildingProgressPeriodItem): string {
  return (!!periodItem && periodItem.calculationType === CalculationType.BillOfQuantities) ? 'font-blue' : '';
}

export function getColumnsMaxNumber(constructionData: BuildingProgressModel | BudgetModel): ColumnsConfigModel {
  const ret = {
    amount: 0,
    unitPrice: 0,
    totalPrice: 0,
    itemsOffset: [],
    averageLineHeight: 0
  };
  let totalHeight = 0;
  const isSearch = (constructionData.items as any[]).find(p => p.itemType === 'K' || p.itemType === 'M')
    ?.description.includes('span style');

  constructionData.items.forEach(p => {
    ret.amount = Math.max(ret.amount, p.amount);
    ret.unitPrice = Math.max(ret.unitPrice, p.unitPrice);
    ret.totalPrice = Math.max(ret.totalPrice, p.totalPrice);
    totalHeight += getTextCellHeight(
      p.description.length - (isSearch ? 48 : 0),
      isSummaryItem(p) ? p.level : -1,
      isConstructionOrBuildingObject(p)
    );
    ret.itemsOffset.push(totalHeight);
  });

  ret.averageLineHeight = Math.floor(totalHeight / constructionData.items.length);

  return ret;
}

export function setColorIfAdditionOrChangeSheet(item: BuildingProgressItem | BudgetItem): string {
  return !!item && isConstructionOrBuildingObject(item) && isAdditionOrChangeSheet(item) ? ' font-green' : '';
}

export function setColorIfAdditionOrChangeSheetAllItems(item: BuildingProgressItem | BudgetItem): string {
  return !!item && isAdditionOrChangeSheet(item) ? ' font-green' : '';
}

export function setColorIfMaterial(item: BuildingProgressItem | BudgetItem): string {
  return !!item && isMaterial(item) ? ' font-blue' : '';
}

export function setCornerForNote(item: BuildingProgressItem): string {
  return !!item && item.hasNote ? ' cell-right-upper-corner-green' : ' cell-right-upper-corner-gray';
}

export function setFontColorIfPartialItem(item: BuildingProgressItem | BudgetItem): string {
  return item?.originalItemIdOfPartialItem ? ' font-orange' : '';
}

export function setIconColorIfUnsplitItem(item: BuildingProgressItem | BudgetItem): string {
  return item?.partialBudgetItems?.length > 1 ? '' : ' font-orange';
}

export function getPeriodStatusIcon(period: BuildingProgressPeriod): string {
  if (period.isApproved) {
    return 'check';
  } else if (period.isMultiStageApprovalInProgress) {
    return 'rule';
  } else if (period.isApprovalRequested) {
    return 'email';
  } else if (period.isLocked) {
    return 'lock';
  }

  return '';
}

export function isBoqVisible(period: BuildingProgressPeriod, item: BuildingProgressItem, permissionType: PermissionType): boolean {
  if (!isItem(item)) {
    return false;
  }

  const periodIndex = item.periods.findIndex(p => p.periodId === period.id);

  if (isPeriodEditable(period) &&
    item.periods[periodIndex].calculationType === CalculationType.BillOfQuantities) {
    return true;
  }

  if (!isPeriodEditable(period) &&
    item.calculationType === CalculationType.BillOfQuantities) {

    if (permissionType === PermissionType.Reader) {
      return item.periods[periodIndex].calculationType === CalculationType.BillOfQuantities;
    }

    return true;
  }

  return false;
}

export function isAutoCalculatedItem(item: BuildingProgressPeriodItem | BuildingProgressItem): boolean {
  return (!!item &&
    (item.calculationType === CalculationType.Weight ||
      item.calculationType === CalculationType.Rubble ||
      item.calculationType === CalculationType.PercentOfPrice));
}

export function isPeriodEditable(period: BuildingProgressPeriod): boolean {
  return period.isLocked || period.isApproved || period.isApprovalRequested;
}

export function getPeriodItemByPeriodId(periods: BuildingProgressPeriodItem[], periodId: number): BuildingProgressPeriodItem {
  return periods.find(p => p.periodId === periodId);
}

export function getPeriodRightStatusIcons(period: BuildingProgressPeriod, projectId: number, translateService: TranslateService,
  appOpenerService: ApplicationOpenerService, invoiceHelper: InvoiceHelper, isOwner: boolean, hasFullOrTrialLicense: boolean)
  : ColumnStatusIcon[] {
  const icons = [];
  if (period.hasDocuments) {
    icons.push({
      iconName: (): string => 'collections',
      tooltip: (): string => translateService.translate('BUILDING_PROGRESS.PERIODS.PERIODS_CONTAINS_PHOTOS'),
      clickAction: (): void => appOpenerService.openGallery(projectId, period.id)
    });
  }
  if (period.hasInvoices && isOwner && hasFullOrTrialLicense) {
    icons.push({
      iconName: (): string => 'description',
      tooltip: (): string => translateService.translate('BUILDING_PROGRESS.PERIODS.PERIODS_CONTAINS_INVOICES'),
      clickAction: (): void => invoiceHelper.showInvoices(projectId, period.id)
    });
  }
  return icons;
}
