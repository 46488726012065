import { Component } from '@angular/core';

import { ToastType } from '../../toast-type';

@Component({
  selector: 'kros-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss']
})
export class ToastsComponent {
  toastTypes = [ToastType.Info, ToastType.Error];

  constructor() { }
}
