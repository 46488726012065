import { Injectable } from '@angular/core';

import { KrosShepherdService } from '@kros-sk/components';
import { TourBaseService, TranslateService } from '@kros-sk/ssw-shared-legacy';
import { TourStep, TourStepButtonType } from '@kros-sk/models';
import { UserService } from '@kros-sk/ssw-cdk';

const tourName = 'projects';

@Injectable()
export class ProjectTourService extends TourBaseService {

  constructor(
    krosShepherdService: KrosShepherdService,
    translateService: TranslateService,
    userService: UserService
  ) {
    super(krosShepherdService, translateService, userService);
    this.tourName = tourName;
  }

  protected getSteps(): TourStep[] {
    const ret = [];

    ret.push(
      this.getStep(
        null,
        this.translate('PROJECTS_TEXT') + this.getStepIndicator(1, 4),
        'app-project-panel-container .btn-new',
        TourStepButtonType.Next,
        'new-button'));
    ret.push(
      this.getStep(
        null,
        this.translate('FILTER_TEXT') + this.getStepIndicator(2, 4),
        'app-project-filters',
        TourStepButtonType.Next | TourStepButtonType.Back,
        'filter',
        'right-start'));
    ret.push(
      this.getStep(
        null,
        this.translate('OPEN_PROJECT_TEXT') + this.getStepIndicator(3, 4),
        '.projects tbody tr:first-child',
        TourStepButtonType.Next | TourStepButtonType.Back,
        'project-row',
        'bottom-start'));
    ret.push(
      this.getStep(
        this.translateService.translate('GUIDE.HELP_HEAD'),
        this.translateService.translate('GUIDE.HELP_TEXT') + this.getStepIndicator(4, 4),
        'kros-help-buton', TourStepButtonType.Back,
        'help',
        'left',
        [{
          action: (): void => this.krosShepherdService.complete(),
          isPrimary: true,
          text: this.translateService.translate('COMMON.UNDERSTAND')
        }]));
    ret.push(
      this.getStep(
        this.translateService.translate('GUIDE.HELP_HEAD'),
        this.translateService.translate('GUIDE.HELP_TEXT'),
        'kros-help-buton',
        undefined,
        'only-help',
        'left',
        [{
          action: (): void => this.krosShepherdService.complete(),
          isPrimary: true,
          text: this.translateService.translate('COMMON.UNDERSTAND')
        }]));

    return ret;
  }

  private translate(keyPart: string): string {
    return this.translateService.translate('PROJECTS.GUIDE.' + keyPart);
  }
}
