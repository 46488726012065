import { Observable } from 'rxjs';

import { License, LicenseDetail } from '../license.model';

export abstract class LicenseService {

  getMishaLicense(): Observable<License> {
    throw new Error('Method not implemented.');
  }

  getBuildingProgressLicense(): Observable<License> {
    throw new Error('Method not implemented.');
  }

  getBudgetLicense(): Observable<License> {
    throw new Error('Method not implemented.');
  }

  getQuantityManagerLicense(): Observable<License> {
    throw new Error('Method not implemented.');
  }

  getDesktopLicense(): Observable<License> {
    throw new Error('Method not implemented.');
  }

  createBuildingProgressTrialLicense(): Observable<any> {
    throw new Error('Method not implemented.');
  }

  createBudgetTrialLicense(): Observable<any> {
    throw new Error('Method not implemented.');
  }

  createQuantityManagerTrialLicense(): Observable<any> {
    throw new Error('Method not implemented.');
  }

  getLicenseDetail(): Observable<LicenseDetail> {
    throw new Error('Method not implemented.');
  }
}
