import { produce } from 'immer';

import { BoqItemViewModel } from '../../building-progress/models/boq-edit.view-model';
import { BoqPeriodItem } from '../../building-progress/models/boq-period-item.model';

export function editBoqItems(boqEditedItems: BoqItemViewModel[], items: BoqPeriodItem[]): BoqItemViewModel[] {
  return produce(boqEditedItems, newBoqEditedItems => {
    items.forEach(item => {
      const newItem = newBoqEditedItems.find(i => i.id === item.id);
      if (newItem) {
        newItem.expression = item.expression;
        newItem.quantity = item.quantity;
        newItem.boqItemType = item.boqItemType;
      } else {
        newBoqEditedItems.push({ ...item } as BoqItemViewModel);
      }
    });
  });
}

export function editItemOrderBoqItems(boqEditedItems: BoqItemViewModel[], items: BoqPeriodItem[]): BoqItemViewModel[] {
  return produce(boqEditedItems, newBoqEditedItems => {
    items.forEach(item => {
      const newItem = newBoqEditedItems.find(i => i.id === item.id);
      if (newItem) {
        newItem.itemOrder = item.itemOrder;
        newItem.expression = item.expression;
        newItem.quantity = item.quantity;
      } else {
        newBoqEditedItems.push({ ...item } as BoqItemViewModel);
      }
    });
  });
}

export function createBoqItems(boqEditedItems: BoqItemViewModel[], items: BoqPeriodItem[], order: number): BoqItemViewModel[] {
  return produce(boqEditedItems, newBoqEditedItems => {
    items.filter(p => p.itemOrder > order)
      .forEach(item => {
        const newItem = newBoqEditedItems.find(i => i.id === item.id);

        if (newItem) {
          newItem.itemOrder = item.itemOrder;
        } else {
          newBoqEditedItems.push({ ...item } as BoqItemViewModel);
        }
      });
  });
}

export function markBoqItem(boqEditedItems: BoqItemViewModel[], boqItem: BoqPeriodItem, periodId: number): BoqItemViewModel[] {
  return produce(boqEditedItems, newBoqEditedItems => {
    const newItem = newBoqEditedItems.find(i => i.id === boqItem.id);
    if (newItem) {
      newItem.periodId = periodId;
    } else {
      newBoqEditedItems.push({ ...boqItem, periodId } as BoqItemViewModel);
    }
  });
}
