import { createAction, props } from '@ngrx/store';

import { PermissionType } from '@kros-sk/ssw-shared/permission';

import { DocumentListInfoModel, FolderTreeNode } from '../models';
import { ItemSharing } from '../../models';

export const loadDocumentsListStart = createAction('[Documents] Load documents list start', props<{ projectId: number }>());
export const loadDocumentsListSuccess = createAction(
  '[Documents] Load documents list success',
  props<{ documentsList: DocumentListInfoModel[] }>()
);
export const loadDocumentsListError = createAction('[Documents] Load documents list error', props<{ error: any }>());

export const loadRootDocumentPermissionStart = createAction(
  '[Documents] Load root document permission', props<{ projectId: number }>());
export const loadRootDocumentPermissionSuccess = createAction(
  '[Documents] Load root document permission success',
  props<{ permission: PermissionType }>()
);

export const loadFolderListStart = createAction('[Documents] Load folder list start', props<{ projectId: number, folderId: string }>());
export const loadFolderListSuccess = createAction(
  '[Documents] Load folder list success',
  props<{ documentsList: DocumentListInfoModel[] }>()
);
export const loadFolderListError = createAction('[Documents] Load folder list error', props<{ error: any }>());

export const loadEmptyList = createAction('[Documents] Load empty list');

export const markDocument = createAction('[Documents] Mark document', props<{ documentId: string }>());
export const markDocuments = createAction('[Documents] Mark documents', props<{ documents: string[] }>());
export const clearMarkedDocuments = createAction('[Documents] Clear marked documents', props<{ documents: string[] }>());
export const clearAllMarkedDocuments = createAction('[Documents] Clear all marked documents');

export const updateSharings = createAction('[Documents] Update sharings',
  props<{ isShared: boolean, sharings: ItemSharing[], documents: DocumentListInfoModel[] }>());

export const setCurrentFolder = createAction('[Documents] Set current folder', props<{ currentFolder: FolderTreeNode }>());
export const reloadCurrentFolder = createAction('[Documents] Reload current folder', props<{ projectId?: number }>());
export const clearCurrentFolder = createAction('[Documents] Clear current folder');

export const setProjectId = createAction('[Documents] Set project id', props<{ projectId: number }>());

export const loadFolder = createAction('[Documents] Load folder', props<{ folder: FolderTreeNode, projectId?: number }>());

export const loadRoot = createAction('[Documents] Load root', props<{ projectId?: number }>());
