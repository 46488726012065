<div class="header-container">
  <h4>{{ headerText }}</h4>

  <div class="header-actions">
    <button
      class="btn btn-link"
      data-test="boq-resize-button"
      (click)="onResizePanelClick()"
    >
      <i class="material-icons-outlined">unfold_more</i>
    </button>

    <button
      class="btn btn-link btn-close"
      data-test="boq-close-button"
      (click)="onCloseClick()"
    >
      <i class="material-icons">close</i>
    </button>
  </div>
</div>
