<div class="kros-modal-header">
  <h4 class="kros-modal-title">
    {{ 'COMPANY.CREATING_NEW' | translate }}
  </h4>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onClose()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div class="kros-modal-body create-company-component">
  <kros-create-company-editor (createCompany)="onCreateCompany($event)">
  </kros-create-company-editor>
</div>
