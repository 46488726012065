<div class="brand"
     [ngClass]="{ 'read-only': !isMobile && !appSelector }">
  <div class="kros-app-selector">
    <i *ngIf="!isMobile && appSelector"
       class="apps-icon material-icons"
       krosTooltip="{{'NAVBAR.APPLICATIONS' | translate}}"
       data-test="app-selector">
      apps
    </i>
    <i *ngIf="isMobile && (appSelector || menu)"
       class="apps-icon material-icons">
      menu
    </i>
    <div class="brand-zone">
      <ng-container *ngTemplateOutlet="brandZone"></ng-container>
    </div>
  </div>
</div>
<ng-container *ngTemplateOutlet="menu"></ng-container>

<div class="right-group">
  <div class="secondary-nav">
    <ng-container *ngTemplateOutlet="secondaryNav"></ng-container>
  </div>

  <span *ngIf="secondaryNav && profile && !isMobile"
        class="divider"></span>

  <ng-container *ngTemplateOutlet="profile"></ng-container>
</div>

<ng-template #appsDesktop>
  <ng-container *ngTemplateOutlet="appSelector"></ng-container>
</ng-template>

<ng-template #appsMobile>
  <div class="wrapper-container"
       mobileHeight>
    <div class="header">
      <div class="brand-zone">
        <ng-container *ngTemplateOutlet="brandZone"></ng-container>
      </div>
      <button class="btn btn-link close"
              (click)="hide()">
        <i class="material-icons secondary">close</i>
      </button>
    </div>
    <div ngbNav
         #tabs="ngbNav"
         [(activeId)]="active">
      <div *ngIf="menu"
           [ngbNavItem]="navItem.Menu">
        <a ngbNavLink>Menu</a>
        <ng-template ngbNavContent>
          <ng-container *ngTemplateOutlet="menu"></ng-container>
        </ng-template>
      </div>

      <div *ngIf="appSelector"
           [ngbNavItem]="navItem.Apps">
        <a ngbNavLink>{{'NAVBAR.APPLICATIONS' | translate}}</a>
        <ng-template ngbNavContent>
          <ng-container *ngTemplateOutlet="appSelector"></ng-container>
        </ng-template>
      </div>
    </div>
    <div [ngbNavOutlet]="tabs"></div>
    <hr />
    <span class="logo">
      <ng-container *ngTemplateOutlet="logo"></ng-container>
    </span>
  </div>
</ng-template>
