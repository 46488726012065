import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter, Observable } from 'rxjs';

import { KrosModalRef } from '@kros-sk/components';

import { BuildingProgressPeriod } from '../../../../building-progress/models/building-progress-period.model';
import { BuildingProgressPeriodDispatchersService } from '../../../../store/building-progress';
import { DetailHelper } from '../../../../building-progress/helpers';
import { SubcontractorsDispatchersService, SubcontractorsSelectorsService } from '../../../../store/subcontractors';
import { TransferExecutionsService } from '../services/transfer-executions.service';
import { UISettingsService } from '../../../services';

const AreDetailsIncludedSettingName = 'TransferExecutionsAreDetailsIncluded';

@Component({
  selector: 'app-transfer-executions-dialog',
  templateUrl: './transfer-executions-dialog.component.html',
  styleUrls: ['./transfer-executions-dialog.component.scss'],
  providers: [TransferExecutionsService]
})
export class TransferExecutionsDialogComponent implements OnInit {
  form: FormGroup<{
    all: FormControl<boolean>,
    period: FormControl<BuildingProgressPeriod>,
    targetPeriod: FormControl<BuildingProgressPeriod | null>,
    areDetailsIncluded: FormControl<boolean>
  }>;
  contractorName: string;
  periods: BuildingProgressPeriod[];
  wholeBuildingPeriods$: Observable<BuildingProgressPeriod[]>;
  wholeBuildingPeriods: BuildingProgressPeriod[];
  subcontractorsPeriods: BuildingProgressPeriod[];
  projectId: number;
  periodToCreate?: Partial<BuildingProgressPeriod>;
  creatingPeriod = false;
  newPeriod?: Partial<BuildingProgressPeriod>;
  hasPartialBudgetItems = false;

  get periodsByForm(): BuildingProgressPeriod[] {
    return this.form.get('all').value ? this.subcontractorsPeriods : this.periods;
  }

  get hasPeriods(): boolean {
    return this.periods.length > 0 || this.subcontractorsPeriods?.length > 0;
  }

  private destroyRef = inject(DestroyRef);
  private fb = inject(UntypedFormBuilder);
  private modalRef = inject(KrosModalRef);
  private dispatchersService = inject(SubcontractorsDispatchersService);
  private selectorsService = inject(SubcontractorsSelectorsService);
  private periodDispatchersService = inject(BuildingProgressPeriodDispatchersService);
  private transferExecutionsService = inject(TransferExecutionsService);
  private detailHelper = inject(DetailHelper);
  private uiSettingsService = inject(UISettingsService);

  constructor() {
    this.contractorName = this.modalRef.data.contractorName;
    this.periods = this.modalRef.data.periods;
    this.projectId = this.modalRef.data.projectId;
    this.wholeBuildingPeriods$ = this.modalRef.data.wholeBuildingPeriods$;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      all: new FormControl(false),
      period: new FormControl(null, Validators.required),
      targetPeriod: new FormControl(null),
      areDetailsIncluded: new FormControl(false)
    });

    this.transferExecutionsService.loadHasPartialBudgetItems(this.projectId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(hasPartialBudgetItems => {
        this.hasPartialBudgetItems = hasPartialBudgetItems;
        if (hasPartialBudgetItems) {
          this.form.get('all').setValue(true);
        }
      });

    this.form.controls.period.valueChanges.pipe(filter(selectedPeriod => !!selectedPeriod), takeUntilDestroyed(this.destroyRef))
      .subscribe(selectedPeriod => this.setTargetPeriodIfNotExists(selectedPeriod));

    this.wholeBuildingPeriods$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(periods => {
        if (periods.length > this.wholeBuildingPeriods?.length) {
          this.wholeBuildingPeriods = periods;
          const newPeriod = this.wholeBuildingPeriods.find(p => p.dateFrom instanceof Date);
          if (newPeriod) {
            this.form.controls.targetPeriod.setValue(newPeriod);
          }
          this.creatingPeriod = false;
        } else {
          this.wholeBuildingPeriods = periods;
        }
      });

    this.form.get('all').valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        if (value && !this.subcontractorsPeriods) {
          this.loadSubcontractorsPeriods();
        } else if (!this.periods.find(p => this.comparePeriods(p, this.form.controls.period.value))) {
          this.selectLastPeriod();
        }
      });

    this.selectorsService.subcontractorsPeriods$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(periods => {
        this.subcontractorsPeriods = periods;
        this.selectLastPeriod();
      });

    if (this.periods.length === 0) {
      this.form.get('all').setValue(true);
    } else {
      this.selectLastPeriod();
    }

    this.uiSettingsService.getUiSettings(AreDetailsIncludedSettingName).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(areDetailsIncluded => this.form.get('areDetailsIncluded').setValue(areDetailsIncluded ?? false));
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  onSubmit(): void {
    const period: BuildingProgressPeriod = this.form.controls.period.value;
    const targetPeriod: BuildingProgressPeriod = this.form.controls.targetPeriod.value;
    const areDetailsIncluded = this.form.controls.areDetailsIncluded.value;

    this.uiSettingsService.saveUiSettings(AreDetailsIncludedSettingName, areDetailsIncluded)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() =>
        this.modalRef.submit({
          all: this.form.get('all').value,
          dateFrom: period.dateFrom,
          dateTo: period.dateTo,
          areDetailsIncluded,
          targetPeriod
        }));
  }

  comparePeriods(periodA: Partial<BuildingProgressPeriod> | null, periodB: Partial<BuildingProgressPeriod> | null): boolean {
    return periodA?.dateFrom === periodB?.dateFrom && periodA?.dateTo === periodB?.dateTo;
  }

  openCreatingPeriod(): void {
    this.creatingPeriod = true;
  }

  onCloseCreatingPeriod(): void {
    this.creatingPeriod = false;
  }

  onCreatePeriod(periodDate: { from: Date, to: Date }): void {
    this.periodDispatchersService.createPeriod(
      this.projectId, periodDate.from, periodDate.to, this.detailHelper.currentSearchModel, true
    );
  }

  private loadSubcontractorsPeriods(): void {
    this.dispatchersService.loadSubcontractorsPeriods(this.projectId);
  }

  private selectLastPeriod(): void {
    this.form.get('period').setValue(
      this.form.get('all').value
        ? this.subcontractorsPeriods[this.subcontractorsPeriods.length - 1]
        : this.periods[this.periods.length - 1]
    );
  }

  private setTargetPeriodIfNotExists(selectedPeriod: BuildingProgressPeriod): void {
    const indexOfTargetPeriod
      = this.wholeBuildingPeriods.findIndex(p => this.comparePeriods(p, selectedPeriod));

    if (indexOfTargetPeriod > -1) {
      this.periodToCreate = undefined;
      this.form.controls.targetPeriod.setValue(this.wholeBuildingPeriods[indexOfTargetPeriod]);
    } else {
      this.periodToCreate = { ...selectedPeriod, id: null };
      this.form.controls.targetPeriod.setValue(null);
    }
  }
}
