<div class="panel-wrapper">
  <div class="panel-header">
    <h4
      class="panel-header-caption"
      data-test="comment-panel-header-caption"
    >
      {{'BUILDING_PROGRESS.COMMENTS.COMMENTS' | translate}}
      <br />{{periodName}}
    </h4>

    <button
      class="btn btn-link btn-close"
      type="button"
      (click)="onCloseClick()"
      data-test="comment-panel-close-button"
    >
      <i class="material-icons">close</i>
    </button>
  </div>

  <div class="panel-content">
    <div
      *ngIf="!readOnly"
      class="btn-new-container"
    >
      <div
        class="comment-add-info"
        *ngIf="!data.budgetItemId"
        data-test="comment-add-info"
      >
        {{'BUILDING_PROGRESS.COMMENTS.NOT_SELECTED_PERIOD' | translate}}
      </div>
      <button
        class="btn btn-outline-primary btn-new"
        type="button"
        [disabled]="newComment || !data.budgetItemId || editing"
        (click)="showNewComment()"
        data-test="comment-new-button"
      >{{'BUILDING_PROGRESS.COMMENTS.NEW' | translate}}</button>
    </div>

    <kros-comments
      (cancelNewComment)="onCancelNewComment()"
      (clickComment)="onClickComment($event)"
      (saveNewComment)="onSaveNewComment($event)"
      (saveComment)="onSaveComment($event)"
      (deleteComment)="onDeleteComment($event)"
      (publish)="onPublish()"
      [focusedCommentIds]="focusedCommentIds"
      [newComment]="newComment"
      [loading]="selectorsService.commentsLoading$ | async"
      [comments]="comments"
      [readOnly]="readOnly"
      [showAnswerButton]="!!data.budgetItemId"
      [createAnswer]="createAnswer"
      [allComments]="selectorsService.allComments$ | async"
    ></kros-comments>
  </div>
</div>
