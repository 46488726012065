import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PermissionType } from '@kros-sk/ssw-shared/permission';

import { ContentTypes } from '../../../upload';
import { DocumentListInfoModel } from '../../models';
import { DocumentType } from '../../enums';

@Component({
  selector: 'app-document-icon',
  templateUrl: './document-icon.component.html',
  styleUrls: ['./document-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentIconComponent {

  @Input() document: DocumentListInfoModel;

  get icon(): string {
    if (this.document.type === DocumentType.Folder) {
      if (this.document.isShared && this.document.permissionType !== PermissionType.Shared) {
        return 'folder_shared';
      }
      return 'folder';
    } else if (this.document.type === DocumentType.File) {
      if (this.document.contentType === ContentTypes.ifc) {
        return 'business';
      } else if (this.document.contentType === ContentTypes.construction) {
        return 'home';
      } else if (ContentTypes.drawings.indexOf(this.document.contentType) !== -1) {
        return 'layers';
      } else if (this.document.contentType === ContentTypes.pdf) {
        return 'picture_as_pdf';
      } else if (ContentTypes.images.indexOf(this.document.contentType) !== -1) {
        return 'photo_library';
      } else {
        return 'insert_drive_file';
      }
    } else if (this.document.type === DocumentType.ApplicationData) {
      return 'note';
    }
    return '';
  }

  get iconClass(): string {
    return `material-icons ${this.icon}`;
  }
}
