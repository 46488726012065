export enum BuildingProgressPermissionType {
  None = 0,
  Commenter = 1,
  Approver = 2,
  Contractor = 4,
  ApproverView = 8,
  ContractorPricesView = 16,
  ContractorFull = 20,
  Exporter = 32,
  CommenterExporterApproverView = 41,
  CommenterExporterApproverFull = 43
}
