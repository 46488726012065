import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { fromFeatureFlags } from '../data-access/store/feature-flags.selectors';

@Injectable()
export class HasFeatureFlagFacade {

  private readonly store = inject(Store);

  readonly featureFlags$ = this.store.select(fromFeatureFlags.selectFeatureFlags);
  readonly hasFeaturesLoaded$ = this.store.select(fromFeatureFlags.selectFeaturesLoaded);

  hasFeatureFlag(featureFlag: string): Observable<boolean> {
    return this.store.select(fromFeatureFlags.selectIsFeatureFlagEnabled(featureFlag));
  }
}
