import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';

import { BudgetPermission, BuildingProgressPermission } from '../models';

@Injectable()
export class PermissionApiService {

  private http = inject(HttpClient);
  private appConfig = inject(APP_CONFIG);

  private get gatewayApi(): string {
    return this.appConfig.appUrls.titanGatewayUrl + '/api/';
  }

  getBuildingProgressPermission(projectId: number): Observable<BuildingProgressPermission> {
    return this.http.get<any>(this.gatewayApi + `buildingProgressService/buildingProgress/permission/${projectId}`);
  }

  getBudgetPermission(projectId: number): Observable<BudgetPermission> {
    return this.http.get<any>(this.gatewayApi + `projects/${projectId}/permissionService/budgetPermissions/permission`);
  }
}
