import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BudgetLicenseGuard } from '@kros-sk/ssw-budget/shared/utils/license';

import { BudgetApprovalComponent } from '../budget-approval/budget-approval.component';
import {
  BudgetApprovalDifferencesComponent
} from '../budget-approval/components/budget-approval-differences/budget-approval-differences.component';
import { BudgetChangesComponent } from '../budget-approval/components/budget-changes/budget-changes.component';
import { CanDeactivateGuard } from '../core/guards/can-deactivate.guard';
import { ChangeSheetComponent } from '../budget-approval/components/change-sheet/change-sheet.component';
import { DocumentsComponent } from '../documents/documents.component';
import { InitKrosAuthGuard } from '../quantity-manager/guards/init-kros-auth.guard';
import { MembersGuard } from '../building-progress/guards/members.guard';
import { ProjectDetailComponent } from './components/project-detail/project-detail.component';
import { ProjectDetailGuard } from '../core/guards/project-detail.guard';
import { ProjectListComponent } from './components/project-list/project-list.component';
import { ProjectMembersComponent } from './components/project-members/project-members.component';
import { ProjectNavigateComponent } from './components/project-navigate/project-navigate.component';
import { ProjectsComponent } from './projects.component';

const routes: Routes = [
  {
    path: '',
    component: ProjectsComponent,
    children: [
      {
        path: '',
        component: ProjectListComponent
      },
      {
        path: 'upload/:temporaryId',
        component: ProjectListComponent,
        data: { isUploadMode: true }
      },
      {
        path: 'navigate/:application/:project-position',
        component: ProjectNavigateComponent
      },
      {
        path: 'project-detail/:projectId',
        component: ProjectDetailComponent,
        canDeactivate: [CanDeactivateGuard],
        canActivate: [ProjectDetailGuard],
        canActivateChild: [ProjectDetailGuard],
        children: [
          {
            path: '',
            redirectTo: 'dashboard',
            pathMatch: 'full',
          },
          {
            path: 'dashboard',
            loadChildren: (): any => import('../dashboard/dashboard.module').then(m => m.DashboardModule)
          },
          {
            path: 'documents',
            component: DocumentsComponent,
            data: { isInDocuments: true }
          },
          {
            path: 'documents/:id',
            component: DocumentsComponent,
            data: { isInDocuments: true }
          },
          {
            path: 'members',
            component: ProjectMembersComponent,
            canActivate: [MembersGuard]
          },
          {
            path: 'budget',
            loadChildren: (): any => import('../budget/budget.module').then(m => m.BudgetModule),
            resolve: [BudgetLicenseGuard]
          },
          {
            path: 'building-progress',
            loadChildren: (): any => import('../building-progress/building-progress.module').then(m => m.BuildingProgressModule)
          },
          {
            path: 'budget-approval',
            component: BudgetApprovalComponent
          },
          {
            path: 'budget-approval/differences',
            component: BudgetApprovalDifferencesComponent
          },
          {
            path: 'budget-approval/:budgetObjectId',
            component: ChangeSheetComponent
          },
          {
            path: 'budget-approval/changes/:budgetObjectId',
            component: BudgetChangesComponent
          },
          {
            path: 'quantity-manager',
            canActivate: [InitKrosAuthGuard],
            loadChildren: (): any =>
              import('../quantity-manager/quantity-manager-wrapper.module').then((m) => m.QuantityManagerWrapperModule),
          }
        ]
      },
      {
        path: 'platform-maintenance',
        loadChildren: () => import('@kros-sk/ssw-shared/platform-maintenance').then(r => r.platformMaintenanceRoutes)
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [MembersGuard, BudgetLicenseGuard, InitKrosAuthGuard]
})
export class ProjectsRoutingModule { }
