<div class="grid-container">
  <div class="content-grid">
    <div class="auto-sized-content">
      <div class="members-content">
        <div class="sharing-list-container">
          <div
            *ngIf="isOwner"
            class="sharing-input"
          >
            <kros-user-sharing-multiple-input
              [sharingMode]="addSharingMode"
              [existingEmails]="existingSharingEmails"
              (shared)="onShared($event)"
            ></kros-user-sharing-multiple-input>
          </div>

          <kros-user-sharing-list
            class="sharing-list"
            *ngIf="loadedItems else loader"
            [readonly]="!isOwner"
            [unconfirmedSharings]="unconfirmedSharings"
            [confirmedSharings]="projectSharings"
            [sharingMode]="sharingMode"
            (deletedPermission)="onDeletedPermission($event)"
            (unconfirmedPermissionDeleted)="onUnconfirmedPermissionDeleted($event)"
            (changedPermission)="onChangedPermission($event)"
          ></kros-user-sharing-list>
        </div>

        <div
          class="members-actions-container"
        >
          <div
            *ngIf="changesMade"
            class="members-actions"
          >
            <div class="button">
              <button
                class="btn btn-primary btn-save"
                type="button"
                [disabled]="isSaving"
                (click)="onSave()"
                data-test="project-sharing-submit"
              >{{'COMMON.ULOZIT' | translate}}</button>
            </div>

            <div class="button">
              <button
                class="btn btn-outline-secondary btn-cancel"
                type="button"
                (click)="onCancel()"
              >{{'COMMON.ZRUSIT' | translate}}</button>
            </div>
          </div>

          <div
            *ngIf="!changesMade && isMultiStageApprovalModeEnabled && isSomeApprover"
            class="members-actions"
          >
            <div class="button">
              <button
                class="btn btn-outline-primary approval-order-button"
                (click)="onOpenApprovalOrder()"
                data-test="approval-order-button"
              >{{'SHARING.BUILDING_PROGRESS.APPROVERS_ORDER' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loader>
  <kros-timeline [type]="timelineType.TwoLine"></kros-timeline>
</ng-template>
