import { Inject, Injectable, Optional } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { featureFlagsActions } from '@kros-sk/core/feature-flags';

import * as authActions from './auth.actions';
import { configFeatureFlagsVersion } from '../injection-tokens';
import { FeatureFlagsService } from '../services/feature-flags/feature-flags.service';
import { FeatureFlagsVersion } from '../kros-auth.module';
import { KrosAuthService } from '../services/auth/kros-auth.service';
import { LicensesService } from '../services/licenses/licenses.service';

@Injectable()
export class AuthEffects {

  constructor(
    private actions$: Actions,
    private authService: KrosAuthService,
    private licensesService: LicensesService,
    @Optional() private featuresService: FeatureFlagsService,
    @Inject(configFeatureFlagsVersion) private featureFlagsVersion: FeatureFlagsVersion,
  ) {
  }

  startAuthentication$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.startAuthentication),
      tap(() => this.authService.startAuthentication()),
    );
  }, { dispatch: false });

  logoutUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.logoutUser),
      tap(() => this.authService.logOut()),
    );
  }, { dispatch: false });

  loadFeaturesAfterLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.setUser),
      filter(() => this.featureFlagsVersion !== FeatureFlagsVersion.None),
      map(action => this.featureFlagsVersion === FeatureFlagsVersion.Original
        ? authActions.loadFeatures({ user: action.user })
        : featureFlagsActions.loadFeatures()),
    );
  });

  loadFeatures$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.loadFeatures),
      filter((action) => !!action.user?.userId),
      switchMap(() => this.featuresService.loadFeaturesState().pipe(
        map(features => authActions.loadFeaturesSuccess({ features })),
        catchError(err => of(authActions.loadFeaturesFail())),
      )),
    );
  });

  loadCurrentLicense$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.loadCurrentLicense),
      switchMap(action => this.licensesService.loadCompanyLicense(action.companyId).pipe(
        map(license => authActions.loadCurrentLicenseSuccess({ licenseState: this.licensesService.mapApiLicense(license) })),
        catchError(err => {
          const licenseState = this.licensesService.createErrorLicenseIfNeeded(err);
          if (licenseState) {
            return of(authActions.loadCurrentLicenseSuccess({licenseState}));
          } else {
            return of(authActions.loadCurrentLicenseFail());
          }
        })
      ))
    );
  });

  assignLicense$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.assignLicense),
      switchMap(action => this.licensesService.assignLicense(action.companyId, action.customerNumber).pipe(
        map(license => authActions.assignLicenseSuccess({ licenseState: this.licensesService.mapApiLicense(license) })),
        catchError(() => of(authActions.assignLicenseFail())),
      )),
    );
  });

  startTrial$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.startLicenseTrial),
      switchMap(action => this.licensesService.startTrial(action.companyId).pipe(
        map(license => authActions.startLicenseTrialSuccess({ licenseState: this.licensesService.mapApiLicense(license) })),
        catchError(error => of(authActions.startLicenseTrialFail({error}))),
      )),
    );
  });

  removeCompaniesFromLicense$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.removeCompaniesFromLicense),
      switchMap(action => this.licensesService.removeCompaniesFromLicense(
        action.companyId, action.companyIds, action.currentlyUsedCompanies, action.customerNumber).pipe(
        map(license => authActions.removeCompaniesFromLicenseSuccess({ licenseState: this.licensesService.mapApiLicense(license) })),
        catchError(() => of(authActions.removeCompaniesFromLicenseFail())),
      )),
    );
  });

}
