<ng-container *ngFor="let subcontractor of subcontractors">
  <div
    class="subcontractor"
    [class.selected]="isSelected(subcontractor)"
    (click)="selectSubcontractor.emit(subcontractor)"
    data-test="subcontractor-item"
  >
    <i
      class="material-icons toggle-icon"
      (click)="toggleExpanded(subcontractor)"
    >{{isSelected(subcontractor) && isExpanded(subcontractor) ? 'arrow_drop_down' : 'arrow_right'}}</i>

    <div
      class="name"
      data-test="subcontractor-name"
    >{{subcontractor.name}}</div>

    <button class="btn no-selection no-padding dropdown-action">
      <div
        *ngIf="canEditSubcontractors"
        ngbDropdown
        container="body"
      >
        <button
          ngbDropdownToggle
          type="button"
          (click)="$event.stopPropagation()"
          class="btn btn-link no-padding toolbar-action no-selection"
          data-test="edit-subcontractor-dropdown"
          placement="center-right"
        >
          <i class="material-icons secondary more-vert-size icon-button">more_vert</i>
        </button>

        <div
          ngbDropdownMenu
          display="dynamic"
        >
          <button
            ngbDropdownItem
            type="button"
            data-test="subcontractor-rename"
            class="subcontractor-action"
            (click)="renameSubcontractor(subcontractor.id, subcontractor.name)"
          >
            <i class="material-icons">edit</i>
            <span>{{ 'COMMON.RENAME' | translate }}</span>
          </button>

          <button
            ngbDropdownItem
            type="button"
            data-test="subcontractor-remove"
            class="subcontractor-action"
            (click)="removeSubcontractor(subcontractor.id, subcontractor.name)"
          >
            <i class="material-icons">delete</i>
            <span>{{ 'COMMON.DELETE' | translate }}</span>
          </button>
        </div>
      </div>
    </button>
  </div>

  <div
    *ngIf="isSelected(subcontractor) && isExpanded(subcontractor) && detail.areConstructionDataLoaded"
    class="building-tree"
  >
    <app-budget-structure
      [data$]="subcontractorData$"
      [tableId]="tableId"
    ></app-budget-structure>
  </div>
</ng-container>
