import { createAction, props } from '@ngrx/store';

import { BoqItem } from '@kros-sk/ssw-shared-legacy';

import {
  BudgetApprovalDifferencesModel,
  BudgetApprovalItem,
  BudgetApprovalModel,
  BudgetChangeModel,
  BudgetModel,
  ChangeSheetApproveModel,
  ChangeSheetModel,
  ChangeSheetRelationEditModel,
  DeleteBuildingObjectResult
} from '../../budget-approval/models';
import { BuildingProgressSharing } from '../../building-progress/models/building-progress-sharing/building-progress-sharing.model';
import { DeleteRelationViewModel } from '../../budget-approval/models/delete-relation-view-model';

export const clearState = createAction('[Budget approval] Clear budget approval state');

export const loadBudgetApprovalDataStart = createAction(
  '[Budget approval] Load budget approval data start', props<{ projectId: number, hierarchyCode?: string }>());
export const loadBudgetApprovalDataSuccess = createAction(
  '[Budget approval] Load budget approval data success',
  props<{ budgetApprovalData: BudgetApprovalModel }>()
);
export const loadBudgetApprovalDataError = createAction('[Budget approval] Load budget approval data error', props<{ error: any }>());

export const deleteBuildingObjectStart = createAction(
  '[Budget approval] Delete building object start', props<{ projectId: number, buildingObjectId: number }>());
export const deleteBuildingObjectSuccess = createAction(
  '[Budget approval] Delete building object success',
  props<{ deleteResult: DeleteBuildingObjectResult }>()
);
export const deleteBuildingObjectError = createAction('[Budget approval] Delete building object error', props<{ error: any }>());

export const loadChangeSheetDataStart = createAction(
  '[Budget approval] Load change sheet data start', props<{ projectId: number, buildingObjectId: number }>());
export const loadChangeSheetDataSuccess = createAction(
  '[Budget approval] Load change sheet data success',
  props<{ changeSheetData: ChangeSheetModel }>()
);
export const loadChangeSheetDataError = createAction('[Budget approval] Load change sheet data error', props<{ error: any }>());

export const loadBudgetChangeDataStart = createAction(
  '[Budget approval] Load budget change data start', props<{ projectId: number, buildingObjectId: number, setLoading: boolean }>());
export const loadBudgetChangeDataSuccess = createAction(
  '[Budget approval] Load budget change data success',
  props<{ budgetChangeData: BudgetChangeModel }>()
);
export const loadBudgetChangeDataError = createAction('[Budget approval] Load budget change data error', props<{ error: any }>());

export const approveChangeSheetStart = createAction(
  '[Budget approval] Approve change sheet start', props<{ changeSheetApproveModel: ChangeSheetApproveModel }>());
export const approveChangeSheetSuccess = createAction(
  '[Budget approval] Approve change sheet success', props<{ approveResult: BudgetApprovalItem[] }>()
);
export const approveChangeSheetError = createAction('[Budget approval] Approve change sheet error', props<{ error: any }>());

export const cancelApprovedChangeSheetStart = createAction(
  '[Budget approval] Cancel approved change sheet start', props<{ changeSheetApproveModel: ChangeSheetApproveModel }>());
export const cancelApprovedChangeSheetSuccess = createAction(
  '[Budget approval] Cancel approved change sheet success', props<{ approveResult: BudgetApprovalItem[] }>()
);
export const cancelApprovedChangeSheetError = createAction('[Budget approval] Cancel approved change sheet error', props<{ error: any }>());

export const loadBudgetDataStart = createAction('[Budget approval] Load budget data start', props<{ projectId: number }>());
export const loadBudgetDataSuccess = createAction(
  '[Budget approval] Load budget data success',
  props<{ budgetData: BudgetModel }>()
);
export const loadBudgetDataError = createAction('[Budget approval] Load budget data error', props<{ error: any }>());

export const editChangeSheetItemRelationStart = createAction(
  '[Budget approval] Edit change sheet item relation start',
  props<{ editModel: ChangeSheetRelationEditModel, reloadChangesData?: boolean, buildingObjectId?: number }>()
);
export const editChangeSheetItemRelationSuccess = createAction(
  '[Budget approval] Edit change sheet item relation success',
  props<{ budgetItemId: number, originalId: number }>()
);
export const editChangeSheetItemRelationError = createAction(
  '[Budget approval] Edit change sheet item relation error',
  props<{ error: any }>()
);

export const deleteChangeSheetRelationStart = createAction(
  '[Budget approval] Delete change sheet relation start',
  props<{ deleteModel: DeleteRelationViewModel }>()
);
export const deleteChangeSheetRelationSuccess = createAction(
  '[Budget approval] Delete change sheet relation success',
  props<{ deleteModel: DeleteRelationViewModel }>()
);
export const deleteChangeSheetRelationError = createAction(
  '[Budget approval] Delete change sheet relation error',
  props<{ error: any }>()
);

export const selectChangeSheetItem = createAction(
  '[Budget approval] Select change sheet item',
  props<{ id: number, state: boolean }>()
);
export const selectAllChangeSheetItems = createAction('[Budget approval] Select all change sheet items');
export const clearSelectedChangeSheetItems = createAction('[Budget approval] Clear all selected change sheet items');

export const multiSelectItems = createAction(
  '[Budget approval] Select budget approval item', props<{ id: number, state: boolean }>());
export const multiSelectAllItems = createAction(
  '[Budget approval] Select all budget approval items');
export const clearMultiSelectItems = createAction(
  '[Budget approval] Clear selected budget approval items');

export const loadSharingListStart = createAction('[Budget approval] Load sharing list start', props<{ projectId: number }>());
export const loadSharingListSuccess = createAction(
  '[Budget approval] Load sharing list success', props<{ sharingList: BuildingProgressSharing[] }>());
export const loadSharingListError = createAction('[Budget approval] Load sharing list error', props<{ error: any }>());

export const loadBoqItemsStart = createAction('[Budget approval] Loads Bill of quantities items start',
  props<{ projectId: number, budgetItemId: number }>());
export const loadBoqItemsSuccess = createAction('[Budget approval] Loads Bill of quantities items success',
  props<{ items: BoqItem[] }>());

export const loadDifferencesDataStart = createAction(
  '[Budget approval] Load differences data start', props<{ projectId: number }>());
export const loadDifferencesDataSuccess = createAction(
  '[Budget approval] Load differences data success',
  props<{ differencesData: BudgetApprovalDifferencesModel }>()
);
export const loadDifferencesDataError = createAction('[Budget approval] Load differences data error', props<{ error: any }>());
