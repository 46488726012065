import { Injectable } from '@angular/core';

import { filter, take } from 'rxjs/operators';

import { ColorCodesDialogComponent, colors, SetColorCodeModel } from '@kros-sk/ssw-shared-legacy';
import { KrosModalService } from '@kros-sk/components';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

import { BuildingProgressActionAccessService } from './building-progress-action-access.service';
import { BuildingProgressDispatchersService } from '../../store/building-progress';
import { BuildingProgressModel } from '../models/construction-data.model';
import { TableChangeDetectionService } from './table-change-detection.service';

@Injectable()
export class ColorCodesService {

  constructor(
    private appInsightsService: AppInsightsBaseService,
    private krosModalService: KrosModalService,
    private buildingProgressActionAccessService: BuildingProgressActionAccessService,
    private buildingProgressDispatchersService: BuildingProgressDispatchersService,
    private tableChangeDetectionService: TableChangeDetectionService
  ) { }

  openColorCodesDialogView(
    callback: Function
  ): void {
    this.krosModalService.openCentered(
      ColorCodesDialogComponent,
      { colors, multiselect: true },
      undefined,
      undefined,
      undefined,
      undefined,
      ['no-min-width'],
    ).afterClosed$
      .pipe(take(1), filter((result: any) => result.type === 'submit'))
      .subscribe(result => callback(result.data.colors));
  }

  openColorCodesDialog(
    element: HTMLElement,
    selectedItemIds: number[],
    constructionData: BuildingProgressModel,
    areItemsSelected: boolean
  ): void {
    this.krosModalService.openConnectedToElementCustomPositioned(
      ColorCodesDialogComponent,
      element,
      [{ originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' }],
      { colors, multiselect: false },
      undefined,
      ['no-min-width'],
    ).afterClosed$
      .pipe(take(1), filter((result: any) => result.type === 'submit'))
      .subscribe(result => {
        const budgetItemIdToColorCodeMap: { [key: number]: string; } = {};
        selectedItemIds.forEach(id => budgetItemIdToColorCodeMap[id] = result.data.color);
        const setColorModel: SetColorCodeModel = {
          projectId: constructionData.projectId,
          budgetItemIdToColorCodeMap
        };

        this.setColorCodeIfCan(setColorModel, constructionData, areItemsSelected, result.data.color);
      });
  }

  getItemIdsForColorSet(selectedIds: Set<number>, actualItemId: number): number[] {
    const selectedItems: number[] = [...selectedIds];
    return !selectedItems || selectedItems.length === 0 ? [actualItemId] : selectedItems;
  }

  private setColorCodeIfCan(
    setColorModel: SetColorCodeModel,
    constructionData: BuildingProgressModel,
    areItemsSelected: boolean,
    color: string
  ): void {
    if (!areItemsSelected || this.buildingProgressActionAccessService.checkForAccessToBulkOperations()) {
      this.buildingProgressDispatchersService.setColorCode(setColorModel, constructionData);
      this.appInsightsService.trackEvent('PV-color-code-set', { color });
    }

    if (areItemsSelected) {
      this.buildingProgressDispatchersService.clearMultiSelectItems();
      this.tableChangeDetectionService.forceTableChanges();
    }
  }
}
