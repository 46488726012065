import { ActivatedRoute } from '@angular/router';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';

import { filter, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApprovalOrderHelper } from '@kros-sk/ssw-building-progress/approval-order';
import { AuthSelectorsService } from '@kros-sk/auth';
import {
  BudgetStructureService,
  DataTableComponent,
  ProjectDetail,
  SEARCH_TEXT,
  UndoRedoDispatchersService
} from '@kros-sk/ssw-shared-legacy';
import { DeviceDetectorService, DeviceType } from '@kros-sk/components';

import {
  BuildingProgressCommentsDispatchersService,
  BuildingProgressDataSettingsDispatchersService,
  BuildingProgressDispatchersService,
  BuildingProgressSelectorsService
} from '../../../store/building-progress';
import { BuildingProgressDemoService } from '../../services';
import { BuildingProgressDetailBaseComponent } from './building-progress-detail-base.component';
import { BuildingProgressItem, BuildingProgressModel } from '../../models/construction-data.model';
import { BuildingProgressNavigation } from '../../models/building-progress-navigation.type';
import {
  BuildingProgressPanelHelper,
  DetailHelper,
  ExportHelper,
  getColumnsMaxNumber,
  LicenseHelper,
  PeriodHelper,
  PermissionHelper,
  VisualHelper
} from '../../helpers';
import { BuildingProgressSummaryPanelModel } from '../../models/building-progress-summary-panel.model';
import { BuildingProgressTableService } from '../../services/building-progress.table.service';
import { BuildingProgressTourService } from '../../services/building-progress-tour.service';
import { BuildingProgressViewsService } from '../../services/building-progress-views.service';
import { ColorCodesService } from '../../services/color-codes.service';
import { ConnectorService } from '../../../core/services/connector.service';
import { EditingPanelHelper } from '../../helpers/editing-panel.helper';
import { environment } from '../../../../environments/environment';
import { getAppInsightsPrefix } from '../../shared/building-progress-utils';
import { LicenseAgreementService } from '../../services/license-agreement.service';
import { PeriodsHelperService } from '../../modules/periods/services/periods-helper.service';
import { PhotoGalleryOpenerService } from '../../services/photo-gallery-opener.service';
import { ProjectsSelectorsService } from '../../../store/projects';
import { TableChangeDetectionService } from '../../services/table-change-detection.service';
import { VatRateUpdateService } from '../../services/vat-rate-update.service';

@Component({
  selector: 'app-building-progress-detail',
  templateUrl: './building-progress-detail.component.html',
  styleUrls: ['./building-progress-detail.component.scss', '../../shared/building-progress-table.scss'],
  providers: [
    ColorCodesService,
    ExportHelper,
    LicenseAgreementService,
    LicenseHelper,
    PermissionHelper,
    VatRateUpdateService,
    BuildingProgressTourService,
    PeriodsHelperService,
    ApprovalOrderHelper,
    BuildingProgressDemoService
  ]
})
export class BuildingProgressDetailComponent extends BuildingProgressDetailBaseComponent implements OnInit, OnDestroy {

  @ViewChild(DataTableComponent) set table(value: DataTableComponent) {
    this.visualHelper.table = value;
    this.buildingProgressSelectorsService.lastEditedItemIds$
      .pipe(filter(p => !!p && p.length > 0 && !!this.visualHelper.table))
      .subscribe(p => this.visualHelper.table.editHelper.removeEditedItemIds(p));
  }

  deviceType = DeviceType;

  get hidePrices(): boolean {
    return this.permissionHelper.isContractor && !this.permissionHelper.isContractorFull;
  }

  get treeOrTableVisible(): boolean {
    return this.detail?.currentNavigation === 'tree' || this.detail?.currentNavigation === 'table';
  }

  get canShowInvoice(): boolean {
    return environment.location === 'sk' && this.detail.currentNavigation !== 'dashboard' && this.permissionHelper.hasPermission &&
      !this.detail.isEmptyContent && this.permissionHelper.isOwner && this.licenseHelper.isFullLicense;
  }

  get appInsightsPrefix(): string {
    return getAppInsightsPrefix(this.permissionHelper.isContractor);
  }

  protected isDemo = false;

  constructor(
    @Inject(SEARCH_TEXT) private searchText: Observable<string>,
    public buildingProgressTableService: BuildingProgressTableService,
    public panelHelper: BuildingProgressPanelHelper,
    public exportHelper: ExportHelper,
    public permissionHelper: PermissionHelper,
    public periodHelper: PeriodHelper,
    public licenseHelper: LicenseHelper,
    public detail: DetailHelper,
    public tableChangeDetectionService: TableChangeDetectionService,
    public buildingProgressSelectorsService: BuildingProgressSelectorsService,
    public deviceDetectorService: DeviceDetectorService,
    protected visualHelper: VisualHelper,
    protected authSelectorsService: AuthSelectorsService,
    private editingPanelHelper: EditingPanelHelper,
    private buildingProgressDataSettingsDispatcher: BuildingProgressDataSettingsDispatchersService,
    private buildingProgressDispatchersService: BuildingProgressDispatchersService,
    private commentsDispatchersService: BuildingProgressCommentsDispatchersService,
    private budgetStructureService: BudgetStructureService,
    private projectsSelectorsService: ProjectsSelectorsService,
    private undoRedoDispatchersService: UndoRedoDispatchersService,
    private licenseAgreementService: LicenseAgreementService,
    private viewsService: BuildingProgressViewsService,
    private tourService: BuildingProgressTourService,
    private activatedRoute: ActivatedRoute,
    private galleryOpenerService: PhotoGalleryOpenerService,
    private datePipe: DatePipe,
    private connectorService: ConnectorService,
    private vatRateUpdateService: VatRateUpdateService,
    private demoService: BuildingProgressDemoService
  ) {
    super(buildingProgressSelectorsService, detail, periodHelper, visualHelper, panelHelper);
  }

  ngOnInit(): void {
    this.buildingProgressDataSettingsDispatcher.loadDataSettings();
    this.detail.logBuildingProgressVisit(this.activatedRoute.snapshot.queryParamMap.get('source'));
    this.detail.hierarchyCode = this.activatedRoute.snapshot.queryParamMap.get('hierarchyCode');
    this.detail.galleryPeriodId = +this.activatedRoute.snapshot.queryParamMap.get('galleryPeriodId');
    this.detail.initWithDashboardNavigation = this.activatedRoute.snapshot.queryParamMap.get('dashboard') === 'true';
    if (this.detail.initWithDashboardNavigation) {
      this.detail.currentNavigation = 'dashboard';
    }

    this.subs.sink = this.buildingProgressSelectorsService.constructionData$
      .pipe(filter(p => p && p.projectId === this.detail.projectId))
      .subscribe(p => {
        this.setConstructionData(p);
        if (p.budgetItemId && this.detail.hierarchyCode) {
          this.detail.setFocusToItemById(p.budgetItemId);
          this.detail.hierarchyCode = undefined;
        }
        this.detail.isMobileSelectionVisible = false;
      });
    this.subs.sink = this.buildingProgressSelectorsService.insertedItem$.pipe(filter(p => !!p))
      .subscribe((insertedItem: BuildingProgressItem) => {
        this.detail.focusedCell = {
          colId: 'code' + 0,
          rowIndex: this.detail.constructionData.items.findIndex(q => q.id === insertedItem.id)
        };
        this.visualHelper.scrollElementToView(this.detail.getFocusedCellElementId(), true);
      });
    this.subs.sink = this.projectsSelectorsService.projectDetail$.pipe(filter(p => !!p))
      .subscribe(p => this.setProject(p));
    this.subs.sink = this.budgetStructureService.setFocusToBudgetItemRequested$
      .subscribe(result => this.detail.setFocusToItemById(result.id));
    this.subs.sink = this.buildingProgressSelectorsService.cellsToFocusAndAnimate$.subscribe(p => this.detail.focusAndAnimateCells(p));
    this.buildingProgressSelectorsService.selectedItemIds$.pipe(filter(itemIds => itemIds && itemIds instanceof Set))
      .subscribe(itemIds => {
        this.detail.selectedItemIds = itemIds;
        if (itemIds.size === 0) {
          this.detail.isMobileSelectionVisible = false;
        }
      });
    this.subs.sink = this.buildingProgressSelectorsService.periods$.pipe(filter(p => !!p && p.length > 0))
      .subscribe(p => {
        if (this.detail.galleryPeriodId) {
          const galleryPeriod = p.find(i => i.id === this.detail.galleryPeriodId);
          if (galleryPeriod) {
            this.periodHelper.markPeriod(p, this.detail.galleryPeriodId);
            this.periodHelper.onPeriodSelected(galleryPeriod);
          }
          this.detail.galleryPeriodId = undefined;
        }
        const markedPeriodIds = p.filter(p => p.isMarkedInFilter).map(p => p.id);
        this.detail.selectedPeriodsChanged(markedPeriodIds);
        this.buildingProgressTableService.setFilter(new Set<number>(markedPeriodIds));
        this.tableChangeDetectionService.forceTableChanges();
      });
    this.subs.sink = this.buildingProgressSelectorsService.periodDetails$.pipe(filter(d => !!d))
      .subscribe(details => this.panelHelper.openDetails(details.itemId));
    this.subs.sink = this.searchText.subscribe(resp => this.detail.searchTextChanged(resp));
    this.subs.sink = this.buildingProgressSelectorsService.selectedView$.pipe(filter(selectedView => !!selectedView))
      .subscribe(selectedView => this.onViewChanged(selectedView));
    this.subs.sink = this.buildingProgressSelectorsService.hiddenColumns$.subscribe((hiddenColumns: string[]) => {
      this.buildingProgressTableService.hideColumns(new Set(hiddenColumns));
      this.tableChangeDetectionService.forceTableChanges();
    });

    this.subs.sink = this.connectorService.buildingProgressBudgetCreationFinished$.pipe(filter(p => p.projectId === this.detail.projectId))
      .subscribe(p => ((p.isSuccess)
        ? this.buildingProgressDispatchersService.loadConstructionData(this.detail.projectId, this.detail.currentSearchModel)
        : this.buildingProgressDispatchersService.budgetCreationError()
      ));

    this.subs.sink = this.buildingProgressSelectorsService.settings$.pipe(filter(s => !!s))
      .subscribe(s => this.vatRateUpdateService.updateVatRateIfCan(s));

    this.panelHelper.init(this.detail.projectId, this.getItemIdByRowIndex.bind(this),
      this.showPanel.bind(this), this.hidePanel.bind(this), this.resizePanel.bind(this));
    this.periodHelper.init(this.lastPeriodId.bind(this));
    this.buildingProgressTableService.init();
  }

  ngOnDestroy(): void {
    this.detail.destroy();
    this.buildingProgressTableService.destroy();
    this.buildingProgressDispatchersService.clearMultiSelectItems();
    this.undoRedoDispatchersService.clearUndoRedoStacks();
    this.licenseHelper.destroy();
    this.permissionHelper.destroy();
    this.periodHelper.destroy();
    this.panelHelper.destroy();
    this.tourService.clear();
    this.licenseAgreementService.closeLicenceAgreementDialog();
    this.galleryOpenerService.destroy();
    super.ngOnDestroy();
  }

  private setConstructionData(constructionData: BuildingProgressModel): void {
    if (!this.detail.dataTableConfig ||
      this.detail.constructionData?.decimalPlaces.totalPrice !== constructionData?.decimalPlaces.totalPrice) {
      this.setDataTableConfig(constructionData);
    } else if (!this.periodHelper.arePeriodsEqual(this.detail.constructionData, constructionData)) {
      if (constructionData.periods.length === 0) {
        this.viewsService.setDefaultView();
      }
      this.setDataTableConfig(constructionData);
    } else {
      this.reloadItemOffsets(constructionData);
    }

    this.detail.constructionData = constructionData;

    if (!this.detail.areConstructionDataLoaded) {
      this.panelHelper.openPeriodsAndSelectPeriod(this.detail.constructionData, this.activatedRoute);
    }

    if (!!this.detail.refocusItemModel) {
      if (!!this.detail.constructionData && this.detail.constructionData.items?.length > 0) {
        this.detail.focusedCell = this.visualHelper.getFocusedCellFromRefocusModel(
          this.detail.refocusItemModel, this.detail.constructionData.items);
        if (this.detail.focusedCell) {
          this.visualHelper.scrollElementToView(this.detail.getFocusedCellElementId(), false);
          this.detail.cellFocusedCore(true, true);
        }
      } else {
        this.detail.focusedCell = undefined;
      }

      this.detail.refocusItemModel = undefined;
    }

    if (!this.detail.focusedCell && !!this.detail.constructionData && this.detail.constructionData.items?.length > 0) {
      this.detail.focusedCell = this.detail.getFocusedCell('description', 0);
    }

    if (!this.visualHelper.table?.editHelper.editingCell) {
      this.visualHelper.refocusFocusedCell();
    }

    this.detail.areConstructionDataLoaded = true;

    this.licenseAgreementService.openLicenceAgreementDialogIfCan(this.permissionHelper.isOwner)
      .pipe(take(1))
      .subscribe(() => {
        this.tourService.runBuildingProgressTourIfCan(
          this.permissionHelper.isOwner &&
          !this.licenseHelper.isReadonlyLicense &&
          this.detail.constructionData?.items?.length > 0,
          () => this.panelHelper.onOpenPeriods());
      });
  }

  private setProject(project: ProjectDetail): void {
    if (this.detail.projectId !== project.id) {
      this.detail.projectId = project.id;
      this.detail.projectName = project.name;
      this.exportHelper.setProjectData(project.name);
      this.buildingProgressDispatchersService.loadSettings(this.detail.projectId);
      this.detail.focusedCell = undefined;
      this.detail.refocusItemModel = undefined;
      this.detail.dataTableConfig = undefined;
      this.detail.areConstructionDataLoaded = false;
      this.detail.constructionData = undefined;
      if (!this.detail.initWithDashboardNavigation) {
        this.detail.currentNavigation = 'table';
      }
      this.detail.initWithDashboardNavigation = false;
      this.buildingProgressTableService.setCommentPositions([]);
      this.buildingProgressDispatchersService.clearMultiSelectItems();
      this.viewsService.setDefaultView();
      this.loadCommentPositions();
      this.buildingProgressDispatchersService.loadPeriods(this.detail.projectId, false);
      this.buildingProgressDispatchersService.loadBudgetHasItems(this.detail.projectId);
      this.isDemo = project.isDemo;
    }
  }

  private lastPeriodId(): number {
    return this.detail.constructionData.periods.length > 0
      ? this.detail.constructionData.periods[this.detail.constructionData.periods.length - 1].id
      : null;
  }

  onCellFocused(event: any): void {
    this.editingPanelHelper.canLeavePanel().pipe(take(1)).subscribe(canLeave => {
      if (canLeave) {
        this.detail.focusedCell = event.cell;
        this.detail.cellFocusedCore(true, event.cantLostFocus, this.detail.constructionData.items[event.cell.rowIndex]);
      }
    });
  }

  private getItemIdByRowIndex(rowIndex: number): number {
    return (rowIndex >= 0) && (this.detail.constructionData.items.length > rowIndex)
      ? this.detail.constructionData.items[rowIndex].id
      : null;
  }

  onScrollFocusedCellToView(scrollToCenter: boolean): void {
    this.visualHelper.scrollElementToView(
      this.detail.getFocusedCellElementId(), false, undefined, { block: scrollToCenter ? 'center' : 'nearest' });
  }

  areCommentsReadOnly(): boolean {
    return this.permissionHelper.areCommentsReadOnly || this.licenseHelper.isReadonlyLicense;
  }

  private setDataTableConfig(constructionData: BuildingProgressModel): void {
    this.detail.dataTableConfig = this.buildingProgressTableService.getTableConfig(
      this.detail.projectId,
      this.permissionHelper.permissionType,
      this.permissionHelper.buildingProgressPermissionType,
      constructionData,
      this.licenseHelper.isReadonlyLicense,
      this.detail.isViewOrSearchActive,
      this.licenseHelper.isTrialOrFullLicense,
      !this.permissionHelper.isContractor && !this.permissionHelper.isApprover
    );
  }

  private reloadItemOffsets(constructionData: BuildingProgressModel): void {
    const columnsMaxNumber = getColumnsMaxNumber(constructionData);
    this.buildingProgressTableService.averageLineHeight = columnsMaxNumber.averageLineHeight;
    this.detail.dataTableConfig.itemsOffset = columnsMaxNumber.itemsOffset;
  }

  private loadCommentPositions(): void {
    this.commentsDispatchersService.loadCommentPositions(this.detail.projectId);
  }

  onItemCheckChanged(item: any, state: boolean): void {
    this.detail.focusedCell = {
      ...this.detail.focusedCell,
      rowIndex: this.detail.constructionData.items.findIndex(i => i.id === item.id)
    };
    this.buildingProgressDispatchersService.multiSelectItem(item.id, state);
  }

  onHeaderCheckChanged(state: boolean): void {
    if (state) {
      this.buildingProgressDispatchersService.multiSelectAllitems();
    } else {
      this.buildingProgressDispatchersService.clearMultiSelectItems();
    }
  }

  onOpenPhotoGallery(): void {
    this.galleryOpenerService.openPhotoGallery(this.detail.projectId,
      this.detail.constructionData?.periods?.length > 0, this.panelHelper.selectedPeriodId);
  }

  onNavigationClick(navigation: BuildingProgressNavigation): void {
    this.detail.currentNavigation = navigation;
  }

  onSearchClicked(): void {
    this.onNavigationClick('table');
    this.detail.toggleMobileSearchVisible();
  }

  getSummaryPanelData(): BuildingProgressSummaryPanelModel {
    const construction = this.detail.constructionData?.items.find(i => i.itemType === 'S');
    let dateBeforeLastPeriod: Date;

    if (this.detail.constructionData?.periods.length > 1) {
      dateBeforeLastPeriod = new Date(this.detail.constructionData.periods[this.detail.constructionData.periods.length - 1].dateFrom);
      dateBeforeLastPeriod.setDate(dateBeforeLastPeriod.getDate() - 1);
    }

    return {
      periodDate: dateBeforeLastPeriod === undefined ? '' : this.datePipe.transform(dateBeforeLastPeriod, 'dd.MM.yyyy'),
      percentage: construction?.completedPercentage,
      partlyCompletedTotalPrice: construction?.partlyCompletedTotalPrice,
      totalPrice: construction?.totalPrice,
      actualPeriodAmount:
        construction?.periods?.length > 0 ?
          construction?.periods.find(p => p.periodId === this.lastPeriodId())?.totalPrice :
          0,
      completetedTotalPrice: construction?.completedTotalPrice,
      restTotalPrice: construction?.restTotalPrice,
      overdrawnTotalPrice: construction?.additionalTotalPrice,
      notCompletedTotalPrice: construction?.notCompletedTotalPrice,
      decimalPlaces: this.detail.constructionData?.decimalPlaces.totalPrice
    };
  }

  protected canShowOpenDemoLink(): boolean {
    return this.demoService.canShowOpenDemoLink(this.isDemo);
  }

  protected onOpenDemo(): void {
    this.demoService.onOpenDemo();
  }
}
