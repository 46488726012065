@if (areNavigationsVisible) {
<kros-menu-item
  routerLinkActive="main-active-link"
  data-test="qm-viewer-tab"
  [class.narrow-navbar]="isNarrowNavbar"
>
  <a
    [routerLink]="[routePrefix, 'viewer']"
    queryParamsHandling="merge"
  >{{ 'QUANTITY_MANAGER.DRAWING_VIEWER' | translate }}</a>
</kros-menu-item>

<kros-menu-item
  *ngIf="isAreaTabVisible"
  routerLinkActive="main-active-link"
  data-test="qm-area-tab"
  [class.narrow-navbar]="isNarrowNavbar"
>
  <a
    [routerLink]="[routePrefix, pathQmArea]"
    queryParamsHandling="merge"
  >{{ 'QUANTITY_MANAGER.AREAS' | translate }}</a>
</kros-menu-item>

<kros-menu-item
  *ngIf="isBudgetTabVisible"
  routerLinkActive="main-active-link"
  [class.narrow-navbar]="isNarrowNavbar"
>
  <a
    [routerLink]="[routePrefix, pathQmStructure]"
    queryParamsHandling="merge"
  >{{ 'BIM.BIM_STRUCTURE' | translate }}</a>
</kros-menu-item>

<kros-menu-item
  *ngIf="isBudgetTabVisible"
  routerLinkActive="main-active-link"
  [class.narrow-navbar]="isNarrowNavbar"
>
  <a
    [routerLink]="[routePrefix, pathQmExtraction]"
    queryParamsHandling="merge"
  >{{ 'BIM.BIM_EXTRACTION' | translate }}</a>
</kros-menu-item>
}
