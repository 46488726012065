<div class="kros-modal-header">
  <h5 class="modal-title">
    {{ 'BUILDING_PROGRESS.DRAW.BY_PERCENTAGE' | translate}}&nbsp;&nbsp;&nbsp;{{periodDateText}}
  </h5>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onCloseClick()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<form
  [formGroup]="percentageDrawForm"
  (ngSubmit)="onSubmit()"
>
  <div class="kros-modal-body">
    <div class="form-container">
      <div class="form-group">
        <kros-numeric-input
          id="percentage"
          [options]="{
                        name: 'percentage',
                        inputClass: 'form-control text-right',
                        formControl: percentageDrawForm.controls['percentage'],
                        autocompleteOff: true,
                        selectAllOnClick: true,
                        testName: 'percentage-draw-input'
                    }"
        ></kros-numeric-input>
        %
      </div>
    </div>
  </div>

  <div class="kros-modal-footer">
    <button
      class="btn btn-primary"
      type="submit"
      data-test="confirmation"
    >{{ 'BUILDING_PROGRESS.DRAW.SELECTED' | translate }}</button>
  </div>
</form>
