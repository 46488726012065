import { Observable } from 'rxjs';

import { ApplicationType, ModuleType, PackageType, RequiredLicenseOptions } from '@kros-sk/models';

import { KrosModalRef } from '../../kros-modal';

/**
 * This service provides way to check and enforce license constraints for components.
 */
export abstract class CheckRequiredLicenseService {

  /**
   * Method verifies license constraints to currently loaded license
   * @param minimalPackage - minimal package required to access. null value can be used as unlimited access.
   * @param requiredModule - optional minimal module required to access
   * @param applicationType - optional application type for selecting correct application license
   * @param forbiddenForAcademicLicense - optional flag to check if feature is forbidden for academic license
   */
  abstract isAllowed(
    minimalPackage: PackageType | null,
    requiredModule?: ModuleType,
    applicationType?: ApplicationType,
    forbiddenForAcademicLicense?: boolean): boolean

  /**
   * Show warning with specified text and link to eshop
   * @param requiredLicenseOptions - modal texts and eshop link params
   */
  abstract showWarning(requiredLicenseOptions: RequiredLicenseOptions): KrosModalRef

  /**
   * Show warning with specified text and link to eshop
   * @param requiredLicenseOptions - modal texts and eshop link params
   */
  abstract showWarning$(requiredLicenseOptions: RequiredLicenseOptions): Observable<KrosModalRef | boolean>
}
