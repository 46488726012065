import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

import { KrosModalRef } from '@kros-sk/components';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { DocumentListInfoModel } from '../../../document-explorer';
import { DocumentsEditDispatchersService } from '../../store';
import { RequiredIgnoreWhiteSpacesValidator } from '../../validators';

@Component({
  selector: 'kros-folder-rename-dialog',
  templateUrl: './folder-rename-dialog.component.html',
  styleUrls: ['./folder-rename-dialog.component.scss']
})
export class FolderRenameDialogComponent extends UnsubscribeComponent implements OnInit {
  data: { document: DocumentListInfoModel, projectId: number };
  nameForm: UntypedFormGroup;
  changesMade = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private documentsEditDispatchersService: DocumentsEditDispatchersService,
    private modalRef: KrosModalRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.data = this.modalRef.data;

    this.nameForm = this.formBuilder.group({
      name: [this.data.document.name, [RequiredIgnoreWhiteSpacesValidator, Validators.maxLength(100)]]
    });
    this.subs.sink = this.nameForm.valueChanges.subscribe(() => {
      this.changesMade = true;
    });
  }

  get name(): AbstractControl { return this.nameForm.controls['name']; }

  onSubmit(): void {
    if (this.nameForm.valid) {
      this.documentsEditDispatchersService.renameFolder(this.data.projectId, this.data.document.id, this.name.value);
      this.modalRef.submit();
    }
  }

  onNoClick(): void {
    this.modalRef.cancel();
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }
}
