import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { combineLatest, filter, startWith } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { AuthSelectorsService } from '@kros-sk/auth';
import { FeatureFlagsDispatchersService, HasFeatureFlagFacade } from '@kros-sk/core/feature-flags';
import { KrosAppInsightsService } from '@kros-sk/core';
import { loadAppVersion } from '@kros-sk/components';

import { ProgressBarService } from '../progress-bar';
import { ToastService, ToastType } from '../toast';
import { TranslateService } from '../translate';

@Component({ template: '' })
export abstract class ApplicationBaseComponent implements OnInit {

  protected readonly featureFlagFacade = inject(HasFeatureFlagFacade);
  protected readonly featureFlagDispatchersService = inject(FeatureFlagsDispatchersService);
  protected appInsightCloudRole: string;
  protected appConfig = inject(APP_CONFIG);
  protected appInsightsService = inject(KrosAppInsightsService);
  protected authSelectorsService = inject(AuthSelectorsService);
  protected destroyRef = inject(DestroyRef);
  protected platform = inject(Platform);
  protected progressBarService = inject(ProgressBarService, { optional: true });
  protected route = inject(ActivatedRoute);
  protected router = inject(Router);
  protected toastService = inject(ToastService);
  protected translateService = inject(TranslateService);

  isLoading = false;
  isEmptyPage = false;
  initialized = false;
  ignoreMaintenance = true;
  featureFlagsLoaded = false;

  get isCzechVersion(): boolean {
    return this.appConfig.location === 'cz';
  }

  get isPhoneDevice(): boolean {
    return this.platform.IOS || this.platform.ANDROID;
  }

  get showUpdateInfo(): boolean {
    return this.initialized && !this.isEmptyPage && this.featureFlagsLoaded && !this.ignoreMaintenance;
  }

  ngOnInit(): void {
    this.initAppInsight();
    combineLatest([
      this.route.data,
      this.router.events.pipe(filter(e => e instanceof NavigationEnd), startWith(undefined))
    ]).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.isEmptyPage = this.router.url.endsWith('maintenance');
      if (!this.isEmptyPage) {
        this.initializeBaseComponent();
      }
    });
    this.featureFlagFacade.hasFeatureFlag('IgnoreMaintenance').pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((ignoreMaintenance: boolean) => this.ignoreMaintenance = ignoreMaintenance);
    this.authSelectorsService.currentUser$.pipe(filter(Boolean), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.featureFlagDispatchersService.loadFeatures());
    this.featureFlagFacade.hasFeaturesLoaded$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(loaded => this.featureFlagsLoaded = loaded);
  }

  showInfoAboutOldVersion(): void {
    this.toastService.open(this.translateService.translate('MAINTENANCE.USING_OLD_VERSION'), ToastType.Warning,
      { autohide: false }, this.translateService.translate('MAINTENANCE.RELOAD'))
      .onAction().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => document.location.reload());
  }

  protected init(): void { }

  protected onLoginFailed(): void { }

  private initializeBaseComponent(): void {
    if (this.initialized) {
      return;
    }

    this.initialized = true;
    this.authSelectorsService.isLoggedIn$.pipe(filter(isLoggedIn => !isLoggedIn), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.onLoginFailed());

    if (this.progressBarService) {
      this.progressBarService.change.pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((isLoading: boolean) => this.isLoading = isLoading);
    }

    if (this.isCzechVersion) {
      document.body.classList.add('czech');
    }

    this.init();
  }

  protected initAppInsight(): void {
    if (this.appConfig.applicationInsightsKey && this.appInsightCloudRole) {
      loadAppVersion();
      this.appInsightsService.initAppInsights(
        this.appConfig.applicationInsightsKey,
        this.appInsightCloudRole,
        0,
        { enableAutoRouteTracking: true }
      );
    }
  }
}
