import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

import { KrosModalRef } from '@kros-sk/components';

import { DocumentsEditDispatchersService, FolderModel, RequiredIgnoreWhiteSpacesValidator } from '../../../document-edit';

@Component({
  selector: 'kros-folder-create-dialog',
  templateUrl: './folder-create-dialog.component.html',
  styleUrls: ['./folder-create-dialog.component.scss']
})
export class FolderCreateDialogComponent implements OnInit {
  data: FolderModel;

  nameForm: UntypedFormGroup;
  get name(): AbstractControl { return this.nameForm.controls['name']; }

  constructor(
    private documentsEditDispatchersService: DocumentsEditDispatchersService,
    private formBuilder: UntypedFormBuilder,
    private modalRef: KrosModalRef,
  ) { }

  ngOnInit(): void {
    this.data = this.modalRef.data;

    this.nameForm = this.formBuilder.group({
      name: [this.data.name, [RequiredIgnoreWhiteSpacesValidator, Validators.maxLength(100)]]
    });
  }

  onSubmit(): void {
    if (this.nameForm.valid) {
      this.data.name = this.name.value;
      this.documentsEditDispatchersService.createFolder(this.data);
      this.modalRef.submit();
    }
  }

  onNoClick(): void {
    this.modalRef.cancel();
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }
}
