import { AbstractControl } from '@angular/forms';

export const OWNER_ME = 'me';

export function isOwnersEmail(email: string): boolean {
  return email === OWNER_ME;
}

export function getQuery(name: string, value: string): string {
  return name && value ? `?${name}=${encodeURIComponent(value)}` : '';
}

export function getProjectIdFromUrl(url: string, projectIdParamName: string): number {
  const segments = url.split(/\\|\/|\?|\=|\&/).filter(segment => segment !== '');
  const projectIndex = segments.findIndex(segment => segment.toLowerCase() === projectIdParamName);
  const projectId = segments[projectIndex + 1];
  return (projectId && !isNaN(+projectId))
    ? +projectId
    : 0;
}

export function getStringValueFromControl(control: AbstractControl): string {
  return control.value?.trim() ?? '';
}
