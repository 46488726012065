import { Component } from '@angular/core';

import { KrosModalRef } from '@kros-sk/components';

@Component({
  selector: 'kros-generic-info-dialog',
  templateUrl: './generic-info-dialog.component.html',
  styleUrls: ['./generic-info-dialog.component.scss'],
})
export class GenericInfoDialogComponent {
  data: {
    header: string,
    description: string,
    secondaryDescription: string,
    closeButtonText: string
  };

  constructor(
    private modalRef: KrosModalRef
  ) {
    this.data = this.modalRef.data;
  }

  onClose(): void {
    this.modalRef.cancel();
  }
}
