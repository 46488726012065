import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ColorCodesDialogComponent } from './color-codes-dialog/color-codes-dialog.component';
import { ColorPaletteComponent } from './color-palette/color-palette.component';
import { TranslateModule } from '../translate';

@NgModule({
  declarations: [
    ColorPaletteComponent,
    ColorCodesDialogComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild()
  ],
  exports: [
    ColorPaletteComponent,
    ColorCodesDialogComponent
  ]
})
export class ColorCodeModule { }
