import { inject, Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError } from 'rxjs/operators';
import { map, Observable, of, switchMap } from 'rxjs';

import * as approvalOrderActions from './approval-order.actions';
import { ApprovalOrderService } from './approval-order.service';

@Injectable()
export class ApprovalOrderEffects {

  private actions$ = inject(Actions);
  private approvalOrderService = inject(ApprovalOrderService);

  loadApprovalOrder$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(approvalOrderActions.loadApprovalOrderStart),
      switchMap(payload => this.approvalOrderService.loadApprovalOrder(payload.projectId).pipe(
        map(approvalOrder => approvalOrderActions.loadApprovalOrderSuccess({ approvalOrders: approvalOrder })),
        catchError(error => of(approvalOrderActions.loadApprovalOrderError({ error })))
      ))
    );
  });

  saveApprovalOrder$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(approvalOrderActions.saveApprovalOrderStart),
      switchMap(payload => this.approvalOrderService.saveApprovalOrder(payload.approvalOrders, payload.projectId)
        .pipe(
          map(_ =>
            approvalOrderActions.saveApprovalOrderSuccess({ approvalOrders: payload.approvalOrders, projectId: payload.projectId })),
          catchError(error => of(approvalOrderActions.saveApprovalOrderError({ error })))
        )
      )
    );
  });
}
