import { Component, Input } from '@angular/core';

import { filter, take } from 'rxjs/operators';

import { formatString } from '@kros-sk/ssw-cdk';
import { KrosModalService } from '@kros-sk/components';
import { QuestionModalComponent, TranslateService } from '@kros-sk/ssw-shared-legacy';

import { DocumentsDispatchersService, DocumentsSelectorsService } from '../../../store/documents';

@Component({
  selector: 'app-upload-building-strip',
  templateUrl: './upload-building-strip.component.html',
  styleUrls: ['./upload-building-strip.component.scss']
})
export class UploadBuildingStripComponent {

  @Input() isInDocuments = false;

  constructor(
    public selectors: DocumentsSelectorsService,
    private dispatchers: DocumentsDispatchersService,
    private translateService: TranslateService,
    private krosModalService: KrosModalService
  ) { }

  close(name: string): void {
    this.krosModalService.openCentered(
      QuestionModalComponent,
      {
        body: formatString(this.translateService.translate('DOCUMENTS.UPLOAD_BUILDING.STOP_UPLOADING'), name),
        confirmButton: this.translateService.translate('COMMON.YES'),
        cancelButton: this.translateService.translate('COMMON.NO')
      },
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: false,
        showMobileArrowBack: false,
        closeOnEscClick: true
      },
      NaN,
      NaN,
      undefined,
      'no-min-width'
    ).afterClosed$.pipe(take(1), filter((p: any) => p.type === 'submit')).subscribe(() => {
      this.dispatchers.clearUploadingData();
    });
  }
}
