import { inject, Injectable } from '@angular/core';

import { FileItem } from 'ng2-file-upload';
import { zip } from 'fflate';

import { ContentTypes } from '../models/content-types';
import { ZIP_SETTINGS_TOKEN } from './zip-settings.model';

@Injectable()
export class ZipperService {

  private zipSettings = inject(ZIP_SETTINGS_TOKEN);

  needZip(file: FileItem): boolean {
    const ext = file._file.name.split('.').pop().toLowerCase();

    return this.zipSettings.enabled && file._file.type !== ContentTypes.zip &&
      (this.zipSettings.extensionsToZip.includes(ext) ||
        (file._file.size > this.zipSettings.minimumSize * 1024 * 1024) && !this.zipSettings.excludedExtensions.includes(ext));
  }

  async zipToFile(file: FileItem): Promise<File> {
    return new File([await this.compressFile(file)], file._file.name + '.zip', { type: ContentTypes.zip });
  }

  private async compressFile(file: FileItem): Promise<Uint8Array> {
    return await this.zipFile(await this.prepareZipObject(file));
  }

  private async prepareZipObject(file: FileItem): Promise<any> {
    const zipObject = {};
    zipObject[file._file.name] = [new Uint8Array(await file._file.arrayBuffer()), {
      level: 6
    }];
    return zipObject;
  }

  private async zipFile(inputFile: any): Promise<any> {
    return new Promise(function (resolve, reject) {
      zip(inputFile, (err, zipped) => {
        if (err === null) {
          resolve(new Blob([zipped.buffer], { type: 'application/zip' }));
        } else {
          reject(new DOMException('problem zipping input files.'));
        }
      });
    });
  }
}
