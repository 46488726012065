<div
  data-test="open-api-row"
  class="card list-item"
>
  <div class="editor-container">
    <div class="header">
      <div class="header-info">
        <div class="arrow-wrapper">
          <i class="material-icons arrow">keyboard_arrow_right</i>
        </div>
        <div class="sub-name animate"></div>
      </div>
    </div>
  </div>
</div>
