import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { SubSink } from 'subsink';
import { switchMap, tap } from 'rxjs/operators';

import { AutocompleteContentDirective } from './autocomplete-content.directive';
import { KrosOptionComponent, KrosOptionMouseAction } from './option.component';

@Component({
  selector: 'kros-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  exportAs: 'krosAutocomplete'
})
export class AutocompleteComponent implements AfterViewInit, OnInit, OnDestroy {
  @Output() optionClicked = new EventEmitter<any>();

  @Input() control: UntypedFormControl;

  @Input() keyPress: EventEmitter<KeyboardEvent>;

  @Input() optionsListFooterTemplate: TemplateRef<any> | null;

  @Input() set removeFooterPadding(value) {
    this._removeFooterPadding = (typeof value === 'boolean') ? value : true;
  }

  get removeFooterPadding(): boolean {
    return this._removeFooterPadding;
  }

  @ViewChild('root') rootTemplate: TemplateRef<any>;

  @ViewChild('scroll', { read: ElementRef, static: false }) scroll: ElementRef;

  @ContentChild(AutocompleteContentDirective) content: AutocompleteContentDirective;

  @ContentChildren(KrosOptionComponent) options: QueryList<KrosOptionComponent>;

  closeModal$ = new Subject<any>();
  optionHovered = false;
  optionHeight: number;
  maxOptionShown: number;

  private focusedIndex = -1;
  private manualClickTrigger$ = new Subject<any>();
  private subs = new SubSink();
  private currentOptions = new BehaviorSubject([]);
  private _removeFooterPadding = true;

  constructor(
    private host: ElementRef<HTMLInputElement>,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.keyPress.subscribe((event) => {
      this.keyboardNavigation(event);
    });
  }

  ngAfterViewInit(): void {
    this.subs.sink = this.options.changes.subscribe(options => {
      this.currentOptions.next(options);
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  optionsClick(): Observable<any> {
    return this.currentOptions.asObservable().pipe(
      switchMap(options => {
        const clicks$ = options.map(option => option.click$);
        return merge(...clicks$, this.manualClickTrigger$);
      })
    );
  }

  mouseAction(): Observable<KrosOptionMouseAction> {
    this.optionHovered = false;
    return this.currentOptions.asObservable().pipe(
      switchMap(options => merge(...options.map(option => option.mouseAction$))),
      tap((evt: KrosOptionMouseAction) => {
        if (evt.event.type === 'mouseenter') {
          this.optionHovered = true;
          this.focusedIndex = evt.index;
          this.focusedItemChange();
        } else {
          this.optionHovered = false;
          this.focusedItemChange(true);
        }
      })
    );
  }

  reset(): void {
    this.focusedIndex = -1;
    this.options.reset([]);
    if (this.scroll) {
      this.scrollToView(this.focusedIndex);
    }
  }

  setActiveOptions(currentValue, valueAttribute): void {
    this.options.forEach((option: KrosOptionComponent) => {
      if (valueAttribute) {
        if (option.value[valueAttribute] && option.value[valueAttribute].toString() === currentValue?.toString()) {
          option.setActive(true);
        } else {
          option.setActive(false);
        }
      } else {
        if (option.value) {
          if (option.value.toString() === currentValue?.toString()) {
            option.setActive(true);
          }
        } else {
          option.setActive(false);
        }
      }
    });
  }

  emitAutoCompleteOptionsStateChanged(
    action: 'opened' | 'selected' | 'cancelled' | 'noOptions' | 'optionsAdded' | 'adCancelled',
    selectedItem?: any
  ): void {
    this.host.nativeElement.dispatchEvent(new CustomEvent(
      'autoCompleteOptionsStateChanged',
      { bubbles: true, detail: { action, selectedItem } })
    );
  }

  private arrowUpPressed(optionsLength: number): void {
    if (this.focusedIndex > -1) {
      this.focusedIndex--;
    } else {
      this.focusedIndex = optionsLength - 1;
    }
    this.focusedItemChange();
    this.scrollToView(this.focusedIndex);
    this.detectChanges();
  }

  private arrowDownPressed(optionsLength: number): void {
    if (this.focusedIndex < optionsLength - 1) {
      this.focusedIndex++;
    } else {
      this.focusedIndex = -1;
    }
    this.focusedItemChange();
    this.scrollToView(this.focusedIndex);
    this.detectChanges();
  }

  private enterPressed(): void {
    const value = this.options.find((v, index) => {
      return index === this.focusedIndex;
    });
    if (value) {
      this.manualClickTrigger$.next(value.value);
    } else {
      this.reset();
      this.emitAutoCompleteOptionsStateChanged('cancelled');
    }
  }

  private focusedItemChange(forceBlur?: boolean): void {
    this.options.forEach((option: KrosOptionComponent, index: number) => {
      option.blur();
      if (this.focusedIndex === index && !forceBlur) {
        option.focus();
      }
    });
    this.detectChanges();
  }

  private keyboardNavigation(event: KeyboardEvent): void {
    const optionsLength = this.optionsListFooterTemplate ? this.options.length - 1 : this.options.length;
    if (optionsLength > 0) {
      switch (event.key) {
        case 'Tab':
        case 'Enter':
          this.enterPressed();
          break;
        case 'ArrowUp':
          this.arrowUpPressed(optionsLength);
          break;
        case 'ArrowDown':
          this.arrowDownPressed(optionsLength);
          break;
        case 'Escape':
          this.reset();
          break;
      }
    } else {
      if (event.key === 'Tab' && this.optionsListFooterTemplate) {
        this.reset();
        this.emitAutoCompleteOptionsStateChanged('adCancelled');
      }
    }
  }

  private scrollToView(index: number): void {
    if (!this.scroll) return;

    const offset = ((this.maxOptionShown - 1) * this.optionHeight) / 2;
    this.scroll.nativeElement.scrollTop = (this.optionHeight * index) - offset;
  }

  private detectChanges(): void {
    if (!this.cdRef['destroyed']) {
      this.cdRef.detectChanges();
    }
  }
}
