<div class="kros-modal-header">
  <h4 class="kros-modal-title">{{'BUILDING_PROGRESS.INVOICE.ISSUE_INVOICE' | translate}}</h4>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onCloseClick()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div class="kros-modal-body">
  <form
    [formGroup]="invoiceForm"
    (ngSubmit)="onSubmit()"
  >
    <div
      class="invoice-periods-empty-info"
      *ngIf="!(periods?.length > 0) else invoiceSettings"
    >
      {{'BUILDING_PROGRESS.INVOICE.PERIODS_EMPTY' | translate}}
    </div>

    <ng-template #invoiceSettings>
      <div class="form-container">
        <div class="form-group">
          <app-period-range-control
            [periods]="periods"
            [tooltipSinglePeriod]="'BUILDING_PROGRESS.INVOICE.SINGLE_PERIOD_TOOLTIP' | translate"
            [tooltipPeriodRange]="'BUILDING_PROGRESS.INVOICE.PERIODS_RANGE_TOOLTIP' | translate"
            [periodRangeTitle]="'BUILDING_PROGRESS.INVOICE.PERIODS_RANGE_TITLE' | translate"
            [singlePeriodTitle]="'BUILDING_PROGRESS.INVOICE.SINGLE_PERIOD_TITLE' | translate"
            formControlName="periodIds"
          ></app-period-range-control>
        </div>

        <div class="form-group range">
          <label class="caption">{{'BUILDING_PROGRESS.RANGE' | translate}}:</label>

          <div>
            <button
              class="titan-dropdown-toggle btn"
              type="button"
              (click)="openRangeModal()"
              data-test="invoice-range-button"
            >
              <span>{{buildingRangeLabel}}</span>
              <span krosMaterialIcon>
                expand_more
              </span>
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </form>
</div>

<div class="kros-modal-footer">
  <button
    *ngIf="periods?.length > 0"
    class="btn btn-primary btn-submit"
    data-test="issue-invoice-button"
    id="ga-create-invoice"
    type="button"
    [disabled]="!invoiceForm.valid"
    (click)="onSubmit()"
  >{{'INVOICE.INVOICE' | translate}}</button>
</div>
