import { Component } from '@angular/core';

import { KrosModalRef } from '@kros-sk/components';

@Component({
  selector: 'app-vat-rate-update-modal',
  templateUrl: './vat-rate-update-modal.component.html',
  styleUrls: ['./vat-rate-update-modal.component.scss'],
})
export class VatRateUpdateModalComponent {

  constructor(
    private modalRef: KrosModalRef
  ) { }

  onCancel(): void {
    this.modalRef.cancel();
  }

  onSubmit(): void {
    this.modalRef.submit();
  }
}
