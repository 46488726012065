import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BlobDownloadResponseModel } from '@azure/storage-blob';
import { catchError, map, Observable, of, switchMap, take, throwError } from 'rxjs';

import {
  DocumentEditService,
  DocumentStorageService,
  DocumentVersionDto,
  FolderTreeNode,
  TemporaryUploadInfoModel,
  TranslateService,
  UploadFileInfoModel,
  UploadVersionInfoModel
} from '@kros-sk/ssw-shared-legacy';
import { getDocumentIdParameter, getDocumentVersionIdParameter } from '@kros-sk/ssw-cdk';

import { environment } from '../../../../environments/environment';

const documentApi = '/api/documentService/Documents';
const documentVersionApi = '/api/documentService/DocumentVersions';
const documentVersionController = '/documentService/DocumentVersions';
const downloadAccessDataEndpoint = '/accessData/';
const viewAccessDataEndpoint = '/viewAccessData/';
const documentNameEndpoint = '/documentName';
const getVersionsEndpoint = '/versionList';
const setDescriptionEndpoint = '/descriptionSet';
const temporaryAccessDataEndpoint = '/temporaryAccessData?accessKey=';
const temporaryBlobCopyEndpoint = '/temporaryBlobCopy';
const folderHierarchyEndpoint = '/folderHierarchy/';
const documentsController = '/documentService/documents';
const getFirstDocumentEndpoint = documentsController + '/firstDocument';

@Injectable()
export class DocumentService {

  constructor(
    private documentStorageService: DocumentStorageService,
    private http: HttpClient,
    private translateService: TranslateService,
    private documentEditService: DocumentEditService
  ) { }

  private get gatewayApi(): string {
    return environment.appUrls.titanGatewayUrl + '/api/projects/';
  }

  private get apiDocumentUrl(): string {
    return environment.appUrls.titanGatewayUrl + documentApi;
  }

  private get apiDocumentVersionUrl(): string {
    return environment.appUrls.titanGatewayUrl + documentVersionApi;
  }

  getFolderHierarchy(projectId: number): Observable<FolderTreeNode[]> {
    return this.http.get<FolderTreeNode[]>(this.apiDocumentUrl + folderHierarchyEndpoint + projectId);
  }

  getDocumentVersionList(documentId: string): Observable<DocumentVersionDto[]> {
    let versionList: DocumentVersionDto[];

    return this.http.get(this.apiDocumentVersionUrl + getVersionsEndpoint + '?documentId=' + encodeURIComponent(documentId))
      .pipe(
        switchMap((versions: DocumentVersionDto[]) => {
          versionList = versions.sort((v1, v2) => v2.order - v1.order);
          return of(versionList);
        }),
        catchError(this.handleError.bind(this))
      );
  }

  getDocumentName(documentId: string): Observable<string> {
    const endpoint = this.apiDocumentUrl + documentNameEndpoint + getDocumentIdParameter(documentId);
    return this.http.get(endpoint, { responseType: 'text' as 'json' })
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  downloadDocument(documentVersionId: string): Observable<Blob> {
    return this.downloadDocumentCore(documentVersionId, downloadAccessDataEndpoint);
  }

  private downloadDocumentCore(documentVersionId: string, accessDataEndpoint: string): Observable<Blob> {
    const endpoint =
      this.apiDocumentVersionUrl + accessDataEndpoint + getDocumentVersionIdParameter(documentVersionId);

    return this.http.get<any>(endpoint)
      .pipe(
        switchMap(resp => this.documentStorageService.downloadDocument(resp)),
        switchMap((resp: BlobDownloadResponseModel) => resp.blobBody)
      );
  }

  openDocument(documentVersionId: string): Observable<Blob> {
    return this.downloadDocumentCore(documentVersionId, viewAccessDataEndpoint);
  }

  completeUploadBuildingVersion(projectId: number, versionInfo: UploadVersionInfoModel, accessKey: string): Observable<any> {
    return this.copyTemporaryBlob(projectId, '', versionInfo.documentId, accessKey)
      .pipe(
        take(1),
        switchMap(() => this.documentEditService.publishUploadVersion(versionInfo))
      );
  }

  completeUploadBuilding(fileInfo: UploadFileInfoModel, accessKey: string): Observable<string> {
    return this.copyTemporaryBlob(fileInfo.projectId, fileInfo.parentId, '', accessKey)
      .pipe(
        take(1),
        switchMap(() => this.documentEditService.publishUploadDocument([fileInfo])),
        map((documents) => documents[0].id));
  }

  private copyTemporaryBlob(projectId: number, parentId: string, documentId: string, accessKey: string): Observable<any> {
    return this.http.post(this.getApiDocumentVersionUrl(projectId, temporaryBlobCopyEndpoint), { parentId, documentId, accessKey });
  }

  private getApiDocumentVersionUrl(projectId: number, endpoint: string): string {
    return this.gatewayApi + projectId + documentVersionController + endpoint;
  }

  getDocumentUrl(documentVersionId: string): Observable<string> {
    const endpoint =
      this.apiDocumentVersionUrl + downloadAccessDataEndpoint + getDocumentVersionIdParameter(documentVersionId);

    return this.http.get<any>(endpoint).pipe(
      map(resp => `${resp.blobUri}`)
    );
  }

  setDescription(documentVersionId: string, description: string): Observable<any> {
    return this.http.patch(this.apiDocumentVersionUrl + setDescriptionEndpoint, { documentVersionId, description })
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      let errorMessage: string = this.translateService.translate('DOCUMENTS.DOCUMENT_GENERALERROR');

      switch (error.status) {
        case 403:
          errorMessage = this.translateService.translate('DOCUMENTS.DOCUMENT_FORBBIDEN');
          break;

        case 404:
          errorMessage = this.translateService.translate('DOCUMENTS.DOCUMENT_NOT_EXIST');
          break;

        case 409:
          errorMessage = this.translateService.translate('DOCUMENTS.ADRESAR_NIE_JE_PRAZDNY');
          break;
      }

      console.error(
        `Backend returned code ${error.status}, ` +
        `error message: ${errorMessage}, ` +
        `body was: ${error.error}`);

      return throwError(() => new Error(errorMessage));
    }
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  getUploadingData(temporaryId: string): Observable<TemporaryUploadInfoModel> {
    return this.http.get<TemporaryUploadInfoModel>(this.apiDocumentVersionUrl + temporaryAccessDataEndpoint + temporaryId);
  }
}
