import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import * as listEditActions from './documents-edit.actions';
import { DocumentListInfoModel, DocumentsState } from '../../document-explorer';
import { FolderModel } from '../models';

@Injectable()
export class DocumentsEditDispatchersService {
  constructor(
    private store: Store<DocumentsState>
  ) { }

  createFolder(folder: FolderModel): void {
    this.store.dispatch(
      listEditActions.createFolderStart({ folder })
    );
  }

  renameFolder(projectId: number, documentId: string, documentName: string): void {
    this.store.dispatch(
      listEditActions.renameFolderStart({ projectId, documentId, documentName })
    );
  }

  deleteDocument(projectId: number, documentId: string): void {
    this.store.dispatch(listEditActions.deleteDocument({ projectId, documentId }));
  }

  deleteDocuments(projectId: number, documents: DocumentListInfoModel[]): void {
    this.store.dispatch(listEditActions.deleteDocuments({ projectId, documents }));
  }

  deleteDocumentVersion(projectId: number, documentId: string, documentVersionId: string): void {
    this.store.dispatch(listEditActions.deleteDocumentVersion({ projectId, documentId, documentVersionId }));
  }

  softDeleteDocumentVersion(projectId: number, documentId: string, documentVersionId: string): void {
    this.store.dispatch(listEditActions.softDeleteDocumentVersion({ projectId, documentId, documentVersionId }));
  }

  softDeleteDocument(projectId: number, documentId: string): void {
    this.store.dispatch(listEditActions.softDeleteDocument({ projectId, documentId }));
  }

  softDeleteDocuments(projectId: number, documents: DocumentListInfoModel[]): void {
    this.store.dispatch(listEditActions.softDeleteDocuments({ projectId, documents }));
  }

  undoDeleteDocumentVersion(documentVersionId: string): void {
    this.store.dispatch(listEditActions.undoDeleteDocumentVersion({ documentVersionId }));
  }

  undoDeleteDocuments(projectId: number, documentIds: string[]): void {
    this.store.dispatch(listEditActions.undoDeleteDocuments({ projectId, documentIds }));
  }
}
