import { Injectable } from '@angular/core';

import { take } from 'rxjs/operators';

import { BudgetStructureService } from '@kros-sk/ssw-shared-legacy';
import { KrosModalService } from '@kros-sk/components';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

import { BudgetApprovalDispatchersService } from '../../store/budget-approval';
import { BudgetChangeItem, ChangeSheetItem } from '../models';
import { BuildingProgressActionAccessService } from '../../building-progress/services/building-progress-action-access.service';
import { RelationDialogComponent } from '../components/relation-dialog/relation-dialog.component';

@Injectable()
export class RelationHelper {

  constructor(
    private appInsightsService: AppInsightsBaseService,
    private krosModalService: KrosModalService,
    private budgetStructureService: BudgetStructureService,
    private dispatcherService: BudgetApprovalDispatchersService,
    private buildingProgressActionAccessService: BuildingProgressActionAccessService
  ) { }

  openRelationDialog(item: ChangeSheetItem | BudgetChangeItem, tableId: string, buildingObjectId?: number): void {
    this.budgetStructureService.setFocusToBudgetItemById(item.id, tableId);
    this.budgetStructureService.setFocusToStructureItemById(item.id, tableId);

    const modalRef = this.krosModalService.openCentered(
      RelationDialogComponent,
      { item },
      { closeOnBackdropClick: false },
      NaN,
      NaN,
      undefined,
      'no-max-width'
    );

    modalRef.afterClosed$.pipe(take(1)).subscribe(result => {
      if (result.type === 'submit' && this.buildingProgressActionAccessService.checkForAccessToCreateAndDeleteRelation()) {
        this.appInsightsService.trackEvent('BA-change-sheet-relation-set', result.data);
        this.dispatcherService.editChangeSheetItemRelation(result.data, buildingObjectId);
      }
    });
  }
}
