import { Injectable } from '@angular/core';

import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { License, LicenseDetail } from '../../license';
import { LicenseState } from './license.state';

const selectLicenseFeature = createFeatureSelector<LicenseState>('license-info');

export const selectMishaLicense = createSelector(
  selectLicenseFeature,
  state => state.mishaLicense
);

export const selectBuildingProgressLicense = createSelector(
  selectLicenseFeature,
  state => state.buildingProgressLicense
);

export const selectBudgetLicense = createSelector(
  selectLicenseFeature,
  state => state.budgetLicense
);

export const selectQuantityManagerLicense = createSelector(
  selectLicenseFeature,
  state => state.quantityManagerLicense
);

export const selectReturnToBuildingProgress = createSelector(
  selectLicenseFeature,
  state => state.returnToBuildingProgress
);

export const selectReturnToBudgetApproval = createSelector(
  selectLicenseFeature,
  state => state.returnToBudgetApproval
);

export const selectDesktopLicense = createSelector(
  selectLicenseFeature,
  state => state.desktopLicense
);

const selectLicenseDetail = createSelector(
  selectLicenseFeature,
  (state): LicenseDetail => {
    return {
      licenseNumber: state.licenseNumber,
      isAutomaticRenewal: state.isAutomaticRenewalLicense,
      existValid: state.existValid
    } as LicenseDetail;
  }
);

@Injectable()
export class LicenseSelectorsService {
  mishaLicense$: Observable<License> = this.store.select(selectMishaLicense);
  buildingProgressLicense$: Observable<License> = this.store.select(selectBuildingProgressLicense);
  budgetLicense$: Observable<License> = this.store.select(selectBudgetLicense);
  quantityManagerLicense$: Observable<License> = this.store.select(selectQuantityManagerLicense);
  desktopLicense$: Observable<License> = this.store.select(selectDesktopLicense);
  returnToBuildingProgress$: Observable<boolean> = this.store.select(selectReturnToBuildingProgress);
  returnToBudgetApproval$: Observable<boolean> = this.store.select(selectReturnToBudgetApproval);
  licenseDetail$: Observable<LicenseDetail> = this.store.select(selectLicenseDetail);

  constructor(private store: Store<LicenseState>) { }
}
