<div
  class="documents-container"
  [ngClass]="{'collapsed': panelCollapsed}"
>
  <app-document-panel-container
    class="panel-container"
    [collapsed]="panelCollapsed"
    (panelCollapsed)="onPanelCollapsed($event)"
  ></app-document-panel-container>
  <app-document-list-container
    class="list-container"
    [documentId]="documentId"
  ></app-document-list-container>
</div>
