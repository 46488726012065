<div class="kros-modal-header">
  <h4 class="kros-modal-title">{{'BUDGET_APPROVAL.EXPORT.EXPORT_SUMMARY_CHANGE_SHEETS'
    | translate}}</h4>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onCloseClick()"
    data-test="budget-approval-summary-settings-close-button"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div class="kros-modal-body">
  <form [formGroup]="settingsForm">
    <div class="form-container">
      <div class="form-group checkbox">
        <input
          id="isTotalPriceChangesIncluded"
          type="checkbox"
          formControlName="isTotalPriceChangesIncluded"
          data-test="is-total-price-changes-included-checkbox"
        >
        <label for="isTotalPriceChangesIncluded">
          {{'BUDGET_APPROVAL.EXPORT.TOTAL_PRICE_CHANGES' | translate}}
        </label>
      </div>

      <div class="form-group checkbox">
        <input
          id="isTotalPriceChangesPercentageIncluded"
          type="checkbox"
          formControlName="isTotalPriceChangesPercentageIncluded"
          data-test="is-total-price-changes-percentage-included-checkbox"
        >
        <label for="isTotalPriceChangesPercentageIncluded">
          {{'BUDGET_APPROVAL.EXPORT.TOTAL_PRICE_PERCENTAGE_CHANGES' | translate}}
        </label>
      </div>

      <div class="form-group checkbox">
        <input
          id="isPositiveAndNegativeChangesIncluded"
          type="checkbox"
          formControlName="isPositiveAndNegativeChangesIncluded"
          data-test="is-positive-and-negative-changes-included-checkbox"
        >
        <label for="isPositiveAndNegativeChangesIncluded">
          {{'BUDGET_APPROVAL.EXPORT.POSITIVE_NEGATIVE_CHANGES' | translate}}
        </label>
      </div>

      <div class="form-group checkbox">
        <input
          id="isAbsoluteChangesIncluded"
          type="checkbox"
          formControlName="isAbsoluteChangesIncluded"
          data-test="is-absolute-changes-included-checkbox"
        >
        <label for="isAbsoluteChangesIncluded">
          {{'BUDGET_APPROVAL.EXPORT.ABSOLUTE_CHANGES' | translate}}
        </label>
      </div>

      <div class="form-group checkbox">
        <input
          id="isAbsoluteChangesPercentageIncluded"
          type="checkbox"
          formControlName="isAbsoluteChangesPercentageIncluded"
          data-test="is-absolute-changes-percentage-included-checkbox"
        >
        <label for="isAbsoluteChangesPercentageIncluded">
          {{'BUDGET_APPROVAL.EXPORT.ABSOLUTE_PERCENTAGE_CHANGES' | translate}}
        </label>
      </div>
    </div>
  </form>
</div>

<div class="kros-modal-footer">
  <button
    class="btn btn-primary btn-submit"
    type="button"
    (click)="onSubmit()"
    data-test="budget-approval-summary-settings-confirm-button"
  >{{'COMMON.APPLY'| translate}}</button>
</div>
