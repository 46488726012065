<app-budget-approval-differences-toolbar
  (backClick)="onBackClick()"
  (exportClick)="onExportClick()"
>
</app-budget-approval-differences-toolbar>

<div class="info-panel">
  {{ 'BUDGET_APPROVAL.DIFFERENCES_DESCRIPTION' | translate }}
</div>

<kros-data-table
  *ngIf="hasData else contentLoader"
  [itemHeight]="tableService.averageLineHeight"
  [data]="tableData"
  [itemCount]="itemCount"
  [config]="dataTableConfig"
  (cellFocused)="onCellFocused($event)"
  [focusedCell]="focusedCell"
  (scrollFocusedCellToView)="onScrollFocusedCellToView($event)"
></kros-data-table>

<ng-template #contentLoader>
  <kros-timeline [type]="timelineType.Table"></kros-timeline>
</ng-template>
