import { Injectable } from '@angular/core';

import { Action, createFeatureSelector, createSelector, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { HistoryState, undoRedoKey } from './undo-redo.state';

const selectUndoRedoFeature = createFeatureSelector<HistoryState>(undoRedoKey);

export const selectPresentAction = createSelector(
  selectUndoRedoFeature,
  state => state.history.past[state.history.past.length - 1]
);

export const selectFutureAction = createSelector(
  selectUndoRedoFeature,
  state => state.history.future[0]
);

export const selectIsUndoable = createSelector(
  selectUndoRedoFeature,
  state => state.history.past.length > 0
);

export const selectIsRedoable = createSelector(
  selectUndoRedoFeature,
  state => state.history.future.length > 0
);

export const selectIsInProgress = createSelector(
  selectUndoRedoFeature,
  state => state.isInProgress
);

@Injectable()
export class UndoRedoSelectorsService {
  constructor(private store: Store<HistoryState>) { }

  get futureAction$(): Observable<Action> {
    return this.store.select(selectFutureAction);
  }

  get presentAction$(): Observable<Action> {
    return this.store.select(selectPresentAction);
  }

  get isUndoable$(): Observable<boolean> {
    return this.store.select(selectIsUndoable);
  }

  get isRedoable$(): Observable<boolean> {
    return this.store.select(selectIsRedoable);
  }

  get isInProgress$(): Observable<boolean> {
    return this.store.select(selectIsInProgress);
  }
}
