import { CDK_MENU, CdkMenu, CdkMenuGroup } from '@angular/cdk/menu';
import { Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[kros-dropdown]',
  exportAs: 'krosDropdown',
  template: '<ng-content></ng-content>',
  styleUrls: ['./kros-dropdown.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'kros-dropdown'
  },
  providers: [
    {provide: CdkMenuGroup, useExisting: KrosDropdownComponent},
    {provide: CDK_MENU, useExisting: KrosDropdownComponent},
  ],
})
export class KrosDropdownComponent extends CdkMenu {}
