import { ActivatedRoute, Router } from '@angular/router';
import { Component, DestroyRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { AuthSelectorsService } from '@kros-sk/auth';
import { BuildingProgressPermission } from '@kros-sk/ssw-shared/permission';
import { Cell, DataTableComponent, DetailBaseComponent } from '@kros-sk/ssw-shared-legacy';

import { ApprovalType } from '../../enums';
import { BudgetApprovalApproveService } from '../../services/budget-approval-approve.service';
import {
  BudgetApprovalDeleteService,
  BudgetApprovalDemoService,
  BudgetApprovalFilterService,
  BudgetApprovalTableService
} from '../../services';
import { BudgetApprovalDispatchersService, BudgetApprovalSelectorsService } from '../../../store/budget-approval';
import { BudgetApprovalItem, BudgetApprovalModel } from '../../models';
import { BuildingProgressActionAccessService } from '../../../building-progress/services/building-progress-action-access.service';
import { BuildingProgressDispatchersService, BuildingProgressSelectorsService } from '../../../store/building-progress';
import { ColumnGroup } from '../../../shared/models/column-group.model';
import { ConnectorService } from '../../../core/services/connector.service';
import { environment } from '../../../../environments/environment';
import { ExportHelper } from '../../helpers';
import { LicenseHelper, PermissionHelper } from '../../../building-progress/helpers';
import { ProjectsSelectorsService } from '../../../store/projects';

const fixedColumnWidth = 688;

@Component({
  selector: 'app-budget-approval-detail',
  templateUrl: './budget-approval-detail.component.html',
  styleUrls: ['./budget-approval-detail.component.scss'],
  providers: [BudgetApprovalTableService, BudgetApprovalDeleteService, LicenseHelper, PermissionHelper, BudgetApprovalDemoService]
})
export class BudgetApprovalDetailComponent extends DetailBaseComponent implements OnInit, OnDestroy {

  public filterService = inject(BudgetApprovalFilterService);
  public selectorService = inject(BudgetApprovalSelectorsService);
  public tableService = inject(BudgetApprovalTableService);
  protected isDemo = false;
  private appInsightsService = inject(AppInsightsBaseService);
  private activatedRoute = inject(ActivatedRoute);
  private budgetApprovalApproveService = inject(BudgetApprovalApproveService);
  private buildingProgressActionAccessService = inject(BuildingProgressActionAccessService);
  private buildingProgressDispatchers = inject(BuildingProgressDispatchersService);
  private buildingProgressSelectorsService = inject(BuildingProgressSelectorsService);
  private deleteService = inject(BudgetApprovalDeleteService);
  private dispatcherService = inject(BudgetApprovalDispatchersService);
  private exportHelper = inject(ExportHelper);
  private licenseHelper = inject(LicenseHelper);
  private permissionHelper = inject(PermissionHelper);
  private projectsSelectorsService = inject(ProjectsSelectorsService);
  private authSelectorsService = inject(AuthSelectorsService);
  private connectorService = inject(ConnectorService);
  private demoService = inject(BudgetApprovalDemoService);
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);

  private permission: BuildingProgressPermission;
  private loadedProjectId: number;
  private selectedItemIds = new Set<number>();

  @ViewChild(DataTableComponent) set tableComponent(value: DataTableComponent) {
    this.table = value;

    if (this.table && this.scrollElementToViewParams) {
      this.scrollElementToView(
        this.scrollElementToViewParams.elementId,
        this.scrollElementToViewParams.elementIdForColumnWidth);
      this.scrollElementToViewParams = undefined;
    }
  }

  get isFilterVisible(): boolean {
    return this.hasData;
  }

  get hasPermission(): boolean {
    return this.permissionHelper.hasPermission && !this.permissionHelper.isContractor;
  }

  constructor() {
    super(environment.location);
  }

  ngOnInit(): void {
    this.appInsightsService.trackEvent('BA-visit', { source: this.activatedRoute.snapshot.queryParamMap.get('source') });
    combineLatest([this.authSelectorsService.featureFlags$, this.projectsSelectorsService.projectDetail$])
      .pipe(filter(([_, p]) => !!p && (p.id !== this.projectId)), takeUntilDestroyed(this.destroyRef))
      .subscribe(([ff, p]) => {
        this.buildingProgressDispatchers.loadPermission(p.id);
        this.projectId = p.id;
        this.isDemo = p.isDemo;
        if (ff.budgetMini) {
          this.buildingProgressDispatchers.loadBudgetHasItems(this.projectId);
        }
      });
    this.buildingProgressSelectorsService.permission$
      .pipe(filter(p => !!p && this.projectId === p.projectId), takeUntilDestroyed(this.destroyRef))
      .subscribe(p => this.changePermission(p));
    this.selectorService.budgetApprovalData$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(p => this.setData(p));
    this.selectorService.selectedItemsIds$.pipe(filter(ids => !!ids), takeUntilDestroyed(this.destroyRef))
      .subscribe(ids => this.selectedItemIds = ids);
    this.connectorService.buildingProgressBudgetCreationFinished$
      .pipe(filter(p => p.projectId === this.projectId && p.isSuccess), takeUntilDestroyed(this.destroyRef))
      .subscribe(p => this.dispatcherService.loadBudgetApprovalData(p.projectId));

    this.fixedColumnWidth = fixedColumnWidth;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.licenseHelper.destroy();
    this.permissionHelper.destroy();
  }

  private setData(data: BudgetApprovalModel): void {
    if (data && this.tableData?.items.length > data.items.length &&
      this.focusedCell.rowIndex > data.items.length - 1 &&
      data.items[data.items.length - 1]
    ) {
      this.focusedCell.rowIndex = data.items.length - 1;
    }

    this.tableData = data;

    this.dataTableConfig = this.hasData
      ? this.tableService.getTableConfig(this.tableData, this.filterService.getColumnGroups())
      : undefined;

    if (data?.budgetItemId) {
      this.focusedCell = {
        colId: 'budget-description',
        rowIndex: data.items.findIndex(i => i.id === data.budgetItemId)
      };
    }

    this.focusCell();
  }

  private changePermission(permission: BuildingProgressPermission): void {
    this.permission = permission;
    this.loadData();
  }

  private loadData(): void {
    if (this.hasPermission &&
      this.permission &&
      this.projectId === this.permission.projectId &&
      this.loadedProjectId !== this.projectId) {
      this.dispatcherService.clearMultiSelectItems();
      this.dispatcherService.loadBudgetApprovalData(this.projectId, this.activatedRoute.snapshot.queryParamMap.get('hierarchyCode'));
      this.dispatcherService.loadDifferencesData(this.projectId);
      this.dispatcherService.loadSharingList(this.projectId);
      this.loadedProjectId = this.projectId;
    }
  }

  onColumnGroupsCheckChanged(columnGroups: ColumnGroup[]): void {
    this.dataTableConfig = this.tableService.getTableConfig(this.tableData, columnGroups);
  }

  onDeleteClick(): void {
    if (this.focusedCell) {
      const selectedItem = this.tableData.items[this.focusedCell.rowIndex];
      if (selectedItem && this.buildingProgressActionAccessService.checkForFreeOwner(true)) {
        this.deleteService.deleteBuildingObject(this.projectId, selectedItem, this.getChilds());
      }
    }
  }

  onApproveClick(): void {
    this.changeApprovalBuildingObjects(false);
  }

  onCancelApprovedClick(): void {
    this.changeApprovalBuildingObjects(true);
  }

  private changeApprovalBuildingObjects(cancelApproved: boolean): void {
    if (this.selectedItemIds.size > 0) {
      this.budgetApprovalApproveService.approveBuildingObjects(
        this.projectId,
        this.tableData.items.filter(i => this.selectedItemIds.has(i.id)));
    } else if (this.focusedCell) {
      const selectedItem = this.tableData.items[this.focusedCell.rowIndex];
      this.budgetApprovalApproveService.changeApprovalBuildingObjects(this.projectId, selectedItem, this.getChilds(), cancelApproved);
    }
  }

  onExportClick(): void {
    this.exportHelper.openBudgetApprovalExport(this.tableData, this.projectId);
  }

  onShowChangeSheetItemsClick(): void {
    if (this.focusedCell) {
      this.showChangeSheetItems(this.focusedCell.rowIndex);
    }
  }

  onItemDblClick(cell: Cell): void {
    this.showChangeSheetItems(cell.rowIndex);
  }

  onItemCheckChanged(item: any, state: boolean): void {
    this.dispatcherService.multiSelectItem(item.id, state);
  }

  onHeaderCheckChanged(state: boolean): void {
    if (state) {
      this.dispatcherService.multiSelectAllitems();
    } else {
      this.dispatcherService.clearMultiSelectItems();
    }
  }

  onShowDifferencesClick(): void {
    if (this.buildingProgressActionAccessService.checkForAccessCauseOfReadonlyOrFreeLicense(true)) {
      this.appInsightsService.trackEvent('BA-different-prices');
      this.router.navigate(['projects/project-detail', this.projectId, 'budget-approval', 'differences']);
    }
  }

  private showChangeSheetItems(rowIndex: number): void {
    if (this.tableData.items[rowIndex]) {
      const budgetObject = this.tableData.items[rowIndex];
      this.appInsightsService.trackEvent('BA-show-change-sheet-items');

      if (budgetObject.approvalType !== ApprovalType.None) {
        this.router.navigate(['projects/project-detail', this.projectId, 'budget-approval', budgetObject.id]);
      } else {
        this.router.navigate(['projects/project-detail', this.projectId, 'budget-approval', 'changes', budgetObject.id]);
      }
    }
  }

  private getChilds(): BudgetApprovalItem[] {
    const ret = [];
    let actualIndex = this.focusedCell.rowIndex + 1;

    if (this.tableData.items[this.focusedCell.rowIndex]) {
      const levelOfSelectedItem = this.tableData.items[this.focusedCell.rowIndex].level;

      while (this.tableData.items.length > actualIndex &&
        this.tableData.items[actualIndex].level > levelOfSelectedItem) {
        ret.push(this.tableData.items[actualIndex++]);
      }
    }

    return ret;
  }

  protected canShowOpenDemoLink(): boolean {
    return this.demoService.canShowOpenDemoLink(this.isDemo);
  }

  protected onOpenDemo(): void {
    this.demoService.onOpenDemo();
  }
}
