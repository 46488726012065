import { Component } from '@angular/core';

import { KrosModalRef } from '@kros-sk/components';

import { Tag } from '../../tags';

@Component({
  selector: 'kros-tags-filter-dialog',
  templateUrl: './tags-filter-dialog.component.html',
  styleUrls: ['./tags-filter-dialog.component.scss']
})
export class TagsFilterDialogComponent {
  tags: Tag[] = [];
  activeFilterTagIds: number[] = [];

  constructor(
    private modalRef: KrosModalRef
  ) {
    this.tags = this.modalRef.data.tags;
    this.activeFilterTagIds = this.modalRef.data.activeFilterTagIds;
  }

  isTagActive(tag: Tag): boolean {
    return this.activeFilterTagIds.some(id => id === tag.id);
  }

  toggleTag(tag: Tag): void {
    if (this.isTagActive(tag)) {
      this.activeFilterTagIds = this.activeFilterTagIds.filter(id => id !== tag.id);
    } else {
      this.activeFilterTagIds.push(tag.id);
    }
  }

  onSubmitFilterClick(): void {
    this.modalRef.submit({ filteredTagIds: this.activeFilterTagIds });
  }

  onCancelFilterClick(): void {
    this.modalRef.submit({ cancelTagsFilter: true });
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }
}
