import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, Observable, of, take } from 'rxjs';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { DecimalPlaces, formatNumberValue, round, ToastService, ToastType, TranslateService } from '@kros-sk/ssw-shared-legacy';
import { formatString, UnsubscribeComponent, UserService } from '@kros-sk/ssw-cdk';
import { KrosModalService, MessageTypes } from '@kros-sk/components';

import { BuildingProgressItem } from '../models/construction-data.model';
import {
  BuildingProgressPartialItemDialogComponent
} from '../components/building-progress-partial-item-dialog/building-progress-partial-item-dialog.component';
import { BuildingProgressPartialItemsSelectorsService } from '../../store/building-progress';
import { environment } from '../../../environments/environment';
import {
  PartialBudgetItem,
  PartialBudgetItemChangedProperty,
  PartialBudgetItemMergeApiModel,
  PartialBudgetItems,
  PartialBudgetItemsApiModel
} from '../models/building-progress-partial-budget-items.model';
import { SubcontractorsDispatchersService } from '../../store/subcontractors';

const CHANGED_ITEM_FLAGS = [
  PartialBudgetItemChangedProperty.Amount,
  PartialBudgetItemChangedProperty.Percentage
];

@Injectable()
export class BuildingProgressPartialItemsService extends UnsubscribeComponent {

  private gatewayApi = `${environment.appUrls.titanGatewayUrl}/api/projects/`;
  private budgetApi = '/buildingProgressService/Budget/';

  private amountDecimalPlaces = 3;

  constructor(
    private http: HttpClient,
    private krosModalService: KrosModalService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private userService: UserService,
    private dispatchersService: SubcontractorsDispatchersService,
    private partialItemsSelectorsService: BuildingProgressPartialItemsSelectorsService,
    private appInsightsService: AppInsightsBaseService
  ) {
    super();

    this.subs.sink = this.partialItemsSelectorsService.decimalPlaces$.subscribe(decimalPlaces => {
      this.amountDecimalPlaces = decimalPlaces?.amount;
    });
  }

  recalculatePartialItems(
    partialBudgetItems: PartialBudgetItems,
    decimalPlaces: DecimalPlaces,
    partialItemAmount: number): Observable<PartialBudgetItems> {
    const hasItemForRecalculation = partialBudgetItems.items
      .some((item: PartialBudgetItem) => CHANGED_ITEM_FLAGS.includes(item.changedProperty));
    if (!hasItemForRecalculation) {
      return of(partialBudgetItems);
    }

    let partialBudgetItemsResult = this.recalculateChangedItem(partialBudgetItems, partialItemAmount, decimalPlaces);
    partialBudgetItemsResult = this.recalculateFirstItem(partialBudgetItemsResult, partialItemAmount, decimalPlaces);
    partialBudgetItemsResult = this.recalculateRestAmount(partialBudgetItemsResult, partialItemAmount, decimalPlaces);
    return of(partialBudgetItemsResult);
  }

  openSplitItemsDialog(item: BuildingProgressItem): void {
    this.appInsightsService.trackEvent('PV-split-budget-items-open-dialog');
    this.krosModalService.openCentered(
      BuildingProgressPartialItemDialogComponent,
      item,
      { closeOnBackdropClick: false },
      undefined,
      undefined,
      undefined,
      'modal-partial-items'
    );
  }

  openMergeItemsDialog(item: BuildingProgressItem, projectId: number): void {
    this.appInsightsService.trackEvent('PV-merge-budget-items-open-dialog');
    this.krosModalService.openModalMessageBox(
      {
        caption: formatString(
          this.translateService.translate('BUILDING_PROGRESS.PARTIAL_ITEMS.MERGE_ITEM_MSG_CAPTION'), item.code),
        message: formatString(
          this.translateService.translate(
            'BUILDING_PROGRESS.PARTIAL_ITEMS.MERGE_ITEM_MSG_MESSAGE'),
          formatNumberValue(this.calculateRemainingPartialAmount(item), this.amountDecimalPlaces, environment.location),
          item.measureUnit),
        messageType: MessageTypes.Primary,
        acceptButton: this.translateService.translate('BUILDING_PROGRESS.PARTIAL_ITEMS.MERGE_ITEM_MSG_ACCEPT'),
        cancelButton: this.translateService.translate('COMMON.ZRUSIT')
      },
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: false,
        showMobileArrowBack: false
      }
    ).pipe(take(1)).subscribe(result => {
      if (result?.data === 'accept') {
        this.mergeRemainingPartialItems(item.originalItemIdOfPartialItem, projectId)
          .pipe(
            take(1),
            catchError(this.handleError.bind(this)))
          .subscribe(() => { this.dispatchersService.loadDialogItemsList(projectId); });
        this.appInsightsService.trackEvent('PV-merge-budget-items-accept-button');
      }
    });
  }

  savePartialItems(partialBudgetItems: PartialBudgetItems, projectId: number, originalItemIdOfPartialItem?: number): Observable<any> {
    const apiUrl = `${environment.appUrls.titanGatewayUrl}/api/projects/${projectId}/buildingProgressService/Budget/budgetItemSplit`;
    return this.http.post(apiUrl, this.getApimodelFromStoreModel(partialBudgetItems, originalItemIdOfPartialItem), {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', }),
    });
  }

  private getApimodelFromStoreModel(
    partialBudgetItems: PartialBudgetItems,
    originalItemIdOfPartialItem?: number): PartialBudgetItemsApiModel {
    return {
      budgetItemId: originalItemIdOfPartialItem ? originalItemIdOfPartialItem : partialBudgetItems.budgetItemId,
      partialBudgetItemId: originalItemIdOfPartialItem ? -partialBudgetItems.budgetItemId : undefined,
      partialBudgetItems: partialBudgetItems.items.map((item: PartialBudgetItem) => ({ amount: item.amount }))
    };
  }

  private recalculateChangedItem(
    partialBudgetItems: PartialBudgetItems,
    totalAmount: number,
    decimalPlaces: DecimalPlaces): PartialBudgetItems {
    const partialBudgetItemsResult = partialBudgetItems.items.map((item: PartialBudgetItem) => {
      const resultItem = { ...item };
      if (item.changedProperty === PartialBudgetItemChangedProperty.Percentage) {
        resultItem.amount = round(item.percentage / 100 * totalAmount, decimalPlaces.amount);
        resultItem.amount = Object.is(resultItem.amount, -0) ? 0 : resultItem.amount;
      } else if (item.changedProperty === PartialBudgetItemChangedProperty.Amount) {
        resultItem.percentage = round(item.amount / totalAmount * 100, decimalPlaces.percentage);
        resultItem.percentage = Object.is(resultItem.percentage, -0) ? 0 : resultItem.percentage;
      }
      return resultItem;
    });

    return {
      ...partialBudgetItems,
      items: partialBudgetItemsResult
    };
  }

  private recalculateFirstItem(
    partialBudgetItems: PartialBudgetItems,
    totalAmount: number,
    decimalPlaces: DecimalPlaces): PartialBudgetItems {
    const allocatedAmount = partialBudgetItems.items
      .slice(1)
      .reduce((accumulator, currentItem) => (accumulator + currentItem.amount), 0);
    const allocatedPercentage = partialBudgetItems.items
      .slice(1)
      .reduce((accumulator, currentItem) => (accumulator + currentItem.percentage), 0);
    const remainingAmount = round((totalAmount - allocatedAmount), decimalPlaces.amount);
    const firstItem = partialBudgetItems.items[0];
    firstItem.percentage = round((100 - allocatedPercentage), decimalPlaces.percentage);
    firstItem.amount = firstItem.percentage === 0 ? 0 : remainingAmount;
    return partialBudgetItems;
  }

  private recalculateRestAmount(
    partialBudgetItems: PartialBudgetItems,
    totalAmount: number,
    decimalPlaces: DecimalPlaces
  ): PartialBudgetItems {
    const calculatedTotalAmount = partialBudgetItems.items
      .reduce((accumulator, currentItem) => (accumulator + currentItem.amount), 0);
    const calculatedTotalPercentage = partialBudgetItems.items
      .reduce((accumulator, currentItem) => (accumulator + currentItem.percentage), 0);

    const firstItem = partialBudgetItems.items[0];
    const isPercantageOverExhausted = firstItem.percentage < 0;
    const isAmountOverExhausted = totalAmount > 0 ? (firstItem.amount < 0) : (firstItem.amount > 0);
    const restAmount = round(totalAmount - calculatedTotalAmount, decimalPlaces.amount);
    const restPercentage = round(100 - calculatedTotalPercentage, decimalPlaces.percentage);

    const partialBudgetItemsResult: PartialBudgetItem[] = [];

    partialBudgetItems.items.forEach((item: PartialBudgetItem) => {
      const resultItem = { ...item };
      if (CHANGED_ITEM_FLAGS.includes(item.changedProperty)) {
        resultItem.amount += (restAmount) ? restAmount : 0;
        resultItem.amount += (isAmountOverExhausted) ? partialBudgetItemsResult[0].amount : 0;

        if (isAmountOverExhausted) {
          partialBudgetItemsResult[0].amount = 0;
        }

        resultItem.percentage += (restPercentage) ? restPercentage : 0;
        resultItem.percentage += (isPercantageOverExhausted) ? partialBudgetItemsResult[0].percentage : 0;

        if (isPercantageOverExhausted) {
          partialBudgetItemsResult[0].percentage = 0;
        }
      }
      resultItem.changedProperty = PartialBudgetItemChangedProperty.None;
      partialBudgetItemsResult.push(resultItem);
    });

    return {
      ...partialBudgetItems,
      items: partialBudgetItemsResult
    };
  }

  private mergeRemainingPartialItems(itemId: number, projectId: number): Observable<any> {
    return this.http.post(this.getEndpointPath(projectId, 'budgetItemListMerge'),
      { budgetItemId: itemId } as PartialBudgetItemMergeApiModel,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) });
  }

  private calculateRemainingPartialAmount(item: BuildingProgressItem): number {
    return item?.partialBudgetItems.reduce((totalAmount, partialItem) => totalAmount + partialItem.amount, 0) || 0;
  }

  private getEndpointPath(projectId: number, endpoint: string): string {
    return this.gatewayApi + projectId + this.budgetApi + endpoint;
  }

  private handleError(error: HttpErrorResponse): void {
    if (error.status === 403) {
      const message = this.translateService.translate('PROJECTS.ERROR.USER') +
        this.userService.getUserEmail() +
        this.translateService.translate('PROJECTS.ERROR.NO_PERMISSION');
      this.toastService.open(message, ToastType.Error);
    } else if (error.status === 404) {
      const message = this.translateService.translate('PROJECTS.ERROR.PROJECT_NOT_EXISTS');
      this.toastService.open(message, ToastType.Error);
    }
  }
}
