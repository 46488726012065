import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthSelectorsService, KrosUser } from '@kros-sk/auth';

import { ApplicationType } from '../application';
import { StorageService } from '../storage/storage.service';
import { UserIds } from './user-ids.model';

const wasRegisteredKey = 'wasRegistered';
const userServiceApi = '/api/userService';
const usersApi = '/Users';
const unregisteredUsersApi = userServiceApi + '/UnregisteredUsers';
const userIdentifiersByEmailListEndpoint = '/userIdentifiersByEmailList';
const registerUserEndpoint = '/userRegister';
const unregisteredUsersCreateEndpoint = '/usersCreate';
const setUserLanguageEndpoint = '/userLanguageSet';
const userLanguageEndpoint = '/userLanguage';
const userIdByIdentityIdEndpoint = '/userIdByIdentityId/';
const licenseAgreementEndpoint = '/licenseAgreement/';
const setLicenseAgreementEndpoint = '/licenseAgreementCreate/';
const publishDemoProjectEndpoint = '/demoProjectPublish';
const createDemoProjectEndpoint = '/demoProjectCreate';

export const adminDemoProjectUserId = -1;

@Injectable()
export class UserService {

  private _userId: number;
  private krosUser: KrosUser;

  get userId(): number {
    return this._userId;
  }

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    authSelectorsService: AuthSelectorsService,
    @Inject('gatewayUrl') private gatewayUrl: string
  ) {
    authSelectorsService.currentUser$.subscribe(user => this.krosUser = user);
    authSelectorsService.isLoggedIn$.subscribe(loggedIn => {
      if (!loggedIn) {
        this.storageService.clearSessionStorage();
      }
    });
  }

  private get userServiceApi(): string {
    return this.gatewayUrl + userServiceApi;
  }

  private get usersApi(): string {
    return this.userServiceApi + usersApi;
  }

  private get unregisteredUsersApi(): string {
    return this.gatewayUrl + unregisteredUsersApi;
  }

  loadUserId(): Observable<number> {
    if (this.krosUser) {
      return this.http.get<number>(this.usersApi + userIdByIdentityIdEndpoint + this.krosUser.userId)
        .pipe(switchMap(userId => {
          this._userId = userId;
          return of(userId);
        }));
    }

    return of(-1);
  }

  getUserLanguage(): Observable<string> {
    return this.http.get<string>(this.usersApi + userLanguageEndpoint, { responseType: 'text' as 'json' });
  }

  setUserLanguage(language: string): void {
    this.http.patch(this.usersApi + setUserLanguageEndpoint + '?language=' + language, undefined).subscribe();
  }

  registerUser(): Observable<boolean> {
    if (this.krosUser) {
      if (this.storageService.getItemFromSessionStorage(wasRegisteredKey) !== 'true') {
        return this.registerUserRequest(this.krosUser.userId, this.krosUser.externalUserId, this.krosUser.email).pipe(
          switchMap(res => {
            this.storageService.setItemToSessionStorage(wasRegisteredKey, 'true');
            return of(res.demoProjectRequested);
          })
        );
      }
    } else {
      return of(null);
    }

    return of(false);
  }

  private registerUserRequest(userId: number, externalUserId: number, email: string): Observable<any> {
    const userCreationModel = { IdentityId: userId, Email: email, ExternalId: externalUserId };

    return this.http.post(this.usersApi + registerUserEndpoint, userCreationModel);
  }

  getUserEmail(): string {
    return this.krosUser?.email ?? '';
  }

  getUserIdentityId(): number {
    return this.krosUser?.userId ?? Number.MIN_VALUE;
  }

  getUserIds(emails: string[]): Observable<UserIds[]> {
    return this.http.post<UserIds[]>(this.usersApi + userIdentifiersByEmailListEndpoint, emails);
  }

  unregisteredUsersCreate(emails: { email: string }[]): Observable<any> {
    return this.http.post<string[]>(this.unregisteredUsersApi + unregisteredUsersCreateEndpoint, emails);
  }

  getLicenseAgreement(applicationType: ApplicationType): Observable<boolean> {
    return this.http.get<boolean>(this.usersApi + licenseAgreementEndpoint + applicationType);
  }

  confirmLicenseAgreement(applicationType: ApplicationType): Observable<any> {
    return this.http.post(this.usersApi + setLicenseAgreementEndpoint + applicationType, {});
  }

  publishDemoProject(): Observable<any> {
    return this.http.post(this.usersApi + publishDemoProjectEndpoint, {});
  }

  createDemoProject(): Observable<any> {
    return this.http.post(this.usersApi + createDemoProjectEndpoint, {});
  }
}
