import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-building-progress-export-dropdown',
  templateUrl: './building-progress-export-dropdown.component.html',
  styleUrls: ['./building-progress-export-dropdown.component.scss']
})
export class BuildingProgressExportDropdownComponent {
  @Input() canShowExport = false;
  @Input() canShowExportTable = false;
  @Input() canShowExportConfirmationProtocol = false;
  @Input() canShowExportItemDrawSheet = false;
  @Input() canShowExportToAnotherProject = false;

  @Output() exportInvoiceDetails = new EventEmitter();
  @Output() exportItemDrawSheet = new EventEmitter();
  @Output() exportToExcel = new EventEmitter();
  @Output() exportAmountsUniXml = new EventEmitter();
  @Output() exportConfirmationProtocol = new EventEmitter();
  @Output() exportRemainingBudget = new EventEmitter();
  @Output() exportToAnotherProject = new EventEmitter();

  constructor() { }

}
