import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { AvatarModule, AvatarOptions, KrosModalRef } from '@kros-sk/components';

import { CompanyDetails } from '../../data-access';

@Component({
  selector: 'ssw-company-picker',
  standalone: true,
  imports: [AvatarModule, CommonModule, TranslateModule],
  templateUrl: './company-picker.component.html',
  styleUrls: ['./company-picker.component.scss'],
})
export class CompanyPickerComponent implements OnInit {

  companies: CompanyDetails[];
  currentCompanyId = 0;

  private modalRef = inject(KrosModalRef);

  ngOnInit(): void {
    this.companies = this.modalRef.data.companies;
    this.currentCompanyId = this.companies.find(c => c.isDefault)?.id || this.companies[0].id;
  }

  createAvatarOptions(company: CompanyDetails): AvatarOptions {
    return { text: company.name, identifier: company.id.toString() };
  }

  onSelectCompany(companyId: number): void {
    this.currentCompanyId = companyId;
  }

  onSubmit(): void {
    this.modalRef.submit(this.currentCompanyId.toString());
  }

  onClose(): void {
    this.modalRef.cancel();
  }
}
