import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter } from 'rxjs/operators';

import { License, LicenseSelectorsService, LicenseType } from '@kros-sk/ssw-cdk';

@Injectable()
export class BuildingObjectsUploadLicenseHelper {

  private licenseSelectorsService = inject(LicenseSelectorsService);

  private buildingProgressLicense: License;

  constructor() {
    this.licenseSelectorsService.buildingProgressLicense$.pipe(filter(l => !!l), takeUntilDestroyed())
      .subscribe(buildingProgressLicense => this.buildingProgressLicense = buildingProgressLicense);
  }

  isLicenseFullOrTrial(): boolean {
    return this.buildingProgressLicense?.licenseType === LicenseType.Full
      || this.buildingProgressLicense?.licenseType === LicenseType.Trial;
  }

  isLicenseFree(): boolean {
    return this.buildingProgressLicense?.licenseType === LicenseType.Free;
  }

  isLicenseReadonly(): boolean {
    return this.buildingProgressLicense?.licenseType === LicenseType.Readonly;
  }

  isLicenseToExpire(): boolean {
    return this.buildingProgressLicense?.remindExpiringFullLicenseInDays >= this.buildingProgressLicense.remainingDays;
  }

  isLicenseCanActivateTrial(): boolean {
    return this.buildingProgressLicense && this.buildingProgressLicense.canActivateTrial;
  }

  getLicenseRemainingDays(): number {
    return this.buildingProgressLicense.remainingDays;
  }

  getTrialDuration(): number {
    return this.buildingProgressLicense.trialDuration;
  }
}
