import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { Observable } from 'rxjs';

import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { createQueryParams, parseSearchQueryParam, SEARCH_TEXT } from '../core/search-utils';
import { SearchMode, SearchParams } from './search.model';

@Component({
  selector: 'kros-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent extends UnsubscribeComponent implements OnInit {
  @Input() dataTestPrefix = '';
  @Input() width: string;
  @Input() directlyChangeRoute = true;
  @Input() readFromRoute = true;
  @Input() showOptions = true;

  @Output() search: EventEmitter<SearchParams> = new EventEmitter();

  searchControl: UntypedFormControl;
  radioGroupForm: UntypedFormGroup;

  get currentSearchMode(): SearchMode {
    return this.radioGroupForm.value.searchMode;
  }

  private previousQueryParams: Params;

  constructor(
    @Inject(SEARCH_TEXT) private searchText: Observable<string>,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.searchControl = new UntypedFormControl('');
    this.radioGroupForm = this.fb.group({
      searchMode: 'all'
    });

    if (this.readFromRoute) {
      this.subs.sink = this.route.queryParamMap
        .subscribe((params: ParamMap) => {
          const searchParam = params.get('search');
          if (searchParam) {
            const parsedSearchObject = parseSearchQueryParam(searchParam);
            this.searchControl.setValue(parsedSearchObject.searchText);
            this.radioGroupForm.get('searchMode').setValue(parsedSearchObject.searchMode);
          } else {
            this.searchControl.reset('');
          }
        });
    }
  }

  onSearchKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.onSearch();
    }
  }

  cancelSearch(): void {
    this.searchControl.reset('');
    this.onSearch();
  }

  onSearch(): void {
    const queryParams = this.createQueryParams();
    if (this.directlyChangeRoute) {
      this.router.navigate([],
        {
          relativeTo: this.route,
          queryParams,
          queryParamsHandling: 'merge'
        });
    } else {
      this.search.emit({
        params: {
          searchText: this.searchControl.value.toString().trim(),
          searchMode: this.currentSearchMode
        },
        forceSearch: queryParams.search && JSON.stringify(queryParams) === JSON.stringify(this.previousQueryParams)
      });
    }

    this.previousQueryParams = queryParams;
  }

  onRadioButtonClick(): void {
    document.getElementById('searchInput').focus();
  }

  private createQueryParams(): Params {
    return createQueryParams(this.searchControl.value.toString().trim(), this.currentSearchMode);
  }
}
