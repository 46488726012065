import {
  DatePipe,
  FormatWidth,
  FormStyle,
  getLocaleDateFormat,
  getLocaleDayNames,
  getLocaleMonthNames,
  TitleCasePipe,
  TranslationWidth
} from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import { NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class DatepickerParserFormatter extends NgbDateParserFormatter {

  constructor(@Inject(LOCALE_ID) private localeId: string, private datePipe: DatePipe) {
    super();
  }

  parse(value: string, exact?: boolean): NgbDateStruct {
    let ret: NgbDateStruct = null;

    if (value === '' || value === null) {
      return null;
    }

    const format = getLocaleDateFormat(this.localeId, FormatWidth.Medium);
    const pattern = format
      .replace(/[.,\/-\s][.,\/-\s]+/g, '.')
      .replace(/[.,\/-\s]+/g, '[.,\\/-\\s]*')
      .replace(/y+/, '(\\d*)')
      .replace(/d+/, '(\\d+)')
      .replace(/M+/, '([a-zA-Z0-9]*)');

    if (!exact && value.match(/^(\d{4}|\d{6}|\d{8})$/)) {
      // we can parse digits only input with length of 4, 6, or 8 digits,
      // but we need to prepare it lil bit
      value = this.prepareDigitsOnlyValueForParsing(value, format);
    }

    const matchGroups = value.match(new RegExp(pattern));

    const dayIdx = { value: format.indexOf('d') };
    const monthIdx = { value: format.indexOf('M') };
    const yearIdx = { value: format.indexOf('y') };
    const arr = [dayIdx, monthIdx, yearIdx];

    arr
      .sort((a, b) => a.value - b.value)
      .forEach((element, i) => {
        element.value = i + 1;
      });

    if (matchGroups) {
      const day = parseInt(matchGroups[dayIdx.value], 10);
      let month = parseInt(matchGroups[monthIdx.value], 10);
      let year = parseInt(matchGroups[yearIdx.value], 10);

      if (year < 100 && !exact) year += 2000;
      if (year < 1000 && !exact) year = new Date().getFullYear();

      // month can be in string representation
      if (isNaN(month)) {
        month = new Date(
          Date.parse(matchGroups[monthIdx.value] + ' 9, 2020')
        ).getMonth() + 1;
      }

      // year can be undefined
      if (isNaN(year) && !exact) {
        year = new Date().getFullYear();
      }

      // month can be still undefined
      if (isNaN(month) && !exact) {
        month = new Date().getMonth() + 1;
      }

      ret = { day, month, year };

      if (!this.isValidDate(ret)) {
        ret = undefined;
      }

      if (year < 1000) {
        ret = undefined;
      }
    } else {
      ret = undefined;
    }

    return ret;
  }

  format(date: NgbDateStruct): string {
    if (!date) return '';

    const jsDate = new Date(date.year, date.month - 1, date.day);
    return this.datePipe.transform(jsDate, 'mediumDate');
  }

  private isValidDate(date: NgbDateStruct): boolean {
    if (!date) return false;

    const dateToCheck = new Date(date.year, date.month - 1, date.day);
    return dateToCheck.getFullYear() === date.year && dateToCheck.getMonth() === date.month - 1 && dateToCheck.getDate() === date.day;
  }

  private prepareDigitsOnlyValueForParsing(currentValue: string, localeDateFormat: string): string {
    // for 4 or 6 digits we separate every 2 digits with a space
    if (currentValue.length < 8) {
      return currentValue.match(/\d{2}/g).join(' ');
    }
    // for 8 digit values we need match year as a 4 digits group
    const pattern = localeDateFormat
      .replace(/[^yMd]+/g, '')
      .replace(/y+/, '(\\d{4})')
      .replace(/d+/, '(\\d{2})')
      .replace(/M+/, '(\\d{2})');
    const match = currentValue.match(new RegExp('^' + pattern + '$'));
    if (match && match.length === 4) {
      return `${match[1]} ${match[2]} ${match[3]}`;
    }
    // nothing found, nothing changed...
    return currentValue;
  }
}

@Injectable()
export class DatePickerLocalizationService extends NgbDatepickerI18n {
  constructor(@Inject(LOCALE_ID) private localeId: string, private datePipe: DatePipe, private titleCasePipe: TitleCasePipe) {
    super();
  }

  getWeekdayLabel(weekday: number): string {
    const weekdayStartsWithSunday = weekday === 7 ? 1 : weekday + 1;
    return getLocaleDayNames(this.localeId, FormStyle.Standalone, TranslationWidth.Short)[weekdayStartsWithSunday - 1];
  }

  getMonthShortName(month: number, year?: number): string {
    return this.titleCasePipe.transform(getLocaleMonthNames(this.localeId, FormStyle.Standalone, TranslationWidth.Wide)[month - 1]);
  }

  getMonthFullName(month: number, year?: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    const jsDate = new Date(date.year, date.month - 1, date.day);
    return this.datePipe.transform(jsDate, 'full');
  }
}
