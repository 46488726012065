<div class="tree-container">
  <div class="buttons easy-select">
    <button
      class="btn btn-outline-primary"
      type="button"
      (click)="onUnselectAllClick()"
    >{{ 'BUDGET_APPROVAL.EXPORT.UNSELECT_ALL' | translate }}</button>

    <button
      class="btn btn-outline-primary"
      type="button"
      (click)="onSelectUnapprovedClick()"
    >{{ 'BUDGET_APPROVAL.EXPORT.SELECT_UNAPPROVED' | translate }}</button>

    <button
      class="btn btn-outline-primary"
      type="button"
      (click)="onSelectApprovedClick()"
    >{{ 'BUDGET_APPROVAL.EXPORT.SELECT_APPROVED' | translate }}</button>
  </div>

  <div class="tree">
    <cdk-tree
      [dataSource]="dataSource"
      [treeControl]="treeControl"
    >
      <cdk-tree-node
        *cdkTreeNodeDef="let node"
        class="tree-node"
        [ngClass]="{
                    'unapproved-color': isUnapprovedChangeSheet(node.data),
                    'approved-color': isApprovedChangeSheet(node.data),
                    'visible': shouldRender(node),
                    'hidden': !shouldRender(node)
                }"
      >
        <div
          id="{{ node.data.id }}"
          class="tree-row level-{{getNodeLevel(node)}} no-selection"
          [ngClass]="{'selected': isSelected(node), 'object': colorizedObjects && isConstructionOrBuildingObject(node.data)}"
          [attr.data-test]="'tree-row-level-' + getNodeLevel(node)"
        >
          <input
            type="checkbox"
            class="checkbox"
            *ngIf="isChangeSheet(node)"
            [checked]="isItemCheckChecked(node)"
            [krosIndeterminate]="isItemCheckIndeterminate(node)"
            (change)="onItemCheckChanged($event.target, node)"
          >

          <ng-template #emptyColumn>
            <div></div>
          </ng-template>

          <span class="empty-column"></span>

          <span
            class="node-text"
            krosTooltip="{{hasTooltips ? node.data.code + ' - ' + node.data.description : ''}}"
            [innerHTML]="node.data.code + ' - ' + node.data.description | safeHtml"
          ></span>
        </div>
      </cdk-tree-node>
    </cdk-tree>
  </div>
</div>
