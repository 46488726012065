import { AfterViewInit, Component, ElementRef, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { KrosModalRef } from '../services/kros-modal-ref';

export interface MessageBoxProperties {
  caption: string;
  messageType: MessageTypes;
  acceptButton: string | TemplateRef<any>;
  cancelButton?: string | TemplateRef<any>;
  message?: string | TemplateRef<any>;
  icon?: string;
  focusedButton?: ButtonTypes;
}

export enum MessageTypes {
  Danger = 'Danger',
  Warning = 'Warning',
  Primary = 'Primary'
}

export enum ButtonTypes {
  Cancel = 'cancel',
  Accept = 'accept'
}
@Component({
  selector: 'kros-message-box-modal',
  templateUrl: './message-box-modal.component.html',
  styleUrls: ['./message-box-modal.component.scss'],
})
export class MessageBoxModalComponent implements OnInit, AfterViewInit {
  @ViewChild('cancelButton') cancelButton: ElementRef;
  @ViewChild('acceptButton') acceptButton: ElementRef;

  msgBoxContext: any;

  settings: MessageBoxProperties;
  showXButton = true;

  constructor(
    private modalRef: KrosModalRef) {
  }

  ngOnInit(): void {
    this.settings = this.modalRef.data;
    this.showXButton = this.modalRef.settings?.showXButton;

    this.msgBoxContext = {
      accept: this.modalRef.submit.bind(this.modalRef)
    };
  }

  ngAfterViewInit(): void {
    if (!this.settings.focusedButton || this.settings.focusedButton === ButtonTypes.Cancel) {
      this.cancelButton?.nativeElement.focus();
    } else {
      this.acceptButton?.nativeElement.focus();
    }
  }

  closeModal(): void {
    this.modalRef.cancel();
  }

  submitModal(value: string): void {
    this.modalRef.submit(value);
  }

  isString(value: string | TemplateRef<any>): boolean {
    return typeof value === 'string';
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(): void {
    this.closeModal();
  }

  get buttonType_v2(): string {
    return 'btn-' + this.settings.messageType.toLowerCase();
  }

  get modalLineClass(): string {
    return 'modal-line-' + this.settings.messageType?.toLowerCase();
  }
}
