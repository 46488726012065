import { inject, Injectable } from '@angular/core';

import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Budget } from '@kros-sk/ssw-budget/shared/data-access/models';
import { BudgetSettings, ProjectDetail } from '@kros-sk/ssw-shared-legacy';

import { BudgetSharedState } from './budget-shared-state';

export const budgetSharedFeatureKey = 'budget-shared';
export const selectBudgetFeature = createFeatureSelector<BudgetSharedState>(budgetSharedFeatureKey);

const selectProject = createSelector(
  selectBudgetFeature,
  state => state.project
);

const selectProjectId = createSelector(
  selectBudgetFeature,
  state => state.project?.id
);

const selectSettings = createSelector(
  selectBudgetFeature,
  state => state.settingsState.settings
);

const selectSettingsIsLoaded = createSelector(
  selectBudgetFeature,
  state => state.settingsState.isLoaded
);

const selectUiSettingsByName =
  (settingsName: string): any => createSelector(
    selectBudgetFeature,
    (state) => state.uiSettings[settingsName]?.isLoading ? null : state.uiSettings[settingsName]?.value ?? undefined
  );

const selectUiSettingsIsLoadingByName =
  (settingsName: string): any => createSelector(
    selectBudgetFeature,
    (state) => state.uiSettings[settingsName]?.isLoading
  );

const selectBudget = createSelector(
  selectBudgetFeature,
  state => state.budget
);

const selectBudgetId = createSelector(
  selectBudgetFeature,
  state => state.budget?.id
);

const selectIsMainBudgetSelected = createSelector(
  selectBudgetFeature,
  state => state.budget?.isMain
);

@Injectable({
  providedIn: 'root'
})
export class BudgetSharedSelectorsService {
  private store = inject(Store<BudgetSharedState>);

  project$: Observable<ProjectDetail> = this.store.select(selectProject);
  projectId$: Observable<number> = this.store.select(selectProjectId);
  budget$: Observable<Budget> = this.store.select(selectBudget);
  budgetId$: Observable<number> = this.store.select(selectBudgetId);
  settings$: Observable<BudgetSettings> = this.store.select(selectSettings);
  settingsIsLoaded$: Observable<boolean> = this.store.select(selectSettingsIsLoaded);
  isMainBudgetSelected$: Observable<boolean> = this.store.select(selectIsMainBudgetSelected);

  getUiSettings(settingsName: string): Observable<any> {
    return this.store.select(selectUiSettingsByName(settingsName));
  }

  getUiSettingsIsLoading(settingsName: string): Observable<boolean> {
    return this.store.select(selectUiSettingsIsLoadingByName(settingsName));
  }
}
