export enum SharingComboMode {
  AddUser = 0,
  EditProject = 1,
  EditDocument = 2,
  UnregisteredUser = 3,
  EditMembers = 4,
  EditBuildingProgress = 5
}

export enum NewSharingComboMode {
  Undefined = 0,
  AddUserProjectDocuments = 1,
  EditUserProject = 2,
  EditUserDocuments = 4,
  EditUserAllDocuments = 5,
  AddUserBuildingProgress = 8,
  EditUserBuildingProgress = 16,
  AddUserContractors = 17,
  EditBudget = 18,
  AddBudget = 19
}
