import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

import { AvatarModule, EshopModule, KrosMobileNavigationModule } from '@kros-sk/components';
import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { PipesModule } from '../pipes';
import { TranslateModule } from '../translate';
import { UserProfileComponent } from './components/user-profile-component/user-profile.component';
import { UserProfileService } from './user-profile.service';
import { UserShellComponent } from './components/user-shell-component/user-shell.component';

const declarations = [
  UserProfileComponent,
  UserShellComponent
];

@NgModule({
  imports: [
    CommonModule,
    NgbToastModule,
    AvatarModule,
    TooltipDirective,
    KrosMobileNavigationModule,
    PipesModule,
    EshopModule,
    TranslateModule.forChild()
  ],
  declarations: [...declarations],
  exports: [...declarations],
  providers: [
    UserProfileService
  ]
})
export class UserProfileModule { }
