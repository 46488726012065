import { Directive, Input, OnDestroy } from '@angular/core';

import { Observable } from 'rxjs';
import { SubSink } from 'subsink';

import { Markable } from './markable.interface';

@Directive({
  selector: '[markAllAsTouched]'
})
export class MarkAllAsTouchedInputDirective implements OnDestroy {
  @Input('markAllAsTouched') set setTrigger$(trigger: Observable<any>) {
    this.subs.sink = trigger?.subscribe(() => this.markAsTouched());
  }

  private markables: Markable[] = [];
  private subs = new SubSink();

  constructor() {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  registerMarkable(markable: Markable): void {
    this.markables.push(markable);
  }

  markAsTouched(): void {
    this.markables.forEach((markable: Markable) => {
      // if (markable.isInvalid()) {
      markable.markAsTouched();
      // }
    });
  }
}
