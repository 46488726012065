<div class="kros-modal-header">
  <h4 class="kros-modal-title">{{'BUILDING_PROGRESS.RANGE' | translate}}</h4>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onCloseClick()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div class="kros-modal-body">
  <app-budget-structure
    [selectedItemIds]="selectedItems"
    [indeterminateItemIds]="indeterminateItems"
    [isDefaultExpanded]="true"
    [hasCheckboxSelection]="true"
    [hasTooltips]="false"
    [colorizedObjects]="false"
    [showTypes]="['S', 'O', 'ZL']"
    (nodesCheckboxSelected)="onRangeSelected($event)"
    [data$]="selector.constructionData$"
  ></app-budget-structure>
</div>

<div class="kros-modal-footer">
  <button
    class="btn btn-primary btn-submit"
    id="ga-export-range"
    data-test="range-export-button"
    type="button"
    [disabled]="selectedItems.size === 0"
    (click)="onSubmit()"
  >{{'COMMON.APPLY' | translate}}</button>
</div>
