import { inject, Injectable } from '@angular/core';

import { APP_CONFIG } from '@kros-sk/app-config';
import { ApplicationType } from '@kros-sk/ssw-cdk';
import { BudgetPermissionType, BuildingProgressPermissionType, PermissionType } from '@kros-sk/ssw-shared/permission';

import { DocumentPermissionType } from '../../models';
import { SharingPermission, SharingPermissionMultipleItem } from '../models/sharing-permission.interface';
import { TranslateService } from '../../translate';

@Injectable()
export class PermissionsUtils {

  private appConfig = inject(APP_CONFIG);
  private translateService = inject(TranslateService);

  addUserProjectDocumentsPermissions(): SharingPermission[] {
    return Array.from([
      {
        permissions: {
          permission: PermissionType.Reader,
          additionalPermission: DocumentPermissionType.None
        },
        name: this.translateService.translate('SHARING.READER'),
        description: this.translateService.translate('SHARING.READER')
      },
      {
        permissions: {
          permission: PermissionType.Reader,
          additionalPermission: DocumentPermissionType.Downloader
        },
        name: this.translateService.translate('SHARING.DOWNLOADER'),
        description: this.translateService.translate('SHARING.DOWNLOADER')
      },
      {
        permissions: {
          permission: PermissionType.Contributor,
          additionalPermission: DocumentPermissionType.Downloader
        },
        name: this.translateService.translate('SHARING.CONTRIBUTOR'),
        description: this.translateService.translate('SHARING.CONTRIBUTOR')
      }
    ]);
  }

  editUserProjectPermissions(hasBudgetFeature: boolean): SharingPermissionMultipleItem[] {
    return [
      {
        name: this.translateService.translate(this.appConfig.location === 'cz' ? 'DOCUMENTS.TITLE_CZ' : 'DOCUMENTS.TITLE'),
        applicationType: ApplicationType.Documents,
        expectedOtherPermissions: [
          { applicationType: ApplicationType.Documents, permissions: [PermissionType.NoAccess] },
          { applicationType: ApplicationType.Budget, permissions: [PermissionType.Reader, PermissionType.Contributor] }
        ],
        warningText: this.translateService.translate(hasBudgetFeature ? 'SHARING.WARNING' : 'SHARING.QM.WARNING')
      },
      {
        name: this.translateService.translate(hasBudgetFeature ? 'SHARING.BUDGET_AND_QM' : 'QUANTITY_MANAGER.TITLE'),
        applicationType: ApplicationType.Budget
      },
      {
        name: this.translateService.translate('BUILDING_PROGRESS.TITLE'),
        applicationType: ApplicationType.BuildingProgress
      }
    ];
  }

  editUserDocumentsPermissions(indeterminateRight: boolean, allDocuments?: boolean): SharingPermission[] {
    const rights = Array.from([
      {
        permissions: {
          permission: PermissionType.Reader,
          additionalPermission: DocumentPermissionType.None
        },
        name: this.translateService.translate('SHARING.DOCUMENTS.READER'),
        description: this.translateService.translate('SHARING.DOCUMENTS.READER_DESCRIPTION')
      },
      {
        permissions: {
          permission: PermissionType.Reader,
          additionalPermission: DocumentPermissionType.Downloader
        },
        name: this.translateService.translate('SHARING.DOCUMENTS.DOWNLOADER'),
        description: this.translateService.translate('SHARING.DOCUMENTS.DOWNLOADER_DESCRIPTION')
      },
      {
        permissions: {
          permission: PermissionType.Contributor,
          additionalPermission: DocumentPermissionType.Downloader
        },
        name: this.translateService.translate('SHARING.DOCUMENTS.CONTRIBUTOR'),
        description: this.translateService.translate('SHARING.DOCUMENTS.CONTRIBUTOR_DESCRIPTION')
      }
    ]);

    if (indeterminateRight) {
      rights.push({
        permissions: {
          permission: PermissionType.Unidentified,
          additionalPermission: DocumentPermissionType.None
        },
        name: this.translateService.translate('SHARING.DOCUMENTS.INDETERMINATE'),
        description: allDocuments ? this.translateService.translate('SHARING.DOCUMENTS.INDETERMINATE_ALL_DOCUMENTS_DESCRIPTION') :
          this.translateService.translate('SHARING.DOCUMENTS.INDETERMINATE_DESCRIPTION')
      });
    }

    if (allDocuments) {
      rights.unshift(
        {
          permissions: {
            permission: PermissionType.NoAccess,
            additionalPermission: DocumentPermissionType.None
          },
          name: this.translateService.translate('SHARING.DOCUMENTS.NO_ACCESS'),
          description: this.translateService.translate('SHARING.DOCUMENTS.NO_ACCESS_DESCRIPTION')
        }
      );
    }
    return rights;
  }

  addUserBuildingProgressPermissions(
    permissionType?: PermissionType,
    additionalPermissionType?: BuildingProgressPermissionType
  ): SharingPermission[] {
    let rights = Array.from([
      {
        permissions: {
          permission: PermissionType.Reader,
          additionalPermission: BuildingProgressPermissionType.None
        },
        name: this.translateService.translate('SHARING.BUILDING_PROGRESS.READER'),
        description: this.translateService.translate('SHARING.BUILDING_PROGRESS.READER_DESCRIPTION')
      },
      {
        permissions: {
          permission: PermissionType.Contributor,
          additionalPermission: BuildingProgressPermissionType.Commenter
        },
        name: this.translateService.translate('SHARING.BUILDING_PROGRESS.CONTRIBUTOR'),
        description:
          this.translateService.translate(
            'SHARING.BUILDING_PROGRESS.CONTRIBUTOR_DESCRIPTION_' + this.appConfig.location.toUpperCase())
      },
      {
        permissions: {
          permission: PermissionType.Reader,
          additionalPermission: BuildingProgressPermissionType.CommenterExporterApproverFull
        },
        name: this.translateService.translate('SHARING.BUILDING_PROGRESS.APPROVER'),
        description: this.translateService.translate('SHARING.BUILDING_PROGRESS.APPROVER_DESCRIPTION')
      },
      {
        permissions: {
          permission: PermissionType.Reader,
          additionalPermission: BuildingProgressPermissionType.CommenterExporterApproverView
        },
        name: this.translateService.translate('SHARING.BUILDING_PROGRESS.CONTROLLER'),
        description: this.translateService.translate('SHARING.BUILDING_PROGRESS.CONTROLLER_DESCRIPTION')
      }
    ]);

    if (permissionType === PermissionType.Shared && (additionalPermissionType === BuildingProgressPermissionType.ContractorFull ||
      additionalPermissionType === BuildingProgressPermissionType.Contractor)) {
      rights = rights.concat(this.addUserContractorsPermissions());
    }
    return rights;
  }

  editBuildingProgressPermissions(
    permissionType: PermissionType,
    additionalPermissionType: BuildingProgressPermissionType
  ): SharingPermission[] {
    let rights = [
      {
        permissions: {
          permission: PermissionType.NoAccess,
          additionalPermission: BuildingProgressPermissionType.None
        },
        name: this.translateService.translate('SHARING.BUILDING_PROGRESS.NO_ACCESS'),
        description: this.translateService.translate('SHARING.BUILDING_PROGRESS.NO_ACCESS_DESCRIPTION')
      }
    ];

    if (permissionType === PermissionType.Shared && (additionalPermissionType === BuildingProgressPermissionType.ContractorFull ||
      additionalPermissionType === BuildingProgressPermissionType.Contractor)) {
      rights = rights.concat(this.addUserContractorsPermissions());
      return rights;
    }
    return rights.concat(this.addUserBuildingProgressPermissions());
  }

  editBudgetPermissions(hasBudgetFeature: boolean): SharingPermission[] {
    const rights = [
      {
        permissions: {
          permission: PermissionType.NoAccess,
          additionalPermission: BudgetPermissionType.None
        },
        name: this.translateService.translate('SHARING.BUDGET.NO_ACCESS'),
        description: this.translateService.translate(`SHARING.${hasBudgetFeature ? 'BUDGET' : 'QM'}.NO_ACCESS_DESCRIPTION`)
      }
    ].concat(this.addBudgetPermissions(hasBudgetFeature));
    return rights;
  }

  addBudgetPermissions(hasBudgetFeature: boolean): SharingPermission[] {
    return [
      {
        permissions: {
          permission: PermissionType.Reader,
          additionalPermission: BudgetPermissionType.None
        },
        name: this.translateService.translate('SHARING.BUDGET.READER'),
        description: this.translateService.translate(`SHARING.${hasBudgetFeature ? 'BUDGET' : 'QM'}.READER_DESCRIPTION`)
      },
      {
        permissions: {
          permission: PermissionType.Contributor,
          additionalPermission: BudgetPermissionType.None
        },
        name: this.translateService.translate('SHARING.BUDGET.CONTRIBUTOR'),
        description: this.translateService.translate(`SHARING.${hasBudgetFeature ? 'BUDGET' : 'QM'}.CONTRIBUTOR_DESCRIPTION`)
      }
    ];
  }

  addUserContractorsPermissions(): SharingPermission[] {
    return [{
      permissions: {
        permission: PermissionType.Shared,
        additionalPermission: BuildingProgressPermissionType.ContractorFull
      },
      name: this.translateService.translate('SHARING.SUBCONTRACTORS.SUBCONTRACTOR_FULL'),
      description: this.translateService.translate('SHARING.SUBCONTRACTORS.SUBCONTRACTOR_DESCRIPTION')
    },
    {
      permissions: {
        permission: PermissionType.Shared,
        additionalPermission: BuildingProgressPermissionType.Contractor
      },
      name: this.translateService.translate('SHARING.SUBCONTRACTORS.SUBCONTRACTOR'),
      description: this.translateService.translate('SHARING.SUBCONTRACTORS.SUBCONTRACTOR_DESCRIPTION')
    }];
  }
}
