import { createAction, props } from '@ngrx/store';

import { BudgetItem, ChangedBudgetItems, Database, OccurrenceItemIndex } from '@kros-sk/ssw-shared-legacy';

import { TotalPriceActionType, UpdateOtherData } from '../models';

export const editBudgetKey = '[Edit_budget]';

export const fetchDatabasesStart = createAction(`${editBudgetKey} Fetch databases start`);
export const fetchDatabasesSuccess = createAction(`${editBudgetKey} Fetch databases success`, props<{ databases: Database[] }>());
export const fetchDatabasesError = createAction(`${editBudgetKey} Fetch databases error`, props<{ error: string }>());

export const setPriceIndexStart = createAction(
  `${editBudgetKey} Set price index start`,
  props<{
    priceIndex: number,
    isMultiplication: boolean,
    selectedItem: BudgetItem,
    selectedItems: BudgetItem[],
    budgetIsInSearchMode: boolean
  }>());
export const setPriceIndexSuccess = createAction(
  `${editBudgetKey} Set price index success`,
  props<{ changedBudgetItems: ChangedBudgetItems, occurrenceItems: OccurrenceItemIndex[] }>());
export const setPriceIndexError = createAction(`${editBudgetKey} Set price index error`, props<{ error: any }>());

export const editTotalPriceStart = createAction(
  `${editBudgetKey} Edit total price start`,
  props<{
    actionType: TotalPriceActionType,
    value: number,
    selectedItem: BudgetItem,
    selectedItems: BudgetItem[],
    budgetIsInSearchMode: boolean
  }>());
export const editTotalPriceSuccess = createAction(
  `${editBudgetKey} Edit total price success`,
  props<{ changedBudgetItems: ChangedBudgetItems, occurrenceItems: OccurrenceItemIndex[] }>());
export const editTotalPriceError = createAction(`${editBudgetKey} Edit total price error`, props<{ error: any }>());

export const setUpdateOtherDataForm = createAction(
  `${editBudgetKey} Set update other data form`,
  props<{ updateOtherData: UpdateOtherData }>());

export const setAreOtherDataUsed = createAction(`${editBudgetKey} Set are other data used`, props<{ areOtherDataUsed: boolean }>());
