import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Component, LOCALE_ID, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';
import { BuildingProgressSettings } from '@kros-sk/ssw-shared/settings';
import { ExportFileType, KrosApiBlobStorageService, TranslateService } from '@kros-sk/ssw-shared-legacy';
import { KrosModalRef, KrosModalService } from '@kros-sk/components';

import { BuildingProgressActionAccessService } from '../../../services/building-progress-action-access.service';
import {
  BuildingProgressExportCompletedWorksComponent
} from '../building-progress-export-completed-works/building-progress-export-completed-works.component';
import { BuildingProgressExportSummaryComponent } from '../building-progress-export-summary/building-progress-export-summary.component';
import {
  BuildingProgressInvoiceDetailExportModel,
  CompletedWorksExportTypeEnum,
  CoverSheetExportTypeEnum
} from '../../../models/building-progress-export.model';
import { BuildingProgressPeriod } from '../../../models/building-progress-period.model';
import { BuildingProgressSelectorsService } from '../../../../store/building-progress';
import { environment } from '../../../../../environments/environment';
import { ExportAnalyticsHelper } from '../../../helpers/export-analytics.helper';
import { getAppInsightsPrefix } from '../../../shared/building-progress-utils';
import { openHeaderSettingsDialog } from '../building-progress-export-helpers/building-progress-export-helpers';
import { PermissionHelper } from '../../../helpers';
import { ProjectsDispatchersService } from '../../../../store/projects';
import { RangeModalComponent } from '../../../shared/range-modal/range-modal.component';

const userServiceApi = '/api/userService';

@Component({
  selector: 'app-building-progress-export-invoice-details',
  templateUrl: './building-progress-export-invoice-details.component.html',
  styleUrls: ['./building-progress-export-invoice-details.component.scss'],
  providers: [
    { provide: LOCALE_ID, useValue: environment.defaultLanguage },
    ExportAnalyticsHelper
  ]
})
export class BuildingProgressExportInvoiceDetailsComponent extends UnsubscribeComponent implements OnInit {
  coverSheetExportTypeEnum = CoverSheetExportTypeEnum;
  exportModel: BuildingProgressInvoiceDetailExportModel;
  periods: BuildingProgressPeriod[];
  settings: BuildingProgressSettings;
  exportForm: UntypedFormGroup;
  summaryPeriods = false;
  exportPeriodRange = false;
  exportFileType = ExportFileType;
  canExportLogo = true;
  canExportStamp = true;

  private rangeSelectedItems: Set<number> = new Set<number>();
  private rangeIndeterminateItems: Set<number> = new Set<number>();
  private isCompletedWorksObjectsDisabled = false;

  get arePeriodsEmpty(): boolean {
    return this.periods?.length === 0;
  }

  get rangeLabel(): string {
    const buildingObjectIdsLength = this.exportForm.get('buildingObjectIds').value.length;
    return buildingObjectIdsLength === 0 ? this.translateService.translate('BUILDING_PROGRESS.WHOLE_BUILDING') :
      `${this.translateService.translate('BUILDING_PROGRESS.SELECTED_OBJECTS')} (${buildingObjectIdsLength})`;
  }

  get isCzechVersion(): boolean {
    return environment.location === 'cz';
  }

  get isContractor(): boolean {
    return this.permissionHelper.isContractor;
  }

  constructor(
    private buildingProgressSelectorsService: BuildingProgressSelectorsService,
    private projectsDispatcherService: ProjectsDispatchersService,
    private formBuilder: UntypedFormBuilder,
    private krosModalService: KrosModalService,
    private modalRef: KrosModalRef,
    private translateService: TranslateService,
    private datePipe: DatePipe,
    private exportAnalyticsHelper: ExportAnalyticsHelper,
    private appInsightsService: AppInsightsBaseService,
    private permissionHelper: PermissionHelper,
    private buildingProgressActionAccessService: BuildingProgressActionAccessService,
    private krosApiBlobService: KrosApiBlobStorageService
  ) {
    super();

    this.exportModel = this.modalRef.data.exportModel;
    this.periods = this.modalRef.data.periods;
  }

  ngOnInit(): void {
    this.subs.sink = this.buildingProgressSelectorsService.settings$
      .subscribe(settings => this.settings = settings);

    this.initializeExportForm();
  }

  private initializeExportForm(): void {
    this.exportForm = this.formBuilder.group(
      {
        periodIds: [[this.exportModel.periodIds[0]]],
        buildingObjectIds: [[]],
        isIncludedCoverSheet: [false],
        coverSheetExportType: [{
          value: this.exportModel.coverSheetExportType,
          disabled: true
        }],
        isIncludedConfirmationProtocol: [this.exportModel.isIncludedConfirmationProtocol],
        isIncludedBuildingObjectsSummary: [this.exportModel.isIncludedBuildingObjectsSummary],
        isIncludedCompletedWorks: [this.exportModel.isIncludedCompletedWorks],
        exportDate: [new Date(), Validators.required],
        invoiceNumber: [{
          value: this.exportModel.invoiceNumber,
          disabled: true
        }],
        isLogoIncluded: [this.exportModel.generalSettings.isLogoIncluded],
        isStampIncluded: [this.exportModel.generalSettings.isStampIncluded]
      },
      { validators: this.formValidator }
    );

    this.subs.sink = this.exportForm.get('isIncludedCoverSheet').valueChanges
      .subscribe(value => {
        const coverSheetExportTypeControl = this.exportForm.get('coverSheetExportType');
        if (value) {
          coverSheetExportTypeControl.enable();
        } else {
          coverSheetExportTypeControl.disable();
          coverSheetExportTypeControl.setValue(this.coverSheetExportTypeEnum.Summary);
        }
      });
    this.subs.sink = this.exportForm.get('isIncludedConfirmationProtocol').valueChanges
      .subscribe(value => {
        const invoiceNumber = this.exportForm.get('invoiceNumber');
        if (value) {
          invoiceNumber.enable();
        } else {
          invoiceNumber.disable();
          invoiceNumber.setValue('');
        }
      });
    this.subs.sink = this.exportForm.get('buildingObjectIds').valueChanges
      .subscribe(value => {
        if (value.length !== 0) {
          this.isCompletedWorksObjectsDisabled = true;
          this.exportModel.completedWorksSettings.completedWorksExportType = CompletedWorksExportTypeEnum.BuildingObjects;
        } else {
          this.isCompletedWorksObjectsDisabled = false;
        }
      });
    this.subs.sink = this.exportForm.get('isStampIncluded').valueChanges
      .subscribe(v => {
        if (v && !this.canExportStamp) {
          this.exportForm.get('isStampIncluded').setValue(false);
        }
      });
    this.subs.sink = this.exportForm.get('isLogoIncluded').valueChanges
      .subscribe(v => {
        if (v && !this.canExportLogo) {
          this.exportForm.get('isLogoIncluded').setValue(false);
        }
      });
    this.subs.sink = this.krosApiBlobService.getDownloadToken(`${environment.gatewayApiUrl}${userServiceApi}/Logo`)
      .subscribe(azureCredentials => this.canExportLogo = azureCredentials?.name !== '');
    this.subs.sink = this.krosApiBlobService.getDownloadToken(`${environment.gatewayApiUrl}${userServiceApi}/Stamp`)
      .subscribe(azureCredentials => this.canExportStamp = azureCredentials?.name !== '');
  }

  private formValidator(formGroup: AbstractControl): ValidationErrors {
    const atLeastOneExportChecked =
      formGroup.get('isIncludedCoverSheet').value ||
      formGroup.get('isIncludedConfirmationProtocol').value ||
      formGroup.get('isIncludedCompletedWorks').value ||
      formGroup.get('isIncludedBuildingObjectsSummary').value;
    const periodSelected = !!formGroup.get('periodIds').value && formGroup.get('periodIds').value.length > 0;
    return atLeastOneExportChecked && periodSelected && formGroup.get('exportDate').valid ?
      null :
      { atLeastOneExportChecked, periodSelected, invalidDate: !formGroup.get('exportDate').valid };
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  openRangeModal(): void {
    const modalRef = this.krosModalService.openCentered(
      RangeModalComponent,
      {
        selectedItems: this.rangeSelectedItems,
        indeterminateItems: this.rangeIndeterminateItems
      },
      {
        addModalToBrowsersHistory: false,
        closeOnEscClick: true
      }
    );

    this.subs.sink = modalRef.afterClosed$
      .subscribe(result => {
        if (result.type === 'submit') {
          this.rangeSelectedItems = result.data.selectedItems;
          this.rangeIndeterminateItems = result.data.indeterminateItems;
          const selectedBuildingObjectIds = [];
          this.rangeSelectedItems.forEach(id => {
            if (!this.rangeIndeterminateItems.has(id)) {
              selectedBuildingObjectIds.push(id);
            }
          });
          this.exportForm.get('buildingObjectIds').setValue(selectedBuildingObjectIds);
          this.appInsightsService.trackEvent(`${getAppInsightsPrefix(this.isContractor)}export`
            + `${this.exportModel.subcontractorId ? '-contractors' : ''}` + '-invoice-range');
        }
      });
  }

  openCompletedWorksSettingsModal(): void {
    const modalRef = this.krosModalService.openCentered(
      BuildingProgressExportCompletedWorksComponent,
      {
        completedWorksSettings: this.exportModel.completedWorksSettings,
        isCompletedWorksObjectsDisabled: this.isCompletedWorksObjectsDisabled,
        subcontractorId: this.exportModel.subcontractorId,
      },
      {
        addModalToBrowsersHistory: false
      }
    );

    this.subs.sink = modalRef.afterClosed$
      .subscribe(result => {
        if (result.type === 'submit' && result.data) {
          this.exportModel.completedWorksSettings = result.data.completedWorksSettings;
          this.exportForm.get('isIncludedCompletedWorks').setValue(true);
        }
      });
  }

  openSummarySettingsModal(): void {
    const modalRef = this.krosModalService.openCentered(
      BuildingProgressExportSummaryComponent,
      {
        summarySettings: this.exportModel.summarySettings ?? {},
        subcontractorId: this.exportModel.subcontractorId,
      },
      {
        addModalToBrowsersHistory: false
      }
    );

    this.subs.sink = modalRef.afterClosed$
      .subscribe(result => {
        if (result.type === 'submit' && result.data) {
          this.exportModel.summarySettings = result.data.summarySettings;
          this.exportForm.get('isIncludedBuildingObjectsSummary').setValue(true);
        }
      });
  }

  onHeaderDataEdit(): void {
    this.subs.sink = openHeaderSettingsDialog(
      this.krosModalService,
      this.projectsDispatcherService,
      this.permissionHelper,
      this.appInsightsService,
      this.modalRef.data.exportModel.projectId,
      this.isContractor,
      this.modalRef.data.exportModel.subcontractorId
    );
  }

  onSubmit(exportFileType?: ExportFileType): void {
    if (exportFileType !== ExportFileType.Xlsx || this.buildingProgressActionAccessService.checkForAccessToExcelFormatExports()) {
      this.copyExportModelFromFormControl();
      this.exportAnalyticsHelper.sendInvoiceAnalyticsNotification(
        {
          ...this.exportModel,
          exportFileType
        });
      this.modalRef.submit({
        ...this.exportModel,
        exportFileType
      });
    }
  }

  getConfirmationProtocolIncludedControlValue(): boolean {
    return this.exportForm.get('isIncludedConfirmationProtocol').value;
  }

  getSummaryIncludedControlValue(): boolean {
    return this.exportForm.get('isIncludedBuildingObjectsSummary').value;
  }

  getCoverSheetIncludedControlValue(): boolean {
    return this.exportForm.get('isIncludedCoverSheet').value;
  }

  getCompletedWorksIncludedControlValue(): boolean {
    return this.exportForm.get('isIncludedCompletedWorks').value;
  }

  private copyExportModelFromFormControl(): void {
    const formControls = this.exportForm.controls;
    this.exportModel.periodIds = formControls.periodIds.value;
    this.exportModel.buildingObjectIds = formControls.buildingObjectIds.value;

    this.exportModel.isIncludedConfirmationProtocol = formControls.isIncludedConfirmationProtocol.value;
    this.exportModel.isIncludedBuildingObjectsSummary = formControls.isIncludedBuildingObjectsSummary.value;
    this.exportModel.coverSheetExportType = formControls.isIncludedCoverSheet.value
      ? formControls.coverSheetExportType.value
      : CoverSheetExportTypeEnum.None;
    this.exportModel.isIncludedCompletedWorks = formControls.isIncludedCompletedWorks.value;
    this.exportModel.exportDate = this.datePipe.transform(formControls.exportDate.value, 'yyyy-MM-dd');
    this.exportModel.invoiceNumber = formControls.invoiceNumber.value;
    this.exportModel.generalSettings.isLogoIncluded = formControls.isLogoIncluded.value;
    this.exportModel.generalSettings.isStampIncluded = formControls.isStampIncluded.value;
  }
}
