import { Injectable } from '@angular/core';

import { Observable, Subject, ReplaySubject, BehaviorSubject } from 'rxjs';

import { DropdownNavItem } from './dropdown-nav-item.model';
import { NewsData } from './news-data.model';

@Injectable()
export class NavbarService {
  titleChanged = new ReplaySubject<string>(1);
  backButtonUrlChanged = new Subject<string>();
  desktopDropdownItemsChanged = new Subject<DropdownNavItem[]>();
  mobileDropdownItemsChanged = new Subject<DropdownNavItem[]>();
  newsDataChanged = new Subject<NewsData>();
  loadProjects$ = new Subject<void>();
  projectsLabelChanged$ = new Subject<string>();
  projectSelectorVisibilityChanged$ = new BehaviorSubject<boolean>(true);

  get homeApplicationUrlChanged$(): Observable<string> {
    return this.homeAppUrlChanged.asObservable();
  }

  private homeAppUrlChanged = new Subject<string>();

  constructor() { }

  changeTitle(newTitle: string): void {
    this.titleChanged.next(newTitle);
  }

  changeBackButtonUrl(newUrl: string): void {
    this.backButtonUrlChanged.next(newUrl);
  }

  setDesktopDropdown(items: DropdownNavItem[]): void {
    this.desktopDropdownItemsChanged.next(items);
  }

  setMobileDropdown(items: DropdownNavItem[]): void {
    this.mobileDropdownItemsChanged.next(items);
  }

  setNewsData(data: NewsData): void {
    this.newsDataChanged.next(data);
  }

  setHomeApplicationUrl(url: string): void {
    this.homeAppUrlChanged.next(url);
  }

  loadProjects(): void {
    this.loadProjects$.next();
  }

  setProjectsLabel(label: string): void {
    this.projectsLabelChanged$.next(label);
  }

  setProjectSelectorVisibility(visible: boolean): void {
    this.projectSelectorVisibilityChanged$.next(visible);
  }
}
