import { BillOfQuantitiesItemType, BoqItem } from '../../models';
import { BoqMeasure } from '../models';

export const quantityTagName = 'k-m';
export const perimeterTagName = 'k-p';
export const perimeterHeightTagName = 'k-ph';
export const heightTagName = 'k-h';
export const areaHeightTagName = 'k-ah';
export const boqTagName = 'k-b';
export const expressionFieldName = 'expression';
export const quantityColumnIndex = 2;

export const measurementTags = [
  quantityTagName, perimeterTagName, perimeterHeightTagName, heightTagName, areaHeightTagName
];

export const getTagNameByMeasurementColumnIndex = (columnIndex: number): string => {
  switch (columnIndex) {
    case quantityColumnIndex: return quantityTagName;
    case 4: return perimeterTagName;
    case 6: return heightTagName;
    case 7: return perimeterHeightTagName;
    case 8: return areaHeightTagName;
  }
  return null;
};

export const getMeasurementColumnIndexByTagName = (tagName: string): number => {
  switch (tagName) {
    case quantityTagName: return quantityColumnIndex;
    case perimeterTagName: return 4;
    case heightTagName: return 6;
    case perimeterHeightTagName: return 7;
    case areaHeightTagName: return 8;
  }
  return null;
};

export const unwrapTags = (value: string, tags: string[]): string => {
  let unwrappedValue = value;

  tags.forEach((tag, index) => {
    unwrappedValue = unwrappedValue?.replace(new RegExp(`<${tag} contenteditable="false">`, 'g'), `###${index}`)
      .replace(new RegExp(`</${tag}>`, 'g'), `---${index}`);
  });

  unwrappedValue = unwrappedValue?.replace(/&nbsp;/g, ' ').trimEnd()
    .replace(/\ufeff/ig, '')
    .replace(/<[^>]*>/ig, '');

  tags.forEach((tag, index) => {
    unwrappedValue = unwrappedValue?.replace(new RegExp(`###${index}`, 'g'), `<${tag}>`)
      .replace(new RegExp(`---${index}`, 'g'), `</${tag}>`);
  });

  return unwrappedValue;
};

export const wrapTags = (value: string, tags: string[], isFirefox: boolean): string => {
  let wrappedValue = value;
  const empty = isFirefox ? '\ufeff' : '';

  tags.forEach(tag => {
    wrappedValue = wrappedValue?.replace(new RegExp(`<${tag}>`, 'g'), `${empty}<${tag} contenteditable="false">`);
  });

  return `\ufeff${wrappedValue?.replace(/\+/g, '+<wbr>').replace(/\*/g, '*<wbr>')}\ufeff`;
};

export const cancelNextClick = (): void => {
  document.addEventListener('click', captureClick, true);
};

const captureClick = (e): void => {
  e.stopPropagation();
  document.removeEventListener('click', captureClick, true);
};

export const substituteExpressionByValues = (measurementsData: BoqMeasure[], expression: string): string => {
  let ret = expression;
  ret = substituteMeasurementIdByValue(measurementsData, ret, quantityTagName, 'quantity');
  ret = substituteMeasurementIdByValue(measurementsData, ret, perimeterTagName, 'perimeter');
  ret = substituteMeasurementIdByValue(measurementsData, ret, heightTagName, 'height');
  ret = substituteMeasurementIdByValue(measurementsData, ret, perimeterHeightTagName, 'perimeterHeight');
  ret = substituteMeasurementIdByValue(measurementsData, ret, areaHeightTagName, 'areaHeight');

  return ret;
};

export const substituteExpressionByValuesForHeightDependsFields = (measurementsData: BoqMeasure[], expression: string): string => {
  let ret = expression;
  ret = substituteMeasurementIdByValue(measurementsData, ret, heightTagName, 'height');
  ret = substituteMeasurementIdByValue(measurementsData, ret, perimeterHeightTagName, 'perimeterHeight');
  ret = substituteMeasurementIdByValue(measurementsData, ret, areaHeightTagName, 'areaHeight');

  return ret;
};

const substituteMeasurementIdByValue = (measurementsData: BoqMeasure[], expression: string, tagName: string, fieldName: string): string => {
  return expression.replace(
    new RegExp(`<${tagName}>(\\d+)</${tagName}>`, 'gi'),
    (s, id) => {
      const m = measurementsData.find(m => m.measurementId === +id);
      return m ? `${m[fieldName]}`.replace('.', ',') : s;
    });
};

export const isElementInTagWithClassName = (element: HTMLElement, ignoreClassNames: string[], forceClassName: string): boolean => {
  if (element && element.tagName.toLowerCase() !== 'body') {
    if (ignoreClassNames.some(ignoreClassName => element.classList.contains(ignoreClassName))) {
      return true;
    } else if (element.classList.contains(forceClassName)) {
      return false;
    }
    return isElementInTagWithClassName(element.parentElement, ignoreClassNames, forceClassName);
  }
  return false;
};

export const getLastRow = (items: BoqItem[]): number => {
  let lastRow = items.length - 1;
  while (items[lastRow].expression === '') {
    lastRow--;
  }
  return lastRow;
};

export const getLastIndexOfDividerChar = (value: string): number => {
  return Math.max(
    value.lastIndexOf('+'),
    value.lastIndexOf('-'),
    value.lastIndexOf('*'),
    value.lastIndexOf('/'),
    value.lastIndexOf('>'),
    value.lastIndexOf('<'),
    value.lastIndexOf('('),
    value.lastIndexOf(')'));
};

export const getBoqCellClass = (itemType: BillOfQuantitiesItemType): string => {
  switch (itemType) {
    case BillOfQuantitiesItemType.Note:
      return 'color-orange';
    case BillOfQuantitiesItemType.Subtotal:
      return 'color-blue';
    case BillOfQuantitiesItemType.Total:
      return 'color-red';
  }
};

export const appendExpression = (values: (Object | string)[]): string => {
  const expressionValues = values.map(value => (typeof value === 'string') ? value : value[expressionFieldName]);
  return expressionValues.length > 1 ? `(${expressionValues.join('+')})` : expressionValues.join('+');
};

export function forceEditMode(editor: HTMLElement): void {
  editor.focus();
  moveCaretToEnd(editor);
}

function moveCaretToEnd(editor: HTMLElement): void {
  if (editor.tagName.toLowerCase() === 'p') {
    const range = document.createRange();
    range.selectNodeContents(editor);
    range.collapse(false);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  }
}
