import { Component, inject } from '@angular/core';

import { KrosModalRef } from '@kros-sk/components';

import { TranslateModule } from '../../translate';

@Component({
  standalone: true,
  selector: 'kros-school-license-forbidden-modal',
  templateUrl: './school-license-forbidden-modal.component.html',
  styleUrls: [ './school-license-forbidden-modal.component.scss' ],
  imports: [TranslateModule]
})
export class SchoolLicenseForbiddenModalComponent {
  private readonly modalRef = inject(KrosModalRef);

  onCancel(): void {
    this.modalRef.cancel();
  }
}
