import { Injectable } from '@angular/core';

import { take } from 'rxjs/operators';

import { BuildingObjectType, Cell, InfoModalComponent, QuestionModalComponent, TranslateService } from '@kros-sk/ssw-shared-legacy';
import { formatString } from '@kros-sk/ssw-cdk';
import { KrosModalService } from '@kros-sk/components';

import { BuildingProgressActionAccessService } from './building-progress-action-access.service';
import { BuildingProgressDispatchersService } from '../../store/building-progress/building-progress-dispatchers.service';
import { BuildingProgressItem, BuildingProgressModel } from '../models/construction-data.model';
import { BuildingProgressPeriod } from '../models/building-progress-period.model';
import { BuildingProgressSelectorsService } from '../../store/building-progress/building-progress-selectors.service';
import { DeleteItemModel } from '../models/item-list-delete.model';
import { isPeriodEditable } from '../helpers';

@Injectable()
export class BuildingProgressDeleteItemsService {
  private selectedItemIds = new Set<number>();

  constructor(
    private dispatchersService: BuildingProgressDispatchersService,
    private actionAccessService: BuildingProgressActionAccessService,
    private selectorsService: BuildingProgressSelectorsService,
    private translateService: TranslateService,
    private krosModalService: KrosModalService
  ) {
    this.selectorsService.selectedItemIds$.subscribe(
      itemIds => {
        if (itemIds && itemIds instanceof Set) {
          this.selectedItemIds = itemIds;
        }
      }
    );
  }

  onDeleteBuildingProgress(constructionData: BuildingProgressModel, focusedCell: Cell): void {
    const areSelected = this.selectedItemIds.size > 0;
    const item = this.getFocusedItem(constructionData.items, focusedCell);
    const budgetItemIds = areSelected
      ? Array.from(this.selectedItemIds.values())
      : this.getItemWithChildrenIds(item, constructionData.items);

    const containsRoot = this.containsRoot(budgetItemIds, constructionData);

    const modalRef = this.krosModalService.openCentered(
      QuestionModalComponent,
      {
        warning: containsRoot ? this.translateService.translate('BUILDING_PROGRESS.DELETE.WARNING') : null,
        body: this.getDeleteMessage(item.itemType, item.description, areSelected, containsRoot),
        confirmButton: this.translateService.translate('COMMON.DELETE')
      },
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: false,
        showMobileArrowBack: false
      },
      NaN,
      NaN,
      undefined,
      'no-min-width',
    );

    modalRef.afterClosed$.pipe(take(1)).subscribe(result => {
      if (result.type === 'submit') {
        if (containsRoot || this.canDeleteItems(budgetItemIds, constructionData.periods, constructionData.items)) {
          this.deleteItems(constructionData, budgetItemIds, areSelected);
        } else {
          this.openInfoDialog();
        }
      }
    });
  }

  openInfoDialog(): void {
    this.krosModalService.openCentered(
      InfoModalComponent,
      {
        body: this.translateService.translate('BUILDING_PROGRESS.DELETE.CANNOT')
      },
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: false,
        showMobileArrowBack: false
      }
    );
  }

  getItemWithChildrenIds(item: BuildingProgressItem, items: BuildingProgressItem[]): number[] {
    const ret: number[] = [item.id];
    let actualIndex = items.findIndex(i => i.id === item.id) + 1;

    while (items.length > actualIndex && items[actualIndex].level > item.level) {
      ret.push(items[actualIndex++].id);
    }

    return ret;
  }

  private getFocusedItem(items: BuildingProgressItem[], focusedCell: Cell): BuildingProgressItem {
    return focusedCell ? items[focusedCell.rowIndex] : items.find(i => i.itemType === 'S');
  }

  private containsRoot(budgetItemIds: number[], constructionData: BuildingProgressModel): boolean {
    const rootId = this.getRootItem(constructionData.items);
    return budgetItemIds.some(i => i === rootId);
  }

  private getRootItem(items: BuildingProgressItem[]): number {
    return items.find(i => i.itemType === 'S').id;
  }

  private getDeleteMessage(itemType: string, itemDescription: string, areItemsSelected: boolean, includeRoot: boolean): string {
    if (includeRoot) {
      return formatString(this.translateService.translate('BUILDING_PROGRESS.DELETE.BUILDING'), itemDescription);
    }
    if (areItemsSelected) {
      return this.translateService.translate('BUILDING_PROGRESS.DELETE.SELECTED');
    } else {
      switch (itemType) {
        case 'ZL':
        case 'O': return formatString(this.translateService.translate('BUILDING_PROGRESS.DELETE.CONTRACT'), itemDescription);
        case 'D': return formatString(this.translateService.translate('BUILDING_PROGRESS.DELETE.PART'), itemDescription);
        case 'K':
        case 'M': return formatString(this.translateService.translate('BUILDING_PROGRESS.DELETE.ITEM'), itemDescription);
      }
    }
  }

  private canDeleteItems(deleteItemIds: number[], periods: BuildingProgressPeriod[], items: BuildingProgressItem[]): boolean {
    const lastPeriodId = this.getLastPeriodId(periods);
    return !deleteItemIds.some(i => {
      const item = items.find(item => item.id === i);

      if (!item) {
        return false;
      }

      if (item.isNotCompleted || item.hasPartialBudgetItems) {
        return true;
      }

      if (periods.length > 0 && !this.isAdditionOrChangeSheet(item.buildingObjectType)) {
        return periods.some(p =>
          item.periods.find(i => i.totalPrice !== 0 &&
            i.periodId === p.id &&
            isPeriodEditable(p))) ||
          item.periods.some(p => p.totalPrice !== 0 && p.periodId !== lastPeriodId);
      }
      return this.isAdditionOrChangeSheet(item.buildingObjectType);
    });
  }

  private isAdditionOrChangeSheet(buildingObjectType: BuildingObjectType): boolean {
    return buildingObjectType === BuildingObjectType.Addition || buildingObjectType === BuildingObjectType.ChangeSheet;
  }

  private getLastPeriodId(periods: BuildingProgressPeriod[]): number {
    return periods.length > 0 ? periods[periods.length - 1].id : null;
  }

  private deleteItems(constructionData: BuildingProgressModel, budgetItemIds: number[], areSelected: boolean): void {
    const deleteItemsModel: DeleteItemModel = {
      budgetItemIds
    };

    if (!areSelected || this.actionAccessService.checkForAccessToBulkOperations()) {
      this.dispatchersService.deleteItem(constructionData.projectId, deleteItemsModel);
    }

    if (areSelected) {
      this.dispatchersService.clearMultiSelectItems();
    }
  }
}
