import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class HelpService {

  get showVideoTutorialChanged$(): Observable<boolean> {
    return this.showVideoTutorialChanged.asObservable();
  }

  get showFaqChanged$(): Observable<boolean> {
    return this.showFaqChanged.asObservable();
  }

  get showHelpChanged$(): Observable<boolean> {
    return this.showHelpChanged.asObservable();
  }

  get showTourChanged$(): Observable<boolean> {
    return this.showTourChange.asObservable();
  }

  get showIfcManualChanged$(): Observable<boolean> {
    return this.showIfcManualChanged.asObservable();
  }

  get showContactsChanged$(): Observable<boolean> {
    return this.showContactsChanged.asObservable();
  }

  get faqLinkChanged$(): Observable<string> {
    return this.faqLinkChanged.asObservable();
  }

  get videoTutorialUrlChanged$(): Observable<string> {
    return this.videoTutorialUrl.asObservable();
  }

  private showVideoTutorialChanged = new BehaviorSubject<boolean>(true);
  private showFaqChanged = new BehaviorSubject<boolean>(true);
  private showHelpChanged = new BehaviorSubject<boolean>(true);
  private showTourChange = new BehaviorSubject<boolean>(true);
  private showIfcManualChanged = new BehaviorSubject<boolean>(true);
  private showContactsChanged = new BehaviorSubject<boolean>(true);
  private faqLinkChanged = new BehaviorSubject<string>(undefined);
  private videoTutorialUrl = new BehaviorSubject<string>(undefined);

  constructor() { }

  changeShowVideoTutorial(show: boolean): void {
    this.showVideoTutorialChanged.next(show);
  }

  changeShowHelp(show: boolean): void {
    this.showHelpChanged.next(show);
  }

  changeShowTour(show: boolean): void {
    this.showTourChange.next(show);
  }

  changeShowIfcManual(show: boolean): void {
    this.showIfcManualChanged.next(show);
  }

  changeShowContacts(show: boolean): void {
    this.showContactsChanged.next(show);
  }

  changeShowFaq(show: boolean): void {
    this.showFaqChanged.next(show);
  }

  changeFaqLink(link: string): void {
    this.faqLinkChanged.next(link);
  }

  changeVideoTutorialUrl(link: string): void {
    this.videoTutorialUrl.next(link);
  }
}
