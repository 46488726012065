import { coerceNumberProperty, NumberInput } from '@angular/cdk/coercion';
import { Directive, forwardRef, Input, OnChanges } from '@angular/core';
import { CdkVirtualScrollViewport, VirtualScrollStrategy, VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';

import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';


export class KrosVirtualScrollStrategy implements VirtualScrollStrategy {
  _scrolledIndexChange = new Subject<number>();
  scrolledIndexChange: Observable<number> = this._scrolledIndexChange.pipe(distinctUntilChanged());

  private _viewport: CdkVirtualScrollViewport | null = null;
  private _itemSize: number;
  private _minBufferPx: number;
  private _maxBufferPx: number;
  private updateRenderedRangeTimeout: NodeJS.Timeout;
  private itemsOffset: number[];
  private scrollToIndexParams: any;

  constructor(itemSize: number, minBufferPx: number, maxBufferPx: number, itemsOffset: number[]) {
    this._itemSize = itemSize;
    this._minBufferPx = minBufferPx;
    this._maxBufferPx = maxBufferPx;
    this.itemsOffset = itemsOffset;
  }

  setItemsOffset(itemsOffset: number[]): void {
    this.itemsOffset = itemsOffset;
  }

  attach(viewport: CdkVirtualScrollViewport): void {
    this._viewport = viewport;
    this._updateTotalContentSize();
    this._updateRenderedRange();
    setTimeout(() => {
      if (this.scrollToIndexParams) {
        this.scrollToIndex(this.scrollToIndexParams.index, this.scrollToIndexParams.behavior);
        this.scrollToIndexParams = undefined;
      }
    }, 0);
  }

  detach(): void {
    this._scrolledIndexChange.complete();
    this.clearUpdateRenderedRangeTimeout();
    this._viewport = null;
    this.scrollToIndexParams = undefined;
  }

  updateItemAndBufferSize(itemSize: number, minBufferPx: number, maxBufferPx: number): void {
    if (maxBufferPx < minBufferPx) {
      throw Error('CDK virtual scroll: maxBufferPx must be greater than or equal to minBufferPx');
    }

    this._itemSize = itemSize;
    this._minBufferPx = minBufferPx;
    this._maxBufferPx = maxBufferPx;
    this._updateTotalContentSize();
    this._updateRenderedRange();
  }

  onContentScrolled(): void {
    this._updateRenderedRange();
  }

  onDataLengthChanged(): void {
    this._updateTotalContentSize();
    this._updateRenderedRange();
  }

  onContentRendered(): void { }

  onRenderedOffsetChanged(): void { }

  scrollToIndex(index: number, behavior: ScrollBehavior): void {
    if (this._viewport) {
      this._viewport.scrollToOffset(this.getOffsetForIndex(index), behavior);
    } else {
      this.scrollToIndexParams = { index, behavior };
    }
  }

  private _updateTotalContentSize(): void {
    if (!this._viewport) {
      return;
    }

    this._viewport.setTotalContentSize(this.getOffsetForIndex(this.itemsOffset.length));
  }

  private _updateRenderedRange(): void {
    if (!this._viewport) {
      return;
    }

    this.clearUpdateRenderedRangeTimeout();

    this.updateRenderedRangeTimeout = setTimeout(() => {
      this.updateRenderedRangeTimeout = undefined;
      this.doUpdateRenderedRange();
    }, 50);
  }

  private clearUpdateRenderedRangeTimeout(): void {
    if (this.updateRenderedRangeTimeout) {
      clearTimeout(this.updateRenderedRangeTimeout);
      this.updateRenderedRangeTimeout = undefined;
    }
  }

  private doUpdateRenderedRange(): void {
    const renderedRange = this._viewport.getRenderedRange();
    const newRange = { start: renderedRange.start, end: renderedRange.end };
    const viewportSize = this._viewport.getViewportSize();
    const dataLength = this._viewport.getDataLength();
    let scrollOffset = this._viewport.measureScrollOffset();
    let firstVisibleIndex = this.getItemIndexForOffset(scrollOffset);

    if (newRange.end > dataLength) {
      const maxVisibleItems = Math.ceil(viewportSize / this._itemSize);
      const newVisibleIndex = Math.max(0, Math.min(firstVisibleIndex, dataLength - maxVisibleItems));

      if (firstVisibleIndex !== newVisibleIndex) {
        firstVisibleIndex = newVisibleIndex;
        scrollOffset = this.getOffsetForIndex(newVisibleIndex);
        newRange.start = Math.floor(firstVisibleIndex);
      }

      newRange.end = Math.max(0, Math.min(dataLength, newRange.start + maxVisibleItems));
    }

    const expandStart = Math.max(this.getItemIndexForOffset(Math.max(0, scrollOffset - this._minBufferPx)));
    const expandEnd = this.getItemIndexForOffset(this._maxBufferPx + scrollOffset + viewportSize);

    newRange.start = Math.max(0, expandStart);
    newRange.end = Math.min(dataLength, expandEnd);

    this._viewport.setRenderedRange(newRange);
    this._viewport.setRenderedContentOffset(this.getOffsetForIndex(newRange.start));
    this._scrolledIndexChange.next(Math.floor(firstVisibleIndex));
  }

  private getOffsetForIndex(index: number): number {
    if (index >= this.itemsOffset.length) {
      index = this.itemsOffset.length - 1;
    }
    if (index > 0) {
      return this.itemsOffset[index - 1];
    }

    return 0;
  }

  private getItemIndexForOffset(offset: number): number {
    const index = this.itemsOffset.findIndex(i => i > offset);
    return index > -1 ? index : this.itemsOffset.length;
  }
}

export function _virtualScrollStrategyFactory(fixedSizeDir: VirtualScrollDirective): KrosVirtualScrollStrategy {
  return fixedSizeDir._scrollStrategy;
}

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'cdk-virtual-scroll-viewport[itemHeight]',
  providers: [{
    provide: VIRTUAL_SCROLL_STRATEGY,
    useFactory: _virtualScrollStrategyFactory,
    deps: [forwardRef(() => VirtualScrollDirective)],
  }],
})
export class VirtualScrollDirective implements OnChanges {
  static ngAcceptInputType_itemSize: NumberInput;
  static ngAcceptInputType_minBufferPx: NumberInput;
  static ngAcceptInputType_maxBufferPx: NumberInput;

  @Input()
  get itemHeight(): number { return this._itemHeight; }
  set itemHeight(value: number) { this._itemHeight = coerceNumberProperty(value); }
  _itemHeight = 20;

  @Input()
  get minBufferPx(): number { return this._minBufferPx; }
  set minBufferPx(value: number) { this._minBufferPx = coerceNumberProperty(value); }
  _minBufferPx = 100;

  @Input()
  get maxBufferPx(): number { return this._maxBufferPx; }
  set maxBufferPx(value: number) { this._maxBufferPx = coerceNumberProperty(value); }
  _maxBufferPx = 200;

  @Input()
  get itemsOffset(): number[] { return this._itemsOffset; }
  set itemsOffset(value: number[]) {
    this._itemsOffset = value;
    this._scrollStrategy.setItemsOffset(value);
  }
  _itemsOffset: number[] = [];

  _scrollStrategy = new KrosVirtualScrollStrategy(
    this.itemHeight, this.minBufferPx, this.maxBufferPx, this.itemsOffset);

  ngOnChanges(): void {
    this._scrollStrategy.updateItemAndBufferSize(this.itemHeight, this.minBufferPx, this.maxBufferPx);
  }
}
