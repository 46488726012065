/* eslint-disable max-lines */
import { AbstractControl } from '@angular/forms';
import { CdkTable } from '@angular/cdk/table';
import {
  Component,
  ContentChild,
  ContentChildren,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, filter, switchMap, take } from 'rxjs/operators';
import { DxToolbarComponent } from 'devextreme-angular/ui/toolbar';

import {
  appendExpression,
  boqTagName,
  cancelNextClick,
  expressionFieldName,
  getMeasurementColumnIndexByTagName,
  isElementInTagWithClassName,
  measurementTags,
  substituteExpressionByValues
} from './boq.helpers';
import { BoqEditHelper, BoqEditService, BoqEditVisualHelper, BoqTableConfig } from './';
import { BoqItem } from '../../models';
import { BoqMeasure, DividerPosition, ExpressionPartChangedEventArgs, RecalculateBoqItems, SelectMeasurement } from '../models';
import { TimelineType } from '../../timeline/timeline-type.enum';

export const selectionColumnName = 'selection';
const description = 'description';

@Component({
  selector: 'kros-boq-edit',
  templateUrl: './boq-edit.component.html',
  styleUrls: ['./boq-edit.component.scss']
})
export class BoqEditComponent implements OnInit {
  public editHelper = inject(BoqEditHelper);
  protected visualHelper = inject(BoqEditVisualHelper);
  private boqEditService = inject(BoqEditService);
  private elementRef = inject(ElementRef);
  private destroyRef = inject(DestroyRef);

  @ContentChild('additionalButtons') additionalButtons: TemplateRef<ElementRef>;
  @ContentChild('showAllBoqMeasurementsButton') showAllBoqMeasurementsButton: TemplateRef<ElementRef>;
  @ContentChildren('additionalToolbarItems', { read: TemplateRef }) additionalToolbarItems: QueryList<TemplateRef<any>>;
  @ContentChildren('additionalToolbarItemsBefore', { read: TemplateRef }) additionalToolbarItemsBefore: QueryList<TemplateRef<any>>;

  @Input() isLoading$: Observable<boolean>;
  @Input() columnsConfig: BoqTableConfig;
  @Input() editItem: (item: BoqItem, column: string) => Observable<BoqItem>;
  @Input() createItem: (id: number) => BoqItem;
  @Input() substituteMeasurements: (value: string) => string;
  @Input() reverseSubstituteMeasurements: (value: string) => string;
  @Input() substituteBoqs: (value: string) => string;
  @Input() reverseSubstituteBoqs: (value: string) => string;
  @Input() set emptyRowsCount(value: number) {
    this._emptyRowsCount = value;
    if (!this.items && value) {
      this.insertEmptyBoqItems(value);
    }
  }
  @Input() edited: boolean;
  @Input() set data(value: BoqItem[]) {
    if (value?.length) {
      this.originalItems = value;
      this.initTable(value);
    } else if (this._emptyRowsCount) {
      this.insertEmptyBoqItems(this._emptyRowsCount);
    } else if (!this.showSummaryRow) {
      this.originalItems = [];
      this.items = [];
    }
  }
  @Input() parentElement: ElementRef = this.elementRef;
  @Input() closeButton: HTMLElement;
  @Input() ignoreEditSelector: string;
  @Input() ignoreClickOutClassNames: string[] = [];
  @Input() forceClickOutClassName: string;
  @Input() canShowDefaultSave = true;
  @Input() readonly = false;
  @Input() showControls = true;
  @Input() set areMatchedBoqs(value: boolean) {
    this.editHelper.setMatchedBoq(value);
  }
  get areMatchedBoqs(): boolean {
    return this.editHelper.areMatchedBoqs;
  }
  @Input() showSummaryRow = true;
  @Input() allowMultiSelection = false;
  @Input() autofocus = true;

  @Output() save = new EventEmitter<BoqItem[]>();
  @Output() deleteRow = new EventEmitter<void>();
  @Output() cancelEdit = new EventEmitter<void>();
  @Output() selectMeasurement = new EventEmitter<SelectMeasurement>();
  @Output() selectBoq = new EventEmitter<number>();
  @Output() expressionPartChanged = new EventEmitter<ExpressionPartChangedEventArgs>();
  @Output() dividerPositionChanged = new EventEmitter<DividerPosition>();
  @Output() afterFocus = new EventEmitter<void>();

  @ViewChild(CdkTable) table: CdkTable<BoqItem>;
  @ViewChild(DxToolbarComponent, { static: false }) toolbar: DxToolbarComponent;
  @ViewChild('additionalButtonsRef') additionalButtonsRef: ElementRef<HTMLElement>;

  selectionColumnName = selectionColumnName;
  items: BoqItem[];
  timelineType = TimelineType;

  get hasItems(): boolean {
    return this.showSummaryRow || this.items?.length !== 0;
  }

  get isSmallPanel(): boolean {
    return this.visualHelper?.isSmallPanel(this.elementRef, this.additionalButtonsRef);
  }

  get canSave(): boolean {
    return this.edited || this.editHelper.isAffected;
  }

  private originalItems: BoqItem[];
  private isClickOutside = false;
  private _emptyRowsCount: number;
  private ignoreEdit = false;
  private isEditFinished$ = new BehaviorSubject<boolean>(true);

  @HostListener('document:mousedown', ['$event'])
  clickOut(event: any): void {
    if (this.parentElement) {
      const clickOnCloseButton = this.closeButton?.contains(event.target);
      if (this.ignoreEditSelector && event.target) {
        this.ignoreEdit = !!event.target.classList.contains(this.ignoreEditSelector);
      }
      const isModal = !!event.composedPath().find(e => e.localName === 'kros-modal');
      const clickedInside = this.parentElement.nativeElement.contains(event.target) ||
        isElementInTagWithClassName(event.target, this.ignoreClickOutClassNames, this.forceClickOutClassName);

      if (event.target && clickedInside && !clickOnCloseButton) {
        this.isClickOutside = !clickedInside;
      } else if (!this.isClickOutside && !isModal) {
        this.isClickOutside = true;
        this.expressionPartChanged.emit(null);
        if (this.visualHelper.rowIndex !== undefined) {
          this.edit(this.items[this.visualHelper.rowIndex], this.visualHelper.getColumn(), false, false, cancelNextClick)
            .pipe(takeUntilDestroyed(this.destroyRef), switchMap(() => this.saveBoqItemsWithQuestion()), filter(p => p), take(1))
            .subscribe();
        }
      }
    }
  }

  ngOnInit(): void {
    this.visualHelper.init(this.columnsConfig, this.readonly, this.allowMultiSelection);
    this.editHelper.init(
      this.substituteMeasurements,
      this.reverseSubstituteMeasurements,
      this.substituteBoqs,
      this.reverseSubstituteBoqs);
    this.boqEditService.insertMeasurementToCurrentRow$.pipe(takeUntilDestroyed(this.destroyRef), filter(p => !!p && !!this.items.length))
      .subscribe(p => this.insertValuesToSelectedRow(p.value, p.preventFocus));
    this.boqEditService.insertMeasurementAsNewRows$.pipe(takeUntilDestroyed(this.destroyRef), filter(p => !!p && !!this.items.length))
      .subscribe(p => this.insertValuesAsNewRows(p.value, true));
    this.boqEditService.insertBoqToRow$.pipe(takeUntilDestroyed(this.destroyRef), filter(p => !!p && !!this.items.length))
      .subscribe(value => this.insertValuesToSelectedRow([value]));
    this.boqEditService.insertBoqAsNewRow$.pipe(takeUntilDestroyed(this.destroyRef), filter(p => !!p && !!this.items.length))
      .subscribe(value => this.insertValuesAsNewRows([value]));
    this.boqEditService.insertBoqToCurrentRow$.pipe(takeUntilDestroyed(this.destroyRef), filter(p => !!p && !!this.items.length))
      .subscribe(value => {
        this.editHelper.removeBoqPredicate(this.visualHelper.rowIndex);
        this.insertValuesToSelectedRow([value], false, true);
      });
    this.boqEditService.resized$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.repaintToolbar());
    this.editHelper.isAffected$.pipe(delay(0), takeUntilDestroyed(this.destroyRef), filter(p => p)).subscribe(() => this.repaintToolbar());
    if (this.originalItems) {
      this.initTable(this.originalItems);
    }
    this.visualHelper.afterFocus$.pipe(takeUntilDestroyed(this.destroyRef), delay(200)).subscribe(() => this.afterFocus.emit());
  }

  endEdit(): Observable<boolean> {
    return this.edit(this.items[this.visualHelper.rowIndex], this.visualHelper.getColumn(), false, false);
  }

  doEdit(row: BoqItem, column: string, forceEditMode, preventFocus = false): void {
    this.edit(row, column, forceEditMode, preventFocus).pipe(takeUntilDestroyed(this.destroyRef), take(1)).subscribe();
  }

  hasCurrentRowValueChanged(): boolean {
    return this.editHelper.hasCurrentRowValueChanged(this.items, this.visualHelper.rowIndex, !this.readonly && !this.ignoreEdit);
  }

  recalculate(data: RecalculateBoqItems): void {
    if (data?.items.length) {
      this.items.forEach(row => {
        row = this.boqEditService.getRecalculatedRow(data, row);
        if (row) {
          this.editItem(row, expressionFieldName)
            .pipe(takeUntilDestroyed(this.destroyRef), take(1)).subscribe(p => this.setEditedItem(row, p));
        }
      });
    }
  }

  replace(measurements: BoqMeasure[]): void {
    if (measurements.length) {
      this.items.forEach(item =>
        this.setEditedItem(item, { ...item, expression: substituteExpressionByValues(measurements, item.expression) })
      );

      this.originalItems.forEach(item =>
        this.setOriginalItem(item, { ...item, expression: substituteExpressionByValues(measurements, item.expression) })
      );
    }
  }

  selectCell(row: BoqItem, column: string): void {
    this.visualHelper.selectCell(this.items, row, column);
    this.expressionPartChanged.emit(null);
  }

  onCellKeyDown(event: KeyboardEvent): void {
    this.visualHelper.onCellKeyDown(event, this.readonly, this.items, this.onCreate.bind(this));
  }

  expressionKeyDown(event: KeyboardEvent, row: BoqItem): void {
    const lastDividerPosition = this.visualHelper.onExpressionKeyDown(event, row, this.items, this.readonly);
    if (lastDividerPosition) {
      this.dividerPositionChanged.emit(lastDividerPosition);
      this.expressionPartChanged.emit(this.visualHelper.getExpressionPartChanged(lastDividerPosition, event));
    }
  }

  trySelectMeasurementOrBoq(event: any, row: any): void {
    const tagName = event?.target?.tagName.toLowerCase();
    if (measurementTags.includes(tagName)) {
      const id = +event.target.getAttribute('measurement-id');
      if (id) {
        this.selectMeasurement.next({ id, column: getMeasurementColumnIndexByTagName(tagName), boqId: row.boqId });
      }
    } else if (tagName === boqTagName) {
      const id = event.target.getAttribute('boq-id');
      if (id) {
        this.selectBoq.next(+id);
      }
    }
  }

  onSave(): void {
    this.isEditFinished$.pipe(takeUntilDestroyed(this.destroyRef), filter(e => e), take(1)).subscribe(() => {
      this.visualHelper.setResetFocusedCells();
      this.save.next(this.items);
    });
  }

  onCancel(): void {
    this.isEditFinished$.pipe(takeUntilDestroyed(this.destroyRef), filter(e => e), take(1)).subscribe(() => {
      this.initTable(this.originalItems);
      this.cancelEdit.next();
    });
  }

  onCreate(canAddAfterRow?: boolean): void {
    this.addRow(canAddAfterRow);
    this.reloadTable();
    this.visualHelper.focusTableCell();
  }

  onDelete(): void {
    const toDelete = this.visualHelper.selectedIndexes;
    const newFocusedCellRowIndex = this.visualHelper.getNewRowIndex(this.items, toDelete.length);
    this.editHelper.onDelete(this.items.filter((p, index) => toDelete.includes(index)).map(p => p.id));
    toDelete.slice().reverse().forEach(rowIndex => this.items.splice(rowIndex, 1));
    this.editHelper.removeForms(toDelete);
    this.table?.renderRows();
    this.visualHelper.setFocusedRowIndex(newFocusedCellRowIndex);
    this.visualHelper.focusTableCell(false);
    this.deleteRow.next();
    if (this.editHelper.numberOfRows === 0) {
      this.insertEmptyBoqItems(3, true);
    }
  }

  focusTableCell(): void {
    this.visualHelper.focusTableCell();
  }

  private initTable(data: BoqItem[], isDeletedLastRow?: boolean): void {
    this.items = [...data];
    this.editHelper.generateForms(this.items, this.allowMultiSelection);
    if (!isDeletedLastRow) {
      this.editHelper.clear();
    }

    if (this.autofocus) {
      this.visualHelper.setFocusedCellByItems(this.items);
    }
  }

  private edit(row: BoqItem, column: string, forceEditMode, preventFocus, beforeEdit = (): void => { }): Observable<boolean> {
    if (!this.readonly && row && !this.ignoreEdit) {
      const newValue = this.editHelper.getNewValue(this.items, row, column);
      if (row[column] !== newValue) {
        this.isEditFinished$.next(false);
        beforeEdit();
        row = this.editHelper.getRow(row, column, newValue);
        this.editHelper.onEdit(row.id);
        return this.editItem(row, column).pipe(
          take(1),
          switchMap(p => {
            this.setEditedItem(row, p);
            this.saveBoqItemsWithQuestion();
            this.visualHelper.focusTableCellAfterEdit(preventFocus, forceEditMode);
            this.isEditFinished$.next(true);
            return this.isEditFinished$;
          })
        );
      }
    }
    this.ignoreEdit = false;
    return of(false);
  }

  private editRow(row: BoqItem, changes: Object, preventFocus: boolean): void {
    this.editHelper.onEdit(row.id);
    const changedItem = this.editHelper.getChangedItems(row, changes);
    this.editItem({ ...row, ...changedItem }, expressionFieldName).pipe(takeUntilDestroyed(this.destroyRef), take(1)).subscribe(boqItem => {
      this.setEditedItem(row, boqItem);
      this.visualHelper.focusTableCellAfterEdit(preventFocus);
    });
  }

  private setEditedItem(row: BoqItem, newItem: BoqItem): void {
    const index = this.items.findIndex(p => p.id === row.id);
    this.items.splice(index, 1, newItem);
    this.editHelper.patchValue(index, newItem);
    this.reloadTable();
  }

  private setOriginalItem(row: BoqItem, newItem: BoqItem): void {
    this.originalItems.splice(this.originalItems.findIndex(p => p.id === row.id), 1, newItem);
  }

  private saveBoqItemsWithQuestion(): Observable<boolean> {
    if (this.isClickOutside) {
      const ret = new BehaviorSubject<boolean>(false);
      const hasExpression = this.items.some(p => p.expression !== '');
      this.editHelper.saveBoqItemsWithQuestion(this.edited, hasExpression, (submit: boolean) => {
        if (submit) {
          this.onSave();
        } else {
          this.onCancel();
        }
        this.isClickOutside = false;
        ret.next(true);
      });
      return ret;
    }
    return of(true);
  }

  private addRow(canAddAfterRow: boolean): void {
    const focusedRowIndex = canAddAfterRow ? this.visualHelper.rowIndex + 1 : this.visualHelper.rowIndex;
    this.visualHelper.setFocusedRowIndex(this.items.length === 0 ? 0 : focusedRowIndex);
    const newItem = this.createItem(this.editHelper.generateNewId(this.items));
    this.items.splice(this.visualHelper.rowIndex, 0, newItem);
    this.editHelper.insertForm(this.visualHelper.rowIndex, newItem, this.allowMultiSelection);
  }

  private repaintToolbar(): void {
    this.toolbar?.instance.repaint();
  }

  private insertEmptyBoqItems(count: number, isDeletedLastRow?: boolean): void {
    const items = Array.from({ length: count }, (_, i) => (i + 1) * -1).map(p => this.createItem(p));

    if (!isDeletedLastRow) {
      this.originalItems = items;
      this.initTable(this.originalItems);
    } else {
      this.initTable(items, isDeletedLastRow);
    }

    this.reloadTable();
    this.visualHelper.resetFocusedCell();

    if (this.autofocus) {
      this.visualHelper.focusTableCell(true);
    }
  }

  private insertValuesToSelectedRow(values: (Object | string)[], preventFocus = false, useLastDivider = false): void {
    const fieldExpression = this.editHelper.getForm(this.visualHelper.rowIndex).get(expressionFieldName);
    const expression = this.editHelper.wrapTags(this.editHelper.append(fieldExpression.value, appendExpression(values), useLastDivider));
    const value = this.boqEditService.getBoqValue(values);
    this.editRowAfterInsert(!value, fieldExpression, expression, value, preventFocus);
  }

  private insertValuesAsNewRows(values: (Object | string)[], isMeasurementTab?: boolean): void {
    let isAddingRow = false;
    values.forEach((value, index) => {
      let fieldExpression = this.visualHelper.getFormForInsert();
      const oldValue: string = fieldExpression?.value?.trimEnd();
      if (isAddingRow || oldValue !== '' || oldValue === undefined) {
        this.addRow(isAddingRow || index === 0);
        fieldExpression = this.editHelper.getForm(this.visualHelper.rowIndex)?.get(expressionFieldName);
        isAddingRow = true;
      }
      const isValueString = typeof value === 'string';
      const expressionValue = isMeasurementTab ? `${value[expressionFieldName]} "${value[description]}"` : value[expressionFieldName];
      const expression = this.editHelper.wrapTags(isValueString ? value : expressionValue);
      this.editRowAfterInsert(isValueString, fieldExpression, expression, value, false);
      if (!isAddingRow && index < values.length - 1) {
        this.visualHelper.setFocusedRowIndex(this.visualHelper.rowIndex + 1);
      }
    });
    this.reloadTable();
    this.visualHelper.focusTableCell();
  }

  private editRowAfterInsert(
    isValueString: boolean,
    fieldExpression: AbstractControl<any, any>,
    expression: string,
    value: Object,
    preventFocus: boolean): void {
    if (isValueString) {
      fieldExpression.setValue(expression);
      this.doEdit(this.items[this.visualHelper.rowIndex], expressionFieldName, true, preventFocus);
    } else {
      value[expressionFieldName] = this.editHelper.unwrapTags(expression);
      this.editRow(this.items[this.visualHelper.rowIndex], value, preventFocus);
    }
  }

  private reloadTable(): void {
    setTimeout(() => {
      try {
        this.table?.renderRows();
      } catch { }
    });
  }
}
