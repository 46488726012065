import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { FolderTreeNode, ProjectDetail } from '@kros-sk/ssw-shared-legacy';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { DocumentsDispatchersService } from '../../../store/documents';
import { ProjectsSelectorsService } from '../../../store/projects';

@Component({
  selector: 'app-document-panel-container',
  templateUrl: './document-panel-container.component.html',
  styleUrls: ['./document-panel-container.component.scss']
})
export class DocumentPanelContainerComponent extends UnsubscribeComponent implements OnInit {
  folderId: string;
  folderHierarchy: FolderTreeNode[];
  projectDetail: ProjectDetail;

  @Input() collapsed = false;

  @Output() panelClosed: EventEmitter<void> = new EventEmitter<void>();
  @Output() panelCollapsed: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private documentsDispatchersService: DocumentsDispatchersService,
    private projectSelectorService: ProjectsSelectorsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.projectSelectorService.projectDetail$
      .subscribe((p: ProjectDetail) => {
        this.projectDetail = p;
        if (p) {
          this.documentsDispatchersService.loadFolderHierarchy(p.id);
        }
      });
  }

  onNodeSelected(): void {
    this.panelClosed.emit();
  }

  toggleCollapsePanel(): void {
    this.collapsed = !this.collapsed;
    this.panelCollapsed.emit(this.collapsed);
  }
}
