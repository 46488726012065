import { Injectable } from '@angular/core';

import { SubSink } from 'subsink';

import {
  CalculationType,
  ColDef,
  ColDefTextAlign,
  ColDefType,
  ColumnIconComponent,
  DataTableConfigModel,
  DecimalPlaces,
  formatNumberValue,
  getColumnWidth,
  getNodeLevel,
  getTextCellHeight,
  isConstructionOrBuildingObject,
  isMaterial,
  isSection,
  isSummaryItem,
  PanelHelper
} from '@kros-sk/ssw-shared-legacy';

import { ApprovalType } from '../enums';
import { BudgetChangeItem, BudgetChangeModel, ChangeSheetColumn, ChangeSheetColumnsConfigModel } from '../models';
import { budgetChangesTableId } from '../constants/budget-approval-constants';
import { environment } from '../../../environments/environment';
import { PermissionHelper } from '../../building-progress/helpers';
import { RelationHelper } from '../helpers';

@Injectable()
export class BudgetChangeTableService {
  averageLineHeight: number;

  private subs = new SubSink();

  constructor(
    private permissionHelper: PermissionHelper,
    private relationHelper: RelationHelper,
    private panelHelper: PanelHelper
  ) { }

  getTableConfig(budgetChangeData: BudgetChangeModel, buildingObjectId?: number): DataTableConfigModel {
    const columnsConfig = this.getColumnsConfig(budgetChangeData);
    this.averageLineHeight = columnsConfig.averageLineHeight;

    return {
      colDefs: this.getColDefs(budgetChangeData.decimalPlaces, columnsConfig, buildingObjectId),
      itemsOffset: columnsConfig.itemsOffset,
      isReadonly: true,
      rowClassGetter: (item: BudgetChangeItem): string =>
        (isConstructionOrBuildingObject(item) ? 'font-strong font-blue' : '') +
        (isSection(item) ? ' font-strong' : '') +
        (isConstructionOrBuildingObject(item) && item?.approvalType === ApprovalType.Approved && !item?.occurence ? ' font-green' : '') +
        (isConstructionOrBuildingObject(item) && item?.approvalType === ApprovalType.NotApproved && !item?.occurence ? ' font-brown' : ''),
      fixedWidth: true
    };
  }

  destroy(): void {
    this.subs.unsubscribe();
  }

  private getColDefs(
    decimalPlaces: DecimalPlaces,
    columnsConfig: ChangeSheetColumnsConfigModel,
    buildingObjectId: number
  ): ColDef[] {
    return [
      {
        id: ChangeSheetColumn.ItemType,
        title: 'CHANGE_SHEET.TYPE',
        type: ColDefType.Column,
        textAlign: ColDefTextAlign.Center,
        isFixed: true,
        width: 25,
        mobileHidden: true,
        valueGetter: (item: BudgetChangeItem): any =>
          item.approvalType !== ApprovalType.None && item.itemType === 'O' ? 'ZL' : item.itemType
      } as ColDef,
      {
        id: ChangeSheetColumn.ItemNumber,
        title: 'CHANGE_SHEET.NUMBER',
        type: ColDefType.Column,
        textAlign: ColDefTextAlign.Right,
        isFixed: true,
        width: 40,
        valueGetter: (item: BudgetChangeItem): any => item.itemNumber
      } as ColDef,
      {
        id: ChangeSheetColumn.Code,
        title: 'CHANGE_SHEET.CODE',
        type: ColDefType.Column,
        isFixed: true,
        width: 100,
        valueGetter: (item: BudgetChangeItem): any => item.code,
        cellClassGetter: (item: BudgetChangeItem): string => item?.approvalType === ApprovalType.Approved ? ' font-green' : ''
      } as ColDef,
      {
        id: ChangeSheetColumn.Description,
        title: 'CHANGE_SHEET.DESCRIPTION',
        type: ColDefType.Column,
        isFixed: true,
        width: 300,
        cellClassGetter: (item: BudgetChangeItem): string => item
          ? ((isSummaryItem(item) ? 'level-' + getNodeLevel(item.level) : '') +
            (isMaterial(item) ? ' font-blue' : ''))
          : '',
        valueGetter: (item: BudgetChangeItem): any => item.description,
      } as ColDef,
      {
        id: ChangeSheetColumn.Relation,
        title: 'CHANGE_SHEET.RELATION.RELATION',
        type: ColDefType.Column,
        width: 40,
        isFixed: true,
        cellClassGetter: (item: BudgetChangeItem): string => item?.originalId > 0 ? 'font-green' : '',
        componentConfig: {
          component: ColumnIconComponent,
          params: {
            iconName: 'link',
            tooltip: 'CHANGE_SHEET.RELATION.RELATION',
            action: (item: BudgetChangeItem): void => this.relationHelper.openRelationDialog(
              item, budgetChangesTableId, buildingObjectId),
            hidden: (item: BudgetChangeItem): boolean =>
              isSummaryItem(item) ||
              (item.approvalType !== ApprovalType.Approved && item.approvalType !== ApprovalType.NotApproved) ||
              ((this.permissionHelper.isReader || this.permissionHelper.isApprover) && !item.originalId)
          }
        }
      } as ColDef,
      {
        id: ChangeSheetColumn.MeasureUnit,
        title: 'CHANGE_SHEET.MEASURE_UNIT',
        type: ColDefType.Column,
        minWidth: 50,
        valueGetter: (item: BudgetChangeItem): any => item.measureUnit
      } as ColDef,
      {
        id: ChangeSheetColumn.Amount,
        title: 'CHANGE_SHEET.AMOUNT',
        type: ColDefType.Column,
        format: this.formatNumberPrice.bind(this, decimalPlaces.amount),
        textAlign: ColDefTextAlign.Right,
        minWidth: this.getColumnWidthFromNumberValue(columnsConfig.amount, decimalPlaces.amount),
        valueGetter: (item: BudgetChangeItem): any => isSection(item) ? null : item.amount,
        cellClassGetter: (item: BudgetChangeItem): string => !item ? '' :
          ((!item.amount ? 'font-red ' : '') +
            (item.calculationType === CalculationType.BillOfQuantities ? 'font-blue' :
              item.calculationType !== CalculationType.None ? 'font-green' : '')),
        cellActionButton: {
          id: (): string => 'ga-boq-open',
          iconName: (): string => 'square_foot',
          tooltip: (): string => 'BILL_OF_QUANTITIES.COUNTED_FROM_BILL_OF_QUANTITIES',
          showCondition: (item: BudgetChangeItem): boolean => item.calculationType === CalculationType.BillOfQuantities,
          action: (item: BudgetChangeItem): void => this.panelHelper.openBoq(item)
        }
      } as ColDef,
      {
        id: ChangeSheetColumn.UnitPrice,
        title: 'CHANGE_SHEET.UNIT_PRICE',
        type: ColDefType.Column,
        format: this.formatNumberPrice.bind(this, decimalPlaces.unitPrice),
        textAlign: ColDefTextAlign.Right,
        minWidth: this.getColumnWidthFromNumberValue(columnsConfig.unitPrice, decimalPlaces.unitPrice),
        valueGetter: (item: BudgetChangeItem): any => isSection(item) ? null : item.unitPrice
      } as ColDef,
      {
        id: ChangeSheetColumn.TotalPrice,
        title: 'CHANGE_SHEET.TOTAL_PRICE',
        type: ColDefType.Column,
        format: this.formatNumberPrice.bind(this, decimalPlaces.totalPrice),
        textAlign: ColDefTextAlign.Right,
        minWidth: this.getColumnWidthFromNumberValue(columnsConfig.totalPrice, decimalPlaces.totalPrice),
        valueGetter: (item: BudgetChangeItem): any => item.totalPrice
      } as ColDef,
      {
        id: ChangeSheetColumn.Occurence,
        title: 'BUDGET_CHANGES.OCCURENCE',
        type: ColDefType.Column,
        minWidth: 150,
        valueGetter: (item: BudgetChangeItem): any => item.occurence,
        cellClassGetter: (item: BudgetChangeItem): string =>
          (item?.approvalType === ApprovalType.Approved ? ' font-green' : '') +
          (item?.approvalType === ApprovalType.NotApproved ? ' font-brown' : '')
      } as ColDef
    ];
  }

  private formatNumberPrice(decimalPlaces: number, value: any): string {
    return isNaN(value) ? '' : formatNumberValue(value, decimalPlaces, environment.location);
  }

  private getColumnWidthFromNumberValue(maxNumber: number, decimalPlaces: number): number {
    return Math.max(100, getColumnWidth(maxNumber, decimalPlaces, environment.location, true));
  }

  private getColumnsConfig(data: BudgetChangeModel): ChangeSheetColumnsConfigModel {
    const itemsOffset = [];
    const root = data.items[0];
    let totalHeight = 0;
    let amount = 0;
    let unitPrice = 0;

    data.items.forEach(p => {
      totalHeight += getTextCellHeight(p.description.length, p.level, isConstructionOrBuildingObject(p), 300);
      itemsOffset.push(totalHeight);
      amount = Math.max(amount, p.amount);
      unitPrice = Math.max(unitPrice, p.unitPrice);
    });

    return {
      itemsOffset,
      averageLineHeight: Math.floor(totalHeight / data.items.length),
      totalPrice: root.totalPrice,
      amount,
      unitPrice
    };
  }
}
