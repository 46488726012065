import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { combineLatest, filter, map } from 'rxjs';

import { AuthSelectorsService } from '@kros-sk/auth';

import { ApplicationModule, ApplicationModuleIdProviderService } from '../../../application-module';
import { InfoMessage } from '../../model';
import { InfoMessageDispatchersService, InfoMessageSelectorsService } from '../../store';

@Component({
  selector: 'kros-info-message',
  templateUrl: './info-message.component.html',
  styleUrls: ['./info-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoMessageComponent implements OnInit {

  private infoMessageSelectorsService = inject(InfoMessageSelectorsService);
  private infoMessageDispatchersService = inject(InfoMessageDispatchersService);
  private appModuleIdProviderService = inject(ApplicationModuleIdProviderService);
  private destroyRef = inject(DestroyRef);
  private authSelectorsService = inject(AuthSelectorsService, { optional: true });

  messages$ = combineLatest([
    this.infoMessageSelectorsService.infoMessages$,
    this.appModuleIdProviderService.applicationModuleId$
  ]).pipe(
    filter(([_, moduleId]) => !!moduleId),
    map(([messages, moduleId]: [InfoMessage[], ApplicationModule]) =>
      messages.filter(m => !m.modules?.length || m.modules.includes(moduleId)))
  );

  ngOnInit(): void {
    if (this.authSelectorsService) {
      this.authSelectorsService.currentUser$
        .pipe(filter(user => !!user), takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.infoMessageDispatchersService.loadInfoMessages());
    } else {
      this.infoMessageDispatchersService.loadInfoMessages();
    }
  }

  onClose(message: string): void {
    this.infoMessageDispatchersService.closeInfoMessage(message);
  }
}
