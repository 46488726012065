<div class="kros-modal-body">
  <div
    *ngFor="let permission of permissionList"
    class="permission"
    [class.selected]="isPermissionSelected(permission)"
    data-test="permission-option"
    (click)="onPermissionChanged(permission)"
  >
    <div class="permission-name">
      <span>{{ permission.name }}</span>
    </div>
    <div class="permission-description">
      <span>{{ permission.description }}</span>
    </div>
  </div>
</div>
