import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CommentModel } from '../comment.iterface';
import { TimelineType } from '../../timeline/timeline-type.enum';

@Component({
  selector: 'kros-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {

  @Input() focusedCommentIds: number[];
  @Input() allComments: CommentModel[];
  @Input() newComment: CommentModel;
  @Input() createAnswer: (c: CommentModel) => CommentModel;
  @Input() showAnswerButton: boolean;
  @Input() readOnly: boolean;
  @Input() loading: boolean;
  @Input()
  get comments(): CommentModel[] {
    return this._comments;
  }
  set comments(values: CommentModel[]) {
    this._comments = values;
    this.areCommentsLoaded = true;
    this.editingCommentIds.clear();
  }

  @Output() publish = new EventEmitter<void>();
  @Output() cancelNewComment = new EventEmitter<void>();
  @Output() saveNewComment = new EventEmitter<CommentModel>();
  @Output() saveComment = new EventEmitter<CommentModel>();
  @Output() deleteComment = new EventEmitter<CommentModel>();
  @Output() clickComment = new EventEmitter<CommentModel>();

  areCommentsLoaded: boolean;
  timelineType = TimelineType;

  get editing(): boolean {
    return this.editingCommentIds && this.editingCommentIds.size > 0;
  }

  private _comments: CommentModel[];
  private editingCommentIds = new Set<number>();

  constructor() { }

  ngOnInit(): void {
    this.areCommentsLoaded = false;
  }

  onCancelNewComment(): void {
    this.cancelNewComment.emit();
  }

  onSaveNewComment(comment: CommentModel): void {
    this.saveNewComment.emit(comment);
  }

  onEditingChanged(commentEditing: any): void {
    if (commentEditing.editing && !this.editingCommentIds.has(commentEditing.comment.id)) {
      this.editingCommentIds.add(commentEditing.comment.id);
    } else if (!commentEditing.editing && this.editingCommentIds.has(commentEditing.comment.id)) {
      this.editingCommentIds.delete(commentEditing.comment.id);
    }
  }

  onSaveComment(comment: CommentModel): void {
    this.saveComment.emit(comment);
  }

  onDeleteComment(comment: CommentModel): void {
    this.deleteComment.emit(comment);
  }

  onClickComment(comment: CommentModel): void {
    this.clickComment.emit(comment);
  }

  onPublish(): void {
    this.publish.emit();
  }

  getNonPublishedCount(): number {
    let count = 0;
    if (this.allComments) {
      this.allComments.forEach(c => {
        if (!c.datePublished) {
          count++;
        }
        c.answers?.forEach(a => {
          if (!a.datePublished) {
            count++;
          }
        });
      });
    }
    return count;
  }

  scrollCommentIntoView(id: number): void {
    const element = document.getElementById('comment-' + id);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }
  }

  findRootComment(comment: CommentModel): CommentModel {
    if (comment.parentId) {
      return this.findRootComment(this.comments.find(p => p.id === comment.parentId));
    } else {
      return comment;
    }
  }

}
