<ng-container *ngIf="!(selector.budgetChangeDataLoading$ | async) else contentLoader">
  <app-budget-changes-toolbar
    [canOpenBoq]="canOpenBoq"
    (backClick)="onBackClick()"
    (showBudgetChangesStructureClick)="onShowBudgetChangesStructure()"
    (exportClick)="onExportClick()"
    (openBoqClick)="onOpenBoqClick()"
  ></app-budget-changes-toolbar>

  <div class="data-container">
    <kros-side-panel>
      <ng-template #bottomPanel>
        <app-budget-boq
          [data]="panelHelper.boqBudgetItem"
          [decimalPlaces$]="selector.budgetApprovalDataDecPlaces$"
          [boqItems$]="selector.boqItems$"
          [boqItemsIsLoading$]="selector.boqItemsIsLoading$"
          (closePanelClicked)="panelHelper.onCloseBoq()"
        >
        </app-budget-boq>
      </ng-template>

      <ng-template #content>
        <kros-data-table
          *ngIf="hasData else emptyContent"
          [itemHeight]="tableService.averageLineHeight"
          [data]="tableData"
          [itemCount]="itemCount"
          [config]="dataTableConfig"
          (cellFocused)="onCellFocused($event)"
          [focusedCell]="focusedCell"
          (scrollFocusedCellToView)="onScrollFocusedCellToView($event)"
        ></kros-data-table>
      </ng-template>
    </kros-side-panel>
  </div>
</ng-container>

<ng-template #emptyContent>
  <p
    class="empty-content"
    data-test="empty-content"
  >
    <span class="text-center">
      {{ 'BUILDING_PROGRESS.EMPTY_CONTENT' | translate }}&nbsp;
      <a
        target="_blank"
        href="{{emptyContentLink}}"
      >{{ emptyContentDescription | translate }}</a>
    </span>
  </p>
</ng-template>

<ng-template #contentLoader>
  <kros-timeline [type]="timelineType.Table"></kros-timeline>
</ng-template>
