import { Action, ActionCreator, createReducer, on, ReducerTypes } from '@ngrx/store';

import {
  adapterDeletedDocumentsVersion,
  adapterDocumentList,
  adapterDocumentVersionList,
  adapterFolderHierarchy,
  adapterMarkedDocuments,
  documentsEditReducers,
  documentsExplorerReducers,
  DocumentsState,
  DocumentType,
  FolderTreeNode,
  initialDocumentsState,
  listEditActions
} from '@kros-sk/ssw-shared-legacy';

import * as listActions from './documents.actions';

const documentsReducers: ReducerTypes<DocumentsState, ActionCreator[]>[] = [
  on(listActions.clearState, state => {
    return {
      ...initialDocumentsState,
      uploadingData: state.uploadingData
    };
  }),
  on(listEditActions.renameFolderSuccess, (state, payload) => {
    const document = adapterDocumentList.getSelectors().selectEntities(state.documentList)[payload.documentId];

    return {
      ...state,
      folderHierarchy: document ? adapterFolderHierarchy.updateOne(
        { id: document.id, changes: { name: payload.documentName } },
        state.folderHierarchy
      ) : state.folderHierarchy,
    };
  }),
  on(listEditActions.softDeleteDocumentSuccess, (state, payload) => {
    const deletedDocument = adapterDocumentList.getSelectors().selectEntities(state.documentList)[payload.documentId];
    return {
      ...state,
      folderHierarchy: deletedDocument.type === DocumentType.Folder ?
        adapterFolderHierarchy.removeOne(payload.documentId, state.folderHierarchy) : state.folderHierarchy,
    };
  }),
  on(listEditActions.softDeleteDocumentsSuccess, (state, payload) => {
    const deletedFolders: FolderTreeNode[] = [];

    payload.documents.forEach(document => {
      if (document.type === DocumentType.Folder) {
        const folder = adapterFolderHierarchy.getSelectors().selectEntities(state.folderHierarchy)[document.id];
        if (folder && folder.id !== undefined) {
          deletedFolders.push(folder);
        }
      }
    });

    return {
      ...state,
      folderHierarchy: adapterFolderHierarchy.removeMany(deletedFolders.map(f => f.id), state.folderHierarchy)
    };
  }),


  on(listActions.loadFolderHierarchySuccess, (state, payload) => {
    return {
      ...state,
      folderHierarchy: adapterFolderHierarchy.setAll(payload.folderHierarchy, state.folderHierarchy)
    };
  }),
  on(listActions.loadUploadingDataSuccess, (state, payload) => {
    return {
      ...state,
      uploadingData: payload.uploadingData
    };
  }),
  on(listActions.clearUploadingData, (state) => {
    return {
      ...state,
      uploadingData: undefined
    };
  }),
  on(listActions.uploadBuildingSuccess, (state, payload) => {
    return {
      ...state,
      uploadingData: undefined,
      showSharing: state.uploadingData?.showSharing,
      markedDocuments: adapterMarkedDocuments.setAll([payload.documentId], state.markedDocuments)
    };
  }),
  on(listActions.uploadBuildingVersionSuccess, (state, payload) => {
    return {
      ...state,
      uploadingData: undefined,
      showSharing: state.uploadingData?.showSharing,
      markedDocuments: adapterMarkedDocuments.setAll([payload.documentId], state.markedDocuments)
    };
  }),
  on(listActions.loadDocumentVersionSuccess, (state, payload) => {
    const deletedDocumentVersion = adapterDeletedDocumentsVersion.getSelectors().selectAll(state.deletedDocumentsVersion);
    if (deletedDocumentVersion.length > 0) {
      deletedDocumentVersion.forEach(doc => payload.documentVersionList.splice(doc.docIndex, 1));
    }
    return {
      ...state,
      documentVersionList: adapterDocumentVersionList.setAll(payload.documentVersionList, state.documentVersionList)
    };
  }),
  on(listActions.setDescriptionSuccess, (state, payload) => {
    const document = adapterDocumentList.getSelectors().selectEntities(state.documentList)[payload.documentId];

    return {
      ...state,
      documentList: document ? adapterDocumentList.updateOne(
        { id: document.id, changes: { description: payload.description } },
        state.documentList
      ) : state.documentList
    };
  })
];

export function documentsReducer(state: DocumentsState | undefined, action: Action): any {
  const reducer = createReducer(initialDocumentsState, ...documentsReducers, ...documentsEditReducers, ...documentsExplorerReducers);
  return reducer(state, action);
}
