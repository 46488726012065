import { ActionCreator, on, ReducerTypes } from '@ngrx/store';

import * as partialItemsActions from './building-progress-partial-items.actions';
import { BuildingProgressState } from '../building-progress.state';
import {
  PartialBudgetItem,
  PartialBudgetItemChangedProperty
} from '../../../building-progress/models/building-progress-partial-budget-items.model';
import { UpdatePartialItemPayload } from './building-progress-partial-items.actions';

function round(num: number, precision: number): number {
  return Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision);
}

function isItemChanged(incommingItem: UpdatePartialItemPayload, testedItem: PartialBudgetItem, testedItemIndex: number): boolean {
  return (
    (incommingItem.changedValue !== testedItem.amount && incommingItem.propertyChanged === PartialBudgetItemChangedProperty.Amount) ||
    (incommingItem.changedValue !== testedItem.percentage && incommingItem.propertyChanged === PartialBudgetItemChangedProperty.Percentage)
  ) && testedItemIndex === incommingItem.rowIndex;
}

export const partialItemsReducers: ReducerTypes<BuildingProgressState, ActionCreator[]>[] = [
  on(partialItemsActions.initPartialItems, (state, payload): BuildingProgressState => ({
    ...state,
    partialItemAmount: payload.budgetItem.partialBudgetItems.length === 1
      ? payload.budgetItem.partialBudgetItems[0].amount
      : payload.budgetItem.amount,
    partialItems: {
      counter: 0,
      budgetItemId: payload.budgetItem.id,
      items: []
    }
  })),
  on(partialItemsActions.addPartialItem, (state, payload): BuildingProgressState => ({
    ...state,
    partialItems: {
      ...state.partialItems,
      counter: state.partialItems.counter + 1,
      items: [
        ...state.partialItems.items,
        payload.partialBudgetItem
      ]
    }
  })),
  on(partialItemsActions.updatePartialItem, (state, payload: UpdatePartialItemPayload): BuildingProgressState => ({
    ...state,
    partialItems: {
      ...state.partialItems,
      items: state.partialItems.items.map((item: PartialBudgetItem, itemIndex) => {
        if (!isItemChanged(payload, item, itemIndex)) {
          return item;
        }
        if (payload.propertyChanged === PartialBudgetItemChangedProperty.Amount) {
          let maxAmount = state.partialItems.items[0].amount + item.amount;

          if (maxAmount >= 0) {
            const changedValue = payload.changedValue <= 0
              ? Math.abs(payload.changedValue)
              : payload.changedValue;

            if (maxAmount > changedValue) {
              maxAmount = changedValue;
            }
          } else if (maxAmount < 0) {
            const changedValue = payload.changedValue >= 0
              ? Object.is(payload.changedValue, -0) ? 0 : -payload.changedValue
              : payload.changedValue;

            if (maxAmount < changedValue) {
              maxAmount = changedValue;
            }
          }
          return {
            amount: maxAmount,
            previousAmount: item.amount,
            percentage: item.percentage,
            changedProperty: PartialBudgetItemChangedProperty.Amount
          };
        } else if (payload.propertyChanged === PartialBudgetItemChangedProperty.Percentage) {
          let maxPercentage = state.partialItems.items[0].percentage + item.percentage;
          if (maxPercentage > payload.changedValue) {
            maxPercentage = payload.changedValue;
          }

          if (maxPercentage <= -0) {
            maxPercentage = 0;
          }

          return {
            amount: item.amount,
            previousAmount: item.amount,
            percentage: maxPercentage,
            changedProperty: PartialBudgetItemChangedProperty.Percentage
          };
        }
      })
    }
  })),
  on(partialItemsActions.recalculatePartialItemSuccess, (state, payload): BuildingProgressState => ({
    ...state,
    partialItems: {
      ...payload.partialBudgetItems,
      items: payload.partialBudgetItems.items.map((item: PartialBudgetItem) => ({
        ...item,
        previousAmount: undefined
      }))
    }
  })),
  on(partialItemsActions.removeLastPartialItem, (state): BuildingProgressState => {
    const lastItemIndex = state.partialItems.counter - 1;
    const lastItem = state.partialItems.items[lastItemIndex];
    const decimalPlaces = state.constructionData.decimalPlaces;
    const firstItem = { ...state.partialItems.items[0] };

    firstItem.amount += lastItem.amount;
    firstItem.amount = round(firstItem.amount, decimalPlaces.amount);
    firstItem.percentage += lastItem.percentage;
    firstItem.percentage = round(firstItem.percentage, decimalPlaces.percentage);

    return {
      ...state,
      partialItems: {
        ...state.partialItems,
        items: [firstItem, ...state.partialItems.items.slice(1, lastItemIndex)],
        counter: state.partialItems.counter - 1,
      }
    };
  })
];
