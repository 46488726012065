import { Injectable } from '@angular/core';

import { SubSink } from 'subsink';

import {
  ColDef,
  ColDefTextAlign,
  ColDefType,
  DataTableConfigModel,
  DecimalPlaces,
  formatNumberValue,
  getColumnWidth,
  getTextCellHeight,
  itemTypeIsMaterial
} from '@kros-sk/ssw-shared-legacy';

import {
  BudgetApprovalDifferencesColumnsConfigModel,
  BudgetApprovalDifferencesItem,
  BudgetApprovalDifferencesModel,
  ChangeSheetColumn
} from '../models';
import { environment } from '../../../environments/environment';

@Injectable()
export class BudgetApprovalDifferencesTableService {
  averageLineHeight: number;

  private subs = new SubSink();

  getTableConfig(data: BudgetApprovalDifferencesModel): DataTableConfigModel {
    const columnsConfig = this.getColumnsConfig(data);
    this.averageLineHeight = columnsConfig.averageLineHeight;

    return {
      colDefs: this.getColDefs(data.decimalPlaces, columnsConfig),
      itemsOffset: columnsConfig.itemsOffset,
      isReadonly: true,
      rowClassGetter: (item: BudgetApprovalDifferencesItem): string =>
        (item.code?.length > 0 ? 'is-item' : '') +
        (item.isDifferentUnitPrice ? ' font-red' : ''),
      fixedWidth: true
    };
  }

  destroy(): void {
    this.subs.unsubscribe();
  }

  private getColDefs(
    decimalPlaces: DecimalPlaces,
    columnsConfig: BudgetApprovalDifferencesColumnsConfigModel
  ): ColDef[] {
    return [
      {
        id: ChangeSheetColumn.ItemType,
        title: 'CHANGE_SHEET.TYPE',
        type: ColDefType.Column,
        textAlign: ColDefTextAlign.Center,
        minWidth: 25,
        valueGetter: (item: BudgetApprovalDifferencesItem): any => item.itemType
      } as ColDef,
      {
        id: ChangeSheetColumn.Code,
        title: 'CHANGE_SHEET.CODE',
        type: ColDefType.Column,
        minWidth: 100,
        valueGetter: (item: BudgetApprovalDifferencesItem): any => item.code
      } as ColDef,
      {
        id: ChangeSheetColumn.Description,
        title: 'BUDGET_APPROVAL.DESCRIPTION_OR_OCCURENCE',
        type: ColDefType.Column,
        minWidth: 300,
        valueGetter: (item: BudgetApprovalDifferencesItem): any => item.description,
        cellClassGetter: (item: BudgetApprovalDifferencesItem): string => itemTypeIsMaterial(item?.itemType) ? 'font-blue' : ''
      } as ColDef,
      {
        id: ChangeSheetColumn.MeasureUnit,
        title: 'CHANGE_SHEET.MEASURE_UNIT',
        type: ColDefType.Column,
        minWidth: 50,
        valueGetter: (item: BudgetApprovalDifferencesItem): any => item.measureUnit
      } as ColDef,
      {
        id: ChangeSheetColumn.UnitPrice,
        title: 'CHANGE_SHEET.UNIT_PRICE',
        type: ColDefType.Column,
        format: this.formatNumberPrice.bind(this, decimalPlaces.unitPrice),
        textAlign: ColDefTextAlign.Right,
        minWidth: this.getColumnWidthFromNumberValue(columnsConfig.unitPrice, decimalPlaces.unitPrice),
        valueGetter: (item: BudgetApprovalDifferencesItem): any => item.unitPrice
      } as ColDef
    ];
  }

  private formatNumberPrice(decimalPlaces: number, value: any): string {
    return isNaN(value) ? '' : formatNumberValue(value, decimalPlaces, environment.location);
  }

  private getColumnWidthFromNumberValue(maxNumber: number, decimalPlaces: number): number {
    return Math.max(100, getColumnWidth(maxNumber, decimalPlaces, environment.location, true));
  }

  private getColumnsConfig(data: BudgetApprovalDifferencesModel): BudgetApprovalDifferencesColumnsConfigModel {
    const itemsOffset = [];
    let totalHeight = 0;
    let unitPrice = 0;

    data.items.forEach(p => {
      totalHeight += getTextCellHeight(p.description.length, 0, false, 300);
      itemsOffset.push(totalHeight);
      unitPrice = Math.max(unitPrice, p.unitPrice);
    });

    return {
      itemsOffset,
      averageLineHeight: Math.floor(totalHeight / data.items.length),
      unitPrice
    };
  }
}
