import { EntityState } from '@ngrx/entity';

import { BoqItem } from '@kros-sk/ssw-shared-legacy';
import { BuildingProgressPermission } from '@kros-sk/ssw-shared/permission';
import { BuildingProgressSettings } from '@kros-sk/ssw-shared/settings';

import {
  boqItemsInitialState,
  boqPeriodItemsInitialState,
  commentPositionsInitialState,
  commentsInitialState,
  notesInitialState,
  periodsInitialState,
  sharingListInitialState
} from './building-progress.adapters';
import { BoqItemViewModel } from '../../building-progress/models/boq-edit.view-model';
import { BoqPeriodItem } from '../../building-progress/models/boq-period-item.model';
import {
  BuildingProgressCell,
  BuildingProgressItem,
  BuildingProgressModel,
  BuildingProgressPeriodItem
} from '../../building-progress/models/construction-data.model';
import {
  BuildingProgressCommentModel, CommentPositionModel
} from '../../building-progress/modules/comments-panel/comments-panel/comment.model';
import { BuildingProgressPeriod } from '../../building-progress/models/building-progress-period.model';
import { BuildingProgressSharing } from '../../building-progress/models/building-progress-sharing/building-progress-sharing.model';
import { DataSettings } from '../../building-progress/models/data-settings.model';
import { ItemDetails } from '../../building-progress/models/item-period-details.model';
import { ItemDrawSheetExportSettings } from '../../building-progress/models/item-draw-sheet-export-settings.model';
import { Note } from '../../building-progress/models/note.model';
import { PartialBudgetItems } from '../../building-progress/models/building-progress-partial-budget-items.model';
import { RemainingBudgetExportSettings } from '../../building-progress/models/remaining-budget-export-settings.model';
import { SelectedView } from '../../building-progress/models/selected-view.model';
import { TableExportSettings } from '../../building-progress/models/table-export-settings.model';

export interface BuildingProgressState {
  editedBoqItems: BoqItemViewModel[];
  constructionData: BuildingProgressModel;
  comments: CommentsState;
  commentsLoading: boolean;
  commentPositions: CommentPositionsState;
  editingBoqBudgetItemIds: number[];
  deletedBoqItemIds: number[];
  permission: BuildingProgressPermission;
  periods: PeriodsState;
  wholeBuildingPeriods: PeriodsState;
  sharingList: SharingListState;
  insertedItem: BuildingProgressItem;
  periodId: number;
  periodsLoading: boolean;
  partialItemAmount: number;
  partialItems: PartialBudgetItems,
  cellToFocusAndAnimate: BuildingProgressCell[];
  selectedItemIds: Set<number>;
  lastEditedItemIds: string[];
  indeterminateItemIds: Set<number>;
  boqPeriodItems: BoqPeriodItemsState;
  boqItems: BoqItemsState;
  deletingItems: boolean;
  settings: BuildingProgressSettings;
  licenseAgreement: boolean;
  periodDetails: {
    itemId: number, period: BuildingProgressPeriodItem, details: ItemDetails, loading: boolean
  };
  selectedView: SelectedView;
  tableExportSettings: TableExportSettings;
  tableIsBusy: boolean;
  hasDocuments: boolean;
  resetOriginalItems: boolean;
  dataSettings: DataSettings;
  itemDrawSheetExportSettings: ItemDrawSheetExportSettings;
  remainingBudgetExportSettings: RemainingBudgetExportSettings;
  budgetCreateState: BuildingProgressBudgetCreateState;
  buildingProgressNotes: NotesState;
}

export const initialBuildingProgressState: BuildingProgressState = {
  editedBoqItems: [],
  constructionData: null,
  comments: commentsInitialState,
  commentsLoading: false,
  commentPositions: commentPositionsInitialState,
  editingBoqBudgetItemIds: [],
  deletedBoqItemIds: [],
  permission: undefined,
  periods: periodsInitialState,
  wholeBuildingPeriods: periodsInitialState,
  sharingList: sharingListInitialState,
  insertedItem: null,
  periodId: 0,
  periodsLoading: false,
  partialItemAmount: undefined,
  partialItems: null,
  cellToFocusAndAnimate: [],
  selectedItemIds: new Set<number>(),
  lastEditedItemIds: [],
  indeterminateItemIds: new Set<number>(),
  boqPeriodItems: boqPeriodItemsInitialState,
  boqItems: boqItemsInitialState,
  deletingItems: false,
  settings: undefined,
  licenseAgreement: undefined,
  periodDetails: undefined,
  selectedView: undefined,
  tableExportSettings: undefined,
  tableIsBusy: false,
  hasDocuments: false,
  resetOriginalItems: false,
  dataSettings: { hiddenColumns: [], isSummaryPanelVisible: true },
  itemDrawSheetExportSettings: undefined,
  remainingBudgetExportSettings: undefined,
  budgetCreateState: { budgetHasItems: false, isProcessing: false },
  buildingProgressNotes: notesInitialState
};

export type CommentsState = EntityState<BuildingProgressCommentModel>
export type CommentPositionsState = EntityState<CommentPositionModel>
export type PeriodsState = EntityState<BuildingProgressPeriod>
export type SharingListState = EntityState<BuildingProgressSharing>
export type NotesState = EntityState<Note>
export interface BoqPeriodItemsState extends EntityState<BoqPeriodItem> {
  isLoading: boolean;
}
export interface BoqItemsState extends EntityState<BoqItem> {
  isLoading: boolean;
}

export interface BuildingProgressBudgetCreateState {
  budgetHasItems: boolean;
  isProcessing: boolean;
}
