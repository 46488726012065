<div class="background-div">
  <h1
    class="h1"
    *ngIf="hasError; else loader"
  >{{'EXTERNAPPLICATION_ERROR' | translate}}</h1>

  <ng-template #loader>
    <kros-components-loader></kros-components-loader>
  </ng-template>
</div>
