import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { catchError } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { KrosModalRef } from '@kros-sk/components';
import {
  OpenApiGenerateTokenResponse,
  OpenApiSubscriptionApiModel,
  OpenApiSubscriptionCreateWithTokenResponse,
} from '@kros-sk/models';
import { ToastService, ToastType, TranslateService } from '@kros-sk/ssw-shared-legacy';

import { OpenApiService } from '../..';

@Component({
  selector: 'ssw-open-api-settings-token-modal',
  templateUrl: './open-api-settings-token-modal.component.html',
  styleUrls: ['./open-api-settings-token-modal.component.scss']
})
export class OpenApiSettingsTokenModalComponent implements OnInit, OnDestroy {
  copiedByUser = false;
  tokenControl = new FormControl({ value: '', disabled: true });
  copiedFromResult = false;

  private data: OpenApiSubscriptionApiModel;
  private subs = new SubSink();
  private _inProgress = true;

  constructor(
    private modalRef: KrosModalRef,
    private openApiService: OpenApiService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {
    this.data = modalRef.data;
  }

  get secret(): string {
    return this.tokenControl.getRawValue();
  }

  get inProgress(): boolean {
    return this._inProgress;
  }

  ngOnInit(): void {
    if (this.data.clientId) {
      this.generateToken();
    } else {
      this.saveAndGenerateToken();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  close(): void {
    if (this._inProgress) {
      this.modalRef.cancel();
    }
    this.modalRef.submit(this.data);
  }

  copyToken(): void {
    navigator.clipboard.writeText(this.secret)
      .then(_ => this.copiedByUser = true)
      .catch(_ => this.copiedByUser = false);
  }

  private generateToken(): void {
    this.subs.sink = this.openApiService.generateToken(this.data.clientId)
      .pipe(catchError(e => {
        this.handleErrorResponse();
        throw e;
      }))
      .subscribe(response => this.handleResponse(response));
  }

  private saveAndGenerateToken(): void {
    this.subs.sink = this.openApiService.createSubscriptionWithToken(this.data)
      .pipe(catchError(e => {
        this.handleErrorResponse();
        throw e;
      }))
      .subscribe(response => this.handleResponse(response));
  }

  private handleErrorResponse(): void {
    this.showGenerateTokenFailedToast();
    this._inProgress = false;
    // set timeout, so the error gets thrown
    setTimeout(_ => this.modalRef.cancel(), 100);
  }

  private handleResponse(response: OpenApiSubscriptionCreateWithTokenResponse | OpenApiGenerateTokenResponse): void {
    this.data = {
      ...this.data,
      secretHint: response.secretHint,
      clientId: response.clientId,
    };
    this.tokenControl.setValue(response.secret);
    navigator.clipboard.writeText(response.secret)
      .then(_ => {
        this._inProgress = false;
        this.copiedFromResult = true;
      })
      .catch(_ => this._inProgress = false);
  }

  private showGenerateTokenFailedToast(): void {
    this.toastService.open(
      this.translateService.translate('SETTINGS.API.GENERATE_TOKEN_ERROR'),
      ToastType.Error
    );
  }
}
