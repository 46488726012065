import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { AlertDialogComponent } from './alert-dialog.component';

@NgModule({
  declarations: [
    AlertDialogComponent
  ],
  imports: [
    CommonModule,
    NgbModalModule
  ],
  providers: [],
  exports: [
    AlertDialogComponent
  ]
})
export class AlertDialogModule {

}
