import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { SmsActivationEndpoints } from '../models';

@Injectable()
export class SmsActivationService {

  constructor(
    private http: HttpClient
  ) { }

  tryGetPhoneNumber(endpoints: SmsActivationEndpoints): Observable<string> {
    return this.http.get(endpoints.tryGetPhoneNumber, { responseType: 'text' });
  }

  checkPhoneNumberAvailability(endpoints: SmsActivationEndpoints, phoneNumber: string): Observable<any> {
    return this.http.post(endpoints.checkPhoneNumberAvailability, { phoneNumber });
  }

  sendCode(endpoints: SmsActivationEndpoints, confirmationCode: string): Observable<any> {
    return this.http.post(endpoints.sendCode, { confirmationCode });
  }
}
