import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import * as buildingProgressActions from './building-progress-comments.actions';
import { BuildingProgressCommentsDispatchersService } from './building-progress-comments-dispatchers.service';
import { BuildingProgressService } from '../../building-progress/services';

@Injectable()
export class BuildingProgressCommentsEffects {
  getComments$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.loadCommentsStart),
      switchMap(payload => this.buildingProgressService.getComments(payload.projectId, payload.contractorId).pipe(
        map(comments => buildingProgressActions.loadCommentsSuccess({ comments })),
        catchError(error => of(buildingProgressActions.loadCommentsError({ error })))
      ))
    );
  });

  getCommentPositions$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.loadCommentPositionsStart),
      switchMap(payload => this.buildingProgressService.getCommentPositions(payload.projectId, payload.contractorId).pipe(
        map(commentPositions => buildingProgressActions.loadCommentPositionsSuccess({ commentPositions })),
        catchError(error => of(buildingProgressActions.loadCommentPositionsError({ error })))
      ))
    );
  });

  createComment$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.createCommentStart),
      switchMap(payload => this.buildingProgressService.createComment(payload.comment, payload.contractorId).pipe(
        map(comment => {
          this.buildingProgressCommentsDispatchersService.loadComments(payload.comment.projectId, payload.contractorId);
          this.buildingProgressCommentsDispatchersService.loadCommentPositions(payload.comment.projectId, payload.contractorId);
          return buildingProgressActions.createCommentSuccess({ comment });
        }),
        catchError(error => of(buildingProgressActions.createCommentError({ error })))
      ))
    );
  });

  updateComment$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.updateCommentStart),
      switchMap(payload => this.buildingProgressService.updateComment(payload.comment).pipe(
        map(() => buildingProgressActions.updateCommentSuccess({ comment: payload.comment })),
        catchError(error => of(buildingProgressActions.updateCommentError({ error })))
      ))
    );
  });

  deleteComment$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.deleteCommentStart),
      switchMap(payload => this.buildingProgressService.deleteComment(payload.commentId, payload.projectId, payload.contractorId).pipe(
        map(() => {
          this.buildingProgressCommentsDispatchersService.loadCommentPositions(payload.projectId, payload.contractorId);
          return buildingProgressActions.deleteCommentSuccess({ commentId: payload.commentId });
        }),
        catchError(error => of(buildingProgressActions.deleteCommentError({ error })))
      ))
    );
  });

  publishComment$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.publishCommentsStart),
      switchMap(payload => this.buildingProgressService.publishComments(payload.projectId, payload.contractorId).pipe(
        map(() => {
          this.buildingProgressCommentsDispatchersService.loadComments(payload.projectId, payload.contractorId);
          return buildingProgressActions.publishCommentsSuccess();
        }),
        catchError(error => of(buildingProgressActions.publishCommentsError({ error })))
      ))
    );
  });

  constructor(
    private actions$: Actions,
    private buildingProgressCommentsDispatchersService: BuildingProgressCommentsDispatchersService,
    private buildingProgressService: BuildingProgressService,
  ) { }
}
