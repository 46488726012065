import { Component, EventEmitter, Input, OnInit, Output, } from '@angular/core';

import { KrosModalService } from '@kros-sk/components';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { DeleteDocumentService, DocumentCreateService } from '../../services';
import { DocumentsExplorerSelectorsService, SelectionService } from '../../../document-explorer';
import { FolderCreateDialogComponent } from '../folder-create-dialog/folder-create-dialog.component';
import { TranslateService } from '../../../translate';
import { UploaderService } from '../../../upload';

@Component({
  selector: 'kros-document-create',
  templateUrl: './document-create.component.html',
  styleUrls: ['./document-create.component.scss']
})
export class DocumentCreateComponent extends UnsubscribeComponent implements OnInit {
  projectId: number;

  @Input() parentId: string;
  @Input() canAddDocumentVersion: boolean;
  @Input() documentId: string;
  @Input() contentType: string;
  @Input() onlyCreateFolder: boolean;

  @Output() filesSelected = new EventEmitter<void>();
  @Output() fileAddVersionSelected = new EventEmitter<void>();

  constructor(
    public uploaderService: UploaderService,
    public selection: SelectionService,
    private deleteDocumentService: DeleteDocumentService,
    private documentCreateService: DocumentCreateService,
    private translateService: TranslateService,
    private krosModalService: KrosModalService,
    private documentsExplorerSelectorsService: DocumentsExplorerSelectorsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.documentsExplorerSelectorsService.projectId$.subscribe(id => this.projectId = id);
    this.subs.sink = this.documentCreateService.createFolder$.subscribe(() => this.createFolder());
  }

  createFolder(): void {
    this.deleteDocumentService.dismissDeleteToast();

    this.krosModalService.openCentered(
      FolderCreateDialogComponent,
      {
        projectId: this.projectId,
        parentId: this.parentId,
        dateCreated: new Date(),
        name: this.translateService.translate('DOCUMENTS.NEW_FOLDER')
      },
      {
        allowFocusAutocapture: false,
        closeOnBackdropClick: false,
        showMobileArrowBack: true,
        fullscreenOnMobile: true
      }
    );
  }

  onFilesSelected(): void {
    this.filesSelected.emit();
  }

  onFileAddVersionSelected(): void {
    this.fileAddVersionSelected.emit();
  }
}
