import produce from 'immer';

import { Tag } from './tag.model';
import { TagType } from './tags.enum';
import { TranslateService } from '../translate';

export const getSortedTags = (tags: Tag[]): Tag[] => {
  const sortedSystemTags = tags.filter(t => t.type === TagType.Project).sort((tagA, tagB) => tagB.id - tagA.id);
  const sortedUserTags = tags.filter(t => t.type === TagType.User).sort((tagA, tagB) => tagA.name.localeCompare(tagB.name));
  const sortedTags = sortedSystemTags.concat(sortedUserTags);
  return sortedTags;
};

export const tagsColorCodes = [
  { colorCode: '#FADEE2', textColorCode: '#DA1F3C' },
  { colorCode: '#FCE9E0', textColorCode: '#E86E30' },
  { colorCode: '#FDF3DE', textColorCode: '#F3AC1F' },
  { colorCode: '#DEF3ED', textColorCode: '#1FAE84' },
  { colorCode: '#D9F8EF', textColorCode: '#00CD90' },
  { colorCode: '#D9E2EA', textColorCode: '#003974' },
  { colorCode: '#D9EFFA', textColorCode: '#0095DA' },
  { colorCode: '#EFE7F1', textColorCode: '#9260A0' },
  { colorCode: '#F6DBFD', textColorCode: '#C00DF2' },
  { colorCode: '#D9D9D9', textColorCode: '#4D4D4D' },
  { colorCode: '#EAEAED', textColorCode: '#6F7585' },
];

export const updateTagInTagsList = (tagsList: Tag[], updatedTag: Tag): Tag[] => {
  return produce(tagsList, newData => {
    const tag = newData.find(item => item.id === updatedTag.id);
    tag.name = updatedTag.name;
    tag.colorCode = updatedTag.colorCode;
    tag.textColorCode = updatedTag.textColorCode;
  });
};

export const getTagName = (key: string, translateService: TranslateService): string => {
  if (key.startsWith('TAGS.')) {
    return translateService.translate(key);
  }
  return key;
};
