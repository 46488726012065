import { BudgetPermission, BuildingProgressPermission } from '../models';

export const permissionFeatureKey = 'permissions';

export interface PermissionState {
  budgetPermission: BudgetPermission;
  buildingProgressPermission: BuildingProgressPermission;
}

export const permissionInitialState: PermissionState = {
  budgetPermission: null,
  buildingProgressPermission: null
};
