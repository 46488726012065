import { createReducer, on } from '@ngrx/store';

import { FeatureFlags } from '../types/feature-flags.types';
import { featureFlagsActions } from './feature-flags.actions';
import { FeatureFlagsState } from './feature-flags.state';

export const INITIAL_STATE: FeatureFlagsState = {
  featureFlags: {},
  featuresLoaded: false,
};

export const featureFlagsStoreReducer = createReducer(
  INITIAL_STATE,
  on(featureFlagsActions.loadFeaturesSuccess,
    (_, payload): FeatureFlagsState =>
      ({featureFlags: payload.features, featuresLoaded: true})
  ),
  on(featureFlagsActions.loadFeaturesFail,
    (): FeatureFlagsState => ({featureFlags: new FeatureFlags(), featuresLoaded: true})
  ),
);
