import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HubConnectionState } from '@microsoft/signalr';
import { Subject, take } from 'rxjs';

import {
  AsyncOperationState,
  BudgetImport,
  ConnectorServiceInterface,
  ToastService,
  ToastType,
  TranslateService
} from '@kros-sk/ssw-shared-legacy';
import { ConnectorBaseService, RegistrationNotificationService } from '@kros-sk/ssw-cdk';
import { KrosModalService, MessageTypes } from '@kros-sk/components';

import { BuildingProgressBudgetCreationFinished } from '../models/building-progress-budget-creation-finished.model';
import { UserDataStatisticsModel } from '../models/user-data-statistics.model';
import { UserDataStatisticsService } from './user-data-statistics/user-data-statistics.service';

@Injectable({
  providedIn: 'root'
})
export class ConnectorService extends ConnectorBaseService implements ConnectorServiceInterface {

  documentVersionProcessingFailed$ = new Subject<void>();
  buildingProgressBudgetCreationFinished$ = new Subject<any>();

  constructor(
    private notificationService: RegistrationNotificationService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private userDataStatisticsService: UserDataStatisticsService,
    private router: Router,
    private krosModalService: KrosModalService
  ) {
    super();
  }

  connect(connectionId: string): void {
    super.connect('titanHub', connectionId);
  }

  write(dataToSend: any, eventName: string): void {
    if (!super.getConnection()) {
      super.connect('titanHub', super.getConnectionId());
    } else if (super.getConnection().state === HubConnectionState.Disconnected) {
      super.tryConnect();
    }

    super.getConnection().send(eventName, JSON.stringify(dataToSend));
  }

  protected registerHandlers(): void {
    super.getConnection().on(
      'ProjectCopyFinished',
      (connectionId: string, isDemoProject: boolean) => {
        if (isDemoProject && super.getConnectionId() === connectionId) {
          this.onDemoProjectCreated();
        }
      }
    );

    super.getConnection().on(
      'UserDataStatisticsChanged',
      (statistics: UserDataStatisticsModel) => this.userDataStatisticsService.updateConsumedData(statistics.dataConsumed)
    );

    super.getConnection().on(
      'DocumentVersionProcessingFailed',
      (name: string) => this.onDocumentVersionProcessingFailed(name)
    );

    super.getConnection().on(
      'BuildingProgressBudgetCreationFinished',
      (data: BuildingProgressBudgetCreationFinished) => this.onBuildingProgressBudgetCreationFinished(data)
    );

    super.getConnection().on(
      'BudgetImportMessage',
      (data: BudgetImport) => this.onBudgetImportMessage(data)
    );
  }

  private onDemoProjectCreated(): void {
    this.notificationService.notifyDemoProjectCreated();
  }

  private onDocumentVersionProcessingFailed(name: string): void {
    this.documentVersionProcessingFailed$.next();
    this.toastService.open(
      this.translateService.translateWithParam('DOCUMENTS.DOCUMENT_PROCESS_FAILED_NOTIFICATION', name),
      ToastType.Error,
      { autohide: false });
  }

  private onBuildingProgressBudgetCreationFinished(data: BuildingProgressBudgetCreationFinished): void {
    if (super.getConnectionId() === data.connectionId) {
      this.buildingProgressBudgetCreationFinished$.next({ projectId: data.projectId, isSuccess: data.isSuccess });
      if (data.isSuccess) {
        this.toastService.open(this.translateService.translate('BUILDING_PROGRESS.CREATE_SUCCESS'));
      } else {
        this.toastService.open(
          this.translateService.translate('BUILDING_PROGRESS.CREATE_FAILED'),
          ToastType.Error);
      }
    }
  }

  private onBudgetImportMessage(data: BudgetImport): void {
    const budgetUrlRegex = /^\/projects\/project-detail\/\d*\/budget/;
    if (super.getConnectionId() === data.connectionId && !budgetUrlRegex.test(this.router.url)) {
      if (data.state === AsyncOperationState.Succeeded) {
        const toastRef = this.toastService.open(
          this.translateService.translate('BUDGET.IMPORT_EXCEL.STATUS.SUCCEEDED'),
          undefined,
          undefined,
          this.translateService.translate('BUDGET.IMPORT_EXCEL.GO_TO_BUDGET'),
        );
        toastRef?.onAction().pipe(take(1)).subscribe(() => this.navigateToBudget(data.projectId));
      } else if (data.state === AsyncOperationState.Failed) {
        this.krosModalService.openModalMessageBox({
          icon: 'warning',
          caption: this.translateService.translate('BUDGET.IMPORT_EXCEL.STATUS.FAILED'),
          message: this.translateService.translate('BUDGET.IMPORT_EXCEL.STATUS.FAILED_MESSAGE'),
          messageType: MessageTypes.Warning,
          acceptButton: this.translateService.translate('BUDGET.IMPORT_EXCEL.GO_TO_BUDGET'),
          cancelButton: this.translateService.translate('COMMON.CLOSE')
        }).pipe(take(1)).subscribe(result => {
          if (result?.data === 'accept') {
            this.navigateToBudget(data.projectId);
          }
        });
      }
    }
  }

  private navigateToBudget(projectId: number): void {
    this.router.navigateByUrl(`/projects/project-detail/${projectId}/budget`);
  }
}
