import { TranslateService } from './translate.service';

export class DynamicLocaleId extends String {
  constructor(private service: TranslateService) {
    super();
  }

  toString(): string {
    return this.service.currentLang;
  }
}
