import { Directive, Input } from '@angular/core';

import { TooltipPosition } from '@kros-sk/ui/tooltip';

@Directive({
  selector: 'kros-input-hint, [krosInputHint]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'kros-form-field-hint',
  },
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class KrosInputHint {
  @Input() align: TooltipPosition = 'above';
}
