import { Injectable } from '@angular/core';

import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { filter, map } from 'rxjs';

import { deviceDetectorActions } from './device-detector.actions';
import { DeviceDetectorService } from '../../utils/device-detector.service';
import { fromDeviceDetector } from './device-detector.selectors';
import { GlobalEventsListenerService } from '../../utils/global-events-listener.service';

@Injectable()
export class DeviceDetectorEffects implements OnInitEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly globalEvents: GlobalEventsListenerService,
    private readonly deviceDetector: DeviceDetectorService,
    private readonly store: Store
  ) {}

  readonly initialDetectDevice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deviceDetectorActions.initialized),
      map(() => this.deviceDetector.detect()),
      map((detectedDevice) => deviceDetectorActions.detected({ detectedDevice }))
    );
  });

  readonly detectResizeRelated$ = createEffect(() => {
    return this.globalEvents.listenEvent('window:resize').pipe(
      concatLatestFrom(() => this.store.select(fromDeviceDetector.selectDeviceType)),
      map(([_, currentDeviceType]) => ({
        currentDeviceType,
        detectedDevice: this.deviceDetector.detectDeviceType(),
      })),
      filter(({ currentDeviceType, detectedDevice }) => currentDeviceType !== detectedDevice),
      map(({ detectedDevice }) => deviceDetectorActions.detected({ detectedDevice: { deviceType: detectedDevice } }))
    );
  });

  ngrxOnInitEffects(): Action {
    return deviceDetectorActions.initialized();
  }
}
