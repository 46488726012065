import { ActivatedRoute } from '@angular/router';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { Cell, getFocusedCellElementId, IBudgetItem, isSectionOrItem, PanelHelper, Position } from '@kros-sk/ssw-shared-legacy';
import { DeviceDetectorService, DeviceType } from '@kros-sk/components';

import { BuildingProgressDispatchersService, BuildingProgressPeriodDispatchersService } from '../../store/building-progress';
import { BuildingProgressItem, BuildingProgressModel } from '../models/construction-data.model';
import { BuildingProgressPanelEnum } from '../models/building-progress-panel.enum';
import { BuildingProgressSelectorsService } from '../../store/building-progress/building-progress-selectors.service';
import { CommentComponentModel } from '../modules/comments-panel/comments-panel/comment.model';
import { ItemDetails } from '../models/item-period-details.model';
import { NoteSetModel } from '../models/note.model';
import { PeriodHelper } from './period.helper';
import { VisualHelper } from './visual.helper';

const commentsWidth = 320;
const periodsWidth = 400;
const detailsWidth = 400;
const notesWidth = 400;

@Injectable()
export class BuildingProgressPanelHelper {

  private panelHelper = inject(PanelHelper);
  private periodService = inject(PeriodHelper);
  private periodDispatchersService = inject(BuildingProgressPeriodDispatchersService);
  private buildingProgressDispatchersService = inject(BuildingProgressDispatchersService);
  private buildingProgressSelectorsService = inject(BuildingProgressSelectorsService);
  private visualHelper = inject(VisualHelper);
  private appInsightsService = inject(AppInsightsBaseService);
  private deviceDetector = inject(DeviceDetectorService);
  private destroyRef = inject(DestroyRef);

  private wasRowChanged = false;
  private wasCellChanged = false;
  private getItemIdByRowIndex: Function;
  private reloadBudgetItemId: number;
  private editingBoqBudgetItemIds: number[];
  private contractorId: number;
  private deviceType: DeviceType;

  commentData: CommentComponentModel;
  rightPanelType: BuildingProgressPanelEnum;
  bottomPanelType: BuildingProgressPanelEnum;
  notesPanelItemId: number;
  projectId: number;

  reloadNotesPanelData$ = new ReplaySubject<number>(1);

  get selectedPeriodId(): number {
    return this.periodService.selectedPeriodId;
  }

  get focusedCell(): Cell {
    return this.visualHelper.focusedCell;
  }

  get isFocusedCellInPeriod(): boolean {
    return !!this.focusedCell && this.focusedCell.params && this.focusedCell.params.periodId;
  }

  get boqBudgetItem(): IBudgetItem {
    return this.panelHelper.boqBudgetItem;
  }

  get isMobileDevice(): boolean {
    return this.deviceType === DeviceType.Mobile;
  }

  get isNotesPanelActive(): boolean {
    return this.rightPanelType === BuildingProgressPanelEnum.Notes;
  }

  init(
    projectId: number,
    getItemIdByRowIndex: Function,
    showPanel: (position: Position, size: number) => void,
    hidePanel: (position: Position) => void,
    resizePanel: Function
  ): void {
    this.buildingProgressSelectorsService.editingBoqBudgetItemIds$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(editingBoqBudgetItemIds => {
        this.editingBoqBudgetItemIds = editingBoqBudgetItemIds;
        if (this.reloadBudgetItemId) {
          this.buildingProgressDispatchersService.loadBoqPeriodItems(this.projectId, this.reloadBudgetItemId);
        }
      });
    this.buildingProgressSelectorsService.constructionData$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(c => {
        if (c && this.panelHelper.boqBudgetItem) {
          this.panelHelper.boqBudgetItem = c.items.find(i => i.id === this.panelHelper.boqBudgetItem.id);
        }
      });
    this.panelHelper.focusedBoqBudgetItem$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(item => {
        this.wasRowChanged = true;
        this.panelHelper.boqBudgetItem = item;
      });
    this.panelHelper.focusedCellChanged$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((item) => {
        this.wasCellChanged = true;

        if (item && this.isNotesPanelActive) {
          this.notesPanelItemId = isSectionOrItem(item) ? item.id : -1;
          this.reloadNotesData();
        }

        if (this.panelHelper.isBoqPanelActive && this.panelHelper.boqBudgetItem) {
          this.openBoqCore();
        }
      });
    this.panelHelper.loadBoqItems$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((budgetItemId: number) => {
        this.loadBoqByPanelType(budgetItemId);

        this.wasRowChanged = false;
        this.wasCellChanged = false;
      });
    this.deviceDetector.deviceType$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(type => {
        this.deviceType = type;
        if (type === DeviceType.Mobile) {
          this.onCloseBoq();
        }
      });

    this.panelHelper.init(showPanel, hidePanel, resizePanel);
    this.projectId = projectId;
    this.contractorId = undefined;
    this.getItemIdByRowIndex = getItemIdByRowIndex;
  }

  setContractorId(contractorId: number): void {
    this.contractorId = contractorId;
  }

  destroy(): void {
    this.onCloseBoq();
  }

  private isBudgetColumn(colId: string): boolean {
    return ['itemType', 'itemNumber', 'code', 'description', 'completedPercentagePie', 'measureUnit',
      'amount', 'unitPrice', 'totalPrice'].some(id => id === colId);
  }

  onCloseComments(): void {
    this.panelHelper.hidePanel(Position.Right);
    this.rightPanelType = undefined;
    this.commentData = undefined;
  }

  onClosePeriods(): void {
    this.panelHelper.hidePanel(Position.Right);
    this.rightPanelType = undefined;
    this.scrollToSelectedPeriod();
  }

  onCloseBoq(): void {
    this.panelHelper.onCloseBoq();
    this.bottomPanelType = undefined;
  }

  openComments(projectId: number, scrollToComment: boolean, contractorId?: number): void {
    this.setCommentData(projectId, scrollToComment, contractorId);
    this.rightPanelType = BuildingProgressPanelEnum.Comments;
    this.panelHelper.showPanel(Position.Right, commentsWidth);

    if (this.focusedCell) {
      this.visualHelper.scrollElementToView(
        getFocusedCellElementId(this.focusedCell), false, getFocusedCellElementId(this.focusedCell)
      );
    }
  }

  openBoq(projectId: number, budgetItem: IBudgetItem): void {
    if (budgetItem) {
      this.projectId = projectId;
      this.panelHelper.boqBudgetItem = budgetItem;
      this.openBoqCore();
    }
  }

  private openBoqCore(): void {
    const newPanelType = this.isBudgetColumn(this.visualHelper.focusedCell.colId)
      ? BuildingProgressPanelEnum.Boq
      : BuildingProgressPanelEnum.BoqPeriods;
    const loadBoq = (this.wasCellChanged && newPanelType === BuildingProgressPanelEnum.BoqPeriods) ||
      (this.wasRowChanged && newPanelType === BuildingProgressPanelEnum.Boq) ||
      newPanelType !== this.bottomPanelType;

    if (loadBoq) {
      this.bottomPanelType = newPanelType;

      if (this.bottomPanelType === BuildingProgressPanelEnum.BoqPeriods) {
        if (this.editingBoqBudgetItemIds && !this.editingBoqBudgetItemIds.includes(this.panelHelper.boqBudgetItem.id)) {
          this.reloadBudgetItemId = undefined;
        } else {
          this.reloadBudgetItemId = this.panelHelper.boqBudgetItem.id;
        }
      }
    }
    this.panelHelper.openBoq(this.panelHelper.boqBudgetItem, loadBoq);
  }

  loadBoqByPanelType(budgetItemId: number): void {
    if (this.bottomPanelType === BuildingProgressPanelEnum.Boq) {
      this.buildingProgressDispatchersService.loadBoqItems(this.projectId, budgetItemId, this.contractorId);
    } else {
      this.buildingProgressDispatchersService.loadBoqPeriodItems(this.projectId, budgetItemId, this.contractorId);
    }
  }

  resizeBoq(): void {
    this.panelHelper.resizeBoq();
  }

  private scrollToSelectedPeriod(): void {
    const elementId = ('details-') + this.selectedPeriodId + '-row-' + 1;
    this.visualHelper.scrollElementToView(elementId, false, 'head-grouped-col-period-' + this.selectedPeriodId);
  }

  openPeriodsAndSelectPeriod(constructionData: BuildingProgressModel, activatedRoute: ActivatedRoute): void {
    const galleryPeriodId = activatedRoute.snapshot.queryParamMap.get('galleryPeriodId');
    const periodIdParam = activatedRoute.snapshot.paramMap.get('periodId') ?? galleryPeriodId;

    const selectedPeriodId = periodIdParam
      ? +periodIdParam
      : (constructionData.periods.length
        ? constructionData.periods[constructionData.periods.length - 1].id
        : undefined);
    this.periodDispatchersService.selectPeriodId(selectedPeriodId);

    if (periodIdParam && !galleryPeriodId) {
      this.onOpenPeriods();
    } else {
      this.onClosePeriods();
    }
  }

  onOpenPeriods(): void {
    this.commentData = undefined;
    this.rightPanelType = BuildingProgressPanelEnum.Periods;
    this.panelHelper.showPanel(Position.Right, periodsWidth);
    setTimeout(() => this.scrollToSelectedPeriod(), 0);
  }

  setCommentData(projectId: number, scrollToComment: boolean, contractorId?: number): void {
    this.commentData = {
      projectId,
      contractorId
    };

    if (this.isFocusedCellInPeriod) {
      const budgetItemId = this.getItemIdByRowIndex(this.focusedCell.rowIndex);
      if (budgetItemId) {
        this.commentData.budgetItemId = budgetItemId;
        this.commentData.periodId = this.focusedCell.params.periodId;
        this.commentData.periodColumn = this.focusedCell.params.periodColumn;
        this.commentData.scrollToComment = scrollToComment;
      }
    }
  }

  openDetails(itemId: number): void {
    const alreadyShown = this.rightPanelType === BuildingProgressPanelEnum.Details;
    if (!alreadyShown) {
      this.commentData = undefined;
      this.rightPanelType = BuildingProgressPanelEnum.Details;
      this.panelHelper.showPanel(Position.Right, detailsWidth);
      setTimeout(() => this.scrollToSelectedPeriod(), 0);
    }

    this.buildingProgressSelectorsService.constructionData$.pipe(take(1)).subscribe(data => {
      if (this.visualHelper.focusedCell && data?.items?.length) {
        this.visualHelper.focusedCell.rowIndex = data.items.findIndex(item => item.id === itemId);
      }
    });

    this.appInsightsService.trackEvent('PV-details-open');
  }

  onCloseDetails(): void {
    this.panelHelper.hidePanel(Position.Right);
    this.rightPanelType = undefined;
    this.periodDispatchersService.closePeriodDetailsPanel();
  }

  onSaveDetails(details: ItemDetails): void {
    this.buildingProgressSelectorsService.periodDetails$
      .pipe(take(1))
      .subscribe(actualDetails => {
        this.periodDispatchersService.savePeriodDetails(actualDetails.itemId, actualDetails.period, details);
        this.appInsightsService.trackEvent('PV-details-' + (actualDetails.period.detailId ? 'edit' : 'create'));
      });
  }

  setDetailsData(item: BuildingProgressItem): void {
    if (item && this.rightPanelType === BuildingProgressPanelEnum.Details && this.isFocusedCellInPeriod) {
      const period = item.periods.find(period => period?.periodId === this.focusedCell.params.periodId);
      if (period) {
        this.periodDispatchersService.openPeriodDetailsPanel(item.id, period);
      }
    }
  }

  openNotes(projectId: number, budgetItem: IBudgetItem): void {
    this.projectId = projectId;

    if (isSectionOrItem(budgetItem)) {
      this.notesPanelItemId = budgetItem.id;
    } else {
      this.notesPanelItemId = -1;
    }

    if (!this.isNotesPanelActive) {
      this.rightPanelType = BuildingProgressPanelEnum.Notes;
      this.panelHelper.showPanel(Position.Right, notesWidth);
    }

    this.appInsightsService.trackEvent('PV-notes-open');
  }

  openSubcontractorNotes(projectId: number, budgetItem: IBudgetItem): void {
    if (budgetItem) {
      this.openNotes(projectId, budgetItem);
    }
  }

  onCloseNotes(): void {
    this.panelHelper.hidePanel(Position.Right);
    this.rightPanelType = undefined;
  }

  onSaveNotes(note: NoteSetModel): void {
    this.buildingProgressDispatchersService.saveNote(this.projectId, note);
    this.appInsightsService.trackEvent('PV-notes-edit');
  }

  reloadNotesData(): void {
    this.reloadNotesPanelData$.next(this.notesPanelItemId);
  }
}
