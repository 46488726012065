import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CommentsModule, TranslateModule } from '@kros-sk/ssw-shared-legacy';

import { CommentsPanelComponent } from './comments-panel/comments-panel.component';

@NgModule({
  declarations: [
    CommentsPanelComponent
  ],
  imports: [
    CommonModule,
    CommentsModule,
    TranslateModule.forChild()
  ],
  exports: [
    CommentsPanelComponent
  ]
})
export class CommentsPanelModule { }
