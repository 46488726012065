<app-upload-building-strip
  *ngIf="isInDocuments && (documentsSelectorsService.isUploadingMode$ | async)"
  [isInDocuments]="true"
></app-upload-building-strip>

<div class="project-detail">
  <div
    class="project-detail-wrapper"
    [ngClass]="{'collapsed': panelCollapsed}"
  >
    <div class="panel-container">
      <div class="header">
        <h5 class="header-text">{{ 'PROJECTS.PROJECT' | translate }}</h5>

        <div
          class="collapse-button"
          (click)="toggleCollapsePanel()"
          data-test="project-detail-collapse"
        >
          <span class="material-icons icon-button">
            {{ panelCollapsed ? 'keyboard_double_arrow_right' : 'keyboard_double_arrow_left' }}
          </span>
        </div>
      </div>

      <app-project-detail-navigation [collapsed]="panelCollapsed"></app-project-detail-navigation>

      <div class="footer">
        <app-user-data-statistics class="user-statistics"></app-user-data-statistics>
      </div>
    </div>

    <div class="detail-container">
      <router-outlet class="detail"></router-outlet>
    </div>
  </div>
</div>
