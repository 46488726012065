import { Component, ContentChild, ElementRef, EventEmitter, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent {
  @ContentChild('leftContent') leftContent: TemplateRef<ElementRef>;
  @ContentChild('rightContent') rightContent: TemplateRef<ElementRef>;

  @Output() defaultClick = new EventEmitter<void>();

  constructor() { }

  onDefaultViewClick(): void {
    this.defaultClick.emit();
  }
}
