import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';

import { fromEvent, merge, NEVER, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface KrosOptionMouseAction {
  index: number;
  event: MouseEvent;
}

@Component({
  selector: 'kros-option',
  template: '<ng-content></ng-content>'
})
export class KrosOptionComponent implements OnInit {
  @Input() value: string;
  @Input() index: number;
  @Input() isFooterOption = false;

  click$: Observable<string>;
  mouseAction$: Observable<KrosOptionMouseAction>;

  @HostBinding('class.dropdown-item') alwaysTrue = true;

  @HostBinding('class.focus') focused = false;

  @HostBinding('class.active') active = false;

  constructor(private host: ElementRef) {}

  ngOnInit(): void {
    if (this.isFooterOption) {
      this.click$ = NEVER;
    } else {
      this.click$ = fromEvent(this.element, 'click').pipe(map(() => this.value));
    }

    this.mouseAction$ = merge(
      fromEvent(this.element, 'mouseenter'),
      fromEvent(this.element, 'mouseleave')
    ).pipe(
      map((evt: MouseEvent) => ({
        index: this.index,
        event: evt
      }))
    );
  }

  get element(): any {
    return this.host.nativeElement;
  }

  focus(): void {
    this.focused = true;
  }

  blur(): void {
    this.focused = false;
  }

  setActive(status: boolean): void {
    this.active = status;
  }
}
