export enum CostType {
  None = 0,
  Hsv = 1,
  Psv = 2,
  M = 4,
  MNosny = 8,
  Hzs = 16,
  Vrn = 32,
  Ost = 64
}

// eslint-disable-next-line no-bitwise
export const anyCostTypes = CostType.Hsv | CostType.Psv | CostType.M | CostType.MNosny | CostType.Hzs | CostType.Vrn | CostType.Ost;

export enum BudgetItemPositionType {
  ToSpecifiedSection = 1,
  BeforeCurrentItem = 2,
  AfterCurrentItem = 3,
  AsChild = 4,
  AsFirstChild = 5
}
