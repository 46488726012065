import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { BudgetStructureComponent } from './budget-structure/budget-structure.component';
import { BudgetStructurePanelContainerComponent } from './budget-structure-panel-container/budget-structure-panel-container.component';
import { DirectivesModule } from '../directives';
import { PipesModule } from '../pipes';
import { TimelineModule } from '../timeline/timeline.module';
import { TranslateModule } from '../translate';

@NgModule({
  imports: [
    CommonModule,
    CdkTreeModule,
    DirectivesModule,
    PipesModule,
    TranslateModule.forChild(),
    TimelineModule,
    TooltipDirective
  ],
  declarations: [
    BudgetStructureComponent,
    BudgetStructurePanelContainerComponent
  ],
  exports: [
    BudgetStructureComponent,
    BudgetStructurePanelContainerComponent
  ]
})
export class BudgetStructureModule { }
