import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

import { BehaviorSubject, catchError, NEVER } from 'rxjs';
import { SubSink } from 'subsink';

import { KrosAppInsightsService } from '@kros-sk/core';

import { dayKeyCountPluralisation } from '../../tools';
import { KrosModalRef } from '../../kros-modal';
import { KrosValidators } from '../../validators';
import { SmsActivationEndpoints } from '../models';
import { SmsActivationService } from '../service/sms-activation.service';

const PHONE_PREFIX = '+421';
const DEFAULT_TRIAL_DURATION = 7;
const DEFAULT_APPLICATION_NAME_KEY = 'LICENSE_ACTIVATION.SMS_ACTIVATION.APPLICATION_NAME';

@Component({
  selector: 'kros-sms-activation-modal',
  templateUrl: './sms-activation-modal.component.html',
  styleUrls: ['./sms-activation-modal.component.scss']
})
export class SmsActivationModalComponent implements OnInit, OnDestroy {
  form: FormGroup<{
    phoneNumber: FormControl<string>,
    code: FormControl<string>
  }>;
  phoneNumberSubmitted = false;
  canTryAgain = false;
  codeExpired = false;
  inProgress$ = new BehaviorSubject<boolean>(false);
  trialDuration = DEFAULT_TRIAL_DURATION;
  applicationNameKey = DEFAULT_APPLICATION_NAME_KEY;
  appInsightsPrefix = '';
  trialDayPluralisation: string;

  @ViewChild('phoneNumberInput', { static: false }) phoneNumberInput: ElementRef;
  @ViewChild('codeInput', { static: false }) codeInput: ElementRef;

  private subs = new SubSink();
  private endpoints: SmsActivationEndpoints;

  constructor(
    private appInsightsService: KrosAppInsightsService,
    private modalRef: KrosModalRef,
    private fb: FormBuilder,
    private smsActivationService: SmsActivationService,
  ) {
    if (this.modalRef.data?.trialDuration) {
      this.trialDuration = this.modalRef.data.trialDuration;
    }
    if (this.modalRef.data?.appInsightsPrefix) {
      this.appInsightsPrefix = this.modalRef.data.appInsightsPrefix;
    }
    if (this.modalRef.data?.endpoints) {
      this.endpoints = this.modalRef.data.endpoints;
    }
    if (this.modalRef.data?.applicationNameKey) {
      this.applicationNameKey = this.modalRef.data.applicationNameKey;
    }
    this.trialDayPluralisation = dayKeyCountPluralisation(this.trialDuration, 'LICENSE_INFO.DAY');
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      phoneNumber: new FormControl<string>(
        PHONE_PREFIX,
        {
          validators: [Validators.required, KrosValidators.internationalPhoneNumber]
        }
      ),
      code: new FormControl<string>(
        '',
        {
          validators: [Validators.required]
        }
      ),
    });

    this.subs.sink = this.smsActivationService.tryGetPhoneNumber(this.endpoints).subscribe(phoneNumber => {
      if (phoneNumber && phoneNumber.trim() !== '') {
        this.form.controls.phoneNumber.setValue('+' + phoneNumber);
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cancel(): void {
    this.modalRef.cancel();
  }

  submitPhoneNumber(): void {
    this.appInsightsService.trackEvent(
      this.appInsightsPrefix + 'trial-submit-phone-number-clicked',
      { phoneNumber: this.form.controls.phoneNumber.value }
    );
    this.inProgress$.next(true);
    this.phoneNumberInput.nativeElement.blur();
    this.subs.sink = this.smsActivationService.checkPhoneNumberAvailability(this.endpoints, this.form.controls.phoneNumber.value)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === HttpStatusCode.Forbidden) {
            this.form.controls.phoneNumber.setErrors({ phoneNumberAlreadyActivated: true });
          } else {
            this.form.controls.phoneNumber.setErrors({ activationFailed: true });
          }
          this.phoneNumberInput.nativeElement.focus();
          this.canTryAgain = true;
          this.inProgress$.next(false);
          return NEVER;
        })
      )
      .subscribe(() => {
        this.inProgress$.next(false);
        this.phoneNumberSubmitted = true;
        setTimeout(() => this.codeInput.nativeElement.focus());
      });
  }

  submitCode(): void {
    this.appInsightsService.trackEvent(
      this.appInsightsPrefix + 'trial-submit-code-clicked',
      { code: this.form.controls.code }
    );
    this.inProgress$.next(true);
    this.codeInput.nativeElement.blur();
    this.subs.sink = this.smsActivationService.sendCode(this.endpoints, this.form.controls.code.value).pipe(
      catchError((err: HttpErrorResponse) => {
        switch (err.status) {
          case HttpStatusCode.Forbidden:
            this.form.controls.code.setErrors({ invalidCode: true });
            break;
          case HttpStatusCode.NotFound:
            this.form.controls.code.setErrors({ codeExpired: true });
            this.codeExpired = true;
            break;
          default:
            this.form.controls.code.setErrors({ activationFailed: true });
        }
        this.codeInput.nativeElement.focus();
        this.canTryAgain = true;
        this.inProgress$.next(false);
        return NEVER;
      })
    ).subscribe(() => {
      this.inProgress$.next(false);
      this.modalRef.submit();
    });
  }

  tryAgain(): void {
    if (!this.phoneNumberSubmitted) {
      this.phoneNumberInput.nativeElement.focus();
      this.form.controls.phoneNumber.reset(PHONE_PREFIX);
    } else {
      if (!this.codeExpired) {
        this.codeInput.nativeElement.focus();
        this.form.controls.code.reset();
      } else {
        this.form.controls.phoneNumber.reset(this.form.controls.phoneNumber.value);
        this.phoneNumberSubmitted = false;
        this.form.controls.code.reset();
        this.codeExpired = false;
        setTimeout(() => this.phoneNumberInput.nativeElement.focus());
      }
    }
    this.canTryAgain = false;
  }
}
