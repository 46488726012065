export enum ApplicationType {
  Unknown = 0,
  Omega = 3,
  AlfaPlus = 13,
  Invoicing = 25,
  ProjectManagement = 26,
  Warehouse = 29,
  DigitalOffice = 31,
  Waxa = 32,
  Payrolls = 36,
  BusinessApp = 40,
  TestApp = 99 // this value is independent of BE
}
