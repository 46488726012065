import { Component, inject, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { fromDeviceDetector } from '@kros-sk/device-detector';
import { KrosModalRef, KrosModalService } from '@kros-sk/components';

import { MarketingContentHelper } from '../helpers';
import { NonPaidPackageConfig } from '../non-paid-package-config.model';

@Component({
  selector: 'kros-marketing-modal',
  templateUrl: './marketing-modal.component.html',
  styleUrls: ['./marketing-modal.component.scss']
})
export class MarketingModalComponent implements OnInit {

  protected contentHelper = inject(MarketingContentHelper, { optional: true });
  private appInsightsService = inject(AppInsightsBaseService);
  private krosModalService = inject(KrosModalService);
  private modalRef = inject(KrosModalRef);
  private store = inject(Store);

  config: NonPaidPackageConfig;
  isMobile$ = this.store.select(fromDeviceDetector.selectIsMobile);

  ngOnInit(): void {
    this.config = this.modalRef.data;
    if (this.config.contentHelper) {
      this.contentHelper = this.config.contentHelper;
    }
    this.contentHelper.init(this.config);
  }

  onCancel(): void {
    this.modalRef.cancel();
  }

  onSubmit(): void {
    this.config.trialButton?.action();
    this.modalRef.submit();
  }

  onOpenOrderUrl(): void {
    if (this.config.canOpenModal && this.config.licenseInfoContent) {
      this.krosModalService.openCentered(
        MarketingModalComponent,
        {
          contentHelper: this.config.licenseInfoContent
        },
        { allowFocusAutocapture: false }
      );
    } else {
      const win = window.open(this.config.orderUrl, '_blank');
      if (win) {
        if (win.opener) {
          win.opener = null;
        }
        win.focus();
      }
    }
    this.appInsightsService.trackEvent(this.config.appPrefix + '-order-clicked');
    this.onCancel();
  }
}
