import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter, take } from 'rxjs';

import { KrosAppInsightsService } from '@kros-sk/core';

import { DeviceInfoService } from './device-info.service';
import { DeviceSamplerService } from './device-sampler.service';

@Injectable()
export class DataCollectorService {

  private isActivity = false;
  private destroyRef = inject(DestroyRef);
  private appInsightsService = inject(KrosAppInsightsService);
  private deviceSamplerService = inject(DeviceSamplerService);
  private deviceInfoService = inject(DeviceInfoService);

  logDeviceData(): void {
    this.isActivity = true;
    if (this.isOkToSend()) {
      this.sendDataToServer();
    }
  }

  private isOkToSend(): boolean {
    return this.isActivity && this.deviceSamplerService.checkLastTakenTimestamp();
  }

  private sendDataToServer(): void {
    this.deviceSamplerService.resetLastTakenTimestamp();
    this.appInsightsService.isUserContextDefined$.pipe(
      takeUntilDestroyed(this.destroyRef),
      filter(Boolean),
      take(1)).subscribe(() => {
      setTimeout(() => this.isActivity = false, 10 * 1000);
      this.appInsightsService.trackEvent('UAL-user-device-log', {
        deviceGuid: this.deviceInfoService.deviceGuid,
        applicationName: this.deviceInfoService.applicationName,
        statisticPropertyNames: 'deviceGuid, applicationName'
      });
    });
  }
}
