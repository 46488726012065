<div class="kros-modal-header">
  <h5 class="modal-title">
    {{ 'SUBCONTRACTORS.SUBCONTRACTOR_ITEMS_LIST' | translate }}
  </h5>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    data-test="modal-close-button"
    (click)="onCloseClick()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<form
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
>
  <div
    class="kros-modal-body"
    data-test="subcontractor-select-modal-body"
  >
    <div class="form-group">
      <label
        for="subcontractor"
        class="caption"
      >{{ 'SUBCONTRACTORS.NAME' | uppercase | translate }}</label>

      <ng-container *ngIf="canSearch else disabledSearch">
        <app-company-search
          [formControlName]="'subcontractor'"
          [placeholder]="'SUBCONTRACTORS.SUBCONTRACTOR_NAME_PLACEHOLDER' | translate"
          [validationOptions]="companySearchOptions"
          [errors]="errors"
          [maxlength]="100"
        ></app-company-search>
      </ng-container>

      <ng-template #disabledSearch>
        <kros-input
          #partnerInput
          [options]="{
                        formControl: form.get('subcontractor'),
                        placeholder: 'SUBCONTRACTORS.SUBCONTRACTOR_NAME_PLACEHOLDER' | translate,
                        name: 'subcontractor',
                        testName: 'subcontractor',
                        maxLength: 100,
                        errors: errors
                    }"
        ></kros-input>
      </ng-template>
    </div>
  </div>

  <div class="kros-modal-footer">
    <button
      class="btn btn-primary ga-pv-subcontractor-items-list-create"
      type="submit"
      data-test="create-subcontractor-items-list-button"
      [disabled]="form.invalid"
    >{{ 'COMMON.CREATE' | translate }}</button>
  </div>
</form>
