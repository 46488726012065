<ng-container *ngIf="!creatingPeriod else createPeriodForm">
  <div class="kros-modal-header">
    <h4 class="kros-modal-title">{{ 'BUILDING_PROGRESS.EXPORT_AMOUNTS_TO_ANOTHER_PROJECT_LABEL' | translate }}</h4>

    <button
      class="btn btn-link close"
      type="button"
      tabindex="-1"
      data-test="modal-close-button"
      (click)="onClose()"
    >
      <i class="material-icons secondary">close</i>
    </button>
  </div>

  <form
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
  >
    <div
      class="kros-modal-body"
      data-test="export-to-another-project-select-modal-body"
    >
      <div class="form-group">
        <ng-container *ngIf="hasPeriods else noPeriods">
          <label
            for="sourcePeriod"
            class="caption mt-25"
          >{{'BUILDING_PROGRESS.EXPORT_AMOUNTS_TO_ANOTHER_PROJECT_SOURCE_PERIOD' | translate}}</label>

          <select
            id="sourcePeriod"
            class="form-control"
            formControlName="sourcePeriod"
            data-test="export-to-another-project-source-period"
            [compareWith]="onComparePeriods"
          >
            <option
              *ngFor="let period of sourceProjectPeriods"
              [ngValue]="period"
            >{{ period.dateFrom | periodDate : period.dateTo }}</option>
          </select>

          <div class="dx-field search-projects mt-25">
            <label class="caption">{{'BUILDING_PROGRESS.EXPORT_AMOUNTS_TO_ANOTHER_PROJECT_TARGET_PROJECT' | translate}}</label>

            <div>
              <dx-select-box
                class="dx-field-value form-control"
                [disabled]="!hasPeriods"
                [dataSource]="targetProjects"
                valueExpr="name"
                displayExpr="name"
                [(value)]="targetProjectName"
                (onSelectionChanged)="onUpdateTargetProject($event)"
                showClearButton="true"
                searchEnabled="true"
                noDataText="{{'COMMON.SEARCH_NO_RESULTS' | translate}}"
                placeholder="{{'COMMON.SEARCH' | translate}}"
              >
                <dxi-button
                  *ngIf="!targetProjectName || targetProjectName.length === 0"
                  name="search"
                  location="before"
                  [options]="searchButton"
                ></dxi-button>

                <dxi-button name="clear"></dxi-button>
              </dx-select-box>
            </div>
          </div>

          <div class="target-period mt-25">
            <div class="select-box">
              <label
                for="targetPeriod"
                class="caption"
              >{{'BUILDING_PROGRESS.EXPORT_AMOUNTS_TO_ANOTHER_PROJECT_TARGET_PERIOD' | translate}}</label>

              <select
                id="targetPeriod"
                class="form-control"
                formControlName="targetPeriod"
                data-test="export-to-another-project-target-period"
                [compareWith]="onComparePeriods"
              >
                <option
                  *ngFor="let period of targetProjectPeriods"
                  [ngValue]="period"
                >{{ period.dateFrom | periodDate : period.dateTo }}</option>
              </select>
            </div>

            <button
              class="btn btn-link"
              type="button"
              [disabled]="!targetProjectName || targetProjectName.length === 0"
              (click)="onOpenCreatingPeriod()"
              data-test="create-new-period"
            >
              <i class="material-icons icon-button">add</i>

              <span class="toolbar-action-text">{{'BUILDING_PROGRESS.PERIODS.NEW_PERIOD' | translate}}</span>
            </button>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="kros-modal-footer">
      <button
        class="btn btn-primary"
        type="submit"
        data-test="export-to-another-project-button"
        [disabled]="form.invalid || !targetProject"
      >{{ 'BUILDING_PROGRESS.EXPORT_AMOUNTS_TO_ANOTHER_PROJECT_CONFIRM' | translate }}</button>
    </div>
  </form>
</ng-container>

<ng-template #createPeriodForm>
  <div class="kros-modal-header">
    <h5 class="modal-title">
      {{ 'BUILDING_PROGRESS.PERIODS.NEW_PERIOD' | translate }}
    </h5>
  </div>

  <app-period-date-picker
    [lastPeriod]="lastPeriod"
    (close)="onCloseCreatingPeriod()"
    (save)="onCreatePeriod($event)"
  ></app-period-date-picker>
</ng-template>

<ng-template #noPeriods>
  <div>{{ 'BUILDING_PROGRESS.EXPORT_PERIODS_EMPTY_INFO' | translate }}</div>
</ng-template>
