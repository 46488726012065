import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

import { PwaToastComponent } from './pwa-toast-component/pwa-toast.component';
import { ServiceWorkingToastComponent } from './service-working-toast-component/service-working-toast.component';
import { ShowPwaToastComponent } from './show-pwa-toast/show-pwa-toast.component';
import { ToastComponent } from './components/toast/toast.component';
import { ToastsComponent } from './components/toasts.component';

@NgModule({
  imports: [
    CommonModule,
    NgbToastModule
  ],
  declarations: [
    ToastsComponent,
    ToastComponent,
    PwaToastComponent,
    ServiceWorkingToastComponent,
    ShowPwaToastComponent
  ],
  exports: [
    ToastsComponent,
    PwaToastComponent,
    ServiceWorkingToastComponent,
    ShowPwaToastComponent
  ],
})
export class ToastModule { }
