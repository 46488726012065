import { Injectable } from '@angular/core';

import { SubSink } from 'subsink';

import { ApplicationOpenerService, InfoModalComponent, TranslateService } from '@kros-sk/ssw-shared-legacy';
import { KrosModalService } from '@kros-sk/components';

import { BuildingProgressSelectorsService } from '../../store/building-progress';

@Injectable()
export class PhotoGalleryOpenerService {
  private subs = new SubSink();
  private hasDocuments = false;

  constructor(
    private krosModalService: KrosModalService,
    private buildingProgressSelectorService: BuildingProgressSelectorsService,
    private appOpenerService: ApplicationOpenerService,
    private translateService: TranslateService
  ) {
    this.subs.sink = this.buildingProgressSelectorService.hasDocuments$.subscribe(hasDocuments => this.hasDocuments = hasDocuments);
  }

  destroy(): void {
    this.subs.unsubscribe();
  }

  openPhotoGallery(projectId: number, hasPeriods: boolean, selectedPeriodId: number): void {
    if (this.hasDocuments || hasPeriods) {
      this.appOpenerService.openGallery(projectId, selectedPeriodId);
    } else {
      this.openInfoModal(this.translateService.translate('BUILDING_PROGRESS.PHOTO_GALLERY_NO_PERIODS'));
    }
  }

  private openInfoModal(message: string): void {
    this.krosModalService.openCentered(
      InfoModalComponent,
      {
        body: message
      },
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: false,
        showMobileArrowBack: false
      }
    );
  }
}
