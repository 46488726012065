import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { catchError, filter, map, Observable, of, switchMap, take, tap } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { KrosModalRef, KrosModalService } from '@kros-sk/components';
import { PermissionType } from '@kros-sk/ssw-shared/permission';

import { OWNER_ME } from '../../core/common-utils';
import { Project } from '../../models';
import { ProjectCreateDialogComponent } from '../components';
import { ProjectCreationModel } from '../models';
import { ToastService, ToastType } from '../../toast';
import { TranslateService } from '../../translate';

const projectCreateEndpoint = '/projectCreate';

@Injectable()
export class ProjectCreateService {

  private appConfig = inject(APP_CONFIG);
  private appInsightsService = inject(AppInsightsBaseService);
  private http = inject(HttpClient);
  private krosModalService = inject(KrosModalService);
  private router = inject(Router);
  private toastService = inject(ToastService);
  private translateService = inject(TranslateService);

  private get projectApi(): string {
    return this.appConfig.appUrls.titanGatewayUrl + '/api/projectService/Projects';
  }

  onCreateProject(eventNameToTrack?: string): Observable<Project> {
    const projectName = this.translateService.translate('PROJECTS.ZOZNAM.NEW_PROJECT');
    const project = this.createEmptyProject(-1, projectName);
    return this.openProjectCreateDialog(project).afterClosed$.pipe(
      switchMap((result: any) =>
        result.type === 'submit' && result.data.project
          ? this.addProject({ ...result.data.project }).pipe(
            tap(() => eventNameToTrack ? this.appInsightsService.trackEvent(eventNameToTrack) : null),
            map(response =>
              ({ ...result.data.project, id: response.id })
            ),
            catchError(() => {
              this.toastService.open(
                this.translateService.translate('PROJECTS.ZOZNAM.PROJEKT_NEZALOZENY'),
                ToastType.Error);
              return of();
            }))
          : of(null)
      ));
  }

  createAndOpenProject(path: string, eventNameToTrack?: string): void {
    this.onCreateProject(eventNameToTrack).pipe(
      take(1),
      filter(project => !!project)
    ).subscribe(project => {
      this.router.navigate([path, project.id]);
    });
  }

  private addProject(creationModel: ProjectCreationModel): Observable<any> {
    return this.http.post(this.projectApi + projectCreateEndpoint, creationModel);
  }

  private createEmptyProject(id: number, name: string): Project {
    return {
      id,
      dateCreated: new Date(),
      name,
      owner: OWNER_ME,
      permissionType: PermissionType.Owner,
      userTags: [],
      isDemo: false
    };
  }

  private openProjectCreateDialog(project: Project): KrosModalRef {
    return this.krosModalService.openCentered(
      ProjectCreateDialogComponent,
      { project },
      { closeOnBackdropClick: false }
    );
  }
}
