import { AbstractControl, ValidationErrors, Validator } from '@angular/forms';

import { areEmailsEqual } from '@kros-sk/ssw-cdk';

export class ExistingSharingValidator implements Validator {
  existingSharings: string[] = [];

  validate(control: AbstractControl): ValidationErrors {
    return control.value && this.existingSharings.find(e => areEmailsEqual(e, control.value))
      ? { existingEmail: true }
      : null;
  }

  validateEmail(email: string): boolean {
    return !this.existingSharings.find(e => areEmailsEqual(e, email));
  }
}

export function validateExistingSharings(internValidator: Validator) {
  return (control: AbstractControl): { [key: string]: any } => {
    return internValidator.validate(control);
  };
}
