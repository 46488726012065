import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ElementRef, EventEmitter, inject, Injectable, Renderer2 } from '@angular/core';

import { APP_CONFIG } from '@kros-sk/app-config';
import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

import { AnalyticsService } from '../../analytics';
import { Cell, ColDef, DataTableConfigModel } from '../data-table-config.model';
import { DataTableKeyboardHandlingService } from './data-table-keyboard-handling.service';
import {
  editValue,
  getCellValueForInput,
  isArrowKey,
  isColumnEditable,
  isControlKey,
  isDeleteKey,
  isEnterKey,
  isEscapeKey,
  isUpDownKey
} from './data-table.utils';
import { getFloatFromString, getThousandsSeparator } from '../../core';
import { isSummaryItem } from '../../budget/helpers';

@Injectable()
export class EditHelper {

  private appConfig = inject(APP_CONFIG);
  private analyticsService = inject(AnalyticsService);
  private appInsightsService = inject(AppInsightsBaseService);
  private keyboardHandlingService = inject(DataTableKeyboardHandlingService);
  private elementRef = inject(ElementRef);
  private renderer = inject(Renderer2);

  editingCell: Cell;
  config: DataTableConfigModel;
  cellWasEdited: boolean;
  actualEditValue: string;
  itemCount: number;
  appInsightsPrefix: string;

  private cellFocused: EventEmitter<{ cell: Cell, cantLostFocus?: boolean }>;
  private scrollFocusedCellToView: EventEmitter<boolean>;
  private changeFocusedCell: Function;
  private _focusedCell: Cell;
  private _thousandsSeparator: string;
  private itemDblClick: EventEmitter<Cell>;

  private get thousandsSeparator(): string {
    if (!this._thousandsSeparator) {
      this._thousandsSeparator = getThousandsSeparator(this.appConfig.location);
    }
    return this._thousandsSeparator;
  }

  private get focusedCell(): Cell {
    return this._focusedCell;
  }
  private set focusedCell(value: Cell) {
    this._focusedCell = value;
    this.changeFocusedCell(value);
  }

  private editedValues: { [key: string]: number } = {};

  init(
    cellFocused: EventEmitter<{ cell: Cell, cantLostFocus?: boolean }>,
    scrollFocusedCellToView: EventEmitter<boolean>,
    changeFocusedCell: Function,
    itemDblClick: EventEmitter<Cell>,
    appInsightsPrefix: string
  ): void {
    this.cellFocused = cellFocused;
    this.scrollFocusedCellToView = scrollFocusedCellToView;
    this.changeFocusedCell = changeFocusedCell;
    this.itemDblClick = itemDblClick;
    this.appInsightsPrefix = appInsightsPrefix;
  }

  setFocusedCell(cell: Cell): void {
    this._focusedCell = cell;
  }

  private getEditingCell(colDef: ColDef, rowIndex: number, item: any): Cell {
    return this.isRowEditable(item, colDef) && isColumnEditable(colDef.editConfig) ? { colId: colDef.id, rowIndex } : undefined;
  }

  private isRowEditable(item: any, colDef: ColDef): boolean {
    return !this.config.isReadonly && !!this.config.rowEditableGetter && this.config.rowEditableGetter(item, colDef);
  }

  removeEditedItemIds(p: string[]): void {
    p.forEach(i => delete this.editedValues[i]);
  }

  editOnChange(value: string, colDef: ColDef, item: any): void {
    this.cellWasEdited = true;
    if (colDef.editConfig.onChange) {
      editValue(value, colDef, item, this.analyticsService, this.appInsightsService, this.appInsightsPrefix);
    }
  }

  onCellDblClick(colDef: ColDef, rowIndex: number, item: any): void {
    this.editingCell = this.getEditingCell(colDef, rowIndex, item);

    if (this.editingCell) {
      this.actualEditValue = getCellValueForInput(colDef, item);
      setTimeout(() => this.elementRef.nativeElement.querySelector('.cell-input')?.select(), 0);
    }

    this.itemDblClick.emit({ colId: colDef.id, rowIndex, item } as Cell);
  }

  private editOnArrowMovement(event: any, colDef: ColDef, item: any): void {
    this.editValueAndLeaveEditMode(colDef.editConfig.onArrowMovement, event, colDef, item);
  }

  editOnEnter(event: any, colDef: ColDef, item: any): void {
    this.editValueAndLeaveEditMode(colDef.editConfig.onEnter, event, colDef, item);
  }

  editOnFocusOut(event: any, colDef: ColDef, item: any): void {
    this.editValueAndLeaveEditMode(colDef.editConfig.onFocusOut, event, colDef, item);
  }

  getEditedValue(itemId: number, colId: string): number {
    return this.editedValues[`${itemId}-${colId}`];
  }

  private editValueAndLeaveEditMode(predicate: boolean, event: any, colDef: ColDef, item: any): void {
    if (predicate) {
      if (editValue(event.target.value, colDef, item, this.analyticsService, this.appInsightsService, this.appInsightsPrefix) &&
        !isSummaryItem(item)) {
        const editedValue = getFloatFromString(event.target.value, this.thousandsSeparator);

        if (!isNaN(editedValue)) {
          this.editedValues[`${item.id}-${colDef.id}`] = editedValue;
        }
      }
      this.leaveEditMode();
    }
  }

  private leaveEditMode(): void {
    this.editingCell = undefined;
    this.cellWasEdited = false;
  }

  onCellKeyUp(colDef: ColDef, rowIndex: number, item: any, event: KeyboardEvent, viewPort: CdkVirtualScrollViewport): void {
    if (!this.editingCell && !!this.focusedCell && event.key) {
      if (isArrowKey(event.key)) {
        this.scrollFocusedCellToView.emit(true);
      } else if (isDeleteKey(event.key)) {
        this.editingCell = this.getEditingCell(colDef, rowIndex, item);
        if (this.editingCell) {
          this.actualEditValue = '';
        }
      } else if (!isControlKey(event.key)) {
        if (!event.ctrlKey && event.key.length === 1) {
          this.editingCell = this.getEditingCell(colDef, rowIndex, item);
          if (this.editingCell) {
            this.actualEditValue = event.key;
          }
        }
      }
    } else if (this.editingCell && event.key) {
      if (isEscapeKey(event.key)) {
        this.leaveEditMode();
        this.cellFocused.emit({ cell: this.focusedCell });
      } else {
        if (!event.ctrlKey && event.key.length === 1 && !this.cellWasEdited) {
          this.actualEditValue += event.key;
        }
      }
    }
  }

  onCellClick(colDef: ColDef, rowIndex: number): void {
    if (!this.focusedCell || (this.focusedCell.colId !== colDef.id || this.focusedCell.rowIndex !== rowIndex)) {
      this.leaveEditMode();
      this.focusedCell = { colId: colDef.id, rowIndex, params: colDef.params };
      this.cellFocused.emit({ cell: this.focusedCell, cantLostFocus: true });
    }
  }

  onCellKeyDown(event: KeyboardEvent, colDef: ColDef, item: any): void {
    if (!this.editingCell && !!this.focusedCell) {
      if (isArrowKey(event.key) || isEnterKey(event.key)) {
        this.handleMovement(event);
      }
    } else if (!!this.editingCell && (isUpDownKey(event.key) || isEnterKey(event.key))) {
      this.editOnArrowMovement(event, colDef, item);
      this.handleMovement(event);
    }
  }

  private handleMovement(event: KeyboardEvent): void {
    event.preventDefault();
    this.focusedCell = this.keyboardHandlingService.handleTableMovement(
      this.focusedCell, this.config.colDefs, this.itemCount, event.key.toLowerCase());
    this.cellFocused.emit({ cell: this.focusedCell });
  }

  isCellEditable(colDef: ColDef, rowIndex: number, item: any): boolean {
    return !!this.getEditingCell(colDef, rowIndex, item);
  }

  isCellEditing(colDef: ColDef, rowIndex: number): boolean {
    return this.editingCell && this.editingCell.colId === colDef.id && this.editingCell.rowIndex === rowIndex;
  }

  isCellActionButtonShown(colDef: ColDef, rowIndex: number, item: any): boolean {
    return !!colDef.cellActionButton && colDef.cellActionButton.showCondition(item) &&
      (this.isCellFocused(colDef, rowIndex) || colDef.cellActionButton.showAlways) &&
      !this.isCellEditing(colDef, rowIndex);
  }

  isFloatingActionButtonShown(colDef: ColDef, rowIndex: number, item: any): boolean {
    return !!colDef.floatingActionButton && colDef.floatingActionButton.showCondition(item) &&
      this.isCellFocused(colDef, rowIndex) && !this.isCellEditing(colDef, rowIndex);
  }

  isCellFocused(colDef: ColDef, rowIndex: number): boolean {
    return !!this.focusedCell && this.focusedCell.colId === colDef.id && this.focusedCell.rowIndex === rowIndex;
  }

  animateCells(elementIds: string[]): void {
    elementIds.forEach(p => {
      const elements = this.elementRef.nativeElement.querySelectorAll('.' + p);
      if (elements) {
        for (const e of elements) {
          this.renderer.removeClass(e, 'animate');
          this.renderer.addClass(e, 'animate');
          setTimeout(() => this.renderer.removeClass(e, 'animate'), 2000);
        }
      }
    });
  }
}
