import { Component } from '@angular/core';

import { KrosModalRef } from '@kros-sk/components';

@Component({
  selector: 'kros-color-codes-dialog',
  templateUrl: './color-codes-dialog.component.html',
  styleUrls: ['./color-codes-dialog.component.scss']
})
export class ColorCodesDialogComponent {
  colors: string[];
  selectedColors: string[] = [];
  multiselect = false;
  isExtraColors = false;

  constructor(
    private modalRef: KrosModalRef
  ) {
    this.colors = this.modalRef.data.colors;
    this.multiselect = this.modalRef.data.multiselect;
    this.isExtraColors = this.modalRef.data.isExtraColors;
  }

  onColorSelected(color: string): void {
    if (this.multiselect) {
      this.selectedColors.push(color);
    } else {
      this.modalRef.submit({ color });
    }
  }

  onColorDeselected(color: string): void {
    this.selectedColors.splice(this.selectedColors.indexOf(color), 1);
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  submitColors(): void {
    this.modalRef.submit({ colors: this.selectedColors });
  }
}
