import { Pipe, PipeTransform } from '@angular/core';

import { FileSizeService } from '../services/file-size.service';

@Pipe({ name: 'fileSize' })
export class FileSizePipe implements PipeTransform {
  constructor(private fileSizeService: FileSizeService) { }

  transform(bytes: number): string {
    return this.fileSizeService.transform(bytes);
  }
}
