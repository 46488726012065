<div
  ngbDropdown
  #dropdown="ngbDropdown"
  autoClose="outside"
  class="form-group range"
  container="body"
>
  <button
    ngbDropdownToggle
    class="titan-dropdown-toggle btn"
    type="button"
    data-test="building-progress-period-toggle"
  >
    <span>{{filterDescription}}</span>
    <span krosMaterialIcon>expand_more</span>
  </button>

  <div ngbDropdownMenu>
    <div
      ngbDropdownItem
      class="form-group checkbox"
      *ngFor="let period of periods"
    >
      <input
        id="period-range-check-{{period.id}}"
        type="checkbox"
        [checked]="isChecked(period.id)"
        (change)="addOrRemovePeriodToFilter(period.id)"
        data-test="building-progress-period-range-check"
      >
      <label for="period-range-check-{{period.id}}">{{ period.dateFrom | periodDate: period.dateTo }}</label>
    </div>

    <div class="dropdown-divider"></div>

    <div class="btn-clear-filter">
      <button
        class="btn btn-link"
        type="button"
        (click)="clearFilter()"
        data-test="building-progress-period-range-clear-button"
      >
        <span>{{ 'BUILDING_PROGRESS.EXPORT_DESELECT_ALL' | translate }}</span>
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
</div>
