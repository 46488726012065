<kros-announcekit
  [url]="widgetUrl"
  [userId]="newsUser.id"
  [lang]="translateService.currentLang"
  [appName]="newsData?.app"
  [modul]="newsData?.modul"
  [isTest]="newsData?.isTest"
>
  <i
    class="material-icons"
    krosTooltip="{{ 'NAVBAR.NEWS' | translate }}"
  >campaign</i>

  <span class="tablet-visible label">{{ 'NAVBAR.NEWS' | translate }}</span>
</kros-announcekit>
