import { DestroyRef, EventEmitter, inject, Injectable, TemplateRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter, Observable, switchMap, take } from 'rxjs';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { ApprovalOrderSelectorsService } from '@kros-sk/ssw-building-progress/approval-order';
import { BuildingProgressPermission, PermissionType } from '@kros-sk/ssw-shared/permission';
import { InfoModalComponent, SearchModel, TranslateService } from '@kros-sk/ssw-shared-legacy';
import { KrosModalRef, KrosModalService, MessageTypes } from '@kros-sk/components';

import { BuildingProgressActionAccessService } from '../../../../building-progress/services/building-progress-action-access.service';
import { BuildingProgressPeriod } from '../../../../building-progress/models/building-progress-period.model';
import { BuildingProgressPeriodDispatchersService, BuildingProgressSelectorsService } from '../../../../store/building-progress';
import { BuildingProgressTourService } from '../../../../building-progress/services/building-progress-tour.service';
import { getAppInsightsPrefix } from '../../../../building-progress/shared/building-progress-utils';
import { PeriodDeleteDialogComponent } from '../period-delete-dialog/period-delete-dialog.component';
import { PermissionHelper } from '../../../../building-progress/helpers';

@Injectable()
export class PeriodsHelperService {
  private destroyRef = inject(DestroyRef);
  private appInsightsService = inject(AppInsightsBaseService);
  private approvalOrderSelectorsService = inject(ApprovalOrderSelectorsService);
  private buildingProgressActionAccessService = inject(BuildingProgressActionAccessService);
  private permissionHelper = inject(PermissionHelper);
  private buildingProgressSelectorsService = inject(BuildingProgressSelectorsService);
  private tourService = inject(BuildingProgressTourService);
  private periodDispatchersService = inject(BuildingProgressPeriodDispatchersService);
  private krosModalService = inject(KrosModalService);
  private translateService = inject(TranslateService);

  save(
    selectedPeriod: BuildingProgressPeriod,
    projectId: number,
    from: Date,
    to: Date,
    basicVatRate: number,
    reducedVatRate: number,
    searchModel: SearchModel,
    periodCreated: EventEmitter<void>,
    firstPeriod: boolean
  ): void {
    if (selectedPeriod) {
      this.periodDispatchersService.editPeriod(
        selectedPeriod.id,
        projectId,
        from,
        to,
        basicVatRate,
        reducedVatRate,
        searchModel
      );
      this.appInsightsService.trackEvent(getAppInsightsPrefix(this.permissionHelper.isContractor) + 'period-edit');
    } else {
      this.periodDispatchersService.createPeriod(
        projectId,
        from,
        to,
        searchModel
      );
      if (firstPeriod) {
        this.appInsightsService.trackEvent(
          getAppInsightsPrefix(this.permissionHelper.isContractor) + 'period-first-add', { projectId: projectId.toString() }
        );
      } else {
        this.appInsightsService.trackEvent(getAppInsightsPrefix(this.permissionHelper.isContractor) + 'period-add');
      }

      if (this.tourService.isActive) {
        this.buildingProgressSelectorsService.periodsLoading$
          .pipe(take(2))
          .subscribe(value => {
            if (!value) {
              periodCreated.emit();
              this.tourService.continueTourOnCreatedPeriod();
            }
          });
      }
    }
  }

  delete(period: BuildingProgressPeriod): KrosModalRef {
    return this.krosModalService.openCentered(
      PeriodDeleteDialogComponent,
      { period },
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: false,
        showMobileArrowBack: false
      },
      NaN,
      NaN,
      undefined,
      'no-min-width',
    );
  }

  lockPeriod(projectId: number, periodId: number): void {
    this.openQuestionDialog(
      this.translateService.translate('BUILDING_PROGRESS.PERIODS.LOCK_QUESTION')
    ).pipe(take(1)).subscribe(result => {
      if (result?.data === 'accept') {
        this.periodDispatchersService.setIsLockedPeriod(projectId, periodId);
        this.appInsightsService.trackEvent('PV-period-lock');
      }
    });
  }

  unlockPeriod(periodId: number, projectId: number, searchModel: SearchModel): void {
    this.openQuestionDialog(
      this.translateService.translate('BUILDING_PROGRESS.PERIODS.UNLOCK_QUESTION')
    ).pipe(take(1)).subscribe(result => {
      if (result?.data === 'accept') {
        this.periodDispatchersService.cancelIsLockedPeriod(periodId, projectId, searchModel);
        this.appInsightsService.trackEvent('PV-period-unlock');
      }
    });
  }

  cancelApproved(
    period: BuildingProgressPeriod,
    projectId: number,
    searchModel: SearchModel,
    isMultiStageApprovalModeEnabled: boolean,
    contractorId?: number): void {
    this.openQuestionDialog(
      this.translateService.translate('BUILDING_PROGRESS.PERIODS.APPROVE_CANCEL_QUESTION'),
      isMultiStageApprovalModeEnabled && !contractorId
        ? this.translateService.translate('BUILDING_PROGRESS.PERIODS.APPROVE_CANCEL_MESSAGE')
        : ''
    ).pipe(take(1)).subscribe(result => {
      if (result?.data === 'accept') {
        if (this.buildingProgressActionAccessService.checkForAccessToCancelApprovedPeriod()) {
          this.periodDispatchersService.cancelApprovedPeriod(period.id, projectId, searchModel, contractorId);
          const prefix = getAppInsightsPrefix(!!contractorId);
          this.appInsightsService.trackEvent(prefix + 'period-cancel-approve', {
            isMultiStageApprovalModeEnabled,
            statisticPropertyNames: 'isMultiStageApprovalModeEnabled'
          });
        }
      }
    });
  }

  cancelIsApprovalRequested(
    periodId: number,
    projectId: number,
    searchModel: SearchModel,
    isMultiStageApprovalModeEnabled: boolean,
    contractorId?: number): void {
    this.openQuestionDialog(
      this.translateService.translate('BUILDING_PROGRESS.PERIODS.CANCEL_APPROVAL_REQUESTED_QUESTION'),
      isMultiStageApprovalModeEnabled && !contractorId
        ? this.translateService.translate('BUILDING_PROGRESS.PERIODS.CANCEL_APPROVAL_REQUESTED_MESSAGE')
        : ''
    ).pipe(take(1)).subscribe(result => {
      if (result?.data === 'accept') {
        const prefix = getAppInsightsPrefix(!!contractorId);
        this.appInsightsService.trackEvent(prefix + 'period-disapprove', {
          isMultiStageApprovalModeEnabled,
          statisticPropertyNames: 'isMultiStageApprovalModeEnabled'
        });
        this.periodDispatchersService.cancelIsApprovalRequestedPeriod(projectId, periodId, searchModel, contractorId);
      }
    });
  }

  showPeriodActionWarning(permission: BuildingProgressPermission): void {
    let warningMessage = '';
    if (permission.permissionType === PermissionType.Contributor) {
      warningMessage =
        this.translateService.translate('BUILDING_PROGRESS.PERIODS.CANT_MAKE_APPROVE_CONTRIBUTOR_REQUEST_REASON');
    } else {
      warningMessage = this.translateService.translate('BUILDING_PROGRESS.PERIODS.CANT_MAKE_APPROVE_REQUEST_REASON') +
        '&nbsp;&nbsp;&nbsp;<span class="material-icon">person_add</span>';
    }
    this.krosModalService.openCentered(
      InfoModalComponent,
      {
        body: warningMessage
      },
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: false,
        showMobileArrowBack: false
      },
      NaN,
      NaN,
      undefined,
      'no-min-width'
    );
  }

  approvePeriodWithQuestion(
    projectId: number,
    periodId: number,
    isMultiStageApprovalModeEnabled: boolean,
    userId: number,
    contractorId?: number
  ): void {
    this.openQuestionDialog(this.translateService.translate('BUILDING_PROGRESS.PERIODS.APPROVE_PERIOD_QUESTION'))
      .pipe(
        filter((result: any) => result?.data === 'accept'),
        switchMap(() => this.approvalOrderSelectorsService.getIsLastApprover(
          userId,
          isMultiStageApprovalModeEnabled,
          contractorId !== undefined)),
        take(1),
        takeUntilDestroyed(this.destroyRef))
      .subscribe(isLastApprover => {
        this.periodDispatchersService.setApprovedPeriod(
          projectId,
          periodId,
          !isLastApprover,
          isLastApprover,
          isMultiStageApprovalModeEnabled);
        const prefix = getAppInsightsPrefix(!!contractorId);
        this.appInsightsService.trackEvent(prefix + 'period-approve', {
          isMultiStageApprovalModeEnabled,
          statisticPropertyNames: 'isMultiStageApprovalModeEnabled'
        });
      });
  }

  requestPeriodApprovalWithQuestion(
    projectId: number,
    periodId: number,
    isMultiStageApprovalModeEnabled: boolean,
    approvalOrderLink: TemplateRef<any>
  ): void {
    this.krosModalService.openModalMessageBox({
      caption: this.translateService.translate('BUILDING_PROGRESS.PERIODS.REQUEST_APPROVAL_QUESTION'),
      message: isMultiStageApprovalModeEnabled && !this.permissionHelper.isContractor ? approvalOrderLink : '',
      messageType: MessageTypes.Primary,
      acceptButton: this.translateService.translate('BUILDING_PROGRESS.PERIODS.REQUEST_APPROVAL'),
      cancelButton: this.translateService.translate('COMMON.NO')
    }).pipe(take(1)).subscribe(result => {
      if (result?.data === 'accept') {
        this.periodDispatchersService.setIsApprovalRequestedPeriod(projectId, periodId);
        this.appInsightsService.trackEvent(getAppInsightsPrefix(this.permissionHelper.isContractor) + 'period-sent-request', {
          isMultiStageApprovalModeEnabled,
          statisticPropertyNames: 'isMultiStageApprovalModeEnabled'
        });
      }
    });
  }

  openQuestionDialog(caption: string, message = ''): Observable<any> {
    return this.krosModalService.openModalMessageBox({
      caption,
      message,
      messageType: MessageTypes.Primary,
      acceptButton: this.translateService.translate('COMMON.YES'),
      cancelButton: this.translateService.translate('COMMON.NO')
    }, {
      closeOnBackdropClick: false,
      fullscreenOnMobile: false,
      showMobileArrowBack: false
    });
  }
}
