import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, flatMap, map, mergeMap, Observable, of, switchMap, tap } from 'rxjs';

import * as documentsEditActions from './documents-edit.actions';
import * as documentsExplorerActions from '../../document-explorer';
import { DeleteDocumentService, DocumentEditService } from '../services';
import { MultipleDocumentsDeleteModel } from '../models';
import { ToastService, ToastType } from '../../toast';
import { TranslateService } from '../../translate';

@Injectable()
export class DocumentsEditEffects {
  createFolder$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(documentsEditActions.createFolderStart),
    switchMap(payload => {
      return this.documentsEditService.createFolder(payload.folder).pipe(
        flatMap(() => [
          !payload.folder.parentId
            ? documentsExplorerActions.loadDocumentsListStart({ projectId: payload.folder.projectId })
            : documentsExplorerActions.loadFolderListStart({
              projectId: payload.folder.projectId,
              folderId: payload.folder.parentId
            }),
          documentsEditActions.createFolderSuccess({ projectId: payload.folder.projectId })
        ]
        ),
        catchError(error => of(documentsEditActions.createFolderError({ error })))
      );
    })
  ));

  renameFolder$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(documentsEditActions.renameFolderStart),
    switchMap(payload => {
      return this.documentsEditService.renameFolder(payload.projectId, payload.documentId, payload.documentName).pipe(
        map(() => documentsEditActions.renameFolderSuccess({ documentId: payload.documentId, documentName: payload.documentName })),
        catchError(error => of(documentsEditActions.renameFolderError({ error })))
      );
    })
  ));

  deleteDocument$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(documentsEditActions.deleteDocument),
    mergeMap(payload =>
      this.documentsEditService.deleteDocument(payload.projectId, payload.documentId).pipe(
        map(() => documentsEditActions.deleteDocumentSuccess({ documentId: payload.documentId }))
      )
    ),
  ));

  deleteDocuments$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(documentsEditActions.deleteDocuments),
    switchMap(payload => {
      const documentDeleteViewModel: MultipleDocumentsDeleteModel = {
        projectId: payload.projectId,
        documentIds: payload.documents.map(doc => doc.id)
      };

      return this.documentsEditService.deleteDocuments(documentDeleteViewModel).pipe(
        map(() => documentsEditActions.deleteDocumentsSuccess({ documentIds: documentDeleteViewModel.documentIds }))
      );
    })
  ));

  softDeleteDocumentVersion$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(documentsEditActions.softDeleteDocumentVersion),
    switchMap(payload => {
      return this.documentsEditService.isDeleteDocumentAllowed(payload.projectId, payload.documentId).pipe(
        map(_ => {
          return documentsEditActions.softDeleteDocumentVersionSuccess({
            documentId: payload.documentId,
            documentVersionId: payload.documentVersionId
          });
        })
      );
    }),
    catchError(error => {
      return of(documentsEditActions.softDeleteDocumentVersionError({ error }));
    })
  ));

  deleteDocumentVersion$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(documentsEditActions.deleteDocumentVersion),
    mergeMap(payload =>
      this.documentsEditService.deleteDocumentVersion(payload.projectId, payload.documentId, payload.documentVersionId).pipe(
        map(documentVersionDelete => {
          return documentsEditActions.deleteDocumentVersionSuccess(
            {
              documentId: payload.documentId,
              documentVersionId: payload.documentVersionId,
              documentVersionDelete
            });
        })
      )
    ),
  ));

  softDeleteDocument$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(documentsEditActions.softDeleteDocument),
    switchMap(payload => {
      return this.documentsEditService.isDeleteDocumentAllowed(payload.projectId, payload.documentId).pipe(
        map(_ => {
          this.deleteDocumentService.deleteDocument(payload.projectId, payload.documentId);
          return documentsEditActions.softDeleteDocumentSuccess({ documentId: payload.documentId });
        })
      );
    }),
    catchError(error => {
      this.handleDeleteError(error);
      return of(documentsEditActions.softDeleteDocumentError({ error }));
    })
  ));

  softDeleteDocuments$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(documentsEditActions.softDeleteDocuments),
    switchMap(payload => {
      const documentDeleteViewModel: MultipleDocumentsDeleteModel = {
        projectId: payload.projectId,
        documentIds: payload.documents.map(doc => doc.id)
      };

      return this.documentsEditService.isDeleteDocumentsAllowed(documentDeleteViewModel).pipe(
        tap(resp =>
          this.deleteDocumentService.deleteSelectedDocuments(resp, payload.projectId, payload.documents)
        ),
        map(_ => documentsEditActions.softDeleteDocumentsSuccess({ documents: payload.documents }))
      );
    }),
    catchError(error => {
      this.handleDeleteError(error);
      return of(documentsEditActions.softDeleteDocumentsError({ error }));
    })
  ));

  constructor(
    private actions$: Actions,
    private documentsEditService: DocumentEditService,
    private deleteDocumentService: DeleteDocumentService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) { }

  private handleDeleteError(error: any): void {
    if (error.status === 403) {
      this.toastService.open(this.translateService.translate('DOCUMENTS.DELETE_FORBIDDEN'),
        ToastType.Warning);
    }
    if (error.status === 409) {
      this.toastService.open(this.translateService.translate('DOCUMENTS.ADRESAR_NIE_JE_PRAZDNY'),
        ToastType.Warning);
    }
  }
}
