import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  Output,
  QueryList,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

import { Subscription } from 'rxjs';

import { KrosAutocompleteContentDirective } from '../autocomplete-content/kros-autocomplete-content.directive';
import { KrosAutocompleteOptionComponent } from '../autocomplete-option/kros-autocomplete-option.component';

/** Event object that is emitted when an autocomplete option is selected. */
export class KrosAutocompleteSelectedEvent<T = any> {
  constructor(
    /** Reference to the autocomplete panel that emitted the event. */
    public source: KrosAutocompleteComponent,
    /** Option that was selected. */
    public option: T
  ) {}
}

/** Event object that is emitted when an autocomplete option is activated. */
export interface KrosAutocompleteActivatedEvent {
  /** Reference to the autocomplete panel that emitted the event. */
  source: KrosAutocompleteComponent;

  /** Option that was selected. */
  option: KrosAutocompleteOptionComponent | null;
}

/** Default `kros-autocomplete` options that can be overridden. */
export interface KrosAutocompleteDefaultOptions {
  /** Whether the first option should be highlighted when an autocomplete panel is opened. */
  autoActiveFirstOption?: boolean;

  /** Class or list of classes to be applied to the autocomplete's overlay panel. */
  overlayPanelClass?: string | string[];

  /** Minimal width of the autocomplete */
  minWidth?: number;

  /** Options count which are visible to user after opening modal */
  optionsVisibleCount?: number;
}

/** Injection token to be used to override the default options for `kros-autocomplete`. */
export const KROS_AUTOCOMPLETE_DEFAULT_OPTIONS = new InjectionToken<KrosAutocompleteDefaultOptions>(
  'kros-autocomplete-default-options',
  {
    providedIn: 'root',
    factory: KROS_AUTOCOMPLETE_DEFAULT_OPTIONS_FACTORY,
  },
);

/** @docs-private */
export function KROS_AUTOCOMPLETE_DEFAULT_OPTIONS_FACTORY(): KrosAutocompleteDefaultOptions {
  return {autoActiveFirstOption: false};
}

@Component({
  selector: 'kros-autocomplete',
  templateUrl: './kros-autocomplete.component.html',
  styleUrls: ['./kros-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'krosAutocomplete'
})
export class KrosAutocompleteComponent implements AfterContentInit, OnDestroy {

  /** Manages active item in option list based on key events. */
  keyManager: ActiveDescendantKeyManager<KrosAutocompleteOptionComponent>;

  /** Whether the autocomplete panel should be visible, depending on option length. */
  showPanel = false;

  /** Class to apply to the panel when it's visible. */
  protected _visibleClass = 'kros-autocomplete-visible';

  /** Class to apply to the panel when it's hidden. */
  protected _hiddenClass = 'kros-autocomplete-hidden';

  _classList: {[key: string]: boolean} = {};

  /** Whether the autocomplete panel is open. */
  get isOpen(): boolean {
    return this.isOpen_ && this.showPanel;
  }
  set isOpen(val) {
    this.isOpen_ = val;
  }
  isOpen_ = false;

  @ViewChild('root') rootTemplate: TemplateRef<any>;
  /** Element for the panel containing the autocomplete options. */
  @ViewChild('panel') panel: ElementRef;

  @ContentChild(KrosAutocompleteContentDirective, {static: true}) content: KrosAutocompleteContentDirective;
  @ContentChildren(KrosAutocompleteOptionComponent, {descendants: true}) options: QueryList<KrosAutocompleteOptionComponent>;

  /** Function that maps an option's control value to its display value in the trigger. */
  @Input() displayWith: ((value: any) => string) | null = null;

  /**
   * Whether the first option should be highlighted when the autocomplete panel is opened.
   * Can be configured globally through the `KROS_AUTOCOMPLETE_DEFAULT_OPTIONS` token.
   */
  @Input()
  get autoActiveFirstOption(): boolean {
    return this.autoActiveFirstOption_;
  }
  set autoActiveFirstOption(value: BooleanInput) {
    this.autoActiveFirstOption_ = coerceBooleanProperty(value);
  }
  private autoActiveFirstOption_: boolean;


  /** Emits whenever an option is activated. */
  @Output() readonly optionActivated: EventEmitter<KrosAutocompleteActivatedEvent> =
    new EventEmitter<KrosAutocompleteActivatedEvent>();

  /** Event that is emitted whenever an option from the list is selected. */
  @Output() readonly optionSelected: EventEmitter<KrosAutocompleteSelectedEvent> =
    new EventEmitter<KrosAutocompleteSelectedEvent>();

  private subs = Subscription.EMPTY;

  constructor(
    private cdr: ChangeDetectorRef,
    @Inject(KROS_AUTOCOMPLETE_DEFAULT_OPTIONS) defaults: KrosAutocompleteDefaultOptions,
  ) {
    this.autoActiveFirstOption_ = !!defaults.autoActiveFirstOption;
  }

  ngAfterContentInit(): void {
    this.keyManager = new ActiveDescendantKeyManager<KrosAutocompleteOptionComponent>(this.options)
      .withTypeAhead()
      .withWrap();

    this.subs.add(
      this.keyManager.change.subscribe(index => {
        if (this.isOpen) {
          this.optionActivated.emit({source: this, option: this.options.toArray()[index] || null});
        }
      })
    );

    // Set the initial visibility state.
    this.setVisibility();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Sets the panel scrollTop. This allows us to manually scroll to display options
   * above or below the fold, as they are not actually being focused when active.
   */
  setScrollTop(scrollTop: number): void {
    if (this.panel) {
      this.panel.nativeElement.scrollTop = scrollTop;
    }
  }

  /** Returns the panel's scrollTop. */
  getScrollTop(): number {
    return this.panel ? this.panel.nativeElement.scrollTop : 0;
  }

  /** Panel should hide itself when the option list is empty. */
  setVisibility(): void {
    this.showPanel = !!this.options.length;
    this._setVisibilityClasses(this._classList);
    this.cdr.markForCheck();
  }

  emitSelectEvent(option: any): void {
    const event = new KrosAutocompleteSelectedEvent(this, option);
    this.optionSelected.emit(event);
  }

  private _setVisibilityClasses(classList: {[key: string]: boolean}): void {
    classList[this._visibleClass] = this.showPanel;
    classList[this._hiddenClass] = !this.showPanel;
  }
}
