import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { CountrySelectorComponent } from './country-selector.component';
import { KrosAutocompleteModule } from '../form/autocomplete/kros-autocomplete.module';
import { KrosFormFieldModule } from '../kros-inputs/kros-form-field/kros-form-field.module';
import { KrosInputsModule } from '../inputs';

@NgModule({
  declarations: [
    CountrySelectorComponent
  ],
  imports: [
    CommonModule,
    KrosInputsModule,
    ReactiveFormsModule,
    KrosFormFieldModule,
    KrosAutocompleteModule,
    TranslateModule
  ],
  exports: [
    CountrySelectorComponent
  ]
})
export class CountrySelectorModule { }
