import { Component, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';

import { SubSink } from 'subsink';

@Component({
  selector: 'kros-toggle',
  standalone: true,
  templateUrl: './toggle.component.html',
  styleUrls: [ './toggle.component.scss' ],
  imports: [
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ToggleComponent,
      multi: true
    },
  ]
})
export class ToggleComponent implements OnDestroy, ControlValueAccessor {

  toggle = new UntypedFormControl(false);

  private subs = new SubSink();

  constructor() { }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  writeValue(enabled: boolean): void {
    this.toggle.setValue(enabled, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.subs.add(this.toggle.valueChanges.subscribe(fn));
  }

  registerOnTouched(fn: any): void {
    this.subs.add(this.toggle.valueChanges.subscribe(fn));
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.toggle.disable({emitEvent: false});
    } else {
      this.toggle.enable({emitEvent: false});
    }
  }
}
