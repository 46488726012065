import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { catchError, EMPTY, map, Observable } from 'rxjs';

import { BuildingProgressSettings } from '@kros-sk/ssw-shared/settings';

import { environment } from '../../../environments/environment';

const projectApi = '/api/projects/';
const buildingProgressSettingEndpoint = '/buildingProgressService/buildingProgress/settings/';

@Injectable()
export class BuildingProgressSettingService {

  private http = inject(HttpClient);
  private gatewayApi = `${environment.appUrls.titanGatewayUrl}${projectApi}`;

  loadIsMultiStageApprovalMode(projectId: number): Observable<boolean> {
    return this.http.get<BuildingProgressSettings>(this.gatewayApi + projectId + buildingProgressSettingEndpoint)
      .pipe(
        map((settings: any) => settings.isMultiStageApprovalModeEnabled),
        catchError(error => EMPTY)
      );
  }
}
