import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Observable, take } from 'rxjs';

import { IBudgetItem, IBudgetModel, QuestionModalComponent, TranslateService } from '@kros-sk/ssw-shared-legacy';
import { KrosModalService } from '@kros-sk/components';

import { BuildingProgressActionAccessService } from '../../../../building-progress/services/building-progress-action-access.service';
import { DetailHelper, PermissionHelper } from '../../../../building-progress/helpers';
import { Subcontractor } from '../models';
import { SubcontractorsDispatchersService } from '../../../../store/subcontractors';
import { SubcontractorsRenameDialog } from '../subcontractor-rename-dialog/subcontractor-rename-dialog.component';

@Component({
  selector: 'app-subcontractors-list',
  templateUrl: './subcontractors-list.component.html',
  styleUrls: ['./subcontractors-list.component.scss']
})
export class SubcontractorsListComponent {
  @Input() subcontractors: Subcontractor[];
  @Input() set selectedSubcontractor(subcontractor: Subcontractor) {
    this._selectedSubcontractor = subcontractor;
    this.expandedId = subcontractor?.id;
  }
  get selectedSubcontractor(): Subcontractor {
    return this._selectedSubcontractor;
  }
  @Input() subcontractorData$: Observable<IBudgetModel>;
  @Input() tableId: string;

  @Output() selectSubcontractor: EventEmitter<Subcontractor> = new EventEmitter();
  @Output() selectBudgetNode: EventEmitter<IBudgetItem> = new EventEmitter();

  private _selectedSubcontractor: Subcontractor;
  private expandedId: number;

  constructor(
    private subcontractorsDispatchers: SubcontractorsDispatchersService,
    private detail: DetailHelper,
    private krosModalService: KrosModalService,
    private permissionHelper: PermissionHelper,
    private translateService: TranslateService,
    private accessService: BuildingProgressActionAccessService
  ) { }

  get canEditSubcontractors(): boolean {
    return !this.permissionHelper.isReader && !this.accessService.isLicenseReadOnly;
  }

  isSelected(subcontractor: Subcontractor): boolean {
    return subcontractor.id === this.selectedSubcontractor?.id;
  }

  isExpanded(subcontractor: Subcontractor): boolean {
    return this.expandedId === subcontractor.id;
  }

  toggleExpanded(subcontractor: Subcontractor): void {
    this.expandedId = this.isExpanded(subcontractor) ? undefined : subcontractor.id;
  }

  removeSubcontractor(subcontractorId: number, subcontractorName: string): void {
    const modalRef = this.krosModalService.openCentered(
      QuestionModalComponent,
      {
        body: `${this.translateService.translate('SUBCONTRACTORS.REMOVE_SUBCONTRACTOR_QUESTION')} ${subcontractorName}?`,
        confirmButton: this.translateService.translate('COMMON.DELETE'),
        cancelButton: this.translateService.translate('COMMON.NO')
      },
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: false,
        showMobileArrowBack: false,
        closeOnEscClick: true
      },
      NaN,
      NaN,
      undefined,
      'no-min-width'
    );

    modalRef.afterClosed$.pipe(take(1)).subscribe(result => {
      if (result.type === 'submit') {
        this.subcontractorsDispatchers.removeSubcontractor(
          this.detail.projectId, subcontractorId, this.selectedSubcontractor?.id ?? 0
        );
      }
    });
  }

  renameSubcontractor(subcontractorId: number, subcontractorName: string): void {
    const modalRef = this.krosModalService.openCentered(
      SubcontractorsRenameDialog,
      { subcontractorName },
      { closeOnBackdropClick: false }
    );

    modalRef.afterClosed$.pipe(take(1)).subscribe(result => {
      if (result.type === 'submit') {
        this.subcontractorsDispatchers.editSubcontractor(
          this.detail.projectId, this.selectedSubcontractor?.id ?? 0, { id: subcontractorId, name: result.data.subcontractorName }
        );
      }
    });
  }
}
