import { createAction, props } from '@ngrx/store';

import { LicensesState, UserFeatureFlags } from '@kros-sk/models';

import { KrosUser } from '../services/auth/kros-user.model';

export const startAuthentication = createAction('[Auth] Start authentication');
export const logoutUser = createAction('[Auth] User logout');

export const setEmptyUser = createAction('[Auth] Clear user');
export const setUser = createAction('[Auth] Set user', props<{ user: KrosUser }>());

export const loadFeatures = createAction('[Auth] Load features', props<{ user: KrosUser }>());
export const loadFeaturesSuccess = createAction('[Auth] Load features Success',
  props<{ features: UserFeatureFlags }>());
export const loadFeaturesFail = createAction('[Auth] Load features Fail');

export const loadCurrentLicense = createAction('[Auth] Load company license', props<{ companyId: number }>());
export const loadCurrentLicenseSuccess = createAction('[Auth] Load company license Success',
  props<{ licenseState: LicensesState }>());
export const loadCurrentLicenseFail = createAction('[Auth] Load company license Fail');
export const resetCurrentLicense = createAction('[Auth] set current license to null');

export const startLicenseTrial = createAction('[Auth] start license trial', props<{ companyId: number }>());
export const startLicenseTrialSuccess = createAction('[Auth] start license trial success', props<{ licenseState: LicensesState }>());
export const startLicenseTrialFail = createAction('[Auth] start license trial  fail', props<{ error: any }>());

export const assignLicense = createAction('[Auth] Assign license',
  props<{ companyId: number, customerNumber: string }>());
export const assignLicenseFail = createAction('[Auth] Assign license Fail');
export const assignLicenseSuccess = createAction('[Auth] Assign license Success',
  props<{ licenseState: LicensesState }>());

export const removeCompaniesFromLicense = createAction('[Auth] Remove companies from license',
  props<{ companyId: number, companyIds: number[], currentlyUsedCompanies: number[], customerNumber: string}>());
export const removeCompaniesFromLicenseSuccess = createAction('[Auth] Remove companies from license Success',
  props<{ licenseState: LicensesState }>());
export const removeCompaniesFromLicenseFail = createAction('[Auth] Remove companies from license Fail');
