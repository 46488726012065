import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'budget-building-objects-upload-toolbar',
  templateUrl: 'building-objects-upload-toolbar.component.html',
  styleUrls: ['building-objects-upload-toolbar.component.scss'],
  standalone: true,
  imports: [
    CommonModule, TranslateModule
  ]
})
export class BuildingObjectsUploadToolbarComponent {

  @Output() uploadBuildingObjects = new EventEmitter<void>();

  onUpload(): void {
    this.uploadBuildingObjects.emit();
  }
}
