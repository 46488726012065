import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { getDefaultTranslationCulture, ItemSearchEnum, KrosCompany } from '@kros-sk/models';

import { getCurrentCompanyIdFromUrl } from '../../../tools';

@Injectable()
export class CompanySearchService {

  private defaultTranslationCulture = getDefaultTranslationCulture();

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any,
    private router: Router
  ) { }

  searchInPartners(search: string, top: number, skip: number): Observable<KrosCompany[]> {
    return this.http.get<any[]>(this.encodeUrl(search, top, skip, 'companyRegister')).pipe(
      map(x => {
        const result = x.map(partner => {
          return ({
            id: 0,
            address: {
              businessName: partner.BusinessName,
              contactName: partner.ContactName,
              street: partner.Street,
              postCode: partner.PostCode,
              city: partner.City,
              country: partner.Country,
            },
            vatPayerType: partner.VatPayerType,
            registrationId: partner.RegistrationId,
            taxId: partner.TaxId,
            vatId: partner.VatId,
            isDefault: false,
            registrationCourtTextTranslations: this.getRegCourtTranslations(partner.RegistrationCourt),
            phoneNumber: partner.PhoneNumber,
            email: partner.Email,
            postalAddress: {
              businessName: partner.PostalAddressBusinessName,
              city: partner.PostalAddressCity,
              contactName: partner.PostalAddressContactName,
              country: partner.PostalAddressCountry,
              postCode: partner.PostalAddressPostCode,
              street: partner.PostalAddressStreet,
            },
          } as KrosCompany);
        });
        if (result.length === 0) {
          result.push(this.createDefaultPartner('Partner nebol nájdený', ItemSearchEnum.ITEM_NOT_FOUND_REG_ID));
        }
        result.push(this.createDefaultPartner('', ItemSearchEnum.ITEM_ADD_NEW_REG_ID));
        return result;
      }),
    );
  }

  searchInPartnersWithCompanyId(search: string, top: number, skip: number): Observable<KrosCompany[]> {
    return this.http.get<any[]>(this.encodeUrl(search, top, skip, 'purchaser', `/companies/${this.companyId}`)).pipe(
      map(x => {
        const result = x.map(partner => {
          return ({
            id: partner.Id ? partner.Id : 0,
            address: {
              businessName: partner.BusinessName,
              contactName: partner.ContactName,
              street: partner.Street,
              postCode: partner.PostCode,
              city: partner.City,
              country: partner.Country,
            },
            vatPayerType: partner.VatPayerType,
            registrationId: partner.RegistrationId,
            taxId: partner.TaxId,
            vatId: partner.VatId,
            isDefault: false,
            registrationCourtTextTranslations: this.getRegCourtTranslations(partner.RegistrationCourt),
            phoneNumber: partner.PhoneNumber,
            email: partner.Email,
            postalAddress: {
              businessName: partner.PostalAddressBusinessName,
              city: partner.PostalAddressCity,
              contactName: partner.PostalAddressContactName,
              country: partner.PostalAddressCountry,
              postCode: partner.PostalAddressPostCode,
              street: partner.PostalAddressStreet,
            },
            discountPercent: partner.DiscountPercent,
            paymentDue: partner.PaymentDue,
          } as KrosCompany);
        });
        if (result.length === 0) {
          result.push(this.createDefaultPartner('Partner nebol nájdený', ItemSearchEnum.ITEM_NOT_FOUND_REG_ID));
        }
        result.push(this.createDefaultPartner('', ItemSearchEnum.ITEM_ADD_NEW_REG_ID));
        return result;
      }),
    );
  }

  private get companyId(): number {
    return getCurrentCompanyIdFromUrl(this.router.url);
  }

  private getRegCourtTranslations(regText: string): { [key: string]: string } {
    return {[this.defaultTranslationCulture]: regText};
  }

  private createDefaultPartner(name: string, registrationId: string): KrosCompany {
    return ({
      address: {
        businessName: name,
      },
      registrationId,
    } as KrosCompany);
  }

  private encodeUrl(search: string, top: number, skip: number, controller: string, companyIdSegment: string = ''): string {
    const startUrl = `${this.appConfig.searchApiUrl}${companyIdSegment}/${controller}?`;
    const $encodedSearch = encodeURIComponent(search);
    return `${startUrl}searchMode=all&search=${$encodedSearch}&$top=${top}&$skip=${skip}`;
  }
}
