import { inject, Injectable } from '@angular/core';

import { ApplicationType } from '@kros-sk/ssw-cdk';

import { ConstructionDiaryDispatchersService } from '../data-access';

@Injectable()
export class ConstructionDiaryHelper {

  private dispatchers = inject(ConstructionDiaryDispatchersService);

  openStavario(): void {
    this.dispatchers.loadConstructionDiaryLicense(ApplicationType.Stavario);
  }

  openBuildary(): void {
    this.dispatchers.loadConstructionDiaryLicense(ApplicationType.Buildary);
  }
}
