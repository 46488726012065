import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { dateIsValid } from './date-tools';

export function getNgbDateStruct(date: Date): NgbDateStruct {
  if (!dateIsValid(date)) return null;

  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  };
}
