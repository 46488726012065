import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

import { DateIntervalComponent } from './date-interval/date-interval.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { EshopModule } from '../eshop';
import { KrosAnalyticsModule } from '../kros-analytics';
import { KrosInputsModule } from '../inputs';
import { KrosPaginationModule } from '../kros-pagination/kros-pagination.module';
import { LoaderEllipsisComponent } from './loader-ellipsis/loader-ellipsis.component';
import { SearchComponent } from './table/search/search.component';
import { TagModule } from '../tag/tag.module';
import { ToggleComponent } from './toggle/toggle.component';
import { TrialInfoComponent } from './trial-info/trial-info.component';
import { VatRateModule } from '../vat-rate/vat-rate.module';

/** this module is obsolete to keep backwards compatibility - every kros-component's component should have its own module */
const DECLARATIONS = [
  DateIntervalComponent,
  DatepickerComponent,
  LoaderEllipsisComponent,
  SearchComponent,
  TrialInfoComponent,
];

@NgModule({
  declarations: [
    ...DECLARATIONS
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    KrosAnalyticsModule,
    KrosInputsModule,
    KrosPaginationModule,
    VatRateModule,
    EshopModule,
    TagModule,
    ToggleComponent,
  ],
  exports: [
    ...DECLARATIONS,
    ToggleComponent,
  ]
})
export class KrosComponentsModule { }
