import { Type } from '@angular/core';

export class DataTableConfigModel {
  colDefs: ColDef[];
  rowClassGetter?: Function;
  rowEditableGetter?: Function;
  rowClicked?: (item: any) => void;
  hasCommentsGetter?: Function;
  itemsOffset: number[];
  isColumnVisible?: Function;
  isMultiSelect?: boolean;
  isItemSelectedGetter?: Function;
  isItemIndeterminateGetter?: Function;
  openDropdownComponent?: Function;
  getDropdownGaId?: Function;
  isReadonly: boolean;
  fixedWidth?: boolean;
}

export class ColDef {
  id: string;
  title: string;
  titleSuffix?: string;
  titleTooltip?: string;
  type: ColDefType;
  format?: Function;
  textAlign?: ColDefTextAlign;
  colsInGroup?: ColDef[];
  valueGetter?: Function;
  valueSetter?: Function;
  dropdownComponentConfig?: DropdownComponentConfig;
  cellClassGetter?: Function;
  editConfig?: EditConfig;
  comments?: boolean;
  params?: any;
  width?: number;
  minWidth?: number;
  left?: number;
  isFixed?: boolean;
  cellActionButton?: ColumnActionButton;
  floatingActionButton?: ColumnActionButton;
  leftStatusIcon?: ColumnStatusIcon;
  rightStatusIcons?: ColumnStatusIcon[];
  isPercentageColumn?: boolean;
  backgroundColorCode?: Function;
  componentConfig?: ComponentConfig;
  mobileHidden?: boolean;
  isHidden?: boolean;
  titleShort?: string;
  valueTooltip?: boolean;
}

export class Cell {
  colId: string;
  rowIndex: number;
  params?: any;
}

export enum ColDefType {
  Column = 1,
  ColumnGroup = 2,
}

export enum ColDefTextAlign {
  Left = 1,
  Center = 2,
  Right = 3,
}

export class EditConfig {
  onChange: boolean;
  onFocusOut: boolean;
  onEnter: boolean;
  onArrowMovement: boolean;
  columnEditableGetter?: Function;
}

export class ColumnStatusIcon {
  iconName: Function;
  tooltip: Function;
  clickAction: Function;
}

export class ColumnActionButton {
  id?: Function;
  iconName: Function;
  tooltip: Function;
  showCondition: Function;
  showAlways?: boolean;
  action: Function;
}

export class DropdownComponentConfig {
  iconName: string;
  tooltip: string;
  component: Type<any>;
  showCondition: Function;
}

export class ComponentConfig {
  component: Type<any>;
  params: IconComponentParams | any;
}

export class IconComponentParams {
  iconName: string;
  tooltip: string;
  action: Function;
  stopPropagation = true;
  hidden?: (item: any) => boolean;
}
