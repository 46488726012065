import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LicenseEventService {

  get licenseInfoClosed$(): Observable<null> {
    return this.licenseInfoClosedSubject.asObservable();
  }

  private licenseInfoClosedSubject = new Subject<null>();

  closeLicenseInfo(): void {
    this.licenseInfoClosedSubject.next(null);
  }
}
