import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';

import { interval } from 'rxjs';
import { SubSink } from 'subsink';

import { EXPIRABLE_CACHE_SETTINGS, ExpirableCacheSettings } from './expirable-cache-settings.model';
import { ExpirableCacheItem } from './expirable-cache-item.model';

/**
 * Expirable cache service of key and value.
 */
@Injectable()
export class ExpirableCacheService<TKey, TValue> implements OnDestroy {
  private cache = new Map<TKey, ExpirableCacheItem<TValue>>();
  private subs = new SubSink();
  /**
   * Costructs expirable cache service.
   * @param settings Optional expirable cache settings. Override defaults in providers.
   */
  constructor(
    @Inject(EXPIRABLE_CACHE_SETTINGS) @Optional() private settings: ExpirableCacheSettings
  ) {
    this.settings = settings || {
      cleanCache: true,
      cleaningInterval: 3600, // an hour
      expirationTime: 86400 // one day
    };

    if (this.settings.cleanCache) {
      this.subs.sink = interval(this.settings.cleaningInterval * 1000).subscribe(() => this.clean());
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Gets item from cache by its key if exists and is not expired, null otherwise.
   * @param key Cache item key.
   * @returns Value or null.
   */
  get(key: TKey): TValue {
    const item = this.cache.get(key);

    if (!item) {
      return null;
    }

    if (item.expires <= Date.now()) {
      this.delete(key);
      return null;
    }

    return item?.value;
  }

  /**
   * Sets item in cache.
   * @param key Cache item key.
   * @param value Cache item value.
   */
  set(key: TKey, value: TValue): void {
    const expires = Date.now() + this.settings.expirationTime * 1000;
    this.cache.set(key, { expires, value });
  }

  /**
   * Deletes an item by the provided key from the cache.
   * @param key Cache item key.
   * @returns True if deleted, false instead.
   */
  delete(key: TKey): boolean {
    return this.cache.delete(key);
  }

  /**
   * Removes invalid cache items.
   */
  private clean(): void {
    this.cache.forEach((item, key) => {
      if (item.expires <= Date.now()) {
        this.delete(key);
      }
    });
  }
}
