import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import * as actions from './budget-approval.actions';
import { BudgetApprovalState } from './budget-approval.state';
import { ChangeSheetApproveModel, ChangeSheetRelationEditModel } from '../../budget-approval/models';
import { DeleteRelationViewModel } from '../../budget-approval/models/delete-relation-view-model';

@Injectable()
export class BudgetApprovalDispatchersService {
  constructor(
    private store: Store<BudgetApprovalState>
  ) { }

  clearState(): void {
    this.store.dispatch(
      actions.clearState()
    );
  }

  loadBudgetApprovalData(projectId: number, hierarchyCode?: string): void {
    this.store.dispatch(
      actions.loadBudgetApprovalDataStart({ projectId, hierarchyCode })
    );
  }

  loadChangeSheetData(projectId: number, buildingObjectId: number): void {
    this.store.dispatch(
      actions.loadChangeSheetDataStart({ projectId, buildingObjectId })
    );
  }

  deleteBuildingObject(projectId: number, buildingObjectId: number): void {
    this.store.dispatch(
      actions.deleteBuildingObjectStart({ projectId, buildingObjectId })
    );
  }

  loadBudgetChangeData(projectId: number, buildingObjectId: number, setLoading = true): void {
    this.store.dispatch(
      actions.loadBudgetChangeDataStart({ projectId, buildingObjectId, setLoading })
    );
  }

  approveChangeSheet(changeSheetApproveModel: ChangeSheetApproveModel): void {
    this.store.dispatch(
      actions.approveChangeSheetStart({ changeSheetApproveModel })
    );
  }

  cancelApprovedChangeSheet(changeSheetApproveModel: ChangeSheetApproveModel): void {
    this.store.dispatch(
      actions.cancelApprovedChangeSheetStart({ changeSheetApproveModel })
    );
  }

  loadBudgetData(projectId: number): void {
    this.store.dispatch(
      actions.loadBudgetDataStart({ projectId })
    );
  }

  editChangeSheetItemRelation(editModel: ChangeSheetRelationEditModel, buildingObjectId: number): void {
    this.store.dispatch(
      actions.editChangeSheetItemRelationStart({ editModel, reloadChangesData: !!buildingObjectId, buildingObjectId })
    );
  }

  deleteChangeSheetRelation(deleteModel: DeleteRelationViewModel): void {
    this.store.dispatch(
      actions.deleteChangeSheetRelationStart({ deleteModel })
    );
  }

  selectChangeSheetItem(id: number, state: boolean): void {
    this.store.dispatch(
      actions.selectChangeSheetItem({ id, state })
    );
  }

  selectAllChangeSheetItems(): void {
    this.store.dispatch(
      actions.selectAllChangeSheetItems()
    );
  }

  clearSelectedChangeSheetItems(): void {
    this.store.dispatch(
      actions.clearSelectedChangeSheetItems()
    );
  }

  multiSelectItem(id: number, state: boolean): void {
    this.store.dispatch(
      actions.multiSelectItems({ id, state })
    );
  }

  multiSelectAllitems(): void {
    this.store.dispatch(
      actions.multiSelectAllItems()
    );
  }

  clearMultiSelectItems(): void {
    this.store.dispatch(
      actions.clearMultiSelectItems()
    );
  }

  loadSharingList(projectId: number): void {
    this.store.dispatch(
      actions.loadSharingListStart({ projectId })
    );
  }

  loadBoqItems(projectId: number, budgetItemId: number): void {
    this.store.dispatch(
      actions.loadBoqItemsStart({ projectId, budgetItemId })
    );
  }

  loadDifferencesData(projectId: number): void {
    this.store.dispatch(
      actions.loadDifferencesDataStart({ projectId })
    );
  }
}
