<div class="columns-selection-header">
  <h5>{{'BUILDING_PROGRESS.EXPORT_ADDITIONAL_COLUMNS' | translate}}</h5>

  <div class="bulk-selection">
    <button
      class="btn"
      type="button"
      data-test="export-deselect-all-columns"
      krosTooltip="{{ 'BUILDING_PROGRESS.EXPORT_DESELECT_ALL_COLUMNS' | translate }}"
      (click)="selectAll(false)"
    >
      <i class="material-icons">remove_done</i>
    </button>

    <button
      class="btn"
      type="button"
      data-test="export-select-all-columns"
      krosTooltip="{{ 'BUILDING_PROGRESS.EXPORT_SELECT_ALL_COLUMNS' | translate }}"
      (click)="selectAll(true)"
    >
      <i class="material-icons">done_all</i>
    </button>
  </div>
</div>

<div class="columns-selection">
  <form [formGroup]="form">
    <div
      *ngFor="let column of columns"
      class="column"
      [ngClass]="{'font-bold': !!column.childred}"
    >
      <div class="form-group checkbox">
        <input
          id="{{column.id}}"
          type="checkbox"
          formControlName="{{column.id}}"
          [attr.data-test]="column.dataTest"
          [krosIndeterminate]="column.indeterminate"
          (change)="onColumnCheckChanged($event.target.checked, column)"
        >

        <label
          for="{{column.id}}"
          [ngClass]="{'font-bold': !!column.children}"
        >{{column.title | translate}}</label>
      </div>

      <div
        *ngIf="!!column.children"
        class="children"
      >
        <div
          *ngFor="let child of column.children"
          class="child"
        >
          <div class="form-group checkbox">
            <input
              id="{{child.id}}"
              type="checkbox"
              formControlName="{{child.id}}"
              [attr.data-test]="child.dataTest"
              (change)="onChildCheckChanged($event.target.checked, column)"
            >

            <label
              for="{{child.id}}"
              [ngClass]="{'font-bold': !!child.children}"
            >{{child.title | translate}}</label>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
