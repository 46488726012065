import { BillOfQuantitiesItemType } from '@kros-sk/ssw-shared-legacy';

import { BoqPeriodItem } from '../models/boq-period-item.model';

const emptyBoqRecordsCount = 3;

export const createEmptyBoqRecords = (budgetItemId: number): BoqPeriodItem[] => {
  const boqRecords: BoqPeriodItem[] = [];

  for (let i = 0; i < emptyBoqRecordsCount; i++) {
    boqRecords.push(createEmptyBoqRecord(budgetItemId, i + 1));
  }

  return boqRecords;
};

const createEmptyBoqRecord = (budgetItemId: number, order: number, id?: number): BoqPeriodItem => {
  return {
    id: id ?? order * -1,
    budgetItemId,
    expression: '',
    isFromBudget: false,
    quantity: 0,
    boqItemType: BillOfQuantitiesItemType.Expression,
    itemOrder: order
  };
};

const getId = (items: BoqPeriodItem[], deletedBoqItemIds: number[]): number => {
  const ids = deletedBoqItemIds?.concat(items.map(p => p.id));
  return items?.length && ids?.length
    ? (Math.max(...ids.map(p => Math.abs(p))) + 1) * -1
    : -1;
};

export const createEmptyBoqAndReorderItems = (
  items: BoqPeriodItem[],
  deletedBoqItemIds: number[],
  budgetItemId: number,
  positionOfNewItem: number,
  isInsertDown: boolean): BoqPeriodItem[] => {
  if (isInsertDown) {
    return [
      ...items.filter(p => p.itemOrder <= positionOfNewItem),
      createEmptyBoqRecord(budgetItemId, positionOfNewItem + 1, getId(items, deletedBoqItemIds)),
      ...items.filter(p => p.itemOrder > positionOfNewItem).map(p => ({ ...p, itemOrder: p.itemOrder + 1 }))
    ].sort((p, r) => p.itemOrder - r.itemOrder);
  } else {
    return [
      ...items.filter(p => p.itemOrder < positionOfNewItem),
      createEmptyBoqRecord(budgetItemId, positionOfNewItem, getId(items, deletedBoqItemIds)),
      ...items.filter(p => p.itemOrder >= positionOfNewItem).map(p => ({ ...p, itemOrder: p.itemOrder + 1 }))
    ].sort((p, r) => p.itemOrder - r.itemOrder);
  }
};

export const getResultAmount = (items: BoqPeriodItem[], periodId: number): number => {
  let resultAmount = 0;
  items.forEach(i => {
    resultAmount += i.periodId && i.periodId === periodId ? i.quantity : 0;
  });

  return resultAmount;
};
