import { EnvironmentProviders, makeEnvironmentProviders, NgModule } from '@angular/core';

import { EffectsModule, provideEffects } from '@ngrx/effects';
import { provideState, StoreModule } from '@ngrx/store';

import { DeviceDetectorEffects } from './device-detector.effects';
import { deviceDetectorStoreFeature } from './device-detector.state';

export function provideDeviceDetector(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideState(deviceDetectorStoreFeature),
    provideEffects(DeviceDetectorEffects)
  ]);
}

/**
 * Compatibility with module based application. In standalone use provideDeviceDetector instead
 */
@NgModule({
  imports: [
    StoreModule.forFeature(deviceDetectorStoreFeature),
    EffectsModule.forFeature(DeviceDetectorEffects)
  ]
})
export class KrosDeviceDetectorProviderModule { }
