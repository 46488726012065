import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter } from 'rxjs/operators';

declare let gtag;

@Injectable()
export class AnalyticsService {

  private containerId = '';

  get analyticContainerId(): string {
    return this.containerId;
  }

  set analyticContainerId(value: string) {
    this.containerId = value;
  }

  private addScript(document: Document): void {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.containerId;
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  public bindAnalytic(document: Document, router?: Router, predicate?: (value: NavigationEnd) => boolean,
    specialEvent?: (value: NavigationEnd) => void): void {

    this.addScript(document);

    if (router) {

      const navEndEvents = router.events.pipe(
        filter(event => event instanceof NavigationEnd));

      navEndEvents.subscribe((event: NavigationEnd) => {
        if (predicate) {
          if (predicate(event)) {
            this.raiseViewChanged(event);
          }
        }
        if (specialEvent) {
          specialEvent(event);
        }
      });
    }
  }

  public raiseViewChanged(navigationEnd: NavigationEnd): void {
    gtag('config', this.containerId, {
      page_path: navigationEnd.urlAfterRedirects
    });
  }

  public raiseEvent(eventCategory: string, eventAction: string, eventLabel?: string, data?: string): void {
    gtag('event', 'btn-click', {
      event_category: eventCategory,
      event_action: eventAction,
      event_label: eventLabel ? eventLabel : '',
      value: data ? data : ''
    });
  }
}
