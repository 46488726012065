import { Injectable } from '@angular/core';

import { filter, take } from 'rxjs/operators';

import {
  DeleteDocumentService,
  DocumentListInfoModel,
  DocumentModalServiceInterface,
  TemporaryUploadInfoModel
} from '@kros-sk/ssw-shared-legacy';
import { KrosModalRef, KrosModalService } from '@kros-sk/components';
import { v4 as uuidv4 } from 'uuid';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

import { ContentTypes } from '../shared/content-types';
import { DocumentDescriptionModalComponent } from '../components/document-description-modal/document-description-modal.component';
import { DocumentsDispatchersService, DocumentsSelectorsService } from '../../store/documents';
import { DocumentSharingDialogComponent } from '../components/document-sharing-dialog/document-sharing-dialog.component';
import { DocumentVersionListModalComponent } from '../components/document-version-list-modal/document-version-list-modal.component';
import { UploadInfoModel } from '../models/upload-info.model';

@Injectable()
export class DocumentModalService implements DocumentModalServiceInterface {

  private uploadingData: TemporaryUploadInfoModel;
  private currentFolderId: string;

  constructor(
    private documentsDispatchersService: DocumentsDispatchersService,
    private documentsSelectorsService: DocumentsSelectorsService,
    private deleteDocumentService: DeleteDocumentService,
    private krosModalService: KrosModalService,
    private appInsightsService: AppInsightsBaseService
  ) {
    this.documentsSelectorsService.uploadingData$
      .subscribe(p => this.uploadingData = p);

    this.documentsSelectorsService.currentFolderId$
      .subscribe(id => this.currentFolderId = id);
  }

  onOpenVersionModal(document: DocumentListInfoModel, projectId: number): void {
    this.krosModalService.openCentered(
      DocumentVersionListModalComponent,
      {
        documentOriginalName: document.originalName,
        documentVersionId: document.currentVersionId,
        isConstruction: document.contentType === ContentTypes.construction
      },
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: true,
        showMobileArrowBack: true
      }
    ).afterClosed$
      .pipe(
        take(1),
        filter((res: any) => res.type === 'submit')
      )
      .subscribe(res => {
        if (res.data) {
          this.deleteDocumentService.deleteDocumentVersion(projectId, document.id, res.data);
        }
      });
  }

  onSetDocumentDescription(document: DocumentListInfoModel, canEdit: boolean): void {
    this.krosModalService.openCentered(
      DocumentDescriptionModalComponent,
      {
        name: document.originalName,
        description: document.description,
        canEdit
      },
      {
        addModalToBrowsersHistory: false,
        showMobileArrowBack: false
      }
    ).afterClosed$
      .pipe(
        take(1),
        filter((res: any) => res.type === 'submit')
      )
      .subscribe(res => {
        this.documentsDispatchersService.setDescription(document.id, document.currentVersionId, res.data.description);
      });
  }

  openDocumentSharingModalForDocuments(documents: DocumentListInfoModel[]): KrosModalRef {
    return this.krosModalService.openCentered(
      DocumentSharingDialogComponent,
      { documents },
      { closeOnBackdropClick: false }
    );
  }

  onUpload(projectId: number, buildingUploadInfo: UploadInfoModel, originalDocument: DocumentListInfoModel): void {
    this.uploadBuilding(projectId, buildingUploadInfo, originalDocument);
  }

  private uploadBuilding(
    projectId: number,
    buildingUploadInfo: UploadInfoModel,
    originalDocument: DocumentListInfoModel): void {
    const partialData = {
      name: buildingUploadInfo.name,
      size: buildingUploadInfo.size,
      originalName: buildingUploadInfo.name,
      blobName: buildingUploadInfo.blobAccessData.name,
      dateCreated: new Date(),
      description: ''
    };

    if (!!originalDocument) {
      const versionInfo = { ...partialData, documentId: originalDocument.id };
      this.documentsDispatchersService.uploadBuildingVersion(
        versionInfo, buildingUploadInfo.accessKey, projectId, this.currentFolderId);
    } else {
      const fileInfo = { ...partialData, id: uuidv4(), projectId, parentId: this.currentFolderId };
      this.documentsDispatchersService.uploadBuilding(fileInfo, buildingUploadInfo.accessKey);
    }
    if (this.uploadingData.showSharing) {
      this.appInsightsService.trackEvent('CD-upload-show-sharing-kros4-archive');
    } else {
      this.appInsightsService.trackEvent('CD-upload-kros4-archive');
    }
  }
}
