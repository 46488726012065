import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';

import { scrollElementIntoParentView, UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { Cell, DataTableConfigModel } from '../data-table-config.model';
import { DataTableComponent } from '../../data-table';
import { getFocusedCellElementId } from '../helpers';
import { TimelineType } from '../../timeline/timeline-type.enum';

export class DetailBaseComponent extends UnsubscribeComponent {

  timelineType = TimelineType;
  tableData: any;
  dataTableConfig: DataTableConfigModel;
  focusedCell: Cell;

  get hasData(): boolean {
    return !!this.itemCount;
  }

  get itemCount(): number {
    return this.tableData?.items?.length;
  }

  protected table: DataTableComponent;
  protected projectId: number;
  protected fixedColumnWidth: number;
  protected scrollElementToViewParams: any;

  constructor(
    protected appLocation: string,
  ) {
    super();
  }

  onCellFocused(event: any): void {
    this.focusedCell = event.cell;
  }

  protected focusCell(): void {
    if (!this.focusedCell && this.dataTableConfig?.colDefs.length) {
      this.focusedCell = {
        colId: this.dataTableConfig.colDefs[3].id,
        rowIndex: 0
      };
    }

    if (!!this.focusedCell) {
      this.scrollToFocusedCellAndSetFocus();
    }
  }

  onScrollFocusedCellToView(scrollToCenter: boolean): void {
    this.scrollToFocusedCellAndSetFocus({ block: scrollToCenter ? 'center' : 'nearest' });
  }

  private scrollToFocusedCellAndSetFocus(scrollArgumets?: ScrollIntoViewOptions): void {
    if (this.focusedCell) {
      this.scrollElementToView(getFocusedCellElementId(this.focusedCell), scrollArgumets).pipe(take(1))
        .subscribe(() => document.getElementById(getFocusedCellElementId(this.focusedCell))?.focus());
    }
  }

  protected scrollElementToView(elementId: string, scrollArgumets?: ScrollIntoViewOptions): Observable<void> {
    if (this.focusedCell && !document.getElementById(getFocusedCellElementId(this.focusedCell))) {
      if (this.table) {
        this.table.viewPort.scrollToIndex(this.focusedCell.rowIndex, 'auto');
      } else {
        this.scrollElementToViewParams = { elementId, scrollArgumets };
        return of(undefined);
      }
    }

    return scrollElementIntoParentView(
      elementId,
      this.table ? this.table.tableElement.nativeElement : document.getElementsByTagName('body')[0],
      200,
      30,
      this.fixedColumnWidth,
      undefined,
      scrollArgumets);
  }

  scrollFocusedCellIntoView(delay: number = 0): void {
    setTimeout(() => {
      if (this.focusedCell) {
        this.scrollElementToView(getFocusedCellElementId(this.focusedCell));
      }
    }, delay);
  }
}
