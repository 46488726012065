import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';

import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

import { DecimalPlaces, TimelineType } from '@kros-sk/ssw-shared-legacy';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';
import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { BuildingProgressDashboardTableComponent } from '../building-progress-dashboard-table/building-progress-dashboard-table.component';
import { BuildingProgressPieChartComponent } from '../building-progress-pie-chart/building-progress-pie-chart.component';
import { BuildingProgressSelectorsService } from '../../../../store/building-progress';
import { BuildingProgressService } from '../../../services';
import { Subcontractor } from '../../../modules/subcontractors/models';
import { SubcontractorsSelectorsService } from '../../../../store/subcontractors';
import { SummaryDashboardModel, SummaryDashboardTableModel } from '../../../models/summary-dashboard.model';

@Component({
  standalone: true,
  selector: 'app-building-progress-dashboard',
  templateUrl: './building-progress-dashboard.component.html',
  styleUrls: ['./building-progress-dashboard.component.scss'],
  imports: [
    CommonModule,
    BuildingProgressDashboardTableComponent,
    TranslateModule,
    BuildingProgressPieChartComponent,
    TooltipDirective
  ]
})
export class BuildingProgressDashboardComponent extends UnsubscribeComponent implements OnInit {
  data: SummaryDashboardModel;
  tableDataSubject: Subject<SummaryDashboardTableModel> = new Subject();
  dataGraph1: any[];
  dataGraph2: any[];
  dataGraph3: any[];
  currencySymbol: string;
  colorScheme1 = { domain: ['#9260A0'] };
  colorScheme2 = { domain: ['#4db5e5'] };
  colorScheme3 = { domain: ['#4bdbaf'] };
  digitsInfo: string;
  isDataLoaded = false;
  timelineType = TimelineType;
  hasAnyAddition = false;
  noItems = false;

  @Input() projectId: number;
  @Input() subcontractorsMode = false;

  @Output() closeClick = new EventEmitter<void>();

  @ViewChild('tableHeaderPlaceholder') tableHeaderPlaceholder: ElementRef<HTMLElement>;
  @ViewChild('dashboardTable') dashboardTable: BuildingProgressDashboardTableComponent;

  constructor(
    private apiService: BuildingProgressService,
    private selector: BuildingProgressSelectorsService,
    private renderer: Renderer2,
    private subcontractorsSelectorsService: SubcontractorsSelectorsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.selector.constructionDataDecPlaces$
      .pipe(filter(resp => !!resp))
      .subscribe((resp: DecimalPlaces) =>
        this.digitsInfo = `1.${resp.totalPrice}-${resp.totalPrice}`
      );
    this.subs.sink = this.selector.hasAnyAddition$.subscribe(resp => this.hasAnyAddition = resp);
    this.subs.sink = this.subcontractorsSelectorsService.selectedSubcontractor$.pipe(
      catchError(_ => of(undefined)),
      filter((s: Subcontractor) => !this.subcontractorsMode || !!s),
      tap(_ => {
        this.isDataLoaded = false;
        this.dashboardTable?.reloadData();
        this.noItems = false;
      }),
      switchMap(s => this.apiService.getSummaryDashboardData(this.projectId, this.subcontractorsMode ? s.id : undefined)
        .pipe(catchError(error => of(error))))
    ).subscribe(resp => {
      if (resp.status === 404) {
        this.noItems = true;
      } else if (resp && resp.table) {
        this.data = resp;
        this.currencySymbol = this.data.table.currency;
        this.dataGraph1 = [{ name: '', value: resp.totalPrices.completedPrice }];
        this.dataGraph2 = [{ name: '', value: resp.contractPrices.completedPrice }];
        this.dataGraph3 = [{ name: '', value: resp.additionsPrices.completedPrice }];
        this.isDataLoaded = true;
        this.tableDataSubject.next(this.data.table);
      }
    });
  }

  headerRowLoaded(event: HTMLElement): void {
    if (event && this.tableHeaderPlaceholder) {
      this.renderer.setProperty(this.tableHeaderPlaceholder.nativeElement, 'innerHTML', event.outerHTML);
    }
  }

  onCloseClick(): void {
    this.closeClick.emit();
  }
}
