import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { KrosModalRef, KrosModalService } from '@kros-sk/components';

import { SchoolLicenseForbiddenModalComponent } from '../school-license-forbidden-modal/school-license-forbidden-modal.component';

@Injectable()
export abstract class LicenseHelper {
  protected krosModalService = inject(KrosModalService);

  abstract openNotAccessDialog(isAutomatic?: boolean): void | Observable<KrosModalRef>;
  abstract destroy(): void;
  abstract checkLicense(): boolean | Observable<any>;
  abstract get isReadonlyLicense(): boolean;
  abstract get isFullLicense(): boolean;
  abstract get isTrialLicense(): boolean;
  abstract get isFreeLicense(): boolean;
  abstract get isTrialOrFullLicense(): boolean;
  abstract get isFreeOrReadonlyLicense(): boolean;
  abstract get isSchoolLicense(): boolean;
  resetPermission(): void {}

  checkSchoolLicense(): boolean {
    if (this.isSchoolLicense) {
      this.krosModalService.openCentered(SchoolLicenseForbiddenModalComponent, null, { addModalToBrowsersHistory: false });
      return false;
    }

    return true;
  }
}
