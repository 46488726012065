import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { ApplicationType } from '@kros-sk/ssw-cdk';

@Injectable()
export class StatisticsService {
  private readonly visitEndpoint = '/api/statisticsService/hubspot/visit';

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any,
    @Inject('gatewayUrlSelector') private gatewayUrlSelector: (appConfig: any) => string
  ) {
  }

  visit(applicationType: ApplicationType): Observable<any> {
    return this.http.patch(this.gatewayUrlSelector(this.appConfig) + this.visitEndpoint, { ApplicationType: applicationType });
  }
}
