import { Injectable } from '@angular/core';

import {
  ColDef,
  ColDefTextAlign,
  ColDefType,
  DataTableConfigModel,
  DecimalPlaces,
  formatNumberValue,
  getColumnWidth,
  getNodeLevel,
  getTextCellHeight,
  isConstructionOrBuildingObject
} from '@kros-sk/ssw-shared-legacy';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { ApprovalType } from '../enums';
import { BudgetApprovalColumn } from '../models/budget-approval-column.enum';
import { BudgetApprovalColumnsConfigModel, BudgetApprovalItem, BudgetApprovalModel } from '../models';
import { BudgetApprovalSelectorsService } from '../../store/budget-approval';
import { ColumnGroup } from '../../shared/models/column-group.model';
import { environment } from '../../../environments/environment';

@Injectable()
export class BudgetApprovalTableService extends UnsubscribeComponent {
  averageLineHeight: number;

  private selectedItemIds = new Set<number>();

  constructor(
    private budgetApprovalSelectorService: BudgetApprovalSelectorsService
  ) {
    super();
    this.subs.sink = this.budgetApprovalSelectorService.selectedItemsIds$.subscribe(
      ids => {
        if (ids && ids instanceof Set) {
          this.selectedItemIds = ids;
        }
      }
    );
  }

  getTableConfig(
    budgetApprovalData: BudgetApprovalModel,
    columnGroups: ColumnGroup[]
  ): DataTableConfigModel {
    const columnsConfig = this.getColumnsConfig(budgetApprovalData);
    this.averageLineHeight = columnsConfig.averageLineHeight;

    return {
      colDefs: this.getColDefs(budgetApprovalData.decimalPlaces, columnsConfig, columnGroups),
      itemsOffset: columnsConfig.itemsOffset,
      isMultiSelect: true,
      isReadonly: true,
      fixedWidth: true,
      rowClassGetter: (item: BudgetApprovalItem): string =>
        (isConstructionOrBuildingObject(item) ? 'font-blue ' : '') +
        (item.approvalType === ApprovalType.None ? 'font-strong ' : '') +
        (item.approvalType === ApprovalType.Approved ? 'approval-item font-green ' : '') +
        (item.approvalType === ApprovalType.NotApproved ? 'approval-item font-brown ' : ''),
      isItemSelectedGetter: (item: BudgetApprovalItem): boolean =>
        this.selectedItemIds && this.selectedItemIds.has(item.id)
    };
  }

  private getColDefs(
    decimalPlaces: DecimalPlaces,
    columnsConfig: BudgetApprovalColumnsConfigModel,
    columnGroups: ColumnGroup[]
  ): ColDef[] {
    return [
      {
        id: BudgetApprovalColumn.ItemType,
        title: 'BUDGET_APPROVAL.TYPE',
        type: ColDefType.Column,
        textAlign: ColDefTextAlign.Center,
        isFixed: true,
        width: 25,
        valueGetter: this.getItemType
      } as ColDef,
      {
        id: BudgetApprovalColumn.Code,
        title: 'BUDGET_APPROVAL.CODE',
        type: ColDefType.Column,
        isFixed: true,
        width: 80,
        valueGetter: (item: BudgetApprovalItem): any => item.code
      } as ColDef,
      {
        id: BudgetApprovalColumn.Description,
        title: 'BUDGET_APPROVAL.DESCRIPTION',
        type: ColDefType.Column,
        isFixed: true,
        width: 300,
        cellClassGetter: (item: BudgetApprovalItem): string => item ? ('level-' + getNodeLevel(item.level)) : '',
        valueGetter: (item: BudgetApprovalItem): any => item.description
      } as ColDef,
      {
        id: BudgetApprovalColumn.TotalPrice,
        title: 'BUDGET_APPROVAL.ORGINAL_PRICE',
        type: ColDefType.Column,
        format: this.formatNumberPrice.bind(this, decimalPlaces),
        textAlign: ColDefTextAlign.Right,
        isFixed: true,
        width: this.getColumnWidthFromNumberValue(columnsConfig.totalPrice, 100),
        valueGetter: (item: BudgetApprovalItem): any => item.totalPrice
      } as ColDef
    ].concat([
      ...this.getApprovedColDefs(decimalPlaces, columnsConfig, columnGroups),
      ...this.getNotApprovedColDefs(decimalPlaces, columnsConfig, columnGroups),
    ]);
  }

  private getApprovedColDefs(
    decimalPlaces: DecimalPlaces,
    columnsConfig: BudgetApprovalColumnsConfigModel,
    columnGroups: ColumnGroup[]
  ): ColDef[] {
    const colDefs = [{
      id: BudgetApprovalColumn.ApprovedChanges,
      title: 'BUDGET_APPROVAL.APPROVED_CHANGES',
      type: ColDefType.ColumnGroup,
      colsInGroup: [
        ...(this.isColumnVisible(BudgetApprovalColumn.ApprovedNegativeTotalPrice, columnGroups) ? [{
          id: BudgetApprovalColumn.ApprovedNegativeTotalPrice,
          title: 'BUDGET_APPROVAL.NEGATIVE',
          type: ColDefType.Column,
          format: this.formatNumberPrice.bind(this, decimalPlaces),
          textAlign: ColDefTextAlign.Right,
          valueGetter: (item: BudgetApprovalItem): any => item.approvedNegativeTotalPrice,
          cellClassGetter: this.approvedColumnsClass,
          minWidth: this.getColumnWidthFromNumberValue(columnsConfig.approvedNegativeTotalPrice, 100)
        }] : []),
        ...(this.isColumnVisible(BudgetApprovalColumn.ApprovedPositiveTotalPrice, columnGroups) ? [{
          id: BudgetApprovalColumn.ApprovedPositiveTotalPrice,
          title: 'BUDGET_APPROVAL.POSITIVE',
          type: ColDefType.Column,
          format: this.formatNumberPrice.bind(this, decimalPlaces),
          textAlign: ColDefTextAlign.Right,
          valueGetter: (item: BudgetApprovalItem): any => item.approvedPositiveTotalPrice,
          cellClassGetter: this.approvedColumnsClass,
          minWidth: this.getColumnWidthFromNumberValue(columnsConfig.approvedPositiveTotalPrice, 100)
        }] : []),
        ...(this.isColumnVisible(BudgetApprovalColumn.ApprovedTotalPrice, columnGroups) ? [{
          id: BudgetApprovalColumn.ApprovedTotalPrice,
          title: 'BUDGET_APPROVAL.TOTAL',
          type: ColDefType.Column,
          format: this.formatNumberPrice.bind(this, decimalPlaces),
          textAlign: ColDefTextAlign.Right,
          valueGetter: (item: BudgetApprovalItem): any => item.approvedTotalPrice,
          cellClassGetter: this.approvedColumnsClass,
          minWidth: this.getColumnWidthFromNumberValue(columnsConfig.approvedTotalPrice, 100)
        }] : []),
        ...(this.isColumnVisible(BudgetApprovalColumn.ApprovedPercentage, columnGroups) ? [{
          id: BudgetApprovalColumn.ApprovedPercentage,
          title: 'BUDGET_APPROVAL.PERCENT_CHANGE',
          titleTooltip: 'BUDGET_APPROVAL.COLUMN_FILTER.TOTAL_PRICE_CHANGE_PERCENT',
          type: ColDefType.Column,
          format: (value, _, item: BudgetApprovalItem): string =>
            this.formatNumberPercentage(decimalPlaces, value, item.approvedAbsoluteChangeTotalPrice > 0),
          textAlign: ColDefTextAlign.Right,
          valueGetter: (item: BudgetApprovalItem): any => item.approvedPercentage,
          cellClassGetter: this.approvedColumnsClass,
          minWidth: this.getColumnWidthFromNumberValue(columnsConfig.approvedPercentage)
        }] : []),
        ...(this.isColumnVisible(BudgetApprovalColumn.ApprovedAbsoluteChangeTotalPrice, columnGroups) ? [{
          id: BudgetApprovalColumn.ApprovedAbsoluteChangeTotalPrice,
          title: 'BUDGET_APPROVAL.ABSOLUTE_CHANGE',
          type: ColDefType.Column,
          format: this.formatNumberPrice.bind(this, decimalPlaces),
          textAlign: ColDefTextAlign.Right,
          valueGetter: (item: BudgetApprovalItem): any => item.approvedAbsoluteChangeTotalPrice,
          cellClassGetter: this.approvedColumnsClass,
          minWidth: this.getColumnWidthFromNumberValue(columnsConfig.approvedAbsoluteChangeTotalPrice, 100)
        }] : []),
        ...(this.isColumnVisible(BudgetApprovalColumn.ApprovedAbsoluteChangePercentage, columnGroups) ? [{
          id: BudgetApprovalColumn.ApprovedAbsoluteChangePercentage,
          title: 'BUDGET_APPROVAL.PERCENT_ABSOLUTE_CHANGE',
          titleTooltip: 'BUDGET_APPROVAL.COLUMN_FILTER.ABSOLUTE_CHANGE_PERCENT',
          type: ColDefType.Column,
          format: (value, _, item: BudgetApprovalItem): string =>
            this.formatNumberPercentage(decimalPlaces, value, item.approvedAbsoluteChangeTotalPrice > 0),
          textAlign: ColDefTextAlign.Right,
          valueGetter: (item: BudgetApprovalItem): any => item.approvedAbsoluteChangePercentage,
          cellClassGetter: this.approvedColumnsClass,
          minWidth: this.getColumnWidthFromNumberValue(columnsConfig.approvedAbsoluteChangePercentage)
        }] : []),
        {
          id: BudgetApprovalColumn.ApprovedActualTotalPrice,
          title: 'BUDGET_APPROVAL.CURRENT_PRICE',
          type: ColDefType.Column,
          format: this.formatNumberPrice.bind(this, decimalPlaces),
          textAlign: ColDefTextAlign.Right,
          valueGetter: (item: BudgetApprovalItem): any => item.approvedActualTotalPrice,
          cellClassGetter: this.approvedColumnsClass,
          minWidth: this.getColumnWidthFromNumberValue(columnsConfig.approvedActualTotalPrice, 100)
        }
      ],
      cellClassGetter: (): string => this.approvedColumnsClass(),
    }];

    if (colDefs[0].colsInGroup[0]) {
      const classString = colDefs[0].colsInGroup[0].cellClassGetter();
      colDefs[0].colsInGroup[0].cellClassGetter = (): string => classString;
    }

    return colDefs;
  }

  private getNotApprovedColDefs(
    decimalPlaces: DecimalPlaces,
    columnsConfig: BudgetApprovalColumnsConfigModel,
    columnGroups: ColumnGroup[]
  ): ColDef[] {
    const colDefs = [{
      id: BudgetApprovalColumn.NotApprovedChanges,
      title: 'BUDGET_APPROVAL.NOT_APPROVED_CHANGES',
      type: ColDefType.ColumnGroup,
      colsInGroup: [
        ...(this.isColumnVisible(BudgetApprovalColumn.NotApprovedNegativeTotalPrice, columnGroups) ? [{
          id: BudgetApprovalColumn.NotApprovedNegativeTotalPrice,
          title: 'BUDGET_APPROVAL.NEGATIVE',
          type: ColDefType.Column,
          format: this.formatNumberPrice.bind(this, decimalPlaces),
          textAlign: ColDefTextAlign.Right,
          valueGetter: (item: BudgetApprovalItem): any => item.notApprovedNegativeTotalPrice,
          cellClassGetter: this.notApprovedColumnsClass,
          minWidth: this.getColumnWidthFromNumberValue(columnsConfig.notApprovedNegativeTotalPrice, 100)
        }] : []),
        ...(this.isColumnVisible(BudgetApprovalColumn.NotApprovedPositiveTotalPrice, columnGroups) ? [{
          id: BudgetApprovalColumn.NotApprovedPositiveTotalPrice,
          title: 'BUDGET_APPROVAL.POSITIVE',
          type: ColDefType.Column,
          format: this.formatNumberPrice.bind(this, decimalPlaces),
          textAlign: ColDefTextAlign.Right,
          valueGetter: (item: BudgetApprovalItem): any => item.notApprovedPositiveTotalPrice,
          cellClassGetter: this.notApprovedColumnsClass,
          minWidth: this.getColumnWidthFromNumberValue(columnsConfig.notApprovedPositiveTotalPrice, 100)
        }] : []),
        ...(this.isColumnVisible(BudgetApprovalColumn.NotApprovedTotalPrice, columnGroups) ? [{
          id: BudgetApprovalColumn.NotApprovedTotalPrice,
          title: 'BUDGET_APPROVAL.TOTAL',
          type: ColDefType.Column,
          format: this.formatNumberPrice.bind(this, decimalPlaces),
          textAlign: ColDefTextAlign.Right,
          valueGetter: (item: BudgetApprovalItem): any => item.notApprovedTotalPrice,
          cellClassGetter: this.notApprovedColumnsClass,
          minWidth: this.getColumnWidthFromNumberValue(columnsConfig.notApprovedTotalPrice, 100)
        }] : []),
        ...(this.isColumnVisible(BudgetApprovalColumn.NotApprovedPercentage, columnGroups) ? [{
          id: BudgetApprovalColumn.NotApprovedPercentage,
          title: 'BUDGET_APPROVAL.PERCENT_CHANGE',
          titleTooltip: 'BUDGET_APPROVAL.COLUMN_FILTER.TOTAL_PRICE_CHANGE_PERCENT',
          type: ColDefType.Column,
          format: (value, _, item: BudgetApprovalItem): string =>
            this.formatNumberPercentage(decimalPlaces, value, item.notApprovedAbsoluteChangeTotalPrice > 0),
          textAlign: ColDefTextAlign.Right,
          valueGetter: (item: BudgetApprovalItem): any => item.notApprovedPercentage,
          cellClassGetter: this.notApprovedColumnsClass,
          minWidth: this.getColumnWidthFromNumberValue(columnsConfig.notApprovedPercentage)
        }] : []),
        ...(this.isColumnVisible(BudgetApprovalColumn.NotApprovedAbsoluteChangeTotalPrice, columnGroups) ? [{
          id: BudgetApprovalColumn.NotApprovedAbsoluteChangeTotalPrice,
          title: 'BUDGET_APPROVAL.ABSOLUTE_CHANGE',
          type: ColDefType.Column,
          format: this.formatNumberPrice.bind(this, decimalPlaces),
          textAlign: ColDefTextAlign.Right,
          valueGetter: (item: BudgetApprovalItem): any => item.notApprovedAbsoluteChangeTotalPrice,
          cellClassGetter: this.notApprovedColumnsClass,
          minWidth: this.getColumnWidthFromNumberValue(columnsConfig.notApprovedAbsoluteChangeTotalPrice, 100)
        }] : []),
        ...(this.isColumnVisible(BudgetApprovalColumn.NotApprovedAbsoluteChangePercentage, columnGroups) ? [{
          id: BudgetApprovalColumn.NotApprovedAbsoluteChangePercentage,
          title: 'BUDGET_APPROVAL.PERCENT_ABSOLUTE_CHANGE',
          titleTooltip: 'BUDGET_APPROVAL.COLUMN_FILTER.ABSOLUTE_CHANGE_PERCENT',
          type: ColDefType.Column,
          format: (value, _, item: BudgetApprovalItem): string =>
            this.formatNumberPercentage(decimalPlaces, value, item.notApprovedAbsoluteChangeTotalPrice > 0),
          textAlign: ColDefTextAlign.Right,
          valueGetter: (item: BudgetApprovalItem): any => item.notApprovedAbsoluteChangePercentage,
          cellClassGetter: this.notApprovedColumnsClass,
          minWidth: this.getColumnWidthFromNumberValue(columnsConfig.notApprovedAbsoluteChangePercentage)
        }] : []),
        {
          id: BudgetApprovalColumn.NotApprovedActualTotalPrice,
          title: 'BUDGET_APPROVAL.TOTAL_PRICE_AFTER_APPROVAL',
          type: ColDefType.Column,
          format: this.formatNumberPrice.bind(this, decimalPlaces),
          textAlign: ColDefTextAlign.Right,
          valueGetter: (item: BudgetApprovalItem): any => item.notApprovedActualTotalPrice,
          cellClassGetter: this.notApprovedColumnsClass,
          minWidth: this.getColumnWidthFromNumberValue(columnsConfig.notApprovedActualTotalPrice, 100)
        }
      ],
      cellClassGetter: (): string => this.notApprovedColumnsClass() + ' ' + this.borderLeftBoldClass(),
    }];

    if (colDefs[0].colsInGroup[0]) {
      const classString = colDefs[0].colsInGroup[0].cellClassGetter();
      colDefs[0].colsInGroup[0].cellClassGetter = (): string => classString + ' ' + this.borderLeftBoldClass();
    }

    return colDefs;
  }

  private getItemType(item: BudgetApprovalItem): string {
    return item.approvalType === ApprovalType.None ? item.itemType : 'ZL';
  }

  private formatNumberPrice(decimalPlaces: DecimalPlaces, value: any): string {
    return value ? formatNumberValue(value, decimalPlaces.totalPrice, environment.location) : '';
  }

  private formatNumberPercentage(decimalPlaces: DecimalPlaces, value: any, showZero?: boolean): string {
    return value || showZero ? (formatNumberValue(value, decimalPlaces.percentage, environment.location) + '%') : '';
  }

  private approvedColumnsClass(): string {
    return 'approved-changes';
  }

  private notApprovedColumnsClass(): string {
    return 'not-approved-changes';
  }

  private borderLeftBoldClass(): string {
    return 'border-left-bold';
  }

  private getColumnWidthFromNumberValue(maxNumber: number, minWidth: number = 50): number {
    return Math.max(minWidth, getColumnWidth(maxNumber, 2, environment.location, false));
  }

  private getColumnsConfig(data: BudgetApprovalModel): BudgetApprovalColumnsConfigModel {
    const itemsOffset = [];
    const stavba = data.items[0];
    let totalHeight = 0;

    data.items.forEach(p => {
      totalHeight += Math.max(
        getTextCellHeight(p.description.length, p.level, false, 300, 0),
        getTextCellHeight(p.code.length, 0, false, 80, 0));
      itemsOffset.push(totalHeight);
    });

    return {
      itemsOffset,
      averageLineHeight: Math.floor(totalHeight / data.items.length),
      totalPrice: stavba.totalPrice,
      approvedTotalPrice: stavba.approvedTotalPrice,
      approvedPercentage: stavba.approvedPercentage,
      approvedActualTotalPrice: stavba.approvedActualTotalPrice,
      approvedNegativeTotalPrice: stavba.approvedNegativeTotalPrice,
      approvedPositiveTotalPrice: stavba.approvedPositiveTotalPrice,
      approvedAbsoluteChangeTotalPrice: stavba.approvedAbsoluteChangeTotalPrice,
      approvedAbsoluteChangePercentage: stavba.approvedAbsoluteChangePercentage,
      notApprovedTotalPrice: stavba.notApprovedTotalPrice,
      notApprovedPercentage: stavba.notApprovedPercentage,
      notApprovedNegativeTotalPrice: stavba.notApprovedNegativeTotalPrice,
      notApprovedPositiveTotalPrice: stavba.notApprovedPositiveTotalPrice,
      notApprovedAbsoluteChangeTotalPrice: stavba.notApprovedAbsoluteChangeTotalPrice,
      notApprovedAbsoluteChangePercentage: stavba.notApprovedAbsoluteChangePercentage,
      notApprovedActualTotalPrice: stavba.notApprovedActualTotalPrice
    };
  }

  private isColumnVisible(columnId: string, columnGroups: ColumnGroup[]): boolean {
    return columnGroups.some(group => group.checked && group.columnIds.some(id => id === columnId));
  }
}
