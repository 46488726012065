import { ComponentPortal } from '@angular/cdk/portal';
import {
  ConnectedPosition,
  FlexibleConnectedPositionStrategy,
  HorizontalConnectionPos,
  OriginConnectionPosition,
  Overlay,
  OverlayConnectionPosition,
  OverlayRef,
  VerticalConnectionPos
} from '@angular/cdk/overlay';
import {
  Directive,
  ElementRef,
  HostListener,
  inject,
  InjectionToken,
  input,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { Platform } from '@angular/cdk/platform';

import { TooltipComponent } from './tooltip.component';

export function TOOLTIP_DEFAULT_OPTIONS_FACTORY(): TooltipDefaultOptions {
  return {
    showDelay: 0,
    hideDelay: 0,
  };
}

/** Injection token to be used to override the default options for `matTooltip`. */
export const TOOLTIP_DEFAULT_OPTIONS = new InjectionToken<TooltipDefaultOptions>(
  'kros-tooltip-default-options',
  {
    providedIn: 'root',
    factory: TOOLTIP_DEFAULT_OPTIONS_FACTORY,
  },
);

/** Default `kros-tooltip` options that can be overridden. */
export interface TooltipDefaultOptions {
  /** Default delay when the tooltip is shown. */
  showDelay: number;

  /** Default delay when the tooltip is hidden. */
  hideDelay: number;
}

const MIN_VIEWPORT_TOOLTIP_THRESHOLD = 8;
const UNBOUNDED_ANCHOR_GAP = 8;

export type TooltipPosition = 'left' | 'right' | 'above' | 'above-left' | 'below' | 'before' | 'after';

@Directive({
  selector: '[krosTooltip]',
  exportAs: 'krosTooltip',
  standalone: true,
})
export class TooltipDirective implements OnDestroy {
  /**
   * The content to be shown in the tooltip. Can be a string or a TemplateRef.
   */
  @Input({ required: true, alias: 'krosTooltip' }) tooltip: string | TemplateRef<void>;

  @Input({ alias: 'krosTooltipContext' }) context: any;

  @Input() tooltipShowManually = false;

  /** Classes to be passed to the tooltip. Supports the same syntax as `ngClass`. */
  tooltipClass = input('', {
    transform: (value: string | string[] | Set<string> | {[key: string]: any}) => {
      this.setTooltipClass(value);
      return value;
    }
  });

  /**
   * The delay in milliseconds before showing the tooltip after a hover event.
   */
  @Input()
  get tooltipShowDelay(): number {
    return this._tooltipShowDelay;
  };

  set tooltipShowDelay(value: number) {
    this._tooltipShowDelay = value;
  }

  private _tooltipShowDelay: number;

  /**
   * The delay in milliseconds before hiding the tooltip after a hide is called.
   */
  @Input()
  get tooltipHideDelay(): number {
    return this._tooltipHideDelay;
  };

  set tooltipHideDelay(value: number) {
    this._tooltipHideDelay = value;
  }

  private _tooltipHideDelay: number;

  /**
   * Whether the tooltip is disabled.
   */
  @Input('tooltipDisabled')
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
    if (this._disabled) {
      this.hide(0);
    }
  }

  /**
   * The position of the tooltip relative to the host element.
   */
  @Input()
  get position(): TooltipPosition {
    return this._position;
  }

  set position(value: TooltipPosition) {
    if (value !== this._position) {
      this._position = value;

      if (this.overlayRef) {
        this.updatePosition(this.overlayRef);
        this.tooltipInstance?.show(0);
        this.overlayRef.updatePosition();
      }
    }
  }

  private _disabled = false;
  private _position: TooltipPosition = 'above';
  private elementRef = inject(ElementRef);
  private overlay = inject(Overlay);
  private platform = inject(Platform);
  private viewContainerRef = inject(ViewContainerRef);
  private readonly tooltipComponent = TooltipComponent;
  private readonly viewportMargin = 8;
  private overlayRef: OverlayRef | null;
  private portal: ComponentPortal<TooltipComponent>;
  private tooltipInstance: TooltipComponent | null;
  private defaultOptions = inject(TOOLTIP_DEFAULT_OPTIONS, { optional: true });
  private touchstartTimeout: ReturnType<typeof setTimeout>;

  constructor() {
    if (this.defaultOptions) {
      this._tooltipShowDelay = this.defaultOptions.showDelay;
      this._tooltipHideDelay = this.defaultOptions.hideDelay;
    }

    this.viewportMargin = MIN_VIEWPORT_TOOLTIP_THRESHOLD;
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (!this.tooltipShowManually && this.platformSupportsMouseEvents()) {
      this.show();
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    if (!this.tooltipShowManually && this.platformSupportsMouseEvents()) {
      this.hide();
    }
  }

  @HostListener('touchstart')
  onTouchStart(): void {
    clearTimeout(this.touchstartTimeout);
    const DEFAULT_LONGPRESS_DELAY = 500;
    this.touchstartTimeout = setTimeout(() => this.show(), DEFAULT_LONGPRESS_DELAY);
  }

  @HostListener('touchend')
  onTouchEnd(): void {
    clearTimeout(this.touchstartTimeout);
    this.hide();
  }

  ngOnDestroy(): void {
    clearTimeout(this.touchstartTimeout);
    this.hide();
  }

  /**
   * Shows the tooltip with an optional delay.
   * @param delay The delay in milliseconds before showing the tooltip.
   * @param templateContext The context to be passed to the tooltip template.
   */
  show(delay: number = this.tooltipShowDelay, templateContext: any = undefined): void {
    if (this.disabled || !this.tooltip || this.isTooltipVisible()) {
      this.tooltipInstance?.cancelPendingAnimations();
      return;
    }

    const overlayRef = this.createOverlay();
    this.detach();
    this.portal = this.portal ||
      new ComponentPortal(this.tooltipComponent, this.viewContainerRef);
    this.tooltipInstance = overlayRef.attach(this.portal).instance;
    this.setTooltipClass(this.tooltipClass());
    this.updateTooltipMessage(templateContext ?? this.context);
    this.tooltipInstance.show(delay);
  }

  /**
   * Hides the tooltip with an optional delay.
   * @param delay The delay in milliseconds before hiding the tooltip.
   */
  hide(delay: number = this.tooltipHideDelay): void {
    const instance = this.tooltipInstance;
    if (instance) {
      if (instance.isVisible) {
        instance.hide(delay);
      } else {
        instance.cancelPendingAnimations();
        this.detach();
      }
    }
  }

  /** Returns true if the tooltip is currently visible to the user */
  isTooltipVisible(): boolean {
    return !!this.tooltipInstance && this.tooltipInstance.isVisible;
  }

  /**
   * Creates an overlay for the tooltip and positions it according to the position input.
   * @returns The created OverlayRef.
   */
  private createOverlay(): OverlayRef {
    if (this.overlayRef) {
      const existingStrategy = this.overlayRef.getConfig().positionStrategy as FlexibleConnectedPositionStrategy;

      if (existingStrategy._origin instanceof ElementRef) {
        return this.overlayRef;
      }

      this.detach();
    }

    // Create connected position strategy that listens for scroll events to reposition.
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withFlexibleDimensions(false)
      .withViewportMargin(this.viewportMargin);

    this.overlayRef = this.overlay.create({ positionStrategy });
    this.updatePosition(this.overlayRef);

    return this.overlayRef;
  }

  /**
   * Updates the position of the tooltip.
   * @param overlayRef - The overlay reference of the tooltip.
   */
  private updatePosition(overlayRef: OverlayRef): void {
    const position = overlayRef.getConfig().positionStrategy as FlexibleConnectedPositionStrategy;
    const origin = this.getOrigin();
    const overlay = this.getOverlayPosition();

    position.withPositions([
      this.addOffset({ ...origin.main, ...overlay.main }),
      this.addOffset({ ...origin.fallback, ...overlay.fallback }),
    ]);
  }

  /**
   * Updates the tooltip message and its position.
   */
  private updateTooltipMessage(templateContext: any): void {
    if (this.tooltipInstance) {
      this.tooltipInstance.context = templateContext;
      this.tooltipInstance.tooltip = this.tooltip;
      this.tooltipInstance.markForCheck();
    }
  }

  /** Updates the tooltip class */
  private setTooltipClass(tooltipClass: string | string[] | Set<string> | {[key: string]: any}): void {
    if (this.tooltipInstance) {
      this.tooltipInstance.tooltipClass = tooltipClass;
    }
  }

  private platformSupportsMouseEvents(): boolean {
    return !this.platform.IOS && !this.platform.ANDROID;
  }

  /**
   * Returns the origin position and a fallback position based on the user's position preference.
   * The fallback position is the inverse of the origin (e.g. `'below' -> 'above'`).
   * @returns An object containing the main and fallback origin connection positions.
   */
  private getOrigin(): { main: OriginConnectionPosition; fallback: OriginConnectionPosition } {
    const position = this.position;
    let originPosition: OriginConnectionPosition;

    if (position === 'above' || position === 'below') {
      originPosition = { originX: 'center', originY: position === 'above' ? 'top' : 'bottom' };
    } else if (position === 'before' || position === 'left') {
      originPosition = { originX: 'start', originY: 'center' };
    } else if (position === 'after' || position === 'right') {
      originPosition = { originX: 'end', originY: 'center' };
    } else if (position === 'above-left') {
      originPosition = { originX: 'start', originY: 'top' };
    }

    const { x, y } = this.invertPosition(originPosition.originX, originPosition.originY);

    return {
      main: originPosition,
      fallback: { originX: x, originY: y },
    };
  }

  /**
   * Returns the overlay position and a fallback position based on the user's position preference.
   * @returns An object containing the main and fallback overlay connection positions.
   */
  private getOverlayPosition(): { main: OverlayConnectionPosition; fallback: OverlayConnectionPosition } {
    const position = this.position;
    let overlayPosition: OverlayConnectionPosition;

    if (position === 'above') {
      overlayPosition = { overlayX: 'center', overlayY: 'bottom' };
    } else if (position === 'below') {
      overlayPosition = { overlayX: 'center', overlayY: 'top' };
    } else if (position === 'before' || position === 'left') {
      overlayPosition = { overlayX: 'end', overlayY: 'center' };
    } else if (position === 'after' || position === 'right') {
      overlayPosition = { overlayX: 'start', overlayY: 'center' };
    } else if (position === 'above-left') {
      overlayPosition = { overlayX: 'start', overlayY: 'bottom' };
    }

    const { x, y } = this.invertPosition(overlayPosition.overlayX, overlayPosition.overlayY);

    return {
      main: overlayPosition,
      fallback: { overlayX: x, overlayY: y },
    };
  }

  /**
   * Adds the configured offset to a position.
   * @param position - The position to which the offset should be added.
   * @returns The position with the added offset.
   */
  private addOffset(position: ConnectedPosition): ConnectedPosition {
    const offset = UNBOUNDED_ANCHOR_GAP;

    if (position.originY === 'top') {
      position.offsetY = -offset;
    } else if (position.originY === 'bottom') {
      position.offsetY = offset;
    } else if (position.originX === 'start') {
      position.offsetX = -offset;
    } else if (position.originX === 'end') {
      position.offsetX = offset;
    }

    return position;
  }

  /**
   * Inverts an overlay position.
   * @param x - The horizontal connection position.
   * @param y - The vertical connection position.
   * @returns An object containing the inverted horizontal and vertical connection positions.
   */
  private invertPosition(x: HorizontalConnectionPos, y: VerticalConnectionPos): {
    x: HorizontalConnectionPos,
    y: VerticalConnectionPos
  } {
    if (this.position === 'above' || this.position === 'below') {
      if (y === 'top') {
        y = 'bottom';
      } else if (y === 'bottom') {
        y = 'top';
      }
    } else {
      if (x === 'end') {
        x = 'start';
      } else if (x === 'start') {
        x = 'end';
      }
    }

    return { x, y };
  }

  /**
   * Detaches the tooltip from the overlay.
   */
  private detach(): void {
    if (this.overlayRef?.hasAttached()) {
      this.overlayRef.detach();
    }
    this.tooltipInstance = null;
  }
}
