import { inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import * as actions from './approval-order.actions';
import { ApprovalOrder } from '../models';
import { ApprovalOrderState } from './approval-order.state';

@Injectable({
  providedIn: 'root'
})
export class ApprovalOrderDispatchersService {

  private store = inject(Store<ApprovalOrderState>);

  loadApprovalOrder(projectId: number): void {
    this.store.dispatch(
      actions.loadApprovalOrderStart({projectId})
    );
  }

  clearApprovalOrder(): void {
    this.store.dispatch(actions.clearApprovalOrderStart());
  }

  saveApprovalOrder(approvalOrders: ApprovalOrder[], projectId: number): void {
    this.store.dispatch(
      actions.saveApprovalOrderStart({ approvalOrders, projectId })
    );
  }
}
