export function formatNumber(value: any): string {
  if (typeof value !== 'number') return value;
  let formattedNumber = value.toString().replace('.', ',');
  let invertedIndex = 0;
  let index = formattedNumber.includes(',') ? formattedNumber.indexOf(',') : formattedNumber.length;
  for (index; index > 0; index--) {
    if (invertedIndex % 3 === 0 && invertedIndex > 0) {
      const firstPart = formattedNumber.substr(0, index);
      const lastPart = formattedNumber.substr(index);
      formattedNumber = firstPart.concat('\xa0', lastPart);
    }
    invertedIndex++;
  }
  return formattedNumber;
}

export const stringFormat = (str: string, ...val: any[]): string => {
  for (let index = 0; index < val.length; index++) {
    str = str.replace(`{${index}}`, `${val[index]}`);
  }

  return str;
};
