import { InfoMessage, InfoMessageInteraction } from '../model';

export const infoMessageFeatureKey = 'info-message';

export interface InfoMessageState {
  messages: InfoMessage[];
  messagesInteractions: InfoMessageInteraction[];
}

export const initialInfoMessageState: InfoMessageState = {
  messages: [],
  messagesInteractions: []
};
