import { Component, EventEmitter, Output } from '@angular/core';

import { PermissionHelper } from '../../../building-progress/helpers';

@Component({
  selector: 'app-budget-approval-differences-toolbar',
  templateUrl: './budget-approval-differences-toolbar.component.html',
  styleUrls: ['./budget-approval-differences-toolbar.component.scss']
})
export class BudgetApprovalDifferencesToolbarComponent {

  @Output() backClick = new EventEmitter<void>();
  @Output() exportClick = new EventEmitter<void>();

  constructor(
    public permissionHelper: PermissionHelper
  ) { }

  onBackClick(): void {
    this.backClick.emit();
  }

  onExport(): void {
    this.exportClick.emit();
  }
}
