import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { BehaviorSubject, catchError, EMPTY, filter, switchMap } from 'rxjs';
import { ImageResult, Options } from 'ngx-image2dataurl';

import { formatString, UnsubscribeComponent } from '@kros-sk/ssw-cdk';
import { KrosModalService, MessageTypes } from '@kros-sk/components';

import { ImageService } from '../services';
import { ToastService, ToastType } from '../../toast';
import { TranslateService } from '../../translate';

@Component({
  selector: 'kros-image-upload-button',
  templateUrl: './image-upload-button.component.html',
  styleUrls: ['./image-upload-button.component.scss']
})
export class ImageUploadButtonComponent extends UnsubscribeComponent implements OnInit {

  @Input() controller: string;

  @ViewChild('uploadButton') uploadButton: ElementRef<any>;

  src: string = null;
  options: Options = {
    resize: {
      maxHeight: 280,
      maxWidth: 210
    },
    allowedExtensions: ['jpg', 'png', 'jpeg', 'bmp']
  };
  inProgress$ = new BehaviorSubject<boolean>(false);
  hasImage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private acceptableFormats: string[] = ['image/png', 'image/bmp', 'image/jpeg', 'image/jpg'];

  constructor(
    private krosModalService: KrosModalService,
    private toastService: ToastService,
    private imageService: ImageService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.imageService.downloadImage(this.controller, this.inProgress$).subscribe(image => {
      if (image) {
        this.setImageToPreview(image);
      } else {
        this.hasImage$.next(false);
      }
    });
  }

  onDelete(event: Event): void {
    event.stopPropagation();

    this.subs.sink = this.krosModalService.openModalMessageBox({
      icon: 'delete',
      caption: this.translateWithController('SETTINGS.DELETE_IMAGE_QUESTION'),
      messageType: MessageTypes.Danger,
      acceptButton: this.translateService.translate('COMMON.DELETE'),
      cancelButton: this.translateService.translate('COMMON.ZRUSIT')
    }).pipe(
      filter(res => res.data === 'accept'),
      switchMap(() => this.imageService.deleteImage(this.controller).pipe(
        catchError(_ => {
          this.toastService.open(this.translateWithController('SETTINGS.DELETE_IMAGE_ERROR'), ToastType.Error);
          return EMPTY;
        })
      ))
    ).subscribe(() => this.hasImage$.next(false));
  }

  async selected(imageResult: ImageResult): Promise<void> {
    if (imageResult?.resized === undefined) {
      this.toastService.open(this.translateWithController('SETTINGS.CORRUPTED_IMAGE_ERROR'), ToastType.Error);
    } else {
      this.src = imageResult.resized.dataURL || imageResult.dataURL;
      const fetchResult = await fetch(this.src);
      const resultBlob = await fetchResult.blob();
      const data = new Blob([resultBlob], { type: imageResult.file.type });
      const arrayOfBlob = new Array<Blob>();
      arrayOfBlob.push(data);
      const file = new File(arrayOfBlob, imageResult.file.name, { type: imageResult.file.type });

      if (this.checkFormat(file)) {
        this.subs.sink = this.imageService.uploadImage(file, this.controller, this.inProgress$).pipe(
          catchError(_ => {
            this.toastService.open(this.translateWithController('SETTINGS.UPLOAD_IMAGE_ERROR'), ToastType.Error);
            return EMPTY;
          })
        ).subscribe(success => {
          if (success) {
            this.setImageToPreview(file);
          } else {
            this.hasImage$.next(false);
          }
        });
      } else {
        this.toastService.open(this.translateWithController('SETTINGS.BAD_FORMAT_IMAGE_ERROR'), ToastType.Error);
      }
    }
  }

  private setImageToPreview(image: Blob): void {
    const myReader: FileReader = new FileReader();
    try {
      myReader.readAsDataURL(image);
      myReader.onload = (e): void => {
        const result = myReader.result as string;
        this.uploadButton.nativeElement.style.backgroundImage = `url(${result})`;
      };
      this.hasImage$.next(true);
    } catch (error) {
      this.hasImage$.next(false);
    }
  }

  private checkFormat(file: File): boolean {
    let foundFormat = this.acceptableFormats.find(format => format === file.type);
    if (!foundFormat) {
      const matches = file.type.match(/(image)\/(.*)(bmp)/g);
      if (matches?.length > 0) {
        foundFormat = matches[0];
      }
    }
    return foundFormat?.length > 0;
  }

  protected translateWithController(resourceKey: string): string {
    return formatString(
      this.translateService.translate(resourceKey),
      this.translateService.translate(`SETTINGS.CONTROLLER_${this.controller.toUpperCase()}`));
  }
}
