import { Observable, Subject } from 'rxjs';

let timeout: NodeJS.Timeout;
const defaultScrollArguments: ScrollIntoViewOptions = { block: 'center', inline: 'end', behavior: 'smooth' };

const isInParentView = (
  element: HTMLElement,
  parent: HTMLElement,
  headerHeight: number,
  leftOffset: number,
  elementIdForColumnWidth?: string): boolean => {

  const isFixed = element.className.indexOf('fixed') > -1;
  const scrollHeight = 16;
  const elementBounding = element.getBoundingClientRect();
  const parentBounding = parent.getBoundingClientRect();
  const columnWidth = document.getElementById(elementIdForColumnWidth)?.clientWidth ?? 0;
  const elementLeft = columnWidth ? elementBounding.right - columnWidth : elementBounding.left;
  const isCorrectLeftOffset = elementLeft >= (parentBounding.left + (!isFixed && leftOffset ? leftOffset : 0)) ||
    parentBounding.right - leftOffset <= columnWidth ||
    parentBounding.width < leftOffset;
  const isCorrectRightOffset =
    (elementBounding.right >= (parentBounding.left + (!isFixed && leftOffset ? leftOffset : 0)) || parentBounding.width < leftOffset) &&
    (elementBounding.right <= parentBounding.right || (isFixed && parentBounding.width < leftOffset));

  return elementBounding.top >= parentBounding.top + headerHeight &&
    isCorrectLeftOffset &&
    isCorrectRightOffset &&
    elementBounding.bottom <= parentBounding.bottom - scrollHeight;
};

export const scrollElementIntoParentView = (
  elementId: string,
  parent: HTMLElement,
  delay = 200,
  headerHeight = 60,
  leftOffset?: number,
  elementIdForColumnWidth?: string,
  scrollArgumets?: ScrollIntoViewOptions,
  subsciber?: Subject<void>): Observable<void> => {

  const ret = subsciber ?? new Subject<void>();
  const scrollAfterDelay = (): void => {
    if (delay < 2000) {
      scrollElementIntoParentView(
        elementId, parent, delay * 2, headerHeight, leftOffset, elementIdForColumnWidth, scrollArgumets, ret);
    }
  };

  if (timeout) {
    clearTimeout(timeout);
  }

  timeout = setTimeout(() => {
    timeout = undefined;
    const element = document.getElementById(elementId);
    if (element) {
      if (!isInParentView(element, parent, headerHeight, leftOffset, elementIdForColumnWidth)) {
        element.scrollIntoView({ ...defaultScrollArguments, ...scrollArgumets });
        scrollAfterDelay();
      }
      ret.next();
      ret.complete();
    } else {
      scrollAfterDelay();
    }
  }, delay);

  return ret;
};
