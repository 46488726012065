import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';

import { FileItem, FileLikeObject, FileUploader } from 'ng2-file-upload';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';
import { v4 as uuidv4 } from 'uuid';

import { FileInfo } from '../models/file-info.model';
import { RestErrorHandleModel } from '../models/rest-error-handle.model';
import { TranslateService } from '../../translate';
import { UploaderSkippedFile } from '../models/skipped-file.model';
import { UploadFileInfoModel } from '../models/upload-file-info.model';
import { UploadProgress } from '../models/upload-progress.model';
import { ZipperService } from './zipper.service';

const maxFileUploadSize = 1500;

@Injectable()
export class UploaderService extends UnsubscribeComponent {
  projectId: number;
  parentId: string;
  uploader: FileUploader;
  uploadCompleted = true;
  skippedFiles: UploaderSkippedFile[];
  filesToUpload: FileInfo[] = [];

  complete = new Subject<string>();
  progressItem = new BehaviorSubject<UploadProgress>({ id: '', progress: 0 });
  filesReady = new Subject<any>();
  filesSelected = new Subject<any>();
  fileUploadReady = new Subject<UploadFileInfoModel[]>();
  uploadDocumentRestErrorHandle = new Subject<RestErrorHandleModel>();

  constructor(
    private zipperService: ZipperService,
    private translateService: TranslateService
  ) {
    super();

    this.subs.sink = this.uploadDocumentRestErrorHandle.subscribe(
      r => this.onHandleUploadDocumentsRestError(r)
    );
  }

  onDestroy(): void {
    this.subs.unsubscribe();
  }

  configure(projectId: number, parentId: string, allowedMimeTypes?: string[]): FileUploader {
    this.projectId = projectId;
    this.parentId = parentId;

    this.skippedFiles = [];
    this.uploader = new FileUploader({
      url: '',
      allowedMimeType: allowedMimeTypes,
      maxFileSize: maxFileUploadSize * 1024 * 1024
    });
    this.uploader.onWhenAddingFileFailed = this.onWhenAddingFileFailed.bind(this);
    this.uploader.onBeforeUploadItem = this.onBeforeUploadItem.bind(this);

    return this.uploader;
  }

  getUploader(): FileUploader {
    return this.uploader;
  }

  get isUploaderReady(): boolean {
    return !this.uploader || (this.uploader && this.uploadCompleted);
  }

  async preprocessFiles(): Promise<void> {
    this.filesToUpload = [];
    for (const file of this.uploader.queue) {
      let fileInfo: FileInfo;
      if (this.zipperService.needZip(file)) {
        fileInfo = {
          id: uuidv4(),
          originalFileName: file._file.name,
          file: await this.zipperService.zipToFile(file),
          size: file.file.size
        };
      } else {
        fileInfo = {
          id: uuidv4(),
          originalFileName: file._file.name,
          file: file._file,
          size: file.file.size
        };
      }

      this.filesToUpload.push(fileInfo);
    }
  }

  uploadFiles(): void {
    const uploadFiles: UploadFileInfoModel[] = [];
    this.filesToUpload.forEach(file => {
      const uploadFileInfo: UploadFileInfoModel = {
        id: file.id,
        size: file.size,
        name: file.file.name,
        originalName: file.originalFileName,
        blobName: '',
        parentId: this.parentId,
        projectId: this.projectId,
        dateCreated: new Date(),
        description: ''
      };

      uploadFiles.push(uploadFileInfo);
    });

    this.fileUploadReady.next(uploadFiles);
  }

  handleUploadDocumentsSuccess(response: any): void {
    response.forEach(validatedFile => {
      if (!validatedFile.isValid) {
        this.skippedFiles.push({
          id: validatedFile.id,
          fileName: validatedFile.fileName,
          reason: this.translateService.translate('DOCUMENTS.UNSUPPORTED_MEDIA_TYPE')
        } as UploaderSkippedFile);
        this.filesToUpload = this.filesToUpload.filter(file => file.id !== validatedFile.id);
      }
    });
    this.complete.next('');
  }

  handleUploadDocumentsError(error: any, uploadFiles: UploadFileInfoModel[]): void {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      uploadFiles.forEach(file => {
        const skippedFile: UploaderSkippedFile = {
          id: file.id,
          fileName: file.originalName,
          reason: this.translateService.translate('DOCUMENTS.UNKNOWN_UPLOAD_ERROR')
        };

        this.skippedFiles.push(skippedFile);
      });

      this.filesToUpload = [];
      this.complete.next('');
    }
  }

  private onHandleUploadDocumentsRestError(viewModel: RestErrorHandleModel): void {
    const skippedFile: UploaderSkippedFile = {
      id: viewModel.badFile.id,
      fileName: viewModel.badFile.originalName,
      reason: this.translateService.translate('DOCUMENTS.UNKNOWN_UPLOAD_ERROR')
    };

    this.skippedFiles.push(skippedFile);
    this.filesToUpload = this.filesToUpload.filter(f =>
      f.id !== viewModel.badFile.id);
  }

  async onFileSelected(): Promise<void> {
    this.filesSelected.next(false);

    if (this.uploader.queue.length > 0) {
      this.uploadCompleted = false;
      await this.preprocessFiles();
      this.filesReady.next(true);
    } else {
      this.filesReady.next(false);
    }
  }

  private onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: any): void {
    if (filter.name === 'fileSize') {
      const reason =
        this.translateService.translate('DOCUMENTS.SIZE_EXCEEDED') + ` (${maxFileUploadSize} MB)`;
      const skippedFile: UploaderSkippedFile = { id: uuidv4(), fileName: item.name, reason };

      this.skippedFiles.push(skippedFile);
    }
  }

  private onBeforeUploadItem(fileItem: FileItem): void {
    this.uploader.setOptions({
      url: '',
      additionalParameter: {
        projectId: this.projectId,
        parentId: this.parentId,
        documentSize: fileItem.file.size
      }
    });
  }
}
