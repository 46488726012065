import { Action, createReducer, on } from '@ngrx/store';

import { IBudgetItem } from '@kros-sk/ssw-shared-legacy';

import * as actions from './budget-approval.actions';
import { ActionInProgress } from '../../budget-approval/enums';
import { adapterBoqItems } from './budget-approval.adapters';
import { adapterSharingList } from '../building-progress';
import {
  budgetApprovalDataAfterApprovalChanged,
  budgetApprovalDataAfterDelete,
  changeSheetDataAfterRelationDelete,
  changeSheetDataAfterRelationEdit
} from './budget-approval.utils';
import { BudgetApprovalState, initialBudgetApprovalState } from './budget-approval.state';

const reducer = createReducer(
  initialBudgetApprovalState,
  on(actions.clearState, (state): BudgetApprovalState => {
    return {
      ...initialBudgetApprovalState
    };
  }),
  on(actions.loadBudgetApprovalDataStart, (state): BudgetApprovalState => {
    return { ...state, budgetApprovalData: undefined, budgetApprovalDataLoading: true };
  }),
  on(actions.loadBudgetApprovalDataSuccess, (state, payload): BudgetApprovalState => {
    return {
      ...state,
      budgetApprovalData: payload.budgetApprovalData,
      budgetApprovalDataLoading: false
    };
  }),
  on(actions.loadDifferencesDataStart, (state): BudgetApprovalState => {
    return {
      ...state,
      differencesData: undefined
    };
  }),
  on(actions.loadDifferencesDataSuccess, (state, payload): BudgetApprovalState => {
    return {
      ...state,
      differencesData: payload.differencesData
    };
  }),
  on(actions.loadBudgetApprovalDataError, (state): BudgetApprovalState => {
    return {
      ...state,
      budgetApprovalData: undefined,
      budgetApprovalDataLoading: false
    };
  }),
  on(actions.deleteBuildingObjectSuccess, (state, payload): BudgetApprovalState => {
    return {
      ...state,
      budgetApprovalData: budgetApprovalDataAfterDelete(state.budgetApprovalData, payload.deleteResult)
    };
  }),
  on(actions.loadChangeSheetDataStart, (state): BudgetApprovalState => {
    return { ...state, changeSheetData: undefined, changeSheetDataLoading: true };
  }),
  on(actions.loadChangeSheetDataSuccess, (state, payload): BudgetApprovalState => {
    return {
      ...state,
      changeSheetData: payload.changeSheetData,
      changeSheetDataLoading: false
    };
  }),
  on(actions.loadBudgetChangeDataStart, (state, payload): BudgetApprovalState => {
    return {
      ...state,
      budgetChangeData: payload.setLoading ? undefined : state.budgetChangeData,
      budgetChangeDataLoading: payload.setLoading
    };
  }),
  on(actions.loadBudgetChangeDataSuccess, (state, payload): BudgetApprovalState => {
    return {
      ...state,
      budgetChangeData: payload.budgetChangeData,
      budgetChangeDataLoading: false
    };
  }),
  on(actions.approveChangeSheetStart, (state, payload): BudgetApprovalState => {
    return {
      ...state,
      actionInProgress: ActionInProgress.approve
    };
  }),
  on(actions.approveChangeSheetSuccess, (state, payload): BudgetApprovalState => {
    return {
      ...state,
      actionInProgress: ActionInProgress.none
    };
  }),
  on(actions.approveChangeSheetError, (state): BudgetApprovalState => {
    return {
      ...state,
      actionInProgress: ActionInProgress.none
    };
  }),
  on(actions.cancelApprovedChangeSheetStart, (state, payload): BudgetApprovalState => {
    return {
      ...state,
      actionInProgress: ActionInProgress.cancelApproval
    };
  }),
  on(actions.cancelApprovedChangeSheetSuccess, (state, payload): BudgetApprovalState => {
    return {
      ...state,
      actionInProgress: ActionInProgress.none,
      budgetApprovalData: budgetApprovalDataAfterApprovalChanged(state.budgetApprovalData, payload.approveResult)
    };
  }),
  on(actions.cancelApprovedChangeSheetError, (state): BudgetApprovalState => {
    return {
      ...state,
      actionInProgress: ActionInProgress.none
    };
  }),
  on(actions.loadBudgetDataStart, (state): BudgetApprovalState => {
    return { ...state, budgetData: undefined, budgetDataLoading: true };
  }),
  on(actions.loadBudgetDataSuccess, (state, payload) => {
    return {
      ...state,
      budgetData: payload.budgetData,
      budgetDataLoading: false
    };
  }),
  on(actions.editChangeSheetItemRelationSuccess, (state, payload): BudgetApprovalState => {
    return {
      ...state,
      changeSheetData: changeSheetDataAfterRelationEdit(state.changeSheetData, payload.budgetItemId, payload.originalId)
    };
  }),
  on(actions.deleteChangeSheetRelationSuccess, (state, payload): BudgetApprovalState => {
    return {
      ...state,
      changeSheetData: changeSheetDataAfterRelationDelete(state.changeSheetData, payload.deleteModel.budgetItemIds)
    };
  }),
  on(actions.selectChangeSheetItem, (state, payload): BudgetApprovalState => {
    const selection = getSelectionWithoutIndeterminates(
      payload, state.selectedChangeSheetItemIds, state.changeSheetData.items);

    return {
      ...state,
      selectedChangeSheetItemIds: new Set<number>(selection)
    };
  }),
  on(actions.selectAllChangeSheetItems, (state) => {
    const selection = new Set<number>(
      state.changeSheetData.items.map(i => i.id)
    );

    return {
      ...state,
      selectedChangeSheetItemIds: selection
    };
  }),
  on(actions.clearSelectedChangeSheetItems, (state): BudgetApprovalState => {
    return {
      ...state,
      selectedChangeSheetItemIds: new Set<number>()
    };
  }),
  on(actions.multiSelectItems, (state, payload): BudgetApprovalState => {
    const selection = getSelectionWithoutIndeterminates(
      payload,
      state.selectedItemsIds,
      state.budgetApprovalData.items);

    return { ...state, selectedItemsIds: new Set<number>(selection) };
  }),
  on(actions.multiSelectAllItems, (state): BudgetApprovalState => {
    const itemIds = state.budgetApprovalData?.items?.length > 0 ? state.budgetApprovalData.items.map(item => item.id) : [];
    const itemIdsSet = new Set<number>(itemIds);

    return { ...state, selectedItemsIds: itemIdsSet };
  }),
  on(actions.clearMultiSelectItems, (state): BudgetApprovalState => {
    return { ...state, selectedItemsIds: new Set<number>() };
  }),
  on(actions.loadSharingListSuccess, (state, payload): BudgetApprovalState => {
    return {
      ...state,
      sharingList: adapterSharingList.setAll(payload.sharingList, state.sharingList),
    };
  }),
  on(actions.loadBoqItemsStart, (state, payload): BudgetApprovalState => {
    return {
      ...state,
      boqItems: adapterBoqItems.removeAll({ ...state.boqItems, isLoading: true })
    };
  }),
  on(actions.loadBoqItemsSuccess, (state, payload): BudgetApprovalState => {
    return {
      ...state,
      boqItems: adapterBoqItems.setAll(payload.items, { ...state.boqItems, isLoading: false }),
    };
  }),
);

export function budgetApprovalReducer(state: BudgetApprovalState | undefined, action: Action): any {
  return reducer(state, action);
}

export function getSelectionWithoutIndeterminates(
  payload: { id: number, state: boolean }, currentSelection: Set<number>, budgetItems: IBudgetItem[]): Set<number> {
  if (!(currentSelection instanceof Set)) {
    currentSelection = new Set<number>();
  }

  const items = getCurrentAndChildItems(budgetItems, payload.id);

  items.forEach(i => {
    if (payload.state) {
      currentSelection.add(i.id);
    } else {
      currentSelection.delete(i.id);
    }
  });

  return currentSelection;
}

export function getCurrentAndChildItems(items: IBudgetItem[], id: number): IBudgetItem[] {
  const item = items.find(x => x.id === id);
  let itemIndex = items.indexOf(item);
  const ret = [item];

  while (items[++itemIndex] && items[itemIndex].level > item.level) {
    ret.push(items[itemIndex]);
  }

  return ret;
}
