import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { BudgetSharedEffects } from './budget-shared-effects';
import { budgetSharedFeatureKey } from './budget-shared-selectors.service';
import { BudgetSharedService } from './budget-shared.service';
import { sharedReducers } from './budget-shared-reducers';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(budgetSharedFeatureKey, sharedReducers),
    EffectsModule.forFeature([
      BudgetSharedEffects,
    ])
  ],
  providers: [
    BudgetSharedService
  ]
})
export class BudgetSharedModule { }
