import { Address } from '../base';
import { PriceLevel } from '../warehouse';

export interface Partner {
    id: number;
    address: Address;
    postalAddress: Address;
    registrationId: string;
    taxId: string;
    vatId: string;
    phoneNumber: string;
    email: string;
    web: string;
    isFree?: boolean;
}

export interface DocumentPartner extends Partner {
  discountPercent?: number;
  paymentDue?: number;
}

export interface PartnerApiModel extends Partner {
  discountPercent?: number;
  paymentDue?: number;
  automaticReminders?: boolean;
  priceLevel: PriceLevel;
}

export interface PartnerUpsertApiModel extends Omit<PartnerApiModel, 'priceLevel'> {
  priceLevelId: string,
}

export interface PartnerFormModel {
  businessName: string;
  street: string;
  postCode: string;
  city: string;
  country: string;
  contactName: string;
  showPostalAddress: boolean;
  registrationId: string;
  taxId: string;
  vatId: string;
  phoneNumber: string;
  email: string;
  web: string;
  postalBusinessName: string;
  postalStreet: string;
  postalPostCode: string;
  postalCity: string;
  postalCountry: string;
  postalContactName: string;
  discountPercent: number;
  paymentDue: number;
  automaticReminders: boolean;
  isFree: boolean;
  priceLevel: PriceLevel;
}

export interface PartnerInsight {
  id: number;
  businessName: string;
  street: string;
  postCode: string;
  city: string;
  country: string;
}

export interface PartnersSummary {
  count: number;
}

export interface PartnerSearchModel {
  Id: string;
  BusinessName: string;
  ContactName: string;
  Street: string;
  PostCode: string;
  City: string;
  Country: string;
  PostalAddressBusinessName: string;
  PostalAddressContactName: string;
  PostalAddressStreet: string;
  PostalAddressPostCode: string;
  PostalAddressCity: string;
  PostalAddressCountry: string;
  RegistrationId: string;
  TaxId: string;
  VatId: string;
  PhoneNumber: string;
  Email: string;
}

export interface PartnerSignalRModel {
  id: string;
  businessName: string;
  contactName: string;
  street: string;
  postCode: string;
  city: string;
  country: string;
  postalAddressBusinessName: string;
  postalAddressContactName: string;
  postalAddressStreet: string;
  postalAddressPostCode: string;
  postalAddressCity: string;
  postalAddressCountry: string;
  registrationId: string;
  taxId: string;
  vatId: string;
  phoneNumber: string;
  email: string;
}

export interface PartnersSearch {
  searchText: string;
  force: boolean;
}

export enum VatIdValidationResult {

  /**
   * Vat id is valid.
   */
  Valid = 0,

  /**
   * Vat id is invalid.
   */
  Invalid = 1,

  /**
   * Vat id is in a wrong format.
   */
  WrongFormat = 2,

  /**
   * Vat id validation service is unavailable.
   */
  Unavailable = 3
}
