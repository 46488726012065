<kros-form-field>
  <kros-label> {{ 'COMMON.ADDRESS.COUNTRY.TITLE' | translate }}</kros-label>

  <input
    krosControl
    focusableInput
    markableAsInvalid
    class="form-control"
    [formControl]="countryForm"
    data-test="company-info-country-select"
    maxlength="255"
    [krosAutocomplete]="autocomplete"/>

    <i krosIconSuffix class="material-icons">keyboard_arrow_down</i>

    <kros-input-error *ngIf="countryForm?.hasError('required')">
      {{ 'COMMON.REQUIRED' | translate }}
    </kros-input-error>
</kros-form-field>

<kros-autocomplete #autocomplete="krosAutocomplete" [displayWith]="displayFn">
  <ng-template krosAutocompleteContent>
    <ng-container *ngIf="(options | filterOptionsStartingWith:countryForm.value:'search') as options">
      <kros-autocomplete-option *ngFor="let option of options" [value]="option.name" class='dropdown-item' data-test="option">
        {{ option.name }}
      </kros-autocomplete-option>
      <kros-autocomplete-option *ngIf="!options.length">
        <span class="country-not-found">{{ 'COMMON.ADDRESS.COUNTRY.NOT_FOUND' | translate }}</span>
      </kros-autocomplete-option>
    </ng-container>
  </ng-template>
</kros-autocomplete>
