import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { BuildingObjectsEffects } from './data-access/store/building-objects.effects';
import { buildingObjectsFeatureState } from './data-access/store/building-objects.reducers';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(buildingObjectsFeatureState),
    EffectsModule.forFeature([
      BuildingObjectsEffects,
    ]),
  ],
})
export class BuildingObjectsModule { }
