import { ArrayDataSource } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';

import {
  BudgetNavigationItem,
  getMultiselectSelectedAndIndeterminateItems,
  getNodeLevel,
  IBudgetItem,
  IBudgetModel,
  isConstructionOrBuildingObject,
  isSummaryItem
} from '@kros-sk/ssw-shared-legacy';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { ApprovalType } from '../../enums';
import { BudgetApprovalModel } from '../../models';

@Component({
  selector: 'app-budget-approval-structure',
  templateUrl: './budget-approval-structure.component.html',
  styleUrls: ['./budget-approval-structure.component.scss']
})
export class BudgetApprovalStructureComponent extends UnsubscribeComponent implements OnInit {

  @Input() selectedItemIds = new Set<number>();
  @Input() indeterminateItemIds = new Set<number>();

  @Input() isDefaultExpanded = false;
  @Input() hasTooltips = true;
  @Input() colorizedObjects = true;
  @Input() showTypes: string[];
  @Input() data: BudgetApprovalModel;

  @Output() nodesCheckboxSelected: EventEmitter<{ selectedItems: Set<number>, indeterminateItems: Set<number>, allSelected: boolean }> =
    new EventEmitter<{ selectedItems: Set<number>, indeterminateItems: Set<number>, allSelected: boolean }>();

  tableData: IBudgetModel;
  dataSource: ArrayDataSource<BudgetNavigationItem>;
  treeControl = new FlatTreeControl<BudgetNavigationItem>(node => node.data.level, node => node.isExpandable);

  private selectedNode: BudgetNavigationItem;
  private treeData: BudgetNavigationItem[] = [];

  constructor(
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.data) {
      this.tableData = { ...this.data, items: this.data.items ? this.getFilteredItems(this.data.items) : [] };
      this.setBuildingStructure();

      if (this.selectedItemIds.size === 0 && this.tableData.items) {
        this.tableData.items.forEach(item => {
          if (this.isApprovedChangeSheet(item) || this.isUnapprovedChangeSheet(item)) {
            this.selectedItemIds.add(item.id);
          }
        });
      }
    }
  }

  onUnselectAllClick(): void {
    this.resetSelectionIds();
    this.emitCheckboxSelection();
  }

  onSelectUnapprovedClick(): void {
    this.resetSelectionIds();
    this.tableData.items.forEach(item => {
      if (this.isUnapprovedChangeSheet(item)) {
        this.selectedItemIds.add(item.id);
      }
    });
    this.emitCheckboxSelection();
  }

  onSelectApprovedClick(): void {
    this.resetSelectionIds();
    this.tableData.items.forEach(item => {
      if (this.isApprovedChangeSheet(item)) {
        this.selectedItemIds.add(item.id);
      }
    });
    this.emitCheckboxSelection();
  }

  private setBuildingStructure(): void {
    const data = this.tableData.items.filter((item: IBudgetItem) => isSummaryItem(item));
    this.treeData = [];

    if (data?.length) {
      const minLevel = data[0].level;
      data.forEach(node => {
        this.treeData.push({
          data: { ...node, level: node.level - minLevel },
          isExpandable: this.hasChild(node.id),
          isExpanded: this.isDefaultExpanded
        });
      });
    }

    this.dataSource = new ArrayDataSource(this.treeData);
    this.expandRoot();
  }

  private expandRoot(): void {
    const root = this.treeData[0];
    this.treeControl.expand(root);
    if (root) {
      root.isExpanded = true;
    }
  }

  private hasChild(nodeId: number): boolean {
    return this.tableData.items.filter(i => i.parentId === nodeId && isSummaryItem(i)).length > 0;
  }

  private resetSelectionIds(): void {
    this.selectedItemIds.clear();
    this.indeterminateItemIds.clear();
  }

  private getFilteredItems(items: IBudgetItem[]): IBudgetItem[] {
    if (this.showTypes) {
      return items.filter(item => this.showTypes.some(type => type === item.itemType));
    }
    return items;
  }

  shouldRender(node: any): boolean {
    let parent: BudgetNavigationItem = node;

    do {
      parent = this.getParent(parent);
      if (parent) {
        if (!parent.isExpanded) {
          return false;
        }
      } else {
        return true;
      }
    }
    while (parent);

    return node.isExpanded;
  }

  private getParent(item: BudgetNavigationItem): BudgetNavigationItem {
    if (item?.data?.parentId > 0) {
      return this.treeData.find(i => i.data.id === item.data.parentId);
    }
    return null;
  }

  isSelected(node: BudgetNavigationItem): boolean {
    return node && this.selectedNode && node.data.id === this.selectedNode.data.id;
  }

  isConstructionOrBuildingObject(item: IBudgetItem): boolean {
    return isConstructionOrBuildingObject(item);
  }

  isChangeSheet(node: BudgetNavigationItem): boolean {
    return this.data.items.find(i => i.id === node.data.id).approvalType !== ApprovalType.None;
  }

  isApprovedChangeSheet(item: IBudgetItem): boolean {
    return this.data.items.find(i => i.id === item.id).approvalType === ApprovalType.Approved;
  }

  isUnapprovedChangeSheet(item: IBudgetItem): boolean {
    return this.data.items.find(i => i.id === item.id).approvalType === ApprovalType.NotApproved;
  }

  getNodeLevel(node: BudgetNavigationItem): number {
    return getNodeLevel(node.data.level);
  }

  isItemCheckChecked(node: BudgetNavigationItem): boolean {
    return this.selectedItemIds.has(node.data.id);
  }

  isItemCheckIndeterminate(node: BudgetNavigationItem): boolean {
    return this.indeterminateItemIds.has(node.data.id);
  }

  onItemCheckChanged(event: any, item: BudgetNavigationItem): void {
    const selection = getMultiselectSelectedAndIndeterminateItems(
      this.tableData.items,
      this.selectedItemIds,
      this.indeterminateItemIds,
      item.data.id,
      event.checked,
      true
    );
    this.resetSelectionIds();
    selection.selectedItems.forEach(id => this.selectedItemIds.add(id));
    selection.indeterminateItems.forEach(id => this.indeterminateItemIds.add(id));
    this.emitCheckboxSelection();
  }

  private emitCheckboxSelection(): void {
    this.nodesCheckboxSelected.emit({
      selectedItems: this.selectedItemIds,
      indeterminateItems: this.indeterminateItemIds,
      allSelected: this.selectedItemIds.size === this.tableData.items.length
    });
  }
}
