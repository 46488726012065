import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ApplicationType } from '@kros-sk/ssw-cdk';

import { ItemSharingMultiple } from '../../models/item-sharing-multiple.model';
import { NewSharingComboMode } from '../../models/sharing-combo-mode.enum';
import { PermissionListService } from '../../services/permission-list.service';
import { SharingPermissionMultipleItem, SharingPermissionValues } from '../../models/sharing-permission.interface';
import { ToastService } from '../../../toast';
import { TranslateService } from '../../../translate';
import { UserSharingInputBaseComponent } from '../user-sharing-input-base/user-sharing-input-base.component';
import { UserSharingService } from '../../services/user-sharing.service';

@Component({
  selector: 'kros-user-sharing-multiple-input',
  templateUrl: './user-sharing-multiple-input.component.html',
  styleUrls: ['./user-sharing-multiple-input.component.scss']
})
export class UserSharingMultipleInputComponent extends UserSharingInputBaseComponent {
  permissionItems: SharingPermissionMultipleItem[] = [];
  applicationPermissions: SharingPermissionValues[] = [];

  @Input() sharingMode = NewSharingComboMode.EditUserProject;

  @Output() shared = new EventEmitter<ItemSharingMultiple[]>();

  constructor(
    translateService: TranslateService,
    userSharingService: UserSharingService,
    toastService: ToastService,
    private permissionListService: PermissionListService
  ) {
    super(translateService, userSharingService, toastService);
    this.permissionItems = this.getPermissionItems();
    this.applicationPermissions = this.getDefaultApplicationPermissions();
    this.checkWarnings();
  }

  getApplicationName(applicationType: ApplicationType): string {
    return ApplicationType[applicationType].toLowerCase();
  }

  getSharingMode(item: SharingPermissionMultipleItem): NewSharingComboMode {
    return this.permissionListService.getApplicationSharingComboMode(item.applicationType);
  }

  getDefaultDropdownValues(item: SharingPermissionMultipleItem): SharingPermissionValues {
    return this.applicationPermissions.find(p => p.applicationType === item.applicationType);
  }

  onPermissionChanged(permission: SharingPermissionValues, item: SharingPermissionMultipleItem): void {
    this.applicationPermissions.forEach(permissionItem => {
      if (permissionItem.applicationType === item.applicationType) {
        permissionItem.permission = permission.permission;
        permissionItem.additionalPermission = permission.additionalPermission;
      }
    });

    this.checkWarnings();
  }

  getLicenseTooltip(item: SharingPermissionMultipleItem): string {
    return this.permissionListService.getLicenseTooltip(item.applicationType);
  }

  hasLicense(item: SharingPermissionMultipleItem): boolean {
    return item.applicationType === ApplicationType.Budget
      ? this.permissionListService.hasLicense(ApplicationType.Budget) ||
        this.permissionListService.hasLicense(ApplicationType.QuantityManager)
      : this.permissionListService.hasLicense(item.applicationType);
  }

  permissionItemTrackByFn(_: number, item: SharingPermissionMultipleItem): ApplicationType {
    return item.applicationType;
  }

  private getPermissionItems(): SharingPermissionMultipleItem[] {
    return this.permissionListService.getPermissionMultipleList(this.sharingMode);
  }

  private getDefaultApplicationPermissions(): SharingPermissionValues[] {
    return this.permissionListService.getDefaultApplicationPermissions(this.permissionItems);
  }

  private checkWarnings(): void {
    this.permissionItems.filter(p => p.expectedOtherPermissions).forEach(permissionItem => {
      permissionItem.canShowWarning = true;
      permissionItem.expectedOtherPermissions.forEach(expectedPermission => {
        const currentPermission = this.applicationPermissions.find(p => p.applicationType === expectedPermission.applicationType);
        if (!expectedPermission.permissions.includes(currentPermission?.permission)) {
          permissionItem.canShowWarning = false;
        }
      });
    });
  }

  protected resetInput(): void {
    super.resetInput();
    this.applicationPermissions = this.getDefaultApplicationPermissions();
  }

  protected submitSharing(): void {
    this.shared.emit(
      this.permissionListService.getNewMultipleSharings(this.users, this.message, this.applicationPermissions)
    );
    this.resetInput();

    if (this.emailsReadySubscription) {
      this.emailsReadySubscription.unsubscribe();
    }
  }
}
