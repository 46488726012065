import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import * as boqReducerHelper from './building-progress-boq-reducer.helper';
import * as buildingProgressActions from './building-progress-boq.actions';
import * as reducerHelper from './building-progress-reducer.helper';
import { adapterBoqItems, adapterBoqPeriodItems } from './building-progress.adapters';
import { BoqPeriodItem } from '../../building-progress/models/boq-period-item.model';
import { BuildingProgressState } from './building-progress.state';
import { createEmptyBoqAndReorderItems } from '../../building-progress/helpers/boq.helper';

export const boqReducers: ReducerTypes<BuildingProgressState, ActionCreator[]>[] = [
  on(buildingProgressActions.loadBoqPeriodItemsStart, (state, payload): BuildingProgressState => {
    return {
      ...state,
      deletedBoqItemIds: [],
      boqPeriodItems: adapterBoqPeriodItems.removeAll({ ...state.boqPeriodItems, isLoading: true }),
      editedBoqItems: []
    };
  }),
  on(buildingProgressActions.loadBoqPeriodItemsSuccess, (state, payload): BuildingProgressState => {
    return {
      ...state,
      resetOriginalItems: true,
      boqPeriodItems: adapterBoqPeriodItems.setAll(payload.items, { ...state.boqPeriodItems, isLoading: false }),
    };
  }),
  on(buildingProgressActions.loadBoqItemsStart, (state): BuildingProgressState => {
    return {
      ...state,
      boqItems: adapterBoqItems.removeAll({ ...state.boqItems, isLoading: true })
    };
  }),
  on(buildingProgressActions.loadBoqItemsSuccess, (state, payload): BuildingProgressState => {
    return {
      ...state,
      boqItems: adapterBoqItems.setAll(payload.items, { ...state.boqItems, isLoading: false }),
    };
  }),
  on(buildingProgressActions.setBoqStart, (state, payload): BuildingProgressState => {
    return {
      ...state,
      tableIsBusy: true,
      editingBoqBudgetItemIds: reducerHelper.addEditingBudgetItemId(state.editingBoqBudgetItemIds, payload.editModel.budgetItemId)
    };
  }),
  on(buildingProgressActions.setBoqSuccess, (state, payload): BuildingProgressState => {
    const entities = Object.values(state.boqPeriodItems.entities);
    const deletedIds = entities.filter(i => !i.expression.trim()).map(i => i.id);

    return {
      ...state,
      resetOriginalItems: true,
      constructionData: reducerHelper.loadNewConstructionData(
        state.constructionData,
        payload.items,
        payload.boqPeriodItems.length ? payload.editModel.budgetItemId : 0),
      cellToFocusAndAnimate: payload.isUndoRedoOperation ? [reducerHelper.getAffectedCellAfterAmountChanged(payload.editModel)] : [],
      tableIsBusy: false,
      editingBoqBudgetItemIds: reducerHelper.deleteEditingBudgetItemId(state.editingBoqBudgetItemIds, payload.editModel.budgetItemId),
      boqPeriodItems: payload.boqPeriodItems.length
        ? adapterBoqPeriodItems.setAll(payload.boqPeriodItems, state.boqPeriodItems)
        : adapterBoqPeriodItems.removeMany(payload.editModel.deletedIds.concat(deletedIds), state.boqPeriodItems),
      editedBoqItems: [],
      deletedBoqItemIds: []
    };
  }),
  on(buildingProgressActions.setBoqIds, (state, payload): BuildingProgressState => {
    const entities = Object.values(state.boqPeriodItems.entities);
    const firstItem = entities.length ? entities[0] : undefined;

    if (firstItem && firstItem.budgetItemId === payload.boqItems[0].budgetItemId) {
      return {
        ...state,
        boqPeriodItems: adapterBoqPeriodItems.setAll([...entities.filter(p => p.id > 0), ...payload.boqItems], state.boqPeriodItems)
      };
    } else {
      return { ...state };
    }
  }),
  on(buildingProgressActions.resetBoqPeriodId, (state, payload): BuildingProgressState => {
    const entities = Object.values(state.boqPeriodItems.entities);
    const updates = entities.filter(i => i.periodId === payload.periodId).map(i => {
      return {
        id: i.id,
        changes: {
          periodId: undefined
        }
      } as Update<BoqPeriodItem>;
    });

    if (updates.length) {
      return {
        ...state,
        boqPeriodItems: adapterBoqPeriodItems.updateMany(updates, state.boqPeriodItems)
      };
    } else {
      return { ...state };
    }
  }),
  on(buildingProgressActions.editBoqItems, (state, payload): BuildingProgressState => {
    const updates = payload.boqItems.map(i => {
      return {
        id: i.id,
        changes: {
          expression: i.expression,
          quantity: i.quantity,
          boqItemType: i.boqItemType
        }
      } as Update<BoqPeriodItem>;
    });

    return {
      ...state,
      boqPeriodItems: adapterBoqPeriodItems.updateMany(updates, state.boqPeriodItems),
      editedBoqItems: boqReducerHelper.editBoqItems(state.editedBoqItems, payload.boqItems)
    };
  }),
  on(buildingProgressActions.markBoqItem, (state, payload): BuildingProgressState => {
    if (payload.boqItem) {
      const update = {
        id: payload.boqItem.id,
        changes: {
          periodId: payload.periodId
        }
      } as Update<BoqPeriodItem>;
      return {
        ...state,
        boqPeriodItems: adapterBoqPeriodItems.updateOne(update, state.boqPeriodItems),
        editedBoqItems: boqReducerHelper.markBoqItem(state.editedBoqItems, payload.boqItem, payload.periodId)
      };
    }
  }),
  on(buildingProgressActions.deleteBoqItem, (state, payload): BuildingProgressState => {
    const entities = Object.values(state.boqPeriodItems.entities)
      .filter(e => payload.editedBoqItems.findIndex(i => i.id === e.id) === -1);
    return {
      ...state,
      deletedBoqItemIds: [...state.deletedBoqItemIds, payload.boqItemId],
      boqPeriodItems: adapterBoqPeriodItems.setAll(
        [...entities, ...payload.editedBoqItems.filter(i => i.id !== payload.boqItemId)],
        state.boqPeriodItems),
      editedBoqItems: boqReducerHelper.editItemOrderBoqItems(state.editedBoqItems, payload.editedBoqItems)
    };
  }),
  on(buildingProgressActions.createBoqItem, (state, payload): BuildingProgressState => {
    const newItems = createEmptyBoqAndReorderItems(
      Object.values(state.boqPeriodItems.entities),
      state.deletedBoqItemIds ?? [],
      payload.budgetItemId,
      payload.position,
      payload.isInsertDown);
    return {
      ...state,
      boqPeriodItems: adapterBoqPeriodItems.setAll(newItems, state.boqPeriodItems),
      editedBoqItems: boqReducerHelper.createBoqItems(state.editedBoqItems, newItems, payload.position)
    };
  }),
  on(buildingProgressActions.originalItemsAreReseted, (state): BuildingProgressState => {
    return {
      ...state,
      resetOriginalItems: false,
      deletedBoqItemIds: []
    };
  }),
  on(buildingProgressActions.cancelBoqEditation, (state): BuildingProgressState => {
    return {
      ...state,
      deletedBoqItemIds: [],
      editedBoqItems: []
    };
  })
];
