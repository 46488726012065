import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';

import { Subscription } from 'rxjs';

import { UserService } from '@kros-sk/ssw-cdk';

import { BudgetApprovalSelectorsService } from '../../store/budget-approval';

@Directive({
  selector: '[ifBudgetApprovalFunctionsPermission]'
})
export class IfBudgetApprovalFunctionsPermissionDirective implements OnDestroy {
  @Input()
  set ifBudgetApprovalFunctionsPermission(value: boolean) {
    this.andCondition = value ?? true;
    this.setVisibility();
  }

  private areFunctionsAvailable: boolean;
  private andCondition = true;
  private hasView = false;
  private subscribtion: Subscription;

  constructor(
    private selectorService: BudgetApprovalSelectorsService,
    private userService: UserService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
    this.subscribtion = this.selectorService.areFunctionsAvailableFor$(this.userService.getUserEmail())
      .subscribe(_ => {
        this.areFunctionsAvailable = _;
        this.setVisibility();
      });
  }

  ngOnDestroy(): void {
    this.subscribtion.unsubscribe();
  }

  private setVisibility(): void {
    const allow = this.andCondition && this.areFunctionsAvailable;
    if (allow && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!allow) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
