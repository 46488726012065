<div
  ngbDropdown
  #dropdown="ngbDropdown"
  autoClose="outside"
>
  <button
    ngbDropdownToggle
    class="btn btn-sm btn-link ga-filter-period"
    type="button"
    data-test="building-progress-period-toggle"
  >
    {{ 'BUILDING_PROGRESS.PERIODS.PERIODS' | translate }}:
    <span
      class="ga-filter-period"
      [class.font-weight-bold]="filterCount > 0"
    >{{filterDescription}}</span>
    <i class="material-icons ga-filter-period">expand_more</i>
  </button>

  <div ngbDropdownMenu>
    <div
      ngbDropdownItem
      class="form-group checkbox"
      *ngFor="let period of periods"
    >
      <input
        id="check-{{period.id}}"
        type="checkbox"
        [checked]="isChecked(period.id)"
        (change)="addOrRemovePeriodToFilter(period.id)"
        data-test="building-progress-filter-period-check"
      >
      <label for="check-{{period.id}}">{{ period.dateFrom | periodDate: period.dateTo }}</label>
    </div>

    <div class="dropdown-divider"></div>

    <div class="btn-clear-filter">
      <button
        class="btn btn-link"
        type="button"
        (click)="clearFilter()"
        data-test="building-progress-period-clear-button"
      >
        <span>{{ 'COMMON.CLEAR_FILTER' | translate }}</span>
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
</div>
