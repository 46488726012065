import { Injectable } from '@angular/core';

import { take } from 'rxjs';

import { formatString } from '@kros-sk/ssw-cdk';
import { InfoModalComponent, QuestionModalComponent, TranslateService } from '@kros-sk/ssw-shared-legacy';
import { KrosModalService } from '@kros-sk/components';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

import { BuildingProgressItem } from '../../../../building-progress/models/construction-data.model';
import { BuildingProgressPeriod } from '../../../../building-progress/models/building-progress-period.model';
import { isPeriodEditable } from '../../../../building-progress/helpers';
import { SubcontractorsDispatchersService } from '../../../../store/subcontractors';

@Injectable({
  providedIn: 'root'
})
export class RemoveItemsService {

  constructor(
    private appInsightsService: AppInsightsBaseService,
    private translateService: TranslateService,
    private krosModalService: KrosModalService,
    private subcontractorsDispatchersService: SubcontractorsDispatchersService
  ) { }

  openInfoDialog(): void {
    this.krosModalService.openCentered(
      InfoModalComponent,
      {
        body: this.translateService.translate('SUBCONTRACTORS.REMOVE_CANNOT')
      },
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: false,
        showMobileArrowBack: false
      }
    );
  }

  openRemoveItemsQuestionDialog(
    ids: number[],
    itemType: string,
    itemDescription: string,
    areItemsSelected: boolean,
    periods: BuildingProgressPeriod[],
    items: BuildingProgressItem[],
    projectId: number,
    contractorId: number
  ): void {
    this.krosModalService.openCentered(
      QuestionModalComponent,
      {
        body: this.getDeleteMessage(itemType, itemDescription, areItemsSelected, this.containsRoot(ids, items)),
        confirmButton: this.translateService.translate('SUBCONTRACTORS.REMOVE_ITEMS'),
        cancelButton: this.translateService.translate('COMMON.STORNO')
      },
      {
        closeOnBackdropClick: false,
        fullscreenOnMobile: false,
        showMobileArrowBack: false,
        closeOnEscClick: true,
        addModalToBrowsersHistory: false
      },
      NaN, NaN, undefined, 'no-min-width'
    ).afterClosed$.pipe(take(1)).subscribe(result => {
      if (result.type === 'submit') {
        if (this.canDeleteItems(ids, periods, items)) {
          this.subcontractorsDispatchersService.removeSubcontractorItems(projectId, contractorId, ids);
          this.appInsightsService.trackEvent('PV-remove-items-from-subcontractor');
        } else {
          this.openInfoDialog();
        }
      }
    });
  }

  private getDeleteMessage(itemType: string, itemDescription: string, areItemsSelected: boolean, includeRoot: boolean): string {
    if (includeRoot) {
      return this.translateService.translate('SUBCONTRACTORS.REMOVE_ALL_ITEMS_QUESTION');
    }
    if (areItemsSelected) {
      return this.translateService.translate('SUBCONTRACTORS.REMOVE_SELECTED_ITEMS_QUESTION');
    } else {
      switch (itemType) {
        case 'O': return formatString(this.translateService.translate('SUBCONTRACTORS.REMOVE_CONTRACT_QUESTION'), itemDescription);
        case 'D': return formatString(this.translateService.translate('SUBCONTRACTORS.REMOVE_PART_QUESTION'), itemDescription);
        case 'K':
        case 'M': return formatString(this.translateService.translate('SUBCONTRACTORS.REMOVE_ITEM_QUESTION'), itemDescription);
      }
    }
  }

  private containsRoot(ids: number[], items: BuildingProgressItem[]): boolean {
    const rootId = items.find(i => i.itemType === 'S').id;
    return ids.some(i => i === rootId);
  }

  private canDeleteItems(deleteItemIds: number[], periods: BuildingProgressPeriod[], items: BuildingProgressItem[]): boolean {
    return !deleteItemIds.some(i => {
      const item = items.find(item => item.id === i);
      return periods.some(p =>
        item.periods.find(i => i.totalPrice !== 0 &&
          i.periodId === p.id &&
          isPeriodEditable(p))) ||
        item.periods.some(p => p.totalPrice !== 0);
    });
  }
}
