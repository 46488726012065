<span
  (click)="openRoot()"
  [ngClass]="{'selected-folder': !currentFolderName}"
  [krosTooltip]="'DOCUMENTS.DOKUMENTY' | translate"
  data-test="breadcrumb-root"
>
  {{ 'DOCUMENTS.DOKUMENTY' | translate }}
</span>

<i
  *ngIf="currentFolderName"
  class="material-icons"
>
  chevron_right
</i>

<ng-container *ngFor="let item of parentFolders; let i = index">
  <span
    (click)="openFolder(item)"
    [krosTooltip]="item.name"
    [attr.data-test]="'breadcrumb-level-' + i"
  >
    {{item.name}}
  </span>
  <i class="material-icons">chevron_right</i>
</ng-container>

<span
  *ngIf="currentFolderName"
  class="selected-folder"
  [krosTooltip]="currentFolderName"
  data-test="breadcrumb-current"
>
  {{currentFolderName}}
</span>
