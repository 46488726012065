<div class="content">
  <input
    #imageInput
    type="file"
    accept=".jpg, .png, .jpeg, .bmp"
    onclick="this.value=null"
    [imageToDataUrl]="options"
    (imageSelected)="selected($event)"
    [attr.data-test]="'general-settings-upload-'+ controller"
  >

  <div *ngIf="hasImage$ | async; then image else noImage"></div>

  <ng-template #noImage>
    <button
      #uploadButton
      type="button"
      class="btn img upload-img"
      [ngStyle]="{'background-image': 'url(shared-assets/images/icon-upload.svg)'}"
      (click)="imageInput.click()"
    >
      <span
        *ngIf="inProgress$ | async"
        class="spinner-border"
      ></span>
    </button>

    <div
      class="img-description upload-img-description caption"
      (click)="imageInput.click()"
    >{{ translateWithController('SETTINGS.INSERT_IMAGE') }}</div>
  </ng-template>

  <ng-template #image>
    <button
      #uploadButton
      type="button"
      class="btn img change-img"
    >
      <span
        *ngIf="inProgress$ | async"
        class="spinner-border"
      ></span>
    </button>

    <div
      class="overlay"
      (click)="imageInput.click()"
    >
      <button
        class="btn-link delete-btn"
        type="button"
        (click)="onDelete($event)"
        [krosTooltip]="'COMMON.DELETE' | translate"
        [attr.data-test]="'general-settings-delete-'+ controller"
      >
        <i class="material-icons">delete</i>
      </button>

      <div class="img-description change-img-description caption">
        {{ translateWithController('SETTINGS.INSERT_IMAGE') }}
      </div>
    </div>
  </ng-template>
</div>
