import { DatePipe } from '@angular/common';
import { Injectable, Injector } from '@angular/core';

import { take } from 'rxjs/operators';

import {
  ApplicationOpenerService,
  ColDef,
  ColDefTextAlign,
  ColDefType,
  ColumnIconComponent,
  formatNumberValue,
  getColumnWidth,
  isItem,
  isSummaryItem,
  TranslateService
} from '@kros-sk/ssw-shared-legacy';

import { BuildingProgressChangedProperty } from '../models/building-progress-edit.model';
import { BuildingProgressDispatchersService } from '../../store/building-progress/building-progress-dispatchers.service';
import { BuildingProgressEditingService } from './building-progress-editing.service';
import {
  BuildingProgressItem, BuildingProgressModel, BuildingProgressPeriodColumn, BuildingProgressPeriodItem
} from '../models/construction-data.model';
import {
  BuildingProgressPanelHelper,
  EditingPanelHelper,
  getAutoCalculatedClass,
  getPeriodCalculationTypeClass,
  getPeriodCellClass,
  getPeriodItemByPeriodId,
  getPeriodRightStatusIcons,
  getPeriodStatusIcon,
  isAutoCalculatedItem,
  isPeriodEditable
} from '../helpers';
import { BuildingProgressPeriod } from '../models/building-progress-period.model';
import { BuildingProgressPeriodDispatchersService, BuildingProgressSelectorsService } from '../../store/building-progress';
import { ColumnsConfigModel } from '../models/columns-config.model';
import { environment } from '../../../environments/environment';
import { InvoiceHelper } from '../helpers/invoice.helper';
import { PeriodDatePipe } from '../modules/periods/pipes/period-date.pipe';
import { PermissionHelper } from '../../building-progress/helpers';
import { TableConfigBudgetColumnsService } from './table-config-budget-columns.service';

const maxPercentage = 2147483600;
type SummaryColumn = 'completed' | 'rest' | 'additional' | 'notCompleted';

@Injectable()
export class TableConfigColumnsService {
  selectedPeriodId: number;

  get appLocation(): string {
    return environment.location;
  }

  constructor(
    private buildingProgressDispatchersService: BuildingProgressDispatchersService,
    private periodDispatchersService: BuildingProgressPeriodDispatchersService,
    private buildingProgressSelectorsService: BuildingProgressSelectorsService,
    private buildingProgressEditingService: BuildingProgressEditingService,
    private periodDatePipe: PeriodDatePipe,
    private budgetColumnsService: TableConfigBudgetColumnsService,
    private editingPanelHelper: EditingPanelHelper,
    private datePipe: DatePipe,
    private translateService: TranslateService,
    private appOpenerService: ApplicationOpenerService,
    private injector: Injector,
    private invoiceHelper: InvoiceHelper,
    private permissionHelper: PermissionHelper,
  ) { }

  getTableColumnsDefinition(
    projectId: number,
    constructionData: BuildingProgressModel,
    columnsMaxNumber: ColumnsConfigModel,
    tableConfigHelper: TableConfigColumnsService,
    hasSubcontractorColumn: boolean,
    hasPeriods: boolean,
    isFullLicense: boolean
  ): Array<ColDef> {
    const hidePrices = this.permissionHelper.isContractor && !this.permissionHelper.isContractorFull;
    return [
      ...this.budgetColumnsService.getBudgetColumns(constructionData, columnsMaxNumber, hasSubcontractorColumn, true, hidePrices),
      ...hasPeriods ? this.getPeriodsColumns(projectId, constructionData, columnsMaxNumber,
        tableConfigHelper, isFullLicense, hidePrices) : [],
      ...this.getSummaryColumns(constructionData, columnsMaxNumber, hasPeriods, hidePrices)
    ];
  }

  private getPeriodsColumns(
    projectId: number,
    constructionData: BuildingProgressModel,
    columnsMaxNumber: any,
    tableConfigColumnsHelper: TableConfigColumnsService,
    isFullOrTrialLicense: boolean,
    hidePrices?: boolean
  ): Array<ColDef> {
    const periodColumns: ColDef[] = [
      ...constructionData.periods.map((period, periodIndex) => (
        {
          id: 'period-' + period.id,
          title: tableConfigColumnsHelper.periodDatePipe.transform(period.dateFrom, period.dateTo),
          type: ColDefType.ColumnGroup,
          leftStatusIcon: isPeriodEditable(period) ?
            {
              iconName: (): string => getPeriodStatusIcon(period),
              tooltip: (): string => this.getPeriodStatusTooltip(period),
              clickAction: undefined
            } :
            undefined,
          rightStatusIcons: getPeriodRightStatusIcons(period, projectId, this.translateService, this.appOpenerService,
            this.invoiceHelper, this.permissionHelper.isOwner, isFullOrTrialLicense),
          params: { periodId: period.id, periodIndex },
          cellClassGetter: (item, rowindex): string =>
            getPeriodCellClass(periodIndex, period.id, this.selectedPeriodId, rowindex),
          colsInGroup: [
            {
              id: 'percentage-' + period.id, title: '%', type: ColDefType.Column, width: 60,
              format: (value: any, editMode: boolean): string => formatNumberValue(
                value, constructionData.decimalPlaces.percentage, this.appLocation, editMode),
              textAlign: ColDefTextAlign.Right, comments: true,
              editConfig: {
                onChange: false, onFocusOut: true, onEnter: true, onArrowMovement: true,
                columnEditableGetter: (): boolean => !isPeriodEditable(period)
              },
              params: { periodId: period.id, periodColumn: BuildingProgressPeriodColumn.Percentage },
              valueGetter: (data: BuildingProgressItem): any => {
                const periodItem = getPeriodItemByPeriodId(data.periods, period.id);
                return periodItem && !isSummaryItem(data) ? periodItem.percentage : null;
              },
              valueSetter: (data: BuildingProgressItem, value: string): void => {
                if (value) {
                  value = value === '' ? '0' : value;
                  this.buildingProgressEditingService.editPeriodPercentage(value, projectId, period.id, data);
                }
              },
              cellClassGetter: (item, rowindex): string =>
                getPeriodCellClass(periodIndex, period.id, this.selectedPeriodId, rowindex, 'percentage')
            },
            {
              id: 'amount-' + period.id, title: 'BUILDING_PROGRESS.TABLE.EXECUTION', type: ColDefType.Column,
              format: (value: any, editMode: boolean): string => formatNumberValue(
                value, constructionData.decimalPlaces.amount, this.appLocation, editMode),
              textAlign: ColDefTextAlign.Right, comments: true, minWidth: 80,
              width: getColumnWidth(
                columnsMaxNumber.amount, constructionData.decimalPlaces.amount, this.appLocation, false),
              editConfig: {
                onChange: false, onFocusOut: true, onEnter: true, onArrowMovement: true,
                columnEditableGetter: (): boolean => !isPeriodEditable(period)
              },
              params: { periodId: period.id, periodColumn: BuildingProgressPeriodColumn.Amount },
              cellActionButton: {
                id: (): string => 'ga-boq-period-open',
                iconName: (): string => 'square_foot',
                tooltip: (): string => 'BILL_OF_QUANTITIES.BILL_OF_QUANTITIES',
                showCondition: (item: BuildingProgressItem): boolean => isItem(item),
                action: (item: BuildingProgressItem): void => {
                  const panelHelper = this.injector.get(BuildingProgressPanelHelper);
                  panelHelper.openBoq(projectId, item);
                }
              },
              floatingActionButton: {
                iconName: (): string => 'reply',
                tooltip: (): string => 'BUILDING_PROGRESS.AUTOCALCULATE_AMOUNT',
                showCondition: (item: BuildingProgressItem): boolean => isAutoCalculatedItem(item) &&
                  !isAutoCalculatedItem(item.periods.find(x => x.periodId === period.id)) && !item.isNotCompleted,
                action: (item: BuildingProgressItem): void =>
                  this.buildingProgressDispatchersService.setAutocalculatedAmount(
                    projectId, period.id, item.id, item.periods.find(p => p.periodId === period.id))
              },
              valueGetter: (data: BuildingProgressItem): any => {
                const periodItem = getPeriodItemByPeriodId(data.periods, period.id);
                return periodItem && !isSummaryItem(data) ? periodItem.amount : null;
              },
              valueSetter: (data: BuildingProgressItem, value: string): void => {
                if (value) {
                  value = value === '' ? '0' : value;
                  this.buildingProgressEditingService.editItemPeriod(
                    value, projectId, period.id, data, BuildingProgressChangedProperty.Amount);
                }
              },
              cellClassGetter: (data: BuildingProgressItem, rowindex): string => {
                const periodItem = data ? getPeriodItemByPeriodId(data.periods, period.id) : undefined;
                return getPeriodCellClass(periodIndex, period.id, this.selectedPeriodId, rowindex, 'amount') + ' ' +
                  getAutoCalculatedClass(periodItem) + ' ' +
                  getPeriodCalculationTypeClass(periodItem);
              }
            },
            ...!hidePrices ?
              [{
                id: 'totalPrice-' + period.id, title: 'BUILDING_PROGRESS.TABLE.PRICE', type: ColDefType.Column,
                format: (value: any, editMode: boolean): string => formatNumberValue(
                  value, constructionData.decimalPlaces.totalPrice, this.appLocation, editMode),
                textAlign: ColDefTextAlign.Right, comments: true, minWidth: 100,
                width: getColumnWidth(
                  columnsMaxNumber.totalPrice, constructionData.decimalPlaces.totalPrice, this.appLocation, true),
                editConfig: {
                  onChange: false, onFocusOut: true, onEnter: true, onArrowMovement: true,
                  columnEditableGetter: (): boolean => !isPeriodEditable(period)
                },
                params: { periodId: period.id, periodColumn: BuildingProgressPeriodColumn.TotalPrice },
                valueGetter: (data: BuildingProgressItem): any => {
                  const periodItem = getPeriodItemByPeriodId(data.periods, period.id);
                  return periodItem ? periodItem.totalPrice : null;
                },
                valueSetter: (data: BuildingProgressItem, value: string): void => {
                  if (value) {
                    value = value === '' ? '0' : value;
                    this.buildingProgressEditingService.editItemPeriod(
                      value, projectId, period.id, data, BuildingProgressChangedProperty.TotalPrice);
                  }
                },
                cellClassGetter: (item, rowindex): string =>
                  getPeriodCellClass(periodIndex, period.id, this.selectedPeriodId, rowindex, 'totalPrice')
              }] : []
          ]
        }
      ))
    ];

    if (periodColumns.length > 1) {
      periodColumns.splice(
        periodColumns.length - 1,
        0,
        this.getPartlyCompletedColumn(constructionData, columnsMaxNumber, hidePrices)
      );
    }

    return periodColumns;
  }

  getDetailsColumn(periodId: number, periodIndex: number): ColDef {
    return {
      id: 'details-' + periodId, title: '', type: ColDefType.Column, width: 36,
      params: { periodId, periodColumn: BuildingProgressPeriodColumn.Details },
      cellClassGetter: (item: BuildingProgressItem, rowindex): string =>
        getPeriodCellClass(periodIndex, periodId, periodId, rowindex, 'details') +
        (item && this.getItemPeriod(item.periods, periodId).detailId > 0 ? ' font-green' : ''),
      componentConfig: {
        component: ColumnIconComponent,
        params: {
          iconName: 'info_outlined',
          stopPropagation: false,
          tooltip: 'DETAILS.DETAILS',
          action: (data: BuildingProgressItem): void => {
            this.buildingProgressSelectorsService.periodDetails$.pipe(take(1)).subscribe(details => {
              if (
                details?.itemId !== data.id ||
                details?.period.periodId !== this.getItemPeriod(data.periods, periodId).periodId
              ) {
                this.openDetailsIfCan(data.id, this.getItemPeriod(data.periods, periodId));
              }
            });
          }
        }
      }
    };
  }

  private getSummaryColumns(
    constructionData: BuildingProgressModel,
    columnsMaxNumber: any,
    hasPeriods: boolean,
    hidePrices?: boolean
  ): Array<ColDef> {
    return [
      ...hasPeriods ?
        [
          this.getSummaryColumn(
            constructionData,
            columnsMaxNumber,
            'completed',
            'BUILDING_PROGRESS.TABLE.UNTIL_NOW_BUILT',
            hidePrices
          )
        ]
        : [],
      this.getSummaryColumn(constructionData, columnsMaxNumber, 'rest', 'BUILDING_PROGRESS.TABLE.REST', hidePrices),
      ...hasPeriods ?
        [this.getSummaryColumn(constructionData, columnsMaxNumber, 'additional', 'BUILDING_PROGRESS.TABLE.OVERDRAWN', hidePrices)]
        : [],
      this.getSummaryColumn(constructionData, columnsMaxNumber, 'notCompleted', 'BUILDING_PROGRESS.TABLE.NOT_COMPLETED', hidePrices)
    ];
  }

  private getSummaryColumn(
    constructionData: BuildingProgressModel,
    columnsMaxNumber: ColumnsConfigModel,
    columnName: SummaryColumn,
    columnTitle: string,
    hidePrices?: boolean
  ): ColDef {
    return ({
      id: columnName,
      title: columnTitle,
      type: ColDefType.ColumnGroup,
      colsInGroup: [
        {
          id: columnName + 'Percentage', title: '%', type: ColDefType.Column, width: 50,
          format: (value: any): string =>
            formatNumberValue(value, constructionData.decimalPlaces.percentage, this.appLocation),
          textAlign: ColDefTextAlign.Right,
          valueGetter: (data: BuildingProgressItem): any =>
            columnName === 'completed' && data.completedPercentage > maxPercentage ? 0 : data[columnName + 'Percentage']
        },
        {
          id: columnName + 'Amount', title: 'BUILDING_PROGRESS.TABLE.AMOUNT', type: ColDefType.Column,
          format: (value: any): string =>
            formatNumberValue(value, constructionData.decimalPlaces.amount, this.appLocation),
          textAlign: ColDefTextAlign.Right, minWidth: 80,
          width: getColumnWidth(
            columnsMaxNumber.amount, constructionData.decimalPlaces.amount, this.appLocation, false),
          valueGetter: (data: BuildingProgressItem): any =>
            isSummaryItem(data) ? null : data[columnName + 'Amount']
        },
        ...!hidePrices ?
          [{
            id: columnName + 'TotalPrice', title: 'BUILDING_PROGRESS.TABLE.PRICE', type: ColDefType.Column,
            format: (value: any): string =>
              formatNumberValue(value, constructionData.decimalPlaces.totalPrice, this.appLocation),
            textAlign: ColDefTextAlign.Right, minWidth: 100,
            width: getColumnWidth(
              columnsMaxNumber.totalPrice, constructionData.decimalPlaces.totalPrice, this.appLocation, true),
            valueGetter: (data: BuildingProgressItem): any => data[columnName + 'TotalPrice']
          }] : []
      ]
    });
  }

  private getPartlyCompletedColumn(constructionData: BuildingProgressModel, columnsMaxNumber: any, hidePrices?: boolean): ColDef {
    const dateBeforeLastPeriod = new Date(constructionData.periods[constructionData.periods.length - 1].dateFrom);
    dateBeforeLastPeriod.setDate(dateBeforeLastPeriod.getDate() - 1);
    const dateUntil = this.datePipe.transform(dateBeforeLastPeriod, 'dd.MM.yyyy');
    return {
      id: 'partlyCompleted',
      title: 'BUILDING_PROGRESS.TABLE.COMPLETED_UNTIL',
      titleShort: 'BUILDING_PROGRESS.TABLE.UNTIL',
      titleSuffix: dateUntil,
      type: ColDefType.ColumnGroup,
      colsInGroup: [
        {
          id: 'partlyCompletedPercentage', title: '%', type: ColDefType.Column, width: 50,
          format: (value: any): string =>
            formatNumberValue(value, constructionData.decimalPlaces.percentage, this.appLocation),
          textAlign: ColDefTextAlign.Right,
          valueGetter: (data: BuildingProgressItem): any => data.partlyCompletedPercentage
        },
        {
          id: 'partlyCompletedAmount', title: 'BUILDING_PROGRESS.TABLE.EXECUTION', type: ColDefType.Column,
          format: (value: any): string =>
            formatNumberValue(value, constructionData.decimalPlaces.amount, this.appLocation),
          textAlign: ColDefTextAlign.Right, minWidth: 80,
          width: getColumnWidth(
            columnsMaxNumber.amount, constructionData.decimalPlaces.amount, this.appLocation, false),
          valueGetter: (data: BuildingProgressItem): any => data.partlyCompletedAmount
        },
        ...!hidePrices ?
          [{
            id: 'partlyCompletedTotalPrice', title: 'BUILDING_PROGRESS.TABLE.PRICE', type: ColDefType.Column,
            format: (value: any): string =>
              formatNumberValue(value, constructionData.decimalPlaces.totalPrice, this.appLocation),
            textAlign: ColDefTextAlign.Right, minWidth: 100,
            width: getColumnWidth(
              columnsMaxNumber.totalPrice, constructionData.decimalPlaces.totalPrice, this.appLocation, true),
            valueGetter: (data: BuildingProgressItem): any => data.partlyCompletedTotalPrice
          }] : []
      ]
    };
  }

  private getPeriodStatusTooltip(period: BuildingProgressPeriod): string {
    if (period.isApproved) {
      return this.translateService.translate('BUILDING_PROGRESS.PERIODS.PERIOD_APPROVED');
    } else if (period.isMultiStageApprovalInProgress) {
      return this.translateService.translate('BUILDING_PROGRESS.PERIODS.APPROVAL_IN_PROGRESS');
    } else if (period.isApprovalRequested) {
      return this.translateService.translate('BUILDING_PROGRESS.PERIODS.REQUEST_FOR_APPROVAL_SENT');
    } else if (period.isLocked) {
      return this.translateService.translate('BUILDING_PROGRESS.PERIODS.LOCKED');
    }

    return '';
  }

  private getItemPeriod(periods: BuildingProgressPeriodItem[], periodId: number): BuildingProgressPeriodItem {
    return periods.find(period => period.periodId === periodId);
  }

  private openDetailsIfCan(itemId: number, period: BuildingProgressPeriodItem): void {
    this.editingPanelHelper.canLeavePanel().pipe(take(1)).subscribe(canLeave => {
      if (canLeave) {
        this.periodDispatchersService.openPeriodDetailsPanel(itemId, period);
      }
    });
  }
}
