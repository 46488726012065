import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { SearchModel } from '@kros-sk/ssw-shared-legacy';

import * as buildingProgressActions from './building-progress.actions';
import { BuildingProgressPeriodItem } from '../../building-progress/models/construction-data.model';
import { BuildingProgressState } from './building-progress.state';
import { ItemDetails } from '../../building-progress/models/item-period-details.model';

@Injectable()
export class BuildingProgressPeriodDispatchersService {

  constructor(
    private store: Store<BuildingProgressState>
  ) { }

  createPeriod(projectId: number, dateFrom: Date, dateTo: Date, searchModel: SearchModel, addToWholeBuildingPeriods?: boolean): void {
    this.store.dispatch(
      buildingProgressActions.createPeriodStart({ projectId, dateFrom, dateTo, searchModel, addToWholeBuildingPeriods })
    );
  }

  editPeriod(
    id: number,
    projectId: number,
    dateFrom: Date,
    dateTo: Date,
    basicVatRate: number,
    reducedVatRate: number,
    searchModel: SearchModel
  ): void {
    this.store.dispatch(
      buildingProgressActions.editPeriodStart({ id, projectId, dateFrom, dateTo, basicVatRate, reducedVatRate, searchModel }));
  }

  deletePeriod(id: number, deleteDocuments: boolean, projectId: number, searchModel: SearchModel): void {
    this.store.dispatch(
      buildingProgressActions.deletePeriodStart({ id, deleteDocuments, projectId, searchModel })
    );
  }

  setApprovedPeriod(
    projectId: number,
    periodId: number,
    isApprovalRequested: boolean,
    isApproved: boolean,
    isMultiStageApprovalModeEnabled: boolean): void {
    this.store.dispatch(
      buildingProgressActions.setApprovedPeriodStart({
        projectId,
        periodId,
        isApprovalRequested,
        isApproved,
        isMultiStageApprovalModeEnabled})
    );
  }

  cancelApprovedPeriod(periodId: number, projectId: number, searchModel: SearchModel, contractorId?: number): void {
    this.store.dispatch(
      buildingProgressActions.cancelApprovedPeriodStart({ periodId, projectId, searchModel, contractorId })
    );
  }

  setIsApprovalRequestedPeriod(projectId: number, periodId: number): void {
    this.store.dispatch(
      buildingProgressActions.setIsApprovalRequestedPeriodStart({ projectId, periodId })
    );
  }

  cancelIsApprovalRequestedPeriod(projectId: number, periodId: number, searchModel: SearchModel, contractorId?: number): void {
    this.store.dispatch(
      buildingProgressActions.cancelIsApprovalRequestedPeriodStart({ projectId, periodId, searchModel, contractorId })
    );
  }

  cancelIsApprovalRequestedPeriods(projectId: number): void {
    this.store.dispatch(
      buildingProgressActions.cancelIsApprovalRequestedPeriods({ projectId })
    );
  }

  setIsLockedPeriod(projectId: number, periodId: number): void {
    this.store.dispatch(
      buildingProgressActions.setIsLockedPeriodStart({ projectId, periodId })
    );
  }

  cancelIsLockedPeriod(periodId: number, projectId: number, searchModel: SearchModel): void {
    this.store.dispatch(
      buildingProgressActions.cancelIsLockedPeriodStart({ periodId, projectId, searchModel })
    );
  }

  selectPeriodId(id: number): void {
    this.store.dispatch(
      buildingProgressActions.selectPeriodId({ id })
    );
  }

  closePeriodDetailsPanel(): void {
    this.store.dispatch(buildingProgressActions.closePeriodDetailsPanel());
  }

  savePeriodDetails(itemId: number, period: BuildingProgressPeriodItem, details: ItemDetails): void {
    this.store.dispatch(
      buildingProgressActions.savePeriodDetailsStart({ itemId, period, details })
    );
  }

  openPeriodDetailsPanel(itemId: number, period: BuildingProgressPeriodItem): void {
    this.store.dispatch(buildingProgressActions.openPeriodDetailsPanel({ itemId, period }));
  }

  setHasPeriodInvoices(ids: number[], hasInvoices: boolean): void {
    this.store.dispatch(
      buildingProgressActions.setHasPeriodInvoices({ ids, hasInvoices }));
  }
}
