import {
  AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';

import { CompanySearchValidationOptions } from '@kros-sk/models';
import { KrosModalRef } from '@kros-sk/components';
import { TranslateService } from '@kros-sk/ssw-shared-legacy';

import { environment } from '../../../../../environments/environment';
import { Subcontractor } from '../models';

@Component({
  selector: 'app-subcontractor-select-dialog',
  templateUrl: './subcontractor-select-dialog.component.html',
  styleUrls: ['./subcontractor-select-dialog.component.scss']
})
export class SubcontractorSelectDialogComponent implements OnInit {
  subcontractors: Subcontractor[] = [];
  form: UntypedFormGroup;
  companySearchOptions: CompanySearchValidationOptions = {
    validators: this.validators
  };
  errors: { [key: string]: string } = {};

  get canSearch(): boolean {
    return environment.location === 'sk';
  }

  get validators(): ValidatorFn[] {
    return [
      Validators.required,
      this.subcontractorExistsValidator()
    ];
  }

  constructor(
    private fb: UntypedFormBuilder,
    private modalRef: KrosModalRef,
    private translateService: TranslateService
  ) {
    this.subcontractors = this.modalRef.data.subcontractors;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      subcontractor: new UntypedFormControl('', this.validators),
    });

    this.errors = {
      required: this.translateService.translate('SUBCONTRACTORS.SUBCONTRACTOR_NAME_REQUIRED'),
      subcontractorAlreadyExists: this.translateService.translate('SUBCONTRACTORS.SUBCONTRACTOR_NAME_ALREADY_EXISTS')
    };
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  onSubmit(): void {
    this.modalRef.submit({ subcontractor: this.form.get('subcontractor').value });
  }

  private subcontractorExistsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = typeof control.value === 'string' ? control.value : control.value.address?.businessName;
      const forbidden = value ? this.subcontractors.some(subcontractor => subcontractor.name === value.trim()) : false;
      return forbidden ? { subcontractorAlreadyExists: { value } } : null;
    };
  }
}
