import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { RouterModule } from '@angular/router';

import { DirectivesModule } from '../directives';
import { KrosModalComponent } from './kros-modal/kros-modal.component';
import { MessageBoxModalComponent } from './message-box-modal/message-box-modal.component';

@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    OverlayModule,
    A11yModule,
    RouterModule
  ],
  declarations: [
    KrosModalComponent,
    MessageBoxModalComponent,
  ],
  exports: [
    KrosModalComponent,
    MessageBoxModalComponent,
    OverlayModule
  ]
})
export class KrosModalModule { }
