import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class SaveButtonStateService {

  private _isEdited$ = new BehaviorSubject<boolean>(false);
  private _save$ = new BehaviorSubject<void>(undefined);

  get isEdited$(): Observable<boolean> {
    return this._isEdited$.asObservable();
  }

  get save$(): Observable<void> {
    return this._save$.asObservable();
  }

  setIsEdited(value: boolean): void {
    this._isEdited$.next(value);
  }

  save(): void {
    this._save$.next();
  }
}
