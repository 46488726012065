import { ElementRef, Renderer2 } from '@angular/core';

/**
 * Calculate width in px for string value
 *
 * @param value
 * @param renderer
 * @param element
 * @returns
 * @throws On some browsers (firefox with addon Selenium or so) getContext('2d') throw error.
 * @see more info about exception: https://dev.azure.com/krossk/Esw/_workitems/edit/67624
 */
export function autosizeTextWidth(
  value: string,
  renderer: Renderer2,
  element: ElementRef
): number {
  const ctx = renderer.createElement('canvas').getContext('2d');

  const style = window.getComputedStyle(element.nativeElement, '');

  const fontStyle = style.getPropertyValue('font-style');
  const fontVariant = style.getPropertyValue('font-variant');
  const fontWeight = style.getPropertyValue('font-weight');
  const fontSize = style.getPropertyValue('font-size');
  const fontFamily = style.getPropertyValue('font-family');

  ctx.font = fontStyle + ' ' + fontVariant + ' ' + fontWeight + ' ' + fontSize + ' ' + fontFamily;

  return ctx.measureText(value).width;
}
