<div class="toolbar-actions">
  <div class="actions left">
    <button
      class="btn toolbar-action gray-toolbar-action no-selection open-panel"
      type="button"
      (click)="onOpenPanel()"
      krosTooltip="{{ 'DOCUMENTS.FOLDERS' | translate }}"
    >
      <i class="material-icons icon-button">list</i>
    </button>

    <div class="divider-vertical tablet-visible"></div>

    <kros-document-create
      *ngIf="canAddDocumentMenu"
      [parentId]="parentId"
      [canAddDocumentVersion]="canAddDocumentVersion"
      [contentType]="getSelectedDocumentContentType"
      [onlyCreateFolder]="isUploadingMode"
      (filesSelected)="onShowDocumentUploader()"
      (fileAddVersionSelected)="onShowDocumentVersionUploader()"
      data-test="add-document-button"
    ></kros-document-create>

    <button
      *ngIf="isSingleDocumentSelected && isSelectedFolder"
      class="btn toolbar-action no-selection"
      type="button"
      krosTooltip="{{ 'PROJECTS.ZOZNAM.OTVORIT' | translate }}"
      data-test="open-document-button"
      [ngClass]="isUploadingMode ? 'gray-toolbar-action' : 'blue-toolbar-action'"
      (click)="openFolder()"
    >
      <i class="material-icons icon-button">open_in_new</i>
      <span class="toolbar-action-text">{{ 'PROJECTS.ZOZNAM.OTVORIT' | translate }}</span>
    </button>

    <button
      class="btn toolbar-action blue-toolbar-action no-selection"
      data-test="document-show-button"
      type="button"
      krosTooltip="{{ 'DOCUMENTS.SHOW' | translate }}"
      *ngIf="canOpenOnNewTab"
      (click)="openOnNewTab()"
    >
      <i class="material-icons icon-button">visibility</i>
      <span class="toolbar-action-text">{{ 'DOCUMENTS.SHOW' | translate }}</span>
    </button>

    <ng-container *ngIf="!isUploadingMode && isSingleDocumentSelected">
      <button
        class="btn toolbar-action blue-toolbar-action no-selection"
        type="button"
        *ngFor="let app of openDocumentApplications"
        (click)="openDocumentInApplication(app.id)"
        [attr.data-test]="'selected-document-application-' + app.id + '-button'"
        krosAnalytic
        krosTooltip="{{ getDisplayName(app) }}"
        eventCategory="aplikacie"
        eventAction="{{ app.displayName.sk }}"
      >
        <i class="material-icons icon-button">{{app.iconName}}</i>
        <span class="toolbar-action-text">{{ getDisplayName(app) }}</span>
      </button>
    </ng-container>

    <kros-upload-version-button
      *ngIf="canAddDocumentVersion"
      [contentType]="getSelectedDocumentContentType"
      [projectId]="project.id"
      [documentId]="selectedDocumentId"
      [parentId]="parentId"
      [toolbarAction]="true"
      (fileAddVersionSelected)="onfileAddVersionSelected()"
    >
    </kros-upload-version-button>

    <button
      class="btn toolbar-action gray-toolbar-action no-selection"
      type="button"
      *ngIf="hasDescription"
      (click)="onSetDocumentDescriptionClick()"
      krosTooltip="{{'DOCUMENTS.DESCRIPTION' | translate}}"
      data-test="description-document-button"
    >
      <i class="material-icons icon-button">description</i>
      <span class="toolbar-action-text">{{'DOCUMENTS.DESCRIPTION' | translate}}</span>
    </button>

    <button
      class="btn toolbar-action gray-toolbar-action no-selection"
      type="button"
      *ngIf="canDownload"
      (click)="onDownloadDocumentClick()"
      data-test="download-button"
      krosAnalytic
      eventCategory="sprava-suborov"
      krosTooltip="{{ 'DOCUMENTS.STIAHNUT' | translate }}"
      eventAction='stiahnut-dokument'
    >
      <i class="material-icons icon-button">cloud_download</i>
      <span class="toolbar-action-text">{{ 'DOCUMENTS.STIAHNUT' | translate }}</span>
    </button>

    <button
      class="btn toolbar-action gray-toolbar-action no-selection"
      type="button"
      *ngIf="canRenameFolder"
      (click)="onRenameFolderClick()"
      krosTooltip="{{ markedDocumentsCount > 1 ? ( 'DOCUMENTS.RENAME_SELECTED' | translate ) : ( 'DOCUMENTS.RENAME' | translate ) }}"
      data-test="rename-folder-button"
    >
      <i class="material-icons icon-button">edit</i>
      <span class="toolbar-action-text">{{ markedDocumentsCount > 1 ? ( 'DOCUMENTS.RENAME_SELECTED' | translate )
        : ( 'DOCUMENTS.RENAME' | translate ) }}</span>
    </button>

    <button
      *ngIf="isUploadingMode"
      class="btn toolbar-action no-selection"
      type="button"
      (click)="uploadBuilding()"
      data-test="open-document-button"
      krosTooltip="{{'DOCUMENTS.UPLOAD_BUILDING.UPLOAD_BUILDING' | translate}}"
    >
      <i class="material-icons icon-button">upload</i>
      <span class="toolbar-action-text">{{'DOCUMENTS.UPLOAD_BUILDING.UPLOAD_BUILDING' | translate}}</span>
    </button>

    <button
      class="btn toolbar-action gray-toolbar-action no-selection"
      type="button"
      *ngIf="canDelete"
      (click)="onDeleteDocumentsClick()"
      krosTooltip="{{ markedDocumentsCount > 1 ? ( 'DOCUMENTS.ZMAZAT_OZNACENE' | translate ) : ( 'DOCUMENTS.ZMAZAT' | translate ) }}"
      data-test="delete-document-button"
    >
      <i class="material-icons icon-button">delete</i>
      <span class="toolbar-action-text">{{ markedDocumentsCount > 1 ? ( 'DOCUMENTS.ZMAZAT_OZNACENE' | translate )
        : ( 'DOCUMENTS.ZMAZAT' | translate ) }}</span>
    </button>
  </div>

  <div class="toolbar-title"></div>

  <div class="actions right">
    <button
      class="btn toolbar-action gray-toolbar-action no-selection"
      type="button"
      *ngIf="canShare"
      (click)="onShareDocumentClick()"
      krosTooltip="{{ 'SHARING.SHARE' | translate }}"
      data-test="share-document-button"
    >
      <i class="material-icons icon-button">person_add</i>
      <span class="toolbar-action-text">{{ 'SHARING.SHARE' | translate }}</span>
    </button>
  </div>
</div>
