import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ImageToDataUrlDirective } from 'ngx-image2dataurl';

import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { AzureBlobStorageService, ImageService, KrosApiBlobStorageService, KrosBlobStorageService } from './services';
import { ImageUploadButtonComponent } from './component/image-upload-button.component';
import { TranslateModule } from '../translate';

@NgModule({
  declarations: [
    ImageUploadButtonComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ImageToDataUrlDirective,
    TooltipDirective
  ],
  exports: [
    ImageUploadButtonComponent
  ],
  providers: [
    AzureBlobStorageService,
    KrosApiBlobStorageService,
    KrosBlobStorageService,
    ImageService
  ]
})
export class ImageUploadButtonModule {
}
