<kros-tag
  *ngFor="let tag of tags"
  [color]="tag.textColorCode"
  [backgroundColor]="tag.colorCode"
  (click)="onTagClick()"
>{{ getTagName(tag.name) }}</kros-tag>

<i
  *ngIf="showTagIcon && tags.length === 0"
  class="material-icons"
  (click)="onTagClick()"
>label</i>
