<div
  ngbDropdown
  class="action"
  container="body"
>
  <button
    ngbDropdownToggle
    class="btn toolbar-action no-selection gray-toolbar-action"
    type="button"
    krosTooltip="{{ 'FILTER.TAG' | translate }}"
    data-test="tags-dropdown-button"
  >
    <i class="material-icons">label</i>
    <span class="toolbar-action-text">{{ 'FILTER.TAG' | translate }}</span>
  </button>

  <ul
    ngbDropdownMenu
    class="tag-list"
  >
    <ng-container *ngFor="let tag of tags">
      <button
        *ngIf="isTagVisible(tag)"
        ngbDropdownItem
        type="button"
        class="tag ga-tag-dropdown-select"
        data-test="tag"
        (click)="selectTag(tag)"
      >
        <i
          class="material-icons ga-tag-dropdown-select"
          [style.color]="tag.textColorCode"
        >label</i>
        <span data-test="tag-name">{{ getTagName(tag.name) }}</span>
      </button>
    </ng-container>
  </ul>
</div>
