import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { ApplicationType, formatString } from '@kros-sk/ssw-cdk';
import { MarketingContentHelper } from '@kros-sk/ssw-shared-legacy';

import { ConstructionDiarySelectorsService } from '../data-access';

@Injectable()
export class ConstructionDiaryMarketingContentHelper extends MarketingContentHelper {

  private selectors = inject(ConstructionDiarySelectorsService);

  private product: ApplicationType;

  protected get moduleName(): string {
    return this.translateService.translate(`CONSTRUCTION_DIARY.${this.productName.toUpperCase()}`);
  }

  private get productName(): string {
    if (this.product) {
      return ApplicationType[this.product];
    } else {
      return '';
    }
  }

  constructor() {
    super();

    this.selectors.product$.pipe(takeUntilDestroyed()).subscribe(product => this.product = product);
  }

  protected resolvePicture(): void {
    this.picture = `logo-${this.productName.toLowerCase()}`;
  }

  protected resolvePictureLabel(): void {
    this.pictureLabel = this.translateService.translate(`CONSTRUCTION_DIARY.OPERATOR_${this.productName.toUpperCase()}`);
  }

  protected resolveHeading(): void {
    this.heading = '';
  }

  protected resolveInfoText(): void {
    switch (true) {
      case this.config.isLicenseFree && this.config.canActivateTrial:
        this.infoText = `${formatString(
          this.translateService.translate(
            `LICENSE_INFO.MKT_MODAL_TEXT_FREE_CAN_ACTIVATE_TEXT_CONSTRUCTION_DIARY_${this.productName.toUpperCase()}`),
          this.moduleName,
          this.config.trialDuration + '',
          this.pluralisationByCountPipe.transform(this.config.trialDuration, 'LICENSE_INFO.DAY')
        )} ${this.getMoreInfoLink()}`;
        break;
      case (this.config.isLicenseFree && !this.config.canActivateTrial) || this.config.isLicenseTrial:
        this.infoText = formatString(
          this.translateService.translate(
            'LICENSE_INFO.MKT_MODAL_TEXT_FREE_CANNOT_ACTIVATE_TEXT_CONSTRUCTION_DIARY'),
          '',
          this.moduleName,
          this.product === ApplicationType.Stavario ? this.urlHelper.contactUsStavario : this.urlHelper.contactUsBuildary
        );
        break;
      case this.config.isLicenseReadonly:
        this.infoText = formatString(
          this.translateService.translate('LICENSE_INFO.MKT_MODAL_TEXT_READONLY_TEXT'),
          '',
          this.moduleName,
          this.product === ApplicationType.Stavario ? this.urlHelper.contactUsStavario : this.urlHelper.contactUsBuildary
        );
        break;
    }
  }

  protected resolveOrderButton(): void {
    this.showOrderButton = this.config.isLicenseReadonly;
  }

  protected resolveFooterText(): void {
    if (this.config.isLicenseFree && this.config.canActivateTrial) {
      this.footerText = formatString(
        this.translateService.translate('CONSTRUCTION_DIARY.TRIAL_WARNING'),
        this.moduleName
      );
    }
  }

  protected getMoreInfoLink(): string {
    const url = this.product === ApplicationType.Stavario
      ? this.urlHelper.skMoreInfoStavarioUrl
      : this.urlHelper.skMoreInfoBuildaryUrl;
    return `<a href="${url}" target="new">${this.translateService.translate('LICENSE_INFO.MORE_INFO')}</a>`;
  }
}
