import { Injectable } from '@angular/core';

import {
  CalculationType,
  ColDef,
  ColDefTextAlign,
  ColDefType,
  ColumnIconComponent,
  DataTableConfigModel,
  formatNumberValue,
  getColumnWidth,
  getNodeLevel,
  isConstructionOrBuildingObject,
  isSummaryItem
} from '@kros-sk/ssw-shared-legacy';

import { BudgetItem, BudgetModel } from '../models';
import { ColumnsConfigModel } from '../../building-progress/models/columns-config.model';
import { environment } from '../../../environments/environment';
import {
  getColumnsMaxNumber,
  setColorIfAdditionOrChangeSheet,
  setColorIfAdditionOrChangeSheetAllItems,
  setColorIfMaterial
} from '../../building-progress/helpers';

@Injectable({
  providedIn: 'root'
})
export class BudgetTableService {
  averageLineHeight: number;

  get appLocation(): string {
    return environment.location;
  }

  getTableConfig(budgetData: BudgetModel): DataTableConfigModel {
    const columnsMaxNumber = getColumnsMaxNumber(budgetData);
    this.averageLineHeight = columnsMaxNumber.averageLineHeight;

    return {
      isReadonly: false,
      itemsOffset: columnsMaxNumber.itemsOffset,
      colDefs: this.getBudgetColumns(budgetData, columnsMaxNumber),
      rowClassGetter: (data: BudgetItem): string => {
        let classString = '';
        classString += isConstructionOrBuildingObject(data) ? 'font-blue font-strong' : '';
        classString += data.itemType === 'D' ? ' font-strong' : '';
        classString += data.hasRelation ? ' has-relation' : '';
        return classString;
      },
      fixedWidth: true
    };
  }

  private getBudgetColumns(budgetData: BudgetModel, columnsMaxNumber: ColumnsConfigModel): ColDef[] {
    return [
      {
        id: 'budget-itemType', title: 'BUILDING_PROGRESS.TABLE.ITEM_TYPE', type: ColDefType.Column,
        textAlign: ColDefTextAlign.Center, isFixed: true, width: 20, mobileHidden: true,
        valueGetter: (data: BudgetItem): any => data.itemType
      },
      {
        id: 'budget-itemNumber', title: 'BUILDING_PROGRESS.TABLE.ITEM_NUMBER', type: ColDefType.Column,
        textAlign: ColDefTextAlign.Right, isFixed: true, width: 40,
        valueGetter: (data: BudgetItem): any => data.itemNumber
      },
      {
        id: 'budget-code', title: 'BUILDING_PROGRESS.TABLE.ITEM_CODE', type: ColDefType.Column, isFixed: true, width: 110,
        cellClassGetter: (data: BudgetItem): string => setColorIfAdditionOrChangeSheetAllItems(data),
        valueGetter: (data: BudgetItem): any => data.code
      },
      {
        id: 'budget-description', title: 'BUILDING_PROGRESS.TABLE.DESCRIPTION', type: ColDefType.Column, width: 300,
        cellClassGetter: (data: BudgetItem): string =>
          (data && isSummaryItem(data) ?
            'level-' + getNodeLevel(data.level) + (data.originalId ? ' additional-item-padding' : '') : '') +
          setColorIfAdditionOrChangeSheet(data) + setColorIfMaterial(data)
        ,
        isFixed: true,
        valueGetter: (data: BudgetItem): any => data.description
      },
      {
        id: 'budget-relation',
        title: 'CHANGE_SHEET.RELATION.RELATION',
        type: ColDefType.Column,
        width: 34,
        isFixed: true,
        cellClassGetter: (item: BudgetItem): string => item?.hasRelation ? 'font-green' : '',
        componentConfig: {
          component: ColumnIconComponent,
          params: {
            iconName: 'link',
            tooltip: 'CHANGE_SHEET.RELATION.RELATION',
            action: (): void => { }
          }
        }
      },
      {
        id: 'budget-measureUnit', title: 'BUILDING_PROGRESS.TABLE.MEASURE_UNIT', type: ColDefType.Column,
        cellClassGetter: (data: BudgetItem): string => setColorIfAdditionOrChangeSheet(data),
        width: 40,
        valueGetter: (data: BudgetItem): any => data.measureUnit
      },
      {
        id: 'budget-amount', title: 'BUILDING_PROGRESS.TABLE.AMOUNT', type: ColDefType.Column, minWidth: 80,
        width: getColumnWidth(columnsMaxNumber.amount, budgetData.decimalPlaces.amount, this.appLocation, false),
        format: (value: any, editMode: boolean): string =>
          formatNumberValue(value, budgetData.decimalPlaces.amount, this.appLocation),
        textAlign: ColDefTextAlign.Right,
        valueGetter: (data: BudgetItem): any => isSummaryItem(data) ? null : data.amount,
        cellClassGetter: (data: BudgetItem): string =>
          !data ? '' :
            ((!data.amount ? 'font-red ' : '') +
              (data.calculationType === CalculationType.BillOfQuantities ? 'font-blue' :
                data.calculationType !== CalculationType.None ? 'font-green' : ''))
      },
      {
        id: 'budget-unitPrice', title: 'BUILDING_PROGRESS.TABLE.UNIT_PRICE', type: ColDefType.Column, minWidth: 60,
        width: getColumnWidth(columnsMaxNumber.unitPrice, budgetData.decimalPlaces.unitPrice, this.appLocation, false),
        format: (value: any, editMode: boolean): string =>
          formatNumberValue(value, budgetData.decimalPlaces.unitPrice, this.appLocation),
        textAlign: ColDefTextAlign.Right,
        valueGetter: (data: BudgetItem): any => isSummaryItem(data) ? null : data.unitPrice
      },
      {
        id: 'budget-totalPrice', title: 'BUILDING_PROGRESS.TABLE.TOTAL_PRICE', type: ColDefType.Column, minWidth: 100,
        width: getColumnWidth(columnsMaxNumber.totalPrice, budgetData.decimalPlaces.totalPrice, this.appLocation, true),
        format: (value: any, editMode: boolean): string =>
          formatNumberValue(value, budgetData.decimalPlaces.totalPrice, this.appLocation),
        textAlign: ColDefTextAlign.Right,
        valueGetter: (data: BudgetItem): any => isSummaryItem(data) ? null : data.totalPrice
      }
    ];
  }
}
