<div class="panel-wrapper">
  <div class="panel-header">
    <div class="panel-header-caption">
      <h4>{{ 'NOTES.ITEM_NOTE' | translate }}</h4>
    </div>

    <button
      class="btn btn-link btn-close"
      type="button"
      data-test="close-item-notes-button"
      (click)="onClose()"
    >
      <i class="material-icons">close</i>
    </button>
  </div>

  <div class="panel-content">
    <form
      *ngIf="isContentLoaded else contentLoader"
      [formGroup]="notesForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="form-content">
        <div class="form-group">
          <textarea
            type="text"
            class="form-control"
            formControlName="note"
            krosTextareaAutosize
            data-test="item-note-text"
            (click)="onClickNote()"
            [readOnly]="isReadOnly"
            [placeholder]="(isReadOnly ? 'NOTES.NO_NOTE' : 'NOTES.TEXT_PLACEHOLDER') | translate"
            tabindex="-1"
          ></textarea>
        </div>
      </div>

      <div class="buttons-container">
        <div
          *ngIf="!readOnly && !notesForm.pristine"
          class="buttons"
        >
          <button
            class="btn btn-primary btn-submit"
            id="ga-save-item-notes"
            type="submit"
            data-test="save-item-notes-button"
            [disabled]="!notesForm.valid"
          >{{'COMMON.ULOZIT' | translate}}</button>

          <button
            class="btn btn-outline-secondary btn-cancel"
            id="ga-cancel-item-notes"
            type="button"
            data-test="cancel-item-notes-button"
            (click)="cancelChanges()"
          >{{'COMMON.ZRUSIT' | translate}}</button>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #contentLoader>
  <kros-timeline [type]="timelineType.ThreeLine"></kros-timeline>
</ng-template>
