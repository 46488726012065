import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { DxDataGridComponent, DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { map } from 'rxjs';

import { GridComponent, TranslateModule } from '@kros-sk/ssw-shared-legacy';

import { ApprovalOrder, ApprovalOrderDispatchersService, ApprovalOrderSelectorsService, ApprovalOrderTableItem } from '../../data-access';

@Component({
  selector: 'bp-approval-order-table',
  standalone: true,
  templateUrl: './approval-order-table.component.html',
  styleUrls: ['./approval-order-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, DxDataGridModule, TranslateModule]
})
export class ApprovalOrderTableComponent extends GridComponent<number, ApprovalOrderTableItem>
  implements AfterViewInit, OnInit {

  @ViewChild('dataDataGridRef', { static: false }) dataDataGrid: DxDataGridComponent;
  @Input() projectId: number;
  @Input() isReadOnly: boolean;

  @Output() orderChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected get dataComponent(): DxDataGridComponent {
    return this.dataDataGrid;
  }

  protected get isLastRowFocused(): boolean {
    return this.dataSource.length - 1 === this.focusedCell?.rowIndex;
  }

  protected get selectedItemId(): number {
    throw new Error('Method not implemented.');
  }

  private originalDataSource: ApprovalOrderTableItem[] = [];

  private approvalOrderSelectorsService = inject(ApprovalOrderSelectorsService);
  private approvalOrderDispatchersService = inject(ApprovalOrderDispatchersService);
  private changeDetector = inject(ChangeDetectorRef);

  constructor() {
    super();
    this.dataSource = null;
    this.onReorder = this.onReorder.bind(this);
  }

  ngOnInit(): void {
    this.approvalOrderSelectorsService.approvalOrder$.pipe(
      map(approvalOrders => approvalOrders.map(this.mapToApprovalOrderTableItem)),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(data => {
      this.dataDataGrid?.instance?.endCustomLoading();
      this.dataSource = data;
      this.originalDataSource = data.map(item => Object.assign({}, item));
      this.changeDetector.markForCheck();
      this.orderChanged.emit(false);
    });
  }

  ngAfterViewInit(): void {
    this.dataDataGrid?.instance?.beginCustomLoading('');
  }

  saveEditData(): void {
    this.dataDataGrid.instance.saveEditData();
    this.approvalOrderDispatchersService.saveApprovalOrder(this.dataSource.map(this.mapToApprovalOrder), this.projectId);
  }

  onReorder(e): void {
    const visibleRows = e.component.getVisibleRows();
    const toIndex = this.dataSource.findIndex((item) => item.id === visibleRows[e.toIndex].data.id);
    const fromIndex = this.dataSource.findIndex((item) => item.id === e.itemData.id);

    this.dataSource.splice(fromIndex, 1);
    this.dataSource.splice(toIndex, 0, e.itemData);

    this.dataSource.forEach((item, index) => { item.order = index + 1; });
    this.changeDetector.markForCheck();

    this.orderChanged.emit(this.getIsOrderChanged());
  }

  onCellPrepared(e: any): void {
  }

  protected selectRow(itemId: number, columnIndex?: number): void {
    throw new Error('Method not implemented.');
  }

  protected dispatchActionSelectItem(item: any): void {
    throw new Error('Method not implemented.');
  }

  private mapToApprovalOrderTableItem(approvalOrders: ApprovalOrder): ApprovalOrderTableItem {
    return {
      id: approvalOrders.userId,
      order: approvalOrders.order,
      email: approvalOrders.email
    };
  }

  private mapToApprovalOrder(approvalOrders: ApprovalOrderTableItem): ApprovalOrder {
    return {
      userId: approvalOrders.id,
      order: approvalOrders.order,
      email: approvalOrders.email
    };
  }

  private getIsOrderChanged(): boolean {
    return this.originalDataSource.length !== this.dataSource.length ||
      this.originalDataSource.some((item, index) => item.id !== this.dataSource[index].id || item.order !== this.dataSource[index].order);
  }
}
