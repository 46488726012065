import { finalize, mergeMap, retryWhen, tap } from 'rxjs/operators';
import { MonoTypeOperatorFunction, Observable, throwError, timer } from 'rxjs';

/**
 * Operator for retrying HTTP requests and logging retry process.
 */
export function httpRetryAndLog<T>(settings: Partial<HttpRetryStrategySettings>): MonoTypeOperatorFunction<T> {
  const completeSettings = { ...defaultRetrySettings, ...settings };
  let retryingSuccessful = false;
  return source => source.pipe(
    retryWhen(httpRetryStrategy(
      completeSettings.retryOnStatus,
      completeSettings.retryDelays,
      (retryItems) => {
        if (retryItems != null && completeSettings.loggerFn) {
          completeSettings.loggerFn({
            retries: retryItems,
            retryingSuccessful
          });
        }
      }
    )),
    tap(() => retryingSuccessful = true)
  );
}

/**
 * Creates HTTP retry error for logging.
 * @param handler Handler class name.
 * @param apiMethod Api method name.
 * @param httpRequestMethod HTTP request method.
 * @returns Http retry log error.
 */
export function createHttpRetryErrorForLog(handler: string, apiMethod: string, httpRequestMethod: string): Error {
  return Error(`${handler}.${apiMethod} ${httpRequestMethod} retrying`);
}

function httpRetryStrategy(
  retryOnStatus: RegExp,
  retryDelays: number[],
  finalized: (items: HttpRetryLogItem[] | null) => void
): (errors: any) => Observable<any> {
  return (errors: Observable<any>) => {
    const internalRetryLog: HttpRetryLogItem[] = [];
    let ignoreRetryForThisStatus = false;
    return errors.pipe(
      mergeMap((error, index) => {
        const errorStatus = error?.status.toString();
        if (index === 0) {
          // on first retry we check if this statusCode has to be retried
          if (!retryOnStatus.test(errorStatus)) {
            ignoreRetryForThisStatus = true;
            return throwError(error);
          }
        }
        internalRetryLog.push({
          attemptNr: index,
          delayMs: index ? retryDelays[index - 1] : null,
          errorStatus
        });
        if (index >= retryDelays.length) {
          // we have exhausted retry attempts, we simply rethrow the error...
          return throwError(error);
        }
        return timer(retryDelays[index]);
      }),
      finalize(
        // After the errors were completed or were rethrown, we call finalized callback with logged retry attempts.
        // if this status code is ignored, finalized callback will be called with null value...
        () => finalized(ignoreRetryForThisStatus ? null : internalRetryLog)
      )
    );
  };
}

/**
 * Settings for HTTP retry process.
 */
export interface HttpRetryStrategySettings {
  /**
   * Response status codes that should be processed.
   */
  retryOnStatus: RegExp;

  /**
   * List of delays in ms. Count of items equals count of retry attempts.
   */
  retryDelays: number[];

  /**
   * Logging callback.
   * @param result Result of retrying process.
   */
  loggerFn: (result: HttpRetryResult) => void;
}

/**
 * Result of HTTP retrying process.
 */
export interface HttpRetryResult {
  retries: HttpRetryLogItem[];
  retryingSuccessful: boolean;
}

/**
 * The attempt of HTTP retrying.
 */
export interface HttpRetryLogItem {
  attemptNr: number,
  delayMs: number | null,
  errorStatus: string
}

const defaultRetrySettings = {
  logger: (): void => {},
  retryDelays: [500, 1000, 2000],
  retryOnStatus: /\d{3}/
};
