import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';

const projectsApi = '/api/projects';
const buildingProgressService = '/buildingProgressService';
const budgetController = '/budget';
const hasPartialBudgetItemsEndpoint = '/hasPartialBudgetItems';

@Injectable()
export class TransferExecutionsService {

  private get projectsApi(): string {
    return environment.appUrls.titanGatewayUrl + projectsApi;
  }

  constructor(
    private http: HttpClient,
  ) { }

  loadHasPartialBudgetItems(projectId: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.projectsApi}/${projectId}`
        + `${buildingProgressService}${budgetController}${hasPartialBudgetItemsEndpoint}`);
  }
}
