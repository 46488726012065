import { Directive, ElementRef, InjectionToken, Input } from '@angular/core';

export const KROS_ERROR = new InjectionToken<KrosError>('KrosError');

@Directive({
  selector: 'kros-error',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'kros-error',
  },
  providers: [{ provide: KROS_ERROR, useExisting: KrosError }],
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class KrosError {
  @Input() errorId = '';

  constructor(
    private element: ElementRef,
  ) {
  }

  get nativeElement(): HTMLElement {
    return this.element.nativeElement;
  }
}
