import { Component, TemplateRef, ViewChild } from '@angular/core';

import { combineLatest, map, Observable } from 'rxjs';

import { UserProfileBase } from '@kros-sk/components';

import { KrosUser } from '@kros-sk/auth';

import { UserProfileService } from '../../user-profile.service';

@Component({
  selector: 'kros-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent extends UserProfileBase {

  // templates su plnene pomocou userProfileItem direktivy
  entitySelector: TemplateRef<void>;

  @ViewChild('entityUserSelectorModal', { read: TemplateRef }) modalUserContent: TemplateRef<any>;

  constructor(
    private userProfileService: UserProfileService,
  ) {
    super();
  }

  get viewModel$(): Observable<{ user: KrosUser, isLoggedIn: boolean, }> {
    return combineLatest([this.authSelectors.currentUser$, this.authSelectors.isLoggedIn$,]).pipe(
      map(([user, isLoggedIn]) => ({ user, isLoggedIn }),),
    );
  }
}
