import { Observable, Subject } from 'rxjs';

export class DocumentCreateService {

  get createDocument$(): Observable<void> {
    return this.createDocument.asObservable();
  }

  get createFolder$(): Observable<void> {
    return this.createFolder.asObservable();
  }

  private createDocument = new Subject<void>();
  private createFolder = new Subject<void>();

  onCreateDocument(): void {
    this.createDocument.next();
  }

  onCreateFolder(): void {
    this.createFolder.next();
  }
}
