import { Address } from '../base';
import { Partner, PartnerSearchModel } from './partners.model';

export function emptyPartner(): Partner {
  return {
    id: -1,
    address: {
      businessName: '',
      contactName: '',
      street: '',
      postCode: '',
      city: '',
      country: ''
    },
    postalAddress: {
      businessName: '',
      contactName: '',
      street: '',
      postCode: '',
      city: '',
      country: ''
    },
    registrationId: '',
    taxId: '',
    vatId: '',
    phoneNumber: '',
    email: '',
    web: '',
    isFree: false,
  };
}

export function mapPartnerSearchToPartnerModel(searchModel: PartnerSearchModel): Partner {
  return {
    id: +searchModel.Id,
    address: {
      businessName: searchModel.BusinessName,
      contactName: searchModel.ContactName,
      street: searchModel.Street,
      postCode: searchModel.PostCode,
      city: searchModel.City,
      country: searchModel.Country
    } as Address,
    postalAddress: {
      businessName: searchModel.PostalAddressBusinessName,
      street: searchModel.PostalAddressStreet,
      postCode: searchModel.PostalAddressPostCode,
      city: searchModel.PostalAddressCity,
      country: searchModel.PostalAddressCountry
    } as Address,
    registrationId: searchModel.RegistrationId,
    taxId: searchModel.TaxId,
    vatId: searchModel.VatId,
    phoneNumber: searchModel.PhoneNumber,
    email: searchModel.Email,
    web: ''
  } as Partner;
}
