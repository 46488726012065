import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FileDropZoneDirective } from './file-drop-zone.directive';

@NgModule({
  declarations: [
    FileDropZoneDirective
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    FileDropZoneDirective
  ]
})
export class FileDropZoneModule { }
