import { inject, Injectable } from '@angular/core';

import { v4 as uuidv4 } from 'uuid';

import { StorageService } from '@kros-sk/ssw-cdk';

const DEVICE_INFO_KEY = 'device-info';

interface DeviceInfo {
  deviceGuid: string;
}

@Injectable()
export class DeviceInfoService {

  private storageService = inject(StorageService);
  private applicationNameProperty = '';
  private deviceGuidProperty = '';

  get applicationName(): string | undefined {
    return this.applicationNameProperty;
  }

  get deviceGuid(): string {
    return this.deviceGuidProperty;
  }

  constructor() {
    this.initDeviceInfo();
  }

  getDeviceInfo(): DeviceInfo {
    return {
      deviceGuid: this.deviceGuidProperty
    };
  }

  setApplicationName(applicationId: string): void {
    this.applicationNameProperty = applicationId;
  }

  private initDeviceInfo(): void {
    const deviceInfoString: string = this.storageService.getItemFromLocalStorage(DEVICE_INFO_KEY);
    if (!deviceInfoString) {
      this.deviceGuidProperty = uuidv4();
      this.storageService.setItemToLocalStorage(DEVICE_INFO_KEY, JSON.stringify(this.getDeviceInfo()));
    } else {
      const deviceInfo = JSON.parse(deviceInfoString);
      this.deviceGuidProperty = deviceInfo.deviceGuid;
    }
  }
}
