/* eslint-disable @ngrx/prefer-inline-action-props */
import { Params } from '@angular/router';

import { createAction, props } from '@ngrx/store';

import { BudgetItem, ChangedBudgetItems, CreatedBudgetItem, OccurrenceItemIndex } from '@kros-sk/ssw-shared-legacy';
import { EditedBudgetItem, MoveItemsActionModel, MoveItemsPositions } from '@kros-sk/ssw-budget/shared/data-access/models';

import { budgetKey } from './budget.actions';

export const setSelectedBudgetItemById = createAction(`${budgetKey} set selected budget item by id`, props<{ id: string }>());

export const createCustomBudgetItemStart = createAction(
  `${budgetKey} Create custom budget item start`,
  props<{ itemBefore: BudgetItem, item: BudgetItem }>());
export const createCustomBudgetItemSuccess = createAction(
  `${budgetKey} Create custom budget item success`,
  props<{ changedBudgetItems: ChangedBudgetItems }>());
export const createCustomBudgetItemError = createAction(`${budgetKey} Create custom budget item error`, props<{ error: any }>());

export const copyBudgetItemStart = createAction(
  `${budgetKey} Copy budget item start`,
  props<{ item: BudgetItem }>());
export const copyBudgetItemSuccess = createAction(
  `${budgetKey} Copy budget item success`,
  props<{ changedBudgetItems: ChangedBudgetItems }>());
export const copyBudgetItemError = createAction(`${budgetKey} Copy budget item error`, props<{ error: any }>());

export const createEmptyBudgetItem = createAction(`${budgetKey} Create empty budget item`, props<{ item: CreatedBudgetItem }>());
export const deleteEmptyBudgetItem = createAction(`${budgetKey} Delete empty budget item`,
  props<{ replacedParentId?: string, childrenToMove?: string[] }>());
export const editEmptyBudgetItem = createAction(`${budgetKey} Edit empty budget item`, props<{ propertyName: string, value: any }>());

export const createBudgetItem = createAction(`${budgetKey} Create budget item`, props<{ changedBudgetItems: ChangedBudgetItems }>());
export const substituteBudgetItem = createAction(
  `${budgetKey} Substitute budget item`,
  props<{ changedBudgetItems: ChangedBudgetItems }>());

export const setCanDeleteCustomItem = createAction(`${budgetKey} Set can delete custom item`, props<{ canDelete: boolean }>());

export const moveBudgetItemsStart = createAction(`${budgetKey} Move budget items start`,
  props<MoveItemsActionModel &
  { isUndoRedoOperation?: boolean, isUndo?: boolean }>());
export const moveBudgetItemsSuccess = createAction(
  `${budgetKey} Move budget items success`,
  props<{
    changedBudgetItems: ChangedBudgetItems,
    sourceDataPositions: MoveItemsPositions<BudgetItem>,
    storedAction: MoveItemsActionModel,
    isUndoRedoOperation?: boolean,
    isUndo: boolean
  }>());
export const moveBudgetItemsError = createAction(`${budgetKey} Move budget items error`, props<{ error: any }>());

export const setSelectedBudgetItems = createAction(
  `${budgetKey} Set selected budget items`,
  props<{ selectedItems: BudgetItem[] }>());

export const loadAndCheckAllChildrenStart = createAction(
  `${budgetKey} Load and check all children start`,
  props<{ itemIds: string[] }>());

export const setIndexListStart = createAction(`${budgetKey} Set index list start`,
  props<{ occurrenceItems: OccurrenceItemIndex[], isUndo: boolean, isUndoRedoOperation: boolean }>());
export const setIndexListSuccess = createAction(`${budgetKey} Set index list success`,
  props<{
    changedBudgetItems: ChangedBudgetItems,
    occurrenceItems: OccurrenceItemIndex[],
    isUndo: boolean,
    isUndoRedoOperation: boolean
  }>());
export const setIndexListError = createAction(`${budgetKey} Set index list error`, props<{ error: any }>());

export const setIsDeletedStart = createAction(
  `${budgetKey} Set is deleted start`,
  props<{
    itemIds: number[],
    isDeleted: boolean,
    isUndo: boolean,
    isUndoRedoOperation: boolean,
    newId: string,
    previousBuildingObjectId?: string
  }>());
export const setIsDeletedSuccess = createAction(
  `${budgetKey} Set is deleted success`,
  props<{ changedBudgetItems: ChangedBudgetItems, canSetHasChildren: boolean, isUndo: boolean, isUndoRedoOperation: boolean }>());
export const setIsDeletedError = createAction(`${budgetKey} Set is deleted error`, props<{ error: any }>());

export const setColorCodeStart = createAction(
  `${budgetKey} Set color codes start`,
  props<{ colorCode?: string, oldBudgetItems?: BudgetItem[], isUndo: boolean, isUndoRedoOperation: boolean }>());
export const setColorCodeSuccess = createAction(
  `${budgetKey} Set color codes success`,
  props<{ changedBudgetItems: ChangedBudgetItems, oldBudgetItems: BudgetItem[], isUndo: boolean, isUndoRedoOperation: boolean }>());
export const setColorCodeError = createAction(`${budgetKey} Set color codes error`, props<{ error: any }>());

export const searchStart = createAction(
  `${budgetKey} Search start`,
  props<{ params: Params, isUndoRedoOperation: boolean, isUndo: boolean }>());
export const searchSuccess = createAction(
  `${budgetKey} Search success`,
  props<{ params: Params, oldParams: Params, isUndoRedoOperation: boolean, isUndo: boolean }>());

export const setShouldRecalculateBoqs = createAction(`${budgetKey} Set should recalculate boqs`);

export const resetIsInitialized = createAction(`${budgetKey} Reset is initialized`);

export const reloadQuantityManagerBoq = createAction(
  `${budgetKey} Reload quantity manager boq`,
  props<{ changedBudgetItems: ChangedBudgetItems }>());

export const undoCreateCustomBudgetSectionStart = createAction(
  `${budgetKey} Undo create custom budget section start`,
  props<{ previousItemId: string, tableItems: BudgetItem[], sectionId: number }>());
export const undoCreateCustomBudgetSectionInProgress = createAction(
  `${budgetKey} Undo create custom budget section in progress`,
  props<{ moveChangedBudgetItems: ChangedBudgetItems, previousItemId: string, sectionId: number }>());
export const redoCreateCustomBudgetSectionStart = createAction(
  `${budgetKey} Redo create custom budget section start`,
  props<{ previousItemId: string, tableItems: BudgetItem[], sectionId: number }>());
export const undoRedoCreateCustomBudgetSectionSuccess = createAction(
  `${budgetKey} Undo/Redo create custom budget section success`,
  props<{ changedBudgetItems: ChangedBudgetItems, occurrenceItemId: string }>());
export const undoRedoCreateCustomBudgetSectionError = createAction(
  `${budgetKey} Undo/Redo create custom budget section error`,
  props<{ error: any }>());

export const renumberItemsStart = createAction(`${budgetKey} Renumber items start`);
export const renumberItemsSuccess = createAction(`${budgetKey} Renumber items success`, props<{ editedItems: EditedBudgetItem[] }>());
export const renumberItemsEmptySuccess = createAction(`${budgetKey} Renumber items empty success`);
export const renumberItemsError = createAction(`${budgetKey} Renumber items error`, props<{ error: any }>());
