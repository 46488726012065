import { ChangeDetectionStrategy, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'kros-announcekit',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './announce-kit.component.html'
})
export class AnnounceKitComponent implements OnInit, OnDestroy {
  @Input() url: string;
  @Input() appName: string;
  @Input() companyId?: number;
  @Input() userId?: string;
  @Input() licenseType?: string;
  @Input() isLicenseValid?: boolean;
  @Input() daysValid?: number;
  @Input() lang?: string;
  @Input() modul?: string;
  @Input() isTest?: string;
  @Input() hasNumberOfAnnoucments = false;

  private defaultWidgetWithNumberStyle = {
    width: '1.4rem',
    height: '1.4rem',
    padding: '0',
    'vertical-align': 'super',
    'margin-left': '-1rem',
    top: '-0.4rem',
    background: '#fff',
    'border-radius': '50%',
  };

  private defaultWidgetStyle = {
    width: '20px',
    height: '20px',
    padding: '0 0.4rem 0.4rem 0.4rem',
    'vertical-align': 'text-top',
    'margin-left': '-1.4rem',
    background: '#fff',
    'border-radius': '50%',
  };

  widgetStyle = null;

  private readonly window = inject(DOCUMENT).defaultView;

  ngOnInit(): void {
    this.widgetStyle = this.hasNumberOfAnnoucments ? this.defaultWidgetWithNumberStyle : this.defaultWidgetStyle;
  }

  ngOnDestroy(): void {
    this.window['announcekit'] = undefined;
  }
}
