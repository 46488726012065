<div class="filter-container">
  <button
    class="btn btn-link btn-home ga-default-view"
    type="button"
    krosTooltip="{{ 'BUILDING_PROGRESS.VIEWS.DEFAULT_VIEW' | translate }}"
    (click)="onDefaultViewClick()"
    data-test="default-view-button"
  >
    <i class="material-icons ga-default-view">home</i>
  </button>

  <ng-container *ngTemplateOutlet="leftContent"></ng-container>

  <div class="empty-space"></div>

  <ng-container *ngTemplateOutlet="rightContent"></ng-container>
</div>
