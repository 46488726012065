<dx-data-grid
  #dataDataGridRef
  id="table"
  class="dx-data-grid approval-order-table"
  data-test="approval-order-table"
  keyExpr="id"
  [dataSource]="dataSource"
  [showBorders]="true"
  [showColumnLines]="true"
  [showRowLines]="true"
  [allowColumnReordering]="false"
  [allowColumnResizing]="true"
  [columnResizingMode]="'widget'"
  [columnAutoWidth]="true"
  [hoverStateEnabled]="true"
  [wordWrapEnabled]="true"
  [disabled]="isReadOnly"
  [loadPanel]="{ text: 'COMMON.PREPARING_DATA' | translate }"
>
  <dxo-scrolling mode="standard"></dxo-scrolling>

  <dxo-row-dragging
    [allowReordering]="true"
    [onReorder]="onReorder"
    [showDragIcons]="true"
  ></dxo-row-dragging>

  <dxo-keyboard-navigation
    [enterKeyAction]="'moveFocus'"
    [enterKeyDirection]="'row'"
  ></dxo-keyboard-navigation>

  <dxi-column
    [caption]="'SHARING.BUILDING_PROGRESS.APPROVER_ORDER' | translate"
    dataField="order"
    dataType="number"
    alignment="center"
    width="60"
    cssClass="order-column"
    [allowEditing]="false"
    [allowSorting]="false"
  >
  </dxi-column>

  <dxi-column
    [caption]="'SHARING.BUILDING_PROGRESS.APPROVER_EMAIL' | translate"
    dataField="email"
    dataType="string"
    cssClass="email-column"
    [allowSorting]="false"
    [allowEditing]="false"
  >
  </dxi-column>
</dx-data-grid>
