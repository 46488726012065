<div>
  <div
    *ngFor="let unconfirmedSharing of unconfirmedSharings"
    class="sharing-item"
  >
    <ng-container [ngSwitch]="sharingMode">
      <ng-container *ngSwitchCase="newSharingComboMode.EditUserProject">
        <kros-user-sharing-list-item-multiple
          [unconfirmedSharing]="true"
          [itemSharing]="unconfirmedSharing"
          [sharingMode]="sharingMode"
          (deletedPermission)="onDeletedPermission($event)"
          (unconfirmedPermissionDeleted)="onUnconfirmedPermissionDeleted($event)"
          (changedPermission)="onChangedPermission($event)"
        ></kros-user-sharing-list-item-multiple>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <kros-user-sharing-list-item
          [unconfirmedSharing]="true"
          [itemSharing]="unconfirmedSharing"
          [sharingMode]="sharingMode"
          (deletedPermission)="onDeletedPermission($event)"
          (unconfirmedPermissionDeleted)="onUnconfirmedPermissionDeleted($event)"
          (changedPermission)="onChangedPermission($event)"
        ></kros-user-sharing-list-item>
      </ng-container>
    </ng-container>
  </div>

  <div
    *ngFor="let sharing of filterConfirmedSharings"
    class="sharing-item"
  >
    <ng-container [ngSwitch]="sharingMode">
      <ng-container *ngSwitchCase="newSharingComboMode.EditUserProject">
        <kros-user-sharing-list-item-multiple
          [itemSharing]="sharing"
          [sharingMode]="sharingMode"
          [readonly]="readonly"
          (deletedPermission)="onDeletedPermission($event)"
          (changedPermission)="onChangedPermission($event)"
        ></kros-user-sharing-list-item-multiple>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <kros-user-sharing-list-item
          [itemSharing]="sharing"
          [sharingMode]="sharingMode"
          [readonly]="readonly"
          (deletedPermission)="onDeletedPermission($event)"
          (changedPermission)="onChangedPermission($event)"
        ></kros-user-sharing-list-item>
      </ng-container>
    </ng-container>
  </div>
</div>
