import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { filter } from 'rxjs';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { AuthSelectorsService } from '@kros-sk/auth';
import { BudgetSettings, TimelineType } from '@kros-sk/ssw-shared-legacy';
import { LicenseSelectorsService, LicenseType } from '@kros-sk/ssw-cdk';

import { BudgetSettingService, SettingService } from '../../services';

@Component({
  selector: 'ssw-budget-setting',
  templateUrl: './budget-setting.component.html',
  styleUrls: ['./budget-setting.component.scss']
})
export class BudgetSettingsComponent implements OnInit {

  protected authSelectorsService = inject(AuthSelectorsService);
  private appInsightsService = inject(AppInsightsBaseService);
  private budgetSettingService = inject(BudgetSettingService);
  private destroyRef = inject(DestroyRef);
  private formBuilder = inject(UntypedFormBuilder);
  private licenseSelectorsService = inject(LicenseSelectorsService);
  private settingService = inject(SettingService);

  round_range = [0, 1, 2, 3, 4, 5];
  isEditedBudgetRate = false;
  isEditedRoundFormat = false;
  timelineType = TimelineType;
  settings: BudgetSettings;
  settingsForm: UntypedFormGroup;

  get isOpenedProject(): boolean {
    return !!this.settingService.projectId;
  }

  get routerLink(): string {
    return this.settingService.previousUrl;
  }

  get isExist(): boolean {
    return this.budgetSettingService.isExist;
  }

  get hasPermission(): boolean {
    return this.budgetSettingService.hasPermission;
  }

  get canSaveSettings(): boolean {
    return this.budgetSettingService.canSaveSettings && this.isTrialOrFullLicense;
  }

  get budgetSettingsIsLoading(): boolean {
    return this.budgetSettingService.isLoading;
  }

  private isTrialOrFullLicense = true;

  ngOnInit(): void {
    this.appInsightsService.trackEvent('B-open-settings');
    this.initializeForm();

    this.licenseSelectorsService.budgetLicense$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(l => this.isTrialOrFullLicense = !!l && (l.licenseType === LicenseType.Trial || l.licenseType === LicenseType.Full));
    this.budgetSettingService.budgetSettings$
      .pipe(
        filter(s => !!s),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(settings => {
        this.settings = settings;
        this.fillForm();
      });
  }

  handleInput(event: any): void {
    event.target.value = event.target.value.replace(/\./g, ',');
    this.isEditedBudgetRate = true;
  }

  onSave(): void {
    const settings: BudgetSettings = {
      basicVatRate: this.settingsForm.value.basicVatRate,
      currencyCode: this.settingsForm.value.currencyCode,
      reducedVatRate: this.settingsForm.value.reducedVatRate,
      amountDecimalPlacesCount: this.settingsForm.value.amountDecimalPlacesCount,
      unitPriceDecimalPlacesCount: this.settingsForm.value.unitPriceDecimalPlacesCount,
      totalPriceDecimalPlacesCount: this.settingsForm.value.totalPriceDecimalPlacesCount
    };
    this.logStatistics();
    this.budgetSettingService.setBudgetSettings(settings)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  private initializeForm(): void {
    this.settingsForm = this.formBuilder.group(
      {
        currencyCode: this.settings?.currencyCode,
        basicVatRate: this.settings?.basicVatRate,
        reducedVatRate: this.settings?.reducedVatRate,
        amountDecimalPlacesCount: this.settings?.amountDecimalPlacesCount,
        unitPriceDecimalPlacesCount: this.settings?.unitPriceDecimalPlacesCount,
        totalPriceDecimalPlacesCount: this.settings?.totalPriceDecimalPlacesCount
      }
    );

    this.settingsForm.controls.amountDecimalPlacesCount.valueChanges
      .pipe(
        filter(v => v && !this.isEditedRoundFormat),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(v => this.isEditedRoundFormat = this.settings?.amountDecimalPlacesCount !== v);

    this.settingsForm.controls.unitPriceDecimalPlacesCount.valueChanges
      .pipe(
        filter(v => v && !this.isEditedRoundFormat),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(v => this.isEditedRoundFormat = this.settings?.unitPriceDecimalPlacesCount !== v);

    this.settingsForm.controls.totalPriceDecimalPlacesCount.valueChanges
      .pipe(
        filter(v => v && !this.isEditedRoundFormat),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(v => this.isEditedRoundFormat = this.settings?.totalPriceDecimalPlacesCount !== v);
  }

  private fillForm(): void {
    this.settingsForm.patchValue({
      currencyCode: this.settings?.currencyCode,
      basicVatRate: this.settings?.basicVatRate,
      reducedVatRate: this.settings?.reducedVatRate,
      amountDecimalPlacesCount: this.settings?.amountDecimalPlacesCount,
      unitPriceDecimalPlacesCount: this.settings?.unitPriceDecimalPlacesCount,
      totalPriceDecimalPlacesCount: this.settings?.totalPriceDecimalPlacesCount
    });
    if (!this.canSaveSettings) {
      this.settingsForm.disable();
    }
  }

  private logStatistics(): void {
    if (this.isEditedBudgetRate) {
      this.appInsightsService.trackEvent('B-edit-budget-rate');
    }
    if (this.isEditedRoundFormat) {
      this.appInsightsService.trackEvent('B-edit-round-format');
    }
    this.appInsightsService.trackEvent('B-save-budget-settings');
    this.isEditedBudgetRate = false;
    this.isEditedRoundFormat = false;
  }
}
