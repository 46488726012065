<div
  class="avatar no-selection"
  krosTooltip="{{email}}"
>
  <ng-container *ngIf="!icon; else iconAvatar">
    <kros-avatar
      class="avatar-text avatar-base-text"
      [options]="{ text: emailFirstLetter }"
    ></kros-avatar>
  </ng-container>
</div>

<ng-template #iconAvatar>
  <i class="material-icons">{{ icon }}</i>
</ng-template>
