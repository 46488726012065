<div class="periods-container">
  <app-periods
    [editMode]="editMode"
    [searchModel]="searchModel"
    [projectId]="projectId"
    [permission]="permission"
    [readonly]="readonly"
    [newPeriodStart]="newPeriodStart"
    (editModeChanged)="onEditModeChanged($event)"
    (periodViewClicked)="onPeriodViewClicked($event)"
  ></app-periods>

  <button
    *ngIf="canAddPeriod"
    id="ga-period-add-mobile"
    class="btn btn-primary btn-add"
    type="button"
    (click)="onNewPeriod()"
    data-test="building-progress-new-period-mobile"
  >
    {{ 'BUILDING_PROGRESS.PERIODS.NEW' | translate }}
  </button>
</div>
