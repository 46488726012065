import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  OnInit,
  QueryList,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { emptyCompany, KrosCompany } from '@kros-sk/models';
import { VatIdValidationService } from '@kros-sk/auth';

import { CompanySearchService } from '../services/company-search/company-search.service';
import { DeviceDetectorService } from '../../services';
import { KROS_ERROR, KrosError, KrosFormsService } from '../../inputs';
import { SearchBase } from './search-base';
import { searchErrorHandler } from '../../search-error-handling/search-error-handler';

@Component({
  selector: 'kros-company-search',
  templateUrl: './company-search.component.html',
  styleUrls: ['./company-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CompanySearchComponent,
      multi: true,
    }],
})
export class CompanySearchComponent extends SearchBase implements OnInit, AfterContentInit {
  @Input() maxLength = 255;

  @ContentChildren(KROS_ERROR, { descendants: true }) _errorChildren: QueryList<KrosError>;

  constructor(
    protected deviceDetectorService: DeviceDetectorService,
    protected formService: KrosFormsService,
    protected vatIdValidationService: VatIdValidationService,
    private searchService: CompanySearchService,
    protected translate: TranslateService
  ) {
    super(deviceDetectorService, formService, vatIdValidationService, translate);
  }

  ngOnInit(): void {
    this.partnerNameMaxLength = this.maxLength;
    super.ngOnInit();
  }

  ngAfterContentInit(): void {
    this.setOptionsError(this._errorChildren);
  }

  protected getSearchResults(searchTerm: string): Observable<KrosCompany[]> {
    if (this.addNewPartner) {
      return this.searchService.searchInPartnersWithCompanyId(searchTerm, 15, 0).pipe(
        tap(() => this.showSearchIndicatorSubject.next(false)),
        searchErrorHandler<KrosCompany>(emptyCompany())
      );
    } else {
      return this.searchService.searchInPartners(searchTerm, 15, 0).pipe(
        tap(() => this.showSearchIndicatorSubject.next(false)),
        searchErrorHandler<KrosCompany>(emptyCompany())
      );
    }
  }
}
