export const ContentTypes = {
  drawings: [
    'image/vnd.dwg'
  ],
  images: [
    'image/bmp',
    'image/gif',
    'image/jpeg',
    'image/png'
  ],
  ifc: 'application/ifc',
  pdf: 'application/pdf',
  zip: 'application/zip',
  construction: 'application/construction'
};
