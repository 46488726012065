<div
  class="header"
  (click)="navigate()"
>
  <div class="header-info">
    <div class="arrow-wrapper">
      <i class="material-icons arrow">{{isOpened ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}</i>
    </div>
    <div class="sub-name">{{subscription.name}}</div>
  </div>
  <div class="right-side">
    <div
      class="tooltip-wrapper"
      krosTooltip="{{ 'COMMON.DELETE' | translate }}"
      *ngIf="isOpened && subscription.clientId"
    >
      <button
        class="btn btn-light delete-btn"
        type="button"
        data-test="delete-button"
        (click)="delete($event)"
      >
        <i class="material-icons">delete</i>
      </button>
    </div>
  </div>
</div>
