import { Component, inject, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { APP_CONFIG } from '@kros-sk/app-config';
import { Partner } from '@kros-sk/models';

@Component({
  selector: 'kros-project-header-data-panel',
  templateUrl: './project-header-data-panel.component.html',
  styleUrls: ['./project-header-data-panel.component.scss']
})
export class ProjectHeaderDataPanelComponent implements OnInit {
  @Input() parentForm: UntypedFormGroup;
  @Input() disabled: boolean;
  @Input() showPlace = true;

  private appConfig = inject(APP_CONFIG);

  companies = ['customer', 'contractor', 'author', 'designer'];

  get canSearch(): boolean {
    return this.appConfig.location === 'sk';
  }

  ngOnInit(): void {
    if (!this.parentForm.contains('place')) {
      this.parentForm.addControl('place',
        new UntypedFormControl({ value: '', disabled: this.disabled }, Validators.maxLength(50)));
    }
    this.enableOrDisableControl('place');

    this.companies.forEach(company => {
      if (!this.parentForm.contains(`${company}Name`)) {
        this.parentForm.addControl(`${company}Name`,
          new UntypedFormControl({ value: '', disabled: this.disabled }, Validators.maxLength(100)));
      }
      this.enableOrDisableControl(`${company}Name`);

      if (!this.parentForm.contains(`${company}Id`)) {
        this.parentForm.addControl(`${company}Id`,
          new UntypedFormControl({ value: '', disabled: this.disabled }, Validators.maxLength(20)));
      }
      this.enableOrDisableControl(`${company}Id`);

      if (!this.parentForm.contains(`${company}VatId`)) {
        this.parentForm.addControl(`${company}VatId`,
          new UntypedFormControl({ value: '', disabled: this.disabled }, Validators.maxLength(20)));
      }
      this.enableOrDisableControl(`${company}VatId`);
    });

    if (!this.parentForm.contains('constructionManager')) {
      this.parentForm.addControl('constructionManager',
        new UntypedFormControl({ value: '', disabled: this.disabled }, Validators.maxLength(100)));
    }
    this.enableOrDisableControl('constructionManager');
  }

  onSelectedPartner(company: string, newCompanyData: Partner): void {
    if (newCompanyData?.id !== undefined && newCompanyData.id !== -1) {
      this.parentForm.controls[`${company}Id`].setValue(newCompanyData.registrationId);
      this.parentForm.controls[`${company}VatId`].setValue(newCompanyData.vatId);
    }
  }

  private enableOrDisableControl(controlName: string): void {
    const control = this.parentForm.controls[controlName];
    if (this.disabled) {
      control.disable();
    } else {
      control.enable();
    }
  }
}
