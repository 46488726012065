import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ColDef } from '../data-table-config.model';

@Component({
  selector: 'kros-column-filter',
  templateUrl: './column-filter.component.html',
  styleUrls: ['./column-filter.component.scss']
})
export class ColumnFilterComponent {

  @Input() filterColumns: ColDef[];
  @Input() dropdonwTitleAllColumns: string;
  @Input() dropdownTitleColumnSelection: string;
  @Input() ungroupedColumnsLabel: string;
  @Input() allowGroupCheck = true;
  @Input() horizontal = false;

  @Output() columnVisibilityChanged = new EventEmitter<string[]>();
  @Output() filterClosed = new EventEmitter<string[]>();

  get dropdownTitle(): string {
    if (this.getHiddenColumns().length > 0) {
      return this.dropdownTitleColumnSelection;
    } else {
      return this.dropdonwTitleAllColumns;
    }
  }

  get hasUngroupedFilterColumns(): boolean {
    return this.filterColumns.some(column => !column.colsInGroup);
  }

  constructor() { }

  onGroupVisibilityChange(colDef: ColDef): void {
    this.toggleColumnVisibility(colDef);
    colDef.colsInGroup?.forEach(c => {
      c.isHidden = colDef.isHidden;
    });
    this.emitVisibilityChange();
  }

  onColumnVisibilityChange(colDef: ColDef, parentCol?: ColDef): void {
    this.toggleColumnVisibility(colDef);
    if (parentCol) {
      this.setParentVisibility(parentCol);
    }
    this.emitVisibilityChange();
  }

  onDropdownOpenChange(opened: boolean): void {
    if (!opened) {
      this.filterClosed.emit(this.getHiddenColumns());
    }
  }

  private emitVisibilityChange(): void {
    const hiddenColumns: string[] = this.getHiddenColumns();
    this.columnVisibilityChanged.emit(hiddenColumns);
  }

  private setParentVisibility(colDef: ColDef): void {
    const numOfHidden: number = colDef.colsInGroup?.filter(c => c.isHidden).length;
    colDef.isHidden = numOfHidden === colDef.colsInGroup.length ? true : false;
  }

  private toggleColumnVisibility(colDef: ColDef): void {
    colDef.isHidden = !colDef.isHidden;
  }

  private getHiddenColumns(): string[] {
    const hiddenGroups: string[] = this.filterColumns.filter(c => c.isHidden).map(c => c.id);
    let hiddenCols: string[] = [];
    this.filterColumns.forEach(c => {
      if (c.colsInGroup) {
        if (c.isHidden) {
          hiddenCols = hiddenCols.concat(c.colsInGroup.map(c => c.id));
        } else {
          hiddenCols = hiddenCols.concat(c.colsInGroup.filter(c => c.isHidden).map(c => c.id));
        }
      }
    });
    return hiddenGroups.concat(hiddenCols);
  }
}
