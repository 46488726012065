import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, map, Observable, of, switchMap, throwError } from 'rxjs';

import { PermissionType } from '@kros-sk/ssw-shared/permission';
import { Project, ProjectDetail, Tag, TagEditModel, TranslateService } from '@kros-sk/ssw-shared-legacy';

import { environment } from '../../../environments/environment';
import { ProjectSharingService } from './project-sharing.service';
import { ProjectState } from '../models';

const gatewayApi = '/api/projects/';
const projectService = '/projectService';
const projectListEndpoint = '/projectExList';
const projectDetailEndpoint = '/projects/projectDetail';
const projectEditEndpoint = '/projects/projectDetailEdit';
const projectCopyEndpoint = '/projectCopy';
const projectDeleteEndpoint = '/projectDelete/';
const projectTagListSetEndpoint = '/tagListSet';
const projectStateEndpoint = '/projects/projectState';
const tagsListEndpoint = '/list';

@Injectable()
export class ProjectsService {

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private projectSharingService: ProjectSharingService,
  ) { }

  private get projectApi(): string {
    return environment.appUrls.titanGatewayUrl + '/api/projectService/Projects';
  }

  private get tagsApi(): string {
    return environment.appUrls.titanGatewayUrl + '/api/projectService/Tags';
  }

  getProjects(tagIds?: number[]): Observable<Project[]> {
    let params: HttpParams = new HttpParams();
    if (tagIds) {
      tagIds.forEach(tag => params = params.append('tagId', tag.toString()));
    }
    return this.http.get<Project[]>(this.projectApi + projectListEndpoint, { params }).pipe(
      switchMap(projects => projects.length > 0 ? this.getProjectsWithSharings(projects) : of([]))
    );
  }

  getProjectDetail(projectId: number, contractorId?: number): Observable<ProjectDetail> {
    return this.http.get<ProjectDetail>(this.getEndpointPath(projectId, projectDetailEndpoint, contractorId));
  }

  copyProject(project: Project): Observable<Project> {
    return this.http.post(`${this.projectApi}${projectCopyEndpoint}/${project.id}`, {}).pipe(
      catchError(this.handleError.bind(this)));
  }

  editProject(project: ProjectDetail, contractorId?: number): Observable<ProjectDetail> {
    return this.http.put(this.getEndpointPath(project.id, projectEditEndpoint, contractorId), project).pipe(
      map(() => project),
      catchError(this.handleError.bind(this)));
  }

  deleteProject(projectId: number): Observable<any> {
    return this.http.delete(this.projectApi + projectDeleteEndpoint + projectId).pipe(catchError(this.handleError.bind(this)));
  }

  getProjectState(projectId: number): Observable<ProjectState> {
    return this.http.get(this.getEndpointPath(projectId, projectStateEndpoint)).pipe(catchError(this.handleError.bind(this)));
  }

  getTagsList(): Observable<Tag[]> {
    return this.http.get<Tag[]>(this.tagsApi + tagsListEndpoint);
  }

  editProjectTag(tagEditModel: TagEditModel): Observable<any> {
    return this.http.patch(this.projectApi + projectTagListSetEndpoint, tagEditModel).pipe(
      catchError(this.handleError.bind(this)));
  }

  private getProjectsWithSharings(projects: Project[]): Observable<Project[]> {
    const projectIds = projects.map(p => p.id);
    return this.projectSharingService.getProjectsUserSharingList(projectIds).pipe(
      map(sharings =>
        projects.map(project => {
          const projectUserSharing = sharings.find(sharing => sharing.projectId === project.id);
          return {
            ...project,
            sharings: !projectUserSharing ? [] : projectUserSharing.sharings
              .filter(sharing => sharing.permissionType !== PermissionType.Owner)
              .map(sharing => ({ email: sharing.email, isRegistered: sharing.isRegisteredUser }))
          };
        })
      )
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      let errorMessage: string;

      switch (error.status) {
        case 403:
          errorMessage = this.translateService.translate('COMMON.NEDOSTATOCNE_PRAVA');
          break;

        case 404:
          errorMessage = this.translateService.translate('PROJECTS.ERROR.PROJECT_NOT_EXISTS');
          break;
      }

      console.error(
        `Backend returned code ${error.status}, ` + `error message: ${errorMessage}, ` + `body was: ${error.error}`
      );

      return throwError(() => new Error(errorMessage));
    }
    // return an observable with a user-facing error message
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  private getEndpointPath(projectId: number, endpoint: string, contractorId?: number): string {
    if (contractorId) {
      return environment.appUrls.titanGatewayUrl + gatewayApi + projectId + `/contractors/${contractorId}` + projectService + endpoint;
    }
    return environment.appUrls.titanGatewayUrl + gatewayApi + projectId + projectService + endpoint;
  }
}
