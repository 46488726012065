import { inject, Injectable } from '@angular/core';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { License } from '@kros-sk/ssw-cdk';

@Injectable()
export class AppInsightsUserContextHelper {

  private appInsightsService = inject(AppInsightsBaseService, { optional: true });

  initUserContext(license: License): void {
    this.appInsightsService?.setAuthenticatedUserContext('' + license.userId, license.licenseType.toString());
  }

  resetUserContext(license: License): void {
    if (license) {
      this.appInsightsService?.clearAuthenticatedUserContext();
      this.appInsightsService?.setAuthenticatedUserContext('' + license.userId);
    }
  }
}
