import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  forwardRef,
  HostBinding,
  OnDestroy,
  QueryList,
  TemplateRef
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { debounceTime, filter } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { createRotatedStateAnimation } from '@kros-sk/animations';

import { DeviceDetectorService, DeviceType } from '../../../services';
import { KrosModalRef, KrosModalService } from '../../../kros-modal';

@Component({
  selector: 'kros-menu-item',
  templateUrl: './menu-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [createRotatedStateAnimation(-180)]
})
export class KrosMenuItemComponent implements AfterContentInit, OnDestroy {
  @HostBinding('class.main-menu-item') alwaysTrue = true;
  @HostBinding('class.multiple') get isMultiple(): boolean {
    return !!this.content;
  }

  @ContentChildren(forwardRef(() => KrosMenuItemComponent), { descendants: true })
    items: QueryList<KrosMenuItemComponent>;

  activeChild = false;
  content: TemplateRef<void>;
  mobileSubmenuState : 'expanded' | 'collapsed' = 'collapsed';

  private openedModal: KrosModalRef;
  private lastItems: KrosMenuItemComponent[] = [];
  private subs = new SubSink();
  private deviceType: DeviceType;

  constructor(
    private host: ElementRef,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private modalService: KrosModalService,
    private deviceDetector: DeviceDetectorService
  ) { }

  ngAfterContentInit(): void {
    if (this.content) {
      this.subs.sink = this.deviceDetector.deviceType$.subscribe(
        (type) => {
          if (type === DeviceType.Mobile) {
            this.closeModal();
          }

          this.deviceType = type;
          this.detectChanges();
        }
      );
      this.subs.sink = this.items.changes.pipe(
        debounceTime(100)
      ).subscribe((items) => {
        this.lastItems = items.toArray();
        this.setActiveLink();
      });
      this.subs.sink = this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        debounceTime(100) // needed to wait while routerLinkActive directive is applied
      ).subscribe(() => {
        this.setActiveLink();
        this.closeModal();
      });
    }
  }

  ngOnDestroy(): void {
    this.closeModal();
    this.subs.unsubscribe();
  }

  get isActive(): boolean {
    return this.host.nativeElement.classList.contains('main-active-link');
  }

  get isMobile(): boolean {
    return this.deviceType === DeviceType.Mobile;
  }

  get isCollapsed(): boolean {
    return this.mobileSubmenuState === 'collapsed';
  }

  openDesktopSubMenu(): void {
    this.closeModal();
    this.openedModal = this.modalService.openConnectedToElementCustomPositioned(
      this.content,
      this.host.nativeElement,
      [
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ],
      null,
      null,
      'menu-dropdown',
      {
        addModalToBrowsersHistory: false,
        allowFocusAutocapture: false,
        fullscreenOnMobile: false,
        showMobileArrowBack: false,
      });
  }

  toggleMobileSubMenu(): void {
    this.mobileSubmenuState = this.mobileSubmenuState === 'expanded' ? 'collapsed' : 'expanded';
  }

  private setActiveLink(): void {
    this.activeChild = this.lastItems.reduce((active, item) => active || item.isActive, false);
    if (this.isMobile && this.activeChild) {
      this.mobileSubmenuState = 'expanded';
    }
    this.detectChanges();
  }

  private closeModal(): void {
    if (this.openedModal) {
      this.openedModal.cancel();
      this.openedModal = null;
    }
  }

  private detectChanges(): void {
    if (!this.cdRef['destroyed']) {
      this.cdRef.detectChanges();
    }
  }
}
