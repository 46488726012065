<div class="table-div alternate-row-colors">
  <table
    class="table"
    cdk-table
    recycleRows
    [dataSource]="detail.constructionData?.items"
    [trackBy]="trackBy"
  >
    <ng-container cdkColumnDef="description">
      <th
        cdk-header-cell
        *cdkHeaderCellDef
        id="description"
      >
        <div
          class="header-content"
          (click)="onDescriptionHeaderClick()"
        >
          <input
            *ngIf="showSelection"
            class="checkbox"
            type="checkbox"
            [checked]="isHeaderCheckChecked()"
            [krosIndeterminate]="isHeaderCheckIndeterminate()"
            data-test="check-all-rows"
          >
          <span>{{ 'BUILDING_PROGRESS.TABLE.DESCRIPTION' | translate | uppercase }}</span>
        </div>
      </th>
      <td
        cdk-cell
        *cdkCellDef="let item"
      >
        <div
          data-test="mobile-row-description"
          [ngClass]="getDescriptionClass(item)"
          [innerHTML]="item.description | safeHtml"
        ></div>

        <div
          class="selection-and-additional-info"
          [class.item]="!isSummaryItem(item)"
        >
          <input
            *ngIf="showSelection"
            class="checkbox"
            type="checkbox"
            [checked]="isItemCheckChecked(item)"
            [krosIndeterminate]="isItemCheckIndeterminate(item)"
            data-test="check-row"
          >

          <div
            class="code"
            data-test="mobile-row-code"
            [innerHTML]="item.code | safeHtml"
          ></div>

          <div
            class="item-number"
            *ngIf="!isSummaryItem(item)"
          >
            {{ 'BUILDING_PROGRESS.TABLE.ITEM_NUMBER' | translate }}:&nbsp;
            <span data-test="mobile-row-item-number">{{ item.itemNumber }}</span>
          </div>

          <i
            *ngIf="hasComments(item)"
            class="comment-icon material-icons"
            data-test="mobile-row-comment-icon"
          >comment</i>
        </div>
      </td>
    </ng-container>

    <ng-container cdkColumnDef="price">
      <th
        cdk-header-cell
        *cdkHeaderCellDef
        id="price"
      >{{ 'BUILDING_PROGRESS.TABLE.PRICE' | translate | uppercase }}</th>
      <td
        cdk-cell
        *cdkCellDef="let item"
        class="text-right"
        data-test="mobile-row-total-price"
      > {{ detail.getSelectedPeriodItemTotalPrice(item, selectedPeriod?.id) }} </td>
    </ng-container>

    <tr
      cdk-header-row
      *cdkHeaderRowDef="columns"
    ></tr>
    <tr
      cdk-row
      *cdkRowDef="let item; columns: columns; let itemIndex = index"
      [class.font-weight-bold]="isSummaryItem(item)"
      [class.font-blue]="isConstructionOrBuildingObject(item)"
      [class.selected]="isItemCheckChecked(item)"
      [id]="'row-' + itemIndex"
      data-test="mobile-row"
      (click)="onItemClick(item)"
    ></tr>
  </table>
</div>

<div
  *ngIf="showDetail"
  class="item-detail"
>
  <app-building-progress-mobile-item
    [currentItem]="detail.constructionData?.items[currentItemIndex]"
    [selectedPeriod]="selectedPeriod"
    [decimalPlaces]="detail.constructionData?.decimalPlaces"
    (toggleDetail)="showDetail = false"
  ></app-building-progress-mobile-item>
</div>
