<kros-user-sharing-dialog-base
  [initialCompactMode]="initialCompactMode"
  [sharings]="sharings"
  [loadedItems]="loadedItems"
  [editItems]="editItems"
  [otherAlreadySharedEmails]="otherAlreadySharedEmails"
  [unconfirmedSharings]="unconfirmedSharings"
  [needRefresh]="needRefresh"
  [checkPermissionsBeforeSubmit]="checkPermissionsBeforeSubmitFunc"
  [submitEditedPermissions]="submitEditedPermissionsFunc"
  [shareNewPermissions]="shareNewPermissionsFunc"
  [getSharing]="getSharingFunc"
  [readonly]="readonly"
  [sharingMode]="sharingMode"
  [defaultPermissions]="defaultPermissions"
  [title]="title"
  [dataTestPrefix]="dataTestPrefix"
  (unconfirmedSharingChange)="onUnconfirmedSharingChange($event)"
  (submitSharing)="onSubmitSharing($event)"
>
  <ng-template #customButton>
    <div *ngIf="canShowApprovalOrder && isSomeApprover">
      <button
        class="btn btn-outline-primary approvers-order-button"
        (click)="onApprovalOrder()"
        [attr.data-test]="dataTestPrefix + '-approvalOrder'"
      >{{'SHARING.BUILDING_PROGRESS.APPROVERS_ORDER' | translate }}</button>
    </div>
  </ng-template>
</kros-user-sharing-dialog-base>
