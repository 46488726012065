import { EntityState } from '@ngrx/entity';

import { PermissionType } from '@kros-sk/ssw-shared/permission';

import {
  deletedDocumentsInitialState,
  deletedDocumentsVersionInitialState,
  documentListInitialState,
  documentVersionInitialState,
  folderHierarchyInitialState,
  markedDocumentsInitialState
} from './documents-explorer.adapters';
import { DocumentListInfoModel, DocumentVersionDto, FolderTreeNode, TemporaryUploadInfoModel } from '../models';

export interface DocumentsState {
  documentList: DocumentListState;
  isDocumentsListLoaded: boolean;
  rootDocumentPermission: PermissionType;
  markedDocuments: MarkedDocumentsState;
  currentFolder: FolderTreeNode;
  projectId: number;
  documentVersionList: DocumentVersionState;
  deletedDocuments: DeletedDocumentsState;
  deletedDocumentsVersion: DeletedDocumentsVersionState;
  folderHierarchy: FolderHierarchyState;
  uploadingData: TemporaryUploadInfoModel;
  showSharing: boolean;
}

export const initialDocumentsState: DocumentsState = {
  documentList: documentListInitialState,
  isDocumentsListLoaded: false,
  rootDocumentPermission: PermissionType.NoAccess,
  markedDocuments: markedDocumentsInitialState,
  currentFolder: undefined,
  projectId: undefined,
  documentVersionList: documentVersionInitialState,
  deletedDocuments: deletedDocumentsInitialState,
  deletedDocumentsVersion: deletedDocumentsVersionInitialState,
  folderHierarchy: folderHierarchyInitialState,
  uploadingData: undefined,
  showSharing: false
};

export interface DeletedDocument {
  docIndex: number;
  document: DocumentListInfoModel;
}

export interface DeletedDocumentVersion {
  docIndex: number;
  document: DocumentVersionDto;
}

export type DocumentListState = EntityState<DocumentListInfoModel>
export type MarkedDocumentsState = EntityState<string>
export type DocumentVersionState = EntityState<DocumentVersionDto>
export type DeletedDocumentsState = EntityState<DeletedDocument>
export type DeletedDocumentsVersionState = EntityState<DeletedDocumentVersion>
export type FolderHierarchyState = EntityState<FolderTreeNode>
