<div class="kros-modal-header">
  <div class="modal-title">
    <h4>{{ 'TAGS.NEW_TAG' | translate }}</h4>
    <span>({{ 'TAGS.DISPLAYED_WILL_BE_ONLY_TO_YOU' | translate }})</span>
  </div>
  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onCloseClick()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<form
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
>
  <div class="kros-modal-body">
    <div class="form-group">
      <label for="tagName">
        {{ 'TAGS.NAME' | translate }}
      </label>
      <input
        id="tagName"
        type="text"
        formControlName="tagName"
        class="form-control"
        data-test="tag-name-input"
        [maxLength]="25"
        [class.is-invalid]="tagName.invalid && tagName.touched"
      >
      <div
        *ngIf="tagName.errors?.alreadyExists"
        class="invalid-feedback"
      >
        {{ 'TAGS.ERROR.ALREADY_EXISTS' | translate }}
      </div>
    </div>

    <label>{{ 'TAGS.COLOR' | translate }}</label>
    <kros-color-palette
      [colors]="colors"
      [selectedColor]="selectedColor.colorCode"
      (colorSelect)="onColorSelect($event)"
    ></kros-color-palette>
  </div>

  <div class="kros-modal-footer">
    <button
      id="ga-new-tag-submit"
      class="btn btn-primary"
      type="submit"
      data-test="new-tag-submit"
      [disabled]="!form.valid"
    >{{ 'TAGS.CREATE' | translate }}</button>
  </div>
</form>
