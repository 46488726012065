import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-change-sheet-container',
  templateUrl: './change-sheet-container.component.html',
  styleUrls: ['./change-sheet-container.component.scss']
})
export class ChangeSheetContainerComponent {
  @Input() tableId: string;

  constructor() { }
}
