import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

import { SubSink } from 'subsink';

import { GlobalEventsService } from '../../services';
import { KrosModalCloseEvent, KrosModalRef } from './kros-modal-ref';

/**
 * Internal modal dialogs state for managing stack of displayed elements in the overlay container.
 * This allows showing multiple modal dialogs on top of each other.
 * Only for internal use by the KrosModalService. It should not be necessary to use this service in components.
 */
@Injectable({
  providedIn: 'root'
})
export class KrosModalStateService {

  private modalRefStack: KrosModalRef[] = [];
  private modalSubs = new SubSink();

  // temporary flags
  private closingUsingHistoryPopState = false;
  private removingTemporaryHistoryItem = false;

  constructor(
    private globalEvent: GlobalEventsService,
    private location: Location,
  ) {
    this.globalEvent.listenEvent('window:popstate').subscribe(
      () => this.onHistoryStepBack()
    );
    this.globalEvent.listenEvent('document:keyDown.escape').subscribe(
      () => this.onEscapeKeyPress()
    );
  }

  get isAnyModalWithHistoryOpened(): boolean {
    return this.modalRefStack.length > 0 && this.usingBrowserHistory;
  }

  isAnyModalOpened(): boolean {
    return this.modalRefStack.length > 0;
  }

  /**
   * Register the newly shown modal into internal stack.
   */
  attachedNewModal(modalRef: KrosModalRef): void {
    document.body.classList.add('modal-opened');

    this.hideAllPreviouslyShownModals();

    this.modalSubs.sink = modalRef.beforeClose$.subscribe((closeValue) => this.modalClosing(closeValue));
    this.modalSubs.sink = modalRef.afterClosed$.subscribe(() => this.afterModalClosed(modalRef));

    this.modalRefStack.push(modalRef);

    if (this.usingBrowserHistory) {
      const newUrl = this.location.path(false) + '#modal' + this.modalRefStack.length;
      history.pushState({}, window.document.title, newUrl);
    }
  }

  private modalClosing(
    closeValue: { type: KrosModalCloseEvent['type'], data?: any }
  ): void {
    if (this.usingBrowserHistory && !this.closingUsingHistoryPopState && (closeValue?.type !== 'redirect')) {
      this.removingTemporaryHistoryItem = true;
      this.location.back();
    }
  }

  private afterModalClosed(
    modalRef: KrosModalRef,
  ): void {
    // remove item from stack and verify closing order
    const removedModalRef = this.modalRefStack.pop();
    if (modalRef !== removedModalRef) {
      console.error('KrosModalStateService: KrosModalRef closed out of stack order!');
    }

    if (this.modalRefStack.length === 0) {
      document.body.classList.remove('modal-opened');
      this.modalSubs.unsubscribe();
    }

    this.closingUsingHistoryPopState = false;
    this.displayTopmostModal();
  }

  private get topmostModalRef(): KrosModalRef {
    return this.modalRefStack.length
      ? this.modalRefStack[this.modalRefStack.length - 1]
      : null;
  }

  // The Browser history behavior is defined by the first modal in a row.
  // The following modal displayed on top of the first one adapts to its settings.
  private get usingBrowserHistory(): boolean {
    return this.modalRefStack.length
      ? this.modalRefStack[0].settings.addModalToBrowsersHistory
      : false;
  }

  private hideAllPreviouslyShownModals(): void {
    // Hide modals by setting class with display: none
    this.modalRefStack.forEach(modal => {
      modal.overlay.backdropElement?.classList.add('d-none');
      modal.overlay.hostElement?.classList.add('d-none');
    });
  }

  private displayTopmostModal(): void {
    this.topmostModalRef?.overlay.backdropElement?.classList.remove('d-none');
    this.topmostModalRef?.overlay.hostElement?.classList.remove('d-none');
  }

  private onHistoryStepBack(): void {
    if (this.modalRefStack.length && this.usingBrowserHistory && !this.removingTemporaryHistoryItem) {
      // we are stepping back in history with the "history aware" dialog(s) shown. We close only the topmost one.
      this.closingUsingHistoryPopState = true;
      this.topmostModalRef.cancel();
    }
    if (this.modalRefStack.length && !this.usingBrowserHistory) {
      // we are stepping back in history with the simple dialog(s) shown. We close them all.
      this.modalRefStack.slice().reverse().forEach(item => {
        item.cancel();
      });
    }
    this.removingTemporaryHistoryItem = false;
  }

  private onEscapeKeyPress(): void {
    if (this.modalRefStack.length && this.topmostModalRef.settings.closeOnEscClick) {
      this.topmostModalRef.cancel();
    }
  }
}
