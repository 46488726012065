<div
  #table
  class="table-div"
  data-test="data-table"
>
  <cdk-virtual-scroll-viewport
    class="virtual-scroll"
    [itemHeight]="itemHeight"
    [minBufferPx]="itemHeight * 20"
    [maxBufferPx]="itemHeight * 20"
    [itemsOffset]="config?.itemsOffset"
  >
    <table
      id="table"
      class="table"
    >
      <thead>
        <tr *ngIf="hasColGroups()">
          <th
            *ngIf="config?.isMultiSelect"
            class="multi-select-column fixed"
          ></th>
          <ng-container *ngFor="let colDef of config?.colDefs; let last = last;">
            <ng-container
              *ngIf="isColumnVisible(colDef)"
              [ngSwitch]="colDef.type"
            >
              <th
                id="head-grouped-col-{{colDef.id}}"
                [attr.colId]="colDef.id"
                [ngClass]="getHeaderCellClass(colDef)"
                [ngStyle]="getColumnStyle(colDef, config.fixedWidth)"
                *ngSwitchCase="colType.Column"
              ></th>
              <ng-container *ngSwitchCase="colType.ColumnGroup">
                <th
                  *ngIf="isColumnGroupVisible(colDef)"
                  id="head-grouped-col-{{colDef.id}}"
                  [attr.colId]="colDef.id"
                  [ngClass]="getHeaderCellClass(colDef)"
                  [ngStyle]="getColumnStyle(colDef, config.fixedWidth)"
                  [attr.colspan]="getNumOfVisibleColumns(colDef)"
                >
                  <i
                    *ngIf="!!colDef.leftStatusIcon"
                    class="material-icons header-icon-left status-icon"
                    [class.header-icon-cursor]="!!colDef.leftStatusIcon.clickAction"
                    krosTooltip="{{colDef.leftStatusIcon.tooltip()}}"
                    (click)="onStatusIconClick(colDef.leftStatusIcon.clickAction)"
                    data-test="status-icon"
                  >{{colDef.leftStatusIcon.iconName()}}</i>
                  <ng-container *ngIf="colDef.rightStatusIcons">
                    <i
                      *ngFor="let rightStatusIcon of colDef.rightStatusIcons"
                      class="material-icons header-icon-right status-icon-{{rightStatusIcon.iconName()}}"
                      [class.header-icon-cursor]="!!rightStatusIcon.clickAction"
                      krosTooltip="{{rightStatusIcon.tooltip()}}"
                      (click)="onStatusIconClick(rightStatusIcon.clickAction)"
                      data-test="status-icon"
                    >{{rightStatusIcon.iconName()}}</i>
                  </ng-container>
                  {{getTitle(colDef) | translate}} {{colDef.titleSuffix | translate}}
                </th>
              </ng-container>
              <th
                *ngIf="config.fixedWidth && last"
                class="empty-cell"
              ></th>
            </ng-container>
          </ng-container>
        </tr>
        <tr>
          <th
            *ngIf="config?.isMultiSelect"
            class="multi-select-column fixed"
          >
            <input
              id="head-col-checkbox"
              type="checkbox"
              [checked]="isHeaderCheckChecked()"
              [krosIndeterminate]="isHeaderCheckIndeterminate()"
              (change)="onHeaderCheckChanged($event.target)"
              data-test="check-all-rows"
            >
          </th>
          <ng-container *ngFor="let colDef of config?.colDefs">
            <ng-container
              *ngIf="isColumnVisible(colDef)"
              [ngSwitch]="colDef.type"
            >
              <th
                id="head-col-{{colDef.id}}"
                [attr.colId]="colDef.id"
                [ngClass]="getHeaderCellClass(colDef)"
                [ngStyle]="getColumnStyle(colDef, config.fixedWidth)"
                *ngSwitchCase="colType.Column"
              >
                {{colDef.title | translate}} {{colDef.titleSuffix | translate}}
              </th>
              <ng-container *ngSwitchCase="colType.ColumnGroup">
                <ng-container *ngFor="let colDefInGroup of colDef.colsInGroup; let last = last;">
                  <th
                    *ngIf="isColumnVisible(colDefInGroup)"
                    id="head-col-{{colDefInGroup.id}}"
                    [attr.colId]="colDefInGroup.id"
                    [ngClass]="getHeaderCellClass(colDefInGroup, last, colDef)"
                    [ngStyle]="getColumnStyle(colDefInGroup, config.fixedWidth)"
                  >
                    {{colDefInGroup.title | translate}}
                    <i
                      *ngIf="!!colDefInGroup.titleTooltip"
                      class="material-icons tooltip-icon"
                      krosTooltip="{{colDefInGroup.titleTooltip | translate}}"
                      data-test="tooltip-icon"
                    >info_outlined</i>
                  </th>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
          <th
            *ngIf="config.fixedWidth"
            class="empty-cell"
          ></th>
        </tr>
      </thead>

      <tbody data-test="building-progress-table">
        <!-- cdkVirtualScrollViewport uses transform: translateY to correct for all elements that are removed.
                We will use a placeholder row in the table instead because the translate causes problems with the sticky header -->
        <tr [ngStyle]="getPlaceHolderStyle()"></tr>
        <tr
          *cdkVirtualFor="let item of data?.items; let itemIndex = index;"
          [ngClass]="getRowClass(item, itemIndex)"
          (click)="onRowClicked(item)"
        >
          <td
            *ngIf="config?.isMultiSelect"
            class="multi-select-column fixed"
            [class.selected-item]="isItemRowSelected(item)"
          >
            <input
              type="checkbox"
              [checked]="isItemCheckChecked(item)"
              [krosIndeterminate]="isItemCheckIndeterminate(item)"
              (change)="onItemCheckChanged($event, item)"
              (click)="onCheckItem($event, item)"
              data-test="check-row"
            >
          </td>
          <ng-container *ngFor="let colDef of config?.colDefs; let colIndex = index;">
            <ng-container
              *ngIf="isColumnVisible(colDef)"
              [ngSwitch]="colDef.type"
            >
              <td
                tabindex="{{itemIndex}}"
                *ngSwitchCase="colType.Column"
                [attr.colId]="colDef.id"
                [id]="colDef.id + '-row-' + itemIndex"
                [ngClass]="getCellClass(colDef, itemIndex, item)"
                [ngStyle]="getColumnStyle(colDef, config.fixedWidth, item)"
                (click)="editHelper.onCellClick(colDef, itemIndex)"
                (dblclick)="editHelper.onCellDblClick(colDef, itemIndex, item)"
                (keydown)="editHelper.onCellKeyDown($event, colDef, item)"
                (keyup)="editHelper.onCellKeyUp(colDef, itemIndex, item, $event, viewPort)"
              >
                <ng-container *ngIf="!isComponent(colDef) else component">
                  <div
                    *ngIf="!editHelper.isCellEditing(colDef, itemIndex)"
                    class="cell"
                    [ngStyle]="getCellStyle(colDef)"
                  >
                    <div class="icon">
                      <ng-container
                        [ngTemplateOutlet]="dropdownComponent"
                        [ngTemplateOutletContext]="{colDef: colDef, item: item, itemIndex: itemIndex}"
                      ></ng-container>

                      <i
                        *ngIf="editHelper.isCellActionButtonShown(colDef, itemIndex, item)"
                        [id]="getCellActionButtonId(colDef, item)"
                        class="material-icons material-icon"
                        krosTooltip="{{ colDef.cellActionButton.tooltip(item) | translate }}"
                        (click)="colDef.cellActionButton.action(item)"
                      >{{ colDef.cellActionButton.iconName(item) }}</i>
                    </div>

                    <ng-container *ngIf="!colDef.isPercentageColumn; else percentColumnValue">
                      <div
                        class="value"
                        krosTooltip="{{ getCellTooltip(colDef, item) | translate }}"
                        [innerHTML]="getCellValue(colDef, item) | safeHtml"
                      ></div>
                    </ng-container>

                    <ng-template #percentColumnValue>
                      <span
                        *ngIf="item.isNotCompleted"
                        class="text-centered"
                      >
                        <i
                          class="material-icons icon-button"
                          krosTooltip="{{ 'BUILDING_PROGRESS.LOCKED_ITEM' | translate }}"
                        >lock</i>
                      </span>

                      <kros-pie-chart
                        *ngIf="!item.isNotCompleted"
                        [percentage]="+getCellValue(colDef, item)"
                      ></kros-pie-chart>
                    </ng-template>
                  </div>
                  <input
                    krosAutofocus
                    *ngIf="editHelper.isCellEditing(colDef, itemIndex)"
                    type="text"
                    class="cell-input"
                    [ngStyle]="getCellStyle(colDef)"
                    [ngModel]="editHelper.actualEditValue"
                    (ngModelChange)="editHelper.editOnChange($event, colDef, item)"
                    (focusout)="editHelper.editOnFocusOut($event, colDef, item)"
                    (keyup.enter)="editHelper.editOnEnter($event, colDef, item)"
                  >
                </ng-container>

                <ng-template #component>
                  <ng-container
                    [ngTemplateOutlet]="renderedComponent"
                    [ngTemplateOutletContext]="{colDef: colDef, item: item}"
                  ></ng-container>
                </ng-template>
              </td>
              <ng-container *ngSwitchCase="colType.ColumnGroup">
                <ng-container *ngFor="let colDefInGroup of colDef.colsInGroup; let last = last;">
                  <td
                    *ngIf="isColumnVisible(colDefInGroup)"
                    tabindex="{{itemIndex}}"
                    [attr.colId]="colDefInGroup.id"
                    [id]="colDefInGroup.id + '-row-' + itemIndex"
                    [ngClass]="getCellClass(colDefInGroup, itemIndex, item, last, colDef)"
                    [ngStyle]="getColumnStyle(colDefInGroup, config.fixedWidth, item)"
                    (click)="editHelper.onCellClick(colDefInGroup, itemIndex)"
                    (dblclick)="editHelper.onCellDblClick(colDefInGroup, itemIndex, item)"
                    (keydown)="editHelper.onCellKeyDown($event, colDefInGroup, item)"
                    (keyup)="editHelper.onCellKeyUp(colDefInGroup, itemIndex, item, $event, viewPort)"
                  >
                    <ng-container *ngIf="!isComponent(colDefInGroup) else component">
                      <div
                        class="cell"
                        [ngClass]="{'editable': editHelper.isCellEditable(colDefInGroup, itemIndex, item)}"
                        [ngStyle]="getCellStyle(colDefInGroup, colDef)"
                      >
                        <div
                          class="icon"
                          *ngIf="!editHelper.isCellEditing(colDefInGroup, itemIndex)"
                        >
                          <i
                            *ngIf="hasComments(colDefInGroup, item)"
                            class="material-icons material-icon comments"
                            krosTooltip="{{ 'COMMENTS.CONTAINS_COMMENTS' | translate }}"
                            (click)="openComments(colDefInGroup, itemIndex)"
                            data-test="item-comment-icon"
                          >comment</i>

                          <i
                            *ngIf="editHelper.isCellActionButtonShown(colDefInGroup, itemIndex, item)"
                            [id]="getCellActionButtonId(colDefInGroup, item)"
                            class="material-icons material-icon"
                            krosTooltip="{{ colDefInGroup.cellActionButton.tooltip(item) | translate }}"
                            (click)="colDefInGroup.cellActionButton.action(item)"
                          >{{ colDefInGroup.cellActionButton.iconName(item) }}</i>

                          <ng-container
                            [ngTemplateOutlet]="dropdownComponent"
                            [ngTemplateOutletContext]="{colDef: colDefInGroup, item: item, itemIndex: itemIndex}"
                          ></ng-container>
                        </div>

                        <div class="value">
                          <div *ngIf="!editHelper.isCellEditing(colDefInGroup, itemIndex)">
                            {{getCellValue(colDefInGroup, item)}}
                          </div>

                          <input
                            krosAutofocus
                            *ngIf="editHelper.isCellEditing(colDefInGroup, itemIndex)"
                            type="text"
                            class="cell-input"
                            [ngStyle]="getCellStyle(colDefInGroup)"
                            [ngModel]="editHelper.actualEditValue"
                            (ngModelChange)="editHelper.editOnChange($event, colDefInGroup, item)"
                            (focusout)="editHelper.editOnFocusOut($event, colDefInGroup, item)"
                            (keyup.enter)="editHelper.editOnEnter($event, colDefInGroup, item)"
                          >
                        </div>

                        <div
                          *ngIf="editHelper.isFloatingActionButtonShown(colDefInGroup, itemIndex, item)"
                          class="action-button-div"
                          data-test="auto-calculation-amount-icon"
                        >
                          <i
                            class="action-button material-icons"
                            krosTooltip="{{ colDefInGroup.floatingActionButton.tooltip(item) | translate }}"
                            (click)="colDefInGroup.floatingActionButton.action(item)"
                          >{{ colDefInGroup.floatingActionButton.iconName(item) }}</i>
                        </div>
                      </div>
                    </ng-container>

                    <ng-template #component>
                      <ng-container
                        [ngTemplateOutlet]="renderedComponent"
                        [ngTemplateOutletContext]="{colDef: colDefInGroup, item: item}"
                      ></ng-container>
                    </ng-template>
                  </td>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
          <td
            *ngIf="config.fixedWidth"
            class="empty-cell"
          ></td>
        </tr>
      </tbody>
    </table>
  </cdk-virtual-scroll-viewport>
</div>

<ng-template
  #dropdownComponent
  let-colDef='colDef'
  let-item='item'
  let-itemIndex='itemIndex'
>
  <div
    *ngIf="hasDropdownComponent(colDef, item) && editHelper.isCellFocused(colDef, itemIndex)"
    ngbDropdown
    container="body"
    placement="left auto"
    #dropdownCellComponent="ngbDropdown"
  >
    <i
      class="material-icons material-icon dropdown-component-icon"
      ngbDropdownAnchor
      [id]="config.getDropdownGaId(colDef)"
      (click)="openDropdownComponent(colDef, dropdownCellComponent, itemIndex)"
    >{{colDef.dropdownComponentConfig.iconName}}</i>

    <div ngbDropdownMenu>
      <kros-component-renderer
        *ngIf="dropdownCellComponent.isOpen()"
        [component]="colDef.dropdownComponentConfig.component"
        [data]="item"
        [dropdownRef]="dropdownCellComponent"
      ></kros-component-renderer>
    </div>
  </div>
</ng-template>

<ng-template
  #renderedComponent
  let-colDef='colDef'
  let-item='item'
>
  <kros-component-renderer
    *ngIf="!isComponentHidden(colDef, item)"
    [component]="colDef.componentConfig.component"
    [data]="item"
    [params]="colDef.componentConfig.params"
  ></kros-component-renderer>
</ng-template>
