import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[disableDrag]'
})
export class DisableDragDirective {

  @HostListener('dragstart', ['$event'])
  onDragStart(event: Event): void {
    event.preventDefault();
  }
}
