import { Inject, Injectable, InjectionToken } from '@angular/core';
import { PRIMARY_OUTLET, Router } from '@angular/router';

import { AnalyticsData } from '@kros-sk/models';
import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

import { ToastService } from '../../toast';

export const ALLOWED_ANALYTICS_ACTIONS = new InjectionToken<string>('Allowed actions for KrosAnalyticsService');

/**
 * Service for logging Analytics info into selected online storage (currently Application Insights)
 */
@Injectable()
export class KrosAnalyticsService {

  private allowedActions: Set<string>;

  constructor(
    private appInsights: AppInsightsBaseService,
    private router: Router,
    private toastService: ToastService,
    @Inject(ALLOWED_ANALYTICS_ACTIONS) allowedActions: string[]
  ) {
    this.allowedActions = new Set(allowedActions);
  }

  /**
   * Log event into application insights as CustomEvent. AIN event name is set to "Analytics".
   * @param data Data to log. Only action name is mandatory.
   * Other data (like route, companyId, browserResolution) will be retrieved automatically, if not provided.
   */
  logData(data: AnalyticsData): void {
    // validating Action name - preventing typos in HTML and enforcing centralized collection of currently used Actions.
    if (!this.allowedActions.has(data.action)) {
      console.error('KrosAnalyticsService - invalid Action name:', data.action);
    }
    const dataToLog: AnalyticsData = { ...data };
    if (!data.route || !data.companyId) {
      const dataFromRoute = this.tryToGetDataFromRoute();
      dataToLog.route = data.route ?? dataFromRoute.route;
      dataToLog.companyId = data.companyId ?? dataFromRoute.companyId;
    }
    if (!data.browserResolution) {
      dataToLog.browserResolution = `${window.innerWidth}x${window.innerHeight}`;
    }
    // this is only for in-house testing purposes to view data sent to AIN
    if ((window as any).krosAnalyticsTest) {
      this.showToast(dataToLog);
      this.logToConsole(dataToLog);
    }
    this.appInsights.trackEvent('Analytics', dataToLog);
  }

  /**
   * Try to get companyId and route info from current router state snapshot
   */
  private tryToGetDataFromRoute(): { companyId: string, route: string } {
    let companyId: string = null;
    let route: string = null;

    const url = this.router.routerState.snapshot.url;
    if (url) {
      const segments = this.router.parseUrl(url)
        .root.children[PRIMARY_OUTLET]
        .segments
        .map(s => s.path);

      segments.forEach((segment, index, array) => {
        // company segment is ignored
        if (segment === 'company') return;
        const numValue = +segment;
        // company id is numeric value exactly after company segment
        if (!isNaN(numValue) && (index > 0) && (array[index - 1] === 'company')) {
          companyId = segment;
          return;
        }
        // string values are appended
        if (isNaN(numValue)) {
          route = (route ?? '').concat('/', segment);
        }
        // any other numeric values are ignored
      });
    }
    return { companyId, route };
  }

  // Only for in-house testing purposes
  private logToConsole(data: AnalyticsData): void {
    const text = `Analytics Data:
    action: ${data.action}
    modal: ${data.modal}
    route: ${data.route}
    companyId: ${data.companyId}
    value: ${data.value}
    resolution: ${data.browserResolution}
    `;
    console.log(text);
  }

  // Only for in-house testing purposes
  private showToast(data: AnalyticsData): void {
    const toastText = `action: "${data.action}"; modal: "${data.modal}"; value: "${data.value}"`;
    this.toastService.show(toastText,
      { classname: 'kros-toast kros-info', bottomRight: true, delay: 10000 }
    );
  }

}
