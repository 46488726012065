import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { PercentPipe } from '@angular/common';

import { APP_CONFIG } from '@kros-sk/app-config';

const maxPercentage = 2147483600;

@Component({
  selector: 'kros-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PieChartComponent {
  completedRange: number[];

  @Input() size = 20;

  private readonly appConfig = inject(APP_CONFIG);
  private readonly percent = inject(PercentPipe);
  private _percentage: number;

  @Input()
  set percentage(value: number) {
    this._percentage = value;
    this.completedRange = this.calculateCompletedRange();
  }

  get percentage(): number {
    return this._percentage;
  }

  get canShowChart(): boolean {
    return this.percentage !== null;
  }

  get completedValueClass(): string {
    if (this.percentage > 100) {
      return 'warning';
    }

    if (this.percentage === 100) {
      return 'green';
    }

    return '';
  }

  get tooltip(): string {
    return this.percentage > maxPercentage
      ? ''
      : this.percent.transform(this.percentage / 100, '1.0-0', this.appConfig.location);
  }

  private calculateCompletedRange(): number[] {
    let percentage = this._percentage;

    if (percentage < 0) {
      percentage = 0;
    } else {
      if (percentage > 0) {
        percentage = Math.max(5, percentage);
      }

      if (percentage < 1) {
        percentage = Math.min(95, percentage);
      }
    }

    return [percentage / 10 * Math.PI, 10 * Math.PI];
  }
}
