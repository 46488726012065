import { Injectable } from '@angular/core';

import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { BuildingProgressItem } from '../../models/construction-data.model';
import {
  PartialBudgetItem,
  PartialBudgetItemChangedProperty,
  PartialBudgetTableItem
} from '../../models/building-progress-partial-budget-items.model';

@Injectable()
export class BudgetApprovalPartialItemsTableService extends UnsubscribeComponent {

  set buildingProgressItemData(buildingProgressItem: BuildingProgressItem) {
    this.buildingProgressItem = buildingProgressItem;
  }

  private buildingProgressItem: BuildingProgressItem;

  constructor() {
    super();
  }

  transformStoreItemsToTableItems(items: PartialBudgetItem[]): PartialBudgetTableItem[] {
    return items.map((item, index) => ({
      code: this.buildingProgressItem.code,
      description: this.buildingProgressItem.description,
      measureUnit: this.buildingProgressItem.measureUnit,
      id: index + 1,
      amount: item.amount,
      percentage: item.percentage,
      changedProperty: PartialBudgetItemChangedProperty.None
    }));
  }

  getMaxPrecision(value: string, precision: number): number {
    value = value.replace(/,/g, '.');
    return this.round(+value, precision);
  }

  firstCaseUp(inputString: string): string {
    if (inputString.length === 0) return;

    const firstChar = inputString[0].toUpperCase();
    const restOfString = inputString.slice(1).toLowerCase();

    return firstChar + restOfString;
  }

  round(num: number, precision: number): number {
    return Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision);
  }
}
