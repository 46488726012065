import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { filter, Observable, of, switchMap, take, tap } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { ApprovalHelper } from '@kros-sk/ssw-shared/utils/building-progress';
import { AuthSelectorsService } from '@kros-sk/auth';
import { License, LicenseSelectorsService, LicenseType } from '@kros-sk/ssw-cdk';
import { TimelineType, VatRateType, VatRateTypePipe } from '@kros-sk/ssw-shared-legacy';

import { BuildingProgressSettings, BuildingProgressSettingsEditModel, RetainageCalculationType } from '../../model';
import { BuildingProgressSettingService, SaveButtonStateService, SettingService } from '../../services';
import { OpenApiService } from '../../open-api-settings';

@Component({
  selector: 'ssw-building-progress-setting',
  templateUrl: './building-progress-setting.component.html',
  styleUrls: ['./building-progress-setting.component.scss']
})
export class BuildingProgressSettingsComponent implements OnInit {

  private destroyRef = inject(DestroyRef);
  private openApiService = inject(OpenApiService);
  private settingService = inject(SettingService);
  private buildingProgressSettingService = inject(BuildingProgressSettingService);
  private saveButtonStateService = inject(SaveButtonStateService);
  private licenseSelectorsService = inject(LicenseSelectorsService);
  private appInsightsService = inject(AppInsightsBaseService);
  private approvalHelper = inject(ApprovalHelper);
  private vatRatePipeType = inject(VatRateTypePipe);
  private appConfig = inject(APP_CONFIG);
  private formBuilder = inject(UntypedFormBuilder);
  private router = inject(Router);

  get routerLink(): string {
    return this.settingService.previousUrl;
  }

  get isOpenedProject(): boolean {
    return !!this.settingService.projectId;
  }

  get isExist(): boolean {
    return this.buildingProgressSettingService.isExist;
  }

  get hasPermission(): boolean {
    return this.buildingProgressSettingService.hasPermission;
  }

  get canSaveSettings(): boolean {
    return this.buildingProgressSettingService.canSaveSettings && this.isTrialFullLicense;
  }

  get settingsIsLoading(): boolean {
    return this.buildingProgressSettingService.isLoading;
  }

  get isCzechVersion(): boolean {
    return this.appLocation === 'cz';
  }

  get appLocation(): string {
    return this.appConfig.location;
  }

  get digitsInfoVat(): string {
    return '1.0-2';
  }

  timelineType = TimelineType;
  isEdited = false;
  isEditedOpenApi = false;
  vatRateType = VatRateType;
  settings: BuildingProgressSettings;
  retainageCalculationType = RetainageCalculationType;
  retainage2CalculationType = RetainageCalculationType;
  showRetainageCalculationType = false;
  showRetainage2CalculationType = false;
  settingsForm: UntypedFormGroup;
  authSelectorsService = inject(AuthSelectorsService);

  private license: License;

  private get isTrialFullLicense(): boolean {
    return !!this.license && (this.license.licenseType === LicenseType.Trial || this.license.licenseType === LicenseType.Full);
  }

  ngOnInit(): void {
    this.appInsightsService.trackEvent('PV-settings-open');
    this.initializeForm();
    this.openApiService.loadSubscriptions().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    this.licenseSelectorsService.buildingProgressLicense$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(l => this.license = l);
    this.saveButtonStateService.isEdited$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(e => this.isEditedOpenApi = e);
    this.buildingProgressSettingService.buildingProgressSettings$
      .pipe(filter(s => !!s), takeUntilDestroyed(this.destroyRef)).subscribe(settings => {
        this.settings = settings;
        this.fillForm();
      });
  }

  handleInput(event: any): void {
    event.target.value = event.target.value.replace(/\./g, ',');
    this.isEdited = true;
  }

  isVatRateDefined(vatRateType: VatRateType): boolean {
    return this.settings?.vatRates[vatRateType] >= 0;
  }

  getVatResName(type: VatRateType): string {
    if (this.isCzechVersion) {
      return this.vatRatePipeType.transform(type);
    } else {
      return Object.keys(this.settings?.vatRates).length === 1 ?
        'SETTINGS.VAT.VAT' :
        this.vatRatePipeType.transform(type);
    }
  }

  getVatRate(type: VatRateType): number {
    return this.settings.vatRates[type];
  }

  onSave(): void {
    if (this.isEdited && this.canSaveSettings) {
      const settings: BuildingProgressSettingsEditModel = {
        projectId: this.settings.projectId,
        isTransferredVat: this.settingsForm.controls.isTransferredVat.value,
        basicVatRate: this.settingsForm.controls.basicVatRate.value,
        reducedVatRate: this.settingsForm.controls.reducedVatRate.value,
        retainagePercentage: this.settingsForm.controls.retainagePercentage.value,
        retainageCalculationType: this.settingsForm.controls.retainageCalculationType.value,
        retainage2Percentage: this.settingsForm.controls.retainage2Percentage.value,
        retainage2CalculationType: this.settingsForm.controls.retainage2CalculationType.value,
        isAutoCompleteItemsEnabled: this.settingsForm.controls.isAutoCompleteItemsEnabled.value,
        isMultiStageApprovalModeEnabled: this.settingsForm.controls.isMultiStageApprovalModeEnabled.value
      };

      if (this.settings.isMultiStageApprovalModeEnabled !== settings.isMultiStageApprovalModeEnabled) {
        this.showApprovalCancellationDialog().pipe(
          take(1),
          switchMap(result => result ? this.editSettings(settings) : this.resetForm()),
          takeUntilDestroyed(this.destroyRef)
        ).subscribe();
      } else {
        this.editSettings(settings).pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe();
      }
    }
    if (this.isEditedOpenApi) {
      this.saveButtonStateService.save();
    }
  }

  navigateTo(url: string): void {
    this.router.navigateByUrl(url);
  }

  private editSettings(settings: BuildingProgressSettingsEditModel): Observable<void> {
    this.isEdited = false;

    return this.buildingProgressSettingService.editSettings(settings).pipe(tap(() => this.trackStatistic()));
  }

  private showApprovalCancellationDialog(): Observable<boolean> {
    return this.approvalHelper.showApprovalCancellationDialog(
      'SETTINGS.PERIOD_APPROVAL_CANCELLATION_DANGER_CAPTION',
      'SETTINGS.PERIOD_APPROVAL_CANCELLATION_DANGER_MESSAGE',
      'SETTINGS.PERIOD_APPROVAL_CANCELLATION_DANGER_ACCEPT'
    );
  }

  private resetForm(): Observable<void> {
    this.isEdited = false;
    this.initializeForm();

    return of();
  }

  private trackStatistic(): void {
    if (this.settings?.isAutoCompleteItemsEnabled !== this.settingsForm.value.isAutoCompleteItemsEnabled) {
      this.appInsightsService.trackEvent('PV-autoCompleteItemsEnabled-changed');
    }
    if (this.settings?.retainagePercentage !== this.settingsForm.value.retainagePercentage) {
      this.appInsightsService.trackEvent('PV-retainage-edit');
    }
    if (this.settings?.retainage2Percentage !== this.settingsForm.value.retainage2Percentage) {
      this.appInsightsService.trackEvent('PV-retainage2-edit');
    }
    if (this.settings?.isMultiStageApprovalModeEnabled !== this.settingsForm.value.isMultiStageApprovalModeEnabled) {
      this.appInsightsService.trackEvent('PV-approval-mode-changed', {
        isMultiStageApprovalModeEnabled: this.settingsForm.value.isMultiStageApprovalModeEnabled,
        statisticPropertyNames: 'isMultiStageApprovalModeEnabled'
      });
    }
    this.appInsightsService.trackEvent('PV-settings-save', { isTransferredVat: this.settingsForm.value.isTransferredVat.toString() });
  }

  private fillForm(): void {
    this.settingsForm.patchValue({
      currencyCode: this.settings?.currencyCode,
      amount: this.settings?.decimalPlaces.amount,
      unitPrice: this.settings?.decimalPlaces.unitPrice,
      totalPrice: this.settings?.decimalPlaces.totalPrice,
      buildingObjectTotalPrice: this.settings?.decimalPlaces.buildingObjectTotalPrice,
      basicVatRate: this.settings?.vatRates[this.vatRateType.Basic] ??
        this.settings?.vatRates[this.vatRateType.BasicTransferred] ?? 0,
      reducedVatRate: this.settings?.vatRates[this.vatRateType.Reduced] ??
        this.settings?.vatRates[this.vatRateType.ReducedTransferred] ?? 0,
      isTransferredVat: this.settings?.isTransferredVat,
      retainagePercentage: this.settings?.retainagePercentage ?? 0,
      retainageCalculationType: this.settings?.retainageCalculationType || RetainageCalculationType.PriceWithVAT,
      retainage2Percentage: this.settings?.retainage2Percentage ?? 0,
      retainage2CalculationType: this.settings?.retainage2CalculationType || RetainageCalculationType.PriceWithVAT,
      isAutoCompleteItemsEnabled: this.settings?.isAutoCompleteItemsEnabled,
      isMultiStageApprovalModeEnabled: this.settings?.isMultiStageApprovalModeEnabled
    });
    if (!this.canSaveSettings) {
      this.settingsForm.disable();
    }
  }

  private initializeForm(): void {
    this.settingsForm = this.formBuilder.group(
      {
        currencyCode: this.settings?.currencyCode,
        amount: this.settings?.decimalPlaces.amount,
        unitPrice: this.settings?.decimalPlaces.unitPrice,
        totalPrice: this.settings?.decimalPlaces.totalPrice,
        buildingObjectTotalPrice: this.settings?.decimalPlaces.buildingObjectTotalPrice,
        basicVatRate: this.settings?.vatRates[this.vatRateType.Basic] ?? 0,
        reducedVatRate: this.settings?.vatRates[this.vatRateType.Reduced] ?? 0,
        isTransferredVat: this.settings?.isTransferredVat,
        retainagePercentage: this.settings?.retainagePercentage ?? 0,
        retainageCalculationType: this.settings?.retainageCalculationType || RetainageCalculationType.PriceWithVAT,
        retainage2Percentage: this.settings?.retainage2Percentage ?? 0,
        retainage2CalculationType: this.settings?.retainage2CalculationType || RetainageCalculationType.PriceWithVAT,
        isAutoCompleteItemsEnabled: this.settings?.isAutoCompleteItemsEnabled,
        isMultiStageApprovalModeEnabled: this.settings?.isMultiStageApprovalModeEnabled
      }
    );

    this.settingsForm.controls.isTransferredVat.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(v => {
      if (!this.isEdited) {
        this.isEdited = this.settings?.isTransferredVat !== v;
      }
    });

    this.settingsForm.controls.isAutoCompleteItemsEnabled.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(v => {
      if (!this.isEdited) {
        this.isEdited = this.settings?.isAutoCompleteItemsEnabled !== v;
      }
    });

    this.settingsForm.controls.retainageCalculationType.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(v => {
      if (!this.isEdited && this.settings?.retainageCalculationType !== RetainageCalculationType.Undefined) {
        this.isEdited = this.settings?.retainageCalculationType !== v;
      }
    });

    this.settingsForm.controls.retainagePercentage.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(v => {
      this.showRetainageCalculationType = !!v;
    });

    if (this.settings?.retainagePercentage > 0) {
      this.showRetainageCalculationType = true;
    }

    this.settingsForm.controls.retainage2CalculationType.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(v => {
      if (!this.isEdited && this.settings?.retainage2CalculationType !== RetainageCalculationType.Undefined) {
        this.isEdited = this.settings?.retainage2CalculationType !== v;
      }
    });

    this.settingsForm.controls.retainage2Percentage.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(v => {
      this.showRetainage2CalculationType = !!v;
    });

    if (this.settings?.retainage2Percentage > 0) {
      this.showRetainage2CalculationType = true;
    }

    this.settingsForm.controls.isMultiStageApprovalModeEnabled.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(v => {
      this.isEdited = this.settings?.isMultiStageApprovalModeEnabled !== v;
    });
  }
}
