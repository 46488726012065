import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';

import { TimelineType } from '@kros-sk/ssw-shared-legacy';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { ItemDetails } from '../../../building-progress/models/item-period-details.model';

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss']
})
export class ItemDetailsComponent extends UnsubscribeComponent implements OnInit {
  timelineType = TimelineType;
  detailsForm: UntypedFormGroup;

  @Input() label: string;
  @Input() info: string;
  @Input() readOnly: boolean;
  @Input() loading: boolean;
  @Input() data: Observable<ItemDetails>;
  @Input() isNew: Observable<boolean>;
  @Input() canShowDetails: boolean;
  @Input() canNotShowDetailsLabel: string;

  @Output() editing: EventEmitter<boolean> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<ItemDetails> = new EventEmitter();

  private originalData: ItemDetails;

  constructor(
    private formBuilder: UntypedFormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this.initializeForm();
    this.subs.sink = this.data.subscribe(data => {
      this.setData(data);
    });
    this.subs.sink = this.isNew.subscribe(isNew => {
      this.setValidators(isNew);
    });
  }

  initializeForm(): void {
    this.detailsForm = this.formBuilder.group(
      { note: ['', Validators.required] }
    );

    this.subs.sink = this.detailsForm.valueChanges.subscribe(() => {
      this.editing.emit(true);
    });
  }

  setData(data: ItemDetails): void {
    this.originalData = { ...data };
    this.detailsForm.patchValue({
      note: data?.note
    });
    this.detailsForm.markAsPristine();
    this.editing.emit(false);
  }

  setValidators(isNew: boolean): void {
    this.detailsForm.controls['note'].setValidators(isNew ? [Validators.required] : []);
  }

  getDataFromForm(): ItemDetails {
    return {
      note: this.detailsForm.controls['note'].value
    };
  }

  onCloseClick(): void {
    this.close.emit();
  }

  cancelChanges(): void {
    this.setData(this.originalData);
  }

  onSubmit(): void {
    this.save.emit(this.getDataFromForm());
  }
}
