import { Injectable } from '@angular/core';

import { KrosShepherdService } from '@kros-sk/components';
import { TourBaseService, TranslateService } from '@kros-sk/ssw-shared-legacy';
import { TourStep, TourStepButtonType } from '@kros-sk/models';
import { UserService } from '@kros-sk/ssw-cdk';

const tourName = 'building-progress';
const backNextButtons = TourStepButtonType.Back | TourStepButtonType.Next;

@Injectable()
export class BuildingProgressTourService extends TourBaseService {
  private openPeriods: Function;

  constructor(
    tourService: KrosShepherdService,
    translateService: TranslateService,
    userService: UserService
  ) {
    super(tourService, translateService, userService);
    this.tourName = tourName;
  }

  runBuildingProgressTourIfCan(canRunTour: boolean, openPeriods: Function): void {
    if (!this.isActive && canRunTour) {
      this.openPeriods = openPeriods;
    }
    super.runTourIfCan(canRunTour);
  }

  protected getSteps(): TourStep[] {
    const ret = [];

    ret.push(
      this.getStep(
        this.translate('PERIODS_HEAD'),
        this.translate('PERIODS_TEXT') + this.getStepIndicator(1, 6),
        '#open-period',
        null,
        'periods', 'bottom', [{
          action: (): void => {
            this.openPeriods();
            this.krosShepherdService.next();
          },
          isPrimary: true,
          text: this.translateService.translate('COMMON.NEXT')
        }]));
    ret.push(
      this.getStep(
        this.translate('NEW_PERIOD_HEAD'),
        this.translate('NEW_PERIOD_TEXT') + this.getStepIndicator(2, 6),
        '#ga-period-add-panel',
        backNextButtons,
        'new-period'));
    ret.push(
      this.getStep(
        this.translate('EDIT_PERIOD_HEAD'),
        this.translate('EDIT_PERIOD_TEXT') + this.getStepIndicator(3, 6),
        'th.percentage.selected',
        backNextButtons,
        'edit-period',
        'left-start',
        null,
        2000));
    ret.push(
      this.getStep(
        this.translate('SHARING_HEAD'),
        this.translate('SHARING_TEXT') + this.getStepIndicator(4, 6),
        '#share-period',
        backNextButtons,
        'sharing'));
    ret.push(
      this.getStep(
        this.translate('COMMENTS_HEAD'),
        this.translate('COMMENTS_TEXT') + this.getStepIndicator(5, 6),
        '#comment-period',
        backNextButtons,
        'comments'));
    ret.push(
      this.getStep(this.translateService.translate('GUIDE.HELP_HEAD'),
        this.translateService.translate('GUIDE.HELP_TEXT') + this.getStepIndicator(6, 6),
        'kros-help-buton',
        TourStepButtonType.Back,
        'help',
        'left',
        [{
          action: (): void => this.krosShepherdService.complete(),
          isPrimary: true,
          text: this.translateService.translate('COMMON.UNDERSTAND')
        }]));
    ret.push(
      this.getStep(
        this.translateService.translate('GUIDE.HELP_HEAD'),
        this.translateService.translate('GUIDE.HELP_TEXT'),
        'kros-help-buton',
        undefined,
        'only-help',
        'left',
        [{
          action: (): void => this.krosShepherdService.complete(),
          isPrimary: true,
          text: this.translateService.translate('COMMON.UNDERSTAND')
        }]));

    return ret;
  }

  private translate(keyPart: string): string {
    return this.translateService.translate('BUILDING_PROGRESS.GUIDE.' + keyPart);
  }

  hide(): void {
    this.krosShepherdService.hide();
  }

  continueTourOnCreatedPeriod(): void {
    if (this.isActive) {
      this.krosShepherdService.show(false, 'edit-period');
    }
  }
}
