import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BudgetStructureService {
  private setFocusToBudgetItem = new Subject<{ id: number, tableId?: string }>();
  private setFocusToStructureItem = new Subject<{ id: number, tableId?: string }>();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  setFocusToBudgetItemRequested$ = this.setFocusToBudgetItem.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  setFocusToStructureItemRequested$ = this.setFocusToStructureItem.asObservable();

  constructor() { }

  setFocusToBudgetItemById(id: number, tableId?: string): void {
    this.setFocusToBudgetItem.next({ id, tableId });
  }

  setFocusToStructureItemById(id: number, tableId?: string): void {
    this.setFocusToStructureItem.next({ id, tableId });
  }
}
