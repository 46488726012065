import { EventEmitter, Injectable, TemplateRef } from '@angular/core';

import { ToastRef } from '../toast-ref';
import { ToastType } from '../toast-type';

const defaultOptions = {
  delay: 10000,
  autohide: true
};

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  arraySize = 3;
  infoToasts: any[] = [];
  errorToasts: any[] = [];

  open(content: string | TemplateRef<any>,
    type: ToastType = ToastType.Info,
    options: any = {},
    actionButton?: string): ToastRef {
    const afterDismissed: EventEmitter<any> = new EventEmitter<any>();
    const afterAction: EventEmitter<void> = new EventEmitter<void>();
    const opts = { ...defaultOptions, ...options };
    const newToast = { content, options: opts, type, actionButton, afterDismissed, afterAction };

    this.pushToStack(newToast);

    return new ToastRef(afterAction, afterDismissed, newToast, this);
  }

  openAndCloseOthersByType(content: string | TemplateRef<any>,
    type: ToastType = ToastType.Info,
    options: any = {},
    actionButton?: string): ToastRef {

    const stack = this.getToastStackByType(type);
    stack.forEach(t => this.remove(t, false));

    return this.open(content, type, options, actionButton);
  }

  private pushToStack(toast: any): void {
    const stack = this.getToastStackByType(toast.type);
    stack.push(toast);

    if (stack.length > this.arraySize) {
      this.setToastStackByType(toast.type, stack.slice(-this.arraySize));
    }
  }

  private getToastStackByType(type: ToastType): any[] {
    if (this.isInfoType(type)) {
      return this.infoToasts;
    }

    return this.errorToasts;
  }

  private setToastStackByType(type: ToastType, stack: any[]): void {
    if (this.isInfoType(type)) {
      this.infoToasts = stack;
    } else {
      this.errorToasts = stack;
    }
  }

  isInfoType(type: ToastType): boolean {
    return type === ToastType.Info || type === ToastType.Warning;
  }

  close(toast: any): void {
    this.remove(toast, false);
  }

  dismissedByAction(toast: any): void {
    this.remove(toast, true);
    toast.afterAction.emit();
  }

  dismissInfoToast(): void {
    this.getToastStackByType(ToastType.Info).forEach(t => this.remove(t, false));
  }

  private remove(toast: any, dismissedByAction: boolean): void {
    this.infoToasts = this.infoToasts.filter(t => t !== toast);
    this.errorToasts = this.errorToasts.filter(t => t !== toast);
    toast.afterDismissed.emit({ dismissedByAction });
  }
}
