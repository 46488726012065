import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ImageUploadButtonModule, LicenseService, TimelineModule, TranslateModule } from '@kros-sk/ssw-shared-legacy';
import { KrosComponentsModule, KrosFormFieldModule, KrosInputsModule } from '@kros-sk/components';
import { LicenseModule } from '@kros-sk/ssw-cdk';
import { TooltipDirective } from '@kros-sk/ui/tooltip';

import { BudgetSettingsComponent, BuildingProgressSettingsComponent, GeneralSettingsComponent, SettingsComponent } from './components';
import { BudgetSettingService, BuildingProgressSettingService, SaveButtonStateService } from './services';
import { OpenApiSettingsModule } from './open-api-settings';
import { SettingsRoutingModule } from './settings-routing.module';

@NgModule({
  declarations: [
    SettingsComponent,
    GeneralSettingsComponent,
    BudgetSettingsComponent,
    BuildingProgressSettingsComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    KrosInputsModule,
    SettingsRoutingModule,
    OpenApiSettingsModule,
    ImageUploadButtonModule,
    KrosFormFieldModule,
    TimelineModule,
    KrosComponentsModule,
    LicenseModule.forRoot(LicenseService),
    TooltipDirective
  ],
  providers: [
    SaveButtonStateService,
    BudgetSettingService,
    BuildingProgressSettingService
  ]
})
export class SettingsModule { }
