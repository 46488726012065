import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output } from '@angular/core';

import { SubSink } from 'subsink';

import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { UploadNotificationService } from '../services/upload-notification.service';
import { UploaderService } from '../services/uploader.service';

@Component({
  selector: 'kros-upload-snackbar',
  templateUrl: './upload-snackbar.component.html',
  styleUrls: ['./upload-snackbar.component.scss']
})
export class UploadSnackbarComponent extends UnsubscribeComponent implements OnDestroy {
  @Output() closeSnackbar: EventEmitter<void> = new EventEmitter<void>();

  zipFiles = true;
  minimized = true;
  progress: { [id: string]: number } = {};

  // pridané na manuálny unsubscribe, z nejakého dôvodu(nezmáme zatiaľ) untilDestroyed nezabezpečil unsubscribe z
  // uploaderService, v prípade refactoru ak vieš ako opraviť tak oprav
  private uploaderSubscription: SubSink;

  constructor(
    public uploaderService: UploaderService,
    private changeDetector: ChangeDetectorRef,
    private uploadNotificationService: UploadNotificationService
  ) {
    super();
    this.subs.sink = this.uploaderService.filesSelected.subscribe(() => this.zipFiles = true);

    this.subs.sink = this.uploaderService.filesReady
      .subscribe(filesReady => {
        if (filesReady) {
          this.beginUpload();
          this.zipFiles = false;
          this.changeDetector.detectChanges();
          this.minimized = false;
        } else {
          this.uploaderService.filesToUpload.splice(0, this.uploaderService.filesToUpload.length);
          this.zipFiles = false;
          this.minimized = false;
          this.uploadDone();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.uploaderSubscription) {
      this.uploaderSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  get finishedCount(): number {
    let ret = 0;

    this.uploaderService.filesToUpload.forEach(item => {
      if (this.progress[item.id] === 100) {
        ret++;
      }
    });

    if (ret === this.uploaderService.filesToUpload.length && !this.isUploadCompleted() && ret !== 0) {
      ret--;
    }

    return ret;
  }

  close(): void {
    this.closeSnackbar.emit();
  }

  private beginUpload(): void {
    this.uploaderSubscription = new SubSink();
    this.uploaderSubscription.sink = this.uploaderService.progressItem.subscribe(p => this.onProgressItem(p.id, p.progress));
    this.uploaderSubscription.sink = this.uploaderService.complete.subscribe(() => this.onUploadCompleted());
    this.setInitialProgress();
    this.uploaderService.uploadFiles();
  }

  private onProgressItem(id: string, progress: any): void {
    this.progress[id] = progress;
    this.changeDetector.detectChanges();
  }

  private onUploadCompleted(): void {
    const uploader = this.uploaderService.getUploader();
    uploader.queue = [];
    this.uploaderService.filesToUpload.forEach(p => this.progress[p.id] = 100);
    this.allFilesUploadCompleted();
    setTimeout(() => this.close(), 10000);
  }

  private allFilesUploadCompleted(): void {
    this.uploadDone();
    this.uploadNotificationService.notifyDocumentsUploaded(
      { projectId: this.uploaderService.projectId, parentId: this.uploaderService.parentId });
    this.uploaderSubscription.unsubscribe();
  }

  private uploadDone(): void {
    this.uploaderService.uploadCompleted = true;
    this.changeDetector.detectChanges();
  }

  isUploadCompleted(): boolean {
    return !this.zipFiles && this.uploaderService.uploadCompleted;
  }

  private setInitialProgress(): void {
    this.uploaderService.filesToUpload.forEach(p => this.progress[p.id] = 0);
  }
}
