import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';

import {
  AnalyticsModule,
  BrowserTabService,
  DocumentsEditEffects,
  DocumentsExplorerEffects,
  LicenseService,
  ProjectInfoModule,
  TagsModule,
  TranslateModule
} from '@kros-sk/ssw-shared-legacy';
import {
  ApprovalOrderEffects,
  approvalOrderFeatureState,
  ApprovalOrderHelper,
  ApprovalOrderService
} from '@kros-sk/ssw-building-progress/approval-order';
import { KrosDropdownModule, LicenseActivationModule, PartialsModule } from '@kros-sk/components';
import { PermissionModule } from '@kros-sk/ssw-shared/permission';
import { provideBootstrapEffects } from '@kros-sk/ssw-cdk';

import { BudgetApprovalEffects } from '../store/budget-approval';
import { BudgetApprovalModule } from '../budget-approval/budget-approval.module';
import {
  BuildingProgressCommentsEffects,
  BuildingProgressDataSettingsEffects,
  BuildingProgressEffects,
  BuildingProgressPartialItemsEffectsService,
  BuildingProgressPeriodsEffects
} from '../store/building-progress';
import { BuildingProgressModule } from '../building-progress/building-progress.module';
import { BuildingProgressSettingService } from './services';
import { DocumentsEffects } from '../store/documents';
import { DocumentsModule } from '../documents/documents.module';
import { NewProjectMembersDetailComponent } from './components/new-project-members-detail/new-project-members-detail.component';
import { ProjectDetailComponent } from './components/project-detail/project-detail.component';
import { ProjectDetailNavigationComponent } from './components/project-detail-navigation/project-detail-navigation.component';
import { ProjectFiltersComponent } from './components/project-filters/project-filters/project-filters.component';
import { ProjectListComponent } from './components/project-list/project-list.component';
import { ProjectMembersComponent } from './components/project-members/project-members.component';
import { ProjectMembersOwnerDetailComponent } from './components/project-members-owner-detail/project-members-owner-detail.component';
import {
  ProjectMembersPanelContainerComponent
} from './components/project-members-panel-container/project-members-panel-container.component';
import { ProjectMembersPanelDialogComponent } from './components/project-members-panel-dialog/project-members-panel-dialog.component';
import { ProjectNavigateComponent } from './components/project-navigate/project-navigate.component';
import { ProjectPanelContainerComponent } from './containers/project-panel-container/project-panel-container.component';
import { ProjectsComponent } from './projects.component';
import { ProjectsDispatchersService, ProjectsEffects, projectsReducer, ProjectsSelectorsService } from '../store/projects';
import { ProjectSharingService } from './services/project-sharing.service';
import { ProjectsRoutingModule } from './projects-routing.module';
import { ProjectsService } from './services/projects.service';
import { ProjectToolbarComponent } from './components/project-toolbar/project-toolbar.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    ProjectsComponent,
    ProjectDetailComponent,
    ProjectDetailNavigationComponent,
    ProjectFiltersComponent,
    ProjectListComponent,
    ProjectPanelContainerComponent,
    ProjectToolbarComponent,
    ProjectMembersComponent,
    ProjectNavigateComponent,
    NewProjectMembersDetailComponent,
    ProjectMembersPanelContainerComponent,
    ProjectMembersOwnerDetailComponent,
    ProjectMembersPanelDialogComponent
  ],
  imports: [
    AnalyticsModule,
    FormsModule,
    ReactiveFormsModule,
    ProjectsRoutingModule,
    SharedModule,
    TranslateModule.forChild(),
    DocumentsModule,
    BudgetApprovalModule,
    BuildingProgressModule,
    TagsModule,
    ProjectInfoModule,
    PermissionModule,
    PartialsModule,
    StoreModule.forFeature('projects', projectsReducer),
    StoreModule.forFeature(approvalOrderFeatureState),
    LicenseActivationModule.forRoot(),
    KrosDropdownModule
  ],
  exports: [
    ProjectsComponent
  ],
  providers: [
    ProjectsDispatchersService,
    ProjectsService,
    ProjectsSelectorsService,
    ProjectSharingService,
    BrowserTabService,
    provideBootstrapEffects([
      ProjectsEffects,
      DocumentsEffects,
      DocumentsExplorerEffects,
      DocumentsEditEffects,
      BudgetApprovalEffects,
      BuildingProgressEffects,
      BuildingProgressPartialItemsEffectsService,
      BuildingProgressPeriodsEffects,
      BuildingProgressCommentsEffects,
      BuildingProgressDataSettingsEffects,
      ApprovalOrderEffects
    ]),
    LicenseService,
    ApprovalOrderService,
    BuildingProgressSettingService,
    ApprovalOrderHelper
  ]
})
export class ProjectsModule { }
