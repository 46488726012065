import { createEntityAdapter } from '@ngrx/entity';

import { BuildingProgressSharing } from '../../building-progress/models/building-progress-sharing/building-progress-sharing.model';
import { SharingListState, SubcontractorsSharingListState } from './subcontractors.state';

export const adapterSharingList = createEntityAdapter<BuildingProgressSharing>({
  selectId: sharing => sharing.email
});
export const sharingListInitialState: SharingListState = adapterSharingList.getInitialState({});

export const adapterSubcontractorsSharingList = createEntityAdapter<BuildingProgressSharing>({
  selectId: sharing => sharing.email
});
export const subcontractorsSharingListInitialState: SubcontractorsSharingListState = adapterSubcontractorsSharingList.getInitialState({});
