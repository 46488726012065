import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { getCurrentCompanyIdFromUrl } from '../../../tools';
import { getSearchQuery, paramNameValuePair } from './search-tools';

const ITEMS_SEARCH_TOP_COUNT = 30;

@Injectable()
export abstract class SearchBaseService<T> {

  protected orderBy: string;
  private get topParam(): string {
    return `$top=${ITEMS_SEARCH_TOP_COUNT}`;
  }

  protected constructor(
    protected http: HttpClient,
    protected router: Router,
    protected appConfig: any) {
  }

  protected fetchItems(
    skip: number,
    filter: string,
    searchText: string = '',
    searchIfEmptySearchText: boolean = true,
    searchUrlPostfix: string,
    searchFields: string = '',
    withoutCompany = false,
    findDuplicates = false,
  ): Observable<T[]> {
    if (searchText === '' && !searchIfEmptySearchText) {
      return of([]);
    }
    const url = `${this.urlBase(searchUrlPostfix, withoutCompany)}?${this.topParam}&` +
      this.createSkipParam(skip) +
      getSearchQuery(searchText, filter) +
      `${paramNameValuePair('$orderby', this.orderBy)}` +
      (findDuplicates ? ('&findDuplicates=' + findDuplicates) : '') +
      '&$searchFields=' + searchFields;
    return this.loadItems(url);
  }

  protected abstract mapApiModelToItem(any): T;

  private urlBase(searchUrlPostfix: string, withoutCompany: boolean): string {
    if (withoutCompany) return `${this.appConfig.searchApiUrl}/${searchUrlPostfix}`;
    const companyId = getCurrentCompanyIdFromUrl(this.router.url);
    return `${this.appConfig.searchApiUrl}/companies/${companyId}/${searchUrlPostfix}`;
  }

  private loadItems(url: string): Observable<T[]> {
    return this.http.get<T[]>(url).pipe(
      map((resultItems: any[]) => resultItems.map(item => this.mapApiModelToItem(item))));
  }

  private createSkipParam(skip: number): string {
    return `$skip=${skip}`;
  }
}
