import { Directive, TemplateRef } from '@angular/core';

import { KrosMenuItemComponent } from './menu-item/menu-item.component';

@Directive({
  selector: '[krosSubMenuItem]'
})
export class SubMenuItemDirective {
  constructor(
    tpl: TemplateRef<any>,
    menu: KrosMenuItemComponent
  ) {
    menu.content = tpl;
  }
}
