import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { DxAutocompleteModule, DxSelectBoxModule } from 'devextreme-angular';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { DirectivesModule, ImageUploadButtonModule, PipesModule, TranslateModule } from '@kros-sk/ssw-shared-legacy';
import { KrosDropdownModule, KrosInputsModule } from '@kros-sk/components';
import { TooltipDirective } from '@kros-sk/ui/tooltip';

import {
  BuildingProgressExportAmountsUnixmlComponent
} from './building-progress-export-amounts-unixml/building-progress-export-amounts-unixml.component';
import {
  BuildingProgressExportCompletedWorksComponent
} from './building-progress-export-completed-works/building-progress-export-completed-works.component';
import { BuildingProgressExportComponent } from './building-progress-export/building-progress-export.component';
import {
  BuildingProgressExportConfirmationProtocolComponent
}
  from './building-progress-export-confirmation-protocol/building-progress-export-confirmation-protocol.component';
import { BuildingProgressExportDropdownComponent } from './building-progress-export-dropdown/building-progress-export-dropdown.component';
import {
  BuildingProgressExportInvoiceDetailsComponent
} from './building-progress-export-invoice-details/building-progress-export-invoice-details.component';
import {
  BuildingProgressExportItemDrawSheetComponent
} from './building-progress-export-item-draw-sheet/building-progress-export-item-draw-sheet.component';
import { BuildingProgressExportRemainingBudgetComponent }
  from './building-progress-export-remaining-budget/building-progress-export-remaining-budget.component';
import { BuildingProgressExportSummaryComponent } from './building-progress-export-summary/building-progress-export-summary.component';
import {
  BuildingProgressExportToAnotherProjectComponent
} from './building-progress-export-to-another-project/building-progress-export-to-another-project.component';
import { BuildingProgressExportToAnotherProjectService } from './services';
import { ExportColumsSelectionComponent } from './export-colums-selection/export-colums-selection.component';
import { PeriodsModule } from '../periods/periods.module';
import { RangeModalModule } from '../../shared/range-modal/range-modal.module';

@NgModule({
  declarations: [
    BuildingProgressExportComponent,
    BuildingProgressExportAmountsUnixmlComponent,
    BuildingProgressExportCompletedWorksComponent,
    BuildingProgressExportConfirmationProtocolComponent,
    BuildingProgressExportInvoiceDetailsComponent,
    BuildingProgressExportDropdownComponent,
    BuildingProgressExportItemDrawSheetComponent,
    BuildingProgressExportRemainingBudgetComponent,
    ExportColumsSelectionComponent,
    BuildingProgressExportSummaryComponent,
    BuildingProgressExportToAnotherProjectComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ReactiveFormsModule,
    KrosDropdownModule,
    KrosInputsModule,
    RangeModalModule,
    PeriodsModule,
    NgbDropdownModule,
    DirectivesModule,
    ImageUploadButtonModule,
    PipesModule,
    DxAutocompleteModule,
    DxSelectBoxModule,
    TooltipDirective
  ],
  providers: [
    BuildingProgressExportToAnotherProjectService
  ],
  exports: [
    BuildingProgressExportComponent,
    BuildingProgressExportAmountsUnixmlComponent,
    BuildingProgressExportInvoiceDetailsComponent,
    BuildingProgressExportConfirmationProtocolComponent,
    BuildingProgressExportDropdownComponent,
    BuildingProgressExportItemDrawSheetComponent,
    BuildingProgressExportRemainingBudgetComponent,
    BuildingProgressExportToAnotherProjectComponent
  ]
})
export class ExportModule { }
