import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { LicenseDetail } from '@kros-sk/ssw-cdk';

import * as actions from './license.actions';
import { LicenseInfo } from '../../license';
import { LicenseState } from './license.state';

@Injectable()
export class LicenseDispatchersService {
  constructor(
    private store: Store<LicenseState>
  ) { }

  clearState(): void {
    this.store.dispatch(
      actions.clearState()
    );
  }

  setMishaLicenseInfo(mishaLicenseInfo: LicenseInfo): void {
    this.store.dispatch(
      actions.setMishaLicenseInfo({ mishaLicenseInfo })
    );
  }

  setBuildingProgressLicenseInfo(licenseInfo: LicenseInfo): void {
    this.store.dispatch(
      actions.setBuildingProgressLicenseInfo({ buildingProgressLicenseInfo: licenseInfo })
    );
  }

  setBudgetLicenseInfo(licenseInfo: LicenseInfo): void {
    this.store.dispatch(
      actions.setBudgetLicenseInfo({ budgetLicenseInfo: licenseInfo })
    );
  }

  setQuantityManagerLicenseInfo(licenseInfo: LicenseInfo): void {
    this.store.dispatch(
      actions.setQuantityManagerLicenseInfo({ quantityManagerLicenseInfo: licenseInfo })
    );
  }

  clearMishaLicenseInfo(): void {
    this.store.dispatch(
      actions.clearMishaLicenseInfo()
    );
  }

  clearBuildingProgressLicenseInfo(): void {
    this.store.dispatch(
      actions.clearBuildingProgressLicenseInfo()
    );
  }

  clearBudgetLicenseInfo(): void {
    this.store.dispatch(
      actions.clearBudgetLicenseInfo()
    );
  }

  clearQuantityManagerLicenseInfo(): void {
    this.store.dispatch(
      actions.clearQuantityManagerLicenseInfo()
    );
  }

  setLicenseDetail(licenseDetail: LicenseDetail): void {
    this.store.dispatch(
      actions.setLicenseDetail({ licenseDetail })
    );
  }
}
