import { formatNumber } from '@angular/common';
import { Injectable } from '@angular/core';

import { take } from 'rxjs/operators';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { getFloatFromString, getThousandsSeparator, isNumber } from '@kros-sk/ssw-shared-legacy';
import { isItem, isSummaryItem } from '@kros-sk/ssw-shared-legacy';
import { KrosModalService } from '@kros-sk/components';

import { BuildingProgressActionAccessService } from './building-progress-action-access.service';
import {
  BuildingProgressChangedProperty,
  BuildingProgressEditedPeriodItem,
  BuildingProgressEditModel
} from '../models/building-progress-edit.model';
import { BuildingProgressDispatchersService, BuildingProgressSelectorsService } from '../../store/building-progress';
import { BuildingProgressModel, BuildingProgressPeriodItem } from '../models/construction-data.model';
import { BuildingProgrressEditPercentageModel } from '../models/building-progress-edit-percentage.model';
import { environment } from '../../../environments/environment';
import { getAppInsightsPrefix } from '../shared/building-progress-utils';
import { PercentageDrawDialogComponent } from '../components/percentage-draw-dialog/percentage-draw-dialog.component';
import { PermissionHelper } from '../helpers';
import { TotalPriceSetDialogComponent } from '../components/total-price-set-dialog/total-price-set-dialog.component';
import { TotalPriceSetModel } from '../models/total-price-set.model';

@Injectable()
export class BuildingProgressEditingService {
  constructionData: BuildingProgressModel;

  private selectedItemIds = new Set<number>();
  private _thousandsSeparator: string;

  private get thousandsSeparator(): string {
    if (!this._thousandsSeparator) {
      this._thousandsSeparator = getThousandsSeparator(this.appLocation);
    }
    return this._thousandsSeparator;
  }

  private get appLocation(): string {
    return environment.location;
  }

  constructor(
    private appInsightsService: AppInsightsBaseService,
    buildingProgressSelectorsService: BuildingProgressSelectorsService,
    private buildingProgressDispatchersService: BuildingProgressDispatchersService,
    private buildingProgressActionAccessService: BuildingProgressActionAccessService,
    private permissionHelper: PermissionHelper,
    private krosModalService: KrosModalService,
  ) {
    buildingProgressSelectorsService.constructionData$
      .subscribe((constructionData: BuildingProgressModel) => {
        if (constructionData) {
          this.constructionData = constructionData;
        }
      });

    buildingProgressSelectorsService.selectedItemIds$.subscribe(
      itemIds => {
        if (itemIds && itemIds instanceof Set) {
          this.selectedItemIds = itemIds;
        }
      }
    );
  }

  editPeriodPercentage(value: string, projectId: number, periodId: number, item: any, showSuccessToast?: boolean): void {
    if (isSummaryItem(item)) {
      const editModel: BuildingProgrressEditPercentageModel = {
        projectId,
        actualBudgetItemId: item.id,
        periodId,
        percentage: this.getFloatFromString(value)
      };
      this.editPercentageIfCan(editModel, showSuccessToast);
    } else {
      this.editItemPeriod(value, projectId, periodId, item, BuildingProgressChangedProperty.Percentage, showSuccessToast);
    }
  }

  private editPercentageIfCan(editModel: BuildingProgrressEditPercentageModel, showSuccessToast?: boolean): void {
    if (this.buildingProgressActionAccessService.checkForAccessToBulkOperations()) {
      this.buildingProgressDispatchersService.editPercentage(editModel, this.constructionData, showSuccessToast);
    }
  }

  openEditPercentageDialog(
    constructionData: BuildingProgressModel,
    projectId: number,
    showSuccessToast?: boolean
  ): void {
    const period = constructionData.periods[constructionData.periods.length - 1];
    const modalRef = this.krosModalService.openCentered(
      PercentageDrawDialogComponent,
      {
        period,
        value: formatNumber(0, this.appLocation, `1.${constructionData.decimalPlaces.percentage}`)
      },
      {
        closeOnBackdropClick: false
      }
    );
    modalRef.afterClosed$
      .subscribe(result => {
        if (result.type === 'submit') {
          const editModel: BuildingProgressEditModel = {
            projectId,
            periodId: period.id,
            editedPeriodItems: this.createEditedPeriodItems(
              Array.from(this.selectedItemIds.values()),
              period.id,
              BuildingProgressChangedProperty.Percentage,
              result.data.percentage)
          };

          this.appInsightsService.trackEvent(
            getAppInsightsPrefix(this.permissionHelper.isContractor) + 'selection-percentage-draw'
          );
          this.editConstructionDataIfCan(editModel, true, showSuccessToast);
        }
      });
  }

  editItemPeriod(
    value: string,
    projectId: number,
    periodId: number,
    item: any,
    changedProperty: BuildingProgressChangedProperty,
    showSuccessToast?: boolean): void {
    const editModel: BuildingProgressEditModel = {
      projectId,
      periodId,
      editedPeriodItems: this.createEditedPeriodItems([item.id], periodId, changedProperty, value)
    };

    this.editConstructionDataIfCan(editModel, false, showSuccessToast);
  }

  openTotalPriceSetDialog(constructionData: BuildingProgressModel, projectId: number): void {
    const period = constructionData.periods[constructionData.periods.length - 1];
    this.krosModalService.openCentered(
      TotalPriceSetDialogComponent,
      {
        period,
        totalPrice: formatNumber(0, this.appLocation, `1.${constructionData.decimalPlaces.totalPrice}`),
        currency: constructionData.currency
      },
      {
        closeOnBackdropClick: false
      }
    ).afterClosed$.pipe(take(1))
      .subscribe(result => {
        if (result.type === 'submit') {
          const totalPriceSetModel: TotalPriceSetModel = {
            totalPrice: this.getFloatFromString(result.data.totalPrice),
            projectId,
            selectedBudgetItemIds: Array.from(this.selectedItemIds.values()),
            periodId: period.id
          };

          this.totalPriceSetIfCan(totalPriceSetModel);
        }
      });
  }

  private totalPriceSetIfCan(totalPriceSetModel: TotalPriceSetModel): void {
    if (totalPriceSetModel.selectedBudgetItemIds.length > 0 &&
      this.buildingProgressActionAccessService.checkForAccessToBulkOperations()) {
      this.appInsightsService.trackEvent(getAppInsightsPrefix(this.permissionHelper.isContractor) + 'selection-total-price-set');
      this.buildingProgressDispatchersService.totalPriceSet(totalPriceSetModel, this.constructionData);
    }

    if (totalPriceSetModel.selectedBudgetItemIds.length > 0) {
      this.buildingProgressDispatchersService.clearMultiSelectItems();
    }
  }

  private editConstructionDataIfCan(editModel: BuildingProgressEditModel, areSelectedItems: boolean, showSuccessToast?: boolean): void {
    if ((editModel.editedPeriodItems.length === 1 && !areSelectedItems) ||
      this.buildingProgressActionAccessService.checkForAccessToBulkOperations()) {
      this.buildingProgressDispatchersService.editConstructionData(editModel, false, showSuccessToast);

      if (areSelectedItems) {
        this.buildingProgressDispatchersService.clearMultiSelectItems();
      }
    }
  }

  private createEditedPeriodItems(
    itemIds: number[],
    periodId: number,
    changedProperty: BuildingProgressChangedProperty,
    value: string): BuildingProgressEditedPeriodItem[] {
    const ret: BuildingProgressEditedPeriodItem[] = [];

    itemIds.forEach(id => {
      const item = this.constructionData.items.find(i => i.id === id);
      if (isItem(item)) {
        const periodItem = this.getPeriodItemByPeriodId(item.periods, periodId);
        const editedItem = {
          budgetItemId: id,
          changedProperty: changedProperty.valueOf(),
          amount: periodItem.amount,
          totalPrice: periodItem.totalPrice,
          percentage: periodItem.percentage,
          calculationType: periodItem.calculationType,
          oldValue: 0
        };

        this.setEditedItemChangedValues(editedItem, value, periodItem, changedProperty);
        ret.push(editedItem);
      }
    });

    return ret;
  }

  private setEditedItemChangedValues(
    editedPeriodItem: BuildingProgressEditedPeriodItem,
    value: string,
    periodItem: BuildingProgressPeriodItem,
    changedProperty: BuildingProgressChangedProperty): void {
    const rawValue = this.getFloatFromString(value);
    const floatValue = isNumber(rawValue) ? rawValue : 0;

    editedPeriodItem.changedProperty = changedProperty.valueOf();
    if (changedProperty === BuildingProgressChangedProperty.Percentage) {
      editedPeriodItem.percentage = floatValue;
      editedPeriodItem.oldValue = periodItem.percentage;
    } else if (changedProperty === BuildingProgressChangedProperty.Amount) {
      editedPeriodItem.amount = floatValue;
      editedPeriodItem.oldValue = periodItem.amount;
    } else if (changedProperty === BuildingProgressChangedProperty.TotalPrice) {
      editedPeriodItem.totalPrice = floatValue;
      editedPeriodItem.oldValue = periodItem.totalPrice;
    }
  }

  private getPeriodItemByPeriodId(periods: BuildingProgressPeriodItem[], periodId: number): BuildingProgressPeriodItem {
    return periods.find(p => p.periodId === periodId);
  }

  private getFloatFromString(value: string): number {
    return getFloatFromString(value, this.thousandsSeparator);
  }
}
