import produce from 'immer';

import { BudgetApprovalItem, BudgetApprovalModel, ChangeSheetModel, DeleteBuildingObjectResult } from '../../budget-approval/models';

export const budgetApprovalDataAfterDelete = (
  oldData: BudgetApprovalModel,
  deleteResult: DeleteBuildingObjectResult
): BudgetApprovalModel => {
  return produce(oldData, newData => {
    deleteResult.deletedBuildingObjectIds.forEach(deletedId => {
      const index = newData?.items.findIndex(item => item.id === deletedId);
      if (index !== -1) {
        newData.items.splice(index, 1);
      }
    });

    deleteResult.recalculatedItems.forEach(recalculatedItem => {
      const affectedITem = newData?.items.find(item => item.id === recalculatedItem.id);
      if (affectedITem) {
        affectedITem.notApprovedNegativeTotalPrice = recalculatedItem.notApprovedNegativeTotalPrice;
        affectedITem.notApprovedPositiveTotalPrice = recalculatedItem.notApprovedPositiveTotalPrice;
        affectedITem.notApprovedTotalPrice = recalculatedItem.notApprovedTotalPrice;
        affectedITem.notApprovedPercentage = recalculatedItem.notApprovedPercentage;
        affectedITem.notApprovedActualTotalPrice = recalculatedItem.notApprovedActualTotalPrice;
        affectedITem.notApprovedAbsoluteChangeTotalPrice = recalculatedItem.notApprovedAbsoluteChangeTotalPrice;
        affectedITem.notApprovedAbsoluteChangePercentage = recalculatedItem.notApprovedAbsoluteChangePercentage;
      }
    });
  });
};

export const budgetApprovalDataAfterApprovalChanged = (
  oldData: BudgetApprovalModel,
  items: BudgetApprovalItem[]
): BudgetApprovalModel => {
  return produce(oldData, newData => {
    items.forEach(recalculatedItem => {
      const index = newData.items.findIndex(i => i.id === recalculatedItem.id);
      if (index !== -1) {
        newData.items[index] = recalculatedItem;
      }
    });
  });
};
export const changeSheetDataAfterRelationEdit = (
  oldData: ChangeSheetModel,
  budgetItemId: number,
  originalId: number
): ChangeSheetModel => {
  return produce(oldData, newData => {
    const affectedItem = newData.items.find(item => item.id === budgetItemId);
    if (affectedItem) {
      affectedItem.originalId = originalId;
    }
  });
};

export const changeSheetDataAfterRelationDelete = (
  oldData: ChangeSheetModel,
  budgetItemIds: number[]
): ChangeSheetModel => {
  return produce(oldData, newData => {
    budgetItemIds.forEach(budgetItemId => {
      const affectedItem = newData.items.find(item => item.id === budgetItemId);
      if (affectedItem) {
        affectedItem.originalId = null;
      }
    });
  });
};
