import { createAction, props } from '@ngrx/store';

export const loadDataSettingsStart = createAction('[BuildingProgress] Load data settings start');

export const loadDataSettingsSuccess = createAction(
  '[BuildingProgress] Load data settings success', props<{ hiddenColumns: string[], isSummaryPanelVisible: boolean }>());

export const loadDataSettingsError = createAction('[BuildingProgress] Load data settings error', props<{ error: any }>());

export const saveDataSettings = createAction(
  '[BuildingProgress] Save data settings', props<{ hiddenColumns: string[], isSummaryPanelVisible: boolean }>());
