import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'periodDate' })
export class PeriodDatePipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe
  ) { }

  transform(dateFrom: Date | string, dateTo: Date | string): string {
    return this.datePipe.transform(dateFrom, 'dd.MM.') + ' - ' + this.datePipe.transform(dateTo, 'dd.MM.yyyy');
  }
}
