<div class="kros-modal-header">
  <div
    *ngIf="modalTitleKey"
    class="modal-title"
  >
    <h4>{{ modalTitleKey | translate }}</h4>
  </div>

  <button
    class="btn btn-link close"
    type="button"
    (click)="onClose()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div class="kros-modal-body">
  <p *ngIf="modalTextKey">{{ modalTextKey | translate }}</p>

  <form [formGroup]="form">
    <kros-form-field>
      <kros-label *ngIf="labelKey">
        {{ labelKey | translate }}
      </kros-label>

      <kros-company-search
        *ngIf="useCompanyInput; else simpleInput"
        formControlName="valueControl"
        placeholderText="{{ placeholderKey ? (placeholderKey | translate) + ' *' : ''}}"
        [validationOptions]="companySearchOptions"
        [showAllErrors]="showErrors"
      >
        <kros-error [errorId]="'required'">{{ requiredErrorTooltipKey | translate }}</kros-error>
      </kros-company-search>

      <ng-template #simpleInput>
        <input
          krosControl
          formControlName="valueControl"
          class="form-control"
          type="text"
          (keydown)="onKeyDown($event)"
          placeholder="{{ placeholderKey ? (placeholderKey | translate) + ' *' : ''}}"
          required
        >
      </ng-template>
    </kros-form-field>
  </form>
</div>

<div class="kros-modal-footer">
  <button
    class="btn btn-primary"
    type="button"
    (click)="onSubmit()"
  >{{ modalSubmitKey | translate }}
  </button>
</div>
