<div
  class="data-limit"
  [ngClass]="{'warning': isReachingLimit()}"
>
  {{ showDataStats() }}
  <div class="progress">
    <div
      class="progress-bar"
      role="progressbar"
      [ngClass]="{'bg-danger' : isReachingLimit()}"
      [style.width]="getProgressBarValue() + '%'"
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  </div>
</div>
