<div class="kros-modal-header">
  <div class="modal-title">
    <h4>{{ 'PROJECTS.ZOZNAM.TAGS' | translate }}</h4>
  </div>

  <button
    #closeButton
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onCloseClick()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div
  class="kros-modal-body"
  data-test="edit-tags-modal-body"
>
  <ng-container *ngFor="let tag of tags; let index = index;">
    <ng-container
      [ngTemplateOutlet]="tagTemplate"
      [ngTemplateOutletContext]="{ tag: tag, isDisabled: isTagDisabled(tag) }"
    ></ng-container>

    <div
      *ngIf="isLastProjectTag(tag, tags[index + 1])"
      class="user-tags"
    >{{ 'TAGS.MY_TAGS' | translate }} ({{ 'TAGS.DISPLAYED_ONLY_TO_YOU' | translate }})</div>
  </ng-container>

  <ng-template
    #tagTemplate
    let-tag="tag"
    let-isDisabled="isDisabled"
  >
    <div
      class="tag-row"
      data-test="tag"
      [class.disabled]="isDisabled"
      (click)="toggleTag(tag)"
    >
      <input
        type="checkbox"
        [checked]="isTagActive(tag)"
        [disabled]="isDisabled"
      />
      <kros-tag
        class="tag"
        [color]="tag.textColorCode"
        [backgroundColor]="tag.colorCode"
      >{{ getTagName(tag.name) }}</kros-tag>
    </div>
  </ng-template>
</div>

<div class="kros-modal-footer">
  <ng-container *ngIf="!activeTagsChanged">
    <button
      class="btn btn-link"
      type="button"
      id="ga-project-tags-new-tag"
      data-test="project-tags-new-tag"
      (click)="onNewTagClick()"
    >
      {{ 'TAGS.CREATE_NEW_TAG' | translate }}
    </button>

    <button
      class="btn btn-link"
      type="button"
      id="ga-project-tags-settings"
      data-test="project-tags-settings"
      (click)="onTagSettingsClick()"
    >
      {{ 'TAGS.TAG_MANAGEMENT' | translate }}
    </button>
  </ng-container>

  <button
    *ngIf="activeTagsChanged"
    class="btn btn-primary"
    type="button"
    id="ga-project-tags-submit"
    data-test="project-tags-submit"
    (click)="onSubmitTagsClick()"
  >{{ 'COMMON.APPLY' | translate }}</button>
</div>
