import { inject, Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { ApplicationType } from '@kros-sk/ssw-cdk';
import { BuildingProgressPermissionType } from '@kros-sk/ssw-shared/permission';
import { KrosModalService, MessageTypes } from '@kros-sk/components';
import { TranslateService } from '@kros-sk/ssw-shared-legacy';


@Injectable({ providedIn: 'root'})
export class ApprovalHelper {
  private krosModalService = inject(KrosModalService);
  private translateService = inject(TranslateService);

  isApprover(additionalPermissionType: number, applicationType: ApplicationType = ApplicationType.BuildingProgress): boolean {
    return (additionalPermissionType & BuildingProgressPermissionType.Approver) === BuildingProgressPermissionType.Approver &&
      applicationType === ApplicationType.BuildingProgress;
  }

  showApprovalCancellationDialog(caption?: string, message?: string, acceptButton?: string): Observable<boolean> {
    return this.krosModalService.openModalMessageBox({
      icon: 'danger',
      caption: this.translateService.translate(caption ?? 'BUILDING_PROGRESS.PERIOD_APPROVAL_CANCELLATION_DANGER_CAPTION'),
      message: this.translateService.translate(message ?? 'BUILDING_PROGRESS.PERIOD_APPROVAL_CANCELLATION_DANGER_MESSAGE'),
      messageType: MessageTypes.Danger,
      acceptButton: this.translateService.translate(acceptButton ?? 'BUILDING_PROGRESS.PERIOD_APPROVAL_CANCELLATION_DANGER_ACCEPT'),
      cancelButton: this.translateService.translate('COMMON.CLOSE')
    }).pipe(map(result => result?.data === 'accept'));
  }
}
