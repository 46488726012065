import { getMimeTypeForFileType } from '@kros-sk/ssw-cdk';
import { PermissionType } from '@kros-sk/ssw-shared/permission';

import { BudgetItem } from '../models';
import { BuildingObjectType, ExportFileType } from '../../budget/enums';
import { IBudgetItem } from './budget-item.inteface';
import { IItemType } from './item-type.interface';
import { ItemSharing } from '../../models';

export const descriptionFieldName = 'description';

export const getChildrenIds = (items: BudgetItem[], parentId: string): string[] => {
  const actualChildren = items.filter(i => (i.parentId === parentId) && i.hasChildren).map(i => i.id);

  return [...actualChildren, ...actualChildren.flatMap(childId => getChildrenIds(items, childId))];
};

export function sharingSortFn(left: ItemSharing, right: ItemSharing): number {
  if (left.permissionType === PermissionType.Owner) {
    return -1;
  }
  if (right.permissionType === PermissionType.Owner) {
    return 1;
  }
  const lEmail = left.email.toLowerCase();
  const rEmail = right.email.toLowerCase();

  return lEmail === rEmail ? 0 : lEmail < rEmail ? -1 : 1;
}

export function isConstruction(item: IItemType): boolean {
  return item?.itemType === 'S';
}

export function isBuildingObject(item: IItemType): boolean {
  return isBuildingObjectTypeObject(item) || isBuildingObjectTypeChangeSheet(item);
}

export function isBuildingObjectTypeObject(item: IItemType): boolean {
  return item?.itemType === 'O';
}

export function isBuildingObjectTypeChangeSheet(item: IItemType): boolean {
  return item?.itemType === 'ZL';
}

export function isConstructionOrBuildingObject(item: IItemType): boolean {
  return isConstruction(item) || isBuildingObject(item);
}

export function isSection(item: IItemType): boolean {
  return item?.itemType === 'D';
}

export function isBuildingObjectOrSection(item: IItemType): boolean {
  return isBuildingObject(item) || isSection(item);
}

export function isSummaryItem(item: IItemType): boolean {
  return isConstructionOrBuildingObject(item) || isSection(item) || isGroupItem(item);
}

export function isSectionOrItem(item: IItemType): boolean {
  return isItem(item) || isSection(item);
}

export function isItem(item: IItemType): boolean {
  return isWork(item) || isMaterial(item);
}

export function isWork(item: IItemType): boolean {
  return item?.itemType === 'K';
}

export function isMaterial(item: IItemType): boolean {
  return itemTypeIsMaterial(item?.itemType);
}

export function itemTypeIsMaterial(itemType: string): boolean {
  return itemType === 'M';
}

export function isGroupItem(item: IItemType): boolean {
  return item?.itemType === 'G';
}

export function getNodeLevel(level: number): number {
  return Math.min(level, 10);
}

export function isAdditionOrChangeSheet(item: IBudgetItem): boolean {
  return item.buildingObjectType === BuildingObjectType.Addition || item.buildingObjectType === BuildingObjectType.ChangeSheet;
}

export function getRowType(item: IBudgetItem): string {
  return !!item && isAdditionOrChangeSheet(item) ? 'ZL' : item.itemType;
}

export function getMultiselectSelectedAndIndeterminateItems(
  items: IBudgetItem[],
  selectedItemIds: Set<number>,
  indeterminateItemIds: Set<number>,
  id: number,
  state: boolean,
  selectionNotApplyForParents?: boolean
): { selectedItems: Set<number>, indeterminateItems: Set<number> } {
  const selectedItems = new Set<number>();
  let indeterminateItems = new Set<number>();
  let affectedItems: number[] = [];

  const item = items.find(x => x.id === id);
  const isTableCheckBehaviour = selectionNotApplyForParents === undefined;

  if (state && isTableCheckBehaviour && isSummaryItem(item) && !indeterminateItemIds.has(id)) {
    const result = getMultiselectObjectOrSection(items, item);
    affectedItems = result.selectedItemIds.map(x => x.id);
    indeterminateItems = result.indeterminateItemIds;
  } else {
    affectedItems = getItemIdsAffectedBySelection(items, item);
  }

  const parentItems = getParentItems(items, id).map(x => x.id);

  if (selectedItemIds instanceof Set) {
    selectedItemIds.forEach(element => {
      if (!affectedItems.includes(element) && !parentItems.includes(element)) {
        selectedItems.add(element);
      }
    });

    indeterminateItemIds.forEach(element => {
      if (!affectedItems.includes(element) && !parentItems.includes(element) && !selectionNotApplyForParents) {
        indeterminateItems.add(element);
      }
    });

    if (!selectedItemIds.has(id) || state) {
      affectedItems.forEach(i => selectedItems.add(i));
    }
  } else {
    if (state) {
      affectedItems.forEach(i => selectedItems.add(i));
    }
  }

  if (selectionNotApplyForParents) {
    parentItems.forEach(i => {
      if (selectedItemIds.has(i)) {
        selectedItems.add(i);
      }
    });
  } else {
    parentItems.forEach(i => setItemIndeterminateState(items, i, selectedItems, indeterminateItems));
  }

  return { selectedItems, indeterminateItems };
}

function getItemIdsAffectedBySelection(items: IBudgetItem[], item: IBudgetItem): number[] {
  const ret = [];

  if (!isSummaryItem(item)) {
    ret.push(item.id);
  } else {
    let itemIndex = items.indexOf(item);
    ret.push(item.id);

    while (items[++itemIndex] && items[itemIndex].level > item.level) {
      ret.push(items[itemIndex].id);
    }
  }

  return ret;
}

function getMultiselectObjectOrSection(items: IBudgetItem[], actualItem: IBudgetItem)
  : { selectedItemIds: IBudgetItem[], indeterminateItemIds: Set<number> } {
  const selectedItemIds = [];
  const indeterminateItemIds = new Set<number>();

  indeterminateItemIds.add(actualItem.id);
  let itemIndex = items.indexOf(actualItem);

  while (items[++itemIndex] && items[itemIndex].level > actualItem.level) {
    const child = items[itemIndex];

    if (isSummaryItem(child)) {
      indeterminateItemIds.add(child.id);
    } else {
      selectedItemIds.push(child);
    }
  }

  return { selectedItemIds, indeterminateItemIds };
}

export function getParentItems(items: IBudgetItem[], id: number): IBudgetItem[] {
  let item = items.find(x => x.id === id);
  const ret = [];

  while (item && item.parentId) {
    item = items.find(i => i.id === item.parentId);
    if (item) {
      ret.push(item);
    }
  }

  return ret;
}

function setItemIndeterminateState(
  items: IBudgetItem[],
  id: number,
  selectedItems: Set<number>,
  indeterminateItems: Set<number>): void {
  const item = items.find(x => x.id === id);

  let itemIndex = items.indexOf(item);
  while (items[++itemIndex] && items[itemIndex].level > item.level) {
    if (selectedItems.has(items[itemIndex].id)) {
      indeterminateItems.add(item.id);
      break;
    }
  }
}

export const getExportMimeType = (exportFileType: ExportFileType): string => {
  switch (exportFileType) {
    case ExportFileType.Pdf:
      return getMimeTypeForFileType('pdf');
    case ExportFileType.Xlsx:
      return getMimeTypeForFileType('xlsx');
    case ExportFileType.Xls:
      return getMimeTypeForFileType('xls');
  }
};

export const getExportFileNameExtension = (exportFileType: ExportFileType): string => {
  switch (exportFileType) {
    case ExportFileType.Pdf:
      return '.pdf';
    case ExportFileType.Xlsx:
      return '.xlsx';
    case ExportFileType.Xls:
      return '.xls';
  }
};
