import { ApplicationModule } from '../../application-module';

const czVideoTutorialsUrl = 'https://www.youtube.com/playlist?list=PLKmKV1S1hyPz5Vg1vFjRx5sb3JeqeSRWZ';
const skVideoTutorialsBudgetUrl = 'https://www.kros.sk/stavebny-rozpocet/kros-academy/videonavody/';
const skVideoTutorialsBuildingProgressUrl = 'https://www.kros.sk/priebeh-vystavby/videonavody/';
const skVideoTutorialsQuantityManagerUrl = 'https://www.kros.sk/vymery/kros-academy/videonavody/';

export class VideoTutorialService {

  static readonly czVideoTutorialsUrlAnonymousMode = 'https://youtu.be/8zw0XGyDqw8';
  static readonly skVideoTutorialsUrlAnonymousMode = 'https://youtu.be/FF94gD5HmoM';

  private videoTutorials = new Map([
    [ApplicationModule.None, { Sk: skVideoTutorialsBuildingProgressUrl, Cz: czVideoTutorialsUrl }],
    [ApplicationModule.Projects, { Sk: skVideoTutorialsBudgetUrl, Cz: czVideoTutorialsUrl }],
    [ApplicationModule.ProjectDashboard, { Sk: skVideoTutorialsBudgetUrl, Cz: czVideoTutorialsUrl }],
    [ApplicationModule.Documents, { Sk: skVideoTutorialsBudgetUrl, Cz: czVideoTutorialsUrl }],
    [ApplicationModule.Budget, { Sk: skVideoTutorialsBudgetUrl, Cz: czVideoTutorialsUrl }],
    [ApplicationModule.BudgetApproval, { Sk: skVideoTutorialsBuildingProgressUrl, Cz: czVideoTutorialsUrl }],
    [ApplicationModule.BuildingProgress, { Sk: skVideoTutorialsBuildingProgressUrl, Cz: czVideoTutorialsUrl }],
    [ApplicationModule.QuantityManager, { Sk: skVideoTutorialsQuantityManagerUrl, Cz: czVideoTutorialsUrl }],
    [ApplicationModule.Misha, { Sk: skVideoTutorialsBudgetUrl, Cz: czVideoTutorialsUrl }],
    [ApplicationModule.Bimprix, { Sk: skVideoTutorialsQuantityManagerUrl, Cz: czVideoTutorialsUrl }],
    [ApplicationModule.ProjectReport, { Sk: skVideoTutorialsBuildingProgressUrl, Cz: czVideoTutorialsUrl }],
    [ApplicationModule.DocumentViewer, { Sk: skVideoTutorialsBuildingProgressUrl, Cz: czVideoTutorialsUrl }],
    [ApplicationModule.ProjectSharing, { Sk: skVideoTutorialsBuildingProgressUrl, Cz: czVideoTutorialsUrl }],
  ]);

  getVideoTutorialUrl(applicationModuleId: ApplicationModule, lang: string): string {
    return this.videoTutorials.get(applicationModuleId)[lang];
  }
}
