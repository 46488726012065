import { NgModule } from '@angular/core';

import { AutofocusDirective } from './autofocus/autofocus.directive';
import { AutoselectDirective } from './autofoselect/autoselect.directive';
import { ContenteditableValueAccessor } from './contenteditable';
import { DisableDragDirective } from './disable-drag/disable-drag.directive';
import { IndeterminateCheckboxDirective } from './indeterminate-checkbox/indeterminate-checkbox.directive';
import { MaterialIconDirective } from './material-icon/material-icon.directive';
import { SortableTableHeaderDirective } from './sortable-table-header/sortable-table-header.directive';
import { VirtualScrollDirective } from '../data-table';

const directives = [
  AutofocusDirective,
  AutoselectDirective,
  ContenteditableValueAccessor,
  DisableDragDirective,
  IndeterminateCheckboxDirective,
  MaterialIconDirective,
  SortableTableHeaderDirective,
  VirtualScrollDirective
];

@NgModule({
  declarations: [
    ...directives
  ],
  imports: [],
  exports: [
    ...directives
  ]
})
export class DirectivesModule {
}
