<ng-container *ngFor="let message of (messages$ | async)">
  <div class="message">
    <div class="message-content">
      <p [innerHtml]="message.message"></p>

      <a
        *ngIf="message.hyperlink"
        class="hyper-link"
        target="_blank"
        [href]="message.hyperlink"
        [innerHtml]="message.displayText"
      ></a>
    </div>

    <button
      type="button"
      class="btn btn-link"
      (click)="onClose(message.message)"
    >
      <i class="material-icons">close</i>
    </button>
  </div>
</ng-container>
