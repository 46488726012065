import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

import { DirectivesModule } from '../directives';
import { TranslateModule } from '../translate';
import { UploaderService } from './services/uploader.service';
import { UploadNotificationService } from './services/upload-notification.service';
import { UploadSnackbarComponent } from './upload-snackbar/upload-snackbar.component';
import { ZipperService } from './services/zipper.service';

@NgModule({
  declarations: [
    UploadSnackbarComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    NgbToastModule,
    DirectivesModule
  ],
  exports: [
    UploadSnackbarComponent
  ],
  providers: [
    UploaderService,
    ZipperService,
    UploadNotificationService
  ]
})
export class UploadModule { }
