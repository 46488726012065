import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { formatString, LicenseType } from '@kros-sk/ssw-cdk';

import { LicenseInfo } from './license-info.model';
import { PluralisationByCountPipe } from '../pipes';
import { TranslateService } from '../translate';

@Pipe({
  name: 'licenseInfoText',
  pure: false
})
export class LicenseInfoTextPipe implements PipeTransform {
  constructor(
    private pluralisationByCountPipe: PluralisationByCountPipe,
    private datePipe: DatePipe,
    private translateService: TranslateService
  ) { }

  transform(licenseInfo: LicenseInfo, isMobile: boolean, canActivateTrial: boolean): string {
    if (licenseInfo && licenseInfo.license) {
      switch (licenseInfo.license.licenseType) {
        case LicenseType.Free:
          return this.translateService.translate(isMobile
            ? canActivateTrial
              ? licenseInfo.freeCanActivateTrialTextKeyPhone
              : licenseInfo.freeTextKeyPhone
            : canActivateTrial
              ? licenseInfo.freeCanActivateTrialTextKey
              : licenseInfo.freeTextKey);
        case LicenseType.Trial:
          return formatString(
            this.translateService.translate(
              isMobile
                ? licenseInfo.trialTextKeyPhone
                : licenseInfo.trialTextKey),
            '' + licenseInfo.license.remainingDays,
            this.pluralisationByCountPipe.transform(licenseInfo.license.remainingDays, 'LICENSE_INFO.DAY'));
        case LicenseType.Readonly:
          return formatString(
            this.translateService.translate(isMobile
              ? licenseInfo.readonlyTextKeyPhone
              : licenseInfo.readonlyTextKey),
            this.datePipe.transform(licenseInfo.license.expirationDate, 'dd.MM.yyyy'));
        case LicenseType.Full:
          return formatString(
            this.translateService.translate(isMobile
              ? licenseInfo.licenseEndingTextKeyPhone
              : licenseInfo.licenseEndingTextKey),
            '' + licenseInfo.license.remainingDays,
            this.pluralisationByCountPipe.transform(licenseInfo.license.remainingDays, 'LICENSE_INFO.DAY'));
      }
    }

    return '';
  }
}
