import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, Observable, throwError } from 'rxjs';

import { isOwnersEmail, Project, TranslateService } from '@kros-sk/ssw-shared-legacy';

import {
  ApplicationPermission,
  ApplicationSharingViewModel,
  ProjectOwnerEditViewModel,
  ProjectSharingResultInfo,
  ProjectSharingResultInfoEnum
} from '../models';
import { environment } from '../../../environments/environment';

const projectSharingApi = '/api/projectService/ProjectSharing';
const applicationPermissionsApi = '/api/permissionService/applicationPermissions';
const projectPermissionApi = '/api/permissionService/projectPermissions';
const projectUserSharingListEndpoint = '/projectsUserSharingList';
const projectOwnerEditEndpoint = '/ownerEdit';
const projectRemoveSharingEndpoint = '/delete';

@Injectable()
export class ProjectSharingService {

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
  ) { }

  private get projectSharingApi(): string {
    return environment.appUrls.titanGatewayUrl + projectSharingApi;
  }

  private get applicationPermissionsApi(): string {
    return environment.appUrls.titanGatewayUrl + applicationPermissionsApi;
  }

  private get projectPermissionApi(): string {
    return environment.appUrls.titanGatewayUrl + projectPermissionApi;
  }

  getProjectsUserSharingList(projectIds: number[]): Observable<any[]> {
    return this.http.post<any[]>(this.projectSharingApi + projectUserSharingListEndpoint, projectIds)
      .pipe(
        catchError(this.handleShareError.bind(this))
      );
  }

  editOwner(ownerEditViewModel: ProjectOwnerEditViewModel): Observable<any> {
    return this.http.post<any>(this.projectPermissionApi + projectOwnerEditEndpoint, ownerEditViewModel)
      .pipe(
        catchError(this.handleShareError.bind(this))
      );
  }

  getApplicationPermissions(projectId: number): Observable<ApplicationPermission[]> {
    return this.http.get<ApplicationPermission[]>(this.applicationPermissionsApi + `/permissionList/${projectId}`);
  }

  shareApplications(shareViewModel: ApplicationSharingViewModel): Observable<ProjectSharingResultInfo> {
    return this.http.post<ProjectSharingResultInfo>(this.applicationPermissionsApi + '/permissonListSet', shareViewModel)
      .pipe(
        catchError(this.handleShareError.bind(this))
      );
  }

  removeSharing(projectId: number): Observable<any> {
    return this.http.delete<any>(`${this.projectSharingApi + projectRemoveSharingEndpoint}/${projectId}`)
      .pipe(
        catchError(this.handleShareError.bind(this))
      );
  }

  private handleShareError(error: HttpErrorResponse): Observable<never> {
    const info: ProjectSharingResultInfo = error.error;
    let errorMessage: string;

    if (info) {
      switch (info.result) {
        case ProjectSharingResultInfoEnum.ProjectDoesNotExist:
          errorMessage = this.translateService.translate('PROJECTS.ERROR.PROJECT_NOT_EXISTS');
          break;
        case ProjectSharingResultInfoEnum.UserWithEmailNotFound:
          errorMessage = this.translateService.translate('PROJECTS.ERROR.EMAIL_NOT_EXISTS');
          break;
        case ProjectSharingResultInfoEnum.NoPermissionForSharingProject:
          errorMessage = this.translateService.translate('PROJECTS.ERROR.SHARING_INSSUFICIENT_RIGTHS');
          break;
        case ProjectSharingResultInfoEnum.SelfSharingNotAllowed:
          errorMessage = this.translateService.translate('PROJECTS.ERROR.SHARING_TO_YOURSELF');
          break;
        case ProjectSharingResultInfoEnum.EmailAddressSetToMoreUsers:
          errorMessage = this.translateService.translate('PROJECTS.ERROR.DUPLICATE_EMAIL');
          break;
        case ProjectSharingResultInfoEnum.ProjectSharingAlreadyExists:
          errorMessage = this.translateService.translate('PROJECTS.ERROR.SHARING_ALREADY_EXISTS');
          break;
        default:
          errorMessage = this.translateService.translate('SHARING.UNKNOWN_ERROR');
          break;
      }
    } else {
      errorMessage = error.message;
    }

    console.error(
      `Backend returned code ${error.status}, ` +
      `error message: ${errorMessage}`
    );

    return throwError(() => new Error(errorMessage));
  }

  canShareProject(project: Project): boolean {
    return isOwnersEmail(project.owner);
  }
}
