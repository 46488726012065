import { ActionCreator, Creator } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

export function waitForResultAction(
  action$: Actions,
  successAction: ActionCreator<string, Creator>,
  failureAction: ActionCreator<string, Creator>): Promise<boolean> {
  return firstValueFrom(action$.pipe(
    ofType(
      successAction,
      failureAction
    ),
    map((action: { type: string }) => action.type === successAction.type)
  ));
}
