import { Injectable } from '@angular/core';

import { KrosModalService } from '@kros-sk/components';

import { DocumentListInfoModel } from '../../document-explorer';
import { FolderRenameDialogComponent } from '../components/folder-rename-dialog/folder-rename-dialog.component';

@Injectable()
export class DocumentModalEditService {

  constructor(
    private krosModalService: KrosModalService
  ) { }

  onRenameFolder(document: DocumentListInfoModel, projectId: number): void {
    this.krosModalService.openCentered(
      FolderRenameDialogComponent,
      {
        document,
        projectId
      },
      {
        allowFocusAutocapture: false,
        closeOnBackdropClick: false,
        showMobileArrowBack: true,
        fullscreenOnMobile: true
      });
  }
}
