import { createAction, props } from '@ngrx/store';

import { ApprovalOrder } from '../models';

export const loadApprovalOrderStart = createAction(
  '[BuildingProgress] Load approval order start',
  props<{ projectId: number }>());
export const loadApprovalOrderSuccess = createAction(
  '[BuildingProgress] Load approval order success',
  props<{ approvalOrders: ApprovalOrder[] }>());
export const loadApprovalOrderError = createAction('[BuildingProgress] Load approval order error', props<{ error: any }>());

export const clearApprovalOrderStart = createAction('[BuildingProgress] Clear approval order');

export const saveApprovalOrderStart = createAction(
  '[BuildingProgress] Save approval order start',
  props<{ approvalOrders: ApprovalOrder[], projectId: number }>());
export const saveApprovalOrderSuccess = createAction(
  '[BuildingProgress] Save approval order success',
  props<{ approvalOrders: ApprovalOrder[], projectId: number }>());
export const saveApprovalOrderError = createAction('[BuildingProgress] Save approval order error', props<{ error: any }>());
