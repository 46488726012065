import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';

import { BuildingProgressDispatchersService, BuildingProgressSelectorsService } from '../../../../store/building-progress';
import { BuildingProgressPeriod } from '../../../models/building-progress-period.model';
import { BuildingProgressViewsService } from '../../../services/building-progress-views.service';
import { PermissionHelper } from '../../../helpers';

@Component({
  selector: 'app-building-progress-filter',
  templateUrl: './building-progress-filter.component.html',
  styleUrls: ['./building-progress-filter.component.scss']
})
export class BuildingProgressFilterComponent extends UnsubscribeComponent implements OnInit {
  @Input() showColumnFilter = true;
  @Input() showSearch = true;
  @Input() isSummaryPanelButtonVisible = true;

  @Output() refresh = new EventEmitter<void>();
  @Output() summaryPanel = new EventEmitter<void>();

  periods: BuildingProgressPeriod[] = [];

  constructor(
    private appInsightsService: AppInsightsBaseService,
    private dispatchersService: BuildingProgressDispatchersService,
    private selectorsService: BuildingProgressSelectorsService,
    private viewsService: BuildingProgressViewsService,
    public permissionHelper: PermissionHelper
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.selectorsService.periods$
      .subscribe(resp => {
        if (resp) {
          this.periods = resp;
        }
      });
  }

  onDefaultViewClick(): void {
    if (this.periods.length > 0) {
      this.dispatchersService.clearAllMarkedPeriodsInFilter(this.periods);
      this.dispatchersService.markPeriodInFilter(this.periods[0].id, true);
    }

    this.viewsService.setDefaultView();
    this.appInsightsService.trackEvent('PV-default-view');
  }

  onRefresh(): void {
    this.refresh.emit();
  }

  onSummaryPanel(): void {
    this.summaryPanel.emit();
  }
}
