type FileType = 'pdf' | 'xlsx' | 'xls';

/**
 * Get mime type based on file type
 * @param fileType Type of the file (function currently supports pdf and xlsx)
 * @returns mime type
 */
export const getMimeTypeForFileType = (fileType: FileType): string => {
  switch (fileType) {
    case 'pdf':
      return 'application/pdf';
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    case 'xls':
      return 'application/vnd.ms-excel';
  }
};
