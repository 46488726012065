import { Injectable } from '@angular/core';

import { createFeatureSelector, createSelector, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { DecimalPlaces } from '@kros-sk/ssw-shared-legacy';

import { buildingProgressFeatureKey } from '../building-progress-selectors.helper';
import { BuildingProgressState } from '../building-progress.state';
import { PartialBudgetItems } from '../../../building-progress/models/building-progress-partial-budget-items.model';
import { ProjectsState } from '../../projects';

const projectsFeatureKey = 'projects';

const selectBuildingProgressFeature = createFeatureSelector<BuildingProgressState>(buildingProgressFeatureKey);
const selectProjectsFeature = createFeatureSelector<ProjectsState>(projectsFeatureKey);

const selectPartialitems = createSelector(
  selectBuildingProgressFeature,
  state => state.partialItems
);

const selectProjectDetail = createSelector(
  selectProjectsFeature,
  state => state.projectDetail?.id
);

const selectPartialItemsCounter = createSelector(
  selectBuildingProgressFeature,
  state => state.partialItems.counter
);

const selectDecimalPlaces = createSelector(
  selectBuildingProgressFeature,
  state => state.constructionData?.decimalPlaces
);

const selectPartialItemAmount = createSelector(
  selectBuildingProgressFeature,
  state => state.partialItemAmount
);


@Injectable()
export class BuildingProgressPartialItemsSelectorsService {

  partialItemsData$: Observable<PartialBudgetItems> = this.store.select(selectPartialitems);
  projectId$: Observable<number> = this.store.select(selectProjectDetail);
  partialItemsCounter$: Observable<number> = this.store.select(selectPartialItemsCounter);
  decimalPlaces$: Observable<DecimalPlaces> = this.store.select(selectDecimalPlaces);
  partialItemAmount$: Observable<number> = this.store.select(selectPartialItemAmount);

  constructor(
    private store: Store<BuildingProgressState>
  ) { }
}
