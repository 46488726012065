import { Action, createReducer, on } from '@ngrx/store';

import * as listActions from './undo-redo.actions';
import { History, HistoryState, initialHistoryState } from './undo-redo.state';

function undo(state: History): History {
  const latestPast = state.past[state.past.length - 1];
  const futureWithLatestPast = [latestPast, ...state.future];
  const pastWithoutLatest = state.past.slice(0, -1);

  return {
    past: pastWithoutLatest,
    future: futureWithLatestPast
  };
}

function redo(state: History): History {
  const [latestFuture, ...futureWithoutLatest] = state.future;
  const pastWithLatestFuture = [...state.past, latestFuture];

  return {
    past: pastWithLatestFuture,
    future: futureWithoutLatest
  };
}

const reducer = createReducer(
  initialHistoryState,
  on(listActions.undo, (state, payload): HistoryState => {
    return {
      ...state,
      isInProgress: true
    };
  }),
  on(listActions.redo, (state, payload): HistoryState => {
    return {
      ...state,
      isInProgress: true
    };
  }),
  on(listActions.undoSuccess, (state): HistoryState => {
    return {
      ...state,
      history: undo(state.history),
      isInProgress: false
    };
  }),
  on(listActions.redoSuccess, (state): HistoryState => {
    return {
      ...state,
      history: redo(state.history),
      isInProgress: false
    };
  }),
);

export function undoRedoReducer(state: HistoryState, action: Action): any {
  return reducer(state, action);
}
