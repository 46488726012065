import { ActivatedRoute, Params } from '@angular/router';
import { inject, InjectionToken } from '@angular/core';

import { distinctUntilChanged, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { SearchMode, SearchModel } from '../search-input';

const defaultHighlightTags = {
  pre: '<span style="background-color: #ffff00">',
  post: '</span>'
};

const encodeSearchQueryParam = (paramName, paramValue): string => {
  return paramName && paramValue ? `$${paramName}=${encodeURIComponent(paramValue)}` : '';
};

export const SEARCH_TEXT = new InjectionToken('Search text token', {
  factory(): Observable<any> {
    const activatedRoute = inject(ActivatedRoute);

    return activatedRoute.queryParams.pipe(
      map((params) => params?.search),
      distinctUntilChanged()
    );
  }
});

export const SEARCH_FILTER = new InjectionToken('Search filter token', {
  factory(): Observable<any> {
    const activatedRoute = inject(ActivatedRoute);

    return activatedRoute.queryParams.pipe(
      map((params) => params?.filter),
      distinctUntilChanged()
    );
  }
});

export const getSearchQuery = (
  searchMode: string,
  searchText: string,
  filter?: string,
  searchHighlight?: string[],
  lead: string = '?',
  queryParams: any = {}
): string => {
  const queryParts = [];
  const uriSearch = encodeSearchQueryParam('search', searchText);
  const uriFilter = encodeSearchQueryParam('filter', filter);
  const uriQueryParams = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');

  if (uriSearch) {
    queryParts.push(
      encodeSearchQueryParam('searchMode', searchMode),
      uriSearch
    );
    if (searchHighlight) {
      queryParts.push(
        encodeSearchQueryParam('highlight', searchHighlight.join(',')),
        encodeSearchQueryParam('highlightPreTag', defaultHighlightTags.pre),
        encodeSearchQueryParam('highlightPostTag', defaultHighlightTags.post)
      );
    }
  }

  if (uriFilter) {
    queryParts.push(uriFilter);
  }

  if (uriQueryParams) {
    queryParts.push(uriQueryParams);
  }

  return queryParts.length > 0 ? lead + queryParts.join('&') : '';
};

export const parseSearchQueryParam = (searchParam: string, filterParam?: string): SearchModel => {
  const splittedParam = searchParam ? decodeURIComponent(searchParam).split(';') : [];
  return {
    searchText: splittedParam[0] ?? '',
    searchMode: splittedParam[0] ? splittedParam[1] as SearchMode : undefined,
    filter: filterParam ? decodeURIComponent(filterParam) : ''
  };
};

export const createQueryParams = (searchText: string, searchMode: string, searchFilter?: string): Params => {
  let searchQueryParams; let filterQueryParams;
  if (searchText !== undefined) {
    searchQueryParams = { search: searchText ? encodeURIComponent(searchText.concat(';', searchMode)) : undefined };
  }
  if (searchFilter !== undefined) {
    filterQueryParams = { filter: searchFilter ? encodeURIComponent(searchFilter) : undefined };
  }

  return { ...searchQueryParams, ...filterQueryParams };
};

export const getSearchParamsFromRoute = (route: ActivatedRoute, paramsToGet?: Params): Params => {
  const hasParamsToGet = !!paramsToGet && !!Object.keys(paramsToGet).length;
  const parsedParams = parseSearchQueryParam(
    !hasParamsToGet || paramsToGet.hasOwnProperty('search') ? route.snapshot.queryParamMap.get('search') : undefined,
    !hasParamsToGet || paramsToGet.hasOwnProperty('filter') ? route.snapshot.queryParamMap.get('filter') : undefined
  );

  return createQueryParams(parsedParams.searchText, parsedParams.searchMode, parsedParams.filter);
};

export const removeSearchHighlights = (text: string): string => {
  return text?.replace(new RegExp(`${defaultHighlightTags.pre}|${defaultHighlightTags.post}`, 'g'), '');
};

export const isSearchActive = (searchModel: SearchModel): boolean => {
  return (!!searchModel?.searchText || !!searchModel?.filter);
};
