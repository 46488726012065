import { Action, createReducer, on } from '@ngrx/store';

import * as listActions from './subcontractors.actions';
import { adapterSharingList, adapterSubcontractorsSharingList } from './subcontractors.adapters';
import { initialSubcontractorsState, SubcontractorsState } from './subcontractors.state';

const reducer = createReducer(
  initialSubcontractorsState,
  on(listActions.loadSubcontractorsStart, (state): SubcontractorsState => {
    return {
      ...state,
      subcontractors: [],
      selectedSubcontractor: undefined,
      sharingList: adapterSharingList.removeAll(state.sharingList),
      subcontractorsSharingList: adapterSharingList.removeAll(state.subcontractorsSharingList),
      subcontractorsPeriods: undefined
    };
  }),
  on(listActions.loadSubcontractorsSuccess, (state, payload): SubcontractorsState => {
    const selectedContractorId = payload.selectedContractorId ?? state.selectedSubcontractor.id;
    const selectedSubcontractor =
      selectedContractorId && payload.subcontractors.some(s => s.id === selectedContractorId)
        ? payload.subcontractors.find(s => s.id === selectedContractorId)
        : payload.subcontractors[0];
    return {
      ...state,
      subcontractors: payload.subcontractors,
      selectedSubcontractor
    };
  }),
  on(listActions.selectSubcontractor, (state, payload): SubcontractorsState => {
    return {
      ...state,
      selectedSubcontractor: payload.subcontractor,
    };
  }),
  on(listActions.addSubcontractorItemsSuccess, (state): SubcontractorsState => {
    return {
      ...state,
      selectedSubcontractor: { ...state.selectedSubcontractor },
    };
  }),
  on(listActions.removeSubcontractorItemsSuccess, (state): SubcontractorsState => {
    return {
      ...state,
      selectedSubcontractor: { ...state.selectedSubcontractor },
    };
  }),
  on(listActions.loadDialogItemsListStart, (state): SubcontractorsState => {
    return {
      ...state,
      dialogItemsList: undefined,
      dialogItemsListLoading: true
    };
  }),
  on(listActions.loadDialogItemsListSuccess, (state, payload): SubcontractorsState => {
    return {
      ...state,
      dialogItemsList: payload.dialogItemsList,
      dialogItemsListLoading: false
    };
  }),
  on(listActions.loadSharingListSuccess, (state, payload): SubcontractorsState => {
    return {
      ...state,
      sharingList: adapterSharingList.setAll(payload.sharingList, state.sharingList),
    };
  }),
  on(listActions.loadSubcontractorsSharingListSuccess, (state): SubcontractorsState => {
    return {
      ...state,
      subcontractorsSharingList: adapterSubcontractorsSharingList.removeAll(state.subcontractorsSharingList)
    };
  }),
  on(listActions.loadSubcontractorsSharingListSuccess, (state, payload): SubcontractorsState => {
    return {
      ...state,
      subcontractorsSharingList: adapterSubcontractorsSharingList.setAll(payload.sharingList, state.subcontractorsSharingList),
    };
  }),
  on(listActions.loadSubcontractorsPeriodsStart, (state): SubcontractorsState => {
    return { ...state, subcontractorsPeriods: undefined };
  }),
  on(listActions.loadSubcontractorsPeriodsSuccess, (state, payload): SubcontractorsState => {
    return { ...state, subcontractorsPeriods: payload.periods };
  })
);

export function subcontractorsReducer(state: SubcontractorsState | undefined, action: Action): SubcontractorsState {
  return reducer(state, action);
}
