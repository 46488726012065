import { Component, EventEmitter, inject, Input, Output } from '@angular/core';

import { take } from 'rxjs';

import { BuildingProgressPermission, PermissionType } from '@kros-sk/ssw-shared/permission';
import { KrosModalService } from '@kros-sk/components';
import { SearchModel } from '@kros-sk/ssw-shared-legacy';

import { BuildingProgressPeriod } from '../../../../building-progress/models/building-progress-period.model';
import { hasContractorRight } from '../../../../building-progress/shared/building-progress-utils';
import { PeriodDatePipe } from '../../../../building-progress/modules/periods/pipes/period-date.pipe';
import { PeriodsDialogComponent } from '../periods-dialog/periods-dialog.component';

@Component({
  selector: 'app-period-select-button',
  templateUrl: './period-select-button.component.html',
  styleUrls: ['./period-select-button.component.scss']
})
export class PeriodSelectButtonComponent {
  @Input() hasConstructionData: boolean;
  @Input() periods: BuildingProgressPeriod[];
  @Input() selectedPeriodId: number;
  @Input() projectId: number;
  @Input() searchModel: SearchModel;
  @Input() permission: BuildingProgressPermission;
  @Input() readonly: boolean;

  @Output() periodSelected = new EventEmitter<BuildingProgressPeriod>();

  get selectedPeriodDate(): string {
    const selectedPeriod = this.periods?.find(period => period.id === this.selectedPeriodId);
    return selectedPeriod ? this.periodDatePipe.transform(selectedPeriod.dateFrom, selectedPeriod.dateTo) : '';
  }

  get canAddPeriod(): boolean {
    return !this.readonly && this.hasConstructionData && this.permission &&
      (this.permission.permissionType === PermissionType.Contributor || this.permission.permissionType === PermissionType.Owner ||
        hasContractorRight(this.permission.buildingProgressPermissionType));
  }

  private periodDatePipe = inject(PeriodDatePipe);
  private krosModalService = inject(KrosModalService);

  openPeriodsDialog(): void {
    const modalRef = this.krosModalService.openCentered(
      PeriodsDialogComponent,
      {
        projectId: this.projectId,
        searchModel: this.searchModel,
        permission: this.permission,
        readonly: this.readonly
      },
      {
        addModalToBrowsersHistory: false
      }
    );

    modalRef.afterClosed$
      .pipe(take(1))
      .subscribe(result => {
        if (result.type === 'submit' && result.data?.viewPeriod) {
          this.selectPeriod(result.data.viewPeriod);
        }
      });
  }

  private selectPeriod(period: BuildingProgressPeriod): void {
    this.periodSelected.emit(period);
  }
}
