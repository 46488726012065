export function invoiceCountPluralisation(count: number): string {
  if (count === 0) {
    return count + ' faktúr';
  } else if (count === 1) {
    return count + ' faktúra';
  } else if (count <= 4) {
    return count + ' faktúry';
  } else {
    return count + ' faktúr';
  }
}

export function dayCountPluralisation(count: number): string {
  if (count === 1) {
    return count + ' deň';
  } else if (count <= 4) {
    return count + ' dni';
  } else {
    return count + ' dní';
  }
}

export function dayKeyCountPluralisation(count: number, translateKeyBase: string): string {
  if (count === 1) {
    return translateKeyBase;
  } else if (count <= 4) {
    return translateKeyBase + 'S_TO_4';
  } else {
    return translateKeyBase + 'S';
  }
}