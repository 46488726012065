import { Injectable } from '@angular/core';

import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { PermissionType } from '@kros-sk/ssw-shared/permission';

import { adapterDocumentList, adapterDocumentVersionList, adapterMarkedDocuments } from './documents-explorer.adapters';
import { DocumentListInfoModel, DocumentVersionDto, FolderTreeNode } from '../models';
import { DocumentsState } from './documents-explorer.state';

export const documentsFeatureKey = 'documents';

const selectDocumentsFeature = createFeatureSelector<DocumentsState>(documentsFeatureKey);

const selectDocumentsEntities = createSelector(
  selectDocumentsFeature,
  state => state.documentList
);

const selectDocuments = createSelector(
  selectDocumentsEntities,
  adapterDocumentList.getSelectors().selectAll
);

const selectRootDocumentPermission = createSelector(
  selectDocumentsFeature,
  state => state.rootDocumentPermission
);

const selectIsDocumentsListLoaded = createSelector(
  selectDocumentsFeature,
  state => state.isDocumentsListLoaded
);

const selectMarkedDocumentsEntities = createSelector(
  selectDocumentsFeature,
  state => state.markedDocuments
);

const selectMarkedDocuments = createSelector(
  selectMarkedDocumentsEntities,
  adapterMarkedDocuments.getSelectors().selectAll
);

const selectMarkedDocument = createSelector(
  selectDocuments,
  selectMarkedDocuments,
  (allDocuments: DocumentListInfoModel[], markedDocuments: string[]) => {
    return allDocuments.find(doc => doc.id === markedDocuments[0]);
  }
);

const selectCurrentFolder = createSelector(
  selectDocumentsFeature,
  state => state.currentFolder
);

const selectProjectId = createSelector(
  selectDocumentsFeature,
  state => state.projectId
);

const selectCurrentFolderId = createSelector(
  selectDocumentsFeature,
  state => state.currentFolder?.id ?? ''
);

const selectVersionList = createSelector(
  selectDocumentsFeature,
  state => state.documentVersionList
);

const selectDocumentVersionList = createSelector(
  selectVersionList,
  adapterDocumentVersionList.getSelectors().selectAll
);

@Injectable()
export class DocumentsExplorerSelectorsService {
  constructor(protected documentsExplorerStore: Store<DocumentsState>) { }

  get documents$(): Observable<DocumentListInfoModel[]> {
    return this.documentsExplorerStore.select(selectDocuments);
  }

  get rootDocumentPermission$(): Observable<PermissionType> {
    return this.documentsExplorerStore.select(selectRootDocumentPermission);
  }

  get isDocumentsListLoaded$(): Observable<boolean> {
    return this.documentsExplorerStore.select(selectIsDocumentsListLoaded);
  }

  get selectMarkedDocuments$(): Observable<string[]> {
    return this.documentsExplorerStore.select(selectMarkedDocuments);
  }

  get selectMarkedDocument$(): Observable<DocumentListInfoModel | null> {
    return this.documentsExplorerStore.select(selectMarkedDocument);
  }

  get currentFolder$(): Observable<FolderTreeNode> {
    return this.documentsExplorerStore.select(selectCurrentFolder);
  }

  get projectId$(): Observable<number> {
    return this.documentsExplorerStore.select(selectProjectId);
  }

  get currentFolderId$(): Observable<string> {
    return this.documentsExplorerStore.select(selectCurrentFolderId);
  }

  get selectDocumentVersion$(): Observable<DocumentVersionDto[]> {
    return this.documentsExplorerStore.select(selectDocumentVersionList);
  }
}
