import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { UserDataStatisticsModel } from '../../models/user-data-statistics.model';

@Injectable()
export class UserDataStatisticsService {
  dataUsage$ = new Subject<number>();

  constructor(
    private http: HttpClient,
  ) { }

  getUserDataStatistics(): Observable<UserDataStatisticsModel> {
    return this.http.get<UserDataStatisticsModel>(environment.appUrls.titanGatewayUrl + '/api/UsersDataStatistics');
  }

  updateConsumedData(dataConsumed: number): void {
    this.dataUsage$.next(dataConsumed);
  }
}
