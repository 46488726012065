import { Injectable } from '@angular/core';

import { ApplicationInsights, IConfig, IConfiguration, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { Observable, ReplaySubject } from 'rxjs';

/**
 * @deprecated use AppInsightsBaseService instead:
 * libs/shared/core/application-insights/src/lib/application-insights/app-insights.service.ts.
 */
@Injectable({
  providedIn: 'root'
})
export class KrosAppInsightsService {

  /**
   * Disable app insights logging on demand.
   */
  disabled = false;

  private appInsights: ApplicationInsights | null = null;
  private aiCloudRole = '';
  private userContextDefined = new ReplaySubject<boolean>();

  isUserContextDefined$: Observable<boolean> = this.userContextDefined;

  constructor() { }

  /**
   * Initialize Application Insights instance.
   * @param instrumentationKey obtained from Azure Portal
   * @param aiCloudRole application identifier, i.e. 'Kros.Esw.FE.Invoicing'
   * @param samplingPercentage
   */
  initAppInsights(
    instrumentationKey: string,
    aiCloudRole: string,
    samplingPercentage: number,
    customConfig?: IConfiguration & IConfig
  ): void {
    if (!instrumentationKey) return;

    this.aiCloudRole = aiCloudRole;
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
        disableAjaxTracking: true,
        ...customConfig
        // samplingPercentage,
      },
    });
    this.appInsights.loadAppInsights();
    this.appInsights.addTelemetryInitializer(this.telemetryInitializer);
  }

  /**
   * Track custom event.
   * @param name custom event name
   * @param properties custom properties
   */
  trackEvent(name: string, properties?: { [key: string]: any }): void {
    if (!this.appInsights || this.disabled) return;
    this.appInsights.trackEvent({ name }, properties);
  }

  /**
   * Track exception event.
   * @param error error instance to log
   * @param handler handler name
   * @param properties custom properties
   */
  trackException(error: Error, handler: string, properties?: { [key: string]: any }): void {
    if (!this.appInsights || this.disabled) return;
    this.appInsights.trackException(
      { exception: error },
      { ...properties, handler }
    );
  }

  /**
   * Set the authenticated user id and the account id.
   * Used for identifying a specific signed-in user.
   * Parameters must not contain whitespace or ,;=|
   *
   * The method will only set the `authenticatedUserId` and `accountId` in the current page view.
   * To set them for the whole session, you should set `storeInCookie = true`
   * @param authenticatedUserId an id that uniquely identifies a user of your app
   * @param [accountId] an optional account id, if your app groups users into accounts
   * @param [storeInCookie=false] true if you want to set `authenticatedUserId` and `accountId` for the whole session
   */
  setAuthenticatedUserContext(authenticatedUserId: string, accountId?: string, storeInCookie?: boolean): void {
    this.appInsights?.setAuthenticatedUserContext(authenticatedUserId, accountId, storeInCookie);
    this.userContextDefined.next(true);
  }

  /**
   * Clears the authenticated user id and account id. The associated cookie is cleared, if present.
   */
  clearAuthenticatedUserContext(): void {
    this.appInsights?.clearAuthenticatedUserContext();
    this.userContextDefined.next(false);
  }

  // function for filtering and expanding data sent to Azure
  private telemetryInitializer = (item: ITelemetryItem): boolean => {
    if (item.tags) {
      // cloud role name
      item.tags['ai.cloud.role'] = this.aiCloudRole;
    }
    if (item.data) {
      // custom data
      item.data['krosEswAppVersion'] = (window as any).eswAppVersion?.date;
      // referer domain
      item.data['refererDomain'] = (window as any).location?.hostname;
    }
    return true;
  };

}
