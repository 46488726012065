import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { KrosModalRef } from '@kros-sk/components';

import { PeriodDatePipe } from '../../../building-progress/modules/periods/pipes/period-date.pipe';

@Component({
  selector: 'app-total-price-set-dialog',
  templateUrl: './total-price-set-dialog.component.html',
  styleUrls: ['./total-price-set-dialog.component.scss']
})
export class TotalPriceSetDialogComponent implements OnInit {
  totalPriceDrawForm: UntypedFormGroup;
  periodDateText: string;
  currency: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalRef: KrosModalRef,
    private periodDatePipe: PeriodDatePipe
  ) { }

  ngOnInit(): void {
    const periodData = this.modalRef.data.period;
    this.totalPriceDrawForm = this.formBuilder.group(
      {
        totalPrice: [this.modalRef.data.totalPrice]
      }
    );
    this.currency = this.modalRef.data.currency;
    this.periodDateText = this.periodDatePipe.transform(periodData.dateFrom, periodData.dateTo);
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  onSubmit(): void {
    this.modalRef.submit({
      totalPrice: this.totalPriceDrawForm.controls['totalPrice'].value
    });
  }
}
