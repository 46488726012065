import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import {
  listEditActions as documentsEditActions,
  DocumentsExplorerDispatchersService,
  ToastService,
  ToastType,
  TranslateService
} from '@kros-sk/ssw-shared-legacy';

import * as documentsActions from './documents.actions';
import { DocumentService } from '../../documents/services/document/document.service';

@Injectable()
export class DocumentsEffects {

  getFolderHierarchy$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(documentsActions.loadFolderHierarchyStart, documentsEditActions.createFolderSuccess),
      switchMap(payload => {
        return this.documentsService.getFolderHierarchy(payload.projectId).pipe(
          map(folders => documentsActions.loadFolderHierarchySuccess({ folderHierarchy: folders })),
          catchError(error => of(documentsActions.loadFolderHierarchyError({ error })))
        );
      })
    );
  });

  undoDeleteDocuments$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(documentsEditActions.undoDeleteDocuments),
      map(payload => documentsActions.loadFolderHierarchyStart({ projectId: payload.projectId }))
    );
  });

  getUploadingData$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(documentsActions.loadUploadingDataStart),
      switchMap(payload => {
        return this.documentsService.getUploadingData(payload.temporaryId).pipe(
          map(uploadingData => documentsActions.loadUploadingDataSuccess({ uploadingData })),
          catchError(error => of(documentsActions.loadUploadingDataError({ error })))
        );
      })
    );
  });

  uploadBuilding$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(documentsActions.uploadBuildingStart),
      switchMap(payload => {
        return this.documentsService.completeUploadBuilding(payload.fileInfo, payload.accessKey).pipe(
          map((documentId) => {
            this.reloadData(payload.fileInfo.projectId, payload.fileInfo.parentId);
            return documentsActions.uploadBuildingSuccess({ documentId });
          }),
          catchError(error => {
            this.handleUploadBuildingError();
            return of(documentsActions.uploadBuildingError({ error }));
          })
        );
      })
    );
  });

  uploadBuildingVersion$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(documentsActions.uploadBuildingVersionStart),
      switchMap(payload => {
        return this.documentsService.completeUploadBuildingVersion(payload.projectId, payload.versionInfo, payload.accessKey).pipe(
          map(() => {
            this.reloadData(payload.projectId, payload.folderId);
            return documentsActions.uploadBuildingVersionSuccess({ documentId: payload.versionInfo.documentId });
          }),
          catchError(error => {
            this.handleUploadBuildingError();
            return of(documentsActions.uploadBuildingVersionError({ error }));
          })
        );
      })
    );
  });

  getDocumentVersionList$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(documentsActions.loadDocumentVersionStart),
      switchMap(payload => {
        return this.documentsService.getDocumentVersionList(payload.documentId).pipe(
          map(documentVersionList => documentsActions.loadDocumentVersionSuccess({ documentVersionList }))
        );
      }),
      catchError(error => of(documentsActions.loadDocumentVersionError({ error })))
    );
  });

  setDescription$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(documentsActions.setDescriptionStart),
      switchMap(payload => {
        return this.documentsService.setDescription(payload.documentVersionId, payload.description).pipe(
          map(() => documentsActions.setDescriptionSuccess(
            { documentId: payload.documentId, documentVersionId: payload.documentVersionId, description: payload.description })),
          catchError(error => of(documentsActions.setDescriptionError({ error })))
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private documentsExplorerDispatchersService: DocumentsExplorerDispatchersService,
    private documentsService: DocumentService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) { }

  private reloadData(projectId: number, folderId: string): void {
    if (folderId) {
      this.documentsExplorerDispatchersService.loadFolderList(projectId, folderId);
    } else {
      this.documentsExplorerDispatchersService.loadDocumentsList(projectId);
    }
  }

  private handleUploadBuildingError(): void {
    this.toastService.open(this.translateService.translate('DOCUMENTS.UNKNOWN_UPLOAD_ERROR'),
      ToastType.Error);
  }
}
