import { Inject, Injectable } from '@angular/core';

import { BehaviorSubject, combineLatest, from, Subscription, timer } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { SigninRedirectArgs, User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

import { ApplicationType } from '@kros-sk/models';
import { KrosAppInsightsService } from '@kros-sk/core';

import { AuthDispatchersService } from '../../store/auth-dispatchers.service';
import { KROS_IDENTITY_SERVER_CONFIG, KrosIdentityServerConfig } from './kros-identity-server.config';
import { KrosUser } from './kros-user.model';

@Injectable()
export class KrosAuthService {
  manager: UserManager = null;

  private oidcUser$ = new BehaviorSubject<User | null>(null);
  private tokenSilentlyRenewed$ = new BehaviorSubject<void>(undefined);
  private clientId: string;
  private scope: string;
  private applicationType: ApplicationType;
  private appTypes = {
    [ApplicationType.Invoicing]: 'ESW',
    [ApplicationType.Warehouse]: 'WRHS',
    [ApplicationType.DigitalOffice]: 'OFCE'
  };

  private timer = timer(5000, 5000);
  private timerSub: Subscription;
  private timerRunning = false;
  private renewAttempts = 0;
  private maxRenewAttempts = 5;

  constructor(
    @Inject(KROS_IDENTITY_SERVER_CONFIG) private identityServerConfig: KrosIdentityServerConfig,
    private authDispatchers: AuthDispatchersService,
    private logger: KrosAppInsightsService
  ) { }

  authorizationHeaderValue$ = combineLatest([this.oidcUser$, this.tokenSilentlyRenewed$]).pipe(
    switchMap(() => from(this.authorizationHeaderValue))
  );

  /**
   * User loader for APP_INITIALIZER token.
   *
   * Loading at app initialization time prevents problem with
   * promises, as UserManager.getUser() return promise object. That's the reason
   * why isLoggedIn would ALWAYS trigger redirect to auth authority, because at
   * the time when AuthGuards are calling isLoggedIn, this.user object is still null,
   * even when user is still signed in.
   */
  async initialize(clientId: string, scope: string, applicationType: ApplicationType): Promise<void> {
    this.clientId = clientId;
    this.scope = scope;
    this.applicationType = applicationType;
    this.oidcUser$.pipe(
      tap(() => this.stopRenewTokenTimer()),
      map(user => ({ krosUser: this.mapOidcUserToUser(user), user })),
      distinctUntilChanged((previous, current) => this.usersAreSame(previous.krosUser, current.krosUser))
    ).subscribe(
      data => {
        if (this.isUserValid(data.user)) {
          return this.authDispatchers.setUser(data.krosUser);
        }
        this.authDispatchers.setEmptyUser();
      }
    );

    this.manager = new UserManager(this.clientSettings);

    this.manager.events.addUserLoaded(user => {
      this.oidcUser$.next(user);
    });

    this.manager.events.addSilentRenewError((e) => {
      // Silent refresh unexpectedly canceled
      if (this.logger) this.logger.trackException(e, 'KrosAuth', { message: 'Silent refresh unexpectedly canceled.' });
      console.warn('silent renew failed', e);

      switch (e.message) {
        // handle hopeless
        case 'interaction_required':
        case 'login_required':
          this.removeOldUser();
          return;
        default:
          this.manager.stopSilentRenew();
          this.startRenewTokenTimer();
      }
    });

    this.manager.events.addUserSignedOut(() => {
      // redirect to login page from all tabs opened with same user account
      this.manager.signinRedirect();
    });

    let userFromManager = await this.manager.getUser();
    if (userFromManager) {
      try {
        // check if user is valid to login (user has been logged out or stored user is different)
        const status = await this.manager.querySessionStatus();
        if (status.sub === userFromManager.profile.sub) {
          if (userFromManager.expired) {
            // User is loaded but token is expired. Try to silent signIn
            userFromManager = await this.manager.signinSilent();
          }
        } else {
          await this.removeOldUser();
        }
      } catch (err) {
        // if user is not valid for login, remove user from user storage and redirect to login page
        if (this.logger) this.logger.trackException(err, 'KrosAuth', { message: 'User has been logged out.' });
        console.log('user has been logged out');
        await this.removeOldUser();
      }
    }
    // sets user to store
    this.oidcUser$.next(userFromManager);
  }

  private isUserValid(user): boolean {
    return !!user && !user?.expired;
  }

  private async removeOldUser(): Promise<null> {
    this.saveCurrentUrl();
    await this.manager.removeUser();
    await this.manager.signinRedirect();
    return null;
  }

  get authorizationHeaderValue(): Promise<string> {
    return this.getCurrentUser().then(user => this.isUserValid(user) ? `${user.token_type} ${user.access_token}` : '');
  }

  get applicationTypeHeaderValue(): ApplicationType {
    return this.applicationType;
  }

  get applicationTypePrefix(): string {
    return this.appTypes[this.applicationType];
  }

  get token(): Promise<string> {
    return this.getCurrentUser()
      .then(user => this.isUserValid(user) ? user.access_token : '');
  }

  private getCurrentUser(): Promise<User | null> {
    return this.manager.getUser()
      .then(user => this.isUserValid(user) ? user : this.manager.signinSilent())
      .catch(error => {
        // if get user or signintSIlent failed, remove user and be done
        if (this.logger) {
          this.logger.trackException(
            error,
            'KrosAuth',
            { message: 'User has been logged out while attempting to retrieve token.' }
          );
        }
        return this.removeOldUser();
      });
  }

  startAuthentication(url?: string): Promise<void> {
    this.saveCurrentUrl(url);

    return this.manager.signinRedirect(this.getSignInRedirectArgs());
  }

  logOut(): Promise<void> {
    return this.manager.signoutRedirect();
  }

  async ensureValidToken(): Promise<boolean> {
    return this.manager.getUser().then(user => {
      if (!!user) {
        if (user.expired) {
          return this.silentRenewToken();
        }
        return true;
      }
      return false;
    });
  }

  private async silentRenewToken(): Promise<boolean> {
    try {
      await this.manager.signinSilent();
      // token acquired start auto silent renew
      console.log('silent renew restarted');
      this.manager.startSilentRenew();
      if (this.logger) this.logger.trackEvent('KrosAuth', { message: 'Silent renew successful.' });
      this.tokenSilentlyRenewed$.next();
      return true;
    } catch (error) {
      if (this.logger) this.logger.trackException(error, 'KrosAuth', { message: 'Silent renew failed.' });
      // signInSilent or startSilentRenew failed, so remove user and redirect to signin page
      console.log('silent renew failed', error);
      await this.removeOldUser();
      return false;
    }
  }

  private startRenewTokenTimer(): void {
    if (this.timerRunning) {
      return;
    }
    this.renewAttempts = 0;
    this.timerRunning = true;

    this.timerSub = this.timer.subscribe(() => {
      this.renewAttempts++;
      if (this.renewAttempts <= this.maxRenewAttempts) {
        this.silentRenewToken();
      } else {
        this.stopRenewTokenTimer();
      }
    });
  }

  private stopRenewTokenTimer(): void {
    this.timerSub?.unsubscribe();
    this.timerRunning = false;
  }

  private mapOidcUserToUser(oidcUser: User): KrosUser | null {
    if (oidcUser) {
      return {
        email: oidcUser.profile.email,
        name: oidcUser.profile.name,
        userId: +oidcUser.profile.sub,
        externalUserId: +oidcUser.profile.external_id || undefined
      };
    }
    return null;
  }

  private usersAreSame(u1: KrosUser, u2: KrosUser): boolean {
    return (JSON.stringify(u1) === JSON.stringify(u2));
  }

  private getSignInRedirectArgs(): SigninRedirectArgs {
    const urlRoute = this.getUrlRoute();

    if (this.isValidExternalLoginRoute(urlRoute)) {
      return { extraQueryParams: { externalLoginAuthority: urlRoute } };
    }

    return undefined;
  }

  private isValidExternalLoginRoute(urlRoute: string): boolean {
    const upperCaseUrlRoute = urlRoute.toUpperCase();
    return this.identityServerConfig.externalLoginRoutes?.some(
      path => path.toUpperCase() === upperCaseUrlRoute
    );
  }

  private getUrlRoute(): string {
    const { pathname } = window.location;

    if (!pathname || !pathname.includes('/')) {
      return pathname || '';
    }

    const redirectUrlRouteParts = pathname.split('/');
    return redirectUrlRouteParts[redirectUrlRouteParts.length - 1];
  }

  /**
   * If user is not logged in, and current location is not '/',
   * then save it to local store for later use after login redirect
   */
  private saveCurrentUrl(url?: string): void {
    if (!localStorage.getItem('redirectUrl')) {
      if (window.location.pathname.length > 1 || window.location.search.length > 1) {
        localStorage.setItem('redirectUrl', window.location.pathname.concat(window.location.search));
      }
      if (url) {
        localStorage.setItem('redirectUrl', url);
      }
    }
  }

  /**
   * Create and return client settings object.
   * this.identityServerConfig is dependent on APP_INNITIALIZATION process.
   * APP_INNITIALIZATION is required to run before this service is loaded.
   */
  private get clientSettings(): UserManagerSettings {
    return {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: `${this.identityServerConfig.url}`,
      client_id: this.clientId,
      redirect_uri: `${this.identityServerConfig.clientUrl}/oidc/login-redirect`,
      extraQueryParams: this.identityServerConfig.applicationId
        ? { app_id: this.identityServerConfig.applicationId }
        : undefined,
      post_logout_redirect_uri: `${this.identityServerConfig.postLogoutUrl ?? this.identityServerConfig.clientUrl}`,
      response_type: 'code',
      scope: this.scope,
      filterProtocolClaims: true,
      loadUserInfo: true,
      automaticSilentRenew: true,
      silent_redirect_uri: `${this.identityServerConfig.clientUrl}/oidc/silent-refresh.html`,
      silentRequestTimeoutInSeconds: 10, // default value
      checkSessionIntervalInSeconds: 3,
      // should resolve No matching state found in storage
      response_mode: 'query',
      monitorSession: true
    };
  }
}
