import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-external-application-opener',
  templateUrl: './external-application-opener.component.html',
  styleUrls: ['./external-application-opener.component.scss']
})
export class ExternalApplicationOpenerComponent implements OnInit {

  hasError = false;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(p => {
      this.hasError = p.get('error') && p.get('error').length > 0;
    });
  }

}
