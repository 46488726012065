/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { IBudgetItem } from '../budget/helpers';
import { Position } from '../side-panel';

const boqDefaultHeight = 350;
const boqAlternativeHeight = 200;

@Injectable({
  providedIn: 'root'
})
export class PanelHelper {
  boqBudgetItem: IBudgetItem;
  previousRowIndex: number;
  actualBoqPanelHeight = 0;
  isBoqPanelActive = false;

  showPanel: (position: Position, size: number) => void;
  hidePanel: (position: Position) => void;

  private resizePanel: Function;

  private loadBoqItems = new Subject<number>();
  loadBoqItems$ = this.loadBoqItems.asObservable();
  focusedBoqBudgetItem$ = new Subject<IBudgetItem>();
  focusedCellChanged$ = new Subject<IBudgetItem>();

  constructor() { }

  init(showPanel: (position: Position, size: number) => void, hidePanel: (position: Position) => void, resizePanel: Function): void {
    this.showPanel = showPanel;
    this.hidePanel = hidePanel;
    this.resizePanel = resizePanel;
  }

  onCloseBoq(): void {
    if (this.hidePanel) {
      this.hidePanel(Position.Bottom);
    }
    this.isBoqPanelActive = false;
  }

  openBoq(budgetItem: IBudgetItem, canLoadBoqItems: boolean = true): void {
    this.boqBudgetItem = budgetItem;

    if (canLoadBoqItems) {
      this.loadBoqItems.next(budgetItem.id);
    }

    if (!this.isBoqPanelActive) {
      const size = this.actualBoqPanelHeight === 0 ? boqDefaultHeight : this.actualBoqPanelHeight;
      this.showPanel(Position.Bottom, size);
      this.actualBoqPanelHeight = size;
      this.isBoqPanelActive = true;
    }
  }

  resizeBoq(): void {
    const size = this.actualBoqPanelHeight === boqDefaultHeight ? boqAlternativeHeight : boqDefaultHeight;
    this.actualBoqPanelHeight = size;

    this.resizePanel(Position.Bottom, size);
  }

  loadBoq(budgetItem: IBudgetItem): void {
    if (this.isBoqPanelActive) {
      this.boqBudgetItem = budgetItem;
      this.loadBoqItems.next(budgetItem.id);
    }
  }
}
