export interface ProjectSharingResultInfo {
  result: ProjectSharingResultInfoEnum;
  message: string;
}

export enum ProjectSharingResultInfoEnum {
  ProjectDoesNotExist = 1,
  UserWithEmailNotFound = 2,
  NoPermissionForSharingProject = 3,
  SelfSharingNotAllowed = 4,
  EmailAddressSetToMoreUsers = 5,
  ProjectSharingAlreadyExists = 6
}
