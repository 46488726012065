import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ApplicationType } from '@kros-sk/models';

import { CircularSpinnerComponent } from './components/circular-spinner/circular-spinner.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PageNotFoundPermissionsRedirectComponent } from './components/page-not-found/page-not-found-permissions-redirect.component';
import { TimeNotSynchronizedComponent } from './components/time-not-synchronized/time-not-synchronized.component';
import { WelcomeComponent } from './components/welcome/welcome.component';

export const APPLICATION_ID = new InjectionToken<ApplicationType>('application id of current app');

const components = [
  CircularSpinnerComponent,
  WelcomeComponent,
  PageNotFoundComponent,
  PageNotFoundPermissionsRedirectComponent,
  TimeNotSynchronizedComponent
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports: [...components],
})
export class PartialsModule { }
