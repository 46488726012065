import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { ProjectDetail, TagCreationModel, TagEditViewModel } from '@kros-sk/ssw-shared-legacy';

import * as listActions from './projects.actions';
import { ApplicationSharingViewModel, ProjectOwnerEditViewModel } from '../../projects/models';
import { ProjectsState } from './projects.state';

@Injectable()
export class ProjectsDispatchersService {

  constructor(
    private store: Store<ProjectsState>
  ) { }

  loadProjectDetail(projectId: number): void {
    this.store.dispatch(
      listActions.loadProjectDetailStart({ projectId })
    );
  }

  editProjectDetail(projectDetail: ProjectDetail, subcontractorId?: number): void {
    this.store.dispatch(
      listActions.editProjectDetailStart({ projectDetail, subcontractorId })
    );
  }

  editOwner(viewModel: ProjectOwnerEditViewModel): void {
    this.store.dispatch(
      listActions.editOwnerStart({ viewModel })
    );
  }

  shareApplications(viewModel: ApplicationSharingViewModel): void {
    this.store.dispatch(
      listActions.shareApplications({ viewModel })
    );
  }

  loadProjectApplicationPermissions(projectId: number): void {
    this.store.dispatch(
      listActions.loadApplicationPermissionsStart({ projectId })
    );
  }

  setIsProjectMembersTabSelected(value: boolean): void {
    this.store.dispatch(
      listActions.setIsProjectMembersTabSelected({ value })
    );
  }

  loadTagsList(): void {
    this.store.dispatch(
      listActions.loadTagsListStart()
    );
  }

  createNewTag(data: { tag: TagCreationModel, setTagToProject: boolean }): void {
    this.store.dispatch(
      listActions.createNewTagStart(data)
    );
  }

  clearNewTagCreated(): void {
    this.store.dispatch(
      listActions.clearNewTagCreated()
    );
  }

  editTag(tag: TagEditViewModel): void {
    this.store.dispatch(
      listActions.editTagStart({ tag })
    );
  }

  setProjectDetail(projectDetail: ProjectDetail): void {
    this.store.dispatch(
      listActions.setProjectDetail({ projectDetail })
    );
  }
}
