import { inject, Injectable } from '@angular/core';

import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { BudgetPermission, BudgetPermissionType, BuildingProgressPermission } from '../models';
import { permissionFeatureKey, PermissionState } from './permission.state';

const selectFeature = createFeatureSelector<PermissionState>(permissionFeatureKey);

const selectBuildingProgressPermission = createSelector(
  selectFeature,
  (state: PermissionState) => state.buildingProgressPermission
);

const selectBudgetPermission = createSelector(
  selectFeature,
  (state: PermissionState) => state.budgetPermission
);

const selectCanEditBudget = createSelector(
  selectFeature,
  state => state?.budgetPermission &&
    (state.budgetPermission.permissionType === BudgetPermissionType.Owner ||
      state.budgetPermission.permissionType === BudgetPermissionType.Contributor)
);

const selectIsBudgetOwner = createSelector(
  selectFeature,
  state => state?.budgetPermission?.permissionType === BudgetPermissionType.Owner
);

@Injectable()
export class PermissionSelectorsService {
  private store = inject(Store<PermissionState>);

  budgetPermission$: Observable<BudgetPermission> = this.store.select(selectBudgetPermission);
  buildingProgressPermission$: Observable<BuildingProgressPermission> = this.store.select(selectBuildingProgressPermission);
  canEditBudget$: Observable<boolean> = this.store.select(selectCanEditBudget);
  isBudgetOwner$: Observable<boolean> = this.store.select(selectIsBudgetOwner);
}
