import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit } from '@angular/core';
import { ENTER, hasModifierKey, SPACE } from '@angular/cdk/keycodes';
import { FocusableOption, FocusOrigin, Highlightable } from '@angular/cdk/a11y';

import { fromEvent, map, merge, Observable, Subject } from 'rxjs';

@Component({
  selector: 'kros-autocomplete-option',
  templateUrl: './kros-autocomplete-option.component.html',
  styleUrls: ['./kros-autocomplete-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'dropdown-item',
    tabindex: '-1',
    '[class.focus]': 'active',
    '(keydown)': 'handleKeydown($event)',
  }
})
export class KrosAutocompleteOptionComponent<T = any> implements OnInit, Highlightable, FocusableOption {

  @Input() value: T;

  /** Whether the option is disabled. */
  @Input()
  get disabled(): boolean {
    return this.disabled_;
  }
  set disabled(value: BooleanInput) {
    this.disabled_ = coerceBooleanProperty(value);
  }

  /**
   * Whether or not the option is currently active and ready to be selected.
   * An active option displays styles as if it is focused, but the
   * focus is actually retained somewhere else. This comes in handy
   * for components like autocomplete where focus must remain on the input.
   */
  get active(): boolean {
    return this.active_;
  }
  private active_ = false;

  click$: Observable<T>;

  private selected = false;
  private disabled_ = false;
  private selectTrigger: Subject<any>;

  constructor(
    private host: ElementRef,
    private _changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.selectTrigger = new Subject();

    this.click$ = merge(
      this.selectTrigger,
      fromEvent(this.element, 'click'),
    ).pipe(
      map(() => this.value)
    );
  }

  get element(): any {
    return this.host.nativeElement;
  }

  getLabel(): string {
    return (this.element.textContent || '').trim();
  }

  /** Sets focus onto this option. */
  focus(_origin?: FocusOrigin, options?: FocusOptions): void {
    // Note that we aren't using `_origin`, but we need to keep it because some internal consumers
    // use `MatOption` in a `FocusKeyManager` and we need it to match `FocusableOption`.
    const element = this.element;

    if (typeof element.focus === 'function') {
      element.focus();
    }
  }

  /** Selects the option. */
  select(): void {
    if (!this.selected) {
      this.selected = true;
      this._changeDetectorRef.markForCheck();
      this.selectTrigger.next(this.value);
    }
  }

  /** Deselects the option. */
  deselect(): void {
    if (this.selected) {
      this.selected = false;
      this._changeDetectorRef.markForCheck();
      this.selectTrigger.next(null);
    }
  }

  /**
   * This method sets display styles on the option to make it appear
   * active. This is used by the ActiveDescendantKeyManager so key
   * events will display the proper options as active on arrow key events.
   */
  setActiveStyles(): void {
    if (!this.active) {
      this.active_ = true;
      this._changeDetectorRef.markForCheck();
    }
  }

  /**
   * This method removes display styles on the option that made it appear
   * active. This is used by the ActiveDescendantKeyManager so key
   * events will display the proper options as active on arrow key events.
   */
  setInactiveStyles(): void {
    if (this.active) {
      this.active_ = false;
      this._changeDetectorRef.markForCheck();
    }
  }

  /**
   * `Selects the option while indicating the selection came from the user. Used to
   * determine if the select's view -> model callback should be invoked.`
   */
  selectViaInteraction(): void {
    if (!this.disabled) {
      this.selected = true;
      this._changeDetectorRef.markForCheck();
      this.selectTrigger.next(this.value);
    }
  }

  /** Ensures the option is selected when activated from the keyboard. */
  handleKeydown(event: KeyboardEvent): void {
    if ((event.keyCode === ENTER || event.keyCode === SPACE) && !hasModifierKey(event)) {
      this.selectViaInteraction();

      // Prevent the page from scrolling down and form submits.
      event.preventDefault();
    }
  }
}

/**
 * Determines the position to which to scroll a panel in order for an option to be into view.
 * @param optionOffset Offset of the option from the top of the panel.
 * @param optionHeight Height of the options.
 * @param currentScrollPosition Current scroll position of the panel.
 * @param panelHeight Height of the panel.
 * @docs-private
 */
export function getOptionScrollPosition(
  optionOffset: number,
  optionHeight: number,
  currentScrollPosition: number,
  panelHeight: number,
): number {
  if (optionOffset < currentScrollPosition) {
    return optionOffset;
  }

  if (optionOffset + optionHeight > currentScrollPosition + panelHeight) {
    return Math.max(0, optionOffset - panelHeight + optionHeight);
  }

  return currentScrollPosition;
}
