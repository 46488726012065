import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Budget } from '@kros-sk/ssw-budget/shared/data-access/models';
import { BudgetSettings, ProjectDetail } from '@kros-sk/ssw-shared-legacy';

export const budgetSharedKey = 'Budget-shared';

export const budgetSharedActions = createActionGroup({
  source: budgetSharedKey,
  events: {
    'Set project': props<{ project: ProjectDetail }>(),

    'Resolve budget': props<{ projectId: number }>(),

    'Edit project details start': props<{ project: ProjectDetail }>(),
    'Edit project details success': props<{ project: ProjectDetail }>(),
    'Edit project details error': props<{ error: any }>(),

    'Set budget': props<{ budget: Budget }>(),
    'Set is main budget': props<{ mainBudget: Budget, budgetId: number }>(),

    'Get budget settings start': emptyProps(),
    'Get budget settings success': props<{ settings: BudgetSettings }>(),
    'Get budget settings error': props<{ error?: any }>(),

    'Get budget UI settings start': props<{ projectId?: number, settingsName: string, forceLoad?: boolean }>(),
    'Get budget UI settings success': props<{ settings: any, settingsName: string }>(),
    'Get budget UI settings error': props<{ error?: any, settingsName: string }>(),

    'Set budget UI settings start': props<{ settings: any; settingsName: string }>(),
    'Set budget UI settings success': props<{ settings: any, settingsName: string }>(),
    'Set budget UI settings error': props<{ error?: any }>(),

    'Set budget grouping': props<{ groupColumns: string[] }>(),
  }
});
