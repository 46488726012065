import { Component } from '@angular/core';

import { KrosModalRef } from '@kros-sk/components';

import { LicenseAgreementConfig } from '../license-agreement-config.model';

@Component({
  selector: 'app-license-agreement',
  templateUrl: './license-agreement.component.html',
  styleUrls: ['./license-agreement.component.scss']
})
export class LicenseAgreementComponent {
  licenseConfig: LicenseAgreementConfig;

  constructor(
    private modalRef: KrosModalRef
  ) {
    this.licenseConfig = this.modalRef.data.licenseConfig;
  }

  confirm(): void {
    this.modalRef.submit();
  }
}
