import { Component } from '@angular/core';

import { KrosModalRef } from '@kros-sk/components';

import { UnsubscribeComponent } from '../../helpers';

@Component({
  selector: 'kros-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent extends UnsubscribeComponent {
  data: {
    caption: string,
    suggestion: string,
    closeButtonText: string
  };

  constructor(
    private modalRef: KrosModalRef
  ) {
    super();
    this.data = this.modalRef.data;
  }

  onClose(): void {
    this.modalRef.cancel();
  }
}
