import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ColumnGroup } from '../models/column-group.model';

@Component({
  selector: 'app-column-filter-dropdown',
  templateUrl: './column-filter-dropdown.component.html',
  styleUrls: ['./column-filter-dropdown.component.scss']
})
export class ColumnFilterDropdownComponent {
  @Input() columnGroups: ColumnGroup[];

  @Output() columnGroupsCheckChanged = new EventEmitter<ColumnGroup[]>();

  constructor() { }

  addOrRemoveColumnGroupToFilter(columnGroup: ColumnGroup): void {
    columnGroup.checked = !columnGroup.checked;
    this.columnGroupsCheckChanged.emit(this.columnGroups);
  }
}
