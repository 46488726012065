import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { catchError, map, shareReplay } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';
import { createHttpRetryErrorForLog, httpRetryAndLog, HttpRetryResult, KrosAppInsightsService } from '@kros-sk/core';
import { VatIdValidationResult } from '@kros-sk/models';

import { ExpirableCacheService } from '../expirable-cache/expirable-cache.service';
import { HandleMethodErrors } from '../shared/decorators/handle-errors/handle-errors-decorator';
import { RequestsWithErrorHandling } from '../shared/request-with-error-handling/requests-with-error-handling.service';

@Injectable()
export class VatIdValidationService extends RequestsWithErrorHandling {
  constructor(
    httpClient: HttpClient,
    private appInsightsService: KrosAppInsightsService,
    @Inject(APP_CONFIG) private appConfig: any,
    private cache: ExpirableCacheService<string, Observable<VatIdValidationResult>>,
  ) {
    super(httpClient);
  }

  vatIdValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.vatIdValidation(control.value);
    };
  }

  vatIdValidation(vatId: string): Observable<ValidationErrors | null> {
    if (!vatId) {
      return of(null);
    }

    return this.validateVatId(vatId).pipe(
      map(result => result === VatIdValidationResult.Invalid ||
        result === VatIdValidationResult.WrongFormat ?
        { invalidVatId: true } : null)
    );
  }

  @HandleMethodErrors({ status: ['401', '408', '499', '500', '502', '503', '504'] })
  private validateVatId(vatId: string): Observable<VatIdValidationResult> {
    const cachedResult = this.cache.get(vatId);
    if (cachedResult) {
      return cachedResult;
    }

    const url = `${this.appConfig.gatewayApiUrl}/partnersinfo?vatId=${encodeURIComponent(vatId)}`;
    const result = this.httpRequest<{ vatId: string, validationResult: VatIdValidationResult }>('get', url).pipe(
      httpRetryAndLog({
        retryOnStatus: /^(500|502|503|504)$/,
        retryDelays: [300, 600, 1200],
        loggerFn: this.logHttpRetryException('validateVatId', 'GET')
      }),
      map(response => response?.validationResult),
      shareReplay(1),
      catchError(() => {
        this.cache.delete(vatId);
        return EMPTY;
      })
    );

    this.cache.set(vatId, result);
    return result;
  }

  private logHttpRetryException = (apiMethod: string, httpRequestMethod: string) => {
    return (log: HttpRetryResult): void => {
      const handler = 'VatIdValidationService';
      this.appInsightsService.trackException(createHttpRetryErrorForLog(handler, apiMethod, httpRequestMethod), handler, log);
    };
  };
}
