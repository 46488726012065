import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, TemplateRef } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'kros-validation-messages',
  templateUrl: './validationMessages.component.html',
  styleUrls: ['./validationMessages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KrosValidationMessagesComponent {
  @Input() formErrors: ValidationErrors;

  @Input() info: TemplateRef<any>;

  @Input() customMessages: any;

  constructor(private cd: ChangeDetectorRef) {}

  detectChanges(): void {
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }

  isNativeError(name: string): boolean {
    return ['required', 'email', 'invalidEmail', 'minlength', 'maxlength', 'invalidIban',
      'optional', 'underMinQuantity', 'overMaxQuantity', 'underPurchasePrice',
      'derivedMeasureUnitNameNotUnique', 'whiteSpaces'].includes(name);
  }

  get formErrorsArray(): {name: string, error: any}[] {
    if (!this.formErrors) {
      return [];
    }
    return Object.keys(this.formErrors).map((name) => {
      return {
        name,
        error: this.formErrors[name]
      };
    });
  }
}
