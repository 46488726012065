import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { BoqItem, DecimalPlaces } from '@kros-sk/ssw-shared-legacy';
import { BuildingProgressPermission } from '@kros-sk/ssw-shared/permission';
import { BuildingProgressSettings } from '@kros-sk/ssw-shared/settings';

import * as selectorsHelper from './building-progress-selectors.helper';
import { BoqItemViewModel } from '../../building-progress/models/boq-edit.view-model';
import { BoqPeriodItem } from '../../building-progress/models/boq-period-item.model';
import {
  BuildingProgressCell,
  BuildingProgressItem,
  BuildingProgressModel,
  BuildingProgressPeriodItem
} from '../../building-progress/models/construction-data.model';
import {
  BuildingProgressCommentModel, CommentPositionModel
} from '../../building-progress/modules/comments-panel/comments-panel/comment.model';
import { BuildingProgressPeriod } from '../../building-progress/models/building-progress-period.model';
import { BuildingProgressSharing } from '../../building-progress/models/building-progress-sharing/building-progress-sharing.model';
import { BuildingProgressState } from './building-progress.state';
import { DataSettings } from '../../building-progress/models/data-settings.model';
import { ItemDetails } from '../../building-progress/models/item-period-details.model';
import { ItemDrawSheetExportSettings } from '../../building-progress/models/item-draw-sheet-export-settings.model';
import { Note } from '../../building-progress/models/note.model';
import { RemainingBudgetExportSettings } from '../../building-progress/models/remaining-budget-export-settings.model';
import { SelectedView } from '../../building-progress/models/selected-view.model';
import { TableExportSettings } from '../../building-progress/models/table-export-settings.model';

@Injectable()
export class BuildingProgressSelectorsService {
  constructor(
    private store: Store<BuildingProgressState>
  ) { }

  get constructionData$(): Observable<BuildingProgressModel> {
    return this.store.select(selectorsHelper.selectConstructionData);
  }

  get constructionDataDecPlaces$(): Observable<DecimalPlaces> {
    return this.store.select(selectorsHelper.selectConstructionDataDecPlaces);
  }

  get constructionDataCurrency$(): Observable<string> {
    return this.store.select(selectorsHelper.selectConstructionDataCurrency);
  }

  get allComments$(): Observable<BuildingProgressCommentModel[]> {
    return this.store.select(selectorsHelper.selectComments);
  }

  get comments$(): Observable<BuildingProgressCommentModel[]> {
    return this.store.select(selectorsHelper.selectCommentsOfSelectedPeriod);
  }

  get commentsLoading$(): Observable<boolean> {
    return this.store.select(selectorsHelper.selectCommentsLoading);
  }

  get commentPositions$(): Observable<CommentPositionModel[]> {
    return this.store.select(selectorsHelper.selectCommentPositions);
  }

  get permission$(): Observable<BuildingProgressPermission> {
    return this.store.select(selectorsHelper.selectPermission);
  }

  get periods$(): Observable<BuildingProgressPeriod[]> {
    return this.store.select(selectorsHelper.selectPeriods);
  }

  get wholeBuildingPeriods$(): Observable<BuildingProgressPeriod[]> {
    return this.store.select(selectorsHelper.selectWholeBuildingPeriods);
  }

  get sharingList$(): Observable<BuildingProgressSharing[]> {
    return this.store.select(selectorsHelper.selectSharingList);
  }

  get insertedItem$(): Observable<BuildingProgressItem> {
    return this.store.select(selectorsHelper.selectInsertedItem);
  }

  get periodId$(): Observable<number> {
    return this.store.select(selectorsHelper.selectPeriodId);
  }

  get period$(): Observable<BuildingProgressPeriod> {
    return this.store.select(selectorsHelper.selectPeriod);
  }

  get periodsLoading$(): Observable<boolean> {
    return this.store.select(selectorsHelper.selectPeriodsLoading);
  }

  get cellsToFocusAndAnimate$(): Observable<BuildingProgressCell[]> {
    return this.store.select(selectorsHelper.selectCellToFocusAndAnimate);
  }

  get selectedItemIds$(): Observable<Set<number>> {
    return this.store.select(selectorsHelper.selectSelectedItemIds);
  }

  get lastEditedItemIds$(): Observable<string[]> {
    return this.store.select(selectorsHelper.selectLastEditedItemIds);
  }

  get indeterminateItemIds$(): Observable<Set<number>> {
    return this.store.select(selectorsHelper.selectIndeterminateItemIds);
  }

  get boqPeriodItems$(): Observable<BoqPeriodItem[]> {
    return this.store.select(selectorsHelper.selectBoqPeriodItems);
  }

  get boqPeriodItemsIsLoading$(): Observable<boolean> {
    return this.store.select(selectorsHelper.selectBoqPeriodItemsIsLoading);
  }

  get editingBoqBudgetItemIds$(): Observable<number[]> {
    return this.store.select(selectorsHelper.selectEditingBoqBudgetItemIds);
  }

  get boqItems$(): Observable<BoqItem[]> {
    return this.store.select(selectorsHelper.selectBoqItems);
  }

  get boqItemsIsLoading$(): Observable<boolean> {
    return this.store.select(selectorsHelper.selectBoqItemsIsLoading);
  }

  get resetOriginalItems$(): Observable<boolean> {
    return this.store.select(selectorsHelper.selectResetOriginalItems);
  }

  get editedBoqItems$(): Observable<BoqItemViewModel[]> {
    return this.store.select(selectorsHelper.selectEditedBoqItems);
  }

  get deletedBoqItemIds$(): Observable<number[]> {
    return this.store.select(selectorsHelper.selectDeletedBoqItemIds);
  }

  get hasAnyAddition$(): Observable<boolean> {
    return this.store.select(selectorsHelper.selectHasAnyAddition);
  }

  get isDeletingItems$(): Observable<boolean> {
    return this.store.select(selectorsHelper.selectDeletingItems);
  }

  get settings$(): Observable<BuildingProgressSettings> {
    return this.store.select(selectorsHelper.selectSettings);
  }

  get licenseAgreement$(): Observable<boolean> {
    return this.store.select(selectorsHelper.selectLicenseAgreement);
  }

  get periodDetails$(): Observable<{ itemId: number, period: BuildingProgressPeriodItem, details: ItemDetails, loading: boolean }> {
    return this.store.select(selectorsHelper.selectPeriodDetails);
  }

  get itemPeriodDetails$(): Observable<ItemDetails> {
    return this.store.select(selectorsHelper.selectItemPeriodDetails);
  }

  get selectedView$(): Observable<SelectedView> {
    return this.store.select(selectorsHelper.selectSelectedView);
  }

  get tableExportSettings$(): Observable<TableExportSettings> {
    return this.store.select(selectorsHelper.selectTableExportSettings);
  }

  get tableIsBusy$(): Observable<boolean> {
    return this.store.select(selectorsHelper.selectTableIsBusy);
  }

  get hasDocuments$(): Observable<boolean> {
    return this.store.select(selectorsHelper.selectHasDocuments);
  }

  get hiddenColumns$(): Observable<string[]> {
    return this.store.select(selectorsHelper.selectHiddenColumns);
  }

  get itemDrawSheetExportSettings$(): Observable<ItemDrawSheetExportSettings> {
    return this.store.select(selectorsHelper.selectItemDrawSheetExportSettings);
  }

  get remainingBudgetExportSettings$(): Observable<RemainingBudgetExportSettings> {
    return this.store.select(selectorsHelper.selectRemainingBudgetExportSettings);
  }

  get isSummaryPanelVisible$(): Observable<boolean> {
    return this.store.select(selectorsHelper.selectSummaryPanelVisible);
  }

  get dataSettings$(): Observable<DataSettings> {
    return this.store.select(selectorsHelper.selectDataSettings);
  }

  get budgetHasItems$(): Observable<boolean> {
    return this.store.select(selectorsHelper.selectBudgetHasItems);
  }

  get budgetCreationIsProcessing$(): Observable<boolean> {
    return this.store.select(selectorsHelper.selectBudgetCreationIsProcessing);
  }

  getBuildingProgressNote$(budgetItemId: number): Observable<Note> {
    return this.store.select(selectorsHelper.selectBuildingProgressNoteByIdFunction(budgetItemId));
  }

  areFunctionsAvailableFor$(userEmail: string): Observable<boolean> {
    return this.store.select(selectorsHelper.selectCanShowFunctions, { userEmail });
  }

  areDeleteColorFunctionsAvailableFor$(userEmail: string): Observable<boolean> {
    return this.store.select(selectorsHelper.selectCanShowColorDeleteFunction, { userEmail });
  }
}
