import { Component, OnInit } from '@angular/core';

import {
  DocumentListInfoModel,
  DocumentPermissionType,
  DocumentsExplorerDispatchersService,
  isOwnersEmail,
  ItemSharing,
  ItemSharingActionViewModel,
  NewSharingComboMode,
  PermissionChangedDto,
  SharingActionType,
  SharingPermissionValues,
  TimelineType,
  ToastService,
  ToastType,
  UserSharingHelpersService,
  UserSharingService
} from '@kros-sk/ssw-shared-legacy';
import { KrosModalRef } from '@kros-sk/components';
import { PermissionType } from '@kros-sk/ssw-shared/permission';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { DocumentSharingActionViewModel } from '../../models/document-sharing/document-sharing-action-view-model';
import { DocumentSharingEdit } from '../../models/document-sharing/document-sharing-edit.model';
import { DocumentSharingService } from '../../services/document-sharing/document-sharing.service';
import { DocumentSharingViewModel } from '../../models/document-sharing/document-sharing-view-model';
import { ProjectsSelectorsService } from '../../../store/projects';

@Component({
  selector: 'app-document-sharing-dialog',
  templateUrl: './document-sharing-dialog.component.html',
  styleUrls: ['./document-sharing-dialog.component.scss']
})
export class DocumentSharingDialogComponent extends UnsubscribeComponent implements OnInit {
  selectedDocuments: DocumentListInfoModel[] = [];

  sharings: ItemSharing[] = [];
  unconfirmedSharings: ItemSharing[] = [];
  compacted = false;
  readonly = false;

  editItems: DocumentSharingEdit[] = [];
  sharingMode = NewSharingComboMode.EditUserDocuments;
  defaultPermissions: SharingPermissionValues = {
    permission: PermissionType.Reader,
    additionalPermission: DocumentPermissionType.None,
  };

  loadedItems = false;
  changesMade = false;
  timelineType = TimelineType;

  constructor(
    private documentSharingService: DocumentSharingService,
    private projectsSelectorService: ProjectsSelectorsService,
    private sharingHelperService: UserSharingHelpersService,
    private documentsExplorerDispatchers: DocumentsExplorerDispatchersService,
    private toastService: ToastService,
    private userSharingService: UserSharingService,
    private modalRef: KrosModalRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.selectedDocuments = this.modalRef.data.documents;

    this.subs.sink = this.documentSharingService
      .getDocumentSharingList(this.selectedDocuments.map(({ id }) => id))
      .subscribe(resp => {
        this.loadedItems = true;
        this.sharings = this.normalizeItemSharings(resp);
        this.editItems = [];
        this.setInitialCompactMode();
      }, error => {
        this.toastService.open(error.message, ToastType.Error);
        this.onCloseClick();
      });

    this.subs.sink = this.projectsSelectorService.projectDetail$
      .subscribe(
        detail => {
          this.readonly = !isOwnersEmail(detail.owner);
          this.setInitialCompactMode();
        });
  }

  getExistingSharingEmails(): string[] {
    return this.sharings.map(s => s.email).concat(this.unconfirmedSharings.map(u => u.email));
  }

  onUnconfirmedPermissionDeleted(sharing: ItemSharing): void {
    this.unconfirmedSharings.splice(this.unconfirmedSharings.indexOf(sharing), 1);
    this.changesMade = (!!this.editItems && this.editItems.length > 0) || this.unconfirmedSharings.length > 0;
  }

  onChangedPermission(permission: PermissionChangedDto): void {
    const existingSharingChanges = this.userSharingService.editUser(this.editItems, permission, SharingActionType.Edit);
    this.changesMade = existingSharingChanges || this.unconfirmedSharings.length > 0;

    if (!existingSharingChanges) {
      this.sharingHelperService.cancelChanges(true);
    }
  }

  onDeletedPermission(permission: PermissionChangedDto): void {
    this.changesMade = this.userSharingService.editUser(this.editItems, permission, SharingActionType.Delete);
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  onShared(newSharings: ItemSharing[]): void {
    if (newSharings.length > 0) {
      this.unconfirmedSharings = this.unconfirmedSharings.concat(newSharings);
      this.compacted = false;
      this.changesMade = true;
    }
  }

  onSubmitChanges(): void {
    this.editItems = this.userSharingService.filterChanges(this.editItems);

    const viewModel: DocumentSharingViewModel = {
      documentIds: this.selectedDocuments.map(({ id }) => id),
      sharingActions: this.userSharingService.createUserEditViewModels(this.editItems).map(p => this.createActionViewModel(p))
        .concat(this.userSharingService.createUserAddViewModels(this.unconfirmedSharings, this.sharings)
          .map(p => this.createActionViewModel(p))),
    };

    this.userSharingService.sendAnalyticsNotification(viewModel, this.sharings);

    this.subs.sink = this.documentSharingService.shareDocument(viewModel)
      .subscribe(
        () => {
          this.userSharingService.deleteLocalShareItems(this.sharings, this.editItems);
          this.sharings = this.unconfirmedSharings.concat(this.sharings);
          this.clearChanges();
          this.documentsExplorerDispatchers.updateSharings(!!(this.sharings?.length > 1), this.sharings, this.selectedDocuments);
          this.modalRef.submit();
        },
        (error) => this.toastService.open(error.message, ToastType.Error)
      );
  }

  private createActionViewModel(viewModel: ItemSharingActionViewModel): DocumentSharingActionViewModel {
    const ret = viewModel as DocumentSharingActionViewModel;
    ret.documentPermissionType = ret.additionalPermissionType;
    return ret;
  }

  onCancelChanges(): void {
    this.userSharingService.undoChanges(this.sharings, this.editItems);
    this.clearChanges();
  }

  private clearChanges(): void {
    this.sharingHelperService.cancelChanges(false);
    this.changesMade = false;
    this.editItems = [];
    this.unconfirmedSharings = [];
  }

  private setInitialCompactMode(): void {
    this.compacted = !this.readonly && this.sharings?.length === 1;
  }

  private normalizeItemSharings(sharings: ItemSharing[]): ItemSharing[] {
    sharings.forEach(s => {
      s.permissionType = s.permissionType ?? PermissionType.NoAccess;
      s.additionalPermissionType = s.additionalPermissionType ?? DocumentPermissionType.None;
    });

    return sharings;
  }
}
