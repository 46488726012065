import { ChangeDetectorRef, Directive, inject, TemplateRef } from '@angular/core';

import { AuthSelectorsService } from '@kros-sk/auth';

import { extractNameFromEmail } from '../../tools';
import { KrosModalRef, KrosModalService } from '../../kros-modal';


@Directive()
export abstract class UserProfileBase {

  protected abstract modalUserContent: TemplateRef<any>;
  protected authSelectors = inject(AuthSelectorsService);
  protected modalService = inject(KrosModalService);
  protected cdRef = inject(ChangeDetectorRef);
  protected openedModal: KrosModalRef;

  getNameFromEmail(email: string): string {
    return extractNameFromEmail(email);
  }

  showUserSideBar(): void {
    const shouldChangePositionOfModal = document.getElementById('account').offsetTop > 20;
    this.openedModal = this.modalService.openCentered(
      this.modalUserContent,
      null,
      { showMobileArrowBack: false, addModalToBrowsersHistory: false, allowFocusAutocapture: false },
      '6rem',
      '100%',
      null,
      shouldChangePositionOfModal ? ['right-top-sidebar-with-info', 'right-top-sidebar'] : 'right-top-sidebar');
  }

  hide = (): void => {
    if (this.openedModal) {
      this.detectChanges();
      this.openedModal.cancel();
    }
  };

  protected detectChanges(): void {
    if (!this.cdRef['destroyed']) {
      this.cdRef.detectChanges();
    }
  }

}
