import { inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { ApplicationType } from '@kros-sk/ssw-cdk';

import { constructionDiaryActions } from './construction-diary.actions';
import { ConstructionDiaryState } from './construction-diary.state';

@Injectable()
export class ConstructionDiaryDispatchersService {

  private store = inject(Store<ConstructionDiaryState>);

  loadConstructionDiaryLicense(product: ApplicationType): void {
    this.store.dispatch(constructionDiaryActions.loadLicenseStart({ product }));
  }
}
