import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SetTypeService {
  isMenuOpened = false;

  toOpenMenu$ = new BehaviorSubject<{ open: boolean; rowIndex?: number }>({ open: false });

  openMenu(rowIndex: number): void {
    this.toOpenMenu$.next({ open: true, rowIndex });
  }

  popupOpened(): void {
    this.isMenuOpened = false;
    this.toOpenMenu$.next({ open: false });
  }
}
