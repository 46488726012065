<div role="document"
     class="kros-modal kros-modal-centered"
     [class.kros-modal-fullscreen-mobile]="fullscreenOnMobile"
     mobileHeight [mobileHeightSettings]="modalMobileHeightSettings">
  <div *ngIf="showMobileArrowBack" class="kros-modal-mobile-arrow-back">
    <button class="btn btn-link arrow-back" tabindex="-1" (click)="close()">
      <i class="material-icons secondary arrow-back-icon">arrow_back</i>
    </button>
    <hr class="hr-divider"/>
  </div>
  <div class="kros-modal-content kros-modal-content--empty" cdkTrapFocus [cdkTrapFocusAutoCapture]="allowFocusAutocapture"
       [ngClass]="{ 'full-mobile-vertical-height': !showMobileArrowBack,
                    'kros-modal-content--empty': !isContentRendered,
                    'kros-modal-small-screen' : smallScreenForDektop(),
                    'kros-modal-content--height-ios': isIOSOrIPadOS }"
       mobileHeight [mobileHeightSettings]="contentMobileHeightSettings">
    <ng-container [ngSwitch]="renderMethod">
      <div *ngSwitchCase="'text'" [innerHTML]="content"></div>

      <ng-container *ngSwitchCase="'template'">
        <ng-container *ngTemplateOutlet="$any(content); context: context"></ng-container>
      </ng-container>
    </ng-container>
    <ng-container #componentSlot></ng-container>
  </div>
</div>

<div *ngIf="addGradient" class="top-gradient"></div>
