<div class="kros-modal-header">
  <h4 class="modal-title">{{'BUILDING_PROGRESS.EXPORT_TABLE' | translate}}</h4>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onCloseClick()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div class="kros-modal-body">
  <div class="form-container">
    <div
      *ngIf="showSingleSheetExportCheck"
      class="form-group checkbox"
    >
      <input
        id="isSingleSheetExport"
        type="checkbox"
        [checked]="isExportPerObjects"
        (change)="exportModel.isSingleSheetExport = !exportModel.isSingleSheetExport"
      >
      <label for="isSingleSheetExport">{{'BUILDING_PROGRESS.EXPORT_PER_OBJECTS' | translate}}</label>
    </div>

    <div class="form-group checkbox">
      <input
        id="areColorCodesIncluded"
        type="checkbox"
        [checked]="exportModel.areColorCodesIncluded"
        (change)="exportModel.areColorCodesIncluded = !exportModel.areColorCodesIncluded"
      >
      <label for="areColorCodesIncluded">{{'BUILDING_PROGRESS.EXPORT_COLOR_CODES' | translate}}</label>
    </div>
  </div>
</div>

<div class="kros-modal-footer">
  <button
    class="btn btn-primary btn-submit"
    id="ga-export"
    data-test="export-button"
    type="button"
    (click)="export()"
  >{{'BUILDING_PROGRESS.EXPORT_ACTION' | translate}}</button>
</div>
