import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import * as approvalOrderActions from '@kros-sk/ssw-building-progress/approval-order';
import { ToastService, TranslateService } from '@kros-sk/ssw-shared-legacy';

import * as buildingProgressActions from './building-progress.actions';
import { BuildingProgressDispatchersService } from './building-progress-dispatchers.service';
import { BuildingProgressEffectsService } from './building-progress.effects.service';
import { BuildingProgressPeriodService } from '../../building-progress/services/building-progress-periods/building-progress-period.service';

@Injectable()
export class BuildingProgressPeriodsEffects {
  getPeriods$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.loadPeriodsStart),
      mergeMap(payload => this.buildingProgressPeriodService.getPeriods(payload.projectId, payload.contractorId).pipe(
        map(periods => {
          if (!payload.keepSelectedPeriods && periods.length > 0) {
            periods[0].isMarkedInFilter = true;
          }
          return buildingProgressActions.loadPeriodsSuccess(
            { periods, keepSelectedPeriods: payload.keepSelectedPeriods, wholeBuilding: payload.wholeBuilding }
          );
        }),
        catchError(error => of(buildingProgressActions.loadPeriodsError({ error })))
      ))
    );
  });

  createPeriod$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.createPeriodStart),
      switchMap(payload => this.buildingProgressPeriodService.createPeriod(payload.projectId, payload.dateFrom, payload.dateTo).pipe(
        map((id) => {
          this.toastService.open(this.translateService.translate('BUILDING_PROGRESS.PERIODS.PERIOD_WAS_CREATED'));
          if (!payload.addToWholeBuildingPeriods) {
            this.buildingProgressDispatchersService.loadConstructionData(payload.projectId, payload.searchModel);
          }
          return buildingProgressActions.createPeriodSuccess(
            { id, dateFrom: payload.dateFrom, dateTo: payload.dateTo, addToWholeBuildingPeriods: payload.addToWholeBuildingPeriods }
          );
        }),
        catchError(error =>
          of(buildingProgressActions.createPeriodError({ error, loading: this.effectsService.handleError(error, 'PERIOD_') })))
      ))
    );
  });

  editPeriod$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.editPeriodStart),
      switchMap(payload =>
        this.buildingProgressPeriodService.editPeriod(
          payload.id,
          payload.projectId,
          payload.dateFrom,
          payload.dateTo,
          payload.basicVatRate,
          payload.reducedVatRate)
          .pipe(
            map(items => {
              this.toastService.open(this.translateService.translate('BUILDING_PROGRESS.PERIODS.PERIOD_WAS_EDITED'));
              this.buildingProgressDispatchersService.loadConstructionData(payload.projectId, payload.searchModel);
              return buildingProgressActions
                .editPeriodSuccess({
                  id: payload.id,
                  dateFrom: payload.dateFrom,
                  dateTo: payload.dateTo,
                  basicVatRate: payload.basicVatRate,
                  reducedVatRate: payload.reducedVatRate,
                  items
                });
            }),
            catchError(error =>
              of(buildingProgressActions.editPeriodError({ error, loading: this.effectsService.handleError(error, 'PERIOD_') })))
          ))
    );
  });

  deletePeriod$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.deletePeriodStart),
      switchMap(payload => this.buildingProgressPeriodService.deletePeriod(payload.id, payload.deleteDocuments).pipe(
        map(() => {
          this.buildingProgressDispatchersService.loadConstructionData(payload.projectId, payload.searchModel);
          return buildingProgressActions.deletePeriodSuccess({ id: payload.id });
        }),
        catchError(error => this.effectsService.handlePeriodErrorAndGetAction(error, buildingProgressActions.deletePeriodError))
      ))
    );
  });

  setApprovedPeriod$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.setApprovedPeriodStart),
      switchMap(payload => this.buildingProgressPeriodService.setApproved(payload.projectId, payload.periodId).pipe(
        map(() => buildingProgressActions.setApprovedPeriodSuccess({
          projectId: payload.projectId,
          periodId: payload.periodId,
          isApprovalRequested: payload.isApprovalRequested,
          isApproved: payload.isApproved,
          isMultiStageApprovalModeEnabled: payload.isMultiStageApprovalModeEnabled
        })),
        catchError(error => this.effectsService.handlePeriodErrorAndGetAction(error, buildingProgressActions.setApprovedPeriodError))
      ))
    );
  });

  cancelApprovedPeriod$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.cancelApprovedPeriodStart),
      switchMap(payload => this.buildingProgressPeriodService.cancelApproved(payload.projectId, payload.periodId).pipe(
        map(() => this.effectsService.cancelApprovedPeriod(payload)),
        catchError(error => this.effectsService.handlePeriodErrorAndGetAction(error, buildingProgressActions.cancelApprovedPeriodError))
      ))
    );
  });

  setIsApprovalRequestedPeriod$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.setIsApprovalRequestedPeriodStart),
      switchMap(payload => this.buildingProgressPeriodService.setIsApprovalRequested(payload.projectId, payload.periodId).pipe(
        map(() => buildingProgressActions.setIsApprovalRequestedPeriodSuccess({ periodId: payload.periodId })),
        catchError(error =>
          this.effectsService.handlePeriodErrorAndGetAction(error, buildingProgressActions.setIsApprovalRequestedPeriodError))
      ))
    );
  });

  cancelIsApprovalRequestedPeriod$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.cancelIsApprovalRequestedPeriodStart),
      switchMap(payload => this.buildingProgressPeriodService.cancelIsApprovalRequested(payload.projectId, payload.periodId).pipe(
        map(() => {
          this.buildingProgressDispatchersService.loadConstructionData(
            payload.projectId, payload.searchModel, undefined, payload.contractorId
          );
          return buildingProgressActions.cancelIsApprovalRequestedPeriodSuccess({ id: payload.periodId });
        }),
        catchError(error =>
          this.effectsService.handlePeriodErrorAndGetAction(error, buildingProgressActions.cancelIsApprovalRequestedPeriodError))
      ))
    );
  });

  setIsLockedPeriod$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.setIsLockedPeriodStart),
      switchMap(payload => this.buildingProgressPeriodService.setIsLocked(payload.projectId, payload.periodId).pipe(
        map(() => buildingProgressActions.setIsLockedPeriodSuccess({ periodId: payload.periodId })),
        catchError(error =>
          this.effectsService.handlePeriodErrorAndGetAction(error, buildingProgressActions.setIsLockedPeriodError))
      ))
    );
  });

  cancelIsLockedPeriod$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(buildingProgressActions.cancelIsLockedPeriodStart),
      switchMap(payload => this.buildingProgressPeriodService.cancelIsLocked(payload.projectId, payload.periodId).pipe(
        map(() => {
          this.buildingProgressDispatchersService.loadConstructionData(payload.projectId, payload.searchModel);
          return buildingProgressActions.cancelIsLockedPeriodSuccess({ periodId: payload.periodId });
        }),
        catchError(error =>
          this.effectsService.handlePeriodErrorAndGetAction(error, buildingProgressActions.cancelIsApprovalRequestedPeriodError))
      ))
    );
  });

  saveApprovalOrder$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(approvalOrderActions.saveApprovalOrderSuccess),
      map(payload => buildingProgressActions.cancelIsApprovalRequestedPeriods({ projectId: payload.projectId }))
    );
  });

  constructor(
    private actions$: Actions,
    private buildingProgressDispatchersService: BuildingProgressDispatchersService,
    private buildingProgressPeriodService: BuildingProgressPeriodService,
    private effectsService: BuildingProgressEffectsService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) { }
}
