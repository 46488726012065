import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { getUtcDate } from '@kros-sk/ssw-shared-legacy';

import { BuildingProgressItem } from '../../../building-progress/models/construction-data.model';
import { BuildingProgressPeriod } from '../../models/building-progress-period.model';
import { environment } from '../../../../environments/environment';
import { PeriodHistory } from '../../../building-progress/models/period-history.model';

const gatewayApi = '/api/projects/';
const buildingProgressApi = '/buildingProgressService/periods';
const approvedCancelEndpoint = '/approvedCancel/';
const approvedSetEndpoint = '/approvedSet';
const contractorsApi = '/contractors/';
const createEndpoint = '/create';
const editEndpoint = '/edit';
const deleteEndpoint = '/delete/';
const isApprovalRequestedCancelEndpoint = '/isApprovalRequestedCancel';
const isApprovalRequestedSetEndpoint = '/isApprovalRequestedSet';
const periodListEndpoint = '/periodList';
const periodHistoryEndpoint = '/periodHistoryList';
const lockEndpoint = '/lockedSet';
const unlockEndpoint = '/lockedCancel';

@Injectable()
export class BuildingProgressPeriodService {

  constructor(
    private http: HttpClient,
  ) { }

  private get buildingProgressApi(): string {
    return environment.appUrls.titanGatewayUrl + '/api' + buildingProgressApi;
  }

  private get gatewayApi(): string {
    return environment.appUrls.titanGatewayUrl + gatewayApi;
  }

  setIsLocked(projectId: number, periodId: number): Observable<any> {
    return this.http.patch(this.getEndpointPath(projectId, lockEndpoint), { periodId });
  }

  cancelIsLocked(projectId: number, periodId: number): Observable<any> {
    return this.http.patch(this.getEndpointPath(projectId, unlockEndpoint), { periodId });
  }

  setIsApprovalRequested(projectId: number, periodId: number): Observable<any> {
    return this.http.patch(this.getEndpointPath(projectId, isApprovalRequestedSetEndpoint), { periodId });
  }

  cancelIsApprovalRequested(projectId: number, periodId: number): Observable<any> {
    return this.http.patch(this.getEndpointPath(projectId, isApprovalRequestedCancelEndpoint), { periodId });
  }

  setApproved(projectId: number, periodId: number): Observable<any> {
    return this.http.patch(this.getEndpointPath(projectId, approvedSetEndpoint), { periodId });
  }

  cancelApproved(projectId: number, periodId: number): Observable<any> {
    return this.http.patch(this.getEndpointPath(projectId, approvedCancelEndpoint), { periodId });
  }

  getPeriods(projectId: number, contractorId?: number): Observable<BuildingProgressPeriod[]> {
    return this.http.get<BuildingProgressPeriod[]>(this.getEndpointPath(projectId, periodListEndpoint, contractorId))
      .pipe(
        map(resp => resp.map(p => ({
          ...p,
          isMarkedInFilter: false
        })))
      );
  }

  getPeriodsHistory(projectId: number, contractorId?: number): Observable<PeriodHistory[]> {
    return this.http.get<PeriodHistory[]>(this.getEndpointPath(projectId, periodHistoryEndpoint, contractorId));
  }

  editPeriod(
    id: number,
    projectId: number,
    dateFrom: Date,
    dateTo: Date,
    basicVatRate: number,
    reducedVatRate: number
  ): Observable<BuildingProgressItem[]> {
    return this.http.put<BuildingProgressItem[]>(this.getEndpointPath(projectId, editEndpoint), {
      id,
      dateFrom: getUtcDate(dateFrom),
      dateTo: getUtcDate(dateTo),
      basicVatRate,
      reducedVatRate
    });
  }

  createPeriod(projectId: number, dateFrom: Date, dateTo: Date): Observable<any> {
    return this.http.post(
      this.getEndpointPath(projectId, createEndpoint),
      { dateFrom: getUtcDate(dateFrom), dateTo: getUtcDate(dateTo) });
  }

  deletePeriod(periodId: number, deleteDocuments: boolean): Observable<any> {
    return this.http.delete(this.buildingProgressApi + deleteEndpoint + periodId + '/' + deleteDocuments);
  }

  private getEndpointPath(projectId: number, endpoint: string, contractorId?: number): string {
    return this.gatewayApi + projectId + (contractorId ? contractorsApi + contractorId : '') + buildingProgressApi + endpoint;
  }
}
