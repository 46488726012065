<div class="kros-modal-header">
  <h4 class="kros-modal-title">{{'BUILDING_PROGRESS.INVOICE.INVOICE_LIST' | translate}}</h4>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onCloseClick()"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div class="invoice-table-container">
  <table>
    <thead>
      <tr>
        <th>{{'BUILDING_PROGRESS.INVOICE.INVOICE_NUMBER' | translate}}</th>

        <th>{{'BUILDING_PROGRESS.INVOICE.INVOICE_DATE' | translate}}</th>

        <th class="price">{{'BUILDING_PROGRESS.INVOICE.PRICE_WITHOUT_VAT' | translate}}</th>
      </tr>
    </thead>

    <tbody>
      <tr
        class="invoice-row"
        id="invoice-{{invoice.id}}"
        *ngFor="let invoice of invoices"
        data-test="invoice-in-invoice-list"
      >
        <td class="number">{{ invoice.documentNumber }}</td>

        <td class="date">{{ invoice.invoiceDate | date: 'dd.MM.yyyy' }}</td>

        <td class="price">
          {{ invoice.totalPrice | currency: invoice.currency: 'code': undefined: appLocation }}
        </td>

        <td class="open">
          <i
            data-test="invoice-open"
            krosTooltip="{{ 'BUILDING_PROGRESS.INVOICE.OPEN_INVOICE_APP' | translate }}"
            position="before"
            (click)="onOpenInvoiceClick(invoice)"
            class="material-icons"
          >open_in_new
          </i>
        </td>
      </tr>
    </tbody>
  </table>
</div>
