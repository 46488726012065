import { AbstractControl } from '@angular/forms';
import { ElementRef, Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { BillOfQuantitiesItemType, BoqItem } from '../../models';
import { BoqCell, DividerPosition, ExpressionPartChangedEventArgs } from '../models';
import { BoqEditHelper, BoqTableConfig, expressionFieldName, forceEditMode, getBoqCellClass, getLastRow } from './';
import { BoqTableKeyboardHandlingService } from '../boq-table-keyboard-handling.service';
import { isArrowKey, isDownKey, isEnterKey, isEscapeKey, isTabKey, isUpDownKey } from '../../data-table/helpers';
import { selectionColumnName } from './boq-edit.component';

@Injectable()
export class BoqEditVisualHelper {

  get rowIndex(): number {
    return this.focusedCell?.rowIndex;
  }

  get columns(): string[] {
    return this._columns;
  }

  get selectedIndexes(): number[] {
    const indexes = this.editHelper.markedIndexes;
    return indexes.length ? indexes : [this.focusedCell.rowIndex];
  }

  get afterFocus$(): Observable<void> {
    return this.afterFocus.asObservable();
  }

  private _columns: string[];
  private focusedCell: BoqCell;
  private isReadonly = false;
  private resetFocusedCells = true;
  private afterFocus = new Subject<void>();

  private get defaultColumnIndex(): number {
    return this._columns?.indexOf(expressionFieldName);
  }

  constructor(
    private editHelper: BoqEditHelper,
    private boqKeyboardHandlingService: BoqTableKeyboardHandlingService
  ) { }

  init(columnsConfig: BoqTableConfig, isReadonly: boolean, allowMultiSelection: boolean): void {
    this._columns = columnsConfig.columns.map(p => p.name);
    if (allowMultiSelection) {
      this._columns = [selectionColumnName, ...this.columns];
    }
    this.isReadonly = isReadonly;
  }

  setResetFocusedCells(): void {
    this.resetFocusedCells = false;
  }

  setFocusedRowIndex(rowIndex: number): void {
    this.focusedCell = { ...this.focusedCell, rowIndex };
  }

  setFocusedCellByItems(items: BoqItem[]): void {
    if (this.resetFocusedCells || items.filter(p => p.expression !== '').length === 0) {
      this.focusedCell = { rowIndex: 0, colId: this.defaultColumnIndex };
    } else {
      if (this.focusedCell.rowIndex > items.length - 1) {
        this.focusedCell = { ...this.focusedCell, rowIndex: getLastRow(items) };
      }

      this.resetFocusedCells = true;
    }
    this.focusTableCell(false);
  }

  resetFocusedCell(): void {
    this.focusedCell = { rowIndex: 0, colId: this.defaultColumnIndex };
  }

  getColumn(): string {
    return this._columns[this.focusedCell.colId];
  }

  getNewRowIndex(items: BoqItem[], count: number): number {
    return (this.focusedCell.rowIndex >= items.length - count && items.length > count)
      ? this.focusedCell.rowIndex - count
      : this.focusedCell.rowIndex;
  }

  getCellClass(itemType: BillOfQuantitiesItemType): string {
    return getBoqCellClass(itemType);
  }

  isCellSelected(items: BoqItem[], row: BoqItem, column: string): boolean {
    return items.findIndex(p => p.id === row.id) === this.focusedCell.rowIndex &&
      this._columns.indexOf(column) === this.focusedCell.colId && !this.isReadonly;
  }

  selectCell(items: BoqItem[], row: BoqItem, column: string): void {
    this.focusedCell.rowIndex = items.findIndex(p => p.id === row.id);
    this.focusedCell.colId = this._columns.indexOf(column);
  }

  private handleTableMovement(event: any, items: BoqItem[]): void {
    if (this.focusedCell) {
      this.focusedCell = this.boqKeyboardHandlingService.handleTableMovement(
        this.focusedCell,
        items.length,
        this._columns.length,
        event.key.toLowerCase(),
        event.shiftKey);
      this.focusTableCell();
    }
  }

  focusTableCellAfterEdit(preventFocus: boolean, forceEditMode = true): void {
    if (!preventFocus) {
      this.focusTableCell(forceEditMode);
    }
  }

  focusTableCell(forceEditMode = true): void {
    setTimeout(() => this.focusTableCellCore(this.focusedCell.rowIndex, this._columns[this.focusedCell.colId], forceEditMode));
  }

  getRowId(items: BoqItem[], row: BoqItem): string {
    return `row-${items.findIndex(p => p.id === row.id)}`;
  }

  getId(items: BoqItem[], row: BoqItem, column: string): string {
    return this.getIdByIndex(items.findIndex(p => p.id === row.id), column);
  }

  isSmallPanel(elementRef: ElementRef, additionalButtonsRef: ElementRef): boolean {
    let addidionalButtonsTotalWidth = 0;

    if (additionalButtonsRef?.nativeElement.children) {
      const children: any[] = Array.from(additionalButtonsRef.nativeElement.children);
      addidionalButtonsTotalWidth = children.reduce((acc: number, element: HTMLElement) => acc + (element?.offsetWidth ?? 0), 0);
    }

    return elementRef.nativeElement.clientWidth < addidionalButtonsTotalWidth + 60;
  }

  getExpressionPartChanged(lastDividerPosition: DividerPosition, event: KeyboardEvent): ExpressionPartChangedEventArgs {
    const path = event.composedPath();
    return {
      text: lastDividerPosition.value,
      left: (event.target as HTMLElement).offsetLeft,
      top: (path[2] as HTMLElement).offsetTop - (path[3] as HTMLElement).scrollTop
    };
  }

  onCellKeyDown(event: KeyboardEvent, readonly: boolean, items: BoqItem[], onCreate: Function): void {
    const element = event.target as HTMLElement;
    const editMode = ['input', 'p'].includes(element.tagName.toLowerCase());
    if (readonly || this.editHelper.handleMatchedBoqItems(event)) {
      event.preventDefault();
    } else {
      if (editMode && (isDownKey(event.key) || isEnterKey(event.key)) && this.rowIndex === items.length - 1) {
        event.preventDefault();
        onCreate(true);
      } else if ((editMode && (isUpDownKey(event.key) || isEnterKey(event.key))) ||
        (!editMode && isArrowKey(event.key)) || isTabKey(event.key)) {
        event.preventDefault();
        this.handleTableMovement(event, items);
      } else if (!isEscapeKey(event.key) && element !== document.activeElement) {
        forceEditMode(element);
      }
    }
    event.stopPropagation();
  }

  onExpressionKeyDown(event: KeyboardEvent, row: BoqItem, items: BoqItem[], readonly: boolean): DividerPosition {
    if (isEnterKey(event.key) || isTabKey(event.key)) {
      event.preventDefault();
    } else if (!isArrowKey(event.key) && !readonly) {
      return this.editHelper.getDividerPosition(event.key, items, row);
    }
    return undefined;
  }

  getFormForInsert(): AbstractControl<any, any> {
    let fieldExpression = this.editHelper.getForm(this.rowIndex)?.get(expressionFieldName);
    const oldValue: string = fieldExpression?.value?.trimEnd();

    if (oldValue !== '') {
      const field = this.editHelper.getForm(this.rowIndex + 1)?.get(expressionFieldName);
      const value = field?.value?.trimEnd();
      if (value === '') {
        fieldExpression = field;
        this.setFocusedRowIndex(this.rowIndex + 1);
      }
    }

    return fieldExpression;
  }

  private getIdByIndex(rowIndex: number, column: string): string {
    return `row-${rowIndex}-col-${column}`;
  }

  private focusTableCellCore(rowIndex: number, column: string, doForceEditMode = true): void {
    const cell = document.getElementById(this.getIdByIndex(rowIndex, column));
    cell?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
    const editor = cell?.getElementsByClassName('cell-input');
    this.editHelper.resetCarretPostion();
    if (doForceEditMode && editor?.length) {
      forceEditMode(editor[0] as HTMLElement);
    } else {
      cell?.focus();
    }
    this.afterFocus.next();
  }
}
