import { createAction, props } from '@ngrx/store';

import { BoqItem } from '@kros-sk/ssw-shared-legacy';

import { BoqEditViewModel } from '../../building-progress/models/boq-edit.view-model';
import { BoqPeriodItem } from '../../building-progress/models/boq-period-item.model';
import { BuildingProgressItem, BuildingProgressPeriodItem } from '../../building-progress/models/construction-data.model';

export const loadBoqPeriodItemsStart = createAction('[BuildingProgress] Loads Bill of quantities period items start',
  props<{ projectId: number, budgetItemId: number, contractorId: number }>());
export const loadBoqPeriodItemsSuccess = createAction('[BuildingProgress] Loads Bill of quantities period items success',
  props<{ items: BoqPeriodItem[] }>());

export const loadBoqItemsStart = createAction('[BuildingProgress] Loads Bill of quantities items start',
  props<{ projectId: number, budgetItemId: number, contractorId: number }>());
export const loadBoqItemsSuccess = createAction('[BuildingProgress] Loads Bill of quantities items success',
  props<{ items: BoqItem[] }>());

export const setBoqStart = createAction(
  '[BuildingProgress] Sets Bill of quantities start',
  props<{
    editModel: BoqEditViewModel,
    oldValue?: BoqEditViewModel,
    isUndoRedoOperation: boolean,
    isUndo?: boolean,
    originalItems?: BoqPeriodItem[],
    oldPeriodItem?: BuildingProgressPeriodItem
  }>());
export const setBoqSuccess = createAction(
  '[BuildingProgress] Sets Bill of quantities success',
  props<{
    items: BuildingProgressItem[],
    editModel: BoqEditViewModel,
    oldValue: BoqEditViewModel,
    isUndoRedoOperation: boolean,
    boqPeriodItems: BoqPeriodItem[]
  }>());

export const setBoqIds = createAction('[BuildingProgress] Set ids to Bill of quantities', props<{ boqItems: BoqPeriodItem[] }>());

export const resetBoqPeriodId = createAction('[BuildingProgress] Reset period id to Bill of quantities', props<{ periodId: number }>());

export const editBoqItems = createAction('[BuildingProgress] Edit Bill of quantities item',
  props<{ boqItems: BoqPeriodItem[] }>());

export const markBoqItem = createAction('[BuildingProgress] Mark Bill of quantities item',
  props<{ boqItem: BoqPeriodItem, periodId: number }>());

export const deleteBoqItem = createAction('[BuildingProgress] Delete Bill of quantities item',
  props<{ boqItemId: number, editedBoqItems: BoqPeriodItem[] }>());

export const createBoqItem = createAction('[BuildingProgress] Create Bill of quantities item',
  props<{ budgetItemId: number, position: number, isInsertDown: boolean }>());

export const originalItemsAreReseted = createAction('[BuildingProgress] Original items are reseted');

export const cancelBoqEditation = createAction('[BuildingProgress] Cancel Bill of quantities editation');
