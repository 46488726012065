import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { combineLatest, distinctUntilChanged, filter, ReplaySubject, switchMap, take } from 'rxjs';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import {
  ApplicationType,
  formatString,
  License,
  LicenseDispatchersService,
  LicenseSelectorsService,
  LicenseType,
  UISettingsService
} from '@kros-sk/ssw-cdk';
import { AuthSelectorsService, KrosUser } from '@kros-sk/auth';
import { BuildingProgressPermission, BuildingProgressPermissionType, PermissionType } from '@kros-sk/ssw-shared/permission';
import {
  ButtonConfig,
  LicenseInfoContentHelper,
  LicenseUrlService,
  MarketingModalComponent,
  NonPaidPackageConfig,
  PermissionListService,
  PluralisationByCountPipe,
  QuestionModalComponent,
  SchoolLicenseForbiddenModalComponent,
  TranslateService
} from '@kros-sk/ssw-shared-legacy';
import { KrosModalService } from '@kros-sk/components';

import { BuildingProgressSelectorsService } from '../../store/building-progress/building-progress-selectors.service';
import { hasContractorRight } from '../shared/building-progress-utils';

const RemindLicenseEndingLastDate = 'RemindPVLicenseEndingLastDate';

@Injectable()
export class BuildingProgressActionAccessService {

  get isLicenseFree(): boolean {
    return this.license?.licenseType === LicenseType.Free;
  }

  get isLicenseReadOnly(): boolean {
    return this.license?.licenseType === LicenseType.Readonly;
  }

  get canActivateTrial(): boolean {
    return this.license?.canActivateTrial;
  }

  get hasAccessToBoq(): boolean {
    return !this.hasInsufficientFreeLicenseForBoq && !this.isLicenseReadOnly;
  }

  private get isOwner(): boolean {
    return this.permission.permissionType === PermissionType.Owner;
  }

  private get isLicenseFull(): boolean {
    return this.license?.licenseType === LicenseType.Full;
  }

  private get isLicenseToExpire(): boolean {
    return this.license?.remindExpiringFullLicenseInDays >= this.license?.remainingDays;
  }

  private get hasInsufficientFreeLicenseForBoq(): boolean {
    return (this.isLicenseFree &&
      (this.permission.permissionType === PermissionType.Contributor || this.permission.permissionType === PermissionType.Owner ||
        hasContractorRight(this.permission.buildingProgressPermissionType)));
  }

  private destroyRef = inject(DestroyRef);
  private appInsightsService = inject(AppInsightsBaseService);
  private licenseDispatchersService = inject(LicenseDispatchersService);
  private krosModalService = inject(KrosModalService);
  private translateService = inject(TranslateService);
  private licenseService = inject(LicenseUrlService);
  private permissionListService = inject(PermissionListService);
  private pluralisationByCountPipe = inject(PluralisationByCountPipe);
  private uiSettingsService = inject(UISettingsService);
  private authSelectorsService = inject(AuthSelectorsService);
  private buildingProgressSelectorService = inject(BuildingProgressSelectorsService);
  private licenseSelectorsService = inject(LicenseSelectorsService);
  private licenseInfoContent = inject(LicenseInfoContentHelper);
  private permission: BuildingProgressPermission;
  private license: License;
  private budgetLicense: License;
  private desktopLicense: License;
  private licenseNumber: string;
  private areItemsSelected: boolean;
  private licenseLoaded = new ReplaySubject<number>(1);
  private permissionLoaded = new ReplaySubject<number>(1);
  private licenseEndingLastShowTime = 0;
  private licenseEndingLastShowTimeLoaded = new ReplaySubject<number>(1);
  private existValid: boolean;

  constructor() {
    this.licenseSelectorsService.buildingProgressLicense$.pipe(filter(Boolean), takeUntilDestroyed())
      .subscribe(license => {
        this.license = license;
        this.permissionListService.setLicense(ApplicationType.BuildingProgress, license);
        this.licenseLoaded.next(1);
      });
    this.buildingProgressSelectorService.permission$.pipe(filter(permission => !!permission), takeUntilDestroyed())
      .subscribe(permission => {
        this.permission = permission;
        this.permissionLoaded.next(1);
      });
    this.buildingProgressSelectorService.selectedItemIds$.pipe(takeUntilDestroyed())
      .subscribe(ids => this.areItemsSelected = ids.size > 0);
    this.authSelectorsService.currentUser$.pipe(
      filter(Boolean),
      distinctUntilChanged((prev: KrosUser, curr: KrosUser) => prev.userId === curr.userId),
      switchMap((user: KrosUser) => this.uiSettingsService.getUiSettings(RemindLicenseEndingLastDate, user.userId)),
      takeUntilDestroyed()
    ).subscribe(p => {
      this.licenseEndingLastShowTime = p?.lastShowTime;
      this.licenseEndingLastShowTimeLoaded.next(1);
    });
    this.licenseSelectorsService.budgetLicense$.pipe(takeUntilDestroyed())
      .subscribe(budgetLicense => this.budgetLicense = budgetLicense);
    this.licenseSelectorsService.desktopLicense$.pipe(takeUntilDestroyed())
      .subscribe(desktopLicense => this.desktopLicense = desktopLicense);
    this.licenseSelectorsService.licenseDetail$.pipe(filter(Boolean), takeUntilDestroyed())
      .subscribe(licenseDetail => {
        this.licenseNumber = licenseDetail.licenseNumber;
        this.existValid = licenseDetail.existValid;
      });
  }

  checkForAccessToShareBuildingProgress(): boolean {
    return this.checkForAccessCauseOfReadonlyLicense() && this.checkForFreeOwner();
  }

  checkForAccessToEdit(): boolean {
    return this.checkForAccessCauseOfReadonlyLicense();
  }

  checkForAccessToBulkOperations(isBudgetApproval = false): boolean {
    if (this.hasInsufficientFreeLicenseForBoq) {
      this.openGeneralNotAccessDialog(isBudgetApproval);
      return false;
    }

    return true;
  }

  checkForAccessToCancelApprovedPeriod(): boolean {
    if (this.isLicenseFree &&
      (this.containsPermission(this.permission.buildingProgressPermissionType, BuildingProgressPermissionType.Approver) ||
        this.permission.permissionType === PermissionType.Contributor)) {
      this.openGeneralNotAccessDialog(false);
      return false;
    }

    return true;
  }

  checkForAccessToExport(isBudgetApproval = false, shouldCheckSchoolLicense = false): boolean {
    if (shouldCheckSchoolLicense && this.checkSchoolLicense()) {
      return false;
    }

    if (this.isLicenseFree) {
      this.openGeneralNotAccessDialog(isBudgetApproval);
      return false;
    } else if (!this.checkForAccessCauseOfReadonlyLicense(isBudgetApproval)) {
      return false;
    }

    return true;
  }

  checkForAccessToExportInvoiceDetails(): boolean {
    return this.checkForAccessCauseOfReadonlyLicense();
  }

  checkForAccessToBoq(): boolean {
    if (this.hasInsufficientFreeLicenseForBoq) {
      this.openGeneralNotAccessDialog(false);
      return false;
    } else if (!this.checkForAccessCauseOfReadonlyLicense()) {
      return false;
    }

    return true;
  }

  checkForAccessToBoqControl(): boolean {
    if (this.isLicenseFree && this.permission.permissionType === PermissionType.Contributor) {
      return true;
    } else if (this.isLicenseReadOnly &&
      (this.permission.permissionType === PermissionType.Contributor || this.permission.permissionType === PermissionType.Owner)) {
      return true;
    }

    return false;
  }

  checkForAccessToCreateAndDeleteRelation(): boolean {
    return this.checkForAccessCauseOfReadonlyLicense(true);
  }

  checkForAccessToApproveAndDeleteChangeSheet(): boolean {
    return this.checkForAccessCauseOfReadonlyLicense(true) && this.checkForAccessCauseOfFreeLicenseAndContributor(true);
  }

  checkForAccessToTransferSubcontractorExecutions(): boolean {
    return this.checkForAccessCauseOfReadonlyLicense() && this.checkForAccessCauseOfFreeLicenseAndContributor();
  }

  checkForAccessToExcelFormatExports(): boolean {
    return this.checkForAccessCauseOfFreeLicense();
  }

  checkForFreeOwner(isBudgetApproval = false): boolean {
    if (this.isFreeOwner()) {
      this.openGeneralNotAccessDialog(isBudgetApproval);
      return false;
    }

    return true;
  }

  isFreeOwner(): boolean {
    return this.permission?.permissionType === PermissionType.Owner && this.isLicenseFree;
  }

  openMarketingDialogFreeOrToExpire(isBudgetApproval: boolean, isAutomatic = false): void {
    combineLatest([this.licenseLoaded, this.permissionLoaded, this.licenseEndingLastShowTimeLoaded])
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.canShowMarketingDialogTriggeredByUser(isAutomatic) ||
          this.canShowMarketingDialogTriggeredAutomatically(isAutomatic) ||
          this.canShowMarketingDialogForLicenseExpiry()) {
          this.openMarketingDialog(isBudgetApproval, isAutomatic);
        }
      });
  }

  checkForAccessToColorCodes(): boolean {
    return this.areItemsSelected ?
      this.checkForAccessCauseOfReadonlyOrFreeLicense() : this.checkForAccessCauseOfReadonlyLicense();
  }

  checkForAccessToPartialItems(): boolean {
    if (this.isLicenseFree) {
      this.openGeneralNotAccessDialog(false);
      return false;
    }
    return true;
  }

  checkForAccessCauseOfReadonlyOrFreeLicense(isBudgetApproval = false): boolean {
    return this.checkForAccessCauseOfReadonlyLicense(isBudgetApproval) && this.checkForAccessCauseOfFreeLicense(isBudgetApproval);
  }

  activateTrialLicenseWithQuestion(isBudgetApproval?: boolean): void {
    setTimeout(() => {
      this.krosModalService.openCentered(
        QuestionModalComponent,
        {
          body: this.translateTrialText('BUILDING_PROGRESS.LICENSE_ACTIVATE_TRIAL_QUESTION'),
          confirmButton: this.translateService.translate('COMMON.YES')
        },
        {
          closeOnBackdropClick: false,
          fullscreenOnMobile: false,
          showMobileArrowBack: false
        },
        NaN,
        NaN,
        undefined,
        'no-min-width',
      ).afterClosed$.pipe(take(1)).subscribe(result => {
        if (result.type === 'submit') {
          this.licenseDispatchersService.activateTrialBuildingProgressLicense(isBudgetApproval);
          this.appInsightsService.trackEvent('PV-license-activate-trial');
        }
      });
    }, 0);
  }

  private canShowMarketingDialogTriggeredAutomatically(isAutomatic: boolean): boolean {
    return (this.isLicenseFree || this.isLicenseReadOnly) && isAutomatic && this.isOwner;
  }
  private canShowMarketingDialogTriggeredByUser(isAutomatic: boolean): boolean {
    return this.isLicenseFree && !isAutomatic;
  }

  private canShowMarketingDialogForLicenseExpiry(): boolean {
    if (this.isLicenseFull && this.isLicenseToExpire && this.isOwner && !this.license?.isSchool &&
      (!this.licenseEndingLastShowTime || (Date.now() - +this.licenseEndingLastShowTime) > 24 * 60 * 60 * 1000)) {
      this.saveLicenseEndingLastShowTime();
      return true;
    }

    return false;
  }

  private saveLicenseEndingLastShowTime(): void {
    this.licenseEndingLastShowTime = Date.now();
    this.uiSettingsService.saveUiSettings(RemindLicenseEndingLastDate, { lastShowTime: this.licenseEndingLastShowTime.toString() })
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  private checkForAccessCauseOfReadonlyLicense(isBudgetApproval = false): boolean {
    if (this.isLicenseReadOnly) {
      this.openMarketingDialog(isBudgetApproval);
      return false;
    }

    return true;
  }

  private checkForAccessCauseOfFreeLicense(isBudgetApproval = false): boolean {
    if (this.isLicenseFree) {
      this.openGeneralNotAccessDialog(isBudgetApproval);
      return false;
    }

    return true;
  }

  private checkForAccessCauseOfFreeLicenseAndContributor(isBudgetApproval = false): boolean {
    if (this.isLicenseFree && this.permission.permissionType === PermissionType.Contributor) {
      this.openGeneralNotAccessDialog(isBudgetApproval);
      return false;
    }

    return true;
  }

  private openGeneralNotAccessDialog(isBudgetApproval: boolean): void {
    this.openMarketingDialog(isBudgetApproval);
  }

  private openMarketingDialog(isBudgetApproval: boolean, isAutomaticLaunch = false): void {
    setTimeout(() => {
      this.krosModalService.openCentered<NonPaidPackageConfig>(
        MarketingModalComponent,
        {
          canActivateTrial: this.license.canActivateTrial && !this.isLicenseReadOnly,
          orderUrl: this.licenseService.orderUrl(
            this.existValid,
            this.license,
            this.licenseNumber,
            this.budgetLicense,
            this.desktopLicense),
          trialButton: this.getTrialButton(isBudgetApproval),
          appPrefix: isBudgetApproval
            ? 'ZPV'
            : 'PV',
          isAutomaticLaunch,
          isLicenseFree: this.isLicenseFree,
          isLicenseReadonly: this.isLicenseReadOnly,
          isLicenseFull: this.isLicenseFull,
          isBudgetApproval,
          isOwner: this.permission.permissionType === PermissionType.Owner,
          isLicenseToExpire: this.isLicenseToExpire,
          trialDuration: this.license.trialDuration,
          remainingDays: this.license.remainingDays,
          licenseInfoContent: this.licenseInfoContent,
          isBudgetOrDesktopLicenseFull: this.budgetLicense?.licenseType === LicenseType.Full ||
            this.desktopLicense?.licenseType === LicenseType.Full,
          canOpenModal: this.budgetLicense?.remainingDays === 0 && this.desktopLicense.remainingDays === 0
        },
        { allowFocusAutocapture: false }
      );
    }, 0);
  }

  private getTrialButton(isBudgetApproval?: boolean): ButtonConfig {
    return {
      label: this.translateTrialText('LICENSE_INFO.TRY_FOR_X_DAYS'),
      action: (): void => { this.activateTrialLicenseWithQuestion(isBudgetApproval); }
    };
  }

  private translateTrialText(word: string): string {
    return formatString(this.translateService.translate(word),
      '' + this.license.trialDuration,
      this.pluralisationByCountPipe.transform(this.license.trialDuration, 'LICENSE_INFO.DAY'));
  }

  private containsPermission(value: BuildingProgressPermissionType, toCheck: BuildingProgressPermissionType): boolean {
    return (value | toCheck) === value;
  }

  private checkSchoolLicense(): boolean {
    if (this.license?.isSchool) {
      this.krosModalService.openCentered(SchoolLicenseForbiddenModalComponent, null, { addModalToBrowsersHistory: false });
      return true;
    }

    return false;
  }
}
