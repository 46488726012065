import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kros-owner-sharing-combo',
  templateUrl: './owner-sharing-combo.component.html',
  styleUrls: ['./owner-sharing-combo.component.scss']
})
export class OwnerSharingComboComponent {
  selectedEmail = 'prilepok@kros.sk';
  disabled = false;

  @Input() emails: string[] = [];

  @Output() changedOwner = new EventEmitter<string>();

  onOwnerChanged(email: string): void {
    this.changedOwner.emit(email);
  }
}
