import { ComponentPortal } from '@angular/cdk/portal';
import { ConnectionPositionPair, Overlay, OverlayConfig, PositionStrategy } from '@angular/cdk/overlay';
import { Injectable, InjectionToken, Injector } from '@angular/core';

import { Observable } from 'rxjs';

import { getDefaultKrosModalSettings, KrosModalRef, KrosModalSettings, ModalContent } from './kros-modal-ref';
import { KrosModalComponent } from '../kros-modal/kros-modal.component';
import { KrosModalStateService } from './kros-modal-state.service';
import { MessageBoxModalComponent, MessageBoxProperties } from '../message-box-modal/message-box-modal.component';

export const MODAL_PERMISSIONS = new InjectionToken('Permissions token for use in modals, allows multi providers definitions');

@Injectable({
  providedIn: 'root'
})
export class KrosModalService {

  private messageBoxSettings = {
    closeOnBackdropClick: false,
    showMobileArrowBack: false,
    fullscreenOnMobile: false,
  };

  constructor(
    private overlay: Overlay,
    private injector: Injector,
    private modalState: KrosModalStateService,
  ) {
  }

  /**
   * Verifies is there is any modal window opened with browser history behavior.
   */
  get isAnyModalWithHistoryOpened(): boolean {
    return this.modalState.isAnyModalWithHistoryOpened;
  }

  isAnyModalOpened(): boolean {
    return this.modalState.isAnyModalOpened();
  }

  /**
   * Opens content - component or template as modal in the middle of the screen
   * Page scrolling is blocked
   * @param content Component or Template to be displayed as modal
   * @param data Data to be passed into content
   * @param modalSettings Additional behavior settings
   */
  openCentered<T>(
    content: ModalContent,
    data?: T,
    modalSettings: Partial<KrosModalSettings> = null,
    height?: number | string,
    minHeight?: number | string,
    customOverlayClass?: string | string[],
    customPanelClass?: string | string[]
  ): KrosModalRef<T> {
    return this.open<T>(
      content,
      this.getOverlayConfigCentered(height, minHeight, customOverlayClass, customPanelClass),
      modalSettings,
      data
    );
  }

  /**
   * Opens content - component or template as modal connected to html element
   * Modal will be positioned next to element passed in as parameter and
   * can be moved to another position on page scroll depending on available space above or below element
   * @param content Component or Template to be displayed as modal
   * @param element Html element to which modal should be connected
   * @param data Data to be passed into content
   * @param modalSettings Additional behavior settings
   */
  openConnectedToElement<T>(
    content: ModalContent,
    element: HTMLElement,
    data?: T,
    modalSettings: Partial<KrosModalSettings> = null,
    customOverlayClass?: string | string[],
    customPanelClass?: string | string[]
  ): KrosModalRef<T> {
    return this.open<T>(
      content,
      this.getOverlayConfigToElement(element, null, customOverlayClass, customPanelClass),
      modalSettings,
      data,
      true
    );
  }

  /**
   * Opens content - component or template as modal connected to html element
   * Modal will be positioned based on custom position config to element passed in as parameter and
   * can be moved to another position on page scroll depending on available space above or below element
   * @param content Component or Template to be displayed as modal
   * @param element Html element to which modal should be connected
   * @param positionPairs ConnectionPositionPair array for custom element positioning
   * @param data Data to be passed into content
   * @param modalSettings Additional behavior settings
   */
  openConnectedToElementCustomPositioned<T>(
    content: ModalContent,
    element: HTMLElement,
    positionPairs: ConnectionPositionPair[],
    data?: T,
    customOverlayClass?: string | string[],
    customPanelClass?: string | string[],
    modalSettings: Partial<KrosModalSettings> = null
  ): KrosModalRef<T> {
    return this.open<T>(
      content,
      this.getOverlayConfigToElement(element, positionPairs, customOverlayClass, customPanelClass),
      modalSettings,
      data,
      true
    );
  }

  /**
   * Opens content - component or template as modal according to provided config
   * @param content Component or Template to be displayed as modal
   * @param config Overlay config - CDK
   * @param additionalSettings Additional settings to set up modal behavior
   * @param data Data to be passed into content
   * @param ignoreMultipleModalsBehavior should be set to true when new modal is connected to component on already shown modal.
   */
  open<T>(
    content: ModalContent,
    config: OverlayConfig,
    additionalSettings: Partial<KrosModalSettings>,
    data?: T,
    ignoreMultipleModalsBehavior?: boolean
  ): KrosModalRef<T> {
    const settings = { ...getDefaultKrosModalSettings(), ...additionalSettings };

    const overlayRef = this.overlay.create(config);
    const modalRef = new KrosModalRef<T>(
      overlayRef,
      content,
      settings,
      data,
    );

    const permissions = !data ? null : data['permissions'];
    const injector = this.createInjector(modalRef, permissions);
    modalRef.componentRef = overlayRef.attach(new ComponentPortal(KrosModalComponent, null, injector));

    if (!ignoreMultipleModalsBehavior) this.modalState.attachedNewModal(modalRef);

    return modalRef;
  }

  /**
   * Opens message box component as modal in the middle of the screen, not full-screen
   * Page scrolling is blocked
   * @param data Data to be passed into content
   * @param messageBoxSettings optional behavior settings
   */
  openModalMessageBox(
    data: MessageBoxProperties,
    messageBoxSettings: Partial<KrosModalSettings> = this.messageBoxSettings
  ): Observable<any> {
    return this.openModalMessageBoxModalRef(data, messageBoxSettings).afterClosed$;
  }

  openModalMessageBoxModalRef(
    data: MessageBoxProperties,
    messageBoxSettings: Partial<KrosModalSettings> = this.messageBoxSettings
  ): KrosModalRef {
    if (!messageBoxSettings.hasOwnProperty('fullscreenOnMobile')) {
      messageBoxSettings.fullscreenOnMobile = false;
      messageBoxSettings.showMobileArrowBack = false;
    }
    return this.openCentered<MessageBoxProperties>(MessageBoxModalComponent, data, messageBoxSettings);
  }

  /**
   *
   * @param modalRef Creates Portal injector to the inject modalRef
   * @param permissions Permissions used in modal
   */
  private createInjector(modalRef: KrosModalRef, permissions?: any): Injector {
    const modalRefProvider = {
      provide: KrosModalRef,
      useValue: modalRef
    };

    let modalPermissionsProvider = {
      provide: MODAL_PERMISSIONS,
      useValue: {},
      multi: true
    };

    if (permissions) {
      modalPermissionsProvider = {
        provide: MODAL_PERMISSIONS,
        useValue: permissions,
        multi: true
      };
    }

    return Injector.create({
      providers: [modalRefProvider, modalPermissionsProvider],
      parent: this.injector
    });
  }

  /**
   * Creates overlay configuration for centered modal:
   * - is centered in the middle of the screen
   * - page scroll is blocked
   */
  private getOverlayConfigCentered(
    minHeight?: number | string,
    height?: number | string,
    customOverlayClass?: string | string[],
    customPanelClass?: string | string[]
  ): OverlayConfig {
    return new OverlayConfig({
      hasBackdrop: true,
      backdropClass: customOverlayClass,
      panelClass: customPanelClass,
      height,
      minHeight,
      positionStrategy: this.getPositionStrategyCentered(),
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });
  }

  /**
   * Creates overlay configuration for modal connected to html element
   * - is connected to element (above or below) depending on available space around element
   *   available positions can be configured by ConnectionPositionPairs in getPositions method
   * @param element html to which modal should be connected
   */
  private getOverlayConfigToElement(
    element: HTMLElement,
    positionPairs?: ConnectionPositionPair[],
    customOverlayClass?: string | string[],
    customPanelClass?: string | string[]
  ): OverlayConfig {
    if (!customPanelClass) {
      customPanelClass = 'kros-modal-connected';
    } else {
      if (Array.isArray(customPanelClass)) {
        customPanelClass.push('kros-modal-connected');
      } else {
        customPanelClass = [customPanelClass, 'kros-modal-connected'];
      }
    }
    return new OverlayConfig({
      hasBackdrop: true,
      backdropClass: customOverlayClass,
      panelClass: customPanelClass,
      positionStrategy: this.getPositionStrategyToElement(element, positionPairs),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });
  }

  /**
   * Creates centered position strategy
   */
  private getPositionStrategyCentered(): PositionStrategy {
    return this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();
  }

  /**
   * Creates position strategy to html element
   * @param element html to which modal should be connected
   */
  private getPositionStrategyToElement(
    element: HTMLElement,
    positionPairs?: ConnectionPositionPair[]
  ): PositionStrategy {
    return this.overlay.position()
      .flexibleConnectedTo(element)
      .withPositions(positionPairs ? positionPairs : this.getPositions())
      .withFlexibleDimensions(false)
      .withPush(false);
  }

  /**
   * Predefined connected position pairs to position modal depending on available space
   * Defines pairs:
   * originX a originY - html element, to which should be modal connected
   * overlayX a overlayY - modal
   */
  private getPositions(): ConnectionPositionPair[] {
    return [
      {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
      },
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
      },
      {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'bottom',
      },
      {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
      },
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      },
      {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
      },
    ];
  }
}
