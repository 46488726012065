import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { DocumentsState, UploadFileInfoModel, UploadVersionInfoModel } from '@kros-sk/ssw-shared-legacy';

import * as listActions from './documents.actions';

@Injectable()
export class DocumentsDispatchersService {
  constructor(
    private store: Store<DocumentsState>
  ) { }

  clearState(): void {
    this.store.dispatch(
      listActions.clearState()
    );
  }

  loadFolderHierarchy(projectId: number): void {
    this.store.dispatch(
      listActions.loadFolderHierarchyStart({ projectId })
    );
  }

  loadUploadingData(temporaryId: string): void {
    this.store.dispatch(
      listActions.loadUploadingDataStart({ temporaryId })
    );
  }

  clearUploadingData(): void {
    this.store.dispatch(
      listActions.clearUploadingData()
    );
  }

  uploadBuilding(fileInfo: UploadFileInfoModel, accessKey: string): void {
    this.store.dispatch(
      listActions.uploadBuildingStart({ fileInfo, accessKey })
    );
  }

  uploadBuildingVersion(versionInfo: UploadVersionInfoModel, accessKey: string, projectId: number, folderId: string): void {
    this.store.dispatch(
      listActions.uploadBuildingVersionStart({ versionInfo, accessKey, projectId, folderId })
    );
  }

  loadDocumentVersions(documentId: string): void {
    this.store.dispatch(
      listActions.loadDocumentVersionStart({ documentId })
    );
  }

  setDescription(documentId: string, documentVersionId: string, description: string): void {
    this.store.dispatch(
      listActions.setDescriptionStart({ documentId, documentVersionId, description })
    );
  }
}
