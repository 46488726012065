import { ActionCreator, createReducer, on, ReducerTypes } from '@ngrx/store';

import produce from 'immer';
import { ApplicationType } from '@kros-sk/ssw-cdk';
import { getMultiselectSelectedAndIndeterminateItems, undoRedoActions } from '@kros-sk/ssw-shared-legacy';

import * as buildingProgressActions from './building-progress.actions';
import * as buildingProgressDataSettingsActions from './building-progress-data-settings.actions';
import * as reducerHelper from './building-progress-reducer.helper';
import {
  adapterComments,
  adapterNotes,
  adapterSharingList
} from './building-progress.adapters';
import { boqReducers } from './building-progress-boq.reducer';
import { BuildingProgressState, initialBuildingProgressState } from './building-progress.state';
import { commentsReducers } from './building-progress-comments.reducer';
import { editationReducers } from './building-progress-editation.reducer';
import {
  loadNewConstructionDataHasNotes,
  loadNewConstructionDataWithEditedNote
} from './building-progress-notes-reducer.helper';
import { partialItemsReducers } from './partial-items';
import { periodsReducers } from './building-progress-periods.reducer';

const reducers: ReducerTypes<BuildingProgressState, ActionCreator[]>[] = [
  on(buildingProgressActions.loadConstructionDataSuccess, (state, payload): BuildingProgressState => {
    return {
      ...state,
      constructionData: payload.constructionData,
      comments: adapterComments.removeAll(state.comments),
      budgetCreateState: {
        ...state.budgetCreateState,
        isProcessing: state.budgetCreateState.isProcessing && !payload.constructionData.items.length
      }
    };
  }),
  on(buildingProgressActions.loadConstructionDataError, (state, payload): BuildingProgressState => {
    return {
      ...state,
      constructionData: payload.constructionData,
      comments: adapterComments.removeAll(state.comments)
    };
  }),
  on(buildingProgressActions.loadSelectedConstructionDataSuccess, (state, payload): BuildingProgressState => {
    const data = produce(payload.constructionData, newData => {
      newData.items = payload.constructionData.items.filter(i =>
        state.selectedItemIds.has(i.id) || state.indeterminateItemIds.has(i.id));
    });

    return {
      ...state,
      constructionData: data,
      comments: adapterComments.removeAll(state.comments)
    };
  }),
  on(buildingProgressActions.loadSharingListSuccess, (state, payload): BuildingProgressState => {
    return {
      ...state,
      sharingList: adapterSharingList.setAll(payload.sharingList, state.sharingList),
    };
  }),
  on(buildingProgressActions.clearMultiSelectItems, (state, payload): BuildingProgressState => {
    return { ...state, selectedItemIds: new Set<number>(), indeterminateItemIds: new Set<number>() };
  }),
  on(buildingProgressActions.multiSelectAllItems, (state, payload): BuildingProgressState => {
    const itemIds = state.constructionData?.items?.length > 0 ? state.constructionData.items.map(item => item.id) : [];
    const itemIdsSet = new Set<number>(itemIds);

    return { ...state, selectedItemIds: itemIdsSet, indeterminateItemIds: new Set<number>() };
  }),
  on(buildingProgressActions.multiSelectItems, (state, payload): BuildingProgressState => {
    if (!state.constructionData?.items?.length) {
      return state;
    }

    const selection = getMultiselectSelectedAndIndeterminateItems(
      state.constructionData.items,
      state.selectedItemIds,
      state.indeterminateItemIds,
      payload.id,
      payload.state
    );
    return { ...state, selectedItemIds: selection.selectedItems, indeterminateItemIds: selection.indeterminateItems };
  }),
  on(undoRedoActions.clearUndoRedoStack, (state): BuildingProgressState => {
    return { ...state, cellToFocusAndAnimate: [] };
  }),
  on(buildingProgressActions.loadSettingsStart, (state): BuildingProgressState => {
    return { ...state, settings: undefined };
  }),
  on(buildingProgressActions.loadSettingsSuccess, (state, payload): BuildingProgressState => {
    return { ...state, settings: payload.settings };
  }),
  on(buildingProgressActions.loadLicenseAgreementStart, (state): BuildingProgressState => {
    return { ...state, settings: undefined };
  }),
  on(buildingProgressActions.loadLicenseAgreementSuccess, (state, payload): BuildingProgressState => {
    return { ...state, licenseAgreement: payload.licenseAgreement };
  }),
  on(buildingProgressActions.confirmLicenseAgreementSuccess, (state): BuildingProgressState => {
    return { ...state, licenseAgreement: true };
  }),
  on(buildingProgressActions.changeSelectedView, (state, payload): BuildingProgressState => {
    return { ...state, selectedView: payload.selectedView };
  }),
  on(buildingProgressActions.loadTableExportSettingsStart, (state): BuildingProgressState => {
    return { ...state, tableExportSettings: undefined };
  }),
  on(buildingProgressActions.loadTableExportSettingsSuccess, (state, payload): BuildingProgressState => {
    return { ...state, tableExportSettings: payload.tableExportSettings };
  }),
  on(buildingProgressActions.changeTableExportSettings, (state, payload): BuildingProgressState => {
    return { ...state, tableExportSettings: payload.tableExportSettings };
  }),
  on(buildingProgressActions.loadPermissionSuccess, (state, payload): BuildingProgressState => {
    return { ...state, permission: payload.permission };
  }),
  on(buildingProgressActions.loadHasDocumentsSuccess, (state, payload): BuildingProgressState => {
    return { ...state, hasDocuments: payload.hasDocuments };
  }),
  on(buildingProgressDataSettingsActions.loadDataSettingsSuccess, (state, payload): BuildingProgressState => {
    return {
      ...state,
      dataSettings: { hiddenColumns: payload.hiddenColumns, isSummaryPanelVisible: payload.isSummaryPanelVisible }
    };
  }),
  on(buildingProgressDataSettingsActions.saveDataSettings, (state, payload): BuildingProgressState => {
    return {
      ...state,
      dataSettings: {
        hiddenColumns: payload.hiddenColumns ?? state.dataSettings.hiddenColumns,
        isSummaryPanelVisible: payload.isSummaryPanelVisible
      }
    };
  }),
  on(buildingProgressActions.setHasPeriodInvoices, (state, payload): BuildingProgressState => {
    return produce(state, draftState => {
      draftState.constructionData.periods.forEach(p => {
        if (payload.ids.includes(p.id)) {
          p.hasInvoices = payload.hasInvoices;
        }
      });
    });
  }),
  on(buildingProgressActions.loadItemDrawSheetExportSettingsStart, (state): BuildingProgressState => {
    return { ...state, itemDrawSheetExportSettings: undefined };
  }),
  on(buildingProgressActions.loadItemDrawSheetExportSettingsSuccess, (state, payload): BuildingProgressState => {
    return { ...state, itemDrawSheetExportSettings: payload };
  }),
  on(buildingProgressActions.loadRemainingBudgetExportSettingsStart, (state): BuildingProgressState => {
    return { ...state, remainingBudgetExportSettings: undefined };
  }),
  on(buildingProgressActions.loadRemainingBudgetExportSettingsSuccess, (state, payload): BuildingProgressState => {
    return { ...state, remainingBudgetExportSettings: payload };
  }),
  on(buildingProgressActions.setItemsNotCompletedStart, (state, _): BuildingProgressState => {
    return { ...state, tableIsBusy: true };
  }),
  on(buildingProgressActions.setItemsNotCompletedSuccess, (state, payload): BuildingProgressState => {
    return {
      ...state,
      constructionData: reducerHelper.loadNewConstructionData(state.constructionData, payload.editedItems, undefined, true),
      tableIsBusy: false
    };
  }),
  on(buildingProgressActions.setItemsNotCompletedError, (state, _): BuildingProgressState => {
    return { ...state, tableIsBusy: false };
  }),
  on(buildingProgressActions.loadBudgetHasItemsSuccess, (state, payload): BuildingProgressState => {
    return {
      ...state,
      budgetCreateState: { ...state.budgetCreateState, budgetHasItems: payload.budgetHasItems }
    };
  }),
  on(buildingProgressActions.loadBudgetHasItemsError, (state): BuildingProgressState => {
    return {
      ...state,
      budgetCreateState: { ...state.budgetCreateState, budgetHasItems: false }
    };
  }),
  on(buildingProgressActions.startBudgetCreationSuccess, (state): BuildingProgressState => {
    return {
      ...state,
      budgetCreateState: { ...state.budgetCreateState, isProcessing: true }
    };
  }),
  on(buildingProgressActions.startBudgetCreationError, (state): BuildingProgressState => {
    return {
      ...state,
      budgetCreateState: { ...state.budgetCreateState, isProcessing: false }
    };
  }),
  on(buildingProgressActions.loadBudgetCreationActiveStateSuccess, (state, payload): BuildingProgressState => {
    return {
      ...state,
      budgetCreateState: { ...state.budgetCreateState, isProcessing: payload.isProcessing }
    };
  }),
  on(buildingProgressActions.loadNotesSuccess, (state, payload): BuildingProgressState => {
    const buildingProgressNotes = payload.notes.filter(n => n.applicationType === ApplicationType.BuildingProgress);
    const notesBudgetItemIds = buildingProgressNotes.map(n => n.budgetItemId);

    return {
      ...state,
      constructionData: loadNewConstructionDataHasNotes(state.constructionData, notesBudgetItemIds),
      buildingProgressNotes: adapterNotes.setAll(buildingProgressNotes, state.buildingProgressNotes),
    };
  }),
  on(buildingProgressActions.saveBuildingProgressNoteSuccess, (state, payload): BuildingProgressState => {
    return {
      ...state,
      constructionData: loadNewConstructionDataWithEditedNote(state.constructionData, payload.note.budgetItemId, !!payload.note.note),
      buildingProgressNotes: !!payload.note.note
        ? state.buildingProgressNotes.entities[payload.note.budgetItemId]
          ? adapterNotes.setOne(payload.note, state.buildingProgressNotes)
          : adapterNotes.addOne(payload.note, state.buildingProgressNotes)
        : adapterNotes.removeOne(payload.note.budgetItemId, state.buildingProgressNotes),
    };
  }),
];

export const reducer = createReducer(
  initialBuildingProgressState,
  ...reducers,
  ...periodsReducers,
  ...editationReducers,
  ...commentsReducers,
  ...boqReducers,
  ...partialItemsReducers);
