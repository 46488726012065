import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ErrorsOptions } from '../decorators/handle-errors/handle-errors-decorator';

export interface Options {
  body?: any;
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  observe?: 'body' | 'events' | 'response';
  reportProgress?: boolean;
}

// WARNING: Pay attention to the case when HandleMethodErrors decorator decorates the method in the children class that extends
// RequestsWithErrorHandling. Then after the first successful request without an error, something happens with currentCriticalOptions
// and the errorHandler starts showing error toasts even for the status code configured by HandleMethodErrors decorator
export abstract class RequestsWithErrorHandling {
  currentCriticalOptions: ErrorsOptions;
  globalCriticalOptions: ErrorsOptions;
  noConnectionId: boolean;

  protected constructor(
    protected http: HttpClient
  ) { }

  protected httpRequest<T>(method: string, url: string, body?: any, params?: any, responseType?: string): Observable<T> {
    return this.http.request<T>(method, url, this.httpOptions(body, params, responseType));
  }

  protected httpRequestWithFullResponse<T>(
    method: string,
    url: string,
    body?: any,
    params?: any,
    responseType?: string
  ): Observable<HttpResponse<T>> {
    return this.http.request<T>(
      method,
      url,
      {
        ...this.httpOptions(body, params, responseType),
        observe: 'response'
      }
    );
  }

  protected httpRequestNotGeneric(method: string, url: string, options?: Options): Observable<any> {
    const requestOptions = this.addHeadersToOptions(options);
    return this.http.request(method, url, requestOptions);
  }

  private httpOptions(body?: any, params?: any, responseType?: any): { body: any, params: any, headers: HttpHeaders, responseType: any } {
    return {
      body,
      headers: this.createHeaders(),
      params,
      responseType
    };
  }

  private addHeadersToOptions(options?: Options): Options {
    if (options == null) {
      return {
        headers: this.createHeaders()
      };
    }

    if (options.headers == null) {
      return {
        ...options,
        headers: this.createHeaders()
      };
    }

    if (options.headers instanceof HttpHeaders) {
      return {
        ...options,
        headers: this.setHeaders(options.headers)
      };
    }

    return {
      ...options,
      headers: {
        ...options.headers,
        'kros-handle-status': this.currentCriticalOptions?.status ? this.currentCriticalOptions.status : [],
        'kros-no-connection-id': this.noConnectionId ? '1' : '0'
      }
    };
  }

  private createHeaders(): HttpHeaders {
    return new HttpHeaders()
      .set('kros-handle-status', this.currentCriticalOptions?.status
        ? this.currentCriticalOptions.status
        : []
      )
      .set('kros-no-connection-id', this.noConnectionId ? '1' : '0');
  }

  private setHeaders(headers: HttpHeaders): HttpHeaders {
    return headers
      .set('kros-handle-status', this.currentCriticalOptions?.status
        ? this.currentCriticalOptions.status
        : []
      )
      .set('kros-no-connection-id', this.noConnectionId ? '1' : '0');
  }
}
