import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { ProjectsDispatchersService, ProjectsSelectorsService } from '../../../store/projects';

@Component({
  selector: 'app-project-members-panel-container',
  templateUrl: 'project-members-panel-container.component.html',
  styleUrls: ['project-members-panel-container.component.scss']
})
export class ProjectMembersPanelContainerComponent extends UnsubscribeComponent implements OnInit {
  isInProjectMembers = true;

  @Output() projectMembersSelected = new EventEmitter<boolean>();

  constructor(
    private projectsDispatchersService: ProjectsDispatchersService,
    private projectsSelectorsService: ProjectsSelectorsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.projectsSelectorsService.isProjectMembersTabSelected$.subscribe(value => this.isInProjectMembers = value);
  }

  onMembersClick(): void {
    this.onProjectMembersSelected(true);
  }

  onOwnerClick(): void {
    this.onProjectMembersSelected(false);
  }

  private onProjectMembersSelected(value: boolean): void {
    this.projectsDispatchersService.setIsProjectMembersTabSelected(value);
    this.projectMembersSelected.emit(value);
  }
}
