import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AfterViewInit, Component, inject, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { fromDeviceDetector } from '@kros-sk/device-detector';
import { KrosModalRef } from '@kros-sk/components';

import { getStringValueFromControl, isOwnersEmail } from '../../../core';
import { getUtcDate } from '../../../tools';
import { Project, ProjectDetail } from '../../../models';
import { RequiredIgnoreWhiteSpacesValidator } from '../../../document-edit';
import { TranslateService } from '../../../translate';
import { urlAllowedCharacters } from '../../../constants/validator-constants';

@Component({ template: '' })
export abstract class ProjectDialogComponent implements OnInit, AfterViewInit {

  private formBuilder = inject(UntypedFormBuilder);
  private store = inject(Store);
  protected translateService = inject(TranslateService);
  protected modalRef = inject(KrosModalRef);

  changesMade = false;
  data: any;
  editForm: UntypedFormGroup;
  isMobile$ = this.store.select(fromDeviceDetector.selectIsMobile);
  project: ProjectDetail;

  get name(): AbstractControl {
    return this.editForm.controls['name'];
  }

  get apiCode(): AbstractControl {
    return this.editForm.controls['apiCode'];
  }

  get isFormDirty(): boolean {
    return this.editForm.dirty;
  }

  ngOnInit(): void {
    this.initializeForm();
    this.fillFormByProject(this.data.project);
  }

  ngAfterViewInit(): void {
    this.onInitializeForm();
  }

  private initializeForm(): void {
    this.editForm = this.formBuilder.group({
      name: ['', [RequiredIgnoreWhiteSpacesValidator, Validators.maxLength(150)]],
      place: ['', Validators.maxLength(50)],
      customer: ['', Validators.maxLength(100)],
      customerName: ['', Validators.maxLength(100)],
      customerId: ['', Validators.maxLength(20)],
      customerVatId: ['', Validators.maxLength(20)],
      contractorName: ['', Validators.maxLength(100)],
      contractorId: ['', Validators.maxLength(20)],
      contractorVatId: ['', Validators.maxLength(20)],
      authorName: ['', Validators.maxLength(100)],
      authorId: ['', Validators.maxLength(20)],
      authorVatId: ['', Validators.maxLength(20)],
      designerName: ['', Validators.maxLength(100)],
      designerId: ['', Validators.maxLength(20)],
      designerVatId: ['', Validators.maxLength(20)],
      constructionManager: ['', Validators.maxLength(100)],
      owner: [{ value: '', disabled: true }, Validators.maxLength(100)],
      apiCode: ['', Validators.pattern(urlAllowedCharacters)],
      dateStarted: [],
      dateEnded: [],
      contractNumber: ['', Validators.maxLength(100)],
      contractDate: []
    });
  }

  protected onInitializeForm(): void { }

  onSubmit(): void { }

  onCancel(): void {
    this.modalRef.cancel();
  }

  private fillFormByProject(project: Project): void {
    if (project) {
      this.editForm.patchValue({
        name: project.name,
        owner: isOwnersEmail(project.owner) ? this.translateService.translate('PROJECTS.DETAIL.INFO.ME') : project.owner
      });
    }
  }

  protected copyProjectFromFormControl(): void {
    this.project.name = getStringValueFromControl(this.name);
    this.project.place = getStringValueFromControl(this.editForm.controls.place);
    this.project.customerName = getStringValueFromControl(this.editForm.controls.customerName);
    this.project.customerId = getStringValueFromControl(this.editForm.controls.customerId);
    this.project.customerVatId = getStringValueFromControl(this.editForm.controls.customerVatId);
    this.project.authorName = getStringValueFromControl(this.editForm.controls.authorName);
    this.project.authorId = getStringValueFromControl(this.editForm.controls.authorId);
    this.project.authorVatId = getStringValueFromControl(this.editForm.controls.authorVatId);
    this.project.contractorName = getStringValueFromControl(this.editForm.controls.contractorName);
    this.project.contractorId = getStringValueFromControl(this.editForm.controls.contractorId);
    this.project.contractorVatId = getStringValueFromControl(this.editForm.controls.contractorVatId);
    this.project.designerName = getStringValueFromControl(this.editForm.controls.designerName);
    this.project.designerId = getStringValueFromControl(this.editForm.controls.designerId);
    this.project.designerVatId = getStringValueFromControl(this.editForm.controls.designerVatId);
    this.project.constructionManager = getStringValueFromControl(this.editForm.controls.constructionManager);
    this.project.apiCode = getStringValueFromControl(this.editForm.controls.apiCode);
    this.project.dateStarted = this.editForm.controls.dateStarted.value
      ? getUtcDate(this.editForm.controls.dateStarted.value)
      : null;
    this.project.dateEnded = this.editForm.controls.dateEnded.value
      ? getUtcDate(this.editForm.controls.dateEnded.value)
      : null;
    this.project.contractDate = this.editForm.controls.contractDate.value
      ? getUtcDate(this.editForm.controls.contractDate.value)
      : null;
    this.project.contractNumber = getStringValueFromControl(this.editForm.controls.contractNumber);
  }
}
