import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { combineLatest, filter, map, Observable, pairwise, startWith, tap } from 'rxjs';

import { AuthSelectorsService } from '@kros-sk/auth';
import { emptyOpenApiSubscriptionApiModel, OpenApiSubscriptionApiModel } from '@kros-sk/models';

import { BuildingProgressBasePathSegment } from '../../../model';
import { getSettingIdFromRoute, getSettingsNavigateCommands, isOnCreateRouteInSettings, SaveButtonStateService } from '../../../services';
import { OpenApiService } from '../../open-api.service';

interface ViewModel {
  subscriptions: OpenApiSubscriptionApiModel[],
  inProgress: boolean,
  routedId: string | 'create' | null
}

@Component({
  selector: 'ssw-open-api-settings',
  templateUrl: './open-api-settings.component.html',
  styleUrls: ['./open-api-settings.component.scss']
})
export class OpenApiSettingsComponent implements OnInit {

  vm$: Observable<ViewModel>;

  get emptySubscription(): OpenApiSubscriptionApiModel {
    return emptyOpenApiSubscriptionApiModel();
  }

  constructor(
    protected authSelectorsService: AuthSelectorsService,
    private openApiService: OpenApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private saveButtonState: SaveButtonStateService

  ) { }

  ngOnInit(): void {
    this.vm$ = this.viewModel;
  }

  trackBy = (index: number, subscription: OpenApiSubscriptionApiModel): string => {
    return subscription.clientId;
  };

  setCreateMode(): void {
    const urlTree = this.router.parseUrl(this.router.url);

    if (isOnCreateRouteInSettings(urlTree)) {
      const dashBoardCommands = getSettingsNavigateCommands(urlTree, '0', BuildingProgressBasePathSegment);
      this.router.navigate(dashBoardCommands, { relativeTo: this.activatedRoute }).then(res => {
        if (res) {
          setTimeout(() => {
            this.router.navigate(['create'], { relativeTo: this.activatedRoute });
          });
        }
      });
    } else {
      const commands = getSettingsNavigateCommands(urlTree, 'create', BuildingProgressBasePathSegment);
      this.router.navigate(commands, { relativeTo: this.activatedRoute });
    }
  }

  private get viewModel(): Observable<ViewModel> {
    return combineLatest([
      this.openApiService.subscriptions$.pipe(startWith([])),
      this.openApiService.loadInProgress$,
      this.router.events.pipe(
        startWith(new NavigationEnd(0, this.router.url, this.router.url), new NavigationEnd(1, this.router.url, this.router.url)),
        filter(event => event instanceof NavigationEnd),
        pairwise(),
        tap(([prev, curr]: [NavigationEnd, NavigationEnd]) => {
          const prevId = getSettingIdFromRoute(this.router.parseUrl(prev.urlAfterRedirects), BuildingProgressBasePathSegment);
          const currId = getSettingIdFromRoute(this.router.parseUrl(curr.urlAfterRedirects), BuildingProgressBasePathSegment);
          if (!(prevId === 'create' && currId)) {
            this.saveButtonState.setIsEdited(false);
          }
        }),
        map(() => getSettingIdFromRoute(this.router.parseUrl(this.router.url), BuildingProgressBasePathSegment)?.toString())
      )
    ]).pipe(
      map(([subscriptions, inProgress, routedId]) => {
        return {
          subscriptions,
          inProgress,
          routedId
        };
      })
    );
  }
}
