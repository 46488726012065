import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';

import { Observable, withLatestFrom } from 'rxjs';
import { SubSink } from 'subsink';

import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { KrosEswSignalRService } from '@kros-sk/auth';

@Directive({
  selector: '[krosFormGuardSaver]',
  exportAs: 'krosFormGuardSaver'
})
export class FormGuardSaverDirective implements OnDestroy {

  @Input() krosFormGuardSaver: Observable<any>;

  private subs = new SubSink();

  constructor(
    private elRef: ElementRef,
    private signalRService: KrosEswSignalRService,
    private appInsights: AppInsightsBaseService
  ) {

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get trigger$(): Observable<any> {
    return this.krosFormGuardSaver;
  }

  @HostListener('click')
  click(): void {
    if (!this.elRef || this.elRef.nativeElement.hasAttribute('disabled')) {
      return;
    }

    this.subs.sink = this.krosFormGuardSaver
      .pipe(withLatestFrom(this.signalRService.isSignalRUnavailable))
      .subscribe(([_ ,isSignalRUnavailable]) => {
        if (isSignalRUnavailable) {
          this.appInsights.trackEvent('SignalRForcedAppReload');
          // timeout is here because of draft saving, we have to wait for route update, so we refresh to correct id
          setTimeout(() => location.reload(), 300);
        }
      });
  }
}
