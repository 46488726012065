import { EnvironmentProviders, makeEnvironmentProviders, ModuleWithProviders, NgModule, Type } from '@angular/core';

import { EffectsModule, provideEffects } from '@ngrx/effects';
import { provideState, StoreModule } from '@ngrx/store';

import { FEATURE_FLAGS_SERVICE, IFeatureFlagsService } from '../types/feature-flags.types';
import { FeatureFlagsEffects } from './feature-flags.effects';
import { featureFlagsStoreFeature } from './feature-flags.state';
import { HasFeatureFlagFacade } from '../../features/has-feature-flag.facade';

/**
 * This function is used to provide feature flags service.
 * It creates an array of providers for the feature flags service, state and effects.
 *
 * @param {Type<IFeatureFlagsService>} featureFlagsServiceType - The type of the feature flags service to be provided.
 * @returns {EnvironmentProviders} - An array of providers for the feature flags service, state and effects.
 */
export function provideFeatureFlags(featureFlagsServiceType: Type<IFeatureFlagsService>): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideState(featureFlagsStoreFeature),
    provideEffects(FeatureFlagsEffects),
    {
      provide: FEATURE_FLAGS_SERVICE,
      useClass: featureFlagsServiceType
    },
    HasFeatureFlagFacade
  ]);
}

/**
 * This class provides compatibility with module-based applications.
 * In standalone use, `provideFeatureFlags` should be used instead.
 */
@NgModule({
  imports: [
    StoreModule.forFeature(featureFlagsStoreFeature),
    EffectsModule.forFeature(FeatureFlagsEffects)
  ]
})
export class KrosFeatureFlagsProviderModule {
  static forRoot(
    featureFlagsServiceType: Type<IFeatureFlagsService>
  ): ModuleWithProviders<KrosFeatureFlagsProviderModule> {
    return {
      ngModule: KrosFeatureFlagsProviderModule,
      providers: [
        {
          provide: FEATURE_FLAGS_SERVICE,
          useClass: featureFlagsServiceType
        },
        HasFeatureFlagFacade
      ]
    };
  }
}
