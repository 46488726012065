import { Component, OnInit } from '@angular/core';
import { formatPercent } from '@angular/common';

import { FileSizeService, TranslateService } from '@kros-sk/ssw-shared-legacy';
import { UnsubscribeComponent } from '@kros-sk/ssw-cdk';

import { environment } from '../../../../environments/environment';
import { UserDataStatisticsService } from '../../../core/services/user-data-statistics/user-data-statistics.service';

@Component({
  selector: 'app-user-data-statistics',
  templateUrl: './user-data-statistics.component.html',
  styleUrls: ['./user-data-statistics.component.scss']
})
export class UserDataStatisticsComponent extends UnsubscribeComponent implements OnInit {
  private dataConsumed = 0;
  private dataLimit = 1024 * 1024 * 1024;

  constructor(
    private fileSizeService: FileSizeService,
    private translateService: TranslateService,
    private userDataStatisticsService: UserDataStatisticsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.userDataStatisticsService.dataUsage$
      .subscribe(dataConsumed => this.dataConsumed = dataConsumed);

    this.subs.sink = this.userDataStatisticsService.getUserDataStatistics().subscribe(
      statistics => {
        this.dataConsumed = statistics.dataConsumed;
        this.dataLimit = statistics.dataLimit;
      }
    );
  }

  showDataStats(): string {
    return this.translateService.translate('PROJECTS.ZOZNAM.STORAGE_USED') + ' ' +
      this.getDataConsumed() + ' ' +
      this.translateService.translate('COMMON.OF') + ' ' +
      this.getDataLimit() +
      ' (' + this.calculateConsumptionPercentage() + ')';
  }

  getProgressBarValue(): number {
    return this.getDataRatio() * 100;
  }

  isReachingLimit(): boolean {
    const limitWarning = 0.9;
    return this.getDataRatio() >= limitWarning;
  }

  private calculateConsumptionPercentage(): string {
    return formatPercent(this.getDataRatio(), environment.location, '1.0-1');
  }

  private getDataRatio(): number {
    return this.dataConsumed / this.dataLimit;
  }

  private getDataConsumed(): string {
    return this.fileSizeService.transform(this.dataConsumed);
  }

  private getDataLimit(): string {
    return this.fileSizeService.transform(this.dataLimit);
  }
}
