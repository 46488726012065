import { inject, Injectable } from '@angular/core';

import { map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { areEmailsEqual, UserIds, UserService } from '@kros-sk/ssw-cdk';
import { AppInsightsBaseService } from '@kros-sk/core/application-insights';
import { PermissionType } from '@kros-sk/ssw-shared/permission';

import { AnalyticsService } from '../../analytics';
import { ItemSharing } from '../../models';
import { ItemSharingActionViewModel } from '../models/item-sharing-action-view-model';
import { ItemSharingEdit } from '../models/item-sharing-edit.model';
import { ItemSharingMultiple } from '../models/item-sharing-multiple.model';
import { ItemSharingMultipleActionViewModel } from '../models/item-sharing-multiple-action-view-model';
import { ItemSharingMultipleViewModel } from '../models/item-sharing-multiple-view-model';
import { ItemSharingViewModel } from '../models/item-sharing-view-model';
import { PermissionChangedDto } from '../models/permission-changed-dto.model';
import { ProjectSharingEdit } from '../models/project-sharing-edit.model';
import { SharingActionType } from '../models/sharing-action-type.enum';
import { UserSharingHelpersService } from './user-sharing-helpers.service';

@Injectable()
export class UserSharingService {

  private sharingHelperService = inject(UserSharingHelpersService);
  private analyticsService = inject(AnalyticsService);
  private appInsightsService = inject(AppInsightsBaseService);
  private userService = inject(UserService);

  editUser(
    editItems: ItemSharingEdit[],
    permissionDto: PermissionChangedDto,
    sharingAction: SharingActionType
  ): boolean {
    let ret = false;

    switch (sharingAction) {
      case SharingActionType.Edit:
        ret = this.addEditAction(editItems, permissionDto);
        break;
      case SharingActionType.Delete:
        this.addDeleteAction(editItems, permissionDto);
        ret = true;
        break;
    }

    if (!editItems.find(y => areEmailsEqual(y.permissionChange.email, permissionDto.email))) {
      this.sharingHelperService.resetState(permissionDto.email);
    }

    return ret;
  }

  getUserIdsToShare(users: UserIds[], emails: string[]): Observable<UserIds[]> {
    let userIds = users;
    return this.userService.getUserIds(emails).pipe(
      switchMap((resp: UserIds[]) => {
        const unregisteredUsers = this.getUnregisteredUsers(emails, resp);
        userIds = userIds.concat(this.getCreatedUsers(users, resp));

        if (unregisteredUsers.length > 0) {
          return this.userService.unregisteredUsersCreate(unregisteredUsers);
        } else {
          return of(false);
        }
      }),
      map(resp => {
        if (resp && Object.keys(resp).length > 0) {
          userIds = userIds.concat(this.getUnregisteredUserIds(resp));
        }
        return userIds;
      })
    );
  }

  private getUnregisteredUserIds(users: { [key: string]: number }): UserIds[] {
    const unregisteredUsers: UserIds[] = [];
    Object.entries(users).forEach(([key, value]) =>
      unregisteredUsers.push({ id: +value, email: key, identityId: undefined, externalId: undefined, isRegisteredUser: false }));
    return unregisteredUsers;
  }

  private getCreatedUsers(users: UserIds[], usersToBeCreated: UserIds[]): UserIds[] {
    const createdUsers: UserIds[] = [];
    usersToBeCreated.forEach(user => {
      user.isRegisteredUser = !!user.identityId;
      if (createdUsers.findIndex(added => areEmailsEqual(added.email, user.email)) === -1 &&
        users.findIndex(added => areEmailsEqual(added.email, user.email)) === -1) {
        createdUsers.push(user);
      }
    });
    return createdUsers;
  }

  private getUnregisteredUsers(emails: string[], resp: UserIds[]): { email: string }[] {
    const notCreated: { email: string }[] = [];
    emails.forEach(email => {
      if (resp.findIndex(user => areEmailsEqual(user.email, email)) === -1) {
        notCreated.push({ email });
      }
    });
    return notCreated;
  }

  private addDeleteAction(editItems: ItemSharingEdit[], permissionDto: PermissionChangedDto): void {
    const deleteItem: ProjectSharingEdit = {
      permissionChange: permissionDto,
      sharingAction: SharingActionType.Delete
    };

    editItems.push(deleteItem);
  }

  private addEditAction(editItems: ItemSharingEdit[], permissionDto: PermissionChangedDto): boolean {
    let changesMade: boolean;
    const item = editItems.find(x => (x.permissionChange.applicationType === permissionDto.applicationType)
      && areEmailsEqual(x.permissionChange.email, permissionDto.email)
      && (x.sharingAction === SharingActionType.Edit));

    if (item && this.areOldPermissionsAsNew(item.permissionChange, permissionDto)) {
      this.removeReverseEditItems(editItems, item);
      changesMade = editItems.length > 0;
    } else {
      const editItem: ProjectSharingEdit = { permissionChange: permissionDto, sharingAction: SharingActionType.Edit };
      if (item) {
        editItem.permissionChange.oldPermission = item.permissionChange.oldPermission;
        editItem.permissionChange.oldAdditionalPermission = item.permissionChange.oldAdditionalPermission;
      }
      changesMade = true;
      editItems.push(editItem);
    }

    return changesMade;
  }

  private areOldPermissionsAsNew(oldChange: PermissionChangedDto, newChange: PermissionChangedDto): boolean {
    return oldChange.oldPermission === newChange.newPermission &&
      oldChange.oldAdditionalPermission === newChange.newAdditionalPermission;
  }

  private removeReverseEditItems(editItems: ItemSharingEdit[], sharingItem: ItemSharingEdit): void {
    const rightArrayPart = editItems.splice(editItems.indexOf(sharingItem), editItems.length + 1);
    rightArrayPart.forEach(ele => {
      if (!(this.isAplicationTypeUserMatch(ele, sharingItem))) {
        editItems.push(ele);
      }
    });
  }

  filterChanges(editedItems: ItemSharingEdit[]): ItemSharingEdit[] {
    let filterCollection: ItemSharingEdit[] = [];

    editedItems.forEach(editModel => {
      filterCollection = filterCollection
        .filter(model => !(this.isAplicationTypeUserMatch(model, editModel)));
      filterCollection.push(editModel);
    });

    return filterCollection;
  }

  private isAplicationTypeUserMatch(first: ItemSharingEdit, second: ItemSharingEdit): boolean {
    return (areEmailsEqual(first.permissionChange.email, second.permissionChange.email) &&
      first.permissionChange.applicationType === second.permissionChange.applicationType);
  }

  deleteLocalShareItems(items: ItemSharing[], editItems: ItemSharingEdit[]): void {
    editItems.forEach(element => {
      if (element.sharingAction === SharingActionType.Delete) {
        items.forEach(item => {
          if (areEmailsEqual(item.email, element.permissionChange.email)) {
            items.splice(items.indexOf(item), 1);
          }
        });
      }
    });
  }

  undoChanges(items: ItemSharing[], editItems: ItemSharingEdit[]): void {
    editItems = editItems.reverse();
    editItems.forEach(edit => {
      switch (edit.sharingAction) {
        case SharingActionType.Edit:
          const share = items.find(model => areEmailsEqual(model.email, edit.permissionChange.email));
          share.permissionType = edit.permissionChange.oldPermission;
          share.additionalPermissionType = edit.permissionChange.oldAdditionalPermission;
          break;
      }
    });
  }

  undoMultipleChanges(items: ItemSharingMultiple[], editItems: ItemSharingEdit[]): void {
    editItems = editItems.reverse();
    editItems.forEach(edit => {
      switch (edit.sharingAction) {
        case SharingActionType.Edit:
          const share = items.find(model => areEmailsEqual(model.email, edit.permissionChange.email));
          const permission = share.permissions.find(p => p.applicationType === edit.permissionChange.applicationType);
          permission.permissionType = edit.permissionChange.oldPermission;
          permission.additionalPermissionType = edit.permissionChange.oldAdditionalPermission;
          break;
      }
    });
  }

  createUserEditViewModels(editedItems: ItemSharingEdit[]): ItemSharingActionViewModel[] {
    const sharingActionViewModels: ItemSharingActionViewModel[] = [];
    editedItems.forEach(element => {
      const sharingViewModel: ItemSharingActionViewModel = {
        email: element.permissionChange.email,
        permissionType: element.permissionChange.newPermission,
        additionalPermissionType: element.permissionChange.newAdditionalPermission,
        actionType: element.sharingAction,
        isRegisteredUser: element.permissionChange.isRegisteredUser,
        userMessage: undefined
      };

      sharingActionViewModels.push(sharingViewModel);
    });

    return sharingActionViewModels;
  }

  createUserMultipleEditViewModels(editedItems: ItemSharingEdit[]): ItemSharingMultipleActionViewModel[] {
    const sharingActionViewModels: ItemSharingMultipleActionViewModel[] = [];

    editedItems.forEach(element => {
      const sharingViewModel: ItemSharingMultipleActionViewModel = {
        email: element.permissionChange.email,
        applicationType: element.permissionChange.applicationType,
        permissionType: element.permissionChange.newPermission,
        additionalPermissionType: element.permissionChange.newAdditionalPermission,
        actionType: element.permissionChange.newPermission === PermissionType.NoAccess ? SharingActionType.Delete :
          element.sharingAction,
        sendNotification: element.sharingAction === SharingActionType.Delete
          ? false
          : element.sharingAction === SharingActionType.Add || element.permissionChange.oldPermission === PermissionType.NoAccess,
        userMessage: undefined
      };

      sharingActionViewModels.push(sharingViewModel);
    });

    return sharingActionViewModels;
  }

  createUserMultipleAddViewModels(sharings: ItemSharingMultiple[]): ItemSharingMultipleActionViewModel[] {
    const sharingActionViewModels: ItemSharingMultipleActionViewModel[] = [];

    sharings.forEach(newShare => {
      newShare.permissions.forEach(newPermission => {
        const sharingViewModel: ItemSharingMultipleActionViewModel = {
          email: newShare.email,
          applicationType: newPermission.applicationType,
          permissionType: newPermission.permissionType,
          additionalPermissionType: newPermission.additionalPermissionType,
          actionType: SharingActionType.Add,
          userMessage: newShare.userMessage,
          sendNotification: true
        };
        if (sharingViewModel.permissionType !== PermissionType.NoAccess) {
          sharingActionViewModels.push(sharingViewModel);
        }
      });
    });

    return sharingActionViewModels;
  }

  createUserAddViewModels(sharings: ItemSharing[], items: ItemSharing[]): ItemSharingActionViewModel[] {
    const sharingActionViewModels: ItemSharingActionViewModel[] = [];

    sharings.forEach(newShare => {
      const currentShare = items.find(shared => areEmailsEqual(shared.email, newShare.email));
      if (!this.canExcludeSharing(currentShare, newShare)) {
        const sharingViewModel: ItemSharingActionViewModel = {
          email: newShare.email,
          permissionType: newShare.permissionType,
          additionalPermissionType: newShare.additionalPermissionType,
          actionType: SharingActionType.Add,
          isRegisteredUser: newShare.isRegisteredUser,
          userMessage: newShare.userMessage
        };
        sharingActionViewModels.push(sharingViewModel);
      }
    });

    return sharingActionViewModels;
  }

  private canExcludeSharing(item: ItemSharing, element: ItemSharing): boolean {
    return item && (item.permissionType === element.permissionType || item.permissionType === PermissionType.Owner);
  }

  sendAnalyticsNotification(model: ItemSharingViewModel, projectSharings: ItemSharing[]): void {
    if (model.sharingActions.some(e => e.actionType === SharingActionType.Edit)) {
      this.sendAnalyticsNotificationPermissionChange();
    }

    if (model.sharingActions.some(e => e.actionType === SharingActionType.Add || e.actionType === SharingActionType.Delete)) {
      const estimatedSharingCount = projectSharings.length
        + (model.sharingActions.filter(e => e.actionType === SharingActionType.Add).length
          - model.sharingActions.filter(e => e.actionType === SharingActionType.Delete).length);

      this.appInsightsService.trackEvent('PROJ-share-project', { count: estimatedSharingCount.toString() });
      this.analyticsService.raiseEvent('projekt', 'zdielanie-projektu', estimatedSharingCount.toString());
    }
  }

  sendAnalyticsNotificationPermissionChange(): void {
    this.appInsightsService.trackEvent('PROJ-share-changed-permission');
    this.analyticsService.raiseEvent('projekt', 'zdielanie-zmena-prav');
  }

  sendAnalyticsMultipleNotification(model: ItemSharingMultipleViewModel, projectSharings: ItemSharingMultiple[]): void {
    if (model.permissionActions.some(e => e.actionType === SharingActionType.Edit)) {
      this.appInsightsService.trackEvent('PROJ-share-changed-permission');
      this.analyticsService.raiseEvent('projekt', 'zdielanie-zmena-prav');
    }

    if (model.permissionActions.some(e => e.actionType === SharingActionType.Add || e.actionType === SharingActionType.Delete)) {
      const estimatedSharingCount = projectSharings.length
        + (model.permissionActions.filter(e => e.actionType === SharingActionType.Add).length
          - model.permissionActions.filter(e => e.actionType === SharingActionType.Delete).length);

      this.appInsightsService.trackEvent('PROJ-share-project', { count: estimatedSharingCount.toString() });
      this.analyticsService.raiseEvent('projekt', 'zdielanie-projektu', estimatedSharingCount.toString());
    }
  }
}
