export const emptyKey: any = '-1';
export const codeFieldName = 'code';
export const itemTypeFieldName = 'itemType';

export const requiredFields: Record<string, Record<string, boolean>> = {
  K: { code: true, description: true, measureUnit: true },
  M: { code: true, description: true, measureUnit: true },
  D: { code: true, description: true },
  O: { code: true, description: true },
  ZL: { code: true, description: true }
};

export const isDataFieldRequired = (dataField: string, itemType: string): boolean => {
  return !!requiredFields[itemType]?.[dataField];
};
