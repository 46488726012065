import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { AmountCalculationType } from '@kros-sk/ssw-shared/data-access/models/amount-calculation';
import { Boq, BoqItem, BoqQuantity, BudgetItem, ChangedBudgetItems, RecalculatedBoqs } from '@kros-sk/ssw-shared-legacy';
import { BoqResponse } from '@kros-sk/ssw-shared/data-access/models/boq';

import { budgetSharedKey } from './budget-shared-actions';

export const boqSharedActions = createActionGroup({
  source: budgetSharedKey,
  events: {
    'Set boq start': props<{
      selectedBudgetItem: BudgetItem,
      boq: Boq,
      boqItems: BoqItem[],
      unitAmount?: number,
      canSetBoqCalculationType?: boolean,
      isUndoRedoOperation?: boolean,
      isUndo?: boolean,
      canRetry?: boolean,
      previousResponse?: BoqResponse
    }>(),
    'Set boq success': props<{
      changedBudgetItems: ChangedBudgetItems,
      occurrenceItemId: number,
      selectedBudgetItem: BudgetItem,
      boqQuantities?: BoqQuantity[],
      boqItems: BoqItem[],
      canSetBoqCalculationType: boolean,
      oldBoq: Boq,
      oldValues: BoqItem[],
      isUndoRedoOperation: boolean,
      isUndo: boolean
    }>(),
    'Set boq error': props<{ error: any, retryPayload?: any, response?: BoqResponse }>(),

    'Set amount calculation type start': props<{
      occurrenceItemId: number,
      amountCalculationType: AmountCalculationType,
      oldAmount?: number,
      oldAmountCalculationType?: AmountCalculationType,
      isUndoRedoOperation?: boolean,
      isUndo?: boolean
    }>(),
    'Set amount calculation type success': props<{
      changedBudgetItems: ChangedBudgetItems,
      occurrenceItemId: number,
      newAmountCalculationType: AmountCalculationType,
      oldAmountCalculationType: AmountCalculationType,
      oldAmount: number,
      isUndoRedoOperation: boolean,
      isUndo: boolean
    }>(),
    'Set amount calculation type error': props<{ error: any }>(),

    'Set quantity manager boq': props<{
      boqId: number,
      boqItems: BoqItem[],
      changedBudgetItems: ChangedBudgetItems
    }>(),

    'Recalculate boqs start': emptyProps(),
    'Recalculate boqs success': props<RecalculatedBoqs>(),
    'Recalculate boqs error': props<{ error?: any }>(),
  }
});
