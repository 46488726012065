import { Component, inject } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { KrosModalRef, KrosModalService } from '@kros-sk/components';

import { getSortedTags, getTagName, updateTagInTagsList } from '../tag-utils';
import { Tag } from '../tag.model';
import { TagCreateDialogComponent } from '../tag-create-dialog/tag-create-dialog.component';
import { TagEditDialogComponent } from '../tag-edit-dialog/tag-edit-dialog.component';
import { TagType } from '../tags.enum';
import { TranslateService } from '../../translate';

@Component({
  selector: 'kros-tag-settings-dialog',
  templateUrl: './tag-settings-dialog.component.html',
  styleUrls: ['./tag-settings-dialog.component.scss']
})
export class TagSettingsDialogComponent {
  private readonly translate = inject(TranslateService);

  tags: Tag[];
  tagEdited: Subject<Tag>;
  tagCreated: Subject<{ tag: Tag, setTagToProject: boolean }>;
  tagsListUpdated: Observable<Tag[]>;

  constructor(
    private modalRef: KrosModalRef,
    private krosModalService: KrosModalService
  ) {
    this.tags = this.modalRef.data.tags.filter(t => t.type === TagType.User);
    this.tagCreated = this.modalRef.data.tagCreatedSubj;
    this.tagEdited = this.modalRef.data.tagEditedSubj;
    this.tagsListUpdated = this.modalRef.data.tagsListUpdatedObs;

    this.tagsListUpdated.subscribe(tags => this.tags = tags.filter(t => t.type === TagType.User));
  }

  onCreateNewTagClick(): void {
    const modalRef = this.krosModalService.openCentered(
      TagCreateDialogComponent,
      {
        alreadyExistingTags: this.tags,
      },
      {
        addModalToBrowsersHistory: false
      }
    );

    modalRef.afterClosed$.subscribe(result => {
      if (result.type === 'submit') {
        this.tagCreated.next({ tag: result.data.tag, setTagToProject: false });
      }
    });
  }

  onTagEditClick(tag: Tag): void {
    const modalRef = this.krosModalService.openCentered(
      TagEditDialogComponent,
      {
        alreadyExistingTags: this.tags,
        tag
      },
      {
        addModalToBrowsersHistory: false
      }
    );

    modalRef.afterClosed$.subscribe(result => {
      if (result.type === 'submit') {
        this.editTagList(result.data.tag);
        this.tagEdited.next(result.data.tag);
      }
    });
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  private editTagList(tag: Tag): void {
    this.tags = getSortedTags(updateTagInTagsList(this.tags, tag));
  }

  getTagName(key: string): string {
    return getTagName(key, this.translate);
  }
}
