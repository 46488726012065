import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { APP_CONFIG } from '@kros-sk/app-config';

import { UserService } from './user.service';

@Injectable()
export class UISettingsService {

  private http: HttpClient = inject(HttpClient);
  private appConfig = inject(APP_CONFIG);
  private userService: UserService = inject(UserService);
  private readonly api = `${this.appConfig.gatewayApiUrl}/api/userService/`;
  private readonly uiSettingsEndpoint = '/ui-settings/';

  getUiSettings(settingsName: string, userId?: number): Observable<any> {
    return this.http.get<any>(`${this.api}${userId ?? this.userService.userId}${this.uiSettingsEndpoint}${settingsName}`);
  }

  saveUiSettings(settingsName: string, value: any): Observable<any> {
    return this.http.put(`${this.api}${this.userService.userId}${this.uiSettingsEndpoint}${settingsName}`, value);
  }
}
