import { ErrorHandler, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { GlobalErrorHandlingInterceptor } from './global-error-handling.interceptor';

const interceptors = [
  {
    provide: ErrorHandler,
    useClass: GlobalErrorHandlingInterceptor,
  }
];

@NgModule()
export class InterceptorsModule {
  constructor(@Optional() @SkipSelf() parentModule: InterceptorsModule) {
    if (parentModule) {
      throw new Error('InterceptorsModule has already been loaded. Import Core modules in the AppModule only.');
    }
  }
  public static forRoot(): ModuleWithProviders<InterceptorsModule> {
    return {
      ngModule: InterceptorsModule,
      providers: interceptors
    };
  }
}
