import { ExportFileType, ExportGeneralSettingsModel } from '@kros-sk/ssw-shared-legacy';

export class BuildingProgressTableExportModel {
  projectId: number;
  isSingleSheetExport: boolean;
  areColorCodesIncluded: boolean;
  itemIds: number[];
  periodIds: number[];
  subcontractorId?: number;
}

export class BuildingProgressInvoiceDetailExportModel {
  projectId: number;
  periodIds: number[];
  buildingObjectIds: number[];
  coverSheetExportType: CoverSheetExportTypeEnum;
  isIncludedConfirmationProtocol: boolean;
  isCompletedWorksObjects: boolean;
  isIncludedBuildingObjectsSummary: boolean;
  isIncludedSectionsSummary: boolean;
  isIncludedCompletedWorks: boolean;
  exportDate: string;
  completedWorksSettings: CompletedWorksSettingsModel;
  summarySettings: SummarySettingsModel;
  subcontractorId?: number;
  exportFileType?: ExportFileType;
  invoiceNumber?: string;
  generalSettings: ExportGeneralSettingsModel;
}

export class CompletedWorksSettingsModel {
  completedWorksExportType: CompletedWorksExportTypeEnum;
  areZeroItemsIncluded: boolean;
  isBoqIncluded: boolean;
  areDetailsIncluded: boolean;
  areNotesIncluded: boolean;
  areColorCodesIncluded: boolean;
  displayAllDecimalPlaces: boolean;
  isCompletedAmountIncluded: boolean;
  isCompletedPercentageIncluded: boolean;
  isCompletedTotalPriceIncluded: boolean;
  isTotalPriceIncluded: boolean;
  isPeriodPercentageIncluded: boolean;
  isPeriodWeightIncluded: boolean;
  isPeriodRubbleIncluded: boolean;
  isPartlyCompletedPercentageIncluded: boolean;
  isPartlyCompletedAmountIncluded: boolean;
  isPartlyCompletedTotalPriceIncluded: boolean;
  isRestPercentageIncluded: boolean;
  isRestAmountIncluded: boolean;
  isRestTotalPriceIncluded: boolean;
  isAdditionalPercentageIncluded: boolean;
  isAdditionalAmountIncluded: boolean;
  isAdditionalTotalPriceIncluded: boolean;
  isNotCompletedPercentageIncluded: boolean;
  isNotCompletedAmountIncluded: boolean;
  isNotCompletedTotalPriceIncluded: boolean;
  isContractorIncluded?: boolean;
}

export class SummarySettingsModel {
  isIncludedSectionsSummary: boolean;
  isPeriodPercentageIncluded: boolean;
  isPartlyCompletedPercentageIncluded: boolean;
  isPartlyCompletedTotalPriceIncluded: boolean;
  isCompletedPercentageIncluded: boolean;
  isCompletedTotalPriceIncluded: boolean;
  isRestPercentageIncluded: boolean;
  isRestTotalPriceIncluded: boolean;
  isAdditionalPercentageIncluded: boolean;
  isAdditionalTotalPriceIncluded: boolean;
  isNotCompletedPercentageIncluded: boolean;
  isNotCompletedTotalPriceIncluded: boolean;
  isContractorIncluded?: boolean;
}

export class BuildingProgressAmountsUniXmlExportModel {
  projectId: number;
  periodIds: number[];
  buildingObjectIds: number[];
  subcontractorId?: number;
}

export class BuildingProgressConfirmationProtocolExportModel {
  projectId: number;
  periodId: number;
  buildingObjectIds: number[];
}

export class BuildingProgressItemDrawSheetModel {
  projectId: number;
  periodIds: number[];
  contractorName: string;
  supervisorName: string;
  areDetailsIncluded: boolean;
  exportDate: string;
  itemIds: number[];
}

export class BuildingProgressRemainingBudgetModel {
  projectId: number;
  exportZeroRemainingBudgetItems: boolean;
  isSingleSheetExport: boolean;
  subcontractorId?: number;
}

export enum CoverSheetExportTypeEnum {
  None = 0,
  Summary = 1,
  SummaryAndBuildingObjects = 2,
}

export enum CompletedWorksExportTypeEnum {
  None = 0,
  Summary = 1,
  BuildingObjects = 2,
}
