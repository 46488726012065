import { Component } from '@angular/core';

import { KrosModalRef } from '@kros-sk/components';

import { BudgetApprovalModel } from '../../models';

@Component({
  selector: 'app-budget-approval-range-modal',
  templateUrl: './budget-approval-range-modal.component.html',
  styleUrls: ['./budget-approval-range-modal.component.scss']
})
export class BudgetApprovalRangeModalComponent {

  selectedItems: Set<number> = new Set<number>();
  indeterminateItems: Set<number> = new Set<number>();

  data: BudgetApprovalModel;

  constructor(
    private modalRef: KrosModalRef
  ) {
    this.data = modalRef.data.budgetApprovalData;
    this.selectedItems = modalRef.data.selectedIds;
  }

  onCloseClick(): void {
    this.modalRef.cancel();
  }

  onSubmit(): void {
    this.modalRef.submit({ changeSheetIds: this.selectedItems });
  }
}
