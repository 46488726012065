import { TranslationCulture, translationCultures } from './translations.model';

/**
 * Returns default translation culture value.
 */
export function getDefaultTranslationCulture(): TranslationCulture {
  return 'sk-SK';
}

/**
 * Function validates unknown value to allowed translation culture values.
 */
export function valueIsTranslationCulture(value: unknown): value is TranslationCulture {
  return translationCultures.includes(value as TranslationCulture);
}

/**
 * Verifies value and returns valid culture value.
 * If validation fails, returns default translation culture.
 */
export function validateTranslationCulture(value: unknown): TranslationCulture {
  return valueIsTranslationCulture(value)
    ? value as TranslationCulture
    : getDefaultTranslationCulture();
}

/**
 * Returns empty value of translations - object with supported language keys and empty text values.
 */
export function getEmptyTranslations(): {[key: string]: string} {
  return translationCultures.reduce<{[key: string]: string}>(
    (prev, curr) => {
      prev[curr] = '';
      return prev;
    },
    {}
  );
}

/**
 * Fixes translations object - adds missing undefined languages and sets nulls to empty string.
 * @param languageTranslations
 */
export function fixLanguageTranslations(languageTranslations: { [key: string]: string }): { [key: string]: string } {
  const ret = {
    ...getEmptyTranslations(),
    ...languageTranslations
  };
  Object.keys(ret).forEach(key => {
    if (ret[key] == null) {
      ret[key] = '';
    }
  });
  return ret;
}
