<div class="toolbar-actions">
  <div class="actions left">
    <button
      class="btn btn-sm btn-primary"
      type="button"
      krosTooltip="{{ 'BUILDING_PROGRESS.DASHBOARD.SHOW_ITEMS' | translate }}"
      (click)="onCloseClick()"
      data-test="show-items-button"
    >
      <i class="material-icons icon-button">visibility</i>
      <span class="toolbar-action-text">{{ 'BUILDING_PROGRESS.DASHBOARD.SHOW_ITEMS' | translate }}</span>
    </button>
  </div>
</div>

<div class="content-wrapper">
  <ng-container *ngIf="!noItems; else noItemsText">
    <div class="chart-wrapper">
      <div class="chart-container">
        <div
          *ngIf="hasAnyAddition"
          class="dark-purple"
          data-test="dashboard-total"
        >
          <app-building-progress-pie-chart
            [currencySymbol]="currencySymbol"
            [colorScheme]="colorScheme1"
            [data]="data?.totalPrices"
            [dataGraph]="dataGraph1"
            [digitsInfo]="digitsInfo"
            [isDataLoaded]="isDataLoaded"
            [title]="'BUILDING_PROGRESS.DASHBOARD.BUILDING_PROGRESS_TOTAL' | translate"
            [totalPriceLabel]="'BUILDING_PROGRESS.DASHBOARD.BUILDING_CONTRACT_PLUS_ADDITIONS' | translate"
          ></app-building-progress-pie-chart>
        </div>

        <div
          class="blue"
          data-test="dashboard-contract-consumed"
        >
          <app-building-progress-pie-chart
            [currencySymbol]="currencySymbol"
            [colorScheme]="colorScheme2"
            [data]="data?.contractPrices"
            [dataGraph]="dataGraph2"
            [digitsInfo]="digitsInfo"
            [isDataLoaded]="isDataLoaded"
            [title]="'BUILDING_PROGRESS.DASHBOARD.BUILDING_CONTRACT_CONSUMED' | translate"
            [totalPriceLabel]="'BUILDING_PROGRESS.DASHBOARD.BUILDING_CONTRACT' | translate"
          ></app-building-progress-pie-chart>
        </div>

        <div
          *ngIf="hasAnyAddition"
          class="green"
          data-test="dashboard-additions-consumed"
        >
          <app-building-progress-pie-chart
            [currencySymbol]="currencySymbol"
            [colorScheme]="colorScheme3"
            [data]="data?.additionsPrices"
            [dataGraph]="dataGraph3"
            [digitsInfo]="digitsInfo"
            [isDataLoaded]="isDataLoaded"
            [title]="'BUILDING_PROGRESS.DASHBOARD.ADDITIONS_CONSUMED' | translate"
            [totalPriceLabel]="'BUILDING_PROGRESS.DASHBOARD.ADDITIONS' | translate"
          ></app-building-progress-pie-chart>
        </div>
      </div>
    </div>

    <div
      #tableHeaderPlaceholder
      class="header-row-wrapper"
    ></div>

    <app-building-progress-dashboard-table
      #dashboardTable
      class="tablet-hidden"
      data-test="dashboard-table"
      [data$]="tableDataSubject.asObservable()"
      (headerRowLoaded)="headerRowLoaded($event)"
    ></app-building-progress-dashboard-table>
  </ng-container>
</div>

<ng-template #noItemsText>
  <div class="no-items text-center">
    <h5>{{ 'COMMON.NO_ITEMS_MESSAGE' | translate }}</h5>
  </div>
</ng-template>

<ng-template #loading>
  <kros-timeline [type]="timelineType.ThreeLine"></kros-timeline>
</ng-template>
