<div class="panel-wrapper">
  <div class="panel-header">
    <div class="panel-header-caption">
      <div class="details-and-info">
        <h4>{{ 'DETAILS.DETAILS' | translate }}</h4>

        <i
          class="material-icons ga-item-details-show-info"
          [krosTooltip]="info | translate"
        >info_outlined</i>
      </div>

      <h4 data-test="details-label">{{label}}</h4>
    </div>

    <button
      class="btn btn-link btn-close"
      type="button"
      data-test="close-item-details-button"
      (click)="onCloseClick()"
    >
      <i class="material-icons">close</i>
    </button>
  </div>

  <div class="panel-content">
    <ng-container *ngIf="canShowDetails else canNotShowDetails">
      <form
        *ngIf="!loading else contentLoader"
        [formGroup]="detailsForm"
        (ngSubmit)="onSubmit()"
      >
        <div class="form-content">
          <div class="form-group">
            <textarea
              type="text"
              class="form-control"
              formControlName="note"
              krosTextareaAutosize
              data-test="item-details-text"
              [readOnly]="readOnly"
              [placeholder]="(readOnly ? 'DETAILS.NO_DETAILS' : 'DETAILS.TEXT_PLACEHOLDER') | translate"
              tabindex="-1"
            ></textarea>
          </div>
        </div>

        <div class="buttons-container">
          <div
            class="buttons"
            *ngIf="!readOnly && !detailsForm.pristine"
          >
            <button
              class="btn btn-primary btn-submit"
              id="ga-save-item-details"
              type="submit"
              data-test="save-item-details-button"
              [disabled]="!detailsForm.valid"
            >{{'COMMON.ULOZIT' | translate}}</button>

            <button
              class="btn btn-outline-secondary btn-cancel"
              id="ga-cancel-item-details"
              type="button"
              data-test="cancel-item-details-button"
              (click)="cancelChanges()"
            >{{'COMMON.ZRUSIT' | translate}}</button>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
</div>

<ng-template #contentLoader>
  <kros-timeline [type]="timelineType.ThreeLine"></kros-timeline>
</ng-template>

<ng-template #canNotShowDetails>
  <span>{{canNotShowDetailsLabel}}</span>
</ng-template>
