<div class="kros-modal-header">
  <h4 class="kros-modal-title">
    {{'BUDGET_APPROVAL.EXPORT.EXPORT_CHANGE_SHEETS' | translate}}
  </h4>

  <button
    class="btn btn-link close"
    type="button"
    tabindex="-1"
    (click)="onCloseClick()"
    data-test="budget-approval-change-sheets-settings-close-button"
  >
    <i class="material-icons secondary">close</i>
  </button>
</div>

<div class="kros-modal-body">
  <form [formGroup]="settingsForm">
    <div class="form-container">
      <div class="form-group checkbox">
        <input
          id="isBoqIncluded"
          type="checkbox"
          formControlName="isBoqIncluded"
          data-test="is-boq-included-checkbox"
        >
        <label for="isBoqIncluded">
          {{'BUDGET_APPROVAL.EXPORT.BOQ_INCLUDED' | translate}}
        </label>
      </div>
    </div>
  </form>
</div>

<div class="kros-modal-footer">
  <button
    class="btn btn-primary btn-submit"
    type="button"
    (click)="onSubmit()"
    data-test="budget-approval-change-sheets-settings-confirm-button"
  >{{'COMMON.APPLY'| translate}}</button>
</div>
