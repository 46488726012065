import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { TranslateModule } from '@ngx-translate/core';

import { FeatureFlags, HasFeatureFlagFacade } from '@kros-sk/core/feature-flags';
import { PermissionService } from '@kros-sk/ssw-shared/permission';

@Component({
  selector: 'budget-building-objects-upload-button',
  templateUrl: './building-objects-upload-button.component.html',
  styleUrls: ['./building-objects-upload-button.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule
  ]
})
export class BuildingObjectsUploadButtonComponent implements OnInit {

  private destroyRef = inject(DestroyRef);
  private permissionService = inject(PermissionService);
  private readonly featureFlagFacade = inject(HasFeatureFlagFacade);

  @Input() launchedFrom: 'budget' | 'building-progress';
  @Input() canShowButton: boolean;

  visible = true;

  get hasBudgetAccess(): boolean {
    return this.isBudgetMini || this.canShowButton;
  }

  private isBudgetMini: boolean;

  ngOnInit(): void {
    this.resolveVisibility();
    this.featureFlagFacade.featureFlags$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((ff: FeatureFlags) => this.isBudgetMini = ff.BudgetMini);
  }

  private resolveVisibility(): void {
    if (this.permissionService.isBuildingProgressAnyContractor()) {
      this.visible = false;
      return;
    }
    if (this.permissionService.isBuildingProgressReader() && this.launchedFrom === 'building-progress') {
      this.visible = false;
      return;
    }
    if (this.permissionService.isBuildingProgressNoAccess() && this.launchedFrom === 'budget') {
      this.visible = false;
      return;
    }
    this.visible = true;
  }
}
