import { ActionCreator, on, ReducerTypes } from '@ngrx/store';

import * as buildingProgressCommentsActions from './building-progress-comments.actions';
import { adapterCommentPositions, adapterComments } from './building-progress.adapters';
import { BuildingProgressState } from './building-progress.state';

export const commentsReducers: ReducerTypes<BuildingProgressState, ActionCreator[]>[] = [
  on(buildingProgressCommentsActions.createCommentStart, (state): BuildingProgressState => {
    return { ...state, commentsLoading: true };
  }),
  on(buildingProgressCommentsActions.createCommentError, (state): BuildingProgressState => {
    return { ...state, commentsLoading: false };
  }),
  on(buildingProgressCommentsActions.updateCommentStart, (state): BuildingProgressState => {
    return { ...state, commentsLoading: true };
  }),
  on(buildingProgressCommentsActions.updateCommentSuccess, (state, payload): BuildingProgressState => {
    const comments = adapterComments.getSelectors().selectAll(state.comments).map(c =>
      c.id === payload.comment.id ? { ...payload.comment } :
        { ...c, answers: c.answers.map(a => a.id === payload.comment.id ? { ...payload.comment } : { ...a }) });
    return {
      ...state,
      comments: adapterComments.setAll(comments, state.comments),
      commentsLoading: false
    };
  }),
  on(buildingProgressCommentsActions.updateCommentError, (state): BuildingProgressState => {
    return { ...state, commentsLoading: false };
  }),
  on(buildingProgressCommentsActions.deleteCommentStart, (state): BuildingProgressState => {
    return { ...state, commentsLoading: true };
  }),
  on(buildingProgressCommentsActions.deleteCommentSuccess, (state, payload): BuildingProgressState => {
    const comments = adapterComments.getSelectors().selectAll(state.comments).filter(c => c.id !== payload.commentId).map(c => ({
      ...c, answers: c.answers.filter(a => a.id !== payload.commentId)
    }));

    return {
      ...state,
      comments: adapterComments.setAll(comments, state.comments),
      commentsLoading: false
    };
  }),
  on(buildingProgressCommentsActions.deleteCommentError, (state): BuildingProgressState => {
    return { ...state, commentsLoading: false };
  }),
  on(buildingProgressCommentsActions.loadCommentsStart, (state): BuildingProgressState => {
    return { ...state, commentsLoading: true };
  }),
  on(buildingProgressCommentsActions.loadCommentsSuccess, (state, payload): BuildingProgressState => {
    return {
      ...state,
      comments: adapterComments.setAll(payload.comments, state.comments),
      commentsLoading: false
    };
  }),
  on(buildingProgressCommentsActions.loadCommentsError, (state): BuildingProgressState => {
    return { ...state, commentsLoading: false };
  }),
  on(buildingProgressCommentsActions.loadCommentPositionsSuccess, (state, payload): BuildingProgressState => {
    return {
      ...state,
      commentPositions: adapterCommentPositions.setAll(payload.commentPositions, state.commentPositions),
    };
  }),
];
