import {
  AfterViewInit,
  ChangeDetectorRef,
  ContentChild,
  Directive,
  Host,
  Input,
  OnDestroy,
  Optional,
} from '@angular/core';
import { ControlContainer, FormArray, FormGroup, FormGroupDirective } from '@angular/forms';

import { filter, map } from 'rxjs/operators';
import { FormGroupState, NgrxFormDirective } from 'ngrx-forms';
import { Observable, of } from 'rxjs';
import { SubSink } from 'subsink';
import { TranslateService } from '@ngx-translate/core';

import { ErrorsSelectorService } from '@kros-sk/core';

import { ButtonTypes, KrosModalService, MessageBoxProperties, MessageTypes } from '../kros-modal';
import { FormGuard } from './form.guard';
import { FormGuardSaverDirective } from './form-guard-saver.directive';
import { hasAnyFormControlNotOptionalError } from '../tools';
import { InputCommandType, KrosFormsService } from '../inputs';

export interface CanLeaveBeforeSaveResult {
  canLeave: boolean;
  forceSave: boolean;
}

// TODO (PS) better to create two classes for NgControl and NgrxForm. Each implement interface for methods.
// Create instance of class based if NgControl or NgrxForm is present
// methods will no more need to if inside.
@Directive({
  selector: '[krosFormGuard]',
  exportAs: 'krosFormGuard',
})
export class FormGuardDirective implements AfterViewInit, OnDestroy {
  @Input({
    transform: (value: unknown) => value ? value : { checkSearch: false }
  }) krosFormGuard: { checkSearch: boolean } = { checkSearch: false };

  @ContentChild(FormGuardSaverDirective) private saveTrigger: FormGuardSaverDirective;

  private formRef: FormGroupDirective;
  private subs = new SubSink();
  private searchApiWork = true;

  constructor(
    @Host() @Optional() formRef: ControlContainer,
    @Host() @Optional() private formStateRef: NgrxFormDirective<any>,
    private modals: KrosModalService,
    private cdr: ChangeDetectorRef,
    private formGuard: FormGuard,
    private formsService: KrosFormsService,
    private errorSelector: ErrorsSelectorService,
    private translate: TranslateService
  ) {
    this.formRef = formRef as FormGroupDirective || null;
  }

  ngAfterViewInit(): void {
    if (this.krosFormGuard.checkSearch) {
      this.subs.sink = this.errorSelector.selectConcreteServiceDown$('search')
        .pipe(filter(error => !!error))
        .subscribe(error => this.searchApiWork = error.isDown);
    }
    this.setRememberedForm();
    this.formGuard.addActiveFormGuardInstance(this);
  }

  ngOnDestroy(): void {
    this.formGuard.removeActiveFormGuardInstance(this);
    this.subs.unsubscribe();
  }

  canLeaveBeforeSave(shouldRememberForm: boolean = false): Observable<CanLeaveBeforeSaveResult> {
    const form = this.formRef?.form || null;
    const formState = this.formStateRef?.state || null;
    this.formGuard.formMemory = null;

    if (this.isFormDirty(form, formState)) {
      if (shouldRememberForm) {
        this.formGuard.formMemory = form;
      } else {
        if (this.isFormValid(form, formState)) {
          return this.canLeaveBeforeSaveValidData();
        } else {
          if (this.hasTotalPriceMismatchError(form, formState)) {
            return this.canLeaveBeforeSaveInvalidData({
              caption: 'Chcete odísť bez uloženia?',
              message: 'Suma spolu sa nerovná so sumou v rozpise DPH',
              messageType: MessageTypes.Primary,
              acceptButton: 'Opraviť'
            });
          }
          return this.canLeaveBeforeSaveInvalidData();
        }
      }
    }

    // there is nothing to save, but user can leave form
    return of({
      canLeave: true,
      forceSave: false
    });
  }

  // compatibility with angular forms and ngrx-forms
  private isFormDirty(form: FormGroup<any> | null, formState: FormGroupState<any> | null): boolean {
    return form?.dirty || formState?.isDirty;
  }

  // TODO wait for finish async validations
  // compatibility with angular forms and ngrx-forms
  private isFormValid(form: FormGroup<any> | null, formState: FormGroupState<any> | null): boolean {
    if (form) {
      return form.valid || !hasAnyFormControlNotOptionalError(form);
    }

    if (formState) {
      // TODO (PS) hasAnyFormControlNotOptionalError something like optional erros doesnt exists yet
      // vyriesime pomocou vlastnych ngrx-forms validatorov, ktore budu ku chybe nastavovat nejaky warning flag
      return formState.isValid;
    }

    return true;
  }

  // compatibility with angular forms and ngrx-forms
  private hasTotalPriceMismatchError(form: FormGroup<any> | null, formState: FormGroupState<any> | null): boolean {
    // TODO (PS) ...but this is really tight coupling with form structure.
    return form?.hasError('totalPriceMismatch') || Boolean(formState?.errors._settings?._totalPrice?.$totalPriceMismatch);
  }

  /**
   * Offer saving trigger as public API for parent directive
   */
  get save$(): Observable<any> {
    return this.saveTrigger?.trigger$;
  }

  /**
   * Used to set form state after changing screen resolution
   * and Master/Detail caused route change and component destruction
   */
  setRememberedForm(): void {
    if (!this.formGuard.formMemory) return;

    const formGroup = this.formGuard.formMemory;
    const form = this.formRef.form;
    Object.keys(formGroup.controls).forEach(controlName => {
      if (!(formGroup.controls[controlName] instanceof FormArray)) {
        form.controls[controlName].setValue(formGroup.controls[controlName].value, { emitEvent: false });
        form.controls[controlName].markAsTouched();
        this.formsService.triggerInputCommand(controlName, { type: InputCommandType.DETECT_CHANGES });
      }
    });
    form.markAsDirty();
    this.cdr.detectChanges();
  }

  private canLeaveBeforeSaveValidData(): Observable<CanLeaveBeforeSaveResult> {
    let caption = this.translate.instant('COMMON.STAY_AT_DOCUMENT.QUESTION');
    let acceptButton = this.translate.instant('COMMON.STAY_AT_DOCUMENT.STAY');
    if (this.searchApiWork) {
      caption = this.translate.instant('COMMON.SAVE_QUESTION');
      acceptButton = this.translate.instant('COMMON.SAVE');
    }
    return this.modals.openModalMessageBox({
      icon: 'warning',
      caption,
      messageType: MessageTypes.Primary,
      acceptButton,
      cancelButton: this.translate.instant('COMMON.LEAVE_WITHOUT_SAVE.CANCEL'),
      focusedButton: ButtonTypes.Accept,
    }, {
      addModalToBrowsersHistory: false,
      allowFocusAutocapture: false,
      closeOnBackdropClick: false,
    }).pipe(
      map(result => {
        if (result.data && (!this.searchApiWork)) {
          return {
            canLeave: result.data === 'cancel',
            forceSave: false,
          };
        }
        if (result.data) {
          // the user clicked on a button
          return {
            canLeave: true,
            forceSave: result.data !== 'cancel'
          };
        }

        // the user hit a close 'X' button
        return {
          canLeave: false,
          forceSave: false,
        };
      })
    );
  }

  private canLeaveBeforeSaveInvalidData(modalSettings: MessageBoxProperties = null): Observable<CanLeaveBeforeSaveResult> {
    return this.modals.openModalMessageBox({
      icon: 'warning',
      caption: modalSettings && modalSettings.caption ? modalSettings.caption :
        this.translate.instant('COMMON.LEAVE_WITHOUT_SAVE.QUESTION'),
      message: modalSettings && modalSettings.message ? modalSettings.message : null,
      messageType: modalSettings && modalSettings.messageType ? modalSettings.messageType : MessageTypes.Primary,
      acceptButton: modalSettings && modalSettings.acceptButton ? modalSettings.acceptButton :
        this.translate.instant('COMMON.LEAVE_WITHOUT_SAVE.ACCEPT'),
      cancelButton: this.translate.instant('COMMON.LEAVE_WITHOUT_SAVE.CANCEL'),
      focusedButton: ButtonTypes.Accept,
    }, {
      addModalToBrowsersHistory: false,
      allowFocusAutocapture: false,
      closeOnBackdropClick: false,
    }).pipe(
      map(result => {
        if (result.data) {
          // the user clicked on a button
          if (result.data === 'accept') {
            return {
              canLeave: false,
              forceSave: true
            };
          }
          return {
            canLeave: result.data === 'cancel',
            forceSave: false
          };
        }

        // the user hit a close 'X' button
        return {
          canLeave: false,
          forceSave: false,
        };
      })
    );
  }
}
