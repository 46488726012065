import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { BuildingProgressCreateInvoiceModel } from '../models/building-progress-invoice/building-progress-create-invoice.model';
import { BuildingProgressCreateInvoiceResult } from '../models/building-progress-invoice/building-progress-create-invoice-result.model';
import { environment } from '../../../environments/environment';
import { InvoiceDetails } from '../models/building-progress-invoice/building-progress-invoice-details.model';
import { PeriodInvoices } from '../models/building-progress-invoice/building-progress-period-invoices.model';

@Injectable({
  providedIn: 'root'
})
export class BuildingProgressInvoiceService {

  constructor(
    private http: HttpClient
  ) { }

  createInvoice(
    data: BuildingProgressCreateInvoiceModel,
    uiCulture: string): Observable<BuildingProgressCreateInvoiceResult> {

    return this.http.post<BuildingProgressCreateInvoiceResult>(
      this.getCreateInvoiceEndpoint(data.projectId),
      data,
      { params: { 'ui-culture': uiCulture } });
  }

  getInvoices(projectId: number, periodId: number): Observable<InvoiceDetails[]> {
    return this.http.get<PeriodInvoices[]>(`${this.getInvoicesEndpoint(projectId)}/${periodId}`).pipe(
      map((periodInvoices: PeriodInvoices[]) => {
        return periodInvoices.length === 0 ? [] : periodInvoices[0].invoiceDetails;
      }));
  }

  getAllProjectInvoices(projectId: number): Observable<PeriodInvoices[]> {
    return this.http.get<PeriodInvoices[]>(this.getInvoicesEndpoint(projectId));
  }

  private getCreateInvoiceEndpoint(projectId: number): string {
    return this.getBuildingProgressServiceApi(projectId) + 'PeriodInvoices/create';
  }

  private getInvoicesEndpoint(projectId: number): string {
    return this.getBuildingProgressServiceApi(projectId) + 'PeriodInvoices/list';
  }

  private getBuildingProgressServiceApi(projectId: number): string {
    return `${environment.appUrls.titanGatewayUrl}/api/projects/${projectId}/buildingProgressService/`;
  }
}
