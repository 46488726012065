<div>
  <div class="input-row">
    <tag-input
      id="tag-input"
      class="tag-input"
      [(ngModel)]="inputItems"
      [(inputText)]="inputText"
      [clearOnBlur]="false"
      [validators]="validators"
      [separatorKeyCodes]="separatorKeysCodes"
      [onTextChangeDebounce]="1"
      [errorMessages]="errorMessages"
      theme="titan-theme"
      placeholder="{{ 'SHARING.ENTER_EMAIL' | translate }}"
      secondaryPlaceholder="{{ 'SHARING.ENTER_EMAIL' | translate }}"
      (onTextChange)="onTextChange($event)"
      data-test="sharing-list-input"
    ></tag-input>
    <kros-user-sharing-dropdown
      class="sharing-combo"
      [sharingMode]="sharingMode"
      [defaultValues]="defaultValues"
    ></kros-user-sharing-dropdown>
  </div>

  @if (wasModified) {
  <textarea
    class="form-control text-area"
    placeholder="{{ 'SHARING.USER_MESSAGE_PLACEHOLDER' | translate }}"
    rows="3"
    [(ngModel)]="message"
  ></textarea>

  @if (warningText) {
  <div
    class="warning-text"
    [attr.data-test]="dataTestPrefix + '-warning'"
  >
    <span>{{ warningText }}</span>
  </div>
  }

  <div
    class="buttons"
  >
    <button
      class="btn btn-primary left-button"
      (click)="onSubmit()"
      data-test="sharing-list-input-submit"
    >{{'SHARING.ADD' | translate }}</button>
    <button
      class="btn btn-outline-secondary right-button"
      (click)="onCloseClick()"
    >{{'COMMON.ZRUSIT' | translate }}</button>
  </div>
  }
</div>
