<ngb-toast
  *ngFor="let toast of toasts"
  [class]="classByType(toast.type)"
  [autohide]="toast.options.autohide"
  [delay]="toast.options.delay"
  (hidden)="toastService.close(toast)"
>

  <ng-template *ngIf="isTemplate(toast) else text">
    <ng-template [ngTemplateOutlet]="toast.content">
    </ng-template>
  </ng-template>

  <ng-template #text>
    <div
      class="text-toast"
      data-test="text-toast"
    >
      <span
        *ngIf="!toastService.isInfoType(toast.type)"
        krosMaterialIcon
        class="toast-icon icon"
      >error</span>
      <span class="text">{{ toast.content }}</span>
      <button
        *ngIf="toast.actionButton"
        class="btn btn-link action"
        type="button"
        (click)="toastService.dismissedByAction(toast)"
      >
        {{toast.actionButton}}
      </button>
      <button
        class="btn btn-link close"
        type="button"
        (click)="toastService.close(toast)"
      >
        <span
          krosMaterialIcon
          class="icon"
        >close</span>
      </button>
    </div>
  </ng-template>

</ngb-toast>
