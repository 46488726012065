import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { ItemSharing } from '../../../models';
import { NewSharingComboMode } from '../../models/sharing-combo-mode.enum';
import { PermissionListService } from '../../services/permission-list.service';
import { SharingPermissionValues } from '../../models/sharing-permission.interface';
import { ToastService } from '../../../toast';
import { TranslateService } from '../../../translate';
import { UserSharingDropdownComponent } from '../user-sharing-dropdown/user-sharing-dropdown.component';
import { UserSharingInputBaseComponent } from '../user-sharing-input-base/user-sharing-input-base.component';
import { UserSharingService } from '../../services/user-sharing.service';

@Component({
  selector: 'kros-user-sharing-input',
  templateUrl: './user-sharing-input.component.html',
  styleUrls: ['./user-sharing-input.component.scss']
})
export class UserSharingInputComponent extends UserSharingInputBaseComponent {
  @Input() sharingMode = NewSharingComboMode.AddUserBuildingProgress;
  @Input() dataTestPrefix = '';
  @Input() defaultValues: SharingPermissionValues;
  @Input() warningText: string;

  @Output() shared = new EventEmitter<ItemSharing[]>();

  @ViewChild(UserSharingDropdownComponent) userSharingCombo: UserSharingDropdownComponent;

  constructor(
    translateService: TranslateService,
    userSharingService: UserSharingService,
    toastService: ToastService,
    private permissionListService: PermissionListService
  ) {
    super(translateService, userSharingService, toastService);
  }

  protected submitSharing(): void {
    this.shared.emit(
      this.permissionListService.getNewSharings(
        this.users,
        this.message,
        +this.userSharingCombo.selectedPermission.permissions.permission,
        +this.userSharingCombo.selectedPermission.permissions.additionalPermission
      )
    );
    this.resetInput();

    if (this.emailsReadySubscription) {
      this.emailsReadySubscription.unsubscribe();
    }
  }
}
