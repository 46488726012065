<div class="grid-container">
  <div class="content-grid">
    <div class="auto-sized-content">
      <div class="owner-content">
        <ng-container *ngIf="isLoaded else loader">
          <div class="card-grid-item">
            <div class="sharing-list-card">
              <div
                class="card-content"
                [ngClass]="{'unsaved': isOwnerChanged}"
              >
                <div class="header">
                  <div class="left-div">
                    <kros-titan-avatar
                      class="avatar-s"
                      [email]="ownerEmail"
                    ></kros-titan-avatar>
                    <div class="user">
                      <span
                        class="user-mail"
                        data-test="current-project-owner"
                      >
                        {{ ownerEmail }}
                      </span>
                      <span
                        *ngIf="isOwnerChanged"
                        class="unsaved-text"
                      >
                        {{ 'SHARING.NOT_SAVED' | translate }}
                      </span>
                    </div>
                  </div>
                  <div class="right-div">
                    <ng-container *ngIf="!isReadOnly; else readOnlyOwner">
                      <kros-owner-sharing-combo
                        (changedOwner)="onOwnerChanged($event)"
                        [emails]="candidateEmails"
                      >
                      </kros-owner-sharing-combo>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ng-container *ngIf="isOwnerChanged">
            <div class="warning">
              <div class="warning-text">
                <p>{{ 'SHARING.CHANGE_OWNER_QUESTION' | translate }}</p>

                <p *ngIf="isSkVersion">{{ 'SHARING.CHANGE_OWNER_QUESTION_INVOICING' | translate }}</p>
              </div>
            </div>

            <div class="button">
              <button
                class="btn btn-primary btn-save"
                type="button"
                (click)="onSave()"
                data-test="change-owner-submit"
              >{{ 'COMMON.ULOZIT' | translate }}</button>
            </div>

            <div class="button">
              <button
                class="btn btn-outline-secondary btn-cancel"
                type="button"
                (click)="onCancel()"
              >{{ 'COMMON.ZRUSIT' | translate }}</button>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <ng-template #readOnlyOwner>
        <span class="read-only-owner">{{ 'SHARING.OWNER' | translate }}</span>
      </ng-template>

      <ng-template #loader>
        <kros-timeline
          class="loader"
          [type]="timelineType.TwoLine"
        ></kros-timeline>
      </ng-template>
    </div>
  </div>
</div>
