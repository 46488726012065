import { InjectionToken } from '@angular/core';

export interface ExpirableCacheSettings {
  /**
   * Expiration time in seconds.
   */
  expirationTime: number;
  /**
   * Value whether the cache should be cleaned from expired items regularly.
   */
  cleanCache: boolean;
  /**
   * Cleaning interval in seconds.
   */
  cleaningInterval: number;
}

export const EXPIRABLE_CACHE_SETTINGS = new InjectionToken<ExpirableCacheSettings>('Expirable cache settings');
