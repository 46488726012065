<div
  class="panel-container"
  [attr.data-test]="'structure-panel' + (collapsed ? '-collapsed' : '')"
  [ngClass]="{'collapsed': collapsed}"
>
  <div class="header">
    <h5>{{ title }}</h5>

    <div
      class="collapse-button"
      (click)="toggleCollapsePanel()"
      [attr.data-test]="dataTestCollapse"
    >
      <span class="material-icons icon-button">
        {{ collapsed ? 'keyboard_double_arrow_right' : 'keyboard_double_arrow_left' }}
      </span>
    </div>

    <div
      *ngIf="collapsed"
      class="collapsed-text"
    >{{ title }}</div>
  </div>

  <div class="building">
    <app-budget-structure
      *ngIf="hasPermission"
      class="building-tree"
      [title]="title"
      (nodeSelected)="onNodeSelected()"
      [data$]="data$"
    ></app-budget-structure>
  </div>
</div>
